define('busy-app/components/equipment/splash-screen', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service(),

		isSaving: false,
		equipmentUrl: null,

		actions: {
			onClose: function onClose() {
				this.sendAction('onClose');
			},
			onEnable: function onEnable() {
				var _this = this;

				var org = Ember.isNone(this.get('auth.organization.content')) ? this.get('auth.organization') : this.get('auth.organization.content');

				// popup blocker workaround:
				//   open the window here, before the async action, then navigate or close the window depending on the result of saving to the org
				//   set busy background color to fake a loading screen while the save is in progress
				var equipmentWindow = window.open();
				equipmentWindow.document.write('<html style="width: 100%; height: 100%; background-color: #2D3E50"></html>');

				org.set('trackEquipment', true);

				this.set('isSaving', true);
				return org.save().then(function () {
					if (!_this.get('isDestroyed')) {
						// navigate the popped window to the correct url
						equipmentWindow.location = _this.get('equipmentUrl');
						equipmentWindow.focus();

						_this.set('isSaving', false);
						_this.send('onClose');
					}
				}).catch(function () {
					// close the popped window
					equipmentWindow.close();
				});
			},
			onLearnMore: function onLearnMore() {
				window.open('http://support.busybusy.com/faq/what-is-the-busybusy-equipment-app');
			}
		}
	});
});