define('busy-app/mixins/check-permission', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		permissions: null,
		permissionStatement: null,

		abortTransition: function abortTransition(transition) {
			transition.abort();
			this.transitionTo('index');
		},
		hasPermission: function hasPermission(authPosition) {
			var hasPermission = true;
			if (!Ember.isNone(this.get('permissions'))) {
				this.get('permissions').forEach(function (permission) {
					if (!authPosition.get(permission)) {
						hasPermission = false;
					}
				});
			} else if (!Ember.isNone(this.get('permissionStatement'))) {
				var statements = this.get('permissionStatement').split(' ');
				var truthy = false;
				var compare = null;
				statements.forEach(function (permission) {
					if (['||', '&&'].indexOf(permission) === -1) {
						var val = !!authPosition.get(permission);
						if (!compare) {
							truthy = val;
						} else {
							if (compare === '||') {
								truthy = truthy || val;
							} else {
								truthy = truthy && val;
							}
						}
					} else {
						compare = permission;
					}
				});

				hasPermission = truthy;
			}
			return hasPermission;
		},


		beforeModel: function beforeModel(transition) {
			if (this.get('features.serverOutage')) {
				return;
			}

			if (!Ember.isEmpty(this.get('permissions')) || !Ember.isEmpty(this.get('permissionStatement'))) {
				var auth = this.modelFor('application');
				if (Ember.isNone(auth.member) || Ember.isNone(auth.positions)) {
					this.abortTransition(transition);
				} else {
					var authPosition = auth.positions.findBy('id', auth.member.get('positionId'));
					if (!this.hasPermission(authPosition)) {
						this.abortTransition(transition);
					} else {
						return this._super.apply(this, arguments);
					}
				}
			} else {
				return this._super.apply(this, arguments);
			}
		}
	});
});