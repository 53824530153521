define('busy-app/services/data-store', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function getStoreObject(key) {
		(false && !(typeof key === 'string') && Ember.assert("You must provide a string as the first param to getStoreObject", typeof key === 'string'));


		return JSON.parse(localStorage.getItem(key)) || {};
	}

	function setStoreObject(key, value) {
		(false && !(typeof key === 'string') && Ember.assert("You must provide a string as the first param to setStoreObject", typeof key === 'string'));
		(false && !((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') && Ember.assert("You must provide an object as the second param to setStoreObject", (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object'));


		localStorage.setItem(key, JSON.stringify(value));
	}

	var AUTH_MAP_KEY = 'busy-app-session:map';
	var SWITCH_KEY = 'busy-app-session:switch';
	var ACTIVE_MEMBER_KEY = 'busy-app-session:active';
	var SIMPLE_AUTH_KEY = 'ember_simple_auth-session'; // has to be ember-simple-auth key

	exports.default = Ember.Service.extend({
		state: 'unathenticated',

		store: Ember.inject.service('store'),
		session: Ember.inject.service('session'),
		auth: Ember.inject.service('auth'),

		save: function save(type, data) {
			var hasMany = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

			_utils.Assert.test("save requires all data to have an id", data.id);

			if (hasMany) {
				var model = this.find(type, { id: data.id });
				var modelArray = this.find(type);
				var idx = modelArray.length;
				if (model) {
					idx = modelArray.indexOf(model);
				}
				modelArray[idx] = data;
				localStorage.setItem(type, JSON.stringify(modelArray));
			} else {
				localStorage.setItem(type, JSON.stringify(data));
			}
		},
		find: function find(type) {
			var search = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			var list = localStorage.getItem(type);
			if (Ember.isEmpty(list)) {
				list = [];
			} else {
				list = JSON.parse(list);
			}

			if (Ember.typeOf(list) === 'array') {
				Object.keys(search).forEach(function (key) {
					var value = search[key];
					list = list.filter(function (item) {
						return item[key] === value;
					});
				});
				return list.length === 1 ? list[0] : list;
			} else {
				return list;
			}
		},


		/**
   * this is the reference point for the suspended auth
   * data stored in localStorage.
   *
   * @public
   * @method suspendedKey
   */
		suspendedKey: function suspendedKey() {
			return localStorage.getItem(SWITCH_KEY);
		},


		/**
   * removes the authenticated member but keeps the data stored
   * in localStorage referenced with suspendedKey() for retrieval
   *
   * @public
   * @method suspendAuth
   */
		suspendAuth: function suspendAuth() {
			var oldKey = localStorage.getItem(ACTIVE_MEMBER_KEY);
			localStorage.setItem(SWITCH_KEY, oldKey);
			localStorage.setItem(ACTIVE_MEMBER_KEY, '');
			this.set('state', 'suspended');

			return this.get('auth').invalidateSession(true);
		},


		/**
   * switches the authenticated account using the same key
   * that is passed to addAuthAccount(key, value)
   *
   * @public
   * @method switchAuth
   */
		switchAuth: function switchAuth(key) {
			(false && !(Ember.typeOf(key) === 'string') && Ember.assert('key must be a string in switchKey(key)', Ember.typeOf(key) === 'string'));

			var authMap = getStoreObject(AUTH_MAP_KEY);

			(false && !(Ember.get(authMap, key) !== undefined) && Ember.assert('The key provided to switchKey(key) was not found in auth', Ember.get(authMap, key) !== undefined));


			localStorage.setItem(SWITCH_KEY, '');
			localStorage.setItem(ACTIVE_MEMBER_KEY, key);
			return this.get('session').authenticate('authenticator:basic', null, null, Ember.get(authMap, key + '.auth'));
		},


		/**
   * restores the auth account after the suspendAuth() method has been
   * invoked.
   *
   * @public
   * @method resumeAuth
   */
		resumeAuth: function resumeAuth() {
			var oldKey = this.suspendedKey();
			this.switchAuth(oldKey);
		},


		/**
   * gets all auth account data from the localStorage. returns
   * an array of auth objects
   *
   * @public
   * @method getAuthAccounts
   */
		getAuthAccounts: function getAuthAccounts() {
			var accounts = getStoreObject(AUTH_MAP_KEY);
			var data = Object.keys(accounts).map(function (key) {
				var item = accounts[key];
				return item.org;
			});

			return this.get('store').push({ data: data });
		},
		getAuthId: function getAuthId() {
			return localStorage.getItem(ACTIVE_MEMBER_KEY);
		},


		/**
   * adds an account reference value to the account map for lookup of other accounts
   *
   * @public
   * @method addAuthAccount
   * @param key {string}
   * @param value {string}
   */
		addAuthAccount: function addAuthAccount(organization) {
			var auth = getStoreObject(SIMPLE_AUTH_KEY);
			if (auth && auth.authenticated) {
				auth = auth.authenticated;

				var org = {
					id: organization.id,
					type: 'organization',
					attributes: organization.toJSON()
				};

				var accounts = getStoreObject(AUTH_MAP_KEY);
				accounts[organization.id] = { auth: auth, org: org };
				setStoreObject(AUTH_MAP_KEY, accounts);
				localStorage.setItem(ACTIVE_MEMBER_KEY, organization.id);
				localStorage.setItem(SWITCH_KEY, '');
			}
		},
		authenticated: function authenticated(data) {
			this.set('state', 'authenticated');
			this.save('auth-member', data, false);
		},
		clear: function clear() {
			if (this.get('state') === 'authenticated') {
				this.set('state', 'unathenticated');
				localStorage.clear();
			}
		}
	});
});