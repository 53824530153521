define('busy-app/components/charts/legacy-bar-chart', ['exports', '@busy-web/utils/loc'], function (exports, _loc) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/**
  * Bar Chart helper object to convert values into
  * functional properties to return in htmlbars.
  *
  * @class GraphObject
  * @namespace Components.BusyCircleGraph
  * @extends EmberObject
  */
	var graphObject = Ember.Object.extend({
		title: null,
		hours: null,
		cost: null,
		costWords: null,
		hoursWords: null,
		noBar: false,

		parentObj: null,

		costStyle: Ember.computed('cost', 'parentObj.maxCost', function () {
			// get cost or set to default of 1
			var cost = Ember.get(this, 'cost') || 1;

			// if cost is less than one set it to one.
			cost = cost > 1 ? cost : 1;

			// calculate the width percentage
			var widthPercent = Math.ceil(cost / Ember.get(this, 'parentObj.maxCost') * 100 * 0.80);

			// return as a string of css
			return Ember.String.htmlSafe('width: ' + widthPercent + '%;');
		}),

		hoursStyle: Ember.computed('hours', 'parentObj.maxHours', function () {
			// get the hours or set to default of 1
			var hours = Ember.get(this, 'hours') || 1;

			// if hours is less than one set it to one.
			hours = hours > 1 ? hours : 1;

			// caclulate the with percentage
			var widthPercent = Math.ceil(hours / Ember.get(this, 'parentObj.maxHours') * 100 * 0.80);

			// return as a string of css
			return Ember.String.htmlSafe('width: ' + widthPercent + '%;');
		}),

		costString: Ember.computed('cost', function () {
			// return cost or a default of 0
			return Ember.get(this, 'cost') || 0;
		}),

		hoursString: Ember.computed('hours', function () {
			// return hours or a default of 0
			return Ember.get(this, 'hours') || 0;
		})
	});

	/**
  * `Component/Charts/LegacyBarChart`
  *
  * @class LegacyBarChart
  * @namespace Components.Charts
  * @extends Component
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['c-charts-legacy-bar-chart'],

		content: null,

		title: null,
		hours: null,
		cost: null,
		totalHours: null,
		totalCost: null,
		type: null,

		hourText: '',
		costText: '',

		maxHours: 1,
		maxCost: 1,

		graphData: null,
		sortedGraphData: null,

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();
			this.buildDataArray();
		},
		buildDataArray: function buildDataArray() {
			var _this = this;

			Ember.set(this, 'graphData', null);
			var graphData = [];
			if (!Ember.isNone(Ember.get(this, 'content'))) {
				var maxCost = 1;
				var maxHours = 1;
				var totalHours = 0;
				var totalCost = 0;
				var project = null;

				Ember.get(this, 'content').forEach(function (item) {
					if (Ember.get(_this, 'type') === 'Subprojects') {
						project = Ember.get(item, 'project');
					} else if (Ember.get(_this, 'type') === 'Employees') {
						project = Ember.get(_this, 'project');
					}

					var member = null;
					var title = Ember.get(item, Ember.get(_this, 'title'));
					var hours = parseInt(Ember.get(item, Ember.get(_this, 'hours')), 10);
					var cost = parseFloat(Ember.get(item, Ember.get(_this, 'cost')));
					totalHours += Ember.get(item, Ember.get(_this, 'hours'));
					totalCost += Ember.get(item, Ember.get(_this, 'cost'));

					if (cost > maxCost) {
						maxCost = cost;
					}

					if (hours > maxHours) {
						maxHours = hours;
					}

					if (!Ember.isNone(Ember.get(item, 'member'))) {
						member = Ember.get(item, 'member');
					}

					if (hours > 0) {
						var obj = graphObject.create();
						Ember.set(obj, 'title', title);
						Ember.set(obj, 'hours', hours);
						Ember.set(obj, 'cost', cost);
						Ember.set(obj, 'member', member);
						Ember.set(obj, 'project', project);
						Ember.set(obj, 'parentObj', _this);

						graphData.pushObject(obj);
					}
				});

				Ember.set(this, 'maxCost', maxCost);
				Ember.set(this, 'maxHours', maxHours);
				Ember.set(this, 'totalCost', totalCost);
				Ember.set(this, 'totalHours', totalHours);
			}

			Ember.set(this, 'graphData', graphData.sortBy('title'));
		},


		totalCostString: Ember.computed('totalCost', function () {
			return Ember.get(this, 'totalCost') || 0;
		}),

		totalHoursString: Ember.computed('totalHours', function () {
			return Ember.get(this, 'totalHours') || 0;
		}),

		setMaxCost: function setMaxCost() {
			var _this2 = this;

			Ember.get(this, 'graphData').forEach(function (item) {
				if (Ember.get(item, 'cost') > Ember.get(_this2, 'maxCost')) {
					Ember.set(_this2, 'maxCost', Ember.get(item, 'cost'));
				}
			});
			this.setCostStyle();
		},
		setMaxHours: function setMaxHours() {
			var _this3 = this;

			Ember.get(this, 'graphData').forEach(function (item) {
				if (Ember.get(item, 'hours') > Ember.get(_this3, 'maxHours')) {
					Ember.set(_this3, 'maxHours', Ember.get(item, 'hours'));
				}
			});
			this.setHourStyle();
		},


		setCostStyle: function setCostStyle() {
			var _this4 = this;

			Ember.get(this, 'graphData').forEach(function (item) {
				var percentage = 0;
				var maxCost = Ember.get(_this4, 'maxCost');
				var itemCost = Ember.get(item, 'cost');
				if (itemCost === 0 && maxCost === 0) {
					Ember.set(item, 'costStyle', 'width: 2%;');
				}

				if (itemCost === 0) {
					Ember.set(item, 'costStyle', 'width: ' + 2 + '%;');
				}

				if (itemCost !== 0 || maxCost !== 0) {
					percentage = itemCost / maxCost * 100 * 0.90;
					if (percentage > 5) {
						Ember.set(item, 'costStyle', 'width: ' + percentage + '%;');
					}
				}
			});
		},

		setHourStyle: function setHourStyle() {
			var _this5 = this;

			Ember.get(this, 'graphData').forEach(function (item) {
				var percentage = 0;
				var maxHours = Ember.get(_this5, 'maxHours');
				var itemHours = Ember.get(item, 'hours');
				if (itemHours === 0 && maxHours === 0) {
					Ember.set(item, 'hoursStyle', 'width: ' + 2 + '%;');
				}

				if (itemHours === 0) {
					Ember.set(item, 'hoursStyle', 'width: ' + 2 + '%;');
				}

				if (itemHours !== 0 || maxHours !== 0) {
					percentage = itemHours / maxHours * 100 * 0.90;
					if (percentage > 5) {
						Ember.set(item, 'hoursStyle', 'width: ' + percentage + '%;');
					}
				}
			});
		},
		setCostWords: function setCostWords() {
			Ember.get(this, 'graphData').forEach(function (item) {
				var current = Ember.get(item, 'cost');
				if (current === 0) {
					Ember.set(item, 'costWords', (0, _loc.default)('less than $1'));
				}

				if (current > 0) {
					Ember.set(item, 'costWords', '$' + current);
				}
			});
		},
		setHourWords: function setHourWords() {
			Ember.get(this, 'graphData').forEach(function (item) {
				var current = Ember.get(item, 'hours');
				if (current === 0) {
					Ember.set(item, 'hoursWords', (0, _loc.default)('less than 1 hour'));
				}

				if (current > 0) {
					Ember.set(item, 'hoursWords', current + ' hours');
				}
			});
		},


		actions: {
			titleSelected: function titleSelected(item) {
				this.sendAction('titleSelected', item);
			}
		}
	});
});