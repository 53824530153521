define('busy-app/helpers/is-equal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.isEqual = isEqual;
	function isEqual(params /*, hash*/) {
		return params[0] === params[1];
	}

	exports.default = Ember.Helper.helper(isEqual);
});