define('busy-app/routes/company-settings/intuit-quickbooks', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    // beforeModel(transition) {
    // 	var org = this.modelFor('application').organization;
    // 	if(isNone(org) || !this.features.get('quickbooks')) {
    // 		transition.abort();
    // 		this.transitionTo('index');
    // 	} else {
    // 		return this._super.apply(this, arguments);
    // 	}
    // },
  });
});