define('busy-app/utils/debug-helper', ['exports', 'busy-app/utils/logger', 'busy-app/utils/settings'], function (exports, _logger, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	var DebugHelper = function () {
		function DebugHelper() {
			_classCallCheck(this, DebugHelper);

			this.stack = null;
		}

		_createClass(DebugHelper, [{
			key: 'log',
			value: function log() {
				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				if (_settings.default.get('log_level') >= 3) {
					if (Ember.isNone(Ember.get(this, 'stack'))) {
						Ember.set(this, 'stack', []);
					}

					args = args.map(function (item) {
						if (!Ember.isNone(item) && (typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object') {
							if (Ember.isArray(item) && !item.content) {
								return item.slice(0);
							}
							return Ember.merge({}, item);
						}
						return item;
					});

					Ember.get(this, 'stack').push(args);
				}
			}
		}, {
			key: 'post',
			value: function post() {
				(0, _logger.print)('\nDEBUG HANDLER', Ember.get(this, 'id'), '------------------');
				Ember.get(this, 'stack').forEach(function (args, idx) {
					_logger.print.apply(undefined, [idx + 1].concat(_toConsumableArray(args)));
				});
				(0, _logger.print)('END DEBUG HANDLER', Ember.get(this, 'id'), '---------------\n\n');
			}
		}]);

		return DebugHelper;
	}();

	exports.default = DebugHelper;
});