define('busy-app/helpers/is-greater', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isGreater = isGreater;


  /**
   * HTMLBars Helper method for checking if value 1 is greater than value 2.
   *
   * @public
   * @method is-greater
   * @param {number} param1 The value to test if it is greater
   * @param {number} param2 The value to test against param1.
   * @returns {boolean} true if param1 is greater than param2.
   */
  /**
   * @module helpers
   *
   */
  function isGreater(params) {
    // Assert only 2 params are passed in
    _utils.Assert.funcNumArgs(params, 2, true);

    // convert params to int
    var p1 = parseInt(params[0], 10);
    var p2 = parseInt(params[1], 10);

    // Assert p1 and p2 are numbers and not NaN
    _utils.Assert.isNumber(p1);
    _utils.Assert.isNumber(p2);

    // return true if p1 is greater than p2.
    return p1 > p2;
  }

  exports.default = Ember.Helper.helper(isGreater);
});