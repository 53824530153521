define('busy-app/models/organization-overtime-period', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    organizationId: _emberData.default.attr('string'),
    startTime: _emberData.default.attr('number'),
    lengthInDays: _emberData.default.attr('number'),
    weeklyOvertime: _emberData.default.attr('number'),
    dailyOvertime: _emberData.default.attr('number'),
    dailyDoubletime: _emberData.default.attr('number'),
    seventhDayOvertime: _emberData.default.attr('boolean'),
    seventhDayDoubletime: _emberData.default.attr('number'),
    saturdayMultiplier: _emberData.default.attr('number'),
    sundayMultiplier: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),

    effectiveDate: Ember.computed('startTime', function () {
      var date = '';
      if (!Ember.isNone(this.get('startTime'))) {
        // hides the Jan 7 1970 and shows begining
        if (this.get('startTime') === 345600) {
          date = (0, _utils.loc)("Beginning");
        } else {
          date = _utils.Time.timeFormat(this.get('startTime'), 'ddd. MMM Do, YYYY');
        }
      }
      return date;
    }),

    effectiveDateShort: Ember.computed('startTime', function () {
      var date = '';
      if (!Ember.isNone(this.get('startTime'))) {
        // hides the Jan 7 1970 and shows begining
        if (this.get('startTime') === 345600) {
          date = (0, _utils.loc)("Beginning");
        } else {
          date = _utils.Time.timeFormat(this.get('startTime'), 'MMM Do, YYYY');
        }
      }
      return date;
    }),

    editDate: Ember.computed('updatedOn', function () {
      var date = '';
      if (!Ember.isNone(this.get('updatedOn'))) {
        date = _utils.Time.timeFormat(this.get('updatedOn'), 'MMM Do, YYYY');
      }
      return date;
    }),

    overtimePeriod: Ember.computed('startTime', function () {
      var date = '';
      if (!Ember.isNone(this.get('startTime'))) {
        date = _utils.Time.timeFormat(this.get('startTime'), 'dddd') + ' - ' + _utils.Time.dateFormat(_utils.Time.date(this.get('startTime')).add(6, 'days'), 'dddd');
      }
      return date;
    }),

    overtimePreference: Ember.computed('weeklyOvertime', 'dailyOvertime', 'dailyDoubletime', 'seventhDayOvertime', 'seventhDayDoubletime', 'saturdayMultiplier', 'sundayMultiplier', function () {
      var weekly = this.get('weeklyOvertime');
      var daily = this.get('dailyOvertime');
      var dailyDouble = this.get('dailyDoubletime');
      var sevenOne = this.get('seventhDayOvertime');
      var sevenTwo = this.get('seventhDayDoubletime');
      var saturday = this.get('saturdayMultiplier');
      var sunday = this.get('sundayMultiplier');

      var pref = "";
      if (weekly === 40 && daily === 8 && dailyDouble === 12 && sevenOne === true && sevenTwo === 8 && saturday === null && sunday === null) {
        pref = (0, _utils.loc)("California Settings");
      } else if (weekly === 40 && daily === null && dailyDouble === null && (sevenOne === false || sevenOne === null) && sevenTwo === null && saturday === null && sunday === null) {
        pref = (0, _utils.loc)("Standard Settings");
      } else {
        pref = (0, _utils.loc)("Custom Settings");
      }
      return pref;
    }),

    weeklyOvertimeString: Ember.computed('weeklyOvertime', function () {
      if (!Ember.isNone(this.get('weeklyOvertime'))) {
        return (0, _utils.loc)('after ') + this.get('weeklyOvertime') + (0, _utils.loc)(' hours');
      } else {
        return (0, _utils.loc)('N/A');
      }
    }),

    dailyOvertimeString: Ember.computed('dailyOvertime', function () {
      if (!Ember.isNone(this.get('dailyOvertime'))) {
        return (0, _utils.loc)('after ') + this.get('dailyOvertime') + (0, _utils.loc)(' hours');
      } else {
        return (0, _utils.loc)('N/A');
      }
    }),

    dailyDoubletimeString: Ember.computed('dailyDoubletime', function () {
      if (!Ember.isNone(this.get('dailyDoubletime'))) {
        return (0, _utils.loc)('after ') + this.get('dailyDoubletime') + (0, _utils.loc)(' hours');
      } else {
        return (0, _utils.loc)('N/A');
      }
    }),

    seventhDayOvertimeString: Ember.computed('seventhDayOvertime', function () {
      if (this.get('seventhDayOvertime')) {
        return this.get('seventhDayOvertime');
      } else {
        return (0, _utils.loc)('N/A');
      }
    }),

    seventhDayDoubletimeString: Ember.computed('seventhDayDoubletime', function () {
      if (!Ember.isNone(this.get('seventhDayDoubletime'))) {
        return (0, _utils.loc)('after ') + this.get('seventhDayDoubletime') + (0, _utils.loc)(' hours');
      } else {
        return (0, _utils.loc)('N/A');
      }
    }),

    saturdayMultiplierString: Ember.computed('saturdayMultiplier', function () {
      if (!Ember.isNone(this.get('saturdayMultiplier'))) {
        return (0, _utils.loc)('wage x ') + this.get('saturdayMultiplier');
      } else {
        return (0, _utils.loc)('N/A');
      }
    }),

    sundayMultiplierString: Ember.computed('sundayMultiplier', function () {
      if (!Ember.isNone(this.get('sundayMultiplier'))) {
        return (0, _utils.loc)('wage x ') + this.get('sundayMultiplier');
      } else {
        return (0, _utils.loc)('N/A');
      }
    })
  });
});