define('busy-app/utils/models/filters/equipment', ['exports', 'busy-app/utils/models/filters/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		getByIdsWithChildren: function getByIdsWithChildren(ids) {
			return this.store.findByIds('equipment', ids).then(function (model) {
				var promise = [];
				model.forEach(function (item) {
					var p = item.get('equipmentModel').then(function (eModel) {
						return eModel.get('equipmentMake');
					});
					promise.push(p);
				});

				return Ember.RSVP.all(promise).then(function () {
					return model;
				});
			});
		}
	});
});