define('busy-app/routes/report/report-setup', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		queryParams: {
			start: {
				refreshModel: true
			},
			end: {
				refreshModel: true
			}
		},

		model: function model() {
			return this.store.query('member', { _asc: ['first_name'], page_size: 1000 });
		}
	});
});