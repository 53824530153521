define('busy-app/models/employee-payroll-item-connector', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		organizationId: _emberData.default.attr('string'),
		payrollItemWageId: _emberData.default.attr('string'),
		memberConnectorId: _emberData.default.attr('string'),
		wageRate: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),

		quickbooksPayrollItemWage: null,
		employeeWageTypes: null,

		wageType: Ember.computed('quickbooksPayrollItemWage.wageType', function () {
			return this.get('quickbooksPayrollItemWage.wageType');
		}),

		wageTypeFormatted: Ember.computed('quickbooksPayrollItemWage.wageTypeFormatted', function () {
			return this.get('quickbooksPayrollItemWage.wageTypeFormatted');
		}),

		wageName: Ember.computed('quickbooksPayrollItemWage.wageItemName', function () {
			return this.get('quickbooksPayrollItemWage.wageItemName');
		}),

		regularWageType: Ember.computed('employeeWageTypes.@each.payrollType', function () {
			return this.get('employeeWageTypes').filterBy('payrollType', 10);
		}),

		isRegular: Ember.computed('regularWageType', function () {
			return this.get('regularWageType.length') > 0;
		}),

		overtimeWageType: Ember.computed('employeeWageTypes.@each.payrollType', function () {
			return this.get('employeeWageTypes').filterBy('payrollType', 20);
		}),

		isOverttime: Ember.computed('overtimeWageType', function () {
			return this.get('overtimeWageType.length') > 0;
		}),

		doubletimeWageType: Ember.computed('employeeWageTypes.@each.payrollType', function () {
			return this.get('employeeWageTypes').filterBy('payrollType', 30);
		}),

		isDoubletime: Ember.computed('doubletimeWageType', function () {
			return this.get('doubletimeWageType.length') > 0;
		}),

		salaryWageType: Ember.computed('employeeWageTypes.@each.payrollType', function () {
			return this.get('employeeWageTypes').filterBy('payrollType', 40);
		}),

		isSalary: Ember.computed('salaryWageType', function () {
			return this.get('salaryWageType.length') > 0;
		})
	});
});