define('busy-app/utils/models/managers/payroll-member-csv', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = _base.default.extend({
		start: null,
		end: null,

		showProject: false,
		showCostCode: false,

		filename: Ember.computed('start', 'end', function () {
			var start = _utils.Time.date(this.get('start')).format('MM-DD-YY');
			var end = _utils.Time.date(this.get('end')).format('MM-DD-YY');

			return 'Payroll_Daily_' + start + '_to_' + end;
		}),

		getSorted: function getSorted() {
			return this.get('model').sortBy('time', 'member.fullName', 'wageTypeOrder');
		},


		dataMap: Ember.computed(function () {
			// dataMap for the csv export key:val object where key is the path to a
			// data property and the val is the header title
			var dataMap = {
				'time': 'Date',
				'member.memberNumber': 'Employee ID',
				'member.firstName': 'First Name',
				'member.lastName': 'Last Name',
				'wage': 'Wage',
				'wageType': 'Wage Type',
				'hours': 'Hours'
			};

			if (this.get('showProject') === true) {
				dataMap['customer'] = 'Customer';
				dataMap['projectNumber'] = 'Project #';
				dataMap['depth1'] = 'Project';
				dataMap['projectNumber2'] = 'Subproject 1 #';
				dataMap['depth2'] = 'Subproject 1';
				dataMap['projectNumber3'] = 'Subproject 2 #';
				dataMap['depth3'] = 'Subproject 2';
				dataMap['projectNumber4'] = 'Subproject 3 #';
				dataMap['depth4'] = 'Subproject 3';
				dataMap['projectNumber5'] = 'Subproject 4 #';
				dataMap['depth5'] = 'Subproject 4';
				dataMap['projectNumber6'] = 'Subproject 5 #';
				dataMap['depth6'] = 'Subproject 5';
				dataMap['projectNumber7'] = 'Subproject 6 #';
				dataMap['depth7'] = 'Subproject 6';
			}

			if (this.get('showCostCode') === true) {
				dataMap['costCode.costCode'] = 'Cost Code';
				dataMap['costCode.title'] = 'Cost Code Desc.';
			}

			dataMap['description'] = 'Description';

			return dataMap;
		}),

		fetch: function fetch(start, end, memberId, projectId, costCodeId) {
			var _this = this;

			this.set('start', start);
			this.set('end', end);

			return this.dispatcherFor('time-entry-segment').findAllByRange(start, end, memberId, projectId, costCodeId).then(function (data) {
				return Ember.RSVP.hash({
					segmentData: data,
					memberData: _this.dispatcherFor('member-pay-period').find(start, end)
				}).then(function (_data) {
					_data.memberData.members.forEach(function (item) {
						if (Ember.isNone(memberId) || memberId === item.id) {
							var idx = _data.segmentData.members.indexOf(item);
							if (idx === -1) {
								var entry = _this.store.createRecord('time-entry', {
									id: _utils.UUID.generate(),
									startTime: _this.get('end'),
									endTime: _this.get('end'),
									memberId: item.id
								});

								var wages = _data.memberData.wage.filterBy('memberId', item.id);
								var wage = _this.getWageHistory(wages, _this.get('start'));
								item.set('wages', wages);
								item.set('wage', wage);

								_data.segmentData.timeEntries.pushObject(entry);
								_data.segmentData.members.pushObject(item);
							}
						}
					});

					return _data.segmentData;
				});
			});
		},
		buildCollections: function buildCollections(data) {
			var _this2 = this;

			var entries = data.timeEntries.sortBy('startTime');
			var timeOffEntries = data.timeOffEntries.sortBy('dateStamp');

			entries.forEach(function (item) {
				_this2.addCollection(item, data);
			});

			timeOffEntries.forEach(function (item) {
				_this2.addCollection(item, data);
			});

			var content = this.getSorted();
			this.set('content', content);
		},
		getWageHistory: function getWageHistory(wages, timestamp) {
			// sort wages
			var _wages = wages.sortBy('changeDate');

			var wage = _wages.objectAt(0);
			_wages.forEach(function (item) {
				if (item.get('changeDate') > wage.get('changeDate') && item.get('changeDate') <= timestamp) {
					wage = item;
				}
			});

			return wage;
		},
		addCollection: function addCollection(entry, data) {
			var collection = this.collectionFor().push(entry);

			var start = entry.get('startTime');
			if (!Ember.isNone(entry.get('dateStamp'))) {
				start = _utils.Time.date(entry.get('dateStamp')).startOf('day').unix();
			}

			if (start < this.get('start')) {
				start = this.get('start');
			}

			var member = data.members.findBy('id', entry.get('memberId'));
			if (Ember.isNone(member)) {
				return;
			}

			if (Ember.isNone(member.get('archivedOn')) || member.get('archivedOn') > this.get('start')) {
				collection.set('member', member);

				if (this.get('showProject') && !Ember.isNone(entry.get('projectId'))) {
					collection.set('project', entry.get('project'));
					this.setProjectPath(collection, collection.get('project'));
				}

				if (this.get('showCostCode') && !Ember.isNone(entry.get('costCodeId'))) {
					collection.set('costCode', entry.get('costCode'));
				}

				collection.set('time', _utils.Time.date(start).format('MM/DD/YYYY'));

				this.buildObject(collection);
			}
		},
		setProjectPath: function setProjectPath(collection, project) {
			if (!Ember.isNone(project)) {
				var depth = project.get('depth');

				if (!Ember.isNone(depth)) {
					var _collection$setProper;

					var depthKey = '_depth' + depth;
					var projectInfoKey = !Ember.isNone(depth) && depth === 1 ? 'projectInfo' : 'projectInfo' + depth;
					var info = project.get('projectInfo');

					collection.setProperties((_collection$setProper = {}, _defineProperty(_collection$setProper, depthKey, project), _defineProperty(_collection$setProper, projectInfoKey, info), _collection$setProper));
				}

				if (!Ember.isNone(project.get('parentProject'))) {
					this.setProjectPath(collection, project.get('parentProject'));
				}
			}
		},


		// _setProjectPath(collection) {
		// 	const fullPath = collection.get('project.fullTitle');
		// 	if (!isNone(fullPath) && !isEmpty(fullPath)) {
		// 		const path = fullPath.split(' > ');
		//
		// 		$.each(path, function(key, val) {
		// 			if (path.hasOwnProperty(key)) {
		// 				collection.set('_depth' + (key+1), val);
		// 			}
		// 		});
		// 	}
		// },

		buildObject: function buildObject(collection) {
			var _this3 = this;

			if (!Ember.isEmpty(collection.get('model.dateStamp'))) {
				var regular = collection.get('model.segmentRegularSeconds');
				this.addCollectionSeconds(collection, { regular: regular, overtime: 0, doubletime: 0, total: regular });
			} else if (!Ember.isEmpty(collection.get('model.segments'))) {
				collection.get('model.segments').forEach(function (item) {
					var start = _utils.Time.date(item.get('startTime')).startOf('day').unix();
					var _collection = _this3.copy(collection);

					_collection.set('time', _utils.Time.date(start).format('MM/DD/YYYY'));

					_this3.addCollectionSeconds(_collection, {
						regular: item.get('regularSeconds'),
						overtime: item.get('overtimeSeconds'),
						doubletime: item.get('doubletimeSeconds'),
						total: item.get('totalSeconds')
					});
				});
			} else {
				this.addCollectionSeconds(collection, { regular: 0, overtime: 0, doubletime: 0, total: 0 });
			}
		},
		addCollectionSeconds: function addCollectionSeconds(collection, seconds) {
			var isHourly = collection.get('member.wage.isHourly');
			var wage = parseFloat(collection.get('member.wage.effectiveRate'));
			var grossWages = isHourly ? 'Hourly' : 'Salary';

			collection.set('grossWages', grossWages);

			if (isHourly) {
				if (seconds.regular > 0) {
					var type = collection.get('model.typeStringShort') || 'REG';
					var typeOrder = 1;
					if (type !== 'REG') {
						typeOrder = 4;
					}

					collection.set('wage', wage.toFixed(2));
					collection.set('wageType', type);
					collection.set('wageTypeOrder', typeOrder);
					collection.set('hours', (seconds.regular / 60 / 60).toFixed(2));
					collection.set('wageKey', 1);

					this.addInternalModel(collection);
				}

				if (seconds.overtime > 0) {
					var otObject = this.copy(collection);

					otObject.set('wage', (wage * 1.5).toFixed(2));
					otObject.set('wageType', 'OT1');
					otObject.set('wageTypeOrder', 2);
					otObject.set('hours', (seconds.overtime / 60 / 60).toFixed(2));
					otObject.set('wageKey', 2);

					this.addInternalModel(otObject);
				}

				if (seconds.doubletime > 0) {
					var dtObject = this.copy(collection);

					dtObject.set('wage', (wage * 2).toFixed(2));
					dtObject.set('wageType', 'OT2');
					dtObject.set('wageTypeOrder', 3);
					dtObject.set('hours', (seconds.doubletime / 60 / 60).toFixed(2));
					dtObject.set('wageKey', 3);

					this.addInternalModel(dtObject);
				}
			} else if (seconds.total >= 0) {
				var _type = ('SAL-' + (collection.get('model.typeStringShort') || '')).replace(/-$/, '');
				var _typeOrder = 5;
				if (_type !== 'SAL') {
					_typeOrder = 6;
				}

				collection.set('wage', "");
				collection.set('wageType', _type);
				collection.set('wageTypeOrder', _typeOrder);
				collection.set('hours', (seconds.total / 60 / 60).toFixed(2));
				collection.set('wageKey', 8);

				this.addInternalModel(collection);
			}
		},
		copy: function copy(collection) {
			var _collection = this.collectionFor().push(collection.get('model'));

			_collection.set('member', collection.get('member'));
			_collection.set('project', collection.get('project'));
			_collection.set('costCode', collection.get('costCode'));
			_collection.set('projectInfo', collection.get('projectInfo'));
			_collection.set('time', collection.get('time'));
			_collection.set('grossWages', collection.get('grossWages'));
			_collection.set('_depth1', collection.get('_depth1'));
			_collection.set('_depth2', collection.get('_depth2'));
			_collection.set('_depth3', collection.get('_depth3'));
			_collection.set('_depth4', collection.get('_depth4'));
			_collection.set('_depth5', collection.get('_depth5'));
			_collection.set('_depth6', collection.get('_depth6'));
			_collection.set('_depth7', collection.get('_depth7'));
			_collection.set('projectInfo2', collection.get('projectInfo2'));
			_collection.set('projectInfo3', collection.get('projectInfo3'));
			_collection.set('projectInfo4', collection.get('projectInfo4'));
			_collection.set('projectInfo5', collection.get('projectInfo5'));
			_collection.set('projectInfo6', collection.get('projectInfo6'));
			_collection.set('projectInfo7', collection.get('projectInfo7'));

			return _collection;
		}
	});
});