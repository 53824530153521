define('busy-app/components/projects/sub-project', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	// default to busy office
	// 37.10102990074617 -113.53877082086876
	/**
  * @module Components
  *
  */
	var kDefaultLocation = {
		latitude: 37.10102990074617,
		longitude: -113.53877082086876,
		locationRadius: 50
	};

	/**
  *
  */
	exports.default = Ember.Component.extend({
		store: Ember.inject.service('store'),
		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),
		features: Ember.inject.service('features'),

		classNames: ['v-project-sub-project'],

		organization: null,
		project: null,
		model: null,

		newProject: null,
		useGeolocation: false,

		showDialog: false,
		isLoading: false,
		hideRowActions: false,
		showArchiveDialog: false,
		_projectCostCodeLinks: null,

		archived: Ember.computed('project.archivedOn', function () {
			return !Ember.isNone(Ember.get(this, 'project.archivedOn'));
		}),

		keyword: '',
		pageSize: 30,
		page: 1,
		totalRows: 0,

		init: function init() {
			this._super.apply(this, arguments);
			var isActive = !Ember.get(this, 'archived');

			this.loadMenus();
			this.getProjects(isActive);
			Ember.set(this, 'editItems', []);
		},


		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),

		loadMenus: Ember.observer('archived', function () {
			if (Ember.get(this, 'archived')) {
				Ember.set(this, 'selectMenu', [Ember.Object.create({ key: 'unarchive', value: (0, _utils.loc)('Unarchive') })]);
				Ember.set(this, 'rowSelectMenu', [Ember.Object.create({ key: 'unarchive', value: (0, _utils.loc)('Unarchive') })]);
				Ember.set(this, 'filterMenu', [Ember.Object.create({ key: 'archived', value: (0, _utils.loc)('Archived'), _selected: true })]);
			} else {
				Ember.set(this, 'selectMenu', [Ember.Object.create({ key: 'archive', value: (0, _utils.loc)('Archive') })]);
				Ember.set(this, 'rowSelectMenu', [Ember.Object.create({ key: 'archive', value: (0, _utils.loc)('Archive') })]);
				Ember.set(this, 'filterMenu', [Ember.Object.create({ key: 'archived', value: (0, _utils.loc)('Archived'), _selected: false })]);
			}
		}),

		canAddProjects: Ember.computed('auth.member.id', 'project.archivedOn', function () {
			var hasPermission = Ember.get(this, 'access').hasPermissionFor('manageProjects');
			var isArchived = !Ember.isNone(Ember.get(this, 'project.archivedOn'));
			return hasPermission && !isArchived;
		}),

		getProjects: function getProjects(isActive) {
			var _this = this;

			if (!Ember.isNone(Ember.get(this, 'project.id')) && !Ember.get(this, 'isDestroyed')) {
				var params = {
					parent_project_id: Ember.get(this, 'project.id'),
					_asc: ['title'],
					page_size: Ember.get(this, 'pageSize'),
					page: Ember.get(this, 'page')
				};

				if (isActive) {
					params.archived_on = '_-NULL-_';
				} else {
					params.archived_on = '!_-NULL-_';
				}

				if (!Ember.isEmpty(Ember.get(this, 'keyword'))) {
					params._search = Ember.get(this, 'keyword');
				}

				Ember.set(this, 'isLoading', true);
				Ember.get(this, 'store').query('project', params).then(function (models) {
					if (!Ember.get(_this, 'isDestroyed')) {
						Ember.set(_this, 'totalRows', Ember.get(models, 'meta.totalRows'));
						Ember.set(_this, 'allSubprojects', models);
						Ember.set(_this, 'model', models);
						Ember.set(_this, 'isLoading', false);
					}
				});
			}
		},


		canManageProjects: Ember.computed('auth.authMemberPosition.manageProjects', function () {
			return Ember.get(this, 'auth.authMemberPosition.manageProjects');
		}),

		hasRowActions: Ember.computed('canManageProjects', 'hideRowActions', function () {
			return Ember.get(this, 'canManageProjects') && !Ember.get(this, 'hideRowActions');
		}),

		openArchiveDialog: function openArchiveDialog(items) {
			Ember.set(this, 'archiveItems', items);
			Ember.set(this, 'showArchiveDialog', true);
		},
		archive: function archive() {
			var _this2 = this;

			var items = Ember.get(this, 'archiveItems');
			var timestamp = _utils.Time.timestamp();
			return Ember.RSVP.all(items.map(function (item) {
				Ember.set(item, 'archivedOn', timestamp);
				return item.save();
			})).then(function () {
				_this2.getProjects(true);
				Ember.set(_this2, 'archiveItems', null);
				Ember.set(_this2, 'showArchiveDialog', false);
			});
		},
		unarchive: function unarchive(items) {
			var _this3 = this;

			var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};

			return Ember.RSVP.all(items.map(function (item) {
				Ember.set(item, 'archivedOn', '_-NULL-_');
				return item.save();
			})).then(function () {
				_this3.getProjects(false);
				Ember.run.next(_this3, function () {
					return callback();
				});
				_this3.clearEdit();
			});
		},


		/**
   * Clears the edit action
   *
   * @public
   * @method clearEdit
   */
		clearEdit: function clearEdit() {
			// bc-list sets isSelected on the model
			// so loop through the models and set isSelected
			// to false to uncheck the checkboxes
			Ember.get(this, 'editItems').forEach(function (item) {
				return Ember.set(item, 'isSelected', false);
			});

			// set local vars back to defaults
			Ember.set(this, 'editAction', null);
			Ember.set(this, 'editItems', []);
			Ember.set(this, 'edit', false);
			Ember.set(this, 'hideRowActions', false);
		},


		archiveMessage: Ember.computed('archiveItems.@each', function () {
			if (Ember.get(this, 'archiveItems.length') > 1) {
				return (0, _utils.loc)('This action will archive any subprojects within these %@ projects.', [Ember.get(this, 'archiveItems.length')]);
			} else {
				return (0, _utils.loc)('This action will archive any subprojects within this project.');
			}
		}),

		archiveHeader: Ember.computed('archiveItems.@each', function () {
			if (Ember.get(this, 'archiveItems.length') > 1) {
				return (0, _utils.loc)('Archive Subprojects');
			} else {
				return (0, _utils.loc)('Archive Subproject');
			}
		}),

		unarchiveHeader: Ember.computed('editItems.@each', function () {
			if (Ember.get(this, 'editItems.length') > 1) {
				return (0, _utils.loc)('Unarchive Subprojects');
			} else {
				return (0, _utils.loc)('Unarchive Subproject');
			}
		}),

		unarchiveMessage: Ember.computed('editItems.@each', function () {
			if (Ember.get(this, 'editItems.length') > 1) {
				return (0, _utils.loc)('This action will restore any subprojects within these %@ projects.', [Ember.get(this, 'editItems.length')]);
			} else {
				return (0, _utils.loc)('This action will restore any subprojects within this project');
			}
		}),

		onUseGeolocationChange: Ember.observer('useGeolocation', function () {
			if (Ember.get(this, 'showDialog')) {
				if (Ember.get(this, 'useGeolocation') && Ember.isNone(Ember.get(this, 'newProjectInfo.locationRadius'))) {
					Ember.get(this, 'newProjectInfo').setProperties(kDefaultLocation);
					Ember.set(this, 'mapInit', Date.now());
				}
			}
		}),

		/*
   * Cost Code Select Actions
   */

		getProjectCostCodeLinks: function getProjectCostCodeLinks() {
			_logger.default.info(this, 'getProjectCostCodeLinks');

			return Ember.RSVP.resolve([]);
		},
		recieveCostCodeChanges: function recieveCostCodeChanges(_projectCostCodeLinks) {
			_logger.default.info(this, 'recieveCostCodeChanges', _projectCostCodeLinks);
			Ember.setProperties(this, { _projectCostCodeLinks: _projectCostCodeLinks });

			return Ember.RSVP.resolve();
		},
		rollbackCostCodeChanges: function rollbackCostCodeChanges() {
			var _this4 = this;

			_logger.default.info(this, 'rollbackCostCodeChanges', this.get('_projectCostCodeLinks'));

			if (!Ember.isEmpty(Ember.get(this, '_projectCostCodeLinks'))) {
				Ember.get(this, '_projectCostCodeLinks').filterBy('hasDirtyAttributes').forEach(function (model) {
					return model.rollbackAttributes();
				});

				Ember.set(this, '_projectCostCodeLinks', null);
			}

			return this.getProjectCostCodeLinks().then(function (_projectCostCodeLinks) {
				return Ember.setProperties(_this4, { _projectCostCodeLinks: _projectCostCodeLinks });
			});
		},


		actions: {
			editListAction: function editListAction(action) {
				Ember.set(this, 'editAction', Ember.get(action, 'key'));
				Ember.set(this, 'hideRowActions', true);
				Ember.set(this, 'edit', true);
			},
			cancelEditAction: function cancelEditAction() {
				this.clearEdit();
			},
			subProjectClicked: function subProjectClicked(item) {
				this.sendAction('onProjectChange', Ember.get(item, 'id'));
			},
			subprojectSelected: function subprojectSelected(isChecked, item) {
				if (isChecked) {
					Ember.get(this, 'editItems').pushObject(item);
				} else {
					Ember.get(this, 'editItems').removeObject(item);
				}
			},
			selectAll: function selectAll(isChecked, items) {
				if (isChecked) {
					Ember.set(this, 'editItems', items);
				} else {
					Ember.set(this, 'editItems', []);
				}
			},


			/**
    * opens the new project dialog
    * and creates a new project model
    *
    * @public
    * @method openNewProjectDialog
    * @returns {void}
    */
			openNewProjectDialog: function openNewProjectDialog() {
				var newProject = Ember.get(this, 'store').createRecord('project', {
					parentProjectId: Ember.get(this, 'project.id'),
					organizationId: Ember.get(this, 'organization.id')
				});

				var newProjectInfo = Ember.get(this, 'store').createRecord('project-info');

				// set lat, lng, rad
				newProjectInfo.setProperties(kDefaultLocation);

				Ember.setProperties(this, {
					newProject: newProject,
					newProjectInfo: newProjectInfo,
					showDialog: true,
					_projectCostCodeLinks: []
				});
			},
			closeNewProjectDialog: function closeNewProjectDialog() {
				Ember.set(this, 'showDialog', false);
				if (Ember.get(this, 'newProject') !== null) {
					Ember.get(this, 'newProject').rollbackAttributes();
				}
				Ember.set(this, 'newProject', null);
				this.rollbackCostCodeChanges();
			},
			saveNewProject: function saveNewProject() {
				var _this5 = this;

				var parentProjectId = Ember.get(this, 'project.id');
				var organizationId = Ember.get(this, 'organization.id');

				var project = Ember.get(this, 'newProject');
				var projectInfo = Ember.get(this, 'newProjectInfo');

				var newProject = Ember.get(this, 'store').createRecord('project', { parentProjectId: parentProjectId, organizationId: organizationId });
				var newProjectInfo = Ember.get(this, 'store').createRecord('project-info', {});

				if (Ember.isEmpty(Ember.get(project, 'title'))) {
					return Ember.RSVP.reject((0, _utils.loc)('Subproject name is required'));
				}

				if (Ember.get(this, 'model').isAny('matchTitle', Ember.get(project, 'title').toLowerCase())) {
					return Ember.RSVP.reject((0, _utils.loc)('Title has already been used for another project.'));
				}

				if (!Ember.get(this, 'useGeolocation')) {
					projectInfo.clearGeolocation();
				}

				Ember.set(this, 'mapError', false);
				if (Ember.get(projectInfo, 'reminder') && !Ember.get(projectInfo, 'hasLocation')) {
					Ember.set(this, 'mapError', true);
					return Ember.RSVP.reject((0, _utils.loc)('Geolocation required for location reminders.'));
				}

				return project.save().then(function (model) {
					var attrs = projectInfo.changedAttributes();
					var promise = Ember.RSVP.resolve();
					if (!Ember.isNone(attrs) && Object.keys(attrs).length > 0) {
						Ember.set(_this5, 'newProjectInfo.projectId', model.id);
						promise = projectInfo.save();
					}

					var _projectCostCodeLinks = Ember.get(_this5, '_projectCostCodeLinks');
					if (Ember.get(_this5, 'isLinkedCostCodesEnabled') && !Ember.isNone(_projectCostCodeLinks)) {
						_projectCostCodeLinks.setEach('projectId', model.id);
						var saveLinks = _projectCostCodeLinks.map(function (link) {
							return link.save();
						});
						promise.then(function () {
							return Ember.RSVP.allSettled(saveLinks);
						});
					}

					return promise.then(function () {
						Ember.setProperties(_this5, {
							newProject: newProject,
							newProjectInfo: newProjectInfo,
							_projectCostCodeLinks: []
						});
						Ember.get(_this5, 'model').update();
					});
				});
			},
			submitEditAction: function submitEditAction() {
				var filteredItems = Ember.get(this, 'editItems');
				if (Ember.get(this, 'editAction') === 'archive') {
					this.openArchiveDialog(filteredItems);
					this.clearEdit();
				}

				if (Ember.get(this, 'editAction') === 'unarchive') {
					Ember.set(this, 'showUnarchiveDialog', true);
					// this.unarchive(filteredItems);
				}
			},
			changePage: function changePage(page) {
				Ember.set(this, 'page', page);
				var isActive = !Ember.get(this, 'archived');
				this.getProjects(isActive);
			},
			changeSize: function changeSize(size) {
				Ember.set(this, 'pageSize', size);
				Ember.set(this, 'page', 1);
				var isActive = !Ember.get(this, 'archived');
				this.getProjects(isActive);
			},
			searchProject: function searchProject(keyword) {
				Ember.set(this, 'keyword', keyword);
				var isActive = !Ember.get(this, 'archived');
				this.getProjects(isActive);
			},
			rowItemSelectAction: function rowItemSelectAction(action, member, project) {
				if (Ember.get(action, 'key') === 'archive') {
					this.openArchiveDialog([project]);
				} else if (Ember.get(action, 'key') === 'unarchive') {
					Ember.set(this, 'showUnarchiveDialog', true);
					Ember.set(this, 'editItems', [project]);
					// this.unarchive([project]);
				} else {
					_logger.default.error("An unrecognized action was called in the component/projects/sub-project actionView");
				}
			},
			confirmUnarchive: function confirmUnarchive() {
				this.unarchive(Ember.get(this, 'editItems'));
				Ember.set(this, 'showUnarchiveDialog', false);
			},
			cancelUnarchive: function cancelUnarchive() {
				Ember.set(this, 'showUnarchiveDialog', false);
				this.clearEdit();
			},
			archiveProject: function archiveProject() {
				this.archive();
			},
			closeArchiveDialog: function closeArchiveDialog() {
				Ember.set(this, 'archiveItems', null);
				Ember.set(this, 'showArchiveDialog', false);
			},
			filterAction: function filterAction(action) {
				if (Ember.get(action, 'key') === 'archived') {
					this.getProjects(false);
					Ember.set(this, 'archived', true);
				} else if (Ember.get(action, 'key') === 'active') {
					this.getProjects(true);
					Ember.set(this, 'archived', false);
				}
			},


			/*
    * Cost Code Select Actions
    */
			onCancelCostCodeChanges: function onCancelCostCodeChanges() {
				_logger.default.info(this, 'onCancelCostCodeChanges');

				return this.rollbackCostCodeChanges();
			},
			onSaveManagerChanges: function onSaveManagerChanges(projectCostCodeLinks) {
				_logger.default.info(this, 'onSaveManagerChanges', { projectCostCodeLinks: projectCostCodeLinks });

				var projectId = Ember.get(this, 'project.id');
				projectCostCodeLinks.setEach('projectId', projectId);

				return this.recieveCostCodeChanges(projectCostCodeLinks);
			}
		}
	});
});