define('busy-app/utils/maps', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var LOCATION_MODIFIER = exports.LOCATION_MODIFIER = 0.000005;
	var VALID_LOCATION_TIME_DIFF = exports.VALID_LOCATION_TIME_DIFF = 150; // in seconds

	var gulfOfGuinea = function gulfOfGuinea(location) {
		return Ember.get(location, 'latitude') === 0 && Ember.get(location, 'longitude') === 0;
	};

	/**
  * Test for checking location objects.
  *
  * All location objects must have a latitude and longitude and createdOn property
  *
  * @private
  * @method isLocationObject
  * @param location {object}
  * @return {boolean}
  */
	var isLocationObject = function isLocationObject(location) {
		if (Ember.isNone(location)) {
			return false;
		}

		// handles ember data PromizeObjects
		if (location.content) {
			location = Ember.get(location, 'content');
		}

		// handles ember data models
		if (location.toJSON) {
			location = location.toJSON();
		}

		var keys = Object.keys(location);
		var filter = keys.filter(function (k) {
			return k === 'latitude' || k === 'longitude';
		});
		return filter.length === 2;
	};

	/**
  * if location is undefined return false
  * otherwise check that the lat lng are usable locations
  *
  * @public
  * @method isValidLatLng
  * @param location {object} location Object
  * @param callback {function} callback function called it result is true
  * @param {boolean}
  */
	var isValidLatLng = exports.isValidLatLng = function isValidLatLng(location, callback) {
		if (Ember.isNone(location)) {
			return false;
		}

		(false && !(isLocationObject(location)) && Ember.assert("isValidLatLng requires a member-location model", isLocationObject(location)));


		if (!gulfOfGuinea(location)) {
			if (typeof callback === 'function') {
				return callback();
			}
			return true;
		}
		return false;
	};

	/**
  * Gets the latitude and longitude from a MemberLocation
  * and returns them as an array of latlng arrays => [ [lat, lng], ... ]
  *
  * @public
  * @method getLatLng
  * @param locations {MemberLocation[]}
  * @return {number[][]}
  */
	var getLatLng = exports.getLatLng = function getLatLng(locations) {
		return locations.map(function (location) {
			return [getUniqLocaleType(locations, 'latitude', location), getUniqLocaleType(locations, 'longitude', location)];
		});
	};

	var getLocationsFromTimeEntry = exports.getLocationsFromTimeEntry = function getLocationsFromTimeEntry(timeEntry, organization) {
		var _ref;

		var locations = [];
		var offset = Ember.get(timeEntry, 'offset');

		var startLocation = void 0;
		if (isLocationObject(Ember.get(timeEntry, 'startLocation'))) {
			startLocation = normalizeLocation(Ember.get(timeEntry, 'startLocation'), Ember.get(timeEntry, 'startTime'), offset);
		}

		var endLocation = void 0;
		if (isLocationObject(Ember.get(timeEntry, 'endLocation'))) {
			endLocation = normalizeLocation(Ember.get(timeEntry, 'endLocation'), Ember.get(timeEntry, 'endTime'), offset);
		}

		if (!Ember.isNone(startLocation)) {
			if (validateLocationForTime(startLocation, organization)) {
				locations.push(startLocation);
			}
		}

		if (!Ember.isNone(endLocation)) {
			if (validateLocationForTime(endLocation, organization)) {
				locations.push(endLocation);
			}
		}

		var breakLocations = getLocationsFromBreaks(Ember.get(timeEntry, 'timeEntryBreaks'), offset, organization);

		return (_ref = []).concat.apply(_ref, [locations].concat(_toConsumableArray(breakLocations)));
	};

	var getLocationsFromBreaks = exports.getLocationsFromBreaks = function getLocationsFromBreaks(breaks, offset, organization) {
		return breaks.map(function (model) {
			var locations = [];
			var startLocation = void 0;
			if (isLocationObject(Ember.get(model, 'startLocation'))) {
				startLocation = normalizeLocation(Ember.get(model, 'startLocation'), Ember.get(model, 'startTime'), offset);
			}

			var endLocation = void 0;
			if (isLocationObject(Ember.get(model, 'endLocation'))) {
				endLocation = normalizeLocation(Ember.get(model, 'endLocation'), Ember.get(model, 'endTime'), offset);
			}

			if (!Ember.isNone(startLocation)) {
				if (validateLocationForTime(startLocation, organization)) {
					locations.push(startLocation);
				}
			}

			if (!Ember.isNone(endLocation)) {
				if (validateLocationForTime(endLocation, organization)) {
					locations.push(endLocation);
				}
			}
		});
	};

	var validTimeOffset = exports.validTimeOffset = function validTimeOffset(organization) {
		(false && !(!Ember.isNone(organization) && !Ember.isNone(Ember.get(organization, 'id'))) && Ember.assert("validTimeOffset requires the organization model", !Ember.isNone(organization) && !Ember.isNone(Ember.get(organization, 'id'))));


		var validLocTime = VALID_LOCATION_TIME_DIFF;
		if (Ember.get(organization, 'timeRounding')) {
			validLocTime = VALID_LOCATION_TIME_DIFF + Ember.get(organization, 'timeRoundingInterval');
		}
		return validLocTime;
	};

	var validateLocationForTime = exports.validateLocationForTime = function validateLocationForTime(_ref2, organization) {
		var createdOn = _ref2.createdOn,
		    timestamp = _ref2.timestamp,
		    offset = _ref2.offset;
		(false && !(!Ember.isNone(createdOn) && typeof createdOn === 'number' && !Ember.isNone(timestamp) && typeof timestamp === 'number' && !Ember.isNone(offset) && typeof offset === 'number') && Ember.assert("validateLocationForTime requires an object with createdOn, timestamp, and offset to exist as the first param", !Ember.isNone(createdOn) && typeof createdOn === 'number' && !Ember.isNone(timestamp) && typeof timestamp === 'number' && !Ember.isNone(offset) && typeof offset === 'number'));
		(false && !(!Ember.isNone(organization) && !Ember.isNone(Ember.get(organization, 'id'))) && Ember.assert("validateLocationForTime requires the organization model", !Ember.isNone(organization) && !Ember.isNone(Ember.get(organization, 'id'))));


		var validLocTime = validTimeOffset(organization);
		var minTime = timestamp - validLocTime;
		var maxTime = timestamp + validLocTime;
		var locCreatedOn = createdOn + offset;

		if (locCreatedOn >= minTime && locCreatedOn <= maxTime) {
			return true;
		}
		return false;
	};

	var getUniqLocaleType = function getUniqLocaleType(locationList, type, location) {
		(false && !(!Ember.isEmpty(type) && type === 'latitude' || type === 'longitude') && Ember.assert('getUniqLocaleType requires type: ( `latitude` | `longitude` )', !Ember.isEmpty(type) && type === 'latitude' || type === 'longitude'));


		var locale = Ember.get(location, type);

		locationList.forEach(function (item) {
			if (item.id !== location.id) {
				if (!Ember.isNone(locationList.find(function (l) {
					return Ember.get(l, type) === locale;
				}))) {
					locale += LOCATION_MODIFIER;
				}
			}
		});

		return locale;
	};

	var normalizeLocation = exports.normalizeLocation = function normalizeLocation(location, time, offset) {
		(false && !(isLocationObject(location)) && Ember.assert("normalizeLocation requires a location object", isLocationObject(location)));


		var locationObj = {
			id: Ember.get(location, 'id'),
			latitude: Ember.get(location, 'latitude'),
			longitude: Ember.get(location, 'longitude'),
			accuracy: Ember.get(location, 'accuracy') || Ember.get(location, 'locationRadius'),
			createdOn: Ember.get(location, 'createdOn')
		};

		if (!Ember.isNone(time)) {
			locationObj.timestamp = time;
			locationObj.offset = offset || 0;
		}

		return locationObj;
	};
});