define('busy-app/controllers/report/payroll/index', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['type', 'start'],

		auth: Ember.inject.service(),
		payPeriods: Ember.inject.service('pay-period'),

		type: '',

		showTimeEntryDialog: false,

		selectMenu: null,
		inlineSelectMenu: null,
		payPeriodSelector: null,

		timeEntryStart: null,
		timeEntryEnd: null,
		timeEntryMember: null,
		timeEntryProject: null,

		openItems: false,
		allOpen: false,
		unassignedProjects: false,
		dirtyFlag: false,

		exceptionalWageChangeWarningText: (0, _utils.loc)('* Totals for employees with multiple wage types are calculated using the most recent wage type.'),

		hasProject: Ember.computed('type', function () {
			return this.get('type') === 'member-project' || this.get('type') === 'project';
		}),

		hasExceptionalWageChange: Ember.computed('model.@each.hasExceptionalWageChange', function () {
			return Ember.get(this, 'model').any(function (i) {
				return Ember.get(i, 'hasExceptionalWageChange');
			});
		}),

		init: function init() {
			this._super();

			//This select Menu is in the Header
			this.set('selectMenu', [Ember.Object.create({ key: 'print-report', value: 'Print Report' }), Ember.Object.create({ key: 'export-to-csv', value: 'Export to CSV' })]);

			//This is a Menu for each item in ContentData Array
			this.set('inlineSelectMenu', [Ember.Object.create({ key: 'time-entries', value: 'View Time Entries' })]);

			this.setPeriods();
		},


		setPeriods: Ember.observer('auth.organizationPayPeriod.@each.id', function () {
			if (!Ember.isEmpty(this.get('auth.organizationPayPeriod'))) {
				var payPeriods = this.get('payPeriods').getPayPeriodRange(_utils.Time.timestamp(), 15);
				this.set('payPeriodSelector', payPeriods);
			}
		}),

		showOvertime: Ember.computed('model.overtimeSeconds', function () {
			return this.get('model.overtimeSeconds') ? true : false;
		}),

		showDoubletime: Ember.computed('model.doubletimeSeconds', function () {
			return this.get('model.doubletimeSeconds') ? true : false;
		}),

		/**
   * Used to Display the Date Range of the Payperiod
   * that was selected . It is used in the Template
   * @public
   * @method reportDateRange
   */
		reportDateRange: Ember.computed('start', 'end', function () {
			return _utils.Time.timeFormat(this.get('start'), 'MMM DD') + ' - ' + _utils.Time.timeFormat(this.get('end'), 'MMM D, YYYY');
		}),

		openItemsObserver: Ember.observer('model.@each.isOpen', function () {
			var models = this.get('model');
			var openItems = [];
			var projectItems = [];

			models.forEach(function (item) {
				if (item.get('hasChildren')) {
					projectItems.pushObject(item);

					if (item.get('isOpen')) {
						openItems.pushObject(item);
					}
				}
			});

			if (projectItems.length > 0) {
				this.set('canOpen', true);
			} else {
				this.set('canOpen', false);
			}

			if (openItems.length > 0) {
				this.set('openItems', true);
			} else if (openItems.length <= 0) {
				this.set('openItems', false);
				this.set('allOpen', false);
			}

			if (openItems.length === projectItems.length) {
				this.set('allOpen', true);
			}
		}),

		expandAllRows: function expandAllRows(model) {
			var _this = this;

			var order = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

			// if nothing is passed then exit the
			// recursive function
			if (Ember.isEmpty(model)) {
				return;
			}

			model.forEach(function (item) {
				// if to prevent project - employee report from opening objects past
				// the employee.
				if (!/^project/.test(order)) {
					item.set('isOpen', true);

					if (!Ember.isEmpty(item.get('projects'))) {
						_this.expandAllRows(item.get('projects'), order + 'member-');
					} else if (!Ember.isEmpty(item.get('members'))) {
						_this.expandAllRows(item.get('members'), order + 'project-');
					}
				}
			});
		},
		collapseAllRows: function collapseAllRows(model) {
			var _this2 = this;

			// if nothing is passed then exit the
			// recursive function
			if (Ember.isEmpty(model)) {
				return;
			}

			model.forEach(function (item) {
				item.set('isOpen', false);

				if (!Ember.isEmpty(item.get('projects'))) {
					_this2.collapseAllRows(item.get('projects'));
				} else if (!Ember.isEmpty(item.get('members'))) {
					_this2.collapseAllRows(item.get('members'));
				}
			});
		},


		actions: {
			expandRow: function expandRow(item) {
				item.set('isOpen', !item.get('isOpen'));
			},
			expandAll: function expandAll() {
				this.expandAllRows(this.get('model'));
			},
			collapseAll: function collapseAll() {
				this.collapseAllRows(this.get('model'));
			},
			openTimeEntriesAction: function openTimeEntriesAction(item) {
				if (!this.get('dontShowTimeEntries')) {
					this.set('timeEntryStart', this.get('model.start'));
					this.set('timeEntryEnd', this.get('model.end'));
					this.set('timeEntryMember', item.get('member'));
					this.set('timeEntryProject', item.get('project'));

					if (Ember.isNone(item.get('member'))) {
						this.set('unassignedProjects', true);
					}

					this.set('showTimeEntryDialog', true);
				}
			},
			closeTimeEntryDialog: function closeTimeEntryDialog() {
				this.set('showTimeEntryDialog', false);

				Ember.run.later(this, function () {
					this.set('timeEntryMember', null);
					this.set('timeEntryProject', null);
					this.set('timeEntryStart', null);
					this.set('timeEntryEnd', null);
					this.set('unassignedProjects', false);
				}, 300);
			},
			refreshSync: function refreshSync() {
				this.set('dirtyFlag', true);
			},
			showWageHistory: function showWageHistory(item) {
				this.set('dontShowTimeEntries', true);
				this.set('showWageDialog', true);
				this.set('member', item);
			},
			closeWageDialog: function closeWageDialog() {
				this.set('dontShowTimeEntries', false);
				this.set('showWageDialog', false);
				this.set('member', null);
			}
		}
	});
});