define('busy-app/utils/docs', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var Docs = Ember.Object.extend();

	/**
  * `Util/Print`
  *
  */
	/**
  * @module Utils
  *
  */
	Docs.reopenClass({
		print: function print(printElement) {
			_utils.Assert.funcNumArgs(arguments, 1);

			if (!Ember.isNone(printElement)) {
				this.printContainer(printElement);
			} else {
				this.printPage();
			}
		},
		printContainer: function printContainer(printElement) {
			_utils.Assert.funcNumArgs(arguments, 1, true);

			var printContainer = Ember.$('body >  .print-container');
			var pe = printElement.clone();

			printContainer.html(pe);

			var app = Ember.$('.ember-application > .ember-view');
			var dialog = Ember.$('.ember-application > #modal-overlays');

			app.hide();
			dialog.hide();
			printContainer.addClass('active');

			this.printPage();

			printContainer.removeClass('active');
			printContainer.html('');
			app.show();
			dialog.show();
		},
		printPage: function printPage() {
			_utils.Assert.funcNumArgs(arguments, 0, true);

			if (window.print === undefined) {
				Ember.$.getScript('https://www.google.com/cloudprint/client/cpgadget.js').then(Ember.run(this, function () {
					var gadget = new window.cloudprint.Gadget();

					gadget.setPrintDocument("url", Ember.$('title').html(), window.location.href, "utf-8");
					gadget.openPrintDialog();
				}));
			} else {
				window.print();
			}
		},
		setPrintable: function setPrintable() {
			var isPrint = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

			_utils.Assert.funcNumArgs(arguments, 1);
			_utils.Assert.isBoolean(isPrint);

			if (isPrint === true) {
				Ember.$('body').addClass('printable');
			} else {
				Ember.$('body').removeClass('printable');
			}
		},
		download: function download(filename, contentType, body) {
			window.URL = window.URL || window.webkitURL || window.mozURL || window.msURL;

			var blob = new window.Blob([body], { type: contentType });

			var a = document.createElement('a');
			a.setAttribute('href', window.URL.createObjectURL(blob));
			a.setAttribute('download', filename);

			a.dataset.downloadurl = [contentType, a.download, a.href].join(':');
			a.draggable = true;
			a.classList.add('dragout');

			if (document.createEvent) {
				var event = document.createEvent('MouseEvents');
				event.initEvent('click', true, true);
				a.dispatchEvent(event);
			} else {
				a.click();
			}
		}
	});

	exports.default = Docs;
});