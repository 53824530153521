define('busy-app/utils/container/container-manager', ['exports', '@busy-web/utils', 'busy-app/utils/container/model-manager', 'busy-app/utils/container/container-array', 'busy-app/utils/container/container'], function (exports, _utils, _modelManager, _containerArray, _container2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Object.extend({
		models: null,
		modelInfo: null,
		hash: null,

		generate: function generate(models) {
			var _this = this;

			for (var _len = arguments.length, types = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
				types[_key - 1] = arguments[_key];
			}

			var options = {};
			if (_typeof(types[0]) === 'object') {
				options = types.shift();
			}

			this.set('modelInfo', _modelManager.default.create({ model: models.get('firstObject') }));

			// handle nested project data first
			// TODO:
			// make this more genneric to handle more
			// then just nested project data.
			if (!options.skipProjectData && this.get('modelInfo').hasProjectData()) {
				// generate project models first
				return this.generateTypes(models, ['project']).then(function (data) {
					// check if project data was requested in the types array
					// and remove it if it was
					var pIndex = types.indexOf('project');
					if (pIndex !== -1) {
						types.splice(pIndex, 1);
					}

					if (types.length <= 0) {
						return data;
					}

					// get parent models from project data
					// pull out all models arrays and merge them into
					// one model array
					var _newModels = data.project.mapBy('model');
					var newModels = [];

					// add each array of models to the new models array
					_newModels.forEach(function (item) {
						return newModels.pushObjects(item);
					});

					// call generateTypes with the new dataset
					return _this.generateTypes(newModels, types).then(function (otherData) {
						// if project type was specified then get it here
						if (pIndex !== -1) {
							otherData.project = data.project;
						}
						return otherData;
					});
				});
			} else {
				// no project data so just generate the containers
				return this.generateTypes(models, types);
			}
		},
		generateTypes: function generateTypes(models, types) {
			var _this2 = this;

			this.set('models', models);
			this.set('hash', Ember.Object.create({}));

			this.setupHash(types);

			// if models is an empty array then return
			// an empty hash object
			if (Ember.isEmpty(models)) {
				return Ember.RSVP.resolve(this.get('hash'));
			}

			// generate each type container
			var promise = [];
			models.forEach(function (model) {
				promise.push(_this2.buildContainer(model));
			});

			return Ember.RSVP.all(promise).then(function () {
				// get the hash object
				var hash = _this2.get('hash');

				// sort each type container array
				types.forEach(function (type) {
					if (type && !Ember.isNone(hash.get(type))) {
						var content = hash.get(type);
						_this2.sortNested(content);
						content = content.sorted();
						hash.set(type + '.content', content);
					}
				});

				return hash;
			});
		},
		createContainerArray: function createContainerArray(type) {
			_utils.Assert.test('modelInfo must be set in order to call createContainerArray', _typeof(this.get('modelInfo')) === 'object');

			// create a new container based off the meta from the modelInfo
			var meta = this.get('modelInfo').getMetaByType(type);

			// create the containerArray
			return _containerArray.default.create({ content: Ember.A(), sortKeys: meta.sortBy, reverseSortKeys: meta.reverseSortBy, reverseSort: meta.reverseSort, type: meta.type });
		},
		sortNested: function sortNested(models) {
			var _this3 = this;

			var remove = [];
			models.forEach(function (model) {
				if (!Ember.isNone(model.get('parentId'))) {
					var parent = _this3.findNested(models, model.get('parentId'));
					if (!Ember.isNone(parent)) {
						var children = parent.get('children');
						if (Ember.isNone(children)) {
							children = _this3.createContainerArray(models.get('type'));
							parent.set('children', children);
						}
						children.pushObject(model);
						children.set('content', children.sorted());
						remove.push(model);
					}
				}
			});
			models.removeObjects(remove);
		},
		findNested: function findNested(models, parentId) {
			var _this4 = this;

			var parent = null;
			models.find(function (container) {
				if (container.get('id') === parentId) {
					parent = container;
					return true;
				} else if (!Ember.isEmpty(container.get('children'))) {
					var res = _this4.findNested(container.get('children'), parentId);
					if (!Ember.isNone(res)) {
						parent = res;
						return true;
					}
				}
			});

			return parent;
		},
		setupHash: function setupHash(types) {
			var _this5 = this;

			// set available types for the container to generate
			types.forEach(function (type) {
				// if the passed in type is in the sortableTypes hash
				// then create a containerArray
				var meta = _this5.get('modelInfo').getMetaByType(type);
				if (!Ember.isNone(meta)) {
					// create the containerArray
					_this5.set('hash.' + type, _containerArray.default.create({ content: Ember.A(), sortKeys: meta.sortBy, reverseSortKeys: meta.reverseSortBy, reverseSort: meta.reverseSort, type: type }));
				}
			});
		},
		createContainer: function createContainer(manager, model) {
			var _container$create;

			// get the manager's meta data
			var meta = manager.getMeta(model);
			var id = meta.id || null;

			// map the properties to match
			// the model array property syntax
			var watchedProps = [];
			var props = [];
			manager.eachProperty(function (key) {
				watchedProps.push('model.@each.' + key);
				props.push(key);
			});

			// get a ref to the joined model parentId if it has one
			var nestedId = null;
			if (!Ember.isNone(meta.nested)) {
				nestedId = model.get(meta.nested);
			}

			// get a ref to the joined model if it has one
			var typeModel = model.get('' + meta.type);
			if (meta.attrType === 'id') {
				typeModel = model.get(meta.type + '.content');
				if (Ember.isNone(id)) {
					typeModel = this.createUnasigned();
				} else {
					typeModel.set('sortWeight', 1);
				}
			}

			// create a container object with an empty model array
			// and a unique identifier `id` based on the
			// sortType meta property value.
			var _container = (0, _container2.default)(props, watchedProps, manager.aggregateProperties);

			// create new container
			var container = _container.create((_container$create = {
				model: Ember.A(),
				id: id,
				sortBy: Ember.isArray(meta.sortChildren) ? meta.sortChildren : [],
				reverseSortBy: Ember.isArray(meta.reverseSortChildren) ? meta.reverseSortChildren : [],
				reverseSort: meta.reverseSortChildren === true ? true : false
			}, _defineProperty(_container$create, meta.type, typeModel), _defineProperty(_container$create, 'parentId', nestedId), _container$create));

			// add container to container array
			this.get('hash.' + meta.type).pushObject(container);

			// return container
			return container;
		},
		createUnasigned: function createUnasigned() {
			return Ember.Object.create({
				firstName: (0, _utils.loc)('Unassigned'),
				title: (0, _utils.loc)('Unassigned'),
				fullTitle: (0, _utils.loc)('Unassigned'),
				costCode: (0, _utils.loc)('Unassigned'),
				equipmentName: (0, _utils.loc)('Unassigned'),
				isUnassigned: true,
				sortWeight: 2
			});
		},
		getContainer: function getContainer(manager, model) {
			// get meta for this model
			var meta = manager.getMeta(model);
			var id = meta.id || null;

			// get the container if it already exists
			var container = this.get('hash.' + meta.type).findBy('id', id);

			// if a container is not found then create a new one
			// and add it to the hash type
			if (Ember.isNone(container)) {
				container = this.createContainer(manager, model);
			}

			// return the container
			return container;
		},
		buildContainer: function buildContainer(model) {
			var _this6 = this;

			_utils.Assert.isObject(model);
			// set the current model to a new ModelManager
			var manager = this.get('modelInfo');

			// load joined model data
			return this.loadData(model, manager).then(function (model) {
				// loop through valid model attrs
				manager.eachAttribute(function (attr, meta) {
					// only add types that exists in the hash
					if (!Ember.isNone(_this6.get('hash.' + meta.type))) {
						// find an existing container for this attr value
						var container = _this6.getContainer(manager, model);

						// add the model to the container
						container.pushObject(model);
						container.sort();
					}
				});
			});
		},


		/**
   * method for getting the belongsTo data to load.
   * this method returns a promise when all the data is loaded
   *
   * @public
   * @method loadData
   * @returns {EmberPromise}
   */
		loadData: function loadData(model, manager) {
			var _this7 = this;

			if (Ember.isNone(model)) {
				return Ember.RSVP.resolve(null);
			}

			var promise = [];
			manager.eachAttribute(function (attr, meta) {
				// where rootProjectId === id (this project is the root project)
				if (!Ember.isNone(model.get(attr)) && model.get(attr) !== model.id) {
					// call get on the model to trigger the load
					var res = model.get(meta.type);

					// if the property is not a DS.belongsTo then wrap it with a resolve promise
					if (Ember.isNone(res) || (typeof res === 'undefined' ? 'undefined' : _typeof(res)) !== 'object' || !res.then) {
						promise.push(Ember.RSVP.resolve(res));
					} else {
						// call loadData again to after the promise returns to
						// load any models this model needs
						promise.push(res.then(function (_model) {
							return _this7.loadData(_model, _modelManager.default.create({ model: _model }));
						}));
					}
				} else {
					// wrap null in a promise so the model does not
					// load itself again.
					promise.push(Ember.RSVP.resolve(null));
				}
			});

			// return the promise array
			return Ember.RSVP.all(promise).then(function () {
				return model;
			});
		}
	});
});