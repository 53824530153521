define('busy-app/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),
    role: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    isEmailVerified: _emberData.default.attr('boolean')
  });
});