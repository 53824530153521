define('busy-app/models/workers-comp', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    organizationId: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),

    type: (0, _utils.loc)("Workers' Compensation"),
    typeKey: 'workers-comp'
  });
});