define('busy-app/components/time/time-sheet/index', ['exports', '@busy-web/utils', 'busy-app/utils/organization/overtime-period'], function (exports, _utils, _overtimePeriod) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = Ember.Component.extend({
		classNames: ['c-time-sheet'],
		classNameBindings: ['isLoading:is-loading'],

		auth: Ember.inject.service(),
		payPeriod: Ember.inject.service(),
		router: Ember.inject.service(),
		store: Ember.inject.service(),
		graylog: Ember.inject.service(),

		// arguments
		member: null,
		start: null,
		end: null,
		mode: null,

		// recordsets created in loadModel()
		memberDaySeconds: null,
		conflicts: null,
		openEntry: null,
		// orgOvertime: null,
		payPeriods: null,

		dirtyFlag: false,
		isLoading: false,
		hasLoaded: false,
		showSummaryDialog: false,
		refreshTimestamp: null,

		activityReportEnd: null,
		activityReportStart: null,
		activityReportSettings: null,

		showTimeEntryView: true,

		entryConflictCutoff: _utils.Time.date().startOf('day').subtract(90, 'days').unix(),

		isRenderable: Ember.computed.and('hasLoaded', 'auth.organizationOvertimePeriod.length', 'payPeriods.length', 'start', 'end', 'mode'),
		isModePayPeriod: Ember.computed.equal('mode', 'pay-period'),
		isModeWeekly: Ember.computed.equal('mode', 'weekly'),

		// this is the currently selected pay period
		currentPayPeriod: Ember.computed('mode', 'payPeriods', 'start', 'end', function () {
			var _this = this;

			if (!this.get('isModePayPeriod') || Ember.isNone(this.get('payPeriods'))) {
				return null;
			}

			return this.get('payPeriods').toArray().find(function (pp) {
				return pp.get('startTime') === _this.get('start');
			});
		}),

		// this is the live pay period encompassing todays date
		livePayPeriod: Ember.computed('payPeriods', function () {
			if (Ember.isNone(this.get('payPeriods'))) {
				return null;
			}

			var now = _utils.Time.date();
			return this.get('payPeriods').find(function (pp) {
				var startTime = _utils.Time.date(pp.get('startTime'));
				var endTime = _utils.Time.date(pp.get('endTime'));

				return now.isBetween(startTime, endTime, null, '[]'); // [] argument means inclusive, moment is exclusive by default
			});
		}),

		activityReportModel: Ember.computed('member', function () {
			return {
				member: this.get('member')
			};
		}),

		init: function init() {
			this._super.apply(this, arguments);
			// Logger.info(this, 'init');
			this.graylogViewedTimeCardSection();

			if (!Ember.isNone(this.get('member'))) {
				this.initData();
			}
		},
		initData: function initData() {
			var _this2 = this;

			var _getProperties = this.getProperties('start', 'end', 'mode'),
			    start = _getProperties.start,
			    end = _getProperties.end;
			// Logger.info(this, 'initData', start, end, mode);

			this.fixStringDates(start, end);

			this.safeSet('isLoading', true);
			this.loadStaticData().then(function () {
				return _this2.initDates();
			});
		},
		fixStringDates: function fixStringDates(start, end) {
			if (!Ember.isNone(start) && parseInt(start, 10) !== start) {
				this.set('start', parseInt(start, 10));
			}

			if (!Ember.isNone(end) && parseInt(end, 10) !== end) {
				this.set('end', parseInt(end, 10));
			}
			// Logger.info(this, 'fixStringDates', this.getProperties('start', 'end', 'mode'));
		},
		initDates: function initDates() {
			var _getProperties2 = this.getProperties('start', 'end', 'mode'),
			    start = _getProperties2.start,
			    end = _getProperties2.end,
			    mode = _getProperties2.mode;
			// Logger.info(this, 'initDates', { start, end, mode });

			if (!Ember.isNone(start) && !Ember.isNone(end) && !Ember.isNone(mode)) {
				this.setDateBounds(start, end);
				// } else if (this.get('mode') === 'pay-period') {
			} else if (this.get('mode') === 'weekly') {
				this.initDatesWeekly();
			} else {
				this.set('mode', 'pay-period');
				this.initDatesPayPeriod();
			}
		},
		initDatesPayPeriod: function initDatesPayPeriod() {
			// const now = Time.date().unix();
			// const payPeriod = this.get('payPeriod').getPayPeriod(now);
			var _get$getProperties = this.get('livePayPeriod').getProperties('startTime', 'endTime'),
			    startTime = _get$getProperties.startTime,
			    endTime = _get$getProperties.endTime;

			_utils.Assert.isNumber(startTime);
			_utils.Assert.isNumber(endTime);

			// Logger.info(this, 'initDatesPayPeriod', { startTime, endTime }, { startTime: Time.date(startTime).format(), endTime: Time.date(endTime).format() });

			this.setDateBounds(startTime, endTime);
		},
		getWeekBounds: function getWeekBounds(timestamp) {
			return (0, _overtimePeriod.createOvertimeBoundWeek)(this.get('auth.organizationOvertimePeriod'), timestamp);
		},
		initDatesWeekly: function initDatesWeekly() {
			var _getWeekBounds = this.getWeekBounds(_utils.Time.date().unix()),
			    start = _getWeekBounds.start,
			    end = _getWeekBounds.end;
			// Logger.info(this, 'initDatesWeekly', { start, end });

			this.setDateBounds(start, end);
		},
		loadStaticData: function loadStaticData() {
			var _this3 = this;

			// Logger.info(this, 'loadStaticData');

			return this.getMergedPayPeriods() // needed early to init the pay periods selector
			.then(function (payPeriods) {
				return _this3.safeSetProperties({ payPeriods: payPeriods });
			});
		},
		loadModel: function loadModel() {
			var _this4 = this;

			// Logger.info(this, 'loadModel', this.getProperties('start', 'end', 'mode'));

			// should only happen when running CI tests
			if (Ember.isNone(this.get('member'))) {
				return Ember.RSVP.resolve();
			}

			this.safeSet('isLoading', true);

			return Ember.RSVP.hash({
				memberDaySeconds: this.loadMemberDaySeconds(),
				memberDayCost: this.loadMemberDayCost(),
				timeOffAggregates: this.loadTimeOffAggregates(),
				conflicts: this.loadConflicts(),
				openEntry: this.loadOpenEntry(),
				payPeriods: this.getMergedPayPeriods(),
				organizationInfo: Ember.get(this, 'auth.organizationInfo') // make sure this is loaded before format-currency is used
			}).then(function (props) {
				// Logger.info(this, 'loadModel', props);

				return _this4.safeSetProperties(props);
			}).finally(function () {
				// Logger.info(this, 'loadModel', 'done');

				return _this4.safeSetProperties({
					isLoading: false,
					hasLoaded: true,
					refreshTimestamp: _utils.Time.date().unix()
				});
			});
		},
		loadMemberDaySeconds: function loadMemberDaySeconds() {
			var params = {
				member_id: this.get('member.id'),
				_gte: { start_date: this.get('start') },
				_lte: { start_date: this.get('end') }
			};

			// Logger.info(this, 'loadMemberDaySeconds', { params });

			return this.get('store').query('member-day-seconds', params);
		},
		loadMemberDayCost: function loadMemberDayCost() {
			var params = {
				member_id: this.get('member.id'),
				_gte: { start_date: this.get('start') },
				_lte: { start_date: this.get('end') }
			};

			// Logger.info(this, 'loadMemberDayCost', { params });

			return this.get('store').query('member-day-cost', params);
		},
		loadConflicts: function loadConflicts() {
			var params = {
				member_id: this.get('member.id'),
				range_start: this.get('start'),
				range_end: this.get('end')
			};

			// Logger.info(this, 'loadConflicts', { params });

			return this.get('store').query('time-entry-member-conflicts', params);
		},
		loadOpenEntry: function loadOpenEntry() {
			var params = {
				member_id: this.get('member.id'),
				end_time: '_-NULL-_',
				_asc: ['start_time'],
				page_size: 1
			};

			// Logger.info(this, 'loadOpenEntries', { params });

			return this.get('store').query('time-entry', params).then(function (entries) {
				var entry = entries.get('firstObject');

				if (!Ember.isNone(entry)) {
					return entry.get('startTime');
				} else {
					return null;
				}
			});
		},
		loadOldestTimeEntry: function loadOldestTimeEntry() {
			var params = {
				member_id: this.get('member.id'),
				_gt: { start_time: 0 }, // no negative time entries
				_asc: ['start_time', 'submitted_on'],
				page_size: 1
			};

			// Logger.log(this, 'loadOldestTimeEntry', params);

			return this.get('store').query('pay-period-member-seconds', params);
		},
		generatePayPeriods: function generatePayPeriods() {
			var _this5 = this;

			// Logger.log(this, 'generatePayPeriods');
			var currentPayPeriod = this.get('payPeriod').getPayPeriod();
			var start = currentPayPeriod.get('end'); // include through tthe end of this pay period in generation
			var excludeEnd = false;
			// Logger.log(this, 'generatePayPeriods', { currentPayPeriod, start, currentPayPeriod });

			return this.loadOldestTimeEntry().then(function (results) {
				var oldestEntry = results.objectAt('0');
				var end = _this5.get('member.createdOn');

				if (!Ember.isNone(oldestEntry)) {
					end = Math.min(end, oldestEntry.get('startTime'));
				}
				// Logger.log(this, 'generatePayPeriods', results, oldestEntry, end);

				var payPeriods = _this5.get('payPeriod').getPayPeriodDateRange(start, end, excludeEnd).sortBy('start').reverse();

				// Logger.log(this, 'generatePayPeriods', {start, end, currentPayPeriod, payPeriods});

				return Ember.RSVP.resolve(payPeriods);
			});
		},
		loadPayPeriodAggregates: function loadPayPeriodAggregates() {
			var params = {
				member_id: this.get('member.id'),
				_desc: ['start_time'],
				page_size: 99999
			};

			return this.get('store').query('pay-period-member-seconds', params);
		},
		getMergedPayPeriods: function getMergedPayPeriods() {
			var _this6 = this;

			// Logger.info(this, 'getMergedPayPeriods');

			return Ember.RSVP.hash({
				allPayPeriods: this.generatePayPeriods(),
				aggPayPeriods: this.loadPayPeriodAggregates()
			}).then(function (props) {
				// Logger.log(this, 'getMergedPayPeriods', props);

				return _this6.mergePayPeriodData(props.allPayPeriods, props.aggPayPeriods);
			});
		},
		mergePayPeriodData: function mergePayPeriodData(allPayPeriods, aggPayPeriods) {
			// Logger.info(this, 'getMergedPayPeriods');
			var aggKeyedByStart = this.keyArrayBy(aggPayPeriods, 'startTime');
			// Logger.info(this, 'getMergedPayPeriods', aggKeyedByStart);

			var mergedPayPeriods = allPayPeriods.map(function (pp) {
				var aggKey = pp.get('start').toString();
				var agg = aggKeyedByStart.get(aggKey);

				// hacky workaround since parts are expecting startTime and endTime props as used in PayPeriodMemberSeconds
				pp.setProperties({
					startTime: Ember.computed.alias('start'),
					endTime: Ember.computed.alias('end')
				});

				if (!Ember.isNone(agg) && agg.get('endTime') === pp.get('end')) {
					var aggProps = agg.getProperties('regularSeconds', 'doubletimeSeconds', 'overtimeSeconds', 'totalSeconds', // computed
					'regularCost', 'overtimeCost', 'doubletimeCost', 'totalCost');

					pp.setProperties(aggProps);
				}

				return pp;
			});
			// Logger.info(this, 'getMergedPayPeriods', mergedPayPeriods);

			return mergedPayPeriods;
		},
		loadOrganizationOvertimePeriods: function loadOrganizationOvertimePeriods() {
			var params = {
				_desc: ['start_time'],
				deleted_on: '_-NULL-_'
			};

			// Logger.info(this, 'loadOrganizationOvertimePeriods', params);

			return this.get('store').query('organization-overtime-period', params);
		},
		loadTimeOffAggregates: function loadTimeOffAggregates() {
			var _this7 = this;

			var params = {
				member_id: this.get('member.id'),
				_gte: { date_stamp: this.get('start') },
				_lte: { date_stamp: this.get('end') }
			};

			// Logger.info(this, 'loadMemberDaySeconds', { params });

			return this.get('store').query('time-off', params).then(function (timeOffEntries) {
				return _this7.aggregateTimeOffEntries(timeOffEntries);
			});
		},
		aggregateTimeOffEntries: function aggregateTimeOffEntries(timeOffEntries) {
			// Logger.info(this, 'aggregateTimeOffEntries', { timeOffEntries });

			var dates = timeOffEntries.mapBy('dateStamp').uniq();

			var timeoffAggregates = dates.map(function (dateStamp) {
				var entries = timeOffEntries.filterBy('dateStamp', dateStamp);
				var seconds = entries.reduce(function (acc, entry) {
					return acc = +parseInt(Ember.get(entry, 'seconds'), 10);
				}, 0);
				var hasPaid = entries.any(function (entry) {
					return Ember.get(entry, 'paid');
				});
				var hasUnpaid = entries.any(function (entry) {
					return !Ember.get(entry, 'paid');
				});

				return {
					dateStamp: dateStamp,
					entries: entries,
					seconds: seconds,
					hasPaid: hasPaid,
					hasUnpaid: hasUnpaid
				};
			});

			// Logger.info(this, 'aggregateTimeOffEntries', { timeoffAggregates });


			return timeoffAggregates;
		},


		// action helpers
		setDateBounds: function setDateBounds(start, end) {
			var _this8 = this;

			// Logger.info(this, 'setDateBounds', { start, end });

			Ember.RSVP.resolve(this.safeSetProperties({
				start: parseInt(start, 10),
				end: parseInt(end, 10)
			})).then(function () {
				Ember.run.next(_this8, function () {
					return _this8.loadModel();
				});
			});
		},
		setMode: function setMode(mode) {
			var _this9 = this;

			// Logger.info(this, 'setMode', mode);

			Ember.RSVP.resolve(this.safeSetProperties({
				mode: mode,
				start: null,
				end: null
			})).then(function () {
				Ember.run.next(_this9, function () {
					return _this9.initDates();
				});
				// next(this, () => this.loadModel());
			});
		},
		keyArrayBy: function keyArrayBy(collection, key) {
			var keyedCollection = collection.reduce(function (acc, item) {
				var itemKey = item.get(key).toString();

				return Object.assign(acc, _defineProperty({}, itemKey, item));
			}, {});

			return Ember.Object.create(keyedCollection);
		},


		// utility helpers
		safeSet: function safeSet(key, val) {
			if (!this.get('isDestroyed')) {
				this.set(key, val);
				return this.get(key);
			}
		},
		safeSetProperties: function safeSetProperties(hash) {
			if (!this.get('isDestroyed')) {
				this.setProperties(hash);
				return Ember.RSVP.resolve(this.getProperties.apply(this, _toConsumableArray(Object.keys(hash))));
			} else {
				return Ember.RSVP.resolve();
			}
		},
		getDialogToggle: function getDialogToggle(dialogName) {
			return 'show' + dialogName;
		},
		setupActivityReport: function setupActivityReport(start) {
			var end = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : start;

			end = _utils.Time.date(end).utc().endOf('day').unix();

			var activityReportSettings = Ember.Object.create({
				start: start,
				end: end,
				type: 'member',
				selected: this.get('mode'),
				column: 'total-hours',
				totalHours: true,
				hideMemberName: true
			});

			var createTimeEntryConfig = {
				date: end,
				member: this.get('member')
			};

			return this.safeSetProperties({
				activityReportStart: start,
				activityReportEnd: end,
				activityReportSettings: activityReportSettings,
				createTimeEntryConfig: createTimeEntryConfig
			});
		},


		/* graylog events */

		graylogViewedTimeCardSection: function graylogViewedTimeCardSection() {
			this.get('graylog').sendEvent({
				short_message: 'Viewed Time Card Section',
				full_message: 'Viewed Time Card Section',
				level: 6,
				_member_id: this.get('auth.memberId'),
				_member_position_id: this.get('auth.member.positionId'),
				_type: 'pay_period'
			});
		},


		actions: {
			openDialog: function openDialog(dialogName) {
				_utils.Assert.isString(dialogName);
				// Logger.info(this, 'openDialog', dialogName);

				this.safeSet(this.getDialogToggle(dialogName), true);
			},
			closeDialog: function closeDialog(dialogName) {
				_utils.Assert.isString(dialogName);
				// Logger.info(this, 'closeDialog', dialogName);

				this.send('reloadSync');

				this.safeSet(this.getDialogToggle(dialogName), false);
			},
			toggleDialog: function toggleDialog(dialogName) {
				_utils.Assert.isString(dialogName);
				// Logger.info(this, 'toggleDialog', dialogName);

				return this.toggleProperty(this.getDialogToggle(dialogName));
			},
			reloadModel: function reloadModel() {
				this.loadModel();
			},
			reloadSync: function reloadSync() {
				this.safeSet('dirtyFlag', _utils.Time.date().unix());
			},
			openDayActivity: function openDayActivity(summaryDate) {
				_utils.Assert.isNumber(summaryDate);
				// Logger.info(this, 'openDayActivity', { summaryDate });

				this.setupActivityReport(summaryDate);

				this.send('openDialog', 'SummaryDialog');
			},
			openRangeActivity: function openRangeActivity(start, end) {
				_utils.Assert.isNumber(start);
				_utils.Assert.isNumber(end);
				// Logger.info(this, 'openRangeActivity', { start, end });

				this.setupActivityReport(start, end);

				this.send('openDialog', 'SummaryDialog');
			},
			setDateBounds: function setDateBounds(start, end) {
				this.setDateBounds(start, end);
			},
			setMode: function setMode(mode) {
				this.setMode(mode);
			}
		}
	});
});