define('busy-app/controllers/note/detail', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		showDialog: false,
		showLargeImage: false,
		largeImage: null,

		openPhotoIndex: Ember.computed('largeImage', function () {
			var str = '';
			if (!Ember.isNone(this.get('largeImage'))) {
				var length = this.get('model.mediaEntries.length');
				var index = this.get('model.mediaEntries').indexOf(this.get('largeImage')) + 1;
				str = index + '/' + length;
			}
			return str;
		}),

		isPrevDisabled: Ember.computed('largeImage', 'model.mediaEntries.[]', function () {
			if (!Ember.isNone(this.get('largeImage'))) {
				var index = this.get('model.mediaEntries').indexOf(this.get('largeImage'));
				if (index > 0) {
					return false;
				}
			}
			return true;
		}),

		isNextDisabled: Ember.computed('largeImage', 'model.mediaEntries.[]', function () {
			if (!Ember.isNone(this.get('largeImage'))) {
				var index = this.get('model.mediaEntries').indexOf(this.get('largeImage')) + 1;
				var length = this.get('model.mediaEntries.length');
				if (index < length) {
					return false;
				}
			}
			return true;
		}),

		actions: {
			viewImage: function viewImage(item) {
				this.set('largeImage', item);
				this.set('showLargeImage', true);
			},
			closeLargeImage: function closeLargeImage() {
				this.set('showLargeImage', false);
				this.set('largeImage', null);
			},
			editNoteCard: function editNoteCard() {
				this.set('showDialog', true);
			},
			closeDialog: function closeDialog() {
				this.get('model').rollbackAttributes();
				this.set('showDialog', false);
			},
			imageChange: function imageChange(file, url) {
				var mediaEntry = this.store.createRecord('media-entry', {
					mediaCollectionId: this.get('model.id'),
					createdBy: this.get('model.createdBy'),
					captureDate: _utils.Time.timestamp(),
					file: file,
					fileThumbUrl: url,
					entryType: 20
				});
				this.get('model.mediaEntries').pushObject(mediaEntry._internalModel);
			},
			saveDialog: function saveDialog() {
				var _this = this;

				return this.get('model').save().then(function () {
					return _this.get('model.mediaEntries').save().finally(function () {
						return _this.get('model.mediaEntries').update();
					});
				});
			},
			changeProject: function changeProject(item) {
				this.set('model.projectId', item.get('id'));
			},
			deleteNotecard: function deleteNotecard() {
				var _this2 = this;

				return this.get('model').destroyRecord().catch(function () {
					return Ember.RSVP.reject((0, _utils.loc)('There was a problem deleting this note card.'));
				}).finally(function () {
					_this2.set('showDialog', false);
					setTimeout(function () {
						window.history.back();
					}, 300);
				});
			},
			nextPhoto: function nextPhoto() {
				var index = this.get('model.mediaEntries').indexOf(this.get('largeImage')) + 1;
				var length = this.get('model.mediaEntries.length');
				if (index < length) {
					this.set('largeImage', this.get('model.mediaEntries').objectAt(index));
				}
			},
			prevPhoto: function prevPhoto() {
				var index = this.get('model.mediaEntries').indexOf(this.get('largeImage')) - 1;
				if (index >= 0) {
					this.set('largeImage', this.get('model.mediaEntries').objectAt(index));
				}
			}
		}
	});
});