define('busy-app/models/authoritative-signature', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		proxyMemberId: _emberData.default.attr('string'),
		createdOnOffset: _emberData.default.attr('number'),
		createdOnDST: _emberData.default.attr('boolean'),
		signature: _emberData.default.attr('svg'),
		signatureUrl: _emberData.default.attr('string'),
		createdOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),

		proxyMember: _emberData.default.belongsTo('member', { inverse: null, referenceKey: 'proxyMemberId', foreignKey: 'id' }),

		createdOnConverted: Ember.computed('createdOn', 'createdOnOffset', 'createdOnDST', function () {
			return this.get('createdOn') + this.get('createdOnOffset');
		}),

		hasProxySigner: Ember.computed('memberId', 'proxyMemberId', function () {
			return this.get('proxyMemberId') && this.get('memberId') !== this.get('proxyMemberId');
		})
	});
});