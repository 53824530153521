define('busy-app/utils/models/managers/time-entry-range', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/base', 'busy-app/utils/time-entry-validator'], function (exports, _utils, _base, _timeEntryValidator) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _base.default.extend({
		start: null,
		end: null,

		getSorted: function getSorted() {
			return this.get('model').sortBy('start').reverse();
		},


		/**
   * fetches the data for a time-entry by range.
   *
   * @public
   * @method fetch
   * @param start {number} Required timestamp for the start of the range.
   * @param end {number} Required timestamp for the end of the range.
   * @param memberId {string} Optional id to filter by a member.
   * @param projectId {string} Optional param to filter by a top level project and its children.
   * @param costCodeId {string} Optional param to filter by a cost code.
   * @param exclusive {boolean} Default false, true will force the filter by projectId to disinclude children.
   * @return {promise}
   */
		fetch: function fetch() {
			var start = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
			var end = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
			var memberId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
			var projectId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
			var costCodeId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
			var equipmentId = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
			var exclusive = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

			var options = start;
			if (Ember.isNone(options) || (typeof options === 'undefined' ? 'undefined' : _typeof(options)) !== 'object') {
				options = {
					start: start, end: end, memberId: memberId, projectId: projectId, costCodeId: costCodeId, equipmentId: equipmentId, exclusive: exclusive,
					disableTimeOff: false,
					disableOpenEntry: false
				};
			}

			this.set('start', options.start);
			this.set('end', options.end);

			return this.dispatcherFor('time-entry-segment').findAllByRange(options);
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			var start = this.get('start');
			var end = this.get('end');

			var entries = data.timeEntries.sortBy('startTime');
			entries.forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));
				_this.checkConflict(model, entries);
				_this.addCollection(model, start, end);
			});

			this.set('content', this.get('content').sortBy('start'));
		},
		checkConflict: function checkConflict(model, entries) {
			model.set('hasConflict', false);
			entries.forEach(function (conflictModel) {
				if (model.id !== conflictModel.id && model.get('memberId') === conflictModel.get('memberId')) {
					if (_timeEntryValidator.default.hasConflict(model, conflictModel)) {
						model.set('hasConflict', true);
					}
				}
			});
		},


		totalSeconds: Ember.computed('model.@each.{regularSeconds,overtimeSeconds,doubletimeSeconds}', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('regularSeconds') + item.get('overtimeSeconds') + item.get('doubletimeSeconds');
				});
			}
			return total;
		}),

		/**
   * totalTimeString is the total time calculated from the time entry segments using
   * the regularSeconds, overtimeSeconds, and doubletimeSeconds
   *
   */
		totalTimeString: Ember.computed('totalSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('totalSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		}),

		regularSeconds: Ember.computed('model.@each.regularSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('regularSeconds');
				});
			}
			return total;
		}),

		/**
   * regularTimeString is the regulartime calculated from the time entry segments
   *
   */
		regularTimeString: Ember.computed('regularSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('regularSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		}),

		overtimeSeconds: Ember.computed('model.@each.overtimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('overtimeSeconds');
				});
			}
			return total;
		}),

		/**
   * overtimeTimeString is the overtime calculated from the time entry segments
   *
   */
		overtimeTimeString: Ember.computed('overtimeSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('overtimeSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		}),

		doubletimeSeconds: Ember.computed('model.@each.doubletimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('doubletimeSeconds');
				});
			}
			return total;
		}),

		/**
   * doubletimeTimeString is the doubletime calculated from the time entry segments
   *
   */
		doubletimeTimeString: Ember.computed('doubletimeSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('doubletimeSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		}),

		breaktimeSeconds: Ember.computed('model.@each.breaktimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('breaktimeSeconds');
				});
			}
			return total;
		}),

		/**
   * breakTimeString is the break time calculated from the time entries
   *
   */
		breakTimeString: Ember.computed('breaktimeSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('breaktimeSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		})
	});
});