define('busy-app/components/busy-select-checkbox', ['exports', '@busy-web/components/components/bc-select'], function (exports, _bcSelect) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _bcSelect.default.extend({
		menuTitle: 'Options',
		itemVal: null,

		actions: {
			selectChange: function selectChange(val, item) {
				this.sendAction('onSelect', val, item);
			}
		}
	});
});