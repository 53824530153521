define('busy-app/utils/integrations/launch-darkly', ['exports', '@busy-web/utils', 'busy-app/utils/logger', 'busy-app/utils/settings', 'busy-app/utils/integrations/base'], function (exports, _utils, _logger, _settings, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend(Ember.Evented, {
    auth: Ember.inject.service('auth'),

    client: null,
    clientId: _settings.default.get('launchdarkly_clientid'),
    featureName: 'NOT_REQUIRED',

    isEnabled: function isEnabled() {
      return true;
    },
    isRunReady: function isRunReady() {
      if (window.LDClient && typeof window.LDClient.initialize === 'function') {
        if (!this.isAuth()) {
          return true;
        } else if (!Ember.isNone(this.get('auth.organization.id'))) {
          return true;
        }
      }
      return false;
    },
    load: function load(callback) {
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.type = 'text/javascript';
      script.async = true;
      script.id = 'launch-darkly-loader';
      script.src = 'https://app.launchdarkly.com/snippet/ldclient.min.js';

      var node = document.getElementsByTagName('script')[0];
      node.parentNode.insertBefore(script, node);
      callback();
    },
    run: function run() {
      var data = {};
      if (!this.isAuth()) {
        data.anonymous = true;
        data.key = 'anon-' + _utils.UUID.generate();
      } else {
        data.anonymous = false;

        data.key = this.get('auth.member.id');
        data.firstName = this.get('auth.member.firstName');
        data.lastName = this.get('auth.member.lastName');
        data.email = this.get('auth.member.email');
        data.avatar = this.get('auth.member.imageUrl');

        data.custom = {
          organizationId: this.get('auth.organization.id'),
          organizationName: this.get('auth.organization.organizationName'),
          webVersionNumber: _settings.default.get('version'),
          webBuildMode: _settings.default.get('build')
        };
      }

      var clientOptions = {
        //bootstrap: 'localStorage',
      };

      var client = window.LDClient.initialize(this.get('clientId'), data, clientOptions);
      this.set('client', client);
      this.attachReadyListener();
      this.attachChangeListener();
    },


    /**
     * Send a `track` event to Launch Darkly
     *
     * @public
     * @async
     * @method track
     */
    track: function track(goalKey) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        return _this.get('client').track(goalKey, function () {
          _logger.default.info(_this, 'track', { goalKey: goalKey });
          return resolve();
        });
      });
    },


    /**
     * Attach a listener for the LD client's `ready` event
     *
     * @public
     * @async
     * @method attachReadyListener
     */
    attachReadyListener: function attachReadyListener() {
      var _this2 = this;

      this.get('client').on('ready', function () {
        return _this2.onReady();
      });
    },


    /**
     * Attach a listener for the LD client's `change` event
     *
     * @public
     * @async
     * @method attachChangeListener
     */
    attachChangeListener: function attachChangeListener() {
      var _this3 = this;

      this.get('client').on('change', function (changedFeatures) {
        return _this3.onChange(changedFeatures);
      });
    },


    /**
     * Triggered when the `onReady` listener is fired
     * apply all the LD flags and attache the change listener
     *
     * @public
     * @async
     * @method onReady
     */
    onReady: function onReady() {
      this.trigger('ready');
    },


    /**
     * Triggered when the `change` listener is fired
     * Hot applies all the updated LD flags
     *
     * @public
     * @async
     * @method onChange
     * @param {Object} flags
     */
    onChange: function onChange(flags) {
      var _flags = {};
      Object.keys(flags).forEach(function (key) {
        _flags[key] = flags[key].current;
      });
      this.trigger('change', _flags);
    },


    /**
     * Proxy the Launch Darkly client to call the allFlags() method
     *
     * @public
     * @async
     * @method getAllFlags
     */
    getAllFlags: function getAllFlags() {
      //this.attachChangeListener();
      return this.get('client').allFlags();
    },


    /**
     * Proxy the Launch Darkly client to call the variation() method
     *
     * @public
     * @async
     * @method getAllFlags
     * @param {String} key the identifying key for the feature, in the format `time.freemium`
     * @param {Boolean} defaultValue the default value to use
     */
    getFlag: function getFlag(key, defaultValue) {
      return this.get('client').variation(key, defaultValue);
    }
  });
});