define('busy-app/components/selectors/list-select', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kCanTake = ['manageMediaCollection', 'requestSignatures', 'manageEmployees', 'timeEvents', 'manageTimeEntries', 'memberLock']; /**
                                                                                                                                     * @module Components
                                                                                                                                     *
                                                                                                                                     */


	var kHas = ['canEditPosition', 'manageProjects', 'manageProjectGroups', 'manageCostCodes', 'viewMemberExpenses', 'lockPayPeriods', 'viewProjectExpenses', 'manageCompanySettings', 'manageEmployeeGroups', 'viewEquipmentStatus', 'viewEquipmentDetails', 'manageEquipment'];

	/**
  * `Components/Selectors/ListSelect`
  *
  * @class ListSelect
  * @namespace Components.Selectors
  * @extends Component
  */
	exports.default = Ember.Component.extend({
		access: Ember.inject.service('access'),

		classNames: ['list-select-menu'],

		forMember: null,
		filterItem: null,
		model: null,
		itemLabel: null,
		small: false,
		showEmpty: false,

		_model: null,

		init: function init() {
			this._super();
			this.setData();
		},


		showSelectMenu: Ember.computed('showEmpty', '_model.length', function () {
			if (this.get('showEmpty')) {
				return true;
			} else if (this.get('_model.length') > 0) {
				return true;
			}
			return false;
		}),

		setData: Ember.observer('model', 'model.length', 'access.auth.authMemberPosition.id', 'access.auth.organization.id', function () {
			var _this = this;

			var data = [];
			if (!Ember.isNone(this.get('model')) && !Ember.isNone(this.get('access.auth.authMemberPosition.id')) && !Ember.isNone(this.get('access.auth.organization.id'))) {
				this.get('model').forEach(function (item) {
					var canAdditem = true;
					if (!Ember.isNone(item.get('permissions'))) {
						if (!_this.hasPermission(item.get('permissions'))) {
							canAdditem = false;
						}
					}

					if (!Ember.isNone(item.get('filters'))) {
						if (_this.isFiltered(item.get('filters'))) {
							canAdditem = false;
						}
					}

					if (canAdditem) {
						data.push(_this.copyObject(item));
					}
				});
			}
			this.set('_model', data);
		}),

		copyObject: function copyObject(obj) {
			var newObj = Ember.Object.create();
			for (var key in obj) {
				if (obj.hasOwnProperty(key)) {
					newObj.set(key, Ember.get(obj, key));
				}
			}
			return newObj;
		},
		isFiltered: function isFiltered(filters) {
			var _this2 = this;

			(false && !(Ember.typeOf(filters) === 'array') && Ember.assert('The filters key must have an array as the value!', Ember.typeOf(filters) === 'array'));

			var isFiltered = false;
			filters.forEach(function (item) {
				var itemStr = item;
				var isNot = false;
				if (/^!/.test(item)) {
					isNot = true;
					itemStr = item.replace(/^!/, '');
				}

				var valuePath = 'filterItem.' + itemStr;
				if (!_this2.hasObserverFor(valuePath)) {
					_this2.addObserver(valuePath, _this2, function () {
						_this2.setData();
					});
				}

				var value = _this2.get(valuePath);
				if (isNot && value) {
					isFiltered = true;
				} else if (!isNot && !value) {
					isFiltered = true;
				}
			});
			return isFiltered;
		},
		hasPermission: function hasPermission(permissions) {
			var _this3 = this;

			(false && !(Ember.typeOf(permissions) === 'array') && Ember.assert('The permissions key must have an array as the value!', Ember.typeOf(permissions) === 'array'));

			var hasPermission = true;
			permissions.forEach(function (item) {
				if (!_this3.getPermision(item)) {
					hasPermission = false;
				}
			});
			return hasPermission;
		},
		getPermision: function getPermision(permission) {
			if (kCanTake.indexOf(permission) !== -1) {
				return this.get('access').canTakeActionFor(permission, this.get('forMember'));
			} else if (kHas.indexOf(permission) !== -1) {
				return this.get('access').hasPermissionFor(permission);
			} else if (permission === 'isNotSelf') {
				return this.get('access.auth.member.id') !== this.get('forMember.id');
			} else if (permission === 'isNotOwner') {
				return !this.get('access').isOwner(this.get('forMember.id'));
			} else if (permission === 'isSelf') {
				return this.get('access.auth.member.id') === this.get('forMember.id');
			} else if (permission === 'isOwner') {
				return this.get('access').isOwner(this.get('forMember.id'));
			} else if (permission.indexOf('features.') === 0) {
				return this.get(permission);
			} else {
				(false && !(false) && Ember.assert('You passed an unsupported permision check to list-select'));
			}
		},


		actions: {
			selectItem: function selectItem(item) {
				this.sendAction('onSelect', item, this.get('forMember'), this.get('filterItem'));
			}
		}
	});
});