define('busy-app/utils/models/dispatchers/time-entry', ['exports', 'busy-app/utils/models/dispatchers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		auth: Ember.inject.service(),

		findOpenEntry: function findOpenEntry(memberId) {
			var _this = this;

			return this.store.filter('time-entry').findOpenEntry(memberId).then(function (timeEntry) {
				return _this.store.filter('time-entry').getRelatedModels(timeEntry);
			});
		},
		findOpenEntrySocket: function findOpenEntrySocket(memberId) {
			return this.store.filter('time-entry').findOpenEntrySocket(memberId);
		},
		findByDateRange: function findByDateRange(start, end, memberId, projectId, costCodeId) {
			var _this2 = this;

			var timeOffPromise = Ember.RSVP.resolve([]);
			if ((Ember.isNone(projectId) || projectId === '_-NULL-_' || projectId === null) && (Ember.isNone(costCodeId) || costCodeId === '_-NULL-_' || costCodeId === null)) {
				timeOffPromise = this.store.filter('time-off').findAllByRange(start, end, memberId);
			}

			return Ember.RSVP.hash({
				timeEntries: this.store.filter('time-entry').findAllByRange(start, end, memberId, projectId, costCodeId),
				timeOffEntries: timeOffPromise,
				scopedGroupMembers: this.loadScopedGroupMembers()
			}).then(function (_ref) {
				var timeEntries = _ref.timeEntries,
				    timeOffEntries = _ref.timeOffEntries,
				    scopedGroupMembers = _ref.scopedGroupMembers;

				var members = [];
				var projects = [];
				var costCodes = [];
				var equipmentIds = [];
				var breaks = [];

				if (Ember.get(_this2, 'auth.useGroupPermissionScoping')) {
					var scopedGroupMemberIds = new Set(scopedGroupMembers.mapBy('id'));
					timeEntries = timeEntries.filter(function (item) {
						return scopedGroupMemberIds.has(Ember.get(item, 'memberId'));
					});
					timeOffEntries = timeOffEntries.filter(function (item) {
						return scopedGroupMemberIds.has(Ember.get(item, 'memberId'));
					});
				}

				timeEntries.forEach(function (item) {
					if (breaks.indexOf(item.id) === -1) {
						breaks.push(item.id);
					}

					if (members.indexOf(item.get('memberId')) === -1) {
						members.push(item.get('memberId'));
					}

					if (!Ember.isNone(item.get('projectId')) && projects.indexOf(item.get('projectId')) === -1) {
						projects.push(item.get('projectId'));
					}

					if (!Ember.isNone(item.get('costCodeId')) && costCodes.indexOf(item.get('costCodeId')) === -1) {
						costCodes.push(item.get('costCodeId'));
					}

					if (!Ember.isNone(item.get('equipmentId')) && equipmentIds.indexOf(item.get('equipmentId')) === -1) {
						equipmentIds.push(item.get('equipmentId'));
					}
				});

				timeOffEntries.forEach(function (entry) {
					if (members.indexOf(entry.get('memberId')) === -1) {
						members.push(entry.get('memberId'));
					}
				});

				return Ember.RSVP.hash({
					timeEntries: timeEntries,
					timeOffEntries: timeOffEntries,
					breaks: _this2.store.findWhereIn('time-entry-break', 'time_entry_id', breaks, { deleted_on: null }),
					members: _this2.store.findByIds('member', members),
					projects: _this2.store.filter('project').findFullProjects(projects),
					costCodes: _this2.store.findByIds('cost-code', costCodes),
					equipment: _this2.store.filter('equipment').getByIdsWithChildren(equipmentIds)
				}).then(function (data) {
					data.timeEntries.forEach(function (item) {
						if (!Ember.isNone(item.get('projectId'))) {
							var project = data.projects.findBy('id', item.get('projectId'));
							item.set('project', project);
						}

						if (!Ember.isNone(item.get('costCodeId'))) {
							var costCode = data.costCodes.findBy('id', item.get('costCodeId'));
							item.set('costCode', costCode);
						}

						if (!Ember.isNone(item.get('equipmentId'))) {
							var equipment = data.equipment.findBy('id', item.get('equipmentId'));
							item.set('equipment', equipment);
						}

						var member = data.members.findBy('id', item.get('memberId'));
						item.set('member', member);

						var breaks = data.breaks.filterBy('timeEntryId', item.id);
						item.set('timeEntryBreaks', breaks);
					});

					data.timeOffEntries.forEach(function (item) {
						var member = data.members.findBy('id', item.get('memberId'));
						item.set('member', member);
					});

					return { timeEntries: data.timeEntries, timeOffEntries: data.timeOffEntries };
				});
			});
		},
		loadScopedGroupMembers: function loadScopedGroupMembers() {
			if (!Ember.get(this, 'auth.useGroupPermissionScoping')) {
				return Ember.RSVP.resolve(null);
			}

			var authMember = Ember.get(this, 'auth.member');

			var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
			    id = _EmberGetProperties.id,
			    memberGroupId = _EmberGetProperties.memberGroupId;

			var query = {
				member_group_id: memberGroupId
			};

			if (Ember.isNone(memberGroupId)) {
				Ember.setProperties(query, { id: id });
			}

			return Ember.get(this, 'store').findAll('member', query);
		}
	});
});