define('busy-app/helpers/is-owner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    access: Ember.inject.service('access'),

    compute: function compute(params) {
      var forMember = params[0];

      if (!Ember.isNone(this.get('access.auth.authMemberPosition.id')) && !Ember.isNone(this.get('access.auth.organization.id'))) {
        return this.get('access').isOwner(forMember.id);
      }

      return false;
    },


    onUpdate: Ember.observer('access.auth.authMemberPosition.id', 'access.auth.organization.id', function () {
      this.recompute();
    })
  });
});