define('busy-app/components/charts/base-chart', ['exports', 'ember-cli-chartjs/components/ember-chart', 'ember-cli-chartjs/templates/components/ember-chart', '@busy-web/utils'], function (exports, _emberChart, _emberChart2, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Components
  *
  */
	var kColors = {
		blue: ['#2997A5', '#37C9DC', '#87DFEA', '#AFE9F1', '#D7F4F8', '#8E8E8E'],
		green: ['#007066', '#009588', '#33AAA0', '#66BFB8', '#99D5CF', '#8E8E8E']
	};

	/**
  * `Component/Charts/BaseChart`
  *
  * @class BaseChart
  * @namespace Components.Charts
  * @extends EmberCliChartJS.EmberChart
  */
	exports.default = _emberChart.default.extend({
		/**
   * default class name to wrap this component with
   *
   * @public
   * @property classNames
   * @type {array}
   */
		classNames: ['busy-chart'],
		classNameBindings: ['fullWidth:full-width'],

		/**
   * The layout to be used with this component
   *
   * @private
   * @property layout
   * @type {string}
   */
		layout: _emberChart2.default,

		/**
   * Ember injected service for auth
   *
   * @private
   * @property auth
   * @type {Services.Auth}
   */
		auth: Ember.inject.service('auth'),

		/**
   * The model array to pass to the chart as the
   * charts data
   *
   * @public
   * @property model
   * @type {DS.Model}
   */
		model: null,

		/**
   * The path in the model to find the value of the label
   *
   * @public
   * @property labelPath
   * @type {string}
   */
		labelPath: '',

		/**
   * The path in the model to find the value for the data
   *
   * @public
   * @property dataPath
   * @type {string}
   */
		dataPath: '',

		/**
   * The type of data to display in the tooltip
   *
   * this can be either `time` or 'currency` or `empty string`
   *
   * @public
   * @property [dataType='']
   * @type {string}
   */
		dataType: '',

		/**
   * This is a default color array type to use.
   *
   * If this is not set it will default to the blue color array
   *
   * @public
   * @property color
   * @type {string}
   */
		color: 'blue',

		/**
   * The max width of the chart to display
   *
   * @public
   * @property [width=400]
   * @type {number}
   */
		width: 400,

		/**
   * The max height of the chart to display
   *
   * @public
   * @property [height=400]
   * @type {number}
   */
		height: 400,

		modelMap: null,
		segmentShowing: 0,

		showLegend: true,
		showTooltips: true,
		cutoutPercentage: 0,
		unassignedProjects: false,

		otherText: (0, _utils.loc)('Other'),
		backText: (0, _utils.loc)('back'),

		fullWidth: false,

		/**
   * Override property to set the color array to display
   * in the chart.
   *
   * @public
   * @property colors
   * @type {array}
   */
		colors: Ember.computed('color', function () {
			return kColors[this.get('color')] || [];
		}),

		/**
   * Override property to pass the options into the parent class
   *
   * @public
   * @property options
   * @type {object}
   */
		options: Ember.computed('showTooltips', 'percentageInnerCutout', function () {
			// return the options setup for this chart.
			var options = {
				legend: {
					labels: {
						fontFamily: "proxima"
					},
					display: this.getWithDefault('showLegend', true)
				},
				tooltips: {
					enabled: this.get('showTooltips'),
					titleFontFamily: "proxima",
					bodyFontFamily: "proxima",
					footerFontFamily: "proxima"
				},
				responsive: this.getWithDefault('responsive', true),
				maintainAspectRatio: this.getWithDefault('maintainAspectRatio', false),
				fullWidth: this.getWithDefault('fullWidth', false)
			};

			this.addOptions(options);
			return options;
		}),

		addOptions: function addOptions() {
			return;
		},


		/**
   * Override of inherited method to init
   * this class
   *
   * @public
   * @method init
   */
		init: function init() {
			// trigger a load of the org info model
			// to get the currencySymbol.
			this.get('auth.currencySymbol');

			this._super.apply(this, arguments);
		},


		/**
   * Override of inherited method to set the
   * information that shows up in the tooltip
   *
   * @public
   * @method tooltip
   * @param label {string}
   * @param value {mixed}
   * @return {string}
   */
		tooltip: function tooltip(label, value) {
			if (this.get('dataType') === 'time') {
				// if dataType is set to the string `time` then return a time formated string
				return label + ' - ' + _utils.Time.convertSecondsString(value, 10, true, false, true);
			} else if (this.get('dataType') === 'currency') {
				// if dataType is set to the string currency return a currency formated string
				return label + ' - ' + this.get('auth.currencySymbol') + Number(value.toFixed(2).toLocaleString());
			} else {
				// otherwise return the parent class default.
				return this._super.apply(this, arguments);
			}
		},
		getWithDefault: function getWithDefault(key, defaultValue) {
			var val = this.get(key);
			if (Ember.isNone(val)) {
				val = defaultValue;
			}
			return val;
		},
		getOptionAsArray: function getOptionAsArray(key, defaultValue) {
			var item = this.getWithDefault(key, defaultValue) || [];
			if (typeof item === 'string') {
				item = item.split(',');
			}
			return item;
		}
	});
});