define('busy-app/components/employees/overtime-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['v-employees-employee-dialog-current-overtime'],

		store: Ember.inject.service('store'),
		analytics: Ember.inject.service('analytics'),

		showCustom: false,
		member: null,

		mindate: null,
		maxdate: null,
		startDateTime: null,

		init: function init() {
			this._super();

			this.set('overtimeChoice', [Ember.Object.create({ key: 'same-as-org', value: (0, _utils.loc)('Follow Company Settings'), _selected: true }), Ember.Object.create({ key: 'standard', value: (0, _utils.loc)('Standard') }), Ember.Object.create({ key: 'california', value: (0, _utils.loc)('California') }), Ember.Object.create({ key: 'custom', value: (0, _utils.loc)('Custom') })]);

			this.set('overtimeIncreasesSaturday', this.getOverTimeIncreases());
			this.set('overtimeIncreasesSunday', this.getOverTimeIncreases());

			this.set('booleanList', [Ember.Object.create({ key: true, value: 'Yes', _selected: true }), Ember.Object.create({ key: false, value: 'No' })]);

			this.set('customOvertime', Ember.Object.create({
				weeklyOvertime: true,
				dailyOvertime: true,
				dailyOvertimeTwo: true,
				seventhDayOvertime: true,
				seventhDayOvertimeTwo: true,
				saturdayPayRate: true,
				sundayPayRate: true
			}));

			this.set('mindate', _utils.Time.date().add(1, 'days').startOf('day').unix());
			this.set('maxdate', _utils.Time.date().startOf('day').add(6, 'months').unix());

			var date = _utils.Time.date().add(1, 'days').startOf('day').unix();
			this.set('startDateTime', date);

			var overtime = this.get('store').createRecord('member-overtime-period', {
				changeDate: date,
				memberId: this.get('member.id'),
				adhereToOrg: true
			});

			this.set('overtimeRules', overtime);

			if (this.get('features.locks')) {
				_utils.Assert.isObject(this.get('member'));
				this.get('member.memberLock');
			}
		},
		getOverTimeIncreases: function getOverTimeIncreases() {
			return [Ember.Object.create({ key: 1.5, value: '1.5' }), Ember.Object.create({ key: 2, value: '2.0' })];
		},
		disableOptions: function disableOptions(customOptions, overtime) {
			var overtimeRules = this.get('store').createRecord('member-overtime-period', {
				changeDate: overtime.get('changeDate'),
				memberId: overtime.get('memberId'),
				adhereToOrg: overtime.get('adhereToOrg'),
				weeklyOvertime: overtime.get('weeklyOvertime'),
				dailyOvertime: overtime.get('dailyOvertime'),
				dailyDoubletime: overtime.get('dailyDoubletime'),
				seventhDayOvertime: overtime.get('seventhDayOvertime'),
				seventhDayDoubletime: overtime.get('seventhDayDoubletime'),
				saturdayMultiplier: overtime.get('saturdayMultiplier'),
				sundayMultiplier: overtime.get('sundayMultiplier')
			});

			if (!customOptions.get('weeklyOvertime')) {
				overtimeRules.set('weeklyOvertime', null);
			}

			if (!customOptions.get('dailyOvertime')) {
				overtimeRules.set('dailyOvertime', null);
			}

			if (!customOptions.get('dailyOvertimeTwo')) {
				overtimeRules.set('dailyDoubletime', null);
			}

			if (!customOptions.get('seventhDayOvertime')) {
				overtimeRules.set('seventhDayOvertime', false);
			}

			if (!customOptions.get('seventhDayOvertimeTwo')) {
				overtimeRules.set('seventhDayDoubletime', null);
			}

			if (!customOptions.get('saturdayPayRate')) {
				overtimeRules.set('saturdayMultiplier', null);
			}

			if (!customOptions.get('sundayPayRate')) {
				overtimeRules.set('sundayMultiplier', null);
			}
			return overtimeRules;
		},


		/**
   * If `feature.locks` is enabled and the member has a lock date set
   *
   * @property hasLockDate
   * @type {Boolean}
   * @default false
   * @static
   *
   */
		hasLockDate: Ember.computed('member.memberLock.effectiveDate', function () {
			var lockDate = this.get('member.memberLock.effectiveDate');
			return this.get('features.locks') && !Ember.isNone(lockDate);
		}),

		/**
   * Is the currently selected `startTime` before the lock date
   *
   * @property isSelectedDateLocked
   * @type {Boolean}
   * @static
   *
   */
		isSelectedDateLocked: Ember.computed('features.locks', 'hasLockDate', 'member.memberLock.effectiveDate', 'startDateTime', function () {
			return this.get('features.locks') && this.get('hasLockDate') && this.get('member').isDateLocked(this.get('startDateTime'));
		}),

		actions: {
			selectAction: function selectAction(action) {
				if (action.get('key') === 'custom') {
					this.set('showCustom', true);
					this.set('showSelectDate', true);

					this.set('overtimeRules.adhereToOrg', false);
					this.set('overtimeRules.weeklyOvertime', 40);
					this.set('overtimeRules.dailyOvertime', 8);
					this.set('overtimeRules.dailyDoubletime', 12);
					this.set('overtimeRules.seventhDayOvertime', true);
					this.set('overtimeRules.seventhDayDoubletime', 10);
					this.set('overtimeRules.saturdayMultiplier', 1.5);
					this.set('overtimeRules.sundayMultiplier', 1.5);
				} else if (action.get('key') === 'standard') {
					this.set('showCustom', false);
					this.set('showSelectDate', true);

					this.set('overtimeRules.adhereToOrg', false);
					this.set('overtimeRules.weeklyOvertime', 40);
					this.set('overtimeRules.dailyOvertime', null);
					this.set('overtimeRules.dailyDoubletime', null);
					this.set('overtimeRules.seventhDayOvertime', false);
					this.set('overtimeRules.seventhDayDoubletime', null);
					this.set('overtimeRules.saturdayMultiplier', null);
					this.set('overtimeRules.sundayMultiplier', null);
				} else if (action.get('key') === 'california') {
					this.set('showCustom', false);
					this.set('showSelectDate', true);

					this.set('overtimeRules.adhereToOrg', false);
					this.set('overtimeRules.weeklyOvertime', 40);
					this.set('overtimeRules.dailyOvertime', 8);
					this.set('overtimeRules.dailyDoubletime', 12);
					this.set('overtimeRules.seventhDayOvertime', true);
					this.set('overtimeRules.seventhDayDoubletime', 8);
					this.set('overtimeRules.saturdayMultiplier', null);
					this.set('overtimeRules.sundayMultiplier', null);
				} else if (action.get('key') === 'same-as-org') {
					this.set('showCustom', false);
					this.set('showSelectDate', false);

					this.set('overtimeRules.adhereToOrg', true);
					this.set('overtimeRules.weeklyOvertime', null);
					this.set('overtimeRules.dailyOvertime', null);
					this.set('overtimeRules.dailyDoubletime', null);
					this.set('overtimeRules.seventhDayOvertime', null);
					this.set('overtimeRules.seventhDayDoubletime', null);
					this.set('overtimeRules.saturdayMultiplier', null);
					this.set('overtimeRules.sundayMultiplier', null);
				}
			},
			saveOvertime: function saveOvertime() {
				var _this = this;

				var overtime = this.get('overtimeRules');
				overtime.set('changeDate', _utils.Time.date(this.get('startDateTime')).startOf('day').unix());

				if (this.get('isSelectedDateLocked')) {
					this.get('analytics').addEvent(84);
					return Ember.RSVP.reject((0, _utils.loc)("Cannot set an effective date older than the lock date"));
				}

				if (this.get('showCustom')) {
					var options = this.get('customOvertime');
					overtime = this.disableOptions(options, overtime);
				}

				return overtime.save().then(function () {
					_this.get('member._overtimePeriods.content').update();
					return true;
				}).catch(function (result) {
					result = Ember.Object.create(result);
					var err = result.get('errors.0');

					if (err === 801) {
						return Ember.RSVP.reject((0, _utils.loc)("You can't save an overtime period for more than 90 days ago."));
					} else if (err === 902) {
						return Ember.RSVP.reject((0, _utils.loc)("Daily Doubletime must be greater than daily overtime!"));
					} else if (err === 903) {
						return Ember.RSVP.reject((0, _utils.loc)("Seventh Day Doubletime must be less than daily doubletime!"));
					} else if (err === 904) {
						return Ember.RSVP.reject((0, _utils.loc)("The provided Saturday Multiplier is not a valid option"));
					} else if (err === 905) {
						return Ember.RSVP.reject((0, _utils.loc)("The provided Sunday Multiplier is not a valid option"));
					} else {
						return Ember.RSVP.reject((0, _utils.loc)("Something went wrong, please try again later."));
					}
				});
			},
			close: function close() {
				this.sendAction('onClose');
			},
			sundayMultiplier: function sundayMultiplier(item) {
				this.set('overtimeRules.sundayMultiplier', item.key);
			},
			saturdayMultiplier: function saturdayMultiplier(item) {
				this.set('overtimeRules.saturdayMultiplier', item.key);
			},
			seventhDayOvertime: function seventhDayOvertime(item) {
				this.set('overtimeRules.seventhDayOvertime', item.key);
			}
		}
	});
});