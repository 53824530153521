define("busy-app/components/busy-nav", ["exports", "@busy-web/utils", "busy-app/components/busy-notification-bar", "busy-app/utils/settings"], function (exports, _utils, _busyNotificationBar, _settings) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kEmailVerificationGracePeriodInHours = 4;

	/**
  * `Component/BusyNav`
  *
  * @class BusyNav
  * @namespace Components
  * @extends Component
  * @uses Services.Store
  * @uses Services.Analytics
  * @uses Services.Auth
  * @uses Services.Session
  * @uses Services.Access
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		// classNames: ["busy-nav"],

		eventEmitter: Ember.inject.service(),
		dataStore: Ember.inject.service("data-store"),
		store: Ember.inject.service("store"),
		analytics: Ember.inject.service("analytics"),
		auth: Ember.inject.service("auth"),
		session: Ember.inject.service("session"),
		access: Ember.inject.service("access"),
		subscription: Ember.inject.service("subscription"),
		features: Ember.inject.service("features"),
		router: Ember.inject.service("router"),
		interop: Ember.inject.service("interop"),
		graylog: Ember.inject.service("graylog"),

		isReady: false,

		showCover: false,

		isIndex: true,
		activeLink: "index",

		showCostCodesSplashScreen: false,
		showEquipmentSplashScreen: false,

		init: function init() {
			var _this = this;

			this._super();

			this.set("isReady", true);
			if (window.BusyApp._application !== undefined) {
				window.BusyApp._application.set("container", this);
			}

			if (!/testing/.test(Ember.$("body").attr("class"))) {
				var location = window.location.pathname.replace(/^\/([^/]*)[#]?[\s\S]*$/, "$1");
				this.setActiveMenu(location);

				this.get("eventEmitter").on("router", "transition", function (route) {
					return _this.setActiveMenu(route);
				});
			}
			var hideSideNav = this.get("features.reactMainApp");
			if (hideSideNav) {
				this.set("classNames", ["busy-nav", "hidden"]);
			} else {
				this.set("classNames", ["busy-nav"]);
			}

			if (window.BusyApp.isMobile) {
				Ember.$("body").addClass("mobile");
			}
		},


		showIntercom: Ember.computed("features.integrations/analytics", function () {
			return this.get("features.integrations/analytics");
		}),

		showReceptive: Ember.computed("features.integrations/receptive", function () {
			return this.get("features.integrations/receptive");
		}),

		equipmentUrl: Ember.computed("session.isAuthenticated", function () {
			return _settings.default.get("equipment_url") + "?authorize=" + window.btoa(this.get("session.data.authenticated.token") + ":" + this.get("session.data.authenticated.id"));
		}),

		/**
   * check for if the member should be able to see the employees section
   *
   * @private
   * @property showEmployees
   * @type boolean
   */
		showEmployees: Ember.computed("auth.authMemberPosition.id", function () {
			// return true if the authMemberPosition is set and manageEmployees or manageTime or timeEvents or manageEmployeeGroups is true.
			return !Ember.isNone(this.get("auth.authMemberPosition.id")) && (this.get("auth.authMemberPosition.manageEmployees") > 1 || // can manage employee models
			this.get("auth.authMemberPosition.manageTime") > 1 || // can manage time entries
			this.get("auth.authMemberPosition.timeEvents") > 1 || // can manage time events (clock in, clock out, ect.)
			this.get("auth.authMemberPosition.manageEmployeeGroups")); // can manage employee groups
		}),

		/**
   * The list of organizations this member is authenticated with
   *
   * @private
   * @property organizationList
   * @type array
   */
		organizationList: Ember.computed("accountList.[]", function () {
			// if the accountList is set the return it otherwise return and empty array.
			if (!Ember.isEmpty(this.get("accountList"))) {
				return this.get("accountList");
			}
			return [];
		}),

		userImage: Ember.computed("auth.member.imageThumbUrl", function () {
			var image = this.get("auth.member.imageThumbUrl");
			if (Ember.isNone(image)) {
				image = "/images/imageplaceholder.png";
			}
			return Ember.String.htmlSafe("background-image: url(" + image + ");");
		}),

		accountSettingsBadge: Ember.computed("needsEmailVerification", function () {
			var badgeCount = 0;

			if (this.get("needsEmailVerification")) {
				badgeCount++;
			}

			return badgeCount;
		}),

		companySettingsBadge: Ember.computed("showFreeTrialExpirationWarning", "subscription.subscriptionStatus.{isPastDue,isLockout}", function () {
			var badgeCount = 0;

			// show warning if free trial is expired and the warning hasn't been dismissed previously
			if (!(0, _busyNotificationBar.isDismissed)("free-trial-expired")) {
				badgeCount += this.get("showFreeTrialExpirationWarning") ? 1 : 0;
			}

			// show warning if account is past due
			badgeCount += this.get("subscription.subscriptionStatus.isPastDue") ? 1 : 0;

			badgeCount += this.get("subscription.subscriptionStatus.isLockout") ? 1 : 0;

			return badgeCount;
		}),

		needsEmailVerification: Ember.computed("auth.member.{id,verifiedEmail}", "subscription.subscriptionStatus.createdOn", function () {
			var isOwner = this.get("access").isOwner(this.get("auth.member.id"));

			if (isOwner && !this.get("auth.member.verifiedEmail") && this.get("auth.member.emailVerificationRequired") && !Ember.isNone(this.get("subscription.subscriptionStatus.createdOn"))) {
				// subscriptionStatus.createdOn is a UTC date
				var signupDate = _utils.Time.date(this.get("subscription.subscriptionStatus.createdOn"));
				var now = _utils.Time.date(_utils.Time.utcTimestamp());
				var gracePeriodEnd = signupDate.clone().add(kEmailVerificationGracePeriodInHours, "hours");

				return now.isAfter(gracePeriodEnd);
			} else {
				return false;
			}
		}),

		showFreeTrialExpirationWarning: Ember.computed("subscription.subscriptionStatus.{isTrialEnded,userCanceled}", "isFreeTrialExpirationWarningDismissed", function () {
			return !this.get("isFreeTrialExpirationWarningDismissed") && !this.get("subscription.subscriptionStatus.userCanceled") && this.get("subscription.subscriptionStatus.isTrialEnded");
		}),

		// Appears once a trial has started. Remains even after the trial expires.
		// Dissappears if the user is on free (unexpired), is on Pro, or has submitted a payment method.
		showTrialRemaining: Ember.computed("subscription.{isFreeTrial,hasPaymentMethod}", function () {
			// disable this feature
			return false;

			// if (this.get('subscription.isFreeTrial') && !this.get('subscription.hasPaymentMethod')) {
			// 	return true;
			// } else {
			// 	return false;
			// }
		}),

		showUpgradeToProFooter: Ember.computed("subscription.subscriptionStatus.{isFreeAccount,isFreeTrial,isFreeTrialUpgraded}", function () {
			return this.get("subscription.subscriptionStatus.isFreeAccount") || this.get("subscription.subscriptionStatus.isFreeTrial") && !this.get("subscription.subscriptionStatus.isFreeTrialUpgraded");
		}),

		// show the `busy-nav-sticky-footer` section and add a `show-sticky-footer` to the main aside
		showStickyFooter: Ember.computed.or("showTrialRemaining", "showUpgradeToProFooter"),

		showCostCodesNavItem: Ember.computed("features.costCodes", "auth.{member.positionId,organization.trackCostCode}", function () {
			this.get("auth.member.positionId");

			return this.showNavItemWithSplash("costCodes", "trackCostCode", "manageCostCodes");
		}),

		showEquipmentNavItem: Ember.computed("features.equipment", "auth.{member.positionId,organization.trackEquipment}", function () {
			this.get("auth.member.positionId");

			return this.showNavItemWithSplash("equipment", "trackEquipment", "manageEquipment");
		}),

		showReportsNavItem: Ember.computed("auth.member.positionId", function () {
			var access = this.get("access");
			if (access.hasPermissionFor("viewAllActivityReport") || access.hasPermissionFor("viewProjectExpenses")) {
				return true;
			}
			return false;
		}),

		// all pro users should be able to see the scheduling section
		showSchedulingNavItem: Ember.computed.alias("features.scheduling"),

		showNavItemWithSplash: function showNavItemWithSplash(featureKey, settingKey, permissionKey) {
			var access = this.get("access");

			if (!this.get("features").isEnabled(featureKey)) {
				// never show if the feature is disabled
				return false;
			} else {
				var canEnableFeature = access.hasPermissionFor(permissionKey) && access.hasPermissionFor("manageCompanySettings");
				var canViewFeature = access.hasPermissionFor(permissionKey) && this.get("auth.organization").get(settingKey);

				return canEnableFeature || canViewFeature;
			}
		},
		createAccountList: function createAccountList() {
			var accounts = this.get("dataStore").getAuthAccounts();
			this.set("accountList", accounts.sortBy("organizationName"));
		},


		/**
   * Change a view from one section to another.
   *
   * This handles the transition with the routes and setting the correct
   * boolean values to highlight the section that is active.
   *
   * @public
   * @method changeView
   * @param [section='index'] {string} The section to transition to.
   * @param [noTransition=false] {boolean} True to stop a route transition from triggering.
   * @param [target] {string} a URL hash to navigate to after the route transition completes, for example, to get inside a tab `/company-settings?bc_tab=subscription`
   */
		changeView: function changeView() {
			var section = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "index";
			var noTransition = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
			var target = arguments[2];

			// set isViewChanging flag
			this.set("isViewChanging", true);
			var queryParams = {};

			// if not noTransition then transitionTo the new section.
			if (!noTransition) {
				// track the section change click
				this.trackClick(section);

				if (section === "index" || section === "company-settings") {
					queryParams = { bc_tab: null };
				}

				if (!Ember.isNone(target)) {
					queryParams = { bc_tab: target };
					//this.set('targetTab', target);
				}

				var currentRoute = this.get("router.currentRouteName");
				if (currentRoute === section) {
					return this.get("router").replaceWith(section, { queryParams: queryParams });
				}

				// lookup the main app route and transitionTo the new section.
				return this.get("router").transitionTo(section, { queryParams: queryParams });
			} else {
				// set the isViewChanging flag to false.
				this.set("isViewChanging", false);
			}

			// close the menu
			Ember.$("body").removeClass("left-nav-open");
		},
		setActiveMenu: function setActiveMenu(path) {
			var section = path.split(".")[0];
			var places = ["index", "employees", "project", "cost-code", "report", "company-settings", "account-settings", "scheduling", "map"];

			// if (!isNone(this.get('targetTab'))) {
			//   window.location.hash = `#${this.get('targetTab')}`;
			// }

			if (places.indexOf(section) !== -1) {
				// save the original section string.
				var activeLink = section;

				// set all active sections to false.
				this.setProperties({
					isIndex: false,
					isEmployees: false,
					isProject: false,
					isConnection: false,
					isReport: false,
					isCompanySettings: false,
					isAccountSettings: false,
					isCostCode: false,
					isCompanySettingsSubscriptionEdit: false,
					isScheduling: false,
					isMap: false
				});

				// set the new active section to true.
				this.set("is" + section.classify(), true);

				// save the section to the activeLink property
				this.set("activeLink", activeLink);
			}

			// set the isViewChanging flag to false.
			this.set("isViewChanging", false);
		},
		logout: function logout() {
			this.send("closeInteropWindows");
			return this.get("auth").invalidateSession();
		},
		trackClick: function trackClick(section) {
			// send tracking info for subscription-edit section only.
			if (section === "company-settings.subscription-edit") {
				// get browser info.
				var browser = _utils.Browser.detect();

				// send analytics event
				this.get("analytics").addEvent(60, {
					os: browser.type,
					os_version: browser.version,
					device: browser.osType + " " + browser.osVersion + " - " + browser.type + " " + browser.version,
					element: "Upgrade:LeftNav"
				});
			}
			//graylog tracking
			if (section === "cost-code") {
				this.graylogViewedCostCodeSection();
			} else if (section === "project") {
				this.graylogViewedProjectSection();
			} else if (section === "employees") {
				this.graylogViewedEmployeeSection();
			} else if (section === "map") {
				this.graylogViewedUniversalMap();
			} else if (section === "scheduling") {
				this.graylogViewedAdmingScheduling();
			}
		},


		startTouch: null,
		endTouch: null,
		touchStart: function touchStart(event) {
			this.set("startTouch", event.touches[0]);
			this.set("startEvent", event);
		},
		touchMove: function touchMove(event) {
			this.set("endTouch", event.touches[0]);
		},
		touchEnd: function touchEnd() {
			var action = {};
			var start = this.get("startTouch");
			var end = this.get("endTouch");

			if (!Ember.isNone(start) && !Ember.isNone(end)) {
				var xChange = Math.abs(end.pageX - start.pageX);
				var yChange = Math.abs(end.pageY - start.pageY);
				var deviation = (xChange + yChange) / 2;

				if (xChange > deviation) {
					if (end.pageX > start.pageX) {
						action.action = "slide-right";
					} else if (start.pageX > end.pageX) {
						action.action = "slide-left";
					} else {
						action.action = "slide";
					}

					action.start = start.pageX;
					action.end = end.pageX;
					action.target = start.target;
				} else if (yChange > deviation) {
					if (end.pageY > start.pageY) {
						action.action = "slide-down";
					} else if (start.pageY > end.pageY) {
						action.action = "slide-up";
					} else {
						action.action = "slide";
					}

					action.start = start.pageY;
					action.end = end.pageY;
					action.target = start.target;
				}
			} else if (!Ember.isNone(start)) {
				action.action = "touch";
				action.start = start.pageX;
				action.target = start.target;
			}
			this.evalTouch(action);
			this.set("startTouch", null);
			this.set("endTouch", null);
			this.set("startEvent", null);
		},
		evalTouch: function evalTouch(item) {
			if (Ember.$("body").hasClass("left-nav-open")) {
				if (item.action === "slide-left") {
					Ember.$("body").removeClass("left-nav-open");
				}
			} else {
				if (item.action === "slide-right") {
					if (item.start < 80) {
						Ember.$("body").addClass("left-nav-open");
					}
				}
			}
		},
		openReactApp: function openReactApp(path, customParameters, event) {
			return Ember.get(this, "interop").openReactApp(path, customParameters, event);
		},
		openEquipmentApp: function openEquipmentApp(event) {
			return Ember.get(this, "interop").openUrl(this.get("equipmentUrl"), event, {
				newWindow: true
			});
		},


		/* Graylog events */

		graylogViewedEmployeeSection: function graylogViewedEmployeeSection() {
			this.get("graylog").sendEvent({
				short_message: "Viewed Employee Section",
				full_message: "Viewed Employee Section",
				level: 6,
				_member_id: this.get("auth.memberId"),
				_member_position_id: this.get("auth.member.positionId")
			});
		},
		graylogViewedProjectSection: function graylogViewedProjectSection() {
			this.get("graylog").sendEvent({
				short_message: "Viewed Project Section",
				full_message: "Viewed Project Section",
				level: 6,
				_member_id: this.get("auth.memberId"),
				_member_position_id: this.get("auth.member.positionId")
			});
		},
		graylogViewedCostCodeSection: function graylogViewedCostCodeSection() {
			this.get("graylog").sendEvent({
				short_message: "Viewed Cost Code Section",
				full_message: "Viewed Cost Code Section",
				level: 6,
				_member_id: this.get("auth.memberId"),
				_member_position_id: this.get("auth.member.positionId")
			});
		},
		graylogViewedUniversalMap: function graylogViewedUniversalMap() {
			this.get("graylog").sendEvent({
				short_message: "Viewed Universal Map",
				full_message: "Viewed Universal Map",
				level: 6,
				_member_id: this.get("auth.memberId"),
				_member_position_id: this.get("auth.member.positionId")
			});
		},
		graylogViewedAdmingScheduling: function graylogViewedAdmingScheduling() {
			this.get("graylog").sendEvent({
				short_message: "Viewed Adming Scheduling",
				full_message: "Viewed Adming Scheduling",
				level: 6,
				_member_id: this.get("auth.memberId"),
				_member_position_id: this.get("auth.member.positionId")
			});
		},
		graylogAccessedPhotosAndNotes: function graylogAccessedPhotosAndNotes() {
			this.get("graylog").sendEvent({
				short_message: "Accessed Photos And Notes",
				full_message: "Accessed Photos And Notes",
				level: 6,
				_member_id: this.get("auth.memberId"),
				_member_position_id: this.get("auth.member.positionId"),
				_view_type: "side_nav"
			});
		},


		actions: {
			changeViewAction: function changeViewAction(section, noTransition) {
				if (!this.get("isViewChanging")) {
					// toggle the organization picker closed
					if (this.get("isOpen")) {
						this.set("isOpen", false);
					}
					this.send("closeSideMenu");
					return this.changeView(section, noTransition);
				}
			},
			logoutAction: function logoutAction() {
				this.logout.apply(this, arguments);
			},
			openWorkspace: function openWorkspace() {
				this.createAccountList();
				// open organization picker
				this.set("isOpen", !this.get("isOpen"));
			},
			toggleAccountAction: function toggleAccountAction() {
				this.get("router").transitionTo("account-settings");
			},
			closeSideMenu: function closeSideMenu() {
				// close side menu
				Ember.$("body").removeClass("left-nav-open");
			},
			addAccount: function addAccount() {
				this.get("dataStore").suspendAuth();
			},
			useAccount: function useAccount(org) {
				this.get("dataStore").switchAuth(org.id);
				window.location = "/";
			},
			openHelpDialog: function openHelpDialog() {
				this.set("showHelpDialog", true);
			},
			closeHelpDialog: function closeHelpDialog() {
				this.set("showHelpDialog", false);
			},
			openIntercom: function openIntercom() {
				// hide the help dialog
				this.set("showHelpDialog", false);
				this.get("analytics").openIntercom();
			},
			openFaq: function openFaq() {
				this.set("showHelpDialog", false);
				window.open("http://support.busybusy.com/");
			},
			openCostCodes: function openCostCodes() {
				if (this.get("auth.organization.trackCostCode")) {
					this.send("changeViewAction", "cost-code");
				} else {
					this.send("openCostCodesSplashScreen");
				}
			},
			openCostCodesSplashScreen: function openCostCodesSplashScreen() {
				this.set("showCostCodesSplashScreen", true);
			},
			closeCostCodesSplashScreen: function closeCostCodesSplashScreen() {
				this.set("showCostCodesSplashScreen", false);
			},
			openEquipment: function openEquipment(event) {
				if (this.get("auth.organization.trackEquipment")) {
					this.openEquipmentApp(event);
				} else {
					this.send("openEquipmentSplashScreen");
				}
			},
			openEquipmentSplashScreen: function openEquipmentSplashScreen() {
				this.set("showEquipmentSplashScreen", true);
			},
			closeEquipmentSplashScreen: function closeEquipmentSplashScreen() {
				this.set("showEquipmentSplashScreen", false);
			},


			// usage: <a onClick={{action 'openReactApp' 'media' (hash) bubbles=false allowedKeys="command control"}} />
			openReactApp: function openReactApp() {
				var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
				var customParameters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
				var event = arguments[2];

				if (path === "photos") {
					this.graylogAccessedPhotosAndNotes();
				} else if (path === "scheduling") {
					this.graylogViewedAdmingScheduling();
				}

				// close the menu
				Ember.$("body").removeClass("left-nav-open");

				return this.openReactApp(path, customParameters, event);
			},
			openSubscriptionDetails: function openSubscriptionDetails() {
				return this.changeView("company-settings", false, "subscription");
			},
			closeInteropWindows: function closeInteropWindows() {
				Ember.get(this, "interop").closeWindows();
			}
		}
	});
});