define('busy-app/components/company/permission-boxes', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	/**
  * @module Components
  *
  */
	var kState = {
		_self: 1,
		_lower: 2,
		_same: 4,
		_higher: 8
	};

	/**
  *
  */
	exports.default = Ember.Component.extend({
		value: null,

		group: null,

		_self: false,
		_lower: false,
		_same: false,
		_higher: false,

		disabled: false,
		disableSelf: false,
		disableLower: false,
		disableSame: false,
		disableHigher: false,

		init: function init() {
			this._super();

			// generate group name
			if (Ember.isNone(this.get('group'))) {
				this.set('group', _utils.UUID.generate());
			}

			// setup boxes
			this.setupCheckBoxes(this.get('value'));
		},


		groupName: Ember.computed('group', function () {
			return 'permission-boxes-' + this.get('group');
		}),

		valueChanged: Ember.observer('value', function () {
			if (!Ember.isNone(this.get('value'))) {
				this.setupCheckBoxes(this.get('value'));
			}
		}),

		disableSelfBool: Ember.computed('disableSelf', 'disabled', function () {
			return this.get('disableSelf') || this.get('disabled');
		}),

		disableLowerBool: Ember.computed('disableLower', 'disabled', function () {
			return this.get('disableLower') || this.get('disabled');
		}),

		disableSameBool: Ember.computed('disableSame', 'disabled', function () {
			return this.get('disableSame') || this.get('disabled');
		}),

		disableHigherBool: Ember.computed('disableHigher', 'disabled', function () {
			return this.get('disableHigher') || this.get('disabled');
		}),

		_selfUpdate: Ember.observer('_self', 'disableSelfBool', function () {
			this.toggleFlag('_self');
		}),

		_lowerUpdate: Ember.observer('_lower', 'disableLowerBool', function () {
			this.toggleFlag('_lower');
		}),

		_sameUpdate: Ember.observer('_same', 'disableSameBool', function () {
			this.toggleFlag('_same');
		}),

		_higherUpdate: Ember.observer('_higher', 'disableHigherBool', function () {
			this.toggleFlag('_higher');
		}),

		toggleFlag: function toggleFlag(key) {
			var value = this.get('value');

			var flagValue = kState[key];
			var isEnabled = this.get(key);

			value = isEnabled ? value | flagValue : // add to bitmask      (1 | 4 = 5, 1 | 5 = 5)
			value & ~flagValue; // remove from bitmask (5 | ~1 = 4, 5 | ~2 = 5)

			this.set('value', value);
		},
		setupCheckBoxes: function setupCheckBoxes() {
			var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

			this.setProperties({
				_self: !!(value & kState._self),
				_lower: !!(value & kState._lower),
				_same: !!(value & kState._same),
				_higher: !!(value & kState._higher)
			});
		}
	});
});