define('busy-app/services/validation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		stripe: Ember.inject.service('stripe'), // uses Stripe.js v2 - https://stripe.com/docs/stripe.js/v2


		/**
   * @public
   * @method isInvalidCardNumber
   * @param cardNumber {string}
   * @return {boolean}
   */
		isInvalidCardNumber: function isInvalidCardNumber(cardNumber) {
			if (Ember.isEmpty(cardNumber)) {
				return true;
			} else if (!this.get('stripe').card.validateCardNumber(cardNumber)) {
				return true;
			}

			return false;
		},


		/**
   * @public
   * @method isInvalidCardDate
   * @param cardMonth {number}
   * @param cardYear {number}
   * @return {boolean}
   */
		isInvalidCardDate: function isInvalidCardDate(cardMonth, cardYear) {
			if (Ember.isEmpty(cardMonth) || Ember.isEmpty(cardYear)) {
				return true;
			} else if (!this.get('stripe').card.validateExpiry(cardMonth, cardYear)) {
				return true;
			} else {
				return false;
			}
		},


		/**
   * @public
   * @method isInvalidCardCVC
   * @param cardCVC {string}
   * @return {boolean}
   */
		isInvalidCardCVC: function isInvalidCardCVC(cardCVC) {
			if (Ember.isEmpty(cardCVC)) {
				return true;
			} else if (!this.get('stripe').card.validateCVC(cardCVC)) {
				return true;
			} else {
				return false;
			}
		}
	});
});