define('busy-app/controllers/time-card/pay-period', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['start', 'end', 'member_id'],

		access: Ember.inject.service('access'),
		auth: Ember.inject.service('auth'),
		signature: Ember.inject.service('signature'),
		features: Ember.inject.service('features'),
		payPeriod: Ember.inject.service('pay-period'),

		start: null,
		end: null,
		member_id: null,

		member: null,
		dirtyFlag: null,

		dayEntries: null,
		showNewTimeEntryDialog: false,
		showNewTimeOffDialog: false,

		debugKey: 'pay-period-controller',

		init: function init() {
			this._super.apply(this, arguments);
			this.set('debugKey', this.get('debugKey') + '-' + Math.random());
			this.getMember();
		},


		isTooOldToEdit: Ember.computed('start', function () {
			var start = _utils.Time.date().unix();
			if (!Ember.isNone(this.get('start'))) {
				start = _utils.Time.date(this.get('start')).unix();
			}

			var minDate = _utils.Time.date().subtract(89, 'days').unix();
			return start <= minDate;
		}),

		selectMenu: Ember.computed('access.auth.authMemberPosition.id', 'member.id', 'isTooOldToEdit', function () {
			var canTakeAction = this.get('access').canTakeActionFor('manageTimeEntries', this.get('member'));
			if (!this.get('isTooOldToEdit') && !Ember.isNone(this.get('member.id')) && !Ember.isNone(this.get('access.auth.authMemberPosition.id')) && canTakeAction) {
				return [Ember.Object.create({ key: 'new-time-entry', value: (0, _utils.loc)('New Time Entry') }), Ember.Object.create({ key: 'new-time-off', value: (0, _utils.loc)('Add Time Off') }), Ember.Object.create({ key: 'full-report', value: (0, _utils.loc)('Full Report') })];
			} else {
				return [Ember.Object.create({ key: 'full-report', value: (0, _utils.loc)('Full Report') })];
			}
		}),

		dataRangeString: Ember.computed('start', 'end', function () {
			var start = Number(this.get('start'));
			var end = Number(this.get('end'));
			var date = '';
			if (!isNaN(start) && !isNaN(end)) {
				var startDate = _utils.Time.timeFormat(start, 'MMM D');
				var endDate = _utils.Time.timeFormat(end, 'MMM D, YYYY');

				date = startDate + ' - ' + endDate;
			}
			return date;
		}),

		getMember: Ember.observer('member_id', function () {
			var _this = this;

			if (!Ember.isNone(this.get('member_id'))) {
				this.store.findRecord('member', this.get('member_id')).then(function (member) {
					_this.set('member', member);
				});
			}
		}),

		payPeriodSalary: Ember.computed('start', 'auth.organizationPayPeriod.[]', 'member.wage.wageRate', function () {
			var period = this.get('payPeriod').getPayPeriod(this.get('start'));
			var wageRate = this.get('member.wage.wageRate');
			var wage = this.get('member.wage.wage');
			if (wageRate === 30 || wageRate === 40 || wageRate === 50) {
				if (!Ember.isNone(period) && !Ember.isNone(period.get('type'))) {
					var salary = wage;
					var payPeriodType = period.get('type');

					// convert all salary to a yearly salary
					if (wageRate === 30) {
						// convert weekly salary to yearly
						salary = salary * 52;
					} else if (wageRate === 40) {
						// convert monthly salary to yearly
						salary = salary * 12;
					}

					// convert salary to the pay-period span
					if (payPeriodType === 10) {
						// calculate weekly salary from yearly
						salary = salary / 52;
					} else if (payPeriodType === 20) {
						// calculate bi weekly salary from yearly
						salary = salary / 26;
					} else if (payPeriodType === 30) {
						// calculate bi monthly salary from yearly
						salary = salary / 24;
					} else if (payPeriodType === 40) {
						// calculate monthly salary from yearly
						salary = salary / 12;
					}
					return salary;
				}
			}
			return;
		}),

		isTimecardMine: Ember.computed('auth.memberId', 'member.id', function () {
			return this.get('auth.memberId') === this.get('member.id');
		}),

		isSupervisor: Ember.computed('member', function () {
			var supervisorAction = 'manageTimeEntries';
			var member = this.get('member');

			if (Ember.isNone(member) || Ember.isNone(member.get('id'))) {
				return false;
			}

			return this.get('access').canTakeActionFor(supervisorAction, member);
		}),

		signatureState: Ember.computed('isTimecardMine', 'isSupervisor', 'payPeriodSeconds.isSignable', 'features.{signatures,signatures-supervisor}', 'signature.{isTimecardSigningEnabled,isTimecardSupervisorSigningEnabled}', 'payPeriodSeconds.memberTimeDocument.{hasValidSelfSignature,needsSelfSignatureResign,hasBothValidSignatures,needsEitherSignatureResign}',
		// 'payPeriodSeconds.memberTimeDocument.needsEitherSignatureResign',
		// 'payPeriodSeconds.memberTimeDocument.needsAuthoritativeSignatureResign',
		function () {

			if (Ember.isNone(this.get('isTimecardMine')) || Ember.isNone(this.get('isSupervisor')) || Ember.isNone(this.get('payPeriodSeconds')) || Ember.isNone(this.get('features')) || Ember.isNone(this.get('signature'))) {
				return null;
			}

			return this.get('signature').getSignatureState(this.get('isTimecardMine'), this.get('isSupervisor'), this.get('payPeriodSeconds'), this.get('features'), this.get('signature'), this.get('payPeriodSeconds.memberTimeDocument'));
		}),

		signatureStateClass: Ember.computed('signatureState', function () {
			return {
				'Signed': 'green',
				'Sign': 'blue',
				'Re-Sign': 'red'
			}[this.get('signatureState')];
		}),

		actions: {
			dayClickAction: function dayClickAction(day) {
				this.transitionToRoute('time-card.time-entries', {
					queryParams: {
						start: day.get('start'),
						member_id: this.get('member.id')
					}
				});
			},
			selectItemAction: function selectItemAction(item) {
				if (item.get('key') === 'new-time-entry') {
					this.set('showNewTimeEntryDialog', true);
				} else if (item.get('key') === 'new-time-off') {
					this.set('showNewTimeOffDialog', true);
				} else if (item.get('key') === 'full-report') {
					this.transitionToRoute('time-card.full-report.index', {
						queryParams: {
							start: this.get('start'),
							member_id: this.get('member.id'),
							end: this.get('end')
						}
					});
				}
			},
			closeNewTimeOffDialog: function closeNewTimeOffDialog() {
				this.set('showNewTimeOffDialog', false);
			},


			/**
    * legacy support for time-off feature
    *
    */
			fullReportClick: function fullReportClick() {
				this.transitionToRoute('time-card.full-report', {
					queryParams: {
						start: this.get('start'),
						member_id: this.get('member.id'),
						end: this.get('end')
					}
				});
			},


			/**
    * legacy support for time-off feature
    *
    */
			openNewTimeEntryDialog: function openNewTimeEntryDialog() {
				this.set('showNewTimeEntryDialog', true);
			},
			closeNewTimeEntryDialog: function closeNewTimeEntryDialog() {
				this.set('showNewTimeEntryDialog', false);
			},
			onDone: function onDone() {
				this.send('reloadModel');
			},
			openFullReport: function openFullReport(payPeriod) {
				_utils.Assert.funcNumArgs(arguments, 1, true);
				_utils.Assert.isObject(payPeriod);

				var queryParams = {
					start: payPeriod.get('startTime'),
					member_id: payPeriod.get('memberId'),
					end: payPeriod.get('endTime')
				};

				return this.transitionToRoute('time-card.full-report', { queryParams: queryParams });
			},
			reloadSync: function reloadSync() {
				this.set('dirtyFlag', true);
			}
		}
	});
});