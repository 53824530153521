define('busy-app/utils/compare', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = compare;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function isObject(value) {
		return !Ember.isNone(value) && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && typeof value.isArray !== 'function';
	}

	/**
  * Compares each property on the object to see if they match.
  *
  * this will match 2 objects that are not pointing at the same created object.
  *
  * @private
  * @method compareObjects
  * @param a {object} first object to compare
  * @param b {object} second object to compare
  * @return {boolean} true if both objects match
  */
	function compareObjects(a, b) {
		(false && !(isObject(a) && isObject(b)) && Ember.assert('compareObjects(a, b) params 1 and 2 are required to be objects', isObject(a) && isObject(b)));


		// native js compare will return true if
		// a and b point to same register in memory
		//
		// @example
		//	var a = [];
		//	var b = a;
		//	a === b => true; // two vars pointing to same memory
		//
		//	var a = [];
		//	var b = [];
		//	a === b => false; // two idential vars pointing to different memory is false;
		//
		if (a === b) {
			return true;
		}

		// return false if objects keys are not equal in length
		if (Object.keys(a).length !== Object.keys(b).length) {
			return false;
		}

		// check each key to verify equality
		var isEqual = true;
		var keys = Object.keys(a);
		for (var i = 0; i < keys.length; i++) {
			var key = keys[i];
			if (!compare(Ember.get(a, key), Ember.get(b, key))) {
				isEqual = false;
				break;
			}
		}
		return isEqual;
	}

	/**
  * Compares each property on the arrays to see if they match.
  *
  * this will match 2 arrays that are not pointing at the same created array.
  *
  * @private
  * @method compareArrays
  * @param a {array} first object to compare
  * @param b {array} second object to compare
  * @return {boolean} true if both objects match
  */
	function compareArrays(a, b) {
		(false && !(Ember.isArray(a) && Ember.isArray(b)) && Ember.assert('compareArrays(a, b) params 1 and 2 are required to be arrays', Ember.isArray(a) && Ember.isArray(b)));


		// native js compare will return true if
		// a and b point to same register in memory
		//
		// @example
		//	var a = {};
		//	var b = a;
		//	a === b => true;
		//
		//	var a = {};
		//	var b = {};
		//	a === b => false; // two idential vars pointing to different memory is false;
		//
		if (a === b) {
			return true;
		}

		// return false if arrays are not equal in length
		if (a.length !== b.length) {
			return false;
		}

		// check each value to verify equality
		var isEqual = true;
		for (var i = 0; i < a.length; i++) {
			if (!compare(a[i], b[i])) {
				isEqual = false;
				break;
			}
		}
		return isEqual;
	}

	/**
  * compares 2 values to see if they are a match
  *
  * options object supports 2 keys:
  *	disableType {boolean} disables the strong type check 1 === 1 => strong type, 1 == 1 => week type (1 == '1'): true
  *	disableCase {boolean} disables case sensitive string compare (Test === test): true
  *
  * @public
  * @method compare
  * @param a {mixed} first value to compare
  * @param b {mixed} second value to compare
  * @param options {object} optional options. supported now are { disableType, disableCase }
  * @return {boolean}
  */
	function compare(a, b) {
		var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

		var typeA = typeof a === 'undefined' ? 'undefined' : _typeof(a);
		var typeB = typeof b === 'undefined' ? 'undefined' : _typeof(b);
		if (typeA === typeB || options.disableType) {
			if (isObject(a)) {
				return compareObjects(a, b);
			} else if (Ember.isArray(a)) {
				return compareArrays(a, b);
			} else {
				if (typeA === 'string' && options.disableCase) {
					if (options.disableType) {
						return a.toLowerCase() == b.toLowerCase();
					}
					return a.toLowerCase() === b.toLowerCase();
				} else if (options.disableType) {
					return a == b;
				}
				return a === b;
			}
		}
		return false;
	}
});