define('busy-app/routes/project/detail/reports/budget-versus-actual', ['exports', '@busy-web/utils', 'busy-app/utils/docs'], function (exports, _utils, _docs) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		resetController: function resetController(controller, isExiting) {
			if (isExiting) {
				if (!Ember.isNone(controller.get('optionSelector')) && controller.get('optionSelector').isAny('_selected', true)) {
					controller.get('optionSelector').forEach(function (selected) {
						return selected.set('_selected', false);
					});

					var defaultSelection = controller.get('optionSelector').findBy('key', 'allTimeRange');
					defaultSelection.set('_selected', true);
				}

				controller.set('isCustomDate', false);
			}
		},
		model: function model() {
			var _this = this;

			var project = this.modelFor('project.detail');
			var id = project.project.id;
			var detail = this.modelFor('project/detail');
			if (!detail.then) {
				detail = Ember.RSVP.resolve(detail);
			}

			return detail.then(function (modelHash) {
				return Ember.RSVP.hash({
					budgetSeconds: _this.store.findAll('project-hours-budget', { project_id: id, deleted_on: null }),
					costBudget: _this.store.findAll('project-cost-budget', { project_id: id, deleted_on: null }),
					project: modelHash.project
				}).then(function (data) {
					data.budgetSeconds = data.budgetSeconds.sortBy('updatedOn').get('lastObject');
					data.costBudget = data.costBudget.sortBy('updatedOn').get('lastObject');
					return data;
				});
			});
		},
		renderTemplate: function renderTemplate(controller) {
			var project = this.modelFor('project.detail');
			var reportController = this.controllerFor('project.detail.reports');
			reportController.set('reportTitle', (0, _utils.loc)('Budget vs Actual'));
			controller.set('project', project.project);
			this.render('project.detail.reports.budget-versus-actual');
		},


		actions: {
			printReport: function printReport() {
				var container = Ember.$('.v-project-reports-budget-actual');
				_docs.default.print(container);
			}
		}
	});
});