define('busy-app/helpers/reduce', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.reduce = reduce;
	/**
  * @module Helpers
  *
  */
	function reduce(params) {
		var args = params.slice(0);

		var list = args.shift();
		var callback = args.shift();
		var startValue = args.shift() || 0;

		if (Ember.isEmpty(list)) {
			return 0;
		}

		return list.reduce(function (a, b) {
			return callback(a, b);
		}, startValue);
	}

	exports.default = Ember.Helper.helper(reduce);
});