define('busy-app/polymorphs/pay-period-member', ['exports', 'busy-app/utils/collection', 'busy-app/polymorphs/base'], function (exports, _collection, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		member: null,
		seconds: null,
		cost: null,

		/**
   * regular seconds should be used when dealing with member only data.
   * When dealing with pay-period-member-project-seconds this will give you
   * the seconds without the child seconds factored in.
   */
		regularSeconds: Ember.computed('seconds.@each.regularSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return total += item.get('regularSeconds');
				});
			}
			return total;
		}),

		/**
   * totalRegularSeconds will provide child regular seconds as well as regualr seconds.
   */
		totalRegularSeconds: Ember.computed('seconds.@each.totalRegularSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return total += item.get('totalRegularSeconds');
				});
			}
			return total;
		}),

		overtimeSeconds: Ember.computed('seconds.@each.overtimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return total += item.get('overtimeSeconds');
				});
			}
			return total;
		}),

		totalOvertimeSeconds: Ember.computed('seconds.@each.totalOvertimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return total += item.get('totalOvertimeSeconds');
				});
			}
			return total;
		}),

		doubletimeSeconds: Ember.computed('seconds.@each.doubletimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return total += item.get('doubletimeSeconds');
				});
			}
			return total;
		}),

		totalDoubletimeSeconds: Ember.computed('seconds.@each.totalDoubletimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return total += item.get('totalDoubletimeSeconds');
				});
			}
			return total;
		}),

		totalSeconds: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}),

		totalWithChildrenSeconds: Ember.computed('totalRegularSeconds', 'totalOvertimeSeconds', 'totalDoubletimeSeconds', function () {
			return this.get('totalRegularSeconds') + this.get('totalOvertimeSeconds') + this.get('totalDoubletimeSeconds');
		}),

		regularCost: Ember.computed('cost.@each.regularCost', function () {
			var total = 0;
			if (!Ember.isNone(this.get('cost'))) {
				this.get('cost').forEach(function (item) {
					return total += item.get('regularCost');
				});
			}
			return total;
		}),

		totalRegularCost: Ember.computed('cost.@each.totalRegularCost', function () {
			var cost = Ember.get(this, 'cost');
			var total = 0;
			if (!Ember.isNone(cost)) {
				cost.forEach(function (item) {
					return total += item.get('totalRegularCost');
				});
			}
			return total;
		}),

		overtimeCost: Ember.computed('cost.@each.overtimeCost', function () {
			var total = 0;
			if (!Ember.isNone(this.get('cost'))) {
				this.get('cost').forEach(function (item) {
					return total += item.get('overtimeCost');
				});
			}
			return total;
		}),

		totalOvertimeCost: Ember.computed('cost.@each.totalOvertimeCost', function () {
			var total = 0;
			if (!Ember.isNone(this.get('cost'))) {
				this.get('cost').forEach(function (item) {
					return total += item.get('totalOvertimeCost');
				});
			}
			return total;
		}),

		doubletimeCost: Ember.computed('cost.@each.doubletimeCost', function () {
			var total = 0;
			if (!Ember.isNone(this.get('cost'))) {
				this.get('cost').forEach(function (item) {
					return total += item.get('doubletimeCost');
				});
			}
			return total;
		}),

		totalDoubletimeCost: Ember.computed('cost.@each.totalDoubletimeCost', function () {
			var total = 0;
			if (!Ember.isNone(this.get('cost'))) {
				this.get('cost').forEach(function (item) {
					return total += item.get('totalDoubletimeCost');
				});
			}
			return total;
		}),

		totalCost: Ember.computed('regularCost', 'overtimeCost', 'doubletimeCost', function () {
			return this.get('regularCost') + this.get('overtimeCost') + this.get('doubletimeCost');
		}),

		totalWithChildrenCost: Ember.computed('totalRegularCost', 'totalOvertimeCost', 'totalDoubletimeCost', function () {
			var _EmberGetProperties = Ember.getProperties(this, ['totalRegularCost', 'totalOvertimeCost', 'totalDoubletimeCost']),
			    totalRegularCost = _EmberGetProperties.totalRegularCost,
			    totalOvertimeCost = _EmberGetProperties.totalOvertimeCost,
			    totalDoubletimeCost = _EmberGetProperties.totalDoubletimeCost;

			var totalWithChildrenCost = totalRegularCost + totalOvertimeCost + totalDoubletimeCost;

			return totalWithChildrenCost;
		}),

		overburdenCost: Ember.computed('cost.@each.overburden', function () {
			var cost = Ember.get(this, 'cost');
			var total = Ember.isNone(cost) ? 0 : (0, _collection.sumBy)(cost, 'overburden');

			return total;
		}),

		overburdenChildrenCost: Ember.computed('cost.@each.childrenOverburden', function () {
			var cost = Ember.get(this, 'cost');
			var total = Ember.isNone(cost) ? 0 : (0, _collection.sumBy)(cost, 'childrenOverburden');

			return total;
		}),

		overburdenWithChildrenCost: Ember.computed('overburdenCost', 'overburdenChildrenCost', function () {
			var _EmberGetProperties2 = Ember.getProperties(this, ['overburdenCost', 'overburdenChildrenCost']),
			    overburdenCost = _EmberGetProperties2.overburdenCost,
			    overburdenChildrenCost = _EmberGetProperties2.overburdenChildrenCost;

			var total = overburdenCost + overburdenChildrenCost;

			return total;
		})
	});
});