define('busy-app/components/subscriptions/subscription-plan-info', ['exports', 'busy-app/utils/logger', 'busy-app/utils/string'], function (exports, _logger, _string) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['v-company-settings-subscription-plan-info'],

		//features: service(),
		subscription: Ember.inject.service(),
		// stripe: service(),
		//auth: service(),

		isLoading: false,
		selectTab: null,

		showUpgradeDialog: false,
		showEditPlan: false,
		showEditBillingAddress: false,
		showEditPaymentMethod: false,
		showAnnualInvoiceWarning: false,

		/*
   * Computeds
   */

		// a plan is considered custom if it has an account balanceInCents and `subscribedThroughProduct` is not true
		// if `OrganizationSubscriptionStatus` has values set for `annualSubscriptionCount`, it isn't a custom plan created by CS in Chargify
		// The `subscribedThroughProduct` is set to true by the web services during the `subscription.upgradeToPro` RPC
		// these are legacy annual billing plans, which display a custom  message to the user instead of show incorrecc versions subscription details
		isCustomPlan: Ember.computed('subscriptionDetails.balanceInCents', 'subscription.subscriptionStatus.{isAnnualPlan,subscribedThroughProduct}', function () {
			var isAnnual = this.get('subscription.subscriptionStatus.isAnnualPlan');
			var isCustom = this.get('subscriptionDetails.balanceInCents') && !this.get('subscription.subscriptionStatus.subscribedThroughProduct');

			return !isAnnual && isCustom;
		}),

		isLoadingBillingDetails: Ember.computed.not('subscriptionDetails'),

		showUpdateText: Ember.computed('subscription.{isProAccount,hasPaymentMethod}', function () {
			return this.get('subscription.isProAccount') && this.get('subscription.hasPaymentMethod');
		}),

		accountBalance: Ember.computed('subscriptionDetails.balanceInCents', function () {
			var balanceInCents = parseInt(this.get('subscriptionDetails.balanceInCents'), 10) * -1;

			return !Ember.isNone(balanceInCents) ? balanceInCents : 0;
		}),

		showAccountBalance: Ember.computed.or('accountBalance', 'subscription.subscriptionStatus.isAnnualPlan'),

		isBalanceDue: Ember.computed('accountBalance', function () {
			var accountBalance = this.get('accountBalance');

			return !Ember.isNone(accountBalance) && accountBalance < 0;
		}),

		isCreditAvailable: Ember.computed('accountBalance', function () {
			var accountBalance = this.get('accountBalance');

			return !Ember.isNone(accountBalance) && accountBalance > 0;
		}),

		accountBalanceFormatted: Ember.computed('accountBalance', function () {
			var accountBalance = this.get('accountBalance');

			return !Ember.isNone(accountBalance) ? (0, _string.centsToDollarFormat)(Math.abs(accountBalance)) : '';
		}),

		costPerActiveUserText: Ember.computed('subscription.{subscriptionStatus.isAnnualPlan,isPaidAccount,costPerActiveUserMonthlyText,costPerActiveUserPerYearText}', function () {
			if (this.get('subscription.subscriptionStatus.isAnnualPlan')) {
				return this.get('subscription.costPerActiveUserPerYearText');
			} else {
				return this.get('subscription.costPerActiveUserMonthlyText');
			}
		}),

		effectiveDateText: null,
		// effectiveDateText: computed('subscription.subscriptionStatus.{hasAnnualSubscriptionChanged,nextAssessmentAt}', function() {
		// 	if (this.get('subscription.subscriptionStatus.hasAnnualSubscriptionChanged') && this.get('subscription.subscriptionStatus.nextAssessmentAt')) {
		// 		const formattedDate = Time.date(this.get('subscription.subscriptionStatus.nextAssessmentAt')).format('ll');
		//
		// 		return loc(`(Effective %@)`, [formattedDate]);
		// 	} else {
		// 		return null;
		// 	}
		// }),

		/*
   * Observers
   */

		onChangeSubscriptionStatus: Ember.observer('subscription.subscriptionStatusVersion', function () {
			_logger.default.info(this, 'onChangeSubscriptionStatus');
			return this.loadData();
		}),

		/**
   * @public
   * @method init
   * @constructor
   */
		init: function init() {
			this._super();

			this.loadData();
		},


		/**
   * @public
   * @method loadData
   */
		loadData: function loadData() {
			var _this = this;

			this.set('isLoading', true);

			return Ember.RSVP.hash({
				billingDetails: this.get('subscription').getBillingDetails(),
				subscriptionDetails: this.get('subscription').getSubscriptionDetails(),
				subscriptionPricing: this.get('subscription.subscriptionPricing'), // ensure `subscriptionPricing` is loaded
				subscriptionStatus: this.get('subscription.subscriptionStatus') // ensure `subscriptionStatus` is loaded
			}).then(function (props) {
				if (!_this.get('isDestroyed')) {
					_this.set('billingDetails', props.billingDetails);
					_this.set('subscriptionDetails', props.subscriptionDetails);
				}
			}).catch(function (err) {
				_logger.default.error(_this, err);
			}).finally(function () {
				if (!_this.get('isDestroyed')) {
					_this.set('isLoading', false);
				}
			});
		},


		actions: {
			/**
    * @public
    * @event openModal
    */
			openModal: function openModal(dialogName) {
				_logger.default.info(this, 'openModal', { dialogName: dialogName });

				this.set('show' + dialogName, true);
			},


			/**
    * @public
    * @event closeModal
    * @param dialogName {string} which dialog to close (EditPlan / EditBillingAddress / EditPaymentMethod)
    */
			closeModal: function closeModal(dialogName) {
				_logger.default.info(this, 'closeModal', { dialogName: dialogName });

				this.set('show' + dialogName, false);
			},


			/**
    * @public
    * @event closeModalAndRefresh
    * @param dialogName {string} which dialog to close (EditPlan / EditBillingAddress / EditPaymentMethod)
    */
			closeModalAndRefresh: function closeModalAndRefresh(dialogName) {
				var _this2 = this;

				_logger.default.info(this, 'closeModalAndRefresh', { dialogName: dialogName });

				return this.loadData().finally(function () {
					if (!_this2.get('isDestroyed')) {
						_this2.sendAction('refreshSubscriptionTabState'); // action lives in subscription-index
						return _this2.send('closeModal', dialogName);
					}
				});
			},


			/**
    * @public
    * @event return
    * @param invoiceId {number}
    */
			openInvoice: function openInvoice(invoiceId) {
				return this.get('subscription').openPdfInvoice(invoiceId);
			},


			/**
    * @public
    * @event openUpgradeDialog
    */
			openUpgradeDialog: function openUpgradeDialog() {
				_logger.default.info(this, 'openUpgradeDialog', {});

				if (!this.get('isDestroyed')) {
					if (this.get('subscription.subscriptionStatus.isInvoice')) {
						this.send('openModal', ['AnnualInvoiceWarning']);
					} else {
						this.send('openModal', ['UpgradeDialog']);
					}
				}
			},


			/**
    * @public
    * @event closeUpgradeDialog
    */
			closeUpgradeDialog: function closeUpgradeDialog() {
				_logger.default.info(this, 'closeUpgradeDialog', {});

				if (!this.get('isDestroyed')) {
					this.sendAction('refreshSubscriptionTabState');

					this.set('showUpgradeDialog', false);
				}
			},


			/**
    * @public
    * @event selectTab
    */
			selectTab: function selectTab(tabName) {
				_logger.default.info(this, 'selectTab', {});

				if (!this.get('isDestroyed')) {
					this.sendAction('selectTab', tabName);
				}
			}
		}
	});
});