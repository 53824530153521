define('busy-app/components/dialogs/tab-header-dialog', ['exports', 'busy-app/utils/docs', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _docs, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		title: null,
		subTitle: null,
		noDate: false,
		start: null,
		end: null,

		onSelectTab: null,
		onSelectOption: null,

		selectOptions: null,
		currentOption: null,

		tabOptions: null,
		currentTab: null,
		createTimeEntryConfig: null,
		buttonOptions: null,

		init: function init() {
			this._super();
			Ember.$('body').addClass('tab-dialog');
		},


		_selectOptions: Ember.computed('selectOptions.[]', 'currentOption', function () {
			return this.setSelected(this.get('selectOptions'), this.get('currentOption'));
		}),

		_tabOptions: Ember.computed('tabOptions.[]', 'currentTab', function () {
			return this.setSelected(this.get('tabOptions'), this.get('currentTab'));
		}),

		_dateTitle: Ember.computed('start', 'end', 'noDate', function () {
			if (this.get('noDate')) {
				return null;
			} else {
				var start = this.get('start');
				var end = this.get('end');

				var startTime = _utils.Time.date(start).utc();
				var endTime = _utils.Time.date(end).utc();
				var currentTime = _utils.Time.date().utc();

				var isDateRange = !startTime.isSame(endTime, 'day');
				var isDifferentYear = !endTime.isSame(currentTime, 'year');

				var dateRange = [startTime.format('MMM DD'), isDateRange ? endTime.format('MMM DD') : null].compact().join(' &ndash; ');

				return [dateRange, isDifferentYear ? endTime.format('YYYY') : null].compact().join(', ');
			}
		}),

		_splitButtonOptions: Ember.computed('enableCsv', function () {
			return {
				primaryText: (0, _utils.loc)('Print'),
				primaryAction: 'printAction',
				selectAction: 'onSplitButtonSelect',
				classModifiers: 'white',

				selectOptions: [
				// EmberObject.create({ key: 'printAction', value: loc('Print') }),
				Ember.Object.create({ key: 'exportCSVAction', value: (0, _utils.loc)('Download (.csv)') })]
			};
		}),

		setSelected: function setSelected(collection, selected) {
			if (Ember.isEmpty(collection) || Ember.isNone(selected)) {
				return collection;
			}

			return collection.map(function (item) {
				item.set('_selected', selected === item.key);

				return item;
			});
		},


		actions: {
			printAction: function printAction() {
				//const $body = this.$().closest('body');
				//const $printNode = $body.find('.c-dialogs-fs-modal-dialog');

				_docs.default.print(); //$printNode);
			},
			selectOptionAction: function selectOptionAction(item) {
				this.sendAction('onSelectOption', item);
			},
			selectTabAction: function selectTabAction(item) {
				this.sendAction('onSelectTab', item);
			},
			closeAction: function closeAction() {
				Ember.$('body').removeClass('tab-dialog');
				this.sendAction('onClose');
			},
			exportCSVAction: function exportCSVAction() {
				this.sendAction('onExportCSV');
			},
			onSplitButtonSelect: function onSplitButtonSelect(item) {
				_logger.default.info(this, 'onSplitButtonSelect', item);
				return this.send(Ember.get(item, 'key'));
			},
			refreshSync: function refreshSync() {
				return this.sendAction('refreshSync');
			}
		}
	});
});