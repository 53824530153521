define('busy-app/authenticators/base', ['exports', 'ember-simple-auth/authenticators/base', 'fetch'], function (exports, _base, _fetch) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		store: Ember.inject.service('store'),

		/**
   * endpoint to make request to
   *
   */
		serverTokenEndpoint: 'token',

		/**
   * name of the token to use for authenticated calls
   * thet is returned from the auth endpoint
   *
   */
		tokenAttributeName: 'token',

		/**
   * GET query may need to use the identifier to filter the results
   *
   */
		identifierName: '',

		restore: function restore(data) {
			return Ember.RSVP.Promise.resolve(data);
		},
		normalizeResponse: function normalizeResponse(data) {
			return data;
		},
		validateResponse: function validateResponse() {
			return true;
		},
		invalidMessage: function invalidMessage() {
			return { message: "Authentication Failed", code: 1001 };
		},
		authenticate: function authenticate(identifier, password, token) {
			var _this = this;

			if (token) {
				return Ember.RSVP.Promise.resolve(token);
			}

			var query = {};
			if (!Ember.isEmpty(Ember.get(this, 'identifierName'))) {
				Ember.set(query, Ember.get(this, 'identifierName'), identifier);
			}

			var auth = window.btoa(identifier + ':' + password);
			return this.makeRequest(auth, query).then(function (response) {
				if (response.ok) {
					return response.json().then(function (data) {
						if (_this.validateResponse(data)) {
							var resp = _this.normalizeResponse(data);
							return resp;
						} else {
							var _invalidMessage = _this.invalidMessage(data),
							    message = _invalidMessage.message,
							    code = _invalidMessage.code;

							return Ember.RSVP.Promise.reject({ status: 401, code: code, message: message });
						}
					});
				} else {
					return response.json().then(function (err) {
						return Ember.RSVP.Promise.reject({ status: response.status, code: err.code[0], message: "Authentication Failed" });
					});
				}
			});
		},
		invalidate: function invalidate(data) {
			return Ember.RSVP.Promise.resolve(data);
		},
		makeRequest: function makeRequest(basicAuth) {
			var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			query = query || {};

			var adapter = this.get('store')._instanceCache.get('adapter');
			var url = adapter.urlForRequest({
				type: { modelName: this.get('serverTokenEndpoint') },
				requestType: 'query',
				query: query
			});

			var body = Ember.$.param(query);
			url = [url, body].join('&');

			var requestOptions = {
				method: 'GET',
				headers: {
					'accept': 'application/json; charset=utf-8',
					'content-type': 'application/json; charset=utf-8',
					'Authorization': 'Basic ' + basicAuth
				}
			};

			return (0, _fetch.default)(url, requestOptions);
		}
	});
});