define('busy-app/router', ['exports', 'busy-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,

    eventEmitter: Ember.inject.service(),
    afterTransition: Ember.on('didTransition', function () {
      this.get('eventEmitter').send('router', 'transition', this.get('currentPath'));
    })
  });

  Router.map(function () {
    this.route('index', { path: '/' });

    this.route('cost-code', function () {});

    this.route('signup');
    this.route('login');
    this.route('reset');

    this.route('company-settings', function () {
      this.route('intuit-quickbooks');
      this.route('procore', function () {
        this.route('callback');
      });
    });

    this.route('account-settings');
    this.route('verify-email');
    this.route('ticket');

    this.route('time-card', function () {
      this.route('time-entry');
      this.route('time-off-entry');
      this.route('time-entries');
      this.route('pay-period');
      this.route('full-report', function () {
        this.route('loading', {});
      });
      this.route('member-location');
    });

    this.route('report', function () {
      this.route('employee-list');
      this.route('report-setup');

      this.route('payroll', function () {
        this.route('loading', {});
      });

      this.route('time-card', function () {
        this.route('loading');
        this.route('time-off-entry');
        this.route('full-report', function () {});
      });
      this.route('time-off', function () {
        this.route('loading');
      });
      this.route('time-card-approval');
      this.route('loading');
      this.route('time-entries', function () {
        this.route('loading');
      });
      this.route('activity', function () {
        this.route('loading');
      });
      this.route('safety-signature', function () {
        this.route('date');
      });
    });

    this.route('project', function () {
      this.route('detail', { path: '/:id' }, function () {
        this.route('index', { path: '' });

        this.route('reports', function () {
          this.route('employees');
          this.route('subprojects');
          this.route('budget-versus-actual');
          this.route('loading');
        });
      });
    });

    this.route('employees', function () {
      this.route('employee', { path: '/employee/:id' });
      this.route('employee-locations');
      this.route('locations');
      this.route('loading');
    });

    this.route('note', function () {
      this.route('detail', { path: '/detail/:id' });
    });
    this.route('account-ready');
    this.route('not-found', { path: '/*path' });
    this.route('loading');
    this.route('scheduling', function () {
      this.route('loading');
    });
    this.route('map');
    this.route('logout');
  });

  exports.default = Router;
});