define('busy-app/components/busy-map', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({
		classNames: ['c-busy-map'],
		classNameBindings: ['canEdit:edit'],

		/** public property list */
		map: null,
		mapMarkers: null,
		mapAutocomplete: null,
		canEdit: false,
		locationInfo: null,
		error: false,
		zoom: 15,
		showRadius: false,

		mapInit: Date.now(),

		actions: {
			onLocationChange: function onLocationChange() {
				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				this.sendAction.apply(this, ['onLocationChange'].concat(_toConsumableArray(args)));
			}
		}
	});
});