define('busy-app/components/time/time-entry/switch', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = Ember.Component.extend({
		classNames: ['v-time-card-dialog-switch-project'],

		auth: Ember.inject.service('auth'),

		timeEntry: null,
		member: null,
		memberEntries: null,

		project: null,
		costCode: null,
		equipment: null,

		_project: null, // initial value
		_costCode: null, // initial value
		_equipment: null, // initial value

		costCodesAutoOpen: false,
		projectsAutoOpen: false,
		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),
		disableCostCodeSelection: Ember.computed('project.id', 'isLinkedCostCodesEnabled', function () {
			return Ember.get(this, 'isLinkedCostCodesEnabled') && Ember.isNone(Ember.get(this, 'project.id'));
		}),

		init: function init() {
			var _this = this;

			this._super();

			if (Ember.get(this, 'memberEntries.length') > 0) {
				var first = Ember.get(this, 'memberEntries.firstObject');
				var projectId = Ember.getWithDefault(first, 'openEntry.projectId', null);
				var costCodeId = Ember.getWithDefault(first, 'openEntry.costCodeId', null);
				var equipmentId = Ember.getWithDefault(first, 'openEntry.equipmentId', null);

				// test to see if all members have the same project, costcode, or equipment
				//
				// all members will be tested against the first member to check for matching values. The reduce method will return the start Id or null if not all entries match
				if (!Ember.isNone(projectId)) projectId = Ember.get(this, 'memberEntries').reduce(function (pid, member) {
					return !Ember.isNone(pid) && pid === Ember.get(member, 'openEntry.projectId') ? pid : null;
				}, projectId);
				if (!Ember.isNone(costCodeId)) costCodeId = Ember.get(this, 'memberEntries').reduce(function (cid, member) {
					return !Ember.isNone(cid) && cid === Ember.get(member, 'openEntry.costCodeId') ? costCodeId : null;
				}, costCodeId);
				if (!Ember.isNone(equipmentId)) equipmentId = Ember.get(this, 'memberEntries').reduce(function (eid, member) {
					return !Ember.isNone(eid) && eid === Ember.get(member, 'openEntry.equipmentId') ? equipmentId : null;
				}, equipmentId);

				// set the project, costcode or equipment that is the same accross all memebers
				//
				// if all entries above return the same value then the id will not be null and the entry will use that value.
				if (!Ember.isNone(projectId)) Ember.RSVP.resolve(Ember.get(first, 'openEntry.project')).then(function (pr) {
					return _this.setInitial('project', pr);
				});
				if (!Ember.isNone(costCodeId)) Ember.RSVP.resolve(Ember.get(first, 'openEntry.costCode')).then(function (cc) {
					return _this.setInitial('costCode', cc);
				});
				if (!Ember.isNone(equipmentId)) Ember.RSVP.resolve(Ember.get(first, 'openEntry.equipment')).then(function (eq) {
					return _this.setInitial('equipment', eq);
				});
			} else {
				// use last project, costcode, equipment to set default values for the select menus
				Ember.RSVP.resolve(Ember.getWithDefault(this, 'member.openEntry.project', null)).then(function (project) {
					return _this.setInitial('project', project);
				});
				Ember.RSVP.resolve(Ember.getWithDefault(this, 'member.openEntry.costCode', null)).then(function (costCode) {
					return _this.setInitial('costCode', costCode);
				});
				Ember.RSVP.resolve(Ember.getWithDefault(this, 'member.openEntry.equipment', null)).then(function (equipment) {
					return _this.setInitial('equipment', equipment);
				});
			}
		},
		setInitial: function setInitial(key, val) {
			var _EmberSetProperties;

			Ember.setProperties(this, (_EmberSetProperties = {}, _defineProperty(_EmberSetProperties, key, val), _defineProperty(_EmberSetProperties, '_' + key, val), _EmberSetProperties));

			// setProperties(this, {
			// 	project: val,
			// 	_project: val,
			// });
		},
		clockIn: function clockIn(member) {
			var _this2 = this;

			var validateOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			var startLocation = null;
			var forceSave = true;
			var isSwitch = true;

			if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
				member = Ember.get(member, 'content');
			}
			return member.clockIn(Ember.get(this, 'project.id'), Ember.get(this, 'costCode.id'), Ember.get(this, 'description'), Ember.get(this, 'equipment.id'), startLocation, forceSave, validateOnly, isSwitch).then(function (entry) {
				if (!validateOnly) {
					Ember.set(entry, 'project', Ember.get(_this2, 'project'));
					Ember.set(entry, 'costCode', Ember.get(_this2, 'costCode'));
					Ember.set(entry, 'equipment', Ember.get(_this2, 'equipment'));
					Ember.set(member, 'openEntry', entry);
				}
				return entry;
			});
		},
		clockOut: function clockOut(member) {
			var isSwitch = true;
			var endLocation = null;

			if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
				member = Ember.get(member, 'content');
			}
			return member.clockOut(isSwitch, endLocation);
		},
		switchEntry: function switchEntry(member) {
			var _this3 = this;

			return this.clockIn(member, true).then(function () {
				// validate the clock in data is correct
				return _this3.clockOut(member).then(function () {
					// clock out current entry
					return _this3.clockIn(member, false); // clock in new entry
				});
			});
		},


		actions: {
			projectSelected: function projectSelected(item) {
				Ember.set(this, 'project', item);

				if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
					Ember.set(this, 'costCode', null);
				}
			},
			costCodeSelected: function costCodeSelected(item) {
				Ember.set(this, 'costCode', item);
			},
			equipmentSelected: function equipmentSelected(item) {
				Ember.set(this, 'equipment', item);
			},


			/**
    * closes dialog if clicked on cancel button or clicked in background window
    *
    * @event close
    */
			close: function close() {
				this.sendAction('onClose');
			},
			saveSwitchProject: function saveSwitchProject() {
				var _this4 = this;

				if (!Ember.isNone(Ember.get(this, 'memberEntries'))) {
					var promise = [];
					Ember.get(this, 'memberEntries').forEach(function (member) {
						promise.push(_this4.switchEntry(member));
					});

					return Ember.RSVP.all(promise);
				} else {
					return this.switchEntry(Ember.get(this, 'member'));
				}
			},
			projectMenuClosed: function projectMenuClosed(didSelectValue, selectedItem, openedBy) {
				var _this5 = this;

				if (Ember.get(this, 'isLinkedCostCodesEnabled') && didSelectValue) {
					Ember.run.next(this, function () {
						Ember.setProperties(_this5, {
							costCodesAutoOpen: Date.now()
						});
					});
				} else if (Ember.isNone(selectedItem) && openedBy === 'auto') {
					// Logger.info(this, 'projectMenuClosed', 'revert to initial');

					Ember.run.next(this, function () {
						Ember.setProperties(_this5, {
							project: Ember.get(_this5, '_project'), // retrieve initial value
							costCode: Ember.get(_this5, '_costCode') // retrieve initial value
						});
					});
				}
			},
			costCodeMenuClosed: function costCodeMenuClosed(didSelectValue, selectedItem, openedBy) {
				var _this6 = this;

				// Logger.info(this, 'costCodeMenuClosed', didSelectValue, selectedItem, openedBy);

				// if the cost code menu was opened automatically by the double nav and the user hits back (!didSelectValue) then re-open the project selecter
				if (Ember.get(this, 'isLinkedCostCodesEnabled') && !didSelectValue && openedBy === 'auto') {
					Ember.run.next(this, function () {
						Ember.setProperties(_this6, {
							costCode: null,
							projectsAutoOpen: Date.now()
						});
					});
				}
			}
		}
	});
});