define('busy-app/mixins/verified-account', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		// session: service('session'),
		//
		// beforeModel(transition) {
		// 	if(!this.get('session.session.authenticated.verifiedEmail')) {
		// 		transition.abort();
		// 		this.get('session').set('attemptedTransition', transition);
		// 		this.transitionTo('verify-email');
		// 	} else {
		// 		return this._super.apply(this, arguments);
		// 	}
		// }
	});
});