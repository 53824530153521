define('busy-app/components/add-edit-scheduling', ['exports', '@busy-web/utils', 'moment'], function (exports, _utils, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	// import Logger from 'busy-app/utils/logger';

	/***/
	/**
  * @module Components
  *
  */
	var SYMBOL_KEYCODES = [8, 13, 33, 46, 186, 187, 188, 189, 190, 191, 192, 219, 220, 221, 222];

	/**
  * utils for setting up a date and ensuring it fits within the
  * min and max date range
  *
  * @method setupInitialDate
  * @param min {number} unix timestamp for min date
  * @param max {number} unix timestamp for max date
  * @param timestamp {number} unix timestamp
  * @param defaultDate {number} unix timestamp used when time is undefined
  * @param defaultTime {number} the hour in 24 hour format to set the timestamp to
  * @return {number}
  */
	function setupInitialDate(min, max, timestamp, defaultDate, defaultTime) {
		// set start time and check if it was provided
		// by the model
		var time = timestamp;
		if (Ember.isNone(time)) {
			// no model start so use component start date
			time = defaultDate;

			// make sure the date is within the bounds
			if (Ember.isNone(time) || time < min) {
				time = min;
			} else if (time > max) {
				time = max;
			}

			// set the hour default for the start time.
			time = _utils.Time.date(time).startOf('day').add(defaultTime || 0, 'seconds').unix();
		}
		return time;
	}

	/**
  * `Components/AddEditScheduling`
  *
  * @class Edit
  * @namespace Components.AddEditScheduling
  * @extends Component
  */
	exports.default = Ember.Component.extend({
		classNames: ['c-add-edit-scheduling'],

		store: Ember.inject.service('store'),
		auth: Ember.inject.service('auth'),

		// componet public props
		isAdd: false,
		isBreak: false,
		isCopy: false,

		reminder: null,
		__reminder: null,

		startDate: null,
		endDate: null,

		// component private props
		startTime: null,
		endTime: null,
		minDate: null,
		maxDate: null,

		multipleDays: false,
		instructions: '',
		members: null,
		project: null,
		costCode: null,
		equipment: null,

		selectedEmployees: null,
		__originalEmployees: null,
		__addEmployees: null,
		__removeEmployees: null,

		employeeSelectorPermissions: null,

		notifyEmployee: null,

		init: function init() {
			this._super();

			this.set('employeeSelectorPermissions', ['timeEvents']);

			this.set('__originalEmployees', []);
			this.set('__addEmployees', []);
			this.set('__removeEmployees', []);

			this.setupScheduleObject();
		},


		headerTitle: Ember.computed('isBreak', 'isAdd', 'isCopy', function () {
			var type = (0, _utils.loc)('Work');
			if (this.get('isBreak')) {
				type = (0, _utils.loc)('Break');
			}

			var action = (0, _utils.loc)('Schedule');
			if (this.get('isCopy')) {
				action = (0, _utils.loc)('Copy');
				type = (0, _utils.loc)('Item');
			} else if (!this.get('isAdd')) {
				action = (0, _utils.loc)('Edit');
			}

			return action + ' ' + type;
		}),

		timeRoundingInterval: Ember.computed('auth.organization.{timeRounding,timeRoundingInterval}', function () {
			var defaultInterval = 1;

			if (this.get('auth.organization.timeRounding')) {
				return parseInt(this.get('auth.organization.timeRoundingInterval') / 60, 10);
			} else {
				return defaultInterval;
			}
		}),

		costCodesAutoOpen: false,
		projectsAutoOpen: false,
		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),
		disableCostCodeSelection: Ember.computed('project.id', 'isLinkedCostCodesEnabled', function () {
			return Ember.get(this, 'isLinkedCostCodesEnabled') && Ember.isNone(Ember.get(this, 'project.id'));
		}),

		setupScheduleObject: function setupScheduleObject() {
			var reminder = this.get('reminder');
			if (!Ember.isNone(reminder)) {
				// set is break to true if the edit/copy reminder is a break
				if (reminder.get('isBreak')) {
					this.set('isBreak', true);
				}

				// get selected employees from original reminder
				this.setSelectedEmployees(reminder.get('scheduleLinks'));

				if (this.get('isCopy')) {
					reminder = this.copyScheduleReminder(reminder);
				}

				// get riminder props

				var _reminder$getProperti = reminder.getProperties('instructions', 'project', 'costCode', 'equipment', 'scheduleLinks', 'startTime', 'endTime'),
				    instructions = _reminder$getProperti.instructions,
				    project = _reminder$getProperti.project,
				    costCode = _reminder$getProperti.costCode,
				    equipment = _reminder$getProperti.equipment,
				    scheduleLinks = _reminder$getProperti.scheduleLinks,
				    startTime = _reminder$getProperti.startTime,
				    endTime = _reminder$getProperti.endTime;

				if (!Ember.isEmpty(instructions)) {
					this.__textHistoryOriginalValue = instructions;
				}

				this.setupDates(startTime, endTime);

				// set common props
				this.setProperties({ project: project, costCode: costCode, equipment: equipment, instructions: instructions, existingLinks: scheduleLinks });
			} else {
				(false && !(this.get('isAdd')) && Ember.assert("reminder is not defined. A scheduleReminder model is required for edit or copy operations in add-edit-schedule dialog", this.get('isAdd')));


				this.setupDates();

				reminder = this.createScheduleReminder();
			}

			this.set('__reminder', reminder);
		},


		/**
   * Setup initial start and end times for the date selectors.
   *
   * if start and end are not found on a ScheduleReminder model
   * then the default is to use the dates provided by the caller
   * of this component.
   *
   * @method setupDates
   * @param start {number} optional
   * @param end {number} optional
   * @return {void}
   */
		setupDates: function setupDates(start, end) {
			// min date is set for the start of tommorrow
			var minDate = _utils.Time.date().startOf('day').unix();

			// max date is set for 3 years into the future
			var maxDate = _utils.Time.date().add(1, 'years').endOf('day').unix();

			// get default start and end time
			var defaultStartTime = this.get('auth.organization.defaultStartTime');
			var defaultEndTime = this.get('auth.organization.defaultEndTime');

			// if break type then use 12 pm plus duration
			if (this.get('isBreak')) {
				var duration = this.get('auth.organization.defaultBreakDuration');

				if (Ember.isNone(duration) || isNaN(duration)) {
					// default break is 1 hour or 3600 seconds
					duration = _moment.default.duration('01:00:00').asSeconds();
				}
				defaultStartTime = _moment.default.duration('12:00:00').asSeconds();
				defaultEndTime = defaultStartTime + duration;
			}

			// date provided to component as the start and end dates
			var defaultStartDate = this.get('startDate');
			var defaultEndDate = this.get('endDate') || defaultStartDate;

			// calls setupInitialDate to create a start date
			// from the correct source
			var startTime = setupInitialDate(minDate, maxDate, start, defaultStartDate, defaultStartTime);

			// calls setupInitialDate to create a end date
			// from the correct source
			var endTime = setupInitialDate(minDate, maxDate, end, defaultEndDate, defaultEndTime);

			// set init props
			this.setProperties({ minDate: minDate, maxDate: maxDate, startDate: startTime, endDate: endTime, startTime: startTime, endTime: endTime });
		},


		/**
   * Create a new ScheduleReminder Model
   *
   * @method createScheduleReminder
   * @param props {object}
   * @return {ScheduleReminder}
   */
		createScheduleReminder: function createScheduleReminder() {
			var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

			// set required properties
			var requiredProps = {
				id: _utils.UUID.generate(),
				organizationId: this.get('auth.organization.id'),
				memberId: this.get('auth.member.id')
			};

			// set default properties
			var defaultProps = {
				type: this.get('isBreak') ? 'break' : 'work'
			};

			// craete ScheduleReminder Model
			// defaultProps will be overridden by props
			// props will be overridden by requiredProps
			return this.get('store').createRecord('schedule-reminder', Object.assign(defaultProps, props, requiredProps));
		},


		/**
   * Creates a copy of a schedule-reminder model
   *
   * @method copyScheduleReminder
   * @param reminder {ScheduleReminder}
   * @param props {object}
   * @return {ScheduleReminder}
   */
		copyScheduleReminder: function copyScheduleReminder(reminder) {
			var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
			(false && !(!Ember.isNone(reminder)) && Ember.assert("`reminder` was not defined. scheduleReminder Model is required for copyScheduleReminder", !Ember.isNone(reminder)));


			var json = reminder.toJSON();
			return this.createScheduleReminder(Object.assign(json, props));
		},


		/**
   * create an array of selected members from an array
   * of schedule-reminder-links
   *
   * @method setSelectedEmployees
   * @param links {ScheduleReminderLink}
   * @return {Member[]}
   */
		setSelectedEmployees: function setSelectedEmployees(links) {
			var _this = this;

			var members = Ember.RSVP.all(links.map(function (l) {
				return Ember.RSVP.resolve(l.get('member'));
			}));

			return members.then(function (m) {
				if (!_this.get('isDestroyed')) {
					var emp = m.slice(0).sortBy('fistName', 'lastName');
					_this.set('selectedEmployees', emp);
					_this.set('__originalEmployees', emp.slice(0));
					_this.set('__addEmployees', []);
					_this.set('__removeEmployees', []);
				}
				return m;
			});
		},


		/**
   * Generates an array of reminder models from the startDate to the endDate,
   * creating an model for each day.
   *
   * @method generateMultiple
   * @param reminder {ScheduleReminder}
   * @return {ScheduleReminder[]}
   */
		generateMultiple: function generateMultiple(reminder) {
			// start and end days
			var _getProperties = this.getProperties('startDate', 'endDate'),
			    startDate = _getProperties.startDate,
			    endDate = _getProperties.endDate;

			// method for adding a day to the timestamp


			var addADay = function addADay(timestamp) {
				return _utils.Time.date(timestamp).add(1, 'days').unix();
			};

			// start the currentDate as the startTime plus one day because
			// the first model is already inserted into the array
			var currentDate = addADay(startDate);

			// model array
			var models = [reminder];

			// create a copy of the reminder for each day
			while (currentDate <= endDate) {
				// get start and end date for this reminder day
				var _generateTime = this.generateTime(currentDate),
				    startTime = _generateTime.startTime,
				    endTime = _generateTime.endTime;

				// create a copy of the reminder


				var reminderCopy = this.copyScheduleReminder(reminder, { startTime: startTime, endTime: endTime });

				// add the model to the model array
				models.pushObject(reminderCopy);

				// move the currentDate ahead on day.
				currentDate = addADay(currentDate);
			}

			// return array of schedule-reminder models
			return models;
		},


		/**
   * generates the start and end times based on the startDate
   * passed in. Date are the hours:minutes from the time selector and
   * the year month day from the date selector
   *
   * @method generateTime
   * @param date {number} Year-month-day timestamp
   * @return {object(number, number)}
   */
		generateTime: function generateTime(date) {
			(false && !(!Ember.isNone(date) && !isNaN(date) && typeof date === 'number') && Ember.assert("date is required to be a number", !Ember.isNone(date) && !isNaN(date) && typeof date === 'number'));

			// creates a start and end time (based on the this.startTime and this.endTime)
			// for the the date that was provided.
			//
			// NOTE: **This example uses strings, but the actual method requires `timestamps` and the results are `timestamps` **
			// @example:
			//	(startTime="9:00 AM", endTime="5:00 PM", date="09/01/2018") => { startTime: "09/01/2018 9:00 AM", endTime: "09/01/2018 5:00 PM" };
			//
			//	(startTime="10:00 PM", endTime="4:00 AM", date="12/01/2018") => { startTime: "12/01/2018 10:00 PM", endTime: "12/02/2018 4:00 AM" };
			//

			var _updateTimeInputDates = updateTimeInputDates(this.get('startTime'), this.get('endTime'), date),
			    startTime = _updateTimeInputDates.startTime,
			    endTime = _updateTimeInputDates.endTime;

			return { startTime: startTime, endTime: endTime };
		},


		/**
   * Saves a new schedule-reminder model or models
   *
   * @method saveSchedule
   * @param startTime {number}
   * @param endTime {number}
   * @param multipleDays {boolean}
   * @return {Promise<ScheduleReminder>}
   */
		saveSchedule: function saveSchedule(startTime, endTime) {
			var _this2 = this;

			var multipleDays = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			var reminder = this.get('__reminder');
			var payload = this.createPayload();

			reminder.setProperties({ startTime: startTime, endTime: endTime, payload: payload });

			var models = [reminder];
			if (multipleDays) {
				models = this.generateMultiple(reminder);
			}

			return Ember.RSVP.all(models.map(function (model) {
				var modelPromise = Ember.RSVP.resolve(model);
				if (model.get('isNew') || model.get('hasDirtyAttributes')) {
					modelPromise = model.save();
				}

				return modelPromise.then(function (savedModel) {
					return _this2.saveLinks(savedModel);
				});
			}));
		},


		/**
   * Create and save the schedule-reminder-links for
   * a saved schedule-reminder
   *
   * @method saveLinks
   * @param model {ScheduleReminder}
   * @return {Promise<ScheduleReminderLink>}
   */
		saveLinks: function saveLinks(model) {
			var _this3 = this;

			var addMemberLinks = Ember.RSVP.resolve([]);
			var removeMemberLinks = Ember.RSVP.resolve([]);

			// get selected members array
			var members = this.get('selectedEmployees');
			if (this.get('isEdit')) {
				members = this.get('__addEmployees');

				var removeMembers = this.get('__removeEmployees');
				if (!Ember.isEmpty(removeMembers)) {
					removeMemberLinks = Ember.RSVP.all(removeMembers.map(function (member) {
						var link = _this3.get('__reminder.scheduleLinks').findBy('memberId', member.id);
						if (link) {
							link.set('deletedOn', _utils.Time.timestamp());
							return link.save();
						} else {
							return Ember.RSVP.resolve();
						}
					}));
				}
			}

			if (!Ember.isEmpty(members)) {
				// save all schedule-reminder-link models
				addMemberLinks = Ember.RSVP.all(members.map(function (member) {
					return _this3.get('store').createRecord('schedule-reminder-link', {
						scheduleReminderId: model.id,
						memberId: member.id
					}).save();
				}));
			}

			return Ember.RSVP.hash({ addMemberLinks: addMemberLinks, removeMemberLinks: removeMemberLinks }).then(function () {
				// refresh the model links from the server
				return _this3.get('store').findAll('schedule-reminder-link', { schedule_reminder_id: model.id }).then(function (links) {
					_this3.setSelectedEmployees(links);
					model.set('scheduleLinks', links);
					return model;
				});
			});
		},


		/**
   * creates a JSON string for the payload object
   *
   * @method createPayload
   * @return {string}
   */
		createPayload: function createPayload() {
			return JSON.stringify({
				project: this.get('project.id'),
				equipment: this.get('equipment.id'),
				instructions: (this.get('instructions') || '').trim(),
				cost_code: this.get('costCode.id')
			});
		},


		__textAreaHistory: null,
		__textAreaFuture: null,

		didInsertElement: function didInsertElement() {
			this._super();

			// sets up a listener to auto adjust the height
			// of the text area when more height is needed.
			this.autoAdjustTextArea();
		},
		autoAdjustTextArea: function autoAdjustTextArea() {
			var el = this.$('.instructions-input');
			if (el.length) {
				el = el.get(0);
				var minHeight = el.scrollHeight;
				el.addEventListener('input', function () {
					var outerHeight = parseInt(window.getComputedStyle(el).height, 10);
					var diff = outerHeight - el.clientHeight;
					var scrollHeight = el.scrollHeight;
					var height = Math.max(minHeight, scrollHeight + diff);

					el.style.height = 0;
					el.style.height = height + 'px';
				});
			}
		},


		actions: {
			close: function close() {
				this.sendAction('onClose');
			},
			addEmployees: function addEmployees(items) {
				// items is the new selectedEmployees list
				this.set('selectedEmployees', items);

				// originals is the list of members found from an edit or
				// copy models scheduled links.
				var originals = this.get('__originalEmployees');

				// added is any members in the items list that is not in the originals list
				var added = items.filter(function (m) {
					return !originals.find(function (orig) {
						return orig.id === m.id;
					});
				});

				// removed is any memebers in the originals list but not in the items list
				var removed = originals.filter(function (m) {
					return !items.find(function (it) {
						return it.id === m.id;
					});
				});

				// update model lists
				this.set('__addEmployees', added);
				this.set('__removeEmployees', removed);
			},
			saveScheduleAction: function saveScheduleAction() {
				var _this4 = this;

				var _getProperties2 = this.getProperties('startDate', 'endDate', 'minDate', 'multipleDays'),
				    startDate = _getProperties2.startDate,
				    endDate = _getProperties2.endDate,
				    minDate = _getProperties2.minDate,
				    multipleDays = _getProperties2.multipleDays;

				// generate start and end times


				var _generateTime2 = this.generateTime(startDate),
				    startTime = _generateTime2.startTime,
				    endTime = _generateTime2.endTime;

				if (startTime < minDate) {
					if (!this.get('isEdit')) {
						return Ember.RSVP.reject((0, _utils.loc)("Reminder must be scheduled in the future."));
					} else if (_utils.Time.date(startTime).format('MM-DD-YYYY') !== _utils.Time.date(this.get('__reminder.startTime')).format('MM-DD-YYYY') || _utils.Time.date(endTime).format('MM-DD-YYYY') !== _utils.Time.date(this.get('__reminder.endTime')).format('MM-DD-YYYY')) {
						// Reminders in the past can be edited, so long as the date is not edited
						return Ember.RSVP.reject((0, _utils.loc)("Dates of historical reminders cannot be edited."));
					}
				} else if (startTime > endTime || multipleDays && startDate > endDate) {
					return Ember.RSVP.reject((0, _utils.loc)("The start time must be before the end time"));
				} else if (Ember.isEmpty(this.get('selectedEmployees'))) {
					return Ember.RSVP.reject((0, _utils.loc)("At least one employee must be selected in order to save a schedule item."));
				}

				return this.saveSchedule(startTime, endTime, multipleDays).then(function () {
					return _this4.sendAction('afterSave');
				});
			},
			projectSelected: function projectSelected(project) {
				if (Ember.isNone(project)) {
					this.set('projectId', null);
				} else {
					this.set('projectId', project.get('id'));
				}

				if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
					Ember.set(this, 'costCode', null);
				}
			},
			costCodeSelected: function costCodeSelected(costCode) {
				if (Ember.isNone(costCode)) {
					this.set('costCodeId', null);
				} else {
					this.set('costCodeId', costCode.get('id'));
				}
			},
			equipmentSelected: function equipmentSelected(equipment) {
				if (Ember.isNone(equipment)) {
					this.set('equipmentId', null);
				} else {
					this.set('equipmentId', equipment.get('id'));
				}
			},


			/**
    * adjust start and end time when startDate is changed
    *
    * @method dateChange
    */
			dateChange: function dateChange(type) {
				if (type === 'start') {
					var startDate = this.get('startDate');

					if (!this.get('multipleDays') || startDate > this.get('endDate')) {
						// update the date for endDate
						this.set('endDate', adjustDate(this.get('endDate'), startDate));
					}

					var _updateTimeInputDates2 = updateTimeInputDates(this.get('startTime'), this.get('endTime'), startDate),
					    startTime = _updateTimeInputDates2.startTime,
					    endTime = _updateTimeInputDates2.endTime;

					this.set('startTime', startTime);
					this.set('endTime', endTime);
				}
			},


			/**
    * adjust start or end time when the opposite is changed
    * if the date is now overlapping.
    *
    * @method timeChange
    */
			timeChange: function timeChange() {
				var _updateTimeInputDates3 = updateTimeInputDates(this.get('startTime'), this.get('endTime'), this.get('startDate')),
				    startTime = _updateTimeInputDates3.startTime,
				    endTime = _updateTimeInputDates3.endTime;

				this.set('startTime', startTime);
				this.set('endTime', endTime);
			},
			instructionsKeyEvent: function instructionsKeyEvent(value, event) {
				var _this5 = this;

				// get keycode
				var keyCode = event.keyCode || event.which;

				// setup return event value
				event.returnValue = true;

				if (keyCode === 9) {
					// handles tab key so tabs can be used in text area
					preventDefault(event);
					// save end cursor position
					this.__lastCursorPosition = event.target.selectionEnd;

					// get new value
					var nValue = handleTabKey(value, event);

					// adjust cusor position for new chars or removed chars
					this.__lastCursorPosition += nValue.length - value.length;

					// update input value
					this.set('instructions', nValue);

					// update cursor after the input is uptdated
					Ember.run.next(function () {
						// reset cursor position
						event.target.setSelectionRange(_this5.__lastCursorPosition, _this5.__lastCursorPosition);
					});

					// update history
					this.__textAreaHistory = updateState(this.__textAreaHistory, value);
				} else if (keyCode === 90 && isModifierKeyActive(event)) {
					// handles undo with [ cmd/ctrl + z ]
					preventDefault(event);
					if (!Ember.isEmpty(this.__textAreaHistory.pop())) {
						var lastValue = this.__textAreaHistory.pop();
						if (!Ember.isEmpty(lastValue)) {
							this.set('instructions', lastValue);
							this.__textAreaHistory = updateState(this.__textAreaHistory, lastValue);
							this.__textAreaFuture = updateState(this.__textAreaFuture, value);
						} else {
							this.set('instructions', this.__textHistoryOriginalValue || '');
						}
					}
				} else if (keyCode === 82 && isModifierKeyActive(event)) {
					// handles redo with [ cmd/ctrl + r ]
					preventDefault(event);
					var _lastValue = this.__textAreaFuture.pop();
					if (!Ember.isEmpty(_lastValue)) {
						this.set('instructions', _lastValue);
						this.__textAreaHistory = updateState(this.__textAreaHistory, _lastValue);
					}
				}

				return event.returnValue;
			},
			instructionsKeyUpEvent: function instructionsKeyUpEvent(value, event) {
				// get event key code
				var keyCode = event.keyCode || event.which;

				// check is number, numPad, letter, or symbol
				var isNumber = keyCode >= 48 && keyCode <= 57;
				var isNumPad = keyCode >= 96 && keyCode <= 111;
				var isLetter = keyCode >= 65 && keyCode <= 90;
				var isSymbol = SYMBOL_KEYCODES.indexOf(keyCode) !== -1;

				// if key is allowed then update state
				if (!isModifierKeyActive(event) && (isNumber || isNumPad || isLetter || isSymbol)) {
					this.__textAreaHistory = updateState(this.__textAreaHistory, value);
					this.__textAreaFuture = [];
				}
			},
			projectMenuClosed: function projectMenuClosed(didSelectValue, selectedItem, openedBy) {
				var _this6 = this;

				var reminder = this.get('reminder');

				if (didSelectValue && Ember.get(this, 'isLinkedCostCodesEnabled')) {
					Ember.run.next(this, function () {
						Ember.setProperties(_this6, {
							costCodesAutoOpen: Date.now()
						});
					});
				} else if (Ember.isNone(selectedItem) && openedBy === 'auto' && !Ember.isNone(reminder)) {
					// Logger.info(this, 'projectMenuClosed', 'revert to initial');

					Ember.run.next(this, function () {
						Ember.setProperties(_this6, {
							project: Ember.get(reminder, 'project'), // retrieve initial value
							costCode: Ember.get(reminder, 'costCode') // retrieve initial value
						});
					});
				}
			},
			costCodeMenuClosed: function costCodeMenuClosed(didSelectValue, selectedItem, openedBy) {
				var _this7 = this;

				// Logger.info(this, 'costCodeMenuClosed', didSelectValue, selectedItem, openedBy);

				// if the cost code menu was opened automatically by the double nav and the user hits back (!didSelectValue) then re-open the project selecter
				if (Ember.get(this, 'isLinkedCostCodesEnabled') && !didSelectValue && openedBy === 'auto') {
					Ember.run.next(this, function () {
						Ember.setProperties(_this7, {
							costCode: null,
							projectsAutoOpen: Date.now()
						});
					});
				}
			}
		}
	});


	var handleTabKey = function handleTabKey(value, event) {
		var selectionStart = event.target.selectionStart;
		var selectionEnd = event.target.selectionEnd;

		var selected = value.slice(selectionStart, selectionEnd);
		var preSelected = value.slice(0, selectionStart);
		var postSelected = value.slice(selectionEnd);

		if (/^\n/.test(selected)) {
			var preSelectStart = preSelected.lastIndexOf('\n');
			if (preSelectStart !== -1) {
				selected = value.slice(preSelectStart, selectionEnd);
				preSelected = value.slice(0, preSelectStart);
			}
		}

		if (value[selectionEnd - 1] === "\n") {
			var postSelectEnd = postSelected.indexOf('\n');
			if (postSelectEnd !== -1) {
				selected = value.slice(selectionStart, selectionEnd + postSelectEnd);
				postSelected = value.slice(selectionEnd + postSelectEnd);
			}
		}

		if (event.shiftKey) {
			// handle tab back
			if (selected.length) {
				selected = selected.split('\n').map(function (s) {
					return s.replace(/\t/, '');
				}).join('\n');
			} else {
				preSelected = preSelected.replace(/\t$/, '');
			}
		} else {
			// handle tab forward
			if (selected.length) {
				selected = selected.split('\n').map(function (s) {
					return '\t' + s;
				}).join('\n');
			} else {
				selected = '\t';
			}
		}

		return preSelected + selected + postSelected;
	};

	var adjustDate = function adjustDate(date, toDate) {
		var _time = _utils.Time.date(toDate);

		// adjust the year month and day or time inputs
		// to match the startDate input
		return _utils.Time.date(date).set({
			year: _time.year(),
			month: _time.month(),
			date: _time.date()
		}).unix();
	};

	var updateTimeInputDates = function updateTimeInputDates(start, end, date) {
		var startTime = adjustDate(start, date);
		var endTime = adjustDate(end, date);
		if (endTime < startTime) {
			endTime = _utils.Time.date(endTime).add(1, 'days').unix();
		}
		return { startTime: startTime, endTime: endTime };
	};

	var isModifierKeyActive = function isModifierKeyActive(event) {
		var keyboard = getKeyboardStyle();
		if (keyboard === 'MAC' && event.metaKey) {
			return true;
		} else if (keyboard === 'PC' && event.ctrlKey) {
			return true;
		}
		return false;
	};

	var getKeyboardStyle = function getKeyboardStyle() {
		if (/Macintosh/.test(window.navigator.userAgent)) {
			return 'MAC';
		} else {
			return 'PC';
		}
	};

	var preventDefault = function preventDefault(event) {
		event.stopPropagation();
		event.preventDefault();
		event.returnValue = false;
	};

	var updateState = function updateState(prevState, value) {
		var state = (prevState || []).slice(-49);
		state.push(value);
		return state;
	};
});