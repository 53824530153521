define('busy-app/models/project-annual-cost', ['exports', 'ember-data', 'busy-app/models/project-day-cost'], function (exports, _emberData, _projectDayCost) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _projectDayCost.default.extend({
    // used to differentiate uid for month and day models
    type: "annual",

    startDate: _emberData.default.attr('date', { format: 'YYYY', sortable: true, sortBy: ['startDate'] })
  });
});