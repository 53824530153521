define('busy-app/components/time/time-conflict/conflict-list', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['conflict-list'],

		proFeature: Ember.inject.service('pro-feature'),
		store: Ember.inject.service('store'),

		model: null,
		start: null,
		end: null,
		project: null,
		memberId: null,
		groupId: null,

		isDisabled: false,

		conflictDay: null,
		title: null,
		conflictArray: null,
		startTime: null,
		endTime: null,
		employee: null,

		showConflict: false,
		showMemberListDialog: false,
		hasInitialized: false,

		showTop: false,
		showMore: false,

		init: function init() {
			this._super();
			this.setConflictArray();
		},
		setConflictArray: function setConflictArray() {
			var _this = this;

			this.set('hasInitialized', true);
			if (this.get('allowConflictResolution')) {
				this.get('store').manager('member-conflicts', this.get('memberId'), this.get('start'), this.get('end'), this.get('project'), this.get('groupId')).then(function (data) {
					if (!_this.get('isDestroyed')) {
						_this.set('totalConflicts', data.reduce(function (a, b) {
							return a + b.get('totalConflicts');
						}, 0));
						_this.set('model', data);
					}
				});
			}
		},


		allowConflictResolution: Ember.computed('features.conflict-resolution', function () {
			this.get('features.conflict-resolution');
			return this.get('proFeature').allowProFeature('conflict-resolution');
		}),

		conflicModels: Ember.computed('model.@each.model', function () {
			return this.get('model');
		}),

		realodTime: Ember.observer('memberId', 'start', 'end', function () {
			if (this.get('hasInitialized')) {
				this.set('model', null);
				this.setConflictArray();
			}
		}),

		hasConflicts: Ember.observer('totalConflicts', function () {
			var totalConflicts = this.get('totalConflicts');
			var container = Ember.$('#c-conflict-resolution-banner-container');
			var text = (0, _utils.loc)("%@ time entries with conflicts.", [totalConflicts]);
			if (totalConflicts === 1) {
				text = (0, _utils.loc)("1 time entry with conflicts.", [totalConflicts]);
			}

			container.find('div > span').html(text);

			if (totalConflicts) {
				if (!container.hasClass('show')) {
					container.addClass('show');
					Ember.run.later(function () {
						return container.addClass('front');
					}, 300);
					Ember.$('body > .ember-view').addClass('conflict-bar');
				}
			} else {
				container.removeClass('front');
				Ember.run.later(function () {
					return container.removeClass('show');
				}, 100);
				Ember.$('body > .ember-view').removeClass('conflict-bar');
			}
		}),

		didInsertElement: function didInsertElement() {
			var _this2 = this;

			var viewId = this.get('elementId');
			var container = Ember.$('#c-conflict-resolution-banner-container');
			var action = container.find('div > a');

			action.unbind('click.' + viewId);
			action.bind('click.' + viewId, function () {
				return _this2.send('openMemberListDialog');
			});
		},
		willDestroyElement: function willDestroyElement() {
			// remove conflict-bar class from main div
			Ember.$('body > .ember-view').removeClass('conflict-bar');

			var viewId = this.get('elementId');
			var container = Ember.$('#c-conflict-resolution-banner-container');
			container.removeClass('top');
			container.removeClass('more');
			container.removeClass('front');
			container.removeClass('show');

			if (this.get('showTop')) {
				container.addClass('top');
			}

			if (this.get('showMore')) {
				container.addClass('more');
			}

			var action = container.find('div > a');
			action.unbind('click.' + viewId);
		},


		actions: {
			openMemberListDialog: function openMemberListDialog() {
				this.set('showMemberListDialog', true);
			},
			closeMemberListDialog: function closeMemberListDialog() {
				if (!this.get('isDisabled')) {
					this.sendAction('onDone');
					this.set('showMemberListDialog', false);
					this.set('currentConflict', null);
				}
			},
			openConflicts: function openConflicts(model, member) {
				// set the view conflict to use later
				// if the conflicts are resolved
				this.set('viewConflict', model);

				// set the conflicts to ressolve.
				this.set('currentConflict', member);
			},
			closeConflicts: function closeConflicts() {
				var _this3 = this;

				// remove the member from the members list if all conflicts
				// are resolved.
				if (this.get('currentConflict.conflicts.length') === 0) {
					this.get('viewConflict.model.members').removeObject(this.get('currentConflict'));

					// remove the conflict from the list if all conflicts are resolved
					// for all members.
					if (this.get('viewConflict.model.members.length') === 0) {
						this.get('model').removeObject(this.get('viewConflict'));
					}
				}

				if (this.get('model.length') === 0) {
					Ember.run.later(function () {
						_this3.sendAction('onDone');
						_this3.set('showMemberListDialog', false);
					}, 4000);
				}

				// reset the dialog view state
				this.set('viewConflict', null);
				this.set('currentConflict', null);
			}
		}
	});
});