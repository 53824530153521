define('busy-app/routes/cost-code/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils'], function (exports, _authenticatedRouteMixin, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		auth: Ember.inject.service('auth'),

		queryParams: {
			group: {
				refreshModel: true
			},
			search: {
				refreshModel: true
			},
			page: {
				refreshModel: true
			},
			page_size: {
				refreshModel: true
			},
			filter: {
				refreshModel: true
			}
		},

		onSetup: Ember.on('activate', function () {
			this.setDefaultPageSize();
		}),

		resetController: function resetController(controller) {
			controller.resetFilters();
			this.setDefaultPageSize();
		},
		setDefaultPageSize: function setDefaultPageSize() {
			var qp = this.get('_qp');
			var pageSize = parseInt(_utils.LocalStorage.getProperty('member-pagination-size') || 30, 10);

			if (pageSize && qp && qp.qps) {
				var obj = qp.qps.findBy('prop', 'page_size');
				if (obj) {
					var defaultValue = parseInt(obj.defaultValue || 30, 10);
					if (defaultValue !== pageSize) {
						obj.defaultValue = pageSize;
						obj.serializedDefaultValue = pageSize;
						obj.undecoratedDefaultValue = pageSize;
					}
				}
			}
		},
		model: function model(params) {
			var _this = this;

			var controller = this.controllerFor('cost-code/index');
			controller.set('isLoading', true);

			return Ember.RSVP.hash({
				costCodes: this.getCostCodes(params),
				isProcoreIntegrationEnabled: this.getProcoreIntegrationStatus()
			}).then(function (_ref) {
				var costCodes = _ref.costCodes,
				    isProcoreIntegrationEnabled = _ref.isProcoreIntegrationEnabled;

				controller.set('isProcoreIntegrationEnabled', isProcoreIntegrationEnabled);
				_this.addNoInsuranceOption();
				controller.getGroups();

				return costCodes;
			}).finally(function () {
				controller.set('isLoading', false);
			});
		},
		getCostCodes: function getCostCodes(params) {
			var query = {
				_asc: ['cost_code', 'title'],
				page_size: params.page_size || 30,
				page: params.page || 1
			};

			if (params.group === 'archived') {
				query.archived_on = '!_-NULL-_';
				query.deleted_on = '_-DISABLE-_';
			} else if (params.group === 'ungrouped') {
				query.cost_code_group_id = null;
				query.archived_on = null;
			} else {
				if (!Ember.isEmpty(params.group)) {
					query.cost_code_group_id = params.group;
				}

				query.archived_on = null;
			}

			if (!Ember.isEmpty(params.search)) {
				query._search = params.search;
			}

			return this.store.query('cost-code', query).then(function (models) {
				models.forEach(function (model) {
					model.getChildren();
				});

				return models;
			});
		},
		getProcoreIntegrationStatus: function getProcoreIntegrationStatus() {
			// short circuit if the procore feature is not enabled
			if (!this.get('features').isEnabled('procore')) {
				return Ember.RSVP.resolve(false);
			}

			var query = {
				integrationName: 'procore',
				enabled: true
			};

			return this.store.query('organization-integration', query).then(function (procoreIntegration) {
				return !!procoreIntegration.get('length');
			});
		},
		addNoInsuranceOption: function addNoInsuranceOption() {
			var _this2 = this;

			var organizationId = this.get('auth.organization.id');
			var title = 'No Insurance';

			return Ember.RSVP.hash({
				workersComp: this.store.query('workers-comp', { title: title }).then(function (compRecord) {
					if (compRecord.get('length') !== 1) {
						if (compRecord.get('length') > 1) {
							compRecord.forEach(function (compItem) {
								return compItem.destroyRecord();
							});
						}
						return _this2.store.createRecord('workers-comp', { title: title, organizationId: organizationId }).save();
					}
				}),

				generalLiability: this.store.query('general-liability', { title: title }).then(function (generalRecord) {
					if (generalRecord.get('length') !== 1) {
						if (!Ember.isEmpty(generalRecord)) {
							generalRecord.forEach(function (genItem) {
								return genItem.destroyRecord();
							});
						}
						return _this2.store.createRecord('general-liability', { title: title, organizationId: organizationId }).save();
					}
				})
			});
		},


		actions: {
			refreshModel: function refreshModel() {
				return this.refresh();
			}
		}
	});
});