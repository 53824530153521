define('busy-app/services/access', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kLevelSelf = 1; /**
                      * @module Services
                      *
                      */

	var kLevelLower = 2;
	var kLevelSame = 4;
	var kLevelHigher = 8;

	//const kLevels = [kLevelHigher, kLevelSame, kLevelLower, kLevelSelf];

	/**
  * `Services/Access`
  *
  * @class Access
  * @namespace Services
  * @extends Service
  * @uses Services.Auth
  */
	exports.default = Ember.Service.extend({
		/**
   * @private
   * @property auth
   * @type Services.Auth
   */
		auth: Ember.inject.service('auth'),

		authPosition: Ember.computed.alias('auth.authMemberPosition'),
		ownerId: Ember.computed.alias('auth.organization.ownedBy'),
		authId: Ember.computed.alias('auth.member.id'),

		positions: Ember.computed.alias('auth.positions'),

		isOwner: function isOwner(forMemberId) {
			if (!Ember.isNone(forMemberId) && forMemberId === Ember.get(this, 'ownerId')) {
				return true;
			}

			return false;
		},
		hasPermissionFor: function hasPermissionFor(action) {
			var hasPermissionFor = false;
			if (!Ember.isNone(Ember.get(this, 'authPosition.id'))) {
				hasPermissionFor = this._hasPermissionFor(action);
			}
			return hasPermissionFor;
		},


		// can the authenticated user perform ANY of the actions
		hasPermissionForAny: function hasPermissionForAny(actions) {
			var _this = this;

			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isArray(actions);

			return actions.reduce(function (result, action) {
				return result || _this.hasPermissionFor(action);
			}, false);
		},


		// can the authenticated user perform EVERY action
		hasPermissionForAll: function hasPermissionForAll(actions) {
			var _this2 = this;

			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isArray(actions);

			return actions.reduce(function (result, action) {
				return result && _this2.hasPermissionFor(action);
			}, true);
		},
		canTakeActionFor: function canTakeActionFor(action, forMember) {
			var canTakeActionFor = false;

			if (!Ember.isNone(forMember) && !Ember.isNone(Ember.get(forMember, 'content'))) {
				forMember = Ember.get(forMember, 'content');
			}

			if (this.isOwner(Ember.get(this, 'authId'))) {
				canTakeActionFor = true;
			} else if (!Ember.isNone(forMember) && !Ember.isNone(forMember.id) && !Ember.isNone(Ember.get(this, 'authPosition.id'))) {
				canTakeActionFor = this._canTakeActionFor(action, forMember);
			}
			return canTakeActionFor;
		},


		// can the authenticated user perform ANY of the actions on the member
		canTakeActionForAny: function canTakeActionForAny(actions, forMember) {
			var _this3 = this;

			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isArray(actions);
			_utils.Assert.isObject(forMember);

			return actions.reduce(function (result, action) {
				return result || _this3.canTakeActionFor(action, forMember);
			}, false);
		},


		// can the authenticated user perform EVERY action on the member
		canTakeActionForAll: function canTakeActionForAll(actions, forMember) {
			var _this4 = this;

			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isArray(actions);
			_utils.Assert.isObject(forMember);

			return actions.reduce(function (result, action) {
				return result && _this4.canTakeActionFor(action, forMember);
			}, true);
		},
		_hasPermissionFor: function _hasPermissionFor(action) {
			_utils.Assert.test('The permission passed to hasPermissionFor does not exist.', Ember.get(this, 'authPosition.' + action) !== undefined);

			return Ember.get(this, 'authPosition.' + action);
		},
		_canTakeActionFor: function _canTakeActionFor(action, forMember) {
			_utils.Assert.test('The permission passed to canTakeActionFor does not exist.', Ember.get(this, 'authPosition.' + action) !== undefined);
			_utils.Assert.test('The canTakeAction forMember must have a position attached to the member', !Ember.isNone(Ember.get(forMember, 'positionId')));

			// the level of the forMember in relation to the level of
			// the auth member
			var targetLevel = this.permissionLevelType(forMember);

			// the auth members access level
			var accessLevel = Ember.get(this, 'authPosition.' + action);

			// check if the auth member has access to take action on the forMember
			var canTakeAction = this.canTakeAction(targetLevel, accessLevel);

			return canTakeAction;
		},
		canTakeAction: function canTakeAction(targetLevel, accessLevel) {
			var settings = this.parseSetting(accessLevel);
			var canTakeAction = false;

			// admin level access.
			if (accessLevel >= kLevelSelf + kLevelLower + kLevelSame + kLevelHigher) {
				canTakeAction = true;
			} else if (targetLevel === kLevelSelf && settings.self) {
				// self permisions
				canTakeAction = true;
			} else if (targetLevel === kLevelLower && settings.lower) {
				// lower permissions
				canTakeAction = true;
			} else if (targetLevel === kLevelSame && settings.same) {
				// same level permisions
				canTakeAction = true;
			} else if (targetLevel === kLevelHigher && settings.higher) {
				// higher level permissions
				canTakeAction = true;
			}
			return canTakeAction;
		},
		parseSetting: function parseSetting(value) {
			var higher = value >= kLevelHigher;
			value %= kLevelHigher;

			var same = value >= kLevelSame;
			value %= kLevelSame;

			var lower = value >= kLevelLower;
			value %= kLevelLower;

			var self = value >= kLevelSelf;

			return { higher: higher, same: same, lower: lower, self: self };
		},
		permissionLevelType: function permissionLevelType(forMember) {
			var forPosition = Ember.get(this, 'positions').findBy('id', Ember.get(forMember, 'positionId'));
			var type = 0;
			if (!Ember.isNone(forPosition)) {
				if (Ember.get(this, 'authId') === Ember.get(forMember, 'id')) {
					type = kLevelSelf;
				} else if (Ember.get(forPosition, 'level') === Ember.get(this, 'authPosition.level')) {
					type = kLevelSame;
				} else if (Ember.get(forPosition, 'level') > Ember.get(this, 'authPosition.level')) {
					type = kLevelLower;
				} else if (Ember.get(forPosition, 'level') < Ember.get(this, 'authPosition.level')) {
					type = kLevelHigher;
				}
			} else if (this.isOwner(Ember.get(this, 'authId'))) {
				type = 16;
			}
			return type;
		}
	});
});