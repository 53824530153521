define('busy-app/helpers/busy-loc', ['exports', '@busy-web/utils/loc'], function (exports, _loc) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		/**
   * Ember injected service auth
   *
   * @private
   * @property auth
   * @type {Services.Auth}
   */
		auth: Ember.inject.service('auth'),

		/**
   * Flag set to true if the helper is using a currency symbol
   *
   * @private
   * @property hasCurrency
   * @type {boolean}
   */
		hasCurrency: false,

		/**
   * Helpers compute override mehtod and main entry
   * mehtod of the template helper
   *
   * @public
   * @method compute
   * @param params {array} mixed values passed from the template
   * @param namedArgs {object} key value pair of args passed from the template
   * @return {string} the localized string
   */
		compute: function compute(params, namedArgs) {
			var string = params[0];
			var args = params.slice(1, params.length);
			var hasCurrency = namedArgs.hasCurrency;

			var str = '';
			if (hasCurrency) {
				this.set('hasCurrency', true);
				str = this.get('auth.currencySymbol');
			}

			str += (0, _loc.default)(string, args);
			return str;
		},


		/**
   * Ember observer triggers on currencySymbol change to rerender the template
   *
   * @private
   * @method rerender
   */
		rerender: Ember.observer('auth.currencySymbol', function () {
			if (this.get('hasCurrency')) {
				this.recompute();
			}
		})
	});
});