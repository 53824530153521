define('busy-app/utils/clock/math', ['exports', '@busy-web/ember-date-time/utils/clock/math'], function (exports, _math) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _math.default;
    }
  });
});