define('busy-app/utils/integrations/busy-event', ['exports', '@busy-web/utils', 'busy-app/utils/integrations/base', 'busy-app/utils/settings'], function (exports, _utils, _base, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		auth: Ember.inject.service('auth'),

		featureName: 'integrations/analytics',

		kPageViewType: 48,

		pageView: function pageView() {
			var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

			if (this.isEnabled()) {
				var path = window.location.pathname;
				title = (this.generateTitle(path) + '-' + title).classify();
				this.send(this.kPageViewType, { view: path, title: title });
			}
		},
		generateTitle: function generateTitle(path) {
			if (this.isEnabled()) {
				var titleParts = path.replace(/^\//, '').split('/');
				var title = "";
				if (titleParts.length === 1) {
					title = titleParts.objectAt(0);
					if (Ember.isEmpty(title)) {
						title = 'Dashboard';
					}
				} else if (titleParts.length === 2) {
					title = titleParts.objectAt(1);
				} else {
					title = titleParts.objectAt(2);
					if (/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(title)) {
						title = titleParts.objectAt(1);
					}
				}
				return title;
			}
			return '';
		},
		send: function send(type, params) {
			if (this.isEnabled()) {
				if (!Ember.isNone(this.get('auth.organization.id')) && !Ember.isNone(this.get('auth.member.id'))) {
					var data = {
						created_on: _utils.Time.timestamp(),
						source_device_type_id: 3,
						version: _settings.default.get('version'),
						event_type: type,
						member_id: this.get('auth.member.id'),
						organization_id: this.get('auth.organization.id'),
						content: JSON.stringify(params)
					};
					return this.ajax(data);
				} else {
					return Ember.RSVP.resolve({});
				}
			} else {
				return Ember.RSVP.resolve({});
			}
		},
		ajax: function ajax(data) {
			var _this = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {
				var authUser = _this.get('session.session.authenticated');
				if (!Ember.isNone(authUser.public_key) || !Ember.isNone(authUser.auth_hash)) {
					var hash = {};
					hash.url = _settings.default.get('api_url') + '/busy-event';
					if (_settings.default.get('api_version') > 3.1) {
						hash.url += '?_version=' + _settings.default.get('api_version');
					} else {
						hash.url += '?version=' + _settings.default.get('api_version');
					}

					if (_settings.default.get('debug_mode')) {
						hash.url += '&_debug=true';
					}

					hash.type = 'POST';
					hash.dataType = 'json';
					hash.data = data;

					hash.onSuccess = function (result) {
						Ember.run(null, resolve, result);
					};

					hash.onError = function (error) {
						Ember.Error(error);
						Ember.run(null, reject, error);
					};

					var headers = {};
					if (authUser.public_key !== undefined) {
						headers['Key-Authorization'] = authUser.public_key;
					} else if (authUser.auth_hash !== undefined) {
						headers.Authorization = 'Basic ' + authUser.auth_hash;
					}

					hash.beforeSend = function (xhr) {
						Object.keys(headers).forEach(function (key) {
							xhr.setRequestHeader(key, headers[key]);
						});
					};

					Ember.$.ajax(hash);
				} else {
					Ember.run(null, resolve, {});
				}
			});
		}
	});
});