define('busy-app/components/image-placeholder', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-image-placeholder'],

		/******** public props ********/

		/**
   * sets the altText on hover and
   * is used to create the initials placeholder
   * image
   *
   * @property altText
   * @type {string}
   */
		altText: '',

		/**
   * image url to use for the image
   *
   * @property image
   * @type {string}
   */
		image: '',

		/**
   * image url to use if image is not available
   *
   * @property backupImage
   * @type {string}
   */
		backupImage: '',

		isSixtyFour: false,

		/******** private props *********/
		__hasImage: false,
		__hasInitials: false,
		__initials: '',
		__backgroundStyle: '',

		/**
   * updates props hook
   *
   * @method didReceiveAttrs
   */
		didReceiveAttrs: function didReceiveAttrs() {
			this._super();

			this.__buildImage();
			this.setupElements();
		},
		didInsertElement: function didInsertElement() {
			this._super();
			this.setupElements();
		},
		setupElements: function setupElements() {
			if (this.$() && this.$().length) {
				if (Ember.get(this, '__hasInitials') && !Ember.get(this, '__hasImage')) {
					var el = this.$().find('.image > div');
					fitText(el);
				}
			}
		},


		/**
   * sets up the required private variables to show the image
   *
   * @private
   * @method __buildImage
   * @return {void}
   */
		__buildImage: function __buildImage() {
			var hasImage = false;
			var hasInitials = false;

			if (!Ember.isEmpty(Ember.get(this, 'altText'))) {
				var initials = generateInitials(Ember.get(this, 'altText'));
				if (!Ember.isEmpty(initials)) {
					Ember.set(this, '__initials', initials);
					hasInitials = true;
				}
			}

			var image = Ember.get(this, 'image');
			if (!Ember.isEmpty(image)) {
				hasImage = true;
				Ember.set(this, '__backgroundStyle', imageStyle(image));
			} else {
				image = Ember.get(this, 'backupImage');
				if (!Ember.isEmpty(image)) {
					hasImage = true;
					Ember.set(this, '__backgroundStyle', imageStyle(image));
				}
			}

			Ember.set(this, '__hasImage', hasImage);
			Ember.set(this, '__hasInitials', hasInitials);
		}
	});


	/**
  * Fits the text size to the element and centers the text vertically
  *
  * @method fitText
  * @param element {Element} DOM tree node
  * @param propagate {boolean} allows fitText to traverse up to the parent if needed
  * @return {void}
  */
	var fitText = function fitText(element) {
		var propagate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

		if (element && element.length) {
			// get element height
			var height = element.height();

			// if element height is greater than 200px
			// assume the element height is not correct
			// and dont set any attrs
			if (height < 200) {
				// set the font size to 40% of the height
				var fontSize = Math.floor(height * 0.4);

				// update the style for the element
				element.css({
					'line-height': height + 1 + 'px',
					'font-size': fontSize + 'px'
				});
			} else if (propagate) {
				// if height was not found try the parent element
				// but only propagate up one element to find a proper
				// height
				fitText(element.parent(), false);
			}
		}
	};

	/**
  * Takes a string and returns the first letter of
  * each word in the string
  *
  * @method generateInitials
  * @param name {string}
  * @return {string}
  */
	var generateInitials = function generateInitials(name) {
		if (Ember.isEmpty(name)) {
			return name;
		}

		name = name.replace(/^[^a-zA-Z]*$/g, '');

		var np = name.split(' ');
		return np.map(function (n, i) {
			return i === 0 || i === 1 ? n.slice(0, 1).toUpperCase() : '';
		}).join('');
	};

	/**
  * creates the style text string for the background image
  *
  * @method imageStyle
  * @param imageUrl {string} url for the image
  * @return {string} html safe string
  */
	var imageStyle = function imageStyle(imageUrl) {
		return (
			// return the safestring css background image.
			Ember.String.htmlSafe('background-image:url(' + imageUrl + ');background-repeat:no-repeat;background-size:cover;background-position:center;')
		);
	};
});