define('busy-app/utils/state', ['exports', 'ember-data', 'busy-app/utils/logger'], function (exports, _emberData, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.deserializeModel = exports.deserializeModelArray = exports.createStateRecord = exports.createStateRecordArray = exports.getState = exports.updateState = exports.createAppState = undefined;

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _possibleConstructorReturn(self, call) {
		if (!self) {
			throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
		}

		return call && (typeof call === "object" || typeof call === "function") ? call : self;
	}

	function _inherits(subClass, superClass) {
		if (typeof superClass !== "function" && superClass !== null) {
			throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
		}

		subClass.prototype = Object.create(superClass && superClass.prototype, {
			constructor: {
				value: subClass,
				enumerable: false,
				writable: true,
				configurable: true
			}
		});
		if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
	}

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var APP_STATE_PATH = "__asm__";
	var APP_GLOBAL = window || {};

	var __setState = function __setState(state) {
		return APP_GLOBAL[APP_STATE_PATH] = state;
	};

	var __getState = function __getState() {
		return APP_GLOBAL[APP_STATE_PATH];
	};

	var createAppState = exports.createAppState = function createAppState() {
		var initState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

		__setState(initState);

		return { setState: __setState, getState: __getState };
	};

	/**
  * updates the state with new state objects
  *
  */
	var updateState = exports.updateState = function updateState(_state) {
		(false && !(_state instanceof StateRecord || _state instanceof StateRecordArray) && Ember.assert("updateState requires a StateRecord or a StateRecordArray", _state instanceof StateRecord || _state instanceof StateRecordArray));


		var serializedRecord = void 0;
		if (_state instanceof StateRecord) {
			serializedRecord = serializeStateRecord(_state);
		} else if (_state instanceof StateRecordArray) {
			serializedRecord = serializeStateRecordArray(_state);
		}

		var app_state = Object.assign({}, __getState());
		Object.keys(serializedRecord).forEach(function (key) {
			app_state[key] = Object.assign({}, app_state[key], serializedRecord[key]);
		});

		// copy the state and merge the new state
		var state = Object.assign(__getState(), app_state);

		_logger.default.info("<utils:state>", { state: Object.assign({}, state), serializedRecord: Object.assign({}, serializedRecord) });

		// set new state
		__setState(state);

		// return new state object
		return getState(_state.__type, _state.id);
	};

	var getState = exports.getState = function getState(type, id) {
		var state = __getState();

		// get raw state from state object
		var rawState = Object.assign({}, state[type]);

		// result for state return
		var returnState = void 0;

		// if id is not provided then return a
		// stateRecordArray of the objects
		if (Ember.isEmpty(id)) {
			// get array of raw state objects
			var stateArray = Object.values(rawState).map(function (sa) {
				return new StateRecord(type, sa);
			});

			// create new record array state
			returnState = new StateRecordArray(type, stateArray);
		} else {
			// return only the StateRecord for the object matching the id
			returnState = new StateRecord(type, rawState[id]);
		}
		return returnState;
	};

	var createStateRecordArray = exports.createStateRecordArray = function createStateRecordArray(modelArray, type) {
		if (Ember.isEmpty(type)) {
			// modelArray is an ember record array object.
			type = Ember.get(modelArray || {}, 'modelName') || Ember.get(modelArray || {}, 'type');
		}

		(false && !(!Ember.isEmpty(type)) && Ember.assert("type was not found on the modelArray", !Ember.isEmpty(type)));


		return new StateRecordArray(type, modelArray);
	};

	var createStateRecord = exports.createStateRecord = function createStateRecord(model, type) {
		if (Ember.isEmpty(type)) {
			type = model._internalModel.modelName;
		}

		(false && !(!Ember.isEmpty(type)) && Ember.assert("type was not found on the model", !Ember.isEmpty(type)));


		return new StateRecord(type, model);
	};

	var deserializeModelArray = exports.deserializeModelArray = function deserializeModelArray(modelArray) {
		(false && !(Array.isArray(modelArray) || Array.isArray(Ember.get(modelArray, 'content'))) && Ember.assert('deserializeModelArray requires an Array, you passed [ ' + (typeof modelArray === 'undefined' ? 'undefined' : _typeof(modelArray)) + ' ]', Array.isArray(modelArray) || Array.isArray(Ember.get(modelArray, 'content'))));

		// get state array

		return modelArray.map(function (model) {
			return deserializeModel(model);
		});
	};

	var deserializeModel = exports.deserializeModel = function deserializeModel(model) {
		(false && !(isModel(model)) && Ember.assert('deserializeModel requires a DS.Model, you passed [ ' + (typeof model === 'undefined' ? 'undefined' : _typeof(model)) + ' ]', isModel(model)));

		// create state from model

		return model.toJSON({ includeId: true });
	};

	var StateRecord = function (_EmberObject) {
		_inherits(StateRecord, _EmberObject);

		function StateRecord(type) {
			var _ref;

			_classCallCheck(this, StateRecord);

			for (var _len = arguments.length, props = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
				props[_key - 1] = arguments[_key];
			}

			var _this = _possibleConstructorReturn(this, (_ref = StateRecord.__proto__ || Object.getPrototypeOf(StateRecord)).call.apply(_ref, [this].concat(props)));

			// set record type
			_this.__type = type;
			return _this;
		}

		_createClass(StateRecord, [{
			key: 'eachProperty',
			value: function eachProperty(cb) {
				var _this2 = this;

				var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
				(false && !(typeof cb === 'function') && Ember.assert("eachProperty requires a callback method", typeof cb === 'function'));


				Object.keys(this).forEach(function (key) {
					if (key !== '__type') {
						cb.call(target, _this2[key], key);
					}
				});
			}
		}, {
			key: 'getProperties',
			value: function getProperties() {
				var props = {};
				this.eachProperty(function (value, key) {
					return props[key] = value;
				});
				return props;
			}
		}, {
			key: 'serialize',
			value: function serialize() {
				var props = this.getProperties();
				return _defineProperty({}, props.id, props);
			}
		}], [{
			key: Symbol.species,
			get: function get() {
				return StateRecord;
			}
		}]);

		return StateRecord;
	}(Ember.Object);

	var ensureStateRecord = function ensureStateRecord(type, record) {
		if (!(record instanceof StateRecord)) {
			record = new StateRecord(type, record);
		}
		return record;
	};

	var StateRecordArray = function (_EmberObject$extend) {
		_inherits(StateRecordArray, _EmberObject$extend);

		function StateRecordArray(type, list) {
			_classCallCheck(this, StateRecordArray);

			var _this3 = _possibleConstructorReturn(this, (StateRecordArray.__proto__ || Object.getPrototypeOf(StateRecordArray)).call(this));

			_this3.__type = type;
			_this3.content = list.map(function (ms) {
				return ensureStateRecord(type, ms);
			});
			return _this3;
		}

		_createClass(StateRecordArray, [{
			key: 'replace',
			value: function replace() {
				var idx = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

				var _this4 = this;

				var amt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
				var objects = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

				// concat new content
				this.content = [].concat(this.slice(0, idx), objects.map(function (obj) {
					return ensureStateRecord(_this4.__type, obj);
				}), this.slice(idx, amt));

				// define getter for this[0] to this[...n]
				this.content.forEach(function (v, i) {
					return _this4.__defineGetter__(i, function () {
						return _this4.content[i];
					});
				});
				return this;
			}
		}, {
			key: 'serialize',
			value: function serialize() {
				var records = this.map(function (state) {
					return state.serialize();
				});
				if (!records.length) {
					records = [{}];
				}
				return Object.assign.apply(Object, _toConsumableArray(records));
			}
		}, {
			key: 'clone',
			value: function clone() {
				//const content = ensureStateRecord(this._type, this.content.slice(0));
				return new StateRecordArray(this.__type, this.slice(0));
			}
		}, {
			key: 'cloneEmpty',
			value: function cloneEmpty() {
				return new StateRecordArray(this.__type, []);
			}
		}, {
			key: 'pop',
			value: function pop() {
				return this.popObject();
			}
		}, {
			key: 'push',
			value: function push(record) {
				return this.pushObject(record);
			}
		}, {
			key: 'shift',
			value: function shift() {
				return this.shiftObject();
			}
		}, {
			key: 'unshift',
			value: function unshift(record) {
				return this.unshiftObject(record);
			}
		}, {
			key: 'toString',
			value: function toString() {
				return this.toString();
			}
		}, {
			key: 'valueOf',
			value: function valueOf() {
				return this;
			}
		}, {
			key: 'concat',
			value: function concat() {
				var _content;

				return (_content = this.content).concat.apply(_content, arguments);
			}
		}, {
			key: 'copyWithin',
			value: function copyWithin() {
				var _content2;

				return (_content2 = this.content).copyWithin.apply(_content2, arguments);
			}
		}, {
			key: 'entries',
			value: function entries() {
				var _content3;

				return (_content3 = this.content).entries.apply(_content3, arguments);
			}
		}, {
			key: 'every',
			value: function every() {
				var _content4;

				return (_content4 = this.content).every.apply(_content4, arguments);
			}
		}, {
			key: 'fill',
			value: function fill() {
				var _content5;

				return (_content5 = this.content).fill.apply(_content5, arguments);
			}
		}, {
			key: 'filter',
			value: function filter() {
				var _content6;

				return (_content6 = this.content).filter.apply(_content6, arguments);
			}
		}, {
			key: 'find',
			value: function find() {
				var _content7;

				return (_content7 = this.content).find.apply(_content7, arguments);
			}
		}, {
			key: 'findIndex',
			value: function findIndex() {
				var _content8;

				return (_content8 = this.content).findIndex.apply(_content8, arguments);
			}
		}, {
			key: 'flat',
			value: function flat() {
				var _content9;

				return (_content9 = this.content).flat.apply(_content9, arguments);
			}
		}, {
			key: 'flatMap',
			value: function flatMap() {
				var _content10;

				return (_content10 = this.content).flatMap.apply(_content10, arguments);
			}
		}, {
			key: 'forEach',
			value: function forEach() {
				var _content11;

				return (_content11 = this.content).forEach.apply(_content11, arguments);
			}
		}, {
			key: 'includes',
			value: function includes() {
				var _content12;

				return (_content12 = this.content).includes.apply(_content12, arguments);
			}
		}, {
			key: 'indexOf',
			value: function indexOf() {
				var _content13;

				return (_content13 = this.content).indexOf.apply(_content13, arguments);
			}
		}, {
			key: 'join',
			value: function join() {
				var _content14;

				return (_content14 = this.content).join.apply(_content14, arguments);
			}
		}, {
			key: 'keys',
			value: function keys() {
				var _content15;

				return (_content15 = this.content).keys.apply(_content15, arguments);
			}
		}, {
			key: 'lastIndexOf',
			value: function lastIndexOf() {
				var _content16;

				return (_content16 = this.content).lastIndexOf.apply(_content16, arguments);
			}
		}, {
			key: 'map',
			value: function map() {
				var _content17;

				return (_content17 = this.content).map.apply(_content17, arguments);
			}
		}, {
			key: 'reduce',
			value: function reduce() {
				var _content18;

				return (_content18 = this.content).reduce.apply(_content18, arguments);
			}
		}, {
			key: 'reduceRight',
			value: function reduceRight() {
				var _content19;

				return (_content19 = this.content).reduceRight.apply(_content19, arguments);
			}
		}, {
			key: 'reverse',
			value: function reverse() {
				var _content20;

				return (_content20 = this.content).reverse.apply(_content20, arguments);
			}
		}, {
			key: 'slice',
			value: function slice() {
				var _content21;

				return (_content21 = this.content).slice.apply(_content21, arguments);
			}
		}, {
			key: 'some',
			value: function some() {
				var _content22;

				return (_content22 = this.content).some.apply(_content22, arguments);
			}
		}, {
			key: 'sort',
			value: function sort() {
				var _content23;

				return (_content23 = this.content).sort.apply(_content23, arguments);
			}
		}, {
			key: 'splice',
			value: function splice() {
				var _content24;

				return (_content24 = this.content).splice.apply(_content24, arguments);
			}
		}, {
			key: 'toLocaleString',
			value: function toLocaleString() {
				var _content25;

				return (_content25 = this.content).toLocaleString.apply(_content25, arguments);
			}
		}, {
			key: 'toSource',
			value: function toSource() {
				var _content26;

				return (_content26 = this.content).toSource.apply(_content26, arguments);
			}
		}, {
			key: 'values',
			value: function values() {
				var _content27;

				return (_content27 = this.content).values.apply(_content27, arguments);
			}
		}, {
			key: 'length',
			get: function get() {
				return this.content.length;
			}
		}], [{
			key: Symbol.species,
			get: function get() {
				return StateRecordArray;
			}
		}]);

		return StateRecordArray;
	}(Ember.Object.extend(Ember.MutableArray));

	/**
  * return object of model-type objects
  *
  * @method serializeStateRecordArray
  * @param stateRecordArray {StateRecordArray}
  * @return {object}
  */
	var serializeStateRecordArray = function serializeStateRecordArray(stateRecordArray) {
		return _defineProperty({}, stateRecordArray.__type, stateRecordArray.serialize());
	};

	var serializeStateRecord = function serializeStateRecord(state) {
		return _defineProperty({}, state.__type, state.serialize());
	};

	var isObject = function isObject(value) {
		return !Ember.isNone(value) && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object';
	};

	var isModel = function isModel(value) {
		return isObject(value) && value instanceof _emberData.default.Model;
	};
});