define('busy-app/utils/touch', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = touch;


	function calculateChange(touchList, type) {
		var ids = touchList.mapBy('identifier').uniq();
		var res = [];
		ids.forEach(function (id) {
			var list = touchList.filterBy('identifier', id).sortBy('order');
			var start = list.get('firstObject.' + type);
			var end = list.get('lastObject.' + type);

			var change = end - start;
			var direction = void 0;
			if (type === 'x') {
				direction = change < 0 ? 'left' : change > 0 ? 'right' : 'none';
			} else if (type === 'y') {
				direction = change < 0 ? 'up' : change > 0 ? 'down' : 'none';
			}

			res.push({ id: id, change: change, direction: direction, start: start, end: end });
		});
		return res;
	}

	function getDoubleTouch(x, y) {
		var action = void 0;
		var xDirections = x.mapBy('direction').uniq();
		var yDirections = y.mapBy('direction').uniq();
		window.console.log(xDirections, yDirections);

		if (yDirections.length > 1 || xDirections.length > 1) {
			action = 'zoom';
			var xChangeStart = Math.abs(x[0].start - x[1].start);
			var xChangeEnd = Math.abs(x[0].end - x[1].end);
			var yChangeStart = Math.abs(y[0].start - y[1].start);
			var yChangeEnd = Math.abs(y[0].end - y[1].end);

			var xChange = Math.abs(xChangeStart - xChangeEnd);
			var yChange = Math.abs(yChangeStart - yChangeEnd);

			window.console.log(xChange, yChange, xChangeStart, xChangeEnd, yChangeStart, yChangeEnd);
			if (xDirections.length > 1 && (yDirections <= 1 || xChange >= yChange)) {
				if (xChangeStart < xChangeEnd) {
					action += '-in';
				} else {
					action += '-out';
				}
			} else if (yDirections.length > 1) {
				if (yChangeStart < yChangeEnd) {
					action += '-in';
				} else {
					action += '-out';
				}
			}
			return action;
		} else {
			return getSingleTouch(x, y);
		}
	}

	function getSingleTouch(x, y) {
		x = x[0];
		y = y[0];
		var changeVarient = (Math.abs(x.change) + Math.abs(y.change)) / 2;
		var action = 'slide';

		if (Math.abs(y.change) > changeVarient) {
			action += '-' + y.direction;
		}

		if (Math.abs(x.change) > changeVarient) {
			action += '-' + x.direction;
		}

		return action;
	}

	function touch() {

		var touchEvents = Ember.Object.extend(Ember.Evented, {}).create();
		var TouchHandler = Ember.Object.extend({
			list: null,

			__setTouch: function __setTouch(type, event, order) {
				var _this = this;

				var list = event.touches || [];
				Object.keys(list).forEach(function (key) {
					var touch = list[key];
					var x = void 0,
					    y = void 0,
					    identifier = void 0;
					if (touch) {
						x = touch.clientX;
						y = touch.clientY;
						identifier = touch.identifier;
					}

					_this.list.pushObject({ type: type, identifier: identifier, x: x, y: y, order: order, _event: event });
				});
			},


			touchIds: Ember.computed(function () {
				return this.list.mapBy('identifier').uniq();
			}),

			touchStart: Ember.computed(function () {
				return this.list.filterBy('type', 'start');
			}),

			isTouch: Ember.computed(function () {
				return this.list.length === 1;
			}),

			isSlide: Ember.computed(function () {
				return this.list.length > 1;
			}),

			xDir: Ember.computed(function () {
				if (this.get('isSlide')) {
					return calculateChange(this.list, 'x');
				} else {
					return null;
				}
			}),

			yDir: Ember.computed(function () {
				if (this.get('isSlide')) {
					return calculateChange(this.list, 'y');
				} else {
					return null;
				}
			}),

			description: Ember.computed(function () {
				var x = this.get('xDir');
				var y = this.get('yDir');
				var fingers = this.get('touchIds.length');

				window.console.log('fingers', fingers);

				var action = 'touch';
				if (fingers === 1 && this.get('isSlide')) {
					action = getSingleTouch(x, y);
				} else if (fingers === 2) {
					action = getDoubleTouch(x, y);
				}

				return action;
			})
		});

		var touchHandler = void 0;
		var counter = 0;
		Ember.$(document.body).on('touchstart', function () {
			if (!touchHandler) {
				touchHandler = TouchHandler.create({ list: Ember.A() });
			}
		});

		Ember.$(document.body).on('touchmove', function (event) {
			if (touchHandler) {
				touchHandler.__setTouch('move', event, counter);
				counter += 1;
			}
		});

		Ember.$(document.body).on('touchend', function () {
			if (touchHandler) {
				touchEvents.trigger('touchmove', touchHandler);
				touchHandler = null;
				counter = 0;
			}
		});

		return touchEvents;
	}

	//function toggleFullScreen(osType, event) {
	//	window.console.log(osType, event);
	//	if (osType === 'Android') {
	//		let isFullScreen = document.isFullScreen || document.webkitIsFullScreen;
	//		if (!isFullScreen) {
	//			let fullScreen = document.body.requestFullScreen || document.body.webkitRequestFullScreen;
	//			window.console.log('fullScreen', fullScreen);
	//			if (fullScreen) {
	//				fullScreen.call(document.body);
	//			}
	//		} else {
	//			let exitFullScreen = document.exitFullScreenMode || document.webkitExitFullScreen;
	//			if (exitFullScreen) {
	//				exitFullScreen.call(document.body);
	//			}
	//		}
	//	}
	//}
});