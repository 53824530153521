define('busy-app/components/busy-infobox', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'span',

		classNames: ['l-flex-infobox', 'page-hide'],
		classNameBindings: ['active:active', 'isAbove:l-flex-infobox-above', 'isBelow:l-flex-infobox-below', 'isLeft:l-flex-infobox-left', 'isRight:l-flex-infobox-right', 'offset:l-flex-infobox-offset', 'multiline:l-flex-infobox-multiline'],

		// Attibutes
		active: false,
		content: null,
		direction: 'left',
		multiline: false,
		offset: false,
		autoMultilineCutoff: null,

		init: function init() {
			this._super();
			(false && !(!Ember.isNone(this.get('direction'))) && Ember.assert('busy-infobox :: Attibute \'direction\' is required', !Ember.isNone(this.get('direction'))));
			(false && !(this.isValidDirection(this.get('direction'))) && Ember.assert('busy-infobox :: Attibute \'direction\' recieved unsupported value: \'' + this.get('direction') + '\'. Supported values are above/below/left/right', this.isValidDirection(this.get('direction'))));
			(false && !(!Ember.isNone(this.get('content'))) && Ember.assert('busy-infobox :: Attribute \'content\' is required', !Ember.isNone(this.get('content'))));
			(false && !(this.isValidAutoMultilineCutoff(this.get('autoMultilineCutoff'))) && Ember.assert('busy-infobox :: Attribute \'autoMultilineCutoff\' must be an integer, got: ' + this.get('autoMultilineCutoff'), this.isValidAutoMultilineCutoff(this.get('autoMultilineCutoff'))));

			if (!Ember.isNone(this.get('autoMultilineCutoff'))) {
				if (this.get('content').length >= this.get('autoMultilineCutoff')) {
					this.set('multiline', true);
				} else if (this.get('offset')) {
					// force offset to false if autoMultilineCutoff not met
					this.set('offset', false);
				}
			}
			(false && !(!this.get('offset') || this.get('multiline')) && Ember.assert('busy-infobox :: Attribute \'offset\' attribute requires attribute \'multiline\' to be true', !this.get('offset') || this.get('multiline')));
		},


		isAbove: Ember.computed('direction', function () {
			return this.get('direction') === 'above';
		}),
		isBelow: Ember.computed('direction', function () {
			return this.get('direction') === 'below';
		}),
		isLeft: Ember.computed('direction', function () {
			return this.get('direction') === 'left';
		}),
		isRight: Ember.computed('direction', function () {
			return this.get('direction') === 'right';
		}),

		isValidDirection: function isValidDirection(direction) {
			var validDirectionsRegex = /^(above|below|left|right)$/;
			return validDirectionsRegex.test(direction);
		},
		isValidAutoMultilineCutoff: function isValidAutoMultilineCutoff(cutoff) {
			return Ember.isNone(cutoff) || parseInt(cutoff, 0) === cutoff;
		},


		actions: {
			onClick: function onClick(e) {
				e.stopPropagation();

				// Logger.info(this, 'onClick');

				this.sendAction('onClick', e);

				return false;
			}
		}
	});
});