define('busy-app/utils/models/managers/time-entry-pto-day', ['exports', 'busy-app/utils/models/managers/time-entry-pto-range', '@busy-web/utils'], function (exports, _timeEntryPtoRange, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _timeEntryPtoRange.default.extend({
		addCollection: function addCollection(model, start, day) {
			var prev = this.get('model').findBy('day', day);
			if (!Ember.isNone(prev)) {
				prev.get('model').unshiftObject(model);
			} else {
				this._super(model, start, day);
			}
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			this.set('_rangeItems', []);

			var entries = data.timeEntries.sortBy('startTime');
			entries.forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));
				_this.checkConflict(model, entries);
				_this.splitEntries(model);
			});

			data.timeOffEntries.sortBy('dateStamp').forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));

				var startTime = _utils.Time.date(model.get('dateStamp')).startOf('day').unix();
				var endTime = _utils.Time.date(model.get('dateStamp')).endOf('day').unix();
				_this.addRangeCollection(model, startTime, endTime, false);
			});

			var openEntries = data.openTimeEntries.sortBy('startTime');
			openEntries.forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));
				_this.splitEntries(model, false);
			});

			this.getRangeCollections().forEach(function (model) {
				var day = model.get('dayString');
				var date = model.get('start');

				_this.addCollection(model, date, day);
			});

			this.set('content', this.getSorted());
		},
		splitEntries: function splitEntries(model) {
			var includeSegments = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

			var start = this.get('start');
			var end = this.get('end');

			var startTime = model.get('startTime');
			startTime = _utils.Time.date(startTime).startOf('day').unix();
			startTime = startTime < start ? start : startTime;

			var endTime = model.get('endTime') || _utils.Time.timestamp();
			endTime = _utils.Time.date(endTime).endOf('day').unix();
			endTime = endTime > end ? end : endTime;

			while (startTime < endTime) {
				var endDay = _utils.Time.date(startTime).endOf('day').unix() + 1;
				this.addRangeCollection(model, startTime, endDay, includeSegments);
				startTime = _utils.Time.date(startTime).add(1, 'days').unix();
			}
		},
		getRangeCollections: function getRangeCollections() {
			var rangeItems = this.get('_rangeItems').sortBy('model.startTime', 'model.endTime');
			if (this.get('sortOrder') === 'asc') {
				rangeItems = rangeItems.reverse();
			}
			return rangeItems;
		},
		addRangeCollection: function addRangeCollection(model, startTime, endTime, includeSegments) {
			if (Ember.isEmpty(this.get('_rangeItems'))) {
				this.set('_rangeItems', []);
			}
			var CollectionClass = this.collectionFor('time-entry-pto-range');
			this.get('_rangeItems').pushObject(CollectionClass.push(model, startTime, endTime, includeSegments));
		},


		totalTime: Ember.computed('model.@each.{regularSeconds,overtimeSeconds,doubletimeSeconds}', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('regularSeconds') + item.get('overtimeSeconds') + item.get('doubletimeSeconds') + item.get('timeOffSeconds');
				});
			}
			return total;
		})
	});
});