define('busy-app/components/charts/pie-chart', ['exports', 'busy-app/components/charts/base-chart'], function (exports, _baseChart) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseChart.default.extend({
		classNames: ['c-charts-pie-chart'],

		/**
   * The chart type
   *
   * @private
   * @property type
   * @type string
   */
		type: 'pie',

		pageSize: 4,

		addOptions: function addOptions(options) {
			options.cutoutPercentage = this.getWithDefault('cutoutPercentage', 0);
		},


		chartOptions: Ember.computed(function () {
			var labels = this.get('legend') || [];
			if (!Ember.isArray(labels)) {
				labels = Ember.A([labels]);
			}

			var dataTypes = this.get('dataType') || [];
			if (!Ember.isArray(dataTypes)) {
				dataTypes = Ember.A([dataTypes]);
			}

			var options = {
				borderColor: this.get('colors'),
				backgroundColor: this.get('colors'),
				_label: labels,
				_dataType: dataTypes
			};

			return options;
		})
	});
});