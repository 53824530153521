define('busy-app/controllers/report/employee-list', ['exports', 'busy-app/utils/docs'], function (exports, _docs) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		hideExport: true,
		meta: null,

		init: function init() {
			this._super();
			this.setMeta();
		},
		setMeta: function setMeta() {
			var meta = [Ember.Object.create({ header: 'Employee Id', machineName: 'memberNumber', sortable: true }), Ember.Object.create({ header: 'First Name', sortable: true }), Ember.Object.create({ header: 'Last Name', sortable: true }), Ember.Object.create({ header: 'Username', sortable: true }), Ember.Object.create({ header: 'Email', machineName: 'latestEmail', sortable: true }), Ember.Object.create({ header: 'Phone', sortable: true }), Ember.Object.create({ header: 'Position', machineName: 'positionName', sortable: true })];

			this.set('meta', meta);
		},


		actions: {
			printAction: function printAction() {
				var container = Ember.$('.v-report-employee-list');
				_docs.default.print(container);
			}
		}
	});
});