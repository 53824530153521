define('busy-app/components/busy-time-picker', ['exports', '@busy-web/utils', 'busy-app/utils/round-time'], function (exports, _utils, _roundTime) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	exports.default = Ember.Component.extend({
		classNames: ['busy-time-picker'],

		auth: Ember.inject.service('auth'),

		seconds: 0,
		hoursInt: 0,
		minutesInt: 0,

		value: null,

		timeRoundingInterval: 1,

		init: function init() {
			this._super();
			this.secondsChanged();

			var interval = 1;
			if (this.get('auth.organization.timeRounding')) {
				interval = parseInt(this.get('auth.organization.timeRoundingInterval') / 60, 10);
			}
			this.set('timeRoundingInterval', interval);
		},


		hourString: Ember.computed('hoursInt', function () {
			return this.get('hoursInt');
		}),

		minuteString: Ember.computed('minutesInt', function () {
			return this.get('minutesInt') < 10 ? '0' + this.get('minutesInt') : this.get('minutesInt');
		}),

		secondsChanged: Ember.observer('seconds', function () {
			if (!Ember.isNone(this.get('seconds'))) {
				var _Time$convertSeconds = _utils.Time.convertSeconds(this.get('seconds'), 2),
				    hours = _Time$convertSeconds.hours,
				    minutes = _Time$convertSeconds.minutes;

				minutes = this.roundMinutes(minutes); // get rounded minutes

				this.set('hoursInt', hours);
				this.set('minutesInt', minutes);

				minutes = minutes < 10 ? '0' + minutes : minutes;
				this.set('value', hours + ':' + minutes);
			}
		}),

		setSeconds: function setSeconds() {
			var oValue = this.get('value');
			if (!Ember.isNone(oValue) && typeof oValue === 'string') {
				var value = oValue.replace(/[^hm.:\d]/g, '');
				var seconds = 0;
				if (/(h)|(m)/.test(value)) {
					value = value.replace(/([.\d]*)h/, '$1-');
					value = value.replace(/-?([.\d]*)m/, '-$1');
					value = value.split('-');

					var hours = parseInt(value[0], 10) || 0;
					var minutes = this.roundMinutes(value[1] || 0); // get minutes rounded

					seconds = hours * 60 * 60 + minutes * 60;
				} else if (!/:/.test(value)) {
					var _value = parseFloat(value);
					if (_value < 25) {
						// assume value entered in hours
						seconds = _value * 60 * 60;
					} else if (_value < 100) {
						// assume value entered in minutes
						seconds = _value * 60;
					} else if (_value < 1000) {
						// assume lazy value ex: 130 === 1:30
						var _hours = parseInt(value.toString()[0], 10);
						var _minutes = this.roundMinutes(value.toString().slice(1)); // get minutes rounded
						seconds = _hours * 60 * 60 + _minutes * 60;
					} else if (_value < 2401) {
						// assume large lazy value ex: 1030 === 10:30
						var _hours2 = parseInt(value.toString().slice(0, 2), 10);
						var _minutes2 = this.roundMinutes(value.toString().slice(2)); // get minutes rounded
						seconds = _hours2 * 60 * 60 + _minutes2 * 60;
					} else {
						// assume value entered in seconds
						seconds = _value;
					}
				} else {
					var _value$split = value.split(':'),
					    _value$split2 = _slicedToArray(_value$split, 2),
					    _hours3 = _value$split2[0],
					    _minutes3 = _value$split2[1];

					_hours3 = _hours3.length ? parseInt(_hours3, 10) : 0;
					_minutes3 = this.roundMinutes(_minutes3.length ? _minutes3 : 0); // get minutes rounded
					seconds = _hours3 * 60 * 60 + _minutes3 * 60;
				}

				if (isNaN(seconds)) {
					seconds = 0;
				} else if (seconds > 86400) {
					seconds = 86400;
				}

				if (seconds === this.get('seconds')) {
					this.secondsChanged();
				}
				this.set('seconds', seconds);
			}
		},
		calcValue: function calcValue() {
			// get hours as an integer
			var hours = parseInt(this.get('hoursInt'), 10);

			// round minutes to nearest interval
			var minutes = this.roundMinutes(this.get('minutesInt'));

			// save seconds
			this.set('seconds', (hours * 60 + minutes) * 60);
		},
		roundMinutes: function roundMinutes(minutes) {
			// make sure input is a number otherwise convert it
			if (typeof minutes !== 'number') {
				minutes = parseInt(minutes, 10);
			}

			// get rounding interval
			var interval = this.get('timeRoundingInterval');

			// only round numbers when interval is greater than 1
			if (interval > 1 && minutes % interval !== 0) {
				minutes = (0, _roundTime.round)(minutes, interval);
			}

			// return minutes
			return minutes;
		},
		calcNumber: function calcNumber(addTo, number, min, max) {
			var interval = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 1;
			(false && !(min < max) && Ember.assert('Min must be less than max', min < max));

			if (addTo) {
				number = number + interval > max ? min : number + interval;
			} else {
				number = number - interval < min ? max : number - interval;
			}
			return number;
		},
		openMenuAction: function openMenuAction() {
			var _this = this;

			this.set('isOpen', true);
			Ember.$('body').unbind('click.busy-time-picker');
			Ember.$('body').bind('click.busy-time-picker', function (e) {
				var el = Ember.$(e.target);
				var self = _this.$();
				if (!Ember.isNone(self) && self.attr('class') !== el.attr('class')) {
					_this.closeMenuAction();
				}
			});
		},
		closeMenuAction: function closeMenuAction() {
			this.set('isOpen', false);
			Ember.$('body').unbind('click.busy-time-picker');
		},


		actions: {
			focusOutAction: function focusOutAction() {
				this.setSeconds();
			},
			enterAction: function enterAction() {
				this.setSeconds();
			},
			toggle: function toggle() {
				if (!this.get('isOpen')) {
					this.openMenuAction();
				} else {
					this.closeMenuAction();
				}
			},
			toggleHours: function toggleHours(addTo) {
				if (this.get('hoursInt') === 23 && addTo) {
					this.set('minutesInt', 0);
				}
				this.set('hoursInt', this.calcNumber(addTo, this.get('hoursInt'), 0, 24, 1));
				this.calcValue();
			},
			toggleMinutes: function toggleMinutes(addTo) {
				var interval = this.get('timeRoundingInterval');
				if (this.get('minutesInt') === 0 && this.get('hoursInt') === 0 && !addTo) {
					this.set('hoursInt', 24);
					this.set('minutesInt', 0);
				} else if (this.get('minutesInt') === 0 && this.get('hoursInt') === 24 && addTo) {
					this.set('hoursInt', 0);
					this.set('minutesInt', 0);
				} else if (this.get('minutesInt') === 60 - interval && addTo || this.get('minutesInt') === 0 && !addTo || this.get('hoursInt') === 24 && addTo) {
					this.set('hoursInt', this.calcNumber(addTo, this.get('hoursInt'), 0, 24, 1));
					this.set('minutesInt', this.calcNumber(addTo, this.get('minutesInt'), 0, 60 - interval, interval));
				} else {
					this.set('minutesInt', this.calcNumber(addTo, this.get('minutesInt'), 0, 60 - interval, interval));
				}
				this.calcValue();
			},
			changePeriod: function changePeriod() {
				this.set('periodBool', !this.get('periodBool'));
			}
		}
	});
});