define('busy-app/components/text-field', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.TextField.extend({
		attributeBindings: ['value'],

		valuePath: '',
		model: null,

		value: Ember.computed('model', function () {
			return this.getValue();
		}),

		getValue: function getValue() {
			var path = this.get('valuePath');
			var value = this.get('model');
			if (!Ember.isEmpty(path)) {
				value = this.get('model.' + path);
			}

			if (Ember.isNone(value)) {
				value = '';
			}
			return value;
		},
		setValue: function setValue(value) {
			var path = this.get('valuePath');
			if (!Ember.isEmpty(path)) {
				this.set('model.' + path, value);
			} else {
				this.set('model', value);
			}
		},
		click: function click(e) {
			e.stopPropagation();
			var val = this.get('value');
			this.sendAction('onClick', val);
			return false;
		},
		keyUp: function keyUp(e) {
			if (e.keyCode === 13 && !Ember.isNone(this.get('enter'))) {
				e.stopPropagation();
				return false;
			}

			var val = this.get('value');
			this.setValue(val);
			if (!Ember.isNone(this.get('onKeyUp'))) {
				this.sendAction('onKeyUp', e.keyCode || e.which, val);
			}
		},
		keyDown: function keyDown(e) {
			var val = this.get('value');
			if (e.keyCode === 13 && !Ember.isNone(this.get('enter'))) {
				e.stopPropagation();
				e.preventDefault();
				this.sendAction('enter', val);
				return false;
			}

			if (!Ember.isNone(this.get('onKeyDown'))) {
				this.sendAction('onKeyDown', e.keyCode || e.which, val);
			}
		},
		keyPress: function keyPress(e) {
			if (e.keyCode === 13 && !Ember.isNone(this.get('enter'))) {
				e.stopPropagation();
				return false;
			}

			var val = this.get('value');
			if (!Ember.isNone(this.get('onKeyPress'))) {
				this.sendAction('onKeyPress', e.keyCode || e.which, val);
			}
		},
		focusOut: function focusOut(evt) {
			this.sendAction('onBlur', evt);
		},
		focusIn: function focusIn(evt) {
			this.sendAction('onFocus', evt);
		}
	});
});