define('busy-app/components/subscriptions/edit-plan', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Component.extend({
		auth: Ember.inject.service(),
		subscription: Ember.inject.service(),

		billingCycle: null,
		activeUserEstimate: null,

		initialBillingCycle: null,
		initialActiveUserEstimate: null,

		agreeToTerms: false,
		errorMessage: null,

		invoiceDueDateFormatted: null,
		invoiceDueDate: null,
		invoiceId: null,

		isSubmitting: false,
		showConfirmation: false,

		/**
   * COMPUTEDS & OBSERVERS
   *
   */

		monthlyCostPerUserMonth: Ember.computed.alias('subscription.costPerActiveUserMonthly'),
		monthlyCostPerUserYear: Ember.computed.alias('subscription.costPerActiveUserMonthlyPerYear'),

		annualCostPerUserMonth: Ember.computed.alias('subscription.costPerActiveUserAnnual'),
		annualCostPerUserYear: Ember.computed.alias('subscription.costPerActiveUserAnnualPerYear'),

		planOptions: Ember.computed('billingCycle', 'monthlyCostPerUserMonth', 'monthlyCostPerUserYear', 'annualCostPerUserMonth', 'annualCostPerUserYear', function () {
			return [{
				key: 'annual',
				label: (0, _utils.loc)('Annual Billing'),
				pricePerUserPerMonth: this.get('annualCostPerUserMonth'),
				pricePerUserPerYear: this.get('annualCostPerUserYear'),
				isSelected: this.get('isAnnual')
			}, {
				key: 'monthly',
				label: (0, _utils.loc)('Monthly Billing'),
				pricePerUserPerMonth: this.get('monthlyCostPerUserMonth'),
				pricePerUserPerYear: this.get('monthlyCostPerUserYear'),
				isSelected: this.get('isMonthly')
			}];
		}),

		isAnnual: Ember.computed.equal('billingCycle', 'annual'),
		isMonthly: Ember.computed.equal('billingCycle', 'monthly'),
		isInvoiceRequired: Ember.computed('isAnnual', 'activeUserEstimate', 'annualCostPerUserYear', function () {
			var annualCost = this.get('activeUserEstimate') * this.get('annualCostPerUserYear');
			return this.get('isAnnual') && annualCost >= this.get('subscription.annualInvoiceThreshold');
		}),

		onChangeActiveUserEstimate: Ember.observer('activeUserEstimate', function () {
			var _this = this;

			// reset `agreeToTerms` to false any time `activeUserEstimate` is changed
			this.set('agreeToTerms', false);

			// catch and fix bad `activeUserEstimate` input
			// invalid inputs are reset in a run.next() so that the UI updates to the new value
			var numUsers = parseInt(this.get('activeUserEstimate'), 10);
			if (numUsers <= 0 || !Number.isInteger(numUsers)) {
				Ember.run.next(this, function () {
					return _this.safeSet('activeUserEstimate', 1);
				});
			} else if (Number.isInteger(numUsers) && numUsers !== this.get('activeUserEstimate')) {
				Ember.run.next(this, function () {
					return _this.safeSet('activeUserEstimate', numUsers);
				});
			}
		}),

		hasChangedBillingCycle: Ember.computed('billingCycle', 'initialBillingCycle', function () {
			return this.get('billingCycle') !== this.get('initialBillingCycle');
		}),

		hasChangedActiveUsers: Ember.computed('activeUserEstimate', 'initialActiveUserEstimate', function () {
			return this.get('activeUserEstimate') !== this.get('initialActiveUserEstimate');
		}),

		hasDataChanged: Ember.computed.or('hasChangedBillingCycle', 'hasChangedActiveUsers'),

		// effective date shows when changing from annual to monthly, or when the number of active users on an annual subscription is changed
		showEffectiveDateDisclaimer: Ember.computed('hasChangedActiveUsers', 'hasChangedBillingCycle', 'billingCycle', function () {
			if (!this.get('hasDataChanged')) {
				return false;
			} else {
				// annual plan, number of users changed, type of plan NOT changed
				var changedAnnualActiveUsers = this.get('billingCycle') === 'annual' && this.get('hasChangedActiveUsers') && !this.get('hasChangedBillingCycle');

				// monthly plan, changed from annual plan, number of users not relevant
				var changedFromAnnualToMonthly = this.get('billingCycle') === 'monthly' && this.get('hasChangedBillingCycle');

				return changedAnnualActiveUsers || changedFromAnnualToMonthly;
			}
		}),

		effectiveDateFormatted: Ember.computed('property', function () {
			return _utils.Time.date(this.get('subscription.subscriptionStatus.annualSubscriptionDate')).format('ll');
		}),

		subscriptionType: Ember.computed('isAnnual', 'isMonthly', 'isInvoiceRequired', 'hasChangedBillingCycle', function () {
			// An invoice is *not* produced when an annual organization is resized.
			// If an organization has been resized above the invoicing threshold, it will be invoiced when the account renews.

			if (this.get('isInvoiceRequired') && this.get('hasChangedBillingCycle')) {
				return 'annualInvoice';
			} else if (this.get('isAnnual')) {
				return 'annualCredit';
			} else if (this.get('isMonthly')) {
				return 'monthlyCredit';
			} else {
				return null;
			}
		}),

		/**
   * COMPONENT INITIALIZATION
   *
   */

		/**
   * @public
   * @method init
   * @constructor
   */
		init: function init() {
			this._super();
			return this.initData();
		},


		/**
   * @public
   * @method initData
   */
		initData: function initData() {
			var _this2 = this;

			this.set('agreeToTerms', false);
			this.set('showConfirmation', false);
			this.set('billingCycle', null);
			this.set('activeUserEstimate', 1);

			var date = _utils.Time.date().startOf('day').add(1, 'month');
			this.set('invoiceDueDate', date);
			this.set('invoiceDueDateFormatted', date.format('LL'));

			return this.loadData().then(function (props) {
				var billingCycle = _this2.get('subscription.subscriptionStatus.isAnnualPlan') ? 'annual' : 'monthly';
				var activeUserEstimate = billingCycle === 'annual' ? _this2.get('subscription.subscriptionStatus.annualSubscriptionCount') : props.memberCount;

				_this2.safeSet('billingCycle', billingCycle);
				_this2.safeSet('activeUserEstimate', activeUserEstimate);

				// check these to see if values have changed
				_this2.safeSet('initialBillingCycle', billingCycle);
				_this2.safeSet('initialActiveUserEstimate', activeUserEstimate);
			});
		},


		/**
   * @public
   * @method initData
   */
		loadData: function loadData() {
			var _this3 = this;

			this.set('isLoading', true);

			return Ember.RSVP.hash({
				subscriptionPricing: this.get('subscription.subscriptionPricing'), // ensure `subscriptionPricing` is loaded
				subscriptionStatus: this.get('subscription.subscriptionStatus'), // ensure `subscriptionStatus` is loaded
				memberCount: this.get('auth.memberCount') // ensure `memberCount` is loaded
			}).finally(function () {
				return _this3.safeSet('isLoading', false);
			});
		},


		/**
   * FORM PROCESSING
   *
   */

		/**
   * Handle form submission:
   *  - Check if any data has changed
   *  - Validate data
   *  - Send data to API
   *
   * @public
   * @async
   * @method onSaveForm
   * @return {EmberPromise}
   */
		onSaveForm: function onSaveForm() {
			var _this4 = this;

			_logger.default.info(this, 'onSaveForm');

			if (this.get('isInvoiceRequired') && !this.get('agreeToTerms')) {
				this.safeSet('isAgreeToTermsInvalid', true);
				return Ember.RSVP.reject((0, _utils.loc)('Must agree to terms...'));
			} else if (!this.get('hasDataChanged')) {
				return Ember.RSVP.reject((0, _utils.loc)('There are no changes to save!'));
			} else {
				this.safeSet('isAgreeToTermsInvalid', false);

				return this.updateSubscriptionPlan().then(function (res) {
					return _this4.get('subscription.subscriptionStatus.content').reload().then(function () {
						return res;
					});
				});
			}
		},


		/**
   * Determine what about the plan has been changed and call the appropriate handler
   *  - monthly to annual
   *  - annual to monthly
   *  - change size of annual plan
   *
   * @public
   * @method updateSubscriptionPlan
   * @return {EmberPromise}
   */
		updateSubscriptionPlan: function updateSubscriptionPlan() {
			var _this5 = this;

			var currentCycle = this.get('subscription.subscriptionStatus.isAnnualPlan') ? 'annual' : 'monthly';

			_logger.default.info(this, 'updateSubscriptionPlan');

			var promisedUpdate = function promisedUpdate() {
				return Ember.RSVP.resolve();
			};
			if (this.get('hasChangedBillingCycle') && this.get('billingCycle') !== currentCycle) {
				if (this.get('billingCycle') === 'annual') {
					promisedUpdate = function promisedUpdate() {
						return _this5.convertMonthlyToAnnual();
					};
				} else {
					promisedUpdate = function promisedUpdate() {
						return _this5.convertAnnualToMonthly();
					};
				}
			} else if (this.get('hasChangedActiveUsers') && this.get('billingCycle') === 'annual') {
				promisedUpdate = function promisedUpdate() {
					return _this5.changeAnnualSize();
				};
			}

			return promisedUpdate();
		},


		/**
   * API Calls
   *
   */

		/**
   * Convert the current plan from monthly to annual
   *
   * @public
   * @async
   * @method convertAnnualToMonthly
   * @return {EmberPromise}
   */
		convertAnnualToMonthly: function convertAnnualToMonthly() {
			_logger.default.info(this, 'convertAnnualToMonthly');

			return this.get('subscription').cancelAnnualSubscription();
		},


		/**
   * Convert the current plan from annual to monthly.
   * If the cost exceeds subscription.annualInvoiceThreshold, send an invoice, otherwise, charge the amount to the card on file.
   *
   * @public
   * @async
   * @method convertMonthlyToAnnual
   * @return {EmberPromise}
   */
		convertMonthlyToAnnual: function convertMonthlyToAnnual() {
			_logger.default.info(this, 'convertMonthlyToAnnual');

			if (this.get('isInvoiceRequired')) {
				return this.startSubscriptionAnnualInvoice();
			} else {
				return this.startSubscriptionAnnual();
			}
		},


		/**
   * Attempt to charge the card for the requested number of users.
   *
   * @public
   * @method startSubscriptionAnnual
   * @async
   * @return {EmberPromise}
   */
		startSubscriptionAnnual: function startSubscriptionAnnual() {
			var activeUserEstimate = parseInt(this.get('activeUserEstimate'), 10);
			_logger.default.info(this, 'startSubscriptionAnnual', { activeUserEstimate: activeUserEstimate });

			return this.get('subscription').chargeAnnualSubscription(activeUserEstimate);
		},


		/**
   * Create an invoice for the balance due for the requested number of users.
   * The created `invoiceId` is saved, so it can be passed to the `order-confirmation` component.
   *
   * @public
   * @method startSubscriptionAnnualInvoice
   * @async
   * @return {EmberPromise}
   */
		startSubscriptionAnnualInvoice: function startSubscriptionAnnualInvoice() {
			var _this6 = this;

			var activeUserEstimate = parseInt(this.get('activeUserEstimate'), 10);
			_logger.default.info(this, 'startSubscriptionAnnualInvoice', { activeUserEstimate: activeUserEstimate });

			return this.get('subscription').invoiceAnnualSubscription(activeUserEstimate).then(function (invoiceId) {
				_this6.safeSet('invoiceId', invoiceId);
				return invoiceId;
			});
		},


		/**
   * Leave the plan on annual, changing the size of the account.
   * This change is NOT immediately effective, the card is not charged or refunded.
   * The change is effective as of the next annually scheduled recurring billing.
   *
   * @public
   * @async
   * @method changeAnnualSize
   * @return {EmberPromise}
   */
		changeAnnualSize: function changeAnnualSize() {
			var activeUserEstimate = parseInt(this.get('activeUserEstimate'), 10);
			_logger.default.info(this, 'changeAnnualSize', { activeUserEstimate: activeUserEstimate });

			return this.get('subscription').changeAnnualSubscriptionCount(activeUserEstimate);
		},


		/**
   * Downgrade the account from pro to free.
   * Works from both monthly and annual plans.
   *
   * @public
   * @async
   * @method downgradeToFree
   * @return {EmberPromise}
   */
		downgradeToFree: function downgradeToFree() {
			var _this7 = this;

			_logger.default.info(this, 'downgradeToFree');

			this.set('isSubmitting', true);
			return this.get('subscription').setSubscriptionStatusFree().catch(function (err) {
				return _this7.handleApiError('downgradeToFree', err);
			}).finally(function () {
				return _this7.safeSet('isSubmitting', false);
			});
		},


		/**
   * If the API returns an error code, try to turn it into a message useful to the end user.
   *
   * @public
   * @method handleApiError
   * @param err {object}
   * @return {EmberPromise}
   */
		handleApiError: function handleApiError(type, err) {
			_logger.default.error(this, type, typeof err === 'undefined' ? 'undefined' : _typeof(err), err);

			if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object') {
				err = (0, _utils.loc)('Something went wrong, please try again later.');
			}

			this.safeSet('errorMessage', err);

			// NOTE Ember RSVP reject will throw an error for
			// unhandled catch errors if the goal is to throw
			// the error then return is here and let it propagate
			// to all the handlers first then ember will throw it.
			return Ember.RSVP.reject(err);
		},
		safeSet: function safeSet(key, value) {
			if (!this.get('isDestroyed')) {
				this.set(key, value);
			}
			return this;
		},


		actions: {
			/**
    * @public
    * @event onClose
    */
			onClose: function onClose() {
				this.sendAction('onClose');
			},


			/**
    * @public
    * @event onUpdate
    */
			onUpdate: function onUpdate() {
				this.sendAction('onUpdate');
			},


			/**
    * @public
    * @event downgradeToFree
    */
			downgradeToFree: function downgradeToFree() {
				var _this8 = this;

				return this.downgradeToFree().then(function () {
					_this8.sendAction('onUpdate');
				});
			},


			/**
    * don't close the dialog after save, the confirmation page is shown instead
    *
    * @public
    * @event saveForm
    */
			saveForm: function saveForm() {
				var _this9 = this;

				this.set('isSubmitting', true);

				return this.onSaveForm().then(function () {
					return _this9.safeSet('showConfirmation', true) && true;
				}).catch(function (err) {
					return _this9.handleApiError('onSaveForm', err);
				}).finally(function () {
					return _this9.safeSet('isSubmitting', false);
				});
			},


			/**
    * @public
    * @event selectBillingCycle
    * @param billingCycle {string} 'annual' or 'monthly'
    */
			selectBillingCycle: function selectBillingCycle(billingCycle) {
				this.set('billingCycle', billingCycle);
			},


			/**
    * @public
    * @event onAgreeToTerms
    * @param isChecked {boolean}
    */
			onAgreeToTerms: function onAgreeToTerms(isChecked) {
				this.safeSet('agreeToTerms', isChecked);
			}
		}
	});
});