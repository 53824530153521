define('busy-app/utils/integrations/full-story', ['exports', 'busy-app/utils/integrations/base', 'busy-app/utils/settings'], function (exports, _base, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		auth: Ember.inject.service('auth'),

		featureName: 'integrations/fullStory',

		isRunReady: function isRunReady() {
			if (this.isAuth()) {
				return window.FS && !Ember.isNone(this.get('auth.organization.id'));
			} else {
				return true;
			}
		},
		run: function run() {
			if (this.isAuth()) {
				window.FS.identify(this.get('auth.member.id'), {
					displayName: this.get('auth.member.fullName'),
					email: this.get('auth.member.email'),
					username_str: this.get('auth.member.username'),
					permission_str: this.get('auth.authMemberPosition.title'),
					company_str: this.get('auth.organization.organizationName'),
					version_str: _settings.default.get('version'),
					app_str: _settings.default.get('build')
				});
			}
		}
	});
});