define('busy-app/models/equipment-annual-seconds', ['exports', 'ember-data', 'busy-app/models/equipment-day-seconds'], function (exports, _emberData, _equipmentDaySeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _equipmentDaySeconds.default.extend({
    // used to differentiate uid for annual and day models
    type: "annual",

    startDate: _emberData.default.attr('date', { format: 'YYYY', sortable: true, sortBy: ['startDate'] })
  });
});