define('busy-app/components/time/time-entry/clock', ['exports', '@busy-web/utils', 'busy-app/utils/round-time'], function (exports, _utils, _roundTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['v-time-card-clock'],

    store: Ember.inject.service('store'),
    auth: Ember.inject.service('auth'),
    features: Ember.inject.service('features'),

    clockInDialog: false,
    switchProjectDialog: false,
    showGpsLocationRequiredDialog: false,

    member: null,
    organization: null,
    isDisabled: false,
    isSuspended: false,

    _socket: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.setup();
    },


    setup: Ember.observer('member.id', function () {
      if (!Ember.isNone(this.get('member.id'))) {
        this.getOpenCard();
      }
    }),

    isClockRunning: Ember.computed('member.openEntry.elapsedTime', function () {
      return this.get('member.openEntry.elapsedTime') >= 0;
    }),

    roundingExplanation: Ember.computed('member.openEntry.{elapsedTime,onBreak}', function () {
      var timeType = (0, _utils.loc)('Clock');
      var time = _utils.Time.timeFormat(this.get('member.openEntry.startTime'), 'h:mm A');
      if (this.get('member.openEntry.openRoundedBreak.length') > 0) {
        time = _utils.Time.timeFormat(this.get('member.openEntry.openRoundedBreak')[0].get('endTime'), 'h:mm A');
      }
      if (this.get('member.openEntry.onBreak')) {
        timeType = (0, _utils.loc)('Break timer');
        time = this.get('member.openEntry.futureBreakTime');
      }
      return timeType + ' ' + (0, _utils.loc)('will begin at ') + time;
    }),

    roundingReason: Ember.computed('auth.organization', function () {
      var interval = this.get('auth.organization.timeRoundingIntervalMinutes');
      var type = this.get('auth.organization.timeRoundingType');
      var typeString = ' ';

      if (type === 20) {
        typeString += (0, _utils.loc)('down') + ' ';
      } else if (type === 30) {
        typeString += (0, _utils.loc)('up') + ' ';
      }

      var reason = (0, _utils.loc)('Employee time for your company is set to round') + typeString + 'to the nearest ' + interval + ' ' + (0, _utils.loc)('minute interval');
      return reason;
    }),

    /**
     * Gets the time card that has an end time equal to null.
     *
     * @public
     * @method getOpenCard
     */
    getOpenCard: function getOpenCard() {
      var _this = this;

      var query = {
        member_id: this.get('member.id'),
        end_time: '_-NULL-_'
      };

      var socket = this.get('store').socket('time-entry', query).then(function (model) {
        if (!_this.get('isDestroyed')) {
          var entry = model.get('firstObject');
          if (!Ember.isNone(entry)) {
            entry.reloadBreaks();
            entry.setTimeInterval(1000);
            if (!_this.get('isDestroyed')) {
              _this.set('member.openEntry', entry);
            }
            return entry;
          } else {
            _this.set('member.openEntry', null);
          }
        }
      });

      socket.shouldUpdate = function () {
        return !_this.get('isSuspended');
      };

      if (Ember.isNone(this.get('_socket'))) {
        this.set('_socket', socket);
      }
    },
    clockOut: function clockOut() {
      var _this2 = this;

      var endTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getEndTime();

      var forceSave = true;
      var endLocation = null;

      if (this.get('member.isTimeLocationRequired')) {
        this.send('openDialog', 'GpsLocationRequiredDialog');
      } else {
        this.get('member').clockOutAt(endTime, forceSave, false, endLocation, true).then(function () {
          _this2.set('isDisabled', true);
          _this2.sendAction('onClockChange', 'clock-out-action');
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (!Ember.isNone(this.get('_socket'))) {
        this.get('_socket').destroy();
      }
    },
    getEndTime: function getEndTime() {
      return (0, _roundTime.roundOrgTime)(this.get("auth.organization"), _utils.Time.nistTimestamp(), { isEnd: true, isSwitch: false });
    },


    actions: {
      clockInAction: function clockInAction() {
        if (this.get('member.isTimeLocationRequired')) {
          this.send('openDialog', 'GpsLocationRequiredDialog');
        } else {
          this.set('clockInDialog', true);
          this.set('isDisabled', false);
        }
      },
      clockOutAction: function clockOutAction() {
        var endTime = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getEndTime();

        if (this.get('member.isTimeLocationRequired')) {
          this.send('openDialog', 'GpsLocationRequiredDialog');
        } else if (!Ember.isNone(this.get('member.openEntry')) && !this.get('isDisabled')) {
          if (this.get('features.safetySignOff') && (this.get('auth.organization.safetySignature') || this.get('auth.organization.timeAccuracy') || this.get('auth.organization.breakPolicy'))) {
            if (this.get('auth.member.id') === this.get('member.id')) {
              this.set('showSafetyDialog', true);
            } else {
              this.set('showSignOffRequiredDialog', true);
              this.clockOut(endTime);
            }
          } else {
            this.clockOut(endTime);
          }
        }
      },
      goOnBreakAction: function goOnBreakAction() {
        var _this3 = this;

        var startLocation = null;

        if (this.get('member.isTimeLocationRequired')) {
          this.send('openDialog', 'GpsLocationRequiredDialog');
        } else if (!this.get('isDisabled')) {
          this.get('member').startBreak(false, true, startLocation).then(function () {
            _this3.set('isDisabled', true);
            _this3.sendAction('onClockChange', 'break-start-action');
          });
        }
      },


      // unpaidBreakAction() {
      // 	const startLocation = null;
      //
      // 	this.get('member').startBreak(false, true, startLocation).then(() => {
      // 		this.set('showBreakTypeDialog', false);
      // 		this.sendAction('onClockChange', 'unpaid-break-start-action');
      // 	});
      // },

      // paidBreakAction() {
      // 	const startLocation = null;
      //
      // 	this.get('member').startBreak(true, true, startLocation).then(() => {
      // 		this.set('showBreakTypeDialog', false);
      // 		this.sendAction('onClockChange', 'paid-break-start-action');
      // 	});
      // },

      closeBreakTypeDialog: function closeBreakTypeDialog() {
        this.set('showBreakTypeDialog', false);
      },
      endBreakAction: function endBreakAction() {
        var _this4 = this;

        var endLocation = null;

        if (this.get('member.isTimeLocationRequired')) {
          this.send('openDialog', 'GpsLocationRequiredDialog');
        } else {
          this.get('member').stopBreak(true, endLocation).then(function () {
            _this4.set('isDisabled', false);
            _this4.sendAction('onClockChange', 'break-end-action');
          });
        }
      },
      switchProjectAction: function switchProjectAction() {
        if (this.get('member.isTimeLocationRequired')) {
          this.send('openDialog', 'GpsLocationRequiredDialog');
        } else {
          this.set('switchProjectDialog', true);
        }
      },
      closeClockIn: function closeClockIn() {
        this.set('clockInDialog', false);
        this.sendAction('onClockChange', 'clock-in-action');
      },
      closeSwitchProject: function closeSwitchProject() {
        this.set('switchProjectDialog', false);
        this.sendAction('onClockChange', 'clock-out-action');
      },
      closeSafetyDialog: function closeSafetyDialog() {
        this.set('showSafetyDialog', false);
        this.set('showSignOffRequiredDialog', false);
      },
      closeSafetyDialogAndClockOut: function closeSafetyDialogAndClockOut(injured, safety, breakPolicy) {
        var endTime = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : this.getEndTime();

        this.set('showSafetyDialog', false);
        this.clockOut(endTime);
        if (injured) {
          this.set("showInjuryProtocolDialog", true);
        }
      },
      closeProtocolDialog: function closeProtocolDialog() {
        this.set('showInjuryProtocolDialog', false);
      },
      openDialog: function openDialog(dialogName) {
        this.set('show' + dialogName, true);
      },
      closeDialog: function closeDialog(dialogName) {
        this.set('show' + dialogName, false);
      }
    }
  });
});