define("busy-app/routes/project/detail/reports/subprojects", ["exports", "@busy-web/utils", "busy-app/utils/collection", "busy-app/utils/unassigned"], function (exports, _utils, _collection, _unassigned) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		auth: Ember.inject.service(),

		queryParams: {
			id: { refreshModel: true },
			custom: { replace: true },
			start: { replace: true },
			end: { replace: true }
		},

		model: function model(params) {
			var _this = this;

			var project = this.modelFor("project.detail");
			var isCustomDate = params.custom === true;
			var start = isCustomDate ? params.start : _utils.Time.date(project.project.get("createdOn")).startOf("day").subtract(10, "years").unix();
			var end = isCustomDate ? params.end : _utils.Time.timestamp();

			return this.loadPrimaryAggregates(project, start, end).then(function (model) {
				if (Ember.get(_this, "auth.useGroupPermissionScoping")) {
					return _this.loadScopedAggregates(project, start, end, model);
				} else {
					return model;
				}
			});
		},
		loadPrimaryAggregates: function loadPrimaryAggregates(project, start, end) {
			var _this2 = this;

			var projectId = Ember.get(project, "project.id");

			return Ember.RSVP.hash({
				childProjects: Ember.get(this, "store").findAll("project", {
					parent_project_id: projectId
				}),
				cost: this.loadProjectCost(projectId, start, end),
				scopedGroupMembers: this.loadScopedGroupMembers(),
				seconds: this.loadProjectSeconds(projectId, start, end)
			}).then(function (_ref) {
				var childProjects = _ref.childProjects,
				    cost = _ref.cost,
				    scopedGroupMembers = _ref.scopedGroupMembers,
				    seconds = _ref.seconds;

				var totalTotalSeconds = !Ember.isNone(seconds) ? Ember.get(seconds, "totalTotalSeconds") : 0;
				var totalTotalOverburdenCost = !Ember.isNone(cost) ? Ember.get(cost, "totalTotalOverburdenCost") : 0;

				var childPromises = [(0, _unassigned.default)(project.project, seconds, cost)];

				childProjects.forEach(function (project) {
					project.set("sortWeight", 1);

					childPromises.push(Ember.RSVP.hash({
						project: project,
						seconds: _this2.loadProjectSeconds(project.id, start, end),
						cost: _this2.loadProjectCost(project.id, start, end)
					}));
				});

				return Ember.RSVP.all(childPromises).then(function (projects) {
					return projects.filter(function (item) {
						return Ember.get(item, "seconds") || Ember.get(item, "cost");
					});
				}).then(function (projects) {
					return projects.sortBy("project.title", "project.sortWeight");
				}).then(function (projects) {
					return {
						projects: projects,
						cost: cost,
						scopedGroupMembers: scopedGroupMembers,
						seconds: seconds,
						totalTotalSeconds: totalTotalSeconds,
						totalTotalOverburdenCost: totalTotalOverburdenCost
					};
				});
			});
		},
		loadScopedAggregates: function loadScopedAggregates(project, start, end, unscopedAggregates) {
			var projects = unscopedAggregates.projects,
			    scopedGroupMembers = unscopedAggregates.scopedGroupMembers;
			var totalTotalSeconds = unscopedAggregates.totalTotalSeconds,
			    totalTotalOverburdenCost = unscopedAggregates.totalTotalOverburdenCost;


			var projectId = Ember.get(project, "project.id");
			var groupMemberIds = new Set(scopedGroupMembers.mapBy("id"));
			var allProjectIds = projects.mapBy("project.id").uniq();

			return Ember.RSVP.hash({
				allMemberProjectSeconds: this.loadAllMemberProjectSeconds(allProjectIds, start, end, groupMemberIds),
				allMemberProjectCost: this.loadAllMemberProjectCost(allProjectIds, start, end, groupMemberIds)
			}).then(function (_ref2) {
				var allMemberProjectSeconds = _ref2.allMemberProjectSeconds,
				    allMemberProjectCost = _ref2.allMemberProjectCost;

				var scopeProjectSeconds = Ember.get(allMemberProjectSeconds, projectId);
				var scopeProjectCost = Ember.get(allMemberProjectCost, projectId);

				if (!Ember.isNone(scopeProjectSeconds)) {
					totalTotalSeconds = (0, _collection.sumBy)(scopeProjectSeconds, "totalTotalSeconds");
				}

				if (!Ember.isNone(scopeProjectCost)) {
					totalTotalOverburdenCost = (0, _collection.sumBy)(scopeProjectCost, "totalTotalOverburdenCost");
				}

				projects.forEach(function (project) {
					var id = Ember.get(project, "project.id");
					var projectSeconds = Ember.get(allMemberProjectSeconds, id);
					var projectCost = Ember.get(allMemberProjectCost, id);

					// the root, "unassigned" project should NOT include child data
					// sub projects SHOULD include all their child data
					if (id === projectId) {
						var totalSeconds = Ember.isNone(projectSeconds) ? 0 : (0, _collection.sumBy)(projectSeconds, "totalSeconds");
						var totalCost = Ember.isNone(projectCost) ? 0 : (0, _collection.sumBy)(projectCost, "totalOverburdenCost");

						Ember.set(project, "seconds.totalSeconds", totalSeconds);
						Ember.set(project, "cost.totalOverburdenCost", totalCost);
					} else {
						var _totalSeconds = Ember.isNone(projectSeconds) ? 0 : (0, _collection.sumBy)(projectSeconds, "totalTotalSeconds");
						var _totalCost = Ember.isNone(projectCost) ? 0 : (0, _collection.sumBy)(projectCost, "totalTotalOverburdenCost");

						Ember.set(project, "seconds.totalTotalSeconds", _totalSeconds);
						Ember.set(project, "cost.totalTotalOverburdenCost", _totalCost);
					}
				});

				return {
					projects: projects,
					totalTotalSeconds: totalTotalSeconds,
					totalTotalOverburdenCost: totalTotalOverburdenCost
				};
			});
		},
		loadProjectSeconds: function loadProjectSeconds(project_id, start_date, end_date) {
			return Ember.get(this, "store").findAll("project-seconds", {
				start_date: start_date,
				end_date: end_date,
				project_id: project_id,
				archived_on: null
			}).then(function (d) {
				return d.get("firstObject");
			});
		},
		loadProjectCost: function loadProjectCost(project_id, start_date, end_date) {
			return Ember.get(this, "store").findAll("project-cost", {
				start_date: start_date,
				end_date: end_date,
				project_id: project_id,
				archived_on: null
			}).then(function (d) {
				return d.get("firstObject");
			});
		},
		loadScopedGroupMembers: function loadScopedGroupMembers() {
			if (Ember.get(this, "auth.useGroupPermissionScoping")) {
				var memberGroupId = Ember.get(this, "auth.member.memberGroupId");
				if (Ember.isNone(memberGroupId)) {
					return Ember.RSVP.resolve([Ember.get(this, "auth.member")]);
				} else {
					return Ember.get(this, "store").findAll("member", {
						member_group_id: memberGroupId
					});
				}
			} else {
				return Ember.RSVP.resolve([]);
			}
		},
		loadMemberProjectCost: function loadMemberProjectCost(project_id, start_date, end_date, groupMemberIds) {
			var _this3 = this;

			return Ember.get(this, "store").findAll("member-project-cost", {
				start_date: start_date,
				end_date: end_date,
				project_id: project_id,
				archived_on: null
			}).then(function (results) {
				return _this3.filterByMembers(results, groupMemberIds);
			});
		},
		loadMemberProjectSeconds: function loadMemberProjectSeconds(project_id, start_date, end_date, groupMemberIds) {
			var _this4 = this;

			return Ember.get(this, "store").findAll("member-project-seconds", {
				start_date: start_date,
				end_date: end_date,
				project_id: project_id,
				archived_on: null
			}).then(function (results) {
				return _this4.filterByMembers(results, groupMemberIds);
			}).then(function (results) {
				return results;
			});
		},
		filterByMembers: function filterByMembers(collection, groupMemberIds) {
			var filteredCollection = Ember.isNone(collection) ? collection : collection.filter(function (item) {
				return groupMemberIds.has(Ember.get(item, "memberId"));
			});
			return filteredCollection;
		},
		loadAllMemberProjectCost: function loadAllMemberProjectCost(projectIds, start_date, end_date, groupMemberIds) {
			var _this5 = this;

			var promises = projectIds.map(function (id) {
				return _this5.loadMemberProjectCost(id, start_date, end_date, groupMemberIds);
			});

			return Ember.RSVP.all(promises).then(function (results) {
				return (0, _collection.flatten)(results);
			}).then(function (flatResults) {
				return (0, _collection.groupBy)(flatResults, "projectId");
			});
		},
		loadAllMemberProjectSeconds: function loadAllMemberProjectSeconds(projectIds, start_date, end_date, groupMemberIds) {
			var _this6 = this;

			var promises = projectIds.map(function (id) {
				return _this6.loadMemberProjectSeconds(id, start_date, end_date, groupMemberIds);
			});

			return Ember.RSVP.all(promises).then(function (results) {
				return (0, _collection.flatten)(results);
			}).then(function (flatResults) {
				return (0, _collection.groupBy)(flatResults, "projectId");
			});
		},
		renderTemplate: function renderTemplate(controller) {
			var project = this.modelFor("project.detail");
			var reportController = this.controllerFor("project.detail.reports");
			reportController.set("reportTitle", (0, _utils.loc)("Subprojects"));
			controller.set("project", project.project);
			this.render("project.detail.reports.subprojects");
		},


		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			}
		}
	});
});