define('busy-app/components/busy-map-leaflet', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var _window = window,
	    L = _window.L,
	    MQ = _window.MQ;


	/***/

	// default to busy office
	// 37.10102990074617 -113.53877082086876
	var kDefaultLocation = [37.10102990074617, -113.53877082086876];

	var kTileServiceProviders = {
		'osm': 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		'wikimedia': 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
		'carto-light': 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
		'carto-dark': 'http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
		'stamen-watercolor': 'http://{s}.tile.stamen.com/watercolor/{z}/{x}/{y}.png',
		'stamen-toner': 'http://{s}.tile.stamen.com/toner/{z}/{x}/{y}.png'
	};

	var kDefaultLocationRadius = 50;
	var kAddressSearchDebounce = 1000 / 4;
	var kAddressSearchMinLength = 3;

	/**
  * `Component/BusyMapLeaflet`
  * Component map for displaying a map to a user.
  *
  * Ember-Leaflet docs: http://www.ember-leaflet.com/docs
  * Leaflet docs: http://leafletjs.com/reference-1.2.0.html
  *
  * @class BusyMap
  * @namespace Components
  *
  * @property locationInfo {object} Object of info for a place to be shown on a map
  * @property canEdit {boolean} Bool used to show edit options for map.
  * @property error {object}
  */
	exports.default = Ember.Component.extend({
		classNames: ['c-busy-map-leaflet'],

		/** params */

		locationInfo: null,
		canEdit: false,
		mapAutocomplete: null,
		zoom: 15,
		// showLayerControl: true,
		showRadius: false,
		onLocationChange: null, // action

		mapInit: Date.now(),

		/** public properties */

		mapMarkers: null,
		error: false,
		isMapReady: false,
		tileService: null,
		locationString: '',
		geocodeSearchResults: null,
		showGeocodeSearchResults: false,

		/** private properties */

		_defaultTileService: 'osm',
		_tileServices: null,
		_mapElement: null,
		_radiusControl: null,
		// _layerControl: null,
		_hoverLocation: null,
		_tileProvider: 'default',

		/** computeds */

		hasLocation: Ember.computed('locationInfo.{latitude,longitude}', function () {
			return !Ember.isNone(this.get('locationInfo.latitude')) && !Ember.isNone(this.get('locationInfo.longitude'));
		}),

		latitude: Ember.computed('hasLocation', 'locationInfo.latitude', '_hoverLocation', function () {

			// if the user is hovering over a geo search result, center on that location temporarily
			if (!Ember.isNone(this.get('_hoverLocation.latlng.lat'))) {
				return this.get('_hoverLocation.latlng.lat');
			}

			return this.get('hasLocation') ? this.get('locationInfo.latitude') : kDefaultLocation[0];
		}),

		longitude: Ember.computed('hasLocation', 'locationInfo.longitude', '_hoverLocation', function () {

			// if the user is hovering over a geo search result, center on that location temporarily
			if (!Ember.isNone(this.get('_hoverLocation.latlng.lng'))) {
				return this.get('_hoverLocation.latlng.lng');
			}

			return this.get('hasLocation') ? this.get('locationInfo.longitude') : kDefaultLocation[1];
		}),

		coords: Ember.computed('latitude', 'longitude', function () {
			return [this.get('latitude'), this.get('longitude')];
		}),

		hasRadius: Ember.computed('locationInfo.locationRadius', function () {
			return !Ember.isNone(this.get('locationInfo.locationRadius')) && !Number.isNaN(this.get('locationInfo.locationRadius'));
		}),

		tileServiceUrl: Ember.computed('_tileProvider', function () {
			if (this.get('isTileDefault')) {
				return kTileServiceProviders['osm'];
			} else if (this.get('isTileHybrid')) {
				return this.getMapQuestTileServiceUrl('hybridLayer');
			}
		}),

		isTileDefault: Ember.computed('_tileProvider', function () {
			return this.get('_tileProvider') === 'default';
		}),

		isTileHybrid: Ember.computed('_tileProvider', function () {
			return this.get('_tileProvider') === 'satellite';
		}),

		/** observers */

		onLocationChanged: Ember.observer('locationInfo.latitude', 'locationInfo.longitude', function () {
			return this.redrawRadiusCircle();
		}),

		onRadiusChanged: Ember.observer('locationInfo.locationRadius', function () {
			return this.redrawRadiusCircle();
		}),

		// whenever the `mapInit` param changes, trigger the leaflet map invalidateSize() method
		// leaflet does not render properly if it it not visible in the DOM, changing to a tab with a map will be broken, and it needs to be re-rendered
		onReInit: Ember.observer('mapInit', function () {
			// Logger.info(this, 'onReInit', this.get('mapInit'));

			if (!Ember.isNone(this.get('_mapElement'))) {
				this.set('locationString', null);
				this.get('_mapElement').invalidateSize();
			}
		}),

		/** component lifecylcle */

		init: function init() {
			this._super.apply(this, arguments);

			this.set('locationString', null);
			this.set('tileService', kTileServiceProviders[this.get('_defaultTileService')]);
		},


		// onload action is triggered by leaflet
		onMapLoad: function onMapLoad(_mapElement) {
			this.setProperties({
				_mapElement: _mapElement,
				isMapReady: true
			});

			this.getReverseGeocode({
				lat: this.get('locationInfo.latitude'),
				lng: this.get('locationInfo.longitude')
			});

			// if (this.get('showLayerControl')) {
			// 	this.drawMapQuestLayerControl();
			// }

			if (this.get('showRadius') && this.get('hasRadius')) {
				this.drawRadiusCircle();
			}
		},
		drawRadiusCircle: function drawRadiusCircle() {
			var _this = this;

			var _mapElement = this.get('_mapElement');

			var circleOptions = {
				radius: this.get('locationInfo.locationRadius'),
				draggable: false,
				resizable: this.get('canEdit')
			};

			var _radiusControl = new L.CircleEditor(this.get('coords'), circleOptions).on('resizeend', function (e) {
				return _this.send('onRadiusResize', e);
			}).addTo(_mapElement);

			this.setProperties({ _radiusControl: _radiusControl });

			return _radiusControl;
		},
		redrawRadiusCircle: function redrawRadiusCircle() {
			var _radiusControl = this.get('_radiusControl');

			if (this.get('hasRadius')) {
				if (!Ember.isNone(_radiusControl)) {
					_radiusControl.remove();
				}

				return this.drawRadiusCircle();
			}
		},


		// drawMapQuestLayerControl() {
		// 	const _mapElement = this.get('_mapElement');
		//
		// 	const baseLayers = {
		// 		'Street Map': this.createTileLayer('osm'),
		// 		'Satellite': MQ.hybridLayer(),
		//
		// 		// 'Carto Light': this.createTileLayer('carto-light'),
		// 		// 'Carto Dark': this.createTileLayer('carto-dark'),
		// 		// 'Stamen Watercolor': this.createTileLayer('stamen-watercolor'),
		// 		// 'Stamen Toner': this.createTileLayer('stamen-toner'),
		// 		//
		// 		// 'MapQuest Map': MQ.mapLayer(),
		// 		// 'MapQuest Satellite': MQ.satelliteLayer(),
		// 		// 'MapQuest Dark': MQ.darkLayer(),
		// 		// 'MapQuest Light': MQ.lightLayer(),
		// 	};
		//
		// 	const _layerControl = L.control.layers(baseLayers).addTo(_mapElement);
		//
		// 	this.setProperties({ _layerControl });
		// },

		// createTileLayer(key) {
		// 	return L.tileLayer(kTileServiceProviders[key], { detectRetina: true });
		// },


		requestBrowserGeolocation: function requestBrowserGeolocation() {
			if (!navigator.geolocation) {
				return Ember.RSVP.resolve();
			}

			return new Ember.RSVP.Promise(function (resolve, reject) {
				return navigator.geolocation.getCurrentPosition(resolve, reject);
			});
		},
		setUserGeolocation: function setUserGeolocation() {
			var _this2 = this;

			return this.requestBrowserGeolocation().then(function (position) {
				if (!Ember.isNone(position) && !Ember.isNone(position.coords)) {
					_this2.setProperties({
						'locationInfo.latitude': position.coords.latitude,
						'locationInfo.longitude': position.coords.longitude
					});
				}
			});
		},
		isLocationDefault: function isLocationDefault(location) {
			// Logger.info(this, 'isLocationDefault', { location, kDefaultLocation, isValid: this.isValidLocationObject(location) });

			if (!this.isValidLocationObject(location)) {
				return false;
			} else {
				return location.lat === kDefaultLocation[0] && location.lng === kDefaultLocation[1];
			}
		},
		isValidLocationObject: function isValidLocationObject(location) {
			// Logger.info(this, 'isValidLocationObject', { location });

			return Ember.isPresent(location) && location.hasOwnProperty('lat') && location.hasOwnProperty('lng') && Ember.isPresent(location.lat) && Ember.isPresent(location.lng);
		},


		// location: {lat, lng}
		getReverseGeocode: function getReverseGeocode(location) {
			var _this3 = this;

			// Logger.info(this, 'getReverseGeocode', { location, isValid: this.isValidLocationObject(location) });

			this.set('locationString', null);

			if (!this.isValidLocationObject(location)) {
				return Ember.RSVP.resolve();
			}

			return this.mapquestGeocodeReverse(location).then(function (_ref) {
				var best = _ref.best;

				if (!_this3.get('isDestroyed') && !_this3.isLocationDefault(location)) {
					var locationString = _this3.describeLocation(best);
					_this3.set('locationString', locationString);
				}

				// Logger.info(this, 'getReverseGeocode', { best, matches, search, locationString });
			}).catch(function (err) {
				_this3.set('geolocationError', err);
			});
		},


		// location: {lat, lng}
		mapquestGeocodeReverse: function mapquestGeocodeReverse(location) {
			var _this4 = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {
				try {
					MQ.geocode().reverse(location).on('success', function (e) {
						var result = e.result;


						if (Ember.isNone(result)) {
							return reject((0, _utils.loc)('No results found'));
						} else {
							var best = result.best,
							    matches = result.matches,
							    search = result.search;

							// Logger.info(this, 'mapquestGeocodeReverse', { best, matches, search });

							return resolve({ best: best, matches: matches, search: search });
						}
					}).on('error', function (e) {
						_logger.default.error(_this4, 'mapquestGeocodeReverse', e);

						var message = e.message;


						return reject(message);
					});
				} catch (err) {
					return reject(err);
				}
			});
		},
		mapquestGeocodeSearch: function mapquestGeocodeSearch(input) {
			var _this5 = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {
				try {
					MQ.geocode().search(input).on('success', function (e) {
						var result = e.result;


						if (Ember.isNone(result)) {
							return reject((0, _utils.loc)('No results found'));
						} else {
							var best = result.best,
							    matches = result.matches,
							    search = result.search;

							// Logger.info(this, 'mapquestGeocodeSearch', { best, matches, search });

							return resolve({ best: best, matches: matches, search: search });
						}
					}).on('error', function (e) {
						_logger.default.error(_this5, 'mapquestGeocodeSearch', e);

						var message = e.message;


						return reject(message);
					});
				} catch (err) {
					return reject(err);
				}
			});
		},


		// based on MQ.geocde().describeLocation()
		describeLocation: function describeLocation(location) {
			var result = [];

			if (location.street) {
				result.push(location.street);
			}

			// city
			if (location.adminArea5) {
				// state
				if (location.adminArea3) {
					result.push(location.adminArea5 + ', ' + location.adminArea3);
				} else {
					result.push(location.adminArea5);
				}
			} else {
				if (location.adminArea3) {
					result.push(location.adminArea3);
				}
			}

			return result.join(', ');
		},
		addressSearch: function addressSearch() {
			var _this6 = this;

			var locationString = this.get('locationString');
			this.mapquestGeocodeSearch(locationString).then(function (_ref2) {
				var matches = _ref2.matches;

				if (Ember.isNone(matches) || Ember.isEmpty(matches)) {
					_this6.setProperties({
						showGeocodeSearchResults: true,
						geocodeSearchResults: []
					});
					return;
				}

				var geocodeSearchResults = matches.map(function (match) {
					var description = _this6.describeLocation(match);
					return Object.assign(match, { description: description });
				});

				_this6.setProperties({
					showGeocodeSearchResults: true,
					geocodeSearchResults: geocodeSearchResults
				});
			});
		},
		getMapQuestTileServiceUrl: function getMapQuestTileServiceUrl(type) {
			// hybridLayer
			// mapLayer
			// satelliteLayer
			// darkLayer
			// lightLayer

			return MQ[type]()._url.replace('{ext}', 'png');
		},


		actions: {
			onLoad: function onLoad(e) {
				var _mapElement = e.target;
				this.onMapLoad(_mapElement);
			},


			// remove the radius control whenever the marker is being moved
			onDrag: function onDrag() {
				var _radiusControl = this.get('_radiusControl');
				if (!Ember.isNone(_radiusControl)) {
					_radiusControl.remove();
				}
			},
			onMarkerMove: function onMarkerMove(e) {
				var location = e.target.getLatLng();
				this.getReverseGeocode(location);
				this.setProperties({
					'locationInfo.latitude': location.lat,
					'locationInfo.longitude': location.lng
				});
				this.send('onLocationChange');
			},
			onRadiusResize: function onRadiusResize(e) {
				var locationRadius = e.target.getRadius();
				this.set('locationInfo.locationRadius', locationRadius);
				this.send('onLocationChange');
			},
			onLocationChange: function onLocationChange() {
				var args = [this.get('locationInfo.latitude'), this.get('locationInfo.longitude'), this.get('locationInfo.locationRadius')];
				this.sendAction.apply(this, ['onLocationChange'].concat(args));
			},
			locationInputChange: function locationInputChange() {
				if (this.get('locationString.length') >= kAddressSearchMinLength) {
					Ember.run.debounce(this, this.addressSearch, kAddressSearchDebounce);
				}
			},
			onSearchResultsClick: function onSearchResultsClick(location) {
				var locationInfo = this.get('locationInfo');
				locationInfo.setProperties({
					latitude: location.latlng.lat,
					longitude: location.latlng.lng,
					locationRadius: kDefaultLocationRadius
				});

				this.setProperties({
					locationString: location.description,
					showGeocodeSearchResults: false,
					locationInfo: locationInfo
				});
			},
			onGeoSearchFocus: function onGeoSearchFocus() {
				if (this.get('locationString.length') >= kAddressSearchMinLength) {
					Ember.run.debounce(this, this.addressSearch, kAddressSearchDebounce);
				}
			},
			onGeoSearchBlur: function onGeoSearchBlur() {
				this.set('showGeocodeSearchResults', false);
			},
			mouseEnterGeoSearchResult: function mouseEnterGeoSearchResult(location) {
				this.set('_hoverLocation', location);
			},
			mouseLeaveGeoSearchResult: function mouseLeaveGeoSearchResult() {
				this.set('_hoverLocation', null);
			},
			setTileProvider: function setTileProvider(tileProvider) {
				this.set('_tileProvider', tileProvider);
			}
		}
	});


	/*
  * based on https://github.com/kartena/Leaflet.EditableHandlers
  *
  * L.CircleEditor is an extension of L.Circle, just to add the edition part (remember, radius in meters).
  */
	L.CircleEditor = L.Circle.extend({
		options: { // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
			icon: new L.DivIcon({
				iconSize: new L.Point(8, 8),
				className: 'leaflet-div-icon leaflet-editing-icon'
			}),

			extendedIconClass: 'extend-icon',
			draggable: false,
			resizable: false,
			stroke: true,
			color: '#30aeef', // $busy-color-1
			opacity: 0.8,
			weight: 1,
			fill: true,
			fillOpacity: 0.5
		},

		onAdd: function onAdd(map) {
			L.Path.prototype.onAdd.call(this, map);
			this.addHooks();
		},
		onRemove: function onRemove(map) {
			this.removeHooks();
			L.Path.prototype.onRemove.call(this, map);
		},
		addHooks: function addHooks() {
			if (this._map) {
				if (!this._markerGroup) {
					this._initMarkers();
				}
				this._map.addLayer(this._markerGroup);
			}
		},
		removeHooks: function removeHooks() {
			if (this._map) {
				this._map.removeLayer(this._markerGroup);
				delete this._markerGroup;
				delete this._markers;
			}
		},
		updateMarkers: function updateMarkers() {
			this._markerGroup.clearLayers();
			this._initMarkers();
		},
		_initMarkers: function _initMarkers() {
			this._markerGroup = new L.LayerGroup();
			this._markers = [];
			this._createCenterMarker();
			this._createResizeMarker();
		},
		_createCenterMarker: function _createCenterMarker() {
			var markerCenter = this._createMarker(this._latlng, 0, true);

			// must be this._markers[0];
			this._markers[0] = markerCenter;
		},
		_createResizeMarker: function _createResizeMarker() {
			var circleBounds = this.getBounds();
			var center = circleBounds.getCenter();
			var neCoord = circleBounds.getNorthEast();
			var northCenterCoord = new L.LatLng(center.lat, neCoord.lng, true);
			var markerNorthCenter = this._createMarker(northCenterCoord, 1);

			// must be this._markers[1];
			this._markers[1] = markerNorthCenter;
		},
		_createMarker: function _createMarker(latlng, index) {
			var isCenter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			var options = {
				draggable: true,
				icon: this.options.icon
			};

			var marker = new L.Marker(latlng, options);

			// these have to be set on the marker object, not in the options
			marker._origLatLng = latlng;
			marker._index = index;
			marker._isCenter = isCenter;

			// the dragging handle is disabled
			if (isCenter && !this.options.draggable) {
				return marker;
			} else if (!isCenter && !this.options.resizable) {
				// the resizing handle is disabled
				return marker;
			}

			if (isCenter) {
				marker.on('drag', this._onCenterMove, this).on('dragend', this._onCenterMoveEnd, this);
			} else {
				marker.on('drag', this._onMarkerDrag, this).on('dragend', this._onResizeEnd, this);
			}

			marker.on('dragend', this._fireEdit, this).on('mouseover', this._onMouseOver, this).on('mouseout', this._onMouseOut, this);

			this._markerGroup.addLayer(marker);
			return marker;
		},
		_onMouseOver: function _onMouseOver(e) {
			var target = e.target;
			var icon = target._icon;
			var classValues = icon.getAttribute("class");

			//icon.setAttribute("class", "extend-icon " + classValues);
			icon.setAttribute("class", this.options.extendedIconClass + " " + classValues);
		},
		_onMouseOut: function _onMouseOut(e) {
			var target = e.target;
			var icon = target._icon;
			var classValues = icon.getAttribute("class");

			//icon.setAttribute("class", classValues.replace("extend-icon", ""));
			icon.setAttribute("class", classValues.replace(this.options.extendedIconClass, ""));
		},
		_fireEdit: function _fireEdit() {
			this.fire('edit');
		},
		_onResizeEnd: function _onResizeEnd() {
			this.fire('resizeend');
		},
		_onCenterMove: function _onCenterMove(e) {
			var marker = e.target;
			L.Util.extend(marker._origLatLng, marker._latlng);

			var mm = this._markers[1];
			mm.setOpacity(0.1);

			this.redraw();
			this.fire('drag');
		},
		_onCenterMoveEnd: function _onCenterMoveEnd() {
			//now resetting the side point
			var circleBounds = this.getBounds();
			var center = circleBounds.getCenter();
			var neCoord = circleBounds.getNorthEast();
			var northCenterCoord = new L.LatLng(center.lat, neCoord.lng, true);
			var mm = this._markers[1];

			mm.setLatLng(northCenterCoord);
			mm.setOpacity(1);

			this.fire('dragend');
		},
		_onMarkerDrag: function _onMarkerDrag(e) {
			var marker = e.target;
			var center = this._markers[0].getLatLng();
			var axis = marker._latlng;
			var distance = Math.floor(center.distanceTo(axis));

			this.setRadius(distance);
			this.redraw();
			this.fire('resize');
		}
	});
});