define('busy-app/models/segment', ['exports', 'ember-data', 'busy-app/mixins/model-sync', '@busy-web/utils'], function (exports, _emberData, _modelSync, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend(_modelSync.default, {
		timeEntryId: _emberData.default.attr('id'),
		startTime: _emberData.default.attr('date', { sortable: true, sortBy: ['startTime'], sortChildren: ['timeEntry.member.firstName', 'timeEntry.member.lastName'] }),
		endTime: _emberData.default.attr('date', { sortable: true }),
		segmentType: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		timeEntry: _emberData.default.belongsTo('time-entry'),
		//seconds: null,// DS.belongsTo('segment-seconds'),
		//cost: null,//DS.belongsTo('segment-cost'),

		seconds: Ember.computed('id', function () {
			var _this = this;

			if (!Ember.isNone(this.id)) {
				return _emberData.default.PromiseArray.create({
					promise: this.store.findAll('segment-seconds', { segment_id: this.id }).then(function (items) {
						if (items.get('length') === 0) {
							_this.sync('seconds', !_this.get('deletedOn'));
						}
						return items;
					})
				});
			}
		}),

		cost: Ember.computed('id', function () {
			var _this2 = this;

			if (!Ember.isNone(this.id)) {
				return _emberData.default.PromiseArray.create({
					promise: this.store.findAll('segment-cost', { segment_id: this.id }).then(function (items) {
						if (items.get('length') === 0) {
							_this2.sync('cost', !_this2.get('deletedOn'));
						}
					})
				});
			}
		}),

		regularSeconds: Ember.computed('seconds.@each.regularSeconds', function () {
			var total = 0;
			if (!this.get('isBreak')) {
				total += this.get('seconds').mapBy('regularSeconds').reduce(function (a, b) {
					return a + b;
				}, 0);
			}
			return total;
		}).meta({ aggregateType: 'sum' }),

		overtimeSeconds: Ember.computed('seconds.@each.overtimeSeconds', function () {
			var total = 0;
			if (!this.get('isBreak')) {
				total += this.get('seconds').mapBy('overtimeSeconds').reduce(function (a, b) {
					return a + b;
				}, 0);
			}
			return total;
		}).meta({ aggregateType: 'sum' }),

		doubletimeSeconds: Ember.computed('seconds.@each.doubletimeSeconds', function () {
			var total = 0;
			if (!this.get('isBreak')) {
				total += this.get('seconds').mapBy('doubletimeSeconds').reduce(function (a, b) {
					return a + b;
				}, 0);
			}
			return total;
		}).meta({ aggregateType: 'sum' }),

		breakSeconds: Ember.computed('seconds.@each.{regularSeconds,overtimeSeconds,doubletimeSeconds}', function () {
			var total = 0;
			if (this.get('isBreak')) {
				// TODO: future code for paid breaks
				//
				//total += this.get('seconds').mapBy('regularSeconds').reduce((a, b) => a+b,  0);
				//total	+= this.get('seconds').mapBy('overtimeSeconds').reduce((a, b) => a+b, 0);
				//total	+= this.get('seconds').mapBy('doubletimeSeconds').reduce((a, b) => a+b, 0);
				total += this.get('endTime') - this.get('startTime');
			}
			return total;
		}).meta({ aggregateType: 'sum' }),

		totalSeconds: Ember.computed('updatedOn', 'regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		regularCost: Ember.computed('cost.@each.regularCost', function () {
			var total = 0;
			if (!this.get('isBreak')) {
				total += this.get('cost').mapBy('regularCost').reduce(function (a, b) {
					return a + b;
				}, 0);
			}
			return total;
		}).meta({ aggregateType: 'sum' }),

		overtimeCost: Ember.computed('cost.@each.overtimeCost', function () {
			var total = 0;
			if (!this.get('isBreak')) {
				total += this.get('cost').mapBy('overtimeCost').reduce(function (a, b) {
					return a + b;
				}, 0);
			}
			return total;
		}).meta({ aggregateType: 'sum' }),

		doubletimeCost: Ember.computed('cost.@each.doubletimeCost', function () {
			var total = 0;
			if (!this.get('isBreak')) {
				total += this.get('cost').mapBy('doubletimeCost').reduce(function (a, b) {
					return a + b;
				}, 0);
			}
			return total;
		}).meta({ aggregateType: 'sum' }),

		breakCost: Ember.computed('cost.@each.{regularCost,overtimeCost,doubletimeCost}', function () {
			var total = 0;
			if (this.get('isBreak')) {
				total += this.get('cost').mapBy('regularCost').reduce(function (a, b) {
					return a + b;
				}, 0);
				total += this.get('cost').mapBy('overtimeCost').reduce(function (a, b) {
					return a + b;
				}, 0);
				total += this.get('cost').mapBy('doubletimeCost').reduce(function (a, b) {
					return a + b;
				}, 0);
			}
			return total;
		}).meta({ aggregateType: 'sum' }),

		totalCost: Ember.computed('regularCost', 'overtimeCost', 'doubletimeCost', function () {
			return this.get('regularCost') + this.get('overtimeCost') + this.get('doubletimeCost');
		}).meta({ aggregateType: 'sum' }),

		isBreak: Ember.computed('segmentType', function () {
			if (this.get('segmentType') === 20 || this.get('segmentType') === 30) {
				return true;
			}
			return false;
		}),

		isPaidBreak: Ember.computed('segmentType', function () {
			if (this.get('segmentType') === 30) {
				return true;
			}
			return false;
		}),

		elapsedTime: Ember.computed('startTime', 'endTime', function () {
			return this.get('endTime') - this.get('startTime');
		}).meta({ aggregateType: 'sum' }),

		elapsedTimeString: Ember.computed('elapsedTime', function () {
			var time = _utils.Time.convertSeconds(this.get('elapsedTime'), true);
			return time.hours + ':' + time.minutes;
		})
	});
});