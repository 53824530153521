define('busy-app/models/member-equipment-seconds', ['exports', 'ember-data', 'busy-app/utils/unique-id', 'busy-app/models/member-seconds'], function (exports, _emberData, _uniqueId, _memberSeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _memberSeconds.default.extend({
    // used to match seconds and cost models
    uid: (0, _uniqueId.default)('startDate', 'endDate', 'memberId', 'equipmentId'),

    // override attrs
    equipmentId: _emberData.default.attr('id', { sortable: true, sortBy: ['equipment.equipmentName'] }),

    // override model injections
    equipment: _emberData.default.belongsTo('equipment', { query: { deleted_on: '_-DISABLE-_' } })
  });
});