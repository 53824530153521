define('busy-app/models/stripe-plan', ['exports', 'ember-data', '@busy-web/data'], function (exports, _emberData, _data) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr;
	exports.default = Model.extend(_data.RPCModelMixin, {
		_clientName: 'busy-stripe',
		_methodName: 'get-stripe-plans',

		amount: attr('number'),
		created: attr('number'),
		currency: attr('string'),
		interval: attr('string'),
		intervalCount: attr('number'),
		livemode: attr('boolean'),
		name: attr('string'),
		object: attr('string'),
		trialPeriodDays: attr('number')
	});
});