define('busy-app/models/member-overtime-period', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		changeDate: _emberData.default.attr('number'),
		adhereToOrg: _emberData.default.attr('boolean'),
		weeklyOvertime: _emberData.default.attr('number'),
		dailyOvertime: _emberData.default.attr('number'),
		dailyDoubletime: _emberData.default.attr('number'),
		seventhDayOvertime: _emberData.default.attr('boolean'),
		seventhDayDoubletime: _emberData.default.attr('number'),
		saturdayMultiplier: _emberData.default.attr('number'),
		sundayMultiplier: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		orgOvertime: _emberData.default.belongsTo('organization-overtime-period', { query: { _desc: ['start_time'] } }),

		startTime: Ember.computed('changeDate', 'adhereToOrg', 'orgOvertime.startTime', function () {
			if (!Ember.isNone(this.get('orgOvertime.startTime'))) {
				var day = _utils.Time.date(this.get('changeDate')).day();
				var eday = _utils.Time.date(this.get('orgOvertime.startTime')).day();
				var offset = eday - day;
				if (offset < 0) {
					offset = 7 + offset;
				}

				return _utils.Time.date(this.get('changeDate')).add(offset, 'days').unix();
			}
		}),

		formattedDate: Ember.computed('changeDate', function () {
			var date = '';
			if (!Ember.isNone(this.get('changeDate'))) {
				// hides the Jan 7 1970 and shows
				// beginning
				if (this.get('changeDate') === 345600 || _utils.Time.timeFormat(this.get('changeDate'), 'YYYY') === '1970') {
					date = (0, _utils.loc)("Beginning");
				} else {
					date = _utils.Time.timeFormat(this.get('changeDate'), 'ddd. MMM Do, YYYY');
				}
			}
			return date;
		}),

		formattedUpdatedOn: Ember.computed('udpatedOn', function () {
			return _utils.Time.timeFormat(this.get('updatedOn'), 'MMM Do, YYYY');
		}),

		overtimePeriod: Ember.computed('orgOvertime.overtimePeriod', function () {
			return this.get('orgOvertime.overtimePeriod');
		}),

		effective: Ember.computed('orgOvertime.startTime', 'changeDate', function () {
			var date = this.get('changeDate');
			if (!Ember.isNone(this.get('orgOvertime.startTime'))) {
				var day = _utils.Time.date(this.get('changeDate')).day();
				var eday = _utils.Time.date(this.get('orgOvertime.startTime')).day();
				var offset = eday - day;
				if (offset < 0) {
					offset = 7 + offset;
				}

				date = _utils.Time.date(this.get('changeDate')).add(offset, 'days').unix();
			}
			return date;
		}),

		effectiveDate: Ember.computed('effective', function () {
			var date = '';
			if (!Ember.isNone(this.get('effective'))) {
				date = _utils.Time.date(this.get('effective'));
				if (date.unix() === 345600 || date.format('YYYY') === '1970') {
					date = (0, _utils.loc)("Beginning");
				} else {
					date = date = date.format('ddd. MMM Do, YYYY');
				}
			}
			return date;
		}),

		overtimePreference: Ember.computed('adhereToOrg', 'weeklyOvertime', 'dailyOvertime', 'dailyDoubletime', 'seventhDayOvertime', 'seventhDayDoubletime', 'saturdayMultiplier', 'sundayMultiplier', function () {
			var weekly = this.get('weeklyOvertime');
			var daily = this.get('dailyOvertime');
			var dailyDouble = this.get('dailyDoubletime');
			var sevenOne = this.get('seventhDayOvertime');
			var sevenTwo = this.get('seventhDayDoubletime');
			var saturday = this.get('saturdayMultiplier');
			var sunday = this.get('sundayMultiplier');

			var pref = "";
			if (this.get('adhereToOrg')) {
				pref = (0, _utils.loc)("Follow Company Settings");
			} else if (weekly === 40 && daily === 8 && dailyDouble === 12 && sevenOne === true && sevenTwo === 8 && saturday === null && sunday === null) {
				pref = (0, _utils.loc)("California Settings");
			} else if (weekly === 40 && daily === null && dailyDouble === null && sevenOne === false && sevenTwo === null && saturday === null && sunday === null) {
				pref = (0, _utils.loc)("Standard Settings");
			} else {
				pref = (0, _utils.loc)("Custom Settings");
			}
			return pref;
		}),

		weeklyOvertimeString: Ember.computed('weeklyOvertime', function () {
			var weeklyOvertime = this.get('weeklyOvertime');
			if (this.get('adhereToOrg')) {
				weeklyOvertime = this.get('orgOvertime.weeklyOvertime');
			}

			if (!Ember.isNone(weeklyOvertime)) {
				return (0, _utils.loc)('after %@ hours', [weeklyOvertime]);
			} else {
				return (0, _utils.loc)('N/A');
			}
		}),

		dailyOvertimeString: Ember.computed('dailyOvertime', function () {
			var dailyOvertime = this.get('dailyOvertime');
			if (this.get('adhereToOrg')) {
				dailyOvertime = this.get('orgOvertime.dailyOvertime');
			}

			if (!Ember.isNone(dailyOvertime)) {
				return (0, _utils.loc)('after %@ hours', [dailyOvertime]);
			} else {
				return (0, _utils.loc)('N/A');
			}
		}),

		dailyDoubletimeString: Ember.computed('dailyDoubletime', function () {
			var dailyDoubletime = this.get('dailyDoubletime');
			if (this.get('adhereToOrg')) {
				dailyDoubletime = this.get('orgOvertime.dailyDoubletime');
			}

			if (!Ember.isNone(dailyDoubletime)) {
				return (0, _utils.loc)('after %@ hours', [dailyDoubletime]);
			} else {
				return (0, _utils.loc)('N/A');
			}
		}),

		seventhDayOvertimeString: Ember.computed('seventhDayOvertime', function () {

			var seventhDayOvertime = this.get('seventhDayOvertime');
			if (this.get('adhereToOrg')) {

				seventhDayOvertime = this.get('orgOvertime.seventhDayOvertime');
			}

			if (seventhDayOvertime) {

				return seventhDayOvertime.toString();
			} else {
				return (0, _utils.loc)('N/A');
			}
		}),

		seventhDayDoubletimeString: Ember.computed('seventhDayDoubletime', function () {
			var seventhDayDoubletime = this.get('seventhDayDoubletime');
			if (this.get('adhereToOrg')) {
				seventhDayDoubletime = this.get('orgOvertime.seventhDayDoubletime');
			}

			if (!Ember.isNone(seventhDayDoubletime)) {
				return (0, _utils.loc)('after %@ hours', [seventhDayDoubletime]);
			} else {
				return (0, _utils.loc)('N/A');
			}
		}),

		saturdayMultiplierString: Ember.computed('saturdayMultiplier', function () {
			var saturdayMultiplier = this.get('saturdayMultiplier');
			if (this.get('adhereToOrg')) {
				saturdayMultiplier = this.get('orgOvertime.saturdayMultiplier');
			}

			if (!Ember.isNone(saturdayMultiplier)) {
				return (0, _utils.loc)('wage x %@', [saturdayMultiplier]);
			} else {
				return (0, _utils.loc)('N/A');
			}
		}),

		sundayMultiplierString: Ember.computed('sundayMultiplier', function () {
			var sundayMultiplier = this.get('sundayMultiplier');
			if (this.get('adhereToOrg')) {
				sundayMultiplier = this.get('orgOvertime.sundayMultiplier');
			}

			if (!Ember.isNone(sundayMultiplier)) {
				return (0, _utils.loc)('wage x %@', [sundayMultiplier]);
			} else {
				return (0, _utils.loc)('N/A');
			}
		})
	});
});