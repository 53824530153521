define('busy-app/components/company/integrations/vendors/procore/grid-item', ['exports', 'busy-app/components/company/integrations/grid/grid-item-base'], function (exports, _gridItemBase) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _gridItemBase.default.extend({
		/**
   * @property classNames
   * @type {string[]}
   * @default ['grid-item-procore']
   */
		classNames: ['grid-item-procore'],

		/**
   * Label identifying the name of the organization-integration
   *
   * @public
   * @property integrationName
   * @type {string}
   * @default 'procore'
   */
		integrationName: 'procore',

		/**
   * Ember route to follow when this element is clicked
   *
   * @public
   * @property dashboardRoute
   * @type {string}
   * @default 'company-settings.procore'
   */
		dashboardRoute: 'company-settings.procore',

		/**
   * URL path to the company logo image. Should begin with '/'
   *
   * @public
   * @property logo
   * @type {string}
   * @default '/images/integration-logos/integration_logos-02.png'
   */
		logo: '/images/integration-logos/integration_logos-02.png',

		/**
   * Feature flag for this integration
   *
   * @public
   * @property featureFlag
   * @type {string}
   * @default 'procore'
   */
		featureFlag: 'procore',

		isEnabled: Ember.computed('features.procore', function () {
			return this.get('features').isEnabled('procore');
		}),

		/**
  * @private
  * @method init
  * @constructor
  */
		init: function init() {
			this._super();

			this.setStatus();
		}
	});
});