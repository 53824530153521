define('busy-app/components/subscriptions/subscription-index', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['v-company-settings-subscription'],

		subscription: Ember.inject.service('subscription'),

		// these can't be a computed or the upgrade-dialog will close unexpectedly during the signup process
		showLandingPage: false, // LandingPage is only shown if the organization is a free account
		showSubscriptionUI: false, // Subscripton Details are shown if the organization is NOT a free account

		selectedTab: null,

		tabOptions: Ember.computed(function () {
			return [{ key: 'currentPlan', label: (0, _utils.loc)('Current Plan') },
			// { key: 'allPlans', label: loc('All Plans') },
			{ key: 'invoiceHistory', label: (0, _utils.loc)('History') }];
		}),

		/**
   * @public
   * @method init
   * @constructor
   */
		init: function init() {
			this._super();
			this.set('selectedTab', 'currentPlan');

			return this.setSubscriptionState();
		},


		/**
   * Refresh the accounts current subscription state to ensure it's up to date with any changes.
   *
   * This related `refreshSubscriptionState` action should be passed to child components and called as the final step whenever subscription state is changed.
   *
   * @public
   * @method setSubscriptionState
   */
		setSubscriptionState: function setSubscriptionState() {
			var _this = this;

			_logger.default.info(this, 'setSubscriptionState');

			return this.get('subscription').refreshStatus(function () {
				return true;
			}).then(function () {
				if (!_this.get('isDestroyed')) {
					var showLandingPage = _this.get('subscription.subscriptionStatus.isFreeAccount') || _this.get('subscription.subscriptionStatus.isFreeTrial') && !_this.get('subscription.subscriptionStatus.isFreeTrialUpgraded');

					_this.set('showLandingPage', showLandingPage);
					_this.set('showSubscriptionUI', !showLandingPage);
				}
			});
		},


		actions: {
			/**
    * @public
    * @event refreshSubscriptionState
    * @param tabKey {string} tabOptions key of the tab to be selected
    */
			selectTab: function selectTab(tabKey) {
				if (!this.get('isDestroyed')) {
					this.set('selectedTab', tabKey);
				}
			},


			/**
    * @public
    * @event refreshSubscriptionState
    */
			refreshSubscriptionState: function refreshSubscriptionState() {
				_logger.default.info(this, 'refreshSubscriptionState', {});
				return this.setSubscriptionState();
			}
		}
	});
});