define('busy-app/routes/employees/employee-locations', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		queryParams: {
			id: {
				refreshModel: true
			},
			member_id: {
				refreshModel: true
			},
			filter: {
				refreshModel: false,
				replace: true
			}
		},

		resetController: function resetController(controller) {
			controller.set('id', null);
			controller.set('member_id', null);
			controller.set('filter', null);
		},

		model: function model(params) {
			var _this = this;

			return this.store.findRecord('time-entry', params.id).then(function (model) {
				return Ember.RSVP.hash({
					timeEntry: model,
					member: _this.store.findRecord('member', model.get('memberId')),
					breaks: _this.store.query('time-entry-break', { time_entry_id: model.get('id') })
				}).then(function (data) {
					var ids = [];
					if (!Ember.isNone(data.timeEntry.get('startLocationId'))) {
						ids.push(data.timeEntry.get('startLocationId'));
					}

					if (!Ember.isNone(data.timeEntry.get('endLocationId'))) {
						ids.push(data.timeEntry.get('endLocationId'));
					}

					data.breaks.forEach(function (entryBreak) {
						if (!Ember.isNone(entryBreak.get('startLocationId'))) {
							ids.push(entryBreak.get('startLocationId'));
						}

						if (!Ember.isNone(entryBreak.get('endLocationId'))) {
							ids.push(entryBreak.get('endLocationId'));
						}
					});

					return _this.store.findByIds('member-location', ids).then(function (locations) {
						var startLocation = locations.findBy('id', data.timeEntry.get('startLocationId'));
						data.timeEntry.set('startLocation', startLocation);

						var endLocation = locations.findBy('id', data.timeEntry.get('endLocationId'));
						data.timeEntry.set('endLocation', endLocation);

						data.breaks.forEach(function (entryBreak) {
							// add time entry for location reference.
							entryBreak.set('timeEntry', model);

							var startLocation = locations.findBy('id', entryBreak.get('startLocationId'));
							entryBreak.set('startLocation', startLocation);

							var endLocation = locations.findBy('id', entryBreak.get('endLocationId'));
							entryBreak.set('endLocation', endLocation);
						});

						model.set('timeEntryBreaks', data.breaks);
						model.set('member', data.member);
						return model;
					});
				});
			});
		}
	});
});