define('busy-app/utils/container/container', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function (props, watchedProps, map) {
		return Ember.Object.extend({
			id: null,
			parentId: null,
			model: null,
			sortBy: null,
			reverseSort: false,

			init: function init() {
				this._super();
				this.recalculate();
			},
			pushObject: function pushObject(model) {
				this.get('model').pushObject(model);
			},


			recalculate: Ember.observer.apply(undefined, _toConsumableArray(watchedProps).concat([function () {
				var _this = this;

				props.forEach(function (key) {
					if (!Ember.isEmpty(_this.get('model'))) {
						var meta = map.get(key);
						var val = void 0;
						if (meta.aggregateType === 'sum') {
							// sum property
							val = _this.get('model').mapBy(key).reduce(function (a, b) {
								return a + b;
							}, 0);
						} else if (meta.aggregateType === 'bool') {
							// default bools to true
							val = true;
							// if a false is found then it is all false
							_this.get('model').mapBy(key).forEach(function (value) {
								return val = value === false ? false : val;
							});
						}
						// set value of the key
						_this.set(key, val);
					} else {
						_this.set(key, null);
					}
				});
			}])),

			sort: function sort() {
				if (this.get('model.length') > 1 && (!Ember.isEmpty(this.get('reverseSortBy')) || !Ember.isEmpty(this.get('sortBy')))) {
					var sorted = this.get('model');

					// reverse sort by first
					if (!Ember.isEmpty(this.get('reverseSortBy'))) {
						sorted = sorted.sortBy.apply(sorted, this.get('reverseSortBy'));
						sorted = sorted.reverse();
					}

					// sort by second if both sortBy and reverseSortBy exist
					if (!Ember.isEmpty(this.get('sortBy'))) {
						sorted = this.get('model').sortBy.apply(sorted, this.get('sortBy'));

						// legacy support for reverseSort remove this after deprecation is through
						if (Ember.isEmpty(this.get('reverseSortBy')) && this.get('reverseSort') === true) {
							// deprecate()
							sorted = sorted.reverse();
						}
					}
					this.set('model', sorted);
				}
			}
		});
	};

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}
});