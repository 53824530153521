define('busy-app/collections/time-entry', ['exports', 'busy-app/collections/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		/**
   * Use this function to rebuild the data structure
   * returned from the getter calls
   *
   * @public
   * @method buildModels
   * @param data {object} keyed model object
   * @returns {array|object}
   */
		buildModels: function buildModels(data) {
			// Set up the data structure however it needs to be for this collection.
			// buildModels can return either an array of models or a single model
			// with nested model objects.

			return data.timeEntry;
		},


		/**
   * Collections may have a predefined model
   * structure for reusability. This is not required
   * so this function may be removed if not used.
   *
   * @public
   * @method model
   * @return {EmberPromise}
   */
		model: function model(startTime, endTime, memberId) {
			var params = {
				member_id: memberId,
				_lte: { start_time: endTime },
				_gte: { end_time: startTime }
			};

			return this.getter.query('time-entry', params).join('project', 'time-entry', 'project_id').join('cost-code', 'time-entry', 'cost_code_id').joinAll('time-entry-break', 'time-entry', 'id').fetch();
		}
	});
});