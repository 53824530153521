define('busy-app/components/time/pay-period/signature-breadcrumb', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'span',

		access: Ember.inject.service(),
		auth: Ember.inject.service(),
		features: Ember.inject.service(),
		router: Ember.inject.service(),
		signature: Ember.inject.service(),

		member: null,
		payPeriodSeconds: null,
		memberTimeDocument: null,

		isTimecardMine: Ember.computed('auth.memberId', 'member.id', function () {
			return this.get('auth.memberId') === this.get('member.id');
		}),

		isSupervisor: Ember.computed('member', function () {
			var supervisorAction = 'manageTimeEntries';
			var member = this.get('member');

			if (Ember.isNone(member) || Ember.isNone(member.get('id'))) {
				return false;
			}

			return this.get('access').canTakeActionFor(supervisorAction, member);
		}),

		signatureState: Ember.computed('isTimecardMine', 'isSupervisor', 'payPeriodSeconds.isSignable', 'features.{signatures,signatures-supervisor}', 'signature.{isTimecardSigningEnabled,isTimecardSupervisorSigningEnabled}', 'memberTimeDocument.{hasValidSelfSignature,needsSelfSignatureResign,hasBothValidSignatures,needsEitherSignatureResign}',
		// 'payPeriodSeconds.memberTimeDocument.needsEitherSignatureResign',
		// 'payPeriodSeconds.memberTimeDocument.needsAuthoritativeSignatureResign',
		function () {

			if (Ember.isNone(this.get('isTimecardMine')) || Ember.isNone(this.get('isSupervisor')) || Ember.isNone(this.get('payPeriodSeconds')) || Ember.isNone(this.get('features')) || Ember.isNone(this.get('signature'))) {
				return null;
			}

			return this.get('signature').getSignatureState(this.get('isTimecardMine'), this.get('isSupervisor'), this.get('payPeriodSeconds'), this.get('features'), this.get('signature'), this.get('memberTimeDocument'));
		}),

		signatureStateClass: Ember.computed('signatureState', function () {
			return {
				'Signed': 'd-button text',
				'Sign': 'd-button blue',
				'Re-Sign': 'd-button red'
			}[this.get('signatureState')];
		}),

		actions: {
			openFullReport: function openFullReport(payPeriod) {
				_utils.Assert.funcNumArgs(arguments, 1, true);
				_utils.Assert.isObject(payPeriod);

				var queryParams = {
					start: payPeriod.get('startTime'),
					member_id: payPeriod.get('memberId'),
					end: payPeriod.get('endTime')
				};

				return this.get('router').transitionTo('time-card.full-report', { queryParams: queryParams });
			}
		}
	});
});