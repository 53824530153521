define('busy-app/utils/models/collections/payroll-member-cost-code-csv', ['exports', 'busy-app/utils/models/collections/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		member: null,
		costCode: null,

		wage: null,
		wageType: null,
		wageTypeOrder: null,
		hours: null,
		time: null,
		timeSort: null,
		wageKey: null,

		description: Ember.computed('model.description', function () {
			return this.get('model.description') || '';
		})
	});
});