define('busy-app/transforms/svg', ['exports', 'ember-data'], function (exports, _emberData) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});


		var noop = function noop() {};

		exports.default = _emberData.default.Transform.extend({
				deserialize: function deserialize(serialized) {
						return serialized;
				},
				serialize: function serialize(deserialized) {
						var safeString = Ember.String.htmlSafe(deserialized);
						var type = 'image/svg+xml';
						var svgBlob = new Blob([safeString], { type: type });

						var fileObj = Ember.Object.create({
								file: svgBlob,
								uploadStart: noop,
								uploadComplete: noop,
								uploadProgress: noop
						});

						return fileObj;
				}
		});
});