define('busy-app/components/select-menu/project', ['exports', 'busy-app/components/select-menu/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({

		classNames: ['c-project-select-menu'],

		_storedList: null,
		depth: 1,
		bypassRequireProject: false,

		allowNone: Ember.computed('bypassRequireProject', 'auth.organization.requireProject', function () {
			return !Ember.get(this, 'auth.organization.requireProject') || Ember.get(this, 'bypassRequireProject');
		}),

		menuTitle: Ember.computed('selectedItem.fullTitle', function () {
			// default title is `None`
			var title = (0, _utils.loc)('None');

			// if the selectedTitle is set to non-empty string
			// then use it for the title
			if (!Ember.isEmpty(this.get('selectedItem.title'))) {
				title = this.get('selectedItem.fullTitle');
			}

			return title;
		}),

		onAutoOpenChanged: Ember.observer('autoOpen', function () {
			var _this = this;

			if (Ember.get(this, 'autoOpen')) {
				Ember.run.next(this, function () {
					Ember.setProperties(_this, {
						autoOpen: false,
						selectedTitle: '',
						selectedItem: null
					});

					_this.send('openMenu', 'auto');
				});
			}
		}),

		loadRecent: function loadRecent() {
			var _this2 = this;

			var query = { member_id: this.get('auth.member.id'), project_id: '!_-NULL-_', page: 1, page_size: 5, _desc: ['start_time'] };

			return this.get('store').query('time-entry', query).then(function (model) {
				var ids = _this2.getPropertyArray(model, 'projectId');
				return _this2.get('store').findWhereIn('project', 'id', ids, { archived_on: '_-NULL-_', deleted_on: '_-NULL-_' }).then(function (projects) {
					return _this2.loadChildren(projects);
				});
			});
		},
		loadData: function loadData() {
			var _this3 = this;

			var depth = 1;

			var query = {
				page: Ember.get(this, 'page'),
				page_size: Ember.get(this, 'pageSize'),
				archived_on: null,
				_asc: ['title']
			};

			// check for a selected project and use its depth and id
			if (!Ember.isNone(Ember.get(this, 'selectedItem.id'))) {
				query.parent_project_id = Ember.get(this, 'selectedItem.id');
				depth = Ember.get(this, 'selectedItem.depth') + 1;
			}

			query.depth = depth;

			// get project models
			return Ember.get(this, 'store').query('project', query).then(function (model) {
				return _this3.loadChildren(model).then(function (models) {
					// add unassigned model for parent project
					if (!Ember.isEmpty(models) && !Ember.isNone(_this3.get('selectedItem.id'))) {
						var unassigned = Ember.get(_this3, 'store').createRecord('project', {
							_model: Ember.get(_this3, 'selectedItem'),
							title: (0, _utils.loc)('Unassigned'),
							isPlaceholder: true
						});

						models.pushObject(unassigned._internalModel);
					}

					// set depth index for component
					Ember.set(_this3, 'depth', depth);

					return models;
				});
			});
		},
		loadSearch: function loadSearch(keyword) {
			var _this4 = this;

			var query = {
				page: Ember.get(this, 'page'),
				page_size: 1000,
				archived_on: '_-NULL-_',
				_search: keyword
			};

			if (!Ember.isNone(Ember.get(this, 'selectedItem.id'))) {
				query.parent_project_id = Ember.get(this, 'selectedItem.rootProjectId');
			}

			return Ember.get(this, 'store').query('project', query).then(function (model) {
				return _this4.filterArchived(model);
			});
		},
		filterArchived: function filterArchived(model) {
			var deleted_on = null,
			    archived_on = null,
			    _asc = ['title'],
			    ids = this.getPropertyArray(model, 'rootProjectId');

			return Ember.get(this, 'store').findWhereIn('project', 'id', ids, { deleted_on: deleted_on, archived_on: archived_on, _asc: _asc }).then(function (parents) {
				var newModels = [];
				model.forEach(function (item) {
					var rootProject = parents.findBy('id', Ember.get(item, 'rootProjectId'));
					if (!Ember.isNone(rootProject)) {
						Ember.set(item, 'rootProject', rootProject);
						newModels.pushObject(item);
					}
				});

				return newModels.sortBy('fullTitle');
			});
		},
		loadNextProjects: function loadNextProjects(item) {
			var _this5 = this;

			// push past state for back buttun queue
			this.pushState();

			// reset state
			Ember.set(this, 'selectedItem', item);
			Ember.set(this, 'page', 1);

			// show loading animation
			this.startLoading();

			return this.loadData().then(function (model) {
				// set model data
				Ember.set(_this5, 'totalRows', Ember.get(model, 'meta.totalRows'));
				Ember.set(_this5, 'model', []);
				Ember.set(_this5, 'model', model);

				// remove loading animation
				_this5.stopLoading();
				return model;
			});
		},
		loadPrevProjects: function loadPrevProjects() {
			this.popState();
		},
		loadChildren: function loadChildren(model) {
			var deleted_on = null,
			    archived_on = null,
			    _asc = ['title'],
			    ids = this.getPropertyArray(model, 'id');

			if (ids.length > 0) {
				return Ember.get(this, 'store').findWhereIn('project', 'parent_project_id', ids, { deleted_on: deleted_on, archived_on: archived_on, _asc: _asc }).then(function (children) {
					// add child models to parents
					model.forEach(function (item) {
						return Ember.set(item, 'children', children.filterBy('parentProjectId', Ember.get(item, 'id')));
					});
					return model;
				});
			}
			return Ember.RSVP.resolve(model);
		},
		pushState: function pushState() {
			Ember.get(this, '_storedList').push({
				selectedItem: Ember.get(this, 'selectedItem'),
				model: Ember.get(this, 'model'),
				depth: Ember.get(this, 'depth'),
				keyword: Ember.get(this, 'keyword'),
				page: Ember.get(this, 'page'),
				pageSize: Ember.get(this, 'pageSize')
			});
		},
		popState: function popState() {
			var stored = Ember.get(this, '_storedList').pop();
			if (!Ember.isNone(stored)) {
				Ember.set(this, 'selectedItem', Ember.get(stored, 'selectedItem'));
				Ember.set(this, 'model', Ember.get(stored, 'model'));
				Ember.set(this, 'page', Ember.get(stored, 'page'));
				Ember.set(this, 'pageSize', Ember.get(stored, 'pageSize'));
				Ember.set(this, 'depth', Ember.get(stored, 'depth'));
				Ember.set(this, 'keyword', Ember.get(stored, 'keyword'));
			}
		},
		getSelectedTitle: function getSelectedTitle(item) {
			return Ember.get(item, 'fullTitle');
		},
		selectHandler: function selectHandler(item) {
			var checkChildren = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

			if (!Ember.get(this, 'isDestroyed')) {
				if (!Ember.isNone(item)) {
					if (checkChildren && Ember.get(item, 'children.length') > 0) {
						return this.loadNextProjects(item);
					}

					item = Ember.get(item, 'isPlaceholder') ? Ember.get(item, '_model') : item;
				}
				return this._super(item);
			}
		},
		handleBack: function handleBack() {
			if (!Ember.get(this, 'isDestroyed')) {
				if (Ember.get(this, 'depth') > 1) {
					return this.loadPrevProjects();
				}

				return this._super();
			}
		},
		openMenuHandler: function openMenuHandler() {
			var openedBy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'manual';

			Ember.set(this, '_storedList', []);
			Ember.set(this, 'depth', 1);
			return this._super(openedBy);
		},


		actions: {
			selectItemAction: function selectItemAction(item) {
				this.selectHandler(item, false);
			}
		}
	});
});