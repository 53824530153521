define('busy-app/routes/employees/employee', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/components/mixins/tab-route'], function (exports, _authenticatedRouteMixin, _tabRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _tabRoute.default, {
		queryParams: {
			timesheet_start: { replace: true },
			timesheet_end: { replace: true },
			timesheet_mode: { replace: true }
		},

		signature: Ember.inject.service('signature'),

		model: function model(params) {
			var memberPromise = this.store.findRecord('member', params.id);
			var needsPromise = Ember.RSVP.resolve();
			if (this.get('features.signatures')) {
				needsPromise = this.get('signature').getNeedsTimecardSignature(params.id);
			}

			return Ember.RSVP.hash({
				member: memberPromise,
				showTimecardBadge: needsPromise
			});
		}
	});
});