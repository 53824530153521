define('busy-app/models/position', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		organizationId: _emberData.default.attr('string'),
		title: _emberData.default.attr('string'),
		level: _emberData.default.attr('number'),

		canEditPosition: _emberData.default.attr('boolean', { defaultValue: true }),
		manageBudgets: _emberData.default.attr('boolean', { defaultValue: false }),
		manageProjects: _emberData.default.attr('boolean', { defaultValue: false }),
		manageProjectGroups: _emberData.default.attr('boolean', { defaultValue: false }),
		manageCostCodes: _emberData.default.attr('boolean', { defaultValue: false }),
		viewMemberExpenses: _emberData.default.attr('boolean', { defaultValue: false }),
		lockPayPeriods: _emberData.default.attr('boolean', { defaultValue: false }),
		viewProjectExpenses: _emberData.default.attr('boolean', { defaultValue: false }),
		manageCompanySettings: _emberData.default.attr('boolean', { defaultValue: false }),
		manageEmployeeGroups: _emberData.default.attr('boolean', { defaultValue: false }),
		viewEquipmentStatus: _emberData.default.attr('boolean', { defaultValue: false }),
		viewEquipmentDetails: _emberData.default.attr('boolean', { defaultValue: false }),
		manageEquipment: _emberData.default.attr('boolean', { defaultValue: false }),
		viewAllActivityReport: _emberData.default.attr('boolean', { defaultValue: false }),
		scopeByGroup: _emberData.default.attr('boolean', { defaultValue: false }),
		dailyProjectPrompts: _emberData.default.attr('boolean', { defaultValue: false }),
		dailyProjectPromptsUseSubprojects: _emberData.default.attr('boolean', { defaultValue: false }),

		manageMediaCollection: _emberData.default.attr('number', { defaultValue: 0 }),
		requestSignatures: _emberData.default.attr('number', { defaultValue: 0 }),
		manageEmployees: _emberData.default.attr('number', { defaultValue: 0 }),
		timeEvents: _emberData.default.attr('number', { defaultValue: 1 }),
		manageTimeEntries: _emberData.default.attr('number', { defaultValue: 0 }),
		memberLock: _emberData.default.attr('number', { defaultValue: 0 }),
		viewMemberActivityReport: _emberData.default.attr('number', { defaultValue: 1 }),

		submittedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		members: _emberData.default.hasMany('member', { query: { archived_on: null } }),

		manageTimeWatcher: Ember.observer('manageTimeEntries', function () {
			if (this.get('manageTimeEntries') > this.get('timeEvents')) {
				this.set('timeEvents', this.get('manageTimeEntries'));
			}
		}),

		timeEventsWatcher: Ember.observer('timeEvents', function () {
			var timeEvents = this.get('timeEvents') || 0;
			timeEvents = timeEvents >= 8 ? timeEvents - 8 : timeEvents;
			timeEvents = timeEvents >= 4 ? timeEvents - 4 : timeEvents;
			timeEvents = timeEvents >= 2 ? timeEvents - 2 : timeEvents;

			if (timeEvents !== 1) {
				this.set('timeEvents', (this.get('timeEvents') || 0) + 1);
			}
		}),

		parseSetting: function parseSetting(value) {
			var higher = value >= 8;
			value %= 8;

			var same = value >= 4;
			value %= 4;

			var lower = value >= 2;
			value %= 2;

			var self = value >= 1;

			return {
				higher: higher,
				same: same,
				lower: lower,
				self: self
			};
		},


		/**
   * there is only one view expenses setting
   * so set member expenses to the same as project expenses
   *
   */
		setMemberExpenses: Ember.observer('viewProjectExpenses', function () {
			this.set('viewMemberExpenses', this.get('viewProjectExpenses'));
		}),

		setProjectGroups: Ember.observer('manageProjects', function () {
			this.set('manageProjectGroups', this.get('manageProjects'));
		})
	});
});