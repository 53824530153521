define('busy-app/components/time/full-report/signature-notification-bar', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		features: Ember.inject.service(),
		signature: Ember.inject.service(),

		memberTimeDocument: null,
		payPeriod: null,
		isTimecardMine: null,
		isSupervisor: null,

		showResignInfo: false,

		needsEitherSignature: Ember.computed.or('needsEmployeeSignature', 'needsSupervisorSignature'),
		needsBothSignature: Ember.computed.and('needsEmployeeSignature', 'needsSupervisorSignature'),
		needsEmployeeSignature: Ember.computed.and('signature.isTimecardSigningEnabled', 'memberTimeDocument.needsSelfSignature'),
		needsSupervisorSignature: Ember.computed.and('signature.isTimecardSupervisorSigningEnabled', 'memberTimeDocument.needsAuthoritativeSignature'),

		needsEitherSignatureResign: Ember.computed.or('needsEmployeeSignatureResign', 'needsSupervisorSignatureResign'),
		needsBothSignatureResign: Ember.computed.and('needsEmployeeSignatureResign', 'needsSupervisorSignatureResign'),
		needsEmployeeSignatureResign: Ember.computed.and('signature.isTimecardSigningEnabled', 'memberTimeDocument.needsSelfSignatureResign'),
		needsSupervisorSignatureResign: Ember.computed.and('signature.isTimecardSupervisorSigningEnabled', 'memberTimeDocument.needsAuthoritativeSignatureResign'),

		isSupervisorMode: Ember.computed('isSupervisor', 'features.signatures-supervisor', 'signature.isTimecardSupervisorSigningEnabled', function () {
			return this.get('isSupervisor') && this.get('features.signatures-supervisor') && this.get('signature.isTimecardSupervisorSigningEnabled');
		}),

		signatureState: Ember.computed('isTimecardMine', 'isSupervisor', 'payPeriod', 'features', 'signature', 'memberTimeDocument', function () {
			return this.get('signature').getSignatureState(this.get('isTimecardMine'), this.get('isSupervisor'), this.get('payPeriod'), this.get('features'), this.get('signature'), this.get('memberTimeDocument'));
		}),

		// possible states
		//
		// 	- features.signatures disabled (!features.signatures)
		// 		- no notifications displayed
		//
		// 	- no signature needed (!needsEitherSignature)
		// 		- no notifications displayed
		//
		// 	- features.signatures-supervisor disabled (!features.signatures-supervisor)
		// 		- no employee signature needed (!needsEmployeeSignature)
		// 			- no notifications displayed
		// 		- employee viewing own time card (isTimecardMine)
		// 	  	- no signature needed (!needsEmployeeSignature)
		// 		  		- no notification
		// 	  	- needs signature, NO previous signatures (needsEmployeeSignature && !needsEmployeeSignatureResign)
		// 	  		- "Ready to sign below"
		// 	  	- needs signature, has previous signatures (needsEmployeeSignature && needsEmployeeSignatureResign)
		// 	  		- "Please re-sign below"
		//
		//
		// 	- features.signatures-supervisor enabled (features.signatures-supervisor)
		// 		- employee viewing own time card (isTimecardMine)
		// 		  - can NOT edit own time (!isSupervisor)
		// 		  	- no signature needed (!needsEmployeeSignature)
		// 			  		- no notification
		// 		  	- needs signature, NO previous signatures (needsEmployeeSignature && !needsEmployeeSignatureResign)
		// 		  		- "Ready to sign below"
		// 		  	- needs signature, has previous signatures (needsEmployeeSignature && needsEmployeeSignatureResign)
		// 		  		- "Please re-sign below"
		// 		  - can edit own time (isSupervisor)
		// 		  	- no signature needed (!needsEitherSignature)
		// 			  	- no notification
		// 		  	- needs both employee and supervisor signature (needsBothSignature)
		// 		  		- "Ready to sign below"
		// 		  	- needs only employee signature (needsEmployeeSignature)
		// 		  		- "Ready for employee signature below"
		// 		  	- needs only supervisor signature (needsSupervisorSignature)
		// 		  		- "Ready for supervisor signature below"
		//

		notificationText: Ember.computed('features.{signatures}', 'signature.{isTimecardSigningEnabled}', 'signatureState', 'isSupervisorMode', 'needsBothSignature', 'needsEmployeeSignature', 'needsSupervisorSignature', function () {
			var resignText = (0, _utils.loc)('Please re-sign below');
			var signText = (0, _utils.loc)('Ready to sign below');
			var employeeSignText = (0, _utils.loc)('Ready for employee signature below');
			var supervisorSignText = (0, _utils.loc)('Ready for supervisor signature below');
			var isSigningEnabled = this.get('features.signatures') && this.get('signature.isTimecardSigningEnabled');
			var isSignatureNeeded = this.get('signatureState') === 'Sign' || this.get('signatureState') === 'Re-Sign';

			var notificationText = null;

			// Logger.info(this, 'notificationText', {isSigningEnabled, isSignatureNeeded});

			// feature is not enabled, or no signature is needed
			if (!isSigningEnabled || !isSignatureNeeded) {
				return null;
			}

			// Logger.info(this, 'notificationText', this.getProperties(
			// 	'features.signatures',
			// 	'signature.isTimecardSigningEnabled',
			// 	'signatureState', 'isSupervisorMode',
			// 	'needsBothSignature', 'needsEmployeeSignature', 'needsSupervisorSignature',
			// ));

			// supervisor mode is disabled, or not allowed
			if (!this.get('isSupervisorMode')) {
				if (this.get('signatureState') === 'Sign') {
					notificationText = signText;
				} else if (this.get('signatureState') === 'Re-Sign') {
					notificationText = resignText;
				}
			}
			// supervisor mode enabled and allowed
			else {
					if (this.get('signatureState') === 'Re-Sign') {
						notificationText = resignText;
					} else if (this.get('signatureState') === 'Sign') {
						if (this.get('needsBothSignature')) {
							notificationText = signText;
						} else if (this.get('needsEmployeeSignature')) {
							notificationText = employeeSignText;
						} else if (this.get('needsSupervisorSignature')) {
							notificationText = supervisorSignText;
						}
					}
				}

			return notificationText;
		}),

		actions: {
			openResignInfo: function openResignInfo() {
				this.set('showResignInfo', true);
			},
			closeResignInfo: function closeResignInfo() /* dialog */{
				this.set('showResignInfo', false);
			}
		}
	});
});