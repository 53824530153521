define('busy-app/components/select-menu/member', ['exports', 'busy-app/components/select-menu/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		auth: Ember.inject.service(),
		classNames: ['c-member-select-menu'],

		menuTitle: Ember.computed('selectedItem.fullName', function () {
			// default title is `None`
			var title = (0, _utils.loc)('None');

			// if the selectedTitle is set to non-empty string
			// then use it for the title
			if (!Ember.isEmpty(Ember.get(this, 'selectedItem.fullName'))) {
				title = Ember.get(this, 'selectedItem.fullName');
			}

			return title;
		}),

		loadRecent: function loadRecent() {
			return Ember.RSVP.resolve([]);
		},
		loadData: function loadData() {
			var page = Ember.get(this, 'page'),
			    page_size = Ember.get(this, 'pageSize'),
			    archived_on = null,
			    _asc = ['first_name', 'last_name'];

			var query = { page: page, page_size: page_size, archived_on: archived_on, _asc: _asc };

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				Ember.setProperties(query, { member_group_id: Ember.get(this, 'auth.member.memberGroupId') });
			}

			return Ember.get(this, 'store').query('member', query);
		},
		loadSearch: function loadSearch(keyword) {
			var page = Ember.get(this, 'page'),
			    page_size = 100,
			    archived_on = null,
			    _asc = ['first_name', 'last_name'],
			    _search = keyword;

			var query = { page: page, page_size: page_size, archived_on: archived_on, _search: _search, _asc: _asc };

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				Ember.setProperties(query, { member_group_id: Ember.get(this, 'auth.member.memberGroupId') });
			}

			return Ember.get(this, 'store').query('member', query);
		},
		getSelectedTitle: function getSelectedTitle(item) {
			return Ember.get(item, 'fullName');
		}
	});
});