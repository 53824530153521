define('busy-app/components/time/time-entry/safety', ['exports', '@busy-web/utils', 'busy-app/utils/logger', 'busy-app/utils/round-time'], function (exports, _utils, _logger, _roundTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    auth: Ember.inject.service('auth'),
    store: Ember.inject.service('store'),

    organization: Ember.computed('auth.organization', function () {
      return Ember.get(this, 'auth.organization');
    }),

    member: null,
    memberEntries: null,
    safetyObject: null,
    timeEntry: null,

    timeAccurateReason: '',
    injuryDescription: '',
    breakFollowedReason: '',
    breakFollowed: true,
    showCaliforniaBreakDialog: false,
    showRestBreakRequirements: false,
    caBreakMismatch: false,

    init: function init() {
      this._super();

      // get member if only one member entry was submitted
      if (Ember.get(this, 'memberEntries.length') === 1) {
        var member = Ember.get(this, 'memberEntries.firstObject');
        if (member.id === Ember.get(this, 'auth.member.id')) {
          Ember.set(this, 'member', member);
        }
      }

      this.createNewSafetySignature();
    },


    headerTitle: Ember.computed('auth.organization.safetySignatureMessage', function () {
      if (Ember.get(this, 'auth.organization.safetySignatureMessage')) {
        return Ember.get(this, 'auth.organization.safetySignatureMessage');
      } else {
        return 'Were you injured?';
      }
    }),

    isToday: Ember.computed('timeEntry', function () {
      var now = _utils.Time.nistTimestamp();
      var timeEntryEnd = Ember.get(this, 'timeEntry.endTime');
      return _utils.Time.date(timeEntryEnd).startOf('day').unix() === _utils.Time.date(now).startOf('day').unix();
    }),

    timeInaccurate: Ember.computed('safetyObject.timeAccurate', function () {
      if (!Ember.isEmpty(Ember.get(this, 'safetyObject.timeAccurate'))) {
        return !Ember.get(this, 'safetyObject.timeAccurate') || false;
      }
      return false;
    }),

    createNewSafetySignature: function createNewSafetySignature() {
      var offset = _utils.Time.timezone(Ember.get(this, 'date'));
      var isDST = _utils.Time.isDST(Ember.get(this, 'dateStamp'));
      var timeEntryEnd = Ember.get(this, 'timeEntry.endTime');
      var endTime = this.getEndTime();
      var startTime = _utils.Time.date(Ember.get(this, 'timeEntry.startTime')).startOf('day').unix();
      if (!Ember.isNone(timeEntryEnd) && Ember.get(this, 'isToday')) {
        endTime = timeEntryEnd;
      }

      if (!Ember.isNone(timeEntryEnd) && !Ember.get(this, 'isToday')) {
        endTime = _utils.Time.date(timeEntryEnd).endOf('day').unix();
      }

      var endDay = _utils.Time.date(endTime).startOf('day').unix();

      if (startTime !== endDay) {
        startTime = endDay;
      }

      var createdOn = endTime - offset;

      // this dialog should only be shown for auth member
      if (Ember.get(this, 'member.id') === Ember.get(this, 'auth.member.id')) {
        var safetyObject = Ember.get(this, 'store').createRecord('safety-signature', {
          memberId: Ember.get(this, 'member.id'),
          startTime: startTime,
          proxyMemberId: Ember.get(this, 'auth.member.id'),
          createdOnOffset: offset,
          createdOnDst: isDST,
          startTimeOffset: offset,
          endTimeOffset: offset,
          startTimeDst: isDST,
          endTimeDst: isDST,
          endTime: endTime,
          deviceType: 'Web Browser',
          createdOn: createdOn
        });
        Ember.set(this, 'safetyObject', safetyObject);
      } else {
        // close dialog and show error in console
        _logger.default.error("Safety dialog was shown for a member other than the auth member");
        this.sendAction('onClose');
      }
    },
    getEndTime: function getEndTime() {
      return (0, _roundTime.roundOrgTime)(Ember.get(this, "auth.organization"), _utils.Time.nistTimestamp(), { isEnd: true, isSwitch: false });
    },
    saveSafetySignature: function saveSafetySignature() {
      var _this = this;

      var safetySignature = Ember.get(this, 'auth.organization.safetySignature');
      var timeAccuracy = Ember.get(this, 'auth.organization.timeAccuracy');
      var breakPolicy = Ember.get(this, 'auth.organization.breakPolicy');
      var injuredReason = Ember.getWithDefault(this, 'injuredReason', '').trim();
      var timeAccurateReason = Ember.getWithDefault(this, 'timeAccurateReason', '').trim();
      var safetyObject = Ember.get(this, 'safetyObject');
      var breakFollowedReason = Ember.getWithDefault(this, 'breakFollowedReason', '').trim();

      // verify that safetySignature is enabled and
      // the injured question has been answered.
      if (safetySignature) {
        if (Ember.isNone(Ember.get(safetyObject, 'injured'))) {
          return Ember.RSVP.reject("Were you injured? You must select yes or no.");
        }

        if (!Ember.isEmpty(injuredReason)) {
          Ember.set(safetyObject, 'injuryDescription', injuredReason);
        }
      }

      // verify that breakPolicy is enabled and
      // the break policy question has been answered.
      if (breakPolicy) {
        if (Ember.isNone(Ember.get(safetyObject, 'breakPolicyFollowed'))) {
          return Ember.RSVP.reject('Did you follow break policy? You must select yes or no.');
        }

        //set break policy reason
        if (!Ember.isEmpty(breakFollowedReason)) {
          Ember.set(safetyObject, 'breakPolicyFollowedReason', breakFollowedReason);
        }
      }

      // verify that timeAccuracy is enabled and
      // the timeAccurate question has been answered.
      if (timeAccuracy) {
        if (Ember.isNone(Ember.get(safetyObject, 'timeAccurate'))) {
          return Ember.RSVP.reject("Is your time accurate? You must select yes or no.");
        }

        // set time accurate reason
        if (!Ember.isEmpty(timeAccurateReason)) {
          Ember.set(safetyObject, 'timeAccurateReason', timeAccurateReason);
        }
      }

      // save the safetyObject if either safetySignature or timeAccuracy is enabled
      // this is a reduntant check since this dialog should not be shown if one of the
      // following options is not enabled
      if (safetySignature || timeAccuracy || breakPolicy) {
        var offset = _utils.Time.timezone(Ember.get(this, 'date'));
        var endTime = this.getEndTime();
        var createdOn = endTime - offset;

        Ember.setProperties(safetyObject, { endTime: endTime, createdOn: createdOn });

        return safetyObject.save().then(function () {
          _this.sendAction('afterSave', Ember.get(safetyObject, 'injured'), Ember.get(safetyObject, 'timeAccurate'), Ember.get(safetyObject, 'breakPolicyFollowed'), endTime);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    checkCaBreaks: function checkCaBreaks() {
      var totalTime = this.get('timeEntry.totalTime');
      var breakTime = this.get('timeEntry.breakTime');

      if (totalTime > 18000 && totalTime < 36000) {
        if (breakTime < 1800) {
          this.set('caBreakMismatch', true);
        }
      } else if (totalTime > 36000 && totalTime < 54000) {
        if (breakTime < 3600) {
          this.set('caBreakMismatch', true);
        }
      } else if (totalTime > 54000 && totalTime < 72000) {
        if (breakTime < 5600) {
          this.set('caBreakMismatch', true);
        }
      } else if (totalTime > 72000) {
        if (breakTime < 7200) {
          this.set('caBreakMismatch', true);
        }
      }
    },


    actions: {
      close: function close() {
        this.sendAction('onClose');
      },
      setInjured: function setInjured(item) {
        Ember.set(this, 'safetyObject.injured', item);
      },
      setBreakFollowed: function setBreakFollowed(item) {
        Ember.set(this, 'safetyObject.breakPolicyFollowed', item);
        Ember.set(this, 'breakFollowed', item);
        if (this.get('organization.breakPolicyCalifornia')) {
          this.checkCaBreaks();
        }
      },
      setTimeAccuracy: function setTimeAccuracy(item) {
        Ember.set(this, 'safetyObject.timeAccurate', item);
      },
      saveInjured: function saveInjured() {
        if (!Ember.isNone(Ember.get(this, 'safetyObject'))) {
          return this.saveSafetySignature();
        } else {
          return Ember.RSVP.reject('Signoff can not be performed for other employees.');
        }
      },
      openCaliforniaBreakDialog: function openCaliforniaBreakDialog() {
        this.set('showCaliforniaBreakDialog', true);
      },
      closeCaliforniaBreakDialog: function closeCaliforniaBreakDialog() {
        this.set('showCaliforniaBreakDialog', false);
      },
      openRestBreakRequirements: function openRestBreakRequirements() {
        this.set('showRestBreakRequirements', true);
      }
    }
  });
});