define('busy-app/polymorphs/payroll-member', ['exports', 'busy-app/polymorphs/pay-period-member'], function (exports, _payPeriodMember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _payPeriodMember.default.extend({
		wage: null,
		payPeriodType: null,
		projects: null,
		nestedMember: false,

		init: function init() {
			this._super.apply(this, arguments);
			Ember.deprecate('Custom wage date feature not supported', !Ember.get(this, 'features.wageCustomDate'), { id: 'polymorph.payroll-member', until: '999.99.99' });
		},


		hasChildren: Ember.computed('projects.[]', function () {
			return !Ember.isEmpty(this.get('projects'));
		}),

		hasRegular: Ember.computed('regularSeconds', 'regularCost', 'totalRegularSeconds', 'totalRegularCost', function () {
			if (this.get('regularSeconds') > 0 || this.get('regularCost') > 0) {
				return true;
			} else if (this.get('nestedMember') && (this.get('totalRegularSeconds') > 0 || this.get('totalRegularCost') > 0)) {
				return true;
			}
			return false;
		}),

		hasOvertime: Ember.computed('overtimeSeconds', 'overtimeCost', 'totalOvertimeSeconds', 'totalOvertimeCost', function () {
			if (this.get('overtimeSeconds') > 0 || this.get('overtimeCost') > 0) {
				return true;
			} else if (this.get('nestedMember') && (this.get('totalOvertimeSeconds') > 0 || this.get('totalOvertimeCost') > 0)) {
				return true;
			}
			return false;
		}),

		hasDoubletime: Ember.computed('doubletimeSeconds', 'doubletimeCost', 'totalDoubletimeSeconds', 'totalDoubletimeCost', function () {
			if (this.get('doubletimeSeconds') > 0 || this.get('doubletimeCost') > 0) {
				return true;
			} else if (this.get('nestedMember') && (this.get('totalDoubletimeSeconds') > 0 || this.get('totalDoubletimeCost') > 0)) {
				return true;
			}
			return false;
		}),

		/**
   * regular seconds should be used when dealing with member only data.
   * When dealing with pay-period-member-project-seconds this will give you
   * the seconds without the child seconds factored in.
   */
		regularSeconds: Ember.computed('seconds.@each.regularSeconds', function () {
			var _this = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this.get('wage.wageRate') !== 10) {
						// salary employees
						total += item.get('regularSeconds');
						total += item.get('overtimeSeconds');
						total += item.get('doubletimeSeconds');
					} else {
						total += item.get('regularSeconds');
					}
				});
			}
			return total;
		}),

		/**
   * totalRegularSeconds will provide child regular seconds as well as regualr seconds.
   */
		totalRegularSeconds: Ember.computed('seconds.@each.totalRegularSeconds', function () {
			var _this2 = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this2.get('wage.wageRate') !== 10) {
						// salary employees
						total += item.get('totalRegularSeconds');
						total += item.get('totalOvertimeSeconds');
						total += item.get('totalDoubletimeSeconds');
					} else {
						total += item.get('totalRegularSeconds');
					}
				});
			}
			return total;
		}),

		overtimeSeconds: Ember.computed('seconds.@each.overtimeSeconds', function () {
			var _this3 = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this3.get('wage.wageRate') === 10) {
						// non salary employees
						total += item.get('overtimeSeconds');
					}
				});
			}
			return total;
		}),

		totalOvertimeSeconds: Ember.computed('seconds.@each.totalOvertimeSeconds', function () {
			var _this4 = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this4.get('wage.wageRate') === 10) {
						// non salary employees
						total += item.get('totalOvertimeSeconds');
					}
				});
			}
			return total;
		}),

		doubletimeSeconds: Ember.computed('seconds.@each.doubletimeSeconds', function () {
			var _this5 = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this5.get('wage.wageRate') === 10) {
						// non salary employees
						total += item.get('doubletimeSeconds');
					}
				});
			}
			return total;
		}),

		totalDoubletimeSeconds: Ember.computed('seconds.@each.totalDoubletimeSeconds', function () {
			var _this6 = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this6.get('wage.wageRate') === 10) {
						// non salary employees
						total += item.get('totalDoubletimeSeconds');
					}
				});
			}
			return total;
		}),

		regularCost: Ember.computed('salaryCostPerSecond', 'wage.wageRate', 'cost.@each.regularCost', 'regularSeconds', 'totalSeconds', function () {
			var total = 0;
			if (this.get('wage.wageRate') !== 10) {
				// salary employees
				if (this.get('totalSeconds') > 0) {
					// salary employee with time
					total += this.get('regularSeconds') * this.get('salaryCostPerSecond');
					total += this.get('overtimeSeconds') * this.get('salaryCostPerSecond');
					total += this.get('totalDoubletimeSeconds') * this.get('salaryCostPerSecond');
				} else {
					// salary employee with no time use the pay period salary.
					total += this.get('payPeriodSalary');
				}
			} else {
				if (!Ember.isEmpty(this.get('cost'))) {
					this.get('cost').forEach(function (item) {
						return total += item.get('regularCost');
					});
				}
			}
			return total;
		}),

		totalRegularCost: Ember.computed('salaryCostPerSecond', 'wage.wageRate', 'cost.@each.totalRegularCost', 'totalRegularSeconds', 'totalWithChildrenSeconds', function () {
			var total = 0;
			if (this.get('wage.wageRate') !== 10) {
				// salary employees
				if (this.get('totalWithChildrenSeconds') > 0) {
					// salary employee with time
					total += this.get('totalRegularSeconds') * this.get('salaryCostPerSecond');
					total += this.get('totalOvertimeSeconds') * this.get('salaryCostPerSecond');
					total += this.get('doubletimeSeconds') * this.get('salaryCostPerSecond');
				} else {
					// salary employee with no time use the pay period salary.
					total += this.get('payPeriodSalary');
				}
			} else {
				if (!Ember.isEmpty(this.get('cost'))) {
					this.get('cost').forEach(function (item) {
						return total += item.get('totalRegularCost');
					});
				}
			}
			return total;
		}),

		overtimeCost: Ember.computed('salaryCostPerSecond', 'wage.wageRate', 'cost.@each.overtimeCost', 'overtimeSeconds', function () {
			var total = 0;
			if (this.get('wage.wageRate') === 10) {
				if (!Ember.isNone(this.get('cost'))) {
					this.get('cost').forEach(function (item) {
						return total += item.get('overtimeCost');
					});
				}
			}
			return total;
		}),

		totalOvertimeCost: Ember.computed('salaryCostPerSecond', 'wage.wageRate', 'cost.@each.totalOvertimeCost', 'totalOvertimeSeconds', function () {
			var total = 0;
			if (this.get('wage.wageRate') === 10) {
				if (!Ember.isNone(this.get('cost'))) {
					this.get('cost').forEach(function (item) {
						return total += item.get('totalOvertimeCost');
					});
				}
			}
			return total;
		}),

		doubletimeCost: Ember.computed('salaryCostPerSecond', 'wage.wageRate', 'cost.@each.doubletimeCost', 'doubletimeSeconds', function () {
			var total = 0;
			if (this.get('wage.wageRate') === 10) {
				if (!Ember.isNone(this.get('cost'))) {
					this.get('cost').forEach(function (item) {
						return total += item.get('doubletimeCost');
					});
				}
			}
			return total;
		}),

		totalDoubletimeCost: Ember.computed('salaryCostPerSecond', 'wage.wageRate', 'cost.@each.totalDoubletimeCost', 'totalDoubletimeSeconds', function () {
			var total = 0;
			if (this.get('wage.wageRate') === 10) {
				if (!Ember.isNone(this.get('cost'))) {
					this.get('cost').forEach(function (item) {
						return total += item.get('totalDoubletimeCost');
					});
				}
			}
			return total;
		}),

		totalOverburdenCost: Ember.computed('totalCost', 'wage.overburden', function () {
			var cost = this.get('totalCost');
			var burden = this.get('wage.overburden') || 0;
			var over = cost * (burden / 100);

			return cost + over;
		}),

		totalOverburdenCostWithChildren: Ember.computed('totalWithChildrenCost', 'wage.overburden', function () {
			var cost = this.get('totalWithChildrenCost');
			var burden = this.get('wage.overburden') || 0;
			var over = cost * (burden / 100);

			return cost + over;
		}),

		actualCost: Ember.computed('totalCost', 'payPeriodSalary', function () {
			if (this.get('payPeriodSalary') > 0) {
				return this.get('payPeriodSalary');
			} else {
				return this.get('totalCost');
			}
		}),

		salaryCostPerSecond: Ember.computed('payPeriodSalary', 'member.seconds.{regularSeconds,overtimeSeconds,doubletimeSeconds}', function () {
			var cph = 0;
			if (this.get('payPeriodSalary') > 0) {
				var regular = this.get('member.seconds.regularSeconds') || 0; // get regular time
				var overtime = this.get('member.seconds.overtimeSeconds') || 0; // get overtime and multiply it by overtime time and a half
				var doubletime = this.get('member.seconds.doubletimeSeconds') || 0; // get doubletime and multiply it by doubletime

				// get salray for this pay period
				var salary = this.get('payPeriodSalary');

				// add up the total time with overtime and doubletime multipliers
				var totaltime = regular + overtime + doubletime;
				if (totaltime <= 0) {
					totaltime = 1;
				}

				cph = salary / totaltime;
			}
			return cph;
		}),

		payPeriodSalary: Ember.computed('wage.{wage,wageRate}', 'payPeriodType', function () {
			var salary = 0;
			var wageRate = this.get('wage.wageRate');
			var payPeriodType = this.get('payPeriodType');
			if (!Ember.isNone(wageRate) && !Ember.isNone(payPeriodType) && wageRate !== 10) {
				// get wage salary
				salary = this.get('wage.wage');

				// convert all salary to a yearly salary
				if (wageRate === 30) {
					// convert weekly salary to yearly
					salary = salary * 52;
				} else if (wageRate === 40) {
					// convert monthly salary to yearly
					salary = salary * 12;
				}

				// convert salary to the pay-period span
				if (payPeriodType === 10) {
					// calculate weekly salary from yearly
					salary = salary / 52;
				} else if (payPeriodType === 20) {
					// calculate bi weekly salary from yearly
					salary = salary / 26;
				} else if (payPeriodType === 30) {
					// calculate bi monthly salary from yearly
					salary = salary / 24;
				} else if (payPeriodType === 40) {
					// calculate monthly salary from yearly
					salary = salary / 12;
				}
			}
			return salary;
		}),

		estCostHourly: Ember.computed('wage.wageRate', 'totalOverburdenCost', function () {
			var estCostHourly = 0;
			if (this.get('wage.wageRate') === 10) {
				estCostHourly = this.get('totalOverburdenCost');
			}
			return estCostHourly;
		}),

		estCostSalary: Ember.computed('wage.wageRate', 'totalOverburdenCost', function () {
			var estCostSalary = 0;
			if (this.get('wage.wageRate') !== 10) {
				estCostSalary = this.get('totalOverburdenCost');
			}
			return estCostSalary;
		}),

		estCostHourlyWithChildren: Ember.computed('wage.wageRate', 'totalOverburdenCostWithChildren', function () {
			var estCostHourly = 0;
			if (this.get('wage.wageRate') === 10) {
				estCostHourly = this.get('totalOverburdenCostWithChildren');
			}
			return estCostHourly;
		}),

		estCostSalaryWithChildren: Ember.computed('wage.wageRate', 'totalOverburdenCostWithChildren', function () {
			var estCostSalary = 0;
			if (this.get('wage.wageRate') !== 10) {
				estCostSalary = this.get('totalOverburdenCostWithChildren');
			}
			return estCostSalary;
		})
	});
});