define('busy-app/transforms/id', ['exports', 'ember-data', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _emberData, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		deserialize: function deserialize(serialized) {
			if (Ember.isNone(serialized) || serialized === '00000000-0000-0000-0000-000000000000') {
				return null;
			}

			if (!_utils.UUID.isValid(serialized)) {
				_logger.default.error(this, 'Invalid UUID found from api - id: ' + serialized);
			}

			return serialized.toString();
		},
		serialize: function serialize(deserialized) {
			if (Ember.isNone(deserialized)) {
				return null;
			}

			if (!_utils.UUID.isValid(deserialized)) {
				_logger.default.error(this, 'Invalid UUID sent to the api - id: ' + deserialized);
			}

			return deserialized.toString();
		}
	});
});