define('busy-app/services/discovery', ['exports', '@busy-web/utils', 'busy-app/utils/logger', 'busy-app/utils/settings'], function (exports, _utils, _logger, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var MAX_AGE = 60 * 60 * 24; // 1 day = 86400 seconds;
	/* eslint-disable ember/jquery-ember-run */

	/**
  * @module Services
  *
  */

	/**
  * @typedef Manifest
  * @type {object}
  * @property Object.<string, ManifestEntry>
  */

	/**
  * @typedef ManifestEntry
  * @type {object}
  * @property {ManifestEntryMeta} meta
  * @property {string} url
  * @property {string} protocol
  * @property {string} port
  * @property {string} domain
  * @property {string} path
  */

	/**
  * @typedef ManifestEntryMeta
  * @type {object}
  * @property {string} description
  * @property {string} type
  * @property {string} method
  */

	var DISCOVERY_URL = _settings.default.get('discovery_url');
	var MANIFEST_FALLBACK = _settings.default.get('manifest_fallback');

	/**
  * `Services/Discovery`
  *
  * @class Discovery
  * @namespace Services
  * @extends Service
  * @uses Services.Auth
  */
	exports.default = Ember.Service.extend({
		auth: Ember.inject.service('auth'),

		/**
   * @private
   * @type {number} _lastUpdate
   */
		_lastUpdate: 0,

		/**
   * @private
   * @type {Manifest} _manifest
   */
		_manifest: null,

		/**
   * @private
   * @method init
   */
		init: function init() {
			this._super();

			Ember.set(this, '_manifest', MANIFEST_FALLBACK);
		},


		/**
   * Public Methods
   *
   */

		/**
   * Get the manifest
   *
   * If there's a valid in the cache, that version is returned
   * If not, load the remote manifest and return that
   *
   * @public
   * @async
   * @method getManifest
   * @reject {Error}
   * @fulfill {Manifest}
   * @return {Promise<Manifest>}
   */
		getManifest: function getManifest() {
			var isValid = this._getIsValidManifest();

			if (isValid) {
				// Logger.info(this, 'Manifest cache hit');
				var _manifest = Ember.get(this, '_manifest');
				return Ember.RSVP.Promise.resolve(_manifest);
			} else {
				// Logger.info(this, 'Manifest cache miss');
				return this._loadManifest();
			}
		},


		/**
   * Get the ManifestEntry for the provided key
   *
   * @public
   * @async
   * @method getManifestEntry
   * @param key {string}
   * @reject {Error}
   * @fulfill {ManifestEntry}
   * @return {Promise<ManifestEntry>}
   */
		getManifestEntry: function getManifestEntry(key) {
			if (!key || !key.length) {
				throw new Error('key must be provided');
			}

			return this.getManifest().then(function (manifest) {
				if (!Ember.isNone(manifest) && manifest.hasOwnProperty(key)) {
					return Ember.get(manifest, key);
				} else {
					_logger.default.error('Requested manifest key not found', { manifest: manifest, key: key });
					return Ember.RSVP.Promise.reject('Requested manifest key not found');
				}
			});
		},


		/**
   * Do an ajax call to the relevant manifest url, as specified in the provided key
   * Standard jQuery AjaxSettings can be provided
   *
   * @public
   * @async
   * @method ajax
   * @param key {string}
   * @param customProps {JQuery.AjaxSettings}
   * @returns {JQuery.jqXHR}
   */
		ajax: function ajax(key) {
			var _this = this;

			var customProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			if (!key || !key.length) {
				throw new Error('key must be provided');
			}

			if (customProps.url) {
				throw new Error('Do not set { url } property');
			}

			var defaultProps = {
				method: 'GET',
				beforeSend: function beforeSend(xhr) {
					xhr.setRequestHeader('Key-Authorization', Ember.get(_this, 'auth.token'));
				}
			};

			var ajaxProps = Object.assign({}, defaultProps, customProps);

			return this.getManifestEntry(key).then(function (entry) {
				ajaxProps.url = entry.url;

				return Ember.$.ajax(ajaxProps);
			});
		},


		/**
   * Private Methods
   *
   */

		/**
   * Load the remote discovery manifest via a jQuery ajax
   *
   * @private
   * @async
   * @method _loadManifest
   * @reject {Error}
   * @fulfill {Manifest}
   * @return {Promise<Manifest>}
   */
		_loadManifest: function _loadManifest() {
			var _this2 = this;

			// the DISCOVERY_URL does not require authentication, so no Key-Authorization header is provided
			return Ember.$.get(DISCOVERY_URL).then(function (manifest) {
				return _this2._onManifestLoad(manifest);
			}).catch(function (e) {
				return _this2._onManifestLoadError(e);
			});
		},


		/**
   * On success handler for _loadManifest.
   *
   * Saves the manifest data to a local variable, _manifest
   * Saves the current timestamp for cache management
   *
   * @private
   * @method _onManifestLoad
   * @param {Manifest} _manifest
   * @return {Manifest}
   */
		_onManifestLoad: function _onManifestLoad(manifest) {
			_logger.default.info(this, 'Manifest loaded', manifest);

			Ember.setProperties(this, {
				_manifest: manifest,
				_lastUpdate: _utils.Time.timestamp()
			});

			return manifest;
		},


		/**
   * On error handler for _loadManifest.
   *
   * If there is already a manifest in the local cache use it and fail silently
   * If there is no cached manifest, reject the promise so the error can be handled
   *
   * @private
   * @method _onManifestLoadError
   * @param {Error} error
   * @return {Manifest|Error}
   */
		_onManifestLoadError: function _onManifestLoadError(error) {
			_logger.default.error('Manifest failed to load', error);

			var cachedManifest = Ember.get(this, '_manifest');

			// if there's a manifest cached, go ahead and return it instead of failing
			if (!Ember.isNone(cachedManifest)) {
				_logger.default.warn('Using fallback manifest');
				return cachedManifest;
			} else {
				return Ember.RSVP.Promise.reject(error);
			}
		},


		/**
   * Determine if the there is a locally cached manifest that is not expired
   *
   * @private
   * @method _getIsValidManifest
   * @return {boolean}
   */
		_getIsValidManifest: function _getIsValidManifest() {
			var isCached = !Ember.isNone(Ember.get(this, '_manifest'));
			var isCurrent = !this._getIsManifestExpired();

			return isCached && isCurrent;
		},


		/**
   * Determine if the `_lastUpdate` timestamp is too old for the manifest to be used
   *
   * @private
   * @method _getIsManifestExpired
   * @return {boolean}
   */
		_getIsManifestExpired: function _getIsManifestExpired() {
			var _lastUpdate = Ember.get(this, '_lastUpdate');
			var now = _utils.Time.timestamp();
			var age = now - _lastUpdate;
			var isExpired = age > MAX_AGE;

			return isExpired;
		}
	});
});