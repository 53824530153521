define('busy-app/assert', ['exports', '@busy-web/utils/assert'], function (exports, _assert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _assert.default;
    }
  });
});