define('busy-app/routes/time-card', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		__controller: null,
		targetName: null,

		setupController: function setupController() {
			this._super.apply(this, arguments);

			this.set('__controller', true);
		},


		/**
   * Gets the target routeName from the transition object
   *
   * @private
   * @method getTarget
   * @param transition {object}
   */
		getTarget: function getTarget(transition) {
			if (!Ember.isNone(transition)) {
				var targetName = Ember.get(transition, 'targetName');
				// set the target routes name
				this.set('targetName', targetName);
			}
		},

		/**
   * Observer triggered on transition to change the pageTitle
   *
   * @public
   * @method setTarget
   */
		setTarget: Ember.observer('targetName', '__controller', function () {
			// get the target route name
			var targetName = this.get('targetName');
			if (!Ember.isEmpty(targetName) && this.get('__controller')) {
				// get the route name from the target name
				var routeName = targetName.split('.')[1];
				if (!Ember.isEmpty(routeName)) {
					// format the route name
					var title = this.formatTitle(routeName);

					// set the pageTitle to the formated route name.
					var controller = this.controllerFor('time-card');
					controller.set('pageTitle', title);
				}
			}
		}),

		/**
   * Formats a title for the route name.
   * This takes a dasherized string an adds spaces and capital letters for a title format.
   *
   * @public
   * @method formatTitle
   * @param routeName {string} Dasherized string
   * @return {string}
   */
		formatTitle: function formatTitle(routeName) {
			// split the name on the dashes
			var titleParts = routeName.split('-');

			// loop the parts and clssify the strings
			var title = [];
			titleParts.forEach(function (item) {
				return title.push(item.classify());
			});

			// return the strings joined with spaces
			return title.join(' ');
		},


		actions: {
			loading: function loading(transition) {
				this.getTarget(transition);
				this._super.apply(this, arguments);
				return true;
			}
		}
	});
});