define('busy-app/components/model-title/member', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		state: Ember.inject.service(),

		/**
   * id is the only property that
   * should be passed in. All other
   * properties are set by the component.
   *
   * this is a id for the member model
   *
   * @property id
   * @type {string} model UUID
   */
		id: null,

		/*** props made available in yield hash ***/

		title: '',
		subTitle: '',
		condensedTitle: '',
		hasInfo: false,
		member: null,

		/*** end props ***/

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();

			this.getMember();
			this.setTitle();
		},
		getMember: function getMember() {
			if (!Ember.isEmpty(Ember.get(this, 'id'))) {
				var member = loadMember(Ember.get(this, 'state'), Ember.get(this, 'id'));
				Ember.set(this, 'member', member);
				Ember.set(this, 'hasInfo', true);
			} else {
				Ember.set(this, 'member', null);
				Ember.set(this, 'hasInfo', false);
			}
		},
		setTitle: function setTitle() {
			var member = Ember.get(this, 'member');
			if (!Ember.isNone(member)) {
				var title = Ember.get(member, 'firstName') + ' ' + Ember.get(member, 'lastName');
				var subTitle = Ember.get(member, 'memberNumber');
				var condensedTitle = title + ' <i>' + subTitle + '</i>';

				this.setProperties({ title: title, subTitle: subTitle, condensedTitle: condensedTitle });
			}
		}
	});


	function loadMember(state, id) {
		return state.getState('member', id);
	}
});