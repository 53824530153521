define('busy-app/models/project-cost-budget', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		projectId: _emberData.default.attr('string'),
		costBudget: _emberData.default.attr('number', { defaultValue: 0 }),
		createdOn: _emberData.default.attr('string'),
		updatedOn: _emberData.default.attr('string'),
		deletedOn: _emberData.default.attr('string'),
		submittedOn: _emberData.default.attr('string'),

		project: _emberData.default.belongsTo('project'),

		cost: Ember.computed('costBudget', function () {
			return parseFloat(this.get('costBudget'));
		})
	});
});