define('busy-app/routes/report/payroll', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});