define('busy-app/mixins/controller-filter', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		_qpDelegate: function _qpDelegate() {},

		/**
   * filter list converts into a queryParams list
   * for the route.
   *
   * @property _filterParams
   * @type computed
   */
		_filterParams: null,

		page: 1,
		page_size: Ember.computed(function () {
			return this._pageSize();
		}),

		routePath: Ember.computed(function () {
			return this.toString().replace(/[^:]*:([^:]*)::[\s\S]*/, '$1').replace(/\//, '.');
		}),

		init: function init() {
			// setup filters before calling _super
			this._setupFilters();
			this._super.apply(this, arguments);
		},
		_pageSize: function _pageSize() {
			return _utils.LocalStorage.getWithDefault('member-pagination-size', 30);
		},
		_setupFilters: function _setupFilters() {
			var _this = this;

			var queryParams = Ember.get(this, 'queryParams') || [];
			var filterParams = {};
			queryParams.forEach(function (key) {
				Ember.set(filterParams, key, Ember.get(_this, key));
			});
			Ember.set(this, '_filterParams', filterParams);
		},


		/**
   * Validates different combinations of filter queries.
   *
   * @public
   * @method validateFilters
   * @param {object} options keyed object of queryParams and the values to set
   * @returns {viod}
   */
		validateFilters: function validateFilters() /*options*/{
			_logger.default.warn("validateFilters should be overridden to handle filter combinations");
			return true;
		},
		sendFilter: function sendFilter(options) {
			options = options || {};
			if (this.validateFilters(options)) {
				this.setProperties(options);
				var path = Ember.get(this, 'routePath');
				this.transitionToRoute(path, { queryParams: options });
			}
		},
		sendResetFilters: function sendResetFilters() {
			this.sendFilter(this.resetFilters());
		},
		resetFilters: function resetFilters() {
			var filterParams = Ember.get(this, '_filterParams');
			for (var key in filterParams) {
				if (filterParams.hasOwnProperty(key)) {
					if (key === 'page_size') {
						Ember.set(filterParams, key, this._pageSize());
					}
					Ember.set(this, key, Ember.get(filterParams, key));
				}
			}
			return filterParams;
		}
	});
});