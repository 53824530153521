define('busy-app/utils/models/collections/time-card-report-csv', ['exports', '@busy-web/utils', 'busy-app/utils/models/collections/base', 'busy-app/utils/time-entry-break'], function (exports, _utils, _base, _timeEntryBreak) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		start: null,
		end: null,

		startTime: Ember.computed('model.startTime', 'start', function () {
			if (!Ember.isNone(this.get('model.startTime'))) {
				var start = this.get('model.startTime');
				if (start < this.get('start')) {
					start = this.get('start');
				}
				return start;
			}
		}),

		endTime: Ember.computed('model.endTime', 'end', function () {
			if (!Ember.isNone(this.get('model.endTime'))) {
				var end = this.get('model.endTime');
				if (end > this.get('end')) {
					end = this.get('end');
				}
				return end;
			}
		}),

		startTimeString: Ember.computed('startTime', 'model.offset', function () {
			var date = '';
			if (!Ember.isNone(this.get('startTime'))) {
				date = _utils.Time.date(this.get('startTime') - this.get('model.offset')).local().utcOffset(this.get('model.offset') / 60).format();
			}
			return date;
		}),

		endTimeString: Ember.computed('endTime', 'model.offset', function () {
			var date = '';
			if (!Ember.isNone(this.get('endTime'))) {
				date = _utils.Time.date(this.get('endTime') - this.get('model.offset')).local().utcOffset(this.get('model.offset') / 60).format();
			}
			return date;
		}),

		breakTime: Ember.computed('model.timeEntryBreaks.@each.{startTime,endTime}', 'startTime', 'endTime', function () {
			var time = 0;
			if (!Ember.isEmpty(this.get('model.timeEntryBreaks')) && !Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
				time = _timeEntryBreak.default.totalTime(this.get('model.timeEntryBreaks'), this.get('startTime'), this.get('endTime'));
			}
			return time;
		}),

		breakTimeString: Ember.computed('breakTime', function () {
			return (this.get('breakTime') / 60 / 60).toFixed(2);
		}),

		totalTime: Ember.computed('startTime', 'endTime', 'breakTime', function () {
			return this.get('endTime') - this.get('startTime') - this.get('breakTime');
		}),

		totalTimeString: Ember.computed('totalTime', function () {
			return (this.get('totalTime') / 60 / 60).toFixed(2);
		}),

		totalHours: Ember.computed('model.totalTime', function () {}),

		projectNumber: Ember.computed('projectInfo.number', function () {
			return this.getCsvValue('projectInfo.number');
		}),

		projectNumber2: Ember.computed('projectInfo2.number', function () {
			return this.getCsvValue('projectInfo2.number');
		}),

		projectNumber3: Ember.computed('projectInfo3.number', function () {
			return this.getCsvValue('projectInfo3.number');
		}),

		projectNumber4: Ember.computed('projectInfo4.number', function () {
			return this.getCsvValue('projectInfo4.number');
		}),

		projectNumber5: Ember.computed('projectInfo5.number', function () {
			return this.getCsvValue('projectInfo5.number');
		}),

		projectNumber6: Ember.computed('projectInfo6.number', function () {
			return this.getCsvValue('projectInfo6.number');
		}),

		projectNumber7: Ember.computed('projectInfo7.number', function () {
			return this.getCsvValue('projectInfo7.number');
		}),

		customer: Ember.computed('projectInfo.customer', function () {
			return this.getCsvValue('projectInfo.customer');
		}),

		getCsvValue: function getCsvValue(key) {
			return !Ember.isNone(this.get(key)) ? this.get(key) : '';
		},


		projectInfo: null,

		depth1: null,
		depth2: null,
		depth3: null,
		depth4: null,
		depth5: null,
		depth6: null,
		depth7: null,
		projectInfo1: null,
		projectInfo2: null,
		projectInfo3: null,
		projectInfo4: null,
		projectInfo5: null,
		projectInfo6: null,
		projectInfo7: null
	});
});