define('busy-app/components/text-box', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-text-box'],
		classNameBindings: ['__multiLine:multi-line:single-line'],

		value: '',
		defaultValue: '',

		__value: null,
		__multiLine: false,

		didReceiveAttrs: function didReceiveAttrs() {
			var value = Ember.get(this, 'value');
			if (Ember.isEmpty(value)) {
				value = Ember.get(this, 'defaultValue');
			}

			value = htmlize(value);

			var lines = value.split(/<p><\/p>/);
			if (lines.length > 1) {
				Ember.set(this, '__multiLine', true);
			}

			Ember.set(this, '__value', Ember.String.htmlSafe(value));
		}
	});


	var htmlize = function htmlize(value) {
		if (typeof value !== 'string') {
			return value;
		}

		value = value.replace(/(\r\n)/g, '</p><p>');
		value = value.replace(/\n/g, '</p><p>');
		value = value.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
		value = value.replace(/[ ]{2}/g, '&nbsp;&nbsp;');

		return '<p>' + value + '</p>';
	};
});