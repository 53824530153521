define('busy-app/utils/models/filters/base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    /**
     * Ember service
     *
     * @private
     * @property store
     * @type Services.Store
     */
    store: null
  });
});