define('busy-app/components/report/time-card-approval/chart', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kChartOptions = {
		legend: {
			display: false
		},
		responsive: true
	};

	exports.default = Ember.Component.extend({
		features: Ember.inject.service(),
		signature: Ember.inject.service(),

		isLoading: null,
		model: null,
		memberTimeDocuments: null,
		filterSelected: null,
		modeFilterConfig: null,

		/**
   * aggregates
   *
   */

		membersSigned: Ember.computed.filterBy('memberTimeDocuments', 'hasValidSelfSignature', true),
		membersToResign: Ember.computed.filterBy('memberTimeDocuments', 'needsSelfSignatureResign', true),

		numMembers: Ember.computed.alias('model.length'),
		numSigned: Ember.computed.alias('membersSigned.length'),
		numResign: Ember.computed.alias('membersToResign.length'),
		numUnsigned: Ember.computed('numMembers', 'numSigned', 'numResign', function () {
			return this.getWithDefault('numMembers', 0) - this.getWithDefault('numSigned', 0) - this.getWithDefault('numResign', 0);
		}),

		bothSigned: Ember.computed.filterBy('memberTimeDocuments', 'hasBothValidSignatures'),
		needsBothSignature: Ember.computed.filterBy('memberTimeDocuments', 'needsBothSignature'),
		needsBothResign: Ember.computed.filterBy('memberTimeDocuments', 'needsBothSignatureResign'),

		// needs one, but not both of the signatures
		needsEitherSignature: Ember.computed.filter('memberTimeDocuments', function (item) {
			return item.get('needsEitherSignature') && !item.get('needsBothSignature');
		}),

		// only the employee signature is needed
		needsSelfSignature: Ember.computed.filter('needsEitherSignature', function (doc) {
			return !doc.get('hasValidSelfSignature') && doc.get('hasValidAuthoritativeSignature');
		}),

		// only the supervisor signature is needed
		needsAuthoritativeSignature: Ember.computed.filter('needsEitherSignature', function (doc) {
			return !doc.get('hasValidAuthoritativeSignature') && doc.get('hasValidSelfSignature');
		}),

		numNeedsSelfSignature: Ember.computed.alias('needsSelfSignature.length'),
		numNeedsAuthoritativeSignature: Ember.computed.alias('needsAuthoritativeSignature.length'),
		numNeedsEitherSignature: Ember.computed.alias('needsEitherSignature.length'),
		numBothSigned: Ember.computed.alias('bothSigned.length'),
		numNeedsBothResign: Ember.computed.alias('needsBothResign.length'),
		numNeedsBothSignature: Ember.computed('numMembers', 'numBothSigned', 'numNeedsEitherSignature', 'numNeedsBothResign', function () {
			return this.getWithDefault('numMembers', 0) - this.getWithDefault('numBothSigned', 0) - this.getWithDefault('numNeedsEitherSignature', 0) - this.getWithDefault('numNeedsBothResign', 0);
		}),

		/**
   * chart setup
   *
   */

		// the template needs access to this, so it needs to be on the component object
		chartOptions: kChartOptions,
		modeFilterConfigWithData: Ember.computed('isLoading', 'modeFilterConfig', 'chartData', function () {
			var _this = this;

			var isLoading = this.get('isLoading');

			return this.get('modeFilterConfig').map(function (o, i) {
				var value = isLoading ? 0 : _this.get('chartData')[i];
				var style = Ember.String.htmlSafe('background-color: ' + o.color);

				return Object.assign({}, o, { value: value, style: style });
			});
		}),

		chartLabels: Ember.computed.map('modeFilterConfig', function (o) {
			return o.label;
		}),
		chartColors: Ember.computed.map('modeFilterConfig', function (o) {
			return o.color;
		}),

		chartData: Ember.computed('isLoading', 'signature.isSignatureModeSupervisor', 'chartDataBase', 'chartDataSupervisor', function () {
			if (this.get('isLoading')) {
				return [0, 0, 0, 0, 0];
			}

			return this.get('signature.isSignatureModeSupervisor') ? this.get('chartDataSupervisor') : this.get('chartDataBase');
		}),

		chartDataBase: Ember.computed('numSigned', 'numResign', 'numUnsigned', function () {
			return [this.getWithDefault('numSigned', 0), this.getWithDefault('numResign', 0), this.getWithDefault('numUnsigned', 0)];
		}),

		chartDataSupervisor: Ember.computed('numBothSigned', 'numNeedsAuthoritativeSignature', 'numNeedsSelfSignature', 'numNeedsBothSignature', 'numNeedsBothResign', function () {
			return [this.getWithDefault('numBothSigned', 0), this.getWithDefault('numNeedsAuthoritativeSignature', 0), this.getWithDefault('numNeedsSelfSignature', 0), this.getWithDefault('numNeedsBothResign', 0), this.getWithDefault('numNeedsBothSignature', 0)];
		}),

		chartConfig: Ember.computed('chartData', 'chartLabels', 'chartColors', function () {
			return {
				labels: this.get('chartLabels'),
				datasets: [{
					data: this.get('chartData'),
					backgroundColor: this.get('chartColors')
				}]
			};
		}),

		actions: {
			filterSelected: function filterSelected(itemKey) {
				if (!this.get('isDestroyed')) {
					this.filterSelected(itemKey);
				}
			}
		}
	});
});