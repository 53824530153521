define('busy-app/models/member-sign-in-submission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    memberId: _emberData.default.attr('id'),
    proxyMemberId: _emberData.default.attr('id'),
    localTime: _emberData.default.attr('number'),
    createdOnOffset: _emberData.default.attr('number'),
    createdOnDst: _emberData.default.attr('boolean'),
    signatureId: _emberData.default.attr('id'),
    deviceType: _emberData.default.attr('string'),
    flagged: _emberData.default.attr('boolean'),
    resolvedOn: _emberData.default.attr('number'),
    resolverId: _emberData.default.attr('id'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    archivedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number')
  });
});