define('busy-app/services/container', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var ContainerArray = Ember.Object.extend({
		__map: null,

		setup: Ember.on('init', function () {
			this.set('__map', new window.Map());
		}),

		addModel: function addModel(model) {
			var _this = this;

			_utils.Assert.isObject(model);

			var groups = model.get('__group');
			groups.forEach(function (key) {
				var id = model.get(key);
				var type = key.replace('Id', '');
				if (Ember.isNone(_this.get(type))) {
					_this.set(type, []);
				}

				var container = _this.findContainer(type, id);
				if (Ember.isNone(container)) {
					var typeArray = _this.get(type);
					var idx = typeArray.length;
					container = _this.createContainer(model);
					typeArray.pushObject(container);
					_this.get('__map').set(id, idx);
				}
				container.add(model);
			});
		},
		findContainer: function findContainer(type, key) {
			var idx = this.get('__map').get(key);
			if (!Ember.isNone(idx)) {
				return this.get(type).objectAt(key);
			}
			return null;
		},
		createContainer: function createContainer(model) {
			_utils.Assert.isObject(model);

			var props = model.get('__aggregate');
			_utils.Assert.isArray(props);

			props = props.map(function (key) {
				return 'model.@each.' + key;
			});

			var container = Ember.Object.extend({
				model: null,

				add: function add(model) {
					this.get('model').pushObject(model);
				},


				recalculate: Ember.computed.apply(undefined, _toConsumableArray(props).concat([function () {
					var _this2 = this;

					props.forEach(function (key) {
						_this2.set(key, _this2.get('model').mapBy(key).reduce(function (a, b) {
							return a + b;
						}, 0));
					});
				}]))
			});

			return container.create({ model: [] });
		}
	});

	/**
  * `Service/Container`
  *
  * @class Container
  * @namespace Service
  * @extends Service
  */
	exports.default = Ember.Service.extend({
		build: function build(models) {
			var containerArray = ContainerArray.create();
			models.forEach(function (model) {
				containerArray.addModel(model);
			});

			return containerArray;
		}
	});
});