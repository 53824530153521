define('busy-app/utils/organization/overtime-period', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.getOvertimePeriodByDate = exports.weekStartForPeriod = exports.createOvertimeBoundWeekForPeriod = exports.createOvertimeBoundWeek = undefined;
	var createOvertimeBoundWeek = exports.createOvertimeBoundWeek = function createOvertimeBoundWeek(overtimePeriods, time) {
		var period = getOvertimePeriodByDate(overtimePeriods, time);

		return createOvertimeBoundWeekForPeriod(period, time);
	}; /**
     * @module Utils.Organization
     *
     */
	var createOvertimeBoundWeekForPeriod = exports.createOvertimeBoundWeekForPeriod = function createOvertimeBoundWeekForPeriod(period, time) {
		var overtimeDay = weekStartForPeriod(period);
		var startDay = _utils.Time.date(time).startOf('day').day();
		var diff = overtimeDay - startDay;

		var start = _utils.Time.date(time).add(diff, 'days').startOf('day').unix();
		if (time < start) {
			start = _utils.Time.date(start).subtract(7, 'days').unix();
		}

		var end = _utils.Time.date(start).add(6, 'days').endOf('day').unix();
		return { start: start, end: end };
	};

	var weekStartForPeriod = exports.weekStartForPeriod = function weekStartForPeriod(period) {
		var overtimeDay = 0;
		if (!Ember.isNone(period)) {
			overtimeDay = _utils.Time.date(Ember.get(period, 'startTime')).day();
		}
		return overtimeDay;
	};

	var getOvertimePeriodByDate = exports.getOvertimePeriodByDate = function getOvertimePeriodByDate(overtimePeriods, timestamp) {
		// return default null value if overtimePeriods is undefined
		if (Ember.isNone(overtimePeriods)) return null;

		// convert overtime periods to normal array
		if (overtimePeriods.toArray) {
			overtimePeriods = overtimePeriods.toArray();
		}

		// return overtime period for the timestamp
		if (!Ember.isEmpty(overtimePeriods) && Array.isArray(overtimePeriods)) {
			return overtimePeriods.find(function (p) {
				return p.get('startTime') <= timestamp;
			});
		}

		// return null if overtimePeriods was not an array
		return null;
	};
});