define('busy-app/utils/models/collections/time-entry-pto-day', ['exports', '@busy-web/utils', 'busy-app/utils/models/collections/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		day: null,
		start: null,

		hasOpenTime: Ember.computed('model.@each.isOpenEntry', function () {
			var openEntries = this.get('model').filterBy('isOpenEntry', true);
			return openEntries.get('length') > 0;
		}),

		onlyHasOpenTime: Ember.computed('model.@each.isOpenEntry', function () {
			var openEntries = this.get('model').filterBy('isOpenEntry', true);
			return openEntries.get('length') === this.get('model.length');
		}),

		totalSeconds: Ember.computed('model.@each.{totalTime,dateStamp}', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					if (!item.get('isTimeEntry')) {
						total += item.get('model.seconds') || 0;
					} else {
						total += item.get('totalTime');
					}
				});
			}
			return total;
		}),

		total: Ember.computed('totalSeconds', function () {
			return _utils.Time.convertSecondsString(this.get('totalSeconds'), 10, true);
		}),

		timeOffTypes: Ember.computed('model.@each.timeOffType', function () {
			var type = [];
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					if (!Ember.isEmpty(item.get('timeOffType'))) {
						if (type.indexOf(item.get('timeOffType')) === -1) {
							type.push(item.get('timeOffType'));
						}
					}
				});
			}
			return ((type.length > 0 ? '*' : '') + type.toString()).replace(/,/g, ', ');
		}),

		applyData: function applyData(model, start, day) {
			Ember.set(this, 'day', day);
			Ember.set(this, 'start', start);
			this._super([model]);
		},


		hasConflict: Ember.computed('model.@each.hasConflict', function () {
			var conflict = this.get('model').filterBy('hasConflict', true);
			return conflict.length > 0;
		}),

		totalPTOSeconds: Ember.computed('model.@each.dateStamp', function () {
			var time = 0;
			var startTime = this.get('start');
			var endTime = _utils.Time.date(startTime).endOf('day').unix();
			this.get('model').forEach(function (item) {
				if (!Ember.isNone(item.get('model.dateStamp')) && item.get('model.paid')) {
					var start = item.get('model.startTime');
					var end = item.get('model.endTime');

					// make sure start and end are within the day boundry
					start = start < startTime ? startTime : start;
					end = end > endTime ? endTime : end;

					// if start is greater than end then this is a zero
					// time pto record.
					start = end < start ? end : start;

					// add the total time of the pto enrty
					time += end - start;
				}
			});
			return time;
		}),

		regularHoursWorked: Ember.computed('model.@each.regularSeconds', function () {
			return this.get('model').mapBy('regularSeconds').reduce(function (a, b) {
				return a + b;
			}, 0);
		})
	});
});