define('busy-app/components/subscriptions/edit-payment-method', ['exports', 'busy-app/utils/logger', '@busy-web/utils'], function (exports, _logger, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		stripe: Ember.inject.service(),
		subscription: Ember.inject.service(),
		validation: Ember.inject.service(),

		isLoading: false,

		cardMonthOptions: null,
		cardYearOptions: null,

		billingDetails: null,

		cardHolderName: null,
		cardNumber: null,
		cardMonth: null,
		cardYear: null,
		cardCVC: null,

		// from Stripe
		stripeToken: null,
		stripeCardId: null,
		stripeCardFingerprint: null,

		// these fields are not editible here, but need to be passed along with the cardholder data
		address: null,
		address2: null,
		city: null,
		state: null,
		zip: null,
		country: null,
		// phone: null,

		isCardHolderNameInvalid: false,
		isCardNumberInvalid: false,
		isCardMonthInvalid: false,
		isCardYearInvalid: false,
		isCardCVCInvalid: false,

		cardHolderNameInvalid: (0, _utils.loc)('This is a required field'),
		cardNumberInvalid: (0, _utils.loc)('This is a required field'),
		cardMonthInvalid: (0, _utils.loc)('This is a required field'),
		cardYearInvalid: (0, _utils.loc)('This is a required field'),
		cardCVCInvalid: (0, _utils.loc)('This is a required field'),
		errorMsgMissingBillingAddress: (0, _utils.loc)('You must first provide your billing address before you can update your payment method'),

		hasDataChanged: Ember.computed('cardHolderName', 'cardNumber', 'cardMonth', 'cardYear', 'cardCVC', function () {
			return this.getHasDataChanged();
		}),

		needsBillingAddress: Ember.computed('address', 'city', 'country', 'postalCode', 'state', function () {
			var _EmberGetProperties = Ember.getProperties(this, ['address', 'city', 'country', 'postalCode', 'state']),
			    address = _EmberGetProperties.address,
			    city = _EmberGetProperties.city,
			    country = _EmberGetProperties.country,
			    postalCode = _EmberGetProperties.postalCode,
			    state = _EmberGetProperties.state;

			var needsBillingAddress = Ember.isEmpty(address) || Ember.isEmpty(city) || Ember.isEmpty(country) || Ember.isEmpty(postalCode) || Ember.isEmpty(state);

			return needsBillingAddress;
		}),

		/**
   * COMPONENT INITIALIZATION
   *
   */

		/**
   * @public
   * @method init
   * @constructor
   */
		init: function init() {
			_logger.default.info(this, 'init');

			this._super();

			this.initForm();
			this.initData();
		},


		/**
   * @public
   * @constructor
   * @method initData
   */
		initData: function initData() {
			_logger.default.info(this, 'initData');

			// create a new billingDetails object to store on this component duplicating the provided object
			var billingDetails = Object.assign({}, this.get('billingDetails'));
			_logger.default.info(this, 'initData', billingDetails);

			// ensure all fields are a string for the loc() helper
			// if a value is undefined or null, set it to an empty string instead
			for (var key in billingDetails) {
				if (billingDetails.hasOwnProperty(key) && Ember.isNone(billingDetails[key])) {
					billingDetails[key] = '';
				}
			}

			this.setProperties(billingDetails);
		},


		/**
   * FORM INITIALIZATION
   *
   */

		/**
   * setup form validation and selection options
   *
   * @public
   * @method initForm
   */
		initForm: function initForm() {
			_logger.default.info(this, 'initForm');

			this.initFormValidation();
			this.setCardMonths();
			this.setCardYears();
		},


		/**
   * setup form validation
   *
   * @public
   * @method initFormValidation
   */
		initFormValidation: function initFormValidation() {
			_logger.default.info(this, 'initFormValidation');

			this.set('isCardHolderNameInvalid', false);
			this.set('isCardNumberInvalid', false);
			this.set('isCardMonthInvalid', false);
			this.set('isCardYearInvalid', false);
			this.set('isCardCVCInvalid', false);
		},


		/**
   * setup selection options for credit card expi months
   *
   * @public
   * @method setCardMonths
   */
		setCardMonths: function setCardMonths() {
			var startOfYear = _utils.Time.date().startOf('year');
			var months = [];

			for (var i = 0; i < 12; i++) {
				months.push({
					value: i + 1,
					label: startOfYear.clone().add(i, 'month').format('MM - MMM')
				});
			}

			this.set('monthOptions', months);
		},


		/**
   * setup selection options for credit card expiration years
   *
   * @public
   * @method setCardYears
   */
		setCardYears: function setCardYears() {
			var startYear = _utils.Time.date().year();
			var numYears = 32;
			var years = [];

			for (var i = 0; i < numYears; i++) {
				years.push(startYear + i);
			}

			this.set('yearOptions', years);
		},


		/**
   * FORM PROCESSING
   *
   */

		/**
   * Handle form submission:
   *  - Reset validation errors
   *  - Check if any data has changed
   *  - Cleanup form data
   *  - Validate data
   *  - Send data to API
   *
   * @public
   * @async
   * @method onSaveForm
   * @return {EmberPromise}
   */
		onSaveForm: function onSaveForm() {
			_logger.default.info(this, 'onSaveForm');

			this.initFormValidation();

			if (!this.get('hasDataChanged')) {
				throw (0, _utils.loc)('There are no changes to save!');
			} else {
				this.cleanupPaymentInformation();

				if (Ember.get(this, 'needsBillingAddress')) {
					throw Ember.get(this, 'errorMsgMissingBillingAddress');
				} else if (this.isMissingRequireds()) {
					throw (0, _utils.loc)('Missing required fields...');
				} else if (this.isInvalidData()) {
					throw (0, _utils.loc)('Provided information is not valid...');
				}
			}

			return this.savePaymentMethod();
		},


		/**
   * Send form data to API
   *
   * @public
   * @async
   * @method savePaymentMethod
   * @return {EmberPromise}
   */
		savePaymentMethod: function savePaymentMethod() {
			_logger.default.info(this, 'savePaymentMethod');

			var cardMonth = parseInt(this.get('cardMonth'), 10);
			var cardYear = parseInt(this.get('cardYear'), 10);

			var newPaymentInformation = Ember.Object.create({
				cardNumber: this.get('cardNumber'),
				cardCVC: this.get('cardCVC'),
				cardMonth: cardMonth,
				cardYear: cardYear,

				cardHolderName: this.get('cardHolderName'),
				address: this.get('address'),
				address2: this.get('address2'),
				postalCode: this.get('postalCode'),
				city: this.get('city'),
				state: this.get('state'),
				country: this.get('country')
			});

			return this.get('subscription').savePaymentMethod(newPaymentInformation);
		},


		/**
   * FORM VALIDATION
   *
   */

		/**
   * Check if any data has been modified
   *
   * @public
   * @method getHasDataChanged
   * @return {boolean}
   */
		getHasDataChanged: function getHasDataChanged() {
			var _this = this;

			var billingDetails = this.get('billingDetails');

			if (Ember.isNone(billingDetails) || Ember.isEmpty(billingDetails)) {
				return false;
			}

			// cardNumber has to compare against maskedCardNumber
			var hasCardNumberChanged = this.get('cardNumber') !== billingDetails.get('maskedCardNumber');

			var hasDataChanged = ['cardHolderName', 'cardMonth', 'cardYear', 'cardCVC'].reduce(function (result, key) {
				return result || _this.get(key) !== billingDetails.get(key);
			}, hasCardNumberChanged);

			return hasDataChanged;
		},


		/**
   * Ensure form data is formatted as expected
   *  - Strip non-numeric from Cardnumber and CVC
   *  - Trim white space on all fields
   *
   * @public
   * @method cleanupPaymentInformation
   */
		cleanupPaymentInformation: function cleanupPaymentInformation() {
			var _this2 = this;

			var cardNumber = this.get('cardNumber') || '';
			var cleanCardNumber = cardNumber.replace(/[^\d]/gi, '');
			this.set('cardNumber', cleanCardNumber);

			var cardCVC = this.get('cardCVC') || '';
			var cleanCardCVC = cardCVC.replace(/[^\d]/gi, '');
			this.set('cardCVC', cleanCardCVC);

			['cardHolderName', 'cardNumber', 'cardCVC'].forEach(function (fieldName) {
				_this2.set(fieldName, (_this2.get(fieldName) || '').trim());
			});
		},


		/**
   * Ensure required form data has been provided.
   * Indicate any missing data to the usser.
   *
   * @public
   * @method isMissingRequireds
   * @return {boolean}
   */
		isMissingRequireds: function isMissingRequireds() {
			var _this3 = this;

			var isInvalid = false;

			var requiredFields = ['cardHolderName', 'cardNumber', 'cardMonth', 'cardYear', 'cardCVC'];

			requiredFields.forEach(function (key) {
				var value = _this3.get(key);
				_this3.set(('is-' + key + '-invalid').camelize(), false);

				if (Ember.isEmpty(value)) {
					isInvalid = true;
					_this3.set(('is-' + key + '-invalid').camelize(), true);
				}
			});

			return isInvalid;
		},


		/**
   * Validate payment information
   *  - Valid card number
   *  - Valid card date, not expired
   *  - Valid CVC
   *
   * @public
   * @method isInvalidData
   * @return {boolean}
   */
		isInvalidData: function isInvalidData() {
			var invalidNumber = this.isInvalidCardNumber(this.get('cardNumber'));
			var invalidDate = this.isInvalidCardDate(this.get('cardMonth'), this.get('cardYear'));
			var invalidCVC = this.isInvalidCardCVC(this.get('cardCVC'));

			return invalidNumber || invalidDate || invalidCVC;
		},


		/**
   * Validate Card Number.
   * Indicate to user if value is invalid.
   *
   * @public
   * @method isInvalidCardNumber
   * @return {boolean}
   */
		isInvalidCardNumber: function isInvalidCardNumber(cardNumber) {
			var isInvalid = false;

			if (Ember.isEmpty(cardNumber)) {
				isInvalid = true;
				this.set('cardNumberInvalid', (0, _utils.loc)('Required Field'));
			} else if (this.get('validation').isInvalidCardNumber(cardNumber)) {
				isInvalid = true;
				this.set('cardNumberInvalid', (0, _utils.loc)('Invalid Input'));
			}

			this.set('isCardNumberInvalid', isInvalid);

			return isInvalid;
		},


		/**
   * Validate Card Expiration Date.
   * Indicate to user if value is invalid.
   *
   * @public
   * @method isInvalidCardDate
   * @return {boolean}
   */
		isInvalidCardDate: function isInvalidCardDate(cardMonth, cardYear) {
			var isInvalid = false;

			if (Ember.isEmpty(cardMonth) || Ember.isEmpty(cardYear)) {
				isInvalid = true;

				if (Ember.isEmpty(cardMonth)) {
					this.set('isCardMonthInvalid', true);
					this.set('cardMonthInvalid', (0, _utils.loc)('Required Field'));
				}

				if (Ember.isEmpty(cardYear)) {
					this.set('isCardYearInvalid', true);
					this.set('cardYearInvalid', (0, _utils.loc)('Required Field'));
				}
			} else if (this.get('validation').isInvalidCardDate(cardMonth, cardYear)) {
				isInvalid = true;
				this.set('isCardMonthInvalid', true);
				this.set('cardMonthInvalid', (0, _utils.loc)('Expired Card'));
			} else {
				this.set('isCardMonthInvalid', false);
				this.set('isCardYearInvalid', false);
			}

			return isInvalid;
		},


		/**
   * Validate Card CVC.
   * Indicate to user if value is invalid.
   *
   * @public
   * @method isInvalidCardCVC
   * @return {boolean}
   */
		isInvalidCardCVC: function isInvalidCardCVC(cardCVC) {
			var isInvalid = false;

			if (Ember.isEmpty(cardCVC)) {
				isInvalid = true;
				this.set('cardCVCInvalid', (0, _utils.loc)('Required Field'));
			} else if (this.get('validation').isInvalidCardCVC(cardCVC)) {
				isInvalid = true;
				this.set('cardCVCInvalid', (0, _utils.loc)('Invalid Input'));
			}

			this.set('isCardCVCInvalid', isInvalid);

			return isInvalid;
		},


		/**
   * UTILITY
   *
   */

		/**
   * retrieve value selected from DOM event
   *
   * @public
   * @method getEventValue
   */
		getEventValue: function getEventValue(evt) {
			return evt.target ? evt.target.value : evt.srcElement.value;
		},


		actions: {
			/**
    * @public
    * @event selectExp
    * @param key {string} key of the property to be set
    * @param evt {object} DOM event
    */
			selectExp: function selectExp(key, evt) {
				if (!this.get('isDestroyed')) {
					var val = this.getEventValue(evt);

					this.set(key, val);

					evt.target.blur();
				}
			},


			/**
    * @public
    * @event onClose
    */
			onClose: function onClose() {
				return this.sendAction('onClose');
			},


			/**
    * @public
    * @event saveForm
    */
			saveForm: function saveForm() {
				var _this4 = this;

				_logger.default.info(this, 'saveForm');

				return this.onSaveForm().then(function () {
					return _this4.sendAction('onUpdate');
				});
			}
		}
	});
});