define('busy-app/components/notes/note-cards', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service('store'),
		auth: Ember.inject.service('auth'),
		interop: Ember.inject.service(),
		features: Ember.inject.service(),

		classNames: ['c-note-cards'],

		showUploadDialog: false,
		showNewUploadDialog: false,
		showCommentDialog: false,
		showDeleteDialog: false,
		showDeleteCollectionDialog: false,
		showProjectDialog: false,

		mediaCollections: null,

		commentCollection: null,
		comment: '',

		deleteCollection: null,
		deleteEntry: null,

		project: null,
		projectCollection: null,

		autoload: true,
		showTab: false,

		reactAppUrl: Ember.computed('project.id', function () {
			var projectId = Ember.get(this, 'project.id');
			var urlParams = { projectId: projectId };
			var reactAppUrl = Ember.get(this, 'interop').getReactAppUrl('photos', urlParams);

			return reactAppUrl;
		}),

		reactAppWindowName: Ember.computed('reactAppUrl', function () {
			var reactAppUrl = Ember.get(this, 'reactAppUrl');
			var reactAppWindowName = Ember.get(this, 'interop').getWindowName(reactAppUrl);

			return reactAppWindowName;
		}),

		init: function init() {
			this._super();

			this.get('auth.organization');
			this.get('auth.member');

			// don't autoload photos, open the react app in a new window
			if (Ember.get(this, 'features.photosReact')) {
				return this.send('openPhotosAndNotes');
			}

			if (this.get('autoload')) {
				this.loadWatcher();
			}
		},
		getMedia: function getMedia() {
			var _this = this;

			this._getMedia().then(function (mediaCollections) {
				if (!_this.get('isDestroyed') && !Ember.isNone(mediaCollections)) {
					_this.set('mediaCollections', mediaCollections);
					_this.getMediaEntries(mediaCollections);
				}
			});
		},
		_getMedia: function _getMedia() {
			if (!Ember.isNone(this.get('project.id'))) {
				return this.get('store').filter('project').findWithAllChildren('media-collection', this.get('project.id'), { _desc: ['submitted_on'], deleted_on: null });
			} else if (!Ember.isNone(this.get('auth.organization.id'))) {
				return this.get('store').query('media-collection', { organization_id: this.get('auth.organization.id'), _desc: ['submitted_on'], deleted_on: null });
			} else {
				return Ember.RSVP.resolve(null);
			}
		},


		loadWatcher: Ember.observer('auth.organization.id', 'project.id', 'autoload', function () {
			if (this.get('autoload')) {
				this.getMedia();
			}
		}),

		isArchived: Ember.computed('project.archivedOn', function () {
			if (!Ember.isNone(this.get('project.archivedOn'))) {
				return true;
			}

			return false;
		}),

		getMediaEntries: function getMediaEntries(collections) {
			var _this2 = this;

			collections.forEach(function (collection) {
				var query = {
					media_collection_id: collection.get('id'),
					_desc: ['created_on']
				};

				_this2.get('store').query('media-entry', query).then(function (entries) {
					collection.set('mediaEntries', entries);
				});
			});
		},
		createNewMediaCollection: function createNewMediaCollection(file) {
			var _this3 = this;

			var mediaCollection = this.get('store').createRecord('media-collection', {
				organizationId: this.get('auth.organization.id'),
				createdBy: this.get('auth.member.id'),
				assignedTo: this.get('auth.member.id')
			});

			if (!Ember.isNone(this.get('project.id'))) {
				mediaCollection.set('projectId', this.get('project.id'));
			}

			return mediaCollection.save().then(function (collection) {
				collection.set('mediaEntries', Ember.A());

				_this3.createNewPhoto(collection, file);

				_this3.get('mediaCollections').pushObject(collection._internalModel);

				return collection;
			});
		},
		createNewComment: function createNewComment(collection, comment) {
			var mediaEntry = this.get('store').createRecord('media-entry', {
				mediaCollectionId: collection.get('id'),
				captureDate: _utils.Time.timestamp(),
				createdBy: this.get('auth.member.id'),
				comment: comment,
				entryType: 10
			});

			return mediaEntry.save().then(function (entry) {
				collection.get('mediaEntries').pushObject(entry);

				return entry;
			});
		},
		createNewPhoto: function createNewPhoto(collection, file) {
			var mediaEntry = this.get('store').createRecord('media-entry', {
				mediaCollectionId: collection.get('id'),
				createdBy: this.get('auth.member.id'),
				captureDate: _utils.Time.timestamp(),
				file: file,
				entryType: 20
			});

			return mediaEntry.save().then(function (entry) {
				collection.get('mediaEntries').unshiftObject(entry);

				return entry;
			});
		},
		openReactApp: function openReactApp(path, customParameters, event) {
			return Ember.get(this, 'interop').openReactApp(path, customParameters, event);
		},


		actions: {
			loadData: function loadData() {
				this.loadWatcher();
			},
			openAddDialog: function openAddDialog() {
				this.set('noteCard', this.get('store').createRecord('media-collection', {
					organizationId: this.get('auth.organization.id'),
					createdBy: this.get('auth.member.id'),
					assignedTo: this.get('auth.member.id'),
					projectId: this.get('project.id'),
					mediaEntries: []
				}));

				this.set('showAddDialog', true);
			},
			closeAddDialog: function closeAddDialog() {
				this.set('showAddDialog', false);
				this.set('noteCard', null);
			},
			imageChangeAddDialog: function imageChangeAddDialog(file, fileUrl) {
				var collection = this.get('noteCard');
				var mediaEntry = this.get('store').createRecord('media-entry', {
					createdBy: collection.get('createdBy'),
					captureDate: _utils.Time.timestamp(),
					file: file,
					fileThumbUrl: fileUrl,
					entryType: 20
				});

				collection.get('mediaEntries').pushObject(mediaEntry);
			},
			saveAddDialog: function saveAddDialog() {
				var _this4 = this;

				var collection = this.get('noteCard');

				return collection.save().then(function (mc) {
					var savedEntries = [];

					collection.get('mediaEntries').forEach(function (entry) {
						entry.set('mediaCollectionId', mc.get('id'));
						savedEntries.push(entry.save());
					});

					return Ember.RSVP.all(savedEntries).then(function (entries) {
						mc.set('mediaEntries', entries);
						_this4.get('mediaCollections').update();

						return entries;
					});
				});
			},
			addNoteProject: function addNoteProject(item) {
				this.set('noteCard.projectId', item.get('id'));
			},
			openNote: function openNote(item) {
				var route = Ember.getOwner(this).lookup('route:application');
				route.transitionTo('note.detail', item.get('id'));
			},
			openPhotosAndNotes: function openPhotosAndNotes(event) {
				return Ember.get(this, 'interop').openReactApp('photos', { projectId: Ember.get(this, 'project.id') }, event);
			},


			/** start add comment functions **/
			openCommentDialog: function openCommentDialog(collection) {
				collection.set('isMenuOpen', false);

				this.set('comment', '');
				this.set('commentCollection', collection);

				this.set('showCommentDialog', true);
			},
			saveComment: function saveComment() {
				var _this5 = this;

				return this.createNewComment(this.get('commentCollection'), this.get('comment')).then(function (comment) {
					_this5.set('commentCollection', null);
					_this5.set('comment', '');

					return comment;
				});
			},
			closeCommentDialog: function closeCommentDialog() {
				this.set('comment', '');
				this.set('commentCollection', null);

				this.set('showCommentDialog', false);
			},

			/** end add comment functions **/

			/** start delete collection entry functions **/
			openDeleteDialog: function openDeleteDialog(collection, entry) {
				this.set('deleteCollection', collection);
				this.set('deleteEntry', entry);
				this.set('showDeleteDialog', true);
			},
			deleteEntry: function deleteEntry() {
				var entry = this.get('deleteEntry');
				var collection = this.get('deleteCollection');

				entry.deleteRecord().then(function () {
					collection.get('mediaEntries').removeObject(entry);
				});

				this.set('deleteCollection', null);
				this.set('deleteEntry', null);
				this.set('showDeleteDialog', false);
			},
			closeDeleteDialog: function closeDeleteDialog() {
				this.set('deleteCollection', null);
				this.set('deleteEntry', null);
				this.set('showDeleteDialog', false);
			},


			/** end delete collection entry functions **/

			/** start delete collection functions **/
			openDeleteCollectionDialog: function openDeleteCollectionDialog(collection) {
				collection.set('isMenuOpen', false);

				this.set('deleteCollection', collection);
				this.set('showDeleteCollectionDialog', true);
			},
			deleteCollection: function deleteCollection() {
				var _this6 = this;

				var collection = this.get('deleteCollection');

				collection.destroyRecord().then(function () {
					_this6.get('mediaCollections').removeObject(collection);
				});

				this.set('deleteCollection', null);
				this.set('showDeleteCollectionDialog', false);
			},
			closeDeleteCollectionDialog: function closeDeleteCollectionDialog() {
				this.set('deleteCollection', null);

				this.set('showDeleteCollectionDialog', false);
			},

			/** end delete collection functions **/

			/** start change project functions **/
			openProjectDialog: function openProjectDialog(collection) {
				collection.set('isMenuOpen', false);

				this.set('projectCollection', collection);
				this.set('showProjectDialog', true);
			},
			projectSelected: function projectSelected(item) {
				this.set('projectCollection.projectId', item.get('id'));
			},
			saveProject: function saveProject() {
				var collection = this.get('mediaCollections').findBy('id', this.get('projectCollection.id'));

				if (!Ember.isNone(collection) && collection.get('hasDirtyAttributes')) {
					return collection.save();
				} else {
					return Ember.RSVP.resolve();
				}
			},
			closeProjectDialog: function closeProjectDialog() {
				var collection = this.get('mediaCollections').findBy('id', this.get('projectCollection.id'));
				collection.rollbackAttributes();

				this.set('projectCollection', null);
				this.set('showProjectDialog', false);
			},

			/** end change project functions **/

			/**
    * add image function for adding images
    * with a new collection
    *
    */
			imageChange: function imageChange(file) {
				return this.createNewMediaCollection(file);
			},


			/**
    * opens the collection actions menu
    *
    */
			openOptionsMenu: function openOptionsMenu(collection) {
				collection.set('isMenuOpen', !collection.get('isMenuOpen'));
			}
		}
	});
});