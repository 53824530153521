define("busy-app/utils/time", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.isRangeInRange = isRangeInRange;
	function isRangeInRange(a, b) {
		var startsInRange = b.startTime >= a.startTime && b.startTime <= a.endTime;
		var endsInRange = b.endTime >= a.startTime && b.endTime <= a.endTime;
		var encompassesRange = b.startTime <= a.startTime && b.endTime >= a.endTime;

		return startsInRange || endsInRange || encompassesRange;
	}
});