define('busy-app/adapters/application-quickbooks', ['exports', 'busy-app/adapters/application', 'busy-app/utils/settings'], function (exports, _application, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    isBatchEnabled: Ember.computed(function () {
      return false;
    }),

    host: Ember.computed(function () {
      return _settings.default.get('qb_url');
    })
  });
});