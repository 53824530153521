define('busy-app/models/organization-pay-period', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    organizationId: _emberData.default.attr('string'),
    payPeriodType: _emberData.default.attr('number'),
    startDate: _emberData.default.attr('number'),
    dayOne: _emberData.default.attr('number'),
    dayTwo: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),

    effectiveDate: Ember.computed('startDate', function () {
      var date = '';
      if (!Ember.isNone(this.get('startDate'))) {
        // hides the Jan 7 1970 and shows begining
        if (this.get('startDate') === 345600) {
          date = (0, _utils.loc)("Beginning");
        } else {
          date = _utils.Time.timeFormat(this.get('startDate'), 'ddd. MMM Do, YYYY');
        }
      }
      return date;
    }),

    periodTypeString: Ember.computed('payPeriodType', function () {
      var type = '';
      if (!Ember.isNone(this.get('payPeriodType'))) {
        type = this.get('payPeriodType');
        if (type === 10) {
          type = (0, _utils.loc)('Weekly');
        } else if (type === 20) {
          type = (0, _utils.loc)('Biweekly');
        } else if (type === 30) {
          type = (0, _utils.loc)('Semimonthly');
        } else if (type === 40) {
          type = (0, _utils.loc)('Monthly');
        } else {
          Ember.Error((0, _utils.loc)("Pay period type not found!"));
        }
      }
      return type;
    }),

    startOnString: Ember.computed('payPeriodType', 'dayOne', 'dayTwo', 'startDate', function () {
      var start = '';
      if (!Ember.isNone(this.get('payPeriodType')) && !Ember.isNone(this.get('dayOne'))) {
        var dayOne = this.get('dayOne');
        var type = this.get('payPeriodType');
        if (type === 10) {
          start = (0, _utils.loc)("Every %@", [_utils.Time.dateFormat(_utils.Time.date().weekday(dayOne), 'dddd')]);
        } else if (type === 20) {
          if (!Ember.isNone(this.get('startDate'))) {
            start = (0, _utils.loc)("Every other %@", [_utils.Time.dateFormat(_utils.Time.date(this.get('startDate')).add(dayOne % 14, 'days'), 'dddd')]);
          }
        } else if (type === 30) {
          if (!Ember.isNone(this.get('dayTwo'))) {
            // set month to 4 which is May to get a month with 31 days so when the
            // day is the 31st it will print the 31st.
            var first = _utils.Time.dateFormat(_utils.Time.date().month(4).date(dayOne), 'Do');
            var sec = _utils.Time.dateFormat(_utils.Time.date().month(4).date(this.get('dayTwo')), 'Do');
            start = (0, _utils.loc)("The %@ and %@ of each month", [first, sec]);
          }
        } else if (type === 40) {
          start = (0, _utils.loc)("The %@ of each month", [_utils.Time.dateFormat(_utils.Time.date().date(dayOne), 'Do')]);
        }
      }
      return start;
    })
  });
});