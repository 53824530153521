define('busy-app/components/menu-button', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-menu-button'],
		classNameBindings: ['animateState:animate', 'startState:active', 'finishState:inactive'],

		openState: false,
		startState: false,
		finishState: false,
		animateState: false,

		click: function click(e) {
			e.stopPropagation();
			if (!this.get('animateState') || this.get('animateState') && this.get('startState') && !this.get('finishState')) {
				this.set('openState', !this.get('openState'));
				//this.animate();
				this.notifyToggle();
			}
		},
		animate: function animate() {
			var _this = this;

			if (this.get('startState')) {
				this.set('finishState', true);
				setTimeout(function () {
					_this.set('animateState', false);
					_this.set('startState', false);
					_this.set('finishState', false);
				}, 400);
			} else {
				this.set('animateState', true);
				setTimeout(function () {
					_this.set('startState', true);
				}, 10);
			}
		},
		notifyToggle: function notifyToggle() {
			var body = Ember.$('body');
			if (!body.hasClass('left-nav-open')) {
				body.addClass('left-nav-open');
			} else {
				body.removeClass('left-nav-open');
			}

			//let container = window.BusyApp._application.get('container');
			//let open = !container.get('isMenuOpen');
			//container.set('isMenuOpen', open);
			//this.set('startState', open);
		}
	});
});