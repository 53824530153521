define('busy-app/initializers/nist-time', ['exports', 'moment', 'busy-app/utils/settings'], function (exports, _moment, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/***/
	var kDeviceTime = 10;
	var kServerTime = 30;
	var kUserTime = 40;

	/**
  * `Service/NIST`
  *
  * This service is initialized in the application initializer and
  * should not be imported by any files.
  *
  * For access to the nist time use `window.__NIST`
  *
  */
	var NIST = Ember.Object.extend({
		store: Ember.inject.service('store'),

		_standardOffset: 0,
		_callDelay: 0,
		trustedTime: false,

		init: function init() {
			this._super();

			if (_settings.default.get('nist_mode')) {
				Ember.run.later(this, function () {
					// starts the time sync loop
					this.syncLoop();
				}, 100);
			}
		},
		syncLoop: function syncLoop() {
			// syncTime 5 minutes
			var syncTime = 300000;
			if (!this.get('trustedTime')) {
				syncTime = 30000;
			}

			var _this = this;
			window.setTimeout(function () {
				_this.syncLoop();
			}, syncTime);

			// set the initial standardOffset
			this.setStandardOffset();
		},
		timezone: function timezone() {
			// local time zone offset
			return (0, _moment.default)().utcOffset() * 60;
		},
		currentClientTime: function currentClientTime() {
			// the current time in utc + the local time zone offset in milliseconds
			return _moment.default.utc().add(this.timezone(), 'seconds').valueOf();
		},
		setStandardOffset: function setStandardOffset() {
			var _this2 = this;

			if (!Ember.isNone(this.get('store'))) {
				// get the current local client time
				var t0 = this.currentClientTime();

				// send the rpc call to the server
				this.get('store').rpcRequest('utility', 'server-time').then(function (result) {
					var offset = 0;
					var delay = 0;
					var isTrusted = false;

					// if reuslt is a valid object then calculate the ntpOffset
					if ((typeof result === 'undefined' ? 'undefined' : _typeof(result)) === 'object' && result.data) {
						// get the date according to local time in milliseconds
						var date = (result.data + _this2.timezone()) * 1000;

						// t1 and t2 are set to the same value
						// because the api only returns on value
						var t1 = date;
						var t2 = date;

						// t3 is the current local client time
						var t3 = _this2.currentClientTime();

						// call ntpOffset
						var nistTime = _this2.ntpOffset(t0, t1, t2, t3);

						// set the new offsets
						offset = nistTime.offset;
						delay = nistTime.callDelay;
						isTrusted = true;
					}

					// set the class properties
					_this2.set('standardOffset', offset);
					_this2.set('callDelay', delay);
					_this2.set('trustedTime', isTrusted);
				});
			}
		},
		getStandardOffset: function getStandardOffset() {
			var offset = this.get('standardOffset');

			var isNeg = false;
			if (offset < 0) {
				offset = -offset;
				isNeg = true;
			}

			var rounded = Math.ceil(offset / 1000);
			if (isNeg) {
				rounded = -rounded;
			}

			return rounded;
		},
		getStandardTime: function getStandardTime() {
			return Math.ceil((this.currentClientTime() + this.get('standardOffset')) / 1000);
		},
		isTrustedTime: function isTrustedTime() {
			return this.get('trustedTime');
		},
		ntpOffset: function ntpOffset(t0, t1, t2, t3) {
			return {
				callDelay: t3 - t0 - (t2 - t1),
				offset: (t1 - t0 + (t2 - t3)) / 2
			};
		},
		timestamp: function timestamp() {
			var time = _moment.default.utc().add(this.timezone(), 'seconds');
			if (_settings.default.get('nist_mode')) {
				time = this.getStandardTime();
			}
			return time;
		},
		isTrustedTimeType: function isTrustedTimeType(isUser) {
			isUser = !Ember.isNone(isUser) ? isUser : false;
			if (isUser) {
				return kUserTime;
			} else {
				return this.isTrustedTime() ? kServerTime : kDeviceTime;
			}
		}
	});

	exports.default = {
		name: 'nist-time',
		after: 'application',
		initialize: function initialize(app) {
			window.__NIST = NIST.create(app.__container__.ownerInjection());
		}
	};
});