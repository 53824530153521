define('busy-app/controllers/report/report-setup', ['exports', 'moment'], function (exports, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service(),
		payPeriods: Ember.inject.service('pay-period'),

		start: null,
		end: null,
		memberId: null,
		payPeriodId: null,
		selectPayPeriodMenu: null,
		wageColumn: true,
		regHoursColumn: true,
		overtimeHoursColumn: true,
		totalHoursColumn: true,

		init: function init() {
			this._super.apply(this, arguments);
			this.loadPeriods();
		},


		loadPeriods: Ember.observer('auth.organizationPayPeriod.id', function () {
			if (!Ember.isNone(this.get('auth.organizationPayPeriod.id'))) {
				var time = (0, _moment.default)();
				var periods = this.get('payPeriods').getPayPeriodRange(time.unix(), 30);

				this.set('selectPayPeriodMenu', periods);
				this._selectPayperiod(periods[0]);
			}
		}),

		openReport: function openReport() {
			var params = {
				start: this.get('start'),
				end: this.get('end')
			};

			if (!this.get('wageColumn')) {
				params.wageColumn = this.get('wageColumn');
			}

			if (!this.get('regHoursColumn')) {
				params.regHoursColumn = this.get('regHoursColumn');
			}

			if (!this.get('overtimeHoursColumn')) {
				params.overtimeHoursColumn = this.get('overtimeHoursColumn');
			}

			if (!this.get('totalHoursColumn')) {
				params.totalHoursColumn = this.get('totalHoursColumn');
			}

			this.transitionToRoute('report.signature', {
				queryParams: params
			});
		},
		_selectPayperiod: function _selectPayperiod(period) {
			this.set('start', period.get('startDate').unix());
			this.set('end', period.get('endDate').unix());
		},


		actions: {
			selectPayPeriod: function selectPayPeriod(item) {
				this._selectPayperiod(item);
			},
			runReport: function runReport() {
				this.openReport();
			}
		}
	});
});