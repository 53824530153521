define('busy-app/components/time/time-sheet/header', ['exports', '@busy-web/utils', 'busy-app/utils/organization/overtime-period'], function (exports, _utils, _overtimePeriod) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-sheet--header'],

		router: Ember.inject.service(),
		store: Ember.inject.service(),
		auth: Ember.inject.service(),

		end: null,
		member: null,
		start: null,
		openDialog: null,
		openRangeActivity: null,

		isModeWeekly: Ember.computed.equal('mode', 'weekly'),
		isModePayPeriod: Ember.computed.equal('mode', 'pay-period'),
		isPreviousYear: Ember.computed('end', function () {
			return _utils.Time.date(this.get('end')).year() !== _utils.Time.date().year();
		}),

		actionsSelectMenu: Ember.computed('isModePayPeriod', function () {
			var actionsSelectMenu = [
			// EmberObject.create({key: 'new-time-entry', value: loc('Add Time Entry')}),
			Ember.Object.create({ key: 'new-time-off', value: (0, _utils.loc)('Add Time Off') })];

			if (this.get('isModePayPeriod')) {
				actionsSelectMenu.push(Ember.Object.create({ key: 'full-report', value: (0, _utils.loc)('View Full Report') }));
			}
			return actionsSelectMenu;
		}),

		modesSelectMenu: Ember.computed('isModePayPeriod', 'isModeWeekly', function () {
			var modesSelectMenu = [Ember.Object.create({ key: 'pay-period', value: (0, _utils.loc)('Pay Period'), _selected: this.get('isModePayPeriod') }), Ember.Object.create({ key: 'weekly', value: (0, _utils.loc)('Weekly'), _selected: this.get('isModeWeekly') })];
			return modesSelectMenu;
		}),

		hasPrevBounds: Ember.computed('isLoading', 'isModeWeekly', 'isModePayPeriod', 'payPeriods', 'currentPayPeriodIndex', function () {
			if (this.get('isLoading')) {
				return false;
			}

			if (this.get('isModePayPeriod') && this.get('currentPayPeriodIndex') + 1 >= this.get('payPeriods.length')) {
				return false;
			}
			return true;
		}),

		hasNextBounds: Ember.computed('isLoading', 'isModeWeekly', 'isModePayPeriod', 'start', 'end', function () {
			if (this.get('isLoading')) {
				return false;
			}

			if (this.get('isModePayPeriod') && this.get('currentPayPeriodIndex') === 0) {
				return false;
			}

			if (this.get('isModeWeekly') && this.get('end') >= _utils.Time.date().unix()) {
				return false;
			}
			return true;
		}),

		currentPayPeriodIndex: Ember.computed('mode', 'payPeriods', 'start', 'end', function () {
			var _this = this;

			if (this.get('mode') !== 'pay-period' || Ember.isNone(this.get('payPeriods'))) {
				return null;
			}

			return this.get('payPeriods').toArray().findIndex(function (pp) {
				return pp.get('startTime') === _this.get('start');
			});
		}),

		currentPayPeriod: Ember.computed('payPeriods', 'currentPayPeriodIndex', function () {
			if (Ember.isNone(this.get('currentPayPeriodIndex'))) {
				return null;
			}

			return this.get('payPeriods').objectAt(this.get('currentPayPeriodIndex'));
		}),

		prevDataBounds: Ember.computed('currentPayPeriodIndex', 'mode', 'start', 'end', function () {
			this.getProperties('mode', 'start', 'end', 'currentPayPeriodIndex');

			if (!this.get('hasPrevBounds')) {
				return null;
			}

			return this.getPrevDateBounds();
		}),

		nextDataBounds: Ember.computed('currentPayPeriodIndex', 'mode', 'start', 'end', function () {
			this.getProperties('currentPayPeriodIndex', 'mode', 'start', 'end');

			if (!this.get('hasNextBounds')) {
				return null;
			}

			return this.getNextDateBounds();
		}),

		prevRangeText: Ember.computed('prevDataBounds', 'hasPrevBounds', function () {
			var prevDataBounds = this.get('prevDataBounds');

			if (Ember.isNone(prevDataBounds) || !this.get('hasPrevBounds')) {
				return null;
			}

			var _get = this.get('prevDataBounds'),
			    start = _get.start,
			    end = _get.end;

			return this.dateRangeString(start, end);
		}),

		nextRangeText: Ember.computed('nextDataBounds', 'hasNextBounds', function () {
			var nextDataBounds = this.get('nextDataBounds');

			if (Ember.isNone(nextDataBounds) || !this.get('hasNextBounds')) {
				return null;
			}

			var _get2 = this.get('nextDataBounds'),
			    start = _get2.start,
			    end = _get2.end;

			return this.dateRangeString(start, end);
		}),

		dateRangeString: function dateRangeString(start, end) {
			var startString = _utils.Time.date(start).format('l');
			var endString = _utils.Time.date(end).format('l');

			return startString + ' - ' + endString;
		},
		getPrevDateBounds: function getPrevDateBounds() {
			switch (this.get('mode')) {
				case 'pay-period':
					return this.getPrevPayPeriodBounds();
				case 'weekly':
					return this.getPrevWeekBounds();
				default:
					throw 'Invalid mode: ' + this.get('mode');
			}
		},
		getNextDateBounds: function getNextDateBounds() {
			switch (this.get('mode')) {
				case 'pay-period':
					return this.getNextPayPeriodBounds();
				case 'weekly':
					return this.getNextWeekBounds();
				default:
					throw 'Invalid mode: ' + this.get('mode');
			}
		},
		setDateBoundsToPrev: function setDateBoundsToPrev() {
			var _get3 = this.get('prevDataBounds'),
			    start = _get3.start,
			    end = _get3.end;

			return this.send('setDateBounds', start, end);
		},
		setDateBoundsToNext: function setDateBoundsToNext() {
			var _get4 = this.get('nextDataBounds'),
			    start = _get4.start,
			    end = _get4.end;

			return this.send('setDateBounds', start, end);
		},
		getPrevPayPeriodBounds: function getPrevPayPeriodBounds() {
			var _getProperties = this.getProperties('payPeriods', 'currentPayPeriodIndex'),
			    payPeriods = _getProperties.payPeriods,
			    currentPayPeriodIndex = _getProperties.currentPayPeriodIndex;
			// Logger.info(this, 'getPrevPayPeriodBounds', payPeriods, currentPayPeriodIndex);

			if (Ember.isNone(payPeriods) || Ember.isNone(currentPayPeriodIndex) || currentPayPeriodIndex === -1) {
				return null;
			}

			var prevIndex = this.get('currentPayPeriodIndex') + 1;
			var toPayPeriod = this.get('payPeriods').objectAt(prevIndex);
			var start = toPayPeriod.get('startTime');
			var end = toPayPeriod.get('endTime');

			return { start: start, end: end };
		},
		getNextPayPeriodBounds: function getNextPayPeriodBounds() {
			var _getProperties2 = this.getProperties('payPeriods', 'currentPayPeriodIndex'),
			    payPeriods = _getProperties2.payPeriods,
			    currentPayPeriodIndex = _getProperties2.currentPayPeriodIndex;
			// Logger.info(this, 'getNextPayPeriodBounds', payPeriods, currentPayPeriodIndex);

			if (Ember.isNone(payPeriods) || Ember.isNone(currentPayPeriodIndex) || currentPayPeriodIndex === -1) {
				return null;
			}

			var nextIndex = currentPayPeriodIndex - 1;
			var toPayPeriod = payPeriods.objectAt(nextIndex);
			var start = toPayPeriod.get('startTime');
			var end = toPayPeriod.get('endTime');

			return { start: start, end: end };
		},
		getPrevWeekBounds: function getPrevWeekBounds() {
			return (0, _overtimePeriod.createOvertimeBoundWeek)(this.get('auth.organizationOvertimePeriod'), _utils.Time.date(this.get('start')).subtract(1, 'week').unix());
		},
		getNextWeekBounds: function getNextWeekBounds() {
			if (!this.get('hasNextBounds')) return null;

			return (0, _overtimePeriod.createOvertimeBoundWeek)(this.get('auth.organizationOvertimePeriod'), _utils.Time.date(this.get('start')).add(1, 'week').unix());
		},


		// UTILITIES
		safeSet: function safeSet(key, val) {
			if (!this.get('isDestroyed')) {
				return this.set(key, val);
			}
		},
		safeSetProperties: function safeSetProperties(hash) {
			if (!this.get('isDestroyed')) {
				return this.setProperties(hash);
			}
		},


		actions: {
			// pass up to parent
			openDialog: function openDialog(dialogName) {
				_utils.Assert.isString(dialogName);
				// Logger.info(this, 'openDialog', dialogName);

				return this.openDialog(dialogName);
			},


			// pass up to parent
			setMode: function setMode(mode) {
				_utils.Assert.isString(mode);
				// Logger.info(this, 'setMode', mode);

				return this.setMode(mode);
			},


			// pass up to parent
			openRangeActivity: function openRangeActivity(start, end) {
				_utils.Assert.isInteger(start);
				_utils.Assert.isInteger(end);
				// Logger.info(this, 'openRangeActivity', { start, end });

				return this.openRangeActivity(start, end);
			},
			openFullReport: function openFullReport() {
				var queryParams = {
					end: this.get('end'),
					member_id: this.get('member.id'),
					start: this.get('start')
				};
				// Logger.info(this, 'openFullReport', { queryParams });

				return this.get('router').transitionTo('time-card.full-report', { queryParams: queryParams });
			},
			setDateBounds: function setDateBounds(start, end) {
				// Logger.info(this, 'setDateBounds', { start, end });

				_utils.Assert.isInteger(start);
				_utils.Assert.isInteger(end);

				return this.setDateBounds(start, end);
			},
			onNext: function onNext() {
				// Logger.info(this, 'onNext');
				return this.setDateBoundsToNext();
			},
			onPrev: function onPrev() {
				// Logger.info(this, 'onPrev');
				return this.setDateBoundsToPrev();
			},
			onSelectAction: function onSelectAction(item) {
				if (item.get('key') === 'new-time-entry') {
					return this.send('openDialog', 'NewTimeEntryDialog');
				} else if (item.get('key') === 'new-time-off') {
					return this.send('openDialog', 'NewTimeOffDialog');
				} else if (item.get('key') === 'full-report') {
					return this.send('openFullReport');
				}
			},
			onSelectMode: function onSelectMode(item) {
				// Logger.info(this, 'onSelectMode', item);

				return this.send('setMode', item.key);
			}
		}
	});
});