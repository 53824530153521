define('busy-app/authenticators/basic', ['exports', 'cryptojs', 'busy-app/authenticators/base'], function (exports, _cryptojs, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		dataStore: Ember.inject.service('data-store'),

		serverTokenEndpoint: 'member',
		tokenAttributeName: 'private_key',
		identifierName: 'username',

		normalizeResponse: function normalizeResponse(data) {
			var token = Ember.get(data, 'public_key');
			data = data.data[0];
			return { token: token, id: data.id };
		},
		restore: function restore(data) {
			this.get('dataStore').authenticated(data);
			return this._super.apply(this, arguments);
		},
		authenticate: function authenticate(identifier, password, token) {
			var _this = this;

			if (!Ember.isNone(password)) {
				password = _cryptojs.default.SHA256(password).toString();
			}

			return this._super(identifier, password, token).then(function (data) {
				_this.get('dataStore').authenticated(data);
				return data;
			});
		},
		validateResponse: function validateResponse(data) {
			data = data.data && data.data[0] || {};
			if (!Ember.isNone(data.archived_on)) {
				return false;
			}
			return true;
		},
		invalidMessage: function invalidMessage(data) {
			data = data.data && data.data[0] || {};
			if (!Ember.isNone(data.archived_on)) {
				return { message: "Access Revoked", code: 1002 };
			}
			return this._super(data);
		},
		invalidate: function invalidate() {
			this.get('dataStore').clear();
			return this._super.apply(this, arguments);
		},
		isInvalid: function isInvalid(auth) {
			if (auth.active === false) {
				return "archived";
			}
			return;
		}
	});
});