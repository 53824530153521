define("busy-app/components/subscriptions/upgrade-dialog", ["exports", "@busy-web/utils", "busy-app/utils/geo", "busy-app/utils/logger", "busy-app/utils/string"], function (exports, _utils, _geo, _logger, _string) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/***/
	var kDefaultSubscriptionPreview = {
		subtotal: null,
		tax: null,
		total: null,
		balance: null,
		startDate: _utils.Time.date().add(1, "months").unix(),
		endDate: null,
		nextPaymentDate: _utils.Time.date().add(2, "months").unix()
	};

	/**
  * `Components/Subscriptions/UpgradeDialog`
  *
  * @class UpgradeDialog
  * @namespace Components.Subscriptions
  * @extends Component
  */
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service(),
		stripe: Ember.inject.service(),
		subscription: Ember.inject.service(),
		validation: Ember.inject.service(),
		features: Ember.inject.service(),

		// determine page state
		isLoading: null,
		isSubscriptionPreviewLoading: false,
		isFormProcessing: false,

		activeUserEstimate: null,
		paymentCycle: null, // monthly or annual
		isPaymentAccepted: false,
		invoiceId: null,
		subscriptionPreview: Ember.RSVP.resolve(kDefaultSubscriptionPreview),

		// billing information selection collections
		countryOptions: null,
		stateOptions: null,
		cardMonths: null,
		cardYears: null,

		/*
   * IMPORTANT: Form validation makes assumptions that the associated properties are named according to the following pattern: `name`, `nameInvalid`, `isNameInvalid`
   *
   * Form fields must be accessible as this.get(key), for example, this.get('address').
   * Error messages must be accessible as this.get('{key}Invalid'), for example, this.get('addressInvalid').
   * Error indicators must be accessible as this.get('is{key}Invalid}'), for example, this.get('isAddressInvalid').
   *
   */

		// billing information state
		address: null,
		address2: null,
		country: null,
		city: null,
		state: null,
		postalCode: null,
		phone: null,
		cardHolderName: null,
		cardNumber: null,
		cardMonth: null,
		cardYear: null,
		cardCVC: null,
		agreeToTerms: false,

		// billing information validation
		isFormInvalid: false,
		isAddressInvalid: false,
		isAddress2Invalid: false,
		isCountryInvalid: false,
		isCityInvalid: false,
		isStateInvalid: false,
		isPostalCodeInvalid: false,
		isPhoneInvalid: false,
		isCardHolderNameInvalid: false,
		isCardNumberInvalid: false,
		isCardMonthInvalid: false,
		isCardYearInvalid: false,
		isCardDateInvalid: false,
		isCardCVCInvalid: false,
		isAgreeToTermsInvalid: false,

		// error messages displayed beneath the input field
		addressInvalid: (0, _utils.loc)("Required Field"),
		cityInvalid: (0, _utils.loc)("Required Field"),
		countryInvalid: (0, _utils.loc)("Required Field"),
		stateInvalid: (0, _utils.loc)("Required Field"),
		postalCodeInvalid: (0, _utils.loc)("Required Field"),
		phoneInvalid: (0, _utils.loc)("Required Field"),
		cardHolderNameInvalid: (0, _utils.loc)("Required Field"),
		cardNumberInvalid: (0, _utils.loc)("Required Field"),
		cardMonthInvalid: (0, _utils.loc)("Required Field"),
		cardYearInvalid: (0, _utils.loc)("Required Field"),
		cardCVCInvalid: (0, _utils.loc)("Required Field"),
		agreeToTermsInvalid: (0, _utils.loc)("Required Field"),
		formInvalidDefault: (0, _utils.loc)("There are problems with the provided information. Please double check your entries and try again."),
		formInvalid: (0, _utils.loc)("There are problems with the provided information. Please double check your entries and try again."),

		/**
   * COMPUTEDS
   *
   */

		isCountryUS: Ember.computed.equal("country", "US"),

		needsPaymentCycle: Ember.computed.empty("paymentCycle"),
		hasPaymentCycle: Ember.computed.notEmpty("paymentCycle"),

		isPaymentCycleMonthly: Ember.computed.equal("paymentCycle", "monthly"),
		isPaymentCycleAnnual: Ember.computed.equal("paymentCycle", "annual"),

		isPaymentMethodCredit: Ember.computed.equal("paymentMethod", "credit"),
		isPaymentMethodInvoice: Ember.computed.equal("paymentMethod", "invoice"),

		showCloseButton: Ember.computed("paymentCycle", function () {
			return Ember.isEmpty(this.get("paymentCycle"));
		}),
		showBackButton: Ember.computed("paymentCycle", "isPaymentAccepted", function () {
			return !Ember.isEmpty(this.get("paymentCycle")) && !this.get("isPaymentAccepted");
		}),

		// plan calculation computeds
		costPerActiveUserAnnual: Ember.computed.alias("subscription.costPerActiveUserAnnual"),
		costPerActiveUserMonthly: Ember.computed.alias("subscription.costPerActiveUserMonthly"),

		estimatedCostPerActiveUserAnnual: Ember.computed("subscription.costPerActiveUserAnnualPerYear", "activeUserEstimate", function () {
			return this.get("activeUserEstimate") * this.get("subscription.costPerActiveUserAnnualPerYear");
		}),

		estimatedCostPerActiveUserMonthly: Ember.computed("subscription.costPerActiveUserMonthly", "activeUserEstimate", function () {
			return this.get("activeUserEstimate") * this.get("subscription.costPerActiveUserMonthly");
		}),

		estimatedCostPerActiveUserMonthlyYear: Ember.computed("subscription.costPerActiveUserMonthlyPerYear", "activeUserEstimate", function () {
			return this.get("activeUserEstimate") * this.get("subscription.costPerActiveUserMonthlyPerYear");
		}),

		estimatedCostPerActiveUserAnnualSavings: Ember.computed("estimatedCostPerActiveUserAnnual", "estimatedCostPerActiveUserMonthlyYear", function () {
			return this.get("estimatedCostPerActiveUserMonthlyYear") - this.get("estimatedCostPerActiveUserAnnual");
		}),

		paymentMethod: Ember.computed("isPaymentCycleAnnual", "estimatedCostPerActiveUserAnnual", function () {
			return this.get("isPaymentCycleAnnual") && this.get("estimatedCostPerActiveUserAnnual") > this.get("subscription.annualInvoiceThreshold") ? "invoice" : "credit";
		}),

		subscriptionType: Ember.computed("isPaymentCycleMonthly", "isPaymentCycleAnnual", "isPaymentMethodInvoice", function () {
			if (this.get("isPaymentMethodInvoice")) {
				return "annualInvoice";
			} else if (this.get("isPaymentCycleAnnual")) {
				return "annualCredit";
			} else if (this.get("isPaymentCycleMonthly")) {
				return "monthlyCredit";
			} else {
				return null;
			}
		}),

		isValidUSState: Ember.computed("state", "stateOptions", function () {
			var _this = this;

			return !!this.get("stateOptions").find(function (stateOption) {
				return stateOption.key === _this.get("state");
			});
		}),

		/*
   * WARNING: Magic numbers to calculate payment dates as modified by trial periods
   * 	All trials are assumed to be 1 month long
   * 	All annual/invoice subscriptions are assumed to be 1 year long
   *
   * An annual invoice is normally due 30 days from the creation time
   * 	If the user is in a free trial, the invoice is due 30 days from the end of the trial period
   * Annual subscriptions are charged immediately
   * Monthly subscription is normally assessed 30 days from the creation time
   * 	If the user is in a free trial, the assessment will occur 30 days from the end of the trial period
   *
   */
		firstPaymentDate: Ember.computed("subscriptionPreview", "isPaymentCycleAnnual", "isPaymentMethodInvoice", "subscription.subscriptionStatus", function () {
			if (this.get("isPaymentCycleAnnual")) {
				if (this.get("isPaymentMethodInvoice")) {
					if (this.get("subscription.subscriptionStatus.isFreeTrial")) {
						return _utils.Time.date(this.get("subscription.subscriptionStatus.trialEndedAt")).add(1, "months").unix();
					} else {
						return _utils.Time.date().add(1, "months").unix();
					}
				} else {
					return _utils.Time.date().unix();
				}
			} else {
				if (this.get("subscription.subscriptionStatus.isFreeTrial")) {
					return _utils.Time.date(this.get("subscription.subscriptionStatus.trialEndedAt")).add(1, "months").unix();
				} else {
					return _utils.Time.date().add(1, "months").unix();
				}
			}
		}),

		/*
   * An annual subscription's next payment date is 1 year after the previous payment date
   * A  monthly subscription's next payment date is 1 month after the previous payment date *
   */
		nextPaymentDate: Ember.computed("firstPaymentDate", "isPaymentCycleAnnual", function () {
			if (this.get("isPaymentCycleAnnual")) {
				return _utils.Time.date(this.get("firstPaymentDate")).add(1, "years").unix();
			} else {
				return _utils.Time.date(this.get("firstPaymentDate")).add(1, "months").unix();
			}
		}),

		accountBalance: Ember.computed("subscriptionDetails.balanceInCents", function () {
			var balanceInCents = parseInt(this.get("subscriptionDetails.balanceInCents"), 10) * -1;

			return !Ember.isNone(balanceInCents) ? balanceInCents : 0;
		}),

		isBalanceDue: Ember.computed("accountBalance", function () {
			var accountBalance = this.get("accountBalance");

			return !Ember.isNone(accountBalance) && accountBalance < 0;
		}),

		isCreditAvailable: Ember.computed("accountBalance", function () {
			var accountBalance = this.get("accountBalance");

			return !Ember.isNone(accountBalance) && accountBalance > 0;
		}),

		showAccountBalance: Ember.computed.or("isCreditAvailable", "isPaymentCycleAnnual"),

		accountBalanceFormatted: Ember.computed("accountBalance", function () {
			var accountBalance = this.get("accountBalance");

			return !Ember.isNone(accountBalance) ? (0, _string.centsToDollarFormat)(Math.abs(accountBalance)) : "";
		}),

		/**
   * OBSERVERS
   *
   */

		// initialize activeUserEstimate
		observeMemberMeta: Ember.observer("auth.memberCount", function () {
			if (Ember.isEmpty(this.get("activeUserEstimate")) && !Ember.isEmpty("auth.memberCount") && !this.get("isDestroyed")) {
				this.set("activeUserEstimate", this.get("auth.memberCount"));
			}
		}),

		onFormInputsChange: Ember.observer("activeUserEstimate", "paymentCycle", "country", "state", "city", "postalCode", "address", function () {
			this.getProperties(this.onFormInputsChange.__ember_observes__); // get all the obprops to make sure they're fresh

			Ember.run.debounce(this, this.setSubscriptionPreview, 500);
		}),

		// catch and fix bad `activeUserEstimate` input
		// invalid inputs are reset in a run.next() so that the UI updates to the new value
		onChangeActiveUserEstimate: Ember.observer("activeUserEstimate", function () {
			var _this2 = this;

			var numUsers = parseInt(this.get("activeUserEstimate"), 10);

			if (numUsers <= 0 || !Number.isInteger(numUsers)) {
				Ember.run.next(this, function () {
					if (!_this2.get("isDestroyed")) {
						_this2.set("activeUserEstimate", 1);
					}
				});
			} else if (Number.isInteger(numUsers) && numUsers !== this.get("activeUserEstimate")) {
				Ember.run.next(this, function () {
					if (!_this2.get("isDestroyed")) {
						_this2.set("activeUserEstimate", numUsers);
					}
				});
			}
		}),

		/**
   * COMPONENT INITIALIZATION
   *
   */

		/**
   * @public
   * @method init
   * @constructor
   */
		init: function init() {
			var _this3 = this;

			this._super();
			this.set("cardMonths", []);
			this.set("cardYears", []);

			return this.loadData().then(function (data) {
				return _this3.initForm(data);
			});

			// this.fillTestData();
		},


		/**
   * Get pricing information, and the number of currently active users in the organization
   *
   * @public
   * @method loadData
   * @async
   */
		loadData: function loadData() {
			var _this4 = this;

			this.set("isLoading", true);

			return Ember.RSVP.hash({
				subscriptionPricing: this.get("subscription.subscriptionPricing"),
				memberCount: Ember.RSVP.resolve(this.get("auth").get("memberCount")),
				billingDetails: Ember.RSVP.resolve(this.get("subscription").getBillingDetails()),
				subscriptionDetails: Ember.RSVP.resolve(this.get("subscription").getSubscriptionDetails()),
				organizationInfo: Ember.RSVP.resolve(this.get("auth.organization.organizationInfo"))
			}).then(function (props) {
				if (!_this4.get("isDestroyed")) {
					_this4.set("activeUserEstimate", props.memberCount);
					_this4.set("subscriptionDetails", props.subscriptionDetails);

					return props;
				}

				return null;
			}).catch(function (err) {
				return _logger.default.error(_this4, err);
			}).finally(function () {
				if (!_this4.get("isDestroyed")) {
					_this4.set("isLoading", false);
				}
			});
		},


		// fillTestData() {
		// 	this.setProperties({
		// 		address: '1234 River Parkway',
		// 		address2: null,
		// 		country: 'US',
		// 		city: 'St George',
		// 		state: 'UT',
		// 		postalCode: '84790',
		// 		phone: '4355551234',
		// 		cardHolderName: 'fname lname',
		// 		cardNumber: '4111 1111 1111 1111',
		// 		cardMonth: 12,
		// 		cardYear: 2020,
		// 		cardCVC: '123',
		// 		agreeToTerms: true,
		// 	});
		// },

		/**
   * FORM INITIALIZATION
   *
   */

		/**
   * Setup the form
   *  - reset all properties to blank
   *  - clear any validation errors
   *  - initialize Observers
   *  - initialize select collections
   *
   * @public
   * @method initForm
   */
		initForm: function initForm(initialData) {
			if (!this.get("isDestroyed")) {
				this.setProperties({
					address: null,
					address2: null,
					country: null,
					city: null,
					state: null,
					postalCode: null,
					phone: null,
					cardHolderName: null,
					cardNumber: null,
					cardMonth: null,
					cardYear: null,
					cardCVC: null,
					agreeToTerms: false
				});

				this.initPaymentInformation(initialData);
				this.initFormValidation();

				// manually trigger observers
				this.observeMemberMeta();
				this.onFormInputsChange();

				// initialize select collections
				this.setCardMonths();
				this.setCardYears();
				this.setupCountryOptions();
				this.setupStateOptions();
			}
		},


		/**
   * Initialize/Pre-Populate the form with other known data
   *
   * @public
   * @method initPaymentInformation
   * @param initialData {Object}
   * @param initialData.billingDetails {Object}
   * @param initialData.organizationInfo {Object}
   */
		initPaymentInformation: function initPaymentInformation(initialData) {
			var billingDetails = initialData.billingDetails,
			    organizationInfo = initialData.organizationInfo;

			// simplify logic by ensuring billingDetails and organizationInfo exist and are valid Ember objects

			if (Ember.isNone(billingDetails)) {
				billingDetails = Ember.Object.create({});
			}

			if (Ember.isNone(organizationInfo)) {
				organizationInfo = Ember.Object.create({});
			}

			_logger.default.info(this, "billingDetails", billingDetails);
			_logger.default.info(this, "organizationInfo", organizationInfo);

			this.initFromFirst("address", [billingDetails.get("address"), organizationInfo.get("address1")]);
			this.initFromFirst("address2", [billingDetails.get("address2"), organizationInfo.get("address2")]);
			this.initFromFirst("city", [billingDetails.get("city"), organizationInfo.get("city")]);
			this.initFromFirst("postalCode", [billingDetails.get("postalCode"), organizationInfo.get("postalCode")]);
			this.initFromFirst("state", [billingDetails.get("state"), organizationInfo.get("state")]);
			this.initFromFirst("country", [billingDetails.get("country"), organizationInfo.get("country")]);
			this.initFromFirst("phone", [billingDetails.get("phone"), organizationInfo.get("phone")]);
			this.initFromFirst("cardHolderName", [billingDetails.get("cardHolderName"), (this.get("auth.member.firstName") + " " + this.get("auth.member.lastName")).trim()]);
			this.initFromFirst("maskedCardNumber", [billingDetails.get("maskedCardNumber")]);
			this.initFromFirst("cardMonth", [billingDetails.get("cardMonth")]);
			this.initFromFirst("cardYear", [billingDetails.get("cardYear")]);
		},


		/**
   * If the specified property does not already have a value, set it to the first non-blank element of the provided array
   *
   * @public
   * @method initFromFirst
   * @param property {String}
   * @param sources {String[]}
   */
		initFromFirst: function initFromFirst(property, sources) {
			if (Ember.isBlank(this.get(property))) {
				this.set(property, sources.find(function (val) {
					return !Ember.isBlank(val);
				}));
			}
		},


		/**
   * Get and prepare data collection for the country selection
   *
   * @public
   * @method setupCountryOptions
   */
		setupCountryOptions: function setupCountryOptions() {
			var countries = (0, _geo.getCountries)();
			var countryOptions = countries.map(function (country) {
				return {
					key: country.code,
					label: country.name
				};
			});

			// pull out US and re-insert at top of collection
			var optionUS = countryOptions.findBy("key", "US");
			countryOptions = countryOptions.rejectBy("key", "US");
			countryOptions.unshift(optionUS);

			if (!this.get("isDestroyed")) {
				this.set("countryOptions", countryOptions);
			}
		},


		/**
   * Get and prepare data collection for the state selection
   *
   * @public
   * @method setupStateOptions
   */
		setupStateOptions: function setupStateOptions() {
			var states = (0, _geo.getStates)();
			var stateOptions = states.map(function (state) {
				return {
					key: state.code,
					label: state.name
				};
			});

			if (!this.get("isDestroyed")) {
				this.set("stateOptions", stateOptions);
			}
		},


		/**
   * Get and prepare data collection for the credit card month selection
   *
   * @public
   * @method setCardMonths
   */
		setCardMonths: function setCardMonths() {
			var startOfYear = _utils.Time.date().startOf("year");
			var months = [];

			for (var i = 0; i < 12; i++) {
				months.push({
					value: i + 1,
					label: startOfYear.clone().add(i, "month").format("MM - MMM")
				});
			}

			if (!this.get("isDestroyed")) {
				this.set("cardMonths", months);
			}
		},


		cardMonthOptions: Ember.computed("cardMonths", "cardMonth", function () {
			var cardMonth = parseInt(this.get("cardMonth"), 10);

			return this.get("cardMonths").map(function (month) {
				return Object.assign({}, month, {
					_isSelected: month.value === cardMonth
				});
			});
		}),

		/**
   * Get and prepare data collection for the credit card year selection
   *
   * @public
   * @method setCardYears
   */
		setCardYears: function setCardYears() {
			var startYear = _utils.Time.date().year();
			var numYears = 32;
			var years = [];

			for (var i = 0; i < numYears; i++) {
				years.push(startYear + i);
			}

			if (!this.get("isDestroyed")) {
				this.set("cardYears", years);
			}
		},


		cardYearOptions: Ember.computed("cardYears", "cardYear", function () {
			var cardYear = parseInt(this.get("cardYear"), 10);

			return this.get("cardYears").map(function (year) {
				return {
					value: year,
					_isSelected: year === cardYear
				};
			});
		}),

		/**
   * FORM VALIDATION
   *
   */

		/**
   * clear any validation errors
   *
   * @public
   * @method initFormValidation
   */
		initFormValidation: function initFormValidation() {
			if (!this.get("isDestroyed")) {
				this.setProperties({
					isAddressInvalid: false,
					isAddress2Invalid: false,
					isCountryInvalid: false,
					isCityInvalid: false,
					isStateInvalid: false,
					isPostalCodeInvalid: false,
					isPhoneInvalid: false,
					isCardHolderNameInvalid: false,
					isCardNumberInvalid: false,
					isCardMonthInvalid: false,
					isCardYearInvalid: false,
					isCardDateInvalid: false,
					isCardCVCInvalid: false,
					isAgreeToTermsInvalid: false,
					isFormInvalid: false,
					isFormProcessing: false
				});
			}
		},


		/**
   * ensure provided data is formatted as expected
   *  - all fiends trimmed of whitespace
   *  - strip all non-numeric from CardNumber and CardCVC
   *
   * @public
   * @method cleanupPaymentInformation
   */
		cleanupPaymentInformation: function cleanupPaymentInformation() {
			var _this5 = this;

			if (!this.get("isDestroyed")) {
				["address", "address2", "city", "state", "postalCode", "cardNumber", "cardHolderName"].forEach(function (fieldName) {
					_this5.set(fieldName, (_this5.get(fieldName) || "").trim());
				});

				var cardNumber = this.get("cardNumber") || "";
				var cardNumeric = cardNumber.replace(/[^\d]/gi, "");
				this.set("cardNumber", cardNumeric);

				var cardCVC = this.get("cardCVC") || "";
				var cardCVCNumeric = cardCVC.replace(/[^\d]/gi, "");
				this.set("cardCVC", cardCVCNumeric);
			}
		},


		/**
   * Validate provided form data
   *
   * @public
   * @method validatePaymentInformation
   * @async
   * @return {Boolean}
   */
		validatePaymentInformation: function validatePaymentInformation() {
			var _this6 = this;

			this.initFormValidation();

			return Ember.RSVP.all([this.validateSimpleRequired("address"), this.validateSimpleRequired("city"), this.validateSimpleRequired("country"), this.validateSimpleRequired("state"), this.validateSimpleRequired("postalCode"),
			// this.get('isPaymentMethodInvoice') ? this.validateSimpleRequired('phone') : false, // FIXME: re-enable this when chargify fixes their partial update bug
			this.validateSimpleRequired("cardHolderName"), this.isInvalidCardNumber(this.get("cardNumber")), this.isInvalidCardDate(this.get("cardMonth"), this.get("cardYear")), this.isInvalidCardCVC(this.get("cardCVC")), this.get("isPaymentMethodInvoice") ? this.validateCheckbox("agreeToTerms") : false]).then(function (hasInvalidData) {
				if (hasInvalidData.includes(true)) {
					throw _this6.get("formInvalidDefault");
				}

				return true;
			});
		},


		/**
   * Check if a field does NOT have a provided value
   *
   * @public
   * @method validateSimpleRequired
   * @return {Boolean}
   */
		validateSimpleRequired: function validateSimpleRequired(key) {
			var value = this.get(key);
			var isInvalid = false;

			if (Ember.isEmpty(value)) {
				isInvalid = true;
				this.set((key + "-invalid").camelize(), (0, _utils.loc)("Required Field"));
			}

			this.set(("is-" + key + "-invalid").camelize(), isInvalid);

			return isInvalid;
		},


		/**
   * Check if a checkbox has NOT been checked
   *
   * @public
   * @method validateCheckbox
   * @return {Boolean}
   */
		validateCheckbox: function validateCheckbox(key) {
			var value = this.get(key);
			var isInvalid = false;

			if (Ember.isEmpty(value) || value !== true) {
				isInvalid = true;
				this.set((key + "-invalid").camelize(), (0, _utils.loc)("Required Field"));
			}

			this.set(("is-" + key + "-invalid").camelize(), isInvalid);

			return isInvalid;
		},


		/**
   * Check if the Credit Card Number is NOT valid
   *
   * @public
   * @method isInvalidCardNumber
   * @return {Boolean}
   */
		isInvalidCardNumber: function isInvalidCardNumber(cardNumber) {
			var isInvalid = false;

			if (Ember.isEmpty(cardNumber)) {
				isInvalid = true;
				this.set("cardNumberInvalid", (0, _utils.loc)("Required Field"));
			} else if (this.get("validation").isInvalidCardNumber(cardNumber)) {
				isInvalid = true;
				this.set("cardNumberInvalid", (0, _utils.loc)("Invalid Input"));
			}

			this.set("isCardNumberInvalid", isInvalid);

			return isInvalid;
		},


		/**
   * Check if the Credit Card Expiration Date is NOT valid
   *
   * @public
   * @method isInvalidCardDate
   * @param cardMonth {String}
   * @param cardYear {String}
   * @return {Boolean}
   */
		isInvalidCardDate: function isInvalidCardDate(cardMonth, cardYear) {
			var isInvalid = false;

			if (Ember.isEmpty(cardMonth) || Ember.isEmpty(cardYear)) {
				isInvalid = true;

				if (Ember.isEmpty(cardMonth)) {
					this.set("isCardMonthInvalid", true);
					this.set("cardMonthInvalid", (0, _utils.loc)("Required Field"));
				}

				if (Ember.isEmpty(cardYear)) {
					this.set("isCardYearInvalid", true);
					this.set("cardYearInvalid", (0, _utils.loc)("Required Field"));
				}
			} else if (this.get("validation").isInvalidCardDate(cardMonth, cardYear)) {
				isInvalid = true;
				this.set("isCardMonthInvalid", true);
				this.set("cardMonthInvalid", (0, _utils.loc)("Expired Card"));
			} else {
				this.set("isCardMonthInvalid", false);
				this.set("isCardYearInvalid", false);
			}

			return isInvalid;
		},


		/**
   * Check if the Credit Card CVC is NOT valid
   *
   * @public
   * @method isInvalidCardDate
   * @param cardCVC {String}
   * @return {Boolean}
   */
		isInvalidCardCVC: function isInvalidCardCVC(cardCVC) {
			var isInvalid = false;

			if (Ember.isEmpty(cardCVC)) {
				isInvalid = true;
				this.set("cardCVCInvalid", (0, _utils.loc)("Required Field"));
			} else if (this.get("validation").isInvalidCardCVC(cardCVC)) {
				isInvalid = true;
				this.set("cardCVCInvalid", (0, _utils.loc)("Invalid Input"));
			}

			this.set("isCardCVCInvalid", isInvalid);

			return isInvalid;
		},


		/**
   * SUBSCRIPTION PREVIEW  / TAX AND ORDER TOTALS
   *
   */

		/**
   * @public
   * @method setSubscriptionPreview
   * @async
   * @return {Object}
   */
		setSubscriptionPreview: function setSubscriptionPreview() {
			var _this7 = this;

			if (!this.get("isDestroyed")) {
				this.set("subscriptionPreview", kDefaultSubscriptionPreview);
				this.set("isSubscriptionPreviewLoading", true);
			}

			return this.getSubscriptionPreview().then(function (subscriptionPreview) {
				if (!_this7.get("isDestroyed")) {
					_this7.set("subscriptionPreview", subscriptionPreview);
					_this7.set("isSubscriptionPreviewLoading", false);

					return subscriptionPreview;
				}
			});
		},


		/**
   * Get the taxes and order totals from the payment processor
   *
   * @public
   * @method getSubscriptionPreview
   * @async
   * @returns {Promise|Object}
   */
		getSubscriptionPreview: function getSubscriptionPreview() {
			var _this8 = this;

			var requiredFields = ["activeUserEstimate", "paymentCycle", "country", "state", "city", "postalCode", "address"];
			var isMissingValue = requiredFields.any(function (key) {
				return Ember.isEmpty(_this8.get(key));
			});

			// Logger.info(this, 'getSubscriptionPreview', {isMissingValue}, this.getProperties(requiredFields));

			if (isMissingValue) {
				return Ember.RSVP.resolve(kDefaultSubscriptionPreview);
			}

			var quantity = parseInt(this.get("activeUserEstimate"), 10);

			var previewLocation = Ember.Object.create({
				allocatedQuantity: quantity,
				subscriptionType: this.get("paymentCycle") === "annual" ? "annually" : "monthly",
				billingCountry: this.get("country"),
				billingState: this.get("state"),
				billingCity: this.get("city"),
				billingZip: this.get("postalCode"),
				billingAddress: this.get("address")
			});

			return this.get("subscription").getSubscriptionPreview(previewLocation);
		},


		/**
   * DATA SUBMISSION
   *
   */

		/**
   * Process the steps required to start a subscription
   *  - Cleanup data formattting
   *  - Perform data validation
   *  - Save the payment information
   *  - Start the subscription
   *
   * @public
   * @method submitForm
   * @async
   * @return {Promise|Boolean}
   */
		submitForm: function submitForm() {
			var _this9 = this;

			this.set("isLoading", true);
			this.cleanupPaymentInformation();

			return this.validatePaymentInformation().then(function () {
				return _this9.savePaymentInformation();
			}).then(function () {
				return _this9.updateSubscription();
			}).then(function () {
				if (!_this9.get("isDestroyed")) {
					/**
      * A sucessful submission does not close this modal.
      * Setting isPaymentAccepted=true causes this modal to display the `order-confirmation` component
      */
					_this9.set("isPaymentAccepted", true);
				}

				return true;
			}).catch(function (err) {
				_logger.default.error(_this9, "submitForm", err);

				var errMsg = typeof err === "string" ? err : (0, _utils.loc)("Something went wrong, please try again later.");

				throw errMsg;
			}).finally(function () {
				_this9.set("isLoading", false);
			});
		},


		/**
   * @public
   * @async
   * @method savePaymentInformation
   * @return {Promise}
   */
		savePaymentInformation: function savePaymentInformation() {
			var cardMonth = parseInt(this.get("cardMonth"), 10);
			var cardYear = parseInt(this.get("cardYear"), 10);

			var paymentInformation = Ember.Object.create({
				cardHolderName: this.get("cardHolderName"),
				address: this.get("address"),
				address2: this.get("address2"),
				postalCode: this.get("postalCode"),
				city: this.get("city"),
				state: this.get("state"),
				country: this.get("country"),

				cardNumber: this.get("cardNumber"),
				cardCVC: this.get("cardCVC"),
				cardMonth: cardMonth,
				cardYear: cardYear
			});

			return this.get("subscription").savePaymentMethod(paymentInformation);
		},


		/**
   * Determine which type of subscription is being requested and hand off the request to the appropriate handler.
   *
   * @public
   * @method updateSubscription
   * @async
   * @return {Promise}
   */
		updateSubscription: function updateSubscription() {
			_logger.default.info(this, "updateSubscription");

			if (this.get("isPaymentCycleAnnual")) {
				if (this.get("isPaymentMethodInvoice")) {
					return this.updateSubscriptionToAnnualInvoice();
				} else {
					return this.updateSubscriptionToAnnual();
				}
			} else {
				return this.updateSubscriptionToMonthly();
			}
		},


		/**
   * Update the subscription to a monthly plan accounting for the state the plan is already in
   *
   * @public
   * @method updateSubscriptionToMonthly
   * @async
   * @return {Promise}
   */
		updateSubscriptionToMonthly: function updateSubscriptionToMonthly() {
			_logger.default.info(this, "updateSubscriptionToMonthly");

			// no change if going from trial to monthly or monthly to monthly
			if (this.get("subscription.subscriptionStatus.isMonthlyPlan") && this.get("subscription.subscriptionStatus.isProAccount")) {
				return this.noOpPromise();
			}
			// from annual or annualInvoice
			else if (this.get("subscription.subscriptionStatus.isAnnualPlan")) {
					return this.updateSubscriptionToMonthlyFromAnnual();
				}
				// default state (free) to monthly
				else {
						return this.updateSubscriptionToMonthlyFromFree();
					}
		},


		/**
   * @public
   * @method updateSubscriptionToMonthlyFromFree
   * @async
   * @return {Promise}
   */
		updateSubscriptionToMonthlyFromFree: function updateSubscriptionToMonthlyFromFree() {
			_logger.default.info(this, "updateSubscriptionToMonthlyFromFree");

			var includeTrialPeriod = false;

			return this.get("subscription").setSubscriptionStatusPro(includeTrialPeriod);
		},


		/**
   * @public
   * @method updateSubscriptionToMonthlyFromAnnual
   * @async
   * @return {Promise}
   */
		updateSubscriptionToMonthlyFromAnnual: function updateSubscriptionToMonthlyFromAnnual() {
			_logger.default.info(this, "subscriptionToMonthlyFromAnnual");

			return this.get("subscription").cancelAnnualSubscription();
		},


		/**
   * If the organization is not already Pro, upgrade it to pro, then attempt to charge the card for the requested number of users.
   *
   * @public
   * @method updateSubscriptionToAnnual
   * @async
   * @return {Promise}
   */
		updateSubscriptionToAnnual: function updateSubscriptionToAnnual() {
			_logger.default.info(this, "updateSubscriptionToAnnual");

			var activeUserEstimate = parseInt(this.get("activeUserEstimate"), 10);

			// if the account is already pro, charge the account for the requested users
			if (this.get("subscription.subscriptionStatus.isProAccount")) {
				return this.chargeAnnualSubscription(activeUserEstimate);
			}
			// from default (free) to annual, upgrade the account to pro, then add the credits
			else {
					return this.updateSubscriptionToAnnualFromFree(activeUserEstimate);
				}
		},


		/**
   * @public
   * @method updateSubscriptionToAnnualFromFree
   * @async
   * @return {Promise}
   */
		updateSubscriptionToAnnualFromFree: function updateSubscriptionToAnnualFromFree(activeUserEstimate) {
			var _this10 = this;

			var includeTrialPeriod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			_logger.default.info(this, "updateSubscriptionToAnnualFromFree", {
				activeUserEstimate: activeUserEstimate,
				includeTrialPeriod: includeTrialPeriod
			});

			return this.get("subscription").setSubscriptionStatusPro(includeTrialPeriod).then(function () {
				return _this10.chargeAnnualSubscription(activeUserEstimate);
			});
		},


		/**
   * Upgrade the organization to pro, then create an invoice for the balance due for the requested number of users.
   * The created `invoiceId` is saved, so it can be passed to the `order-confirmation` component.
   *
   * @public
   * @method updateSubscriptionToAnnualInvoice
   * @async
   * @return {Promise}
   */
		updateSubscriptionToAnnualInvoice: function updateSubscriptionToAnnualInvoice() {
			_logger.default.info(this, "updateSubscriptionToAnnualInvoice");

			var activeUserEstimate = parseInt(this.get("activeUserEstimate"), 10);

			// if the account is already pro, create an invoice for the requested additional users
			if (this.get("subscription.subscriptionStatus.isProAccount")) {
				return this.invoiceAnnualSubscription(activeUserEstimate);
			}
			// from default (free) to annual, upgrade the account to pro, then add the credits
			else {
					return this.updateSubscriptionToAnnualInvoiceFromFree(activeUserEstimate);
				}
		},


		/**
   * @public
   * @method updateSubscriptionToAnnualInvoiceFromFree
   * @async
   * @param activeUserEstimate {Int}
   * @param [includeTrialPeriod=false] {Boolean}
   * @return {Promise}
   */
		updateSubscriptionToAnnualInvoiceFromFree: function updateSubscriptionToAnnualInvoiceFromFree(activeUserEstimate) {
			var _this11 = this;

			var includeTrialPeriod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			_logger.default.info(this, "updateSubscriptionToAnnualFromFree", {
				activeUserEstimate: activeUserEstimate,
				includeTrialPeriod: includeTrialPeriod
			});

			return this.get("subscription").setSubscriptionStatusPro(includeTrialPeriod).then(function () {
				return _this11.invoiceAnnualSubscription(activeUserEstimate);
			});
		},


		/**
   * charge the account to produce additional annual credits
   * side effects are the `annualSubscriptionDate` and `annualSubscriptionCount` properties on OrganizationSubscriptionStatus will change
   *
   * @public
   * @method updateSubscriptionToAnnualInvoiceFromFree
   * @async
   * @param userCount {Int}
   * @return {Promise}
   */
		chargeAnnualSubscription: function chargeAnnualSubscription(userCount) {
			_logger.default.info(this, "chargeAnnualSubscription", { userCount: userCount });

			return this.get("subscription").chargeAnnualSubscription(userCount);
		},


		/**
   * invoice the account to produce additional annual credits
   * side effects are the `annualSubscriptionDate` and `annualSubscriptionCount` properties on OrganizationSubscriptionStatus will change
   *
   * @public
   * @method updateSubscriptionToAnnualInvoiceFromFree
   * @async
   * @return {Promise}
   */
		invoiceAnnualSubscription: function invoiceAnnualSubscription(userCount) {
			var _this12 = this;

			_logger.default.info(this, "invoiceAnnualSubscription", { userCount: userCount });

			return this.get("subscription").invoiceAnnualSubscription(userCount).then(function (invoiceId) {
				if (!_this12.get("isDestroyed")) {
					_this12.set("invoiceId", invoiceId);
				}

				return invoiceId;
			});
		},


		/**
   * Downgrade the account from pro to free.
   * Works for trial, monthly, and annual plans.
   *
   * @public
   * @async
   * @method downgradeToFree
   * @return {Promise}
   */
		downgradeToFree: function downgradeToFree() {
			var _this13 = this;

			_logger.default.info(this, "downgradeToFree");

			this.set("isFormProcessing", true);
			return this.get("subscription").setSubscriptionStatusFree().catch(function (err) {
				return _this13.handleApiError("downgradeToFree", err);
			}).finally(function () {
				return _this13.safeSet("isFormProcessing", false);
			});
		},


		/**
   * UTILITY
   *
   */

		/**
   * retrieve value selected from DOM event
   *
   * @public
   * @param evt {Object}
   * @method getEventValue
   */
		getEventValue: function getEventValue(evt) {
			return evt.target ? evt.target.value : evt.srcElement.value;
		},


		/**
   * Only attempt to set to `this` if it has not been destroyed
   *
   * @public
   * @chainable
   * @param key {String}
   * @param value {any}
   * @method safeSet
   */
		safeSet: function safeSet(key, value) {
			if (!this.get("isDestroyed")) {
				this.set(key, value);
			}
			return this;
		},


		/**
   * Utilty to return a promise that does nothing
   *
   * @public
   * @method noOpPromise
   */
		noOpPromise: function noOpPromise() {
			_logger.default.info(this, "noOpPromise");

			return Ember.RSVP.resolve();
		},


		/**
   * If the API returns an error code, try to turn it into a message useful to the end user.
   *
   * @public
   * @method handleApiError
   * @param type {Object}
   * @param err {object}
   * @return {Promise}
   */

		handleApiError: function handleApiError(type, err) {
			_logger.default.error(this, type, typeof err === "undefined" ? "undefined" : _typeof(err), err);

			if ((typeof err === "undefined" ? "undefined" : _typeof(err)) === "object") {
				err = (0, _utils.loc)("Something went wrong, please try again later.");
			}

			this.safeSet("errorMessage", err);

			// NOTE Ember RSVP reject will throw an error for
			// unhandled catch errors if the goal is to throw
			// the error then return is here and let it propagate
			// to all the handlers first then ember will throw it.
			return Ember.RSVP.reject(err);
		},


		actions: {
			/**
    * @public
    * @event onClose
    */
			onClose: function onClose() {
				_logger.default.info(this, "onClose");

				if (!this.get("isDestroyed")) {
					this.sendAction("onClose");
				}
			},


			/**
    * There are 3 "pages"
    *  - Hitting back on the first page where plan type is selected closes the modal
    *  - Hitting back on the payment form returns to the plan page
    *  - The order confirmation page does not allow a "back" action
    *
    * @public
    * @event onBack
    */
			onBack: function onBack() {
				if (!this.get("isDestroyed")) {
					if (Ember.isEmpty(this.get("paymentCycle"))) {
						this.send("onClose");
					} else {
						this.send("selectPaymentCycle", null);
					}
				}
			},


			/**
    * @public
    * @event selectPaymentCycle
    * @param paymentCycle {string} 'annual' or 'monthly'
    */
			selectPaymentCycle: function selectPaymentCycle(paymentCycle) {
				// console.log('selectPaymentCycle');
				if (!this.get("isDestroyed")) {
					this.initFormValidation();
					this.set("paymentCycle", paymentCycle);
				}
			},


			/**
    * @public
    * @event onSelectCountry
    * @param evt {object} DOM event
    */
			onSelectCountry: function onSelectCountry(evt) {
				if (!this.get("isDestroyed")) {
					var val = this.getEventValue(evt);

					this.set("country", val);
					this.set("state", null);
					this.set("postalCode", null);

					evt.target.blur();
				}
			},


			/**
    * @public
    * @event onSelectState
    * @param evt {object} DOM event
    */
			onSelectState: function onSelectState(evt) {
				if (!this.get("isDestroyed")) {
					var val = this.getEventValue(evt);

					this.set("state", val);

					evt.target.blur();
				}
			},


			/**
    * @public
    * @event onSelectState
    * @param key {string} key of the property to be set (cardYear or cardMonth)
    * @param evt {object} DOM event
    */
			selectExp: function selectExp(key, evt) {
				if (!this.get("isDestroyed")) {
					var val = this.getEventValue(evt);

					this.set(key, val);

					evt.target.blur();
				}
			},


			/**
    * @public
    * @event onAgreeToTerms
    * @param isChecked {boolean} new state for the agree to terms checkbox
    */
			onAgreeToTerms: function onAgreeToTerms(isChecked) {
				// console.log('onAgreeToTerms', isChecked);
				if (!this.get("isDestroyed")) {
					this.set("agreeToTerms", isChecked);
				}
			},


			/**
    * @public
    * @event onSubmitPaymentInformation
    */
			onSubmitPaymentInformation: function onSubmitPaymentInformation() {
				var _this14 = this;

				this.set("isFormInvalid", false);
				this.set("isFormProcessing", true);

				return this.submitForm().catch(function (err) {
					if (!_this14.get("isDestroyed")) {
						_this14.set("formInvalid", err);
						_this14.set("isFormInvalid", true);
					}
				}).finally(function () {
					if (!_this14.get("isDestroyed")) {
						_this14.set("isFormProcessing", false);
					}
				});
			},


			/**
    * @public
    * @event downgradeToFree
    */
			downgradeToFree: function downgradeToFree() {
				var _this15 = this;

				this.set("isLoading", true);
				return this.downgradeToFree().then(function () {
					_this15.safeSet("isLoading", false);
					_this15.sendAction("onClose");
				});
			}
		}
	});
});