define('busy-app/components/projects/cost-codes-manager/cost-code', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		costCode: null,
		createCostCodeLink: null,
		deleteCostCodeLink: null,

		toggleCostCodeSelection: function toggleCostCodeSelection(costCode) {
			// Logger.info(this, 'toggleCostCodeSelection', get(costCode, 'costCode'), !get(costCode, '_selected'));

			var includeCostCode = !Ember.get(costCode, '_selected');
			var now = _utils.Time.timestamp();

			var costCodeAction = includeCostCode ? Ember.get(this, 'createCostCodeLink') : Ember.get(this, 'deleteCostCodeLink');

			return costCodeAction(costCode, now);
		},


		actions: {
			toggleCostCodeSelection: function toggleCostCodeSelection(costCode) {
				// Logger.info(this, 'toggleCostCodeSelection', get(costCode, 'id'), get(costCode, '_selected'));

				this.toggleCostCodeSelection(costCode);
			}
		}
	});
});