define('busy-app/utils/clock/string', ['exports', '@busy-web/ember-date-time/utils/clock/string'], function (exports, _string) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _string.default;
    }
  });
});