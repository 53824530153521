define('busy-app/utils/models/managers/base', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/**
  * `Util/Model/Manager/Base`
  *
  * @class Base
  * @namespace Utils.Models.Managers
  * @extends ArrayProxy
  */
	var Manager = Ember.ArrayProxy.extend(Ember.Evented, {
		_store: Ember.inject.service('store'),
		store: null,
		content: null,
		model: Ember.computed.alias('content'),
		collectionType: null,
		_models: null,

		init: function init() {
			this._super();
			this.store = this.get('_store');
		},
		getSorted: function getSorted() {
			_logger.default.warn('The default sortBy was used. This function should be overridden in the manager to set the sort');
			return this.get('model');
		},
		objectAtContent: function objectAtContent(index) {
			var content = Ember.get(this, 'content');
			return content.objectAt(index);
		},
		fetch: function fetch() {
			return new Ember.RSVP.resolve(Ember.A());
		},
		_fetch: function _fetch() {
			var _this = this;

			return this.fetch.apply(this, arguments).then(function (models) {
				return _this.build(models);
			});
		},
		build: function build(models) {
			try {
				this.set('_models', models);
				this.buildCollections(models);
				return this;
			} catch (e) {
				_logger.default.error(e);
				return this;
			}
		},
		addCollection: function addCollection() {
			var collection = this.collectionFor(this.get('collectionType'));
			if (!Ember.isNone(collection)) {
				this.addInternalModel(collection.push.apply(collection, arguments));
			} else {
				this.addInternalModel.apply(this, arguments);
			}
		},
		buildCollections: function buildCollections(models) {
			var _this2 = this;

			models.forEach(function (model) {
				_this2.addCollection(model);
			});
			return this;
		},
		addInternalModel: function addInternalModel(model, idx) {
			if (idx !== undefined) {
				this.get('content').removeAt(idx, 1);
				this.get('content').insertAt(idx, model);
			} else {
				this.get('content').pushObject(model);
			}
			return this;
		},
		removeInternalModel: function removeInternalModel(model) {
			this.get('content').removeObject(model);
			return this;
		},
		collectionFor: function collectionFor(type) {
			type = typeof type === 'string' ? type : this.constructor.typeName;

			var owner = Ember.getOwner(this);
			var collection = owner.factoryFor('util:models.collections.' + type).create(owner.ownerInjection());

			_utils.Assert.test('No Collection was found for ' + type.classify(), !Ember.isNone(collection));

			return collection;
		},
		dispatcherFor: function dispatcherFor(type) {
			_utils.Assert.test('a type must be passed to Manager.dispatcherFor()', typeof type === 'string' && !Ember.isEmpty(type));

			return this.store.dispatcher(type);
		}
	});

	Manager.reopenClass({
		_create: Manager.create,

		typeName: '',

		create: function create() {
			_utils.Assert.throw('create() cannot be called on a Dispatcher. Please use fetch()');
		},
		fetch: function fetch(store) {
			_utils.Assert.test('A store instance must be passed to fetch', (typeof store === 'undefined' ? 'undefined' : _typeof(store)) === 'object' && !Ember.isNone(store.query));

			var args = Array.prototype.slice.call(arguments, 1);
			var owner = Ember.getOwner(store);
			var manager = this._create(owner.ownerInjection(), { content: Ember.A() });
			return manager._fetch.apply(manager, args);
		}
	});

	exports.default = Manager;
});