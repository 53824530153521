define('busy-app/utils/project-csv-import', ['exports', 'busy-app/utils/csv-import', '@busy-web/utils'], function (exports, _csvImport, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _csvImport.default.extend({
		activeProjectList: null,

		rootProjects: Ember.computed('model.@each.isTopLevelProject', function () {
			var projects = [];
			if (!Ember.isNone(this.get('model'))) {
				projects = this.get('model').filterBy('isTopLevelProject', true);
			}
			return projects;
		}),

		loadSubProjects: function loadSubProjects() {
			var projects = this.get('model');
			projects.forEach(function (item) {
				var subProjects = projects.filterBy('parentProjectId', item.get('id'));
				item.set('subProjects', subProjects);
			});
		},

		defaultMap: Ember.computed(function () {
			return {
				"projectInfo.number": "Project Number",
				"projectInfo.customer": "Customer",
				"projectInfo.address1": "Address 1",
				"projectInfo.address2": "Address 2",
				"projectInfo.city": "City",
				"projectInfo.state": "State",
				"projectInfo.postalCode": "Postal Code",
				"project1": "Project Name",
				"project2": "Project Name Lvl 2",
				"project3": "Project Name Lvl 3",
				"project4": "Project Name Lvl 4",
				"project5": "Project Name Lvl 5",
				"project6": "Project Name Lvl 6",
				"project7": "Project Name Lvl 7"
			};
		}),

		run: function run(file) {
			this.set('activeProjectList', []);
			this._super(file);
		},

		onFinishFileParse: function onFinishFileParse(headers, data) {
			this._super(headers, data);
			this.setHeaderMap({});
			this.parse();
		},

		project1Transform: function project1Transform(value, model) {
			if (!Ember.isNone(value)) {
				model.set('title', value);
				model.set('depth', 1);

				var oldModel = this.hasModel(model);
				if (!oldModel) {
					this.setActiveList(0, model);
				} else {
					this.setActiveList(0, oldModel);
				}
			}
			return true;
		},

		project2Transform: function project2Transform(value, model) {
			this.createNewSubModel(1, value, model);
			return true;
		},

		project3Transform: function project3Transform(value, model) {
			this.createNewSubModel(2, value, model);
			return true;
		},

		project4Transform: function project4Transform(value, model) {
			this.createNewSubModel(3, value, model);
			return true;
		},

		project5Transform: function project5Transform(value, model) {
			this.createNewSubModel(4, value, model);
			return true;
		},

		project6Transform: function project6Transform(value, model) {
			this.createNewSubModel(5, value, model);
			return true;
		},

		project7Transform: function project7Transform(value, model) {
			this.createNewSubModel(6, value, model);
			return true;
		},

		setActiveList: function setActiveList(index, model) {
			this.get('activeProjectList').splice(index, 1, model);
			return this;
		},

		getActiveList: function getActiveList(index) {
			return this.get('activeProjectList').objectAt(index);
		},

		createNewModel: function createNewModel(hasProjectInfo) {
			hasProjectInfo = !Ember.isNone(hasProjectInfo) ? hasProjectInfo : true;

			var id = _utils.UUID.generate();
			var project = this.get('store').createRecord('project', {
				id: id,
				rootProjectId: id,
				organizationId: this.get('organizationId'),
				subProjects: []
			});

			if (hasProjectInfo) {
				project.set('projectInfo', this.get('store').createRecord('project-info', {
					id: _utils.UUID.generate(),
					projectId: id
				}));
			}
			return project;
		},

		createNewSubModel: function createNewSubModel(index, value, model) {
			if (!Ember.isNone(value)) {
				var rootProject = this.getActiveList(0);
				var parentProject = this.getActiveList(index - 1);

				var subProject = this.createNewModel(false);
				subProject.set('title', value);
				subProject.set('rootProjectId', rootProject.get('id'));
				subProject.set('parentProjectId', parentProject.get('id'));
				subProject.set('depth', index + 1);
				subProject.set('projectInfo', model.get('projectInfo'));
				subProject.set('projectInfo.projectId', subProject.get('id'));

				if (this.modelHasData(subProject)) {
					var oldModel = this.hasModel(subProject);
					if (!oldModel) {
						this.get('model').pushObject(subProject);
					} else {
						subProject = oldModel;
					}
					this.setActiveList(index, subProject);
				}
			}
		},

		hasModel: function hasModel(model) {
			var found = this.get('model').find(function (item) {
				if (item.get('title') === model.get('title')) {
					if (model.get('rootProjectId') === model.get('id')) {
						return true;
					} else if (item.get('rootProjectId') === model.get('rootProjectId') && item.get('parentProjectId') === model.get('parentProjectId')) {
						return true;
					}
				}
				return false;
			}, this);

			return !Ember.isNone(found) ? found : false;
		},

		modelHasData: function modelHasData(model) {
			var title = model.get('title') || '';
			if (typeof title !== 'string') {
				title = title.toString();
			}

			title = title.trim();

			if (!Ember.isEmpty(title)) {
				return true;
			}
			return false;
		},

		onDoneParsing: function onDoneParsing() {
			this.loadSubProjects();
			this._super();
		},

		saveModel: function saveModel() {
			var _this = this;
			var models = this.get('model').sortBy('depth');
			return this._saveModels(models).then(function (data) {
				_this.trigger('complete');
				return data;
			});
		},

		_saveModels: function _saveModels(models) {
			var _this = this;
			var dataArray = [];
			models.forEach(function (item) {
				var projectData = _this.prepareObject('project', {
					id: item.id,
					title: item.get('title'),
					root_project_id: item.get('rootProjectId'),
					parent_project_id: item.get('parentProjectId'),
					depth: item.get('depth'),
					organization_id: item.get('organizationId'),
					created_on: _utils.Time.timestamp()
				});

				dataArray.push(projectData);

				if (!Ember.isNone(item.get('projectInfo'))) {
					var projectInfoData = _this.prepareObject('project-info', {
						id: item.get('projectInfo.id'),
						project_id: item.get('projectInfo.projectId'),
						number: item.get('projectInfo.number'),
						customer: item.get('projectInfo.customer'),
						address1: item.get('projectInfo.address1'),
						address2: item.get('projectInfo.address2'),
						city: item.get('projectInfo.city'),
						state: item.get('projectInfo.state'),
						postal_code: item.get('projectInfo.postalCode'),
						created_on: _utils.Time.timestamp()
						//	phone: item.get('phone')
					});
					dataArray.push(projectInfoData);
				}
			});
			return this.sendRequest(dataArray, 20);
		}
	});
});