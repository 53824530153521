define('busy-app/components/report/time-card-approval/list-item-loading', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				tagName: 'span',
				classNames: ['c-icon', 'refresh-icon', 'icon-spin-counter']
		});
});