define('busy-app/components/file-drop-box', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-file-drop-box'],

		multiple: false,

		title: (0, _utils.loc)('Drag files or click here to upload files'),

		click: function click(evt) {
			evt.stopPropagation();
			return true;
		},
		change: function change(evt) {
			this.handleFile(evt);
		},
		drop: function drop(evt) {
			evt.stopPropagation();
			evt.preventDefault();
			this.handleFile(evt);
		},
		handleFile: function handleFile(evt) {
			var files = null;
			if (!Ember.isNone(evt.target) && evt.target.files.length > 0) {
				files = evt.target.files;
				this.sendAction('onChange', files);
			} else if (!Ember.isNone(evt.dataTransfer) && evt.dataTransfer.files.length > 0) {
				files = evt.dataTransfer.files;
				this.sendAction('onChange', files);
			} else if (!Ember.isNone(evt.originalEvent) && !Ember.isNone(evt.originalEvent.srcElement) && evt.originalEvent.srcElement.files.length > 0) {
				files = evt.originalEvent.srcElement.files;
				this.sendAction('onChange', files);
			}
		}
	});
});