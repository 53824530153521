define('busy-app/collections/base', ['exports', 'busy-app/utils/request-helpers'], function (exports, _requestHelpers) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var generateId = _requestHelpers.default.generateId;
	exports.default = Ember.ArrayProxy.extend(Ember.Evented, {
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		content: null,
		store: null,
		manager: null,

		isLoading: false,
		isUpdating: false,

		update: function update() {
			var _this = this;

			this.set('isUpdating', true);
			this.manager.update().then(function () {
				_this.set('isUpdating', false);
			});
		},
		buildModels: function buildModels(data) {
			return data;
		},
		model: function model() {
			return Ember.RSVP.resolve();
		},
		createPolymorph: function createPolymorph(type) {
			var content = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			var owner = Ember.getOwner(this);
			var Polymorph = owner.factoryFor('polymorph:' + Ember.String.dasherize(type));
			var polymorph = Polymorph.create({
				store: this.store,
				content: content,
				manager: this,
				getter: this,
				id: generateId(),
				_name: type
			});

			return polymorph;
		},
		populateModels: function populateModels(data) {
			var _this2 = this;

			this.set('isLoading', true);
			var models = this.buildModels(data);
			if (!Ember.isNone(models.forEach)) {
				models.forEach(function (model) {
					_this2.addInternalModel(model);
				});
			} else {
				this.set('model', models);
			}
			this.set('isLoading', false);
			return this;
		},
		objectAtContent: function objectAtContent(index) {
			var content = Ember.get(this, 'content');
			return content.objectAt(index);
		},
		addInternalModel: function addInternalModel(model, idx) {
			if (idx !== undefined) {
				this.get('content').removeAt(idx, 1);
				this.get('content').insertAt(idx, model);
			} else {
				this.get('content').pushObject(model);
			}
			return this;
		},
		removeInternalModel: function removeInternalModel(model) {
			this.get('content').removeObject(model);
			return this;
		}
	});
});