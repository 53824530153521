define('busy-app/services/state', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.deserializeModel = exports.deserializeModelArray = undefined;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var deserializeModelArray = exports.deserializeModelArray = function deserializeModelArray(modelArray) {
		(false && !(Array.isArray(modelArray) || Array.isArray(Ember.get(modelArray, 'content'))) && Ember.assert('deserializeModelArray requires an Array, you passed [ ' + (typeof modelArray === 'undefined' ? 'undefined' : _typeof(modelArray)) + ' ]', Array.isArray(modelArray) || Array.isArray(Ember.get(modelArray, 'content'))));

		// get state array

		return modelArray.map(function (model) {
			return modelToState(model);
		});
	};

	var deserializeModel = exports.deserializeModel = function deserializeModel(model) {
		(false && !(isModel(model)) && Ember.assert('deserializeModel requires a DS.Model, you passed [ ' + (typeof model === 'undefined' ? 'undefined' : _typeof(model)) + ' ]', isModel(model)));

		// create state from model

		return model.toJSON({ includeId: true });
	};

	var modelToState = function modelToState(model) {
		var data = model.toJSON({ includeId: true });
		return Object.assign({}, data);
	};

	var isArray = function isArray(value) {
		return !Ember.isNone(value) && Array.isArray(value);
	};

	var isObject = function isObject(value) {
		return !Ember.isNone(value) && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && !isArray(value);
	};

	var isModel = function isModel(value) {
		return isObject(value) && value instanceof _emberData.default.Model;
	};

	/**
  * @class StateService
  *
  */
	exports.default = Ember.Service.extend(Ember.Evented, {
		store: Ember.inject.service(),

		_store: function _store() {
			return Ember.get(this, 'store');
		},
		find: function find(type, id) {
			return this._store().findRecord(type, id).then(function (model) {
				return deserializeModel(model);
			});
		},
		findRecords: function findRecords(type, ids, query, key) {
			query = Object.assign({}, query);
			key = !Ember.isEmpty(key) ? key : 'id';

			return this._store().findWhereIn(type, key, ids, query).then(function (modelArray) {
				return deserializeModelArray(modelArray.toArray());
			});
		},
		findAll: function findAll(type, query) {
			query = Object.assign({}, query);

			return this._store().findAll(type, query).then(function (modelArray) {
				return deserializeModelArray(modelArray.toArray());
			});
		},
		queryRecord: function queryRecord(type, query) {
			query = Object.assign({}, query);

			return this._store().queryRecord(type, query).then(function (model) {
				return deserializeModel(model);
			});
		},
		query: function query(type, _query) {
			_query = Object.assign({}, _query);

			return this._store().query(type, _query).then(function (modelArray) {
				return deserializeModelArray(modelArray.toArray());
			});
		},
		updateState: function updateState(type, state) {
			var model = this.__getModel(type, state.id);
			model.setProperties(state);

			if (Ember.get(model, 'hasDirtyAttributes')) {
				// trigger model has changed update
				state = Object.assign({}, state, deserializeModel(model));
			}

			return state;
		},
		saveModel: function saveModel(type, state) {
			// copy state
			state = Object.assign({}, state);

			// find model record and update props
			var model = this.__getModel(state.__type, state.id);
			model.setProperties(state);

			// save model to database
			return model.save().then(function (model) {
				return deserializeModel(model);
			});
		},
		createModelAndSave: function createModelAndSave(type, state) {
			// create new model record
			var model = this._store().createRecord(type, state);

			// save new model record
			return model.save().then(function (model) {
				return deserializeModel(model);
			});
		},
		createState: function createState(type, state) {
			if (!state.id) {
				state.id = _utils.UUID.generate();
			}

			var model = this._store().createRecord(type, state);
			return deserializeModel(model);
		},
		getState: function getState(type, id) {
			var model = this.__getModel(type, id);
			if (!Ember.isNone(model)) {
				return deserializeModel(model);
			}
			return null;
		},
		getStateBy: function getStateBy(type, key, id) {
			var models = this._store().peekAll(type);
			if (models && models.length) {
				var model = models.findBy(key, id);
				if (model) {
					return deserializeModel(model);
				}
			}
			return null;
		},
		getAllStatesBy: function getAllStatesBy(type, key, id) {
			var models = this._store().peekAll(type);
			models = models.filterBy(key, id);
			if (!Ember.isEmpty(models)) {
				return deserializeModelArray(models);
			}
			return [];
		},
		__getModel: function __getModel(type, id) {
			return this._store().peekRecord(type, id);
		}
	});
});