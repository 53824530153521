define('busy-app/utils/models/collections/time-card-conflicts', ['exports', 'busy-app/utils/models/collections/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		conflictModel: null,

		applyData: function applyData(model, conflictModel) {
			this.set('conflictModel', conflictModel);
			this._super(model);
		}
	});
});