define('busy-app/events/emitter', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Object.extend(Ember.Evented, {
		send: function send(eventName) {
			var dispatch = Ember.get(this, '_' + eventName);
			if (!Ember.isNone(dispatch) && typeof dispatch === 'function') {
				for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
					args[_key - 1] = arguments[_key];
				}

				var eventArgs = dispatch.apply(this, args);

				_utils.Assert.test('Events must return an array of arguments for event listeners', Ember.isArray(eventArgs));

				eventArgs.unshift(eventName);

				this.trigger.apply(this, _toConsumableArray(eventArgs));
				return true;
			}
			return false;
		}
	});
});