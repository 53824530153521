define('busy-app/components/time/time-entry/clock-in-at', ['exports', '@busy-web/utils', 'busy-app/utils/round-time'], function (exports, _utils, _roundTime) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    classNames: ['v-time-card-dialog-clock-in-at'],

    analytics: Ember.inject.service('analytics'),
    auth: Ember.inject.service('auth'),

    member: null,
    startDateTime: null,

    memberEntries: null,
    memberMode: 'single',

    project: null,
    costCode: null,
    equipment: null,
    description: '',

    costCodesAutoOpen: false,
    projectsAutoOpen: false,
    isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),
    disableCostCodeSelection: Ember.computed('project.id', 'isLinkedCostCodesEnabled', function () {
      return Ember.get(this, 'isLinkedCostCodesEnabled') && Ember.isNone(Ember.get(this, 'project.id'));
    }),

    /**
     * @private
     * @method init
     */
    init: function init() {
      this._super();

      this.setTimeRoundingInterval();

      //sets the clock In time input box to the current time
      var start = _utils.Time.date().startOf('minute').unix();
      start = (0, _roundTime.roundOrgTime)(this.get('auth.organization'), start, { isStart: true });
      this.set('startDateTime', start);

      //sets the minimum and maximum date that the user can select
      this.set('mindate', _utils.Time.date().subtract(1, 'day').startOf('day').unix());
      this.set('maxdate', _utils.Time.date().endOf('day').unix());

      if (!Ember.isNone(this.get('memberEntries'))) {
        this.set('memberMode', 'multi');
      }

      if (this.get('features.locks')) {
        return this.setMemberLocks();
      }
    },


    /**
     * @method setTimeRoundingInterval
     */
    setTimeRoundingInterval: function setTimeRoundingInterval() {
      var interval = 1;
      if (this.get('auth.organization.timeRounding')) {
        interval = parseInt(this.get('auth.organization.timeRoundingInterval') / 60, 10);
      }
      this.set('timeRoundingInterval', interval);
    },


    /**
     * @public
     * @method clockIn
     */
    clockIn: function clockIn(member, override) {
      if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
        member = Ember.get(member, 'content');
      }

      var startTime = this.get('startDateTime');

      //project and costCode CANNOT be passed into clockInAt
      //Only used for then function to set model project and costCode
      var project = this.get('project');
      var costCode = this.get('costCode');
      var equipment = this.get('equipment');
      return member.clockInAt(startTime, this.get('project.id'), this.get('costCode.id'), this.get('description'), this.get('equipment.id'), override).then(function (model) {
        model.set('project', project);
        model.set('costCode', costCode);
        model.set('equipment', equipment);

        return model;
      });
    },


    /**
     * Make sure all the `member` objects have an up to date `member-lock`
     *
     * @private
     * @method setMemberLocks
     * @async
     * @chainable
     * @return {EmberPromise}
     */
    setMemberLocks: function setMemberLocks() {
      var promises = [];
      this.get('membersCombined').forEach(function (member) {
        if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
          member = Ember.get(member, 'content');
        }
        promises.push(member.get('memberLock'));
      });
      return Ember.RSVP.all(promises);
    },


    /**
     * Create a unified array that contains either the one `member` or all members in `memberEntries`
     *
     * @public
     * @property membersCombined
     * @type {Models.Member[]}
     */
    membersCombined: Ember.computed('member', 'memberEntries', function () {
      return this.get('memberMode') === 'multi' ? [].concat(_toConsumableArray(this.get('memberEntries'))) : [this.get('member')];
    }),

    /**
     * Filtered array of `membersCombined` who have a lock date before the selected `startDateTime`
     *
     * @public
     * @property membersWithLocksBeforeStartTime
     * @type {Models.Member[]}
     */
    membersWithLocksBeforeStartTime: Ember.computed('startDateTime', 'membersCombined', function () {
      if (!this.get('features.locks')) {
        return null;
      } else {
        var startDate = this.get('startDateTime');
        return this.get('membersCombined').filter(function (member) {
          if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
            member = Ember.get(member, 'content');
          }
          member.isDateLocked(startDate);
        });
      }
    }),

    /**
     * Error message to be shown for a `startDateTime` which violates a lock date
     *
     * @public
     * @property lockDateErrorMessage
     * @type {string[]}
     */
    lockDateErrorMessage: Ember.computed('membersWithLocksBeforeStartTime', 'memberMode', function () {
      if (this.get('memberMode') === 'single') {
        var msg = (0, _utils.loc)('Date must be later than the lock date');
        var date = _utils.Time.date(this.get('member.memberLock.effectiveDate')).format('L');

        return msg + ': ' + date;
      } else {
        var members = this.get('membersWithLocksBeforeStartTime');
        var numMembers = members.get('length');
        var _msg = (0, _utils.loc)('employees have lock dates before the selected date');

        return numMembers + ' ' + _msg;
      }
    }),

    hasMultipleMembers: Ember.computed('memberEntries.[]', function () {
      if (this.get('memberEntries.length') > 1) {
        return true;
      }
      return false;
    }),

    actions: {
      /**
       * @event projectSelected
       */
      projectSelected: function projectSelected(item) {
        this.set('project', item);

        if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
          Ember.set(this, 'costCode', null);
        }
      },


      /**
       * @event costCodeSelected
       */
      costCodeSelected: function costCodeSelected(item) {
        this.set('costCode', item);
      },


      /**
       * @event equipmentSelected
       */
      equipmentSelected: function equipmentSelected(item) {
        this.set('equipment', item);
      },


      /**
       * closes dialog if clicked on cancel button or clicked in background window
       *
       * @event close
       */
      close: function close() {
        this.sendAction('onClose');
      },


      /**
       * @event clockinSave
       */
      clockinSave: function clockinSave(dialog) {
        var _this = this;

        var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (this.get('features.locks') && !Ember.isEmpty(this.get('membersWithLocksBeforeStartTime'))) {
          this.get('analytics').addEvent(84);
          return Ember.RSVP.reject((0, _utils.loc)('Errors must be fixed before saving.'));
        } else {
          if (!Ember.isNone(this.get('memberEntries'))) {
            var members = this.get('memberEntries');

            var promise = [];
            members.forEach(function (member) {
              promise.push(_this.clockIn(member, true));
            });

            return Ember.RSVP.all(promise);
          } else {
            return this.clockIn(this.get('member'), forceSave);
          }
        }
      },
      projectMenuClosed: function projectMenuClosed(didSelectValue /*, selectedItem, openedBy*/) {
        var _this2 = this;

        if (Ember.get(this, 'isLinkedCostCodesEnabled') && didSelectValue) {
          Ember.run.next(this, function () {
            Ember.setProperties(_this2, {
              costCodesAutoOpen: Date.now()
            });
          });
        }
      },
      costCodeMenuClosed: function costCodeMenuClosed(didSelectValue, selectedItem, openedBy) {
        var _this3 = this;

        // Logger.info(this, 'costCodeMenuClosed', didSelectValue, selectedItem, openedBy);

        // if the cost code menu was opened automatically by the double nav and the user hits back (!didSelectValue) then re-open the project selecter
        if (Ember.get(this, 'isLinkedCostCodesEnabled') && !didSelectValue && openedBy === 'auto') {
          Ember.run.next(this, function () {
            Ember.setProperties(_this3, {
              costCode: null,
              projectsAutoOpen: Date.now()
            });
          });
        }
      }
    }
  });
});