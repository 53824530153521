define('busy-app/utils/time-entry', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kFlags = {
		OTE: 10, // Open Time Entry
		OB: 20, // Open Break
		CTEOB: 30 // Closed Time Entry / Open Break
	}; /**
     * @module Utils
     *
     */


	var TimeEntry = Ember.Object.extend();

	/**
  * `Util/TimeEntry`
  *
  */
	TimeEntry.reopenClass({
		timeByRange: function timeByRange(entry, start, end, calcOpenBreak) {
			_utils.Assert.funcNumArgs(arguments, 4);

			// convert to numbers incase start and end a re strings
			start = parseInt(start, 10);
			end = parseInt(end, 10);

			_utils.Assert.isNumber(start);
			_utils.Assert.isNumber(end);
			_utils.Assert.test("Start or End was passed to timeByRange as NaN", !isNaN(start) && !isNaN(end));

			var flag = 0;
			var startTime = Ember.get(entry, 'startTime');
			var endTime = Ember.get(entry, 'endTime');
			var dateStamp = Ember.get(entry, 'dateStamp');
			var timeOffSeconds = Ember.get(entry, 'seconds');

			if (Ember.isNone(endTime)) {
				flag = kFlags.OTE;
				endTime = _utils.Time.nistTimestamp();
			}

			if (start > startTime) {
				startTime = start;
			}

			if (end < endTime) {
				endTime = end;
			}

			var breakInfo = this.totalBreakTime(entry, startTime, endTime, calcOpenBreak);

			return {
				startTime: startTime,
				endTime: endTime,
				dateStamp: dateStamp,
				timeOffSeconds: timeOffSeconds,
				flag: flag,
				breakTime: breakInfo.total,
				breakFlag: breakInfo.flags
			};
		},
		totalBreakTime: function totalBreakTime(entry, startTime, endTime, calcOpenBreak) {
			var _this = this;

			calcOpenBreak = !Ember.isNone(calcOpenBreak) ? calcOpenBreak : false;
			startTime = !Ember.isNone(startTime) ? startTime : Ember.get(entry, 'startTime');
			endTime = !Ember.isNone(endTime) ? endTime : Ember.get(entry, 'endTime');

			if (Ember.isNone(endTime)) {
				endTime = _utils.Time.nistTimestamp();
			}

			var breakTime = 0;
			var flags = [];
			var breaks = Ember.get(entry, 'timeEntryBreaks');
			var breakTimes = [];

			if (!Ember.isNone(breaks)) {
				breaks.forEach(function (breakEntry) {
					if (!breakEntry.get('isDeleted')) {
						var breakObj = _this.breakTimeByRange(breakEntry, startTime, endTime, calcOpenBreak);

						if (breakObj.flag === kFlags.OB && !Ember.isNone(Ember.get(entry, 'endTime'))) {
							breakObj.flag = kFlags.CTEOB;
							breakObj.endTime = breakObj.startTime;
						}

						breakTimes.forEach(function (item) {
							var startsIn = breakObj.startTime !== breakObj.endTime && item.startTime <= breakObj.startTime && item.endTime > breakObj.startTime;
							var endsIn = breakObj.startTime !== breakObj.endTime && item.startTime < breakObj.endTime && item.endTime >= breakObj.endTime;

							if (startsIn && endsIn) {
								// The break is contained inside another break
								// set the end the same as the start so the time does not
								// effect the total break time.
								breakObj.endTime = breakObj.startTime;
							} else if (startsIn) {
								// The startTime is contained inside another break
								// set the startTime to the other breaks endTime
								breakObj.startTime = item.endTime;
							} else if (endsIn) {
								// The endTime is contained inside another break
								// set the endTime to the other breaks startTime
								breakObj.endTime = item.startTime;
							}
						});

						if (breakObj.startTime < entry.get('startTime')) {
							breakObj.startTime = entry.get('startTime');
						} else if (!Ember.isNone(entry.get('endTime')) && breakObj.startTime > entry.get('endTime')) {
							breakObj.startTime = entry.get('endTime');
						}

						if (breakObj.endTime < entry.get('startTime')) {
							breakObj.endTime = entry.get('startTime');
						} else if (!Ember.isNone(entry.get('endTime')) && breakObj.endTime > entry.get('endTime')) {
							breakObj.endTime = entry.get('endTime');
						}

						breakTimes.pushObject(breakObj);
						flags.push(breakObj.flag);

						breakTime += breakObj.endTime - breakObj.startTime;
					}
				});
			}

			return { total: breakTime, flags: flags };
		},
		breakTimeByRange: function breakTimeByRange(breakEntry, startTime, endTime, calcOpenBreak) {
			_utils.Assert.funcNumArgs(arguments, 4);
			_utils.Assert.isNumber(startTime);
			_utils.Assert.isNumber(endTime);
			_utils.Assert.test("startTime and endTime are required params for validBreakTimes(breakEntry, startTime, endTime)", !Ember.isNone(startTime) && !Ember.isNone(endTime));

			var flag = 0;
			var breakStart = Ember.get(breakEntry, 'startTime');
			var breakEnd = Ember.get(breakEntry, 'endTime');

			if (Ember.isNone(breakEnd)) {
				flag = kFlags.OB;
				if (calcOpenBreak) {
					breakEnd = _utils.Time.nistTimestamp();
				} else {
					breakEnd = breakStart; //Time.nistTimestamp();
				}
			}

			if (breakStart < startTime) {
				breakStart = startTime;
			}

			if (breakEnd > endTime) {
				breakEnd = endTime;
			}

			if (breakEnd < breakStart) {
				breakEnd = breakStart;
			}

			return {
				id: breakEntry.get('id'),
				startTime: breakStart,
				endTime: breakEnd,
				flag: flag
			};
		},
		isTimeEntry: function isTimeEntry(timeEntry) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(timeEntry);

			return !Ember.isNone(timeEntry) && !Ember.isNone(timeEntry.get) && !Ember.isNone(timeEntry.get('startTime'));
		},
		isTimeOffEntry: function isTimeOffEntry(timeEntry) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(timeEntry);

			return !Ember.isNone(timeEntry) && !Ember.isNone(timeEntry.get) && !Ember.isNone(timeEntry.get('dateStamp')) && !Ember.isNone(timeEntry.get('model.createdOnDaylightSavingTime'));
		},
		isDayDiff: function isDayDiff(timeEntry) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeEntry model to isDayDiff", this.isTimeEntry(timeEntry));

			var start = timeEntry.get('startTime');
			var end = timeEntry.get('endTime');

			if (start !== end) {
				end = end - 1;
			}

			return _utils.Time.date(start).date() !== _utils.Time.date(end).date();
		},
		isDSTDiff: function isDSTDiff(timeEntry) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeEntry model to isDSTDiff", this.isTimeEntry(timeEntry));

			var isDSTDiff = !Ember.isNone(timeEntry.get('endTime')) && !Ember.isNone(timeEntry.get('metaDaylightSavingTime')) && timeEntry.get('daylightSavingTime') !== timeEntry.get('metaDaylightSavingTime');

			// if the timeZone is the same and the start and end
			// are on the same day then the dst must be the same.
			// This can happen on time-entries where the end time
			// is being split for a day and the end time is not the
			// actual time entry end time.
			if (!this.isTimeZoneDiff(timeEntry) && !this.isDayDiff(timeEntry)) {
				return false;
			} else {
				return isDSTDiff;
			}
		},
		isTimeZoneDiff: function isTimeZoneDiff(timeEntry) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeEntry model to isTimeZoneDiff", this.isTimeEntry(timeEntry));

			return !Ember.isNone(timeEntry.get('endTime')) && !Ember.isNone(timeEntry.get('metaOffset')) && timeEntry.get('offset') !== timeEntry.get('metaOffset');
		},
		isViewingZoneDiff: function isViewingZoneDiff(timeEntry) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeEntry model to isViewingZoneDiff", this.isTimeEntry(timeEntry));

			var offset = timeEntry.get('offset');
			var currentOffset = _utils.Time.timezone();

			return offset !== currentOffset;
		},
		showTimeZoneInfo: function showTimeZoneInfo(timeEntry) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(timeEntry);

			var endOfDay = _utils.Time.date(timeEntry.get('startTime')).endOf('day').unix() + 1;
			var isEndMatch = endOfDay === timeEntry.get('endTime');

			if (!Ember.isNone(timeEntry.get('model.endTime'))) {
				isEndMatch = isEndMatch && timeEntry.get('model.endTime') !== endOfDay;
			}

			var isDSTDiff = this.isDSTDiff(timeEntry);
			var isTimeZoneDiff = this.isTimeZoneDiff(timeEntry);
			var isViewingZoneDiff = this.isViewingZoneDiff(timeEntry);

			var show = false;
			if (!isEndMatch && isViewingZoneDiff) {
				show = true;
			} else if (!isEndMatch && isDSTDiff) {
				show = true;
			} else if (!isEndMatch && isTimeZoneDiff) {
				show = true;
			}

			return show;
		},
		startTimeString: function startTimeString(timeEntry) {
			var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'h:mm A';
			var removeHTML = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			// force 3 arguments 1 required and default 2
			_utils.Assert.funcNumArgs(arguments, 3);

			// assert this is a timeEntry
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeEntry model to startTimeString", this.isTimeEntry(timeEntry));

			// format must ba a string
			_utils.Assert.isString(format);

			// removeHTML must be a boolean
			_utils.Assert.isBoolean(removeHTML);

			// setup timezone information
			var startZone = '';
			if (this.showTimeZoneInfo(timeEntry)) {
				startZone = _utils.Time.timezoneString(timeEntry.get('offset'), timeEntry.get('daylightSavingTime'));
				if (!removeHTML) {
					startZone = ' <sup>' + startZone + '</sup>';
				} else {
					startZone = ' ' + startZone;
				}
			}

			return _utils.Time.timeFormat(timeEntry.get('startTime'), format) + startZone;
		},
		endTimeString: function endTimeString(timeEntry) {
			var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'h:mm A';
			var removeHTML = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			// force 3 arguments 1 required and default 2
			_utils.Assert.funcNumArgs(arguments, 3);

			// assert this is a timeEntry
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeEntry model to endTimeString", this.isTimeEntry(timeEntry));

			// format must ba a string
			_utils.Assert.isString(format);

			// removeHTML must be a boolean
			_utils.Assert.isBoolean(removeHTML);

			// get the endtime
			var end = timeEntry.get('endTime');

			// check for endtime that ends on the next day and that is is a split time entry
			// example time entry
			//	 -> start 10:00 AM ends 10:00 AM
			// the split time entry would be:
			//   -> start 10:00 AM ends 12:00 AM
			//   -> start 12:00 AM ends 10:00 AM
			// in this case we would subtract 1 second from the first entry to show:
			//   -> start 10:00 AM ends 11:59 PM
			//   -> start 12:00 AM ends 10:00 AM
			//
			var isSplitEntry = !Ember.isNone(timeEntry.get('model.endTime'));
			var isSpanningDay = _utils.Time.date(timeEntry.get('startTime')).date() !== _utils.Time.date(timeEntry.get('endTime')).date();
			var isNotActualEnd = timeEntry.get('model.endTime') !== timeEntry.get('endTime');

			if (isSplitEntry && isSpanningDay && isNotActualEnd) {
				end = end - 1;
			}

			// setup the timezone information
			var endZone = '';
			if (this.showTimeZoneInfo(timeEntry)) {
				endZone = _utils.Time.timezoneString(timeEntry.get('metaOffset'), timeEntry.get('metaDaylightSavingTime'));
				if (!removeHTML) {
					endZone = ' <sup>' + endZone + '</sup>';
				} else {
					endZone = ' ' + endZone;
				}

				// adjust end time according to the metaOffset
				end = end - timeEntry.get('offset') + timeEntry.get('metaOffset');
			}

			return _utils.Time.timeFormat(end, format) + endZone;
		},
		timeOffType: function timeOffType(timeEntry) {
			// allow only 1 argument
			_utils.Assert.funcNumArgs(arguments, 1, true);

			// assert this is a timeEntry
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeOff model to timeOffType", this.isTimeOffEntry(timeEntry));

			// get the type
			var type = timeEntry.get('model.getType');

			// return the type string.
			return '*' + type + '*';
		},
		timeRangeString: function timeRangeString(timeEntry) {
			var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'h:mm A';
			var removeHTML = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			// must pass 1 argument and optional format
			_utils.Assert.funcNumArgs(arguments, 3);

			// arg 1 must be a time entry model
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeEntry model to timeRangeString", this.isTimeEntry(timeEntry));

			// arg2 must be a string
			_utils.Assert.isString(format);

			return this.startTimeString(timeEntry, format, removeHTML) + ' - ' + this.endTimeString(timeEntry, format, removeHTML);
		},
		timeOffRangeString: function timeOffRangeString(timeEntry) {
			var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'h:mm A';

			// must pass 1 argument and optional format
			_utils.Assert.funcNumArgs(arguments, 2);

			// arg 1 must be a time entry model
			_utils.Assert.isObject(timeEntry);
			_utils.Assert.test("You must pass a timeOff model to timeOffRangeString", this.isTimeOffEntry(timeEntry));

			// arg2 must be a string
			_utils.Assert.isString(format);

			return this.timeOffType(timeEntry);
		}
	});

	exports.default = TimeEntry;
});