define('busy-app/utils/models/filters/member', ['exports', 'busy-app/utils/models/filters/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Utils/Models/Filters
  *
  */
	var kDefaultModels = ['timeEntries', 'openEntry', 'timeOffEntries'];

	/**
  * `Util/Model/Filter/Member`
  *
  * @class Member
  * @namespace Utils.Models.Filters
  * @extends Utils.Models.Filter.Base
  */
	exports.default = _base.default.extend({
		/**
   * default models to return
   *
   */
		getRelatedModelsByRange: function getRelatedModelsByRange(start, end, memberId) {
			var _this = this;

			var models = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : kDefaultModels;

			_utils.Assert.test("filter.member.getRelatedModelsByRange() - start and end are required", !Ember.isNone(start) && !Ember.isNone(end));
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - memberId is required", !Ember.isNone(memberId));
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - models is required", !Ember.isNone(models));

			_utils.Assert.test("filter.member.getRelatedModelsByRange() - start must be a valid epoch time in seconds of type number", typeof start === 'number');
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - end must be a valid epoch time in seconds of type number", typeof end === 'number');
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - memberId must be a valid UUID", _utils.UUID.isValid(memberId));
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - models must be an array", Array.isArray(models));
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - models array cannot be empty", !Ember.isEmpty(models));

			var modelGetters = {
				timeEntries: function timeEntries() {
					return _this.store.filter('time-entry').findAllByRange(start, end, memberId);
				},
				openEntry: function openEntry() {
					return _this.store.filter('time-entry').findOpenEntry(memberId);
				},
				timeOffEntries: function timeOffEntries() {
					return _this.store.filter('time-off').findAllByRange(start, end, memberId);
				},
				memberLocations: function memberLocations() {
					return _this.store.query('member-location', { memberId: memberId, _gte: { time_gmt: start }, _lte: { time_gmt: end } });
				}
			};

			var rsvpHash = {};

			models.forEach(function (model) {
				_utils.Assert.test('filter.member.getRelatedModelsByRange() - ' + model + ' is not a supported model', modelGetters.hasOwnProperty(model));
				rsvpHash[model] = modelGetters[model]();
			});

			return Ember.RSVP.hash(rsvpHash);
		},
		getRelatedModelsByRangeForMembers: function getRelatedModelsByRangeForMembers(start, end, memberIds) {
			var _this2 = this;

			var models = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : kDefaultModels;

			_utils.Assert.test("filter.member.getRelatedModelsByRange() - start and end are required", !Ember.isNone(start) && !Ember.isNone(end));
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - memberIds is required", !Ember.isNone(memberIds));
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - models is required", !Ember.isNone(models));

			_utils.Assert.test("filter.member.getRelatedModelsByRange() - start must be a valid epoch time in seconds of type number", typeof start === 'number');
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - end must be a valid epoch time in seconds of type number", typeof end === 'number');
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - memberIds must be an array of valid UUIDs", Ember.typeOf(memberIds) === 'array');
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - models must be an array", Array.isArray(models));
			_utils.Assert.test("filter.member.getRelatedModelsByRange() - models array cannot be empty", !Ember.isEmpty(models));

			var modelGetters = {
				timeEntries: function timeEntries() {
					return _this2.store.findWhereIn('time-entry', 'member_id', memberIds, { _gte: { end_time: start }, _lte: { start_time: end }, deleted_on: '_-NULL-_' });
				},
				openEntry: function openEntry() {
					return _this2.store.findWhereIn('time-entry', 'member_id', memberIds, { end_time: '_-NULL-_', deleted_on: '_-NULL-_' });
				},
				timeOffEntries: function timeOffEntries() {
					return _this2.store.findWhereIn('time-off', 'member_id', memberIds, { _gte: { date_stamp: start }, _lte: { date_stamp: end }, deleted_on: '_-NULL-_' });
				},
				memberLocations: function memberLocations() {
					return _this2.store.findWhereIn('member-location', 'member_id', memberIds, { _gte: { time_gmt: start }, _lte: { time_gmt: end }, deleted_on: '_-NULL-_' });
				},
				memberLocks: function memberLocks() {
					return _this2.store.findWhereIn('member-lock', 'member_id', memberIds, { deleted_on: '_-NULL-_' });
				}
			};

			var rsvpHash = {};

			models.forEach(function (model) {
				_utils.Assert.test('filter.member.getRelatedModelsByRange() - ' + model + ' is not a supported model', modelGetters.hasOwnProperty(model));
				rsvpHash[model] = modelGetters[model]();
			});

			return Ember.RSVP.hash(rsvpHash);
		}
	});
});