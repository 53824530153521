define('busy-app/models/pay-period-member-project-seconds', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('id', { sortable: true, sortBy: ['member.firstName', 'member.lastName'] }),
		projectId: _emberData.default.attr('id', { sortable: true, sortBy: ['project.title'], nested: 'project.parentProjectId' }),
		startTime: _emberData.default.attr('date', { sortable: true }),
		endTime: _emberData.default.attr('date', { sortable: true }),
		regularSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overtimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		doubletimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenRegularSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenOvertimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenDoubletimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		updatedOn: _emberData.default.attr('date'),
		submittedOn: _emberData.default.attr('date'),
		deletedOn: _emberData.default.attr('date'),

		member: _emberData.default.belongsTo('member'),
		project: _emberData.default.belongsTo('project'),

		dateString: Ember.computed('startTime', 'endTime', function () {
			var start = _utils.Time.date(this.get('startTime'));
			var end = _utils.Time.date(this.get('endTime'));
			var format = 'MMM D';
			if (start.year() !== end.year()) {
				format = 'MMM D, YYYY';
			}
			return _utils.Time.dateFormat(start, format) + ' - ' + _utils.Time.dateFormat(end, 'MMM D, YYYY');
		}),

		totalSeconds: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		childrenTotalSeconds: Ember.computed('childrenRegularSeconds', 'childrenOvertimeSeconds', 'childrenDoubletimeSeconds', function () {
			return this.get('childrenRegularSeconds') + this.get('childrenOvertimeSeconds') + this.get('childrenDoubletimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalRegularSeconds: Ember.computed('regularSeconds', 'childrenRegularSeconds', function () {
			return this.get('regularSeconds') + this.get('childrenRegularSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalOvertimeSeconds: Ember.computed('overtimeSeconds', 'childrenOvertimeSeconds', function () {
			return this.get('overtimeSeconds') + this.get('childrenOvertimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalDoubletimeSeconds: Ember.computed('doubletimeSeconds', 'childrenDoubletimeSeconds', function () {
			return this.get('doubletimeSeconds') + this.get('childrenDoubletimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalTotalSeconds: Ember.computed('totalSeconds', 'childrenTotalSeconds', function () {
			return this.get('totalSeconds') + this.get('childrenTotalSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalNoChildrenSeconds: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}),

		totalChildrenSeconds: Ember.computed('childrenRegularSeconds', 'childrenOvertimeSeconds', 'childrenDoubletimeSeconds', function () {
			return this.get('childrenRegularSeconds') + this.get('childrenOvertimeSeconds') + this.get('childrenDoubletimeSeconds');
		}),

		regularTimeString: Ember.computed('regularSeconds', function () {
			var seconds = this.get('regularSeconds') || 0;
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		overtimeTimeString: Ember.computed('overtimeSeconds', function () {
			var seconds = this.get('overtimeSeconds') || 0;
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		doubletimeTimeString: Ember.computed('doubletimeSeconds', function () {
			var seconds = this.get('doubletimeSeconds') || 0;
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		childrenRegularTimeString: Ember.computed('childrenRegularSeconds', function () {
			var seconds = this.get('childrenRegularSeconds') || 0;
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		childrenOvertimeTimeString: Ember.computed('childrenOvertimeSeconds', function () {
			var seconds = this.get('childrenOvertimeSeconds') || 0;
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		childrenDoubletimeTimeString: Ember.computed('childrenDoubletimeSeconds', function () {
			var seconds = this.get('childrenDoubletimeSeconds') || 0;
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		totalRegularTimeString: Ember.computed('totalRegularSeconds', function () {
			var seconds = this.get('totalRegularSeconds');
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		totalOvertimeTimeString: Ember.computed('totalOvertimeSeconds', function () {
			var seconds = this.get('totalOvertimeSeconds');
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		totalDoubletimeTimeString: Ember.computed('totalDoubletimeSeconds', function () {
			var seconds = this.get('totalDoubletimeSeconds');
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		totalTotalTimeString: Ember.computed('totalSeconds', function () {
			var seconds = this.get('totalSeconds');
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		}),

		totalTotalNoChildrenTimeString: Ember.computed('totalNoChildrenSeconds', function () {
			var seconds = this.get('totalNoChildrenSeconds');
			var time = _utils.Time.convertSeconds(seconds, true);
			return time.hours + ':' + time.minutes;
		})
	});
});