define('busy-app/components/time/time-entry/safety-required', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			close: function close() {
				this.sendAction('onClose');
			},
			viewSafetyReport: function viewSafetyReport() {
				this.sendAction('onClose');
				Ember.getOwner(this).lookup('route:application').transitionTo('report.safety-signature');
			}
		}
	});
});