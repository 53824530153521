define('busy-app/routes/report/payroll/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils'], function (exports, _authenticatedRouteMixin, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		payPeriods: Ember.inject.service('pay-period'),
		features: Ember.inject.service('features'),

		model: function model(params) {
			var type = params.type,
			    start = params.start;


			var managerType = this.getManagerType(type);
			var pController = this.controllerFor('report/payroll');
			var controller = this.controllerFor('report/payroll/index');

			Ember.setProperties(pController, { type: type, start: start });

			var routeStart = pController.get('start') || _utils.Time.date().unix();
			var payPeriod = this.get('payPeriods').getPayPeriod(routeStart);

			start = payPeriod.get('start');
			var end = payPeriod.get('end');

			Ember.setProperties(controller, { start: start, end: end });

			return this.store.getter().collection(managerType).fetch(payPeriod.get('startDate').unix(), payPeriod.get('endDate').unix());
		},
		getManagerType: function getManagerType(type) {
			var managerType = 'payroll-' + type;

			if (Ember.get(this, 'features.wageCustomDate')) {
				managerType = 'custom-wage-date/' + managerType;
			}

			return managerType;
		},


		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			}
		}
	});
});