define('busy-app/transitions', ['exports', 'busy-app/animations/time-card', 'busy-app/animations/employee', 'busy-app/animations/project', 'busy-app/animations/report', 'busy-app/animations/company'], function (exports, _timeCard, _employee, _project, _report, _company) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		var durationTo = 300;
		var durationBack = 300;

		_timeCard.default.call(this, durationTo, durationBack);
		_employee.default.call(this, durationTo, durationBack);
		_project.default.call(this, durationTo, durationBack);
		_report.default.call(this, durationTo, durationBack);
		_company.default.call(this, durationTo, durationBack);

		// note transition
		this.transition(this.toRoute('note.default'), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));

		// liquid-if transition
		this.transition(this.hasClass('slide-in'), this.toValue(true), this.use('toLeft', { duration: 400 }), this.reverse('toRight', { duration: 300 }));

		// liquid-if transition
		this.transition(this.hasClass('slide-route'), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));

		// login transition
		this.transition(this.hasClass('authification'), this.toValue(true), this.use('fade', { duration: 600 }), this.reverse('fade', { duration: 100 }));

		// clock transition
		this.transition(this.childOf('#liquid-bind-timer'), this.use('toUp'));

		// quickbooks mapping transition
		this.transition(this.hasClass('mapping-items'), this.toValue(true), this.use('toDown'), this.reverse('toUp'));
	};
});