define('busy-app/components/company/integrations/vendors/procore/connect-dialog', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		/**
   * @property classNames
   * @type {string[]}
   * @default ['v-company-settings-integration-connect-dialog']
   */
		classNames: ['v-company-settings-integration-connect-dialog'],

		/**
   * @property headerTitle
   * @type {string}
   */
		headerTitle: null,

		/**
   * @property cancelText
   * @type {string}
   */
		cancelText: null,

		/**
   * @property saveText
   * @type {string}
   */
		saveText: null,

		/**
   * @property onSave
   * @type {function}
   */
		onSave: null,

		/**
   * @property onClose
   * @type {function}
   */
		onClose: null,

		actions: {
			/** @event onClose */
			onClose: function onClose() {
				this.sendAction('onClose');
			},


			/** @event onSave */
			onSave: function onSave() {
				this.sendAction("onSave");
			},


			/** @event openSubscriptionPage */
			openSubscriptionPage: function openSubscriptionPage() {
				this.send('onClose');
				this.sendAction('openSubscriptionPage');
			}
		}
	});
});