define('busy-app/routes/report/safety-signature', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		model: function model() {
			var controller = this.controllerFor('report/safety-signature');
			var cController = this.controllerFor('report/safety-signature/date');
			controller.set('cController', cController);
		},


		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			}
		}
	});
});