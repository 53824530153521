define('busy-app/routes/account-settings', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    auth: Ember.inject.service('auth'),

    model: function model() {
      return this.get('auth.member');
    }
  });
});