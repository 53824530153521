define('busy-app/utils/clock/svg', ['exports', '@busy-web/ember-date-time/utils/clock/svg'], function (exports, _svg) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _svg.default;
    }
  });
});