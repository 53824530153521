define('busy-app/models/equipment-location', ['exports', 'ember-data', 'moment', '@busy-web/utils'], function (exports, _emberData, _moment, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		equipmentId: _emberData.default.attr('string'),
		longitude: _emberData.default.attr('number'),
		latitude: _emberData.default.attr('number'),
		altitude: _emberData.default.attr('number'),
		source: _emberData.default.attr('string'),
		utc: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		lastSync: Ember.computed('utc', function () {
			return _moment.default.utc(this.get('utc') * 1000).fromNow();
		}),

		sourceLocalized: Ember.computed('source', function () {
			if (this.get('source') === 'VisionLink') {
				return this.get('source');
			} else if (this.get('source') === 'Verified by beacon') {
				return (0, _utils.loc)("Verified by beacon");
			} else if (this.get('source') === 'Operator') {
				return (0, _utils.loc)("Operator location");
			}
		}),

		verifiedByBacon: Ember.computed('source', function () {
			if (this.get('source') === 'Verified by beacon') {
				return true;
			}
			return false;
		}),

		noLocation: Ember.computed('longitude', 'latitude', function () {
			if (Math.round(this.get('longitude')) === 0 && Math.round(this.get('latitude')) === 0) {
				return true;
			}
			return false;
		})
	});
});