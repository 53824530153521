define('busy-app/components/busy-image-uploader', ['exports', 'busy-app/components/busy-file-uploader', '@busy-web/utils'], function (exports, _busyFileUploader, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _busyFileUploader.default.extend({
		classNames: ['busy-image-uploader'],

		imageUrl: null,

		title: Ember.computed('fileName', function () {
			var title = '';
			if (!this.get('multiple')) {
				title = (0, _utils.loc)('Drag a file or click here to upload a photo.');
				if (!Ember.isNone(this.get('imageUrl')) && !Ember.isEmpty(this.get('imageUrl'))) {
					title = (0, _utils.loc)('Drag a file or click here to change your photo.');
				}
			} else {
				title = (0, _utils.loc)('Drag files or click here to upload a photos.');
				if (!Ember.isNone(this.get('imageUrl')) && !Ember.isEmpty(this.get('imageUrl'))) {
					title = (0, _utils.loc)('Drag files or click here to change your photos.');
				}
			}
			return title;
		}),

		fileNameUrl: Ember.computed('fileUrl', 'imageUrl', function () {
			var url = this.get('fileUrl');
			if (Ember.isNone(this.get('fileUrl'))) {
				if (!Ember.isNone(this.get('imageUrl'))) {
					url = this.get('imageUrl');
				} else {
					url = '';
				}
			}
			return url;
		})
	});
});