define('busy-app/components/company/integrations/upgrade-dialog', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-company-integrations-upgrade-dialog'],

		/**
   * Name of the integration to be displayed inline in the upgrade text
   *
   * @public
   * @property displayName
   * @type {String}
   */
		displayName: null,

		actions: {
			/**
    * Broadcasts the `closeUpgradeDialog` action
    *
    * @event closeUpgradeDialog
    */
			closeUpgradeDialog: function closeUpgradeDialog() {
				this.sendAction('closeUpgradeDialog');
			},


			/**
    * Broadcasts the `openSubscriptionPage` action
    *
    * @event openSubscriptionPage
    */
			openSubscriptionPage: function openSubscriptionPage() {
				this.sendAction('openSubscriptionPage');
			}
		}
	});
});