define('busy-app/components/company/integrations/sync-log-dialog/log-section', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		/**
   * Title of the section
   *
   * @public
   * @property titleName
   * @type {string}
   */
		titleName: null,

		/**
   * Array of entries which make up this sections data
   *
   * @public
   * @property entries
   * @type {object[]}
   */
		entries: null,

		/**
   * Component Lifecycle
   */

		/**
  * @private
  * @method init
  */
		init: function init() {
			this._super();

			if (Ember.isEmpty(Ember.get(this, 'entries'))) {
				Ember.set(this, 'entries', []);
			}

			(false && !(!Ember.isEmpty(Ember.get(this, 'titleName'))) && Ember.assert('sync-log-dialog.log-section :: property \'titleName\' is required', !Ember.isEmpty(Ember.get(this, 'titleName'))));
			(false && !(!Ember.isNone(Ember.get(this, 'entries'))) && Ember.assert('sync-log-dialog.log-section :: property \'entries\' is required', !Ember.isNone(Ember.get(this, 'entries'))));
		}
	});
});