define('busy-app/routes/report/time-entries', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		queryParams: {
			project: {
				refreshModel: true
			},
			sort_order: {
				replace: true
			}
		},

		model: function model(params) {
			return params;
		},


		actions: {
			reloadModel: function reloadModel() {
				this.refresh();
			}
		}
	});
});