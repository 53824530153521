define('busy-app/controllers/time-card/time-entry', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['id', 'member_id'],

		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		id: null,
		member_id: null,

		showEditDialog: false,

		entryHistoryDialog: false,

		timeEntryLog: null,
		updatedTimeEntryLog: null,
		timeEntryBreakLog: null,
		startTimeTrusted: false,
		endTimeTrusted: false,

		minDate: null,
		maxDate: null,
		startDateTime: null,
		endDateTime: null,

		breakTime: 0,
		totalTime: 0,

		projectIdChange: null,
		costCodeIdChange: null,

		init: function init() {
			this._super();

			this.setDates();
			this.adjustEndTime();
			this.totalAdjusted();
			this.totalTimeString();
		},


		isTooOldToEdit: Ember.computed('model.{startTime,member.lockDate}', function () {
			if (this.get('features.locks')) {
				if (this.get('model.startTime') > this.get('model.member.lockDate')) {
					return false;
				}
				return true;
			} else {
				var start = _utils.Time.date().unix();
				var minDate = _utils.Time.date().subtract(89, 'days').unix();
				if (!Ember.isNone(this.get('model.startTime'))) {
					start = _utils.Time.date(this.get('model.startTime')).unix();
				}
				return start <= minDate;
			}
		}),

		setDates: Ember.observer('model.startTime', 'model.endTime', function () {
			if (!Ember.isNone(this.get('model.startTime')) && !Ember.isNone(this.get('model.endTime'))) {
				var startTime = _utils.Time.date(this.get('model.startTime'));
				var endTime = _utils.Time.date(this.get('model.endTime'));
				var startDeviceTime = _utils.Time.date(this.get('model.startTime'));

				this.set('endDateTime', endTime);
				this.set('oldStartDate', startTime);
				this.set('startDateTime', startTime);
				this.set('startDeviceTime', startDeviceTime);
			}
		}),

		setBreakTimes: Ember.observer('model.breakTime', function () {
			if (!Ember.isNone(this.get('model.breakTime'))) {
				this.set('breakTime', this.get('model.breakTime'));
			}
		}),

		oldStartDate: null,
		adjustEndTime: Ember.observer('startDateTime', function () {
			if (!Ember.isNone(this.get('startDateTime'))) {
				var oldStart = this.get('oldStartDate');
				var start = this.get('startDateTime');
				var breakTime = this.get('breakTime');

				if (oldStart.date() !== start.date()) {
					var totalTime = this.get('totalTime');
					var curDuration = totalTime + breakTime;
					this.set('endDateTime', _utils.Time.date(start.unix()).add(curDuration, 'seconds').startOf('hour'));
				} else {
					var end = this.get('endDateTime');
					var seconds = end.unix() - start.unix() - breakTime;
					this.set('totalTime', seconds);
				}
				this.set('oldStartDate', this.get('startDateTime'));
			}
		}),

		totalAdjusted: Ember.observer('totalTime', function () {
			if (!Ember.isNone(this.get('startDateTime')) && !Ember.isNone(this.get('endDateTime'))) {
				var start = this.get('startDateTime');
				var total = this.get('totalTime');
				var breakTime = this.get('breakTime');
				var totalTime = total + breakTime;
				this.set('endDateTime', _utils.Time.date(start.unix()).add(totalTime, 'seconds'));
			}
		}),

		/**
   * function that calculates the amount of hours and minutes
   * from clock-in to clock-out input boxes.
   *
   * @public
   */
		totalTimeString: Ember.observer('endDateTime', 'breakTime', function () {
			if (!Ember.isNone(this.get('startDateTime')) && !Ember.isNone(this.get('endDateTime'))) {
				//variables for setting up seconds, minutes, and hours
				var seconds = this.get('endDateTime').unix() - this.get('startDateTime').unix();
				var breakSeconds = this.get('breakTime');
				seconds = seconds - breakSeconds;
				this.set('totalTime', seconds);
			}
		}),

		actions: {
			editTimeEntry: function editTimeEntry() {
				this.set('showEditDialog', true);
			},
			closeEditDialog: function closeEditDialog() {
				this.get('model').rollbackBreaks();
				this.get('model').rollbackAttributes();
				this.set('showEditDialog', false);
			},
			itemDeleted: function itemDeleted() {
				this.set('showEditDialog', false);
				window.history.back();
			},
			projectSelected: function projectSelected(item) {
				this.set('model.projectId', item.get('id'));
			},
			costCodeSelected: function costCodeSelected(item) {
				this.set('model.costCodeId', item.get('id'));
			},
			equipmentSelected: function equipmentSelected(item) {
				this.set('model.equipmentId', item.get('id'));
			},
			viewEntryHistory: function viewEntryHistory() {
				this.set('entryHistoryDialog', true);
			},
			viewFullMap: function viewFullMap() {
				this.transitionToRoute('employees.employee-locations', {
					queryParams: {
						id: this.get('model.id'),
						member_id: this.get('model.member.id')
					}
				});
			},
			closeEntryHistory: function closeEntryHistory() {
				this.set('entryHistoryDialog', false);
			}
		}
	});
});