define('busy-app/utils/string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = string;
  exports.centsToDollarFormat = centsToDollarFormat;
  function string() {
    return true;
  }

  /**
   * convert cents to dollars, fixed to 2 decimal places, with a dollar sign in front
   *
   * @public
   * @method centsToDollarFormat
   * @param cents {Integer}
   * @returns {string}
   */
  function centsToDollarFormat(cents) {
    var safeCents = parseInt(cents, 10);
    var symbol = safeCents < 0 ? '-$' : '$';
    var dollars = Math.abs(safeCents / 100).toFixed(2);

    return '' + symbol + dollars;
  }
});