define('busy-app/helpers/map', ['exports', 'busy-app/utils/logger'], function (exports, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.map = map;

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	function map(params, _ref) {
		var callback = _ref.callback;

		var _params$slice = params.slice(0, 2),
		    _params$slice2 = _slicedToArray(_params$slice, 2),
		    list = _params$slice2[0],
		    func = _params$slice2[1];

		if (!Ember.isNone(callback)) {
			func = callback;
		}

		if (Ember.isEmpty(list)) {
			_logger.default.error(this, "map helper got an undefined array passed in");
			return [];
		}

		if (typeof func !== 'function') {
			_logger.default.error(this, "map helper requires a callback function to map the list by");
			return list;
		}

		return list.map(function (item) {
			return func(item);
		});
	}

	exports.default = Ember.Helper.helper(map);
});