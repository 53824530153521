define('busy-app/controllers/currency', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		init: function init() {
			this._super();

			(0, _utils.loc)("USD - United States Dollars");
			(0, _utils.loc)("USD -  United States dollar");
			(0, _utils.loc)("AED -  United Arab Emirates dirham");
			(0, _utils.loc)("AFN -  Afghan afghani");
			(0, _utils.loc)("ALL -  Albanian lek");
			(0, _utils.loc)("AMD -  Armenian dram");
			(0, _utils.loc)("ANG -  Netherlands Antillean guilder");
			(0, _utils.loc)("AOA -  Angolan kwanza");
			(0, _utils.loc)("ARS -  Argentine peso");
			(0, _utils.loc)("AUD -  Australian dollar");
			(0, _utils.loc)("AWG -  Aruban florin");
			(0, _utils.loc)("AZN -  Azerbaijani manat");
			(0, _utils.loc)("BAM -  Bosnia and Herzegovina convertible mark");
			(0, _utils.loc)("BBD -  Barbados dollar");
			(0, _utils.loc)("BDT -  Bangladeshi taka");
			(0, _utils.loc)("BGN -  Bulgarian lev");
			(0, _utils.loc)("BHD -  Bahraini dinar");
			(0, _utils.loc)("BIF -  Burundian franc");
			(0, _utils.loc)("BMD -  Bermudian dollar");
			(0, _utils.loc)("BND -  Brunei dollar");
			(0, _utils.loc)("BOB -  Boliviano");
			(0, _utils.loc)("BRL -  Brazilian real");
			(0, _utils.loc)("BSD -  Bahamian dollar");
			(0, _utils.loc)("BTN -  Bhutanese ngultrum");
			(0, _utils.loc)("BWP -  Botswana pula");
			(0, _utils.loc)("BYR -  Belarusian ruble");
			(0, _utils.loc)("BZD -  Belize dollar");
			(0, _utils.loc)("CAD -  Canadian dollar");
			(0, _utils.loc)("CDF -  Congolese franc");
			(0, _utils.loc)("CHF -  Swiss franc");
			(0, _utils.loc)("CLP -  Chilean peso");
			(0, _utils.loc)("CNY -  Chinese yuan");
			(0, _utils.loc)("COP -  Colombian peso");
			(0, _utils.loc)("COU -  Unidad de Valor Real");
			(0, _utils.loc)("CRC -  Costa Rican colon");
			(0, _utils.loc)("CUC -  Cuban convertible peso");
			(0, _utils.loc)("CUP -  Cuban peso");
			(0, _utils.loc)("CVE -  Cape Verde escudo");
			(0, _utils.loc)("CZK -  Czech koruna");
			(0, _utils.loc)("DJF -  Djiboutian franc");
			(0, _utils.loc)("DKK -  Danish krone");
			(0, _utils.loc)("DOP -  Dominican peso");
			(0, _utils.loc)("DZD -  Algerian dinar");
			(0, _utils.loc)("EGP -  Egyptian pound");
			(0, _utils.loc)("ERN -  Eritrean nakfa");
			(0, _utils.loc)("ETB -  Ethiopian birr");
			(0, _utils.loc)("EUR -  Euro");
			(0, _utils.loc)("FJD -  Fiji dollar");
			(0, _utils.loc)("FKP -  Falkland Islands pound");
			(0, _utils.loc)("GBP -  Pound sterling");
			(0, _utils.loc)("GEL -  Georgian lari");
			(0, _utils.loc)("GHS -  Ghanaian cedi");
			(0, _utils.loc)("GIP -  Gibraltar pound");
			(0, _utils.loc)("GMD -  Gambian dalasi");
			(0, _utils.loc)("GNF -  Guinean franc");
			(0, _utils.loc)("GTQ -  Guatemalan quetzal");
			(0, _utils.loc)("GYD -  Guyanese dollar");
			(0, _utils.loc)("HKD -  Hong Kong dollar");
			(0, _utils.loc)("HNL -  Honduran lempira");
			(0, _utils.loc)("HRK -  Croatian kuna");
			(0, _utils.loc)("HTG -  Haitian gourde");
			(0, _utils.loc)("HUF -  Hungarian forint");
			(0, _utils.loc)("IDR -  Indonesian rupiah");
			(0, _utils.loc)("ILS -  Israeli new shekel");
			(0, _utils.loc)("INR -  Indian rupee");
			(0, _utils.loc)("IQD -  Iraqi dinar");
			(0, _utils.loc)("IRR -  Iranian rial");
			(0, _utils.loc)("ISK -  Icelandic króna");
			(0, _utils.loc)("JMD -  Jamaican dollar");
			(0, _utils.loc)("JOD -  Jordanian dinar");
			(0, _utils.loc)("JPY -  Japanese yen");
			(0, _utils.loc)("KES -  Kenyan shilling");
			(0, _utils.loc)("KGS -  Kyrgyzstani som");
			(0, _utils.loc)("KHR -  Cambodian riel");
			(0, _utils.loc)("KMF -  Comoro franc");
			(0, _utils.loc)("KPW -  North Korean won");
			(0, _utils.loc)("KRW -  South Korean won");
			(0, _utils.loc)("KWD -  Kuwaiti dinar");
			(0, _utils.loc)("KYD -  Cayman Islands dollar");
			(0, _utils.loc)("KZT -  Kazakhstani tenge");
			(0, _utils.loc)("LAK -  Lao kip");
			(0, _utils.loc)("LBP -  Lebanese pound");
			(0, _utils.loc)("LKR -  Sri Lankan rupee");
			(0, _utils.loc)("LRD -  Liberian dollar");
			(0, _utils.loc)("LSL -  Lesotho loti");
			(0, _utils.loc)("LTL -  Lithuanian litas");
			(0, _utils.loc)("LVL -  Latvian lats");
			(0, _utils.loc)("LYD -  Libyan dinar");
			(0, _utils.loc)("MAD -  Moroccan dirham");
			(0, _utils.loc)("MDL -  Moldovan leu");
			(0, _utils.loc)("MKD -  Macedonian denar");
			(0, _utils.loc)("MMK -  Myanma kyat");
			(0, _utils.loc)("MNT -  Mongolian tugrik");
			(0, _utils.loc)("MOP -  Macanese pataca");
			(0, _utils.loc)("MUR -  Mauritian rupee");
			(0, _utils.loc)("MVR -  Maldivian rufiyaa");
			(0, _utils.loc)("MWK -  Malawian kwacha");
			(0, _utils.loc)("MXN -  Mexican peso");
			(0, _utils.loc)("MYR -  Malaysian ringgit");
			(0, _utils.loc)("MZN -  Mozambican metical");
			(0, _utils.loc)("NAD -  Namibian dollar");
			(0, _utils.loc)("NGN -  Nigerian naira");
			(0, _utils.loc)("NIO -  Nicaraguan córdoba");
			(0, _utils.loc)("NOK -  Norwegian krone");
			(0, _utils.loc)("NPR -  Nepalese rupee");
			(0, _utils.loc)("NZD -  New Zealand dollar");
			(0, _utils.loc)("OMR -  Omani rial");
			(0, _utils.loc)("PAB -  Panamanian balboa");
			(0, _utils.loc)("PEN -  Peruvian nuevo sol");
			(0, _utils.loc)("PGK -  Papua New Guinean kina");
			(0, _utils.loc)("PHP -  Philippine peso");
			(0, _utils.loc)("PKR -  Pakistani rupee");
			(0, _utils.loc)("PLN -  Polish złoty");
			(0, _utils.loc)("PYG -  Paraguayan guaraní");
			(0, _utils.loc)("QAR -  Qatari riyal");
			(0, _utils.loc)("RON -  Romanian new leu");
			(0, _utils.loc)("RSD -  Serbian dinar");
			(0, _utils.loc)("RUB -  Russian rouble");
			(0, _utils.loc)("RWF -  Rwandan franc");
			(0, _utils.loc)("SAR -  Saudi riyal");
			(0, _utils.loc)("SBD -  Solomon Islands dollar");
			(0, _utils.loc)("SCR -  Seychelles rupee");
			(0, _utils.loc)("SDG -  Sudanese pound");
			(0, _utils.loc)("SEK -  Swedish krona/kronor");
			(0, _utils.loc)("SGD -  Singapore dollar");
			(0, _utils.loc)("SHP -  Saint Helena pound");
			(0, _utils.loc)("SLL -  Sierra Leonean leone");
			(0, _utils.loc)("SOS -  Somali shilling");
			(0, _utils.loc)("SRD -  Surinamese dollar");
			(0, _utils.loc)("SSP -  South Sudanese pound");
			(0, _utils.loc)("STD -  São Tomé and Príncipe dobra");
			(0, _utils.loc)("SYP -  Syrian pound");
			(0, _utils.loc)("SZL -  Swazi lilangeni");
			(0, _utils.loc)("THB -  Thai baht");
			(0, _utils.loc)("TJS -  Tajikistani somoni");
			(0, _utils.loc)("TMT -  Turkmenistani manat");
			(0, _utils.loc)("TND -  Tunisian dinar");
			(0, _utils.loc)("TOP -  Tongan paʻanga");
			(0, _utils.loc)("TRY -  Turkish lira");
			(0, _utils.loc)("TTD -  Trinidad and Tobago dollar");
			(0, _utils.loc)("TWD -  New Taiwan dollar");
			(0, _utils.loc)("TZS -  Tanzanian shilling");
			(0, _utils.loc)("UAH -  Ukrainian hryvnia");
			(0, _utils.loc)("UGX -  Ugandan shilling");
			(0, _utils.loc)("UYI -  Uruguay Peso en Unidades Indexadas");
			(0, _utils.loc)("UYU -  Uruguayan peso");
			(0, _utils.loc)("UZS -  Uzbekistan som");
			(0, _utils.loc)("VEF -  Venezuelan bolívar");
			(0, _utils.loc)("VND -  Vietnamese dong");
			(0, _utils.loc)("VUV -  Vanuatu vatu");
			(0, _utils.loc)("WST -  Samoan tala");
			(0, _utils.loc)("XAF -  CFA franc BEAC");
			(0, _utils.loc)("XBT -  Bitcoin");
			(0, _utils.loc)("XCD -  East Caribbean dollar");
			(0, _utils.loc)("XOF -  CFA franc BCEAO");
			(0, _utils.loc)("XPF -  CFP franc (Franc du Pacifique)");
			(0, _utils.loc)("YER -  Yemeni rial");
			(0, _utils.loc)("ZAR -  South African rand");
			(0, _utils.loc)("ZMW -  Zambian kwacha");
		}
	});
});