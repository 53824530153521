define('busy-app/components/file-input', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		/**
   * Component class override for class names on the container element
   *
   * @public
   * @property classNames
   * @type {array}
   */
		classNames: ['c-file-input', 'd-button', 'blue'],

		/**
   * Component class override sets the container tag to an `a` tag
   *
   * @private
   * @property tagName
   * @type {string}
   */
		tagName: 'a',

		/**
   * The title for the upload button
   *
   * @public
   * @property [title='Upload File']
   * @type {string}
   */
		title: (0, _utils.loc)('Upload File'),

		/**
   * Set to true to allow multiple file uploads
   *
   * @public
   * @property [multiple=false]
   * @type {boolean}
   */
		multiple: false,

		restrictedTypes: '',

		/**
   * Component Class event callback for onClick
   *
   * @private
   * @method click
   * @param evt {object} jquery event object
   * @return {boolean} true to allow propagation
   */
		click: function click(evt) {
			evt.stopPropagation();
			return true;
		},


		/**
   * Component Class event callback for onChange
   *
   * @private
   * @method change
   * @param evt {object} jquery event object
   */
		change: function change(evt) {
			var files = null;

			// most browsers support evt.target
			// but not IE!!
			if (!Ember.isNone(evt.target)) {
				files = evt.target.files;
			} else {
				// ie support for file uploads.
				files = evt.originalEvent.srcElement.files;
			}
			this.sendAction('onChange', files);
		}
	});
});