define('busy-app/components/busy-button-bar', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		classNames: ['busy-button-bar'],

		saveCloseText: (0, _utils.loc)('Save and Close'),
		saveText: (0, _utils.loc)('Save'),
		deleteText: (0, _utils.loc)('Delete'),
		cancelText: (0, _utils.loc)('Cancel'),
		closeText: (0, _utils.loc)('Close'),
		savingMessage: (0, _utils.loc)('Saving'),
		savedMessage: (0, _utils.loc)('Successfully Saved'),
		errorMessage: (0, _utils.loc)('An error occurred! Please try again later.'),

		showSaveClose: false,
		showSave: false,
		showDelete: false,
		showCancel: false,
		showClose: false,

		isDisabled: false,
		canClose: false,

		targetParent: null,

		showSaving: Ember.computed('showSaveClose', 'showSave', 'showDelete', function () {
			return this.get('showSaveClose') || this.get('showSave') || this.get('showDelete');
		}),

		init: function init() {
			if (!Ember.isNone(this.get('targetParent'))) {
				this.set('targetParent.busySave', this);
			}

			if (Ember.isNone(this.get('busySave'))) {
				this.set('busySave', {});
			}

			this._super.apply(this, arguments);
		},


		/**
   * Shows a saving message at the
   * bottom of the dialog
   *
   * @public
   * @method showSavingMessage
   * @param message {string} The save message text
   * @param timeout {number} The time in miliseconds to show the message for
   * @returns {EmberPromise}
   */
		showSavingMessage: function showSavingMessage(message, timeout) {
			if (!this.get('isDestroyed')) {
				this.set('isDisabled', true);
			}

			return this.get('busySave').savingMessage(message, timeout);
		},


		/**
   * Shows an error message at the
   * bottom of the dialog
   *
   * @public
   * @method showErrorMessage
   * @param message {string} The error message text
   * @param timeout {number} The time in miliseconds to show the message for
   * @returns {EmberPromise}
   */
		showErrorMessage: function showErrorMessage(message, timeout) {
			Ember.run.next(this, function () {
				if (!this.get('isDestroyed')) {
					this.set('isDisabled', false);
				}
			});

			return this.get('busySave').errorMessage(message, timeout);
		},


		/**
   * Shows a saved message at the
   * bottom of the dialog and fires the close dialog event.
   *
   * @public
   * @method showSavedMessage
   * @param message {string} The saved message text
   * @param timeout {number} The time in miliseconds to show the message for
   * @returns {EmberPromise}
   */
		showSavedMessage: function showSavedMessage(message, timeout) {
			var _this = this;

			if (!this.get('isDestroyed')) {
				this.set('isDisabled', false);

				return this.get('busySave').showSavedMessage(message, timeout).then(function () {
					if (_this.get('canClose')) {
						_this.set('canClose', false);
						_this.sendAction('onClose');
					}

					return true;
				});
			} else {
				return Ember.RSVP.resolve(true).then(function () {
					_this.sendAction('onClose');
					return true;
				});
			}
		},


		actions: {
			saveCloseAction: function saveCloseAction() {
				if (!this.get('isDisabled')) {
					this.set('canClose', true);
					this.sendAction('closeOnSave', this);
				}
			},
			saveAction: function saveAction() {
				if (!this.get('isDisabled')) {
					if (!this.get('showSaveClose')) {
						this.set('canClose', true);
					}

					this.sendAction('onSave', this);
				}
			},
			deleteAction: function deleteAction() {
				if (!this.get('isDisabled')) {
					this.set('canClose', true);
					this.sendAction('onDelete', this);
				}
			},
			closeAction: function closeAction() {
				if (!this.get('isDisabled')) {
					this.sendAction('onClose', this);
				}
			},
			cancelAction: function cancelAction() {
				if (!this.get('isDisabled')) {
					this.sendAction('onCancel', this);
				}
			}
		}
	});
});