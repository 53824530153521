define('busy-app/components/busy-select-create', ['exports', '@busy-web/components/components/bc-select', '@busy-web/utils'], function (exports, _bcSelect, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _bcSelect.default.extend({
		classNames: ['busy-select-create'],

		addPlaceholder: (0, _utils.loc)('Name'),
		canAddItem: false,
		canEditItems: false,
		isItemEdit: false,
		addNewValue: '',
		showEdit: true,
		defaultList: null,

		selectedItem: Ember.computed('model.{@each._selected,[]}', 'defaultList.{@each._selected,[]}', function () {
			var selected = null;
			if (!Ember.isNone(this.get('model'))) {
				selected = this.getSelected();
			}
			return selected;
		}),

		getSelected: function getSelected() {
			var defaults = this.get('defaultList');
			var selected = null;
			if ((typeof defaults === 'undefined' ? 'undefined' : _typeof(defaults)) === 'object' && typeof defaults.forEach === 'function') {
				defaults.forEach(function (item) {
					if (Ember.get(item, '_selected')) {
						selected = item;
					}
				});
			} else {
				Object.keys(defaults).forEach(function (key) {
					if (Ember.get(defaults[key], '_selected')) {
						selected = defaults[key];
					}
				});
			}

			if (selected === null) {
				selected = this._super();
			}
			return selected;
		},
		unselectAll: function unselectAll() {
			var items = this.get('defaultList');
			if ((typeof items === 'undefined' ? 'undefined' : _typeof(items)) === 'object' && typeof items.forEach === 'function') {
				items.forEach(function (item) {
					Ember.set(item, '_selected', false);
				});
			} else {
				Object.keys(items).forEach(function (key) {
					Ember.set(items[key], '_selected', false);
				});
			}
			this._super();
		},
		closeMenuAction: function closeMenuAction() {
			this.set('canAddItem', false);
			this.set('canEditItems', false);
			this.set('isItemEdit', false);

			this.get('model').forEach(function (item) {
				item.set('canEdit', false);
				item.rollbackAttributes();
			});
			this._super();
		},
		refreshValue: function refreshValue(item) {
			this.itemClicked(item);
		},


		actions: {
			addNewItem: function addNewItem() {
				this.set('canAddItem', !this.get('canAddItem'));
			},
			saveNewItem: function saveNewItem() {
				this.sendAction('onAddNew', this.get('addNewValue'));
				this.set('addNewValue', '');
				this.set('canAddItem', false);
			},
			cancelAddnewItem: function cancelAddnewItem() {
				this.set('canAddItem', false);
			},
			editItems: function editItems() {
				this.set('canAddItem', false);
				this.set('canEditItems', true);
			},
			editSelectItem: function editSelectItem(item) {
				item.set('canEdit', true);
				var index = this.get('model').indexOf(item);
				this.set('selectItemEdit', index);
				this.set('isItemEdit', true);
			},
			showDeleteDialog: function showDeleteDialog(item) {
				this.unbindClick();
				this.set('selectItemEdit', item);
				this.set('showDeleteDialog', true);
			},
			closeDeleteDialog: function closeDeleteDialog() {
				this.set('showDeleteDialog', false);
				this.bindClick();
			},
			deleteListItem: function deleteListItem() {
				var _this = this;

				return this.get('selectItemEdit').destroyRecord().then(function (item) {
					_this.sendAction('onDelete', item);
					_this.get('model').removeObject(item);
					_this.set('showDeleteDialog', false);
				});
			},
			saveItem: function saveItem() {
				var item = this.get('model').objectAt(this.get('selectItemEdit'));
				item.set('canEdit', false);
				item.save();

				this.set('selectItemEdit', null);
				this.set('isItemEdit', false);
				if (item.get('_selected')) {
					this.itemClicked(item);
				}
			},
			cancelItem: function cancelItem() {
				var item = this.get('model').objectAt(this.get('selectItemEdit'));
				item.rollbackAttributes();
				item.set('canEdit', false);

				this.set('selectItemEdit', null);
				this.set('isItemEdit', false);
			},
			doneEditItems: function doneEditItems() {
				this.set('canEditItems', false);
			},
			itemClickAction: function itemClickAction(item) {
				this.itemClicked(item);
			}
		}
	});
});