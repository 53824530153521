define('busy-app/models/organization-info', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		organizationId: _emberData.default.attr('string'),
		phone: _emberData.default.attr('string'),
		address1: _emberData.default.attr('string'),
		address2: _emberData.default.attr('string'),
		employeeMin: _emberData.default.attr('number'),
		employeeMax: _emberData.default.attr('number'),
		city: _emberData.default.attr('string'),
		county: _emberData.default.attr('string'),
		state: _emberData.default.attr('string'),
		postalCode: _emberData.default.attr('string'),
		currencyCode: _emberData.default.attr('string'),
		country: _emberData.default.attr('string'),
		longitude: _emberData.default.attr('number'),
		latitude: _emberData.default.attr('number'),
		locationRadius: _emberData.default.attr('number'),
		reminder: _emberData.default.attr('boolean'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		cityStateString: Ember.computed('city', 'state', 'postalCode', function () {
			var str = '';
			if (!Ember.isNone(this.get('city'))) {
				str += this.get('city') + ', ';
			}

			if (!Ember.isNone(this.get('state'))) {
				str += this.get('state') + ' ';
			}

			if (!Ember.isNone(this.get('postalCode'))) {
				str += this.get('postalCode');
			}
			return str.replace(/,? ?$/, '');
		})
	});
});