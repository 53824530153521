define('busy-app/controllers/report/time-entries/index', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),

		queryParams: ['start', 'end'],

		start: 0,
		end: 0,

		showTimeEntryEdit: false,
		editEntry: null,
		showTimeOffEdit: false,
		editTimeOffEntry: null,

		createdBy: Ember.computed('auth.member.id', function () {
			return this.get('auth.member.fullName') + ' @ ' + _utils.Time.date().format('h:mm A MM/DD/YYYY');
		}),

		actions: {
			openTimeEntryAction: function openTimeEntryAction(entry) {
				if (this.get('access').canTakeActionFor('manageTimeEntries', entry.get('model.member'))) {
					if (entry.get('isTimeEntry')) {
						this.set('editEntry', entry.get('model'));
						this.set('showTimeEntryEdit', true);
					} else {
						this.set('editTimeOffEntry', entry.get('model'));
						this.set('showTimeOffEdit', true);
					}
				}
			},


			/**
    * Shares a template with time-card report which allows for
    * navigating to the time-card full report but is disabled
    * in the time-entries report
    *
    */
			openTimeCard: function openTimeCard() {
				return false;
			},
			closeEditDialog: function closeEditDialog() {
				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);
			},
			itemSaved: function itemSaved() {
				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);
				Ember.getOwner(this).lookup('route:report/time-entries').refresh();
			},
			itemDeleted: function itemDeleted() {
				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);
				Ember.getOwner(this).lookup('route:report/time-entries').refresh();
			}
		}
	});
});