define('busy-app/components/max-images-box', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var MIN_CHILDREN = 3; /**
                        * @module Components
                        *
                        */
	exports.default = Ember.Component.extend({
		classNames: ['c-max-images-box'],

		eventEmitter: Ember.inject.service('event-emitter'),

		model: null,

		imagePath: '',
		altTextPath: '',

		__imageMeta: null,

		init: function init() {
			var _this = this;

			this._super();

			Ember.get(this, 'eventEmitter').on('dom', 'resize', function () {
				if (!Ember.get(_this, 'isDestroyed')) {
					if (_this.hasChangedWidth()) {
						setMaxImages(_this.$(), Ember.get(_this, '__imageMeta'));
					}
				}
			});
		},
		didInsertElement: function didInsertElement() {
			this._super();
			//this.setupImages();
		},
		didReceiveAttrs: function didReceiveAttrs() {
			this._super();
			//this.setupImages();
		},
		didRender: function didRender() {
			this._super();
			this.setupImages();
		},
		setupImages: function setupImages() {
			if (Ember.get(this, 'model.length')) {
				var meta = getImageMeta(this.$());
				Ember.set(this, '__imageMeta', meta);

				setMaxImages(this.$(), Ember.get(this, '__imageMeta'));
			}
		},
		hasChangedWidth: function hasChangedWidth() {
			if (this.$().length) {
				var lastWidth = Ember.get(this, '__containerWidth');
				var width = this.$().width();
				if (lastWidth !== width) {
					Ember.set(this, '__containerWidth', width);
					return true;
				}
			}
			return false;
		}
	});


	var setMaxImages = function setMaxImages(element, imageMeta) {
		if (!Ember.isNone(imageMeta) && imageMeta.length > 0) {
			var maxChildren = MIN_CHILDREN;
			if (!Ember.isNone(element)) {
				var width = element.width();

				// take the width of the container and subtract the negativeBounds
				// then divide that by the image adjustedWidth to get the total number
				// of images that will fit within the container. Subtract 1 from the total
				// to allow space for the hidden-total images element
				maxChildren = Math.floor((width - imageMeta.negativeBounds) / imageMeta.adjustedWidth) - 1;
			}
			if (isNaN(maxChildren)) {
				maxChildren = MIN_CHILDREN;
			}

			var images = element.children('.image');
			images.each(function (index, child) {
				if (index < maxChildren) {
					Ember.$(child).removeClass('hide');
				} else {
					Ember.$(child).addClass('hide');
				}
			});

			var hiddenElement = element.children('.hidden-total').first();
			hiddenElement.children().first().html('+' + (imageMeta.length - maxChildren));

			if (imageMeta.length <= maxChildren) {
				hiddenElement.addClass('hide');
			} else {
				hiddenElement.removeClass('hide');
			}

			fitText(hiddenElement);
		}
	};

	var getImageMeta = function getImageMeta(element) {
		// width is the raw width of the image
		var width = 0;

		// how many images are in the caontainer
		var length = 0;

		// adjusted width is the width plus margin left and right
		var adjustedWidth = 0;

		// negative bounds is any width that is not on all images
		// and will effect the images container width.
		// in this case if the first image has left margin and the
		// rest of the images have right margin
		var negativeBounds = 0;

		if (element && element.length) {
			var images = element.children('.image');
			length = images.length;

			if (length > 0) {
				var first = images.first();
				var middle = first.next();

				width = images.first().width();

				// margin is calculated of the second element (middle) if
				// there is more than 1 image
				var marginLeft = 0,
				    marginRight = 0;
				if (middle && middle.length) {
					marginLeft = parseInt(middle.css('margin-left'), 10) + parseInt(middle.css('padding-left'), 10);
					marginRight = parseInt(middle.css('margin-right'), 10) + parseInt(middle.css('padding-right'), 10);
				} else {
					marginLeft = parseInt(first.css('margin-left'), 10) + parseInt(first.css('padding-left'), 10);
					marginRight = parseInt(first.css('margin-right'), 10) + parseInt(first.css('padding-right'), 10);
				}

				// padding left and right are use to calculate the negativeBounds
				var paddingLeft = 0,
				    paddingRight = 0;

				// if margin left is 0 than check the first image to
				// see if it has margin left and use that
				// as the left bound of negativeBounds
				if (!marginLeft) {
					paddingLeft = parseInt(first.css('margin-left'), 10) + parseInt(middle.css('padding-left'), 10);
				}

				// if margin right is 0 than check the last image to
				// see if it has margin right and use that
				// as the right bound of negativeBounds
				if (!marginRight) {
					var last = length > 2 ? images.last() : middle;
					if (last && last.length) {
						paddingRight = parseInt(last.css('margin-right'), 10) + parseInt(middle.css('padding-right'), 10);
					}
				}

				// set adjustedWidth and negativeBounds
				adjustedWidth = width + (marginLeft || 0) + (marginRight || 0);
				negativeBounds = (paddingLeft || 0) + (paddingRight || 0);
			}
		}

		// return object meta data
		return { length: length, width: width, adjustedWidth: adjustedWidth, negativeBounds: negativeBounds };
	};

	/**
  * Fits the text size to the element and centers the text vertically
  *
  * @method fitText
  * @param element {Element} DOM tree node
  * @param propagate {boolean} allows fitText to traverse up to the parent if needed
  * @return {void}
  */
	var fitText = function fitText(element) {
		var propagate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

		if (element && element.length) {
			// get element height
			var height = element.height();

			// if element height is greater than 200px
			// assume the element height is not correct
			// and dont set any attrs
			if (height < 200) {
				// set the font size to 40% of the height
				var fontSize = Math.floor(height * 0.4);

				// update the style for the element
				element.css({
					'line-height': height + 1 + 'px',
					'font-size': fontSize + 'px'
				});
			} else if (propagate) {
				// if height was not found try the parent element
				// but only propagate up one element to find a proper
				// height
				fitText(element.parent(), false);
			}
		}
	};
});