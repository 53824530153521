define('busy-app/helpers/diff', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.diff = diff;
	function diff(params) {
		var args = params.slice(0);
		if (args.length === 1 && Array.isArray(args[0])) {
			args = args[0];
		}
		args = args.reverse();
		return args.reduce(function (a, b) {
			return b - a;
		}, 0);
	} /**
    * @module Helpers
    *
    */
	exports.default = Ember.Helper.helper(diff);
});