define('busy-app/components/busy-file-uploader', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/**
  * File object property helper for file uploader
  *
  * @class FileObject
  * @namespace Components.BusyFileUploader
  * @extends EmberObject
  */
	var FileObject = Ember.Object.extend(Ember.Evented, {
		_debugKey: 'FileUploader.FileObject',
		file: null,
		uploadPercent: 0,

		_percent: 0,

		/**
   * ember property method
   *
   * returns the css for the upload percentage in the progress bar
   *
   * @public
   * @method uploadPercent
   * @returns {string}
   */
		uploadProgressStyle: Ember.computed('uploadPercent', function () {
			return Ember.String.htmlSafe('width:' + this.get('uploadPercent') + '%');
		}),

		innerStyle: Ember.computed('uploadPercent', function () {
			if (this.get('_state') === 'inDOM') {
				return Ember.String.htmlSafe('width:' + this.$('.progress').width() + 'px;');
			}
			return Ember.String.htmlSafe('');
		}),

		uploadStart: function uploadStart() {
			this.trigger('start');
		},
		uploadComplete: function uploadComplete() {
			this.trigger('complete');
		},
		uploadProgress: function uploadProgress(e) {
			e.percent = e.loaded / e.total * 100;
			this.trigger('progress', e);
			this.progress(e.percent);
		},
		progress: function progress(percent) {
			if (percent !== undefined) {
				this.set('_percent', percent);
			}

			if (this.get('uploadPercent') === 100) {
				return;
			} else if (this.get('uploadPercent') <= this.get('_percent')) {
				this.set('uploadPercent', this.get('uploadPercent') + 1);
				Ember.run.next(this, function () {
					this.progress();
				});
			}
		}
	});

	/**
  * `Component/BusyFileUploader`
  *
  * @class BusyFileUploader
  * @namespace Components
  * @extends Component
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['busy-file-uploader'],

		title: (0, _utils.loc)('Choose File'),
		fileLabel: (0, _utils.loc)('File Name'),
		fileName: (0, _utils.loc)('Choose a file to upload.'),
		errorMessage: (0, _utils.loc)('There was an error. Please try again.'),

		files: null,
		fileUrl: null,

		multiple: false,

		restrictedTypes: null,

		/**
   * set this to false if the view doesn't show a preview
   *
   * @property
   * @type bool
   */
		showPreview: true,

		/**
   * set this to false if the view doesn't show the progress
   *
   * @property
   * @type bool
   */
		showProgress: true,

		/**
   * gets set by the uplader to true once the
   * preview image is ready to be shown
   *
   * @property
   * @type bool
   */
		isPreviewReady: false,

		isSaving: false,

		/**
   * the percent uploaded out of 100
   *
   * @property
   * @type float
   */
		uploadPercent: 0,

		_percent: 0,

		/**
   * the css for the upload percentage in the progress bar
   *
   * @private
   * @property uploadProgress
   * @type string
   */
		uploadProgress: Ember.computed('uploadPercent', function () {
			return Ember.String.htmlSafe('width:' + this.get('uploadPercent') + '%;');
		}),

		/**
   * the css for the inner upload percent progress bar.
   *
   * @private
   * @property innerStyle
   * @type string
   */
		innerStyle: Ember.computed('uploadPercent', function () {
			if (this.get('_state') === 'inDOM') {
				return Ember.String.htmlSafe('width:' + this.$('.progress').width() + 'px;');
			}
			return Ember.String.htmlSafe('');
		}),

		/**
   * sets the progress float from the uploaders progress event
   *
   * @public
   * @param evt {object} event callback object
   */
		progress: function progress(evt) {
			this._progress(evt.percent);
		},
		_progress: function _progress(percent) {
			if (percent !== undefined) {
				this.set('_percent', percent);
			} else {
				this.set('_percent', 100);
			}

			if (this.get('uploadPercent') === 100) {
				return;
			} else if (this.get('uploadPercent') <= this.get('_percent')) {
				if (!this.get('isDestroyed')) {
					this.set('uploadPercent', this.get('uploadPercent') + 1);
					Ember.run.next(this, function () {
						this._progress();
					});
				}
			}
		},


		/**
   * remove any data and reset the upload view
   *
   * @public
   */
		cleanup: function cleanup() {
			if (!this.get('isDestroyed')) {
				this.set('files', null);
				this.set('fileUrl', null);
				this.set('isPreviewReady', false);

				Ember.run.later(this, function () {
					if (!this.get('isDestroyed')) {
						this.set('isSaving', false);

						Ember.run.later(this, function () {
							if (!this.get('isDestroyed')) {
								this.set('uploadPercent', 0);
								this.set('_percent', 0);
							}
						}, 1000);
					}
				}, 500);
			}
		},

		/**
   * renders the file for a preview
   *
   * @public
   * @param files {array}
   */
		renderFiles: function renderFiles(file) {
			var _this = this;

			var showPreview = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			return this.readFile(file).then(function (fileInfo) {
				var fileUrl = Ember.get(fileInfo, 'target.result');
				if (showPreview) {
					_this.set('fileUrl', fileUrl);
					_this.set('isPreviewReady', true);
				}
				return fileUrl;
			});
		},


		/**
   * reads file data
   *
   * @public
   * @param {file}
   * @returns {promise} ember promise callback
   */
		readFile: function readFile(file) {
			return new Ember.RSVP.Promise(function (resolve) {
				var reader = new window.FileReader();
				reader.onload = function (data) {
					return Ember.run(null, resolve, data);
				};
				reader.readAsDataURL(file);
			});
		},
		sendObjects: function sendObjects(files, urls) {
			var _this2 = this,
			    _arguments = arguments;

			Object.keys(files).forEach(function (key) {
				var file = Ember.get(files, key);
				var url = Ember.get(urls, key);
				var obj = FileObject.create({ file: file });

				obj.on('start', function () {
					return _this2.set('isSaving', true);
				});
				obj.on('progress', function () {
					return _this2.progress.apply(_this2, _arguments);
				});
				obj.on('complete', function () {
					return _this2.cleanup();
				});

				_this2.sendAction('onChange', obj, url);
			});
		},


		actions: {
			removeFile: function removeFile() {
				this.cleanup();
			},
			uploadFile: function uploadFile(files) {
				var _this3 = this;

				this.set('files', files);
				this.set('showError', false);

				var showError = false;
				var typeArray = [];

				if (!Ember.isNone(this.get('restrictedTypes'))) {
					var restrictedTypesStripped = this.get('restrictedTypes').replace(/ /g, '');
					typeArray = restrictedTypesStripped.split(',');
				}

				if (typeArray.length > 0) {
					Ember.$.each(files, function (key, val) {
						if (!Ember.isNone(val.type) && !typeArray.includes(val.type)) {
							showError = true;
						}
					});
				}

				if (showError) {
					this.set('showError', true);
				} else {
					if (this.get('showPreview')) {
						this.renderFiles(files[0], true).then(function (url) {
							_this3.sendObjects(files, url);
						});
					} else {
						var promise = [];
						Object.keys(files).forEach(function (key) {
							var file = Ember.get(files, key);
							promise.push(_this3.renderFiles(file, false));
						});

						Ember.RSVP.all(promise).then(function (urls) {
							_this3.sendObjects(files, urls);
						});
					}
				}
			}
		}
	});
});