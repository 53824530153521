define('busy-app/controllers/employees/locations', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['ids', 'from'],

		selectedEmployees: null,
		id: null,
		member_id: null,
		filter: null,
		groups: null,
		from: null,

		init: function init() {
			this._super();
			this.setGroups();
		},


		setGroups: Ember.observer('model.timeEntryLocations.@each.id', function () {
			if (!Ember.isNone(this.get('model'))) {
				var groups = [Ember.Object.create({ key: 'all-locations', value: (0, _utils.loc)('All Locations') })];
				if (!Ember.isNone(this.get('model.startLocationId'))) {
					groups.pushObject(Ember.Object.create({ key: 'start-location', value: (0, _utils.loc)('Clock in'), _selected: 'start-location' === this.get('filter') }));
				}

				if (!Ember.isNone(this.get('model.endLocationId'))) {
					groups.pushObject(Ember.Object.create({ key: 'end-location', value: (0, _utils.loc)('Clock out'), _selected: 'end-location' === this.get('filter') }));
				}
				this.set('groups', groups);
			}
		}),

		setDefaultGroup: Ember.observer('group.@each', function () {
			this.set('group', this.get('groups').objectAt(0));
		}),

		actions: {
			setSelectedGroup: function setSelectedGroup(view, group) {
				this.set('selectedGroup', group);
			},
			filterGroup: function filterGroup(group) {
				var filter = group.get('key') === 'all-locations' ? null : group.get('key');

				this.set('filter', filter);

				this.transitionToRoute('employees.employee-locations', {
					queryParams: {
						id: this.get('id'),
						member_id: this.get('member_id'),
						filter: filter
					}
				});
			},
			backAction: function backAction() {
				if (!Ember.isNone(this.get('from'))) {
					this.transitionToRoute(this.get('from'));
				} else {
					window.history.back();
				}
			}
		}
	});
});