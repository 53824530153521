define('busy-app/helpers/bg-image', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.bgImage = bgImage;
	function bgImage(params /*, hash*/) {
		// set placeholder string
		var string = '/images/imageplaceholder.png';

		// if params[0] is not null or undefined then set that to the string
		if (!Ember.isNone(params[0])) {
			string = params[0];
		}

		// return the safestring css background image.
		return Ember.String.htmlSafe('background-image:url(' + string + ');background-repeat:no-repeat;background-size:cover;background-position:center;');
	}

	exports.default = Ember.Helper.helper(bgImage);
});