define('busy-app/models/time-off', ['exports', 'ember-data', '@busy-web/utils', 'busy-app/utils/time-entry-validator'], function (exports, _emberData, _utils, _timeEntryValidator) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		analytics: Ember.inject.service('analytics'),

		memberId: _emberData.default.attr('id', { sortable: true, sortBy: ['member.firstName', 'member.lastName'], sortChildren: ['startTime'], reverseSortChildren: true }),
		dateStamp: _emberData.default.attr('date', { sortable: true, reverseSort: true, sortBy: ['dateStamp'], sortChildren: ['member.firstName', 'member.lastName'] }),
		seconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		description: _emberData.default.attr('string'),
		paid: _emberData.default.attr('boolean'),
		type: _emberData.default.attr('number'),
		submitterMemberId: _emberData.default.attr('id'),
		createdOn: _emberData.default.attr('number'),
		createdOnOffset: _emberData.default.attr('number'),
		createdOnDaylightSavingTime: _emberData.default.attr('boolean'),
		updatedOn: _emberData.default.attr('date'),
		deletedOn: _emberData.default.attr('date'),
		submittedOn: _emberData.default.attr('date'),

		member: _emberData.default.belongsTo('member'),

		isTimeOff: Ember.computed(function () {
			return true;
		}).readOnly().meta({ aggregateType: 'bool' }),

		startTime: Ember.computed('dateStamp', function () {
			return _utils.Time.date(this.get('dateStamp')).startOf('day').unix();
		}).meta({ sortable: true, reverseSort: true, sortBy: ['startTime'], sortChildren: ['member.firstName', 'member.lastName'] }),

		endTime: Ember.computed('startTime', 'seconds', function () {
			return this.get('startTime') + this.get('seconds');
		}),

		offset: Ember.computed('createdOnOffset', function () {
			return this.get('createdOnOffset');
		}),

		dateString: Ember.computed('dateStamp', function () {
			var date = '';
			if (!Ember.isNone(this.get('dateStamp'))) {
				date = _utils.Time.dateFormat(_utils.Time.date(this.get('dateStamp')).startOf('day'), 'ddd. MMM Do, YYYY');
			}
			return date;
		}),

		submittedByMember: Ember.computed('submitterMemberId', function () {
			var id = this.get('submitterMemberId');
			if (!Ember.isNone(id)) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.findRecord('member', id)
				});
			}
		}),

		submittedBy: Ember.computed('submittedByMember.id', function () {
			if (!Ember.isNone(this.get('submittedByMember.id'))) {
				return this.get('submittedByMember.fullName');
			}
			return '';
		}),

		dateStringReport: Ember.computed('dateStamp', function () {
			var date = '';
			if (!Ember.isNone(this.get('dateStamp'))) {
				date = _utils.Time.timeFormat(this.get('dateStamp'), 'M/D/YY');
			}
			return date;
		}),

		submittedOnString: Ember.computed('submittedOn', function () {
			var date = '';
			if (!Ember.isNone(this.get('submittedOn'))) {
				date = _utils.Time.timeFormat(this.get('submittedOn') + this.get('createdOnOffset'), 'MMMM D, YYYY @h:mm A');
			}
			return date;
		}),

		typeStringShort: Ember.computed('type', function () {
			var typeInt = this.get('type');
			var typeString = '';
			if (!Ember.isNone(typeInt)) {
				if (typeInt === 10) {
					typeString = (0, _utils.loc)('HOL');
				} else if (typeInt === 20) {
					typeString = (0, _utils.loc)('PTO');
				} else if (typeInt === 30) {
					typeString = (0, _utils.loc)('SIC');
				} else if (typeInt === 40) {
					typeString = (0, _utils.loc)('VAC');
				} else if (typeInt === 50) {
					typeString = (0, _utils.loc)('OTH');
				}
			}
			return typeString;
		}),

		getType: Ember.computed('type', function () {
			var typeInt = this.get('type');
			var typeString = '';
			if (!Ember.isNone(typeInt)) {
				if (typeInt === 10) {
					typeString = (0, _utils.loc)('Holiday');
				} else if (typeInt === 20) {
					typeString = (0, _utils.loc)('Personal');
				} else if (typeInt === 30) {
					typeString = (0, _utils.loc)('Sick');
				} else if (typeInt === 40) {
					typeString = (0, _utils.loc)('Vacation');
				} else if (typeInt === 50) {
					typeString = (0, _utils.loc)('Other');
				}
			}
			return typeString;
		}),

		paidString: Ember.computed('paid', function () {
			var isPaid = null;
			if (!Ember.isNone(this.get('paid'))) {
				if (this.get('paid')) {
					isPaid = (0, _utils.loc)("Paid");
				} else {
					isPaid = (0, _utils.loc)("Unpaid");
				}
			}
			return isPaid;
		}),

		totalString: Ember.computed('seconds', function () {
			var hours = 0;
			var minutes = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				hours = Math.floor(this.get('seconds') / 60 / 60);
				minutes = Math.floor((this.get('seconds') - hours * 60 * 60) / 60);
			}
			return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
		}),

		onCreate: Ember.on('didCreate', function () {
			this.set('_isValid', false);
		}),

		onLoad: Ember.on('didLoad', function () {
			if (!this.get('hasDirtyAttributes')) {
				this.set('_isValid', true);
			}
		}),

		onUpdate: Ember.on('didUpdate', function () {
			if (!this.get('hasDirtyAttributes')) {
				this.set('_isValid', true);
			}
		}),

		onRollback: Ember.on('rolledBack', function () {
			if (!this.get('hasDirtyAttributes')) {
				this.set('_isValid', true);
			}
		}),

		onChange: Ember.observer('hasDirtyAttributes', function () {
			if (this.get('hasDirtyAttributes') && this.get('_isValid')) {
				this.set('_isValid', false);
			}
		}),

		validate: function validate(validateOverlap, isMulti) {
			var _this = this;

			if (!this.get('_isValid')) {
				return _timeEntryValidator.default.timeOff(this, validateOverlap, isMulti).then(function (result) {
					if (result.isValid) {
						_this.set('_isValid', true);
						return true;
					} else {
						return Ember.RSVP.reject(result);
					}
				});
			} else {
				return Ember.RSVP.resolve(true);
			}
		},


		segmentStartTime: Ember.computed.alias('startTime'),
		segmentEndTime: Ember.computed.alias('endTime'),

		segmentRegularSeconds: Ember.computed('seconds', function () {
			return this.get('seconds') || 0;
		}).meta({ aggregateType: 'sum' }),

		segmentTotalSeconds: Ember.computed('segmentRegularSeconds', function () {
			return this.get('segmentRegularSeconds');
		}).meta({ aggregateType: 'sum' }),

		isFuture: Ember.computed('dateStamp', function () {
			var date = _utils.Time.timestamp();
			if (this.get('dateStamp') > date) {
				return true;
			} else {
				return false;
			}
		}),

		save: function save() {
			var _this2 = this;

			var forceSave = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
			var isMulti = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			var validateOverlap = forceSave === true ? false : true;
			if (this.get('isDeleted')) {
				return this._save();
			} else {
				return this.validate(validateOverlap, isMulti).then(function () {
					return _this2._save();
				});
			}
		},
		_save: function _save() {
			var _this3 = this;

			return this._internalModel.save().then(function () {
				// submit happy oficer event
				var source = 'from_' + _this3.get('analytics').generateTitle(window.location.pathname);
				_this3.get('analytics').addEvent(55, { navSource: source, targetMemberIds: [_this3.get('memberId')], deleted: !Ember.isNone(_this3.get('deletedOn')) });

				return _this3;
			});
		}
	});
});