define('busy-app/components/lib/display-value', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Component.extend({
		model: null,

		key: null,

		value: Ember.computed('model.id', 'key', function () {
			(false && !(!Ember.isNone(this.get('model')) && _typeof(this.get('model')) === 'object') && Ember.assert("You must provide a model of type object to display-value", !Ember.isNone(this.get('model')) && _typeof(this.get('model')) === 'object'));
			(false && !(!Ember.isNone(this.get('key')) && typeof this.get('key') === 'string') && Ember.assert("You must provide a key of type String to display-value", !Ember.isNone(this.get('key')) && typeof this.get('key') === 'string'));


			return Ember.get(this.get('model'), this.get('key'));
		}),

		click: function click() {
			this.sendAction('onClick');
		}
	});
});