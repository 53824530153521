define('busy-app/components/subscriptions/subscription-manager', ['exports', '@busy-web/utils', 'busy-app/utils/chargify', 'busy-app/utils/logger'], function (exports, _utils, _chargify, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-subscriptions-subscription-manager'],

		auth: Ember.inject.service('auth'),
		subscription: Ember.inject.service('subscription'),

		slideMenuState: false,
		slidePaymentActive: false,
		showAnnualPlanDialog: false,

		couponCode: '',
		couponValid: false,

		currentPlan: '',

		firstName: '',
		lastName: '',
		cardNumber: '',
		expMonth: '',
		expYear: '',
		cvvCode: '',

		isLoading: false,

		errorMessage: '',
		successMessage: '',
		noChangeMessage: '',

		init: function init() {
			this._super();
			this.setMenus();
			this.setPlan();
			this.setCardOwner();
		},


		setPlan: Ember.observer('subscription.subscriptionStatus.id', function () {
			if (!this.get('isDestroyed')) {
				if (!Ember.isNone(this.get('subscription.subscriptionStatus.id'))) {
					this.set('currentPlan', this.get('subscription.subscriptionStatus.planType'));
				}

				if (!Ember.isEmpty(this.get('subscription.subscriptionStatus.cardLastFour'))) {
					this.set('cardNumberPlaceholder', '************' + this.get('subscription.subscriptionStatus.cardLastFour'));
				}
			}
		}),

		setCardOwner: Ember.observer('auth.owner.id', function () {
			if (!this.get('isDestroyed')) {
				if (!Ember.isNone(this.get('auth.owner.id'))) {
					this.set('firstName', this.get('auth.owner.firstName') || '');
					this.set('lastName', this.get('auth.owner.lastName') || '');
				}
			}
		}),

		costPerUser: Ember.computed('subscription.costPerActiveUser', function () {
			var cost = this.get('subscription.costPerActiveUser');
			return cost.toFixed(2);
		}),

		billingPeriodStarts: Ember.computed('subscription.subscriptionStatus.trialEndedAt', function () {
			return _utils.Time.dateFormat(_utils.Time.date(this.get('subscription.subscriptionStatus.trialEndedAt')).local(), 'MMM DD, YYYY');
		}),

		firstPayment: Ember.computed('subscription.subscriptionStatus.trialEndedAt', function () {
			return _utils.Time.dateFormat(_utils.Time.date(this.get('subscription.subscriptionStatus.trialEndedAt')).local().add(1, 'months'), 'MMM DD, YYYY');
		}),

		updatePaymentUrl: Ember.computed('subscription.subscriptionStatus.subscriptionId', function () {
			return _chargify.default.generateUrl('update_payment', this.get('subscription.subscriptionStatus.subscriptionId'));
		}),

		planChoices: Ember.computed('currentPlan', 'subscription.subscriptionStatus.planType', function () {
			var _this = this;

			if (Ember.isEmpty(this.get('currentPlan'))) {
				this.set('currentPlan', this.get('subscription.subscriptionStatus.planType'));
			}

			var planChoices = this.get('subscription.availablePlans');

			// cannot select free if the user in in the free trial, or if they have not submitted payment information
			if (!this.get('subscription.isFreeAccount') && (!this.get('hasCard') || this.get('subscription.isFreeTrial'))) {
				planChoices = planChoices.reject(function (plan) {
					return plan.get('key') === 'free';
				});
			}

			// don't let user "dowgrade" from an Annual account to a Monthly account
			if (this.get('subscription.subscriptionStatus.isAnnualPlan')) {
				planChoices = planChoices.reject(function (plan) {
					return plan.get('key') === 'pro';
				});
			}

			return planChoices.map(function (planOption) {
				return Ember.Object.create({
					key: planOption.get('key'),
					value: planOption.get('name'),
					_selected: _this.get('currentPlan') === planOption.get('key')
				});
			});
		}),

		currentPlanDetails: Ember.computed('currentPlan', 'subscription.availablePlans', function () {
			var _this2 = this;

			if (!Ember.isNone(this.get('currentPlan')) && !Ember.isNone(this.get('subscription.availablePlans'))) {
				return this.get('subscription.availablePlans').find(function (plan) {
					return plan.get('key') === _this2.get('currentPlan');
				});
			}
		}),

		isFreePlan: Ember.computed('currentPlan', function () {
			return this.get('currentPlan') === 'free';
		}),

		hasCard: Ember.computed('subscription.subscriptionStatus.hasCard', function () {
			return this.get('subscription.subscriptionStatus.hasCard');
		}),

		setMenus: function setMenus() {
			var curMonth = _utils.Time.date().month();
			var curYear = _utils.Time.date().year();
			var months = [];
			var years = [];
			for (var i = 1; i <= 12; i++) {
				var year = _utils.Time.date().add(i - 1, 'years').year();
				var month = i < 10 ? '0' + i : '' + i;
				years.pushObject(Ember.Object.create({ key: year, value: year, _selected: year === curYear }));
				months.pushObject(Ember.Object.create({ key: month, value: month, _selected: i - 1 === curMonth }));
			}

			this.set('expMonth', months.get('firstObject.value'));
			this.set('expYear', years.get('firstObject.value'));

			this.set('yearMenu', years);
			this.set('monthMenu', months);
		},
		resetSlider: function resetSlider() {
			this.resetErrors();
			this.set('slideMenuState', false);
			this.set('showUpdatePayment', false);
			this.set('isPaymentLoading', false);
			this.set('nextStep', false);
		},
		resetErrors: function resetErrors() {
			this.showError('');
			this.showSuccess('', 0);
			this.showNoChange('', 0);
			this.set('isNameInvalid', false);
			this.set('isCardInvalid', false);
			this.set('isExpMonthInvalid', false);
			this.set('isExpYearInvalid', false);
			this.set('isCvvInvalid', false);
		},


		fNameInvalid: (0, _utils.loc)('first Name is required'),
		isFNameInvalid: false,

		lNameInvalid: (0, _utils.loc)('Last name is required'),
		isLNameInvalid: false,

		cardNumberInvalid: (0, _utils.loc)("Card # is invalid"),
		isCardInvalid: false,

		expMonthInvalid: (0, _utils.loc)('Expiration month is required'),
		isExpMonthInvalid: false,

		expYearInvalid: (0, _utils.loc)('Expiration year is required'),
		isExpYearInvalid: false,

		cvvInvalid: (0, _utils.loc)('CVV code is required'),
		isCvvInvalid: false,

		validateCard: function validateCard(firstName, lastName, cardNumber, expMonth, expYear, cvv) {
			var valid = true;
			this.set('cardNumberInvalid', (0, _utils.loc)('Card # is invalid'));
			this.set('expMonthInvalid', (0, _utils.loc)('Expiration month is required'));

			this.set('isCardInvalid', false);
			this.set('isFNameInvalid', false);
			this.set('isLNameInvalid', false);
			this.set('isExpMonthInvalid', false);
			this.set('isExpYearInvalid', false);
			this.set('isCardInvalid', false);

			if (Ember.isEmpty(firstName)) {
				valid = false;
				this.set('isFNameInvalid', true);
			}

			if (Ember.isEmpty(lastName)) {
				valid = false;
				this.set('isLNameInvalid', true);
			}

			if (Ember.isEmpty(cardNumber)) {
				valid = false;
				this.set('cardNumberInvalid', (0, _utils.loc)('Card # is required'));
				this.set('isCardInvalid', true);
			} else if (!/^(([3]\d{14})|([456]\d{15}))$/.test(cardNumber)) {
				valid = false;
				this.set('isCardInvalid', true);
			}

			if (Ember.isEmpty(expYear)) {
				valid = false;
				this.set('isExpYearInvalid', true);
			}

			var exp = _utils.Time.date().local().startOf('month').year(expYear).month(expMonth - 1);
			if (Ember.isEmpty(expMonth)) {
				valid = false;
				this.set('isExpMonthInvalid', true);
			} else if (_utils.Time.date().isAfter(exp)) {
				this.set('isExpMonthInvalid', true);
				this.set('expMonthInvalid', (0, _utils.loc)('Card is expired'));
			}

			if (Ember.isEmpty(cvv)) {
				valid = false;
				this.set('isCvvInvalid', true);
			}

			return valid;
		},


		/**
   * Updates the credit card if the cardNumber property is set
   *
   * returns a truthy promise on success or if the org already has
   * a valid card on file.
   *
   * returns a non truthy value if the org does not have a card on file and the card info is not valid
   *
   * @public
   * @method updateCard
   * @return {EmberPromise}
   */
		updateCard: function updateCard() {
			var _this3 = this;

			// if a card is not set on the subscription status or a new card number is set then try to update the card
			if (!Ember.isEmpty(this.get('cardNumber').trim())) {
				var firstName = this.get('firstName').trim();
				var lastName = this.get('lastName').trim();
				var cardNumber = this.get('cardNumber').trim();
				var expMonth = parseInt(this.get('expMonth'), 10);
				var expYear = parseInt(this.get('expYear'), 10);
				var cvv = this.get('cvvCode').trim();

				// make sure the card info is valid
				if (this.validateCard(firstName, lastName, cardNumber, expMonth, expYear, cvv)) {
					// update card returns a true or false on success if the credit card was submitted
					return this.get('subscription').updateCard(firstName, lastName, cardNumber, expMonth, expYear, cvv).then(function () {
						return true;
					}).catch(function (err) {
						var errStr = (0, _utils.loc)('There was an error updating your subscription.');
						if (err.code) {
							if (err.code[0] === 597) {
								errStr = (0, _utils.loc)("Card number is invalid.");
							}
						}

						_this3.showError(errStr);
						return Ember.RSVP.resolve(false);
					});
				} else {
					// validation failed so return a falsy promise
					return Ember.RSVP.resolve(false);
				}
			} else {
				// return hasCard so accounts with card info are still valid or return !hasTrialed for free trial customers
				return Ember.RSVP.resolve(false);
			}
		},


		/**
   * set the coupon code for the subscription.
   *
   * coupon codes must be valid, to validate a coupon use getCouponCode.
   *
   * @public
   * @method setCouponCode
   */
		setCouponCode: function setCouponCode() {
			var _this4 = this;

			var code = this.get('couponCode').trim();
			// coupon must be set to non empty string that does not match a current couponCode and it must be a valid coupon set by chargify.
			if (!Ember.isEmpty(code) && this.get('subscription.subscriptionStatus.couponCode') !== code) {
				if (this.get('couponValid') === true) {
					// save the coupon
					return this.get('subscription').setCouponCode(code);
				} else {
					// validate and save coupon
					return this.getCouponCode(code).then(function (isValid) {
						if (isValid) {
							return _this4.get('subscription').setCouponCode(code).then(function () {
								return true;
							});
						}
						return false;
					});
				}
			} else {
				return Ember.RSVP.resolve(false);
			}
		},


		/**
   * validates a coupon code so it can be sent to the api by setting this.couponValid to true if valid
   * false if invalid.
   *
   * sets subscription error message when the coupon is invalid
   * sets couponName to the description of the coupon when the coupon is valid
   *
   * returns an empty promise to notify the caller when `this.couponValid` can be checked
   *
   * @public
   * @method getCouponCode
   * @param code {string}
   * @return {EmberPromise}
   */
		getCouponCode: function getCouponCode(code) {
			var _this5 = this;

			this.set('couponValid', false);
			this.showError('');
			this.set('couponName', '');

			// return invalid coupon state with out setting subscription error
			// then the coupon is an empty string
			if (!Ember.isEmpty(code)) {
				return this.get('subscription').getCouponCode(code).then(function (res) {
					if (!_this5.get('isDestroyed')) {
						// make sure view is not destroyed
						if (res) {
							// coupon is valid
							_this5.set('couponValid', true);
							_this5.set('couponName', res.name);
							return true;
						} else {
							// coupon is invalid
							_this5.showError((0, _utils.loc)("The coupon code is invalid."));
							return false;
						}
					}
				});
			} else {
				return Ember.RSVP.resolve(false);
			}
		},


		/**
   * upgrade subscription can only be set after the trial if the org is sending in credit card info
   * or already has credit card info.
   *
   * before the trial credit card info is not required
   *
   * @public
   * @method updateSubscription
   * @param hasCard {boolean}
   * @return {EmberPromise}
   */
		upgradeSubscription: function upgradeSubscription(hasCard) {
			var _this6 = this;

			// make sure subscriptionStatus is not already pro
			if (this.get('subscription.subscriptionStatus.planType') !== 'pro') {
				if (hasCard || this.get('subscription.subscriptionStatus.hasCard') || !this.get('subscription.subscriptionStatus.hasTrialed')) {
					return this.get('subscription').setSubscriptionStatusPro().then(function () {
						return true;
					}).catch(function (err) {
						_logger.default.error('<Components::Sliders::SubscriptionManager.updateSubscription> ', err);
						_this6.showError((0, _utils.loc)('There was an error updating your subscription.'));
						return Ember.RSVP.resolve(false);
					});
				}
			}
			return Ember.RSVP.resolve(false);
		},


		/**
   * downgrade subscription for an org that is currently on a pro account and is going to a free account
   *
   * @public
   * @method downgradeSubscription
   * @return {EmberPromise}
   */
		downgradeSubscription: function downgradeSubscription() {
			var _this7 = this;

			// make sure subscriptionStatus is not already free
			if (this.get('subscription.subscriptionStatus.planType') !== 'free') {
				// call downgrade method on subscription service
				return this.get('subscription').setSubscriptionStatusFree().then(function () {
					return true;
				}).catch(function (err) {
					_logger.default.error('<Components::Sliders::SubscriptionManager.downgradeSubscription> ', err);
					_this7.showError((0, _utils.loc)('There was an error updating your subscription.'));
					return Ember.RSVP.resolve(false);
				});
			} else {
				return Ember.RSVP.resolve(false);
			}
		},
		toggleLoading: function toggleLoading() {
			if (!this.get('isDestroyed')) {
				if (this.get('isLoading') === true) {
					Ember.run.later(this, function () {
						this.set('isLoading', false);
					}, 300);
				} else {
					this.set('isLoading', true);
				}
			}
		},
		showSuccess: function showSuccess(message) {
			var timer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3000;

			if (!this.get('isDestroyed')) {
				this.set('successMessage', message);
				Ember.run.later(this, function () {
					this.set('successMessage', '');
				}, timer);
			}
		},
		showNoChange: function showNoChange(message) {
			var timer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3000;

			if (!this.get('isDestroyed')) {
				this.set('noChangeMessage', message);
				Ember.run.later(this, function () {
					this.set('noChangeMessage', '');
				}, timer);
			}
		},
		showError: function showError(message) {
			if (!this.get('isDestroyed')) {
				this.set('errorMessage', message);
			}
		},


		actions: {
			checkCoupon: function checkCoupon(coupon) {
				// get and validate coupon
				this.getCouponCode(coupon.trim());
			},
			changePlan: function changePlan(evt) {
				var val = void 0;
				if (evt.target) {
					val = evt.target.value;
				} else {
					val = evt.srcElement.value;
				}

				if (val === 'pro-annual') {
					this.set('showAnnualPlanDialog', true);
					this.send('closeAction');

					return false;
				} else {
					this.set('currentPlan', val);
				}
			},
			selectExp: function selectExp(type, evt) {
				var val = void 0;
				if (evt.target) {
					val = evt.target.value;
				} else {
					val = evt.srcElement.value;
				}
				this.set(type, val);
			},
			closeAction: function closeAction() {
				this.resetSlider();
				this.sendAction('onClose');
			},
			toggleUpdatePayment: function toggleUpdatePayment(isOpen) {
				this.set('slidePaymentActive', isOpen);
			},
			updateSubscription: function updateSubscription() {
				var _this8 = this;

				// reset subscription error message
				this.showError('');

				// show loading animation
				this.toggleLoading();

				if (this.get('isFreePlan')) {
					// free plan is selected
					this.downgradeSubscription().finally(function () {
						return _this8.toggleLoading();
					});
				} else {
					// pro plan is selected
					// set coupon code
					this.setCouponCode().then(function (couponChanged) {
						// update credit card information
						return _this8.updateCard().then(function (hasCard) {
							// submit the subscription upgrade if a card has has passed
							// one of the following pre checks:
							//  - org has a valid card on file
							//  - org is setting up a new card
							//  - org is allowed to start free trial
							return _this8.upgradeSubscription(hasCard).then(function (upgraded) {
								if (upgraded) {
									_this8.showSuccess((0, _utils.loc)('Subscription has been upgraded.'));
								} else if (hasCard && couponChanged) {
									_this8.showSuccess((0, _utils.loc)('Payment Info and Coupon Code have been updated.'));
								} else if (hasCard) {
									_this8.showSuccess((0, _utils.loc)('Payment Info has been updated.'));
								} else if (couponChanged) {
									_this8.showSuccess((0, _utils.loc)('Coupon Code has been updated.'));
								} else if (Ember.isEmpty(_this8.get('errorMessage'))) {
									_this8.showNoChange('No changes have been made.');
								}
							});
						});
					}).finally(function () {
						_this8.toggleLoading();
					});
				}
			},
			learnMore: function learnMore() {
				window.open('https://busybusy.com/fair-billing/');
			},
			openAnnualPlanDialog: function openAnnualPlanDialog() {
				this.set('showAnnualPlanDialog', true);
			},
			closeAnnualPlanDialog: function closeAnnualPlanDialog() {
				this.set('showAnnualPlanDialog', false);
			},
			openIntercom: function openIntercom() {
				var busyNav = Ember.getOwner(this).lookup('component:busy-nav');
				busyNav.send('openIntercom');
			}
		}
	});
});