define('busy-app/utils/models/socket', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/***/
	/**
  * @module Utils/Models
  *
  */
	var kMaxInterval = 60;
	var kMinInterval = 15;

	/**
  * `Util/Model/Socket`
  *
  * @class Socket
  * @namespace Utils.Models
  * @extends EmberObject
  */
	exports.default = Ember.Object.extend(Ember.Evented, {
		type: null,
		modelType: null,
		queryParams: null,

		_interval: null,

		lastSync: null,
		syncTime: null,

		_lastModelState: null,

		query: function query(modelType, _query) {
			var _this = this;

			this.set('type', 'query');
			this.set('modelType', modelType);
			this.set('queryParams', _query);
			this.scheduleSync();

			this.store.query(modelType, _query).then(function (res) {
				_this.sync(res);
			});
			return this;
		},
		findRecord: function findRecord(modelType, id) {
			var _this2 = this;

			this.set('type', 'query');
			this.set('modelType', modelType);
			this.set('queryParams', id);
			this.scheduleSync();

			this.store.query(modelType, id).then(function (res) {
				_this2.sync(res);
			});
			return this;
		},
		then: function then(callback) {
			this.on('sync', callback);
			return this;
		},
		update: function update() {
			var shouldUpdate = this.shouldUpdate;
			if (Ember.isNone(shouldUpdate) || typeof shouldUpdate === 'function' && this.shouldUpdate()) {
				var method = this.get('type');
				var modelType = this.get('modelType');
				var queryParams = this.get('queryParams');
				this[method].call(this, modelType, queryParams);
			} else {
				this.scheduleSync();
			}
		},
		sync: function sync(data) {
			var isUpdated = true;
			if (isUpdated) {
				this.set('lastSync', _utils.Time.timestamp());
				this.set('_lastModelState', data);
				this.trigger('sync', data, this);
			}
		},
		validateData: function validateData(data) {
			var savedData = this.get('_lastModelState');
			var isValid = false;
			if ((typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object') {
				if ((typeof savedData === 'undefined' ? 'undefined' : _typeof(savedData)) === 'object') {
					if (typeof Ember.get(data, 'content.length') === 'number') {
						if (Ember.get(data, 'content.length') === Ember.get(savedData, 'content.length')) {
							data.forEach(function (item, key) {
								if (item.get('updatedOn') !== savedData.objectAt(key).get('updatedOn')) {
									isValid = true;
								}
							});
						} else {
							isValid = true;
						}
					} else if (typeof Ember.get(data, 'id') === 'string') {
						if (Ember.get(data, 'updatedOn') !== Ember.get(savedData, 'updatedOn')) {
							isValid = true;
						}
					} else {
						isValid = true;
					}
				} else {
					isValid = true;
				}
			} else if ((typeof savedData === 'undefined' ? 'undefined' : _typeof(savedData)) === 'object') {
				isValid = true;
			}
			return isValid;
		},
		scheduleSync: function scheduleSync() {
			this.clearInterval();
			var time = _utils.Time.timestamp();
			var timeSince = Math.round((time - this.get('lastSync')) / 60) * 5;

			var timeToWait = kMinInterval + timeSince;
			if (timeToWait > kMaxInterval) {
				timeToWait = kMaxInterval;
			}

			this.set('syncTime', timeToWait);
			this.interval(kMinInterval);
		},
		destroy: function destroy() {
			this.clearInterval();
			this.set('savedData', null);
		},
		clearInterval: function clearInterval() {
			var curInterval = this.get('_interval');
			if (!Ember.isNone(curInterval)) {
				window.clearTimeout(curInterval);
				this.set('_interval', null);
			}
		},
		interval: function interval(time) {
			var _this3 = this;

			if (Ember.isNone(this.get('_interval'))) {
				var interval = window.setTimeout(function () {
					_this3.update();
				}, time * 1000);
				this.set('_interval', interval);
			}
		}
	});
});