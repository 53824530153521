define('busy-app/controllers/report/activity/index', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		pController: null,
		features: Ember.inject.service('features'),
		graylog: Ember.inject.service('graylog'),

		settings: Ember.computed('pController.settings', function () {
			return this.get('pController.settings');
		}),

		exportCSV: function exportCSV() {
			var model = this.get('model');
			var settings = this.get('model.settings');
			var type = model.get('showType');
			var dataModel = model.get(type);
			var startDate = _utils.Time.date(settings.start).format('DDMMMYYYY');
			var endDate = _utils.Time.date(settings.end).format('DDMMMYYYY');

			var reportTitle = this.get('pController.reportTitle') + 'Activity(' + startDate + '-' + endDate + ')';
			console.log('\uD83D\uDE80 ~ file: index.js ~ line 29 ~ exportCSV ~ reportTitle', reportTitle);

			var dataMap = {};
			if (type === 'startDate') {
				dataMap = {};
				dataMap.date = function (data) {
					return _utils.Time.date(data.startDate).format('MM/DD/YYYY');
				};
			} else if (type === 'member') {
				dataMap = { 'First Name': 'member.firstName', 'Last Name': 'member.lastName', 'Employee ID': 'member.memberNumber' };
			} else if (type === 'project') {
				dataMap = { 'Project': 'project.title', 'Project Number': 'project.projectNumber' };
			} else if (type === 'equipment') {
				dataMap = { 'Equipment Name': 'equipment.equipmentName', 'Equipment Make/Model': 'equipment.makeModel' };
			} else if (type === 'costCode') {
				dataMap = { 'Cost Code': 'costCode.costCode', 'Cost Code Title': 'costCode.title' };
			}

			if (settings.regCost) {
				var _key = type === 'project' ? 'totalRegularCost' : 'regularCost';

				dataMap.regularCost = function (data) {
					return "$" + data[_key].toFixed(2);
				};
			}

			if (settings.regHours) {
				var _key2 = type === 'project' ? 'totalRegularSeconds' : 'regularSeconds';

				dataMap.regularHours = function (data) {
					return _utils.Time.convertSecondsString(data[_key2], 20, true, false);
				};
			}

			if (settings.otCost) {
				var _key3 = type === 'project' ? 'totalOvertimeCost' : 'overtimeCost';

				dataMap.overtimeCost = function (data) {
					return "$" + data[_key3].toFixed(2);
				};
			}

			if (settings.otHours) {
				var _key4 = type === 'project' ? 'totalOvertimeSeconds' : 'overtimeSeconds';

				dataMap.overtimeHours = function (data) {
					return _utils.Time.convertSecondsString(data[_key4], 20, true, false);
				};
			}

			if (settings.ot2Cost) {
				var _key5 = type === 'project' ? 'totalDoubletimeCost' : 'doubletimeCost';

				dataMap.doubleTimeCost = function (data) {
					return "$" + data[_key5].toFixed(2);
				};
			}

			if (settings.ot2Hours) {
				var _key6 = type === 'project' ? 'totalDoubletimeSeconds' : 'doubletimeSeconds';

				dataMap.doubleTimeHours = function (data) {
					return _utils.Time.convertSecondsString(data[_key6], 20, true, false);
				};
			}

			if (settings.overburden) {
				var _key7 = type === 'project' ? 'totalOverburden' : 'overburden';

				dataMap.laborBurden = function (data) {
					return "$" + data[_key7].toFixed(2);
				};
			}

			if (settings.totalCost) {
				if (this.get('features.reportLaborBurden')) {
					var _key8 = type === 'project' ? 'totalTotalOverburdenCost' : 'totalOverburdenCost';

					dataMap.totalCost = function (data) {
						return "$" + data[_key8].toFixed(2);
					};
				} else {
					var _key9 = type === 'project' ? 'totalTotalCost' : 'totalCost';

					dataMap.totalCost = function (data) {
						return "$" + data[_key9].toFixed(2);
					};
				}
			}

			var key = type === 'project' ? 'totalTotalSeconds' : 'totalSeconds';

			dataMap.totalHours = function (data) {
				return _utils.Time.convertSecondsString(data[key], 20, true, false);
			};
			console.log('\uD83D\uDE80 ~ file: index.js ~ line 122 ~ exportCSV ~ dataMap', dataMap);

			this.store.csvExport(dataModel.get('content'), dataMap, reportTitle, true, true);
		},


		/* Graylog events */

		graylogActivityReportSummaryViewed: function graylogActivityReportSummaryViewed(type) {
			this.get('graylog').sendEvent({
				short_message: 'Activity Report Summary Viewed',
				full_message: 'Activity Report Summary Viewed',
				level: 6,
				_member_id: this.get('auth.memberId'),
				_member_position_id: this.get('auth.member.positionId'),
				_summary_type: type,
				_is_pro_account: this.get('subscription.isProAccount')
			});
		},


		actions: {
			toggleChildren: function toggleChildren(item) {
				if (!Ember.isEmpty(item.get('children'))) {
					item.set('showChildren', !item.get('showChildren'));
				}
			},
			openTableRow: function openTableRow(model) {
				var isUnassignedProject = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

				this.graylogActivityReportSummaryViewed(this.get('model.showType'));
				if (Ember.isNone(this.get('model.id'))) {
					this.set('model.id', '_-NULL-_');
				}

				var type = this.get('model.showType');
				this.set('summaryType', type === 'startDate' ? 'day' : type);
				this.set('summaryModel', model);
				this.set('summaryDialog', true);
				this.set('isUnassignedProject', isUnassignedProject);
			},
			closeDialog: function closeDialog() {
				this.set('summaryDialog', false);
			}
		}
	});
});