define('busy-app/controllers/employees/employee-locations', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['id', 'member_id', 'filter'],

		id: null,
		member_id: null,
		filter: null,
		groups: null,

		locationFilter: Ember.computed('filter', function () {
			var filter = [];
			if (/break/.test(this.get('filter'))) {
				var breakId = this.get('filter').replace(/break-/, '');
				var breakEntry = this.get('model.timeEntryBreaks').findBy('id', breakId);
				if (!Ember.isNone(breakEntry)) {
					filter.push(breakEntry.get('startLocation.id'));
					filter.push(breakEntry.get('endLocation.id'));
				}
			}

			if (this.get('filter') === 'start-location') {
				filter.push(this.get('model.startLocation.id'));
			}

			if (this.get('filter') === 'end-location') {
				filter.push(this.get('model.endLocation.id'));
			}
			return filter;
		}),

		setGroups: Ember.observer('model.{startLocationId,isStartLocationValid,endLocationId,isEndLocationValid}', 'model.timeEntryBreaks.@each.{startLocationId,endLocationId}', function () {
			var _this = this;

			if (!Ember.isNone(this.get('model'))) {
				var groups = [Ember.Object.create({ key: 'all-locations', value: (0, _utils.loc)('All Locations') })];
				if (!Ember.isNone(this.get('model.isStartLocationValid'))) {
					groups.pushObject(Ember.Object.create({
						key: 'start-location',
						value: (0, _utils.loc)('Clock in'),
						_selected: 'start-location' === this.get('filter')
					}));
				}

				if (!Ember.isNone(this.get('model.endLocationId'))) {
					groups.pushObject(Ember.Object.create({
						key: 'end-location',
						value: (0, _utils.loc)('Clock out'),
						_selected: 'end-location' === this.get('filter')
					}));
				}

				var idx = 1;
				this.get('model.timeEntryBreaks').forEach(function (item) {
					if (!Ember.isNone(item.get('startLocationId')) || !Ember.isNone(item.get('endLocationId'))) {
						var keyName = 'break-' + item.id;
						var isSelected = false;
						if (keyName === _this.get('filter')) {
							isSelected = true;
						}
						groups.push(Ember.Object.create({
							key: keyName,
							value: (0, _utils.loc)('Break %@', [idx]),
							_selected: isSelected
						}));
						idx++;
					}
				});
				this.set('groups', groups);
			}
		}),

		setDefaultGroup: Ember.observer('group.@each.key', function () {
			this.set('group', this.get('groups').objectAt(0));
		}),

		actions: {
			setSelectedGroup: function setSelectedGroup(view, group) {
				this.set('selectedGroup', group);
			},
			filterGroup: function filterGroup(group) {
				var filter = group.get('key') === 'all-locations' ? null : group.get('key');
				this.set('filter', filter);
				this.transitionToRoute('employees.employee-locations', {
					queryParams: {
						id: this.get('id'),
						member_id: this.get('member_id'),
						filter: filter
					}
				});
			}
		}
	});
});