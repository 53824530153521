define('busy-app/components/time/time-entry/log', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-time-entry-log'],

		auth: Ember.inject.service('auth'),
		store: Ember.inject.service('store'),

		model: null,

		mergedModel: null,
		showMergedHistory: false,
		showGPSMapDialog: false,

		timeEntry: null,
		mapItem: null,

		showDetails: false,
		showMap: false,

		init: function init() {
			this._super();
			this.loadData();
		},


		loadData: Ember.observer('timeEntry.id', function () {
			var _this = this;

			if (!Ember.isNone(this.get('timeEntry.id'))) {
				this.get('store').manager('time-entry-log', this.get('timeEntry.id')).then(function (model) {
					_this.set('model', model);
				});
			}
		}),

		loadMergedData: function loadMergedData(id) {
			var _this2 = this;

			if (Ember.isNone(this.get('mergedModel')) || this.get('mergedModel.id') !== id) {
				this.get('store').manager('time-entry-log', id).then(function (model) {
					_this2.set('mergedModel', model);
				});
			}
			this.set('showMergedHistory', true);
		},


		actions: {
			showEditDetails: function showEditDetails(item) {
				item.set('showDetails', true);
			},
			showMap: function showMap(item) {
				var entry = item.get('locationInfo');
				entry.set('showMap', true);
				this.set('mapItem', entry);
			},
			hideEditDetails: function hideEditDetails(item) {
				item.set('showDetails', false);
			},
			viewMergedHistory: function viewMergedHistory(item) {
				this.loadMergedData(item.get('model.mergedTimeEntryId'));
			},
			cancelMergedHistory: function cancelMergedHistory() {
				this.set('showMergedHistory', false);
			},
			closeAction: function closeAction() {
				this.sendAction('onClose');
			},
			showLocation: function showLocation() {
				this.set('showGPSMapDialog', true);
			},
			closeGPSDialog: function closeGPSDialog() {
				this.set('mapItem.showMap', false);
				this.set('showGPSMapDialog', false);
				this.set('mapItem', null);
			}
		}
	});
});