define('busy-app/initializers/@busy-web/ember-cli-launch-darkly', ['exports', '@busy-web/ember-cli-launch-darkly/initializers/@busy-web/ember-cli-launch-darkly'], function (exports, _emberCliLaunchDarkly) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberCliLaunchDarkly.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _emberCliLaunchDarkly.initialize;
    }
  });
});