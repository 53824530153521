define('busy-app/components/dialogs/save-message', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'busy-save',

		classNames: ['busy-save'],

		isShowing: false,

		isSaving: false,
		isSaved: false,
		isError: false,

		timeout: null,

		savingMessageText: (0, _utils.loc)('Saving'),
		savedMessageText: (0, _utils.loc)('Successfully Saved'),
		errorMessageText: (0, _utils.loc)('An error occurred! Please try again later.'),

		message: '',

		init: function init() {
			this._super();

			if (!Ember.isNone(this.get('targetParent'))) {
				this.set('targetParent.busySave', this);
			}
		},

		/**
   * Shows a saving message at the
   * bottom of the dialog
   *
   * @public
   * @method showSavingMessage
   * @param message {string} The save message text
   * @param timeout {number} The time in miliseconds to show the message for
   * @returns {EmberPromise}
   */
		showSavingMessage: function showSavingMessage(message, timeout) {
			return this.savingMessage(message, timeout);
		},


		/**
   * Shows an error message at the
   * bottom of the dialog
   *
   * @public
   * @method showErrorMessage
   * @param message {string} The error message text
   * @param timeout {number} The time in miliseconds to show the message for
   * @returns {EmberPromise}
   */
		showErrorMessage: function showErrorMessage(message, timeout) {
			return this.errorMessage(message, timeout);
		},


		/**
   * Shows a saved message at the
   * bottom of the dialog and fires the close dialog event.
   *
   * @public
   * @method showSavedMessage
   * @param message {string} The saved message text
   * @param timeout {number} The time in miliseconds to show the message for
   * @returns {EmberPromise}
   */
		showSavedMessage: function showSavedMessage(message, timeout) {
			return this.successMessage(message, timeout);
		},


		/**
   * shows a saving message box. this box will not hide until
   * either success or error message calls have been made
   *
   * @public
   * @param message {string} the message to show when saving (not required)
   */
		savingMessage: function savingMessage(message) {
			return this._savingMessage(message);
		},


		/**
   * private save message call for internal class use
   *
   * @public
   * @param message {string} the message to show when saving
   * @param complete {function} the callback function called when the timeout has finished
   */
		_savingMessage: function _savingMessage(message) {
			var _this = this;

			message = typeof message === 'string' ? message : this.get('savingMessageText');

			this.set('savingMessageText', message);

			this.set('isSaved', false);
			this.set('isError', false);

			return this.show().then(function () {
				_this.set('isSaving', true);

				return true;
			});
		},


		/**
   * show the message success box
   *
   * @private
   * @param message {string} the success message (not required)
   * @param timeout {int} the time in milliseconds for the message to display (not required) default 2000 miliseconds
   */
		successMessage: function successMessage(message, timeout) {
			var _this2 = this;

			return this.show().then(function () {
				return _this2._successMessage(message, timeout);
			});
		},


		/**
   * private success message call
   *
   * @private
   */
		_successMessage: function _successMessage(message, timeout) {
			var _this3 = this;

			message = message !== undefined ? message : this.get('savedMessageText');
			timeout = timeout !== undefined ? timeout : 2000;

			if (this.get('timeout')) {
				window.clearTimeout(this.get('timeout'));
			}

			this.set('savedMessageText', message);
			this.set('isSaving', false);
			this.set('isSaved', true);
			this.set('isError', false);

			return this.promise(timeout).then(function () {
				_this3.sendAction('onSaved');
				return _this3.hide();
			});
		},


		/**
   * shows the message error box
   *
   * @public
   * @param message {string} the success message (not required)
   * @param timeout {int} the time in milliseconds for the message to display (not required) default 4000 miliseconds
   */
		errorMessage: function errorMessage(message, timeout) {
			var _this4 = this;

			return this.show().then(function () {
				return _this4._errorMessage(message, timeout);
			});
		},


		/**
   * private error message call
   *
   * @private
   */
		_errorMessage: function _errorMessage(message, timeout) {
			var _this5 = this;

			message = message !== undefined ? message : this.get('errorMessageText');
			timeout = timeout !== undefined ? timeout : 4000;

			this.set('errorMessageText', message);
			this.set('isSaving', false);
			this.set('isSaved', false);
			this.set('isError', true);

			return this.promise(timeout).then(function () {
				_this5.sendAction('onError');
				return _this5.hide();
			});
		},
		show: function show() {
			var timeout = 0;
			if (!this.get('isShowing')) {
				// set isShowing flag to true
				this.set('isShowing', true);

				// set the timeout to a 200 milliseconds
				timeout = 200;
			} else if (!Ember.isNone(this.get('timeout'))) {
				// get the stored timeout
				var timer = this.get('timeout');

				// calculate the time spent since the timeout was stored
				var timeSpent = new Date().valueOf() - timer.timestamp;

				// get the time left for this timeout
				timeout = timer.duration - timeSpent;
			}
			return this.promise(timeout);
		},
		hide: function hide() {
			var _this6 = this;

			var timeout = 0;
			if (this.get('isError') || this.get('isSaved')) {
				// reset the error flag
				this.set('isError', false);

				// set the isSaved flag to off
				this.set('isSaved', false);

				// set a timeout of 300 milliseconds
				timeout = 300;
			} else if (!Ember.isNone(this.get('timeout'))) {
				// get the stored timeout
				var timer = this.get('timeout');

				// calculate the time left in the timeout
				var timeSpent = new Date().valueOf() - timer.timestamp;

				// get the time left for the timeout
				timeout = timer.duration - timeSpent;
			}

			return this.promise(timeout).then(function () {
				_this6.set('isShowing', false);

				return true;
			});
		},
		promise: function promise(duration) {
			var _this7 = this;

			var timestamp = new Date().valueOf();

			if (this.get('timeout')) {
				window.clearTimeout(this.get('timeout').timeout);
			}

			return new Ember.RSVP.Promise(function (resolve) {
				var timer = window.setTimeout(function () {
					if (!_this7.get('isDestroyed')) {
						Ember.run(null, resolve, true);
					}
				}, duration);

				_this7.set('timeout', { timeout: timer, timestamp: timestamp, duration: duration });
			});
		}
	});
});