define('busy-app/models/media-collection', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		title: _emberData.default.attr('string'),
		organizationId: _emberData.default.attr('string'),
		projectId: _emberData.default.attr('string'),
		createdBy: _emberData.default.attr('string'),
		description: _emberData.default.attr('string'),
		assignedTo: _emberData.default.attr('string'),
		createdOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),

		member: _emberData.default.belongsTo('member', { referenceKey: 'createdBy', foreignKey: 'id' }),
		project: _emberData.default.belongsTo('project'),
		mediaEntries: null,

		submittedTime: Ember.computed('submittedOn', function () {
			var date = '';
			if (!Ember.isNone(this.get('submittedOn'))) {
				date = _utils.Time.timeFormat(this.get('submittedOn') + _utils.Time.timezone(this.get('submittedOn')), 'MMM. DD, h:mm A');
			}
			return date;
		}),

		projectTitle: Ember.computed('project.title', function () {
			var title = '';
			if (!Ember.isNone(this.get('project'))) {
				title = this.get('project.title');
			}
			return title;
		}),

		moreEntries: Ember.computed('mediaEntries.{@each.id,[]}', function () {
			var count = this.get('mediaEntries.length') || 0;
			if (count > 4) {
				count = count - 4;
			} else {
				count = 0;
			}
			return count;
		}),

		deleteRecord: function deleteRecord() {
			this.set('deletedOn', _utils.Time.utcTimestamp());
			return this;
		}
	});
});