define('busy-app/components/busy-terms', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-terms'],

		isScrolled: false,

		initScroll: function initScroll() {
			var _this = this;

			var $dialogBody = Ember.$('.busy-terms > .dialog-main > .dialog-body');
			if (!Ember.isNone($dialogBody)) {
				$dialogBody.scroll(function (evt) {
					_this.checkScroll(evt);
				});
			}
		},
		didInsertElement: function didInsertElement() {
			this._super();
			this.initScroll();
		},
		checkScroll: function checkScroll(evt) {
			var navTop = Ember.$(evt.target).find('#navigation').offset().top;
			var navHeight = Ember.$(evt.target).find('#navigation').height();
			if (navTop + navHeight > 0) {
				this.set('isScrolled', false);
			} else {
				this.set('isScrolled', true);
			}
		},


		actions: {
			scrollTo: function scrollTo(id) {
				var $dialogBody = Ember.$('.busy-terms > .dialog-main > .dialog-body');
				var link = Ember.$('#' + id).offset().top;
				var position = $dialogBody.scrollTop();
				$dialogBody.animate({
					scrollTop: link + position - 50
				}, 500);
			},
			scrollToTop: function scrollToTop() {
				var $dialogBody = Ember.$('.busy-terms > .dialog-main > .dialog-body');
				var nav = Ember.$('#navigation').offset().top;
				var position = $dialogBody.scrollTop();
				var top = position + nav - 125;

				$dialogBody.animate({
					scrollTop: top
				}, 500);
			},
			closeTermsDialog: function closeTermsDialog() {
				this.sendAction('onClose');
			}
		}
	});
});