define('busy-app/components/ember-chart', ['exports', 'ember-cli-chartjs/components/ember-chart'], function (exports, _emberChart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberChart.default;
    }
  });
});