define('busy-app/components/busy-map-google', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var kDefaultLocation = [37.10102990074617, -113.53877082086876];

	/**
  * `Component/BusyMapGoogle`
  * Component map for displaying a map to a user.
  *
  * @class BusyMapGoogle
  * @namespace Components
  *
  * @property locationInfo {object} Object of info for a place to be shown on a map
  * @property canEdit {boolean} Bool used to show edit options for map.
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['c-busy-map'],
		classNameBindings: ['canEdit:edit'],

		/** public property list */
		map: null,
		mapMarkers: null,
		mapAutocomplete: null,
		canEdit: false,
		locationInfo: null,
		error: false,

		locationString: '',

		/**
   * inititalizes the Map
   *
   * @private
   * @method initializer
   * @returns {void}
   */
		reloadMap: Ember.observer('locationInfo.latitude', 'locationInfo.longitude', 'locationInfo.locationRadius', function () {
			if (!Ember.isNone(this.get('locationInfo')) && !this.get('canEdit')) {
				this.loadMap();
			}
		}),

		/**
   * Method to see whether a map
   * should be shown or not.
   *
   * @public
   * @method shouldShowMap
   * @property {canEdit, locationInfo.hasLocation}
   * @returns {boolean}
   */
		shouldShowMap: Ember.computed('canEdit', 'locationInfo.hasLocation', function () {
			if (this.get('canEdit') || this.get('locationInfo.hasLocation')) {
				return true;
			}
			return false;
		}),

		isDefaultLocation: Ember.computed('locationInfo', function () {
			return this.get('locationInfo.latitude') === kDefaultLocation[0] && this.get('locationInfo.longitude') === kDefaultLocation[1];
		}),

		/**
   * Loads the Map based on info
   * from locationInfo. If no locationInfo
   * it will load the default Map which is
   * busybusy office building.
   *
   * @public
   * @method loadMap
   * @returns {void}
   */
		loadMap: function loadMap() {
			var _this = this;

			var $map = this.$('#map-canvas');
			var locationInfo = Ember.get(this, 'locationInfo');

			if ($map && $map.length > 0 && $map.is(':visible') && !Ember.isNone(locationInfo)) {
				var isDefaultLocation = Ember.get(this, 'isDefaultLocation');

				var _EmberGetProperties = Ember.getProperties(locationInfo, ['locationString', 'hasLocation']),
				    locationString = _EmberGetProperties.locationString,
				    hasLocation = _EmberGetProperties.hasLocation;

				if (hasLocation && !isDefaultLocation) {
					return this.loadMapByLocation(Ember.get(locationInfo, 'latitude'), Ember.get(locationInfo, 'longitude'), Ember.get(locationInfo, 'locationRadius'));
				} else if (!Ember.isNone(locationString) && locationString !== '') {
					// geolocateAddress sets locationInfo properties
					return this.geolocateAddress(locationString).then(function (geo) {
						if (Ember.isNone(geo)) {
							return _this.loadDefaultMap();
						} else {
							return _this.loadMapByLocation(Ember.get(locationInfo, 'latitude'), Ember.get(locationInfo, 'longitude'), Ember.get(locationInfo, 'locationRadius'));
						}
					});
				} else {
					return this.loadDefaultMap();
				}
			} else {
				return Ember.run.later(this, function () {
					return _this.loadMap();
				}, 100);
			}
		},


		/**
   * Loads the Map based on params
   *
   * @public
   * @method loadMapByLocation
   * @param latitude {number} The latitude of the location
   * @param longitude {number} The longitude of the location
   * @param radius {number} The accuracy or radius of location.
   * @returns {void}
   */
		loadMapByLocation: function loadMapByLocation(latitude, longitude, radius) {
			var google = window.google;
			var latlng = new google.maps.LatLng(latitude, longitude);
			var container = this.$('#map-canvas').get(0);

			//Map Options
			var options = {
				zoom: 15,
				center: latlng,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			};

			var map = new google.maps.Map(container, options);

			this.set('map', map);

			this.setMapMarkerCircle(map, latlng, radius);
		},


		/**
   * Loads the Default Map which is
   * busybusy office building.
   *
   * @public
   * @method loadDefaultMap
   * @returns {void}
   */
		loadDefaultMap: function loadDefaultMap() {
			// default to busy office
			// 37.10102990074617 -113.53877082086876
			this.loadMapByLocation.apply(this, kDefaultLocation);
		},
		clearMap: function clearMap() {
			var map = this.get('map');
			var container = this.$('#map-canvas');

			if (!Ember.isNone(map)) {
				this.set('map', null);

				window.google.maps.event.clearInstanceListeners(window);
				window.google.maps.event.clearInstanceListeners(document);
				window.google.maps.event.clearInstanceListeners(container[0]);

				container.children().detach();
			}
		},


		/**
   * Sets Marker and Circle on the map
   * that is passed in at the lat and
   * long params. Radius is used for
   * the circle. There are listeners
   * on the circle. If circle moves, location
   * of the marker and circle get updated as well as
   * location info. If circle Radius changes, radius value
   * gets updated. The marker is boundTo
   * the circle.
   *
   * @public
   * @method setMapMarkerCircle
   * @param map {object} The Map that the options need to be set on
   * @param latlng {object} The Latitdue & longitude of center of Map
   * @param radius {number} The accuracy or radius of location.
   * @returns {void}
   */
		setMapMarkerCircle: function setMapMarkerCircle(map, latlng, radius) {
			var _this2 = this;

			radius = !Ember.isNone(radius) ? radius : 50;
			var object = {};
			var circle = new window.google.maps.Circle({
				fillOpacity: '0.0',
				strokeColor: '#30AEEF',
				strokeWeight: 1,
				map: map,
				center: latlng,
				radius: radius,
				draggable: this.get('canEdit'),
				geodesic: this.get('canEdit'),
				editable: this.get('canEdit')
			});

			object.circle = circle;

			var marker = new window.google.maps.Marker({
				map: map,
				position: circle.center,
				animation: window.google.maps.Animation.DROP
			});

			object.marker = marker;

			marker.bindTo('position', circle, 'center');

			if (this.get('canEdit')) {
				circle.addListener('center_changed', function () {
					_this2.geolocateLocation(circle.getCenter());
				});

				circle.addListener('radius_changed', function () {
					//locationRadius needs to be an int
					_this2.set('locationInfo.locationRadius', Math.floor(circle.getRadius()));
				});

				if (this.get('locationInfo.hasLocation')) {
					this.set('locationString', this.get('locationInfo.locationString'));
				}

				this.attachAutoCompleteWidget(map, circle);
			}
		},


		/*
   *	From a notice in Google API documentation:
   * 		"IMPORTANT: To avoid paying for data that you don't need, be sure to use Autocomplete.setFields() to specify only the place data that you will use."
   *
   */
		attachAutoCompleteWidget: function attachAutoCompleteWidget(map, circle) {
			var _this3 = this;

			var $address = this.$('#address');
			var addressInput = $address.get(0);

			if (!Ember.isNone(addressInput)) {
				this.selectFirstOnEnter(addressInput);

				map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(addressInput);

				var bounds = new window.google.maps.LatLngBounds(new window.google.maps.LatLng(-90, -180), new window.google.maps.LatLng(90, 180));

				// To reduce API costs, explicitly specify the autocomplete fields necessary
				// https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete.setFields
				// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
				var fields = ['geometry.location', 'address_components'];

				var autocomplete = new window.google.maps.places.Autocomplete(addressInput, { bounds: bounds, fields: fields });

				window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
					var place = autocomplete.getPlace(); // only call the getPlace() lookup once and hang on to the result

					if (typeof place.address_components === 'undefined') {
						$address.addClass('error');
					} else {
						$address.removeClass('error');
						circle.setCenter(place.geometry.location);
						map.panTo(place.geometry.location);
						_this3.parseAddress(place);
					}
				});
			}

			// create event for when the map is ready
			// window.google.maps.event.addListener(map, 'bounds_changed', () => {});
		},


		// If the user presses the enter key while searching, auto select the first result
		// https://stackoverflow.com/a/49620828
		selectFirstOnEnter: function selectFirstOnEnter(input) {
			/* Store original event listener */
			var _addEventListener = input.addEventListener ? input.addEventListener : input.attachEvent;

			var addEventListenerWrapper = function addEventListenerWrapper(type, listener) {
				if (type === 'keydown') {
					/* Store existing listener function */
					var _listener = listener;
					listener = function listener(event) {
						/* Simulate a 'down arrow' keypress if no address has been selected */
						var suggestion_selected = document.getElementsByClassName('pac-item-selected').length > 0;

						if (event.which === 13 && !suggestion_selected) {
							var e = Object.assign({}, event);

							e.which = 40;
							e.keyCode = 40;

							_listener.apply(input, [e]);
						}

						_listener.apply(input, [event]);
					};
				}

				_addEventListener.apply(input, [type, listener]);
			};

			input.addEventListener = addEventListenerWrapper;
			input.attachEvent = addEventListenerWrapper;
		},


		/**
   * Gets address info for the lat and
   * long params that are passed in.
   *
   * @public
   * @method geolocateLocation
   * @param lattitude {number} The Latitude of Map location to find address for
   * @param longitude {number} The Longitude of Map location to find address for
   * @returns {void}
   */
		geolocateLocation: function geolocateLocation(center) {
			var _this4 = this;

			if (!Ember.isNone(center)) {
				this.set('locationInfo.latitude', center.lat());
				this.set('locationInfo.longitude', center.lng());

				if (Ember.isNone(this.get('locationInfo.locationRadius'))) {
					this.set('locationInfo.locationRadius', 50);
				}

				var geocoder = new window.google.maps.Geocoder();
				geocoder.geocode({ 'location': center }, function (results, status) {
					if (status === window.google.maps.GeocoderStatus.OK) {
						if (results.length > 0) {
							_this4.parseAddress(results.objectAt(0));
						}
					}
				});
			}
		},


		/**
   * Gets Latitude and Longitude  info
   * for the address param that is passed in.
   *
   * @public
   * @method geolocateAddress
   * @param address {object} The address to find map location for
   * @returns {void}
   */
		geolocateAddress: function geolocateAddress(address) {
			var _this5 = this;

			var google = window.google;
			var geocoder = new google.maps.Geocoder();

			return new Ember.RSVP.Promise(function (resolve, reject) {
				geocoder.geocode({ 'address': address }, function (results, status) {
					if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
						var result = results.objectAt(0);
						if (!Ember.isNone(result.geometry) && !Ember.isNone(result.geometry.location)) {
							_this5.set('locationInfo.latitude', result.geometry.location.lat());
							_this5.set('locationInfo.longitude', result.geometry.location.lng());

							if (Ember.isNone(_this5.get('locationInfo.locationRadius'))) {
								_this5.set('locationInfo.locationRadius', 50);
							}
						}
						_this5.parseAddress(result);
						return resolve(result);
					} else {
						return reject(status);
					}
				});
			});
		},


		/**
   * Seperates the address from a google geolocate result
   *
   * @public
   * @method parseAddress
   * @param results {object} The result from a geolocate function
   * @returns {void}
   */
		parseAddress: function parseAddress(result) {
			var object = {};
			var addrObj = result.address_components;

			addrObj.forEach(function (item) {
				if (item.types[0] === 'street_number') {
					object.address1 = (item.short_name + ' ' + (object.address1 || '')).trim();
				} else if (item.types[0] === 'route') {
					object.address1 = ((object.address1 || '') + ' ' + item.short_name).trim();
				} else if (item.types[0] === 'locality') {
					object.city = item.long_name;
				} else if (item.types[0] === 'administrative_area_level_1') {
					object.state = item.short_name;
				} else if (item.types[0] === 'postal_code') {
					object.postalCode = item.short_name;
				} else if (item.types[0] === 'country') {
					object.country = item.short_name;
				}
			});
			this.set('locationString', result.formatted_address);
		},
		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			this.loadMap();
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			this.clearMap();
		}
	});
});