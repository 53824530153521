define("busy-app/controllers/project/detail/reports/budget-versus-actual", ["exports", "@busy-web/utils"], function (exports, _utils) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		payPeriods: Ember.inject.service("pay-period"),

		hoursPercentage: 0,
		costPercentage: 0,
		start: 0,
		end: 0,

		remainingCost: 0,
		remainingHours: 0,

		hoursNegative: false,
		costNegative: false,

		isCustomDate: false,

		init: function init() {
			this._super();
			this.setOptions();
		},


		moneySpent: Ember.computed.alias("model.project.totalTotalCost"),
		hoursWorked: Ember.computed.alias("model.project.totalTotalSeconds"),

		budgetedHours: Ember.computed("model.budgetSeconds.budgetSeconds", function () {
			return (Math.round(this.get("model.budgetSeconds.budgetSeconds") / 3600 * 100) / 100).toFixed(2);
		}),

		setOptions: Ember.observer("auth.organizationPayPeriod.@each.id", function () {
			if (!Ember.isEmpty(this.get("auth.organizationPayPeriod"))) {
				var currentPeriod = this.get("payPeriods").getPayPeriod(_utils.Time.timestamp());
				var previousPeriod = this.get("payPeriods").getPayPeriod(currentPeriod.get("startDate").subtract(1, "days").unix());

				this.set("start", previousPeriod.get("start"));
				this.set("end", previousPeriod.get("end"));

				this.set("optionSelector", [Ember.Object.create({ key: "allTimeRange", value: (0, _utils.loc)("All time") }), Ember.Object.create({ key: "customDateRange", value: (0, _utils.loc)("Custom") })]);
			}
		}),

		updateRemaining: Ember.observer("model.{project.totalTotalSeconds,project.totalTotalOverburdenCost,budgetSeconds.budgetSeconds,costBudget.costBudget}", function () {
			var budgetHours = this.get("model.budgetSeconds.budgetSeconds") || 1;
			var actualHours = this.get("model.project.totalTotalSeconds") || 0;
			var budgetCost = this.get("model.costBudget.costBudget") || 1;
			var actualCost = this.get("model.project.totalTotalOverburdenCost") || 0;

			var hoursLeft = budgetHours - actualHours;
			var costLeft = budgetCost - actualCost;

			var hoursPercent = Math.round(actualHours / budgetHours * 100);

			var costPercent = Math.round(actualCost / budgetCost * 100);

			this.set("hoursPercentage", hoursPercent);
			this.set("costPercentage", costPercent);

			if (costLeft < 0) {
				this.set("remainingCost", costLeft);
				this.set("costNegative", true);
			} else {
				this.set("remainingCost", costLeft);
				this.set("costNegative", false);
			}

			if (hoursLeft < 0) {
				this.set("remainingHours", Math.abs(hoursLeft));
				this.set("hoursNegative", true);
			} else {
				this.set("remainingHours", hoursLeft);
				this.set("hoursNegative", false);
			}

			var costString = "width: " + this.get("costPercentage") + "%;";
			var hoursString = "width: " + this.get("hoursPercentage") + "%;";
			this.set("costWidthString", Ember.String.htmlSafe(costString));
			this.set("hoursWidthString", Ember.String.htmlSafe(hoursString));
		}),

		actions: {
			changeDateRange: function changeDateRange(start, end) {
				this.set("start", start);
				this.set("end", end);
			},
			allTimeRange: function allTimeRange() {
				this.set("isCustomDate", false);
			},
			customDateRange: function customDateRange() {
				this.set("isCustomDate", true);
			}
		}
	});
});