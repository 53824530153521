define('busy-app/models/project', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Models
  *
  */
	//import { all } from 'rsvp';
	var kFullTitleDelimiter = ' / ';

	/**
  * `Model/Project`
  *
  * @class Project
  * @namespace Models
  * @extends DS.Model
  */
	exports.default = _emberData.default.Model.extend({
		organizationId: _emberData.default.attr('id'),
		parentProjectId: _emberData.default.attr('id', { sortable: true, sortBy: ['parentProject.title'] }),
		rootProjectId: _emberData.default.attr('id'),
		title: _emberData.default.attr('string'),
		depth: _emberData.default.attr('number'),
		imageUrl: _emberData.default.attr('string'),
		imageThumbUrl: _emberData.default.attr('string'),
		taskLoggable: _emberData.default.attr('boolean', { defaultValue: true }),
		timeLoggable: _emberData.default.attr('boolean', { defaultValue: true }),
		allAccess: _emberData.default.attr('boolean', { defaultValue: true }),
		projectGroupId: _emberData.default.attr('string'),
		percentComplete: _emberData.default.attr('number'),
		revokedOn: _emberData.default.attr('date'),
		updatedOn: _emberData.default.attr('date'),
		archivedOn: _emberData.default.attr('date'),
		createdOn: _emberData.default.attr('date'),

		rootProject: _emberData.default.belongsTo('project', { inverse: null, referenceKey: 'rootProjectId', foreignKey: 'id' }),
		parentProject: _emberData.default.belongsTo('project', { inverse: null, referenceKey: 'parentProjectId', foreignKey: 'id' }),
		projectCostCodeLinks: _emberData.default.hasMany('project-cost-code-link'),

		fullTitleDelimiter: kFullTitleDelimiter,

		subprojects: Ember.computed('id', function () {
			if (!Ember.isNone(this.id)) {
				return _emberData.default.PromiseArray.create({
					promise: this.store.findAll('project', { _asc: ['title'], parent_project_id: this.get('id'), deleted_on: null })
				});
			}
		}),
		// projectCostCodeLinks: computed('id', function() {
		// 	if (!isNone(this.get('id'))) {
		// 		return DS.PromiseArray.create({
		// 			promise: this.store.findAll('project-cost-code-links', { project_id: this.get('id'), deleted_on: null })
		// 		});
		// 	}
		// }),

		projectInfo: Ember.computed('id', function () {
			if (!Ember.isNone(this.id)) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.query('project-info', { project_id: this.get('id') }).then(function (data) {
						return data.get('firstObject');
					})
				});
			}
		}),

		parentProjects: Ember.computed('id', function () {
			var _this = this;

			if (!Ember.isNone(this.id)) {
				return _emberData.default.PromiseArray.create({
					promise: this.store.query('project-lookup', { child_id: this.id }).then(function (lookups) {
						return _this.store.findByIds('project', lookups.map(function (l) {
							return l.get('parentId');
						}));
					})
				});
			}
		}),

		projectNumber: Ember.computed('projectInfo.number', function () {
			return this.get('projectInfo.number');
		}),

		payPeriodHours: Ember.computed('id', function () {
			if (!Ember.isNone(this.get('id'))) {
				return _emberData.default.PromiseArray.create({
					promise: this.store.findAll('project-seconds', {
						start_date: _utils.Time.date().subtract(10, 'years').unix(),
						end_date: _utils.Time.date().add(10, 'years').unix(),
						project_id: this.get('id'),
						limit: 10000
					})
				});
			}
			return [];
		}),

		payPeriodCost: Ember.computed('id', function () {
			if (!Ember.isNone(this.get('id'))) {
				return _emberData.default.PromiseArray.create({
					promise: this.store.findAll('project-cost', {
						start_date: _utils.Time.date().subtract(10, 'years').unix(),
						end_date: _utils.Time.date().add(10, 'years').unix(),
						project_id: this.get('id'),
						limit: 10000
					})
				});
			}
			return [];
		}),

		isTopLevelProject: Ember.computed('rootProjectId', function () {
			return this.get('id') === this.get('rootProjectId');
		}),

		imagePlaceholder: Ember.computed(function () {
			return '/images/imageplaceholder.png';
		}),

		folderImage: Ember.computed(function () {
			return '/images/icons/sprite-80-old.png';
		}),

		/**
   * subProjects and hasChildren are for the project selector
   * dont change or edit or use for other cases.
   *
   */
		hasChildren: Ember.computed('children.{@each.id,[],length}', function () {
			return this.get('children.length') > 0 ? true : false;
		}),

		children: Ember.computed('subprojects.@each.id', function () {
			return this.get('subprojects');
		}),

		regularSeconds: Ember.computed('payPeriodHours.@each.regularSeconds', function () {
			return this.get('payPeriodHours').mapBy('regularSeconds').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		overtimeSeconds: Ember.computed('payPeriodHours.@each.overtimeSeconds', function () {
			return this.get('payPeriodHours').mapBy('overtimeSeconds').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		doubletimeSeconds: Ember.computed('payPeriodHours.@each.doubletimeSeconds', function () {
			return this.get('payPeriodHours').mapBy('doubletimeSeconds').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		totalSeconds: Ember.computed('payPeriodHours.@each.totalSeconds', function () {
			return this.get('payPeriodHours').mapBy('totalSeconds').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		totalHours: Ember.computed('totalSeconds', function () {
			return Math.round(this.get('totalSeconds') / 3600 * 10 / 10);
		}),

		totalTotalSeconds: Ember.computed('payPeriodHours.@each.totalTotalSeconds', function () {
			return this.get('payPeriodHours').mapBy('totalTotalSeconds').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		totalTotalHours: Ember.computed('totalTotalSeconds', function () {
			return Math.round(this.get('totalTotalSeconds') / 3600 * 10) / 10;
		}),

		totalCost: Ember.computed('payPeriodCost.@each.totalCost', function () {
			return this.get('payPeriodCost').mapBy('totalCost').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		totalTotalCost: Ember.computed('payPeriodCost.@each.totalTotalCost', function () {
			return this.get('payPeriodCost').mapBy('totalTotalCost').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		totalTotalOverburdenCost: Ember.computed('payPeriodCost.@each.totalTotalOverburdenCost', function () {
			return this.get('payPeriodCost').mapBy('totalTotalOverburdenCost').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		depthString: Ember.computed('depth', function () {
			if (this.get('depth') === 1) {
				return (0, _utils.loc)('one');
			} else if (this.get('depth') === 2) {
				return (0, _utils.loc)('two');
			} else if (this.get('depth') === 3) {
				return (0, _utils.loc)('three');
			} else if (this.get('depth') === 4) {
				return (0, _utils.loc)('four');
			} else if (this.get('depth') === 5) {
				return (0, _utils.loc)('five');
			} else if (this.get('depth') === 6) {
				return (0, _utils.loc)('six');
			} else if (this.get('depth') === 7) {
				return (0, _utils.loc)('seven');
			}
		}),

		matchTitle: Ember.computed('title', function () {
			return this.get('title').toLowerCase();
		}),

		fullTitle: Ember.computed('title', 'fullTitleDelimiter', 'parentProject.fullTitle', function () {
			var fullTitleDelimiter = this.get('fullTitleDelimiter');

			var title = this.get('title');
			if (!Ember.isNone(this.get('parentProject.fullTitle'))) {
				title = this.get('parentProject.fullTitle') + fullTitleDelimiter + title;
			}

			return title;
		}),

		/*** budget hours and cost ***/

		projectHoursBudget: Ember.computed('id', function () {
			if (!Ember.isNone(this.get('id'))) {
				return _emberData.default.PromiseArray.create({
					promise: this.store.query('project-hours-budget', { project_id: this.get('id'), _desc: ['created_on'], deleted_on: '_-NULL-_' })
				});
			}
		}),

		budgetHoursModel: Ember.computed('projectHoursBudget.firstObject', function () {
			return this.get('projectHoursBudget.firstObject') || {};
		}),

		projectCostBudget: Ember.computed('id', function () {
			if (!Ember.isNone(this.get('id'))) {
				return _emberData.default.PromiseArray.create({
					promise: this.store.query('project-cost-budget', { project_id: this.get('id'), _desc: ['created_on'], deleted_on: '_-NULL-_' })
				});
			}
		}),

		budgetCostModel: Ember.computed('projectCostBudget.firstObject', function () {
			return this.get('projectCostBudget.firstObject') || {};
		}),

		budgetSeconds: Ember.computed('budgetHoursModel.seconds', function () {
			return this.get('budgetHoursModel.seconds') || 0;
		}),

		budgetHours: Ember.computed('budgetHoursModel.seconds', function () {
			return (this.get('budgetHoursModel.seconds') || 0) / 3600;
		}),

		budgetCost: Ember.computed('budgetCostModel.cost', function () {
			return this.get('budgetCostModel.cost') || 0;
		}),

		subprojectsTotalBudgetSeconds: Ember.computed('subprojects.@each.budgetSeconds', function () {
			if (!Ember.isEmpty(this.get('subprojects'))) {
				return this.get('subprojects').mapBy('budgetSeconds').map(function (v) {
					return parseFloat(v);
				}).reduce(function (a, b) {
					return a + b;
				}, 0);
			} else {
				return 0;
			}
		}),

		subprojectsTotalBudgetCost: Ember.computed('subprojects.@each.budgetCost', function () {
			if (!Ember.isEmpty(this.get('subprojects'))) {
				return this.get('subprojects').mapBy('budgetCost').map(function (v) {
					return parseFloat(v);
				}).reduce(function (a, b) {
					return a + b;
				}, 0);
			} else {
				return 0;
			}
		}),

		remainingBudgetSeconds: Ember.computed('subprojectsTotalBudgetSeconds', 'budgetSeconds', function () {
			return this.get('budgetSeconds') - this.get('subprojectsTotalBudgetSeconds');
		}),

		remainingBudgetCost: Ember.computed('subprojectsTotalBudgetCost', 'budgetCost', function () {
			return this.get('budgetCost') - this.get('subprojectsTotalBudgetCost');
		}),

		remainingTotalSeconds: Ember.computed('totalTotalSeconds', 'budgetSeconds', function () {
			return this.get('budgetSeconds') - this.get('totalTotalSeconds');
		}),

		remainingTotalCost: Ember.computed('totalTotalCost', 'budgetCost', function () {
			return this.get('budgetCost') - this.get('totalTotalCost');
		}),

		remainingTotalOverburdenCost: Ember.computed('totalTotalOverburdenCost', 'budgetCost', function () {
			return this.get('budgetCost') - this.get('totalTotalOverburdenCost');
		})
	});
});