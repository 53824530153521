define('busy-app/controllers/project/detail/reports/subprojects', ['exports', '@busy-web/utils', 'busy-app/utils/docs'], function (exports, _utils, _docs) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['custom', 'start', 'end'],

		payPeriods: Ember.inject.service('pay-period'),
		auth: Ember.inject.service('auth'),

		project: null,
		custom: false,
		start: 0,
		end: 0,

		showEntriesDialog: false,
		showBarChart: true,
		unassignedProjects: false,
		dirtyFlag: false,

		init: function init() {
			this.get('auth.currencySymbol');

			this._super.apply(this, arguments);

			this.setOptions();

			this.set('chartOptions', [Ember.Object.create({ key: 'barChart', value: (0, _utils.loc)('Bar Chart') }), Ember.Object.create({ key: 'pieChart', value: (0, _utils.loc)('Pie Chart') })]);
		},


		syncStart: Ember.computed('start', 'custom', function () {
			if (this.get('custom')) {
				return this.get('start');
			}
		}),

		syncEnd: Ember.computed('end', 'custom', function () {
			if (this.get('custom')) {
				return this.get('end');
			}
		}),

		setOptions: Ember.observer('custom', function () {
			this.set('optionSelector', [Ember.Object.create({ key: 'allTimeRange', value: (0, _utils.loc)('All time'), _selected: !this.get('custom') }), Ember.Object.create({ key: 'customDateRange', value: (0, _utils.loc)('Custom'), _selected: this.get('custom') })]);
		}),

		actions: {
			titleSelected: function titleSelected(item) {
				var project = Ember.get(item, 'project');
				this.set('showEntriesDialog', true);
				if (Ember.get(item, 'project.id') === Ember.get(this, 'project.id')) {
					this.set('unassignedProjects', true);
					project = Ember.get(this, 'project');
				} else {
					this.set('unassignedProjects', false);
				}
				this.set('clickedProject', project);
			},
			closeTimeEntryDialog: function closeTimeEntryDialog() {
				this.set('showEntriesDialog', false);
			},
			changeDateRange: function changeDateRange(start, end) {
				this.set('start', start);
				this.set('end', end);

				this.transitionToRoute('project.detail.reports.subprojects', {
					queryParams: {
						custom: this.get('custom'),
						start: start,
						end: end
					}
				});
				Ember.getOwner(this).lookup('route:project.detail.reports.subprojects').refresh();
			},
			optionChanged: function optionChanged(opt) {
				var custom = opt === 'customDateRange';
				this.set('custom', custom);

				if (custom) {
					var currentPeriod = this.get('payPeriods').getPayPeriod(_utils.Time.timestamp());
					var previousPeriod = this.get('payPeriods').getPayPeriod(currentPeriod.get('startDate').subtract(1, 'days').unix());
					this.set('start', previousPeriod.get('start'));
					this.set('end', previousPeriod.get('end'));
				} else {
					this.set('start', 0);
					this.set('end', 0);
				}

				this.transitionToRoute('project.detail.reports.subprojects', {
					queryParams: {
						custom: custom,
						start: this.get('start'),
						end: this.get('end')
					}
				});
				Ember.getOwner(this).lookup('route:project.detail.reports.subprojects').refresh();
			},
			printReport: function printReport() {
				var container = Ember.$('.vproject-reports-subprojects');
				_docs.default.print(container);
			},
			changeChartStyle: function changeChartStyle(item) {
				if (item.key === 'pieChart') {
					this.set('showBarChart', false);
				} else if (item.key === 'barChart') {
					this.set('showBarChart', true);
				}
			},
			refreshSync: function refreshSync() {
				this.set('dirtyFlag', true);
			}
		}
	});
});