define("busy-app/controllers/company-settings/index", ["exports", "@busy-web/utils"], function (exports, _utils) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		subscription: Ember.inject.service("subscription"),
		features: Ember.inject.service(),

		// show the warning if the trial ended automatically
		showFreeTrialExpirationWarning: Ember.computed("subscription.subscriptionStatus.{isTrialEnded,userCanceled}", function () {
			return this.get("subscription.subscriptionStatus.isTrialEnded") && !this.get("subscription.subscriptionStatus.userCanceled");
		}),

		emberMainApp: Ember.computed("features.reactMainApp", function () {
			return !this.get("features.reactMainApp");
		}),

		notificationName: Ember.computed("showFreeTrialExpirationWarning", "subscription.subscriptionStatus.isPastDue", function () {
			if (this.get("subscription.subscriptionStatus.isPastDue")) {
				return "past-due-account";
			} else if (this.get("showFreeTrialExpirationWarning")) {
				return "free-trial-expired";
			}
			return "";
		}),

		notificationText: Ember.computed("showFreeTrialExpirationWarning", "subscription.subscriptionStatus.isPastDue", function () {
			if (this.get("subscription.subscriptionStatus.isPastDue")) {
				return (0, _utils.loc)("Your account is past due. To avoid service interruption");
			} else if (this.get("showFreeTrialExpirationWarning")) {
				return (0, _utils.loc)("Your free trial has expired.");
			}
			return "";
		}),

		notificationType: Ember.computed("subscription.subscriptionStatus.isPastDue", function () {
			if (this.get("subscription.subscriptionStatus.isPastDue")) {
				return "error";
			}
			return "warning";
		}),

		clickText: Ember.computed("showFreeTrialExpirationWarning", "subscription.subscriptionStatus.isPastDue", function () {
			if (this.get("subscription.subscriptionStatus.isPastDue")) {
				return (0, _utils.loc)("please resolve this issue now.");
			} else if (this.get("showFreeTrialExpirationWarning")) {
				return (0, _utils.loc)("If you enjoyed the service, upgrade to Pro.");
			}
			return "";
		}),

		isDismissable: Ember.computed("subscription.subscriptionStatus.isPastDue", function () {
			if (this.get("subscription.subscriptionStatus.isPastDue")) {
				return false;
			}
			return true;
		}),

		clickAction: Ember.computed("showFreeTrialExpirationWarning", "subscription.subscriptionStatus.isPastDue", function () {
			if (this.get("subscription.subscriptionStatus.isPastDue")) {
				return "openSubscription";
			} else if (this.get("showFreeTrialExpirationWarning")) {
				return "startUpgrade";
			}
			return undefined;
		}),

		init: function init() {
			this._super();
			this.set("qbpc1", []);
			this.set("qbpc2", []);
			this.set("qbpc3", []);
		},


		actions: {
			startUpgrade: function startUpgrade() {
				window.location = "/company-settings#tab-subscription";
				this.set("showUpgradeDialog", true);
			},
			closeUpgradeDialog: function closeUpgradeDialog() {
				this.set("showUpgradeDialog", false);

				// allowing the location change to happen inside this event triggers the `onClose` event in FS-Dialog to fire, causing an infininte loop
				Ember.run.next(this, function () {
					window.location = "/company-settings#tab-subscription";
				});
			},
			openSubscription: function openSubscription() {
				window.location = "/company-settings#tab-subscription";
			}
		}
	});
});