define('busy-app/utils/models/filters/segment', ['exports', 'ember-data', 'busy-app/utils/models/filters/base'], function (exports, _emberData, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _base.default.extend({
		findByTimeEntryId: function findByTimeEntryId(id) {
			var _this = this;

			var promise = this.store.query('segment', { time_entry_id: id }).then(function (segments) {
				var ids = [];
				segments.forEach(function (item) {
					if (ids.indexOf(item.id) === -1) {
						ids.push(item.id);
					}
				});
				return Ember.RSVP.hash({
					seconds: _this.store.findWhereIn('segment-seconds', 'segment_id', ids, { deleted_on: '_-NULL-_' }),
					cost: _this.store.findWhereIn('segment-cost', 'segment_id', ids, { deleted_on: '_-NULL-_' })
				}).then(function (data) {
					segments.forEach(function (item) {
						item.set('seconds', data.seconds.findBy('segmentId', item.id));
						item.set('cost', data.cost.findBy('segmentId', item.id));
					});
					return segments;
				});
			});
			return _emberData.default.PromiseArray.create({ promise: promise });
		},
		findByTimeEntryIds: function findByTimeEntryIds(ids, query) {
			var _this2 = this;

			(false && !((typeof ids === 'undefined' ? 'undefined' : _typeof(ids)) === 'object') && Ember.assert('ids must be an array of strings in segmentFilter.findByTimeEntryIds()', (typeof ids === 'undefined' ? 'undefined' : _typeof(ids)) === 'object'));


			if (!Ember.isNone(query.deleted_on)) {
				query.deleted_on = '_-NULL-_';
			}

			var timeEntryIds = ids.copy();
			return this.store.findWhereIn('segment', 'time_entry_id', timeEntryIds, query).then(function (segments) {
				var secondIds = [];
				var costIds = [];
				segments.forEach(function (item) {
					secondIds.push(item.id);
					costIds.push(item.id);
				});

				return Ember.RSVP.hash({
					segments: segments,
					seconds: _this2.store.findWhereIn('segment-seconds', 'segment_id', secondIds, { deleted_on: '_-NULL-_' }),
					cost: _this2.store.findWhereIn('segment-cost', 'segment_id', costIds, { deleted_on: '_-NULL-_' })
				}).then(function (data) {
					data.segments.forEach(function (item) {
						var seconds = data.seconds.filterBy('segmentId', item.id);
						var cost = data.cost.filterBy('segmentId', item.id);

						if (!Ember.isNone(seconds)) {
							item.set('seconds', seconds);
						}

						if (!Ember.isNone(cost)) {
							item.set('cost', cost);
						}
					});
					return data.segments.sortBy('startTime').reverse();
				});
			});
		}
	});
});