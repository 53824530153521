define('busy-app/models/chargify-customer-statement-pdf', ['exports', 'ember-data', '@busy-web/data'], function (exports, _emberData, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend(_data.RPCModelMixin, {
    _clientName: 'statement-rpc',
    _methodName: 'get-pdf-statement',

    url: attr('string')
  });
});