define('busy-app/models/integration-attempt', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    startTime: _emberData.default.attr('number'),
    endTime: _emberData.default.attr('number'),
    progress: _emberData.default.attr('number'),
    success: _emberData.default.attr('boolean'),
    errorType: _emberData.default.attr('string'),
    errorMessage: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),
    organizationIntegrationId: _emberData.default.attr('string'),

    organizationIntegration: _emberData.default.belongsTo('organization-integration', { inverse: null, referenceKey: 'id', foreignKey: 'organizationIntegrationId' }),
    integrationLogs: _emberData.default.hasMany('integration-log'),

    /**
     * Cache object for holding shared promises
     *
     * @property resources
     * @type EmberObject
     * @private
     */
    resources: Ember.Object.create(),

    getIntegrationLogs: function getIntegrationLogs() {
      // return this.getMockIntegrationLogs(this.get('id'));
      return this.get('store').findAll('integration-log', this.get('id'));
    },


    /**
     * Linked Resource Lookups
    *
    * @public
    * @method getLogResource
    * @param resourceType {string}
    * @param resourceId {string}
    * @return {EmberPromise}
     */
    getLogResource: function getLogResource(resourceType, resourceId) {
      var _this = this;

      (false && !(!Ember.isNone(resourceType)) && Ember.assert('integration-attempt :: getLogResource :: \'resourceType\' is a required parameter', !Ember.isNone(resourceType)));
      (false && !(!Ember.isNone(resourceId)) && Ember.assert('integration-attempt :: getLogResource :: \'resourceId\' is a required parameter', !Ember.isNone(resourceId)));

      // pools promises so that each resource only gets looked up once

      var cacheKey = 'resources.' + resourceType + '-' + resourceId;

      var resource = this.get(cacheKey);

      if (Ember.isNone(resource)) {
        resource = new Ember.RSVP.Promise(function (resolve) {
          var resourceGetters = {
            'employee': function employee() {
              return _this.getLogResourceEmployee(resourceId);
            },
            'project': function project() {
              return _this.getLogResourceProject(resourceId);
            },
            'costcode': function costcode() {
              return _this.getLogResourceCostCode(resourceId);
            }
          };

          if (resourceGetters.hasOwnProperty(resourceType)) {
            return resolve(resourceGetters[resourceType]());
          } else {
            throw resourceType + ' is not a supported resourceType';
          }
        });

        this.set(cacheKey, resource);
      }

      return resource;
    },
    getLogResourceByModel: function getLogResourceByModel(resourceId, modelType, modelKey) {
      (false && !(!Ember.isNone(resourceId)) && Ember.assert('integration-attempt :: getLogResourceByModel :: \'resourceId\' is a required parameter', !Ember.isNone(resourceId)));
      (false && !(!Ember.isNone(modelType)) && Ember.assert('integration-attempt :: getLogResourceByModel :: \'modelType\' is a required parameter', !Ember.isNone(modelType)));
      (false && !(!Ember.isNone(modelKey)) && Ember.assert('integration-attempt :: getLogResourceByModel :: \'modelKey\' is a required parameter', !Ember.isNone(modelKey)));


      return this.get('store').findRecord(modelType, resourceId).then(function (model) {
        if (!Ember.isNone(model)) {
          return Ember.Object.create({ name: model.get(modelKey) });
        } else {
          return Ember.Object.create();
        }
      });
    },
    getLogResourceEmployee: function getLogResourceEmployee(resourceId) {
      (false && !(!Ember.isNone(resourceId)) && Ember.assert('integration-attempt :: getLogResourceEmployee :: \'resourceId\' is a required parameter', !Ember.isNone(resourceId)));


      return this.getLogResourceByModel(resourceId, 'member', 'fullName');
    },
    getLogResourceProject: function getLogResourceProject(resourceId) {
      (false && !(!Ember.isNone(resourceId)) && Ember.assert('integration-attempt :: getLogResourceProject :: \'resourceId\' is a required parameter', !Ember.isNone(resourceId)));


      return this.getLogResourceByModel(resourceId, 'project', 'title');
    },
    getLogResourceCostCode: function getLogResourceCostCode(resourceId) {
      (false && !(!Ember.isNone(resourceId)) && Ember.assert('integration-attempt :: getLogResourceCostCode :: \'resourceId\' is a required parameter', !Ember.isNone(resourceId)));


      return this.getLogResourceByModel(resourceId, 'cost-code', 'costCodeLabel');
    }
  });
});