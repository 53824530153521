define('busy-app/components/time/time-conflict/resolve-conflicts', ['exports', '@busy-web/utils', 'busy-app/utils/time-entry-validator'], function (exports, _utils, _timeEntryValidator) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		analytics: Ember.inject.service('analytics'),
		auth: Ember.inject.service('auth'),

		classNames: ['time-conflict-resolve-conflicts'],

		model: null,
		member: null,
		currentConflict: null,

		showDetails: false,
		showEdit: false,
		showMerge: false,

		isDisabled: false,

		init: function init() {
			this._super();
			this.get('auth.organization');
			this.setupConflict();

			if (this.get('features.locks')) {
				_utils.Assert.isObject(this.get('member'));
				return this.get('member.memberLock');
			}
		},


		dayString: Ember.computed('currentConflict.model.startTime', function () {
			var date = '';
			if (!Ember.isNone(this.get('currentConflict.model.startTime'))) {
				date = _utils.Time.date(this.get('currentConflict.model.startTime')).format('MMMM Do');
			}
			return date;
		}),

		conflictNumber: Ember.computed('model', 'model.[]', 'currentConflict', function () {
			var index = 0;
			if (!Ember.isNone(this.get('model'))) {
				index = this.get('model').indexOf(this.get('currentConflict')) + 1;
			}
			return index;
		}),

		isSelf: Ember.computed('member.id', 'auth.member.id', function () {
			if (this.get('member.id') === this.get('auth.member.id')) {
				return true;
			}
			return false;
		}),

		setupConflict: function setupConflict() {
			//observer('model', 'model.[]', function() {
			if (!Ember.isNone(this.get('model'))) {
				this.setCurrentConflict(0);
			}
		}, //),

		setCurrentConflict: function setCurrentConflict(currentConflict) {
			if (currentConflict >= 0 && this.get('model.length') > currentConflict) {
				var conflict = this.get('model').objectAt(currentConflict);
				if (!Ember.isNone(conflict)) {
					this.set('currentConflict', conflict);
				}
			}
		},


		hasNextConflict: Ember.computed('model.[]', 'currentConflict', function () {
			if (!Ember.isNone(this.get('model')) && this.get('model').indexOf(this.get('currentConflict')) + 1 < this.get('model.length')) {
				return true;
			}
			return false;
		}),

		hasPrevConflict: Ember.computed('model.[]', 'currentConflict', function () {
			if (!Ember.isNone(this.get('model')) && this.get('model').indexOf(this.get('currentConflict')) > 0) {
				return true;
			}
			return false;
		}),

		setNextCurrentConflict: function setNextCurrentConflict() {
			this.setCurrentConflict(this.get('model').indexOf(this.get('currentConflict')) + 1);
		},
		setPrevCurrentConflict: function setPrevCurrentConflict() {
			this.setCurrentConflict(this.get('model').indexOf(this.get('currentConflict')) - 1);
		},
		refactorConflicts: function refactorConflicts() {
			var _this = this;

			var curConflict = this.get('currentConflict');

			// remove the resolved conflict from the list
			this.get('model').removeObject(curConflict);

			var ids = [];
			var remove = [];
			this.get('model').forEach(function (conflict) {
				// Check for the curConflict main entry and conflict entry to match any main entries left in theconflict list.
				// Most of these should still be valid conflicts, the model may have to be replaced to reflect the changes
				// from the last resolution.
				if (curConflict.get('model.id') === conflict.get('model.id') || curConflict.get('conflictModel.id') === conflict.get('model.id')) {
					conflict.set('model', curConflict.get('model'));

					// keep track of any conflict combinations to eliminate any double conflicts
					// or inverse conflicts.
					var cid = conflict.get('model.id') + conflict.get('conflictModel.id');
					if (ids.indexOf(cid) === -1) {
						// set both combinations of the id hash to catch inverse conflict relationships
						// that get created from the previous merge.
						ids.push(cid);
						ids.push(conflict.get('conflictModel.id') + conflict.get('model.id'));
					} else {
						// remove the doubles
						remove.pushObject(conflict);
					}
				}

				// Check for the resolved main entry to match any conflict entries in the list.
				// Most of these will continue to get filter by the hasConflict check later on
				// but the duplicate check still aplies in this case as well.
				if (curConflict.get('model.id') === conflict.get('conflictModel.id')) {
					conflict.set('conflictModel', curConflict.get('model'));

					var _cid = conflict.get('model.id') + conflict.get('conflictModel.id');
					if (ids.indexOf(_cid) === -1) {
						ids.push(_cid);
						ids.push(conflict.get('conflictModel.id') + conflict.get('model.id'));
					} else {
						remove.pushObject(conflict);
					}
				}

				// remove and resolved conflicts that match other conflicts, this in all cases found
				// will not result in future conflicts so they can be discarded now.
				if (curConflict.get('conflictModel.id') === conflict.get('conflictModel.id')) {
					remove.pushObject(conflict);
				}

				// check to see if any replaced conflicts are no longer in conflict with each other.
				if (!_this.hasConflict(conflict)) {
					remove.pushObject(conflict);
				}
			}, this);

			// remove all non conflict objects.
			this.get('model').removeObjects(remove);
		},
		hasConflict: function hasConflict(conflict) {
			// any entries matched with deleted conflicts will no longer be conflicts
			if (conflict.get('model.isDeleted') || conflict.get('conflictModel.isDeleted')) {
				return false;
			}

			// check for conflicts.
			return _timeEntryValidator.default.hasConflict(conflict.get('model'), conflict.get('conflictModel'));
		},
		resolvedConflict: function resolvedConflict() {
			var index = this.get('model').indexOf(this.get('currentConflict'));
			var lastIndex = this.get('model.length') - 1;

			this.set('conflictResolved', true);
			this.refactorConflicts();

			var hasEntries = false;
			var time = 1500;
			if (lastIndex > index) {
				time = 2500;
				hasEntries = true;
				this.setCurrentConflict(index);
			} else if (lastIndex > 0) {
				time = 2500;
				hasEntries = true;
				this.setCurrentConflict(lastIndex);
			}

			Ember.run.later(this, function () {
				this.set('conflictResolved', false);
				if (!hasEntries) {
					// send onClose action
					this.sendAction('onClose');

					// send on back action
					this.sendAction('onBack');
				}
			}, time);
		},


		/**
   * Is the currently selected `startTime` before the lock date
   *
  * @public
   * @property isSelectedDateLocked
   * @type {boolean}
   */
		isSelectedDateLocked: Ember.computed('features.locks', 'member', 'member.memberLock.effectiveDate', 'currentConflict.model.startTime', function () {
			var member = this.get('member');
			if (!Ember.isNone(this.get('member.content'))) {
				member = this.get('member.content');
			}
			return this.get('features.locks') && member && member.isDateLocked(this.get('currentConflict.model.startTime'));
		}),

		showLockError: function showLockError() {
			this.get('analytics').addEvent(84);
			return this.get('busySave').showErrorMessage((0, _utils.loc)("This time period has been locked to prevent editing."));
		},


		actions: {
			showDetails: function showDetails(item) {
				this.set('detailItem', item);
				this.set('showDetails', true);
			},
			hideDetails: function hideDetails() {
				this.set('detailItem', null);
				this.set('showDetails', false);
			},
			editTimeEntry: function editTimeEntry(entry) {
				if (this.get('isSelectedDateLocked')) {
					return this.showLockError();
				}

				this.set('editItem', entry);
				this.set('showEdit', true);
			},
			closeEdit: function closeEdit() {
				this.set('editItem', null);
				this.set('showEdit', false);
			},
			editSavedAction: function editSavedAction() {
				this.set('showEdit', false);
				this.set('editItem', null);
				if (!this.hasConflict(this.get('currentConflict'))) {
					this.resolvedConflict();
				}
			},
			deleteAction: function deleteAction(entry) {
				if (this.get('isSelectedDateLocked')) {
					return this.showLockError();
				}

				this.set('deleteEntry', entry);
				this.set('showDeleteOverlay', true);
			},
			confirmDelete: function confirmDelete() {
				var _this2 = this;

				var dialog = this.get('busySave');
				dialog.showSavingMessage('Deleting');

				this.set('showDeleteOverlay', false);
				this.get('deleteEntry').destroyRecord().then(function () {
					dialog.showSavedMessage('Deleted');
					_this2.set('deleteEntry', null);
					_this2.resolvedConflict();
				});
			},
			cancelDelete: function cancelDelete() {
				this.set('deleteEntry', null);
				this.set('showDeleteOverlay', false);
			},
			closeSuccess: function closeSuccess() {
				this.set('conflictResolved', false);
			},
			openMerge: function openMerge() {
				this.set('showMerge', true);
			},
			closeMerge: function closeMerge() {
				this.set('showMerge', false);
			},
			nextCurrentConflict: function nextCurrentConflict() {
				if (this.get('hasNextConflict')) {
					this.setNextCurrentConflict();
				}
			},
			prevCurrentConflict: function prevCurrentConflict() {
				if (this.get('hasPrevConflict')) {
					this.setPrevCurrentConflict();
				}
			},
			conflictResolvedAction: function conflictResolvedAction() {
				this.set('showMerge', false);
				this.resolvedConflict();
			},
			backAction: function backAction() {
				this.sendAction('onBack');
			},
			itemDeleted: function itemDeleted() {
				this.set('showEdit', false);
				this.set('editItem', null);
				this.resolvedConflict();
			}
		}
	});
});