define('busy-app/adapters/application-platform-etl', ['exports', 'busy-app/adapters/application', 'busy-app/utils/settings'], function (exports, _application, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		host: Ember.computed(function () {
			return _settings.default.get('etl_plat_url');
		}),

		isBatchEnabled: Ember.computed(function () {
			return false;
		})
	});
});