define('busy-app/utils/models/collections/time-off-report', ['exports', 'busy-app/utils/models/collections/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		member: null,

		applyData: function applyData(model, member) {
			this.set('member', member);
			this._super(model);
		},


		totalSeconds: Ember.computed('model.seconds', function () {
			return this.get('model').filterBy('paid', true).mapBy('seconds').reduce(function (a, b) {
				return a + b;
			}, 0);
		}),

		totalDaysUnpaid: Ember.computed('model.seconds', function () {
			return this.get('model').filterBy('paid', false).get('length');
		})
	});
});