define('busy-app/helpers/map-by', ['exports', 'busy-app/utils/mod/array', 'busy-app/utils/logger'], function (exports, _array, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.mapBy = mapBy;

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function _toArray(arr) {
		return Array.isArray(arr) ? arr : Array.from(arr);
	}

	function mapBy(params) {
		var _params$slice = params.slice(0),
		    _params$slice2 = _toArray(_params$slice),
		    list = _params$slice2[0],
		    keys = _params$slice2.slice(1);

		if (Ember.isEmpty(list)) {
			return list;
		}

		if (list.toArray) {
			list = list.toArray();
		}

		if (Ember.isEmpty(keys)) {
			_logger.default.error('<helper:map-by', 'mapBy helper got a empty mapBy \'keys\' [ ' + keys + ' ].');
			return [];
		}

		// format keys without dots and special syntax like @each
		keys = normailizeKeys(keys);

		// return the mapped resutls
		var result = mapRecursive(list, keys);
		return (0, _array.compact)(result || []);
	}

	var mapRecursive = function mapRecursive(list, keys) {
		// return if the keys list is empty
		if (keys.length <= 0) {
			list = list.compact();
			return list;
		}

		// get the next key from the bottom
		// of the keys array
		var key = keys.shift();

		// handle objects in path
		if (!Ember.isNone(list) && (typeof list === 'undefined' ? 'undefined' : _typeof(list)) === 'object') {
			if (!Array.isArray(list)) {
				// not an array so it must be an object
				// use handleMapResult to get the value from the object
				// and return it or allow handleMapResult to recurse the map
				return handleMapResult(list, key, keys.slice(0));
			} else if (key === 'firstObject') {
				// handle firstObject keys
				key = key.shift();

				// get the 0 array element and pass it to handleMapResult
				list = handleMapResult(list[0], key, keys.slice(0));
			} else {
				// map the list using the handleMapResult so it can call mapRecursive
				// if it needs to.
				list = list.map(function (m) {
					return handleMapResult(m, key, keys.slice(0));
				});
			}

			// result could be a list of list so flatten the lists into one list
			return mergeLists(list);
		} else {
			// something other than an array or object
			// so just return it.
			return list;
		}
	};

	var handleMapResult = function handleMapResult(obj, key, keys) {
		// get the value from the map item
		var value = Ember.get(obj, key);

		// if it is an array call the map again
		// with the keys list
		if (Array.isArray(value) && !Ember.isEmpty(value)) {
			value = mapRecursive(value, keys);
		}
		return value;
	};

	var mergeLists = function mergeLists(list) {
		if (!Ember.isEmpty(list)) {
			var _ref;

			// merge each list in the list
			// into one list.
			return (_ref = []).concat.apply(_ref, _toConsumableArray(list));
		}
		return list;
	};

	var normailizeKeys = function normailizeKeys(keys) {
		var mapKeys = keys.map(function (k) {
			var keysplit = k.split('.');
			return keysplit.filter(function (k) {
				return ['@each', '[]'].indexOf(k) === -1;
			});
		});
		return mergeLists(mapKeys);
	};

	exports.default = Ember.Helper.helper(mapBy);
});