define('busy-app/components/busy-save-message', ['exports', '@busy-web/utils/loc'], function (exports, _loc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['busy-save-message'],

    classNameBindings: ['isLoading:active', 'minimal'],

    isLoading: false,
    minimal: false,
    loadingMessage: (0, _loc.default)('Loading...')
  });
});