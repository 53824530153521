define('busy-app/utils/container', ['exports', 'busy-app/utils/container/container-manager', '@busy-web/utils'], function (exports, _containerManager, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (models) {
    _utils.Assert.isArray(models);

    var manager = _containerManager.default.create();

    for (var _len = arguments.length, types = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      types[_key - 1] = arguments[_key];
    }

    return manager.generate.apply(manager, [models].concat(types));
  };
});