define('busy-app/components/d-button', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['d-button'],
		classNameBindings: ['isActive:active', 'isSelected:selected'],
		attributeBindings: ['tabindex'],

		tagName: 'a',

		isActive: false,
		isSelected: false,
		disabled: false,
		tabindex: null,
		name: '',
		idx: 0,

		init: function init() {
			this._super();
			this.setDisabled(this.get('disabled'));
		},
		setDisabled: function setDisabled(disabled) {
			var _this = this;

			if (!Ember.isEmpty(this.$())) {
				Ember.run.next(null, function () {
					if (!_this.get('isDestroyed')) {
						_this.$().attr('disabled', disabled);
					}
				});
			} else {
				Ember.run.next(null, function () {
					if (!_this.get('isDestroyed')) {
						_this.setDisabled(disabled);
					}
				});
			}
		},


		checkDisable: Ember.observer('disabled', function () {
			this.setDisabled(this.get('disabled'));
		}),

		submitAction: function submitAction() {
			this.sendAction('onSubmit', this.get('idx'));
		},
		click: function click() {
			this.submitAction();
		},
		keyPress: function keyPress(e) {
			if (e.which === 13) {
				this.submitAction();
			}
		},
		focusIn: function focusIn(e) {
			this.sendAction('onFocusIn', e);
		},
		focusOut: function focusOut(e) {
			this.sendAction('onFocusOut', e);
		},
		keyUp: function keyUp(e) {
			this.sendAction('onKeyUp', e);
		},
		keyDown: function keyDown(e) {
			this.sendAction('onKeyDown', e);
		}
	});
});