define('busy-app/transforms/duration-string', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		deserialize: function deserialize(serialized, _ref) {
			var defaultValue = _ref.defaultValue;

			if (Ember.isEmpty(serialized) && Ember.isEmpty(defaultValue)) {
				return null;
			}

			var val = Ember.isEmpty(serialized) ? defaultValue : serialized;
			return _moment.default.duration(val).asSeconds();
		},
		serialize: function serialize(deserialized) {
			if (Ember.isEmpty(deserialized)) return;

			// pad numbers with leading zero
			var padTime = function padTime(time) {
				return time < 10 ? '0' + time : '' + time;
			};

			var type = void 0;
			if (typeof deserialized === 'number') {
				type = 'seconds';
			}

			// create duration object
			var duration = _moment.default.duration(deserialized, type);

			// create string
			var str = [padTime(duration.hours()), padTime(duration.minutes()), padTime(duration.seconds())].join(':');

			return str;
		}
	});
});