define('busy-app/components/time/time-off/view', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c--time--time-off-entry--view'],

		auth: Ember.inject.service(),
		features: Ember.inject.service(),
		store: Ember.inject.service(),

		// attributes
		id: null,
		onSaved: null,
		onCancel: null,

		// properties
		model: null,

		init: function init() {
			this._super.apply(this, arguments);

			if (!Ember.isNone(Ember.get(this, 'id'))) {
				this.loadData(Ember.get(this, 'id'));
			}
		},
		loadData: function loadData(id) {
			Ember.set(this, 'model', Ember.get(this, 'store').findRecord('time-off', id));
		},
		safeSet: function safeSet(key, val) {
			if (!Ember.get(this, 'isDestroyed')) {
				Ember.set(this, key, val);
			}
		},


		actions: {
			editTimeOffEntry: function editTimeOffEntry() {
				Ember.set(this, 'showEditDialog', true);
			},
			closeEditDialog: function closeEditDialog() {
				Ember.set(this, 'showEditDialog', false);
			},
			itemDeleted: function itemDeleted() {
				var _this = this;

				this.safeSet('model', null);
				this.sendAction('onSaved');

				Ember.run.next(this, function () {
					_this.safeSet('showEditDialog', false);
					_this.sendAction('onCancel'); // closes this view dialog
				});
			},
			onSaved: function onSaved() {
				var _this2 = this;

				this.safeSet('model', null);
				this.sendAction('onSaved');

				Ember.run.next(this, function () {
					_this2.loadData(Ember.get(_this2, 'id'));
					_this2.safeSet('showEditDialog', false);
				});
			}
		}

	});
});