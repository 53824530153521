define('busy-app/mixins/safety-signoff', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		service: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		verifySafetyAndSave: function verifySafetyAndSave(member, safetySignOff, safetySignOffRequired, callBackPromise) {
			var _this = this;

			// show daily sign off if safety or accuracy is enabled on organization
			if (this.get('features.safetySignOff') && (this.get('auth.organization.safetySignature') || this.get('auth.organization.timeAccuracy'))) {
				// only show the safety signoff if it has not been completed
				if (!Ember.isNone(member) && member.id === this.get('auth.member.id')) {
					this.set(safetySignOff, true);
				} else {
					// this will always be called when the org has signoff settings on
					// and a group entry is used.
					this.set(safetySignOffRequired, true);
				}

				// create a promise to wait for the signoff trigger event to be fired.
				return new Ember.RSVP.Promise(function (resolve, reject) {
					_this.on('safetySignOffComplete', function (result) {
						if (result) {
							callBackPromise().then(function (model) {
								return resolve(model);
							}).catch(function (err) {
								return reject(err);
							});
						} else {
							resolve(false);
						}
					});
				});
			}
			return callBackPromise();
		},


		/**
   * call this method from save safety dialog action
   *
   */
		saveSafetySignoff: function saveSafetySignoff() {
			this.trigger('safetySignOffComplete', true);
		},


		/**
   * call this method from the cancel save safety dialog action
   *
   */
		cancelSafetySignoff: function cancelSafetySignoff() {
			this.trigger('safetySignOffComplete', false);
		}
	});
});