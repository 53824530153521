define('busy-app/components/time/time-off/create-time-off', ['exports', 'moment', '@busy-web/utils', 'p-limit'], function (exports, _moment, _utils, _pLimit) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var throttledPromise = (0, _pLimit.default)(1);

	/**
  * `Components/Time/TimeOff/CreateTimeOff`
  *
  * @class CreateTimeOff
  * @namespace Components.Time.TimeOff
  * @extends Component
  * @uses Services.Auth
  * @uses Services.Analtyics
  * @uses Moment
  * @uses Utils.Assert
  * @uses Utils.Loc
  * @uses Utils.LocaleFormate
  * @uses Utils.Time
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['v-time-card-dialog-new-time-off'],

		analytics: Ember.inject.service('analytics'),
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		date: null,
		dateStamp: null,
		member: null,
		memberEntries: null,
		memberMode: 'single',
		timeOff: null,
		isPaid: false,
		type: null,

		totalTime: 0,

		totalHours: 0,
		totalMinutes: 0,
		isSelectedDateLocked: true,

		lockDate: 0,

		format: _utils.Locale.format('ddd, MMM DD', _moment.default.locale()),

		isMultiMemberMode: Ember.computed.equal('memberMode', 'multi'),
		useGroupActionThrottle: Ember.computed.and('features.groupActionThrottle', 'isMultiMemberMode'),

		init: function init() {
			this._super();

			if (!Ember.isNone(this.get('memberEntries'))) {
				this.set('memberMode', 'multi');
			}

			this.setDates();
			this.get('auth.member');

			this.set('typesList', [Ember.Object.create({ key: 'holiday', value: (0, _utils.loc)('Holiday') }), Ember.Object.create({ key: 'personal', value: (0, _utils.loc)('Personal') }), Ember.Object.create({ key: 'sick', value: (0, _utils.loc)('Sick') }), Ember.Object.create({ key: 'vacation', value: (0, _utils.loc)('Vacation') }), Ember.Object.create({ key: 'other', value: (0, _utils.loc)('Other') })]);

			this.setLockDate();
		},
		setLockDate: function setLockDate() {
			var _this = this;

			if (!Ember.isNone(this.get('memberEntries'))) {
				var promise = [];
				this.get('memberEntries').forEach(function (member) {
					var lock = member.get('memberLock');
					if (lock.then) {
						promise.push(lock);
					} else {
						promise.push(Ember.RSVP.resolve(lock));
					}
				});

				Ember.RSVP.all(promise).then(function () {
					var lockDate = 0;
					_this.get('memberEntries').forEach(function (member) {
						if (member.get('lockDate') > lockDate) {
							lockDate = member.get('lockDate');
						}
					});
					_this.set('lockDate', lockDate);
				});
			} else {
				var lock = this.get('member.memberLock');
				if (lock.then) {
					lock.then(function () {
						_this.set('lockDate', _this.get('member.lockDate'));
					});
				} else {
					this.set('lockDate', this.get('member.lockDate'));
				}
			}
		},


		setTotalTime: Ember.observer('isPaid', function () {
			if (this.get('isPaid')) {
				this.set('totalTime', 28800);
			} else {
				this.set('totalTime', 0);
			}
		}),

		setDates: function setDates() {
			// open the dialog to this date or default to now
			var now = _utils.Time.timestamp();
			var date = this.get('date');
			if (Ember.isNone(date) || date > now) {
				date = now;
			}

			//sets the minimum and maximum date that the user can select
			this.set('mindate', (0, _moment.default)().add(-89, 'days').unix());
			this.set('maxdate', (0, _moment.default)().add(366, 'days').unix());

			var start = _utils.Time.date(date).startOf('day').unix();
			this.set('oldStartDate', start);
			this.set('dateStamp', start);

			if (this.get('features.locks')) {
				if (this.get('membersCombined').length && this.get('membersWithLocksBeforeStartTime').length) {
					this.get('analytics').addEvent(84);
				}
			}
		},
		getMemberId: function getMemberId() {
			var memberId = this.get('auth.member.id');
			if (!Ember.isNone(this.get('member'))) {
				memberId = this.get('member.id');
			}
			return memberId;
		},
		createTimeOff: function createTimeOff(member, isMulti, allowOverlap) {
			if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
				member = Ember.get(member, 'content');
			}

			var dateStamp = _utils.Time.date(this.get('dateStamp')).startOf('day').unix();
			var type = this.get('type');
			var description = this.get('description');
			var paid = this.get('isPaid');
			var seconds = this.get('totalTime');

			if (allowOverlap) {
				// submit happy oficer event
				var source = 'from_' + this.get('analytics').generateTitle(window.location.pathname);
				this.get('analytics').addEvent(58, { navSource: source, targetMemberIds: [this.get('member.id')] });
			}

			if (Ember.get(this, 'useGroupActionThrottle')) {
				var numEntries = this.get('membersCombined.length');
				var pendingCount = throttledPromise.pendingCount;

				var completed = numEntries - pendingCount;
				var saveProgress = completed + ' / ' + numEntries;
				var savingMessage = (0, _utils.loc)('Saving') + ' ' + saveProgress + ' ';

				this.get('busySave').showSavingMessage(savingMessage);
			}

			return member.createTimeOffEntry(dateStamp, type, isMulti, seconds, paid, description, allowOverlap);
		},


		/**
   * Create a unified array that contains either one `member` or all members in `memberEntries`
   *
   * @public
   * @property membersCombined
   * @type {Models.Member[]}
   */
		membersCombined: Ember.computed('member', 'memberEntries', function () {
			return this.get('memberMode') === 'multi' ? [].concat(_toConsumableArray(this.get('memberEntries'))) : [this.get('member')];
		}),

		/**
   * Filtered array of `membersCombined` who have a lock date before the selected `dateStamp`
   *
   * @public
   * @property membersWithLocksBeforeStartTime
   * @type {Models.Member[]}
   */
		membersWithLocksBeforeStartTime: Ember.computed('lockDate', 'dateStamp', 'membersCombined', function () {
			if (this.get('features.locks')) {
				var startDate = this.get('dateStamp');
				this.get('membersCombined').filter(function (member) {
					if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
						member = Ember.get(member, 'content');
					}
					member.isDateLocked(startDate);
				});

				if (this.get('lockDate') > this.get('dateStamp')) {
					return true;
				}
			}
			return false;
		}),

		/**
   * Error message to be shown for a `dateStamp` which violates a lock date
   *
   * @public
   * @property lockDateErrorMessage
   * @type {string}
   */
		lockDateErrorMessage: Ember.computed('membersWithLocksBeforeStartTime', 'memberMode', function () {
			if (this.get('memberMode') === 'single') {
				var msg = (0, _utils.loc)('Date must be later than the lock date');
				var date = _utils.Time.date(this.get('member.memberLock.effectiveDate')).format('L');
				return msg + ': ' + date;
			} else {
				var members = this.get('membersWithLocksBeforeStartTime');
				var numMembers = members.get('length');
				var _msg = (0, _utils.loc)('employees have lock dates before the selected date');

				return numMembers + ' ' + _msg;
			}
		}),

		actions: {
			typeTimeOffSelected: function typeTimeOffSelected(item) {
				if (item.get('key') === 'holiday') {
					this.set('type', 10);
				} else if (item.get('key') === 'personal') {
					this.set('type', 20);
				} else if (item.get('key') === 'sick') {
					this.set('type', 30);
				} else if (item.get('key') === 'vacation') {
					this.set('type', 40);
				} else if (item.get('key') === 'other') {
					this.set('type', 50);
				}
			},
			cancelAction: function cancelAction() {
				// submit happy officer event
				var source = 'from_' + this.get('analytics').generateTitle(window.location.pathname);
				this.get('analytics').addEvent(56, { navSource: source, targetMemberIds: [this.get('member.id')] });

				//Close the Dialog onCancel. Notice Happy Officer below
				this.sendAction('onClose');
			},


			/**
    * closes dialog if clicked on cancel button or clicked in background window
    *
    * @event close
    */
			closeAction: function closeAction() {
				this.sendAction('onClose');
			},
			timeOffSave: function timeOffSave(busySave) {
				var _this2 = this;

				var useGroupActionThrottle = Ember.get(this, 'useGroupActionThrottle');
				var allowOverlap = true;
				var isMultiMemberMode = Ember.get(this, 'isMultiMemberMode');

				this.set('busySave', busySave);

				if (this.get('membersWithLocksBeforeStartTime')) {
					return Ember.RSVP.reject((0, _utils.loc)('Errors must be fixed before saving.'));
				} else if (Ember.isNone(this.get('type'))) {
					return Ember.RSVP.reject((0, _utils.loc)('You must select a type.'));
				} else if (this.get('isPaid') && this.get('totalTime') <= 0) {
					return Ember.RSVP.reject((0, _utils.loc)('Hours must be more than 0.'));
				} else {
					if (isMultiMemberMode) {
						var members = this.get('memberEntries');
						var promise = [];

						members.forEach(function (member) {
							var promisedTimeOff = _this2.createTimeOff.bind(_this2, member, isMultiMemberMode, allowOverlap);

							if (useGroupActionThrottle) {
								promisedTimeOff = throttledPromise(promisedTimeOff);
							} else {
								promisedTimeOff = promisedTimeOff();
							}

							promise.push(promisedTimeOff);
						});

						return Ember.RSVP.all(promise).then(function (data) {
							_this2.sendAction('onSave', data);
							return data;
						}).catch(function (err) {
							// eslint-disable-next-line no-console
							console.error('create-time-off => timeOffSave -> err', err, Ember.get(_this2, 'membersCombined'));
							return Ember.RSVP.reject((0, _utils.loc)('An error occurred. Some actions may have completed, please double check and try again.'));
						});
					} else {
						return this.createTimeOff(this.get('member'), isMultiMemberMode, allowOverlap).then(function (model) {
							_this2.sendAction('onSave', model);
							return model;
						}).catch(function (err) {
							if (err && err.message) {
								return Ember.RSVP.reject(err.message);
							} else {
								return Ember.RSVP.reject((0, _utils.loc)('An error occurred. Please double check your entry and try again.'));
							}
						});
					}
				}
			}
		}
	});
});