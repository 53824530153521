define('busy-app/controllers/report/safety-signature/index', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),

    init: function init() {
      this._super();
      this.setTotals();
    },


    groupedModel: Ember.computed('model', function () {
      var _this = this;

      var model = Ember.get(this, 'model');
      var dayObjectArray = [];

      if (!Ember.isNone(model)) {
        var current = Ember.get(model, 'payPeriod.startDate').unix();
        var endDate = Ember.get(model, 'payPeriod.endDate').unix();

        while (current <= endDate) {
          var dayFormat = 'ddd, MMM D';
          if (_utils.Time.date(current).year() !== _utils.Time.date().year()) {
            dayFormat = 'ddd, MMM D, YYYY';
          }

          var dayObject = Ember.Object.create({
            day: _utils.Time.date(current).format(dayFormat),
            startOfDay: _utils.Time.date(current).startOf('day').unix(),
            endOfDay: _utils.Time.date(current).endOf('day').unix(),
            memberIds: [],
            memberTimeEntries: [],
            signatures: [],
            injuredIds: [],
            inaccurateIds: [],
            breakNotFollowedIds: [],
            signed: 0,
            injuries: 0,
            inaccuracies: 0,
            breakIssues: 0
          });

          dayObjectArray.pushObject(dayObject);
          current = _utils.Time.date(current).add(1, 'days').unix();
        }

        dayObjectArray.forEach(function (day) {
          _this.loadTimeEntries(model, day);
          _this.loadSignatures(model, day);

          Ember.get(day, 'memberTimeEntries').forEach(function (object) {
            if (Ember.get(object, 'timeEntries.length')) {
              var sortedEntries = Ember.get(object, 'timeEntries').sortBy('endTime');
              var latestEntry = Ember.get(sortedEntries, 'lastObject');
              Ember.set(object, 'latestEntryTime', Ember.get(latestEntry, 'endTime'));
            }

            if (Ember.get(object, 'signatures.length')) {
              var sortedSignatures = Ember.get(object, 'signatures').sortBy('endTime');
              var latestSignature = Ember.get(sortedSignatures, 'lastObject');
              Ember.set(object, 'latestSignatureTime', Ember.get(latestSignature, 'endTime'));
            }

            if (Ember.get(object, 'latestSignatureTime') >= Ember.get(object, 'latestEntryTime')) {
              Ember.set(day, 'signed', Ember.get(day, 'signed') + 1);
            }
          });

          day.set('allSigned', Ember.get(day, 'signed') === Ember.get(day, 'memberIds.length') && Ember.get(day, 'memberIds.length') > 0);
          day.set('unsigned', Ember.get(day, 'memberIds.length') - Ember.get(day, 'signed'));
        });

        return dayObjectArray.filterBy('memberIds.length').sortBy('startOfDay');
      }
    }),

    setTotals: Ember.observer('groupedModel', function () {
      if (this.get('groupedModel.length') > 0) {
        var groupedModel = this.get('groupedModel');
        var notSigned = 0;
        var incorrectTime = 0;
        var injured = 0;
        var breakIssues = 0;
        groupedModel.forEach(function (item) {
          notSigned += item.get('unsigned');
          incorrectTime += item.get('inaccuracies');
          injured += item.get('injuries');
          breakIssues += item.get('breakIssues');
        });

        this.setProperties({
          notSigned: notSigned,
          incorrectTime: incorrectTime,
          injured: injured,
          breakIssues: breakIssues
        });
      }
    }),

    loadSignatures: function loadSignatures(model, currentObject) {
      Ember.get(model, 'signatures').sortBy('endTime').forEach(function (sig) {
        if (Ember.get(currentObject, 'endOfDay') >= Ember.get(sig, 'startTime') && Ember.get(currentObject, 'startOfDay') <= Ember.get(sig, 'startTime')) {
          var memberId = Ember.get(sig, 'memberId');
          if (Ember.get(sig, 'injured')) {
            if (Ember.get(currentObject, 'injuredIds').indexOf(memberId) < 0) {
              Ember.get(currentObject, 'injuredIds').push(memberId);
              Ember.set(currentObject, 'injuries', Ember.get(currentObject, 'injuries') + 1);
            }
          }

          if (Ember.get(sig, 'timeAccurate') === false) {
            if (Ember.get(currentObject, 'inaccurateIds').indexOf() < 0) {
              Ember.get(currentObject, 'inaccurateIds').push(memberId);
              Ember.set(currentObject, 'inaccuracies', Ember.get(currentObject, 'inaccuracies') + 1);
            }
          } else if (Ember.get(sig, 'timeAccurate') === true) {
            if (Ember.get(currentObject, 'inaccurateIds').indexOf(memberId) > -1) {
              Ember.get(currentObject, 'inaccurateIds').splice(Ember.get(currentObject, 'inaccurateIds').indexOf(memberId), 1);
              Ember.set(currentObject, 'inaccuracies', Ember.get(currentObject, 'inaccuracies') - 1);
            }
          }

          if (Ember.get(sig, 'breakPolicyFollowed') === false) {
            if (Ember.get(currentObject, 'breakNotFollowedIds').indexOf(memberId) < 0) {
              Ember.get(currentObject, 'breakNotFollowedIds').push(memberId);
              Ember.set(currentObject, 'breakIssues', Ember.get(currentObject, 'breakIssues') + 1);
            }
          } else if (Ember.get(sig, 'breakPolicyFollowed') === true) {
            if (Ember.get(currentObject, 'breakNotFollowedIds').indexOf(memberId) > -1) {
              Ember.get(currentObject, 'breakNotFollowedIds').splice(Ember.get(currentObject, 'breakNotFollowedIds').indexOf(memberId), 1);
              Ember.set(currentObject, 'breakIssues', Ember.get(currentObject, 'breakIssues') - 1);
            }
          }

          if (!Ember.get(currentObject, 'signatures').isAny('memberId', memberId)) {
            Ember.get(currentObject, 'signatures').push(sig);
          }

          if (Ember.get(currentObject, 'memberTimeEntries').isAny('memberId', memberId)) {
            var memberEntry = Ember.get(currentObject, 'memberTimeEntries').findBy('memberId', memberId);
            Ember.get(memberEntry, 'signatures').push(sig);
          }
        }
      });
    },
    loadTimeEntries: function loadTimeEntries(model, currentObject) {
      Ember.get(model, 'timeEntries').forEach(function (timeEntry) {
        if (Ember.get(currentObject, 'endOfDay') >= Ember.get(timeEntry, 'startTime') && Ember.get(currentObject, 'startOfDay') <= Ember.get(timeEntry, 'endTime')) {
          var memberId = Ember.get(timeEntry, 'memberId');
          Ember.get(currentObject, 'memberIds').push(memberId);

          Ember.set(currentObject, 'memberIds', Ember.get(currentObject, 'memberIds').uniq());

          var memberTimeEntryObject = Ember.Object.create({
            memberId: memberId,
            timeEntries: [timeEntry],
            signatures: [],
            latestSignatureTime: null,
            latestEntryTime: null
          });

          if (Ember.get(currentObject, 'memberTimeEntries').isAny('memberId', memberId)) {
            var memberEntry = Ember.get(currentObject, 'memberTimeEntries').findBy('memberId', memberId);
            Ember.get(memberEntry, 'timeEntries').push(timeEntry);
          } else {
            Ember.get(currentObject, 'memberTimeEntries').pushObject(memberTimeEntryObject);
          }
        }
      });
    },


    actions: {
      openDateReport: function openDateReport(item) {
        this.transitionToRoute('report.safety-signature.date', {
          queryParams: {
            date: item.get('startOfDay')
          }
        });
      }
    }
  });
});