define("busy-app/utils/_model-errors", ["exports", "@busy-web/utils"], function (exports, _utils) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		"busy-event": {
			"300": (0, _utils.loc)("non-unique primary key"),
			"401": (0, _utils.loc)("organization_id does not exist in the database"),
			"402": (0, _utils.loc)("member_id does not exist in the database"),
			"701": (0, _utils.loc)("source_device_type_id must not be null."),
			"702": (0, _utils.loc)("version must not be null."),
			"703": (0, _utils.loc)("event_type must not be null."),
			"704": (0, _utils.loc)("created_on must not be null.")
		},
		"sign-up-event": {
			"300": (0, _utils.loc)("non-unique primary key"),
			"401": (0, _utils.loc)("member_id does not exist in the database"),
			"701": (0, _utils.loc)("member_id must not be null"),
			"702": (0, _utils.loc)("ad_click_date must not be null"),
			"703": (0, _utils.loc)("created_on must not be null")
		}
	};
});