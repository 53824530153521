define('busy-app/components/company/integrations/vendors/procore/disconnect-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['v-company-settings-integrations-dialog'],

		buttonInfo: null,

		init: function init() {
			var _this = this;

			this._super();

			// pass a custom buttonInfo config to dialog/content-view
			Ember.set(this, 'buttonInfo', [{ action: function action() {
					return _this.send('onDisconnect');
				}, color: 'red', text: (0, _utils.loc)('Disconnect'), closeAfter: true }, { action: function action() {
					return _this.send('onClose');
				}, color: 'grey', text: (0, _utils.loc)('Cancel'), closeAfter: true }]);
		},


		actions: {
			/** @event onClose */
			onClose: function onClose() {
				this.sendAction('onClose');
			},


			/** @event onDisconnect */
			onDisconnect: function onDisconnect() {
				this.sendAction('onDisconnect');
			}
		}
	});
});