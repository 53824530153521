define('busy-app/components/report/time-card-approval/signature-status', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: 'span',
		classNames: ['signature-status'],
		attributeBindings: ['title'],

		title: (0, _utils.loc)('Status'),
		isLoading: null,
		hasNoSeconds: null,
		hasValidSignature: null,
		needsSignatureResign: null,
		signature: null
	});
});