define("busy-app/routes/signup", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _unauthenticatedRouteMixin) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
		interop: Ember.inject.service("interop"),
		features: Ember.inject.service("features"),

		model: function model() {
			Ember.$("body").addClass("signup");
			return Ember.RSVP.resolve(null);
		},
		beforeModel: function beforeModel(transition) {
			if (Ember.get(this, "features.onboardingInterop")) {
				transition.abort();
				return this.redirectToReact();
			}
		},
		redirectToReact: function redirectToReact() {
			var interop = Ember.get(this, "interop");

			var search = window.location.search;

			var onboardPath = "/login" + search;
			var onboardUrl = interop.getReactAppUrl(onboardPath, {}, false);

			var windowHandle = interop.openUrl(onboardUrl, null, {
				newWindow: false,
				replace: true
			});

			return windowHandle;
		}
	});
});