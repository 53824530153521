define('busy-app/utils/integrations/base', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Object.extend({
		features: Ember.inject.service('features'),
		session: Ember.inject.service('session'),

		featureName: null,
		isInitialized: false,

		init: function init() {
			this._super();

			_utils.Assert.test("<featureName::string> must be set to a the feature name for this integration", !Ember.isNone(this.get('featureName')));

			this.set('isInitialized', false);

			//Logger.info('Integrations:', this.get('featureName'), ' - Enabled:', this.isEnabled());

			// require that the feature flag is enabled
			if (this.isEnabled()) {
				this._initializeLoad();
			}
		},
		isAuth: function isAuth() {
			return this.get('session.isAuthenticated');
		},
		isEnabled: function isEnabled() {
			return !Ember.isNone(this.get('featureName')) && this.get('features').isEnabled(this.get('featureName'));
		},
		isReady: function isReady() {
			return this.get('isInitialized');
		},
		_initializeLoad: function _initializeLoad() {
			var _this = this;

			var ready = this.isLoadReady();
			if (ready) {
				this.load(function () {
					return _this._initializeRun();
				});
			} else {
				Ember.run.later(this, function () {
					this._initializeLoad();
				}, 100);
			}
		},
		_initializeRun: function _initializeRun() {
			if (this.isRunReady()) {
				this.set('isInitialized', true);
				this.run();
			} else {
				Ember.run.later(this, function () {
					this._initializeRun();
				}, 100);
			}
		},
		isLoadReady: function isLoadReady() {
			return true;
		},
		isRunReady: function isRunReady() {
			return true;
		},


		/**
   * Called on init to auto load and libraries for an integration and on isLoadReady === true
   *
   * this method is only called if the featureName is enableded in config/features.js
   *
   * this method expects a callback function to be called when the integration is done loading
   *
   * @private
   * @method load
   * @param callback {function}
   */
		load: function load(callback) {
			callback();
		},


		/**
   * Called on init to run the setup for an integration after load and on isRunReady === true
   *
   * this method is only called if the featureName is enableded in config/features.json
   *
   * @private
   * @method run
   */
		run: function run() {}
	});
});