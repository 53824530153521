define('busy-app/models/member-cost', ['exports', 'ember-data', 'busy-app/utils/unique-id'], function (exports, _emberData, _uniqueId) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		// used to match seconds and cost models
		uid: (0, _uniqueId.default)('startDate', 'endDate', 'memberId'),

		memberId: _emberData.default.attr('id', { sortable: true, sortBy: ['member.firstName', 'member.lastName'] }),
		startDate: _emberData.default.attr('date', { sortable: true }),
		endDate: _emberData.default.attr('date', { sortable: true }),
		regularCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overtimeCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		doubletimeCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overburden: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		updatedOn: _emberData.default.attr('date'),
		submittedOn: _emberData.default.attr('date'),

		member: _emberData.default.belongsTo('member', { query: { deleted_on: '_-DISABLE-_' } }),

		totalCost: Ember.computed('regularCost', 'overtimeCost', 'doubletimeCost', function () {
			return this.get('regularCost') + this.get('overtimeCost') + this.get('doubletimeCost');
		}).meta({ aggregateType: 'sum' }),

		totalOverburdenCost: Ember.computed('totalCost', 'overburden', function () {
			return this.get('totalCost') + this.get('overburden');
		}).meta({ aggregateType: 'sum' })
	});
});