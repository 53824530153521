define('busy-app/routes/time-card/time-off-entry', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		queryParams: {
			id: {
				refreshModel: true
			},
			member_id: {
				refreshModel: true
			}
		},

		model: function model(params) {
			var _this = this;

			return this.store.findRecord('time-off', params.id).then(function (model) {
				_this.store.findRecord('member', params.member_id).then(function (member) {
					model.set('member', member);
					if (model.get('memberId') !== model.get('submitterMemberId')) {
						_this.store.findRecord('member', model.get('submitterMemberId')).then(function (submitMember) {
							model.set('submitMember', submitMember);
						});
					}
				});
				return model;
			});
		}
	});
});