define('busy-app/components/time/time-sheet/summary', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-sheet--summary'],

		member: null,
		memberDaySeconds: null,

		reportTotals: Ember.computed('memberDaySeconds', 'memberDayCost', function () {
			var _EmberGetProperties = Ember.getProperties(this, ['memberDaySeconds', 'memberDayCost']),
			    memberDaySeconds = _EmberGetProperties.memberDaySeconds,
			    memberDayCost = _EmberGetProperties.memberDayCost;

			var reportTotals = {
				regular: 0,
				overtime: 0,
				doubletime: 0,
				totalSeconds: 0,
				regularCost: 0,
				overtimeCost: 0,
				doubletimeCost: 0,
				totalCost: 0
			};

			if (Ember.isNone(memberDaySeconds) && Ember.isNone(memberDayCost)) {
				return reportTotals;
			}

			memberDaySeconds.forEach(function (item) {
				reportTotals.regular += Ember.get(item, 'regular');
				reportTotals.overtime += Ember.get(item, 'overtime');
				reportTotals.doubletime += Ember.get(item, 'doubletime');
				reportTotals.totalSeconds += Ember.get(item, 'totalSeconds');
			});

			memberDayCost.forEach(function (item) {
				reportTotals.regularCost += Ember.get(item, 'regularCost');
				reportTotals.overtimeCost += Ember.get(item, 'overtimeCost');
				reportTotals.doubletimeCost += Ember.get(item, 'doubletimeCost');
				reportTotals.totalCost += Ember.get(item, 'totalCost');
			});

			// Logger.info(this, 'reportTotals', reportTotals);

			return reportTotals;
		})
	});
});