define('busy-app/utils/parse', ['exports', '@busy-web/components/utils/parse'], function (exports, _parse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _parse.default;
    }
  });
});