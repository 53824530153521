define('busy-app/components/projects/report-index', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c-projects-report-index'],

    auth: Ember.inject.service(),
    features: Ember.inject.service('features'),
    proFeature: Ember.inject.service('pro-feature'),
    interop: Ember.inject.service('interop'),

    project: null,
    organization: null,

    proFeatureManager: null,

    allowPayrollReport: Ember.computed('features.payroll-report', function () {
      this.get('features.payroll-report');
      return this.get('proFeature').allowProFeature('payroll-report');
    }),

    allowDailyReport: Ember.computed.alias('features.dailyProjectReport'),

    // show the badge if the user is not allowed access to payroll reports
    proBadgeHtml: Ember.computed('allowPayrollReport', function () {
      return !this.get('allowPayrollReport') ? '<span class="pro-feature-badge d-badge blue">' + (0, _utils.loc)('PRO') + '</span>' : null;
    }),

    init: function init() {
      this._super();
      this.set('proFeatureManager', this.get('proFeature').getManager());
    },
    requestPayrollReport: function requestPayrollReport(actionRequested) {
      this.get('proFeatureManager').requestAction('payroll-report', actionRequested);
    },


    actions: {
      dispatchReport: function dispatchReport(report) {
        this.sendAction('onTransition', report);
      },
      dispatchPayrollReport: function dispatchPayrollReport(report) {
        var _this = this;

        this.requestPayrollReport(function () {
          return _this.send('dispatchReport', report);
        });
      },
      openReactApp: function openReactApp(path, event) {
        var _this2 = this;

        this.requestPayrollReport(function () {
          return _this2.get('interop').openReactApp(path, { projectId: _this2.get('project.id') }, event);
        });
      },
      proFeatureManagerOpenModal: function proFeatureManagerOpenModal() {
        this.get('proFeatureManager').openModal();
      },
      proFeatureManagerCloseModal: function proFeatureManagerCloseModal() {
        this.get('proFeatureManager').closeModal();
      },
      proFeatureManagerPlanUpgraded: function proFeatureManagerPlanUpgraded() {
        this.get('proFeatureManager').onAllow();
      }
    }
  });
});