define("busy-app/components/time/time-entry/status", ["exports", "@busy-web/utils", "busy-app/utils/time-entry"], function (exports, _utils, _timeEntry) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ["v-time-card-status"],

		store: Ember.inject.service("store"),
		auth: Ember.inject.service("auth"),
		router: Ember.inject.service("router"),
		interop: Ember.inject.service("interop"),

		selectMenu: null,

		hasHeader: false,
		hasLocation: false,
		isDisabled: false,
		isSuspended: false,
		showNewTimeEntryDialog: false,
		showNewTimeOffDialog: false,
		showClockInAtDialog: false,
		showClockOutAtDialog: false,
		showClockInDialog: false,
		showEditDialog: false,

		actionType: null,
		todaysEntries: null,
		dayObject: null,
		member: null,
		editTime: null,
		organization: null,

		todaySeconds: 0,
		todayBreak: 0,

		dirtyFlag: false,

		init: function init() {
			this._super();
			if (!Ember.isEmpty(this.get("auth.member"))) {
				this.setSelectMenu();
				this.getTodaysCards();
				this.checkLocations();
			} else {
				this.get("interop").openReactApp("/logout");
			}
		},


		/**
   * Sets up the select Menu for this view
   *
   * Observes member.openEntry.id on Init
   *
   * @public
   * @method setSelectMenu
   */
		setSelectMenu: Ember.observer("member.openEntry.id", function () {
			if (Ember.isNone(this.get("member.openEntry"))) {
				this.set("selectMenu", [Ember.Object.create({
					key: "new-time-entry",
					value: (0, _utils.loc)("New Time Entry")
				}), Ember.Object.create({ key: "clock-in-at", value: (0, _utils.loc)("Clock In At") }), Ember.Object.create({ key: "new-time-off", value: (0, _utils.loc)("Add Time Off") })]);
			} else {
				this.set("selectMenu", [Ember.Object.create({
					key: "new-time-entry",
					value: (0, _utils.loc)("New Time Entry")
				}), Ember.Object.create({ key: "clock-out-at", value: (0, _utils.loc)("Clock Out At") }), Ember.Object.create({ key: "new-time-off", value: (0, _utils.loc)("Add Time Off") })]);
			}
		}),

		/**
   * Gets all of the timcards with a timestamp
   * that starts on the current day.
   *
   * @public
   * @method getTodaysCards
   */
		getTodaysCards: function getTodaysCards() {
			var _this = this;

			//: observer('member.id', 'member.timeEntries.[]', 'member.timeEntries.@each.id', 'member.openEntry.id', function() {
			Ember.RSVP.resolve(this.get("member")).then(function (member) {
				var todayStart = _utils.Time.date().startOf("day").unix();
				var todayEnd = _utils.Time.date().endOf("day").unix();

				var timeEntryQuery = {
					_gte: { end_time: todayStart },
					_lte: { start_time: todayEnd },
					member_id: member.id
				};
				var segmentQuery = {
					_gte: { start_time: todayStart },
					_lte: { end_time: todayEnd },
					deleted_on: null
				};

				_this.get("store").findAll("time-entry", timeEntryQuery).then(function (entries) {
					_this.get("store").findWhereIn("segment", "time_entry_id", entries.mapBy("id"), segmentQuery).then(function (data) {
						Ember.RSVP.all(data.map(function (s) {
							return Ember.RSVP.resolve(s.get("seconds"));
						})).then(function () {
							if (!_this.get("isDestroyed")) {
								var todaySeconds = data.mapBy("totalSeconds").reduce(function (a, b) {
									return a + b;
								}, 0);
								var todayBreak = data.mapBy("breakSeconds").reduce(function (a, b) {
									return a + b;
								}, 0);

								Ember.setProperties(_this, { todaySeconds: todaySeconds, todayBreak: todayBreak });
							}
						});
					});
				});
			});
		},


		openBreakTime: Ember.computed("member.openEntry.{timeEntryBreaks.@each.id,elapsedTime}", function () {
			var total = 0;
			if (!Ember.isNone(this.get("member.openEntry"))) {
				var dayStart = _utils.Time.date().startOf("day").unix();
				var dayEnd = _utils.Time.date().endOf("day").unix();
				var time = _timeEntry.default.totalBreakTime(this.get("member.openEntry"), dayStart, dayEnd, true);
				total += time.total;
			}
			return total;
		}),

		openTotalTime: Ember.computed("member.openEntry.totalTime", "openBreakTime", function () {
			var total = 0;
			if (!Ember.isNone(this.get("member.openEntry"))) {
				var dayStart = _utils.Time.date().startOf("day").unix();
				if (this.get("member.openEntry.startTime") < dayStart) {
					total += _utils.Time.date().unix() - dayStart - this.getWithDefault("openBreakTime", 0);
				} else {
					total += this.getWithDefault("member.openEntry.totalTime", 0);
				}
			}
			return total;
		}),

		breakTime: Ember.computed("todayBreak", "openBreakTime", function () {
			return this.getWithDefault("todayBreak", 0) + this.getWithDefault("openBreakTime", 0);
		}),

		totalTime: Ember.computed("todaySeconds", "openTotalTime", function () {
			return this.getWithDefault("todaySeconds", 0) + this.getWithDefault("openTotalTime", 0);
		}),

		//overtimeTime: computed('todaysEntries.overtimeSeconds', 'member.openEntry.totalTime', function() {
		//	let total = this.get('todaysEntries.overtimeSeconds') || 0;
		//	if (this.get('todaysEntries.overtimeSeconds') !== 0) {
		//		total = total + this.get('member.openEntry.totalTime');
		//	}
		//	return total;
		//}),

		updateData: function updateData() {
			this.set("dirtyFlag", true);
		},


		checkLocations: Ember.observer("member.openEntry", function () {
			var _this2 = this;

			if (!Ember.isNone(this.get("member.openEntry"))) {
				var startTime = _utils.Time.date(this.get("member.openEntry.startTime")).startOf("minute").unix();
				var now = _utils.Time.date().unix();
				var query = {
					_lte: { time_local: now },
					_gte: { time_local: startTime },
					member_id: this.get("member.id"),
					deleted_on: "_-NULL-_",
					archived_on: "_-NULL-_"
				};

				this.get("store").query("member-location", query).then(function (results) {
					if (results.content.length > 0) {
						_this2.set("hasLocations", true);
					} else {
						_this2.set("hasLocations", false);
					}
				});
			} else {
				this.set("hasLocations", false);
			}
		}),

		actions: {
			//Set each selectMenu item
			selectItemAction: function selectItemAction(key) {
				key = key.get("key");
				if (key === "new-time-entry") {
					this.set("showNewTimeEntryDialog", true);
				} else if (key === "clock-in-at") {
					this.set("isSuspended", false);
					this.set("isDisabled", false);
					this.set("showClockInAtDialog", true);
				} else if (key === "clock-out-at") {
					this.set("isSuspended", false);
					this.set("isDisabled", false);
					this.set("showClockOutAtDialog", true);
				} else if (key === "new-time-off") {
					this.set("isSuspended", false);
					this.set("isDisabled", false);
					this.set("showNewTimeOffDialog", true);
				}
			},
			openEditDialog: function openEditDialog() {
				this.set("isDisabled", true);
				this.set("isSuspended", true);
				this.set("showEditDialog", true);
			},
			closeEditDialog: function closeEditDialog() {
				if (!Ember.isNone(this.get("member.openEntry")) && this.get("member.openEntry.hasDirtyAttributes")) {
					this.get("member.openEntry").rollbackAttributes();
				}
				this.set("isSuspended", false);
				this.set("isDisabled", false);
				this.set("showEditDialog", false);
			},
			openLocationRoute: function openLocationRoute() {
				this.sendAction("openLoacation", this.get("member"));
			},
			openLocationDialog: function openLocationDialog() {
				if (this.get("hasLocations")) {
					this.set("showLocationDialog", true);
				}
			},
			closeLocationDialog: function closeLocationDialog() {
				this.set("showLocationDialog", false);
			},


			//close dialog for adding Time Entry
			closeTimeEntryAdd: function closeTimeEntryAdd() {
				this.updateData();
				this.set("showNewTimeEntryDialog", false);
			},


			//close dialog for adding Time Entry
			closeClockIn: function closeClockIn() {
				this.updateData();
				this.set("showClockInDialog", false);
			},


			//close dialog for adding Time Entry
			closeClockInAt: function closeClockInAt() {
				this.updateData();
				this.set("showClockInAtDialog", false);
			},
			closeClockOutAt: function closeClockOutAt() {
				this.updateData();
				this.set("showClockOutAtDialog", false);
			},


			//close dialog for adding new Time Off
			closeTimeOffAdd: function closeTimeOffAdd() {
				this.updateData();
				this.set("showNewTimeOffDialog", false);
			},
			clockChangeAction: function clockChangeAction() {
				this.updateData();
			},
			reloadModel: function reloadModel() {
				this.getTodaysCards();
			}
		}
	});
});