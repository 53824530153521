define('busy-app/components/select-menu/cost-code', ['exports', 'busy-app/components/select-menu/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		classNames: ['c-cost-code-select-menu'],

		bypassRequireCostCode: false,

		projectId: null,
		projectCostCodeLinks: null,
		linkedCostCodes: null,
		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),

		selectedTitle: '',
		selectedItem: null,

		_selectedTitle: '',
		_selectedItem: null,

		__projectId: null,
		__recent: null,
		__linkedCostCodes: null,
		__projectCostCodeLinks: null,

		allowNone: Ember.computed('bypassRequireCostCode', 'auth.organization.requireCostCode', function () {
			return !Ember.get(this, 'auth.organization.requireCostCode') || Ember.get(this, 'bypassRequireCostCode');
		}),

		onAutoOpenChanged: Ember.observer('autoOpen', function () {
			var _this = this;

			if (Ember.get(this, 'autoOpen')) {
				Ember.run.next(this, function () {
					Ember.setProperties(_this, {
						autoOpen: false,
						selectedTitle: '',
						selectedItem: null
					});

					_this.initLinkedCostCodes();
					_this.send('openMenu', 'auto');
				});
			}
		}),

		init: function init() {
			this._super.apply(this, arguments);
			// Logger.info(this, 'init');

			this.initLinkedCostCodes();
		},
		initLinkedCostCodes: function initLinkedCostCodes() {
			if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
				// Logger.info(this, 'initLinkedCostCodes');

				Ember.setProperties(this, {
					_selectedTitle: '',
					_selectedItem: null,

					__projectId: Ember.get(this, 'projectId'),
					__recent: null,
					__linkedCostCodes: null,
					__projectCostCodeLinks: null
				});
			}
		},


		menuTitle: Ember.computed('selectedItem.costCodeLabel', function () {
			// default title is `None`
			var title = (0, _utils.loc)('None');

			// if the selectedTitle is set to non-empty string
			// then use it for the title
			if (!Ember.isEmpty(Ember.get(this, 'selectedItem.costCodeLabel'))) {
				title = Ember.get(this, 'selectedItem.costCodeLabel');
			}

			return title;
		}),

		getSelectedTitle: function getSelectedTitle(item) {
			return Ember.get(item, 'costCode');
		},
		loadRecent: function loadRecent() {
			// Logger.info(this, 'loadRecent');

			if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
				return this.loadRecentScoped();
			} else {
				return this.loadRecentDefault();
			}
		},
		loadData: function loadData() {
			// Logger.info(this, 'loadData');

			if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
				return this.loadDataScoped();
			} else {
				return this.loadDataDefault();
			}
		},
		loadSearch: function loadSearch(keyword) {
			// Logger.info(this, 'loadSearch', keyword);

			if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
				return this.loadSearchScoped(keyword);
			} else {
				return this.loadSearchDefault(keyword);
			}
		},


		// without Project Scoped Cost Codes
		loadRecentDefault: function loadRecentDefault() {
			var _this2 = this;

			// Logger.info(this, 'loadRecentDefault');
			var __recent = Ember.get(this, '__recent');

			if (Ember.isNone(__recent)) {
				// Logger.info(this, 'loadRecentDefault', 'miss');

				var params = {
					archived_on: null,
					deleted_on: '_-NULL-_',
					_asc: ['cost_code']
				};

				__recent = this.getRecentTimeEntries().then(function (model) {
					return Ember.get(_this2, 'store').findWhereIn('cost-code', 'id', model.mapBy('costCodeId'), params);
				});

				Ember.setProperties(this, { __recent: __recent });
			}

			return __recent;
		},


		// without Project Scoped Cost Codes
		loadDataDefault: function loadDataDefault() {
			var params = {
				page: Ember.get(this, 'page'),
				page_size: Ember.get(this, 'pageSize'),
				archived_on: null,
				_asc: ['cost_code']
			};

			return Ember.get(this, 'store').query('cost-code', params);
		},


		// without Project Scoped Cost Codes
		loadSearchDefault: function loadSearchDefault(keyword) {
			// Logger.info(this, 'loadSearchDefault', keyword);

			var params = {
				page: Ember.get(this, 'page'),
				page_size: 100,
				archived_on: null,
				_asc: ['cost_code'],
				_search: keyword
			};

			return Ember.get(this, 'store').query('cost-code', params);
		},


		// Project Scoped Cost Codes enabled
		loadRecentScoped: function loadRecentScoped() {
			// Logger.info(this, 'loadRecentScoped');
			var __recent = Ember.get(this, '__recent');

			if (Ember.isNone(__recent)) {
				// Logger.info(this, 'loadRecentScoped', 'miss');

				__recent = Ember.RSVP.hash({
					timeEntries: this.getRecentTimeEntries(),
					costCodes: this.getScopedCostCodes()
				}).then(function (props) {
					var timeEntries = props.timeEntries,
					    costCodes = props.costCodes;

					var recentCostCodeIds = timeEntries.mapBy('costCodeId');

					return costCodes.filter(function (costCode) {
						return recentCostCodeIds.includes(Ember.get(costCode, 'id'));
					});
				});

				Ember.setProperties(this, { __recent: __recent });
			}

			return __recent;
		},


		// Project Scoped Cost Codes enabled
		// must retrieve all available cost codes upfront
		loadDataScoped: function loadDataScoped() {
			var _this3 = this;

			// Logger.info(this, 'loadDataScoped');

			return this.getScopedCostCodes().then(function (results) {
				return _this3.paginateResults(results);
			});
		},


		// Project Scoped Cost Codes enabled
		// must retrieve all available cost codes upfront, then filters by the 'costCode' and 'title' fields
		loadSearchScoped: function loadSearchScoped(keyword) {
			var _this4 = this;

			return this.getScopedCostCodes().then(function (results) {
				return _this4.filterResultsByKeyword(results, keyword);
			}).then(function (results) {
				return _this4.paginateResults(results);
			});
		},
		paginateResults: function paginateResults(fullResults) {
			var _EmberGetProperties = Ember.getProperties(this, ['page', 'pageSize']),
			    page = _EmberGetProperties.page,
			    pageSize = _EmberGetProperties.pageSize;

			var startRow = (page - 1) * pageSize;
			var endRow = startRow + pageSize;
			var totalRows = Ember.get(fullResults, 'length');
			var results = fullResults.slice(startRow, endRow);

			// Logger.info(this, 'paginateResults', `${startRow}-${endRow} of ${totalRows}`, { page, pageSize }, results);

			return {
				results: results,
				meta: {
					totalRows: totalRows,
					returnedRows: Ember.get(results, 'length')
				}
			};
		},
		filterResultsByKeyword: function filterResultsByKeyword(results, keyword) {
			var safeLower = function safeLower(str) {
				return (str || '').trim().toLowerCase();
			};
			var searchKeyword = safeLower(keyword);

			// Logger.info(this, 'loadSearchScoped', `'${keyword}'`, `'${searchKeyword}'`);

			return results.filter(function (item) {
				return safeLower(Ember.get(item, 'costCode')).includes(searchKeyword) || safeLower(Ember.get(item, 'title')).includes(searchKeyword);
			});
		},
		getRecentTimeEntries: function getRecentTimeEntries() {
			var params = {
				member_id: Ember.get(this, 'auth.member.id'),
				page: 1,
				page_size: 5,
				cost_code_id: '!_-NULL-_',
				deleted_on: null,
				_desc: ['start_time']
			};

			return Ember.get(this, 'state').query('time-entry', params);
		},


		// since this is a full table lookup, the results are cached on the component
		getScopedCostCodes: function getScopedCostCodes() {
			var _this5 = this;

			// Logger.info(this, 'getScopedCostCodes');
			_utils.Assert.test('projectId must be provided', Ember.get(this, 'projectId'));
			var __linkedCostCodes = Ember.get(this, '__linkedCostCodes');

			if (Ember.isNone(__linkedCostCodes)) {
				// Logger.info(this, 'getScopedCostCodes', 'miss');
				__linkedCostCodes = this.getProjectCostCodeLinks().then(function (links) {
					var params = {
						archived_on: null
					};

					var allowedIds = links.mapBy('costCodeId');
					return Ember.get(_this5, 'store').findWhereIn('cost-code', 'id', allowedIds, params).then(function (results) {
						return results.sortBy('id').sortBy('title').sortBy('costCode');
					}); // sort the cost codes
				});

				Ember.setProperties(this, { __linkedCostCodes: __linkedCostCodes }); // NOTE: the promise is saved, not the result
			}

			return __linkedCostCodes;
		},


		// since this is a full table lookup, the results are cached on the component
		getProjectCostCodeLinks: function getProjectCostCodeLinks() {
			// Logger.info(this, 'getProjectCostCodeLinks');
			_utils.Assert.test('projectId must be provided', Ember.get(this, 'projectId'));
			var __projectCostCodeLinks = Ember.get(this, '__projectCostCodeLinks');

			if (Ember.isNone(__projectCostCodeLinks)) {
				// Logger.info(this, 'getProjectCostCodeLinks', 'miss');
				var project_id = Ember.get(this, 'projectId');

				__projectCostCodeLinks = Ember.get(this, 'state').findAll('project-cost-code-link', { project_id: project_id });

				Ember.setProperties(this, { __projectCostCodeLinks: __projectCostCodeLinks }); // NOTE: the promise is saved, not the result
			}

			return __projectCostCodeLinks;
		}
	});
});