define('busy-app/models/pay-period-member-project-cost-code-seconds', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		projectId: _emberData.default.attr('string'),
		costCodeId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		endTime: _emberData.default.attr('number'),
		regularSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		overtimeSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		doubletimeSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		childrenRegularSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		childrenOvertimeSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		childrenDoubletimeSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		totalSeconds: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}),

		totalRegularSeconds: Ember.computed('regularSeconds', 'childrenRegularSeconds', function () {
			return this.get('regularSeconds') + this.get('childrenRegularSeconds');
		}),

		totalOvertimeSeconds: Ember.computed('overtimeSeconds', 'childrenOvertimeSeconds', function () {
			return this.get('overtimeSeconds') + this.get('childrenOvertimeSeconds');
		}),

		totalDoubletimeSeconds: Ember.computed('doubletimeSeconds', 'childrenDoubletimeSeconds', function () {
			return this.get('doubletimeSeconds') + this.get('childrenDoubletimeSeconds');
		}),

		totalTotalSeconds: Ember.computed('totalRegularSeconds', 'totalOvertimeSeconds', 'totalDoubletimeSeconds', function () {
			return this.get('totalRegularSeconds') + this.get('totalOvertimeSeconds') + this.get('totalDoubletimeSeconds');
		})
	});
});