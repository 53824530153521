define('busy-app/utils/csv-export', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Object.extend({
		data: null,
		dataMap: null,
		isReversed: false,

		setDataMap: function setDataMap(dataMap) {
			var isReversed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
			(false && !(!Ember.isNone(dataMap) && Ember.typeOf(dataMap) === 'object') && Ember.assert('setDataMap requires an key:value object where key is the data mapped path and value is the header title', !Ember.isNone(dataMap) && Ember.typeOf(dataMap) === 'object'));


			Ember.set(this, 'isReversed', isReversed);

			var _map = Ember.Map.create();
			Object.keys(dataMap).forEach(function (key) {
				_map.set(key, dataMap[key]);
			});

			this.set('dataMap', _map);
		},
		setData: function setData(data) {
			(false && !(Ember.typeOf(data) === 'array') && Ember.assert('setData requires an array of objects', Ember.typeOf(data) === 'array'));

			this.set('data', data);
		},
		buildHeaderLine: function buildHeaderLine(dataMap) {
			var header = [];
			var isReversed = Ember.get(this, 'isReversed');
			dataMap.forEach(function (value, key) {
				if (isReversed) {
					header.push(normalizeKey(key));
				} else {
					header.push(value);
				}
			});

			return header;
		},
		buildContentLine: function buildContentLine(dataMap, data) {
			var _this = this;

			var line = [];
			var isReversed = Ember.get(this, 'isReversed');
			dataMap.forEach(function (value, key) {
				var val = void 0;
				if (isReversed) {
					if (typeof value === 'function') {
						val = value(data);
					} else {
						val = Ember.get(data, value);
					}
				} else {
					val = Ember.get(data, key) || "";
				}
				line.push(_this.csvSafeString(val));
			});

			return line;
		},
		csvSafeString: function csvSafeString(str) {
			if (Ember.isNone(str)) {
				str = '';
			}
			if (Ember.typeOf(str) !== 'string') {
				str = str.toString();
			}
			// replace non breaking spaces with regular space
			str = str.replace(/\u00a0/g, ' ');

			// remove begining and end quotes
			str = str.replace(/^"/, '');
			str = str.replace(/"$/, '');

			// escape any quotes left inside the string
			str = str.replace(/"/g, '""');

			// add the begining and ending quotes
			return '"' + str + '"';
		},
		buildContentString: function buildContentString() {
			var _this2 = this;

			var data = this.get('data');
			var dataMap = this.get('dataMap');

			var content = [this.buildHeaderLine(dataMap)].concat(_toConsumableArray(data.map(function (item) {
				return _this2.buildContentLine(dataMap, item);
			})));

			return content.join('\n');
		},
		generate: function generate(name) {
			var content = this.buildContentString();
			var filename = name + '.csv';

			this.triggerDownload(content, filename);
		},
		triggerDownload: function triggerDownload(content, filename) {
			var type = filename.split('.')[1];

			(false && !(typeof content === 'string') && Ember.assert('content must be string', typeof content === 'string'));
			(false && !(!Ember.isNone(type)) && Ember.assert("filename must include the file extension", !Ember.isNone(type)));


			if (window.navigator.msSaveOrOpenBlob) {
				var blob = new window.Blob([decodeURIComponent(encodeURI(content))], {
					type: 'text/' + type + ';charset=utf-8;'
				});
				window.navigator.msSaveBlob(blob, filename);
			} else {
				var fileContent = "data:application/octet-stream;base64,";
				fileContent += this.encodeBase64(content);

				var link = document.createElement('a');
				document.body.appendChild(link);
				link.setAttribute('href', fileContent);
				link.setAttribute('download', filename);

				link.click();
			}
		},
		encodeBase64: function encodeBase64(str) {
			return window.btoa(this.encodeUTF8(str));
		},
		encodeUTF8: function encodeUTF8(str) {
			return window.unescape(encodeURIComponent(str));
		}
	});


	function normalizeKey(key) {
		var kp = Ember.String.dasherize(key).replace(/-\w/g, function (letter) {
			return ' ' + letter.slice(1).toUpperCase();
		});
		return kp.slice(0, 1).toUpperCase() + kp.slice(1);
	}
});