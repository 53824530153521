define('busy-app/components/d-button-split', ['exports', 'busy-app/utils/logger'], function (exports, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({
		classNames: ['d-button-group'],
		elementType: 'span',

		classModifiers: 'blue',

		primaryText: null,
		primaryIcon: null,
		primaryAction: null,

		selectAction: null,
		selectOptions: null,

		actions: {
			onPrimaryAction: function onPrimaryAction() {
				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				_logger.default.info.apply(_logger.default, [this, 'onPrimaryAction'].concat(_toConsumableArray(args)));

				return Ember.get(this, 'primaryAction').apply(undefined, _toConsumableArray(args));
			},
			onSelectAction: function onSelectAction(item) {
				_logger.default.info(this, 'onSelectAction', item);

				return Ember.get(this, 'selectAction')(item);
			}
		}
	});
});