define('busy-app/collections/payroll-project', ['exports', 'busy-app/collections/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		payPeriods: Ember.inject.service('pay-period'),

		init: function init() {
			this._super.apply(this, arguments);
			Ember.deprecate('Custom wage date feature not supported', !Ember.get(this, 'features.wageCustomDate'), { id: 'collections.payroll-project', until: '999.99.99' });
		},


		/**
   * Use this function to rebuild the data structure
   * returned from the getter calls
   *
   * @public
   * @method buildModels
   * @param model {object} keyed model object
   * @returns {array|object}
   */
		buildModels: function buildModels(model) {
			var _this = this;

			var data = [];
			model.timeMembers.forEach(function (item) {
				if (model.member.indexOf(item) === -1) {
					model.member.pushObject(item._internalModel);
				}
			});

			model.project.filterBy('depth', 1).forEach(function (item) {
				var poly = _this.createPolymorph('payroll-project');

				var seconds = model.payPeriodMemberProjectSeconds.filterBy('projectId', item.id);
				var cost = model.payPeriodMemberProjectCost.filterBy('projectId', item.id);
				var hasSeconds = seconds.mapBy('totalTotalSeconds').reduce(function (a, b) {
					return a + b;
				}, 0);

				if (hasSeconds) {
					item.set('sortWeight', 1);
					poly.set('project', item);
					poly.set('seconds', seconds);
					poly.set('cost', cost);
					poly.set('forceOpen', true);
					poly.set('hideData', false);
					poly.set('disableDepth', true);
					poly.set('isTopLevel', true);

					_this.addMemberModels(poly, item.id, model, 1);

					data.pushObject(poly);
				}
			});

			this.addUnassigned(null, data, model, null, 1);

			this.setAggregates(data);

			return data.sortBy('project.sortWeight', 'project.title');
		},
		addMemberModels: function addMemberModels(polyObject, parentProjectId, model, depth) {
			var _this2 = this;

			var payPeriod = model.organizationPayPeriod.objectAt(0);
			var data = [];
			model.member.forEach(function (item) {
				var seconds = polyObject.get('seconds').filterBy('memberId', item.id);
				var cost = polyObject.get('cost').filterBy('memberId', item.id);
				var hasSeconds = seconds.mapBy('totalTotalSeconds').reduce(function (a, b) {
					return a + b;
				}, 0);

				if (hasSeconds) {
					// each member needs a total seconds to reference for calculating saly employees.
					var memberSeconds = _this2.createPolymorph('pay-period-member');
					memberSeconds.set('seconds', model.payPeriodMemberProjectSeconds.filterBy('memberId', item.id));
					item.set('seconds', memberSeconds);

					var poly = _this2.createPolymorph('payroll-member');
					poly.set('member', item);
					poly.set('project', polyObject.get('project'));
					poly.set('seconds', seconds);
					poly.set('cost', cost);
					poly.set('wage', _this2.getWageHistory(item));
					poly.set('payPeriodType', payPeriod.get('payPeriodType'));
					poly.set('nestedMember', true);
					poly.set('hideData', true);
					poly.set('isOpen', true);
					poly.set('hidden', true);

					if (depth === 1) {
						poly.set('isOpen', false);
						poly.set('hidden', false);
					}

					_this2.addChildProjects(poly, parentProjectId, model, depth + 1);

					data.pushObject(poly);
				}
			});

			polyObject.set('members', data.sortBy('member.fullName'));
		},
		addSalaryMembers: function addSalaryMembers(polyObject, model) {
			var _this3 = this;

			var payPeriodType = model.organizationPayPeriod.objectAt(0).get('payPeriodType');

			model.member.forEach(function (item) {
				_this3._addSalaryMembers(item, polyObject, model, payPeriodType);
			});

			model.archivedMember.forEach(function (item) {
				_this3._addSalaryMembers(item, polyObject, model, payPeriodType);
			});

			polyObject.set('members', polyObject.get('members').sortBy('member.fullName'));
		},
		_addSalaryMembers: function _addSalaryMembers(item, polyObject, model, payPeriodType) {
			var seconds = model.payPeriodMemberProjectSeconds.filterBy('memberId', item.id);
			var cost = model.payPeriodMemberProjectCost.filterBy('memberId', item.id);
			var wage = this.getWageHistory(item);
			var isSalary = !Ember.isNone(wage) && (wage.get('wageRate') === 30 || wage.get('wageRate') === 40 || wage.get('wageRate') === 50);

			// add members with time clocked or members with salary that may not have any time clocked.
			if (Ember.isEmpty(seconds) && isSalary) {
				var poly = this.createPolymorph('payroll-member');
				poly.set('member', item);
				poly.set('seconds', seconds);
				poly.set('cost', cost);
				poly.set('wage', this.getWageHistory(item));
				poly.set('payPeriodType', payPeriodType);
				poly.set('nestedMember', true);

				polyObject.get('members').pushObject(poly);
			}
		},
		addChildProjects: function addChildProjects(polyObject, parentProjectId, model, depth) {
			var _this4 = this;

			var data = [];
			var projects = model.project.filterBy('depth', depth).filterBy('parentProjectId', parentProjectId);
			projects.forEach(function (item) {
				var seconds = model.payPeriodMemberProjectSeconds.filterBy('projectId', item.id).filterBy('memberId', polyObject.get('member.id'));
				var cost = model.payPeriodMemberProjectCost.filterBy('projectId', item.id).filterBy('memberId', polyObject.get('member.id'));
				var hasSeconds = seconds.mapBy('totalTotalSeconds').reduce(function (a, b) {
					return a + b;
				}, 0);

				if (hasSeconds) {
					var poly = _this4.createPolymorph('payroll-project');
					item.set('sortWeight', 1);
					poly.set('project', item);
					poly.set('member', polyObject.get('member'));
					poly.set('seconds', seconds);
					poly.set('cost', cost);

					_this4.addMemberModels(poly, item.id, model, depth);

					data.pushObject(poly);
				}
			});

			if (data.length > 0) {
				this.addUnassigned(polyObject, data, model, parentProjectId, depth);
			}

			polyObject.set('projects', data.sortBy('project.sortWeight', 'project.title'));
		},
		addUnassigned: function addUnassigned(polyObject, data, model, parentProjectId, depth) {
			var project = Ember.Object.create({
				id: null,
				title: (0, _utils.loc)('Unassigned'),
				isUnassigned: true,
				depth: depth,
				parentProjectId: parentProjectId,
				sortWeight: 2
			});

			var seconds = void 0,
			    cost = void 0;
			if (Ember.isNone(parentProjectId)) {
				seconds = model.payPeriodMemberProjectSeconds.filterBy('projectId', null);
				cost = model.payPeriodMemberProjectCost.filterBy('projectId', null);
			} else {
				seconds = model.payPeriodMemberProjectSeconds.filterBy('projectId', parentProjectId).filterBy('memberId', polyObject.get('member.id'));
				cost = model.payPeriodMemberProjectCost.filterBy('projectId', parentProjectId).filterBy('memberId', polyObject.get('member.id'));
			}

			var poly = this.createPolymorph('payroll-project');
			poly.set('project', project);
			poly.set('seconds', seconds);
			poly.set('cost', cost);
			poly.set('disableDepth', Ember.isNone(parentProjectId));
			poly.set('includeChildren', false);
			poly.set('members', []);

			if (!Ember.isNone(polyObject)) {
				poly.set('member', polyObject.get('member'));
			}

			var hasTime = false;
			if (poly.get('totalNoChildrenSeconds') > 0 && (depth === 1 || poly.get('totalChildrenSeconds') > 0)) {
				hasTime = true;
				this.addMemberModels(poly, project.id, model, depth);
			}

			var hasSalary = false;
			if (Ember.isNone(parentProjectId) && depth === 1) {
				hasSalary = true;
				poly.set('forceOpen', true);
				poly.set('hideData', false);
				poly.set('isTopLevel', true);
				this.addSalaryMembers(poly, model);
			}

			if (!Ember.isEmpty(poly.get('members')) && (hasTime || hasSalary)) {
				data.pushObject(poly);
			}
		},
		setAggregates: function setAggregates(data) {
			var regularSeconds = 0;
			var overtimeSeconds = 0;
			var doubletimeSeconds = 0;
			var totalSeconds = 0;
			var estCostSalary = 0;
			var estCostHourly = 0;

			data.forEach(function (item) {
				regularSeconds += item.get('totalRegularSeconds');
				overtimeSeconds += item.get('totalOvertimeSeconds');
				doubletimeSeconds += item.get('totalDoubletimeSeconds');
				totalSeconds += item.get('totalSeconds');
				estCostSalary += item.get('estCostSalary');
				estCostHourly += item.get('estCostHourly');
			});

			// round the salary and hourly before adding the total
			// so the numbers add correctly in the interface.
			estCostSalary = estCostSalary.toFixed(2);
			estCostHourly = estCostHourly.toFixed(2);

			// to fixed creates a string so parseFloat befaore adding the values.
			var total = parseFloat(estCostSalary) + parseFloat(estCostHourly);

			this.set('regularSeconds', regularSeconds);
			this.set('overtimeSeconds', overtimeSeconds);
			this.set('doubletimeSeconds', doubletimeSeconds);
			this.set('totalSeconds', totalSeconds);
			this.set('estCostSalary', estCostSalary);
			this.set('estCostHourly', estCostHourly);
			this.set('totalCost', total);
		},
		getWageHistory: function getWageHistory(member) {
			var _this5 = this;

			// sort wages
			var _wages = member.get('wageHistories').filterBy('memberId', member.id).sortBy('changeDate');

			var wage = _wages.objectAt(0);
			_wages.forEach(function (item) {
				if (item.get('changeDate') > wage.get('changeDate') && item.get('changeDate') <= _this5.get('start')) {
					wage = item;
				}
			});

			return wage;
		},


		/**
   * Collections may have a predefined model
   * structure for reusability. This is not required
   * so this function may be removed if not used.
   *
   * @public
   * @method model
   * @return {EmberPromise}
   */
		model: function model(start, end, memberId, projectId) {
			this.set('start', start);
			this.set('end', end);

			var query = {
				start_time: start,
				end_time: end
			};

			// filter by member id
			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			// filter by project id
			if (!Ember.isNone(projectId)) {
				query.project_id = projectId;
			}

			var memberQuery = { archived_on: null, _lte: { created_on: start } };
			var archivedMembersQuery = { _gte: { archived_on: start } };
			var payPeriodMemberProjectSecondsQuery = {};

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				var authMember = Ember.get(this, 'auth.member');

				var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
				    id = _EmberGetProperties.id,
				    memberGroupId = _EmberGetProperties.memberGroupId;

				Ember.setProperties(query, { member_group_id: memberGroupId });
				Ember.setProperties(memberQuery, { member_group_id: memberGroupId });
				Ember.setProperties(archivedMembersQuery, { member_group_id: memberGroupId });
				Ember.setProperties(payPeriodMemberProjectSecondsQuery, { member_group_id: memberGroupId });

				if (Ember.isNone(memberGroupId)) {
					Ember.setProperties(query, { member_id: id });
					Ember.setProperties(memberQuery, { id: id });
					Ember.setProperties(archivedMembersQuery, { id: id });
					Ember.setProperties(payPeriodMemberProjectSecondsQuery, { id: id });
				}
			}

			return this.getter.findAll('pay-period-member-project-seconds', query).findAll('pay-period-member-project-cost', query).findAll('organization-pay-period', { _lte: { start_date: start }, _desc: ['start_date'] }).findAll('member', memberQuery).joinAll('wage-history', 'member', 'id', { deleted_on: null }).findAll('member', archivedMembersQuery, 'archivedMember').joinAll('wage-history', 'archivedMember', 'id', { deleted_on: null }).outerJoin('project', 'pay-period-member-project-seconds', 'project_id').outerJoin('member', 'pay-period-member-project-seconds', 'member_id', payPeriodMemberProjectSecondsQuery, 'timeMembers').joinAll('wage-history', 'timeMembers', 'id', { deleted_on: null }).fetch();
		}
	});
});