define('busy-app/components/charts/doughnut-chart', ['exports', 'busy-app/components/charts/pie-chart'], function (exports, _pieChart) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _pieChart.default.extend({
		classNames: ['doughnut'],

		/**
   * The chart type
   *
   * @private
   * @property type
   * @type string
   */
		type: 'doughnut',

		/**
   * The size of the doughnut hole
   *
   * @public
   * @property cutoutPercentage
   * @type number
   */
		cutoutPercentage: 50
	});
});