define("busy-app/routes/application", ["exports", "@busy-web/utils", "busy-app/utils/query-string-to-json", "ember-simple-auth/mixins/application-route-mixin", "moment"], function (exports, _utils, _queryStringToJson, _applicationRouteMixin, _moment) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
		auth: Ember.inject.service("auth"),
		interop: Ember.inject.service("interop"),
		session: Ember.inject.service("session"),
		features: Ember.inject.service("features"),
		toggle: Ember.inject.service("toggle"),
		subscription: Ember.inject.service("subscription"),
		analytics: Ember.inject.service("analytics"),
		discovery: Ember.inject.service("discovery"),

		queryParams: {
			authorize: { replace: true },
			token: { replace: true }
		},

		beforeModel: function beforeModel(transition) {
			var _this = this,
			    _arguments = arguments;

			if (Ember.get(this, 'features.serverOutage')) {
				return;
			}

			// load analytics classes
			this.get("analytics");
			this.get("discovery").getManifest();

			// set user language.
			this.setLang();

			// check for any authentication query params and normalize the results

			var _parseAuthenticationP = parseAuthenticationParams(transition.queryParams),
			    query = _parseAuthenticationP.query,
			    auth = _parseAuthenticationP.auth,
			    id = _parseAuthenticationP.id;

			if (!Ember.isEmpty(auth) && !Ember.isEmpty(id)) {
				// Note:
				// this authentication style doesnt make any calls to the server
				// it expects that the token and id are valid and sets them in
				// localstorage for use. Only after the window reload will the first call
				// be made and if the token is invalid the user will be logged out at that time.

				// a full window reload is the only way to prevent odd behavior here
				return this.get("session").authenticate("authenticator:basic", null, null, { token: auth, id: id }).then(function () {
					var queryStr = Object.keys(query).map(function (key) {
						return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
					}).join("&");

					if (queryStr.length) {
						queryStr = "?" + queryStr;
					}

					var url = window.location.pathname + queryStr;

					return window.location.replace(url);
				});
			} else {
				// setup auth data
				return this.setupAuth().then(function () {
					// enable features
					return _this.enableFeatures().then(function () {
						// allow ember app to conitnue loading.
						return _this._super.apply(_this, _arguments);
					});
				});
			}
		},
		model: function model() {
			var _this2 = this;

			if (Ember.get(this, 'features.serverOutage')) {
				return true;
			}

			window.BusyApp.interop = function () {
				return _this2.dumpInteropDebug();
			};

			return this.setupAuth();
		},
		getInteropDebug: function getInteropDebug() {
			var interopUrl = Ember.get(this, "interop").getReactAppUrl();
			var keyAuthorization = Ember.get(this, "session.data.authenticated.token");
			var memberId = Ember.get(this, "session.data.authenticated.id");
			var organizationId = Ember.get(this, "auth.organization.id");
			var interopValues = [keyAuthorization, memberId].join(":");
			var interopToken = window.btoa(interopValues);

			return {
				memberId: memberId,
				organizationId: organizationId,
				keyAuthorization: keyAuthorization,
				interopToken: interopToken,
				interopUrl: interopUrl
			};
		},
		dumpInteropDebug: function dumpInteropDebug() {
			var debug = this.getInteropDebug();

			// eslint-disable-next-line no-console
			Object.keys(debug).forEach(function (k) {
				return console.log(_defineProperty({}, k, debug[k]));
			});
		},
		setupAuth: function setupAuth() {
			var _this3 = this;

			return Ember.RSVP.hash({
				member: this.get('auth.member'),
				positions: this.get('auth.positions'),
				organization: this.get('auth.organization'),
				organizationPayPeriod: this.get('auth.organizationPayPeriod'),
				organizationOvertimePeriod: this.get('auth.organizationOvertimePeriod'),
				subscriptionStatus: this.get('subscription.subscriptionStatus')
			}).catch(function () {
				// if auth fails, logout the user
				return _this3.get('auth').invalidateSession();
			});
			//.then(data => {
			//	//setup syncing web worker
			//	if (this.get('session.isAuthenticated')) {
			//		webWorker(this.get('auth.token'), this.get('auth.member.id'), this.get('auth.organization.id'));
			//	}
			//	return data;
			//});
		},
		enableFeatures: function enableFeatures() {
			var _this4 = this;

			if (this.get("subscription.isProAccount")) {
				this.get("features").enable("insurance-classes");
			}

			return this.get("toggle").setupLaunchDarkly().then(function (data) {
				if (_this4.get("features.allowSubscriptionOverride")) {
					var proOveride = _utils.LocalStorage.getProperty("x-allow-override-pro") === "true";
					if (proOveride) {
						_this4.get("features").enable("overrides/pro");
					} else {
						_this4.get("features").disable("overrides/pro");
					}
					_this4.get("toggle").getAllFlags();
				}
				if (_this4.get("features").isEnabled("overrides/reactMainApp")) {
					_this4.get("features").enable("overrides/reactMainApp");
				}
				return data;
			});
		},
		setLang: function setLang() {
			// suported languages must be in lowercase values.
			var supportedLanguages = ["en-us", "en", "es"];

			// get the users chosen languages
			var allLanguages = window.navigator.languages;
			if (Ember.isNone(allLanguages)) {
				allLanguages = [window.navigator.language];
			}

			var lang = allLanguages.find(function (language) {
				return typeof language === "string" && supportedLanguages.indexOf(language.toLowerCase()) !== -1;
			});

			if (Ember.isNone(lang)) {
				lang = "en-US";
			}

			//lang = 'es';
			_moment.default.locale(lang);

			var langNormalized = lang.toLowerCase();

			// add lang to body class
			Ember.$("body").addClass(langNormalized);

			// load the lang module.
			Ember.STRINGS = Ember.getOwner(this).factoryFor("lang:" + langNormalized).class;
		},
		getTokenlessRedirect: function getTokenlessRedirect(targetUrl, query) {
			var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";

			delete query.token;
			delete query.member_id;
			delete query.memberId;

			var baseUrl = targetUrl.split("?")[0];
			if (!Ember.isEmpty(hash)) {
				hash = "#" + hash.replace(/^#/, ""); // remove the hash if it exists so it can be added back to ensure it exists
				baseUrl += hash;
			}

			var qs = Object.keys(query).map(function (key) {
				var encodedKey = encodeURIComponent(key);
				var encodedValue = encodeURIComponent(query[key]);
				return encodedKey + "=" + encodedValue;
			}).join("&");

			return qs.length ? baseUrl + "?" + qs : baseUrl;
		},


		actions: {
			authenticateSession: function authenticateSession(username, password) {
				var success = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};
				var error = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};

				this.get("session").authenticate("authenticator:basic", username, password).then(function () {
					return Ember.run(null, success, null);
				}).catch(function (err) {
					return Ember.run(null, error, err);
				});
			},
			invalidateSession: function invalidateSession() {
				return this.get("auth").invalidateSession();
			},
			reloadAuth: function reloadAuth() {
				this.refresh();
			}
		}
	});


	function parseAuthenticationParams(query) {
		query = Ember.assign({}, query);

		// ember cuts off part of the btoa hash.
		// this is a hack to ensure the auth hash remains entact
		if (query && !Ember.isEmpty(query.authorize)) {
			var authorize = window.location.search.slice(1).split("&").find(function (i) {
				return i.search(/authorize=/) !== -1;
			});
			authorize = authorize.slice(authorize.indexOf("=") + 1);
			query.authorize = authorize;
		}

		var hash = window.location.hash;
		if (!Ember.isEmpty(hash)) {
			// check for queryParams appended to the hash
			// just incase someone doesnt format the url correctly
			var qid = hash.indexOf("?");
			if (qid !== -1) {
				var queryStr = hash.slice(qid + 1);
				query = Ember.assign({}, query, (0, _queryStringToJson.default)(queryStr));
				hash = hash.slice(0, qid);
			}

			// better tab format for redirected links
			if (!Ember.isNone(hash)) {
				query.bc_tab = hash.replace(/^#tab-/, "");
			}
		}

		var auth = void 0,
		    id = void 0;
		if (!Ember.isEmpty(query.authorize)) {
			var _window$atob$split = window.atob(query.authorize).split(":");

			var _window$atob$split2 = _slicedToArray(_window$atob$split, 2);

			auth = _window$atob$split2[0];
			id = _window$atob$split2[1];


			// delete app query params from query
			delete query.authorize;
		} else if (!Ember.isEmpty(query.token) && (!Ember.isEmpty(query.member_id) || !Ember.isEmpty(query.memberId))) {
			auth = query.token;
			id = query.member_id || query.memberId;

			// delete app query params from query
			delete query.token;
			delete query.member_id;
			delete query.memberId;
		}

		return { auth: auth, id: id, query: query };
	}
});