define('busy-app/components/dialogs/delete-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		deleteType: '',
		deleteText: (0, _utils.loc)("Delete"),

		deleteMessage: Ember.computed('deleteText', 'deleteType', function () {
			var type = this.get('deleteType');
			if (Ember.isEmpty(type)) {
				type = 'this';
			}
			return (0, _utils.loc)("Are you sure you want to %@ %@?", [this.get('deleteText').toLowerCase(), type]);
		}),

		actions: {
			confirm: function confirm() {
				this.sendAction('onConfirm');
			},
			cancel: function cancel() {
				this.sendAction('onCancel');
			}
		}
	});
});