define('busy-app/components/subscriptions/subscription-plans', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-company-subscription-plans'],

		auth: Ember.inject.service('auth'),
		subscription: Ember.inject.service('subscription'),

		organization: Ember.computed.alias('auth.organization'),

		// component attributes
		selectSubscriptionTab: function selectSubscriptionTab() {
			return null;
		},

		// component properties
		featureGrid: null,

		showHelpDialog: false,
		showSubscriptionManager: false,

		isLoading: false,

		userPricePerMonth: Ember.computed.alias('subscription.costPerActiveUserMonthly'),
		userPriceAnnualPerMonth: Ember.computed.alias('subscription.costPerActiveUserAnnual'),
		userPriceAnnualPerYear: Ember.computed('userPriceAnnualPerMonth', function () {
			return (this.get('userPriceAnnualPerMonth') * 12).toFixed(2);
		}),

		userPricePerMonthDollar: Ember.computed('userPricePerMonth', function () {
			return !Ember.isNone(this.get('userPricePerMonth')) ? this.getDollars(this.get('userPricePerMonth')) : null;
		}),

		userPricePerMonthCents: Ember.computed('userPricePerMonth', 'userPricePerMonthDollar', function () {
			return !Ember.isNone(this.get('userPricePerMonth')) ? this.getCents(this.get('userPricePerMonth')) : null;
		}),

		userPriceAnnualPerMonthDollar: Ember.computed('userPriceAnnualPerMonth', function () {
			return !Ember.isNone(this.get('userPriceAnnualPerMonth')) ? this.getDollars(this.get('userPriceAnnualPerMonth')) : null;
		}),

		userPriceAnnualPerMonthCents: Ember.computed('userPriceAnnualPerMonth', 'userPriceAnnualPerMonthDollar', function () {
			return !Ember.isNone(this.get('userPriceAnnualPerMonth')) ? this.getCents(this.get('userPriceAnnualPerMonth')) : null;
		}),

		needsPaymentMethod: Ember.computed.not('subscription.hasPaymentMethod'),
		showPaymentMethodPrompt: Ember.computed.and('subscription.isTrialAccount', 'needsPaymentMethod'),

		init: function init() {
			this._super();
			this.set('featureGrid', []);

			// this.getPlanDefinitions();
			this.setupFeatureGrid();
		},


		// getPlanDefinitions() {
		//
		// 	return resolve(this.setProperties({
		// 		userPricePerMonth: USER_PRICE_PER_MONTH,
		// 		userLimitFree: USER_LIMIT_FREE,
		// 		userLimitPro: USER_LIMIT_PRO,
		// 	}));
		// },

		getDollars: function getDollars(amount) {
			return Math.floor(amount);
		},
		getCents: function getCents(amount) {
			return Math.floor((amount - this.getDollars(amount)) * 100);
		},
		setupFeatureGrid: function setupFeatureGrid() {
			// free, pro, enterprise, featureLabel
			// don't localize featureLabel here
			var featureGrid = [this.createGridRow(1, 1, 1, 'Time Tracking'), this.createGridRow(1, 1, 1, 'Projects, Cost Codes, & Equipment'), this.createGridRow(1, 1, 1, 'Overtime Calculation'), this.createGridRow(1, 1, 1, 'Event-Based GPS Tracking'), this.createGridRow(1, 1, 1, 'Basic Reporting'), this.createGridRow(1, 1, 1, 'Digital Time Card Signatures'), this.createGridRow(1, 1, 1, 'Equipment App Integration'), this.createGridRow(1, 1, 1, 'Online Support'), this.createGridRow(1, 1, 1, 'Location-Based Reminders'), this.createGridRow(0, 1, 1, 'Labor Budgets'), this.createGridRow(0, 1, 1, 'Advanced Reporting'), this.createGridRow(0, 1, 1, 'Custom User Permissions'), this.createGridRow(0, 1, 1, 'Job Photos'), this.createGridRow(0, 1, 1, 'Priority Phone & Web Support'), this.createGridRow(0, 1, 1, 'Time Entry Logs'), this.createGridRow(0, 1, 1, 'Time Entry Conflict Resolutions'), this.createGridRow(0, 1, 1, 'QuickBooks Desktop Integration')];

			this.set('featureGrid', featureGrid);
		},
		createGridRow: function createGridRow(free, pro, enterprise, featureLabel) {
			return { featureLabel: featureLabel, free: free, pro: pro, enterprise: enterprise };
		},


		actions: {
			openCurrentPlan: function openCurrentPlan() {
				// call the selectSubscriptionTab method provided to this component by the parent
				this.get('selectSubscriptionTab')('currentPlan');
			},
			openAllPlans: function openAllPlans() {
				// call the selectSubscriptionTab method provided to this component by the parent
				this.get('selectSubscriptionTab')('allPlans');
			},
			openInvoiceHistory: function openInvoiceHistory() {
				// call the selectSubscriptionTab method provided to this component by the parent
				this.get('selectSubscriptionTab')('invoiceHistory');
			},
			openSubscriptionManager: function openSubscriptionManager() {
				this.set('showSubscriptionManager', true);
			},
			closeSubscriptionManager: function closeSubscriptionManager() {
				this.set('showSubscriptionManager', false);
			},
			startTrial: function startTrial() {
				var _this = this;

				this.set('isLoading', true);
				this.get('subscription').startTrial().finally(function () {
					_this.set('isLoading', false);
				});
			},
			downgradeAccount: function downgradeAccount() {
				var _this2 = this;

				this.set('isLoading', true);
				this.get('subscription').setSubscriptionStatusFree().finally(function () {
					_this2.set('isLoading', false);
				});
			},
			openHelpDialog: function openHelpDialog() {
				this.set('showIntercom', true);
				this.set('showReceptive', false);
				this.set('showHelpDialog', true);
			},
			closeHelpDialog: function closeHelpDialog() {
				this.set('showHelpDialog', false);
			},
			openIntercom: function openIntercom() {
				var busyNav = Ember.getOwner(this).lookup('component:busy-nav');
				busyNav.send('openIntercom');
			},
			openFaq: function openFaq() {
				var busyNav = Ember.getOwner(this).lookup('component:busy-nav');
				busyNav.send('openFaq');
			}
		}
	});
});