define('busy-app/mixins/pay-period-loader', ['exports', 'busy-app/mixins/scroll-loader', '@busy-web/utils', 'busy-app/utils/settings'], function (exports, _scrollLoader, _utils, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(_scrollLoader.default, {
		/**
   * Data Service Class to load data
   *
   * @public
   * @property store
   * @type DS.Store
   */
		store: Ember.inject.service('store'),
		payPeriod: Ember.inject.service('pay-period'),

		/**
   * Data retireved from the load query
   *
   * @public
   * @property model
   * @type DS.Model
   */
		model: null,

		/**
   * Public property to check if there is data
   * loadding currently.
   *
   * @public
   * @property isLoading
   * @type boolean
   */
		isLoading: false,

		autoLoad: true,
		oldestEntry: null,

		/**
   * Model loader method for loading models through a query.
   * In this method the meta.next param will be used for future load more queries.
   *
   * @public
   * @method load
   * @param modelType {string} The model type string to load
   * @param query {object} The query filter object
   * @param replaceData {boolean} Replaces the model property rather than adds to it. default: true
   * @returns {EmberPromise}
   */
		load: function load(memberId, offset, limit) {
			var _this = this;

			var replaceData = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
			var autoLoad = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;

			if (this.get('isDestroyed')) {
				return Ember.RSVP.resolve();
			}

			this.set('autoLoad', autoLoad);

			// set isLoading to true
			this.set('isLoading', true);
			this.set('__offset', offset);
			this.set('__limit', limit);
			this.set('memberId', memberId);

			return this._getPayPeriods(memberId, offset, limit).then(function (model) {
				if (!_this.get('isDestroyed')) {
					var promise = Ember.RSVP.resolve(model);

					if (Ember.isNone(_this.get('oldestEntry'))) {
						promise = _this.get('store').query('time-entry', { member_id: memberId, _asc: ['start_time'], page: 1, page_size: 1 }).then(function (entry) {
							_this.set('oldestEntry', entry.get('firstObject'));

							// return pay period promise model
							return model;
						});
					}

					return promise.then(function (model) {
						// adds to the current model data or replaces the current data.
						var data = _this._addData(model, replaceData);

						// trigger afterLoad event
						_this.trigger('afterLoad', model);
						return data;
					});
				}
			}).finally(function () {
				return !_this.get('isDestroyed') && _this.set('isLoading', false);
			});
		},
		_getPayPeriods: function _getPayPeriods(memberId, offset, limit) {
			var _this2 = this;

			var results = limit * (offset + 1);
			var payPeriods = this.get('payPeriod').getPayPeriodRange(_utils.Time.timestamp(), results).slice(limit * offset, results);

			// first getPayPeriods call will not have an oldestEntry set
			// so always load the first set of results
			if (!Ember.isNone(this.get('oldestEntry'))) {
				var endPeriod = this.endPayPeriodTime();

				// load first ten pay periods and any periods that are not older than the endPeriod
				payPeriods = payPeriods.filter(function (item, index) {
					return index < 9 || item.get('start') >= endPeriod;
				});
			}

			// if payPeriods length is greater than 0 then load the actual pay-period-member-seconds models
			if (payPeriods.get('length') > 0) {
				var query = {
					member_id: memberId,
					_gte: { start_time: payPeriods.get('lastObject.start') },
					_lte: { end_time: payPeriods.get('firstObject.end') }
				};

				return this.get('store').query('pay-period-member-seconds', query).then(function (data) {
					var generated = [];
					payPeriods.forEach(function (item) {
						var model = data.findBy('startTime', item.get('start'));
						if (Ember.isNone(model)) {
							model = _this2._createPeriod(memberId, item.get('start'), item.get('end'));
							generated.pushObject(model._internalModel);
						}
					});
					data.pushObjects(generated);
					return data.sortBy('startTime').reverse();
				});
			} else {
				return Ember.RSVP.resolve([]);
			}
		},
		_createPeriod: function _createPeriod(memberId, start, end) {
			// matchup with expected properties
			var model = this.get('store').createRecord('pay-period-member-seconds', {
				startTime: start,
				endTime: end,
				regularSeconds: 0,
				overtimeSeconds: 0,
				doubletimeSeconds: 0,
				memberId: memberId
			});

			return model;
		},


		/**
   * Retrieves the next set of results from the original load query results.
   *
   * @public
   * @method loadMore
   * @returns {EmberPromise}
   */
		loadMore: function loadMore() {
			// make sure there is more
			if (this.get('hasLoadMore')) {
				return this.load(this.get('memberId'), this.get('__offset') + 1, this.get('__limit'), false);
			}
			// return a place holder promise.
			return Ember.RSVP.resolve(this.get('model') || []);
		},
		canLoadMore: function canLoadMore() {
			if (!Ember.isNone(this.get('member.id'))) {
				var start = this.get('model.lastObject.startTime');
				var end = this.endPayPeriodTime();

				if (!Ember.isNone(start) && start > end) {
					return true;
				}
			}
			return false;
		},
		endPayPeriodTime: function endPayPeriodTime() {
			var end = this.get('oldestEntry.startTime');
			if (Ember.isNone(end) || end > this.get('member.createdOn')) {
				end = this.get('member.createdOn');
			}
			return end;
		},


		/**
   * Handles adding or replacing the model data
   *
   * @private
   * @method _addData
   * @param model {DS.RecordArray}
   * @param replaceData {boolean}
   * @returns {DS.RecordArray}
   */
		_addData: function _addData(model) {
			var _this3 = this;

			var replaceData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			if (!this.get('isDestroyed')) {
				// set the model if it has not been set
				// else add objects to the model.
				if (replaceData || Ember.isNone(this.get('model'))) {
					this.set('model', null);
					this.set('model', model);
				} else {
					model.forEach(function (item) {
						_this3.get('model').pushObject(item);
					});
				}

				if (this.canLoadMore()) {
					this.set('hasLoadMore', true);
				} else {
					this.set('hasLoadMore', false);
				}
			}

			return this.get('model');
		},


		/**
   * Handles parsing the next or previous url from the busybusy api
   *
   * @private
   * @method _parseUrlString
   * @param url {string}
   * @returns {object} key:value with modelType:string, query:object
   */
		_parseUrlString: function _parseUrlString(url) {
			// remove the api url
			url = url.replace(_settings.default.get('api_url') + '/', '');

			// split the model from the args
			var urlComponents = url.split('?');

			// set the modelType
			var modelType = urlComponents[0];

			var query = {};
			if (urlComponents[1] !== undefined) {
				// separate the the query params
				var argComponents = urlComponents[1].split('&');

				// loop through the query params to create
				// key value pairs
				argComponents.forEach(function (item) {
					// separate the key from the value
					var parts = item.split('=');

					// add the key:value pair to the args list
					query[parts[0]] = parts[1];
				});
			}

			// return the modelType and query.
			return { modelType: modelType, query: query };
		}
	});
});