define('busy-app/models/equipment-model', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		title: _emberData.default.attr('string'),
		modelNumber: _emberData.default.attr('string'),
		year: _emberData.default.attr('number'),
		equipmentMakeId: _emberData.default.attr('string'),
		equipmentCategoryId: _emberData.default.attr('string'),
		unknown: _emberData.default.attr('boolean'),
		image: _emberData.default.attr('file'),
		imageUrl: _emberData.default.attr('string'),
		imageThumbUrl: _emberData.default.attr('string'),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		equipmentMake: Ember.computed('equipmentMakeId', function () {
			if (!Ember.isNone(this.get('equipmentMakeId'))) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.findRecord('equipment-make', this.get('equipmentMakeId'))
				});
			}
		}),

		equipmentCategory: Ember.computed('equipmentCategoryId', function () {
			if (!Ember.isNone(this.get('equipmentCategoryId'))) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.findRecord('equipment-category', this.get('equipmentCategoryId'))
				});
			}
		})
	});
});