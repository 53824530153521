define('busy-app/components/busy-container', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var kNotificationTypes = ['warning', 'success', 'error', 'info'];

	/**
  * `Component/BusyContainer`
  *
  * @class BusyContainer
  * @namespace Components
  * @extends Component
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['busy-container'],
		classNameBindings: ['fullWidth:full-width', 'fullNav:full-nav-bar', '_notificationName:notif'],

		access: Ember.inject.service('access'),
		subscription: Ember.inject.service('subscription'),

		fullNav: false,
		fullWidth: false,
		hideMenu: false,
		hasBackButton: false,
		isBeta: false,

		notificationName: '',
		notificationType: '',
		notificationText: '',
		isDismissable: true,
		clickText: '',

		_notificationName: Ember.computed('notificationName', 'subscription.subscriptionStatus.isLockout', function () {
			if (this.get('subscription.subscriptionStatus.isLockout')) {
				return 'lockout-account';
			}
			return this.get('notificationName');
		}),

		_notificationText: Ember.computed('notificationText', 'subscription.subscriptionStatus.isLockout', function () {
			if (this.get('subscription.subscriptionStatus.isLockout')) {
				return (0, _utils.loc)("Your account is locked. Employees can not log in until a new payment method is submitted. To avoid further interruption");
			}
			return this.get('notificationText');
		}),

		_clickText: Ember.computed('clickText', 'subscription.subscriptionStatus.isLockout', function () {
			if (this.get('subscription.subscriptionStatus.isLockout')) {
				return (0, _utils.loc)('please resolve this issue now.');
			}
			return this.get('clickText');
		}),

		_isDismissable: Ember.computed('isDismissable', 'subscription.subscriptionStatus.isLockout', function () {
			if (this.get('subscription.subscriptionStatus.isLockout')) {
				return false;
			}
			return this.get('isDismissable');
		}),

		_notificationType: Ember.computed('notificationType', 'subscription.subscriptionStatus.isLockout', function () {
			if (this.get('subscription.subscriptionStatus.isLockout')) {
				return 'error';
			} else if (!Ember.isEmpty(this.get('notificationName'))) {
				_utils.Assert.test("notificationType must be a string: `warning` `success` `error` `info`", kNotificationTypes.indexOf(this.get('notificationType')) !== -1);
				return this.get('notificationType');
			} else {
				return '';
			}
		}),

		_onClick: Ember.computed('onClick', 'subscription.subscriptionStatus.isLockout', function () {
			if (this.get('subscription.subscriptionStatus.isLockout')) {
				return 'openSubscription';
			}
			return this.get('onClick') ? 'notifClick' : undefined;
		}),

		actions: {
			notifClick: function notifClick() {
				this.sendAction('onClick');
			},
			openSubscription: function openSubscription() {
				if (this.get('access').hasPermissionFor('manageCompanySettings')) {
					window.location = '/company-settings#tab-subscription';
				}
			},
			backButton: function backButton() {
				if (!Ember.isNone(this.get('onBack'))) {
					this.sendAction('onBack');
				} else {
					window.history.back();
				}
			}
		}
	});
});