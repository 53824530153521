define('busy-app/controllers/company-settings/intuit-quickbooks', ['exports', 'moment', '@busy-web/utils', 'busy-app/utils/logger', 'busy-app/utils/settings', 'busy-app/utils/docs'], function (exports, _moment, _utils, _logger, _settings, _docs) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service('auth'),
		subscription: Ember.inject.service('subscription'),
		features: Ember.inject.service('features'),

		quickbooksConnected: false,
		hourlyItems: null,
		overtimeItems: null,
		doubletimeItems: null,
		salaryItems: null,
		payrollItems: null,
		saveItems: null,
		showMapping: false,
		isLoading: true,
		syncLog: null,
		currentLog: null,
		showSyncOptionsDialog: false,

		init: function init() {
			this._super.apply(this, arguments);

			this.set('hourlyItems', []);
			this.set('overtimeItems', []);
			this.set('doubletimeItems', []);
			this.set('salaryItems', []);
			this.set('payrollItems', []);
			this.set('saveItems', []);

			this.setQuickBooksStatus();
		},
		downloadQwc: function downloadQwc() {
			var orgId = this.get('auth.organization.id');
			return this.store.rpcRequest('quick-books', 'createQWCFile', { organization_id: orgId }, _settings.default.get('qb_url')).then(function (result) {
				if (result.success) {
					_docs.default.download('busybusy.qwc', 'application/octet-stream', result.data);
					return;
				} else {
					var errStr = (0, _utils.loc)("Something went wrong, please try again later.");
					return Ember.RSVP.reject(errStr);
				}
			}).catch(function () /*res*/{
				var errStr = (0, _utils.loc)("Something went wrong, please try again later.");
				return Ember.RSVP.reject(errStr);
			});
		},


		/**
   * gets the member connector data for the payroll mapping
   * dialog
   *
   * @public
   * @method getMemberData
   * @return {RSPV.Promise}
   */
		getMemberData: function getMemberData() {
			var _this = this;

			// get the member connector data with the qb payroll data
			return Ember.RSVP.hash({
				wageItems: this.store.findAll('quickbooks-payroll-item-wage', { organization_id: this.get('auth.organization.id') }),
				memberConnectors: this.store.findAll('member-connector', { organization_id: this.get('auth.organization.id'), archived_on: '_-NULL-_' })
			}).then(function (mainData) {
				var memberIds = [];
				var memberConnectorIds = [];
				mainData.memberConnectors.forEach(function (connector) {
					memberIds.push(connector.get('memberId'));
					memberConnectorIds.push(connector.get('id'));
				});

				// get the members and the employee payroll connector data
				return Ember.RSVP.hash({
					members: _this.store.findByIds('member', memberIds),
					payrollConnectors: _this.store.findWhereIn('employee-payroll-item-connector', 'member_connector_id', memberConnectorIds, { deleted_on: '_-NULL-_' })
				}).then(function (subData) {
					var connectorIds = [];
					subData.payrollConnectors.forEach(function (connector) {
						connectorIds.push(connector.id);
					});

					// get the employee wage types and return the reset of the data
					return Ember.RSVP.hash({
						wageItems: mainData.wageItems,
						memberConnectors: mainData.memberConnectors,
						members: subData.members,
						payrollConnectors: subData.payrollConnectors,
						wageTypes: _this.store.findWhereIn('employee-wage-type', 'employee_payroll_item_connector_id', connectorIds, { deleted_on: '_-NULL-_' })
					}).then(function (allData) {
						// put the data together for rendering
						return _this.buildWageTypeObjects(allData);
					});
				});
			});
		},


		/**
   * builds an array of member connectors with the data needed to
   * list and show the current wage types
   *
   * @public
   * @method buildWageTypeObjects
   * @param data {object}
   * @return {array}
   */
		buildWageTypeObjects: function buildWageTypeObjects(data) {
			var _this2 = this;

			var removeItems = [];

			// loop through the memberConnectors first because all the data
			// will be stored on the member connector models
			data.memberConnectors.forEach(function (memberConnector) {
				// get the member model
				var member = data.members.findBy('id', memberConnector.get('memberId'));
				memberConnector.set('member', member);

				if (!Ember.isNone(member.get('archivedOn'))) {
					removeItems.pushObject(memberConnector);
				}

				// get the payroll connectors
				var connectors = data.payrollConnectors.filterBy('memberConnectorId', memberConnector.get('id'));
				memberConnector.set('employeePayrollItemConnectors', connectors);

				// create the select menus
				var headerTypes = [];
				var regularList = [];
				var overtimeList = [];
				var doubletimeList = [];

				// loop the connector data and get wage types and add qb payroll types
				connectors.forEach(function (payrollConnector) {
					var qbpayrollItem = data.wageItems.findBy('id', payrollConnector.get('payrollItemWageId'));
					payrollConnector.set('quickbooksPayrollItemWage', qbpayrollItem);

					var wageTypeKey = _this2.wageTypeKey(qbpayrollItem.get('wageType'));
					if (headerTypes.indexOf(qbpayrollItem.get('wageType')) === -1) {
						// add a list item for each type regular, overtime, doubletime, and salary
						regularList.pushObject(Ember.Object.create({ wageName: qbpayrollItem.get('wageTypeFormatted'), _unselectable: true, sortKey: wageTypeKey }));

						if (wageTypeKey !== 40) {
							overtimeList.pushObject(Ember.Object.create({ wageName: qbpayrollItem.get('wageTypeFormatted'), _unselectable: true, sortKey: wageTypeKey }));
							doubletimeList.pushObject(Ember.Object.create({ wageName: qbpayrollItem.get('wageTypeFormatted'), _unselectable: true, sortKey: wageTypeKey }));
						}
						headerTypes.push(qbpayrollItem.get('wageType'));
					}

					// add a list item for each type regular, overtime, doubletime, and salary
					regularList.pushObject(Ember.Object.create({
						id: payrollConnector.get('id'),
						wageName: payrollConnector.get('wageName'),
						sortKey: wageTypeKey + 1,
						wageType: wageTypeKey,
						memberId: member.get('id')
					}));

					if (wageTypeKey !== 40) {
						overtimeList.pushObject(Ember.Object.create({
							id: payrollConnector.get('id'),
							wageName: payrollConnector.get('wageName'),
							sortKey: wageTypeKey + 1,
							wageType: wageTypeKey,
							memberId: member.get('id')
						}));

						doubletimeList.pushObject(Ember.Object.create({
							id: payrollConnector.get('id'),
							wageName: payrollConnector.get('wageName'),
							sortKey: wageTypeKey + 1,
							wageType: wageTypeKey,
							memberId: member.get('id')
						}));
					}

					// get the wage types and add selected to the lists for any type found in the wage types
					var wageTypes = data.wageTypes.filterBy('employeePayrollItemConnectorId', payrollConnector.get('id'));
					wageTypes.forEach(function (wageType) {
						if (wageType.get('payrollType') === 10 || wageType.get('payrollType') === 40) {
							regularList[regularList.length - 1].set('_selected', true);
							regularList[regularList.length - 1].set('currentWageType', true);
						} else if (wageType.get('payrollType') === 20) {
							overtimeList[overtimeList.length - 1].set('_selected', true);
							overtimeList[overtimeList.length - 1].set('currentWageType', true);
						} else if (wageType.get('payrollType') === 30) {
							doubletimeList[doubletimeList.length - 1].set('_selected', true);
							doubletimeList[doubletimeList.length - 1].set('currentWageType', true);
						}
					});

					payrollConnector.set('employeeWageTypes', wageTypes);
				});

				// add the lists to the memberconnector
				memberConnector.set('regularList', regularList.sortBy('sortKey', 'employeePayrollItemConnectors.wageName'));
				memberConnector.set('overtimeList', overtimeList.sortBy('sortKey', 'employeePayrollItemConnectors.wageName'));
				memberConnector.set('doubletimeList', doubletimeList.sortBy('sortKey', 'employeePayrollItemConnectors.wageName'));
			});

			// remove archived members from list.
			data.memberConnectors.removeObjects(removeItems);

			return data.memberConnectors.sortBy('member.firstName', 'member.lastName');
		},
		wageTypeKey: function wageTypeKey(wageType) {
			if (wageType === 'HourlyRegular') {
				return 10;
			} else if (wageType === 'HourlyOvertime') {
				return 20;
			} else if (wageType === 'HourlyDoubletime') {
				return 30;
			} else if (wageType === 'SalaryRegular') {
				return 40;
			} else {
				return 90;
			}
		},
		setQuickBooksStatus: function setQuickBooksStatus() {
			var _this3 = this;

			if (this.get('features.quickbooks')) {
				var orgId = this.get('auth.organization.id');
				this.store.rpcRequest('quick-books', 'getStatus', { organization_id: orgId }, _settings.default.get('qb_url')).then(function (result) {
					_this3.set('quickbooksStatus', result.data);
					if (result.data === 'connected' || result.data === 'pending') {
						_this3.set('quickbooksConnected', true);
					}
				}).catch(function (reason) {
					_logger.default.error('IntuitQuickbooks', 'setQuickBooksStatus', { reason: reason });
					throw reason;
				});
			} else {
				this.set('quickbooksStatus', 'Subscription Required');
			}
		},


		/**
   * gets the changed items for saving the payroll mapping
   *
   * @public
   * @method getSaveItems
   * @return {array}
   */
		getSaveItems: function getSaveItems() {
			var items = [];
			var memberConnectors = this.get('memberConnectors');
			memberConnectors.forEach(function (connector) {
				var isNewConnector = connector.get('syncTimeEntries') === false && connector.get('mappingConfirmation') === false;

				// Any selected items will be set before the view renders or when a user
				// selects an item from the drop down.
				var regularSelected = connector.get('regularList').findBy('_selected', true);
				var overtimeSelected = connector.get('overtimeList').findBy('_selected', true);
				var doubletimeSelected = connector.get('doubletimeList').findBy('_selected', true);

				// Any current wage types will be set before rendering the view
				var regularOrig = connector.get('regularList').findBy('currentWageType', true);
				var overtimeOrig = connector.get('overtimeList').findBy('currentWageType', true);
				var doubletimeOrig = connector.get('doubletimeList').findBy('currentWageType', true);

				var hasRegular = !Ember.isNone(regularSelected) && regularSelected.get('wageType') !== 40;
				var hasOvertime = !Ember.isNone(overtimeSelected);
				var hasDoubletime = !Ember.isNone(doubletimeSelected);
				var hasSalary = !Ember.isNone(regularSelected) && regularSelected.get('wageType') === 40;

				// if a selected item exists and it does not match a currentWageType item then it is considered changed
				var regularChanged = hasRegular && (Ember.isNone(regularOrig) || regularSelected.get('id') !== regularOrig.get('id') || isNewConnector);
				var overtimeChanged = hasOvertime && (Ember.isNone(overtimeOrig) || overtimeSelected.get('id') !== overtimeOrig.get('id') || isNewConnector);
				var doubletimeChanged = hasDoubletime && (Ember.isNone(doubletimeOrig) || doubletimeSelected.get('id') !== doubletimeOrig.get('id') || isNewConnector);
				var salaryChanged = hasSalary && (Ember.isNone(regularOrig) || regularSelected.get('id') !== regularOrig.get('id') || isNewConnector);

				// if one item changes then submit all items for that member again if they exist.
				if (!salaryChanged && (regularChanged || overtimeChanged || doubletimeChanged)) {
					if (hasRegular) {
						items.push({ epic_id: regularSelected.get('id'), type: 10 });
					}

					if (hasOvertime) {
						items.push({ epic_id: overtimeSelected.get('id'), type: 20 });
					}

					if (hasDoubletime) {
						items.push({ epic_id: doubletimeSelected.get('id'), type: 30 });
					}
				} else if (salaryChanged) {
					if (hasSalary) {
						items.push({ epic_id: regularSelected.get('id'), type: 40 });
					}
				}
			});

			// return the save list
			return items;
		},
		getSyncLogData: function getSyncLogData() {
			var _this4 = this;

			var orgId = this.get('auth.organization.id');
			this.set('syncLog', []);
			this.store.findAll('sync-log', { organization_id: orgId }).then(function (result) {
				result = result.sortBy('createdOn').reverse();
				result.forEach(function (data) {
					var employeeData = JSON.parse(data.get('employeeData'));
					var projectData = JSON.parse(data.get('projectData'));
					var costCodeData = JSON.parse(data.get('costCodeData'));
					var payrollItemData = JSON.parse(data.get('payrollItemData'));
					var createdOn = data.get('createdOn');
					var newDataObject = Ember.Object.create({
						employeeData: employeeData,
						projectData: projectData,
						costCodeData: costCodeData,
						payrollItemData: payrollItemData,
						createdOn: createdOn,
						displayDate: (0, _moment.default)(createdOn * 1000).format('MMM DD, YYYY h:mm A'),
						_isSelected: false
					});

					_this4.get('syncLog').pushObject(newDataObject);
				});
			});
		},


		sliceSyncLog: Ember.observer('syncLog.[]', function () {
			this.get('syncLog').slice(0, 10);
		}),

		setTransition: function setTransition(item, forceState) {
			var toggle = item.get('showMapping');
			var transitionState = item.get('doneTransition');
			if (toggle === transitionState) {
				if (!toggle && forceState === undefined || forceState === true) {
					item.set('showMapping', true);
					Ember.run.later(this, function () {
						item.set('doneTransition', true);
					}, 300);
				} else if (toggle && forceState === undefined || forceState === false) {
					item.set('showMapping', false);
					item.set('doneTransition', false);
				}
			}
		},


		isMappingOpen: Ember.computed('memberConnectors.@each.showMapping', function () {
			var connectors = this.get('memberConnectors').filterBy('hasPayrollItems', true);
			var totalConnectors = connectors.get('length');
			var openConnectors = connectors.filterBy('showMapping', true).get('length');
			var currentState = this.get('showMapping');

			if (currentState === true && openConnectors > 0) {
				return true;
			} else if (currentState === false && totalConnectors === openConnectors) {
				this.set('showMapping', true);
				return true;
			} else if (currentState === true && openConnectors === 0) {
				this.set('showMapping', false);
				return false;
			}
			return false;
		}),

		actions: {
			regularSelected: function regularSelected(item) {
				if (item.get('wageType') === 40) {
					var connector = this.get('memberConnectors').findBy('memberId', item.get('memberId'));
					connector.set('hasHourlyItems', false);
					connector.set('hasSalaryItems', true);
				}
			},
			salarySelected: function salarySelected(item) {
				if (item.get('wageType') !== 40) {
					var connector = this.get('memberConnectors').findBy('memberId', item.get('memberId'));
					connector.set('hasHourlyItems', true);
					connector.set('hasSalaryItems', false);
				}
			},
			openConnector: function openConnector() {
				if (this.get('quickbooksConnected')) {
					this.set('showQuickBooksDialog', true);
				} else {
					this.set('showConnectorDialog', true);
				}
			},
			openMappingDialog: function openMappingDialog() {
				var _this5 = this;

				if (this.get('quickbooksConnected')) {
					this.set('showQuickBooksDialog', false);
					this.set('showMappingDialog', true);
					this.set('isLoading', true);

					this.getMemberData().then(function (data) {
						_this5.set('memberConnectors', data);
						_this5.set('isLoading', false);
					});
				}
			},
			openDisconnectDialog: function openDisconnectDialog() {
				this.set('showDisconnectDialog', true);
			},
			closeDisconnectDialog: function closeDisconnectDialog() {
				this.set('showDisconnectDialog', false);
			},
			disconnectQuickBooks: function disconnectQuickBooks() {
				var _this6 = this;

				var orgId = this.get('auth.organization.id');
				this.store.rpcRequest('quick-books', 'disconnect', { organization_id: orgId }, _settings.default.get('qb_url')).then(function (result) {
					// TODO:
					// show a success box that tells the user quickbooks was disconnected.
					_this6.set('showDisconnectDialog', false);
					_logger.default.info(result);
				});
			},
			openSyncLogDialog: function openSyncLogDialog() {
				if (this.get('quickbooksConnected')) {
					this.set('showSyncLogDialog', true);
					this.getSyncLogData();
				}
			},
			closeSyncLogDialog: function closeSyncLogDialog() {
				this.set('currentLog', null);
				this.set('showSyncLogDialog', false);
			},
			closeQuickBooksDialog: function closeQuickBooksDialog() {
				this.set('showQuickBooksDialog', false);
				this.set('showConnectorDialog', false);
				this.set('showMappingDialog', false);
				this.set('showStepThreeDialog', false);
				this.set('isLoading', true);
				this.set('showMapping', false);
			},
			downloadQwcFile: function downloadQwcFile() {
				return this.downloadQwc();
			},
			saveQuickBooks: function saveQuickBooks(dialog) {
				dialog.showSavingMessage();
				var epicIdArray = this.getSaveItems();
				if (epicIdArray.length > 0) {
					this.store.rpcRequest('quick-books', 'confirmMemberMapping', { member_mapping: epicIdArray }, _settings.default.get('qb_url')).then(function () {
						dialog.showSavedMessage();
					});
				} else {
					dialog.showErrorMessage((0, _utils.loc)('No payroll items set or changed!'));
				}
			},
			toggleMapping: function toggleMapping(item) {
				this.setTransition(item);
			},
			expandAll: function expandAll() {
				var _this7 = this;

				var mappingMembers = this.get('memberConnectors');
				this.set('showMapping', true);
				mappingMembers.forEach(function (member) {
					_this7.setTransition(member, true);
				});
			},
			collapseAll: function collapseAll() {
				var _this8 = this;

				var mappingMembers = this.get('memberConnectors');
				this.set('showMapping', false);
				mappingMembers.forEach(function (member) {
					_this8.setTransition(member, false);
				});
			},
			setCurrentLog: function setCurrentLog(item) {
				this.set('currentLog', item);
			},
			openSyncOptionsDialog: function openSyncOptionsDialog() {
				if (this.get('quickbooksConnected')) {
					this.set('showSyncOptionsDialog', true);
				}
			},
			closeSyncOptionsDialog: function closeSyncOptionsDialog() {
				if (!this.get('isDestroyed')) {
					this.set('showSyncOptionsDialog', false);
				}
			}
		}
	});
});