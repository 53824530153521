define('busy-app/utils/models/managers/quickbooks', ['exports', 'busy-app/utils/models/managers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		organization: null,

		fetch: function fetch() {
			var _this = this;

			var orgId = this.get('organization.id');
			var mappingMembers = [];
			this.store.query('member', { organization_id: orgId, archived_on: '_-NULL-_' }).then(function (members) {
				var memberIds = [];
				members.forEach(function (member) {
					memberIds.push(member.id);
				});

				_this.store.findWhereIn('member-connector', 'member_id', memberIds, { deleted_on: '_-NULL-_' }).then(function (memberConnectors) {
					memberConnectors.forEach(function (connector) {
						_this.store.findRecord('member', connector.get('memberId')).then(function (member) {
							member.set('connectorId', connector.id);
							mappingMembers.pushObject(member);
						});
					});
				});
			});

			Ember.RSVP.hash({ members: mappingMembers });
		}
	});
});