define('busy-app/components/-private-api/addon-factory', ['exports', 'ember-google-maps/components/-private-api/addon-factory'], function (exports, _addonFactory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _addonFactory.default;
    }
  });
});