define('busy-app/controllers/login', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		dataStore: Ember.inject.service('data-store'),

		loginEmail: '',
		loginPass: '',
		loginRememberMe: 1,
		isLoginError: false,
		loginErrorMessage: '',

		cancelKey: null,

		init: function init() {
			this._super();
			if (!window.runningTests) {
				var tempKey = this.get('dataStore').suspendedKey();
				if (!Ember.isEmpty(tempKey) && !Ember.isNone(tempKey)) {
					this.set('cancelKey', tempKey);
				}
			}
		},
		login: function login() {
			var _this = this;

			if (!Ember.isEmpty(this.get('loginEmail')) && !Ember.isEmpty(this.get('loginPass'))) {
				this.set('isLoginError', false);
				this.send('authenticateSession', this.get('loginEmail'), this.get('loginPass'), function () {
					return _this.loginSuccess();
				}, function (err) {
					return _this.loginError(err);
				});
			} else {
				this.set('isLoginError', true);
				this.set('loginErrorMessage', (0, _utils.loc)('You must provide a username and password!'));
			}
		},
		loginSuccess: function loginSuccess() {
			this.send('reloadAuth');
			this.transitionToRoute('index');
			return window.location = "/";
		},
		loginError: function loginError(err) {
			if (err.status === 401) {
				if (err.code === 4011) {
					this.set('isLoginError', true);
					this.set('loginErrorMessage', (0, _utils.loc)('Access to your account has been removed.'));
				} else {
					this.set('isLoginError', true);
					this.set('loginErrorMessage', (0, _utils.loc)('The username or password is incorrect.'));
				}
			}
		},


		actions: {
			submitAction: function submitAction() {
				this.login();
			},
			cancelAdvancedSignin: function cancelAdvancedSignin() {
				this.get('dataStore').resumeAuth();
				window.location = '/';
			}
		}
	});
});