define('busy-app/components/projects/cost-codes', ['exports', '@busy-web/utils', 'busy-app/utils/logger', 'busy-app/utils/collection'], function (exports, _utils, _logger, _collection) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({
		// component definition
		classNames: ['c--projects--cost-codes'],
		tagName: 'section',

		// services
		auth: Ember.inject.service(),
		state: Ember.inject.service(),
		store: Ember.inject.service(),

		// arguments
		project: null,
		showCostCodesManager: false,
		linkedCostCodes: null,

		// component state
		costCodeGroupFilter: null,

		// collections
		projectCostCodeLinks: null,
		costCodeGroups: null,
		costCodes: null,

		canManageProjects: Ember.computed('auth.authMemberPosition.manageProjects', function () {
			return Ember.get(this, 'auth.authMemberPosition.manageProjects');
		}),

		// costCodesFiltered: computed.alias('costCodes'),
		costCodesFiltered: Ember.computed('costCodes.@each.{costCodeGroupId}', 'costCodeGroupFilter', function () {
			return this.filterCostCodes(Ember.get(this, 'costCodes'), Ember.get(this, 'costCodeGroupFilter'));
		}),

		// collections sort config
		costCodesSorter: Object.freeze(['costCode', 'id']), // eslint-disable-line ember/avoid-leaking-state-in-ember-objects
		costCodeGroupsSorter: Object.freeze(['groupName', 'id']), // eslint-disable-line ember/avoid-leaking-state-in-ember-objects

		// collections - sorted, for output
		costCodesSorted: Ember.computed.sort('costCodesFiltered', 'costCodesSorter'),
		costCodeGroupsSorted: Ember.computed.sort('costCodeGroups', 'costCodeGroupsSorter'),

		noEntries: Ember.computed('isLoaded', 'projectCostCodeLinks.length', function () {
			return Ember.get(this, 'isLoaded') && Ember.get(this, 'projectCostCodeLinks.length') === 0;
		}),

		costCodeGroupFilterOptions: Ember.computed('costCodeGroupFilter', 'costCodeGroupsSorted.@each.{isFulfilled,id,groupName}', function () {
			return this.getCostCodeGroupFilterOptions(Ember.get(this, 'costCodeGroupsSorted'), Ember.get(this, 'costCodeGroupFilter'));
		}),

		costCodeGroupsHash: Ember.computed('costCodeGroups.[]', function () {
			return (0, _collection.keyBy)(Ember.get(this, 'costCodeGroups'), 'id');
		}),

		currentGroupFilter: Ember.computed('costCodeGroupFilterOptions.@each._selected', function () {
			return Ember.get(this, 'costCodeGroupFilterOptions').findBy('_selected');
		}),

		init: function init() {
			this._super.apply(this, arguments);

			if (!window.runningTests) {
				this.loadData();
			}
		},
		loadData: function loadData() {
			var _this = this;

			// Logger.info(this, 'loadData');

			Ember.setProperties(this, {
				costCodes: null,
				costCodeGroups: null,
				projectCostCodeLinks: null,

				isLoaded: false,
				isLoading: true
			});

			return Ember.RSVP.hash({
				projectCostCodeLinks: this.loadProjectCostCodeLinks(),
				costCodeGroups: this.loadCostCodeGroups()
			}).then(function (_ref) {
				var projectCostCodeLinks = _ref.projectCostCodeLinks,
				    costCodeGroups = _ref.costCodeGroups;

				return _this.loadCostCodes(projectCostCodeLinks).then(function (costCodes) {
					Ember.setProperties(_this, {
						costCodes: costCodes,
						costCodeGroups: costCodeGroups,
						projectCostCodeLinks: projectCostCodeLinks,

						isLoaded: true,
						isLoading: false
					});

					return {
						costCodes: costCodes,
						costCodeGroups: costCodeGroups,
						projectCostCodeLinks: projectCostCodeLinks
					};
				});
			});
		},
		loadProjectCostCodeLinks: function loadProjectCostCodeLinks() {
			return Ember.get(this, 'store').findAll('project-cost-code-link', { project_id: Ember.get(this, 'project.id') });
		},
		loadCostCodeGroups: function loadCostCodeGroups() {
			return Ember.get(this, 'state').findAll('cost-code-group');
		},
		loadCostCodes: function loadCostCodes(projectCostCodeLinks) {
			if (Ember.isNone(projectCostCodeLinks)) {
				return Ember.RSVP.resolve(projectCostCodeLinks);
			}

			var ids = projectCostCodeLinks.mapBy('costCodeId');
			// Logger.info(this, 'loadCostCodes', ids);

			return Ember.get(this, 'state').findRecords('cost-code', ids);
		},
		getCostCodeGroupFilterOptions: function getCostCodeGroupFilterOptions(costCodeGroups, costCodeGroupFilter) {
			var options = [Ember.Object.create({ key: null, value: (0, _utils.loc)('All'), _selected: costCodeGroupFilter === null }), Ember.Object.create({ key: 'ungrouped', value: (0, _utils.loc)('Ungrouped'), _selected: costCodeGroupFilter === 'ungrouped' })];

			Ember.get(this, 'costCodeGroupsSorted').forEach(function (group) {
				return options.push(Ember.Object.create({ key: Ember.get(group, 'id'), value: Ember.get(group, 'groupName'), _selected: costCodeGroupFilter === Ember.get(group, 'id') }));
			});

			return options;
		},
		filterCostCodes: function filterCostCodes(costCodes, costCodeGroupFilter) {
			// Logger.info(this, 'filterCostCodes', costCodes, costCodeGroupFilter);

			if (Ember.isNone(costCodes)) {
				return costCodes;
			}

			costCodes = costCodes.filterBy('archivedOn', null);

			if (!Ember.isNone(costCodeGroupFilter)) {
				var filterBy = costCodeGroupFilter === 'ungrouped' ? null : costCodeGroupFilter;
				costCodes = costCodes.filterBy('costCodeGroupId', filterBy);
			}

			// Logger.info(this, 'filterCostCodes', costCodes);
			// Logger.info(this, 'filterCostCodes', costCodes.mapBy('id'));


			return costCodes;
		},
		rollbackManagerChanges: function rollbackManagerChanges() {
			var _this2 = this;

			// Logger.info(this, 'rollbackManagerChanges', get(this, 'costCodeManagerProjectLinks'));

			Ember.get(this, 'costCodeManagerProjectLinks').forEach(function (model) {
				model.rollbackAttributes();
			});

			// return this.loadData().then(() => {});
			return Ember.get(this, 'project.projectCostCodeLinks').reload().then(function () {

				return Ember.setProperties(_this2, {
					costCodeManagerProjectLinks: [],
					showCostCodesManager: false
				});
			});
		},
		saveManagerChanges: function saveManagerChanges(incomingProjectCostCodeLinks) {
			var _this3 = this;

			// Logger.info(this, 'saveManagerChanges');
			var changedModels = incomingProjectCostCodeLinks.filterBy('hasDirtyAttributes');

			if (Ember.isNone(changedModels)) {
				return Ember.RSVP.resolve();
			}

			return Ember.RSVP.all(changedModels.map(function (model) {
				return model.save();
			})).then(function () {
				return _this3.loadData();
			}).catch(function (err) {
				_logger.default.error(_this3, 'saveManagerChanges', err);

				return Ember.RSVP.reject(err);
			});
		},


		actions: {
			onSelectGroupFilter: function onSelectGroupFilter(item) {
				// Logger.info(this, 'onSelectGroupFilter');
				Ember.setProperties(this, { costCodeGroupFilter: Ember.get(item, 'key') });
			},
			onClearGroupFilter: function onClearGroupFilter() {
				this.send('onSelectGroupFilter', { key: null });
			},
			manageCostCodes: function manageCostCodes() {
				// Logger.info(this, 'manageCostCodes');

				if (Ember.get(this, 'canManageProjects')) {
					Ember.setProperties(this, {
						costCodeManagerProjectLinks: [].concat(_toConsumableArray(Ember.get(this, 'projectCostCodeLinks').toArray())),
						showCostCodesManager: true
					});
				}
			},
			onRollBackManagerChanges: function onRollBackManagerChanges() {
				// Logger.info(this, 'onRollBackManagerChanges');

				return this.rollbackManagerChanges();
			},
			onSaveManagerChanges: function onSaveManagerChanges(projectCostCodeLinks) {
				// Logger.info(this, 'onSaveManagerChanges');

				return this.saveManagerChanges(projectCostCodeLinks);
			}
		}
	});
});