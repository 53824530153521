define('busy-app/components/paper-datetime-picker', ['exports', '@busy-web/ember-date-time/components/ember-date-time-picker'], function (exports, _emberDateTimePicker) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	(function () {
		Ember.deprecate('{{paper-datetime-picker}} has been changed to {{ember-date-time-picker}}. Please migrate to use this.', false, { id: '@busy-web-ember-date-time-paper-datetime-picker', until: 'v2.4.0' });
	})();

	exports.default = _emberDateTimePicker.default;
});