define('busy-app/utils/models/filters/time-entry', ['exports', 'busy-app/utils/models/filters/base', '@busy-web/utils/assert'], function (exports, _base, _assert) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		features: Ember.inject.service('features'),

		/**
   * gets the open time entry model
   *
   * @method findOpenEntry
   */
		findOpenEntry: function findOpenEntry(memberId) {
			var query = { end_time: null, member_id: memberId };

			if (window.runningTests) {
				return Ember.RSVP.resolve();
			}
			return this.store.query('time-entry', query).then(function (entries) {
				return entries.objectAt(0);
			});
		},
		findOpenEntrySocket: function findOpenEntrySocket(memberId) {
			var query = { end_time: null, member_id: memberId };

			if (window.runningTests) {
				return Ember.RSVP.resolve();
			}
			return this.store.socket('time-entry', query);
		},
		findAllOpenEntries: function findAllOpenEntries(memberId, start, end) {
			var query = { end_time: null };

			if (!Ember.isNone(memberId)) {
				_assert.default.isUUID(memberId);
				query.member_id = memberId;
			}

			if (!Ember.isNone(start)) {
				_assert.default.isNumber(start);
				query._gte = { start_time: start };
			}

			if (!Ember.isNone(end)) {
				_assert.default.isNumber(end);
				query._lt = { start_time: end };
			}

			if (window.runningTests) {
				return Ember.RSVP.resolve([]);
			}
			return this.store.query('time-entry', query);
		},


		/**
   * returns a date range of time-entries with no dependent
   * models included.
   *
   * @public
   * @method range
   * @param start {number} start epoch date
   * @param end {number} end epoch date
   * @param memberId {string} member model id
   * @param projectId {string} project model id
   * @param costCodeId {string} cost-code model id
   * @returns {promise}
   */
		range: function range(start, end, memberId, projectId, costCodeId) {
			_assert.default.isNumber(start);
			_assert.default.isNumber(end);

			var query = {
				_gte: { end_time: start },
				_lte: { start_time: end },
				_desc: ['start_time'],
				deleted_on: null
			};

			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			if (!Ember.isNone(projectId)) {
				query.project_id = projectId;
			}

			if (!Ember.isNone(costCodeId)) {
				query.cost_code_id = costCodeId;
			}

			if (window.runningTests) {
				return Ember.RSVP.resolve([]);
			}
			return this.store.query('time-entry', query);
		},
		findAllByRange: function findAllByRange(start, end, memberId, projectId, costCodeId, equipmentId, exclusive) {
			_assert.default.isNumber(start);
			_assert.default.isNumber(end);

			var query = {
				_gte: { end_time: start },
				_lte: { start_time: end },
				_desc: ['start_time'],
				deleted_on: null
			};

			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			if (!Ember.isNone(costCodeId)) {
				query.cost_code_id = costCodeId;
			}

			if (!Ember.isNone(equipmentId)) {
				query.equipment_id = equipmentId;
			}

			if (!Ember.isNone(projectId) && (projectId === null || projectId === '_-NULL-_' || exclusive)) {
				query.project_id = projectId;
				projectId = null;
			}

			if (window.runningTests) {
				return Ember.RSVP.resolve([]);
			}

			if (!Ember.isNone(projectId)) {
				return this.store.filter('project').findWithAllChildren('time-entry', projectId, query);
			} else {
				return this.store.findAll('time-entry', query);
			}
		},
		getRelatedModels: function getRelatedModels(timeEntry) {
			if (Ember.isNone(timeEntry) || window.runningTests) {
				return Ember.RSVP.resolve({ entry: null, breaks: null, project: null, costCode: null, equipment: null });
			}

			var projectPromise = this.store.filter('project').findFullProject(timeEntry.get('projectId'));
			var costCodePromise = Ember.RSVP.resolve(null);
			if (!Ember.isNone(timeEntry.get('costCodeId'))) {
				costCodePromise = this.store.findRecord('costCode', timeEntry.get('costCodeId'));
			}

			var equipmentPromise = Ember.RSVP.resolve(null);
			if (!Ember.isNone(timeEntry.get('equipmentId'))) {
				equipmentPromise = this.store.findRecord('equipment', timeEntry.get('equipmentId'));
			}

			var startLocPromise = Ember.RSVP.resolve(null);
			if (!Ember.isNone(timeEntry.get('startLocationId'))) {
				startLocPromise = this.store.findRecord('member-location', timeEntry.get('startLocationId'));
			}

			var endLocPromise = Ember.RSVP.resolve(null);
			if (!Ember.isNone(timeEntry.get('endLocationId'))) {
				endLocPromise = this.store.findRecord('member-location', timeEntry.get('endLocationId'));
			}

			return Ember.RSVP.hash({
				entry: timeEntry,
				breaks: this.store.query('time-entry-break', { time_entry_id: timeEntry.id, deleted_on: null }),
				project: projectPromise,
				costCode: costCodePromise,
				equipment: equipmentPromise,
				startLocation: startLocPromise,
				endLocation: endLocPromise
			});
		},
		getRelatedModelsForEntries: function getRelatedModelsForEntries(timeEntries) {
			if (Ember.isEmpty(timeEntries) || window.runningTests) {
				return Ember.RSVP.resolve(timeEntries);
			}

			var projectIds = [];
			var costCodeIds = [];
			var equipmentIds = [];
			var startLocationIds = [];
			var endLocationIds = [];
			var breakIds = [];

			timeEntries.forEach(function (entry) {
				if (!Ember.isNone(entry.get('projectId')) && projectIds.indexOf(entry.get('projectId')) === -1) {
					projectIds.push(entry.get('projectId'));
				}

				if (!Ember.isNone(entry.get('costCodeId')) && costCodeIds.indexOf(entry.get('costCodeId')) === -1) {
					costCodeIds.push(entry.get('costCodeId'));
				}

				if (!Ember.isNone(entry.get('equipmentId')) && equipmentIds.indexOf(entry.get('equipmentId')) === -1) {
					equipmentIds.push(entry.get('equipmentId'));
				}

				if (!Ember.isNone(entry.get('startLocationId')) && startLocationIds.indexOf(entry.get('startLocationId')) === -1) {
					startLocationIds.push(entry.get('startLocationId'));
				}
				if (!Ember.isNone(entry.get('endLocationId')) && endLocationIds.indexOf(entry.get('endLocationId')) === -1) {
					endLocationIds.push(entry.get('endLocationId'));
				}

				if (breakIds.indexOf(entry.get('id')) === -1) {
					breakIds.push(entry.get('id'));
				}
			});

			var equipmentPromise = Ember.RSVP.resolve([]);
			if (this.get('features.equipment')) {
				equipmentPromise = this.store.findWhereIn('equipment', 'id', equipmentIds, { deleted_on: null });
			}

			return Ember.RSVP.hash({
				entries: timeEntries,
				breaks: this.store.findWhereIn('time-entry-break', 'time_entry_id', breakIds, { deleted_on: null }),
				projects: this.store.filter('project').findFullProjects(projectIds),
				costCodes: this.store.findWhereIn('cost-code', 'id', costCodeIds, { deleted_on: null }),
				equipment: equipmentPromise,
				startLocations: this.store.findWhereIn('member-location', 'id', startLocationIds, { deleted_on: null }),
				endLocations: this.store.findWhereIn('member-location', 'id', endLocationIds, { deleted_on: null })
			}).then(function (data) {
				data.entries.forEach(function (entry) {
					var relationships = {};
					relationships.timeEntryBreaks = data.breaks.filterBy('timeEntryId', entry.get('id'));
					relationships.project = data.projects.findBy('id', entry.get('projectId'));
					relationships.costCode = data.costCodes.findBy('id', entry.get('costCodeId'));
					relationships.equipment = data.equipment.findBy('id', entry.get('equipmentId'));
					relationships.startLocation = data.startLocations.findBy('id', entry.get('startLocationId'));
					relationships.endLocation = data.endLocations.findBy('id', entry.get('endLocationId'));
					entry.setProperties(relationships);
				});
				return data.entries;
			});
		}
	});
});