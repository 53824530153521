define('busy-app/utils/query', ['exports', '@busy-web/data/utils/query'], function (exports, _query) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _query.default;
    }
  });
});