define('busy-app/utils/integrations/google-tag-manager', ['exports', 'busy-app/utils/integrations/base', 'busy-app/utils/settings'], function (exports, _base, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		featureName: 'integrations/analytics',

		load: function load(callback) {
			/*jshint ignore:start */
			/**
    * google tag manager
    */
			(function (w, d, s, l, i) {
				w[l] = w[l] || [];w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });var f = d.getElementsByTagName(s)[0],
				    j = d.createElement(s),
				    dl = l != 'dataLayer' ? '&l=' + l : '';j.async = true;j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);
			})(window, document, 'script', 'dataLayer', _settings.default.get('google_tag_id'));
			/*jshint ignore:end */

			callback();
		},
		update: function update() {
			if (this.isEnabled()) {
				window.dataLayer.push({
					'event': 'Page View',
					'url': window.location.href,
					'url hostname': window.location.hostname,
					'url path': window.location.pathname,
					'Page Url': window.location.href,
					'Page Hostname': window.location.hostname,
					'Page Path': window.location.pathname
				});
			}
		}
	});
});