define('busy-app/utils/models/collections/time-card-report', ['exports', 'busy-app/utils/models/collections/time-entry-report'], function (exports, _timeEntryReport) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _timeEntryReport.default.extend({
		auth: Ember.inject.service(),
		payPeriod: Ember.inject.service('payPeriod'),

		currentPeriod: Ember.computed('auth.organizationPayPeriod.[]', 'start', 'end', function () {
			var period = this.get('payPeriod').getPayPeriod(this.get('start'));
			if (!Ember.isNone(period)) {
				if (period.get('start') === this.get('start') && period.get('end') === this.get('end')) {
					return period;
				}
			}
			return null;
		}),

		memberTimeDocument: Ember.computed('model.memberTimeDocuments', 'currentPeriod', function () {
			if (!Ember.isNone(this.get('currentPeriod'))) {
				// return this.get('model.memberTimeDocuments').findBy('startTime', this.get('currentPeriod.start'));
				return this.get('model.memberTimeDocuments').findBy('edited', null);
			}
			return null;
		})
	});
});