define('busy-app/utils/models/managers/payroll-basic-csv', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		start: null,
		end: null,
		showProject: false,
		showCostCode: false,

		filename: Ember.computed('start', 'end', function () {
			var start = _utils.Time.date(this.get('start')).format('MM-DD-YY');
			var end = _utils.Time.date(this.get('end')).format('MM-DD-YY');
			return 'Payroll_Basic_' + start + '_to_' + end;
		}),

		getSorted: function getSorted() {
			return this.get('model').sortBy('model.firstName', 'model.lastName', 'wageKey');
		},


		dataMap: Ember.computed(function () {
			// dataMap for the csv export key:val object where key is the path to a
			// data property and the val is the header title
			var dataMap = {
				'model.memberNumber': 'Employee ID',
				'model.firstName': 'First Name',
				'model.lastName': 'Last Name',
				'wage': 'Wage',
				'wageType': 'Wage Type',
				'hours': 'Hours'
			};
			return dataMap;
		}),

		fetch: function fetch(start, end) {
			this.set('start', start);
			this.set('end', end);

			return Ember.RSVP.hash({
				payPeriod: this.dispatcherFor('member-pay-period').find(start, end),
				timeOff: this.store.filter('time-off').findAllByRange(start, end)
			});
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			data.payPeriod.members.forEach(function (item) {
				_this.addCollection(item, data);
			});
		},
		addCollection: function addCollection(member, data) {
			var collection = this.collectionFor().push(member);

			var wage = data.payPeriod.wage.filterBy('memberId', member.id).sortBy('changeDate').reverse().objectAt(0);
			collection.set('wageHistory', wage);

			var seconds = data.payPeriod.seconds.findBy('memberId', member.id);
			collection.set('seconds', seconds);

			var cost = data.payPeriod.cost.findBy('memberId', member.id);
			collection.set('cost', cost);

			var timeOff = data.timeOff.filterBy('memberId', member.id);
			collection.set('timeOff', timeOff);

			this.buildObject(collection);
		},
		buildObject: function buildObject(collection) {
			var isHourly = collection.get('wageHistory.isHourly');
			var wage = parseFloat(collection.get('wageHistory.effectiveRate'));
			var grossWages = isHourly ? 'Hourly' : 'Salary';

			var vacSeconds = 0;
			var sicSeconds = 0;
			var holSeconds = 0;
			var ptoSeconds = 0;
			var othSeconds = 0;

			collection.set('grossWages', grossWages);

			if (isHourly) {
				var regular = collection.get('seconds.totalRegularSeconds') || 0;
				if (regular > 0) {
					//Find time off seconds and types
					//Subtract from regular time
					collection.timeOff.forEach(function (item) {
						var type = item.get('type');
						var seconds = 0;

						if (!Ember.isNone(item.get('seconds'))) {
							seconds = item.get('seconds');
						}

						if (type === 10) {
							holSeconds += seconds;
							regular -= seconds;
						} else if (type === 20) {
							ptoSeconds += seconds;
							regular -= seconds;
						} else if (type === 30) {
							sicSeconds += seconds;
							regular -= seconds;
						} else if (type === 40) {
							vacSeconds += seconds;
							regular -= seconds;
						} else if (type === 50) {
							othSeconds += seconds;
							regular -= seconds;
						}
					});

					if (regular > 0) {
						collection.set('wage', wage.toFixed(2));
						collection.set('wageType', 'REG');
						collection.set('hours', (regular / 60 / 60).toFixed(2));
						collection.set('wageKey', 1);
						this.addInternalModel(collection);
					}

					if (holSeconds > 0) {
						var obj = this.copy(collection);
						obj.set('wage', wage.toFixed(2));
						obj.set('wageType', 'HOL');
						obj.set('hours', (holSeconds / 60 / 60).toFixed(2));
						obj.set('wageKey', 1);
						this.addInternalModel(obj);
					}

					if (ptoSeconds > 0) {
						var _obj = this.copy(collection);
						_obj.set('wage', wage.toFixed(2));
						_obj.set('wageType', 'PTO');
						_obj.set('hours', (ptoSeconds / 60 / 60).toFixed(2));
						_obj.set('wageKey', 1);
						this.addInternalModel(_obj);
					}

					if (sicSeconds > 0) {
						var _obj2 = this.copy(collection);
						_obj2.set('wage', wage.toFixed(2));
						_obj2.set('wageType', 'SIC');
						_obj2.set('hours', (sicSeconds / 60 / 60).toFixed(2));
						_obj2.set('wageKey', 1);
						this.addInternalModel(_obj2);
					}

					if (vacSeconds > 0) {
						var _obj3 = this.copy(collection);
						_obj3.set('wage', wage.toFixed(2));
						_obj3.set('wageType', 'VAC');
						_obj3.set('hours', (vacSeconds / 60 / 60).toFixed(2));
						_obj3.set('wageKey', 1);
						this.addInternalModel(_obj3);
					}

					if (othSeconds > 0) {
						var _obj4 = this.copy(collection);
						_obj4.set('wage', wage.toFixed(2));
						_obj4.set('wageType', 'OTH');
						_obj4.set('hours', (othSeconds / 60 / 60).toFixed(2));
						_obj4.set('wageKey', 1);
						this.addInternalModel(_obj4);
					}
				}

				var overtime = collection.get('seconds.totalOvertimeSeconds') || 0;
				if (overtime > 0) {
					var otObject = this.copy(collection);
					otObject.set('wage', (wage * 1.5).toFixed(2));
					otObject.set('wageType', 'OT1');
					otObject.set('hours', (overtime / 60 / 60).toFixed(2));
					otObject.set('wageKey', 2);
					this.addInternalModel(otObject);
				}

				var doubletime = collection.get('seconds.totalDoubletimeSeconds') || 0;
				if (doubletime > 0) {
					var dtObject = this.copy(collection);
					dtObject.set('wage', (wage * 2).toFixed(2));
					dtObject.set('wageType', 'OT2');
					dtObject.set('hours', (doubletime / 60 / 60).toFixed(2));
					dtObject.set('wageKey', 3);
					this.addInternalModel(dtObject);
				}
			} else {
				var total = collection.get('seconds.totalSeconds') || 0;
				collection.timeOff.forEach(function (item) {
					var type = item.get('type');
					var seconds = 0;
					if (!Ember.isNone(item.get('seconds'))) {
						seconds = item.get('seconds');
					}

					if (type === 10) {
						holSeconds += seconds;
						total -= seconds;
					} else if (type === 20) {
						ptoSeconds += seconds;
						total -= seconds;
					} else if (type === 30) {
						sicSeconds += seconds;
						total -= seconds;
					} else if (type === 40) {
						vacSeconds += seconds;
						total -= seconds;
					} else if (type === 50) {
						othSeconds += seconds;
						total -= seconds;
					}
				});

				if (total >= 0) {
					collection.set('wage', "");
					collection.set('wageType', 'SAL');
					collection.set('hours', (total / 60 / 60).toFixed(2));
					collection.set('wageKey', 8);
					this.addInternalModel(collection);
				}

				if (holSeconds > 0) {
					var _obj5 = this.copy(collection);
					_obj5.set('wage', wage.toFixed(2));
					_obj5.set('wageType', 'SAL-HOL');
					_obj5.set('hours', (holSeconds / 60 / 60).toFixed(2));
					_obj5.set('wageKey', 1);
					this.addInternalModel(_obj5);
				}

				if (ptoSeconds > 0) {
					var _obj6 = this.copy(collection);
					_obj6.set('wage', wage.toFixed(2));
					_obj6.set('wageType', 'SAL-PTO');
					_obj6.set('hours', (ptoSeconds / 60 / 60).toFixed(2));
					_obj6.set('wageKey', 1);
					this.addInternalModel(_obj6);
				}

				if (sicSeconds > 0) {
					var _obj7 = this.copy(collection);
					_obj7.set('wage', wage.toFixed(2));
					_obj7.set('wageType', 'SAL-SIC');
					_obj7.set('hours', (sicSeconds / 60 / 60).toFixed(2));
					_obj7.set('wageKey', 1);
					this.addInternalModel(_obj7);
				}

				if (vacSeconds > 0) {
					var _obj8 = this.copy(collection);
					_obj8.set('wage', wage.toFixed(2));
					_obj8.set('wageType', 'SAL-VAC');
					_obj8.set('hours', (vacSeconds / 60 / 60).toFixed(2));
					_obj8.set('wageKey', 1);
					this.addInternalModel(_obj8);
				}

				if (othSeconds > 0) {
					var _obj9 = this.copy(collection);
					_obj9.set('wage', wage.toFixed(2));
					_obj9.set('wageType', 'SAL-OTH');
					_obj9.set('hours', (othSeconds / 60 / 60).toFixed(2));
					_obj9.set('wageKey', 1);
					this.addInternalModel(_obj9);
				}
			}
		},
		copy: function copy(collection) {
			var _collection = this.collectionFor().push(collection.get('model'));
			_collection.set('wageHistory', collection.get('wageHistory'));
			_collection.set('seconds', collection.get('seconds'));
			_collection.set('cost', collection.get('cost'));
			return _collection;
		}
	});
});