define('busy-app/models/cost-code-month-seconds', ['exports', 'ember-data', 'busy-app/models/cost-code-day-seconds'], function (exports, _emberData, _costCodeDaySeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _costCodeDaySeconds.default.extend({
    // used to differentiate uid for month and day models
    type: "month",

    startDate: _emberData.default.attr('date', { format: 'MM-YYYY', sortable: true, sortBy: ['startDate'] })
  });
});