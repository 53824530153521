define('busy-app/routes/employees/locations', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

		queryParams: {
			ids: {
				refreshModel: true
			},
			from: {
				refreshModel: true
			}
		},

		resetController: function resetController(controller) {
			controller.set('ids', null);
		},
		model: function model(params) {
			var _this = this;

			if (!Ember.isEmpty(params.ids)) {

				var memberIds = params.ids.split(',');
				return Ember.RSVP.hash({
					members: this.store.findByIds('member', memberIds.copy()),
					timeEntries: this.store.findWhereIn('time-entry', 'member_id', memberIds.copy(), { end_time: '_-NULL-_', deleted_on: '_-NULL-_' })
				}).then(function (data) {
					if (Ember.isNone(data.timeEntries)) {
						return Object.assign(data, { timeEntryBreaks: [] });
					}

					var timeEntryIds = data.timeEntries.mapBy('id');
					return _this.store.findWhereIn('time-entry-break', 'timeEntryId', timeEntryIds, { end_time: '_-NULL-_', deleted_on: '_-NULL-_' }).then(function (timeEntryBreaks) {
						return Object.assign(data, { timeEntryBreaks: timeEntryBreaks });
					});
				}).then(function (data) {
					var locationIds = [];
					data.timeEntries.forEach(function (item) {
						if (!Ember.isEmpty(item.get('startLocationId'))) {
							// make sure this entry is not on break
							var breakEntry = data.timeEntryBreaks.findBy('timeEntryId', item.id);
							if (Ember.isNone(breakEntry)) {
								locationIds.push(item.get('startLocationId'));
							} else {
								breakEntry.set('timeEntry', item);
							}
						}
					});

					data.timeEntryBreaks.forEach(function (item) {
						if (!Ember.isEmpty(item.get('startLocationId'))) {
							// make sure this is still on break
							if (Ember.isNone(item.get('endTime'))) {
								locationIds.push(item.get('startLocationId'));
							}
						}
					});

					return _this.store.findByIds('member-location', locationIds).then(function (locations) {
						// get data for locations
						locations.forEach(function (item) {
							// get location start time model
							var startTime = data.timeEntries.findBy('startLocationId', item.id);
							if (!Ember.isNone(startTime)) {
								// add the timeEntry to the location
								item.set('startTimeEntry', startTime);
							}

							// get location end time model
							var startBreakTime = data.timeEntryBreaks.findBy('startLocationId', item.id);
							if (!Ember.isNone(startBreakTime)) {
								// add the timeEntry to the location
								item.set('breakStart', startBreakTime);
							}

							// add the member to the location
							var member = data.members.findBy('id', item.get('memberId'));
							item.set('member', member);
						});

						return locations;
					});
				});
			} else {
				return Ember.RSVP.resolve([]);
			}
		},
		getValues: function getValues(models, keys) {
			var newArray = [];
			models.forEach(function (model) {
				keys.forEach(function (key) {
					newArray.push(model.get(key));
				});
			});
			return newArray;
		}
	});
});