define('busy-app/components/time/time-sheet/signature-status', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-sheet--signature-status'],

		access: Ember.inject.service(),
		session: Ember.inject.service(),
		features: Ember.inject.service(),
		router: Ember.inject.service(),
		signature: Ember.inject.service(),
		store: Ember.inject.service(),

		currentPayPeriod: null,
		member: null,
		start: null,
		end: null,

		showSignatures: Ember.computed('features.signatures', 'signature.isTimecardSigningEnabled', 'currentPayPeriod', 'session.isAuthenticated', function () {
			return this.get('session.isAuthenticated') && this.get('features.signatures') && this.get('signature.isTimecardSigningEnabled') && this.get('currentPayPeriod.isSignable');
		}),

		memberTimeDocument: Ember.computed('member.id', 'start', 'end', 'currentPayPeriod', function () {
			return _emberData.default.PromiseObject.create({
				promise: this.loadMemberTimeDocument()
			});
		}),

		selfSignature: Ember.computed('hasMemberTimeDocument', 'memberTimeDocument.selfSignatureId', function () {
			var id = this.get('memberTimeDocument.selfSignatureId');
			var promise = id ? this.loadSelfSignature(id) : Ember.RSVP.resolve();

			return _emberData.default.PromiseObject.create({ promise: promise });
		}),

		authoritativeSignature: Ember.computed('hasMemberTimeDocument', 'memberTimeDocument.authoritativeSignatureId', function () {
			var id = this.get('memberTimeDocument.authoritativeSignatureId');
			var promise = id ? this.loadAuthoritativeSignature(id) : Ember.RSVP.resolve();

			return _emberData.default.PromiseObject.create({ promise: promise });
		}),

		hasMemberTimeDocument: Ember.computed('memberTimeDocument.{id,isPending}', function () {
			return !Ember.isNone(this.get('memberTimeDocument')) && !this.get('memberTimeDocument.isPending');
		}),

		hasSelfSignature: Ember.computed('memberTimeDocument.selfSignatureId', 'selfSignature.{id,isPending}', function () {
			return this.get('memberTimeDocument.selfSignatureId') && !Ember.isNone(this.get('selfSignature')) && !this.get('selfSignature.isPending');
		}),

		hasAuthoritativeSignature: Ember.computed('memberTimeDocument.authoritativeSignatureId', 'authoritativeSignature.{id,isPending}', function () {
			return this.get('memberTimeDocument.authoritativeSignatureId') && !Ember.isNone(this.get('authoritativeSignature')) && !this.get('authoritativeSignature.isPending');
		}),

		isLoadingSelfSignature: Ember.computed.or('memberTimeDocument.isPending', 'selfSignature.isPending'),
		isLoadingAuthoritativeSignature: Ember.computed.or('memberTimeDocument.isPending', 'authoritativeSignature.isPending'),

		isSupervisor: Ember.computed('member', function () {
			var supervisorAction = 'timeEvents';
			var member = this.get('member');

			if (Ember.isNone(member) || Ember.isNone(member.get('id'))) {
				return false;
			}

			return this.get('access').canTakeActionFor(supervisorAction, member);
		}),

		requestSupervisorSignature: Ember.computed('isSupervisor', 'signature.isTimecardSigningEnabled', 'currentPayPeriod.isSignable,hasAuthoritativeSignature', function () {
			return this.get('isSupervisor') && this.get('signature.isTimecardSigningEnabled') && this.get('currentPayPeriod.isSignable') && !this.get('hasAuthoritativeSignature');
		}),

		// loadData() {
		// 	// should only happen when running CI tests
		// 	if (!this.get('member.id')) {
		// 		return resolve();
		// 	}
		//
		// 	this.safeSet('isLoading', true);
		//
		// 	return hash({
		// 		memberTimeDocument: this.loadMemberTimeDocument(),
		// 	}).then(props => {
		// 		Logger.info(this, 'loadData', props);
		//
		// 		this.safeSetProperties(props);
		// 	}).finally(() => {
		// 		this.safeSet('isLoading', false)
		// 	});
		// },

		loadMemberTimeDocument: function loadMemberTimeDocument() {
			var params = {
				member_id: this.get('member.id'),
				start_time: this.get('start'),
				end_time: this.get('end'),
				edited: '_-NULL-_',
				page_size: 1,
				_desc: 'submitted_on'
			};

			// Logger.info(this, 'loadMemberTimeDocument', { params });

			return this.get('store').query('member-time-document', params).then(function (results) {
				return results.get('firstObject');
			});
		},
		loadSelfSignature: function loadSelfSignature(id) {
			return this.get('store').query('self-signature', { id: id }).then(function (results) {
				return results.get('firstObject');
			});
		},
		loadAuthoritativeSignature: function loadAuthoritativeSignature(id) {
			return this.get('store').query('authoritative-signature', { id: id }).then(function (results) {
				return results.get('firstObject');
			});
		},


		// utility helpers
		safeSet: function safeSet(key, val) {
			if (!this.get('isDestroyed')) {
				return this.set(key, val);
			}
		},
		safeSetProperties: function safeSetProperties(hash) {
			if (!this.get('isDestroyed')) {
				return this.setProperties(hash);
			}
		},


		actions: {
			openFullReport: function openFullReport() {
				var queryParams = {
					end: this.get('end'),
					member_id: this.get('member.id'),
					start: this.get('start')
				};

				// Logger.info(this, 'openFullReport', { queryParams });

				return this.get('router').transitionTo('time-card.full-report', { queryParams: queryParams });
			}
		}

	});
});