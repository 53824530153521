define('busy-app/routes/report/activity', ['exports', '@busy-web/utils', 'ember-simple-auth/mixins/authenticated-route-mixin', 'busy-app/utils/organization/overtime-period'], function (exports, _utils, _authenticatedRouteMixin, _overtimePeriod) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		auth: Ember.inject.service('auth'),
		payPeriod: Ember.inject.service('pay-period'),
		subscription: Ember.inject.service('subscription'),
		features: Ember.inject.service('features'),

		queryParams: {
			type: { replace: true },
			start: { replace: true },
			end: { replace: true },
			custom: { replace: true },
			selected: { replace: true },
			column: { replace: true }
		},

		model: function model(params) {
			var _this = this;

			var controller = this.controllerFor('report/activity');
			controller.set('cController', this.controllerFor('report/activity/index'));

			if (!params.start) {
				if (params.selected === 'monthly') {
					params.start = _utils.Time.date().startOf('month').unix();
					params.end = _utils.Time.date(params.start).endOf('month').unix();
				} else {
					// setup weekly range.
					//
					// This must be done according to ovettime period
					// therefore only the start is set so that the end can be set by the period
					// after the actual start is set.
					params.start = _utils.Time.date().startOf('day').unix();
				}
			}

			return Ember.RSVP.hash({
				overtimePeriods: this.get('auth.organizationOvertimePeriod'),
				payPeriods: this.get('auth.organizationPayPeriod')
			}).then(function (data) {
				var overtimePeriod = (0, _overtimePeriod.getOvertimePeriodByDate)(data.overtimePeriods, params.start);
				params.weekStart = (0, _overtimePeriod.weekStartForPeriod)(overtimePeriod);

				if (!params.end) {
					if (params.selected === 'weekly') {
						var _createOvertimeBoundW = (0, _overtimePeriod.createOvertimeBoundWeekForPeriod)(overtimePeriod, params.start),
						    start = _createOvertimeBoundW.start,
						    end = _createOvertimeBoundW.end;

						// set overtime period week


						params.start = start;
						params.end = end;
					} else if (params.selected === 'monthly') {
						params.end = _utils.Time.date(params.start).endOf('month').unix();
					} else {
						params.end = _utils.Time.date(params.start).endOf('day').unix();
					}
				}

				params.currentPeriod = _this.get('payPeriod').getPayPeriod(_utils.Time.timestamp());
				return params;
			});
		},


		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			}
		}
	});
});