define('busy-app/animations/time-card', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = timecard;
	function timecard(durationTo, durationBack) {
		//time card transitions
		var animate = ['time-card', 'time-card.time-entries', 'time-card.full-report', 'time-card.time-entry'];

		this.transition(this.toRoute(animate), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));

		this.transition(this.toRoute('time-card.time-entry'), this.fromRoute('time-card.time-entries'), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));

		this.transition(this.toRoute('time-card.time-off-entry'), this.fromRoute('time-card.time-entries'), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));
	}
});