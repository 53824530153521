define('busy-app/components/employees/edit-info-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['v-employees-employee-dialog-edit-details'],

    auth: Ember.inject.service('auth'),
    store: Ember.inject.service('store'),
    access: Ember.inject.service('access'),
    features: Ember.inject.service('features'),

    showCompanyInfo: true,
    positionsMenu: null,
    member: null,

    username: '',
    firstName: '',
    lastName: '',
    memberNumber: '',
    pendingEmail: '',
    phone: '',
    timeLocationRequired: 'AUTO',

    init: function init() {
      this._super();
      this.setMemberInfo();
      this.get('auth.isOwner');
    },


    canShowCompanyInfo: Ember.computed('access.auth.authmemberPosition.id', 'showCompanyInfo', 'member.id', function () {
      if (this.get('showCompanyInfo') && (this.get('member.id') !== this.get('access.auth.authmemberPosition.id') || this.get('access').isOwner(this.get('member.id')))) {
        return true;
      }
      return false;
    }),

    usernameWarning: Ember.computed('username', function () {
      var username = this.get('username');

      if (username !== this.get('member.username')) {
        return 'Warning: User will be logged out if username is changed.';
      }
      return;
    }),

    setMemberInfo: Ember.observer('member', function () {
      if (!Ember.isNone(this.get('member'))) {
        this.set('username', this.get('member.username'));
        this.set('firstName', this.get('member.firstName'));
        this.set('lastName', this.get('member.lastName'));
        this.set('memberNumber', this.get('member.memberNumber') || '');
        this.set('phone', this.get('member.phone') || '');

        if (this.get('member.pendingEmail')) {
          this.set('email', this.get('member.pendingEmail'));
        } else if (this.get('member.certifiedEmail')) {
          this.set('email', this.get('member.certifiedEmail'));
        }

        // only allow changing the setting if the LD Pro feature flag is enabled
        if (this.get('features.memberGpsSettingDevelopment') && this.get('features.memberGpsSetting')) {
          this.set('timeLocationRequired', this.get('member.timeLocationRequired') || 'AUTO');
        }
      }
    }),

    gpsLocationMenu: Ember.computed('member.timeLocationRequired', function () {
      var curentValue = this.get('member.timeLocationRequired');

      var options = [Ember.Object.create({ key: 'YES', title: (0, _utils.loc)('Required'), _selected: curentValue === 'YES' }), Ember.Object.create({ key: 'AUTO', title: (0, _utils.loc)('Not Required'), _selected: curentValue === 'AUTO' }), Ember.Object.create({ key: 'NO', title: (0, _utils.loc)('Off'), _selected: curentValue === 'NO' })];

      // If the account is not pro, the only option is the current value
      if (!Ember.get(this, 'features.memberGpsSetting')) {
        options.forEach(function (item) {
          var _unselectable = Ember.get(item, 'key') !== curentValue;
          Ember.setProperties(item, { _unselectable: _unselectable });
        });
      }

      return options;
    }),

    validate: function validate(member) {
      var email = this.get('email');

      if (this.get('access').isOwner(member.get('id')) && Ember.isEmpty(email)) {
        return Ember.RSVP.reject((0, _utils.loc)('Email is required for main account owner.'));
      } else {
        if (!Ember.isEmpty(email)) {
          if (email !== member.get('certifiedEmail')) {
            if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
              member.set('pendingEmail', email);
            } else {
              return Ember.RSVP.reject((0, _utils.loc)('Email must be a vaild email address.'));
            }
          }
        } else if (Ember.isEmpty(email)) {
          member.setProperties({
            pendingEmail: null,
            certifiedEmail: null
          });
        }
      }

      var phone = this.get('phone').trim();
      if (!Ember.isEmpty(phone)) {
        member.set('phone', phone);
      } else if (!Ember.isNone(member.get('phone'))) {
        member.set('phone', '_-NULL-_');
      }

      var memberNumber = this.get('memberNumber').trim();
      if (!Ember.isEmpty(memberNumber)) {
        member.set('memberNumber', memberNumber);
      } else if (!Ember.isNone(member.get('memberNumber'))) {
        member.set('memberNumber', '_-NULL-_');
      }

      var firstName = this.get('firstName').trim();
      if (!Ember.isEmpty(firstName) && firstName !== member.get('firstName')) {
        member.set('firstName', firstName);
      } else if (Ember.isEmpty(this.get('firstName'))) {
        return Ember.RSVP.reject((0, _utils.loc)('First Name is required'));
      }

      var lastName = this.get('lastName').trim();
      if (!Ember.isEmpty(lastName) && lastName !== member.get('lastName')) {
        member.set('lastName', lastName);
      } else if (Ember.isEmpty(this.get('lastName'))) {
        return Ember.RSVP.reject((0, _utils.loc)('Last Name is required'));
      }

      if (Ember.isNone(member.get('positionId'))) {
        return Ember.RSVP.reject((0, _utils.loc)('Position must be set before saving'));
      }
      return Ember.RSVP.resolve(member);
    },


    actions: {
      close: function close() {
        this.sendAction('onClose');
      },
      selectPosition: function selectPosition(item) {
        this.set('member.positionId', item.get('id'));
        this.set('member.position', item);
      },
      selectGpsLocation: function selectGpsLocation(item) {
        this.set('member.timeLocationRequired', item.get('key'));
      },
      save: function save() {
        var _this = this;

        this.set('usernameError', '');
        return this.validate(this.get('member')).then(function (member) {
          if (member) {
            var username = _this.get('username').trim();
            var email = _this.get('email');

            if (!Ember.isEmpty(email) && email !== member.get('pendingEmail') && email !== member.get('certifiedEmail')) {
              member.set('pendingEmail', email);
            }
            if (!Ember.isEmpty(username) && member.get('username') !== username && /^\S{6}\S*$/.test(username)) {
              return _this.get('store').rpcRequest('account', 'username-available', { username: username }).then(function (data) {
                if (data.data === false) {
                  _this.set('usernameError', (0, _utils.loc)('This username is taken. Please choose another.'));
                  return Ember.RSVP.reject((0, _utils.loc)('This username is taken. Please choose another.'));
                } else {
                  member.set('username', username);
                  return member.save();
                }
              });
            } else if (Ember.isEmpty(username)) {
              return Ember.RSVP.reject((0, _utils.loc)('Username is required'));
            } else if (!/^\S{2}\S*$/.test(username)) {
              return Ember.RSVP.reject((0, _utils.loc)('Username must be at least 2 characters'));
            } else if (member.get('hasDirtyAttributes')) {
              return member.save();
            } else {
              return Ember.RSVP.resolve();
            }
          }
        });
      }
    }
  });
});