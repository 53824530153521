define('busy-app/services/raven', ['exports', 'ember-cli-sentry/services/raven'], function (exports, _raven) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Services
  *
  */
	var ignoreList = ["TransitionAborted", "Call Aborted", "Not Authorized", "The adapter operation was aborted", "invalid credentials"];

	/**
  * `Services/Raven`
  *
  * Sentry Error logger.
  *
  * @class Raven
  * @namespace Services
  * @extends Service
  * @uses Services.Auth
  */
	exports.default = _raven.default.extend({
		/**
   * @private
   * @property auth
   * @type Services.Auth
   */
		auth: Ember.inject.service('auth'),

		init: function init() {
			this._super.apply(this, arguments);

			this.setContext();
		},


		setContext: Ember.observer('auth.member.id', function () {
			if (!Ember.isNone(this.get('auth.member.id'))) {
				window.Raven.setUserContext({
					id: this.get('auth.member.id'),
					name: this.get('auth.member.fullName')
				});
			}
		}),

		//	captureException(error, extra) {
		//		debugger;
		//		extra = extra || {};
		//		if (error && error.errors) {
		//			extra.errors = error.errors;
		//		}
		//
		//		if (error && error.hash) {
		//			extra.hash = error.hash;
		//		}
		//
		//		if (window.FS) {
		//			extra.fullstory = window.FS.getCurrentSessionURL();
		//		}
		//
		//
		//		window.Raven.captureException(error, extra);
		//	},
		//
		//	captureMessage(/* message */) {
		//		return this._super(...arguments);
		//	},
		//
		//		enableGlobalErrorCatching() {
		//			this._super(...arguments);
		//		},
		//			if (this.get('isRavenUsable') && !this.get('globalErrorCatchingInitialized')) {
		//			debugger;
		//			var _oldOnError = onerror;
		//			onerror = (reason) => {
		//				debugger;
		//				if(this.ignoreError(reason)) {
		//					return;
		//				}
		//
		//				const name = this.getName(reason);
		//				if(reason instanceof Error) {
		//					reason.name = name;
		//					this.captureException(reason);
		//				} else {
		//					this.captureMessage(name, {
		//						extra: { reason: reason }
		//					});
		//				}
		//
		//				if(typeof _oldOnError === 'function') {
		//					_oldOnError.call(this, reason);
		//				}
		//			};
		//
		//			this.set('globalErrorCatchingInitialized', true);
		//		}
		//
		//		return this;
		//	},

		//	getName(err) {
		//		return err.name || err.statusText || err.message || this.get('unhandledPromiseErrorMessage');
		//	},

		ignoreError: function ignoreError(err) {
			//	if (err.errors && !err.status) {
			//		if (!isNone(err.errors[0]) && typeof err.errors[0] === 'object') {
			//			err.status = parseInt(err.errors[0].status, 10);
			//			err.message = err.errors[0].detail;
			//			err.name = err.errors[0].title;
			//		} else {
			//			err.name = err.errors[0];
			//		}
			//	}
			if (err.status && (err.status === 401 || err.status === 0)) {
				return true;
			} else if (Ember.isNone(err) || ignoreList.indexOf(err.name) !== -1 || ignoreList.indexOf(err.message) !== -1) {
				return true;
			}
			return false;
		}
	});
});