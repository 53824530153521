define('busy-app/utils/query-string-to-json', ['exports', 'busy-app/utils/type-cast'], function (exports, _typeCast) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = queryStringToJson;

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	/**
  * @public
  * @method queryStringToJson
  * @params str {string} the query string to parse
  * @return {object} JSON object
  */
	function queryStringToJson(str) {
		var res = {};

		// remove leading question mark if it exists
		str = str.replace(/^\?/, '');

		// split string into pairs
		str.split('&').forEach(function (pair) {
			var _pair$split = pair.split('='),
			    _pair$split2 = _slicedToArray(_pair$split, 2),
			    key = _pair$split2[0],
			    val = _pair$split2[1];

			if (/\[\]$/.test(key)) {
				// key is an array of values
				var _key = key.slice(0, -2);
				if (Ember.isNone(Ember.get(res, _key))) {
					Ember.set(res, _key, []);
				}
				Ember.get(res, _key).push((0, _typeCast.default)(val));
			} else if (/[^[].\]$/.test(key)) {
				var _key$split = key.split('['),
				    _key$split2 = _slicedToArray(_key$split, 2),
				    _key2 = _key$split2[0],
				    _sub = _key$split2[1];

				_sub = _sub.slice(0, -1);
				if (Ember.isNone(Ember.get(res, _key2))) {
					Ember.set(res, _key2, {});
				}
				Ember.set(res, _key2 + '.' + _sub, (0, _typeCast.default)(val));
			} else {
				Ember.set(res, key, (0, _typeCast.default)(val));
			}
		});
		return res;
	}
});