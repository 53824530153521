define('busy-app/helpers/throw-alert', ['exports', '@busy-web/components/helpers/throw-alert'], function (exports, _throwAlert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _throwAlert.default;
    }
  });
  Object.defineProperty(exports, 'throwAlert', {
    enumerable: true,
    get: function () {
      return _throwAlert.throwAlert;
    }
  });
});