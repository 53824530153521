define('busy-app/models/pay-period-member-seconds', ['exports', 'ember-data', 'moment', '@busy-web/utils'], function (exports, _emberData, _moment, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		signatures: Ember.inject.service('signature'),

		memberId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		endTime: _emberData.default.attr('number'),
		regularSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		overtimeSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		doubletimeSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		totalSeconds: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}),

		totalRegularSeconds: Ember.computed.alias('regularSeconds'),
		totalOvertimeSeconds: Ember.computed.alias('overtimeSeconds'),
		totalDoubletimeSeconds: Ember.computed.alias('doubletimeSeconds'),
		totalTotalSeconds: Ember.computed.alias('totalSeconds'),

		hasSeconds: Ember.computed.bool('totalTotalSeconds'),
		hasNoSeconds: Ember.computed.not('hasSeconds'),

		dateRange: Ember.computed('startTime', function () {
			var start = (0, _moment.default)(this.get('startTime') * 1000).add(new Date(this.get('startTime') * 1000).getTimezoneOffset(), 'minutes');
			var end = (0, _moment.default)(this.get('endTime') * 1000).add(new Date(this.get('endTime') * 1000).getTimezoneOffset(), 'minutes');
			var format = _utils.Locale.format("MMM Do", _moment.default.locale());
			return start.format(format) + " - " + end.format(format);
		}),

		dateString: Ember.computed('startTime', 'endTime', function () {
			var start = _utils.Time.date(this.get('startTime'));
			var end = _utils.Time.date(this.get('endTime'));
			var format = 'MMM D';
			if (start.year() !== end.year()) {
				format = 'MMM D, YYYY';
			}
			return _utils.Time.dateFormat(start, format) + ' - ' + _utils.Time.dateFormat(end, 'MMM D, YYYY');
		}),

		regularTimeString: Ember.computed('totalRegularSeconds', function () {
			var seconds = this.get('totalRegularSeconds');
			return this.convertSeconds(seconds);
		}),

		overtimeTimeString: Ember.computed('totalOvertimeSeconds', function () {
			var seconds = this.get('totalOvertimeSeconds');
			return this.convertSeconds(seconds);
		}),

		doubletimeTimeString: Ember.computed('totalDoubletimeSeconds', function () {
			var seconds = this.get('totalDoubletimeSeconds');
			return this.convertSeconds(seconds);
		}),

		totalTimeString: Ember.computed('totalRegularSeconds', 'totalOvertimeSeconds', 'totalDoubletimeSeconds', function () {
			var seconds = this.get('totalRegularSeconds') + this.get('totalOvertimeSeconds') + this.get('totalDoubletimeSeconds');
			return this.convertSeconds(seconds);
		}),

		convertSeconds: function convertSeconds(seconds) {
			var hours = Math.floor(seconds / 60 / 60);
			var minutes = Math.floor((seconds - hours * 60 * 60) / 60);
			return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
		},


		/**
  	 * Is the signing feature enabled, signing enabled on the organization, and is this payperiod eligible for signing.
  	 * Assumes the pay period is attached to the organization of the curent logged in member.
  	 *
  	 * @public
  	 * @method isSignable
  	 * @return {boolean}
  	 */
		isSignable: Ember.computed('features.signatures', 'auth.organization.{isSignaturesEnabled,signatureDate}', 'endTime', function () {
			if (!this.get('features.signatures') || !this.get('auth.organization.isSignaturesEnabled')) {
				return false;
			}

			var signatureDate = this.get('auth.organization.signatureDate');
			var payPeriodEndTime = this.get('endTime');
			var startOfToday = _utils.Time.date().startOf('day').unix();
			return payPeriodEndTime >= signatureDate && payPeriodEndTime < startOfToday;
		})
	});
});