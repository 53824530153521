define('busy-app/controllers/verify-email', ['exports', 'busy-app/utils/api-error', 'busy-app/utils/settings'], function (exports, _apiError, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Controller.extend({
		session: Ember.inject.service('session'),
		auth: Ember.inject.service('auth'),

		verifyEmailDialog: false,
		resentHeader: false,

		showError: false,
		errorMessage: '',

		init: function init() {
			this._super.apply(this, arguments);

			// add the verify class to the body
			// this hides the left nav bar.
			Ember.$('body').addClass('verify');

			// call verify to check if
			// the email has been verified.
			this.verify();
		},
		checkVerified: function checkVerified() {
			var _this = this;

			window.setTimeout(function () {
				_this.verify();
			}, 10000);
		},
		verify: function verify() {
			var _this2 = this;

			// get authKey from session
			var authKey = this.get('session.data.authenticated');

			// if the authKey is not null or undefined
			if (!Ember.isNone(authKey)) {
				// setup the url string
				var url = _settings.default.get('api_url') + '/member?_version=' + _settings.default.get('api_version');

				// set query param data
				var data = {
					username: authKey.username
				};

				var beforeSend = function beforeSend(xhr) {
					// setup basic auth for the call
					xhr.setRequestHeader('Authorization', 'Basic ' + authKey.auth_hash);
				};

				var success = function success(res) {
					// get the member data from the api response
					var member = _typeof(res.data) === 'object' ? res.data[0] : {};

					// if the response is successful and the email has been verified
					if (res.success && member.verified_email) {
						// get auth session hash
						var auth = _this2.get('session.data.authenticated');

						// set the verifiedEmail and keys for the auth member
						Ember.set(auth, 'verifiedEmail', true);
						Ember.set(auth, 'public_key', res.public_key);
						Ember.set(auth, 'private_key', res.private_key);

						// send the new data to the session store.
						_this2.get('session.store').persist(auth);

						// remove class verify
						Ember.$('body').removeClass('verify');

						// transition to the index route.
						_this2.transitionToRoute('index');

						// reload the authenticated member models
						_this2.send('reloadAuth');
					} else {
						// call verified timeout callback.
						_this2.checkVerified();
					}
				};

				var error = function error(xhr) {
					// handle a unauthorized api error
					if (xhr.status === 401) {
						// invalidate the auth session
						_this2.send('invalidateSession');
					}
				};

				// send the ajax api request
				Ember.$.ajax({ url: url, data: data, beforeSend: beforeSend, success: success, error: error });
			}
		},
		resendEmail: function resendEmail() {
			var _this3 = this;

			// get session auth data
			var auth = this.get('session.data.authenticated');

			// restet the error message
			this.resetErrorMessage();

			var success = function success(res) {
				if (res.data === true) {
					_this3.set('resentHeader', true);
				}
			};

			var error = function error(err) {
				var code = _apiError.default.getRpcErrorString(err, 'account', 'request-verification-email');
				_this3.showErrorMessage(code);
			};

			return this.store.rpcRequest('account', 'request-verification-email', { member_id: auth.id }).then(success).catch(error);
		},
		showErrorMessage: function showErrorMessage(message, time) {
			// show errorMessage
			this.set('showError', true);
			this.set('errorMessage', message);

			if (!Ember.isNone(time)) {
				Ember.run.later(this, function () {
					// hide errorMessage
					this.resetErrorMessage();
				}, time);
			}
		},
		resetErrorMessage: function resetErrorMessage() {
			// hide errorMessage
			this.set('showError', false);
			this.set('errorMessage', '');
		},


		actions: {
			sendNewEmail: function sendNewEmail(dialog) {
				var _this4 = this;

				dialog.showSavingMessage();

				if (this.get('auth.member.hasDirtyAttributes')) {
					// trim the whitespace on the email
					this.set('auth.member.email', this.get('auth.member.email').trim());

					// save the auth member model
					this.get('auth.member.content').save().then(function () {
						// call to reverify the email.
						_this4.resendEmail();

						// show the saving message.
						dialog.showSavedMessage();
					});
				} else {
					dialog.showSavedMessage();
				}
			},
			resendEmailAction: function resendEmailAction() {
				this.resendEmail();
			},
			changeEmail: function changeEmail() {
				this.set('verifyEmailDialog', true);
			},
			cancelNewEmail: function cancelNewEmail() {
				this.set('verifyEmailDialog', false);
			},
			logout: function logout() {
				this.get('auth').invalidateSession();
			}
		}
	});
});