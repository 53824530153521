define("busy-app/services/toggle", ["exports", "@busy-web/utils", "busy-app/utils/debug", "busy-app/utils/features", "busy-app/utils/logger", "busy-app/utils/settings"], function (exports, _utils, _debug, _features, _logger, _settings) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		features: Ember.inject.service("features"),
		session: Ember.inject.service("session"),
		auth: Ember.inject.service("auth"),
		subscription: Ember.inject.service("subscription"),
		launchDarkly: Ember.inject.service("launchDarkly"),

		cacheKey: "busy-app-toggle",
		appType: "time",

		localFeatures: null,

		init: function init() {
			var _this = this;

			this._super();

			(0, _debug.disable_test_runner)(function () {
				return (
					// debug flags will log info when
					// log level 3 is set in config
					window.BusyApp.__log_features = createDebugMethod(_this)
				);
			});

			if (this.get("features").isEnabled("overrides/pro")) {
				// override flags to turn on all pro features
				_logger.default.warn(this, "Override Pro");
			} else if (this.get("features").isEnabled("overrides/free")) {
				// override flags to turn off all pro features
				_logger.default.warn(this, "Override Free");
			}

			//this.setupLaunchDarkly();
			this.set("localFeatures", _features.default);
		},


		userCacheKey: Ember.computed("cacheKey", "auth.member.id", function () {
			var id = this.get("auth.member.id");
			var cacheKey = this.get("cacheKey");

			return id + "-" + cacheKey;
		}),

		setupLaunchDarkly: function setupLaunchDarkly() {
			var _this2 = this;

			return this.userData().then(function (data) {
				return _this2.get("launchDarkly").initialize(_settings.default.get("launchdarkly_clientid"), data, {}).then(function () {
					_this2.getAllFlags();

					_this2.get("launchDarkly").on("change", function (flags) {
						_logger.default.info(_this2, "LaunchDarkly change event", { flags: flags });
						for (var i in flags) {
							_this2.setFlag(i, flags[i].current);
						}
					});
					return true;
				});
			});
		},


		/**
   * get the user data for initializing the launch darkly sdk
   *
   * @private
   * @method userData
   * @return {EmberPromise}
   */
		userData: function userData() {
			var _this3 = this;

			var data = {};
			if (!this.get("session.isAuthenticated")) {
				data.anonymous = true;
				data.key = "anon-" + _utils.UUID.generate();
				return Ember.RSVP.resolve(data);
			} else {
				data.anonymous = false;
				var statusReady = Ember.RSVP.resolve(this.get("subscription.subscriptionStatus"));
				return statusReady.then(function () {
					data.key = _this3.get("auth.member.id");
					data.firstName = _this3.get("auth.member.firstName");
					data.lastName = _this3.get("auth.member.lastName");
					data.email = _this3.get("auth.member.email");
					data.avatar = _this3.get("auth.member.imageUrl");

					data.custom = {
						isOwner: _this3.get("auth.organization.ownedBy") === _this3.get("auth.member.id"),
						organizationId: _this3.get("auth.organization.id"),
						organizationName: _this3.get("auth.organization.organizationName"),
						organizationNameId: _this3.get("auth.organization.organizationName") + " - " + _this3.get("auth.organization.id"),
						organizationCreatedOn: _utils.Time.date(_this3.get("auth.organization.createdOn")).format("MM/DD/YYYY HH:mm"),
						subscriptionProductHandle: _this3.get("subscription.subscriptionStatus.productHandle"),
						subscriptionStatus: _this3.get("subscription.subscriptionStatus.status"),
						subscriptionCreatedOn: _utils.Time.date(_this3.get("subscription.subscriptionStatus.createdOn")).local().format("MM/DD/YYYY HH:mm"), // chargify dates need to be local
						subscriptionCouponCode: _this3.get("subscription.subscriptionStatus.couponCode") || "",
						platform: "Web",
						product: "Time",
						version: _settings.default.get("version"),
						device: _utils.Browser.type(),
						os: _utils.Browser.osType()
					};
					return data;
				});
			}
		},


		/**
   * observer for updating the flags if the subscriptionStatus model changes
   *
   */
		updateUser: Ember.observer("subscription.subscriptionStatus.productHandle", function () {
			if (this.get("launchDarkly") && this.get("launchDarkly.isInitialized")) {
				var params = {
					key: this.get("auth.member.id"),
					custom: {
						subscriptionProductHandle: this.get("subscription.subscriptionStatus.productHandle"),
						subscriptionStatus: this.get("subscription.subscriptionStatus.status"),
						subscriptionCreatedOn: this.get("subscription.subscriptionStatus.createdOn"),
						subscriptionCouponCode: this.get("subscription.subscriptionStatus.couponCode")
					}
				};

				this.identify(params);
			}
		}),

		/**
   * Parse the key for the featureFlag from the provided compound key containing the app prefix, and set the relevant feature flag to the provided value.
   * Ensures the app prefix in the key matches the current appType and that the flagValue is a boolean.
   *
   * @public
   * @method setFlag
   * @param key {String} can be feature flag or compund feature flag `time.projects`
   * @param value {Boolean} the value to be set on the featureFlag
   * @return {this}
   */
		setFlag: function setFlag(key, value) {
			_utils.Assert.isString(key);

			if (this.isValidFeature(key)) {
				// non boolean values will be set to false
				if (typeof value !== "boolean") {
					_logger.default.warn(this, "toggleFeature", "ignored - not a boolean value", {
						key: key,
						value: value
					});
					value = false;
				}

				// only allow overrides when DEBUG_MODE is turned on
				if (_settings.default.get("debug_mode")) {
					if (this.get("features").isEnabled("overrides/reactMainApp")) {
						if (key === "react-main-app") {
							value = false;
						}
					}
					var isProFeature = _features.default.isProFeature(key);
					if (isProFeature && this.get("features").isEnabled("overrides/pro")) {
						// override flags to turn on all pro features
						value = true;
						//debugFlag('pro', key, 'on', 'overrides/pro');
					} else if (isProFeature && this.get("features").isEnabled("overrides/free")) {
						// override flags to turn off all pro features
						value = false;
						//debugFlag('pro', key, 'off', 'overrides/free');
					} else if (_settings.default.getFeature(key)) {
						// flags set to true will ignore launch darkly
						value = true;
						//debugFlag('free', key, 'on', 'overrides/none');
						//} else {
						//debugFlag('pro', key, 'off', 'overrides/none');
						//Logger.info(this, `feature:pro [${key}:inactive]`);
					}
				}

				// add debug flag for logger
				__add_debug_flag(key, value, this.get("features"));

				if (value) {
					// enable flag
					this.get("features").enable(key);
				} else {
					// disable flag
					this.get("features").disable(key);
				}
				this.setCache(key, value);
			}
			return this;
		},
		getFlag: function getFlag(key, defaultValue) {
			if (Ember.isNone(defaultValue)) {
				defaultValue = this.getCache(key);
				if (Ember.isNone(defaultValue)) {
					defaultValue = this.getDefaults(key);
				}
			}
			var value = this.get("launchDarkly").variation(key, defaultValue);
			this.setFlag(key, value);
			return value;
		},
		setAllFlags: function setAllFlags(flags) {
			for (var i in flags) {
				this.setFlag(i, flags[i]);
			}
			return this;
		},
		getAllFlags: function getAllFlags() {
			var flags = this.getDefault();
			for (var i in flags) {
				flags[i] = this.getFlag(i, flags[i]);
			}

			(0, _debug.disable_test_runner)(function () {
				return (
					// log debug info will get looged to console when
					// log_level 3 is set in config
					(0, _debug.log_level)(3, function () {
						return window.BusyApp.__log_features();
					})
				);
			});

			return flags;
		},
		track: function track() {
			var _get;

			(_get = this.get("launchDarkly")).track.apply(_get, arguments);
		},
		identify: function identify() {
			var _get2;

			return (_get2 = this.get("launchDarkly")).identify.apply(_get2, arguments);
		},


		cache: null,
		getCache: function getCache() {
			var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

			// check local cache for quick lookups
			var flags = this.get("cache");
			if (!Ember.isNone(flags)) {
				return flags;
			}

			// get from browser cache if local cache is not available
			flags = _utils.LocalStorage.getProperty(this.get("userCacheKey"));
			if (!Ember.isEmpty(flags)) {
				flags = JSON.parse(flags);
			}

			// return default flags if cache is not set
			if (Ember.isNone(flags)) {
				flags = this.getDefault();
			}

			// return full cache if cache key is not set
			return Ember.isNone(key) ? flags : flags[key];
		},
		setCache: function setCache(key, value) {
			_utils.Assert.isString(key);
			_utils.Assert.isBoolean(value);

			// get current flags
			var _flags = this.getCache();

			// set value
			_flags[key] = value;

			// add to local cache for quick lookups
			this.set("cache", _flags);

			// convert to json string
			_flags = JSON.stringify(_flags);

			if (this.get("session.isAuthenticated")) {
				// send to local storage
				_utils.LocalStorage.setProperty(this.get("userCacheKey"), _flags);
			}
		},
		getDefault: function getDefault() {
			var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

			// get account status
			var state = this.get("subscription.subscriptionStatus.isProAccount") ? "pro" : "free";

			// get defaults for current account status
			var flags = _features.default.getFlags(state);

			// return all flags if key is not set
			return Ember.isNone(key) ? flags : flags[key];
		},
		isValidFeature: function isValidFeature() {
			var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";

			_utils.Assert.isString(name);

			// make sure the flag is in the default list
			var flags = this.getDefault();
			if (flags.hasOwnProperty(name)) {
				return true;
			}
			return false;
		}
	});


	var DEBUG_FLAGS = {};

	var __add_debug_flag = function __add_debug_flag(name, status, featureService) {
		var type = _features.default.isProFeature(name) ? "PRO" : "FREE";

		var isProOverride = featureService.isEnabled("overrides/pro");
		var isFreeOverride = featureService.isEnabled("overrides/free");

		var key = "" + name; // <${type}>`;
		var value = status ? "ON" : "OFF";

		var overrides = "NONE";
		if (isProOverride) {
			overrides = "PRO"; //' (overrides/pro)';
		} else if (isFreeOverride) {
			overrides = "FREE"; //' (overrides/free)';
		}

		DEBUG_FLAGS[key] = { type: type, overrides: overrides, status: value };
	};

	var createDebugMethod = function createDebugMethod(target) {
		return function () {
			var block = _logger.default.blockCollapsed(target, "FEATURES");
			block.table(DEBUG_FLAGS);
			block.end();
		};
	};
});