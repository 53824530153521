define('busy-app/collections/job-costing', ['exports', 'busy-app/collections/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		/**
   * Use this function to rebuild the data structure
   * returned from the getter calls
   *
   * @public
   * @method buildModels
   * @param data {object} keyed model object
   * @returns {array|object}
   */
		buildModels: function buildModels(data) {
			var _this = this;

			var newData = [];
			var sortBy = Ember.String.camelize(this.get('sortByModel'));
			var secondsModel = Ember.String.camelize(this.get('secondsModel'));
			var costModel = Ember.String.camelize(this.get('costModel'));
			var polyType = this.get('secondsModel').replace(/-seconds$/, '');

			data[sortBy].forEach(function (item) {
				var poly = _this.createPolymorph(polyType);

				var seconds = data[secondsModel].filterBy(sortBy + 'Id', item.id);
				var cost = data[costModel].filterBy(sortBy + 'Id', item.id);

				poly.set(sortBy, item);
				poly.set(secondsModel, seconds);
				poly.set(costModel, cost);

				newData.pushObject(poly);
			});

			return newData;
		},


		/**
   * Collections may have a predefined model
   * structure for reusability. This is not required
   * so this function may be removed if not used.
   *
   * @public
   * @method model
   * @return {EmberPromise}
   */
		model: function model(start, end, memberId, projectId, costCodeId, sortBy) {
			this.set('sortByModel', sortBy || 'member');

			var query = {
				start_time: start,
				end_time: end
			};

			var seconds = 'pay-period-member-seconds';
			var cost = 'pay-period-member-cost';
			if (sortBy === 'project') {
				seconds = 'pay-period-member-project-seconds';
				cost = 'pay-period-member-project-cost';
			} else if (sortBy === 'cost-code') {
				seconds = 'pay-period-member-project-cost-code-seconds';
				cost = 'pay-period-member-project-cost-code-cost';
			}

			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			if (!Ember.isNone(projectId)) {
				query.project_id = projectId;
				if (sortBy !== 'cost-code') {
					seconds = 'pay-period-member-project-seconds';
					cost = 'pay-period-member-project-cost';
				}
			}

			if (!Ember.isNone(costCodeId)) {
				query.cost_code_id = costCodeId;
				seconds = 'pay-period-member-project-cost-code-seconds';
				cost = 'pay-period-member-project-cost-code-cost';
			}

			this.set('secondsModel', seconds);
			this.set('costModel', cost);

			var getter = this.getter.findAll(seconds, query).findAll(cost, query).outerJoin('member', seconds, 'member_id');

			if (/project/.test(seconds)) {
				getter.outerJoin('project', seconds, 'project_id');
			}

			if (/cost-code/.test(seconds)) {
				getter.outerJoin('cost-code', seconds, 'cost_code_id');
			}
			return getter.fetch();
		},


		totalRegularSeconds: Ember.computed('model.@each.totalRegularSeconds', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('totalRegularSeconds');
			});
			return total;
		}),

		totalOvertimeSeconds: Ember.computed('model.@each.totalOvertimeSeconds', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('totalOvertimeSeconds');
			});
			return total;
		}),

		totalDoubletimeSeconds: Ember.computed('model.@each.totalDoubletimeSeconds', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('totalDoubletimeSeconds');
			});
			return total;
		})
	});
});