define('busy-app/components/time/time-sheet/dates-list-item', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-sheet--dates-list', 'time-sheet-row'],
		tagName: 'tr',
		attributeBindings: ['title'],

		store: Ember.inject.service(),

		item: null,
		member: null,
		openDayActivity: null,
		reloadSync: null,

		conflicts: null,
		openEntry: null,
		showConflictResolver: false,

		startOfDay: Ember.computed('item.date', function () {
			return _utils.Time.date(Ember.get(this, 'item.date')).startOf('day').unix();
		}),

		endOfDay: Ember.computed('item.date', function () {
			return _utils.Time.date(Ember.get(this, 'item.date')).endOf('day').unix();
		}),

		isItemLoading: Ember.computed.or('isLoading', 'isEmptyDate.isPending'),

		isEmptyDate: Ember.computed('item.{seconds,hasAggregate,hasUnpaidTimeOff}', function () {
			return _emberData.default.PromiseObject.create({
				promise: this.checkForEmptyDate()
			});
		}),

		/*
   * Extra checks for when the PayPeriodMemberSecond aggregate isn't enough
   * Special case days that should show 0:00 for their hours
   *
   * Aggregates don't get created for Zero-Time entries,
   * 	so if there isn't an aggregate for a day, check to see if any time entries exist
   *
   * Aggregates don't get deleted when all time entries on a day are deleted,
   * 	so if there's an aggregate with 0 seconds, check to make see if are time entries on that day
   */
		checkForEmptyDate: function checkForEmptyDate() {
			// Logger.info(this, 'checkForEmptyDate', Time.date(get(this, 'startOfDay')).format('l'), !get(this, 'item.hasAggregate'), get(this, 'item.seconds') !== 0, get(this, 'item.hasUnpaidTimeOff'), );

			if (Ember.get(this, 'item.seconds') !== 0) {
				return Ember.RSVP.resolve({ isEmpty: false });
			} else if (Ember.get(this, 'item.hasUnpaidTimeOff')) {
				return Ember.RSVP.resolve({ isEmpty: false });
			} else {

				// zombie aggregates are days that have had all entries deleted, but the aggregate still exists
				var needsZombieCheck = Ember.get(this, 'item.hasAggregate') && Ember.get(this, 'item.seconds') === 0;

				// zero time entries do not cause aggregates to be created
				var needsZeroTimeCheck = !Ember.get(this, 'item.hasAggregate');

				if (needsZombieCheck || needsZeroTimeCheck) {
					return this.isDayEmpty(needsZombieCheck, needsZeroTimeCheck).then(function (isEmpty) {
						return { isEmpty: isEmpty };
					});
				} else {
					return Ember.RSVP.resolve({ isEmpty: true });
				}
			}
		},


		// check to see if there are any time entries on the current day
		isDayEmpty: function isDayEmpty() {
			return this.getTimeEntryMeta().then(function (meta) {
				var totalRows = Ember.get(meta, 'totalRows');

				return totalRows === 0;
			});
		},
		getTimeEntryMeta: function getTimeEntryMeta() {
			var startOfDay = Ember.get(this, 'startOfDay');
			var endOfDay = Ember.get(this, 'endOfDay');

			var params = {
				_lte: { start_time: endOfDay },
				_gte: { end_time: startOfDay },
				member_id: Ember.get(this, 'member.id'),
				deleted_on: '_-NULL-_',
				page_size: 1
			};

			return Ember.get(this, 'store').query('time-entry', params).then(function (results) {
				return Ember.get(results, 'meta');
			});
		},


		showTimeColumn: Ember.computed('item.{seconds,hasAggregate,hasUnpaidTimeOff}', 'isEmptyDate.isEmpty', function () {
			var showTimeColumn = Ember.get(this, 'item.seconds') || Ember.get(this, 'item.hasUnpaidTimeOff') || !Ember.get(this, 'isEmptyDate.isEmpty');

			// Logger.info(this, 'showTimeColumn', Time.date(get(this, 'startOfDay')).format('l'), Time.date(get(this, 'endOfDay')).format('l'), showTimeColumn);

			return showTimeColumn;
		}),

		title: Ember.computed('item.date', function () {
			var date = Ember.get(this, 'item.date');
			_utils.Assert.isInteger(date);

			return _utils.Time.date(date).format('LL');
		}),

		conflictsInDay: Ember.computed('conflicts', 'startOfDay', 'endOfDay', function () {
			var _this = this;

			var conflicts = Ember.get(this, 'conflicts');

			if (Ember.isNone(conflicts)) {
				return [];
			} else {
				var startTime = Ember.get(this, 'startOfDay');
				var endTime = Ember.get(this, 'endOfDay');

				return conflicts.filter(function (conflict) {
					return _this.isRangeInRange({ startTime: startTime, endTime: endTime }, conflict.getProperties('startTime', 'endTime'));
				});
			}
		}),

		// test if any of the days conflicts actually conflict with each other
		// for example, two multi-day entries might not conflict with each other,
		// 		but could be in conflict with other days entries. those can't be resolved with this day as the base
		hasResolvableConflicts: Ember.computed('conflictsInDay', function () {
			var _this2 = this;

			var conflictsInDay = Ember.get(this, 'conflictsInDay');

			if (Ember.isNone(conflictsInDay) || conflictsInDay.length < 2) {
				return false;
			}

			return conflictsInDay.any(function (conflict) {
				return conflictsInDay.any(function (compare) {
					return _this2.isRangeInRange(conflict.getProperties('startTime', 'endTime'), compare.getProperties('startTime', 'endTime'));
				});
			});
		}),

		isRangeInRange: function isRangeInRange(a, b) {
			var startsInRange = b.startTime >= a.startTime && b.startTime <= a.endTime;
			var endsInRange = b.endTime >= a.startTime && b.endTime <= a.endTime;
			var encompassesRange = b.startTime <= a.startTime && b.endTime >= a.endTime;

			return startsInRange || endsInRange || encompassesRange;
		},


		hasOpenTime: Ember.computed('openEntry', 'startOfDay', 'endOfDay', function () {
			var _EmberGetProperties = Ember.getProperties(this, ['openEntry', 'startOfDay', 'endOfDay']),
			    openEntry = _EmberGetProperties.openEntry,
			    startOfDay = _EmberGetProperties.startOfDay,
			    endOfDay = _EmberGetProperties.endOfDay;

			var now = _utils.Time.date().unix();

			if (Ember.isNone(openEntry)) {
				return false;
			}

			var hasOpenTime = openEntry <= endOfDay && startOfDay <= now;

			return hasOpenTime;
		}),

		actions: {
			openDayActivity: function openDayActivity() {
				// Logger.info(this, 'openDayActivity');

				this.openDayActivity(Ember.get(this, 'item.date'));

				return false;
			},
			openConflictResolver: function openConflictResolver(e) {
				e.stopPropagation();
				// Logger.info(this, 'openConflictResolver', e);

				Ember.set(this, 'showConflictResolver', true);

				return false;
			},
			closeConflictResolver: function closeConflictResolver() {
				// Logger.info(this, 'closeConflictResolver');

				this.reloadSync();
				Ember.set(this, 'showConflictResolver', false);
			}
		}
	});
});