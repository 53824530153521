define('busy-app/components/bc-sortable-list', ['exports', '@busy-web/components/components/bc-sortable-list'], function (exports, _bcSortableList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bcSortableList.default;
    }
  });
});