define('busy-app/collections/custom-wage-date/utils', ['exports', '@busy-web/utils', 'busy-app/utils/time'], function (exports, _utils, _time) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.getWageHistory = getWageHistory;
	exports.setWageEffectiveEndDate = setWageEffectiveEndDate;
	exports.isWageRelevant = isWageRelevant;
	function getWageHistory(member, start, end) {
		var wageHistories = Ember.get(member, 'wageHistories');

		if (Ember.isNone(wageHistories)) {
			return [];
		}

		var reportRange = { startTime: start, endTime: end };
		var result = [];

		wageHistories = wageHistories.sortBy('changeDate');
		wageHistories.forEach(function (wage, wageIndex) {
			var nextWage = wageHistories.objectAt(wageIndex + 1);
			var prevWage = wageHistories.objectAt(wageIndex - 1);

			setWageEffectiveEndDate(wage, nextWage);

			if (isWageRelevant(wage, prevWage, reportRange)) {
				result.pushObject(wage);
			}
		});

		return result;
	}

	function setWageEffectiveEndDate(wage, nextWage) {
		var defaultEndDate = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _utils.Time.date().add(99, 'years').endOf('year');

		var hasNextWage = !Ember.isNone(nextWage) && !Ember.isNone(Ember.get(nextWage, 'changeDate'));

		// if there's a next wage, use that as the basis for a wage end date
		// otherwise, use an arbitrary date far in the future
		var endDate = hasNextWage ? _utils.Time.date(Ember.get(nextWage, 'changeDate')).subtract(1, 'day').endOf('day').unix() : defaultEndDate;

		Ember.setProperties(wage, { endDate: endDate });

		return wage;
	}

	function isWageRelevant(wage, prevWage, reportRange) {
		var wageRange = { startTime: Ember.get(wage, 'changeDate'), endTime: Ember.get(wage, 'endDate') };

		var isInRange = (0, _time.isRangeInRange)(wageRange, reportRange);
		Ember.setProperties(wage, { isInRange: isInRange });

		var isWageChanged = Ember.isNone(prevWage) || Ember.get(prevWage, 'wage') !== Ember.get(wage, 'wage') || Ember.get(prevWage, 'wageRate') !== Ember.get(wage, 'wageRate') || Ember.get(prevWage, 'overburden') !== Ember.get(wage, 'overburden') || Ember.get(prevWage, 'workWeek') !== Ember.get(wage, 'workWeek') || Ember.get(prevWage, 'effectiveRate') !== Ember.get(wage, 'effectiveRate') || Ember.get(prevWage, 'isInRange') !== Ember.get(wage, 'isInRange');

		return isInRange && isWageChanged;
	}
});