define('busy-app/controllers/report/time-off', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service(),
		payPeriods: Ember.inject.service('pay-period'),

		start: 0,
		end: 0,
		maxDate: null,

		optionSelector: null,
		isCSVLoading: false,

		init: function init() {
			this._super();

			this.setPeriod();
			this.set('maxDate', _utils.Time.date().add(1, 'year').unix());

			this.set('optionSelector', [Ember.Object.create({ key: 'export', value: 'Export' })]);
		},

		setPeriod: Ember.observer('auth.organizationPayPeriod.@each.id', function () {
			if (!Ember.isEmpty(this.get('auth.organizationPayPeriod'))) {
				var currentPeriod = this.get('payPeriods').getPayPeriod(_utils.Time.timestamp());
				var previousPeriod = this.get('payPeriods').getPayPeriod(currentPeriod.get('startDate').subtract(1, 'days').unix());

				this.set('start', previousPeriod.get('start'));
				this.set('end', previousPeriod.get('end'));
			}
		}),

		actions: {
			changeDateRange: function changeDateRange(start, end) {
				this.set('start', start);
				this.set('end', end);

				Ember.getOwner(this).lookup('route:report.time-off').refresh();
			},

			optionChange: function optionChange() {
				var _this = this;
				this.set('isCSVLoading', true);

				var start = this.get('start');
				var end = this.get('end');

				this.store.exportCSV('time-off-report-csv', start, end).then(function () {
					_this.set('isCSVLoading', false);
				});
			}
		}
	});
});