define('busy-app/controllers/project/index', ['exports', '@busy-web/utils', 'busy-app/mixins/controller-filter', 'busy-app/utils/debug', 'busy-app/utils/logger'], function (exports, _utils, _controllerFilter, _debug, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	// default to busy office
	// 37.10102990074617 -113.53877082086876
	/**
  * @module Controllers
  *
  */
	var kDefaultLocation = {
		latitude: 37.10102990074617,
		longitude: -113.53877082086876,
		locationRadius: 50
	};

	/**
  * `Controller/Project/Index`
  *
  * @class Index
  * @namespace Controllers.Project
  * @extends Controller
  */
	/**
  * `Controller/Project/Index`
  *
  */
	exports.default = Ember.Controller.extend(_controllerFilter.default, {
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		queryParams: ['group', 'search', 'page_size', 'page', 'filter'],

		group: null,
		search: '',
		filter: false,

		isLoading: false,

		selectMenu: null,
		rowSelectMenu: null,

		showDialog: false,
		showAdvancedOptions: false, //in dialog
		newProject: null,
		newProjectInfo: null,
		useGeolocation: false,
		newProjectsArray: null,
		parentProjectArray: null,
		hasChanged: false,

		groups: null,

		newGroupName: '',

		selectedGroup: null,
		groupMembers: null,

		shouldAddGroup: false,
		showMoveGroupDialog: false,

		hideRowActions: false,

		_projectCostCodeLinks: null,

		init: function init() {
			this._super();
			Ember.set(this, 'editItems', []);

			// log the organization
			Ember.get(this, 'auth.organization');
			this.setupMenu();
			this.isEditMode();
		},


		isEditMode: Ember.observer('filter', function () {
			if (Ember.get(this, 'filter')) {
				Ember.set(this, 'edit', true);
			} else {
				Ember.set(this, 'edit', false);
			}
		}),

		canManageProjectGroups: Ember.computed('auth.authMemberPosition.manageProjectGroups', function () {
			return Ember.get(this, 'auth.authMemberPosition.manageProjectGroups');
		}),

		canManageProjects: Ember.computed('auth.authMemberPosition.manageProjects', function () {
			return Ember.get(this, 'auth.authMemberPosition.manageProjects');
		}),

		canAddProjects: Ember.computed('canManageProjects', 'group', function () {
			return Ember.get(this, 'canManageProjects') && Ember.get(this, 'group') !== 'archived';
		}),

		hasRowActions: Ember.computed('canManageProjects', 'hideRowActions', function () {
			return Ember.get(this, 'canManageProjects') && !Ember.get(this, 'hideRowActions');
		}),

		canViewManagementTools: Ember.computed.and('canManageProjects', 'features.managementListsProjects'),

		totalRows: Ember.computed('model.@each.id', function () {
			var rows = 0;
			var meta = Ember.get(this, 'model.meta');
			if (!Ember.isNone(meta)) {
				rows = meta.totalRows;
			}
			return rows;
		}),

		setupMenu: Ember.observer('group', function () {
			if (Ember.get(this, 'group') === 'archived') {
				Ember.set(this, 'selectMenu', [Ember.Object.create({ key: 'unarchive', value: (0, _utils.loc)('Unarchive') })]);

				Ember.set(this, 'rowSelectMenu', [Ember.Object.create({ key: 'unarchive', value: (0, _utils.loc)('Unarchive') })]);
			} else {
				Ember.set(this, 'selectMenu', [Ember.Object.create({ key: 'move-to-group', value: (0, _utils.loc)('Move to Group') }), Ember.Object.create({ key: 'archive', value: (0, _utils.loc)('Archive') })]);

				Ember.set(this, 'rowSelectMenu', [Ember.Object.create({ key: 'archive', value: (0, _utils.loc)('Archive') }), Ember.Object.create({ key: 'duplicate', value: (0, _utils.loc)('Duplicate') }), Ember.Object.create({ key: 'move-to-group', value: (0, _utils.loc)('Move to Group') })]);
			}
		}),

		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),

		getGroups: function getGroups() {
			var _this = this;

			return (0, _debug.component_safe_api)(function () {
				return _this.store.query('project-group', { _asc: ['group_name'], page_size: 10000 }).then(function (models) {
					return Ember.set(_this, 'groups', models.sortBy('groupName'));
				});
			});
		},
		archive: function archive() {
			var _this2 = this;

			var items = Ember.get(this, 'archiveItems');
			var timestamp = _utils.Time.timestamp();
			Ember.RSVP.all(items.map(function (item) {
				Ember.set(item, 'archivedOn', timestamp);
				return item.save();
			})).then(function () {
				_this2.clearEdit();
				Ember.set(_this2, 'archiveItems', null);
				Ember.set(_this2, 'showArchiveDialog', false);
				_this2.refreshRoute();
			});
		},
		unarchive: function unarchive(items, callback) {
			var _this3 = this;

			callback = callback !== undefined ? callback : function () {};
			Ember.RSVP.all(items.map(function (item) {
				Ember.set(item, 'archivedOn', '_-NULL-_');
				return item.save();
			})).then(function () {
				_this3.refreshRoute();
				Ember.run.next(function () {
					return callback();
				});
				_this3.clearEdit();
			});
		},
		openArchiveDialog: function openArchiveDialog(items) {
			Ember.set(this, 'archiveItems', items);
			Ember.set(this, 'showArchiveDialog', true);
		},
		createDuplicateProject: function createDuplicateProject(project, newParent) {
			var _this4 = this;

			var params = {
				title: Ember.get(project, 'title'),
				organizationId: Ember.get(this, 'auth.organization.id')
			};

			if (!Ember.isNone(Ember.get(project, 'parentProjectId'))) {
				params.parentProjectId = Ember.get(newParent, 'id');
			} else {
				params.title = Ember.get(this, 'duplicateTitle');
			}

			var newProject = this.store.createRecord('project', params);
			return newProject.saveBatch(true).then(function (nProject) {
				// get this projects children
				return _this4.store.query('project', { parent_project_id: Ember.get(project, 'id') }).then(function (children) {
					return Ember.RSVP.all(children.map(function (child) {
						if (!Ember.isNone(child) && !Ember.isNone(nProject)) {
							return _this4.createDuplicateProject(child, nProject);
						} else {
							_logger.default.error('child or parent project was undefined');
						}
					})).then(function () {
						return nProject;
					});
				});
			});
		},


		editAction: null,
		editItems: null,

		/**
   * Clears the edit action
   *
   * @public
   * @method clearEdit
   */
		clearEdit: function clearEdit() {
			// torpid-list sets isSelected on the model
			// so loop through the models and set isSelected
			// to false to uncheck the checkboxes
			Ember.get(this, 'editItems').forEach(function (item) {
				Ember.set(item, 'isSelected', false);
			});

			// set local vars back to defaults
			Ember.set(this, 'editAction', null);
			Ember.set(this, 'editItems', []);
			Ember.set(this, 'edit', false);
			Ember.set(this, 'hideRowActions', false);

			this.sendFilter({
				search: Ember.get(this, 'search'),
				group: Ember.get(this, 'group')
			});
		},
		validateFilters: function validateFilters(options) {
			var isAll = Ember.isNone(options.group);
			var isUngrouped = !Ember.isNone(options.group) && options.group === 'ungrouped';
			var isArchived = !Ember.isNone(options.group) && options.group !== 'archived';

			if (options.filter) {
				if ((!Ember.isEmpty(options.search) || !isAll && !isUngrouped && !isArchived) && !options.page) {
					options.page = 1;
					options.page_size = 1000;
				} else if ((isUngrouped || isArchived) && !options.page) {
					options.page = 1;
					options.page_size = Ember.get(this, 'page_size');
				}
			}

			options.filter = options.filter === true ? true : false;
			options.group = Ember.isNone(options.group) ? null : options.group;
			options.search = Ember.isEmpty(options.search) ? '' : options.search;

			return true;
		},
		moveToGroup: function moveToGroup(items) {
			if (!Ember.isNone(items) && Ember.get(items, 'length') > 0) {
				Ember.set(this, 'groupMembers', items);
				Ember.set(this, 'showMoveGroupDialog', true);
			}
		},
		addProjectToGroup: function addProjectToGroup(group) {
			var _this5 = this;

			var groupId = !Ember.isNone(group) ? Ember.get(group, 'id') : "_-NULL-_";
			var models = Ember.get(this, 'groupMembers');
			return Ember.RSVP.all(models.map(function (item) {
				Ember.set(item, 'projectGroupId', groupId);
				return item.save();
			})).then(function () {
				Ember.set(_this5, 'showMoveGroupDialog', false);
				Ember.set(_this5, 'groupMembers', []);
				Ember.set(_this5, 'selectedGroup', null);
				_this5.refreshRoute();
			});
		},
		_createGroup: function _createGroup(groupName) {
			var _this6 = this;

			var organizationId = Ember.get(this, 'auth.organization.id');
			return this.store.createRecord('project-group', { groupName: groupName, organizationId: organizationId }).save().finally(function () {
				return _this6.getGroups();
			});
		},
		refreshRoute: function refreshRoute() {
			this.send('refreshModel');
		},


		archiveMessage: Ember.computed('archiveItems.@each', function () {
			if (Ember.get(this, 'archiveItems.length') > 1) {
				var string = 'This action will archive any subprojects within these ' + Ember.get(this, 'archiveItems.length') + ' projects.';
				return (0, _utils.loc)(string);
			} else {
				return (0, _utils.loc)('This action will archive any subprojects within this project.');
			}
		}),

		archiveHeader: Ember.computed('archiveItems', function () {
			if (Ember.get(this, 'archiveItems.length') > 1) {
				return (0, _utils.loc)('Archive Projects');
			} else {
				return (0, _utils.loc)('Archive Project');
			}
		}),

		unarchiveHeader: Ember.computed('editItems.@each', function () {
			if (Ember.get(this, 'editItems.length') > 1) {
				return (0, _utils.loc)('Unarchive Subprojects');
			} else {
				return (0, _utils.loc)('Unarchive Subproject');
			}
		}),

		unarchiveMessage: Ember.computed('editItems.@each', function () {
			if (Ember.get(this, 'editItems.length') > 1) {
				var string = 'This action will restore any subprojects within these ' + Ember.get(this, 'editItems.length') + ' projects.';
				return (0, _utils.loc)(string);
			} else {
				return (0, _utils.loc)('This action will restore any subprojects within this project');
			}
		}),

		initNewProject: function initNewProject() {
			var newProject = this.store.createRecord('project', {
				organizationId: Ember.get(this, 'auth.organization.id')
			});

			var newProjectInfo = this.store.createRecord('project-info', {
				locationRadius: kDefaultLocation.locationRadius,
				latitude: kDefaultLocation.latitude,
				longitude: kDefaultLocation.longitude
			});

			this.setProperties({
				useGeolocation: false,
				newProject: newProject,
				newProjectInfo: newProjectInfo,
				_projectCostCodeLinks: []
			});
		},


		onUseGeolocationChange: Ember.observer('useGeolocation', function () {
			if (Ember.get(this, 'showDialog')) {
				if (Ember.get(this, 'useGeolocation') && Ember.isNone(Ember.get(this, 'newProjectInfo.locationRadius'))) {
					Ember.get(this, 'newProjectInfo').setProperties(kDefaultLocation);
				}
			}
		}),

		/*
   * Cost Code Select Actions
   */

		getProjectCostCodeLinks: function getProjectCostCodeLinks() {
			_logger.default.info(this, 'getProjectCostCodeLinks');

			return Ember.RSVP.resolve([]);
		},
		recieveCostCodeChanges: function recieveCostCodeChanges(_projectCostCodeLinks) {
			_logger.default.info(this, 'recieveCostCodeChanges', _projectCostCodeLinks);
			Ember.setProperties(this, { _projectCostCodeLinks: _projectCostCodeLinks });

			return Ember.RSVP.resolve();
		},
		rollbackCostCodeChanges: function rollbackCostCodeChanges() {
			var _this7 = this;

			_logger.default.info(this, 'rollbackCostCodeChanges', this.get('_projectCostCodeLinks'));

			if (!Ember.isEmpty(Ember.get(this, '_projectCostCodeLinks'))) {
				Ember.get(this, '_projectCostCodeLinks').filterBy('hasDirtyAttributes').forEach(function (model) {
					return model.rollbackAttributes();
				});

				Ember.set(this, '_projectCostCodeLinks', null);
			}

			return this.getProjectCostCodeLinks().then(function (_projectCostCodeLinks) {
				return Ember.setProperties(_this7, { _projectCostCodeLinks: _projectCostCodeLinks });
			});
		},
		saveCostCodeLinks: function saveCostCodeLinks(projectId) {
			var _projectCostCodeLinks = Ember.get(this, '_projectCostCodeLinks');

			if (Ember.get(this, 'isLinkedCostCodesEnabled') && !Ember.isNone(_projectCostCodeLinks)) {
				_projectCostCodeLinks.setEach('projectId', projectId);

				return Ember.RSVP.allSettled(_projectCostCodeLinks.map(function (link) {
					return link.save();
				}));
			} else {
				return Ember.RSVP.resolve();
			}
		},


		// when a group is deleted, ungroup all the cost-code's which were assigned to it
		ungroupOrphans: function ungroupOrphans(group) {
			var _this8 = this;

			// Logger.info(this ,'ungroupOrphans', group, get(group, 'id'));

			if (Ember.isNone(group)) {
				return Ember.RSVP.resolve();
			}

			var project_group_id = Ember.get(group, 'id');
			var params = {
				project_group_id: project_group_id,
				page_size: 1000
			};

			Ember.set(this, 'isLoading', true);
			return Ember.get(this, 'store').findAll('project', params).then(function (projects) {
				// Logger.info(this ,'ungroupOrphans', 'orphans', get(projects, 'length'));

				projects.setEach('projectGroupId', null);

				return Ember.RSVP.all(projects.invoke('save'));
			}).then(function () {
				return _this8.refreshRoute();
			}).finally(function () {
				return Ember.set(_this8, 'isLoading', false);
			});
		},
		scrollToTop: function scrollToTop() {
			var _this9 = this;

			var $addProjectModal = Ember.$('.v-add-project-dialog').closest('.modal-dialog-body');

			// short circuit if the modal no longer exists
			if (!$addProjectModal.length) {
				return;
			}

			// have this method continue callilng itself until the saving notice is no longer visible
			if (Ember.$('.autosave-signal.active').length) {
				return Ember.run.later(this, function () {
					return _this9.scrollToTop();
				}, 100);
			}

			// put the cursor in the first input
			$addProjectModal.find('input').first().focus();

			// scroll the modal top the top
			$addProjectModal.get(0).scrollIntoView();
		},


		actions: {
			addProjectToGrpupAction: function addProjectToGrpupAction(project) {
				this.addProjectToGroup(project);
			},
			rowItemSelectAction: function rowItemSelectAction(action, member, project) {
				if (Ember.get(action, 'key') === 'archive') {
					this.openArchiveDialog([project]);
				} else if (Ember.get(action, 'key') === 'unarchive') {
					Ember.set(this, 'showUnarchiveDialog', true);
					Ember.set(this, 'editItems', [project]);
					// this.unarchive([project]);
				} else if (Ember.get(action, 'key') === 'duplicate') {
					Ember.set(this, 'duplicateProject', project);
					Ember.set(this, 'duplicateDialog', true);
				} else if (Ember.get(action, 'key') === 'move-to-group') {
					this.moveToGroup([project]);
				} else {
					_logger.default.error("An unrecognized action was called in the controller/project/index actionView");
				}
			},
			projectSelected: function projectSelected(isChecked, item) {
				if (isChecked) {
					Ember.get(this, 'editItems').pushObject(item);
				} else {
					Ember.get(this, 'editItems').removeObject(item);
				}
			},
			selectAll: function selectAll(isChecked, items) {
				if (isChecked) {
					Ember.set(this, 'editItems', items);
				} else {
					Ember.set(this, 'editItems', []);
				}
			},
			editListAction: function editListAction(action) {
				Ember.set(this, 'editAction', Ember.get(action, 'key'));
				Ember.set(this, 'hideRowActions', true);
				Ember.set(this, 'edit', true);

				var params = {
					filter: true,
					group: Ember.get(this, 'group'),
					ungrouped: Ember.get(this, 'ungrouped'),
					search: Ember.get(this, 'search')
				};

				if (Ember.get(action, 'key') === 'unarchive') {
					params.group = 'archived';
				}

				this.sendFilter(params);
			},
			submitEditAction: function submitEditAction() {
				var filteredItems = Ember.get(this, 'editItems');

				if (Ember.isNone(filteredItems) || Ember.get(filteredItems, 'length') === 0) {
					this.clearEdit();
				} else if (Ember.get(this, 'editAction') === 'archive') {
					this.openArchiveDialog(filteredItems);
				} else if (Ember.get(this, 'editAction') === 'unarchive') {
					Ember.set(this, 'showUnarchiveDialog', true);
					// this.unarchive(filteredItems, function()
					// {
					// 	this.clearEdit();
					// });
				} else if (Ember.get(this, 'editAction') === 'move-to-group') {
					if (!Ember.isNone(filteredItems) && Ember.get(this, 'editItems.length') > 0) {
						Ember.set(this, 'groupMembers', filteredItems);
						Ember.set(this, 'showMoveGroupDialog', true);
					}
					this.clearEdit();
				}
			},
			cancelEditAction: function cancelEditAction() {
				this.clearEdit();
			},
			confirmUnarchive: function confirmUnarchive() {
				this.unarchive(Ember.get(this, 'editItems'));
				Ember.set(this, 'showUnarchiveDialog', false);
			},
			cancelUnarchive: function cancelUnarchive() {
				Ember.set(this, 'showUnarchiveDialog', false);
				this.clearEdit();
			},


			/**
    * opens the new project dialog
    * and creates a new project model
    *
    * @public
    * @method openNewProjectDialog
    * @returns {void}
    */
			openNewProjectDialog: function openNewProjectDialog() {
				this.initNewProject();

				Ember.setProperties(this, {
					showDialog: true,
					_projectCostCodeLinks: []
				});
			},
			closeNewProjectDialog: function closeNewProjectDialog() {
				Ember.set(this, 'showDialog', false);

				if (!Ember.isNone(Ember.get(this, 'newProject'))) {
					Ember.get(this, 'newProject').rollbackAttributes();
				}
				Ember.set(this, 'newProject', null);
			},
			saveNewProject: function saveNewProject() {
				var _this10 = this;

				var newProject = Ember.get(this, 'newProject');
				var newProjectInfo = Ember.get(this, 'newProjectInfo');

				Ember.set(this, 'mapError', false);

				if (!Ember.get(this, 'useGeolocation')) {
					newProjectInfo.clearGeolocation();
				}

				if (!Ember.isEmpty(Ember.get(newProject, 'title'))) {
					if (Ember.get(this, 'model').isAny('matchTitle', Ember.get(newProject, 'title').toLowerCase())) {
						return Ember.RSVP.reject((0, _utils.loc)('Project name must be unique'));
					} else if (Ember.get(newProjectInfo, 'reminder') && !Ember.get(newProjectInfo, 'hasLocation')) {
						Ember.set(this, 'mapError', true);
						return Ember.RSVP.reject((0, _utils.loc)('Geolocation required for location reminders.'));
					} else {
						return newProject.save().then(function (project) {
							Ember.set(newProjectInfo, 'projectId', project.id);

							return Ember.RSVP.all([newProjectInfo.save(), _this10.saveCostCodeLinks(project.id)]);
						}).then(function () {
							_this10.initNewProject();
							_this10.refreshRoute();
							_this10.scrollToTop();
						});
					}
				} else {
					return Ember.RSVP.reject((0, _utils.loc)('Project name is required'));
				}
			},
			projectClicked: function projectClicked(project) {
				if (!Ember.get(this, 'edit')) {
					this.transitionToRoute('project.detail', Ember.get(project, 'id'));
				}
			},
			setSelectedGroup: function setSelectedGroup(view, group) {
				Ember.set(this, 'selectedGroup', group);
			},
			addNewGroup: function addNewGroup(value) {
				this._createGroup(value);
			},
			createGroup: function createGroup() {
				var _this11 = this;

				var name = Ember.get(this, 'newGroupName');

				if (!Ember.isEmpty(name)) {
					this._createGroup(name).then(function () {
						Ember.set(_this11, 'newGroupName', '');
					});
				}
			},
			showAddGroup: function showAddGroup() {
				Ember.set(this, 'shouldAddGroup', !Ember.get(this, 'shouldAddGroup'));
			},
			closeGroupDialog: function closeGroupDialog() {
				Ember.set(this, 'showMoveGroupDialog', false);
			},
			archiveProject: function archiveProject() {
				this.archive();
			},
			closeArchiveDialog: function closeArchiveDialog() {
				Ember.set(this, 'archiveItems', null);
				Ember.set(this, 'showArchiveDialog', false);
				this.clearEdit();
			},
			filterGroup: function filterGroup(group) {
				var options = {
					search: Ember.get(this, 'search'),
					filter: Ember.get(this, 'filter'),
					group: Ember.get(group, 'id')
				};
				this.sendFilter(options);
			},
			changePage: function changePage(page) {
				page = parseInt(page, 10);
				page = !isNaN(page) && page > 1 ? page : 1;

				this.sendFilter({
					page: page,
					page_size: Ember.get(this, 'page_size'),
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group'),
					search: Ember.get(this, 'search')
				});
			},
			searchAction: function searchAction(keyword) {
				keyword = !Ember.isEmpty(keyword) ? keyword : '';

				this.sendFilter({
					search: keyword,
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group')
				});
			},
			changeSize: function changeSize(size) {
				size = parseInt(size, 10);
				size = !isNaN(size) && size > 30 ? size : 30;

				this.sendFilter({
					page: 1,
					page_size: size,
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group'),
					search: Ember.get(this, 'search')
				});
			},
			saveDuplicate: function saveDuplicate() {
				var _this12 = this;

				if (!Ember.isNone(Ember.get(this, 'duplicateTitle')) && !Ember.isEmpty(Ember.get(this, 'duplicateTitle'))) {
					var project = Ember.get(this, 'duplicateProject');
					return this.createDuplicateProject(project).catch(function () {
						return Ember.RSVP.reject((0, _utils.loc)("There was an error duplicating the project"));
					}).finally(function () {
						return _this12.refreshRoute();
					});
				} else {
					return Ember.RSVP.reject((0, _utils.loc)('You must provide a project title.'));
				}
			},
			closeDuplicate: function closeDuplicate() {
				Ember.set(this, 'duplicateProject', null);
				Ember.set(this, 'duplicateDialog', false);
			},


			/*** Project Import ***/

			openImportAction: function openImportAction() {
				Ember.set(this, 'showDialog', false);
				Ember.set(this, 'showImportDialog', true);
			},
			closeImportAction: function closeImportAction() {
				Ember.set(this, 'showImportDialog', false);
				Ember.set(this, 'showDialog', false);
			},
			cancelImportAction: function cancelImportAction() {
				Ember.set(this, 'showImportDialog', false);
				Ember.set(this, 'showDialog', true);
			},
			importCompleteAction: function importCompleteAction() {
				Ember.set(this, 'showDialog', false);
				Ember.set(this, 'showImportDialog', false);
				this.refreshRoute();
			},


			/*** Project Import End ***/

			/*
    * Cost Code Select Actions
    */
			onCancelCostCodeChanges: function onCancelCostCodeChanges() {
				_logger.default.info(this, 'onCancelCostCodeChanges');

				return this.rollbackCostCodeChanges();
			},
			onSaveManagerChanges: function onSaveManagerChanges(projectCostCodeLinks) {
				_logger.default.info(this, 'onSaveManagerChanges', { projectCostCodeLinks: projectCostCodeLinks });

				var projectId = Ember.get(this, 'project.id');
				projectCostCodeLinks.setEach('projectId', projectId);

				return this.recieveCostCodeChanges(projectCostCodeLinks);
			},
			onDeleteGroup: function onDeleteGroup(group) {
				_logger.default.info(this, 'onDeleteGroup', group);

				return this.ungroupOrphans(group);
			}
		}
	});
});