define('busy-app/utils/models/managers/payroll-member-project', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/payroll-member'], function (exports, _utils, _payrollMember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _payrollMember.default.extend({
		projectId: null,

		type: 'member-project',

		titleString: 'Title',
		typeString: 'Employee Project',
		hasImage: false,

		fetch: function fetch(start, end, projectId) {
			this.set('start', start);
			this.set('end', end);
			this.set('projectId', projectId);

			(false && !(!Ember.isNone(start)) && Ember.assert('You must provide a start {number} to PayrollMemberManager', !Ember.isNone(start)));
			(false && !(!Ember.isNone(end)) && Ember.assert('You must provide a end {number} to PayrollMemberManager', !Ember.isNone(end)));


			return Ember.RSVP.hash({
				memberData: this.store.dispatcher('member-pay-period').find(start, end),
				projectData: this.store.dispatcher('project-pay-period').find(start, end, projectId)
			}).then(function (data) {
				return {
					memberCost: data.memberData.cost,
					memberSeconds: data.memberData.seconds,
					wageHistory: data.memberData.wage,
					projectSeconds: data.projectData.seconds,
					projectCost: data.projectData.cost,
					members: data.memberData.members,
					projects: data.projectData.projects
				};
			});
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			var members = data.members.sortBy('firstName', 'lastName');
			members.forEach(function (member) {
				if (Ember.isNone(member.get('archivedOn')) || member.get('archivedOn') > _this.get('start')) {
					var seconds = data.projectSeconds.filterBy('memberId', member.id);
					var cost = data.projectCost.filterBy('memberId', member.id);
					var actualCost = data.memberCost.findBy('memberId', member.id);
					var actualSeconds = data.memberSeconds.findBy('memberId', member.id);
					var wage = _this.getWageHistory(data.wageHistory.filterBy('memberId', member.id), _this.get('start'));

					if (!Ember.isNone(actualSeconds) && actualSeconds.get('totalSeconds') > 0 || wage.get('wageRate') === 30 || wage.get('wageRate') === 40 || wage.get('wageRate') === 50) {
						var memberData = _this.buildCostData(member, seconds, cost, actualSeconds, actualCost, wage, data.projects);
						_this.addInternalModel(memberData);
					}
				}
			});
		},
		buildCostData: function buildCostData(member, projectSeconds, projectCost, memberSeconds, memberCost, wage, projects) {
			var PayrollMemberCollection = this.collectionFor('payroll-member');
			var data = {
				type: this.get('type'),
				id: member.get('id'),
				isModel: true,
				startTime: this.get('start'),
				endTime: this.get('end'),
				member: member, wage: wage, projectSeconds: projectSeconds, projectCost: projectCost, memberCost: memberCost, memberSeconds: memberSeconds
			};

			var collection = PayrollMemberCollection.push(data);
			var projectData = this.buildProjectData(projects, projectSeconds, projectCost, member, memberSeconds, memberCost, wage);
			collection.set('projectData', projectData);
			return collection;
		},
		buildProjectData: function buildProjectData(projects, projectSeconds, projectCost, member, memberSeconds, memberCost, wage, depth) {
			var _this2 = this;

			depth = depth || 1;
			var data = [];
			projectSeconds.forEach(function (item) {
				var project = projects.findBy('id', item.get('projectId'));
				if (!Ember.isNone(project)) {
					project.set('sortWeight', 1);
				} else {
					if (depth === 1) {
						project = Ember.Object.create({
							id: null,
							isUnassigned: true,
							title: (0, _utils.loc)('Unassigned'),
							depth: depth,
							sortWeight: 2
						});
					}
				}

				if (!Ember.isNone(project) && project.get('depth') === depth) {
					var seconds = projectSeconds.filterBy('projectId', project.id);
					var cost = projectCost.filterBy('projectId', project.id);
					var filtered = _this2.getChildProjects(projects, project.id);
					var projectData = _this2.buildProjectCostData(project, seconds, cost, member, memberSeconds, memberCost, wage, filtered.length > 0);

					if (filtered.length > 0) {
						var childProjectData = _this2.buildProjectData(filtered, projectSeconds, projectCost, member, memberSeconds, memberCost, wage, depth + 1);
						projectData.set('projectData', childProjectData);
						_this2.addUnassigned(projectData, member, memberSeconds, memberCost, wage, project.id);
					}
					data.pushObject(projectData);
				}
			});
			return data.sortBy('project.sortWeight', 'project.title');
		},
		getChildProjects: function getChildProjects(projects, projectId) {
			var _this3 = this;

			var filtered = projects;
			if (!Ember.isNone(projectId)) {
				filtered = filtered.filterBy('parentProjectId', projectId);
				var children = [];
				filtered.forEach(function (item) {
					item.set('sortWeight', 1);
					children = children.concat(_this3.getChildProjects(projects, item.id));
				});
				filtered = filtered.concat(children);
			}
			return filtered;
		},
		addUnassigned: function addUnassigned(data, member, memberSeconds, memberCost, wage, parentProjectId) {
			data.set('filterChildren', true);
			if (data.get('totalSeconds') > 0) {
				var project = Ember.Object.create({
					id: null,
					title: (0, _utils.loc)('Unassigned'),
					isUnassigned: true,
					depth: data.get('project.depth') + 1,
					parentProjectId: parentProjectId,
					sortWeight: 2
				});

				var seconds = Ember.Object.create({
					totalRegularSeconds: data.get('totalRegularSeconds'),
					totalOvertimeSeconds: data.get('totalOvertimeSeconds'),
					totalDoubletimeSeconds: data.get('totalDoubletimeSeconds'),
					totalSeconds: data.get('totalSeconds')
				});

				var cost = Ember.Object.create({
					totalRegularCost: data.get('totalRegularCost'),
					totalOvertimeCost: data.get('totalOvertimeCost'),
					totalDoubletimeCost: data.get('totalDoubletimeCost'),
					totalCost: data.get('totalCost')
				});

				var projectData = this.buildProjectCostData(project, [seconds], [cost], member, memberSeconds, memberCost, wage, false);
				data.get('projectData').pushObject(projectData);
				data.set('hasUnassigned', true);
			}
			data.set('filterChildren', false);
		},
		buildProjectCostData: function buildProjectCostData(project, projectSeconds, projectCost, member, memberSeconds, memberCost, wage, hasChildren) {
			var PayrollProjectCollection = this.collectionFor();
			var data = {
				type: this.get('type'),
				id: project.get('id'),
				isOpen: hasChildren,
				isModel: true,
				startTime: this.get('start'),
				endTime: this.get('end'),
				project: project, member: member, wage: wage, projectSeconds: projectSeconds, projectCost: projectCost, memberCost: memberCost, memberSeconds: memberSeconds
			};
			return PayrollProjectCollection.push(data);
		}
	});
});