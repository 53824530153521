define('busy-app/components/time/time-entry/clock-in', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['v-time-card-dialog-clock-in'],

    auth: Ember.inject.service('auth'),
    features: Ember.inject.service('features'),
    store: Ember.inject.service(),
    subscription: Ember.inject.service(),

    member: null,
    timeEntry: null,
    entryLocation: null,

    memberEntries: null,

    project: null,
    costCode: null,
    description: '',
    organization: null,

    covidQuestions: null,
    signInAnswers: [],
    signInSubmission: null,
    showCovidRestrictionDialog: false,
    covidRestrictionHeader: '',
    covidRestrictionMessage: '',
    covidRestricted: false,
    hideCovidCheckForm: false,
    multipleMemberCovidCheck: 0,

    costCodesAutoOpen: false,
    projectsAutoOpen: false,
    showCovidQuestionDialog: false,
    isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),
    disableCostCodeSelection: Ember.computed('project.id', 'isLinkedCostCodesEnabled', function () {
      return Ember.get(this, 'isLinkedCostCodesEnabled') && Ember.isNone(Ember.get(this, 'project.id'));
    }),

    init: function init() {
      this._super();
      this.setOrganization();
      this.setCovidQuestions();
      this.setProperties({
        covidRestrictionHeader: 'Clock In Restricted Due to COVID-19',
        covidRestrictionMessage: 'Based on your answers to the COVID-19 check, you may be at risk for having contracted COVID and will not be allowed to clock in for the day. Please contact your supervisor for additional information and guidance.'
      });

      this.setCovidRestricted();
      this.hideCovidCheck();
    },
    setOrganization: function setOrganization() {
      if (Ember.isNone(this.get('organization'))) {
        this.set('organization', this.get('auth.organization'));
      }
    },
    setCovidQuestions: function setCovidQuestions() {
      var _this = this;

      if (this.get('organization.busySignInQuestion')) {
        this.get('store').findAll('busy-sign-in-question').then(function (results) {
          _this.setProperties({
            covidQuestions: results,
            signInAnswers: [],
            signInSubmission: null
          });
        });
      }
    },
    setCovidRestricted: function setCovidRestricted() {
      var _this2 = this;

      if (this.get('hasMultipleMembers')) {
        this.get('memberEntries').forEach(function (member) {
          if (member.get('id') === _this2.get('auth.member.id')) {
            _this2.getSubmission(member.get('id')).then(function (a) {
              var flagged = a.filter(function (ans) {
                return ans.get('flagged') === true;
              });

              var covidCheck = parseFloat(_this2.get('multipleMemberCovidCheck')) + 0;
              var newCovidCheckNumber = covidCheck += flagged.length;
              _this2.set('multipleMemberCovidCheck', newCovidCheckNumber);
              _this2.set('covidRestricted', _this2.get('multipleMemberCovidCheck') > 0);
            });
          }
        });
      } else {
        if (!Ember.isEmpty(this.get('member'))) {
          if (this.get('member.id') === this.get('auth.member.id')) {
            this.getSubmission(this.get('member.id')).then(function (a) {
              var flagged = a.filter(function (ans) {
                return ans.get('flagged') === true;
              });
              _this2.set('covidRestricted', flagged.length > 0);
            });
          }
        } else if (this.get('memberEntries.length') > 0) {
          var member = this.get('memberEntries')[0];
          if (member.get('id') === this.get('auth.memberId')) {
            this.getSubmission(member.get('id')).then(function (a) {
              var flagged = a.filter(function (ans) {
                return ans.get('flagged') === true;
              });
              _this2.set('covidRestricted', flagged.length > 0);
            });
          }
        }
      }
    },
    getSubmission: function getSubmission(memberId) {
      var startOfDay = _utils.Time.date().startOf('day').unix();
      var endOfDay = _utils.Time.date().endOf('day').unix();

      return DS.PromiseArray.create({
        promise: this.get('store').query('member-sign-in-submission', { member_id: memberId, _lte: { local_time: endOfDay }, _gte: { local_time: startOfDay } })
      });
    },
    hideCovidCheck: function hideCovidCheck() {
      var _this3 = this;

      if (!Ember.isEmpty(this.get('member'))) {
        if (this.get('member.id') !== this.get('auth.member.id')) {
          this.set('hideCovidCheckForm', true);
        }
      } else if (this.get('memberEntries.length') > 0) {
        var memberArray = this.get('memberEntries').filter(function (m) {
          return m.get('id') === _this3.get('auth.member.id') ? m : null;
        });

        if (memberArray.length < 1) {
          this.set('hideCovidCheckForm', true);
        }
      }
    },
    clockIn: function clockIn(member, override) {
      var _this4 = this;

      var startLocation = null;
      var isSwitch = false;
      var signInAnswers = this.get('signInAnswers');
      var authMember = this.get('auth.member');

      if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
        member = Ember.get(member, 'content');
      }

      //project and costCode CANNOT be passed into clockInAt
      //Only used for then function to set model project and costCode
      return member.clockIn(this.get('project.id'), this.get('costCode.id'), this.get('description'), this.get('equipment.id'), startLocation, override, false, isSwitch, signInAnswers, authMember, this.get('covidRestricted'), this.get('isFreeAccount')).then(function (model) {
        model.setTimeInterval(1000);
        model.set('project', _this4.get('project'));
        model.set('costCode', _this4.get('costCode'));
        model.set('equipment', _this4.get('equipment'));

        return model;
      });
    },


    hasMultipleMembers: Ember.computed('memberEntries.[]', function () {
      if (this.get('memberEntries.length') > 1) {
        return true;
      }
      return false;
    }),

    hasTimeLocationRequired: Ember.computed('memberEntries', 'member', 'auth.member.id', function () {
      var members = [].concat(this.get('memberEntries')).concat(this.get('member')).compact();

      return members.any(function (member) {
        return member.get('isTimeLocationRequired');
      });
    }),

    covidQuestionsSubmittedText: Ember.computed('signInAnswers.@each', 'showCovidQuestionDialog', 'covidRestricted', function () {
      var signInAnswers = this.get('signInAnswers');
      return !Ember.isEmpty(signInAnswers) && signInAnswers.length > 0 || this.get('covidRestricted') ? 'Submitted' : 'Not Submitted (Required)';
    }),

    editCovidQuestionsDisabled: Ember.computed('covidQuestionsSubmittedText', function () {
      var covidQuestionsSubmittedText = this.get('covidQuestionsSubmittedText');

      return covidQuestionsSubmittedText === 'Submitted';
    }),

    setSubmissions: function setSubmissions() {
      var _this5 = this;

      var answers = this.get('signInAnswers');
      var store = this.get('store');
      var covidQuestions = this.get('covidQuestions');
      var offset = _utils.Time.timezone(_utils.Time.date());
      var isDST = _utils.Time.isDST(_utils.Time.date().unix());

      var submission = store.createRecord('member-sign-in-submission', {
        id: _utils.UUID.generate(),
        memberId: this.get('auth.member.id'),
        proxyMemberId: this.get('auth.memberId'),
        deviceType: 'Web Browser',
        localTime: _utils.Time.date().unix(),
        flagged: false,
        createdOnOffset: offset,
        createdOnDst: isDST

      });

      answers.forEach(function (a) {
        var cQuestionForPrompt = covidQuestions.filter(function (c) {
          return c.get('questionTitle') === a.get('questionTitle');
        });
        if (cQuestionForPrompt.length > 0) {
          a.set('description', cQuestionForPrompt[0].get('promptAnswer'));
          a.set('memberSignInSubmissionId', submission.get('id'));
        }

        if (a.get('flagged')) {
          submission.set('flagged', a.get('flagged'));
        };
      });

      submission.save().then(function () {
        answers.forEach(function (answer, index) {
          var timeIndex = index + 1;
          answer.set('createdOn', _utils.Time.date().unix() + timeIndex);
          answer.save();
        });
        _this5.setCovidRestricted();
      });;
    },


    actions: {
      projectSelected: function projectSelected(item) {
        this.set('project', item);

        if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
          Ember.set(this, 'costCode', null);
        }
      },
      costCodeSelected: function costCodeSelected(item) {
        this.set('costCode', item);
      },
      equipmentSelected: function equipmentSelected(item) {
        this.set('equipment', item);
      },


      /**
       * closes dialog if clicked on cancel button
       * or clicked in background window
       *
       */
      close: function close() {
        return this.sendAction('onClose');
      },
      clockinSave: function clockinSave(forceSave) {
        var _this6 = this;

        if (!Ember.isNone(this.get('memberEntries'))) {
          var members = this.get('memberEntries');

          var promise = [];
          members.forEach(function (member) {
            promise.push(_this6.clockIn(member, true));
          });

          return Ember.RSVP.all(promise);
        } else {
          return this.clockIn(this.get('member'), forceSave);
        }
      },
      projectMenuClosed: function projectMenuClosed(didSelectValue /*, selectedItem, openedBy*/) {
        var _this7 = this;

        if (didSelectValue && Ember.get(this, 'isLinkedCostCodesEnabled')) {
          Ember.run.next(this, function () {
            Ember.setProperties(_this7, {
              costCodesAutoOpen: Date.now()
            });
          });
        }
      },
      costCodeMenuClosed: function costCodeMenuClosed(didSelectValue, selectedItem, openedBy) {
        var _this8 = this;

        // Logger.info(this, 'costCodeMenuClosed', didSelectValue, selectedItem, openedBy);

        // if the cost code menu was opened automatically by the double nav and the user hits back (!didSelectValue) then re-open the project selecter
        if (Ember.get(this, 'isLinkedCostCodesEnabled') && !didSelectValue && openedBy === 'auto') {
          Ember.run.next(this, function () {
            Ember.setProperties(_this8, {
              costCode: null,
              projectsAutoOpen: Date.now()
            });
          });
        }
      },
      openCovidQuestionDialog: function openCovidQuestionDialog() {
        this.set('showCovidQuestionDialog', true);
      },
      closeCovidDialog: function closeCovidDialog() {
        this.get('covidQuestions').forEach(function (q) {
          q.set('showPrompt', false);
        });

        this.setProperties({
          showCovidQuestionDialog: false,
          signInAnswers: [],
          signInSubmission: null,
          showCovidError: false,
          covidError: ''
        });
      },
      setClockInQuestionAnswer: function setClockInQuestionAnswer(questionObject, value) {
        var offset = _utils.Time.timezone(Ember.get(this, 'date'));
        var isDST = _utils.Time.isDST(Ember.get(this, 'dateStamp'));
        var question = this.get('covidQuestions').filter(function (q) {
          return q.get('questionTitle') === questionObject.get('questionTitle');
        });
        var signInAnswers = this.get('signInAnswers');
        var answer = this.get('store').createRecord('member-sign-in-answer', {
          memberId: this.get('auth.memberId'),
          answer: value === true ? 'Yes' : 'No',
          questionTitle: questionObject.get('questionTitle'),
          questionDescription: question[0].get('questionDescription'),
          questionPrompt: question[0].get('questionPrompt'),
          flagged: value,
          localTime: _utils.Time.date().unix()
        });
        questionObject.set('showPrompt', value === true && !Ember.isEmpty(questionObject.get('questionPrompt')));

        var currentItemInAnswerArray = signInAnswers.findIndex(function (a) {
          return a.get('questionTitle') === answer.get('questionTitle');
        });

        if (currentItemInAnswerArray >= 0) {
          signInAnswers[currentItemInAnswerArray] = answer;
        } else {
          signInAnswers.push(answer);
        }
      },
      setCovidQuestionAnswers: function setCovidQuestionAnswers() {
        var signInAnswers = this.get('signInAnswers');
        this.setProperties({
          showCovidError: false,
          covidError: ''
        });
        if (signInAnswers.length < 4) {
          this.setProperties({
            showCovidError: true,
            covidError: 'All questions are required'
          });
        } else {
          this.setSubmissions();
          this.set('showCovidQuestionDialog', false);
        }
      },
      openCovidRestrictionDialog: function openCovidRestrictionDialog() {
        this.set('showCovidRestrictionDialog', true);
      },
      closeCovidRestrictionDialog: function closeCovidRestrictionDialog() {
        this.set('showCovidRestrictionDialog', false);
      }
    }
  });
});