define('busy-app/models/cost-code-day-cost', ['exports', 'ember-data', '@busy-web/utils', 'busy-app/utils/unique-id'], function (exports, _emberData, _utils, _uniqueId) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		// used to match seconds and cost models
		uid: (0, _uniqueId.default)('type', 'startDate', 'costCodeId'),

		// used to differentiate uid for month and day models
		type: "day",

		costCodeId: _emberData.default.attr('id', { sortable: true, sortBy: ['costCode.costCode'] }),
		organizationId: _emberData.default.attr('id'),
		startDate: _emberData.default.attr('date', { sortable: true, sortBy: ['startDate'] }),
		regular: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overtime: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		doubletime: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		updatedOn: _emberData.default.attr('date'),
		submittedOn: _emberData.default.attr('date'),
		overburden: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),

		costCode: _emberData.default.belongsTo('cost-code'),

		startDateFormatted: Ember.computed('startDate', function () {
			return _utils.Time.date(this.get('startDate')).format('MMM D');
		}),

		startOfWeek: Ember.computed('startDate', function () {
			return _utils.Time.date(this.get('startDate')).startOf('week').format('MM/D/YY');
		}),

		endOfWeek: Ember.computed('startDate', function () {
			return _utils.Time.date(this.get('startDate')).endOf('week').format('MM/D/YY');
		}),

		weekRangeFormatted: Ember.computed('startOfWeek', function () {
			return this.get('startOfWeek') + ' - ' + this.get('endOfWeek');
		}),

		regularCost: Ember.computed.alias('regular').meta({ aggregateType: 'sum' }),
		overtimeCost: Ember.computed.alias('overtime').meta({ aggregateType: 'sum' }),
		doubletimeCost: Ember.computed.alias('doubletime').meta({ aggregateType: 'sum' }),

		totalCost: Ember.computed('regular', 'overtime', 'doubletime', function () {
			return this.get('regular') + this.get('overtime') + this.get('doubletime');
		}).meta({ aggregateType: 'sum' }),

		totalOverburdenCost: Ember.computed('totalCost', 'overburden', function () {
			return this.get('totalCost') + this.get('overburden');
		}).meta({ aggregateType: 'sum' })
	});
});