define('busy-app/components/dialogs/open-trial', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		actions: {
			close: function close() {
				this.sendAction('onClose');
			}
		}
	});
});