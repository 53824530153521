define('busy-app/utils/models/collections/time-entry-log', ['exports', '@busy-web/utils', 'busy-app/utils/models/collections/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Utils/Models/Collections
  *
  */
	var kBreakActionTypes = {
		10: (0, _utils.loc)("Break start"),
		11: (0, _utils.loc)("Break start location"),
		20: (0, _utils.loc)("Break end"),
		21: (0, _utils.loc)("Break end location"),
		25: (0, _utils.loc)("Break end"),
		30: (0, _utils.loc)("Manual break"),
		40: (0, _utils.loc)("Edited break"),
		50: (0, _utils.loc)("Manual break"),
		60: (0, _utils.loc)("Edited break"),
		80: (0, _utils.loc)('Break conflict resolution'),
		90: (0, _utils.loc)('Break deleted'),
		100: (0, _utils.loc)('Break deleted by conflict resolution')
	};

	var kActionTypes = {
		10: (0, _utils.loc)('Clock in'),
		11: (0, _utils.loc)('Clock in location'),
		20: (0, _utils.loc)('Clock out'),
		21: (0, _utils.loc)('Clock out location'),
		25: (0, _utils.loc)('Server clock out'),
		30: (0, _utils.loc)('Clock in at'),
		31: (0, _utils.loc)('Clock in at location'),
		40: (0, _utils.loc)('Clock out at'),
		41: (0, _utils.loc)('Clock out at location'),
		50: (0, _utils.loc)('Manual entry'),
		60: (0, _utils.loc)('Edit'),
		70: (0, _utils.loc)('Edit'),
		80: (0, _utils.loc)('Conflict resolution'),
		90: (0, _utils.loc)('Deleted'),
		100: (0, _utils.loc)('Deleted by conflict resolution')
	};

	/**
  * `Util/Model/Collection/TimeEntryLog`
  *
  * @class TimeEntryLog
  * @namespace Utils.Models.Collections
  * @extends Utils.Models.Collections.Base
  */
	exports.default = _base.default.extend({
		model: null,
		prevState: null,
		mergedState: null,

		showDetails: false,
		showMap: false,

		applyData: function applyData(model, prevState, mergedState) {
			this.set('prevState', prevState);
			this.set('mergedState', mergedState);

			this._super(model);
		},


		hasLocations: Ember.computed('model.{createdOnString,actionType}', 'startBreak.createdOnString', 'stopBreak.createdOnString', 'sysStopBreak.createdOnString', function () {
			// valid locations
			var startBreak = this.get('startBreak.isStartLocationValid');
			var endBreak = this.get('stopBreak.isEndLocationValid');
			var startEntry = this.get('model.isStartLocationValid');
			var stopEntry = this.get('model.isEndLocationValid');

			// actions types
			var ciAction = [10, 11, 30, 31].indexOf(this.get('model.actionType')) !== -1;
			var coAction = [20, 21, 40, 41].indexOf(this.get('model.actionType')) !== -1;
			var sbAction = [10, 11].indexOf(this.get('startBreak.actionType')) !== -1;
			var ebAction = [20, 21].indexOf(this.get('stopBreak.actionType')) !== -1;

			return startBreak && sbAction || endBreak && ebAction || startEntry && ciAction || stopEntry && coAction;
		}),

		createdOnString: Ember.computed('model.{createdOnString,actionType}', 'startBreak.createdOnString', 'stopBreak.createdOnString', 'sysStopBreak.createdOnString', function () {
			if (this.get('model.actionType') === 70) {
				if (!Ember.isEmpty(this.get('startBreak.createdOnString'))) {
					return this.get('startBreak.createdOnString');
				} else if (!Ember.isEmpty(this.get('stopBreak.createdOnString'))) {
					return this.get('stopBreak.createdOnString');
				} else if (!Ember.isEmpty(this.get('sysStopBreak.createdOnString'))) {
					return this.get('sysStopBreak.createdOnString');
				}
			}
			return this.get('model.createdOnString');
		}),

		/**
   * string for the time and date of the clock in/ clock in at
   *
   */
		startString: Ember.computed('model.{startString,actionType}', 'startBreak.startString', 'stopBreak.startString', 'sysStopBreak.startString', function () {
			if (this.get('model.actionType') === 70) {
				if (!Ember.isEmpty(this.get('startBreak.startString'))) {
					return this.get('startBreak.startString');
				} else if (!Ember.isEmpty(this.get('stopBreak.startString'))) {
					return this.get('stopBreak.startString');
				} else if (!Ember.isEmpty(this.get('sysStopBreak.startString'))) {
					return this.get('sysStopBreak.startString');
				}
			}
			return this.get('model.startString');
		}),

		/**
   * string for the time and date of the clock out/ clock out at
   *
   */
		endString: Ember.computed('model.{endString,actionType}', 'startBreak.endString', 'stopBreak.endString', 'sysStopBreak.endString', function () {
			if (this.get('model.actionType') === 70) {
				if (!Ember.isEmpty(this.get('startBreak.endString'))) {
					return this.get('startBreak.endString');
				} else if (!Ember.isEmpty(this.get('stopBreak.endString'))) {
					return this.get('stopBreak.endString');
				} else if (!Ember.isEmpty(this.get('sysStopBreak.endString'))) {
					return this.get('sysStopBreak.endString');
				}
			}
			return this.get('model.endString');
		}),

		updaterName: Ember.computed('model.{updaterMember.fullName,actionType}', 'startBreak.updaterMember.fullName', 'stopBreak.updaterMember.fullName', 'sysStopBreak.updaterMember.fullName', function () {
			if (this.get('model.actionType') === 70) {
				if (!Ember.isNone(this.get('startBreak.updaterMember.fullName'))) {
					return this.get('startBreak.updaterMember.fullName');
				} else if (!Ember.isNone(this.get('stopBreak.updaterMember.fullName'))) {
					return this.get('stopBreak.updaterMember.fullName');
				} else if (!Ember.isNone(this.get('sysStopBreak.updaterMember.fullName'))) {
					return this.get('sysStopBreak.updaterMember.fullName');
				}
			}
			return this.get('model.updaterMember.fullName');
		}),

		deviceType: Ember.computed('model.{deviceType,actionType}', 'startBreak.deviceType', 'stopBreak.deviceType', 'sysStopBreak.deviceType', function () {
			if (this.get('model.actionType') === 70) {
				if (!Ember.isNone(this.get('startBreak.deviceType'))) {
					return this.get('startBreak.deviceType');
				} else if (!Ember.isNone(this.get('stopBreak.deviceType'))) {
					return this.get('stopBreak.deviceType');
				} else if (!Ember.isNone(this.get('sysStopBreak.deviceType'))) {
					return this.get('sysStopBreak.deviceType');
				}
			}
			return this.get('model.deviceType');
		}),

		actionString: Ember.computed('model.actionType', 'newBreaks.@each.actionType', 'startBreak.actionType', 'stopBreak.actionType', 'sysStopBreak.actionType', function () {
			var modelType = this.get('model.actionType');
			var types = kActionTypes;
			if (modelType === 70) {
				if (!Ember.isNone(this.get('startBreak.id'))) {
					types = kBreakActionTypes;
					modelType = this.get('startBreak.actionType');
				} else if (!Ember.isNone(this.get('stopBreak.id'))) {
					types = kBreakActionTypes;
					modelType = this.get('stopBreak.actionType');
				} else if (!Ember.isNone(this.get('sysStopBreak.id'))) {
					types = kBreakActionTypes;
					modelType = this.get('sysStopBreak.actionType');
				}
			}

			return types[modelType] || 'Edit';
		}),

		subActionString: Ember.computed('model.actionType', 'startBreak.id', 'stopBreak.id', 'sysStopBreak.id', function () {
			var actionString = '';
			var modelType = this.get('model.actionType');
			if (modelType === 10 || modelType === 11 || modelType === 30 || modelType === 31) {
				actionString = this.get('model.startString');
			} else if (modelType === 20 || modelType === 21 || modelType === 25 || modelType === 40 || modelType === 41) {
				actionString = this.get('model.endString');
			} else if (modelType === 50) {
				actionString = this.get('model.startString') + ' - ' + this.get('model.endString');
			} else if (modelType === 60 || modelType === 80) {
				actionString = this.get('changedItems');
			} else if (modelType === 70) {
				if (!Ember.isNone(this.get('startBreak.id'))) {
					actionString = this.get('startBreak.startString');
				} else if (!Ember.isNone(this.get('stopBreak.id'))) {
					actionString = this.get('stopBreak.endString');
				} else if (!Ember.isNone(this.get('sysStopBreak.id'))) {
					actionString = this.get('sysStopBreak.endString');
				} else {
					actionString = this.get('changedItems');
				}
			}
			return actionString;
		}),

		isEditEntry: Ember.computed('model.actionType', 'startBreak.id', 'stopBreak.id', 'sysStopBreak.id', function () {
			if (this.get('model.actionType') === 70) {
				if (Ember.isNone(this.get('startBreak.id')) && Ember.isNone(this.get('stopBreak.id')) && Ember.isNone(this.get('sysStopBreak.id'))) {
					return true;
				}
			} else if (this.get('model.actionType') === 60) {
				return true;
			}
			return false;
		}),

		isBreakEntry: Ember.computed('isEditEntry', 'model.actionType', function () {
			return !this.get('isEditEntry') && this.get('model.actionType') === 70;
		}),

		hasChanges: Ember.computed('isDescription', 'isProject', 'isCostCode', 'isStartTime', 'isEndTime', 'isBreakAdd', 'isBreakEdit', 'isEquipment', function () {
			if (this.get('isDescription') || this.get('isProject') || this.get('isCostCode') || this.get('isStartTime') || this.get('isEndTime') || this.get('isBreakAdd') || this.get('isBreakEdit') || this.get('isEquipment')) {
				return true;
			}
			return false;
		}),

		changedItems: Ember.computed('isDescription', 'isProject', 'isCostCode', 'isStartTime', 'isEndTime', 'isBreak', 'isEquipment', function () {
			var items = '';
			items += this.get('isStartTime') ? (0, _utils.loc)('Start Time') : '';
			items += this.get('isEndTime') ? ', ' + (0, _utils.loc)('Stop Time') : '';
			items += this.get('isDescription') ? ', ' + (0, _utils.loc)('Description') : '';
			items += this.get('isProject') ? ', ' + (0, _utils.loc)('Project') : '';
			items += this.get('isCostCode') ? ', ' + (0, _utils.loc)('Cost Code') : '';
			items += this.get('isEquipment') ? ', ' + (0, _utils.loc)('Equipment') : '';
			items += this.get('isBreak') ? ', ' + (0, _utils.loc)('Breaks') : '';

			return items.replace(/^,/, '').trim();
		}),

		isDescription: Ember.computed('prevState.description', 'model.description', function () {
			if (this.get('prevState.description') !== this.get('model.description')) {
				return true;
			}
			return false;
		}),

		isProject: Ember.computed('prevState.projectId', 'model.projectId', function () {
			if (this.get('prevState.projectId') !== this.get('model.projectId')) {
				return true;
			}
			return false;
		}),

		isCostCode: Ember.computed('prevState.costCodeId', 'model.costCodeId', function () {
			if (this.get('prevState.costCodeId') !== this.get('model.costCodeId')) {
				return true;
			}
			return false;
		}),

		isEquipment: Ember.computed('prevState.equipmentId', 'model.equipmentId', function () {
			if (this.get('prevState.equipmentId') !== this.get('model.equipmentId')) {
				return true;
			}
			return false;
		}),

		isStartTime: Ember.computed('prevState.startTime', 'model.startTime', function () {
			if (this.get('prevState.startTime') - this.get('model.startTime') > 60) {
				return true;
			}
			return false;
		}),

		isEndTime: Ember.computed('prevState.endTime', 'model.endTime', function () {
			if (!Ember.isNone(this.get('prevState.endTime')) && this.get('prevState.endTime') !== this.get('model.endTime')) {
				return true;
			}
			return false;
		}),

		isBreak: Ember.computed('activeBreaks.length', 'previousBreaks.length', function () {
			var _this = this;

			var breaks = this.get('activeBreaks').filter(function (item) {
				var match = _this.get('previousBreaks').findBy('id', item.get('id'));
				return Ember.isNone(match);
			});
			return breaks.get('length') > 0;
		}),

		startBreak: Ember.computed('model.breaks.@each.actionType', function () {
			var modelId = this.get('model.id');
			return this.get('model.breaks').find(function (item) {
				return modelId === item.get('clientTimeEntryLogId') && [10, 11].indexOf(item.get('actionType')) !== -1;
			});
		}),

		stopBreak: Ember.computed('model.breaks.@each.actionType', function () {
			var modelId = this.get('model.id');
			return this.get('model.breaks').find(function (item) {
				return modelId === item.get('clientTimeEntryLogId') && [20, 21].indexOf(item.get('actionType')) !== -1;
			});
		}),

		sysStopBreak: Ember.computed('model.breaks.@each.actionType', function () {
			var _this2 = this;

			return this.get('model.breaks').find(function (item) {
				return _this2.get('model.id') === item.get('clientTimeEntryLogId') && item.get('actionType') === 25;
			});
		}),

		addBreaks: Ember.computed('model.breaks.@each.actionType', function () {
			var breaks = this.get('model.breaks').filterBy('actionType', 50);
			var oldBreaks = this.get('model.breaks').filterBy('actionType', 30);
			if (oldBreaks.length) {
				breaks = breaks.concat(oldBreaks);
			}
			return breaks.filterBy('clientTimeEntryLogId', this.get('model.id'));
		}),

		editBreaks: Ember.computed('model.breaks.@each.actionType', function () {
			var breaks = this.get('model.breaks').filterBy('actionType', 60);
			var oldBreaks = this.get('model.breaks').filterBy('actionType', 40);
			if (oldBreaks.length) {
				breaks = breaks.concat(oldBreaks);
			}
			return breaks.filterBy('clientTimeEntryLogId', this.get('model.id'));
		}),

		previousBreaks: Ember.computed('prevState.breaks.@each.actionType', function () {
			var prevStateBreaks = this.get('prevState.breaks');
			if (Ember.isNone(prevStateBreaks)) {
				return [];
			}

			var filteredResults = prevStateBreaks.filter(function (item) {
				var type = item.get('actionType');
				if (type !== 90 && type !== 100 && type !== 10 && type !== 11 && type !== 21) {
					return item;
				}
			});

			if (Ember.isNone(filteredResults)) {
				return [];
			}

			return filteredResults.sortBy('startTime');
		}),

		newBreaks: Ember.computed('model.breaks', function () {
			var _this3 = this;

			return this.get('model.breaks').filter(function (item) {
				return _this3.get('model.id') === item.get('clientTimeEntryLogId');
			}).sortBy('startTime');
		}),

		activeBreaks: Ember.computed('model.breaks.@each.actionType', function () {
			return this.get('model.breaks').filter(function (item) {
				var type = item.get('actionType');
				if (type !== 90 && type !== 100 && type !== 10 && type !== 11 && type !== 21) {
					return item;
				}
			}).sortBy('startTime');
		}),

		removedBreaks: Ember.computed('model.breaks.@each.actionType', function () {
			return this.get('model.breaks').filterBy('actionType', 90);
		}),

		addedByResolutionBreaks: Ember.computed('model.breaks.@each.actionType', function () {
			return this.get('model.breaks').filterBy('actionType', 80);
		}),

		removedByResolutionBreaks: Ember.computed('model.breaks.@each.actionType', function () {
			return this.get('model.breaks').filterBy('actionType', 100);
		}),

		locationInfo: Ember.computed('actionString', function () {
			var str = this.get('actionString');
			var entry = Ember.Object.create({
				startTime: this.get('model.startTime'),
				endTime: this.get('model.endTime'),
				member: this.get('model.member'),
				timeEntryBreaks: []
			});

			if (str === kActionTypes[10] || str === kActionTypes[11] || str === kActionTypes[30] || str === kActionTypes[31]) {
				entry.set('isStartLocationValid', this.get('model.isStartLocationValid'));
				entry.set('startLocation', getTargetContent(this, 'model.startLocation'));
				return entry;
			} else if (str === kActionTypes[20] || str === kActionTypes[21] || str === kActionTypes[40] || str === kActionTypes[41]) {
				entry.set('isEndLocationValid', this.get('model.isEndLocationValid'));
				entry.set('endLocation', getTargetContent(this, 'model.endLocation'));
				return entry;
			} else if (str === kBreakActionTypes[10] || str === kBreakActionTypes[11]) {
				var breakEntry = Ember.Object.create({
					startTime: this.get('startBreak.startTime'),
					endTime: this.get('startBreak.endTime'),
					startLocation: getTargetContent(this, 'startBreak.startLocation'),
					isStartLocationValid: this.get('startBreak.isStartLocationValid'),
					timeEntry: entry
				});

				entry.get('timeEntryBreaks').pushObject(breakEntry);
				return entry;
			} else if (str === kBreakActionTypes[20] || str === kBreakActionTypes[21]) {
				var _breakEntry = Ember.Object.create({
					startTime: this.get('stopBreak.startTime'),
					endTime: this.get('stopBreak.endTime'),
					endLocation: getTargetContent(this, 'stopBreak.endLocation'),
					isEndLocationValid: this.get('stopBreak.isEndLocationValid'),
					timeEntry: entry
				});

				entry.get('timeEntryBreaks').pushObject(_breakEntry);
				return entry;
			}
			return this;
		})
	});


	function getTargetContent(target, path) {
		var model = Ember.get(target, path);
		if (!model || Ember.get(model, 'content') === undefined) {
			return model;
		}
		return Ember.get(model, 'content');
	}
});