define('busy-app/utils/date-range-pay-period', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = dateRangePayPeriod;


	/**
  * EmberDateRangePicker list item for pay periods
  *
  * @class DateRangePayPeriod
  */
	var DateRangePayPeriod = Ember.Object.extend({
		id: null,
		name: null,
		type: null,
		selected: false,

		payPeriod: null,

		span: function span(start, end, direction) {
			if (!Ember.isEmpty(Ember.get(this, 'payPeriod'))) {
				var timestamp = start;
				if (direction === 1) {
					timestamp = end + 2;
				} else if (direction === -1) {
					timestamp = start - 2;
				}

				var period = Ember.get(this, 'payPeriod').getPayPeriod(timestamp);
				return { start: Ember.get(period, 'start'), end: Ember.get(period, 'end') };
			}
			return { start: start, end: end };
		}
	}); /**
      * @module Utils
      *
      */
	function dateRangePayPeriod(payPeriodService) {
		var selected = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

		return DateRangePayPeriod.create({
			id: 'pay-period',
			name: (0, _utils.loc)('Pay Period'),
			type: 'days',
			payPeriod: payPeriodService,
			selected: selected
		});
	}
});