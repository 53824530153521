define('busy-app/components/report/time-card/signature-display', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    auth: Ember.inject.service('auth'),
    signature: Ember.inject.service('signature'),

    createdBy: Ember.computed('auth.member.id', function () {
      return this.get('auth.member.fullName') + ' @ ' + _utils.Time.date().format('h:mm A MM/DD/YYYY');
    })
  });
});