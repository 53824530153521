define('busy-app/components/select-menu/equipment', ['exports', 'busy-app/components/select-menu/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		classNames: ['c-equipment-select-menu'],

		menuTitle: Ember.computed('selectedItem.fullName', function () {
			// default title is `None`
			var title = (0, _utils.loc)('None');

			// if the selectedTitle is set to non-empty string
			// then use it for the title
			if (!Ember.isEmpty(Ember.get(this, 'selectedItem.fullName'))) {
				title = Ember.get(this, 'selectedItem.fullName');
			}

			return title;
		}),

		loadRecent: function loadRecent() {
			var _this = this;

			var member_id = Ember.get(this, 'auth.member.id'),
			    equipment_id = '!_-NULL-_',
			    page = 1,
			    page_size = 1,
			    _desc = ['start_time'],
			    deleted_on = null;

			return Ember.get(this, 'store').query('time-entry', { member_id: member_id, equipment_id: equipment_id, page: page, page_size: page_size, _desc: _desc, deleted_on: deleted_on }).then(function (model) {
				return Ember.get(_this, 'store').findWhereIn('equipment', 'id', _this.getPropertyArray(model, 'equipmentId'), { archived_on: null, deleted_on: deleted_on, _asc: ['title'] });
			});
		},
		loadData: function loadData() {
			var page = Ember.get(this, 'page'),
			    page_size = Ember.get(this, 'pageSize'),
			    archived_on = null,
			    _asc = ['equipment_name'];

			return this.get('store').query('equipment', { page: page, page_size: page_size, archived_on: archived_on, _asc: _asc });
		},
		loadSearch: function loadSearch(keyword) {
			var page = Ember.get(this, 'page'),
			    page_size = 100,
			    archived_on = null,
			    _asc = ['equipment_name'],
			    _search = keyword;

			return this.get('store').query('equipment', { page: page, page_size: page_size, archived_on: archived_on, _search: _search, _asc: _asc });
		},
		getSelectedTitle: function getSelectedTitle(item) {
			return Ember.get(item, 'fullName');
		}
	});
});