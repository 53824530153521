define('busy-app/components/subscriptions/subscription-invoice-history', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kDefaultShowCount = 6; // including current month, if more than 12 ensure getChartLabel() includes the year

	/**
  * `Components/Subscriptions/SubscriptionInvoiceHistory`
  *
  * @class InvoiceHistory
  * @namespace Components.Company
  * @extends Component
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['v-company-settings-invoice-history'],

		// services
		auth: Ember.inject.service('auth'),
		store: Ember.inject.service('store'),
		subscription: Ember.inject.service('subscription'),

		// public properties
		statements: null,

		reportCount: kDefaultShowCount,
		pageNumber: 1,

		isLoadingStatements: false,
		isLoadingPdf: false,

		activeUsers: Ember.computed('statements.[]', function () {
			var list = this.get('statements') || [];
			return this.generateActiveUserList(list);
		}),

		hasUsage: Ember.computed('activeUsers', function () {
			return this.get('activeUsers').any(function (row) {
				return row.getWithDefault('usage', 0) > 0;
			});
		}),

		/**
   * @public
   * @method init
   */
		init: function init() {
			this._super();
			this.setInvoices();
		},


		/**
   * @public
   * @method setInvoices
   */
		setInvoices: function setInvoices() {
			var _this = this;

			if (Ember.isNone(this.get('statements'))) {
				this.set('isLoadingStatements', true);

				return this.getInvoices().then(function (results) {
					if (!_this.get('isDestroyed')) {
						_this.set('statements', results);
					}
				}).finally(function () {
					if (!_this.get('isDestroyed')) {
						_this.set('isLoadingStatements', false);
					}
				});
			}
		},


		/**
   * @public
   * @method getInvoices
  	 * @param {Number} count number of statements to get
   * @return {Array}
   */
		getInvoices: function getInvoices() {
			return this.get('store').query('chargify-customer-statement', {
				report_count: this.get('reportCount'),
				page_number: this.get('pageNumber')
			});
		},


		/**
   * generates a unique data set to render a bar chart of activeUsers per month
   *
   * @public
   * @method generateActiveUserList
   * @param list {object[]}
   * @return {object[]}
   */
		generateActiveUserList: function generateActiveUserList(list) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isArray(list);

			var today = _utils.Time.date();
			var statements = [];
			list.forEach(function (item) {
				var date = _utils.Time.date(item.get('periodEndDate'));
				var format = today.year() === date.year() ? 'MMM.' : 'MMM. YYYY';

				// create a chart object with a month and year field for
				// comparison operations
				var chartObj = Ember.Object.create({
					chartLabel: date.format(format),
					periodEndDate: item.get('periodEndDate'),
					usage: item.get('usage'),
					month: date.month(),
					year: date.year()
				});

				// find any matching data for the same month and year
				// and reduce them to a single object with the largest usage record
				var hasMonth = statements.findBy('month', chartObj.get('month'));

				// check for a month match
				if (!Ember.isNone(hasMonth)) {
					// check for a year match on the month match
					if (hasMonth.get('year') === chartObj.get('year')) {
						// if the new chart object has a larger usage then
						// remove the old object and insert the new object
						// order does not matter here so push it to the end
						if (hasMonth.get('usage') < chartObj.get('usage')) {
							statements.removeObject(hasMonth);
							statements.pushObject(chartObj);
						}
					} else {
						// no matching year so push it to the end
						statements.pushObject(chartObj);
					}
				} else {
					// no matching month so push it to the end
					statements.pushObject(chartObj);
				}
			});

			// return the list of unique months sorted by year and month
			return statements.sortBy('year', 'month').slice(-6);
		},


		actions: {

			/**
    * Open the billing policy in a new tab
    *
    * @public
    * @event openBillingPolicy
    */
			openBillingPolicy: function openBillingPolicy() {
				return window.open('https://busybusy.com/fair-billing/');
			},
			openStatementPdf: function openStatementPdf(item) {
				var _this2 = this;

				this.set('isLoadingPdf', true);

				// open window before async call as workaround for popup blocking
				var invoiceWindow = window.open();

				this.get('store').query('chargify-customer-statement-pdf', { statement_id: item.get('id') }).then(function (results) {
					if (!Ember.isNone(results) && !Ember.isNone(results.get('firstObject'))) {
						invoiceWindow.location = results.get('firstObject.url');
						invoiceWindow.focus();
					} else {
						invoiceWindow.close();
					}
				}).catch(function (err) {
					invoiceWindow.close();
					window.console.error(err);
				}).finally(function () {
					if (!_this2.get('isDestroyed')) {
						_this2.set('isLoadingPdf', false);
					}
				});
			}
		}
	});
});