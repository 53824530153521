define('busy-app/controllers/employees/employee', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['timesheet_start', 'timesheet_end', 'timesheet_mode'],

		access: Ember.inject.service('access'),
		features: Ember.inject.service('features'),

		organization: Ember.computed.alias('access.auth.organization'),

		selectMenu: null,
		showNewTimeEntryDialog: false,
		showClockInAtDialog: false,
		showClockOutAtDialog: false,

		timecardBadgeContent: '',
		timecardBadgeColor: 'red',

		timesheet_start: null,
		timesheet_end: null,
		timesheet_mode: null,

		init: function init() {
			this._super();
			this.setSelectMenu();
		},

		onStartChange: Ember.observer('timesheet_start', function () {
			var timesheet_start = this.get('timesheet_start');

			if (!Ember.isNone(timesheet_start) && parseInt(timesheet_start, 10) !== timesheet_start) {
				this.set('timesheet_start', parseInt(timesheet_start, 10));
			}
		}),

		onEndChange: Ember.observer('timesheet_end', function () {
			var timesheet_end = this.get('timesheet_end');

			if (!Ember.isNone(timesheet_end) && parseInt(timesheet_end, 10) !== timesheet_end) {
				this.set('timesheet_end', parseInt(timesheet_end, 10));
			}
		}),

		canShowEntries: Ember.computed('model.member.id', 'access.auth.authMemberPosition', function () {
			if (!Ember.isNone(this.get('model.member.id')) && !Ember.isNone(this.get('access.auth.authMemberPosition.id'))) {
				return this.get('access').canTakeActionFor('timeEvents', this.get('model.member'));
			}
			return false;
		}),

		canShowStatus: Ember.computed('canShowEntries', 'model.member.id', 'access.auth.authMemberPosition', function () {
			var result = false;
			if (this.get('canShowEntries')) {
				result = true;
			} else if (!Ember.isNone(this.get('model.member.id')) && !Ember.isNone(this.get('access.auth.authMemberPosition.id'))) {
				result = this.get('access').canTakeActionFor('timeEvents', this.get('model.member'));
			}
			return result;
		}),

		canShowWages: Ember.computed('model.member.id', 'access.auth.authMemberPosition', function () {
			if (!Ember.isNone(this.get('model.member.id')) && !Ember.isNone(this.get('access.auth.authMemberPosition.id'))) {
				return this.get('access').canTakeActionFor('manageEmployees', this.get('model.member'));
			}
			return false;
		}),

		canShowDetails: Ember.computed('model.member.id', 'access.auth.authMemberPosition', function () {
			if (!Ember.isNone(this.get('model.member.id')) && !Ember.isNone(this.get('access.auth.authMemberPosition.id'))) {
				return this.get('access').canTakeActionFor('manageEmployees', this.get('model.member'));
			}
			return false;
		}),

		isArchived: Ember.computed('model.member.archivedOn', function () {
			if (!Ember.isNone(this.get('model.member.archivedOn'))) {
				return true;
			}
			return false;
		}),

		/**
   * Sets up the select Menu for this view
   *
   * Observes member.openEntry.id on Init
   *
   * @public
   * @method setSelectMenu
   */
		setSelectMenu: Ember.observer('model.member.openEntry.id', function () {
			if (Ember.isNone(this.get('model.member.openEntry'))) {
				this.set('selectMenu', [Ember.Object.create({ key: 'new-time-entry', value: (0, _utils.loc)('New Time Entry') }), Ember.Object.create({ key: 'clock-in-at', value: (0, _utils.loc)('Clock In At') })]);
			} else {
				this.set('selectMenu', [Ember.Object.create({ key: 'new-time-entry', value: (0, _utils.loc)('New Time Entry') }), Ember.Object.create({ key: 'clock-out-at', value: (0, _utils.loc)('Clock Out At') })]);
			}
		}),

		actions: {
			openLoacationAction: function openLoacationAction(member) {
				this.transitionToRoute('employees.employee-locations', {
					queryParams: {
						id: member.get('openEntry.id'),
						member_id: member.get('id')
					}
				});
			},


			/**
    * Set each selectMenu item
    */
			selectItemAction: function selectItemAction(item) {
				if (item.get('key') === 'new-time-entry') {
					this.set('showNewTimeEntryDialog', true);
				} else if (item.get('key') === 'clock-in-at') {
					this.set('showClockInAtDialog', true);
				} else if (item.get('key') === 'clock-out-at') {
					this.set('showClockOutAtDialog', true);
				}
			},


			/**
    * close dialog for adding Time Entry
    */
			closeTimeEntryAdd: function closeTimeEntryAdd() {
				this.set('showNewTimeEntryDialog', false);
			},


			/**
    * close dialog for adding Time Entry
    */
			closeClockInAt: function closeClockInAt() {
				this.set('showClockInAtDialog', false);
			},
			closeClockOutAt: function closeClockOutAt() {
				this.set('showClockOutAtDialog', false);
			}
		}
	});
});