define('busy-app/components/projects/import-projects', ['exports', 'busy-app/utils/project-csv-import', '@busy-web/utils'], function (exports, _projectCsvImport, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ["c-projects-import-projects"],

		model: null,
		importMembers: null,

		showImportError: false,
		showFormat: false,
		showPreview: false,

		importProgress: (0, _utils.loc)('Saving'),
		importErrorMessage: '',

		init: function init() {
			this._super();
			this.setupButtons();
		},
		setupButtons: function setupButtons() {
			this.set('buttonInfo1', [{ action: this.get('actions.closeImportAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfo2', [{ action: this.get('actions.saveAction'), color: 'blue', text: (0, _utils.loc)('Save'), target: this }, { action: this.get('actions.closePreviewAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfo3', [{ action: this.get('actions.closeFormatAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfoError', [{ action: this.get('actions.closeErrorAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
		},
		closeImport: function closeImport() {
			this.set('showImportError', false);
			this.set('showPreview', false);
		},
		showError: function showError(err) {
			this.set('importErrorMessage', err);
			this.set('showImportError', true);
		},


		actions: {
			closeImportAction: function closeImportAction() {
				this.closeImport();
				this.sendAction('onClose');
			},
			closeErrorAction: function closeErrorAction() {
				this.set('showPreview', false);
				this.set('showImportError', false);
			},
			closePreviewAction: function closePreviewAction() {
				this.set('showPreview', false);
			},
			openFormatAction: function openFormatAction() {
				this.set('showFormat', true);
			},
			closeFormatAction: function closeFormatAction() {
				this.set('showFormat', false);
			},
			uploadAction: function uploadAction(data) {
				var _this = this;

				data = data[0];
				var owner = Ember.getOwner(this);
				var projectImport = _projectCsvImport.default.create(owner.ownerInjection());

				projectImport.on('onFinishFileParse', function () {
					_this.set('showPreview', true);
				});

				projectImport.on('progress', function (left, total) {
					if (total > 20) {
						_this.set('importProgress', (0, _utils.loc)('Importing projects') + " " + Math.ceil((total - left) / total * 100) + '%');
					}
				});

				projectImport.on('complete', function () {
					_this.sendAction('onComplete', _this.get('model'));
				});

				projectImport.on('error', function (err) {
					_this.showError(err);
				});

				projectImport.run(data);
				this.set('projectsArray', projectImport);
			},
			saveAction: function saveAction() {
				var _this2 = this;

				var projects = this.get('projectsArray');
				return projects.saveModel().then(function (res) {
					_this2.set('model', projects.get('projects'));
					return (0, _utils.loc)("Saved ") + res.length + " " + (0, _utils.loc)("projects successfully!");
				}).finally(function () {
					return _this2.closeImport();
				});
			}
		}
	});
});