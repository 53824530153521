define('busy-app/components/employees/employee-info', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['v-employees-employee-details'],

    auth: Ember.inject.service('auth'),
    features: Ember.inject.service('features'),
    router: Ember.inject.service('router'),
    toggle: Ember.inject.service('toggle'),
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    subscription: Ember.inject.service(),

    showEditDialog: false,
    showPassDialog: false,
    showPhotoDialog: false,
    showCompanyInfo: true,
    isArchived: false,

    member: null,
    positionsMenu: null,

    allowProOverride: false,

    transferHeader: (0, _utils.loc)('Transfer Ownership'),
    transferMessage: (0, _utils.loc)('Are you sure you want to re-assign control of this company account to another person?'),

    init: function init() {
      this._super();
      this.loadPositions();
      this.getAdminMembers();
      if (this.get('features.allowSubscriptionOverride')) {
        var allowProOverride = _utils.LocalStorage.getProperty('x-allow-override-pro') === "true";
        if (!Ember.isNone(allowProOverride)) {
          this.set('allowProOverride', allowProOverride);
        }
      }
    },


    timeFormat: Ember.computed(function () {
      var isDecimal = parseInt(_utils.Time.getHoursFormat(), 10) === 20;
      return [Ember.Object.create({ key: 10, value: "00:00", _selected: !isDecimal }), Ember.Object.create({ key: 20, value: "0.00", _selected: isDecimal })];
    }),

    isSelf: Ember.computed('member.id', 'auth.member.id', function () {
      if (!Ember.isNone(this.get('member.id')) && this.get('auth.member.id')) {
        return this.get('auth.member.id') === this.get('member.id');
      }
      return false;
    }),

    isOwner: Ember.computed('member.id', 'auth.organization.ownedBy', function () {
      return this.get('member.id') === this.get('auth.organization.ownedBy');
    }),

    saveMember: function saveMember() {
      return this.get('member').save();
    },


    loadPositions: Ember.observer('auth.positions.@each.id', 'member.id', 'auth.authMemberPosition.id', function () {
      if (!Ember.isNone(this.get('member.positionId')) && !Ember.isEmpty(this.get('auth.positions'))) {

        var memberPosition = this.get('auth.positions').findBy('id', this.get('authmember.positionId'));

        if (!Ember.isNone(memberPosition) && !Ember.isNone(memberPosition.get('id'))) {
          this.set('member.position', memberPosition);
        }

        var authPosition = this.get('auth.authMemberPosition');
        if (!Ember.isNone(authPosition) && !Ember.isNone(authPosition.get('id'))) {
          var pos = this.get('auth.positions').filter(function (item) {
            return authPosition.get('level') <= item.get('level');
          });

          this.set('positionsMenu', pos);
        }
      }
    }),

    overridePro: Ember.observer('allowProOverride', function () {
      if (this.get('features.allowSubscriptionOverride')) {
        _utils.LocalStorage.setProperty('x-allow-override-pro', this.get('allowProOverride'));
        if (this.get('allowProOverride')) {
          this.get('features').enable('overrides/pro');
        } else {
          this.get('features').disable('overrides/pro');
        }
        this.get('toggle').getAllFlags();
      }
    }),

    getAdminMembers: function getAdminMembers() {
      var _this = this;

      var adminPosition = this.get('auth.positions').filter(function (pos) {
        if (pos.get('title').toLowerCase() === 'admin') {
          return pos;
        }
      });

      var adminMembers = [];

      this.get('store').findAll('member', { archived_on: null }).then(function (members) {

        members.sortBy('firstName').forEach(function (member) {
          if (member.get('positionId') === adminPosition[0].get('id') && !_this.get('access').isOwner(member.get('id'))) {
            if (!member.get('pendingEmail') && member.get('certifiedEmail')) {
              adminMembers.push(member);
            }
          }
        });
      });

      this.set('adminMembers', adminMembers);
    },


    hideTransferFooter: Ember.computed('adminMembers', function () {
      return this.get('adminMembers.length') < 1;
    }),

    transferOwnership: function transferOwnership() {
      var _this2 = this;

      var newOwnerId = this.get('selectedTransferMemberId');

      this.get('store').find('organization', this.get('auth.organization.id')).then(function (org) {
        org.set('ownedBy', newOwnerId);
        org.save().then(function () {
          _this2.setProperties({
            showConfirmTransferDialog: false,
            showTransferDialog: false
          });
        });
      });
    },


    actions: {
      openEditDialog: function openEditDialog() {
        this.set('showEditDialog', true);
      },
      cancelEditDetails: function cancelEditDetails() {
        this.get('member').rollbackAttributes();
        this.set('showEditDialog', false);
      },
      openPassDialog: function openPassDialog() {
        this.set('showPassDialog', true);
      },
      cancelResetPass: function cancelResetPass() {
        this.get('member').rollbackAttributes();
        this.set('showPassDialog', false);
      },
      openPinDialog: function openPinDialog() {
        this.set('showPinDialog', true);
      },
      cancelResetPin: function cancelResetPin() {
        this.get('member').rollbackAttributes();
        this.set('showPinDialog', false);
      },
      openPhotoDialog: function openPhotoDialog() {
        this.set('showPhotoDialog', true);
      },
      closePhotoDialog: function closePhotoDialog() {
        this.set('showPhotoDialog', false);
      },
      changeTimeFormat: function changeTimeFormat(item) {
        _utils.Time.setHoursFormat(item.get('key'));
      },
      navigateToSubscription: function navigateToSubscription() {
        return this.get('router').transitionTo('company-settings', { queryParams: { bc_tab: 'subscription' } });
      },
      openTransferDialog: function openTransferDialog() {
        this.set('showTransferDialog', true);
      },
      closeTransferDialog: function closeTransferDialog() {
        this.set('showTransferDialog', false);
      },
      saveTransferOwner: function saveTransferOwner(dialog) {
        this.transferOwnership();
      },
      openConfirmTransferDialog: function openConfirmTransferDialog() {
        this.set('showConfirmTransferDialog', true);
      },
      closeConfirmTransferDialog: function closeConfirmTransferDialog() {
        this.set('showConfirmTransferDialog', false);
      },
      setSelectedTransferMember: function setSelectedTransferMember(value) {
        this.set('selectedTransferMemberId', value);
      }
    }
  });
});