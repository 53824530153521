define("busy-app/utils/mod/time-entry/day", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = modTimeEntryDay;
  function modTimeEntryDay() {
    return true;
  }
});