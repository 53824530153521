define('busy-app/components/busy-map-leaflet-locations', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var _window = window,
	    L = _window.L,
	    MQ = _window.MQ;


	var kTileServiceProviders = {
		'osm': 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		'wikimedia': 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
		'carto-light': 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
		'carto-dark': 'http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
		'stamen-watercolor': 'http://{s}.tile.stamen.com/watercolor/{z}/{x}/{y}.png',
		'stamen-toner': 'http://{s}.tile.stamen.com/toner/{z}/{x}/{y}.png'
	};

	var kDefaultLocation = [37.10102990074617, -113.53877082086876];

	/**
  * `BusyMapLeafletLocations`
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['c-busy-map-leaflet c-busy-map-leaflet-locations'],

		_tileProvider: 'default',
		model: null,

		locations: Ember.computed.map('model', function (location) {
			return this.createLocationObject(location);
		}),

		bounds: Ember.computed('locations', function () {
			var locations = this.get('locations');

			var coords = locations.map(function (location) {
				if (!Ember.isNone(location) && !Ember.isEmpty(location)) {
					return [location.get('lat'), location.get('lng')];
				}
			}).compact();

			if (Ember.isNone(coords) || Ember.isEmpty(coords)) {
				coords.push(kDefaultLocation);
			}

			return L.latLngBounds(coords);
		}),

		hasBounds: Ember.computed.and('locations', 'bounds'),

		tileServiceUrl: Ember.computed('_tileProvider', function () {
			if (this.get('isTileDefault')) {
				return kTileServiceProviders['osm'];
			} else if (this.get('isTileHybrid')) {
				return this.getMapQuestTileServiceUrl('hybridLayer');
			}
		}),

		isTileDefault: Ember.computed('_tileProvider', function () {
			return this.get('_tileProvider') === 'default';
		}),

		isTileHybrid: Ember.computed('_tileProvider', function () {
			return this.get('_tileProvider') === 'satellite';
		}),

		createLocationObject: function createLocationObject(location) {
			if (Ember.isNone(location)) {
				return Ember.Object.create(null);
			}

			var obj = {
				image: location.get('member.imageThumbUrl'),
				name: location.get('member.fullName'),
				lat: location.get('latitude'),
				lng: location.get('longitude'),

				get coords() {
					return [this.lat, this.lng];
				},

				get timeStr() {
					return _utils.Time.date(this.locationTime).format('h:mm A');
				},

				get clockString() {
					return this.clockType + ' ' + this.timeStr;
				}
			};

			if (!Ember.isNone(location.get('startTimeEntry.id'))) {
				obj.locationTime = location.get('startTimeEntry.startTime');
				obj.locationType = 'startLocation';
				obj.clockType = (0, _utils.loc)('Clocked In @');
			} else if (!Ember.isNone(location.get('endTimeEntry.id'))) {
				obj.locationTime = location.get('endTimeEntry.endTime');
				obj.locationType = 'endLocation';
				obj.clockType = (0, _utils.loc)('Clocked Out @');
			} else if (!Ember.isNone(location.get('breakStart'))) {
				obj.locationTime = location.get('breakStart.startTime');
				obj.locationType = 'breakStartLocation';
				obj.clockType = (0, _utils.loc)('Break start @');
			} else if (!Ember.isNone(location.get('breakEnd'))) {
				obj.locationTime = location.get('breakEnd.endTime');
				obj.locationType = 'breakEndLocation';
				obj.clockType = (0, _utils.loc)('Break end @');
			}

			obj.icon = this.getIconForLocationType(obj.locationType);

			return Ember.Object.create(obj);
		},
		getIconForLocationType: function getIconForLocationType(locationType) {
			var props = {
				iconSize: [22, 32],
				iconAnchor: [10, 32],
				shadowUrl: '/assets/images/marker-shadow.png',
				shadowAnchor: [12, 42]
			};

			if (locationType === 'startLocation') {
				props.iconUrl = '/images/icons/map_icons_start.png';
				props.className = 'map-icon map-icon-start';
			} else if (locationType === 'endLocation') {
				props.iconUrl = '/images/icons/map_icons_end.png';
				props.className = 'map-icon map-icon-end';
			} else if (locationType === 'breakStartLocation') {
				props.iconUrl = '/images/icons/map_icons_start-break.png';
				props.className = 'map-icon map-icon-start-break';
			} else if (locationType === 'breakEndLocation') {
				props.iconUrl = '/images/icons/map_icons_end-break.png';
				props.className = 'map-icon map-icon-end-break';
			}

			return L.icon(props);
		},


		// onload action is triggered by leaflet
		onMapLoad: function onMapLoad(_mapElement) {
			this.setProperties({
				_mapElement: _mapElement,
				isMapReady: true
			});
		},
		getMapQuestTileServiceUrl: function getMapQuestTileServiceUrl(type) {
			// hybridLayer
			// mapLayer
			// satelliteLayer
			// darkLayer
			// lightLayer

			return MQ[type]()._url.replace('{ext}', 'png');
		},


		actions: {
			onLoad: function onLoad(e) {
				var _mapElement = e.target;

				this.onMapLoad(_mapElement);
			},
			setTileProvider: function setTileProvider(tileProvider) {
				this.set('_tileProvider', tileProvider);
			}
		}
	});
});