define('busy-app/routes/time-card/pay-period', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils'], function (exports, _authenticatedRouteMixin, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		features: Ember.inject.service('features'),
		signature: Ember.inject.service('signature'),
		store: Ember.inject.service('store'),
		payPeriod: Ember.inject.service('pay-period'),

		queryParams: {
			start: {
				refreshModel: true
			},
			end: {
				refreshModel: true
			},
			member_id: {
				refreshModel: true
			}
		},

		payPeriodDays: null,
		payPeriodSeconds: null,
		payPeriodCost: null,

		init: function init() {
			this._super.apply(this, arguments);

			// set payPeriodDays after init
			this.set('payPeriodDays', []);
		},


		model: function model(params) {
			var _this = this;

			var start = Number(params.start);
			var end = Number(params.end);
			var memberId = params.member_id;

			_utils.Assert.isNumber(start);
			_utils.Assert.isNumber(end);
			_utils.Assert.isUUID(memberId);

			// load payperiod data parallel to the main model data
			Ember.run.later(this, function () {
				return _this.setPayPeriodData(start, end, memberId);
			});

			return this.store.manager('time-entry-pto-day', start, end, memberId).then(function (entry) {
				// set time interval
				_this.setOpenEntryTimeInterval(entry);

				// return entry models
				return entry;
			});
		},

		/**
   * Sets the time interval for the open entry to update
   * the total seconds
   *
   * @public
   * @method setOpenEntryTimeInterval
   * @param models {array} array of time entry day objects
   * @param timer {number} the number of milliseconds before refreshing the total seconds
   */
		setOpenEntryTimeInterval: function setOpenEntryTimeInterval(models) {
			var timer = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 60000;

			models.forEach(function (day) {
				// check if day has open time in it.
				if (day.get('hasOpenTime')) {
					// loop through time entry range objects
					day.get('model').forEach(function (item) {
						// check if range object is an open entry an the time interval is not already set
						if (item.get('isOpenEntry') && item.get('model.timeInterval') !== timer) {
							item.get('model').setTimeInterval(timer);
						}
					});
				}
			});
		},


		/**
   * load pay period data for the start and end time.
   *
   */
		setPayPeriodData: function setPayPeriodData(start_time, end_time, member_id) {
			var _this2 = this;

			var controller = this.controllerFor('time-card/pay-period');
			var queryParams = { start_time: start_time, end_time: end_time, member_id: member_id };

			this.get('store').query('pay-period-member-seconds', queryParams).then(function (payPeriodSeconds) {
				var payPeriod = payPeriodSeconds.get('firstObject');
				if (Ember.isNone(payPeriod)) {
					payPeriod = _this2.get('payPeriod').getPayPeriod(start_time);
					payPeriod = _this2.get('store').createRecord('pay-period-member-seconds', {
						startTime: payPeriod.get('start'),
						endTime: payPeriod.get('end'),
						regularSeconds: 0,
						overtimeSeconds: 0,
						doubletimeSeconds: 0,
						memberId: member_id
					});
				}
				_this2.setPayPeriodMemberTimeDocument(payPeriod);
				controller.set('payPeriodSeconds', payPeriod);
			});

			this.get('store').query('pay-period-member-cost', queryParams).then(function (payPeriodCost) {
				controller.set('payPeriodCost', payPeriodCost.get('firstObject'));
			});
		},


		/**
   * load the member time document for this pay period
   *
   * @private
   */
		setPayPeriodMemberTimeDocument: function setPayPeriodMemberTimeDocument(payPeriod) {
			if (this.get('features.signatures') && this.get('signature.isTimecardSigningEnabled')) {
				_utils.Assert.funcNumArgs(arguments, 1, true);
				_utils.Assert.isObject(payPeriod);

				payPeriod.set('isLoadingSignatureState', true);

				return this.get('signature').getStateForPayPeriod(payPeriod).then(function (memberTimeDocument) {
					payPeriod.set('isLoadingSignatureState', false);
					payPeriod.set('memberTimeDocument', memberTimeDocument);

					return payPeriod;
				});
			}
		},


		/**
   * Mock api call for testing
   *
   * @private
   * @method getMockedPayPeriodDay
   * @param startOfDay {date} moment date object
   * @return {object}
   */
		getMockedPayPeriodDay: function getMockedPayPeriodDay(startOfDay) {
			_utils.Assert.isMoment(startOfDay);

			return Ember.Object.create({
				start: startOfDay.unix(),
				timeOffTypes: null,
				hasConflict: false,
				totalSeconds: 0
			});
		},


		actions: {
			reloadModel: function reloadModel() {
				this.refresh();
			}
		}
	});
});