define('busy-app/models/sign-up-event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    memberId: _emberData.default.attr('string'),
    medium: _emberData.default.attr('string'),
    campaign: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    term: _emberData.default.attr('string'),
    platform: _emberData.default.attr('string'),
    os: _emberData.default.attr('string'),
    osVersion: _emberData.default.attr('string'),
    appVersion: _emberData.default.attr('string'),
    device: _emberData.default.attr('string'),
    adClickDate: _emberData.default.attr('number'),
    endTimeTrusted: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('number')
  });
});