define('busy-app/controllers/project/detail/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		access: Ember.inject.service('access'),
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		organization: Ember.computed.alias('auth.organization'),
		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),

		canHaveSubProjects: Ember.computed('model.project.depth', function () {
			return !Ember.isNone(this.get('model.project.depth')) && this.get('model.project.depth') <= 6;
		}),

		breadcrumbs: Ember.computed('model.project.{title,parentProject.fullTitle}', function () {
			var fullTitle = this.get('model.project.fullTitle');
			var fullTitleDelimiter = this.get('model.project.fullTitleDelimiter');
			var projectsArray = fullTitle.split(fullTitleDelimiter);
			var projects = [];

			var count = projectsArray.length - 1;
			projectsArray.forEach(function (project) {
				if (count < 1) {
					project = null;
				} else if (count === 1) {
					project = Ember.Object.create({ key: count, value: project });
				} else {
					project = Ember.Object.create({ key: count, value: project, slash: '/' });
				}
				count = count - 1;
				projects.pushObject(project);
			});

			return projects;
		}),

		actions: {
			clickBreadcrumb: function clickBreadcrumb(item) {
				window.history.go('-' + item);
			},
			changeSubProject: function changeSubProject(projectId) {
				this.transitionToRoute('project.detail', projectId);
			},
			reportTransition: function reportTransition(report) {
				if (report === 'employee') {
					this.transitionToRoute('project.detail.reports.employees');
				} else if (report === 'subproject') {
					this.transitionToRoute('project.detail.reports.subprojects');
				} else if (report === 'budget-versus-actual') {
					this.transitionToRoute('project.detail.reports.budget-versus-actual');
				} else if (report === 'time-cards') {
					this.transitionToRoute('report.time-entries', {
						queryParams: {
							project: this.get('model.project.id')
						}
					});
				}
			}
		}
	});
});