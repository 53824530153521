define('busy-app/routes/scheduling', ['exports', '@busy-web/utils', 'ember-simple-auth/mixins/authenticated-route-mixin', 'busy-app/utils/organization/overtime-period'], function (exports, _utils, _authenticatedRouteMixin, _overtimePeriod) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		payPeriod: Ember.inject.service('pay-period'),
		auth: Ember.inject.service('auth'),
		subscription: Ember.inject.service('subscription'),
		features: Ember.inject.service('features'),
		interop: Ember.inject.service("interop"),

		queryParams: {
			start: { replace: true },
			end: { replace: true },
			selected: { replace: true },
			custom: { replace: true },
			isMobile: { replace: true },
			group: { replace: true }
		},

		allowGroupFilter: Ember.computed.not('auth.useGroupPermissionScoping'),

		beforeModel: function beforeModel(transition) {
			if (Ember.get(this, 'features.schedulingInterop')) {
				transition.abort();
				return this.redirectToReact();
			} else {
				return true;
			}
		},
		redirectToReact: function redirectToReact() {
			var interop = Ember.get(this, "interop");

			var reactAppPath = '/scheduling';
			var reactAppUrl = interop.getReactAppUrl(reactAppPath, {}, false);
			var windowHandle = interop.openUrl(reactAppUrl, null, {
				newWindow: false,
				replace: true
			});

			return windowHandle;
		},
		model: function model(params) {
			var _this = this;

			var start = params.start,
			    end = params.end,
			    selected = params.selected,
			    custom = params.custom,
			    isMobile = params.isMobile,
			    currentDay = params.currentDay,
			    group = params.group;

			var controller = this.controllerFor('scheduling');
			controller.set('selected', selected);

			// start can be null or 0
			if (Ember.isNone(start) || !start) {
				start = _utils.Time.date().startOf('day').unix();
			}

			var overtimePeriod = (0, _overtimePeriod.getOvertimePeriodByDate)(this.get('auth.organizationOvertimePeriod'), start);
			var weekStart = (0, _overtimePeriod.weekStartForPeriod)(overtimePeriod);

			if (!end) {
				if (selected === 'daily') {
					// set overtime period week
					end = _utils.Time.date(start).endOf('day').unix();
				} else {
					var _createOvertimeBoundW = (0, _overtimePeriod.createOvertimeBoundWeekForPeriod)(overtimePeriod, start);
					// set overtime period week


					start = _createOvertimeBoundW.start;
					end = _createOvertimeBoundW.end;
				}
			}

			return Ember.RSVP.hash({
				orgPayPeriod: Ember.RSVP.resolve(this.get('auth.organizationPayPeriod')),
				memberGroups: this.getMemberGroups()
			}).then(function (_ref) {
				var memberGroups = _ref.memberGroups;

				var currentPeriod = _this.get('payPeriod').getPayPeriod(start);
				return { start: start, end: end, custom: custom, selected: selected, weekStart: weekStart, currentPeriod: currentPeriod, isMobile: isMobile, currentDay: currentDay, group: group, memberGroups: memberGroups };
			});
		},
		getMemberGroups: function getMemberGroups() {
			var params = { _asc: ['group_name'], page_size: 10000 };
			return this.store.query('member-group', params);
		},


		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			},
			selectDay: function selectDay(day) {
				var currentDay = Ember.get(day, 'start');
				Ember.set(this.controller, 'currentDay', currentDay);
				this.replaceWith('scheduling', { queryParams: { currentDay: currentDay } });
			}
		}
	});
});