define('busy-app/models/stripe-customer', ['exports', 'ember-data', '@busy-web/data'], function (exports, _emberData, _data) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo,
	    hasMany = _emberData.default.hasMany;
	exports.default = Model.extend(_data.RPCModelMixin, {
		_clientName: 'busy-stripe',
		_methodName: 'get-stripe-customer',

		accountBalance: attr('number'),
		created: attr('number'),
		delinquent: attr('boolean'),
		description: attr('string'),
		email: attr('string'),

		cards: hasMany('stripe-customer-card'),
		subscription: belongsTo('stripe-customer-subscription'),
		discount: belongsTo('stripe-customer-discount')
	});
});