define('busy-app/services/auth', ['exports', 'ember-data', '@busy-web/utils', 'busy-app/utils/debug'], function (exports, _emberData, _utils, _debug) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		/**
   * @private
   * @property store
   * @type Services.Store
   */
		store: Ember.inject.service('store'),

		/**
   * @private
   * @property session
   * @type Services.Session
   */
		session: Ember.inject.service('session'),
		features: Ember.inject.service('features'),
		dataStore: Ember.inject.service('data-store'),
		interop: Ember.inject.service('interop'),

		sessionId: _utils.UUID.generate(),

		memberId: Ember.computed('session.isAuthenticated', function () {
			if (this.get('session.isAuthenticated')) {
				var auth = this.get('dataStore').find('auth-member') || {};
				return Ember.get(auth, 'id');
			} else {
				return null;
			}
		}),

		token: Ember.computed('session.isAuthenticated', function () {
			if (this.get('session.isAuthenticated')) {
				var auth = this.get('dataStore').find('auth-member') || {};
				return Ember.get(auth, 'token');
			} else {
				return null;
			}
		}),

		/**
   * Computed property to get the current auth member
   *
   * @public
   * @property member
   * @type {DS.Model}
   */
		member: Ember.computed('memberId', 'session.isAuthenticated', function () {
			var _this = this;

			var id = this.get('memberId');
			if (!Ember.isEmpty(id) && this.get('session.isAuthenticated')) {
				return _emberData.default.PromiseObject.create({
					promise: this.get('store').findRecord('member', id).then(function (model) {
						if (!Ember.isNone(model.get('archivedOn')) || !Ember.isNone(model.get('deletedOn'))) {
							_this.invalidateSession();
						}
						return model;
					})
				});
			}
		}),

		/**
   * Computed property to get the current auth organization
   *
   * @public
   * @property organization
   * @type {DS.Model}
   */
		organization: Ember.computed('session.isAuthenticated', function () {
			var _this2 = this;

			if (this.get('session.isAuthenticated')) {
				return _emberData.default.PromiseObject.create({
					promise: this.get('store').queryRecord('organization', { page_size: 1 }).then(function (org) {
						_this2.addToAuthMap(org);
						return org;
					})
				});
			}
		}),

		/**
   * Computed property to get the current auth organizationInfo
   *
   * @public
   * @property organizationInfo
   * @type {DS.Model}
   */
		organizationInfo: Ember.computed('organization.id', function () {
			var id = this.get('organization.id');
			if (!Ember.isEmpty(id)) {
				return _emberData.default.PromiseObject.create({
					promise: this.get('store').queryRecord('organization-info', { organizationId: id })
				});
			}
		}),

		/**
   * Computed property to get the current auth member
   *
   * @public
   * @property member
   * @type {DS.Model}
   */
		organizationPayPeriod: Ember.computed('session.isAuthenticated', function () {
			var _this3 = this;

			if ((0, _debug.disable_test_runner)()) return [];

			if (this.get('session.isAuthenticated')) {

				var query = { _desc: ['start_date'], deleted_on: null };

				return _emberData.default.PromiseArray.create({
					promise: this.get('store').findAll('organization-pay-period', query).then(function (periods) {
						// create default period if no periods were found.
						if (Ember.isEmpty(periods)) {
							var defaultPeriod = _this3.get('store').createRecord('organization-pay-period', {
								payPeriodType: 10,
								startDate: 345600,
								dayOne: 1
							});

							return [defaultPeriod];
						}
						return periods;
					})
				});
			}
		}),

		/**
   * Computed property to get the current auth member
   *
   * @public
   * @property member
   * @type {DS.Model}
   */
		organizationOvertimePeriod: Ember.computed('session.isAuthenticated', function () {
			if ((0, _debug.disable_test_runner)()) return [];

			if (this.get('session.isAuthenticated')) {
				var query = { _desc: ['start_time'], deleted_on: null };

				return _emberData.default.PromiseArray.create({
					promise: this.get('store').findAll('organization-overtime-period', query)
				});
			}
		}),

		isOwner: Ember.computed('member.id', 'organization.ownedBy', function () {
			return !Ember.isNone(this.get('member.id')) && this.get('member.id') === this.get('organization.ownedBy');
		}),

		owner: Ember.computed('organization.ownedBy', 'member.id', 'isOwner', function () {
			if (this.get('isOwner')) {
				return this.get('member');
			} else {
				if (!Ember.isNone(this.get('organization.ownedBy'))) {
					return _emberData.default.PromiseObject.create({
						promise: this.get('store').findRecord('member', this.get('organization.ownedBy'))
					});
				}
			}
		}),

		/**
   * Computed property for the organizations choice of currency symbol to use.
   *
   * @public
   * @property currencySymbol
   * @type string
   */
		currencySymbol: Ember.computed('organizationInfo.currencyCode', function () {
			if (!Ember.isNone(this.get('organizationInfo.currencyCode'))) {
				var cur = _utils.Currency.code(this.get('organizationInfo.currencyCode'));
				return cur.get('symbol');
			} else {
				return null;
			}
		}),

		/**
   * Computed property to get the the positions for this organization
   *
   * @public
   * @property positions
   * @type {DS.Model}
   */
		positions: Ember.computed('session.isAuthenticated', function () {
			if (this.get('session.isAuthenticated')) {
				return _emberData.default.PromiseArray.create({
					promise: this.get('store').findAll('position', { _asc: ['level'] })
				});
			}
		}),

		/**
   * Computed property to get the the position for the auth member
   *
   * @public
   * @property authMemberPosition
   * @type {DS.Model}
   */
		authMemberPosition: Ember.computed('positions.@each.id', 'member.positionId', function () {
			if (!Ember.isNone(this.get('member.positionId')) && !Ember.isEmpty(this.get('positions'))) {
				return this.get('positions').findBy('id', this.get('member.positionId'));
			}

			return null;
		}),

		addToAuthMap: function addToAuthMap(organization) {
			this.get('dataStore').addAuthAccount(organization);
		},


		memberCountRefresh: 0,

		/**
   * Computed property to get the current member count for this organization
   *
   * @public
   * @property memberCount
   * @type {DS.Model}
   */
		memberMeta: Ember.computed('memberCountRefresh', 'session.isAuthenticated', function () {
			if (this.get('session.isAuthenticated')) {
				return _emberData.default.PromiseObject.create({
					promise: this.get('store').query('member', { page_size: 1, archived_on: '_-NULL-_' }).then(function (models) {
						return models.get('meta');
					})
				});
			}
		}),

		memberCount: Ember.computed('memberMeta', 'memberMeta.totalRows', function () {
			var promisedData = Ember.RSVP.resolve(this.get('memberMeta'));

			return promisedData.then(function (data) {
				var memberMeta = Ember.Object.create(data);
				return memberMeta.getWithDefault('totalRows', 1);
			});
		}),

		invalidateSession: function invalidateSession() {
			var _this4 = this;

			var switching = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

			return this.get('session').invalidate('authenticator:basic', {}).then(function () {
				if (Ember.get(_this4, 'features.onboardingInterop')) {
					return _this4.redirectToLogin(switching);
				} else {
					return true;
				}
			});
		},
		redirectToLogin: function redirectToLogin() {
			var switching = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

			var interop = Ember.get(this, 'interop');
			var reactUrl = switching ? interop.getReactAppUrl('/login', { switch: true }, false) : interop.getReactAppUrl('/logout', {}, false);

			var windowHandle = interop.openUrl(reactUrl, null, { newWindow: false });

			return windowHandle;
		},


		/**
   * Computed property to indicate whether the current user's permissions should be restricted to their own group
   * 	WARNING: This attribute relies on promises being resolved
   *
   * @public
   * @property useGroupPermissionScoping
   * @type {Boolean}
   */
		useGroupPermissionScoping: Ember.computed.and('features.groupPermissions', 'authMemberPosition.scopeByGroup')
	});
});