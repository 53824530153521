define('busy-app/components/model-title/equipment', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		state: Ember.inject.service(),

		/**
   * id is the only property that
   * should be passed in. All other
   * properties are set by the component.
   *
   * this is a id for the equipment model
   *
   * @property id
   * @type {string} model UUID
   */
		id: null,

		/*** props made available in yield hash ***/

		title: '',
		subTitle: '',
		condensedTitle: '',
		isUnassigned: false,
		hasInfo: false,
		imageUrl: null,
		equipment: null,

		/*** end props ***/

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();

			this.getEquipment();
			this.setTitle();
		},
		getEquipment: function getEquipment() {
			if (!Ember.isEmpty(Ember.get(this, 'id'))) {
				var equipment = loadEquipment(Ember.get(this, 'state'), Ember.get(this, 'id'));
				Ember.set(this, 'equipment', equipment);
				Ember.set(this, 'imageUrl', getImage(equipment));
				Ember.set(this, 'hasInfo', true);
			} else {
				Ember.set(this, 'equipment', null);
				Ember.set(this, 'imageUrl', null);
				Ember.set(this, 'hasInfo', false);
			}
		},
		setTitle: function setTitle() {
			var equipment = Ember.get(this, 'equipment');

			var title = (0, _utils.loc)('Unassigned');
			var subTitle = '';
			var condensedTitle = (0, _utils.loc)('Unassigned');
			var isUnassigned = true;

			if (!Ember.isNone(equipment)) {
				var equipmentMake = fixUnknownStrings(Ember.get(equipment, 'equipmentModel.equipmentMake.title'));
				var equipmentModel = fixUnknownStrings(Ember.get(equipment, 'equipmentModel.title'));

				title = fixUnknownStrings(Ember.get(equipment, 'equipmentName'));
				subTitle = (equipmentMake + ' ' + equipmentModel).trim();
				condensedTitle = title + ' ' + subTitle;

				isUnassigned = false;
			}

			this.setProperties({ title: title, subTitle: subTitle, condensedTitle: condensedTitle, isUnassigned: isUnassigned });
		}
	});


	var fixUnknownStrings = function fixUnknownStrings(str) {
		return !str || str.toLowerCase() === 'unknown' ? '' : str;
	};

	function loadEquipment(state, id) {
		var equipment = state.getState('equipment', id);

		if (!Ember.isNone(equipment) && !Ember.isEmpty(equipment.equipmentModelId)) {
			var equipmentModel = state.getState('equipment-model', equipment.equipmentModelId);

			if (!Ember.isNone(equipmentModel)) {
				if (!Ember.isEmpty(equipmentModel.equipmentMakeId)) {
					equipmentModel.equipmentMake = state.getState('equipment-make', equipmentModel.equipmentMakeId);
				}

				if (!Ember.isEmpty(equipmentModel.equipmentCategoryId)) {
					equipmentModel.equipmentCategory = state.getState('equipment-category', equipmentModel.equipmentCategoryId);
				}
			}

			equipment.equipmentModel = equipmentModel;
		}
		return equipment;
	}

	var getImage = function getImage(equipment) {
		if (!Ember.isNone(equipment)) {
			if (Ember.isNone(Ember.get(equipment, 'imageThumbUrl')) && Ember.isNone(Ember.get(equipment, 'equipmentModel.imageThumbUrl')) && Ember.isNone(Ember.get(equipment, 'equipmentModel.equipmentMake.imageThumbUrl')) && Ember.isNone(Ember.get(equipment, 'equipmentModel.equipmentCategory.imageThumbUrl'))) {
				return '/images/80x80.png';
			} else if (Ember.isNone(Ember.get(equipment, 'imageThumbUrl')) && Ember.isNone(Ember.get(equipment, 'equipmentModel.imageThumbUrl')) && Ember.isNone(Ember.get(equipment, 'equipmentModel.equipmentCategory.imageThumbUrl'))) {
				return Ember.get(equipment, 'equipmentModel.equipmentMake.imageThumbUrl');
			} else if (Ember.isNone(Ember.get(equipment, 'imageThumbUrl')) && Ember.isNone(Ember.get(equipment, 'equipmentModel.imageThumbUrl'))) {
				return Ember.get(equipment, 'equipmentModel.equipmentCategory.imageThumbUrl');
			} else if (Ember.isNone(Ember.get(equipment, 'imageThumbUrl'))) {
				return Ember.get(equipment, 'equipmentModel.imageThumbUrl');
			} else {
				return Ember.get(equipment, 'imageThumbUrl');
			}
		}
		return null;
	};
});