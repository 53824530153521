define('busy-app/components/time/time-entry/view', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c--time--time-entry--view'],

		auth: Ember.inject.service(),
		features: Ember.inject.service(),
		router: Ember.inject.service(),
		store: Ember.inject.service(),

		// attributes
		id: null,
		onSaved: null,
		onCancel: null,

		showEditOption: true,
		showEntryHistoryOption: true,
		showHoursSection: true,
		showJobDetailsSection: true,
		showLocationSection: true,
		useLocationFullView: true,

		// properties
		model: null,
		headerTitle: (0, _utils.loc)('Entry Details'),

		// state
		showEditDialog: false,
		entryHistoryDialog: false,

		init: function init() {
			var _this = this;

			this._super();

			if (!Ember.isNone(Ember.get(this, 'id'))) {
				this.getModel().then(function (model) {
					return Ember.set(_this, 'model', model);
				});
			}
		},
		getModel: function getModel() {
			var getter = Ember.get(this, 'store').getter().findRecord('time-entry', Ember.get(this, 'id')).join('member', 'time-entry', 'member_id', { deleted_on: '_-NULL-_' }).join('project', 'time-entry', 'project_id', { deleted_on: '_-NULL-_' }).join('cost-code', 'time-entry', 'cost_code_id', { deleted_on: '_-NULL-_' }).join('equipment', 'time-entry', 'equipment_id', { deleted_on: '_-NULL-_' }).join('member-location', 'time-entry', 'start_location_id', { deleted_on: '_-NULL-_' }, 'startLocation').join('member-location', 'time-entry', 'end_location_id', { deleted_on: '_-NULL-_' }, 'endLocation').joinAll('time-entry-break', 'time-entry', 'id', { _asc: ['start_time'], deleted_on: '_-NULL-_' }).join('member-location', 'time-entry-break', 'start_location_id', { deleted_on: '_-NULL-_' }, 'startLocation').join('member-location', 'time-entry-break', 'end_location_id', { deleted_on: '_-NULL-_' }, 'endLocation');

			return getter.fetch().then(function (data) {
				data.timeEntry.get('timeEntryBreaks').forEach(function (item) {
					Ember.set(item, 'timeEntry', data.timeEntry);
				});
				return data.timeEntry;
			});
		},


		isCostCodesEnabled: Ember.computed.and('features.costCodes', 'auth.organization.trackCostCode'),
		isEquipmentEnabled: Ember.computed.and('features.equipment', 'auth.organization.trackEquipment'),

		projectNameDisplay: Ember.computed('model.{projectId,project}', function () {
			if (Ember.isNone(Ember.get(this, 'model.projectId'))) {
				return (0, _utils.loc)('None');
			} else {
				return Ember.get(this, 'model.project') ? Ember.get(this, 'model.project.fullTitle') : '-';
			}
		}),

		costCodeDisplay: Ember.computed('model.{costCodeId,costCode}', function () {
			if (Ember.isNone(Ember.get(this, 'model.costCodeId'))) {
				return (0, _utils.loc)('None');
			} else {
				return Ember.get(this, 'model.costCode') ? Ember.get(this, 'model.costCode.costCodeLabel') : '-';
			}
		}),

		equipmentDisplay: Ember.computed('model.{equipmentId,equipment}', function () {
			if (Ember.isNone(Ember.get(this, 'model.equipmentId'))) {
				return (0, _utils.loc)('None');
			} else {
				return Ember.get(this, 'model.equipment') ? [Ember.get(this, 'model.equipment.equipmentName'), Ember.get(this, 'model.equipment.equipmentModel.equipmentMake.title'), Ember.get(this, 'model.equipment.equipmentModel.title')].join(' ') : '-';
			}
		}),

		safeSet: function safeSet(key, val) {
			if (!Ember.get(this, 'isDestroyed')) {
				Ember.set(this, key, val);
			}
		},


		actions: {
			editTimeEntry: function editTimeEntry() {
				this.safeSet('showEditDialog', true);
			},
			onSaved: function onSaved() {
				var _this2 = this;

				this.safeSet('model', null);
				this.sendAction('onSaved');

				Ember.run.next(this, function () {
					_this2.getModel();
					_this2.safeSet('showEditDialog', false);
				});
			},
			closeEditDialog: function closeEditDialog() {
				if (Ember.get(this, 'model.hasDirtyAttributes')) {
					Ember.get(this, 'model').rollbackAttributes();
				}

				this.safeSet('showEditDialog', false);
			},
			itemDeleted: function itemDeleted() {
				var _this3 = this;

				this.safeSet('model', null);
				this.sendAction('onSaved');

				Ember.run.next(this, function () {
					_this3.safeSet('showEditDialog', false);
					_this3.sendAction('onCancel'); // closes this view dialog
				});
			},
			viewEntryHistory: function viewEntryHistory() {
				this.safeSet('entryHistoryDialog', true);
			},
			closeEntryHistory: function closeEntryHistory() {
				this.safeSet('entryHistoryDialog', false);
			},
			viewFullMap: function viewFullMap() {
				Ember.get(this, 'router').transitionTo('employees.employee-locations', {
					queryParams: {
						id: Ember.get(this, 'model.id'),
						member_id: Ember.get(this, 'model.member.id')
					}
				});
			}
		}
	});
});