define('busy-app/components/dialogs/instructions-header', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-dialogs-instructions-header'],
		tagName: 'header',

		logo: null,
		title: null
	});
});