define('busy-app/components/busy-map-leaflet-time-entry-locations', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var _window = window,
	    L = _window.L,
	    MQ = _window.MQ;

	var DEVIATION = 0.000009;

	/***/
	var kTileServiceProviders = {
		'osm': 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		'wikimedia': 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png',
		'carto-light': 'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
		'carto-dark': 'http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
		'stamen-watercolor': 'http://{s}.tile.stamen.com/watercolor/{z}/{x}/{y}.png',
		'stamen-toner': 'http://{s}.tile.stamen.com/toner/{z}/{x}/{y}.png'
	};

	var kDefaultLocation = [37.10102990074617, -113.53877082086876];

	exports.default = Ember.Component.extend({
		classNames: ['c-busy-map-leaflet c-busy-map-leaflet-time-entry-locations'],

		timeEntry: null,
		filter: null,
		_tileProvider: 'default',

		locations: Ember.computed('timeEntry', function () {
			return this.createLocationObjects(this.get('timeEntry'));
		}),

		bounds: Ember.computed('locations', function () {
			var locations = this.get('locations');

			var coords = locations.map(function (location) {
				if (!Ember.isNone(location) && !Ember.isEmpty(location)) {
					return [location.get('lat'), location.get('lng')];
				}
			}).compact();

			if (Ember.isNone(coords) || Ember.isEmpty(coords)) {
				coords.push(kDefaultLocation);
			}

			return L.latLngBounds(coords);
		}),

		hasBounds: Ember.computed.and('timeEntry', 'bounds'),

		tileServiceUrl: Ember.computed('_tileProvider', function () {
			if (this.get('isTileDefault')) {
				return kTileServiceProviders['osm'];
			} else if (this.get('isTileHybrid')) {
				return this.getMapQuestTileServiceUrl('hybridLayer');
			}
		}),

		isTileDefault: Ember.computed('_tileProvider', function () {
			return this.get('_tileProvider') === 'default';
		}),

		isTileHybrid: Ember.computed('_tileProvider', function () {
			return this.get('_tileProvider') === 'satellite';
		}),

		init: function init() {
			this._super.apply(this, arguments);

			// this.set('tileService', kTileServiceProviders[this.get('_defaultTileService')]);
		},


		// onload action is triggered by leaflet
		onMapLoad: function onMapLoad(_mapElement) {
			this.setProperties({
				_mapElement: _mapElement,
				isMapReady: true
			});
		},
		getMapQuestTileServiceUrl: function getMapQuestTileServiceUrl(type) {
			// hybridLayer
			// mapLayer
			// satelliteLayer
			// darkLayer
			// lightLayer

			return MQ[type]()._url.replace('{ext}', 'png');
		},
		createLocationObjects: function createLocationObjects(timeEntry) {
			var _this = this;

			var markers = [];
			var lats = [];
			var longs = [];
			var filter = this.get('filter');

			if (!Ember.isNone(timeEntry.get('startLocation')) && timeEntry.get('isStartLocationValid')) {
				if (Ember.isEmpty(filter) || filter.indexOf(timeEntry.get('startLocation.id')) !== -1) {
					var timeStr = _utils.Time.date(timeEntry.get('startTime')).format('h:mm A');
					markers.pushObject(this.createMarkerObj(timeEntry.get('startLocation'), 'startLocation', (0, _utils.loc)('Clocked In @'), timeStr, lats, longs));
				}
			}

			if (!Ember.isNone(timeEntry.get('endLocation')) && timeEntry.get('isEndLocationValid')) {
				if (Ember.isEmpty(filter) || filter.indexOf(timeEntry.get('endLocation.id')) !== -1) {
					var _timeStr = _utils.Time.date(timeEntry.get('endTime')).format('h:mm A');
					markers.pushObject(this.createMarkerObj(timeEntry.get('endLocation'), 'endLocation', (0, _utils.loc)('Clocked Out @'), _timeStr, lats, longs));
				}
			}

			timeEntry.get('timeEntryBreaks').forEach(function (model) {
				if (!Ember.isNone(model.get('startLocation')) && model.get('isStartLocationValid')) {
					if (Ember.isEmpty(filter) || filter.indexOf(model.get('startLocation.id')) !== -1) {
						var _timeStr2 = _utils.Time.date(model.get('startTime')).format('h:mm A');
						markers.pushObject(_this.createMarkerObj(model.get('startLocation'), 'breakStartLocation', (0, _utils.loc)('Break start @'), _timeStr2, lats, longs));
					}
				}

				if (!Ember.isNone(model.get('endLocation')) && model.get('isEndLocationValid')) {
					if (Ember.isEmpty(filter) || filter.indexOf(model.get('endLocation.id')) !== -1) {
						var _timeStr3 = _utils.Time.date(model.get('endTime')).format('h:mm A');
						markers.pushObject(_this.createMarkerObj(model.get('endLocation'), 'breakEndLocation', (0, _utils.loc)('Break end @'), _timeStr3, lats, longs));
					}
				}
			});

			return markers;
		},
		createMarkerObj: function createMarkerObj(location, locationType, clockType, timeStr, lats, longs) {
			var lat = this.adjustLatitude(lats, location.get('latitude'));
			var lng = this.adjustLongitude(longs, location.get('longitude'));

			var marker = Ember.Object.create({
				image: this.get('timeEntry.member.imageThumbUrl'),
				name: this.get('timeEntry.member.fullName'),
				lat: lat,
				lng: lng,
				coords: [lat, lng],
				locationType: locationType,
				clockString: clockType + timeStr,
				icon: this.getIconForLocationType(locationType)
			});

			return marker;
		},
		adjustLatitude: function adjustLatitude(lats, lat) {
			if (!matchCoord(lats, DEVIATION, lat)) {
				lats.push(lat);
			} else {
				lat = this.adjustLatitude(lats, lat + DEVIATION);
			}
			return lat;
		},
		adjustLongitude: function adjustLongitude(longs, lng) {
			if (!matchCoord(longs, DEVIATION, lng)) {
				longs.push(lng);
			} else {
				lng = this.adjustLongitude(longs, lng + DEVIATION);
			}
			return lng;
		},
		getIconForLocationType: function getIconForLocationType(locationType) {
			var props = {
				iconSize: [22, 32],
				iconAnchor: [10, 32],
				shadowUrl: '/assets/images/marker-shadow.png',
				shadowAnchor: [12, 42]
			};

			if (locationType === 'startLocation') {
				props.iconUrl = '/images/icons/map_icons_start.png';
				props.className = 'map-icon map-icon-start';
			} else if (locationType === 'endLocation') {
				props.iconUrl = '/images/icons/map_icons_end.png';
				props.className = 'map-icon map-icon-end';
			} else if (locationType === 'breakStartLocation') {
				props.iconUrl = '/images/icons/map_icons_start-break.png';
				props.className = 'map-icon map-icon-start-break';
			} else if (locationType === 'breakEndLocation') {
				props.iconUrl = '/images/icons/map_icons_end-break.png';
				props.className = 'map-icon map-icon-end-break';
			}

			return L.icon(props);
		},


		actions: {
			onLoad: function onLoad(e) {
				var _mapElement = e.target;

				this.onMapLoad(_mapElement);
			},
			setTileProvider: function setTileProvider(tileProvider) {
				this.set('_tileProvider', tileProvider);
			}
		}
	});


	function matchCoord(coordArray, deviation, coord) {
		return coordArray.find(function (c) {
			return c - deviation < coord && c + deviation > coord;
		});
	}
});