define('busy-app/models/client-time-entry-log', ['exports', 'ember-data', '@busy-web/utils', 'busy-app/utils/time-entry'], function (exports, _emberData, _utils, _timeEntry) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Models
  *
  */
	var kLocationValidTime = 150;

	/**
  * `Model/ClientTimeEntryLog`
  *
  * @class ClientTimeEntryLog
  * @namespace Models
  * @extends DS.Model
  *
  * @property updaterMemberId {string}
  * @property originalTimeEntryId {string}
  * @property mergedTimeEntryId {string}
  * @property timeEntryId {string}
  */
	exports.default = _emberData.default.Model.extend({
		auth: Ember.inject.service('auth'),

		updaterMemberId: _emberData.default.attr('string'),
		originalTimeEntryId: _emberData.default.attr('string'),
		mergedTimeEntryId: _emberData.default.attr('string'),
		timeEntryId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		endTime: _emberData.default.attr('number'),
		offset: _emberData.default.attr('number'),
		metaOffset: _emberData.default.attr('number'),
		daylightSavingTime: _emberData.default.attr('boolean'),
		metaDaylightSavingTime: _emberData.default.attr('boolean'),
		description: _emberData.default.attr('string'),
		memberId: _emberData.default.attr('string'),
		projectId: _emberData.default.attr('string'),
		costCodeId: _emberData.default.attr('string'),
		equipmentId: _emberData.default.attr('string'),
		actionType: _emberData.default.attr('number'),
		timeEntryCreatedOn: _emberData.default.attr('number'),
		timeEntryDeletedOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		deviceType: _emberData.default.attr('string'),
		startLocationId: _emberData.default.attr('string'),
		endLocationId: _emberData.default.attr('string'),
		startDeviceTime: _emberData.default.attr('number'),
		endDeviceTime: _emberData.default.attr('number'),
		startTimeTrusted: _emberData.default.attr('number'),
		endTimeTrusted: _emberData.default.attr('number'),

		member: _emberData.default.belongsTo('member'),
		updaterMember: _emberData.default.belongsTo('member', { referenceKey: 'updaterMemberId', foreignKey: 'id' }),
		project: _emberData.default.belongsTo('project'),
		costCode: _emberData.default.belongsTo('cost-code'),
		equipment: _emberData.default.belongsTo('equipment'),

		breakLog: _emberData.default.belongsTo('client-time-entry-break-log'),
		addBreaks: _emberData.default.hasMany('client-time-entry-break-log'),
		editBreaks: _emberData.default.hasMany('client-time-entry-break-log'),
		prevEditBreaks: _emberData.default.hasMany('client-time-entry-break-log'),
		timeEntryBreaks: _emberData.default.hasMany('client-time-entry-break-log'),

		startLocation: _emberData.default.belongsTo('member-location', { referenceKey: 'startLocationId', foreignKey: 'id' }),
		endLocation: _emberData.default.belongsTo('member-location', { referenceKey: 'endLocationId', foreignKey: 'id' }),

		startTimeString: Ember.computed('startTime', function () {
			return _timeEntry.default.startTimeString(this, 'h:mm A');
		}),

		endTimeString: Ember.computed('endTime', function () {
			return _timeEntry.default.endTimeString(this, 'h:mm A');
		}),

		createdOnString: Ember.computed('createdOn', function () {
			// created on is in utc time so convert to local time.
			var time = this.get('createdOn') + _utils.Time.timezone(this.get('createdOn'));
			return _utils.Time.timeFormat(time, 'h:mm A MM/DD/YY');
		}),

		actionTypeString: Ember.computed('actionType', 'breakLog.actionTypeString', function () {
			var modelType = this.get('actionType');
			var actionString = '';
			if (modelType === 10) {
				actionString = (0, _utils.loc)('Clock in');
			} else if (modelType === 20) {
				actionString = (0, _utils.loc)('Clock out');
			} else if (modelType === 30) {
				actionString = (0, _utils.loc)('Clock in at');
			} else if (modelType === 40) {
				actionString = (0, _utils.loc)('Clock out at');
			} else if (modelType === 50) {
				actionString = (0, _utils.loc)('Manual entry');
			} else if (modelType === 60) {
				actionString = (0, _utils.loc)('Edit');
			} else if (modelType === 70) {
				if (!Ember.isNone(this.get('breakLog.actionTypeString'))) {
					actionString = this.get('breakLog.actionTypeString');
				} else {
					actionString = (0, _utils.loc)('Edit No Changes Made');
				}
			} else if (modelType === 80) {
				actionString = (0, _utils.loc)('Conflict resolution');
			} else if (modelType === 90) {
				actionString = (0, _utils.loc)('Deleted');
			} else if (modelType === 100) {
				actionString = (0, _utils.loc)('Deleted by conflict resolution');
			}
			return actionString;
		}),

		validLocationTime: Ember.computed('auth.organization.{timeRounding,timeRoundingInterval}', function () {
			var validLocTime = kLocationValidTime;
			if (this.get('auth.organization.timeRounding')) {
				validLocTime = kLocationValidTime + this.get('auth.organization.timeRoundingInterval');
			}
			return validLocTime;
		}),

		isStartLocationValid: Ember.computed('startLocation.createdOn', 'startLocationId', 'startTime', 'offset', 'validLocationTime', function () {
			var validLocTime = this.get('validLocationTime');
			var isValid = false;
			if (!Ember.isNone(this.get('startLocation.createdOn'))) {
				var locCreatedOn = this.get('startLocation.createdOn') + this.get('offset');
				if (locCreatedOn >= this.get('startTime') - validLocTime && locCreatedOn <= this.get('startTime') + validLocTime) {
					isValid = true;
				}
			}
			return isValid;
		}),

		isEndLocationValid: Ember.computed('endLocation.createdOn', 'endLocationId', 'endTime', 'offset', 'validLocationTime', function () {
			var validLocTime = this.get('validLocationTime');
			var isValid = false;
			if (!Ember.isNone(this.get('endLocation.createdOn')) && !Ember.isNone(this.get('endTime'))) {
				var locCreatedOn = this.get('endLocation.createdOn') + this.get('offset');
				if (locCreatedOn >= this.get('endTime') - validLocTime && locCreatedOn <= this.get('endTime') + validLocTime) {
					isValid = true;
				}
			}
			return isValid;
		}),

		/**
   * checks to see if the time-entry-log is a clock in/ clocked in at
   *
   */
		isClockIn: Ember.computed('actionType', 'startTime', function () {
			return this.get('actionType') === 10 || this.get('actionType') === 30 ? true : false;
		}),

		/**
   * checks to see if the time-entry-log is a clock out/ clocked out at
   *
   */
		isClockOut: Ember.computed('actionType', 'endTime', function () {
			return this.get('actionType') === 20 || this.get('actionType') === 40 ? true : false;
		}),

		/**
   * checks to see if the time-entry-log is manually done
   *
   */
		isManualEntry: Ember.computed('actionType', function () {
			return this.get('actionType') === 50 ? true : false;
		}),

		/**
   * checks to see if the time-entry-log has been edited
   *
   */
		isEditEntry: Ember.computed('actionType', function () {
			return this.get('actionType') === 60 || this.get('actionType') === 70 && Ember.isNone(this.get('breakLog.id')) ? true : false;
		}),

		isConflictResolution: Ember.computed('actionType', function () {
			return this.get('actionType') === 80;
		}),

		isBreakStart: Ember.computed('breakLog.isBreakStart', function () {
			return this.get('breakLog.isBreakStart') ? true : false;
		}),

		isBreakEnd: Ember.computed('breakLog.isBreakEnd', function () {
			return this.get('breakLog.isBreakEnd') ? true : false;
		}),

		startEndCheck: Ember.computed('actionType', function () {
			return this.get('actionType') !== 60 && this.get('actionType') !== 70 ? true : false;
		}),

		/**
   * string for the time and date of the clock in/ clock in at
   *
   */
		startString: Ember.computed('startTime', function () {
			return _timeEntry.default.startTimeString(this, 'h:mm A M/D/YY');
		}),

		/**
   * string for the time and date of the clock out/ clock out at
   *
   */
		endString: Ember.computed('endTime', function () {
			return _timeEntry.default.endTimeString(this, 'h:mm A M/D/YY');
		})
	});
});