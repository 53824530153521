define('busy-app/initializers/application', ['exports', 'moment', 'busy-app/utils/app-global', 'busy-app/utils/settings'], function (exports, _moment, _appGlobal, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		name: 'application',
		after: 'export-application-global',

		initialize: function initialize(app) {
			if (_settings.default.get('debug_mode')) {
				Ember.run.backburner.DEBUG = true;
			}

			window.BusyApp.isMobile = false;
			if (window.orientation !== undefined) {
				window.BusyApp.isMobile = true;
			}

			// set moment locale
			_moment.default.locale(window.navigator.language);

			window.BusyApp._application = _appGlobal.default.create(app.__container__.ownerInjection());
		}
	};
});