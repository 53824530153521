define('busy-app/models/schedule-reminder-link', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		scheduleReminderId: _emberData.default.attr('string'),
		memberId: _emberData.default.attr('string'),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),

		member: Ember.computed('memberId', function () {
			return _emberData.default.PromiseObject.create({
				promise: this.store.query('member', { id: this.get('memberId') }).then(function (data) {
					return data.get('firstObject');
				})
			});
		}),

		userImage: Ember.computed('member.imageThumbUrl', function () {
			var image = this.get('member.imageThumbUrl');
			if (Ember.isNone(image)) {
				image = '/images/imageplaceholder.png';
			}
			return Ember.String.htmlSafe('background-image: url(' + image + ');');
		})
	});
});