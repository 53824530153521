define('busy-app/utils/models/collections/member-conflicts', ['exports', 'busy-app/utils/models/collections/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		applyData: function applyData(model) {
			this._super(model);
		},


		dayString: Ember.computed('model.day', function () {
			var time = '';
			if (!Ember.isNone(this.get('model.day'))) {
				time = _utils.Time.date(this.get('model.day')).format('MMMM Do, YYYY');
			}
			return time;
		}),

		totalConflicts: Ember.computed('model.members.@each.totalConflicts', function () {
			var total = 0;
			if (!Ember.isEmpty(this.get('model.members'))) {
				this.get('model.members').forEach(function (item) {
					total += item.get('totalConflicts');
				});
			}
			return total;
		})
	});
});