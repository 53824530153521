define('busy-app/models/organization-subscription-status', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		organizationId: _emberData.default.attr('string'),
		subscriptionId: _emberData.default.attr('string'),
		customerId: _emberData.default.attr('string'),
		status: _emberData.default.attr('string'),
		periodDay: _emberData.default.attr('number'),
		cardType: _emberData.default.attr('string'),
		cardLastFour: _emberData.default.attr('string'),
		hasTrialed: _emberData.default.attr('boolean'),
		trialEndedAt: _emberData.default.attr('number'),
		nextAssessmentAt: _emberData.default.attr('number'),
		productHandle: _emberData.default.attr('string'),
		couponCode: _emberData.default.attr('string'),
		referralCode: _emberData.default.attr('string'),
		annualSubscriptionDate: _emberData.default.attr('number'),
		annualSubscriptionCount: _emberData.default.attr('number'),
		annualSubscriptionPendingInvoiceId: _emberData.default.attr('number'),
		subscriptionUpdatedAt: _emberData.default.attr('number'),
		userCanceled: _emberData.default.attr('boolean'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		// subscribedThroughProduct is set to true during the subscription.upgradeToPro RPC call
		// this property is to differentiate between subscriptions created in app versus those created directly in Chargify
		subscribedThroughProduct: _emberData.default.attr('boolean', { defaultValue: false }),

		hasCard: Ember.computed('cardType', 'cardLastFour', function () {
			return !Ember.isNone(this.get('cardType')) && !Ember.isNone(this.get('cardLastFour'));
		}),

		cardFormatted: Ember.computed('hasCard', 'cardType', 'cardLastFour', function () {
			if (!this.get('hasCard')) {
				return '';
			} else if (this.get('cardType') === 'amex') {
				return this.get('cardType') + ' **** ****** ' + this.get('cardLastFour');
			} else {
				return this.get('cardType') + ' **** **** **** ' + this.get('cardLastFour');
			}
		}),

		planType: Ember.computed('productHandle', function () {
			return (/pro|enterprise/.test(this.get('productHandle')) ? 'pro' : 'free'
			);
		}),

		planTypeFormatted: Ember.computed('isProAccount', 'isFreeTrial', 'isAnnualPlan', function () {
			var plan = (0, _utils.loc)('Free');
			if (this.get('isProAccount')) {
				if (this.get('isFreeTrial')) {
					plan = (0, _utils.loc)('Pro Free Trial');
					// } else if (this.get('isAnnualPlan')) {
					// 	plan = loc('Pro (Billed Yearly)');
				} else {
					plan = (0, _utils.loc)('Pro');
				}
			}
			return plan;
		}),

		isFreeAccount: Ember.computed('planType', function () {
			return this.get('planType') === 'free';
		}),

		isProAccount: Ember.computed('planType', function () {
			return this.get('planType') === 'pro';
		}),

		// enterprise accounts pro accounts with an additional flag
		isEnterpriseAccount: Ember.computed('planType', function () {
			return this.get('isProAccount') && /enterprise/.test(this.get('productHandle'));
		}),

		isFreeTrial: Ember.computed('isProAccount', 'trialEndedAt', 'status', function () {
			if (this.get('isProAccount')) {
				if (this.get('status') === 'trialing' && this.get('trialEndedAt') > _utils.Time.date().unix()) {
					return true;
				}
			}
			return false;
		}),

		// account is in free trial, but payment information has been provided
		isFreeTrialUpgraded: Ember.computed('isFreeTrial', 'hasCard', function () {
			return this.get('isFreeTrial') && this.get('hasCard');
		}),

		isTrialEnded: Ember.computed('hasTrialed', 'isFreeAccount', function () {
			return this.get('hasTrialed') && this.get('isFreeAccount');
		}),

		isTrialAvailable: Ember.computed('hasTrialed', 'isFreeAccount', function () {
			return !this.get('hasTrialed') && this.get('isFreeAccount');
		}),

		isPastDue: Ember.computed('isFreeAccount', 'status', function () {
			return !this.get('isFreeTrial') && this.get('status') === 'past_due';
		}),

		isLockout: Ember.computed('isFreeAccount', 'status', function () {
			return !this.get('isFreeTrial') && this.get('status') === 'canceled';
		}),

		expiresFromNow: Ember.computed('trialEndedAt', function () {
			return _utils.Time.date(this.get('trialEndedAt')).local().fromNow();
		}),

		isAnnualPlan: Ember.computed.bool('annualSubscriptionCount'), // if annualSubscriptionCount has a value, the organization is using an annual plan
		isMonthlyPlan: Ember.computed.not('isAnnualPlan'),
		isInvoice: Ember.computed.bool('annualSubscriptionPendingInvoiceId'),

		// FIXME: This is wrong and does not work. Testing that updatedOn is newer than annualSubscriptionDate is not reliable
		// if `updatedOn` is newer than `annualSubscriptionDate`, the subscription has been modified since the subscription was started
		hasAnnualSubscriptionChanged: Ember.computed('updatedOn', 'annualSubscriptionDate', function () {
			var updatedOn = this.get('updatedOn');
			var annSubDate = this.get('annualSubscriptionDate');
			return !Ember.isEmpty(annSubDate) && annSubDate < updatedOn;
		}),

		/*
   * WARNING: Magic numbers to calculate payment due
   * 	calculations assume a trial period of 1 month
   *
   */

		// when the first payment DID happen
		firstPaymentDate: Ember.computed('isAnnualPlan', 'isInvoice', 'trialEndedAt', 'annualSubscriptionDate', 'nextAssessmentAt', 'isFreeTrial', function () {
			if (this.get('isAnnualPlan')) {
				if (this.get('isInvoice')) {
					return _utils.Time.date(this.get('trialEndedAt')).add(1, 'months').unix();
				} else {
					return this.get('annualSubscriptionDate');
				}
			} else if (this.get('isFreeTrial')) {
				return _utils.Time.date(this.get('trialEndedAt')).add(1, 'months').unix();
			}

			return this.get('nextAssessmentAt');
		}),

		// when the next payment WILL happen
		nextPaymentDueDate: Ember.computed('isMonthlyPlan', 'firstPaymentDate', function () {
			if (this.get('isMonthlyPlan')) {
				return this.get('firstPaymentDate');
			} else {
				return _utils.Time.date(this.get('firstPaymentDate')).add(1, 'years').unix();
			}
		})
	});
});