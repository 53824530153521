define('busy-app/services/signature', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		access: Ember.inject.service('access'),
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		payPeriods: Ember.inject.service('pay-period'),
		store: Ember.inject.service('store'),

		member: Ember.computed.alias('auth.member.content'),
		organization: Ember.computed.alias('auth.organization.content'),

		isTimecardSigningEnabled: Ember.computed.and('features.signatures', 'organization.isSignaturesEnabled'),
		isTimecardSupervisorSigningEnabled: Ember.computed.and('isTimecardSigningEnabled', 'organization.authoritativeSignature'),

		signatureMode: Ember.computed('features.signatures-supervisor', 'isTimecardSupervisorSigningEnabled', function () {
			return this.get('features.signatures-supervisor') && this.get('isTimecardSupervisorSigningEnabled') ? 'supervisor' : 'base';
		}),
		isSignatureModeSupervisor: Ember.computed.equal('signatureMode', 'supervisor'),
		isSignatureModeBase: Ember.computed.equal('signatureMode', 'base'),

		isTimecardMine: Ember.computed('auth.memberId', 'member.id', function () {
			return this.get('auth.memberId') === this.get('member.id');
		}),

		isSupervisor: Ember.computed('member', function () {
			var supervisorAction = 'manageTimeEntries';
			var member = this.get('member');

			if (Ember.isNone(member) || Ember.isNone(member.get('id'))) {
				return false;
			}

			return this.get('access').canTakeActionFor(supervisorAction, member);
		}),

		getNeedsTimecardSignature: function getNeedsTimecardSignature(memberId) {
			var _this = this;

			var previousPayPeriodBounds = this.getPreviousPayPeriodBounds();
			var isPayPeriodSignable = this.isPayPeriodSignable(previousPayPeriodBounds);

			// previous pay period is signabled, and the current user is eligible to sign
			if (isPayPeriodSignable && (this.get('isTimecardMine') || !this.get('isSupervisor'))) {
				var queryParams = {
					member_id: memberId,
					start_time: previousPayPeriodBounds.get('start'),
					end_time: previousPayPeriodBounds.get('end'),
					canceled: '_-NULL-_',
					edited: '_-NULL-_'
				};

				return this.get('store').query('member-time-document', queryParams).then(function (docs) {

					// if there is no valid MemberTimeDocument for this period, a signature must be needed
					if (Ember.isEmpty(docs)) {
						return true;
					}

					var hasValidSignature = docs.any(function (doc) {
						if (_this.get('isSignatureModeSupervisor') && _this.get('isSupervisor')) {
							return doc.get('hasBothValidSignatures');
						} else {
							return doc.get('hasValidSelfSignature');
						}
					});

					return !hasValidSignature;
				});
			} else {
				return Ember.RSVP.resolve(false);
			}
		},


		/**
   * Pay Periods
   *
   */

		/**
   * Is signing enabled, and is the provided payperiod eligible for signing
   *
   * @public
   * @method isPayPeriodSignable
   * @param {Models.PayPeriodMemberSeconds} payPeriod
   * @return {boolean}
   */
		isPayPeriodSignable: function isPayPeriodSignable(payPeriod) {
			_utils.Assert.funcNumArgs(arguments, 2);
			_utils.Assert.isObject(payPeriod);

			var signatureDate = this.get('organization.signatureDate');
			var payPeriodStartTime = payPeriod.get('start');
			var payPeriodEndTime = payPeriod.get('end');
			var startOfToday = _utils.Time.date().startOf('day').unix();

			return this.get('isTimecardSigningEnabled') && payPeriodStartTime >= signatureDate && payPeriodEndTime < startOfToday;
		},
		getCurrentPayPeriodBounds: function getCurrentPayPeriodBounds() {
			var todayStart = _utils.Time.date().startOf('day').unix();
			var currentPayPeriodBounds = this.get('payPeriods').getPayPeriod(todayStart);

			return currentPayPeriodBounds;
		},
		getPreviousPayPeriodBounds: function getPreviousPayPeriodBounds() {
			var currentPayPeriodBounds = this.getCurrentPayPeriodBounds();
			var previousPayPeriodBounds = this.get('payPeriods').getPayPeriod(currentPayPeriodBounds.get('start') - 1);

			return previousPayPeriodBounds;
		},


		/**
   *
   *
    * @public
   * @method getStateForPayPeriod
   * @param {Models.PayPeriodMemberSeconds} payPeriod
   * @param {Models.MemberTimeDocument[]} [memberTimeDocuments]
   * @return {object}
   */
		getStateForPayPeriod: function getStateForPayPeriod(payPeriod, memberTimeDocuments) {
			_utils.Assert.funcNumArgs(arguments, 2);
			_utils.Assert.isObject(payPeriod);

			// don't bother looking up signature state if this pay period isn't signable
			if (Ember.isNone(payPeriod) || !payPeriod.get('isSignable')) {
				return Ember.RSVP.resolve(null);
			} else {
				return this.getByMemberPayPeriod(payPeriod, memberTimeDocuments);
			}
		},
		getStateForPreviousPayPeriod: function getStateForPreviousPayPeriod(memberId) {
			var _this2 = this;

			var previousPayPeriodBounds = this.getPreviousPayPeriodBounds();

			if (this.isPayPeriodSignable(previousPayPeriodBounds)) {
				var queryParams = {
					member_id: memberId,
					start_time: previousPayPeriodBounds.get('start'),
					end_time: previousPayPeriodBounds.get('end')
				};

				return this.getPayPeriod(queryParams).then(function (ppms) {
					if (Ember.isNone(ppms)) {
						return ppms;
					} else {
						return _this2.getStateForPayPeriod(ppms.get('firstObject'));
					}
				});
			} else {
				return Ember.RSVP.resolve();
			}
		},
		getPayPeriod: function getPayPeriod(queryParams) {
			return this.get('store').query('pay-period-member-seconds', queryParams);
		},


		/**
   *
   *
   * @public
   * @method getStateForMultiplePayPeriods
   * @param {Models.PayPeriodMemberSeconds} payPeriod
   * @param {Models.MemberTimeDocument[]} [memberTimeDocuments]
   * @return {Model.MemberTimeDocument[]}
   */
		getStateForMultiplePayPeriods: function getStateForMultiplePayPeriods(payPeriods, memberTimeDocuments) {
			var _this3 = this;

			_utils.Assert.isArray(payPeriods);

			if (!Ember.isNone(memberTimeDocuments)) {
				_utils.Assert.isArray(memberTimeDocuments);
			}

			return Ember.RSVP.all(payPeriods.map(function (payPeriod) {
				return _this3.getStateForPayPeriod(payPeriod, memberTimeDocuments);
			}));
		},


		/**
   * Get the current `memberTimeDocument` for the provided `payPeriodMemberSeconds` object.
   * Optionally accepts a `memberTimeDocuments` array to filter against as a cache instead of performing an API lookup
   *
   * @public
   * @method getByMemberPayPeriod
   * @param {Models.PayPeriodMemberSeconds} payPeriod
   * @param {Models.MemberTimeDocument[]} [memberTimeDocuments]
   * @return {Model.MemberTimeDocument}
   */
		getByMemberPayPeriod: function getByMemberPayPeriod(payPeriod, memberTimeDocuments) {
			var _this4 = this;

			_utils.Assert.funcNumArgs(arguments, 2);
			_utils.Assert.isObject(payPeriod);

			if (!Ember.isNone(memberTimeDocuments) && !Array.isArray(memberTimeDocuments)) {
				memberTimeDocuments = memberTimeDocuments.toArray();
			}

			var filterQuery = {
				startTime: payPeriod.get('startTime'),
				endTime: payPeriod.get('endTime'),
				memberId: payPeriod.get('memberId')
			};
			var dbQuery = {
				start_time: payPeriod.get('startTime'),
				end_time: payPeriod.get('endTime'),
				member_id: payPeriod.get('memberId'),
				_desc: ['submitted_on']
			};

			var promise = void 0;
			if (Array.isArray(memberTimeDocuments)) {
				promise = this.filterByQuery(memberTimeDocuments, filterQuery);
			} else {
				promise = this.queryMemberTimeDocuments(dbQuery);
			}

			return promise.then(function (docs) {
				var cleanedDocs = _this4.cleanupMemberDocResults(docs);

				if (Ember.isEmpty(cleanedDocs)) {
					return null;
				}

				// if the first document is valid, use it, if not, create a new temp document
				var currentDoc = cleanedDocs.get('firstObject.isValid') ? cleanedDocs.shift() : _this4.get('store').createRecord('member-time-document', {
					memberId: payPeriod.get('memberId'),
					startTime: payPeriod.get('startTime'),
					endTime: payPeriod.get('endTime')
				});

				var hasPreviousSelfSignature = cleanedDocs.any(function (doc) {
					return doc.get('isSelfSigned');
				});
				var hasPreviousAuthoritativeSignature = cleanedDocs.any(function (doc) {
					return doc.get('isAuthoritativeSigned');
				});

				currentDoc.set('hasPreviousSelfSignature', hasPreviousSelfSignature);
				currentDoc.set('hasPreviousAuthoritativeSignature', hasPreviousAuthoritativeSignature);

				return currentDoc;
			});
		},


		/**
   * Remove empty and `canceled` records, sort the result set by `createdOn`
   *
   * @public
   * @method cleanupMemberDocResults
   * @param {Models.MemberTimeDocument[]} memberTimeDocuments
   * @return {Model.MemberTimeDocument[]}
   */
		cleanupMemberDocResults: function cleanupMemberDocResults(docs) {
			return docs.toArray() // ensure it's an array
			.compact() // no empty results
			.filter(function (doc) {
				return !doc.get('isCanceled');
			}) // no cancelled document requests
			.sortBy('createdOn').reverse(); // newest documents first
		},


		/**
   * Exludes `canceled` documents by default
   *
   * @public
   * @method queryMemberTimeDocuments
   * @param {params} options
   * @async
   * @return {Object}
   */
		queryMemberTimeDocuments: function queryMemberTimeDocuments() {
			var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(params);

			if (this.get('features.signatures')) {
				var defaultParams = {
					canceled: '_-NULL-_',
					_desc: ['submitted_on'],
					page_size: 999999
				};

				var queryParams = Ember.merge(defaultParams, params);
				return this.get('store').query('member-time-document', queryParams);
			} else {
				return Ember.RSVP.resolve(Ember.A());
			}
		},


		/**
   * Create a `selfSignuture` object
   *
   * @public
   * @method createSelfSignature
   * @param {string} signatureSvg
   * @chainable
   * @return {Model.SelfSignature}
   */
		createMemberTimeDocument: function createMemberTimeDocument(startTime, endTime, memberId) {
			return this.get('store').createRecord('memberTimeDocument', {
				// id: UUID.generate(),
				memberId: memberId,
				startTime: startTime,
				endTime: endTime
			}).save();
		},


		/**
   * Create a `selfSignuture` object
   *
   * @public
   * @method createSelfSignature
   * @param {string} signatureSvg
   * @chainable
   * @return {Model.SelfSignature}
   */
		createSelfSignature: function createSelfSignature(memberTimeDocument, signatureSvg) {
			return this.createSignature('selfSignature', memberTimeDocument, signatureSvg);
		},


		/**
   * Create a signture object of the specified type
   *
   * @public
   * @method createSignature
   * @param {string} signatureType
   * @param {string} signatureSvg
   * @chainable
   * @return {Model}
   */
		createSignature: function createSignature(signatureType, memberTimeDocument, signatureSvg) {
			var createdOnOffset = _utils.Time.timezone();
			var createdOnDST = _utils.Time.isDST();

			return this.get('store').createRecord(signatureType, {
				id: _utils.UUID.generate(),
				memberId: memberTimeDocument.get('memberId'),
				proxyMemberId: this.get('member.id'),
				signature: signatureSvg,

				createdOnOffset: createdOnOffset,
				createdOnDST: createdOnDST
			});
		},


		/**
   * Save `selfSignature`, attach to `memberTimeDocument` and save object, update `memberLock`
   *
   * @public
   * @method attachSignature
   * @param {Models.MemberTimeDocument} inMemberTimeDocument
   * @param {Models.SelfSignature} inSelfSignature
   * @async
   * @chainable
   * @return {object}
   */
		attachSignatureAndSave: function attachSignatureAndSave(memberTimeDocument, selfSignature) {
			var _this5 = this;

			return selfSignature.save().then(function (newSelfSignature) {

				memberTimeDocument.set('selfSignature', newSelfSignature);
				memberTimeDocument.set('selfSignatureId', newSelfSignature.get('id'));

				return _this5.saveMemberTimeDocument(memberTimeDocument);
			});
		},
		attachSupervisorSignatureAndSave: function attachSupervisorSignatureAndSave(memberTimeDocument, authoritativeSignature) {
			var _this6 = this;

			return authoritativeSignature.save().then(function (newAuthoritativeSignature) {

				memberTimeDocument.set('authoritativeSignature', newAuthoritativeSignature);
				memberTimeDocument.set('authoritativeSignatureId', newAuthoritativeSignature.get('id'));

				return _this6.saveMemberTimeDocument(memberTimeDocument);
			});
		},
		saveMemberTimeDocument: function saveMemberTimeDocument(memberTimeDocument) {
			var _this7 = this;

			return memberTimeDocument.save().then(function (newMemberTimeDocument) {
				return _this7.updateLockOnSignature(newMemberTimeDocument);
			});
		},


		/**
   * Update the memberLock to the end of the pay period.
   * Returns the memberTimeDocument not the memberLock object
   *
   * @public
   * @method updateLockOnSignature
   * @param {Models.MemberTimeDocument} memberTimeDocument
   * @async
   * @chainable
   * @return {EmberPromise}
   */
		updateLockOnSignature: function updateLockOnSignature(memberTimeDocument) {
			var _this8 = this;

			var memberId = memberTimeDocument.get('memberId');
			var payPeriodEnd = memberTimeDocument.get('endTime');

			// if `lockOnSelfSignature` is not enabled, short circuit
			if (!this.get('organization.lockOnSelfSignature')) {
				return Ember.RSVP.resolve(memberTimeDocument);
			}

			return this.get('store').queryRecord('member-lock', { member_id: memberId }).then(function (memberLock) {
				// if there is a lock, and it's newer than the end of the pay period, leave it alone
				// short circuit the method
				if (!Ember.isNone(memberLock) && memberLock.get('effectiveDate') >= payPeriodEnd) {
					return Ember.RSVP.resolve(memberTimeDocument);
				}

				if (Ember.isNone(memberLock)) {
					// if there isn't a lock, create a new object
					memberLock = _this8.get('store').createRecord('member-lock', {
						memberId: memberId,
						updaterMemberId: memberId,
						effectiveDate: payPeriodEnd
					});
				} else {
					// update lock date to the end of the pay period
					memberLock.set('effectiveDate', payPeriodEnd);
				}

				// return the memberTimeDocument not the memberLock object
				return memberLock.save().then(function () {
					return memberTimeDocument;
				});
			});
		},


		/**
   * Used by "breadcrumb" buttons
   * 	controllers/time-card/pay-period
   * 	components/time/pay-period/list
   *
   * @public
   * @method getSignatureState
   * @param {Boolean} isTimecardMine
   * @param {Boolean} isSupervisor
   * @param {Object} payPeriodSeconds
   * @param {Object} features
   * @param {Object} signature
   * @param {Object} memberTimeDocument
   * @return string
   */
		getSignatureState: function getSignatureState(isTimecardMine, isSupervisor, payPeriodSeconds, features, signature, memberTimeDocument) {
			var supervisorMode = isSupervisor && features.get('signatures-supervisor') && signature.get('isTimecardSupervisorSigningEnabled');

			// Logger.info(this, 'feature enabled', features.get('signatures'));
			// Logger.info(this, 'setting enabled', signature.get('isTimecardSigningEnabled'));
			// Logger.info(this, 'signable', payPeriodSeconds.get('isSignable'));
			// Logger.info(this, 'needs resign', memberTimeDocument.get('needsEitherSignatureResign'));
			// Logger.info(this, 'hasPreviousSelfSignature', memberTimeDocument.get('hasPreviousSelfSignature'));
			// Logger.info(this, 'hasPreviousAuthoritativeSignature', memberTimeDocument.get('hasPreviousAuthoritativeSignature'));

			// signature feature disabled
			if (!features.get('signatures') || !signature.get('isTimecardSigningEnabled')) {
				return null;
			}

			// not eligible for signing
			else if (Ember.isNone(payPeriodSeconds) || !payPeriodSeconds.get('isSignable')) {
					return null;
				}

				// no rights to sign this pay period
				else if (!isTimecardMine && !isSupervisor) {
						return null;
					}

					// supervisor signature feature not enabled, or the user is not a supervisor
					// non-supervisors don't need to know about the status of the supervisor signature
					else if (!supervisorMode) {
							if (Ember.isNone(memberTimeDocument) || Ember.isNone(memberTimeDocument.get('hasValidSelfSignature'))) {
								return 'Sign';
							} else if (memberTimeDocument.get('hasValidSelfSignature')) {
								return 'Signed';
							} else if (memberTimeDocument.get('needsSelfSignatureResign')) {
								return 'Re-Sign';
							} else {
								return 'Sign';
							}
						}

						// features.signatures-supervisor is enabled AND the user is a supervisor
						else if (supervisorMode) {
								if (Ember.isNone(memberTimeDocument) || Ember.isNone(memberTimeDocument.get('hasBothValidSignatures'))) {
									return 'Sign';
								} else if (memberTimeDocument.get('hasBothValidSignatures')) {
									return 'Signed';
								} else if (memberTimeDocument.get('needsBothSignatureResign')) {
									return 'Re-Sign';
								} else {
									return 'Sign';
								}
							}

			_logger.default.error(this, 'getSignatureState', 'no valid state could be determined', arguments);

			return null;
		},


		// different function signature that can be cleaner to use in some instances
		// turn a hash of arguments into a list of arguments
		getSignatureStateProxy: function getSignatureStateProxy(_ref) {
			var isTimecardMine = _ref.isTimecardMine,
			    isSupervisor = _ref.isSupervisor,
			    payPeriodSeconds = _ref.payPeriodSeconds,
			    features = _ref.features,
			    signature = _ref.signature,
			    memberTimeDocument = _ref.memberTimeDocument;

			return this.getSignatureState(isTimecardMine, isSupervisor, payPeriodSeconds, features, signature, memberTimeDocument);
		},


		/**
   * apply a hash of filters to the supplied recordset
   *
   * @public
   * @method filterByQuery
   * @param {Array} original - the recordset to filter
   * @param {Object} filters - hash of filters to apply
   * @return {Array}
   */
		filterByQuery: function filterByQuery(original, filters) {
			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isArray(original);
			_utils.Assert.isObject(filters);

			var filtered = Object.keys(filters).reduce(function (acc, key) {
				return acc.filterBy(key, filters[key]);
			}, original);

			return Ember.RSVP.resolve(filtered);
		},


		/**
   *	development mockups
   *
   */

		// dumpMTD(tag, mtd) {
		// 	if (Array.isArray(mtd)) {
		// 		mtd.forEach(d => this.dumpMTD(tag, d));
		// 		return;
		// 	}
		//
		// 	if (!isNone(mtd) && !isEmpty(mtd)) {
		// 		Logger.info(
		// 			tag,
		// 			Time.date(mtd.get('createdOn')).format('L LTS'),
		// 			mtd.getProperties(
		// 				'memberId',
		// 				// 'selfSignatureId',
		// 				'isSelfSigned',
		// 				// 'isEdited',
		// 				'hasValidSelfSignature',
		// 				'needsSelfSignatureResign',
		// 				'hasPreviousSelfSignature',
		// 			),
		// 		);
		// 	}
		// },

		mockApiCall: function mockApiCall() {
			var result = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
			var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;

			return new Ember.RSVP.Promise(function (resolve) {
				return setTimeout(function () {
					return resolve(result);
				}, delay);
			}).then(function (results) {
				_logger.default.info('Signature', 'mockApiCall', delay, { results: results });
				return results;
			});
		},
		mockQueryMemberTimeDocument: function mockQueryMemberTimeDocument(params, length) {
			var _this9 = this;

			var startTime = params.startTime,
			    endTime = params.endTime,
			    memberId = params.memberId;


			return Ember.RSVP.all(Array.from({ length: length }).map(function () {
				var hasDoc = 0; //!!Math.round(Math.random() * 3);

				return hasDoc ? _this9.mockApiCall(_this9.mockMemberTimeDocument(startTime, endTime, memberId)) : _this9.mockApiCall(_this9.createMemberTimeDocument(startTime, endTime, memberId));
			}));
		},
		mockMemberTimeDocument: function mockMemberTimeDocument(startTime, endTime, memberId) {
			var now = _utils.Time.date();

			var hasSignature = 1; //!!(Math.round(Math.random() * 3));
			var isEdited = 0; //!!(hasSignature && !Math.round(Math.random() * 4));
			var isCanceled = 0; //!!(!Math.round(Math.random() * 4));
			var createdOn = now.clone().add(-Math.round(Math.random() * 10000), 'seconds');

			var memberTimeDocument = this.createMemberTimeDocument(startTime, endTime, memberId);

			memberTimeDocument.setProperties({
				selfSignatureId: hasSignature ? _utils.UUID.generate() : null,
				// authoritativeSignatureId: null,
				edited: isEdited ? now.unix() : null,
				canceled: isCanceled ? now.unix() : null,
				createdOn: createdOn.unix()
			});

			return memberTimeDocument;
		}
	});
});