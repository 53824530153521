define('busy-app/components/company/integrations/grid/grid-item', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		/**
   * @private
   * @property auth
   * @type Services.Auth
   */
		auth: Ember.inject.service(),
		features: Ember.inject.service('features'),

		/**
   * @public
   * @property classNames
   * @type {string[]}
   * @default ['grid-item']
   */
		classNames: ['grid-item'],
		isBeta: false,

		/**
   * Label identifying the name of the organization-integration
   *
  	 * @public
   * @property integrationName
   * @type String
   * @default null
   * @example
   *	procore
   */
		integrationName: null,

		/**
   * Ember route to follow when this element is clicked
   *
  	 * @public
   * @property dashboardRoute
   * @type String
   * @default null
   * @example
   *	company-settings.procore
   */
		dashboardRoute: null,

		/**
   * URL path to the company logo image. Should begin with '/'
   *
  	 * @public
   * @property logo
   * @type String
   * @default null
   * @example
   *	'/images/integration-logos/integration_logos-02.png',
   */
		logo: null,

		/**
   * Feature flag for this integration
   *
  	 * @public
   * @property featureFlag
   * @type String
   * @default null
   * @example
   *	procore
   */
		featureFlag: null,

		/**
   * Short categorization of the type of error
   *
  	 * @public
   * @property errorType
   * @type String
   * @default null
   */
		errorType: null,

		/**
   * Description of the error
   *
  	 * @public
   * @property errorMessage
   * @type String
   * @default null
   */
		errorMessage: null,

		/**
   * Is the organization-integration connected
   *
  	 * @public
   * @property isConnected
   * @type Boolean
   * @default false
   */
		isConnected: false,

		/**
   * Is the an import currenctly in progress
   *
   * @public
   * @property isInProgress
   * @type Boolean
   * @default false
   */
		isInProgress: false,

		/**
   * Is the the data for this component currently loading
   *
  	 * @public
   * @property isLoading
   * @type Boolean
   * @default false
   */
		isLoading: false,

		/**
   * Should the upgrade dialog be visible
   *
  	 * @public
   * @property showUpgradeDialog
   * @type Boolean
   * @default false
   */
		showUpgradeDialog: false,

		isEnabled: false,
		notFeatureEnabled: Ember.computed('isEnabled', function () {
			return !this.get('isEnabled');
		})
	});
});