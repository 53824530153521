define('busy-app/initializers/ember-feature-flags', ['exports', 'busy-app/config/environment', 'busy-app/services/features'], function (exports, _environment, _features) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  var INJECTION_FACTORIES_DEPRECATION_MESSAGE = '[ember-feature-flags] The next major version of ember-feature-flags will no longer inject the service automatically. Instead, you should explicitly inject it into your Route, Controller or Component with `Ember.inject.service`.';

  function initialize() {
    var application = arguments[1] || arguments[0];
    var serviceName = _environment.default.featureFlagsService || 'features';
    var serviceLookupName = 'service:' + serviceName;

    application.register(serviceLookupName, _features.default);
    application.inject('route', serviceName, serviceLookupName);
    application.inject('controller', serviceName, serviceLookupName);
    application.inject('component', serviceName, serviceLookupName);
    application.inject(serviceLookupName, 'application', 'application:main');

    Ember.deprecate(INJECTION_FACTORIES_DEPRECATION_MESSAGE, false, {
      id: 'ember-feature-flags.deprecate-injection-factories',
      until: '4.0.0'
    });
  }

  exports.default = {
    name: 'ember-feature-flags',
    initialize: initialize
  };
});