define('busy-app/mixins/droppable', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create(Ember.Evented, {
		classNames: ['busy-drop-mixin'],
		classNameBindings: ['model.isDragOver:drop', 'disabled'],
		attributeBindings: ['droppable'],

		isDragOver: false,
		droppable: true,
		disabled: false,

		model: null,

		setDragOver: function setDragOver(isDrag) {
			if (Ember.isNone(this.get('model'))) {
				this.set('model', Ember.Object.create({}));
			}
			this.set('model.isDragOver', isDrag);
		},
		dragEnter: function dragEnter(event) {
			event.preventDefault();
			if (!this.get('disabled') && !this.get('isDragOver')) {
				this.setDragOver(true);
				this.trigger('dragEnterEvent', event);
			}
			return false;
		},


		/**
   * drag over event handler
   *
   * @public
   */
		dragOver: function dragOver(event) {
			event.preventDefault();
			return false;
		},


		/**
   * drag leave event handler
   *
   * @public
   */
		dragLeave: function dragLeave(event) {
			event.preventDefault();
			if (Ember.$(event.target).parents('.busy-drop-mixin').attr('id') !== this.$().attr('id')) {
				this.setDragOver(false);
				this.trigger('dragLeaveEvent', event);
			}
			return false;
		},


		/**
   * drop event handler
   *
   * @public
   */
		drop: function drop(event) {
			event.preventDefault();
			this.setDragOver(false);
			this.trigger('dropEvent', event);
			return false;
		}
	});
});