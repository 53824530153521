define('busy-app/controllers/account-settings', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    access: Ember.inject.service('access'),
    auth: Ember.inject.service('auth'),
    session: Ember.inject.service('session'),

    member: Ember.computed.alias('auth.member'),
    organization: Ember.computed.alias('auth.organization'),
    authSession: Ember.computed.alias('session.session.authenticated'),

    memberIsOwner: Ember.computed('member.id', function () {
      return this.get('access').isOwner(this.get('member.id'));
    }),

    memberIsAdmin: Ember.computed('member.positionId', function () {
      var adminPosition = this.get('auth.positions').filter(function (pos) {
        if (pos.get('title').toLowerCase() === 'admin') {
          return pos;
        }
      });
      return this.get('member.positionId') === adminPosition[0].get('id');
    }),

    showEmailVerificationBanner: Ember.computed.and('member.pendingEmail'),

    sendVerification: Ember.computed(function () {
      return _utils.LocalStorage.getProperty('send-verification') ? true : false;
    }),

    notifName: Ember.computed('sendVerification', 'showEmailVerificationBanner', function () {
      var name = '';
      if (this.get('showEmailVerificationBanner')) {
        if (!this.get('sendVerification')) {
          name = 'send-verification';
        } else {
          name = 'resend-verification';
        }
      }
      return name;
    }),

    notifText: Ember.computed('sendVerification', 'showEmailVerificationBanner', function () {
      var text = '';
      if (this.get('showEmailVerificationBanner')) {
        if (!this.get('sendVerification')) {
          text = (0, _utils.loc)("We noticed your email address hasn’t been verified.");
        } else {
          text = (0, _utils.loc)("We’ve sent a verification email to %@. It may take a few minutes to arrive.", [this.get('member.pendingEmail')]);
        }
      }
      return text;
    }),

    notifClickText: Ember.computed('sendVerification', 'showEmailVerificationBanner', function () {
      var text = '';
      if (this.get('showEmailVerificationBanner')) {
        if (!this.get('sendVerification')) {
          text = (0, _utils.loc)("Verify your email here.");
        } else {
          text = (0, _utils.loc)("Resend email.");
        }
      }
      return text;
    }),

    actions: {
      sendVerificationEmail: function sendVerificationEmail() {
        return this.get('store').rpcRequest('account', 'request-verification-email', { member_id: this.get('member.id') }).finally(function () {
          _utils.LocalStorage.setProperty('send-verification', true);
          window.location.reload();
        });
      }
    }
  });
});