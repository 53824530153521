define('busy-app/models/member-seconds', ['exports', 'ember-data', 'busy-app/utils/unique-id'], function (exports, _emberData, _uniqueId) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		// used to match seconds and cost models
		uid: (0, _uniqueId.default)('startDate', 'endDate', 'memberId'),

		memberId: _emberData.default.attr('id', { sortable: true, sortBy: ['member.firstName', 'member.lastName'] }),
		startDate: _emberData.default.attr('date', { sortable: true }),
		endDate: _emberData.default.attr('date', { sortable: true }),
		regularSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overtimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		doubletimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		updatedOn: _emberData.default.attr('date'),
		submittedOn: _emberData.default.attr('date'),

		member: _emberData.default.belongsTo('member', { query: { deleted_on: '_-DISABLE-_' } }),

		cost: null,

		totalSeconds: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		overburden: Ember.computed.alias('cost.overburden').meta({ aggregateType: 'sum' }),
		regularCost: Ember.computed.alias('cost.regularCost').meta({ aggregateType: 'sum' }),
		overtimeCost: Ember.computed.alias('cost.overtimeCost').meta({ aggregateType: 'sum' }),
		doubletimeCost: Ember.computed.alias('cost.doubletimeCost').meta({ aggregateType: 'sum' }),
		totalCost: Ember.computed.alias('cost.totalCost').meta({ aggregateType: 'sum' }),
		totalOverburdenCost: Ember.computed.alias('cost.totalOverburdenCost').meta({ aggregateType: 'sum' })
	});
});