define('busy-app/controllers/report/payroll/employee', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['type'],

		auth: Ember.inject.service('auth'),
		payPeriods: Ember.inject.service('pay-period'),

		type: '',

		regHoursCol: true,
		otHoursCol: true,
		ot2HoursCol: true,
		totalHoursCol: true,
		totalWagesCol: true,

		employeeIdCol: false,
		wageCol: false,
		laborBurdenCol: false,

		showTimeEntryDialog: false,

		selectMenu: null,
		inlineSelectMenu: null,
		payPeriodSelector: null,

		timeEntryStart: null,
		timeEntryEnd: null,
		timeEntryMember: null,
		timeEntryProject: null,

		openItems: false,
		allOpen: false,

		hasProject: Ember.computed('type', function () {
			return this.get('type') === 'member-project';
		}),

		init: function init() {
			this._super();

			//This select Menu is in the Header
			this.set('selectMenu', [Ember.Object.create({ key: 'print-report', value: 'Print Report' }), Ember.Object.create({ key: 'export-to-csv', value: 'Export to CSV' })]);

			//This is a Menu for each item in ContentData Array
			this.set('inlineSelectMenu', [Ember.Object.create({ key: 'time-entries', value: 'View Time Entries' })]);

			this.setPeriods();
		},


		setPeriods: Ember.observer('auth.organizationPayPeriod.@each.id', function () {
			if (!Ember.isEmpty(this.get('auth.organizationPayPeriod'))) {
				var payPeriods = this.get('payPeriods').getPayPeriodRange(_utils.Time.timestamp(), 15);
				this.set('payPeriodSelector', payPeriods);
			}
		}),

		showOvertime: Ember.computed('model.overtimeSeconds', function () {
			return this.get('model.overtimeSeconds') ? true : false;
		}),

		showDoubletime: Ember.computed('model.doubletimeSeconds', function () {
			return this.get('model.doubletimeSeconds') ? true : false;
		}),

		/**
   * Used to Display the Date Range of the Payperiod
   * that was selected . It is used in the Template
   * @public
   * @method reportDateRange
   */
		reportDateRange: Ember.computed('start', 'end', function () {
			return _utils.Time.timeFormat(this.get('start'), 'MMM DD') + ' - ' + _utils.Time.timeFormat(this.get('end'), 'MMM D, YYYY');
		}),

		openItemsObserver: Ember.observer('model.@each.isOpen', function () {
			var models = this.get('model');
			var openItems = [];
			var projectItems = [];

			models.forEach(function (item) {
				if (item.get('hasChildren')) {
					projectItems.pushObject(item);

					if (item.get('isOpen')) {
						openItems.pushObject(item);
					}
				}
			});

			if (projectItems.length > 0) {
				this.set('canOpen', true);
			} else {
				this.set('canOpen', false);
			}

			if (openItems.length > 0) {
				this.set('openItems', true);
			} else if (openItems.length <= 0) {
				this.set('openItems', false);
				this.set('allOpen', false);
			}

			if (openItems.length === projectItems.length) {
				this.set('allOpen', true);
			}
		}),

		actions: {
			expandRow: function expandRow(item) {
				item.set('isOpen', !item.get('isOpen'));
			},
			expandAll: function expandAll() {
				var _this = this;

				this.get('model').forEach(function (item) {
					if (item.get('hasChildren')) {
						_this.send('expandRow', item);
					}
				});
			},
			collapseAll: function collapseAll() {
				this.get('model').forEach(function (item) {
					item.set('isOpen', false);
				});
			},
			openTimeEntriesAction: function openTimeEntriesAction(item) {
				this.set('timeEntryStart', this.get('model.start'));
				this.set('timeEntryEnd', this.get('model.end'));
				this.set('timeEntryMember', item.get('member'));
				this.set('timeEntryProject', item.get('project'));

				this.set('showTimeEntryDialog', true);
			},
			closeTimeEntryDialog: function closeTimeEntryDialog() {
				this.set('showTimeEntryDialog', false);

				Ember.run.later(this, function () {
					this.set('timeEntryMember', null);
					this.set('timeEntryProject', null);
					this.set('timeEntryStart', null);
					this.set('timeEntryEnd', null);
				}, 300);
			}
		}
	});
});