define('busy-app/components/time/signature-invalidation-warning', ['exports', 'moment', '@busy-web/utils/assert'], function (exports, _moment, _assert) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		features: Ember.inject.service('features'),
		signature: Ember.inject.service('signature'),
		store: Ember.inject.service('store'),
		payPeriod: Ember.inject.service('payPeriod'),

		showDialog: false,
		hasBeenDismissed: false,

		member: null,
		memberEntries: null,
		startTime: null,
		endTime: null,
		warningType: 'time-entry',

		/**
   * @private
   * @method init
   */
		init: function init() {
			this._super();

			if (this.get('features.signatures')) {
				// Validate member data
				if (!Ember.isNone(this.get('member')) && !Ember.isNone(this.get('memberEntries'))) {
					throw 'Cannot define both "member" and "memberEntries"';
				} else if (Ember.isNone(this.get('member')) && Ember.isNone(this.get('memberEntries'))) {
					throw 'Must define either "member" or "memberEntries"';
				} else if (!Ember.isNone(this.get('memberEntries'))) {
					_assert.default.isArray(this.get('memberEntries'));
				}

				_assert.default.isInteger(this.get('startTimeStamp'));
				_assert.default.isInteger(this.get('endTimeStamp'));

				this.checkForEffectedMemberTimeDocuments();
			}
		},


		/**
   * computeds
   *
   */

		startTimeStamp: Ember.computed('startTime', function () {
			return !Ember.isNone(this.get('startTime')) ? this.ensureTimestamp(this.get('startTime')) : null;
		}),

		endTimeStamp: Ember.computed('endTime', function () {
			return !Ember.isNone(this.get('endTime')) ? this.ensureTimestamp(this.get('endTime')) : null;
		}),

		memberAsArray: Ember.computed('member', function () {
			return this.get('member') ? [this.get('member')] : null;
		}),

		membersCombined: Ember.computed.union('memberAsArray', 'memberEntries'),

		membersCombinedIds: Ember.computed.mapBy('membersCombined', 'id'),

		startTimePayPeriodBounds: Ember.computed('startTimeStamp', 'endTimeStamp', function () {
			var startTimeStamp = this.getWithDefault('startTimeStamp', null);
			var endTimeStamp = this.getWithDefault('endTimeStamp', null);
			var minTimeStamp = Math.min(startTimeStamp, endTimeStamp);

			return !Ember.isNone(minTimeStamp) ? this.getPayPeriodBounds(minTimeStamp) : null;
		}),

		endTimePayPeriodBounds: Ember.computed('startTimeStamp', 'endTimeStamp', function () {
			var startTimeStamp = this.getWithDefault('startTimeStamp', null);
			var endTimeStamp = this.getWithDefault('endTimeStamp', null);
			var maxTimeStamp = Math.max(startTimeStamp, endTimeStamp);

			return !Ember.isNone(maxTimeStamp) ? this.getPayPeriodBounds(maxTimeStamp) : null;
		}),

		/**
   * observers
   *
   */

		payPeriodsChanged: Ember.observer('membersCombinedIds', 'startTimePayPeriodBounds', 'endTimePayPeriodBounds', function () {
			if (this.get('features.signatures')) {
				return this.checkForEffectedMemberTimeDocuments();
			}
		}),

		/**
   * data access
   *
   */

		/**
   * Checks if there are any `memberTimeDocument` with valid signatures within the relevant date range.
   * If there are any matches, trigger the `displayDialog()` event.
   *
   * Intentionally does not check the `organization.signatureDate` setting as there could be a warning even if the setting is disabled.
   *
   * @private
   * @method checkForEffectedMemberTimeDocuments
   */
		checkForEffectedMemberTimeDocuments: function checkForEffectedMemberTimeDocuments() {
			var _this = this;

			if (!window.runningTests && this.get('features.signatures')) {
				var startTime = this.get('startTimePayPeriodBounds').get('start');
				var endTime = this.get('endTimePayPeriodBounds').get('end');
				var memberIds = this.get('membersCombinedIds');

				_assert.default.isInteger(startTime);
				_assert.default.isInteger(endTime);
				_assert.default.isArray(memberIds);

				var queryParams = {
					_gte: { start_time: startTime },
					_lte: { end_time: endTime },
					_desc: ['created_on'],
					deleted_on: '_-NULL-_'
				};

				return Ember.RSVP.hash({
					ppms: this.getPayPeriodMemberSeconds(memberIds, startTime, endTime),
					mtd: this.get('store').findWhereIn('member-time-document', 'member_id', memberIds, queryParams)
				}).then(function (props) {
					return _this.get('signature').getStateForMultiplePayPeriods(props.ppms, props.mtd);
				}).then(function (memberTimeDocuments) {
					if (!_this.get('isDestroyed') && !Ember.isEmpty(memberTimeDocuments)) {
						var anyDocHasValidSignature = memberTimeDocuments.any(function (doc) {
							return !Ember.isNone(doc) && doc.get('hasValidSelfSignature');
						});

						if (anyDocHasValidSignature) {
							_this.send('displayDialog');
						}
					}

					return memberTimeDocuments;
				});
			}
		},


		/**
   * util
   *
   */

		/**
  	 * @private
  	 * @method getPayPeriodBounds
  	 * @return {EmberObject}
  	 */
		getPayPeriodBounds: function getPayPeriodBounds(timestamp) {
			_assert.default.funcNumArgs(arguments, 1, true);
			_assert.default.isInteger(timestamp);

			return this.get('payPeriod').getPayPeriod(timestamp);
		},


		/**
  	 * Ensure the providated value is a unix timestamp, not a moment object
  	 *
  	 * @private
  	 * @method ensureTimestamp
  	 * @param {Number || Moment}
  	 * @return {EmberObject}
  	 */
		ensureTimestamp: function ensureTimestamp(dateVal) {
			_assert.default.funcNumArgs(arguments, 1, true);

			return _moment.default.isMoment(dateVal) ? dateVal.unix() : dateVal;
		},
		getPayPeriodMemberSeconds: function getPayPeriodMemberSeconds(memberIds, startTime, endTime) {
			var _this2 = this;

			if (!window.runningTests) {
				var queryParams = {
					start_time: startTime,
					end_time: endTime,
					deleted_on: '_-NULL-_'
				};

				return this.get('store').findWhereIn('pay-period-member-seconds', 'memberId', memberIds, queryParams).then(function (payPeriodMemberSeconds) {
					var results = payPeriodMemberSeconds.toArray();
					var payPeriodMembers = results.mapBy('memberId');

					// find members for which we didn't get a pay-period-member-seconds result and create a usable mock
					memberIds.forEach(function (memberId) {
						if (payPeriodMembers.indexOf(memberId) === -1) {
							results.push(_this2.createPayPeriodMemberSeconds(memberId, startTime, endTime));
						}
					});

					return results;
				});
			}
		},
		createPayPeriodMemberSeconds: function createPayPeriodMemberSeconds(memberId, startTime, endTime) {
			return this.get('store').createRecord('pay-period-member-seconds', {
				memberId: memberId,
				startTime: startTime,
				endTime: endTime,
				regularSeconds: 0,
				overtimeSeconds: 0,
				doubletimeSeconds: 0
			});
		},


		/**
   * actions
   *
   */

		actions: {
			/**
    * Hide the warning dialog
    *
    * @event dismissDialog
    */
			dismissDialog: function dismissDialog() {
				if (!this.get('isDestroyed')) {
					this.set('showDialog', false);
					this.set('hasBeenDismissed', true);
				}
			},


			/**
    * Show the warning dialog if it hasn't been shown before
    *
    * @event displayDialog
    */
			displayDialog: function displayDialog() {
				if (!this.get('hasBeenDismissed') && !this.get('isDestroyed')) {
					this.set('showDialog', true);

					// force any underlying datepickers to close when the dialog shows
					this.$().focus().click();
				}
			}
		}
	});
});