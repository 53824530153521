define('busy-app/components/busy-date-range', ['exports', 'moment', '@busy-web/utils'], function (exports, _moment, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-date-range'],

		payPeriods: Ember.inject.service('pay-period'),

		startDate: null,
		endDate: null,
		minDate: null,
		maxDate: null,

		showButton: true,

		format: Ember.computed(function () {
			return _utils.Locale.format("MMM Do, YYYY", _moment.default.locale());
		}),

		showPayPeriods: false,
		isPayPeriodSelector: false,
		defaultPayPeriod: true,

		titleBar: Ember.computed('defaultPayPeriod', function () {
			var str = '<span class="toggle">';
			if (this.get('defaultPayPeriod')) {
				str += '<input class="toggle-custom-date" type="checkbox" checked="checked"/><label>';
			} else {
				str += '<input class="toggle-custom-date" type="checkbox" /><label>';
			}

			str += (0, _utils.loc)('Use Pay Period Dates');
			str += '</label></span>';
			return str;
		}),

		init: function init() {
			this._super();

			if (this.get('showPayPeriods') && this.get('defaultPayPeriod')) {
				this.set('isPayPeriodSelector', true);
			} else {
				this.set('isPayPeriodSelector', false);
			}
		},
		beforeShowDay: function beforeShowDay(date) {
			var startTime = _utils.Time.date(date.valueOf() / 1000).startOf('day').unix();
			var endTime = _utils.Time.date(date.valueOf() / 1000).endOf('day').unix();
			var args = [];

			// set isClickable
			args.push(true);

			if (this.get('showPayPeriods')) {
				var period = this.get('payPeriods').getPayPeriod(startTime);

				var classNames = 'pay-period';
				if (startTime === period.get('start')) {
					classNames += ' start';
				}

				if (endTime === period.get('end')) {
					classNames += ' end';
				}

				args.push(classNames);
			}
			return args;
		},
		didInsertElement: function didInsertElement() {
			var _this = this;

			this._super.apply(this, arguments);

			if (this.get('showPayPeriods')) {
				var datePicker = this.$().find('.js-date-picker > input').data('dateRangePicker');
				var el = datePicker.getDatePicker();
				var $toggle = el.find('.toggle > input.toggle-custom-date');

				$toggle.bind('change.busy-date-range-toggle', function () {
					_this.set('isPayPeriodSelector', $toggle.is(':checked'));
				});
			}
		},
		willDestroyElement: function willDestroyElement() {
			this._super.apply(this, arguments);

			if (this.get('showPayPeriods')) {
				var el = this.$().find('.js-date-picker > input').data('dateRangePicker').getDatePicker();
				el.find('.toggle > input.toggle-custom-date').unbind('change.busy-date-range-toggle');
			}
		},


		actions: {
			changeDate: function changeDate(time1, time2) {
				if (this.get('isPayPeriodSelector')) {
					var payPeriod = this.get('payPeriods').getPayPeriod(time1);

					time1 = payPeriod.get('start');
					time2 = payPeriod.get('end');

					var datePicker = this.$().find('.js-date-picker > input').data('dateRangePicker');
					datePicker.setDateRange(payPeriod.get('startDate').format(this.get('format')), payPeriod.get('endDate').format(this.get('format')));
				}

				Ember.run.next(this, function () {
					this.set('startDate', time1);
					this.set('endDate', time2);
					this.sendAction('onChange', time1, time2);
				});
			}
		}
	});
});