define('busy-app/components/dialogs/pro-feature-upgrade', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		proFeature: Ember.inject.service('pro-feature'),
		subscription: Ember.inject.service('subscription'),
		router: Ember.inject.service(),

		onClose: null,
		onPlanUpgraded: null,

		actions: {
			close: function close() {
				this.sendAction('onClose');
			},
			startTrial: function startTrial(dialog) {
				var _this = this;

				dialog.showSavingMessage((0, _utils.loc)("Starting your free trial"));
				this.get('proFeature').startTrial().then(function () {
					dialog.showSavedMessage((0, _utils.loc)("Free trial has been started"));
					_this.send('planUpgraded');
				});
			},
			startUpgrade: function startUpgrade() {
				var _this2 = this;

				_logger.default.info(this, 'startUpgrade');

				return this.get('proFeature').startUpgrade().then(function () {
					return _this2.send('close');
				});
			},
			planUpgraded: function planUpgraded() {
				_logger.default.info(this, 'planUpgraded');

				this.send('close');
				this.sendAction('onPlanUpgraded');
			},
			learnMore: function learnMore() {
				var _this3 = this;

				_logger.default.info(this, 'learnMore');

				return this.get('router').transitionTo('company-settings', { queryParams: { bc_tab: 'subscription' } }).then(function () {
					return _this3.send('close');
				});
			}
		}
	});
});