define('busy-app/components/dialogs/fs-modal-dialog', ['exports', 'busy-app/components/dialogs/modal-dialog'], function (exports, _modalDialog) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _modalDialog.default.extend({
		className: 'c-dialogs-fs-modal-dialog',

		didInsertElement: function didInsertElement() {
			this._super();

			// reverse the history and close the dialog
			// when the back button is clicked
			var _this = this;
			window.onpopstate = function () {
				window.history.forward();
				_this.send('closeAction');
			};
		},
		willDestroyElement: function willDestroyElement() {
			this._super();
			window.onpopstate = null;
		}
	});
});