define('busy-app/models/time-entry-break', ['exports', 'ember-data', '@busy-web/utils', 'busy-app/utils/time-entry-validator', 'busy-app/utils/maps'], function (exports, _emberData, _utils, _timeEntryValidator, _maps) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		auth: Ember.inject.service('auth'),

		timeEntryId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		startTimeDst: _emberData.default.attr('boolean'),
		startMetaOffset: _emberData.default.attr('number'),
		endTime: _emberData.default.attr('number'),
		endTimeDst: _emberData.default.attr('boolean'),
		endMetaOffset: _emberData.default.attr('number'),
		paidBreak: _emberData.default.attr('boolean', { defaultValue: false }),
		actionType: _emberData.default.attr('number'),
		startLocationId: _emberData.default.attr('string'),
		endLocationId: _emberData.default.attr('string'),
		startDeviceTime: _emberData.default.attr('number'),
		endDeviceTime: _emberData.default.attr('number'),
		startTimeTrusted: _emberData.default.attr('number'),
		endTimeTrusted: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		timeEntry: _emberData.default.belongsTo('time-entry', { inverse: null }),
		startLocation: _emberData.default.belongsTo('member-location', { referenceKey: 'startLocationId', foreignKey: 'id' }),
		endLocation: _emberData.default.belongsTo('member-location', { referenceKey: 'endLocationId', foreignKey: 'id' }),

		startBounds: null,
		endBounds: null,

		// break flags
		invalidStartTime: false, // starts outside the time entry
		invalidEndTime: false, // ends outside the time entry
		invalidEntry: false, // starts and ends outside the time entry
		overlappingStartTime: false, // start time overlaps another break
		overlappingEndTime: false, // end time overlaps another break
		overlappingEntry: false, // starts and ends inside another break

		startTimeGetter: function startTimeGetter() {
			var startTime = this.get('startTime');
			var startBounds = this.get('startBounds');
			if (!Ember.isNone(startBounds) && startTime < startBounds) {
				return startBounds;
			} else {
				return startTime;
			}
		},
		endTimeGetter: function endTimeGetter() {
			var endTime = this.get('endTime');
			if (Ember.isNone(endTime)) {
				return null;
			}

			var endBounds = this.get('endBounds');
			if (!Ember.isNone(endBounds) && endTime > endBounds) {
				return endBounds;
			} else {
				return endTime;
			}
		},


		totalTime: Ember.computed('startTime', 'endTime', function () {
			var total = (this.get('endTime') || 0) - (this.get('startTime') || 0);
			if (total < 0) {
				total = 0;
			}
			return total;
		}),

		elapsedTimeString: Ember.computed('totalTime', function () {
			var time = _utils.Time.convertSeconds(this.get('totalTime'), true);
			return time.hours + ':' + time.minutes;
		}),

		totalTimeString: Ember.computed('totalTime', function () {
			var time = _utils.Time.convertSeconds(this.get('totalTime'), true);
			var str = '';
			if (parseInt(time.hours, 10) > 0) {
				str += time.hours + " " + (0, _utils.loc)('hrs ');
			}

			if (parseInt(time.minutes, 10) > 0) {
				str += time.minutes + " " + (0, _utils.loc)('mins');
			}
			return str.replace(/ $/, '');
		}),

		dateString: Ember.computed('startTime', 'endTime', 'paidBreak', function () {
			var date = '';
			if (!Ember.isNone(this.get('startTime'))) {
				date += _utils.Time.timeFormat(this.get('startTime'), 'h:mm A');

				if (!Ember.isNone(this.get('endTime'))) {
					date += ' - ' + _utils.Time.timeFormat(this.get('endTime'), 'h:mm A');
				} else {
					date += ' - ' + (0, _utils.loc)('NOW');
				}
			}

			//if (!isEmpty(date)) {
			//	let  isPaid = loc("Unpaid");
			//	if (this.get('paidBreak')) {
			//		isPaid = loc("Paid");
			//	}
			//	date = date + ' (' + isPaid + ')';
			//}
			return date;
		}),

		validLocationTime: Ember.computed('auth.organization.{timeRounding,timeRoundingInterval}', function () {
			if (!Ember.isNone(this.get('auth.organization'))) {
				return (0, _maps.validTimeOffset)(this.get('auth.organization'));
			}
		}),

		isStartLocationValid: Ember.computed('startLocation.createdOn', 'startLocationId', 'startTime', 'timeEntry.offset', 'validLocationTime', function () {
			var validLocTime = this.get('validLocationTime');
			var isValid = false;
			if (!Ember.isNone(this.get('startLocation.createdOn'))) {
				var locCreatedOn = this.get('startLocation.createdOn') + this.get('timeEntry.offset');
				if (locCreatedOn >= this.get('startTime') - validLocTime && locCreatedOn <= this.get('startTime') + validLocTime) {
					if ((0, _maps.isValidLatLng)(this.get('startLocation'))) {
						isValid = true;
					}
				}
			}
			return isValid;
		}),

		isEndLocationValid: Ember.computed('endLocation.createdOn', 'endLocationId', 'endTime', 'timeEntry.offset', 'validLocationTime', function () {
			var validLocTime = this.get('validLocationTime');
			var isValid = false;
			if (!Ember.isNone(this.get('endLocation.createdOn')) && !Ember.isNone(this.get('endTime'))) {
				var locCreatedOn = this.get('endLocation.createdOn') + this.get('timeEntry.offset');
				if (locCreatedOn >= this.get('endTime') - validLocTime && locCreatedOn <= this.get('endTime') + validLocTime) {
					if ((0, _maps.isValidLatLng)(this.get('startLocation'))) {
						isValid = true;
					}
				}
			}
			return isValid;
		}),

		startLocationCreatedOn: Ember.computed('startLocation.createdOn', function () {
			return this.get('startLocation.createdOn');
		}),

		endLocationCreatedOn: Ember.computed('endLocation.createdOn', function () {
			return this.get('endLocation.createdOn');
		}),

		validateTime: function validateTime(startBounds, endBounds) {
			if (!Ember.isNone(this.startTimeGetter()) && !Ember.isNone(this.endTimeGetter())) {
				// check if break starts before time entry start time
				if (this.startTimeGetter() < startBounds) {
					this.set('startBounds', startBounds);
					this.set('invalidStartTime', true);
				}

				// check if break ends after time entry end time
				if (this.endTimeGetter() > endBounds) {
					this.set('endBounds', endBounds);
					this.set('invalidEndTime', true);
				}

				// check if the end time is still greater than the start time
				if (this.endTimeGetter() < this.startTimeGetter()) {
					this.set('endBounds', this.startTimeGetter());
					this.set('invalidEntry', true);
				}
			}
		},
		adjustOverlap: function adjustOverlap(breakEntry) {
			// make sure not to adjust this break against itself
			if (this.id !== breakEntry.id) {
				var thatStart = breakEntry.startTimeGetter();
				var thatEnd = breakEntry.endTimeGetter() || _utils.Time.timestamp();
				if (this.startTimeGetter() !== this.endTimeGetter()) {
					if (thatStart !== thatEnd) {
						if (this.startTimeGetter() >= thatStart && this.startTimeGetter() < thatEnd) {
							// starts inside another break
							this.set('startBounds', thatEnd);
							this.set('overlappingStartTime', true);
						}

						if (this.endTimeGetter() > thatStart && this.endTimeGetter() <= thatEnd) {
							// ends inside another break
							this.set('endBounds', thatStart);
							this.set('overlappingEndTime', true);
						}

						if (this.endTimeGetter() < this.startTimeGetter()) {
							// starts and ends inside another break
							this.set('endBounds', this.startTimeGetter());
							this.set('overlappingEntry', true);
						}
					}
				}
			}
		},


		overlappingBreak: Ember.computed('overlappingStartTime', 'overlappingEndTime', 'overlappingEntry', function () {
			return this.get('overlappingStartTime') || this.get('overlappingEndTime') || this.get('overlappingEntry');
		}),

		resetFlags: function resetFlags() {
			// reset invalid flags
			this.set('invalidStartTime', false);
			this.set('invalidEndTime', false);
			this.set('invalidEntry', false);

			// reset overlapping
			this.set('overlappingStartTime', false);
			this.set('overlappingEndTime', false);
			this.set('overlappingEntry', false);

			// reset bounds
			this.set('startBounds', null);
			this.set('endBounds', null);
		},
		populate: function populate(timeEntry) {
			if (this.get('actionType') === 10 || this.get('actionType') === 11) {
				// start break and start break with location
				var startTime = this.get('startTime');
				var startMetaOffset = _utils.Time.timezone(startTime);
				var startTimeDst = _utils.Time.isDST(startTime);

				// convert startTime to the time of the timecards timezone
				startTime = startTime - startMetaOffset + timeEntry.get('offset');
				var startDeviceTime = _utils.Time.date().unix() - startMetaOffset + timeEntry.get('offset');

				this.set('startTime', startTime);
				this.set('startMetaOffset', startMetaOffset);
				this.set('startTimeDst', startTimeDst);
				this.set('startDeviceTime', startDeviceTime);
				this.set('startTimeTrusted', _utils.Time.isTrustedTimeType());
			} else if (this.get('actionType') === 20 || this.get('actionType') === 21) {
				// stop break and stop break with location
				var endTime = this.get('endTime');
				var endMetaOffset = _utils.Time.timezone();
				var endTimeDst = _utils.Time.isDST();

				endTime = endTime - endMetaOffset + timeEntry.get('offset');
				var endDeviceTime = _utils.Time.date().unix() - endMetaOffset + timeEntry.get('offset');

				this.set('endTime', endTime);
				this.set('endMetaOffset', endMetaOffset);
				this.set('endTimeDst', endTimeDst);
				this.set('endDeviceTime', endDeviceTime);
				this.set('endTimeTrusted', _utils.Time.isTrustedTimeType());
			} else if (this.get('actionType') === 30 || this.get('actionType') === 50) {
				// create new manual break
				var _startTime = this.get('startTime');
				var _endTime = this.get('endTime');

				(false && !(!Ember.isNone(_startTime) && typeof _startTime === 'number') && Ember.assert("You must provide a startTime in seconds for a manual entry action", !Ember.isNone(_startTime) && typeof _startTime === 'number'));
				(false && !(!Ember.isNone(_endTime) && typeof _endTime === 'number') && Ember.assert("You must provide a endTime in seconds for a manual entry action", !Ember.isNone(_endTime) && typeof _endTime === 'number'));

				// start

				this.set('startMetaOffset', _utils.Time.timezone(_startTime));
				this.set('startTimeDst', _utils.Time.isDST(_startTime));
				this.set('startTimeTrusted', _utils.Time.isTrustedTimeType(true));

				// end
				this.set('endMetaOffset', _utils.Time.timezone(_endTime));
				this.set('endTimeDst', _utils.Time.isDST(_endTime));
				this.set('endTimeTrusted', _utils.Time.isTrustedTimeType(true));

				// convert to the local time of the time-entry offset
				this.set('startTime', _startTime - this.get('startMetaOffset') + timeEntry.get('offset'));
				this.set('startDeviceTime', this.get('startTime'));

				this.set('endTime', _endTime - this.get('endMetaOffset') + timeEntry.get('offset'));
				this.set('endDeviceTime', this.get('endTime'));
			} else if (this.get('actionType') === 40 || this.get('actionType') === 60 || this.get('actionType') === 80) {
				// edit existing break and resolve conflict
				var _startTime2 = this.get('startTime');
				(false && !(!Ember.isNone(_startTime2) && typeof _startTime2 === 'number') && Ember.assert("You must provide a startTime in seconds for a manual entry action", !Ember.isNone(_startTime2) && typeof _startTime2 === 'number'));

				// start

				if (!Ember.isNone(_startTime2) && !Ember.isNone(this._internalModel._attributes.startTime)) {
					this.set('startMetaOffset', _utils.Time.timezone(_startTime2));
					this.set('startTimeDst', _utils.Time.isDST(_startTime2));
					this.set('startTimeTrusted', _utils.Time.isTrustedTimeType(true));

					// convert to the local time of the time-entry offset
					this.set('startTime', _startTime2 - this.get('startMetaOffset') + timeEntry.get('offset'));
					this.set('startDeviceTime', this.get('startTime'));
				}

				var _endTime2 = this.get('endTime');
				(false && !(!Ember.isNone(_endTime2) && typeof _endTime2 === 'number') && Ember.assert("You must provide a endTime in seconds for a manual entry action", !Ember.isNone(_endTime2) && typeof _endTime2 === 'number'));

				// end

				if (!Ember.isNone(_endTime2) && !Ember.isNone(this._internalModel._attributes.endTime)) {
					this.set('endMetaOffset', _utils.Time.timezone(_endTime2));
					this.set('endTimeDst', _utils.Time.isDST(_endTime2));
					this.set('endTimeTrusted', _utils.Time.isTrustedTimeType(true));

					// convert to the local time of the time-entry offset
					this.set('endTime', _endTime2 - this.get('endMetaOffset') + timeEntry.get('offset'));
					this.set('endDeviceTime', this.get('endTime'));
				}
				this.checkAddedAttrs();
			}
		},
		checkAddedAttrs: function checkAddedAttrs() {
			if (Ember.isNone(this.get('startDeviceTime'))) {
				this.set('startDeviceTime', this.get('startTime'));
			}

			if (Ember.isNone(this.get('startTimeTrusted'))) {
				this.set('startTimeTrusted', _utils.Time.isTrustedTimeType(true));
			}

			if (Ember.isNone(this.get('endDeviceTime'))) {
				this.set('endDeviceTime', this.get('endTime'));
			}

			if (Ember.isNone(this.get('endTimeTrusted'))) {
				this.set('endTimeTrusted', _utils.Time.isTrustedTimeType(true));
			}
		},


		onCreate: Ember.on('didCreate', function () {
			this.set('_isValid', false);
		}),

		onLoad: Ember.on('didLoad', function () {
			if (!this.get('hasDirtyAttributes')) {
				this.set('_isValid', true);
			}
		}),

		onUpdate: Ember.on('didUpdate', function () {
			if (!this.get('hasDirtyAttributes')) {
				this.set('_isValid', true);
			}
		}),

		onRollback: Ember.on('rolledBack', function () {
			if (!this.get('hasDirtyAttributes')) {
				this.set('_isValid', true);
			}
		}),

		onChange: Ember.observer('hasDirtyAttributes', function () {
			if (this.get('hasDirtyAttributes') && this.get('_isValid')) {
				this.set('_isValid', false);
			}
		}),

		validate: function validate(timeEntry, validateOverlap) {
			var _this = this;

			if (!this.get('_isValid')) {
				return _timeEntryValidator.default.timeEntryBreak(this, timeEntry, validateOverlap).then(function (result) {
					if (result.isValid) {
						_this.set('_isValid', true);
						return result.isValid;
					} else {
						return Ember.RSVP.reject(result);
					}
				});
			} else {
				return Ember.RSVP.resolve(true);
			}
		},
		saveLog: function saveLog(timeEntryLogId) {
			return this.store.createRecord('client-time-entry-break-log', {
				updaterMemberId: window.BusyApp._application.get('auth.member.id'),
				originalTimeEntryBreakId: this.id,
				timeEntryBreakId: this.id,
				mergedTimeEntryBreakId: this.get('mergedTimeEntryBreakId'),
				timeEntryId: this.get('timeEntryId'),
				paidBreak: this.get('paidBreak'),
				startTime: this.get('startTime'),
				startMetaOffset: this.get('startMetaOffset'),
				startTimeDst: this.get('startTimeDst'),
				endTime: this.get('endTime'),
				endMetaOffset: this.get('endMetaOffset'),
				endTimeDst: this.get('endTimeDst'),
				actionType: this.get('actionType'),
				timeEntryBreakCreatedOn: this.get('createdOn'),
				timeEntryBreakDeletedOn: this.get('deletedOn'),
				deviceType: 'Web Browser',
				startDeviceTime: this.get('startDeviceTime'),
				endDeviceTime: this.get('endDeviceTime'),
				startTimeTrusted: this.get('startTimeTrusted'),
				endTimeTrusted: this.get('endTimeTrusted'),
				clientTimeEntryLogId: timeEntryLogId,
				startLocationId: this.get('startLocationId'),
				endLocationId: this.get('endLocationId')
			}).save();
		},
		deleteRecord: function deleteRecord(isResolve) {
			this.set('actionType', isResolve ? 100 : 90);

			this._internalModel.deleteRecord();

			return this;
		},
		save: function save(timeEntry, timeEntryLogId) {
			var _this2 = this;

			var forceSave = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			var validateOverlap = forceSave === true ? false : true;

			if (this.get('isDeleted')) {
				return this.saveLog(timeEntryLogId).then(function () {
					return _this2._internalModel.save();
				});
			} else {
				(false && !(!Ember.isNone(timeEntry) && !Ember.isNone(timeEntry.startTime)) && Ember.assert('you must provide a timeEntry model to TimeEntryBreak.save()', !Ember.isNone(timeEntry) && !Ember.isNone(timeEntry.startTime)));
				(false && !(typeof timeEntryLogId === 'string') && Ember.assert('You must provide a clientTimeEntryLogId to save a break', typeof timeEntryLogId === 'string'));


				return this.validate(timeEntry, validateOverlap).then(function () {
					_this2.populate(timeEntry);
					return _this2._save(timeEntryLogId);
				});
			}
		},
		_save: function _save(timeEntryLogId) {
			var _this3 = this;

			return this._internalModel.save().then(function () {
				_this3.saveLog(timeEntryLogId);
				return _this3;
			});
		}
	});
});