define('busy-app/components/sync-status', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	/*** CONSTANTS ***/
	/**
  * @module Components
  *
  */
	var MIN_TIMER = 1000;

	/*** private ***/
	var __dirty = false;
	var __resolved = false;
	var __syncstamp = null;
	var __timeout = null;

	exports.default = Ember.Component.extend({
		classNames: ['c-sync-status', 'page-hide'],

		__dirty: false,
		__resolved: false,

		showHide: Ember.observer('__dirty', '__resolved', 'message', 'actionMessage', function () {
			var container = Ember.$('#c-sync-status-container');
			container.find('.action').html(this.get('actionText'));
			container.find('.action-message').html(this.get('actionMessage'));
			container.find('.message').html(this.get('message'));

			if (this.get('__dirty')) {
				if (!container.hasClass('active')) {
					container.addClass('active');
				}
			} else {
				container.removeClass('active');
			}

			if (this.get('__resolved')) {
				if (!container.hasClass('resolve')) {
					container.addClass('resolve');
				}
			} else {
				container.removeClass('resolve');
			}
		}),

		store: Ember.inject.service('store'),

		memberId: null,
		start: null,
		end: null,
		syncing: false,
		syncTries: 0,

		message: (0, _utils.loc)('Updating totals'),
		actionMessage: (0, _utils.loc)('Update ready'),
		actionText: (0, _utils.loc)('RELOAD'),

		initializeSync: function initializeSync() {
			var _this = this;

			// get the latest time entry sync stamp
			var promise = this.getLastEntry().then(function (entry) {
				if (!Ember.isNone(entry)) {
					// get the latest aggregate sync stamp
					return _this.getLastAggregate(entry.get('memberId')).then(function (aggregate) {
						if (!Ember.isNone(aggregate)) {
							// set sync stamp if it has never been set
							if (Ember.isNone(__syncstamp)) {
								__syncstamp = entry.get('updatedOn');
							}
							// return true if isDirty false if not
							return _this.compareTime(entry, aggregate);
						}
						return false; // return not dirty because no aggregate was found
					});
				}
				return false; // return not dirty because no entry was found
			});

			promise.then(function (isDirty) {
				// set dirty state
				_this.setState(isDirty);

				// update timer callbacks
				_this.updateTimer();
			});
		},
		getLastEntry: function getLastEntry() {
			// sync with latest closed time entry
			var query = { _desc: ['updated_on'], page: 1, page_size: 1, end_time: '!_-NULL-_', deleted_on: '_-DISABLE-_' };
			if (!Ember.isEmpty(this.get('memberId'))) {
				query.member_id = this.get('memberId');
			}

			if (!Ember.isNone(this.get('start')) && !Ember.isNone(this.get('end'))) {
				query._lte = { start_time: this.get('end') };
				query._gte = { end_time: this.get('start') };
			}

			var timeEntryQuery = this.get('store').query('time-entry', query);
			var breakQuery = this.get('store').query('time-entry-break', query).then(function (breakModels) {
				if (Ember.isNone(breakModels)) {
					return breakModels;
				} else {
					return Ember.RSVP.allSettled(breakModels.map(function (breakModel) {
						return Ember.RSVP.resolve(breakModel.get('time-entry'));
					})).then(function () {
						return breakModels;
					});
				}
			});

			return Ember.RSVP.allSettled([timeEntryQuery, breakQuery]).then(function (models) {
				var _models = _slicedToArray(models, 2),
				    timeEntryModels = _models[0],
				    breakModels = _models[1];

				var firstTimeEntryModel = Ember.get(timeEntryModels, 'firstObject');
				var firstTimeEntryModelUpdatedOn = Ember.get(timeEntryModels, 'firstObject.updatedOn');

				var firstBreakModel = Ember.get(breakModels, 'firstObject');
				var firstBreakModelUpdatedOn = Ember.get(breakModels, 'firstObject.updatedOn');
				var firstBreakEntryIsClosed = !!Ember.get(firstBreakModel || {}, 'timeEntry.endTime');

				var hasValidTimeEntry = !Ember.isNone(firstTimeEntryModel);
				var hasNewValidBreak = !Ember.isNone(firstBreakModel) && firstBreakEntryIsClosed; // open time entries don't trigger aggregate updates

				if (!hasValidTimeEntry && !hasNewValidBreak) {
					// neither
					return firstTimeEntryModel;
				} else if (hasValidTimeEntry && !hasNewValidBreak) {
					// only firstTimeEntryModel
					return firstTimeEntryModel;
				} else if (!hasValidTimeEntry && hasNewValidBreak) {
					// only firstBreakModel, return dummy object
					return Ember.Object.create({
						updatedOn: Ember.get(firstBreakModel, 'updatedOn'),
						memberId: Ember.get(firstBreakModel, 'timeEntry.memberId')
					});
				} else if (firstTimeEntryModelUpdatedOn > firstBreakModelUpdatedOn) {
					// both are valid, but firstTimeEntryModel is newer
					return firstTimeEntryModel;
				} else {
					// both are valid, but firstBreakModel is newer, return dummy object
					return Ember.Object.create({
						updatedOn: Ember.get(firstBreakModel, 'updatedOn'),
						memberId: Ember.get(firstBreakModel, 'timeEntry.memberId')
					});
				}
			});
		},
		getLastAggregate: function getLastAggregate(memberId) {
			_utils.Assert.isUUID(memberId);
			var query = { _desc: ['updated_on'], page_size: 1, member_id: memberId };

			if (!Ember.isNone(this.get('start')) && !Ember.isNone(this.get('end'))) {
				query._lte = { start_time: this.get('end') };
				query._gte = { end_time: this.get('start') };
			}

			return this.get('store').query('pay-period-member-cost', query).then(function (model) {
				return model.get('firstObject');
			});
		},
		compareTime: function compareTime(entry, aggregate) {
			if (!Ember.isNone(entry.get('updatedOn')) && !Ember.isNone(aggregate.get('updatedOn'))) {
				if (entry.get('updatedOn') > aggregate.get('updatedOn') || entry.get('updatedOn') !== __syncstamp) {
					__syncstamp = entry.get('updatedOn');
					return true;
				}
			}
			return false;
		},
		updateTimer: function updateTimer() {
			var _this2 = this;

			if (this.get('syncing')) {
				// quit after a minute of trying if the record is not updated yet
				if (this.get('syncTries') < 30) {
					__timeout = window.setTimeout(function () {
						if (!_this2.get('isDestroyed')) {
							_this2.set('syncTries', _this2.get('syncTries') + 1);
							_this2.initializeSync();
						}
					}, MIN_TIMER);
				} else {
					this.setState(false);
				}
			}
		},
		setState: function setState(isDirty) {
			if (!this.get('isDestroyed') && __dirty !== isDirty) {
				// set isDirty
				__dirty = isDirty;
				this.set('__dirty', __dirty);

				if (this.get('onResolve')) {
					// set resolved to not isDirty
					__resolved = !isDirty;
					this.set('__resolved', __resolved);
				}

				// only set syncing when syncing is already true
				// and isDirty is false
				if (this.get('syncing') && !isDirty) {
					this.set('syncing', false);

					// if onResolve listener is not set then fire
					// onSync listener
					if (this.get('onSync')) {
						this.sendAction('onSync');
					}
				} else if (!this.get('syncing') && isDirty) {
					this.set('syncing', true);
				}
			}
		},
		didReceiveAttrs: function didReceiveAttrs() {
			if (!Ember.isNone(__timeout)) {
				window.clearTimeout(__timeout);
			}

			if (this.get('syncing')) {
				this.setState(true);
			}

			this.set('syncTries', 0);
			this.updateTimer();
		},
		didInsertElement: function didInsertElement() {
			var _this3 = this;

			var viewId = this.get('elementId');
			var container = Ember.$('#c-sync-status-container');

			var action = container.find('.action');
			action.unbind('click.' + viewId);
			action.bind('click.' + viewId, function () {
				return _this3.send('clickAction');
			});
			action.html(this.get('actionText'));

			var close = container.find('.close');
			close.unbind('click.' + viewId);
			close.bind('click.' + viewId, function () {
				return _this3.send('closeAction');
			});
		},
		willDestroyElement: function willDestroyElement() {
			var viewId = this.get('elementId');
			var container = Ember.$('#c-sync-status-container');
			container.removeClass('active');
			container.removeClass('resolve');

			var action = container.find('.action');
			action.unbind('click.' + viewId);

			var close = container.find('.close');
			close.unbind('click.' + viewId);
		},


		actions: {
			clickAction: function clickAction() {
				this.set('__resolved', false);
				this.sendAction('onResolve');
			},
			closeAction: function closeAction() {
				this.set('__resolved', false);
				this.sendAction('onClose');
			}
		}
	});
});