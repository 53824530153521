define('busy-app/helpers/has-permission-for', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		access: Ember.inject.service('access'),

		compute: function compute(params) {
			var actionName = params[0];
			var hasPermission = false;
			if (!Ember.isNone(this.get('access.auth.authMemberPosition.id'))) {
				hasPermission = this.get('access').hasPermissionFor(actionName);
			}
			return hasPermission;
		},

		onUpdate: Ember.observer('access.auth.authMemberPosition', function () {
			this.recompute();
		})
	});
});