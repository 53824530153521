define('busy-app/components/time/time-entry/clock-out-at', ['exports', '@busy-web/utils', 'busy-app/utils/round-time', 'busy-app/mixins/safety-signoff'], function (exports, _utils, _roundTime, _safetySignoff) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend(_safetySignoff.default, {
    classNames: ['v-time-card-dialog-clock-out-at'],

    member: null,
    endDateTime: null,
    auth: Ember.inject.service('auth'),
    features: Ember.inject.service('features'),

    memberEntries: null,

    init: function init() {
      this._super();

      this.setTimeRoundingInterval();

      var time = this.get('member.openEntry.startTime');
      if (Ember.isNone(time)) {
        var start = _utils.Time.date().endOf('day').unix();
        this.get('memberEntries').forEach(function (item) {
          if (start > item.get('openEntry.startTime')) {
            start = item.get('openEntry.startTime');
          }
        });

        time = start;

        if (time === _utils.Time.date().endOf('day').unix()) {
          time = _utils.Time.date().startOf('day').unix();
        }
      }

      //sets the clock Out time input box to the current time
      var end = _utils.Time.date().startOf('minute').unix();
      end = (0, _roundTime.roundOrgTime)(this.get('auth.organization'), end, { isEnd: true });
      this.set('endDateTime', end);

      //sets the minimum and maximum date that the user can select
      this.set('mindate', _utils.Time.date(time).subtract(1, 'minute').unix());
      this.set('maxdate', _utils.Time.date().endOf('day').unix());
    },


    /**
    * @method setTimeRoundingInterval
    */
    setTimeRoundingInterval: function setTimeRoundingInterval() {
      var interval = 1;
      if (this.get('auth.organization.timeRounding')) {
        interval = parseInt(this.get('auth.organization.timeRoundingInterval') / 60, 10);
      }
      this.set('timeRoundingInterval', interval);
    },
    clockOut: function clockOut(member, override, isGroup) {
      var _this = this;

      if (!Ember.isNone(member) && !Ember.isNone(Ember.get(member, 'content'))) {
        member = Ember.get(member, 'content');
      }

      var endTime = this.get('endDateTime');

      // clock out at validateOnly is true, this will only validate the
      // time entry to see if it should allow the clock out at.
      return member.clockOutAt(endTime, override, true).then(function () {
        // creates an event callback to save or cancel the entry
        // based on the action taken from the safety signoff
        return _this.verifySafetyAndSave(isGroup ? null : member, 'showSafetyDialog', 'showSignOffRequiredDialog', function () {
          return member.clockOutAt(endTime, override);
        });
      });
    },
    handleError: function handleError(err, dialog) {
      var _this2 = this;

      if (err instanceof Error) {
        throw new Ember.Error(err);
      }

      if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object') {
        if (err.canOverride) {
          dialog.showConfirm(err.message, function (override, d) {
            if (override) {
              _this2.clockOut(_this2.get('member'), true).then(function () {
                d.showSavedMessage();
              });
            } else {
              d.showErrorMessage('', 0);
            }
          });
        } else if (err.message) {
          dialog.showErrorMessage(err.message);
        }
      } else {
        if (typeof err === 'string') {
          dialog.showErrorMessage(err);
        } else {
          dialog.showErrorMessage();
        }
      }
    },


    actions: {
      //closes dialog if clicked on cancel button or clicked in background window
      close: function close() {
        if (this.get('memberEntries.length') > 0) {
          this.get('memberEntries').forEach(function (item) {
            if (item.get('openEntry.hasDirtyAttributes')) {
              item.get('openEntry').rollbackAttributes();
            }
          });
        } else {
          if (this.get('member.openEntry.hasDirtyAttributes')) {
            this.get('member.openEntry').rollbackAttributes();
          }
        }
        this.sendAction('onClose');
      },
      clockoutSave: function clockoutSave(dialog) {
        var _this3 = this;

        var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (!Ember.isNone(this.get('memberEntries'))) {
          var members = this.get('memberEntries');

          if (members.get('length') === 1 && members.isAny('id', this.get('auth.member.id'))) {
            return this.clockOut(members.get('firstObject'), forceSave).then(function () {
              return _this3.sendAction('onClose');
            });
          } else {
            var promise = [];
            members.forEach(function (member) {
              promise.push(_this3.clockOut(member, true, true));
            });

            if (this.get('features.safetySignOff') && (this.get('auth.organization.safetySignature') || this.get('auth.organization.timeAccuracy'))) {
              this.set('showSignOffRequiredDialog', true);
            }

            return Ember.RSVP.all(promise).then(function () {
              return _this3.sendAction('onClose');
            });
          }
        } else {
          return this.clockOut(this.get('member'), forceSave).then(function () {
            return _this3.sendAction('onClose');
          });
        }
      },
      closeSafetyDialog: function closeSafetyDialog() {
        // triggers the event callback to cancel the entry save
        this.cancelSafetySignoff();
        this.set('showSafetyDialog', false);
      },
      closeSafetyRequiredDialog: function closeSafetyRequiredDialog() {
        // triggers the event callback to save the entry
        this.saveSafetySignoff();
        this.set('showSignOffRequiredDialog', false);
      },
      safetyClockOut: function safetyClockOut() {
        // triggers the event callback to save the entry
        this.saveSafetySignoff();
        this.set('showSafetyDialog', false);
      }
    }
  });
});