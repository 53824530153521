define('busy-app/utils/clock/data', ['exports', '@busy-web/ember-date-time/utils/clock/data'], function (exports, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _data.default;
    }
  });
});