define('busy-app/components/time/break-entry/list', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-break-entry-list'],

		model: null,

		actions: {
			closeAction: function closeAction() {
				this.sendAction('onClose');
			},
			addNewAction: function addNewAction() {
				this.sendAction('onCreate');
			},
			editAction: function editAction(item) {
				if (!Ember.isNone(item.get('endTime'))) {
					this.sendAction('onEdit', item);
				}
			}
		}
	});
});