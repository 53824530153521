define('busy-app/utils/employee-csv-import', ['exports', 'busy-app/utils/csv-import', '@busy-web/utils', 'cryptojs'], function (exports, _csvImport, _utils, _cryptojs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _csvImport.default.extend({
    headerLists: null,
    hasInvalidData: false,

    payPeriods: Ember.inject.service('pay-period'),

    defaultMap: Ember.computed(function () {
      return {
        memberNumber: "Employee ID",
        firstName: "First Name",
        lastName: "Last Name",
        username: "Username",
        password: "Password",
        payrollRate: "Payroll Rate",
        payrollType: "Payroll Type",
        phone: "Phone",
        email: "Email"
      };
    }),

    memberNumberTransform: function memberNumberTransform(number) {
      if (!Ember.isNone(number)) {
        return number.toString().trim().replace(/[^\w\d-.!@*&$#%(),?;:]/g, '');
      }
      return null;
    },
    generateUsername: function generateUsername(firstName, lastName) {
      if (Ember.isNone(lastName) || Ember.isNone(lastName)) {
        this.triggerError("First Name and Last Name are required to import an employee!");
        return;
      }

      firstName = firstName.trim().charAt(0);
      lastName = lastName.trim().replace(/[ ,.-]/g, '').slice(0, 7);

      var randomNum = Math.floor(Math.random() * 10000 + 1000).toString().slice(0, 4);
      var username = (firstName + lastName).toLowerCase() + randomNum;
      return username.slice(0, 12);
    },
    generatePassword: function generatePassword() {
      var now = _utils.Time.date();
      var orgName = this.get('organization.organizationName').toLowerCase().slice(0, 6).trim();
      return orgName + now.format('DDMM');
    },
    passwordTransform: function passwordTransform(password, model) {
      if (!Ember.isNone(password)) {
        if (password.length < 8) {
          password = this.generatePassword();
          model.set('invalidPassword', true);
          this.set('hasInvalidData', true);
        }
      } else {
        password = this.generatePassword();
      }
      return password;
    },
    usernameTransform: function usernameTransform(username, model) {
      if (!Ember.isNone(username)) {
        if (!/^\S{6}\S*$/.test(username)) {
          username = this.generateUsername(model.get('firstName'), model.get('lastName'));
          model.set('invalidUsername', true);
          this.set('hasInvalidData', true);
        }
      } else {
        username = this.generateUsername(model.get('firstName'), model.get('lastName'));
      }
      return username;
    },
    firstNameTransform: function firstNameTransform(name, model) {
      if (typeof name === 'string' && !Ember.isEmpty(name)) {
        return name;
      } else {
        model.set('invalidName', true);
        this.set('hasInvalidData', true);
        return 'InvalidName';
      }
    },
    lastNameTransform: function lastNameTransform(name, model) {
      if (typeof name === 'string' && !Ember.isEmpty(name)) {
        return name;
      } else {
        model.set('invalidName', true);
        this.set('hasInvalidData', true);
        return 'InvalidName';
      }
    },
    payrollRateTransform: function payrollRateTransform(rate, model) {
      var payrollRate = null;
      if (!Ember.isNone(rate)) {
        payrollRate = parseFloat(rate.toString().replace(/[$,]/g, ''));
        if (isNaN(payrollRate)) {
          payrollRate = 0;
          model.set('invalidPayrollRate', true);
          this.set('hasInvalidData', true);
        }
        payrollRate = payrollRate.toString();
      }
      return payrollRate;
    },
    emailTransform: function emailTransform(email, model) {
      if (!Ember.isNone(email)) {
        if (typeof email === 'string' && /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
          return email;
        } else {
          model.set('invalidEmail', true);
          this.set('hasInvalidData', true);
        }
      }
      return null;
    },
    phoneTransform: function phoneTransform(phone, model) {
      if (!Ember.isNone(phone)) {
        if (typeof phone === 'string') {
          phone = phone.replace(/\D/g, '');
          if (/\d{10,}/.test(phone)) {
            return phone;
          } else {
            model.set('invalidPhone', true);
            this.set('hasInvalidData', true);
          }
        } else {
          model.set('invalidPhone', true);
          this.set('hasInvalidData', true);
        }
      }
      return null;
    },
    payrollTypeTransform: function payrollTypeTransform(type, model) {
      if (!Ember.isNone(type) && typeof type === 'string') {
        var payrollType = type.toLowerCase();
        if (/((hr)|(hour))/.test(payrollType)) {
          return 'hourly';
        } else if (/((wk)|(week))/.test(payrollType)) {
          return 'weekly';
        } else if (/((mo)|(month))/.test(payrollType)) {
          return 'monthly';
        } else if (/((yr)|(year)|(sal))/.test(payrollType)) {
          return 'yearly';
        } else {
          model.set('invalidPayrollType', true);
          this.set('hasInvalidData', true);
        }
      }
      return null;
    },
    triggerParse: function triggerParse() {
      this.parse();
    },
    setHeaderByListType: function setHeaderByListType(listItem) {
      this.set('headerMap.' + listItem.get('mapKey'), listItem.get('value'));
    },
    buildHeaderLists: function buildHeaderLists(headers) {
      var _this = this;

      var defaultMap = this.get('defaultMap');
      defaultMap = this.setHeaderMap(defaultMap);

      var headerList = [];
      Ember.$.each(defaultMap, function (mapKey, mapHeader) {
        var simpleKey = mapHeader.camelize();
        if (defaultMap.hasOwnProperty(mapKey)) {
          var list = Ember.Object.create({ key: mapKey, label: mapHeader, value: [] });

          headers.forEach(function (header) {
            var simpleHeader = header.camelize();
            var isSelected = simpleKey === simpleHeader;
            var item = Ember.Object.create({ key: simpleHeader, mapKey: mapKey, value: header, _selected: isSelected });
            if (isSelected) {
              _this.setHeaderByListType(item);
            }
            list.get('value').pushObject(item);
          });

          headerList.pushObject(list);
        }
      });
      this.set('headerLists', headerList);
    },
    createNewModel: function createNewModel() {
      var positions = this.get('auth.positions');
      positions.sortBy('level');

      var position = positions.get('lastObject');
      return this.get('store').createRecord('member', {
        id: _utils.UUID.generate(),
        organizationId: this.get('organizationId'),
        positionId: position.get('id'),
        position: position
      });
    },
    afterSave: function afterSave(model) {
      var wageHistory = null;
      if (!Ember.isNone(model.get('payrollType')) && !Ember.isNone(model.get('payrollRate'))) {
        var payrollRate = parseFloat(model.get('payrollRate').replace(/[$,]/g, ''));
        if (isNaN(payrollRate)) {
          payrollRate = 0;
        }

        var wageRate = null;
        var workWeek = 40;
        var payrollType = model.get('payrollType').toLowerCase();
        if (/((wk)|(week))/.test(payrollType)) {
          wageRate = 30;
        } else if (/((mo)|(month))/.test(payrollType)) {
          wageRate = 40;
        } else if (/((yr)|(year)|(sal))/.test(payrollType)) {
          wageRate = 50;
        } else {
          wageRate = 10;
          workWeek = null;
        }

        if (!Ember.isNone(wageRate)) {
          var obj = {
            id: _utils.UUID.generate(),
            member_id: model.id,
            wage: model.get('payrollRate'),
            wage_rate: wageRate,
            effective_rate: payrollRate,
            change_date: this.getPayPeriods(),
            overburden: 0,
            created_on: _utils.Time.timestamp()
          };

          if (!Ember.isNone(workWeek)) {
            obj.work_week = workWeek;
          }
          wageHistory = this.prepareObject('wage-history', obj);
        }
      }
      return wageHistory;
    },
    getPayPeriods: function getPayPeriods() {
      var start = _utils.Time.date().endOf('day').subtract(1, 'days').unix();
      var end = _utils.Time.date().startOf('day').subtract(88, 'days').unix();
      var payPeriods = this.get('payPeriods').getPayPeriodDateRange(start, end, true);
      return payPeriods.objectAt(2).get('startDate').unix();
    },
    onFinishFileParse: function onFinishFileParse(headers, data) {
      this._super(headers, data);
      this.buildHeaderLists(headers);
    },
    hasModel: function hasModel(model) {
      var found = this.get('model').find(function (item) {
        if (item.get('firstName') === model.get('firstName') && item.get('lastName') === model.get('lastName')) {
          return true;
        }
        return false;
      });
      return !Ember.isNone(found) ? found : false;
    },
    modelHasData: function modelHasData(model) {
      var firstName = (model.get('firstName') || '').trim();
      var lastName = (model.get('lastName') || '').trim();
      if (!Ember.isEmpty(firstName) && !Ember.isEmpty(lastName)) {
        return true;
      }
      return false;
    },
    _saveModels: function _saveModels(models) {
      var _this2 = this;

      var dataArray = [];
      models.forEach(function (item) {
        var obj = {
          id: item.id,
          first_name: item.get('firstName'),
          last_name: item.get('lastName'),
          username: item.get('username'),
          password: _cryptojs.default.SHA256(item.get('password')).toString(),
          position_id: item.get('positionId'),
          organization_id: item.get('organizationId'),
          created_on: _utils.Time.timestamp()
        };

        if (!Ember.isNone(item.get('email')) && !Ember.isEmpty(item.get('email')) && /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(item.get('email'))) {
          obj.email = item.get('email');
        }

        if (!Ember.isNone(item.get('phone')) && !Ember.isEmpty(item.get('phone'))) {
          obj.phone = item.get('phone');
        }

        if (!Ember.isNone(item.get('memberNumber')) && !Ember.isEmpty(item.get('memberNumber'))) {
          obj.member_number = item.get('memberNumber');
        }

        var memberData = _this2.prepareObject('member', obj);
        dataArray.push(memberData);

        var wageHistory = _this2.afterSave(item);
        if (!Ember.isNone(wageHistory)) {
          dataArray.push(wageHistory);
        }
      });

      return this.sendRequest(dataArray, 5);
    }
  });
});