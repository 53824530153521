define('busy-app/models/pay-period-member-cost', ['exports', 'ember-data', 'moment', '@busy-web/utils'], function (exports, _emberData, _moment, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		endTime: _emberData.default.attr('number'),
		regularCost: _emberData.default.attr('number', { defaultValue: 0 }),
		overtimeCost: _emberData.default.attr('number', { defaultValue: 0 }),
		doubletimeCost: _emberData.default.attr('number', { defaultValue: 0 }),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		dateString: Ember.computed('startTime', 'endTime', function () {
			// get start and end moment objects
			var start = (0, _moment.default)(this.get('startTime') * 1000);
			var end = (0, _moment.default)(this.get('endTime') * 1000);

			// format the moment objects to strings
			start = start.format(_utils.Locale.format('MMM D', _moment.default.locale()));
			end = end.format(_utils.Locale.format('MMM D, YYYY', _moment.default.locale()));

			// return the string
			return start + ' - ' + end;
		}),

		totalCost: Ember.computed('regularCost', 'overtimeCost', 'doubletimeCost', function () {
			return this.get('regularCost') + this.get('overtimeCost') + this.get('doubletimeCost');
		}),

		totalRegularCost: Ember.computed.alias('regularCost'),
		totalOvertimeCost: Ember.computed.alias('overtimeCost'),
		totalDoubletimeCost: Ember.computed.alias('doubletimeCost'),
		totalTotalCost: Ember.computed.alias('totalCost'),

		/*** deprecated ****/
		regularCostString: Ember.computed('regularCost', function () {
			return this.convertDollars(this.get('regularCost') || 0);
		}),

		overtimeCostString: Ember.computed('overtimeCost', function () {
			return this.convertDollars(this.get('overtimeCost') || 0);
		}),

		doubletimeCostString: Ember.computed('doubletimeCost', function () {
			return this.convertDollars(this.get('doubletimeCost') || 0);
		}),

		totalCostString: Ember.computed('totalCost', function () {
			return this.convertDollars(this.get('totalCost'));
		}),

		convertDollars: function convertDollars(total) {
			return total.toFixed(2);
		}
	});
});