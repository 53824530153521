define('busy-app/utils/models/managers/member-conflicts', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/time-card-conflicts', 'busy-app/utils/time-entry-validator'], function (exports, _utils, _timeCardConflicts, _timeEntryValidator) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var _MemberObject = Ember.Object.extend({
		member: null,
		conflicts: null,

		totalConflicts: Ember.computed('conflicts.length', function () {
			return this.get('conflicts.length');
		}),

		conflictText: Ember.computed('totalConflicts', function () {
			return this.get('totalConflicts') > 1 ? 'conflicts' : 'conflict';
		})
	});

	/**
  * `Util/Model/Manager/MemberConflicts`
  *
  * @class MemberConflicts
  * @namespace Utils.Models.Managers
  * @extends Utils.Models.Managers.TimeCardConflicts
  */
	/**
  * @module Utils/Models/Managers
  *
  */
	exports.default = _timeCardConflicts.default.extend({
		fetch: function fetch() {
			return this._super.apply(this, arguments);
		},
		isInDay: function isInDay(entry, day) {
			if (entry.get('startTime') > day && entry.get('startTime') <= _utils.Time.date(day).endOf('day').unix()) {
				return true;
			}
			return false;
		},
		filterEntriesByDay: function filterEntriesByDay(day, entries) {
			var _this = this;

			return entries.filter(function (item) {
				if (_this.isInDay(item, day)) {
					return item;
				}
			});
		},
		filterMembersByDay: function filterMembersByDay(day, members, entries) {
			return members.filter(function (member) {
				var entry = entries.findBy('memberId', member.id);
				if (!Ember.isNone(entry)) {
					return member;
				}
			});
		},
		buildCollections: function buildCollections(data) {
			var _this2 = this;

			var days = [];
			data.entries.sortBy('startTime').reverse().forEach(function (item) {
				var day = _utils.Time.date(item.get('startTime')).startOf('day').unix();
				if (days.indexOf(day) === -1) {
					days.push(day);
				}
			});

			days.forEach(function (day) {
				var todayEntries = _this2.filterEntriesByDay(day, data.entries);
				var todayMembers = _this2.filterMembersByDay(day, data.members, todayEntries);
				var dayObject = Ember.Object.create({ day: day, members: [] });
				todayMembers.forEach(function (member) {
					var entries = todayEntries.filterBy('memberId', member.id).sortBy('createdOn');
					var conflicts = _this2.checkConflict(entries);
					entries.forEach(function (entry) {
						_this2.setEntryData(entry, data);
					});

					if (conflicts.length > 0) {
						var memberObject = _MemberObject.create({ member: member, conflicts: conflicts });
						dayObject.get('members').pushObject(memberObject);
					}
				});

				if (dayObject.get('members.length')) {
					_this2.addCollection(dayObject);
				}
			});
		},
		checkConflict: function checkConflict(entries) {
			var _this3 = this;

			var conflicts = [];
			var collections = [];
			entries.forEach(function (entry1) {
				entries.forEach(function (entry2) {
					if (conflicts.indexOf(entry1.id + entry2.id) === -1 && entry1.id !== entry2.id) {
						conflicts.push(entry1.id + entry2.id);
						conflicts.push(entry2.id + entry1.id);
						if (_timeEntryValidator.default.hasConflict(entry1, entry2)) {
							collections.pushObject(_this3.createTimeCardConflict(entry1, entry2));
						}
					}
				});
			});
			return collections;
		},
		createTimeCardConflict: function createTimeCardConflict() {
			var collection = this.collectionFor('time-card-conflicts');
			return collection.push.apply(collection, arguments);
		}
	});
});