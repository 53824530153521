define('busy-app/components/dialogs/modal-dialog', ['exports', '@busy-web/components/mixins/close-on-escape'], function (exports, _closeOnEscape) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var kDefaults = {
		translucentOverlay: true,
		renderInPlace: false,
		clickOutsideToClose: false
	};

	/**
  * `Component\Dialogs\ModalDialog`
  *
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend(_closeOnEscape.default, {
		className: 'c-dialogs-modal-dialog',

		_classNames: Ember.computed('basic', function () {
			var classNames = this.get('className');
			if (this.get('basic')) {
				classNames += ' basic';
			}

			if (this.get('small')) {
				classNames += ' small';
			}

			if (this.get('fixed')) {
				classNames += ' fixed';
			}

			return classNames;
		}),

		basic: false,
		small: false,
		fixed: false,

		showCloseButton: true,

		init: function init() {
			var _this = this;

			this._super();
			Object.keys(kDefaults).forEach(function (item) {
				var prop = _this.getWithDefault(item, kDefaults[item]);
				_this.set(item, prop);
			});
		},
		didInsertElement: function didInsertElement() {
			this._super();
			this.bindEscape();
		},
		onEscape: function onEscape() {
			this.send('closeAction');
			return false;
		},


		actions: {
			closeAction: function closeAction() {
				this.sendAction('onClose');
			},
			closeButtonAction: function closeButtonAction() {
				this.sendAction('onCloseButton');
			}
		}
	});
});