define('busy-app/components/busy-action-bar', ['exports', '@busy-web/utils/loc', 'busy-app/utils/logger'], function (exports, _loc, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		features: Ember.inject.service('features'),
		interop: Ember.inject.service('interop'),

		classNames: [''],
		classNameBindings: ['features.flexActionBar:busy-action-bar-flex:busy-action-bar', 'isFlipped:flip', 'hasAddAction:add', 'filterMenu:filter', 'groupMenu:group', 'selectMenu:select', 'isSub:no-padding'],

		selectMenu: null,
		filterMenu: null,

		isFlipped: false,
		hasAddAction: false,

		groupMenu: null,
		groupEditPermission: false,
		selectedGroup: null,

		search: '',
		placeholder: (0, _loc.default)('Search'),

		interopTarget: null, // url script for interop to target, for example, '/manage/employees'
		interopLabel: (0, _loc.default)('Management Tool'),

		__groupMenuTop: null,
		__groupMenuBottom: null,
		__filterMenu: null,

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();
			this.setupGroups();
			this.setupFilter();
		},
		getDefaultGroups: function getDefaultGroups() {
			return [Ember.Object.create({
				id: null,
				groupName: (0, _loc.default)('All ') + Ember.get(this, 'defaultTitle'),
				defaultGroup: true,
				_selected: false
			}), Ember.Object.create({ id: 'ungrouped', groupName: (0, _loc.default)('Ungrouped'), ungroupedGroup: true, _selected: false }), Ember.Object.create({ id: 'archived', groupName: (0, _loc.default)('Archived'), archivedGroup: true, _selected: false })];
		},
		setupGroups: function setupGroups() {
			var group = Ember.get(this, 'groupMenu');
			if (!Ember.isNone(group)) {
				if (group.toArray) {
					group = group.toArray();
				}

				var selectedGroup = Ember.get(this, 'selectedGroup');
				var defaultGroups = this.getDefaultGroups();

				var item = void 0;
				if (!Ember.isNone(selectedGroup)) {
					item = defaultGroups.findBy('id', selectedGroup);
					if (Ember.isNone(item)) {
						item = group.findBy('id', selectedGroup);
					}
				}

				// apply filter a filter is currently selected
				this.applyFilter(item, 'group');

				// set top group menu
				Ember.set(this, '__groupMenuTop', defaultGroups);

				// set bottom group menu
				Ember.set(this, '__groupMenuBottom', group);
			}
		},
		setupFilter: function setupFilter() {
			var filterMenu = Ember.get(this, 'filterMenu');
			if (!Ember.isNone(filterMenu)) {
				if (filterMenu.toArray) {
					filterMenu = filterMenu.toArray();

					var selected = filterMenu.findBy('_selected', true);
					this.applyFilter(selected, 'filter');

					Ember.set(this, '__filterMenu', filterMenu);
				}
			}
		},
		applyFilter: function applyFilter(item, type) {
			var filterName = Ember.get(item || {}, 'groupName') || Ember.get(item || {}, 'value') || null;

			Ember.set(this, 'filter', filterName);
			Ember.set(this, 'filterApplied', !Ember.isEmpty(filterName));

			if (type === 'group') {
				if (!Ember.isNone(item)) {
					Ember.set(item, '_selected', true);
					Ember.set(this, 'isGroup', true);
				} else {
					Ember.set(this, 'isGroup', false);
				}
			} else if (type === 'filter') {
				Ember.set(this, 'isFilter', !Ember.isNone(item));
			}
		},
		openInterop: function openInterop() {
			var interop = Ember.get(this, 'interop');
			var reactAppPath = Ember.get(this, 'interopTarget');
			console.log('openInterop -> reactAppPath', reactAppPath);

			return interop.openReactApp(reactAppPath);
		},


		// hasFilter: observer('filterMenu', '__groupMenuTop', function() {
		//   if (!isNone(get(this, 'filterMenu')) || !isNone(get(this, '__groupMenuTop'))) {
		//     return true;
		//   }
		//   return false;
		// }),

		actions: {
			submit: function submit() {
				Ember.set(this, 'isFlipped', false);
				this.sendAction('onSubmit');
			},
			cancel: function cancel() {
				Ember.set(this, 'isFlipped', false);
				this.sendAction('onCancel');
			},
			addAction: function addAction() {
				this.sendAction('onAdd');
			},
			selectAction: function selectAction(item) {
				Ember.set(this, 'isFlipped', true);
				Ember.set(item, '_selected', false);

				this.sendAction('onSelect', item);
			},
			searchAction: function searchAction(keyword) {
				this.sendAction('onSearch', keyword);
			},
			filterAction: function filterAction(item) {
				this.applyFilter(item, 'filter');

				if (Ember.get(item, 'value') === 'Archived') {
					Ember.set(this, 'archived', true);
				}

				this.sendAction('onFilter', item);
			},
			clearFilter: function clearFilter() {
				//clear filter type
				if (Ember.get(this, 'isFilter') === true) {
					// reset filter
					this.applyFilter(null, 'filter');

					var defaultFilter = Ember.Object.create({ key: 'active' });
					this.sendAction('onFilter', defaultFilter);
				}

				// clear group filter type
				if (Ember.get(this, 'isGroup') === true) {
					Ember.get(this, 'groupMenu').forEach(function (item) {
						return Ember.set(item, '_selected', false);
					});

					// reset filter
					this.applyFilter(null, 'group');

					var defaultGroup = Ember.get(this, '__groupMenuTop').findBy('defaultGroup', true);
					this.sendAction('onSelectGroup', defaultGroup, true);
				}
			},
			itemSelectAction: function itemSelectAction(item) {
				this.applyFilter(item, 'group');
				this.sendAction('onSelectGroup', item, Ember.get(item, 'defaultGroup') === true);
			},
			addNewGroup: function addNewGroup(value) {
				value = value.trim();
				if (!Ember.isEmpty(value)) {
					this.sendAction('addGroup', value);
				}
			},
			onDeleteGroup: function onDeleteGroup(value) {
				_logger.default.info(this, 'onDeleteGroup', value);
				this.sendAction('onDeleteGroup', value);
			},
			interop: function interop() {
				_logger.default.info(this, 'interop');
				this.openInterop();
			}
		}
	});
});