define('busy-app/polymorphs/payroll-project', ['exports', 'busy-app/utils/collection', 'busy-app/polymorphs/pay-period-member-project'], function (exports, _collection, _payPeriodMemberProject) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _payPeriodMemberProject.default.extend({
		members: null,
		disableDepth: false,
		hideData: true,

		depthString: Ember.computed('project.depth', 'disableDepth', function () {
			var depth = this.get('project.depth');
			var depthString = '';
			if (!this.get('disableDepth')) {
				depthString = depth === 1 ? 'one' : depthString;
				depthString = depth === 2 ? 'two' : depthString;
				depthString = depth === 3 ? 'three' : depthString;
				depthString = depth === 4 ? 'four' : depthString;
				depthString = depth === 5 ? 'five' : depthString;
				depthString = depth === 6 ? 'six' : depthString;
				depthString = depth === 7 ? 'seven' : depthString;
			}
			return depthString;
		}),

		depthList: Ember.computed('project.depth', 'disableDepth', function () {
			var depth = this.get('project.depth');
			var array = [];
			if (depth > 0 && !this.get('disableDepth')) {
				for (var i = 0; i < depth; i++) {
					array.push(i);
				}
			}
			return array;
		}),

		hasChildren: Ember.computed('members.@each.hasChildren', 'project.depth', function () {
			var hasChildren = false;
			if (this.get('forceOpen') && this.get('members.length')) {
				hasChildren = true;
			} else if (!Ember.isEmpty(this.get('members'))) {
				this.get('members').forEach(function (member) {
					if (member.get('hasChildren')) {
						hasChildren = true;
					}
				});
			}
			return hasChildren;
		}),

		hasRegular: Ember.computed('totalRegularSeconds', 'totalRegularCost', function () {
			if (this.get('totalRegularSeconds') > 0 || this.get('totalRegularCost') > 0) {
				return true;
			}
			return false;
		}),

		hasOvertime: Ember.computed('totalOvertimeSeconds', 'totalOvertimeCost', function () {
			if (this.get('totalOvertimeSeconds') > 0 || this.get('totalOvertimeCost') > 0) {
				return true;
			}
			return false;
		}),

		hasDoubletime: Ember.computed('totalDoubletimeSeconds', 'totalDoubletimeCost', function () {
			if (this.get('totalDoubletimeSeconds') > 0 || this.get('totalDoubletimeCost') > 0) {
				return true;
			}
			return false;
		}),

		totalRegularSeconds: Ember.computed('members.@each.{totalRegularSeconds,regularSeconds}', function () {
			var _this = this;

			var total = 0;
			if (!Ember.isNone(this.get('members'))) {
				this.get('members').forEach(function (item) {
					if (_this.get('includeChildren')) {
						total += item.get('totalRegularSeconds');
					} else {
						total += item.get('regularSeconds');
					}
				});
			}
			return total;
		}),

		totalOvertimeSeconds: Ember.computed('members.@each.{totalOvertimeSeconds,overtimeSeconds}', function () {
			var _this2 = this;

			var total = 0;
			if (!Ember.isNone(this.get('members'))) {
				this.get('members').forEach(function (item) {
					if (_this2.get('includeChildren')) {
						total += item.get('totalOvertimeSeconds');
					} else {
						total += item.get('overtimeSeconds');
					}
				});
			}
			return total;
		}),

		totalDoubletimeSeconds: Ember.computed('members.@each.{totalDoubletimeSeconds,doubletimeSeconds}', function () {
			var _this3 = this;

			var total = 0;
			if (!Ember.isNone(this.get('members'))) {
				this.get('members').forEach(function (item) {
					if (_this3.get('includeChildren')) {
						total += item.get('totalDoubletimeSeconds');
					} else {
						total += item.get('doubletimeSeconds');
					}
				});
			}
			return total;
		}),

		totalRegularCost: Ember.computed('includeChildren', 'members.@each.{totalRegularCost,regularCost}', function () {
			var _this4 = this;

			var total = 0;
			if (!Ember.isNone(this.get('members'))) {
				this.get('members').forEach(function (item) {
					if (_this4.get('includeChildren')) {
						total += item.get('totalRegularCost');
					} else {
						total += item.get('regularCost');
					}
				});
			}
			return total;
		}),

		totalOvertimeCost: Ember.computed('members.@each.totalOvertimeCost', function () {
			var _this5 = this;

			var total = 0;
			if (!Ember.isNone(this.get('members'))) {
				this.get('members').forEach(function (item) {
					if (_this5.get('includeChildren')) {
						total += item.get('totalOvertimeCost');
					} else {
						total += item.get('overtimeCost');
					}
				});
			}
			return total;
		}),

		totalDoubletimeCost: Ember.computed('members.@each.totalDoubletimeCost', function () {
			var _this6 = this;

			var total = 0;
			if (!Ember.isNone(this.get('members'))) {
				this.get('members').forEach(function (item) {
					if (_this6.get('includeChildren')) {
						total += item.get('totalDoubletimeCost');
					} else {
						total += item.get('doubletimeCost');
					}
				});
			}
			return total;
		}),

		totalOverburdenCost: Ember.computed('members.@each.{totalOverburdenCostWithChildren,totalOverburdenCost}', function () {
			var includeChildren = this.get('includeChildren');
			var members = this.get('members');
			var sumByKey = includeChildren ? 'totalOverburdenCostWithChildren' : 'totalOverburdenCost';

			var total = Ember.isNone(members) ? 0 : (0, _collection.sumBy)(members, sumByKey);

			return total;
		}),

		totalOverburdenCostWithChildren: Ember.computed('members.@each.totalOverburdenCostWithChildren', function () {
			var members = this.get('members');
			var total = Ember.isNone(members) ? 0 : (0, _collection.sumBy)(members, 'totalOverburdenCostWithChildren');

			return total;
		}),

		estCostHourly: Ember.computed('members.@each.estCostHourlyWithChildren', function () {
			var estCostHourly = 0;
			if (!Ember.isEmpty(this.get('members'))) {
				this.get('members').forEach(function (item) {
					estCostHourly += item.get('estCostHourlyWithChildren');
				});
			}
			return estCostHourly;
		}),

		estCostSalary: Ember.computed('members.@each.estCostSalaryWithChildren', function () {
			var estCostSalary = 0;
			if (!Ember.isEmpty(this.get('members'))) {
				this.get('members').forEach(function (item) {
					estCostSalary += item.get('estCostSalaryWithChildren');
				});
			}
			return estCostSalary;
		})
	});
});