define('busy-app/components/busy-header-floating', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-header-floating', 'page-hide'],
		classNameBindings: ['isResponsive:is-responsive:not-responsive'],
		tagName: 'header',

		isResponsive: true
	});
});