define('busy-app/utils/models/filters/time-off', ['exports', '@busy-web/utils', 'busy-app/utils/models/filters/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		/**
   * returns a date range of time-entries with no dependent
   * models included.
   *
   * @public
   * @method range
   * @param start {number} start epoch date
   * @param end {number} end epoch date
   * @param memberId {string} member model id
   * @returns {promise}
   */
		range: function range(start, end, memberId) {
			_utils.Assert.isNumber(start);
			_utils.Assert.isNumber(end);

			var query = {
				_gte: { date_stamp: start },
				_lte: { date_stamp: end },
				_desc: ['date_stamp']
			};

			if (!Ember.isNone(memberId)) {
				_utils.Assert.isString(memberId);
				query.member_id = memberId;
			}

			if (window.runningTests) {
				return Ember.RSVP.resolve([]);
			}
			return this.store.query('time-off', query);
		},
		findAllByRange: function findAllByRange(start, end, memberId) {
			_utils.Assert.isNumber(start);
			_utils.Assert.isNumber(end);

			var query = {
				_gte: { date_stamp: start },
				_lte: { date_stamp: end },
				_desc: ['date_stamp']
			};

			if (!Ember.isNone(memberId)) {
				_utils.Assert.isString(memberId);
				query.member_id = memberId;
			}

			if (window.runningTests) {
				return Ember.RSVP.resolve([]);
			}
			return this.store.findAll('time-off', query);
		},
		getRelatedModels: function getRelatedModels(entry) {
			if (Ember.isNone(entry)) {
				entry = null;
			}
			return Ember.RSVP.hash({ entry: entry });
		}
	});
});