define('busy-app/models/schedule-reminder', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		organizationId: _emberData.default.attr('string'),
		memberId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		endTime: _emberData.default.attr('number'),
		payload: _emberData.default.attr('string'),
		type: _emberData.default.attr('string'),
		repeat: _emberData.default.attr('number'),
		notifyEmployee: _emberData.default.attr('boolean'),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),

		// NOTE:
		// scheduleLinks are getting loaded in the
		// scheduling/index route
		scheduleLinks: null,

		payloadJson: Ember.computed('payload', function () {
			if (Ember.isNone(this.get('payload'))) {
				return {};
			}
			return JSON.parse(this.get('payload'));
		}),

		projectId: Ember.computed('payloadJson', function () {
			return this.get('payloadJson.project');
		}),

		costCodeId: Ember.computed('payloadJson', function () {
			// proper payload format is underscore.
			return this.get('payloadJson.cost_code');
		}),

		equipmentId: Ember.computed('payloadJson', function () {
			return this.get('payloadJson.equipment');
		}),

		project: Ember.computed('projectId', function () {
			if (!Ember.isNone(this.get('projectId'))) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.findRecord('project', this.get('projectId'))
				});
			}
			return null;
		}),

		costCode: Ember.computed('costCodeId', function () {
			if (!Ember.isNone(this.get('costCodeId'))) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.findRecord('cost-code', this.get('costCodeId'))
				});
			}
			return null;
		}),

		equipment: Ember.computed('equipmentId', function () {
			if (!Ember.isNone(this.get('equipmentId'))) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.findRecord('equipment', this.get('equipmentId'))
				});
			}
			return null;
		}),

		startTimeFormatted: Ember.computed('startTime', function () {
			return _utils.Time.date(this.get('startTime')).format('h:mm A');
		}),

		startDayTime: Ember.computed('startTime', function () {
			return _utils.Time.date(this.get('startTime')).startOf('day').unix();
		}),

		endDayTime: Ember.computed('endTime', function () {
			return _utils.Time.date(this.get('endTime')).startOf('day').unix();
		}),

		startDay: Ember.computed('startTime', function () {
			return '(' + _utils.Time.date(this.get('startTime')).format('MMM D') + ')';
		}),

		endDay: Ember.computed('endTime', function () {
			return '(' + _utils.Time.date(this.get('endTime')).format('MMM D') + ')';
		}),

		endTimeFormatted: Ember.computed('endTime', function () {
			return _utils.Time.date(this.get('endTime')).format('h:mm A');
		}),

		instructions: Ember.computed('payloadJson', function () {
			return this.get('payloadJson.instructions');
		}),

		hasInstructions: Ember.computed('instructions', function () {
			return !Ember.isEmpty(this.get('instructions'));
		}),

		instructionsRows: Ember.computed('instructions', function () {
			var parts = this.get('instructions').split('\n');
			return parts.length + 1;
		}),

		isBreak: Ember.computed('type', function () {
			return this.get('type') === 'break';
		}),

		typeFormatted: Ember.computed('type', function () {
			return Ember.String.classify(this.get('type'));
		})
	});
});