define('busy-app/models/media-entry', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		mediaCollectionId: _emberData.default.attr('string'),
		file: _emberData.default.attr('file'),
		fileUrl: _emberData.default.attr('string'),
		fileThumbUrl: _emberData.default.attr('string'),
		createdBy: _emberData.default.attr('string'),
		captureDate: _emberData.default.attr('number'),
		comment: _emberData.default.attr('string'),
		entryType: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),

		mediaCollection: _emberData.default.belongsTo('media-collection'),
		member: _emberData.default.belongsTo('member', { referenceKey: 'createdBy', foreignKey: 'id' }),

		sinceCreated: Ember.computed('createdOn', function () {
			var time = '';
			if (!Ember.isNone(this.get('createdOn'))) {
				time = _utils.Time.date(this.get('createdOn')).fromNow();
			}
			return time;
		}),

		captureDateString: Ember.computed('captureDate', function () {
			var date = '';
			if (!Ember.isNone(this.get('captureDate'))) {
				date = _utils.Time.timeFormat(this.get('captureDate'), 'M/D/YYYY [@] h:mm A');
			}
			return date;
		}),

		imageSet: Ember.observer('file', function () {
			var _this = this;

			if (!Ember.isNone(this.get('file'))) {
				var reader = new window.FileReader();
				reader.onload = function (data) {
					_this.set('fileThumbUrl', data.target.result);
					_this.set('fileUrl', data.target.result);
				};
				reader.readAsDataURL(this.get('file.file'));
			}
		})
	});
});