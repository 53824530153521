define('busy-app/adapters/application', ['exports', '@busy-web/data', 'busy-app/utils/settings'], function (exports, _data, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = _data.JsonApiAdapter.extend(_data.SimpleAuthDataAdapterMixin, _data.ImageAdapterMixin, _data.BatchAdapterMixin, {
		auth: Ember.inject.service('auth'),
		session: Ember.inject.service('session'),
		features: Ember.inject.service('features'),

		hasManyFilterKey: '_in',
		coalesceFindRequests: true,
		isBatchEnabled: Ember.computed(function () {
			return _settings.default.get('batch_api');
		}),

		host: Ember.computed(function () {
			return _settings.default.get('api_url');
		}),

		// headers: computed('auth.sessionId', function () {
		// 	const { version, osVersion, osType, type } = browser;

		// 	return {
		// 		'X-Platform': 'web',
		// 		'X-Os': type,
		// 		'X-Os-Version': version,
		// 		'X-App-Name': 'time',
		// 		'X-App-Version': Settings.get('version'),
		// 		'X-Device-Model': `${osType} ${osVersion} - ${type} ${version}`,
		// 		'X-Client-Session-Id': this.get('auth.sessionId'),
		// 	};
		// }),

		authorize: function authorize(xhr) {
			var _get = this.get('session.data.authenticated'),
			    token = _get.token;

			if (token) {
				xhr.setRequestHeader('Key-Authorization', token);
			}
		},


		//findRecord(store, type, id, snapshot) {
		//  let request = this._requestFor({
		//    store, type, id, snapshot,
		//		query: {deleted_on: '_-DISABLE-_'},
		//    requestType: 'findRecord'
		//  });

		//  return this._makeRequest(request);
		//},

		getBatchStatusForModel: function getBatchStatusForModel() {
			var status = this._super.apply(this, arguments);
			return parseInt(('' + status).replace(/^([0-9]*) [\s\S]*$/, '$1'), 10);
		},
		getBatchStatusTextForModel: function getBatchStatusTextForModel(result, defaultValue) {
			var statusText = Ember.get(result, 'status') || defaultValue;
			return statusText.replace(/^[0-9]* ([\s\S]*)$/, '$1');
		},
		addBatchParams: function addBatchParams(query) {
			query._version = _settings.default.get('api_version');
			delete query.data._version;

			if (_settings.default.get('debug_mode')) {
				query._debug = true;
				delete query.data._debug;
			}

			if (_settings.default.get('xdebug_session_start')) {
				query.XDEBUG_SESSION_START = _settings.default.get('xdebug_session_start');
				delete query.data.XDEBUG_SESSION_START;
			}
		},
		modifyNullStrings: function modifyNullStrings(data) {
			var nullValue = '_-NULL-_';
			var invalidNull = null;
			if (this.get('features.server-null')) {
				nullValue = null;
				invalidNull = '_-NULL-_';
			}

			if (data) {
				Object.keys(data).forEach(function (item) {
					var value = Ember.get(data, item);
					if (value === invalidNull) {
						//deprecate(`{${item}: '_-NULL-_'} is deprecated please use {${item}: null} instead.`, !this.get('features.server-null'), { id: 'adapter.params.null', until: "6.0" });
						Ember.set(data, item, nullValue);
					} else if (value === '!null') {
						Ember.set(data, '_not_equal', _defineProperty({}, item, null));
						delete data[item];
					}
				});
			}
		},
		addDefaultParams: function addDefaultParams(data, type) {
			// parse data params and remove '_-NULL-_' strings.
			this.modifyNullStrings(data);

			if (type === 'GET') {
				if (Ember.isNone(Ember.get(data, 'deleted_on')) && Ember.isNone(Ember.get(data, '_in'))) {
					if (this.get('features.server-null')) {
						if (Ember.get(data, 'deleted_on') === undefined) {
							Ember.set(data, 'deleted_on', null);
						}
					} else {
						Ember.set(data, 'deleted_on', '_-NULL-_');
					}
				} else if (Ember.get(data, 'deleted_on') === '_-DISABLE-_') {
					delete data.deleted_on;
				}
			}
		},
		addUrlParams: function addUrlParams(data) {
			Ember.set(data, '_version', _settings.default.get('api_version'));

			// set debug flag
			if (_settings.default.get('debug_mode')) {
				Ember.set(data, '_debug', true);

				if (_settings.default.get('xdebug_session_start')) {
					Ember.set(data, 'XDEBUG_SESSION_START', _settings.default.get('xdebug_session_start'));
				}
			}
		},
		isSuccess: function isSuccess(status, headers, payload) {
			var isSuccess = this._super(status, headers, payload);
			if (isSuccess && !Ember.isNone(payload) && (!Ember.isNone(Ember.get(payload, 'jsonapi')) || Ember.get(payload, 'success') === true)) {
				return true;
			} else {
				return false;
			}
		},
		_requestToJQueryAjaxHash: function _requestToJQueryAjaxHash(request) {
			// setup special headers like auth
			var authHeader = Ember.get(request, 'data.auth_header');
			if (!Ember.isNone(authHeader)) {
				Ember.merge(request.headers, authHeader);
				delete request.data.auth_header;

				request.disableBatch = true;
			}
			return this._super(request);
		},
		ajaxOptions: function ajaxOptions() {
			var hash = this._super.apply(this, arguments);

			// setup special headers like auth
			var headers = null;
			if (!Ember.isNone(Ember.get(hash, 'data.auth_header'))) {
				headers = Ember.get(hash, 'data.auth_header');
				delete hash.data.auth_header;
				Ember.set(hash, 'disableBatch', true);
			}

			if (!Ember.isNone(headers)) {
				hash.beforeSend = function (request) {
					Object.keys(headers).forEach(function (key) {
						request.setRequestHeader(key, headers[key]);
					});
				};
			}

			return hash;
		}
	});
});