define('busy-app/utils/models/managers/payroll-member-project-cost-code-csv', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/payroll-member-csv'], function (exports, _utils, _payrollMemberCsv) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _payrollMemberCsv.default.extend({
		showProject: true,
		showCostCode: true,

		filename: Ember.computed('start', 'end', function () {
			var start = _utils.Time.date(this.get('start')).format('MM-DD-YY');
			var end = _utils.Time.date(this.get('end')).format('MM-DD-YY');

			return 'Payroll_Daily_By_Project_Cost_Code_' + start + '_to_' + end;
		}),

		getSorted: function getSorted() {
			return this.get('model').sortBy('time', 'member.fullName', 'wageTypeOrder', 'depth1Sort', 'depth2', 'depth3', 'depth4', 'depth5', 'depth6', 'depth7', 'costCode.costCode');
		}
	});
});