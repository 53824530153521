define('busy-app/utils/api-error', ['exports', '@busy-web/utils', 'busy-app/utils/_model-errors', 'busy-app/utils/_rpc-model-errors'], function (exports, _utils, _modelErrors2, _rpcModelErrors2) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Utils
  *
  */
	var ApiError = Ember.Object.extend();
	var kDefaultError = (0, _utils.loc)('Something went wrong! Please try again.');

	/**
  * `Util/ApiError`
  *
  * @class ApiError
  * @namespace Utils
  * @extends EmberObject
  */
	ApiError.reopenClass({
		getError: function getError(error) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(error);

			// get the code from result.code in the object
			// if not look for just code.
			var code = Ember.get(error, 'result.code');
			if (Ember.isNone(code)) {
				code = Ember.get(error, 'code');
			}

			var result = void 0;

			// if the code is an array the return the first code as an int.
			// else assume the code is a string and convet it to an int.
			if (Ember.isArray(code) && !Ember.isEmpty(code)) {
				result = parseInt(code[0], 10);
			} else if (!Ember.isNone(code)) {
				result = parseInt(code, 10);
			}

			// if null or undefined or NaN then set the code to -1
			if (Ember.isNone(result) || window.isNaN(result)) {
				result = -1;
			}

			// return the result as a string.
			return '' + result;
		},
		getDebugError: function getDebugError(error) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(error);

			// get the code from result.code in the object
			// if not look for just code.
			var code = Ember.get(error, 'result.debug.errors');
			if (Ember.isNone(code)) {
				code = Ember.get(error, 'debug.errors');
			}

			var result = void 0;

			// if the code is an array the return the first code as an int.
			// else assume the code is a string and convet it to an int.
			if (Ember.isArray(code) && !Ember.isEmpty(code)) {
				result = code[0];
			} else if (!Ember.isNone(code)) {
				result = code;
			} else {
				result = this.getError(error);
			}

			// if null or undefined or NaN then set the code to -1
			if (Ember.isEmpty(result)) {
				result = -1;
			}

			// return the result as a string.
			return '' + result;
		},
		messageHandler: function messageHandler(modelErrors, code) {
			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isObject(modelErrors);
			_utils.Assert.isString(code);

			// get the message
			var message = Ember.get(modelErrors, code);

			// if the message does not exists then
			// set it to the default error message.
			if (Ember.isEmpty(message)) {
				message = kDefaultError;
			}

			// return the message.
			return message;
		},
		getErrorString: function getErrorString(error, modelType) {
			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isObject(error);
			_utils.Assert.isString(modelType);

			var code = this.getError(error);
			var modelErrors = Ember.get(_modelErrors2.default, modelType);

			return this.messageHandler(modelErrors, code);
		},
		getRpcErrorString: function getRpcErrorString(error, type, method) {
			_utils.Assert.funcNumArgs(arguments, 3, true);
			_utils.Assert.isObject(error);
			_utils.Assert.isString(type);
			_utils.Assert.isString(method);

			var code = this.getError(error);
			var modelErrors = Ember.get(_rpcModelErrors2.default, type + '.' + method);

			return this.messageHandler(modelErrors, code);
		}
	});

	exports.default = ApiError;
});