define('busy-app/collections/payroll-member-project', ['exports', 'busy-app/collections/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		/**
   * Use this function to rebuild the data structure
   * returned from the getter calls
   *
   * @public
   * @method buildModels
   * @param data {object} keyed model object
   * @returns {array|object}
   */
		init: function init() {
			this._super.apply(this, arguments);
			Ember.deprecate('Custom wage date feature not supported', !Ember.get(this, 'features.wageCustomDate'), {
				id: 'collections.payroll-member-project',
				until: '999.99.99'
			});
		},
		buildModels: function buildModels(model) {
			var _this = this;

			var data = [];

			// get payPeriodType
			var payPeriodType = model.organizationPayPeriod.objectAt(0).get('payPeriodType');

			// add member array
			model.member.forEach(function (item) {
				_this.buildModel(item, model, data, payPeriodType);
			});

			// add archivedMember array
			model.archivedMember.forEach(function (item) {
				_this.buildModel(item, model, data, payPeriodType);
			});

			this.setAggregates(data);

			return data.sortBy('member.fullName');
		},
		buildModel: function buildModel(item, model, data, payPeriodType) {
			var seconds = model.payPeriodMemberProjectSeconds.filterBy('memberId', item.id);
			var cost = model.payPeriodMemberProjectCost.filterBy('memberId', item.id);
			var wage = this.getWageHistory(item);
			var isSalary = !Ember.isNone(wage) && (wage.get('wageRate') === 30 || wage.get('wageRate') === 40 || wage.get('wageRate') === 50);
			var hasSeconds = seconds.mapBy('totalTotalSeconds').reduce(function (a, b) {
				return a + b;
			}, 0);

			// add members with time clocked or members with salary that may not have any time clocked.
			if (hasSeconds > 0 || isSalary) {
				var poly = this.createPolymorph('payroll-member');
				poly.set('member', item);
				poly.set('seconds', seconds);
				poly.set('cost', cost);
				poly.set('wage', wage);
				poly.set('payPeriodType', payPeriodType);
				poly.set('isTopLevel', true);

				if (!Ember.isEmpty(seconds)) {
					this.addChildProjects(poly, null, model, 1);
				}

				data.pushObject(poly);
			}
		},
		addMemberModels: function addMemberModels(polyObject, parentProjectId, model, depth) {
			var _this2 = this;

			var payPeriod = model.organizationPayPeriod.objectAt(0);
			var data = [];
			model.member.forEach(function (item) {
				var seconds = polyObject.get('seconds').filterBy('memberId', item.id);
				var cost = polyObject.get('cost').filterBy('memberId', item.id);
				var hasSeconds = seconds.mapBy('totalTotalSeconds').reduce(function (a, b) {
					return a + b;
				}, 0);

				if (hasSeconds) {
					// each member needs a total seconds to reference for calculating saly employees.
					var memberSeconds = _this2.createPolymorph('pay-period-member');
					memberSeconds.set('seconds', model.payPeriodMemberProjectSeconds.filterBy('memberId', item.id));
					item.set('seconds', memberSeconds);

					var poly = _this2.createPolymorph('payroll-member');
					poly.set('member', item);
					poly.set('project', polyObject.get('project'));
					poly.set('seconds', seconds);
					poly.set('cost', cost);
					poly.set('wage', _this2.getWageHistory(item));
					poly.set('payPeriodType', payPeriod.get('payPeriodType'));
					poly.set('hidden', true);
					poly.set('isOpen', true);

					if (!Ember.isNone(depth)) {
						_this2.addChildProjects(poly, parentProjectId, model, depth + 1);
					}

					data.pushObject(poly);
				}
			});

			polyObject.set('members', data);
		},
		addChildProjects: function addChildProjects(polyObject, parentProjectId, model, depth) {
			var _this3 = this;

			var data = [];

			var projects = model.project.filterBy('depth', depth);
			if (!Ember.isNone(parentProjectId)) {
				projects = projects.filterBy('parentProjectId', parentProjectId);
			}

			projects.forEach(function (item) {
				var seconds = model.payPeriodMemberProjectSeconds.filterBy('projectId', item.id).filterBy('memberId', polyObject.get('member.id'));
				var cost = model.payPeriodMemberProjectCost.filterBy('projectId', item.id).filterBy('memberId', polyObject.get('member.id'));
				var hasSeconds = seconds.mapBy('totalTotalSeconds').reduce(function (a, b) {
					return a + b;
				}, 0);

				if (hasSeconds) {
					var poly = _this3.createPolymorph('payroll-project');
					item.set('sortWeight', 1);
					poly.set('project', item);
					poly.set('member', polyObject.get('member'));
					poly.set('seconds', seconds);
					poly.set('cost', cost);

					_this3.addMemberModels(poly, item.id, model, depth);

					data.pushObject(poly);
				}
			});

			if (data.length > 0) {
				this.addUnassigned(polyObject, data, model, parentProjectId, depth);
			}

			polyObject.set('projects', data.sortBy('project.sortWeight', 'project.title'));
		},
		addUnassigned: function addUnassigned(polyObject, data, model, parentProjectId, depth) {
			var project = Ember.Object.create({
				id: null,
				title: (0, _utils.loc)('Unassigned'),
				isUnassigned: true,
				depth: depth,
				parentProjectId: parentProjectId,
				sortWeight: 2
			});

			var seconds = void 0,
			    cost = void 0;
			if (Ember.isNone(parentProjectId)) {
				seconds = model.payPeriodMemberProjectSeconds.filterBy('projectId', null);
				cost = model.payPeriodMemberProjectCost.filterBy('projectId', null);
			} else {
				seconds = model.payPeriodMemberProjectSeconds.filterBy('projectId', parentProjectId);
				cost = model.payPeriodMemberProjectCost.filterBy('projectId', parentProjectId);
			}

			seconds = seconds.filterBy('memberId', polyObject.get('member.id'));
			seconds = seconds.filterBy('memberId', polyObject.get('member.id'));

			if (!Ember.isEmpty(seconds)) {
				var poly = this.createPolymorph('payroll-project');
				poly.set('project', project);
				poly.set('seconds', seconds);
				poly.set('cost', cost);
				poly.set('includeChildren', false);

				if (poly.get('totalNoChildrenSeconds') > 0 && (depth === 1 || poly.get('totalChildrenSeconds') > 0)) {
					this.addMemberModels(poly, project.id, model);
					data.pushObject(poly);
				}
			}
		},
		setAggregates: function setAggregates(data) {
			var regularSeconds = 0;
			var overtimeSeconds = 0;
			var doubletimeSeconds = 0;
			var totalSeconds = 0;
			var estCostSalary = 0;
			var estCostHourly = 0;

			data.forEach(function (item) {
				regularSeconds += item.get('regularSeconds');
				overtimeSeconds += item.get('overtimeSeconds');
				doubletimeSeconds += item.get('doubletimeSeconds');
				totalSeconds += item.get('totalSeconds');

				if (item.get('wage.wageRate') !== 10) {
					estCostSalary += item.get('totalOverburdenCost');
				} else {
					estCostHourly += item.get('totalOverburdenCost');
				}
			});

			// round the salary and hourly before adding the total
			// so the numbers add correctly in the interface.
			estCostSalary = estCostSalary.toFixed(2);
			estCostHourly = estCostHourly.toFixed(2);

			// to fixed creates a string so parseFloat befaore adding the values.
			var total = parseFloat(estCostSalary) + parseFloat(estCostHourly);

			this.set('regularSeconds', regularSeconds);
			this.set('overtimeSeconds', overtimeSeconds);
			this.set('doubletimeSeconds', doubletimeSeconds);
			this.set('totalSeconds', totalSeconds);
			this.set('estCostSalary', estCostSalary);
			this.set('estCostHourly', estCostHourly);
			this.set('totalCost', total);
		},
		getWageHistory: function getWageHistory(member) {
			var _this4 = this;

			// sort wages
			var _wages = member.get('wageHistories').filterBy('memberId', member.id).sortBy('changeDate');

			var wage = _wages.objectAt(0);
			_wages.forEach(function (item) {
				if (item.get('changeDate') > wage.get('changeDate') && item.get('changeDate') <= _this4.get('start')) {
					wage = item;
				}
			});

			return wage;
		},


		/**
   * Collections may have a predefined model
   * structure for reusability. This is not required
   * so this function may be removed if not used.
   *
   * @public
   * @method model
   * @return {EmberPromise}
   */
		model: function model(start, end, memberId, projectId) {
			this.set('start', start);
			this.set('end', end);

			var query = {
				start_time: start,
				end_time: end
			};

			// filter by member id
			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			// filter by project id
			if (!Ember.isNone(projectId)) {
				query.project_id = projectId;
			}

			var memberQuery = { archived_on: null };
			var archivedMembersQuery = { _gte: { archived_on: start } };

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				var memberGroupId = Ember.get(this, 'auth.member.memberGroupId');

				if (Ember.isNone(memberGroupId)) {
					var authMemberId = Ember.get(this, 'auth.memberId');
					Ember.setProperties(query, { member_id: authMemberId });
					Ember.setProperties(memberQuery, { id: authMemberId });
					Ember.setProperties(archivedMembersQuery, { id: authMemberId });
				} else {
					Ember.setProperties(query, { member_group_id: memberGroupId });
					Ember.setProperties(memberQuery, { member_group_id: memberGroupId });
					Ember.setProperties(archivedMembersQuery, { member_group_id: memberGroupId });
				}
			}

			return this.getter.findAll('pay-period-member-project-seconds', query).findAll('pay-period-member-project-cost', query).findAll('organization-pay-period', { _lte: { start_date: start }, _desc: ['start_date'] }).findAll('member', memberQuery).findAll('member', archivedMembersQuery, 'archivedMember').joinAll('wage-history', 'member', 'id', { deleted_on: null }).joinAll('wage-history', 'archivedMember', 'id', { deleted_on: null }).outerJoin('project', 'pay-period-member-project-seconds', 'project_id').fetch();
		}
	});
});