define('busy-app/components/charts/bar-chart', ['exports', 'busy-app/components/charts/base-chart', '@busy-web/utils'], function (exports, _baseChart, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseChart.default.extend({
		classNames: ['c-charts-bar-chart'],

		/**
   * The chart type
   *
   * @private
   * @property type
   * @type string
   */
		type: 'bar',
		responsive: true,
		fullWidth: true,
		gridLines: true,

		pageSize: 9,
		labels: null,
		showXAxis: true,
		showYAxis: true,

		colors: Ember.computed('model', 'modelPath', function () {
			var _this = this;

			var colors = ['#63c3af', '#37c9dc'];
			var paths = this.get('modelPath') || Ember.A(['']);
			if (typeof paths === 'string') {
				paths = paths.split(',');
			}

			var color = [];
			paths.forEach(function (item, index) {
				var path = ('model.' + item).replace(/\.$/, '');
				var models = _this.get(path) || [];
				var _color = [];
				models.forEach(function () {
					_color.push(colors[index]);
				});

				if (!Ember.isEmpty(_color)) {
					color.push(_color);
				}
			});
			return color;
		}),

		addOptions: function addOptions(options) {
			var _this2 = this;

			var paths = this.get('modelPath') || Ember.A(['']);
			var showXAxis = this.get('showXAxis');
			var showYAxis = this.get('showYAxis');
			var gridLines = this.get('gridLines');
			if (typeof paths === 'string') {
				paths = paths.split(',');
			}

			var yAxes = [];
			var xAxes = [];
			paths.forEach(function (item, index) {
				yAxes.push({
					type: 'linear',
					id: 'y-axis-' + index,
					display: showYAxis,
					position: _this2.get('type') === 'horizontalBar' ? 'top' : 'left',
					gridLines: {
						display: gridLines,
						lineWidth: 1
					},
					ticks: {
						beginAtZero: true
					}
				});
				xAxes.push({
					type: 'category',
					id: 'x-axis-' + index,
					display: showXAxis || index === 1,
					position: _this2.get('type') === 'horizontalBar' ? 'left' : 'bottom',
					gridLines: {
						display: gridLines,
						offsetGridLines: true
					}
				});
			});

			options.legend.display = false;

			options.scales = {};
			if (this.get('type') === 'horizontalBar') {
				options.scales.yAxes = xAxes;
				options.scales.xAxes = yAxes;
			} else {
				options.scales.yAxes = yAxes;
				options.scales.xAxes = xAxes;
			}
		},


		chartOptions: Ember.computed(function () {
			var paths = this.getOptionAsArray('modelPath');
			var borderColor = this.get('borderColor') || this.get('colors');
			var backgroundColor = this.get('backgroundColor') || this.get('colors');
			var labels = this.getOptionAsArray('legend');
			var dataTypes = this.getOptionAsArray('dataType');

			// if no paths provided then add an empty path
			// so the loop cycles at least once.
			if (Ember.isEmpty(paths)) {
				paths.push('');
			}

			var yAxes = [];
			var xAxes = [];
			paths.forEach(function (item, index) {
				yAxes.push('y-axis-' + index);
				xAxes.push('x-axis-0');
			});

			var options = {
				_borderColor: borderColor,
				_backgroundColor: backgroundColor,
				_dataType: dataTypes,
				barThickness: 36,
				stacked: true
			};

			if (!Ember.isEmpty(labels)) {
				options._label = labels;
			}

			if (this.get('type') === 'horizontalBar') {
				options._yAxisID = xAxes;
				options._xAxisID = yAxes;
			} else {
				options._yAxisID = yAxes;
				options._xAxisID = xAxes;
			}

			return options;
		}),

		/**
   * Override of inherited method to set the
   * information that shows up in the tooltip
   *
   * @public
   * @method tooltip
   * @param label {string}
   * @param value {mixed}
   * @return {string}
   */
		tooltip: function tooltip(label, value, type) {
			if (type === 'time') {
				// if dataType is set to the string `time` then return a time formated string
				return _utils.Time.convertSecondsString(value, 10, true, false, true);
			} else if (type === 'currency') {
				// if dataType is set to the string currency return a currency formated string
				return this.get('auth.currencySymbol') + value.toFixed(2).toLocaleString();
			} else if (type === 'hours') {
				return (0, _utils.loc)('%@ hrs', [value]);
			} else {
				// otherwise return the parent class default.
				return this._super.apply(this, arguments);
			}
		}
	});
});