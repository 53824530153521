define('busy-app/components/dialogs/button-bar', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    classNames: ['c-dialogs-button-bar'],

    /**
     * List of button information including required params like action and text
     *
     * @public
     * @property buttonInfo
     * @type array
     */
    buttonInfo: null,

    /**
     * internal button list
     *
     * @private
     * @property _buttonInfo
     * @type array
     */
    _buttonInfo: null,

    // private properties below whould not be set in template
    timeout: null,

    isShowing: false,
    isSaving: false,
    isSaved: false,
    isError: false,

    savingMessageText: (0, _utils.loc)('Saving'),
    savedMessageText: (0, _utils.loc)('Successfully Saved'),
    errorMessageText: (0, _utils.loc)('An error occurred! Please try again later.'),

    confirmText: (0, _utils.loc)("Save"),
    confirmCancelText: (0, _utils.loc)("Cancel"),
    confirmReasonText: (0, _utils.loc)("Are you sure you want to take this action?"),
    confirmHeader: (0, _utils.loc)("Warning: Are you sure you want to Continue?"),
    showConfirm: false,

    /**
     * initialize the component
     *
     * @private
     * @method init
     */
    init: function init() {
      this._super();

      if (!Ember.isNone(this.get('targetParent'))) {
        this.set('targetParent.buttonBar', this);
      }

      // set the button list to an empty array if nothing was passed in
      if (Ember.isNone(this.get('buttonInfo'))) {
        this.set('buttonInfo', []);
      }

      // make sure the button list is an array
      _utils.Assert.test("Component: <button-bar> requires [buttonInfo] to be an array of objects.", Ember.isArray(this.get('buttonInfo')));

      // setup the internal button list
      this.setupButtons();
    },


    /**
     * parse the button info list and build it with optional params included
     * make sure the objects in the list are all ember objects
     *
     * @private
     * @method setupButtons
     */
    setupButtons: function setupButtons() {
      var list = [];
      this.get('buttonInfo').forEach(function (item) {
        _utils.Assert.test("Component: <button-bar> requires [buttonInfo] to be an array of objects.", !Ember.isNone(item) && (typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object');

        // wrap item in EmberObject
        if (Ember.typeOf(item) !== 'instance') {
          item = Ember.Object.create(item);
        }

        // throw error if missing required params
        _utils.Assert.test("Component: <button-bar> missing required field [action:function] in buttonInfo.", !Ember.isNone(item.get('action')) && typeof item.get('action') === 'function');
        _utils.Assert.test("Component: <button-bar> missing required field [text:string] in buttonInfo.", !Ember.isEmpty(item.get('text')) && typeof item.get('text') === 'string');

        // set defaults and force optional params to be correct type.
        var color = item.getWithDefault('color', 'grey');
        _utils.Assert.test("Component: <button-bar> optional field [color:string] must be the following options -> blue red grey", color === 'blue' || color === 'red' || color === 'grey' || color === 'gray');
        item.set('color', color);

        var left = item.getWithDefault('left', false);
        _utils.Assert.test("Component: <button-bar> optional field [left:boolean] in buttonInfo. must be true or false", typeof left === 'boolean');
        item.set('left', left);

        var disabled = item.getWithDefault('disabled', false);
        _utils.Assert.test("Component: <button-bar> optional field [disabled:boolean] in buttonInfo. must be true or false", typeof disabled === 'boolean');
        item.set('disabled', disabled);

        var confirm = item.getWithDefault('confirm', false);
        _utils.Assert.test("Component: <button-bar> optional field [confirm:boolean] in buttonInfo. must be true or false", typeof confirm === 'boolean');
        item.set('confirm', confirm);

        var closeAfter = item.getWithDefault('closeAfter', false);
        _utils.Assert.test("Component: <button-bar> optional field [closeAfter:boolean] in buttonInfo. must be true or false", typeof closeAfter === 'boolean');
        item.set('closeAfter', closeAfter);

        // add object to new list
        list.pushObject(item);
      });

      var view = this;
      Ember.$(document).find('.button-bar').on('keyup', function (evt) {
        if (evt.which === 13) {
          var button = list.findBy('keyAction', true);
          if (Ember.isNone(button)) {
            button = list.get('firstObject');
          }

          if (!Ember.isNone(button)) {
            view.invokeActionItem(button.get('text'));
          }
        }
      });

      // set internal button list
      this.set('_buttonInfo', list);
    },


    /**
     * Shows the pop up saving box
     *
     * @public
     * @method showSavingBox
     */
    showSavingBox: function showSavingBox() {
      var timeout = 0;
      if (!this.get('isShowing')) {
        // set isShowing flag to true
        this.set('isShowing', true);

        // set the timeout to a 200 milliseconds
        timeout = 200;
      } else if (!Ember.isNone(this.get('timeout'))) {
        // get the stored timeout
        var timer = this.get('timeout');

        // calculate the time spent since the timeout was stored
        var timeSpent = new Date().valueOf() - timer.timestamp;

        // get the time left for this timeout
        timeout = timer.duration - timeSpent;
      }
      return this.promise(timeout);
    },


    /**
     * Hides the pop up saving message box
     *
     * @public
     * @method hideSavingBox
     */
    hideSavingBox: function hideSavingBox() {
      var _this = this;

      var timeout = 0;
      if (this.get('isError') || this.get('isSaved')) {
        // reset the error flag
        this.set('isError', false);

        // set the isSaved flag to off
        this.set('isSaved', false);

        // set a timeout of 300 milliseconds
        timeout = 300;
      } else if (!Ember.isNone(this.get('timeout'))) {
        // get the stored timeout
        var timer = this.get('timeout');

        // calculate the time left in the timeout
        var timeSpent = new Date().valueOf() - timer.timestamp;

        // get the time left for the timeout
        timeout = timer.duration - timeSpent;
      }

      return this.promise(timeout).then(function () {
        _this.set('isShowing', false);

        return true;
      });
    },
    promise: function promise(duration) {
      var _this2 = this;

      var timestamp = new Date().valueOf();

      if (this.get('timeout')) {
        window.clearTimeout(this.get('timeout').timeout);
      }

      return new Ember.RSVP.Promise(function (resolve) {
        var timer = window.setTimeout(function () {
          if (!_this2.get('isDestroyed')) {
            Ember.run(null, resolve, true);
          }
        }, duration);

        if (!_this2.get('isDestroyed')) {
          _this2.set('timeout', { timeout: timer, timestamp: timestamp, duration: duration });
        }
      });
    },


    /**
     * shows a saving message box. this box will not hide until
     * either success or error message calls have been made
     *
     * @public
     * @param message {string} the message to show when saving (not required)
     */
    showSavingMessage: function showSavingMessage(message) {
      return this._savingMessage(message);
    },


    /**
     * private save message call for internal class use
     *
     * @public
     * @param message {string} the message to show when saving
     * @param complete {function} the callback function called when the timeout has finished
     */
    _savingMessage: function _savingMessage(message) {
      var _this3 = this;

      message = !Ember.isEmpty(message) ? message : this.get('savingMessageText');

      this.set('savingMessageText', message);

      this.set('isSaved', false);
      this.set('isError', false);

      return this.showSavingBox().then(function () {
        _this3.set('isSaving', true);

        return true;
      });
    },


    /**
     * show the message success box
     *
     * @private
     * @param message {string} the success message (not required)
     * @param timeout {int} the time in milliseconds for the message to display (not required) default 2000 miliseconds
     */
    showSavedMessage: function showSavedMessage(message, timeout) {
      var _this4 = this;

      return this.showSavingBox().then(function () {
        return _this4._successMessage(message, timeout);
      });
    },


    /**
     * private success message call
     *
     * @private
     */
    _successMessage: function _successMessage(message, timeout) {
      var _this5 = this;

      message = !Ember.isEmpty(message) ? message : this.get('savedMessageText');
      timeout = !Ember.isNone(timeout) ? timeout : 2000;

      // clear window timeout
      if (this.get('timeout')) {
        window.clearTimeout(this.get('timeout'));
      }

      // set saved message
      this.set('savedMessageText', message);

      // hide saving and error and show saved
      this.set('isSaving', false);
      this.set('isSaved', true);
      this.set('isError', false);

      return this.promise(timeout).then(function () {
        return _this5.hideSavingBox();
      });
    },


    /**
     * shows the message error box
     *
     * @public
     * @param message {string} the success message (not required)
     * @param timeout {int} the time in milliseconds for the message to display (not required) default 4000 miliseconds
     */
    showErrorMessage: function showErrorMessage(message, timeout) {
      var _this6 = this;

      return this.showSavingBox().then(function () {
        return _this6._errorMessage(message, timeout);
      });
    },


    /**
     * private error message call
     *
     * @private
     */
    _errorMessage: function _errorMessage(message, timeout) {
      var _this7 = this;

      message = !Ember.isEmpty(message) ? message : this.get('errorMessageText');
      timeout = !Ember.isNone(timeout) ? timeout : 4000;

      // set error message
      this.set('errorMessageText', message);

      // hide saving and saved and show error
      this.set('isSaving', false);
      this.set('isSaved', false);
      this.set('isError', true);

      return this.promise(timeout).then(function () {
        return _this7.hideSavingBox();
      });
    },


    /**
     * clear out the current saving status message
     *
     * @public
     * @method clearStatus
     */
    clearStatus: function clearStatus() {
      if (!this.get('isDestroyed')) {
        // clear window timeout
        if (this.get('timeout')) {
          window.clearTimeout(this.get('timeout').timeout);
          this.set('timeout', null);
        }

        // hide saving saved and erorr
        this.set('isSaving', false);
        this.set('isSaved', false);
        this.set('isError', false);
      }
    },


    /**
     * dispatch calls to the correct action then handles showing and hiding status information
     *
     * @public
     * @method dispatchCall
     * @param item {object}
     * @param forceSave {boolean}
     * @return EmberPromise
     */
    dispatchCall: function dispatchCall(item, forceSave) {
      var _this8 = this;

      if (!forceSave && item.get('disabled')) {
        return Ember.RSVP.resolve();
      }

      // disable buttons
      this.get('_buttonInfo').forEach(function (item) {
        return item.set('disabled', true);
      });

      // clear current save status
      this.clearStatus();

      // show saving messge
      this.showSavingMessage(item.get('savingMessage'));

      // override close actions on error
      var isError = false;

      // call the action handler
      return this.callAction(item, forceSave).then(function (message) {
        // response can return false to disable saved message.
        if (message === false) {
          // clear status and hide saving box
          _this8.clearStatus();
          _this8.hideSavingBox();
          return false;
        } else {
          // show saved message
          return _this8.showSavedMessage(message);
        }
      }).catch(function (err) {
        isError = true;
        if (err instanceof Error) {
          throw err;
        }
        // show saved message
        return _this8.showErrorMessage(err);
      }).finally(function () {
        // enable buttons
        _this8.get('_buttonInfo').forEach(function (item) {
          return item.set('disabled', false);
        });

        // send the done action if closeAfter is true
        if (item.get('closeAfter') && !isError) {
          _this8.sendAction('onDone');
        }
      });
    },


    /**
     * Checks if the call returns a promise and handles success and error
     *
     * @public
     * @method callAction
     * @param item {object}
     * @param forceSave {boolean}
     * @return EmberPromise
     */
    callAction: function callAction(item) {
      var _this9 = this;

      var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      _utils.Assert.isObject(item);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var method = item.get('action'); // get method for action
        var target = item.get('target') || null; // get context target for this
        var promise = method.call(target, _this9, forceSave); // invoke action method

        // if the method returns a promise then show status
        if (!Ember.isNone(promise) && typeof promise.then === 'function') {
          // listen for promise to return
          promise.then(function (result) {
            // if the result returned a string then
            // use it as the message for success.
            var message = void 0;
            if (typeof result === 'string') {
              message = result;
            } else if (result === false) {
              message = false;
            }
            // resolve success
            Ember.run(null, resolve, message);
          }).catch(function (err) {
            // get error message
            var message = _this9.handleError(item, err);
            if (message !== undefined) {
              // reject promise if the error message was not
              // an override error
              Ember.run(null, reject, message);
            }
          });
        } else {
          // no promise was found so resolve the current promise
          Ember.run(null, resolve, false);
        }
      });
    },


    /**
     * @private
     * @method handleError
     * @param error
     */
    handleError: function handleError(item, error) {
      // throw the error if error is and Error instance
      if (error instanceof Error) {
        throw new Ember.Error(error);
      }

      // if the error is an object then check for override ability
      if ((typeof error === 'undefined' ? 'undefined' : _typeof(error)) === 'object') {
        if (error.canOverride) {
          // show the override confirm dialog
          this.showConfirmDialog(item, error.message);

          // return undefined so the caller knows the override is pending
          return undefined;
        } else if (error.message) {
          // return the error message
          return error.message;
        }
      }

      // if error is a string the return it
      if (typeof error === 'string') {
        return error;
      }

      // return an empty string for all other errors
      return null;
    },


    /**
     * Show the confirm dialog
     *
     * @public
     * @method showConfirmDialog
     * @param item {object}
     * @param message {string} optional message for dialog
     */
    showConfirmDialog: function showConfirmDialog(item, message) {
      _utils.Assert.isObject(item);
      message = !Ember.isNone(message) ? message : item.getWithDefault('confirmReasonText', this.get('confirmReasonText'));

      this.set('_confirmItem', item);
      this.set('confirmText', item.getWithDefault('confirmText', this.get('confirmText')));
      this.set('confirmCancelText', item.getWithDefault('confirmCancelText', this.get('confirmCancelText')));
      this.set('confirmReasonText', message);
      this.set('confirmHeader', item.getWithDefault('confirmHeader', this.get('confirmHeader')));
      this.set('confirmAlertHigh', item.getWithDefault('confirmAlertHigh', false));
      this.set('showConfirm', true);
    },


    /**
     * Hides the confirm dialog
     *
     * @public
     * @method hideConfirmDialog
     */
    hideConfirmDialog: function hideConfirmDialog() {
      this.set('showConfirm', false);
      this.get('_buttonInfo').forEach(function (item) {
        return item.set('disabled', false);
      });
      this.set('_confirmItem', null);
    },
    invokeActionItem: function invokeActionItem(textName) {
      var item = this.get('_buttonInfo').findBy('text', textName);
      if (!Ember.isNone(item)) {
        this.send('invokeAction', item);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(document).off('keyup.button-bar');
    },


    actions: {
      invokeAction: function invokeAction(item) {
        if (item.get('confirm')) {
          this.showConfirmDialog(item);
        } else {
          this.dispatchCall(item);
        }
      },
      confirmAction: function confirmAction() {
        var item = this.get('_confirmItem');
        this.dispatchCall(item, true);
        this.hideConfirmDialog();
      },
      cancelConfirmAction: function cancelConfirmAction() {
        this.hideConfirmDialog();
        this.hideSavingBox();
      }
    }
  });
});