define('busy-app/components/cost-codes/splash-screen', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    auth: Ember.inject.service(),

    isSaving: false,
    scopeToProject: true,
    requireCostCode: false,

    actions: {
      onClose: function onClose() {
        this.sendAction('onClose');
      },
      onEnable: function onEnable() {
        var _this = this;

        var org = Ember.isNone(this.get('auth.organization.content')) ? this.get('auth.organization') : this.get('auth.organization.content');

        org.setProperties({
          trackCostCode: true,
          useProjectCostCodeScoping: this.get('scopeToProject'),
          requireCostCode: this.get('requireCostCode')
        });

        this.set('isSaving', true);
        return org.save().then(function () {
          if (!_this.get('isDestroyed')) {
            _this.set('isSaving', false);
            _this.send('onClose');
            _this.sendAction('onEnabled');
          }
        });
      },
      onLearnMore: function onLearnMore() {
        window.open('http://support.busybusy.com/faq/what-are-busybusy-cost-codes');
      }
    }
  });
});