define('busy-app/utils/app-global', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Object.extend({
		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),
		payPeriods: Ember.inject.service('pay-period'),
		controller: null,
		container: null,
		loaders: null,
		ready: false,

		init: function init() {
			this._super();
			this.set('loaders', []);
		},


		waitForLoad: Ember.observer('controller.isReady', 'auth.isReady', 'container.isReady', 'payPeriods.isReady', function () {
			if (!this.get('ready')) {
				if (this.get('controller.isReady') && this.get('auth.isReady') && this.get('container.isReady') && this.get('payPeriods.isReady')) {
					this.onLoad();
				}
			}
		}),

		onLoad: function onLoad() {
			var _this = this;

			this.set('ready', true);
			var loaders = this.get('loaders');
			Ember.$.each(loaders, function (key, loader) {
				if (loaders.hasOwnProperty(key)) {
					_this.triggerLoader(loader);
				}
			});
		},
		triggerLoader: function triggerLoader(loader) {
			var target = loader.target;
			var func = loader.func;
			target.get(func).call(target);
		},
		createLoader: function createLoader(target, func) {
			return Ember.Object.create({ target: target, func: func });
		}
	});
});