define("busy-app/utils/_rpc-model-errors", ["exports", "@busy-web/utils"], function (exports, _utils) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		"account": {
			"create": {
				"200": (0, _utils.loc)("This username is taken. Please choose another.")
			},
			"verify-email": {
				"201": (0, _utils.loc)("This url has expired or has already been used to verify this email!")
			},
			"request-verification-email": {
				"202": (0, _utils.loc)("The email must be set to request an email verification."),
				"204": (0, _utils.loc)("The account was not found to verify the email.")
			},
			"password-reset-request": {
				"203": (0, _utils.loc)("The email was not found in the system.")
			},
			"reset-password": {
				"201": (0, _utils.loc)("This reset url has already been used. You must submit a new password reset request.")
			}
		}
	};
});