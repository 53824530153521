define('busy-app/utils/collection', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.keyBy = keyBy;
	exports.groupBy = groupBy;
	exports.tupleByKey = tupleByKey;
	exports.toDictionary = toDictionary;
	exports.sumBy = sumBy;
	exports.flatten = flatten;
	exports.localeSortBy = localeSortBy;

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	function keyBy(collection, key) {
		var defaultValue = {};

		if (Ember.isNone(collection)) {
			return collection;
		}

		var keyedHash = collection.reduce(function (acc, item) {
			return Object.assign(acc, _defineProperty({}, Ember.get(item, key), item));
		}, defaultValue);

		return keyedHash;
	}

	function groupBy(collection, key) {
		var defaultValue = {};

		if (Ember.isNone(collection)) {
			return collection;
		}

		var result = collection.reduce(function (acc, item) {
			var keyValue = Ember.get(item, key);

			if (acc.hasOwnProperty(keyValue)) {
				acc[keyValue].push(item);
			} else {
				acc[keyValue] = [item];
			}

			return acc;
		}, defaultValue);

		return result;
	}

	function tupleByKey(collection, key) {
		return collection.map(function (item) {
			return [Ember.get(item, key), item];
		});
	}

	/**
  * converts an array of objects into a Map keyed on the specified attribute
  * toDictionary([[a:'foo', b:1],[a:'bar', b:2]], 'a') => {foo: [a:'foo', b:1], bar: [a:'bar, b:2]}
  *
  * @method toDictionary
  * @param collection {object[]}
  * @param key {string}
  * @return {object}
  */
	function toDictionary(collection) {
		var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'id';

		return new Map(tupleByKey(collection, key));
	}

	/**
  * @method sumBy
  * @param collection {object[]}
  * @param key {string | string[]}
  * @return {number}
  */
	function sumBy(collection, key) {
		if (Array.isArray(key)) {
			return key.reduce(function (acc, recurseKey) {
				return acc + sumBy(collection, recurseKey);
			}, 0);
		}

		return collection.reduce(function (acc, item) {
			return acc + Ember.get(item, key);
		}, 0);
	}

	function flatten(collection) {
		return collection.reduce(function (acc, item) {
			return acc.concat.apply(acc, _toConsumableArray(item));
		}, []);
	}

	function localeSortBy(collection, key) {
		var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
		var config = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : { numeric: true, sensitivity: 'base' };

		if (Ember.isNone(collection)) {
			return collection;
		}

		var lang = window.navigator.language || 'en';

		return collection.sort(function (a, b) {
			var x = Ember.getWithDefault(a, key, defaultValue);
			var y = Ember.getWithDefault(b, key, defaultValue);

			return x.localeCompare(y, lang, config);
		});
	}
});