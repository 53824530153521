define('busy-app/utils/query-builder', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.createQuery = exports.mapByUniq = undefined;
	exports.default = queryBuilder;

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	/**
  * @module utils
  *
  */
	var NULL_VALUE = null;

	var mapByUniq = exports.mapByUniq = function mapByUniq(list, property) {
		return list.map(function (item) {
			return item[property];
		}).uniq().compact();
	};

	var Builder = Ember.Object.extend({
		_query: null,
		_modelAttrs: null,

		_addToQuery: function _addToQuery(key, value) {
			key = Ember.String.underscore(key);
			Ember.set(this, '_query.' + key, value);
		},
		equal: function equal(key, value) {
			this._addToQuery(key, value);
			return this;
		},
		notEqual: function notEqual(key, value) {
			this._addToQuery('_not_equal', _defineProperty({}, key, value));
			return this;
		},
		page: function page() {
			var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
			var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 80;

			this._addToQuery('page', page);
			this._addToQuery('page_size', size);
			return this;
		},
		limit: function limit() {
			var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 80;

			this._addToQuery('limit', size);
			return this;
		},
		greaterThanOrEqual: function greaterThanOrEqual(key, value) {
			key = Ember.String.underscore(key);
			this._addToQuery('_gte', _defineProperty({}, key, value));
			return this;
		},
		lessThanOrEqual: function lessThanOrEqual(key, value) {
			key = Ember.String.underscore(key);
			this._addToQuery('_lte', _defineProperty({}, key, value));
			return this;
		},
		ascending: function ascending(key) {
			key = Ember.String.underscore(key);
			this._addToQuery('_asc', [key]);
			return this;
		},
		descending: function descending(key) {
			key = Ember.String.underscore(key);
			this._addToQuery('_desc', [key]);
			return this;
		},
		notDeleted: function notDeleted() {
			this._addToQuery('deleted_on', NULL_VALUE);
			return this;
		},
		notArchived: function notArchived() {
			this._addToQuery('archived_on', NULL_VALUE);
			return this;
		},
		deleted: function deleted() {
			this._addToQuery('_not_equal', { deleted_on: NULL_VALUE });
			return this;
		},
		archived: function archived() {
			this._addToQuery('archived_on', '!' + NULL_VALUE);
			return this;
		},
		openEntry: function openEntry() {
			this.equal('end_time', NULL_VALUE);
			return this;
		},
		inRange: function inRange(startKey, start, endKey, end) {
			_utils.Assert.isString(startKey);
			_utils.Assert.isNumber(start);

			_utils.Assert.isString(endKey);
			_utils.Assert.isNumber(end);

			this.greaterThanOrEqual(endKey, start);
			this.lessThanOrEqual(startKey, end);
			return this;
		},
		strictInRange: function strictInRange(startKey, start, endKey, end) {
			_utils.Assert.isString(startKey);
			_utils.Assert.isNumber(start);

			_utils.Assert.isString(endKey);
			_utils.Assert.isNumber(end);

			this.greaterThanOrEqual(startKey, start);
			this.lessThanOrEqual(endKey, end);
			return this;
		},
		matchRange: function matchRange(startKey, start, endKey, end) {
			_utils.Assert.isString(startKey);
			_utils.Assert.isNumber(start);

			_utils.Assert.isString(endKey);
			_utils.Assert.isNumber(end);

			this.equal(startKey, start);
			this.equal(endKey, end);
			return this;
		},
		toJSON: function toJSON() {
			return Ember.get(this, '_query');
		}
	});

	function queryBuilder(_query, _modelName, modelClass) {
		_utils.Assert.isObject(_query);

		var _modelAttrs = void 0;
		if (modelClass && modelClass.attributes) {
			_modelAttrs = Ember.get(modelClass, 'attributes');
		}

		return Builder.create({ _modelName: _modelName, _modelAttrs: _modelAttrs, _query: _query });
	}

	var QueryBuilder = function () {
		function QueryBuilder(baseQuery) {
			_classCallCheck(this, QueryBuilder);

			this._query = Object.assign({}, baseQuery);
		}

		_createClass(QueryBuilder, [{
			key: '_addToQuery',
			value: function _addToQuery(key, value) {
				key = Ember.String.underscore(key);
				this._query = Object.assign({}, this._query, _defineProperty({}, key, value));
			}
		}, {
			key: 'add',
			value: function add(prop) {
				if (!Ember.isNone(prop) && (typeof prop === 'undefined' ? 'undefined' : _typeof(prop)) === 'object') {
					this._query = Object.assign({}, this._query, prop);
				}
			}
		}, {
			key: 'equal',
			value: function equal(key, value) {
				this._addToQuery(key, value);
				return this;
			}
		}, {
			key: 'notEqual',
			value: function notEqual(key, value) {
				key = Ember.String.underscore(key);
				this._addToQuery('_not_equal', Object.assign({}, this._query._not_equal, _defineProperty({}, key, value)));
				return this;
			}
		}, {
			key: 'page',
			value: function page() {
				var _page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

				var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 80;

				this._addToQuery('page', _page);
				this._addToQuery('page_size', size);
				return this;
			}
		}, {
			key: 'limit',
			value: function limit() {
				var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 80;

				this._addToQuery('limit', size);
				return this;
			}
		}, {
			key: 'greaterThanOrEqual',
			value: function greaterThanOrEqual(key, value) {
				key = Ember.String.underscore(key);
				this._addToQuery('_gte', Object.assign({}, this._query._gte, _defineProperty({}, key, value)));
				return this;
			}
		}, {
			key: 'lessThanOrEqual',
			value: function lessThanOrEqual(key, value) {
				key = Ember.String.underscore(key);
				this._addToQuery('_lte', Object.assign({}, this._query._lte, _defineProperty({}, key, value)));
				return this;
			}
		}, {
			key: 'ascending',
			value: function ascending(key) {
				key = Ember.String.underscore(key);
				this._addToQuery('_asc', [].concat(this._query._asc || [], [key]));
				return this;
			}
		}, {
			key: 'descending',
			value: function descending(key) {
				key = Ember.String.underscore(key);
				this._addToQuery('_desc', [].concat(this._query._desc || [], [key]));
				return this;
			}
		}, {
			key: 'sort',
			value: function sort(dir, key) {
				if (dir === 'desc') {
					this.descending(key);
				} else {
					this.ascending(key);
				}
				return this;
			}
		}, {
			key: 'deleted',
			value: function deleted() {
				var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

				if (flag) {
					this.notEqual({ deleted_on: null });
				} else {
					this._addToQuery('deleted_on', null);
				}
				return this;
			}
		}, {
			key: 'archived',
			value: function archived() {
				var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

				if (flag) {
					this.notEqual({ archived_on: null });
				} else {
					this._addToQuery('archived_on', null);
				}
				return this;
			}
		}, {
			key: 'inRange',
			value: function inRange(startKey, start, endKey, end) {
				this.greaterThanOrEqual(endKey, start);
				this.lessThanOrEqual(startKey, end);
				return this;
			}
		}, {
			key: 'strictInRange',
			value: function strictInRange(startKey, start, endKey, end) {
				this.greaterThanOrEqual(startKey, start);
				this.lessThanOrEqual(endKey, end);
				return this;
			}
		}, {
			key: 'matchRange',
			value: function matchRange(startKey, start, endKey, end) {
				this.equal(startKey, start);
				this.equal(endKey, end);
				return this;
			}
		}, {
			key: 'toJSON',
			value: function toJSON() {
				return Ember.get(this, '_query');
			}
		}]);

		return QueryBuilder;
	}();

	var createQuery = exports.createQuery = function createQuery() {
		var baseQuery = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

		return new QueryBuilder(baseQuery);
	};
});