define('busy-app/models/busy-sign-in-question', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    questionTitle: _emberData.default.attr('string'),
    questionDescription: _emberData.default.attr('string'),
    questionPrompt: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    required: _emberData.default.attr('boolean'),
    choices: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    archivedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),

    choicesArray: Ember.computed('choices', function () {
      var choices = this.get('choices').replace('[', '').split('}, {');
      var choiceArray = [];
      choices.forEach(function (choice) {
        if (choice.includes('Yes') && choice.includes('true')) {
          var choiceObj = { choice: 'Yes', value: true };
          choiceArray.push(choiceObj);
        } else if (choice.includes('No') && choice.includes('false')) {
          var _choiceObj = { choice: 'No', value: false };
          choiceArray.push(_choiceObj);
        }
      });
      return choiceArray;
    })
  });
});