define('busy-app/routes/report/time-card-approval', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils/assert', '@busy-web/utils/time'], function (exports, _authenticatedRouteMixin, _assert, _time) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		access: Ember.inject.service('access'),
		auth: Ember.inject.service('auth'),
		signature: Ember.inject.service('signature'),
		store: Ember.inject.service('store'),
		payPeriods: Ember.inject.service('pay-period'),

		payPeriodMemberSeconds: null,
		members: null,
		memberLocks: null,
		memberTimeDocuments: null,

		memberIds: Ember.computed.mapBy('members', 'id'),

		start: Ember.computed.alias('controller.start'),

		payPeriod: Ember.computed('start', function () {
			return this.get('payPeriods').getPayPeriod(this.get('start'));
		}),

		startTime: Ember.computed('payPeriod', function () {
			return this.get('payPeriod').get('startDate');
		}),

		endTime: Ember.computed('payPeriod', function () {
			return this.get('payPeriod').get('endDate');
		}),

		/**
   * @private
   * @method model
   * @return {Model}
   */
		model: function model() {
			var _this = this;

			if (!this.get('controller')) {
				var controller = this.controllerFor('report/time-card-approval');
				this.set('controller', controller);
			}

			if (!this.get('controller.isSelectedPayPeriodSignable')) {
				return Ember.RSVP.resolve();
			}

			// Seperates the API calls into the minimum needed to render the page and the additional data that can be deferred
			return this.getPrimaryData().then(function (model) {
				Ember.run.next(function () {
					return _this.getSecondaryData();
				});

				return model;
			});
		},


		/**
   * Get the minimum "blocking" data needed to render the page
   *
   * Gets all `members` and `payPayPeriodMemberSeconds` in range for the current organization
   * The `member` result is returned to be the model.
   *
   * @private
   * @async
   * @method getPrimaryData
   * @return {Model.Member[]}
   */
		getPrimaryData: function getPrimaryData() {
			var _this2 = this;

			return this.getMembers().then(function (members) {
				return _this2.setPayPeriodMemberSeconds(members).then(function () {
					return members.map(function (member) {
						return _this2.initMember(member);
					});
				});
			});
		},


		/**
   * Get the rest of the data needed to flesh out the report.
   * Uses run.later() to let the page render before queueing up more data requests.
   * Data isn't returned from this function, it it attached directly to each member on the model
   *
   * Independently look up the related `memberLock` and `memberTimeDocument` records
   *
   * @private
   * @method getSecondaryData
   */
		getSecondaryData: function getSecondaryData() {
			var _this3 = this;

			this.set('controller.isLoadingMemberLocks', true);
			this.set('controller.isLoadingMemberTimeDocuments', true);

			Ember.run.later(function () {
				Ember.RSVP.all([_this3.getMemberLocks(), _this3.getMemberDocuments()]);
			});
		},


		/**
   * Pay Period
   *
   */

		/**
   * Get all `pay-period-member-seconds` for the selected date range.
   * Sets `payPeriodMemberSeconds` and calls `setPayPeriodsByMember()`
   *
   * @private
   * @async
   * @method getPrimaryData
   * @return {Model.PayPeriodMemberSeconds[]}
   */
		setPayPeriodMemberSeconds: function setPayPeriodMemberSeconds(members) {
			var _this4 = this;

			var queryParams = {
				start_time: this.get('startTime').unix(),
				end_time: this.get('endTime').unix()
			};

			var memberIds = members.mapBy('id');

			this.set('controller.isLoadingPayPeriods', true);
			return this.get('store').findWhereIn('pay-period-member-seconds', 'member_id', memberIds, queryParams).then(function (payPeriodMemberSeconds) {
				_this4.set('payPeriodMemberSeconds', payPeriodMemberSeconds);
				_this4.setPayPeriodsByMember(payPeriodMemberSeconds);

				return payPeriodMemberSeconds;
			}).finally(function () {
				_this4.set('controller.isLoadingPayPeriods', false);
			});
		},


		/**
   * Convert `payPeriodMemberSeconds` results to an object keyed by the `memberId`
   *
   * @private
   * @method setPayPeriodsByMember
  	 * @param {Model.PayPeriodMemberSeconds[]} payPeriodMemberSeconds
   * @return {object}
   */
		setPayPeriodsByMember: function setPayPeriodsByMember(payPeriodMemberSeconds) {
			var payPeriodByMember = Ember.Object.create({});

			payPeriodMemberSeconds.forEach(function (payPeriod) {
				var memberId = payPeriod.get('memberId');

				payPeriodByMember.set(memberId, payPeriod);
			});

			this.set('payPeriodByMember', payPeriodByMember);

			return payPeriodByMember;
		},
		getCachedPayPeriodByMember: function getCachedPayPeriodByMember(memberId) {
			var ppms = this.get('payPeriodByMember');

			return ppms.hasOwnProperty(memberId) ? ppms[memberId] : null;
		},


		/**
   * Members
   *
   */

		/**
   * Get all `member` objects with pay period data in the selected date range.
   * Set related `payPeriod` on each `member` object.
   * Stub blank `memberLock` and `memberTimeDocument` objects
   *
   * @private
   * @async
   * @method getMembers
   * @return {Model.Member[]}
   */
		getMembers: function getMembers() {
			var _this5 = this;

			// const endTime = this.get('endTime').unix();

			var queryParams = {
				// _lte: { created_on: endTime },
				archived_on: '_-NULL-_',
				page_size: 9999,
				_asc: ['first_name', 'last_name']
			};

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				var authMember = Ember.get(this, 'auth.member');

				var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
				    id = _EmberGetProperties.id,
				    memberGroupId = _EmberGetProperties.memberGroupId;

				Ember.setProperties(queryParams, { member_group_id: memberGroupId });

				if (Ember.isNone(memberGroupId)) {
					Ember.setProperties(queryParams, { id: id });
				}
			}

			this.set('controller.isLoadingMembers', true);
			return this.get('store').query('member', queryParams).then(function (members) {
				return _this5.filterMembersByAccess(members);
			}).then(function (members) {
				_this5.set('members', members);

				return members;
			}).finally(function () {
				_this5.set('controller.isLoadingMembers', false);
			});
		},
		filterMembersByAccess: function filterMembersByAccess(members) {
			var _this6 = this;

			return Ember.isNone(members) ? members : members.filter(function (member) {
				return _this6.get('access').canTakeActionFor('manageTimeEntries', member);
			});
		},


		/**
   * Attach `payPeriod` to member and initialize the `memberLock` and `memberTimeDocument` objects
   *
   * @private
   * @async
   * @method getMembers
   * @return {Model.Member[]}
   */
		initMember: function initMember(member) {
			var memberId = member.get('id');
			var payPeriod = this.getCachedPayPeriodByMember(memberId);

			if (Ember.isNone(payPeriod)) {
				payPeriod = this.get('store').createRecord('pay-period-member-seconds', {
					memberId: memberId,
					startTime: _time.default.date(this.get('startTime') / 1000).unix(),
					endTime: _time.default.date(this.get('endTime') / 1000).unix(),
					regularSeconds: 0,
					overtimeSeconds: 0,
					doubletimeSeconds: 0
				});
			}

			member.set('payPeriod', payPeriod);
			member.set('memberLock', null);
			member.set('memberTimeDocument', null);

			return member;
		},


		/**
   * Member Lock
   *
   */

		/**
   * Get `memberLock` records for all relevant members
   *
   * @private
   * @async
   * @method getMemberLocks
   * @return {Model.MemberLock[]}
   */
		getMemberLocks: function getMemberLocks() {
			var _this7 = this;

			var memberIds = this.get('memberIds');

			this.set('controller.isLoadingMemberLocks', true);
			return this.get('store').findWhereIn('member-lock', 'memberId', memberIds, { deleted_on: '_-NULL-_' }).then(function (memberLocks) {
				_this7.attachMemberLockToMembers(memberLocks);
				_this7.set('memberLocks', memberLocks);

				return memberLocks;
			}).finally(function () {
				_this7.set('controller.isLoadingMemberLocks', false);
			});
		},


		/**
   * Attach related `memberLock` results to each `member`
   *
   * @private
   * @method attachMemberLockToMembers
  	 * @param {Model.MemberLock[]} memberLocks
   * @return {Model.MemberLock[]}
   */
		attachMemberLockToMembers: function attachMemberLockToMembers(memberLocks) {
			if (!Ember.isNone(memberLocks)) {
				var memberLocksByMember = this.getMemberLocksByMember(memberLocks);

				this.get('members').forEach(function (member) {
					var memberId = member.get('id');

					member.set('memberLock', memberLocksByMember[memberId]);
				});
			}

			return memberLocks;
		},


		/**
   * Convert `memberLock` resultset to an object keyed by the memberId
   *
   * @private
   * @method memberLocksByMember
  	 * @param {Model.MemberLock[]} memberLocks
   * @return {object}
   */
		getMemberLocksByMember: function getMemberLocksByMember(memberLocks) {
			var result = Ember.Object.create({});

			memberLocks.forEach(function (memberLock) {
				var memberId = memberLock.get('memberId');

				result.set(memberId, memberLock);
			});

			return result;
		},


		/**
   * Member Time Document
   *
   */

		/**
   * Get all `memberTimeDocuments` records for all relevant members
   *
   * @private
   * @async
   * @method getMemberDocuments
   * @return {Model.MmberTimeDocuments[]}
   */
		getMemberDocuments: function getMemberDocuments() {
			var _this8 = this;

			var memberIds = this.get('memberIds');
			var start = this.get('controller.start');
			var end = this.get('controller.end');

			var queryParams = {
				start_time: start,
				end_time: end,
				canceled: '_-NULL-_',
				_desc: ['created_on'],
				deleted_on: '_-NULL-_',
				page_size: 999999
			};

			this.set('controller.isLoadingMemberTimeDocuments', true);
			return this.get('store').findWhereIn('member-time-document', 'memberId', memberIds, queryParams).then(function (memberTimeDocuments) {
				_this8.set('memberTimeDocuments', memberTimeDocuments);

				return _this8.attachDocumentsToMembers(memberTimeDocuments);
			}).finally(function () {
				_this8.set('controller.isLoadingMemberTimeDocuments', false);
			});
		},


		/**
   * Lookup the signature state for each member, passing along the `memberTimeDocuments` cache
   *
   * @private
   * @async
   * @method attachDocumentsToMembers
  	 * @param {Model.MemberTimeDocument[]} memberTimeDocuments
   * @return {Model.MemberTimeDocuments[]}
   */
		attachDocumentsToMembers: function attachDocumentsToMembers(memberTimeDocuments) {
			var _this9 = this;

			var mtdArray = memberTimeDocuments.toArray();

			Ember.run.later(function () {
				return _this9.attachSignaturesToDocuments(memberTimeDocuments);
			});

			return Ember.RSVP.all(this.get('members').map(function (member) {
				if (!Ember.isNone(member.get('payPeriod'))) {
					return _this9.getMemberSignatureState(member, mtdArray);
				} else {
					return Ember.RSVP.resolve(member);
				}
			}));
		},


		/**
   * SIDE EFFECT: memberTimeDocuments is modified
   *
   * @private
   * @async
   * @method attachSignaturesToDocuments
  	 * @param {Model.MemberTimeDocument[]} memberTimeDocuments
   */
		attachSignaturesToDocuments: function attachSignaturesToDocuments(memberTimeDocuments) {
			return this.getSignatures(memberTimeDocuments).then(function (_ref) {
				var selfSignatures = _ref.selfSignatures,
				    authoritativeSignatures = _ref.authoritativeSignatures;

				memberTimeDocuments.forEach(function (mtd) {
					var selfSigId = mtd.get('selfSignatureId');
					var supSigId = mtd.get('authoritativeSignatureId');

					if (!Ember.isNone(selfSigId) && selfSignatures.has(selfSigId)) {
						mtd.set('selfSignature', selfSignatures.get(selfSigId));
					}

					if (!Ember.isNone(supSigId) && authoritativeSignatures.has(supSigId)) {
						mtd.set('authoritativeSignature', authoritativeSignatures.get(supSigId));
					}
				});
			});
		},
		getSignatures: function getSignatures(memberTimeDocuments) {
			var selfSignatureIds = memberTimeDocuments.filterBy('hasValidSelfSignature').mapBy('selfSignatureId').uniq();
			var supSignatureIds = memberTimeDocuments.filterBy('hasValidAuthoritativeSignature').mapBy('authoritativeSignatureId').uniq();

			return Ember.RSVP.hash({
				selfSignatures: this.getSignaturesByType('self-signature', selfSignatureIds, true),
				authoritativeSignatures: this.getSignaturesByType('authoritative-signature', supSignatureIds, this.get('signature.isSignatureModeSupervisor'))
			});
		},


		// promise returns a Map object, not an array
		getSignaturesByType: function getSignaturesByType(signatureType, ids, isEnabled) {
			var mapInit = new window.Map();

			if (!isEnabled) {
				return Ember.RSVP.resolve(mapInit);
			}

			return this.get('store').findWhereIn(signatureType, 'id', ids, { deleted_on: '_-NULL-_' }).then(function (signatures) {
				var signaturesMap = signatures.reduce(function (result, signature) {
					result.set(signature.get('id'), signature);
					return result;
				}, mapInit);

				return signaturesMap;
			});
		},


		/**
   * Uses cache of `memberTimeDocuments` to attach relevant `memberLock` result to the specified `member`
   *
   * @private
   * @async
   * @method getMemberSignatureState
  	 * @param {Model.Member} member
  	 * @param {array} memberTimeDocuments
   * @return {Model.MemberTimeDocuments}
   */
		getMemberSignatureState: function getMemberSignatureState(member, memberTimeDocuments) {
			_assert.default.isObject(member);
			_assert.default.isArray(memberTimeDocuments);

			var payPeriod = member.get('payPeriod');

			return this.get('signature').getStateForPayPeriod(payPeriod, memberTimeDocuments).then(function (memberTimeDocument) {
				member.set('memberTimeDocument', memberTimeDocument);

				return memberTimeDocument;
			});
		}
	});
});