define('busy-app/routes/ticket', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		interop: Ember.inject.service('interop'),
		features: Ember.inject.service('features'),

		queryParams: {
			verify_email: { refreshModel: false },
			reset_password: { refreshModel: false },
			invitation: { refreshModel: false },
			unsubscribe: { refreshModel: false }
		},

		model: function model(queryParams) {
			var reset_password = Ember.get(queryParams, 'reset_password');
			var unsubscribe = Ember.get(queryParams, 'unsubscribe');
			var onboardingInterop = Ember.get(this, 'features.onboardingInterop');

			if (onboardingInterop) {
				if (!Ember.isNone(reset_password)) {
					return this.redirectToReact({ reset_password: reset_password });
				} else if (!Ember.isNone(unsubscribe)) {
					return this.redirectToReact({ unsubscribe: unsubscribe });
				}
			}

			return Ember.RSVP.resolve(null);
		},
		redirectToReact: function redirectToReact(ticketParams) {
			var interop = Ember.get(this, 'interop');
			var resetPasswordUrl = interop.getReactAppUrl('/ticket', ticketParams, false);
			var windowHandle = interop.openUrl(resetPasswordUrl, null, { newWindow: false });

			return windowHandle;
		}
	});
});