define('busy-app/services/interop', ['exports', 'busy-app/utils/logger', 'busy-app/utils/settings'], function (exports, _logger, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),

    windows: null,

    init: function init() {
      this._super();

      Ember.set(this, 'windows', []);
    },
    closeWindows: function closeWindows() {
      Ember.get(this, 'windows').forEach(function (childWindow) {
        try {
          if (childWindow && childWindow.close) {
            childWindow.close();
          }
        } catch (e) {
          _logger.default.error(e);
        }
      });

      Ember.set(this, 'windows', []);
    },
    openReactApp: function openReactApp(path, customParameters, event) {
      var interopUrl = this.getReactAppUrl(path, customParameters);

      return this.openUrl(interopUrl, event, { newWindow: false });
    },
    openUrl: function openUrl(targetLocation, event) {
      var _this = this;

      var customOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var defaultOptions = {
        newWindow: false,
        replace: false
      };
      var options = Object.assign({}, defaultOptions, customOptions);
      var windowName = this.getWindowName(targetLocation);

      // if the CTRL or CMD key was held when the link was clicked, open the url in a new window
      var isMetaKeyActive = !Ember.isNone(event) && (event.ctrlKey || event.metaKey);

      if (options.newWindow || isMetaKeyActive) {
        return new Promise(function (resolve, reject) {
          try {
            // it needs to run in next() or it won't attain focus on load
            Ember.run.next(_this, function () {
              var interopAppHandle = window.open(targetLocation, windowName);
              interopAppHandle.focus();

              // cache handle so it can be closed on logout
              Ember.set(_this, 'windows', Ember.get(_this, 'windows').concat(interopAppHandle));

              resolve(interopAppHandle);
            });
          } catch (e) {
            reject(e);
          }
        });
      } else {
        var navigationMethod = options.replace ? 'assign' : 'replace';
        window.location[navigationMethod](targetLocation);

        return Promise.resolve(window);
      }
    },
    getWindowName: function getWindowName(targetLocation) {
      var windowName = encodeURIComponent('__app_interop__' + targetLocation.split('?')[0]);

      return windowName;
    },
    getReactAppUrl: function getReactAppUrl() {
      var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '/';
      var customParameters = arguments[1];
      var includeAuthToken = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

      var defaultParameters = {};
      var parameters = Object.assign({}, defaultParameters, customParameters);

      var authorize = '';

      if (Ember.get(this, 'session.isAuthenticated')) {
        var authToken = Ember.get(this, 'session.data.authenticated.token');
        var memberId = Ember.get(this, 'session.data.authenticated.id');

        if (!Ember.isNone(authToken) && !Ember.isNone(memberId)) {
          authorize = window.btoa(authToken + ':' + memberId);
        }
      }

      if (includeAuthToken) {
        parameters.authorize = authorize;
      }

      // convert key/val hash to a query string
      var queryString = Object.keys(parameters).map(function (key) {
        var paramKey = encodeURIComponent(key);
        var paramVal = encodeURIComponent(parameters[key]);

        return paramKey + '=' + paramVal;
      }).join('&');

      // no leading slash
      path = path.replace(/^\/+/g, '');
      var queryPath = Ember.isEmpty(queryString) ? path : [path, queryString].join('?');
      var interopUrl = [_settings.default.get('react_app_url'), queryPath].join('/');

      return interopUrl;
    }
  });
});