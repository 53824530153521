define('busy-app/components/dialogs/confirm-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    confirmHeader: (0, _utils.loc)("Warning: Are you sure you want to Continue?"),
    confirmMessage: (0, _utils.loc)("Are you sure you want to take this action?"),

    confirmText: (0, _utils.loc)("Save"),
    cancelText: (0, _utils.loc)("Cancel"),

    alertHigh: false,
    hideConfirmButton: false,

    actions: {
      confirm: function confirm() {
        this.sendAction('onConfirm');
      },
      cancel: function cancel() {
        this.sendAction('onCancel');
      }
    }
  });
});