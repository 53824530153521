define('busy-app/utils/models/collections/time-entry-report', ['exports', '@busy-web/utils', 'busy-app/utils/models/collections/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		start: null,
		end: null,

		days: null,
		projectData: null,

		member: Ember.computed.alias('model'),

		dateRangeString: Ember.computed('start', 'end', function () {
			var date = '';
			if (!Ember.isNone(this.get('start')) && !Ember.isNone(this.get('end'))) {
				date = _utils.Time.date(this.get('start')).format('M/D/YY') + ' - ' + _utils.Time.date(this.get('end')).format('M/D/YY');
			}
			return date;
		}),

		applyData: function applyData(model, days, projects, start, end) {
			Ember.set(this, 'days', days);
			Ember.set(this, 'projectData', projects);
			Ember.set(this, 'start', start);
			Ember.set(this, 'end', end);

			this._super(model);
		},


		projectDataRegularSeconds: Ember.computed('projectData.@each.regularSeconds', function () {
			var _this = this;

			var total = 0;
			if (!Ember.isNone(this.get('projectData'))) {
				this.get('projectData').forEach(function (item) {
					if (_this.get('model.wage.wageRate') === 10) {
						// hourly employee
						total += item.get('regularSeconds');
					} else {
						// salary employee
						total += item.get('regularSeconds');
						total += item.get('overtimeSeconds');
						total += item.get('doubletimeSeconds');
					}
				});
			}
			return total;
		}),

		projectDataOvertimeSeconds: Ember.computed('projectData.@each.overtimeSeconds', function () {
			var _this2 = this;

			var total = 0;
			if (!Ember.isNone(this.get('projectData'))) {
				this.get('projectData').forEach(function (item) {
					if (_this2.get('model.wage.wageRate') === 10) {
						// hourly employee
						total += item.get('overtimeSeconds');
					}
				});
			}
			return total;
		}),

		projectDataDoubletimeSeconds: Ember.computed('projectData.@each.doubletimeSeconds', function () {
			var _this3 = this;

			var total = 0;
			if (!Ember.isNone(this.get('projectData'))) {
				this.get('projectData').forEach(function (item) {
					if (_this3.get('model.wage.wageRate') === 10) {
						// hourly employee
						total += item.get('doubletimeSeconds');
					}
				});
			}
			return total;
		}),

		projectDataTotalSeconds: Ember.computed('projectData.@each.totalSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('projectData'))) {
				this.get('projectData').forEach(function (item) {
					total += item.get('totalSeconds');
				});
			}
			return total;
		}),

		projectDataRegularTimeString: Ember.computed('projectDataRegularSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('projectDataRegularSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		projectDataOvertimeTimeString: Ember.computed('projectDataOvertimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('projectDataOvertimeSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		projectDataDoubletimeTimeString: Ember.computed('projectDataDoubletimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('projectDataDoubletimeSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		projectDataTotalTimeString: Ember.computed('projectDataTotalSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('projectDataTotalSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		totalPTOSeconds: Ember.computed('days.@each.totalPTOSeconds', function () {
			var total = 0;
			this.get('days').forEach(function (item) {
				total += item.get('totalPTOSeconds');
			});
			return total;
		}),

		regularHoursWorked: Ember.computed('days.@each.regularHoursWorked', function () {
			var total = 0;
			this.get('days').forEach(function (item) {
				total += item.get('regularHoursWorked');
			});
			return total;
		})
	});
});