define('busy-app/adapters/time-entry-organization-conflicts', ['exports', 'busy-app/adapters/application'], function (exports, _application) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({
		buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
			if (requestType === 'updateRecord') {
				return this._super('time-entry', id, snapshot, requestType, query);
			} else {
				return this._super(modelName, id, snapshot, requestType, query);
			}
		}
	});
});