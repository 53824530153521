define("busy-app/controllers/logout", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service("auth"),

		init: function init() {
			this._super();
			this.logout.apply(this, arguments);
		},
		logout: function logout() {
			return this.get("auth").invalidateSession();
		}
	});
});