define('busy-app/services/pay-period', ['exports', '@busy-web/utils', 'busy-app/utils/organization/pay-period'], function (exports, _utils, _payPeriod) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		auth: Ember.inject.service('auth'),

		organizationPayPeriod: Ember.computed.alias('auth.organizationPayPeriod'),

		/**
   * Gets a single payperiod that the timestamp fits within
   *
   * @public
   * @method getPayPeriod
   * @param timestamp {number}
   * @return {object} The payPeriod Object
   */
		getPayPeriod: function getPayPeriod() {
			var timestamp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _utils.Time.timestamp();

			return callWithLegacySupport(this, _payPeriod.getPayPeriod, timestamp);
		},


		/**
   * Generates a list of pay periods from the timestamp provided
   * back to the number of periods asked for.
   *
   * @public
   * @method getPayPeriodRange
   * @param timestamp {number} start time for generating the periods
   * @param periods {number} number of periods to genrate
   * @return {array}
   */
		getPayPeriodRange: function getPayPeriodRange(timestamp, periods) {
			return callWithLegacySupport(this, _payPeriod.getPayPeriodRange, timestamp, periods);
		},
		getPayPeriodDateRange: function getPayPeriodDateRange(start, end) {
			var excludeEnd = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			return callWithLegacySupport(this, _payPeriod.getPayPeriodDateRange, start, end, excludeEnd);
		},
		getPreviousPayPeriod: function getPreviousPayPeriod() {
			var payPeriod = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getPayPeriod();

			var dayBefore = Ember.get(payPeriod, 'startDate').subtract(1, 'day').unix();

			return this.getPayPeriod(dayBefore);
		},
		getNextPayPeriod: function getNextPayPeriod() {
			var payPeriod = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.getPayPeriod();

			var dayAfter = Ember.get(payPeriod, 'endDate').add(1, 'day').unix();

			return this.getPayPeriod(dayAfter);
		},


		// index 0 is current, index -1 is previous, index 1 is next
		getPayPeriodByIndex: function getPayPeriodByIndex(index) {
			var _this = this;

			var payPeriod = this.getPayPeriod(_utils.Time.timestamp());

			if (index > 0) {
				return Array.from({ length: index }).reduce(function (acc) {
					return _this.getNextPayPeriod(acc);
				}, payPeriod);
			} else if (index < 0) {
				index = Math.abs(index);
				return Array.from({ length: index }).reduce(function (acc) {
					return _this.getPreviousPayPeriod(acc);
				}, payPeriod);
			} else {
				return payPeriod; // index 0
			}
		}
	});


	var callWithLegacySupport = function callWithLegacySupport(target, func) {
		for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
			args[_key - 2] = arguments[_key];
		}

		args.unshift(getPeriods(target));

		var result = func.apply(undefined, args);
		if (!Ember.isEmpty(result) && Array.isArray(result)) {
			return result.map(function (obj) {
				return createPayPeriodObject(target, obj);
			});
		}
		return createPayPeriodObject(target, result);
	};

	var getPeriods = function getPeriods(target) {
		var payPeriods = Ember.get(target, 'auth.organizationPayPeriod');

		if (!Ember.isNone(payPeriods) && payPeriods.toArray) {
			payPeriods = payPeriods.toArray();
		}

		if (Ember.isNone(payPeriods)) {
			payPeriods = [];
		}
		return payPeriods;
	};

	/**
  * creates a pay period data object to provide the
  * client with.
  *
  * @public
  * @method createPayPeriodObject
  * @param startDate {object} moment date object
  * @param endDate {object} moment date object
  * @return {object}
  */
	var createPayPeriodObject = function createPayPeriodObject(target, _ref) {
		var start = _ref.start,
		    end = _ref.end,
		    type = _ref.type;

		var owner = Ember.getOwner(target);
		return PayPeriodObject.create(owner.ownerInjection(), { type: type, start: start, end: end, startDate: _utils.Time.date(start), endDate: _utils.Time.date(end) });
	};

	var PayPeriodObject = Ember.Object.extend({
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		type: null,
		start: null,
		end: null,

		startDate: null,
		endDate: null,

		isCurrentPeriod: Ember.computed('start', 'end', function () {
			var today = _utils.Time.timestamp();
			var isCurrentPeriod = false;
			if (Ember.get(this, 'start') < today && today < Ember.get(this, 'end')) {
				isCurrentPeriod = true;
			}
			return isCurrentPeriod;
		}),

		dateRange: Ember.computed('startDate', 'endDate', function () {
			var start = _utils.Time.dateFormat(Ember.get(this, 'startDate'), 'MMM D');
			var end = _utils.Time.dateFormat(Ember.get(this, 'endDate'), 'MMM D YYYY');
			if (Ember.get(this, 'startDate').year() !== Ember.get(this, 'endDate').year()) {
				start = _utils.Time.dateFormat(Ember.get(this, 'startDate'), 'MMM D YYYY');
			}
			return start + ' - ' + end;
		}),

		startDateString: Ember.computed('startDate', function () {
			return _utils.Time.dateFormat(Ember.get(this, 'startDate'), 'MMM D YYYY');
		}),

		endDateString: Ember.computed('endDate', function () {
			return _utils.Time.dateFormat(Ember.get(this, 'endDate'), 'MMM D YYYY');
		}),

		/**
  	 * Is the signing feature enabled, signing enabled on the organization, and is this payperiod eligible for signing.
  	 * Assumes the pay period is attached to the organization of the curent logged in member.
  	 *
  	 * @public
  	 * @method isSignable
  	 * @return {boolean}
  	 */
		isSignable: Ember.computed('features.signatures', 'auth.organization.{isSignaturesEnabled,signatureDate}', 'end', function () {
			if (!Ember.get(this, 'features.signatures') || !Ember.get(this, 'auth.organization.isSignaturesEnabled')) {
				return false;
			}

			var signatureDate = Ember.get(this, 'auth.organization.signatureDate');
			var payPeriodEndTime = Ember.get(this, 'end');
			var startOfToday = _utils.Time.date().startOf('day').unix();

			return payPeriodEndTime >= signatureDate && payPeriodEndTime < startOfToday;
		})
	});
});