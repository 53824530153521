define('busy-app/components/jq-date-picker', ['exports', 'ember-jquery-date-range-picker/components/jq-date-picker'], function (exports, _jqDatePicker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _jqDatePicker.default;
    }
  });
});