define('busy-app/components/model-title/cost-code', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		state: Ember.inject.service(),

		/**
   * id is the only property that
   * should be passed in. All other
   * properties are set by the component.
   *
   * this is a id for the cost-code model
   *
   * @property id
   * @type {string} model UUID
   */
		id: null,

		/*** props made available in yield hash ***/

		title: '',
		subTitle: '',
		condensedTitle: '',
		isUnassigned: false,
		hasInfo: false,
		costCode: null,

		/*** end props ***/

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();

			this.getCostCode();
			this.setTitle();
		},
		getCostCode: function getCostCode() {
			if (!Ember.isEmpty(Ember.get(this, 'id'))) {
				var costCode = loadCostCode(Ember.get(this, 'state'), Ember.get(this, 'id'));
				Ember.set(this, 'costCode', costCode);
				Ember.set(this, 'hasInfo', true);
			} else {
				Ember.set(this, 'costCode', false);
				Ember.set(this, 'hasInfo', false);
			}
		},
		setTitle: function setTitle() {
			var costCode = Ember.get(this, 'costCode');

			var isUnassigned = true;
			var title = (0, _utils.loc)('Unassigned');
			var subTitle = '';
			var condensedTitle = (0, _utils.loc)('Unassigned');

			if (!Ember.isNone(costCode)) {
				title = Ember.get(costCode, 'costCode');
				subTitle = Ember.get(costCode, 'title');
				condensedTitle = title + ' <i>' + subTitle + '</i>';
				isUnassigned = false;
			}

			this.setProperties({ title: title, subTitle: subTitle, condensedTitle: condensedTitle, isUnassigned: isUnassigned });
		}
	});


	function loadCostCode(state, id) {
		return state.getState('cost-code', id);
	}
});