define('busy-app/controllers/report/time-off/index', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service('auth'),

		hasContent: Ember.computed('model.entries.@each', function () {
			return this.get('model.entries.length') > 0;
		}),

		showTimeEntryEdit: false,
		editEntry: null,

		createdBy: Ember.computed('auth.member.id', function () {
			return this.get('auth.member.fullName');
		}),

		createdOn: Ember.computed(function () {
			return _utils.Time.date().format('MM/DD/YYYY h:mm A');
		}),

		actions: {
			openTimeEntryAction: function openTimeEntryAction(entry) {
				this.set('editEntry', entry);
				this.set('showTimeEntryEdit', true);
			},
			closeEditDialog: function closeEditDialog() {
				this.set('showTimeEntryEdit', false);
				this.set('editEntry', null);
			},
			itemSaved: function itemSaved() {
				this.set('showTimeEntryEdit', false);
				this.set('editEntry', null);
				Ember.getOwner(this).lookup('route:report/time-card').refresh();
			},
			itemDeleted: function itemDeleted() {
				this.set('showTimeEntryEdit', false);
				this.set('editEntry', null);
				Ember.getOwner(this).lookup('route:report/time-card').refresh();
			}
		}
	});
});