define('busy-app/components/projects/cost-codes-manager', ['exports', '@busy-web/utils', 'busy-app/utils/collection', 'busy-app/utils/logger'], function (exports, _utils, _collection, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		// component definition
		classNames: ['c--projects--cost-codes-manager'],

		// services
		store: Ember.inject.service(),
		state: Ember.inject.service(),
		auth: Ember.inject.service(),

		// arguments
		onClose: null,
		onCancel: null,
		onSave: null,
		cancelText: (0, _utils.loc)('Cancel'),
		saveText: (0, _utils.loc)('Save'),
		projectId: null, // not required
		projectCostCodeLinks: null,

		// collections
		costCodes: null, // heavy use of passing values around by reference and relying on side effects
		costCodeGroups: null, // heavy use of passing values around by reference and relying on side effects

		// state
		isLoading: false,

		isAllSelected: Ember.computed('costCodes.@each._selected', function () {
			// Logger.info(this, 'COMPUTE', 'isAllSelected');

			if (Ember.isNone(Ember.get(this, 'costCodes'))) {
				return false;
			}

			return Ember.get(this, 'costCodes').isEvery('_selected');
		}),

		isPartialSelected: Ember.computed('isAllSelected', 'costCodes.@each._selected', function () {
			// Logger.info(this, 'COMPUTED', 'isPartialSelected', getProperties(this, ['isAllSelected', 'costCodes']));

			if (Ember.get(this, 'isAllSelected') || Ember.isNone(Ember.get(this, 'costCodes')) || !Ember.get(this, 'costCodes.isLoaded')) {
				return false;
			} else {
				return Ember.get(this, 'costCodes').isAny('_selected') && Ember.get(this, 'costCodes').isAny('_selected', false);
			}
		}),

		init: function init() {
			this._super.apply(this, arguments);
			// Logger.info(this, 'init');

			if (window.runningTests) {
				return;
			}

			this.loadData();
			_utils.Assert.isArray(Ember.get(this, 'projectCostCodeLinks'));
			_utils.Assert.test('onClose action must be provided', typeof Ember.get(this, 'onClose') === 'function');
			_utils.Assert.test('onCancel action must be provided', typeof Ember.get(this, 'onCancel') === 'function');
			_utils.Assert.test('onSave action must be provided and must return a promise', typeof Ember.get(this, 'onSave') === 'function');
		},
		loadData: function loadData() {
			var _this = this;

			Ember.set(this, 'isLoading', true);

			Ember.setProperties(this, {
				costCodes: null,
				costCodeGroups: null
			});

			return Ember.RSVP.hash({
				costCodes: this.loadCostCodes(),
				costCodeGroups: this.loadCostCodeGroups()
			}).then(function (data) {
				return _this.initData(data);
			}).finally(function () {
				return Ember.set(_this, 'isLoading', false);
			});
		},
		loadCostCodes: function loadCostCodes() {
			var query = {
				archived_on: null,
				_asc: ['costCode']
			};

			return Ember.get(this, 'state').findAll('costCode', query).then(function (results) {
				return (0, _collection.localeSortBy)(results, 'costCode');
			});
		},
		loadCostCodeGroups: function loadCostCodeGroups() {
			var query = {
				archived_on: null,
				_asc: ['groupName']
			};

			return Ember.get(this, 'state').findAll('costCodeGroup', query).then(function (results) {
				return (0, _collection.localeSortBy)(results, 'groupName');
			});
		},
		initData: function initData(_ref) {
			var costCodes = _ref.costCodes,
			    costCodeGroups = _ref.costCodeGroups;

			// Logger.info(this, 'initData', { costCodes, costCodeGroups });

			return Ember.setProperties(this, {
				costCodes: this.initCostCodes(costCodes),
				costCodeGroups: this.initCostCodeGroups(costCodeGroups, costCodes)
			});
		},
		initCostCodes: function initCostCodes(costCodes) {
			var links = Ember.get(this, 'projectCostCodeLinks');

			// Logger.info(this, 'initCostCodes', { costCodes, links });

			if (Ember.isNone(costCodes) || Ember.isEmpty(links)) {
				return costCodes;
			}

			costCodes.forEach(function (costCode) {
				var link = links.findBy('costCodeId', Ember.get(costCode, 'id'));
				var _selected = !Ember.isNone(link) && Ember.get(link, 'deletedOn') === null;

				Ember.setProperties(costCode, { _selected: _selected });
			});

			return costCodes;
		},
		initCostCodeGroups: function initCostCodeGroups(costCodeGroups, costCodes) {
			// Logger.info(this, 'buildCostCodeGroups', { costCodeGroups, costCodes });

			if (Ember.isNone(costCodeGroups)) {
				return costCodeGroups;
			}

			var costCodesByGroup = (0, _collection.groupBy)(costCodes, 'costCodeGroupId');

			if (costCodesByGroup.hasOwnProperty(null) && costCodesByGroup[null].length) {
				var id = _utils.UUID.generate();
				var unGroup = Ember.get(this, 'store').createRecord('cost-code-group', { id: id, groupName: (0, _utils.loc)('Ungrouped') });

				costCodeGroups.pushObject(unGroup);

				costCodesByGroup[id] = costCodesByGroup[null];
				delete costCodesByGroup[null];
			}

			costCodeGroups.forEach(function (group) {
				var groupCostCodes = Ember.get(costCodesByGroup, Ember.get(group, 'id'));

				if (!Ember.isNone(groupCostCodes)) {
					groupCostCodes = (0, _collection.localeSortBy)(groupCostCodes, 'costCode');
				}

				Ember.setProperties(group, {
					_costCodes: groupCostCodes,
					_selected: Ember.computed('_costCodes.@each._selected', function () {
						var costCodes = Ember.get(this, '_costCodes');
						return Ember.isNone(costCodes) ? false : Ember.get(this, '_costCodes').every(function (cc) {
							return Ember.get(cc, '_selected');
						});
					}),

					_selectedPartial: Ember.computed('_selected', '_costCodes.@each._selected', function () {
						var costCodes = Ember.get(this, '_costCodes');

						if (Ember.get(this, '_selected') || Ember.isNone(costCodes)) {
							return false;
						} else {
							return costCodes.isAny('_selected') && costCodes.isAny('_selected', false);
						}
					})
				});
			});

			return costCodeGroups;
		},


		/*
   * Action helpers
   */

		createCostCodeLink: function createCostCodeLink(costCode) {
			var createdOn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _utils.Time.timestamp();

			// Logger.info(this, 'createCostCodeLink', get(costCode, 'costCode'));

			var costCodeId = Ember.get(costCode, 'id');
			var projectId = Ember.get(this, 'projectId');

			var link = Ember.get(this, 'projectCostCodeLinks').findBy('costCodeId', costCodeId);

			if (Ember.isNone(link)) {
				// Logger.info(this, 'createCostCodeLink', get(costCode, 'costCode'), 'create');

				link = Ember.get(this, 'store').createRecord('project-cost-code-link', {
					// id: UUID.generate(),
					costCodeId: costCodeId,
					projectId: projectId,
					createdOn: createdOn,
					deletedOn: null
				});

				// console.log('link', link);
				// debugger;

				Ember.set(this, 'projectCostCodeLinks', Ember.get(this, 'projectCostCodeLinks').concat(link));
			} else {
				// Logger.info(this, 'createCostCodeLink', get(costCode, 'costCode'), 'undelete');

				Ember.setProperties(link, { deletedOn: null });
			}

			Ember.setProperties(costCode, { _selected: true });

			return link;
		},
		deleteCostCodeLink: function deleteCostCodeLink(costCode) {
			var deletedOn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _utils.Time.timestamp();

			var costCodeId = Ember.get(costCode, 'id');
			// Logger.info(this, 'deleteCostCodeLink', get(costCode, 'costCode'));

			var link = Ember.get(this, 'projectCostCodeLinks').findBy('costCodeId', costCodeId);

			// if it has an id, it's from the API, so set the deletedOn to make it patch as a deleted record
			// 	otherwise, it hasn't been persisted the database, so just destroy it
			if (Ember.get(link, 'id')) {
				Ember.setProperties(link, { deletedOn: deletedOn });
			} else {
				// Logger.info(this, 'destroyCostCode', get(costCode, 'costCode'));
				Ember.set(this, 'projectCostCodeLinks', Ember.get(this, 'projectCostCodeLinks').without(link));
			}

			Ember.setProperties(costCode, { _selected: false });

			return link;
		},
		toggleAll: function toggleAll(selectAll) {
			var _this2 = this;

			// Logger.info(this, 'toggleAll', selectAll);
			var costCodes = Ember.get(this, 'costCodes');
			var now = _utils.Time.timestamp();

			if (selectAll) {
				costCodes.forEach(function (costCode) {
					return _this2.createCostCodeLink(costCode, now);
				});
			} else {
				costCodes.forEach(function (costCode) {
					return _this2.deleteCostCodeLink(costCode, now);
				});
			}

			// Logger.info(this, 'toggleAll', 'complete');
		},


		actions: {
			createCostCodeLink: function createCostCodeLink(costCode, createdOn) {
				// Logger.info(this, 'createCostCodeLink', costCode, createdOn);

				return this.createCostCodeLink(costCode, createdOn);
			},
			deleteCostCodeLink: function deleteCostCodeLink(costCode, deletedOn) {
				// Logger.info(this, 'deleteCostCodeLink', costCode, deletedOn);

				return this.deleteCostCodeLink(costCode, deletedOn);
			},
			toggleAll: function toggleAll() {
				// Logger.info(this, 'toggleAll');

				var selectAll = !Ember.get(this, 'isAllSelected');

				return this.toggleAll(selectAll);
			},
			cancel: function cancel() {
				_logger.default.info(this, 'cancel');

				return this.sendAction('onCancel');
			},
			close: function close() {
				_logger.default.info(this, 'close');

				return this.sendAction('onClose');
			},
			save: function save() {
				var _this3 = this;

				var _EmberGetProperties = Ember.getProperties(this, ['onSave', 'projectCostCodeLinks', 'store']),
				    onSave = _EmberGetProperties.onSave,
				    projectCostCodeLinks = _EmberGetProperties.projectCostCodeLinks;

				_logger.default.info(this, 'save', projectCostCodeLinks);

				return onSave(projectCostCodeLinks).catch(function (err) {
					// Logger.error(this, 'actions:save', err);

					return Ember.RSVP.reject('Something went wrong (' + err.message + ')'); // FIXME
				}).then(function () {
					return _this3.send('close');
				});
			}
		}
	});
});