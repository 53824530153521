define('busy-app/models/project-seconds', ['exports', 'ember-data', 'busy-app/utils/unique-id'], function (exports, _emberData, _uniqueId) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		// used to match seconds and cost models
		uid: (0, _uniqueId.default)('startDate', 'endDate', 'projectId'),

		projectId: _emberData.default.attr('id', { sortable: true, sortBy: ['project.title'], nested: 'project.parentProjectId' }),
		startDate: _emberData.default.attr('number'),
		endDate: _emberData.default.attr('number'),
		regularSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overtimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		doubletimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenRegularSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenOvertimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenDoubletimeSeconds: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),

		project: _emberData.default.belongsTo('project'),

		cost: null,

		totalSeconds: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		childrenTotalSeconds: Ember.computed('childrenRegularSeconds', 'childrenOvertimeSeconds', 'childrenDoubletimeSeconds', function () {
			return this.get('childrenRegularSeconds') + this.get('childrenOvertimeSeconds') + this.get('childrenDoubletimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalRegularSeconds: Ember.computed('regularSeconds', 'childrenRegularSeconds', function () {
			return this.get('regularSeconds') + this.get('childrenRegularSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalOvertimeSeconds: Ember.computed('overtimeSeconds', 'childrenOvertimeSeconds', function () {
			return this.get('overtimeSeconds') + this.get('childrenOvertimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalDoubletimeSeconds: Ember.computed('doubletimeSeconds', 'childrenDoubletimeSeconds', function () {
			return this.get('doubletimeSeconds') + this.get('childrenDoubletimeSeconds');
		}).meta({ aggregateType: 'sum' }),

		totalTotalSeconds: Ember.computed('totalSeconds', 'childrenTotalSeconds', function () {
			return this.get('totalSeconds') + this.get('childrenTotalSeconds');
		}).meta({ aggregateType: 'sum' }),

		overburden: Ember.computed.alias('cost.overburden').meta({ aggregateType: 'sum' }),
		regularCost: Ember.computed.alias('cost.regularCost').meta({ aggregateType: 'sum' }),
		overtimeCost: Ember.computed.alias('cost.overtimeCost').meta({ aggregateType: 'sum' }),
		doubletimeCost: Ember.computed.alias('cost.doubletimeCost').meta({ aggregateType: 'sum' }),
		totalCost: Ember.computed.alias('cost.totalCost').meta({ aggregateType: 'sum' }),
		totalOverburdenCost: Ember.computed.alias('cost.totalOverburdenCost').meta({ aggregateType: 'sum' }),

		childrenOverburden: Ember.computed.alias('cost.childrenOverburden').meta({ aggregateType: 'sum' }),
		childrenRegularCost: Ember.computed.alias('cost.childrenRegularCost').meta({ aggregateType: 'sum' }),
		childrenOvertimeCost: Ember.computed.alias('cost.childrenOvertimeCost').meta({ aggregateType: 'sum' }),
		childrenDoubletimeCost: Ember.computed.alias('cost.childrenDoubletimeCost').meta({ aggregateType: 'sum' }),
		childrenTotalCost: Ember.computed.alias('cost.childrenTotalCost').meta({ aggregateType: 'sum' }),
		childrenTotalOverburdenCost: Ember.computed.alias('cost.childrenTotalOverburdenCost').meta({ aggregateType: 'sum' }),

		totalOverburden: Ember.computed.alias('cost.totalOverburden').meta({ aggregateType: 'sum' }),
		totalRegularCost: Ember.computed.alias('cost.totalRegularCost').meta({ aggregateType: 'sum' }),
		totalOvertimeCost: Ember.computed.alias('cost.totalOvertimeCost').meta({ aggregateType: 'sum' }),
		totalDoubletimeCost: Ember.computed.alias('cost.totalDoubletimeCost').meta({ aggregateType: 'sum' }),
		totalTotalCost: Ember.computed.alias('cost.totalTotalCost').meta({ aggregateType: 'sum' }),
		totalTotalOverburdenCost: Ember.computed.alias('cost.totalTotalOverburdenCost').meta({ aggregateType: 'sum' })
	});
});