define('busy-app/models/client-time-entry-break-log', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kLocationValidTime = 150;

	/**
  * `Model/ClientTimeEntryBreakLog`
  *
  * @class ClientTimeEntryBreakLog
  * @namespace Models
  * @extends DS.Model
  */
	/**
  * @module Models
  *
  */
	exports.default = _emberData.default.Model.extend({
		auth: Ember.inject.service('auth'),

		clientTimeEntryLogId: _emberData.default.attr('string'),
		originalTimeEntryBreakId: _emberData.default.attr('string'),
		mergedTimeEntryBreakId: _emberData.default.attr('string'),
		updaterMemberId: _emberData.default.attr('string'),
		timeEntryBreakId: _emberData.default.attr('string'),
		timeEntryId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		startMetaOffset: _emberData.default.attr('number'),
		paidBreak: _emberData.default.attr('boolean'),
		startTimeDst: _emberData.default.attr('boolean'),
		endTime: _emberData.default.attr('number'),
		endMetaOffset: _emberData.default.attr('number'),
		endTimeDst: _emberData.default.attr('boolean'),
		actionType: _emberData.default.attr('number'),
		timeEntryBreakCreatedOn: _emberData.default.attr('number'),
		timeEntryBreakDeletedOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		deviceType: _emberData.default.attr('string'),
		startLocationId: _emberData.default.attr('string'),
		endLocationId: _emberData.default.attr('string'),
		startDeviceTime: _emberData.default.attr('number'),
		endDeviceTime: _emberData.default.attr('number'),
		startTimeTrusted: _emberData.default.attr('number'),
		endTimeTrusted: _emberData.default.attr('number'),

		updaterMember: _emberData.default.belongsTo('member'),
		startLocation: _emberData.default.belongsTo('member-location', { referenceKey: 'startLocationId', foreignKey: 'id' }),
		endLocation: _emberData.default.belongsTo('member-location', { referenceKey: 'endLocationId', foreignKey: 'id' }),

		rangeString: Ember.computed('startTime', 'endTime', function () {
			return _utils.Time.timeFormat(this.get('startTime'), 'h:mm A') + ' - ' + _utils.Time.timeFormat(this.get('endTime'), 'h:mm A');
		}),

		totalTime: Ember.computed('startTime', 'endTime', function () {
			var total = 0;
			if (!Ember.isNone(this.get('endTime'))) {
				total = (this.get('endTime') || 0) - (this.get('startTime') || 0);
			}
			return total;
		}),

		rangeTotalString: Ember.computed('startTime', 'endTime', function () {
			var start = _utils.Time.timeFormat(this.get('startTime'), 'h:mm A');
			var end = _utils.Time.timeFormat(this.get('endTime'), 'h:mm A');
			var total = _utils.Time.convertSeconds(this.get('endTime') - this.get('startTime'), true);
			return start + ' - ' + end + ' (' + total.hours + ':' + total.minutes + ')';
		}),

		createdOnString: Ember.computed('createdOn', function () {
			// created on is in utc time so convert to local time.
			var time = this.get('createdOn') + _utils.Time.timezone(this.get('createdOn'));
			return _utils.Time.timeFormat(time, 'h:mm A MM/DD/YYYY');
		}),

		actionTypeString: Ember.computed('actionType', function () {
			var modelType = this.get('actionType');
			var actionString = '';
			if (modelType === 10) {
				actionString = (0, _utils.loc)("Break start");
			} else if (modelType === 20) {
				actionString = (0, _utils.loc)("Break end");
			} else if (modelType === 30 || modelType === 50) {
				actionString = (0, _utils.loc)("Manual break");
			} else if (modelType === 40 || modelType === 60) {
				actionString = (0, _utils.loc)("Edited break");
			} else if (modelType === 80) {
				actionString = (0, _utils.loc)('Break conflict resolution');
			} else if (modelType === 90) {
				actionString = (0, _utils.loc)('Break deleted');
			} else if (modelType === 100) {
				actionString = (0, _utils.loc)('Break deleted by conflict resolution');
			}
			return actionString;
		}),

		validLocationTime: Ember.computed('auth.organization.{timeRounding,timeRoundingInterval}', function () {
			var validLocTime = kLocationValidTime;
			if (this.get('auth.organization.timeRounding')) {
				validLocTime = kLocationValidTime + this.get('auth.organization.timeRoundingInterval');
			}
			return validLocTime;
		}),

		isStartLocationValid: Ember.computed('startLocation.createdOn', 'startLocationId', 'startTime', 'timeEntry.offset', 'validLocationTime', function () {
			var validLocTime = this.get('validLocationTime');
			var isValid = false;
			if (!Ember.isNone(this.get('startLocation.createdOn'))) {
				var locCreatedOn = this.get('startLocation.createdOn') + this.get('timeEntry.offset');
				if (locCreatedOn >= this.get('startTime') - validLocTime && locCreatedOn <= this.get('startTime') + validLocTime) {
					isValid = true;
				}
			}
			return isValid;
		}),

		isEndLocationValid: Ember.computed('endLocation.createdOn', 'endLocationId', 'endTime', 'timeEntry.offset', 'validLocationTime', function () {
			var validLocTime = this.get('validLocationTime');
			var isValid = false;
			if (!Ember.isNone(this.get('endLocation.createdOn')) && !Ember.isNone(this.get('endTime'))) {
				var locCreatedOn = this.get('endLocation.createdOn') + this.get('timeEntry.offset');
				if (locCreatedOn >= this.get('endTime') - validLocTime && locCreatedOn <= this.get('endTime') + validLocTime) {
					isValid = true;
				}
			}
			return isValid;
		}),

		/**
   * string for the time and date of the start of the break
   *
   */
		startString: Ember.computed('startTime', function () {
			return _utils.Time.timeFormat(this.get('startTime'), 'h:mm A M/D/YY');
		}),

		/**
   * string for the time and date of the end of the break
   *
   */
		endString: Ember.computed('endTime', function () {
			return _utils.Time.timeFormat(this.get('endTime'), 'h:mm A M/D/YY');
		}),

		/**
   * checks to see if the break is manual
   *
   */
		isManualBreak: Ember.computed('actionType', function () {
			return this.get('actionType') === 30 ? true : false;
		}),

		/**
   * checks to see if its the start of a time break
   *
   */
		isBreakStart: Ember.computed('actionType', function () {
			return this.get('actionType') === 10 ? true : false;
		}),

		/**
   * checks to see if its the end of a time break
   *
   */
		isBreakEnd: Ember.computed('actionType', function () {
			return this.get('actionType') === 20 ? true : false;
		}),

		/**
   * checks to see if its an edit of the break
   *
   */
		editBreak: Ember.computed('actionType', function () {
			return this.get('actionType') === 40 ? true : false;
		}),

		/**
   * string of date/time from beginnging to end of the break
   *
   */
		dateString: Ember.computed('startTime', 'endTime', function () {
			var date = '';
			if (!Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
				date = _utils.Time.timeFormat(this.get('startTime'), 'h:mm A M/D/YY') + ' - ' + _utils.Time.timeFormat(this.get('endTime'), 'h:mm A M/D/YY');
			}
			return date;
		})
	});
});