define('busy-app/components/charts/line-chart', ['exports', 'busy-app/components/charts/base-chart', '@busy-web/utils'], function (exports, _baseChart, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _baseChart.default.extend({
		classNames: ['c-charts-line-chart'],

		/**
   * The chart type
   *
   * @private
   * @property type
   * @type string
   */
		type: 'line',

		legends: '',
		colors: Ember.computed(function () {
			return ['#2997A5'];
		}),

		lineTension: 0,
		pointRadius: 6,
		pointHitRadius: 12,
		fill: false,
		title: '',

		chartOptions: Ember.computed(function () {
			var labels = this.get('legend') || [];
			if (!Ember.isArray(labels)) {
				labels = Ember.A([labels]);
			}
			var dataTypes = this.get('dataType') || [];
			if (!Ember.isArray(dataTypes)) {
				dataTypes = Ember.A([dataTypes]);
			}

			var options = {
				fill: this.get('fill'),
				lineTension: this.get('lineTension'),
				pointRadius: this.get('pointRadius') || 1,
				pointHitRadius: this.get('pointHitRadius') || 2,
				borderColor: this.get('colors'),
				backgroundColor: this.get('colors'),
				_label: labels,
				_dataType: dataTypes
			};

			return options;
		}),

		/**
   * Override of inherited method to set the
   * information that shows up in the tooltip
   *
   * @public
   * @method tooltip
   * @param label {string}
   * @param value {mixed}
   * @return {string}
   */
		tooltip: function tooltip(label, value, type) {
			if (type === 'time') {
				value = value * 60 * 60;
				// if dataType is set to the string `time` then return a time formated string
				return _utils.Time.convertSecondsString(value, 10, true, false, true);
			} else if (type === 'currency') {
				// if dataType is set to the string currency return a currency formated string
				return this.get('auth.currencySymbol') + Number(value.toFixed(2).toLocaleString());
			} else {
				// otherwise return the parent class default.
				return this._super.apply(this, arguments);
			}
		}
	});
});