define('busy-app/utils/organization/pay-period', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getPayPeriod = exports.getPayPeriodRange = exports.getPayPeriodDateRange = undefined;


  /**
   * Pay period generation tools
   *
   */

  /**
   * @module Utils
   *
   */
  var getPayPeriodDateRange = exports.getPayPeriodDateRange = function getPayPeriodDateRange(payPeriodDefs, start, end, excludeEnd) {
    excludeEnd = excludeEnd !== undefined ? excludeEnd : false;

    var timestamp = start;
    var def = getPayPeriodDef(payPeriodDefs, timestamp);
    var nextDef = getNextPayPeriodDef(payPeriodDefs, timestamp);

    var payPeriods = [];

    while (timestamp >= end) {
      var payPeriod = getPayPeriodByDefinition(timestamp, def, nextDef);

      timestamp = __createEndDate(payPeriod.start).subtract(1, 'days').unix();

      if (timestamp < end && excludeEnd === true) {
        break;
      }

      payPeriods.push(payPeriod);

      if (timestamp < Ember.get(def, 'startDate')) {
        nextDef = def;
        def = getPayPeriodDef(payPeriodDefs, timestamp);
      }

      if (Ember.isNone(def)) {
        break;
      }
    }
    return payPeriods;
  };

  /**
   * Generates a list of pay periods from the timestamp provided
   * back to the number of periods asked for.
   *
   * @public
   * @method getPayPeriodRange
   * @param timestamp {number} start time for generating the periods
   * @param periods {number} number of periods to genrate
   * @return {array}
   */
  var getPayPeriodRange = exports.getPayPeriodRange = function getPayPeriodRange(payPeriodDefs, timestamp, periods) {
    timestamp = timestamp || _utils.Time.timestamp();

    var def = getPayPeriodDef(payPeriodDefs, timestamp);
    var nextDef = getNextPayPeriodDef(payPeriodDefs, timestamp);

    var payPeriods = [];

    for (var i = 0; i < periods && timestamp > 0; i++) {
      var payPeriod = getPayPeriodByDefinition(timestamp, def, nextDef);

      timestamp = __createEndDate(payPeriod.start).subtract(1, 'days').unix();

      payPeriods.push(payPeriod);

      if (timestamp < Ember.get(def, 'startDate')) {
        nextDef = def;
        def = getPayPeriodDef(payPeriodDefs, timestamp);
      }
    }
    return payPeriods;
  };

  /**
   * Gets a single payperiod that the timestamp fits within
   *
   * @public
   * @method getPayPeriod
   * @param timestamp {number}
   * @return {object} The payPeriod Object
   */
  var getPayPeriod = exports.getPayPeriod = function getPayPeriod(payPeriodDefs, timestamp) {
    var def = getPayPeriodDef(payPeriodDefs, timestamp);
    var nextDef = getNextPayPeriodDef(payPeriodDefs, timestamp);
    return getPayPeriodByDefinition(timestamp, def, nextDef);
  };

  /**
   * Method to get a single pay period that can be called
   * by getPayPeriod or getPayPeriodRange
   *
   * @private
   * @method _getPayPeriod
   * @param timestamp {number}
   * @param def {DS.Model} organizationPayPeriod model object
   * @param nextDef {DS.Model} organizationPayPeriod model object
   * @return {object} PayPeriod Object
   */
  var getPayPeriodByDefinition = function getPayPeriodByDefinition(timestamp, def, nextDef) {
    var type = parseInt(Ember.get(def, 'payPeriodType'), 10);
    timestamp = timestamp || _utils.Time.timestamp();

    // get the startDate and endDate from the definition and next definition.
    // if the nextDefinition does not exist then make up a date some time in
    // the future.
    var start = Ember.get(def, 'startDate');
    var end = !Ember.isNone(nextDef) ? Ember.get(nextDef, 'startDate') : _utils.Time.date().add(1, 'years').unix();

    var dayOne = Ember.get(def, 'dayOne');
    var dayTwo = Ember.get(def, 'dayTwo');

    // get the payperiod
    var payPeriod = createPeriodForType(type, start, dayOne, dayTwo, timestamp);

    // adjust the period if the period is outside
    // the definitions start or end.
    if (!Ember.isNone(payPeriod)) {
      payPeriod = adjustForStartAndEndPeriod(payPeriod, start, end);
    }
    return payPeriod;
  };

  /**
   * Dispatches the pay period call to getWeekly or get Monthly types
   * all other types are unsupported at this time
   *
   * @private
   * @method createPeriodForType
   * @param timestamp {number}
   * @param definition {DS.Model} organizationPayPeriod Model object
   * @return {object}
   */
  var createPeriodForType = function createPeriodForType(type, start, dayOne, dayTwo, timestamp) {
    (false && !([10, 20, 30, 40].indexOf(type) !== -1) && Ember.assert("<utils:organization/pay-period> createPeriodByDefinition only accepts type: [ 10, 20, 30, 40 ]", [10, 20, 30, 40].indexOf(type) !== -1));


    if (type === 10 || type === 20) {
      // get the pay period bounds for biweekly and weekly periods.
      return createWeeklyPeriod(timestamp, start, dayOne, type);
    } else if (type === 30 || type === 40) {
      // get the pay period bounds for monthly and Semimonthly periods.
      return createMonthlyPeriod(timestamp, dayOne, dayTwo, type);
    }
  };

  var adjustForStartAndEndPeriod = function adjustForStartAndEndPeriod(payPeriod, start, end) {
    payPeriod = Object.assign({}, payPeriod);

    // Create moment js date objects to work with. The definition end
    // will be the next definition start minus one day.
    // NOTE: createEndDate returns a date at the end of the day. So
    // subtracting a day here will give us the previous day and 23:59 hours.
    var periodStart = __createStartDate(start).unix();
    var periodEnd = __createEndDate(end).subtract(1, 'days').unix();

    // if the definition start is greater than the payPeriod start
    // set the payPeriod start to the definition start
    if (periodStart > payPeriod.start) {
      payPeriod.start = periodStart;
    }

    // if the definition end is less than the payPeriod end
    // set the payPeriod end to the definition end.
    if (periodEnd < payPeriod.end) {
      payPeriod.end = periodEnd;
    }

    return payPeriod;
  };

  /**
   * Creates a pay period for both weekly and biweekly types
   * and returns the object
   *
   * @private
   * @method createWeeklyPeriod
   * @param timestamp {number}
   * @param payPeriodDay {number} The day of the week from 0-6 for Sun-Sat.
   * @param duration {number} The duration of the pay period in weeks
   * @return {object}
   */
  var createWeeklyPeriod = function createWeeklyPeriod(timestamp, defTimestamp, defDay, type) {
    var secsInWeek = 604800;
    var originalOffset = void 0,
        defStartDate = void 0;
    var duration = 1;

    // biweekly definition day is not the day of the
    // week like in weekly. It is an offset from the start date of the definition.
    // Add the offset to the start date to get the day of the week before doing any other operations.
    if (type === 20) {
      // mod the dayOne by 14 and add the that to the definition startDate
      defStartDate = _utils.Time.date(defTimestamp).add(defDay % 14, 'days');

      // moment js function returns the day of the week.
      defDay = defStartDate.day();
      duration = 2;

      // gets the week 0 or 1 that the biweekly period whould be in.
      originalOffset = parseInt(defStartDate.unix() / secsInWeek % 2, 10);
    }

    // get the timestamp day of the week
    var targetDay = _utils.Time.date(timestamp).day();

    // get the offset of the definition day and the timestamp day
    var dayOffset = defDay - targetDay;

    // create a start and end date by adding the dayOffset to the timestamp.
    // Adding the offset moves it to the nearest day that matches the definitions
    // day of the week. The end date gets the start date plus the duration (1 or 2 weeks) minus
    // one day. So it will end the day before the next start date.
    var startDate = __createStartDate(timestamp).add(dayOffset, 'days');
    var endDate = __createEndDate(startDate.unix()).add(duration, 'weeks').subtract(1, 'days');

    // Get the biweekly offset 0 or 1 the period is on currently
    var targetOffset = parseInt(startDate.unix() / secsInWeek % 2, 10);

    // if the originalOffset is set then ensure the offsets match
    // when the offset do not match adjust the dates back one week
    // to get the proper biweekly start and end dates
    if (!Ember.isNone(originalOffset) && originalOffset !== targetOffset) {
      startDate.subtract(1, 'weeks');
      endDate.subtract(1, 'weeks');
    }

    // At this point the dates could only be off if the timestamp
    // is older the start date. So adjust the start and end dates
    // back according to the duration (1 or 2 weeks).
    if (timestamp < startDate.unix()) {
      startDate.subtract(duration, 'weeks');
      endDate.subtract(duration, 'weeks');
    }

    var start = startDate.unix();
    var end = endDate.unix();
    return { start: start, end: end, type: type };
  };

  /**
   * Creates a pay period for both Semimonthly and monthly types
   * and returns the object
   *
   * @private
   * @method createMonthlyPeriod
   * @param timestamp {number}
   * @param definition {DS.Model} organizationPayPeriod Model object
   * @return {object}
   */
  var createMonthlyPeriod = function createMonthlyPeriod(timestamp, payPeriodDay, payPeriodDay2, type) {
    // disreguard monthly pay period day2 in case invalid data
    // shows up. Monthly pay periods only use day1.
    payPeriodDay2 = type === 40 ? payPeriodDay : payPeriodDay2;

    // set payPeriodDay2 to payPeriodDay if payPeriodDay2 was undefined
    payPeriodDay2 = !Ember.isNone(payPeriodDay2) ? payPeriodDay2 : payPeriodDay;

    // make sure payPeriodDay is less than payPeriodDay2
    if (payPeriodDay > payPeriodDay2) {
      var tempDay = payPeriodDay;
      payPeriodDay = payPeriodDay2;
      payPeriodDay2 = tempDay;
    }

    // create the start and end dates. The end date will be day2 -1 day
    // even in monthly because payPeriodDay2 is payPeriodDay when payPeriodDay2
    // was undefined.
    var startDate = __createStartDateByDay(timestamp, payPeriodDay);
    var endDate = __createEndDateByDay(timestamp, payPeriodDay2).subtract(1, 'day');

    if (timestamp < startDate.unix()) {
      // The timestamp is less than the startDate of the pay period. In order to cover
      // semi monthly and monthly, swap the start and end dates with the definition
      // dates to get fresh dates with no conversion and subtract a month
      // from the startDate and only one day from the endDate. Since the dayOne is ensured
      // to be less than dayTwo (the same in monthly cases) this will give us the previous
      // pay period.
      startDate = __createStartDateByDay(_utils.Time.date(timestamp).subtract(1, 'months').unix(), payPeriodDay2);
      endDate = __createEndDateByDay(timestamp, payPeriodDay).subtract(1, 'days');
    } else if (timestamp > endDate.unix()) {
      // The timestamp is greater than the startDate of the pay period. This is the same as
      // the less than case with swapping the dates, but in this case the end date needs to
      // be moved forward one month and then back on day.
      startDate = __createStartDateByDay(timestamp, payPeriodDay2);
      endDate = __createEndDateByDay(_utils.Time.date(timestamp).add(1, 'months').unix(), payPeriodDay).subtract(1, 'days');
    } else if (payPeriodDay === payPeriodDay2) {
      // if the first two case have not been hit then in monthly
      // cases the end date is the same as the start date so
      // add one month to the end date here.
      endDate.add(1, 'months');
    }

    var start = startDate.unix();
    var end = endDate.unix();
    return { start: start, end: end, type: type };
  };

  /**
   * gets the pay period definition model for the timestamp
   *
   * @private
   * @method getPayPeriodDef
   * @param timestamp {number}
   * @return {DS.Model} organizationPayPeriod model object
   */
  var getPayPeriodDef = function getPayPeriodDef(payPeriods, timestamp) {
    (false && !(!Ember.isEmpty(payPeriods) && Array.isArray(payPeriods)) && Ember.assert("<utils:organization/pay-periods> getPayPeriodDef() requires a list of OrganizationPayPeriod models", !Ember.isEmpty(payPeriods) && Array.isArray(payPeriods)));


    if (Ember.isNone(timestamp)) {
      timestamp = _utils.Time.timestamp();
    }

    var definition = void 0;
    payPeriods.forEach(function (item) {
      if (Ember.get(item, 'startDate') <= timestamp) {
        if (Ember.isNone(definition)) {
          definition = item;
        } else if (Ember.get(definition, 'startDate') < Ember.get(item, 'startDate')) {
          definition = item;
        }
      }
    });

    if (Ember.isNone(definition)) {
      createDefaultPeriod(Ember.get(payPeriods[0], 'store'));
    }

    return definition;
  };

  /**
   * gets the next pay period definition model after the timestamp
   *
   *
   * @private
   * @method getNextPayPeriodDef
   * @param timestamp {number}
   * @return {DS.Model} OrganizationPayPeriod model object
   */
  var getNextPayPeriodDef = function getNextPayPeriodDef(payPeriods, timestamp) {
    if (Ember.isNone(timestamp)) {
      timestamp = _utils.Time.timestamp();
    }

    var definition = null;
    payPeriods.forEach(function (item) {
      if (Ember.get(item, 'startDate') > timestamp) {
        if (Ember.isNone(definition)) {
          definition = item;
        } else if (Ember.get(item, 'startDate') < Ember.get(definition, 'startDate')) {
          definition = item;
        }
      }
    });
    return definition;
  };

  var createDefaultPeriod = function createDefaultPeriod(store) {
    return store.createRecord('organization-pay-period', {
      payPeriodType: 10,
      startDate: 345600,
      dayOne: 1
    });
  };

  /** internal helper methods **/
  var __createStartDate = function __createStartDate(timestamp) {
    return _utils.Time.date(timestamp).startOf('day');
  };
  var __createEndDate = function __createEndDate(timestamp) {
    return _utils.Time.date(timestamp).endOf('day');
  };

  var __createStartDateByDay = function __createStartDateByDay(timestamp, day) {
    var startDate = __createStartDate(timestamp).date(day);

    // if the startDate is the last day of the month and the month
    // has less days then the startDate like 31 and the month has 30
    // then the date will shift to the 1st of the next month so
    // use the last day of the month instead.
    if (_utils.Time.date(timestamp).endOf('month').date() < day) {
      startDate = __createStartDate(timestamp).endOf('month').startOf('day');
    }
    return startDate;
  };

  var __createEndDateByDay = function __createEndDateByDay(timestamp, day) {
    var endDate = __createEndDate(timestamp).date(day);

    // if the endDate is the last day of the month and the month
    // has less days then the endDate like 31 and the month has 30
    // then the date will shift to the 1st of the next month so
    // use the last day of the month instead.
    if (_utils.Time.date(timestamp).endOf('month').date() < day) {
      endDate = __createEndDate(timestamp).endOf('month');
    }
    return endDate;
  };
});