define('busy-app/components/employees/overtime-preview-dialog', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		model: null,
		effectiveDate: null,

		actions: {
			onClose: function onClose() {
				this.sendAction('onClose');
			}
		}
	});
});