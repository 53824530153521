define('busy-app/components/busy-title', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-title'],
		titleName: '',

		actions: {
			buttonClick: function buttonClick() {
				this.sendAction('onClick');
			}
		}
	});
});