define('busy-app/components/company/add-card-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Component.extend({
		classNames: ['v-company-settings-dialog-add-card'],

		store: Ember.inject.service('store'),

		stripeId: null,
		shouldUpdate: false,
		yearMenu: null,
		monthMenu: null,
		secCode: null,
		nameOnCard: null,
		ccNum: null,
		expMonth: null,
		expYear: null,
		defaultCard: false,

		init: function init() {
			this._super();
			this.setMenus();
		},
		setMenus: function setMenus() {
			var months = [];
			var years = [];
			for (var i = 1; i <= 12; i++) {
				var year = _utils.Time.date().add(i - 1, 'years').year();
				var key = i;
				key = key < 10 ? '0' + key : '' + key;

				years.pushObject(Ember.Object.create({ key: year, value: year }));
				months.pushObject(Ember.Object.create({ key: key, value: key }));
			}
			this.set('yearMenu', years);
			this.set('monthMenu', months);
		},
		saveCard: function saveCard() {
			var valid = this.validateCardInfo();
			if (!valid.isValid) {
				return Ember.RSVP.reject(valid.message);
			} else {
				var card = {
					customer_stripe_id: this.get('stripeId'),
					stripe_source: {
						source: {
							object: 'card',
							number: this.get('ccNum'),
							exp_month: this.get('expMonth'),
							exp_year: this.get('expYear'),
							cvc: this.get('secCode'),
							name: this.get('nameOnCard')
						}
					},
					is_default_source: this.get('defaultCard')
				};
				return this.get('store').rpcRequest('busy-stripe', 'create-new-card', card);
			}
		},
		validateCardInfo: function validateCardInfo() {
			var message = '';
			var isValid = true;
			if (!this.get('ccNum')) {
				isValid = false;
				message = (0, _utils.loc)('Card Number cannot be blank.');
			} else if (!/^(([3]\d{14})|([456]\d{15}))$/.test(this.get('ccNum'))) {
				isValid = false;
				message = (0, _utils.loc)('Card Number is Invalid. Please check your number and try again.');
			} else if (!this.get('secCode') || !this.get('nameOnCard')) {
				isValid = false;
				message = (0, _utils.loc)('Please fill out all required fields.');
			} else if (!this.get('expMonth') || !this.get('expYear')) {
				isValid = false;
				message = (0, _utils.loc)('Please set the expiration date.');
			}
			return { isValid: isValid, message: message };
		},


		actions: {
			close: function close() {
				this.sendAction('onClose');
			},
			selectYear: function selectYear(item) {
				this.set('expYear', item.key);
			},
			selectMonth: function selectMonth(item) {
				this.set('expMonth', item.key);
			},
			save: function save() {
				var _this = this;

				return this.saveCard().then(function () {
					_this.set('shouldUpdate', true);
				}).catch(function (err) {
					var message = (0, _utils.loc)("There was an error. Please try again at a later tine.");
					if (typeof err === 'string') {
						message = err;
					} else if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object' && Ember.isArray(err.code) && err.code[0] === 303) {
						message = (0, _utils.loc)("There was a problem processing your payment. Please check your card info and try again.");
					}
					return Ember.RSVP.reject(message);
				});
			}
		}
	});
});