define('busy-app/services/subscription', ['exports', 'ember-data', 'moment', '@busy-web/utils', 'busy-app/utils/logger', 'busy-app/utils/string'], function (exports, _emberData, _moment, _utils, _logger, _string) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	/***/
	// const kcostPerActiveUserMonthly = 11.99; // dollars
	// const kCostPerActiveUserAnnual = 9.99; // dollars
	var kAnnualInvoiceThreshold = 40000; // cutoff in dollars to use invoicing instead of realtime CC charge
	var kTrialDuration = 30; // days
	var EMPLOYEE_LIMIT_FREE = Infinity;
	var EMPLOYEE_LIMIT_PRO = Infinity;

	var kDisplayUserLimitFree = 100;
	var kDisplayUserLimitPro = 200;

	/**
  * `Services/Subscription`
  *
  * @class Subscription
  * @namespace Services
  * @extends Service
  * @uses Services.Auth
  * @uses Services.Features
  * @uses Services.Store
  */
	exports.default = Ember.Service.extend({
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		session: Ember.inject.service('session'),
		store: Ember.inject.service('store'),
		stripe: Ember.inject.service(),
		router: Ember.inject.service(),

		displayUserLimitFree: kDisplayUserLimitFree,
		displayUserLimitPro: kDisplayUserLimitPro,
		trialDuration: kTrialDuration,
		annualInvoiceThreshold: kAnnualInvoiceThreshold,
		availableCredit: null,
		renewsOn: null,
		subscriptionStatusVersion: Date.now(),

		// returns a promise object
		subscriptionStatus: Ember.computed('auth.organization.id', 'session.isAuthenticated', 'subscriptionStatusVersion', function () {
			var _this = this;

			var subscriptionStatusVersion = this.get('subscriptionStatusVersion');

			if (!Ember.isNone(this.get('auth.organization.id')) && this.get('session.isAuthenticated')) {
				return _emberData.default.PromiseObject.create({
					promise: this.get('store').queryRecord('organization-subscription-status', { organization_id: this.get('auth.organization.id') }).then(function (model) {
						if (model && model._internalModel && model._internalModel._data) {
							_logger.default.info(_this, { subscriptionStatus: model._internalModel._data });
						}

						model.set('subscriptionStatusVersion', subscriptionStatusVersion);

						return model;
					})
				});
			}
		}),

		// returns a promise object
		subscriptionPricing: Ember.computed('auth.organization.id', 'session.isAuthenticated', function () {
			var _this2 = this;

			if (!Ember.isNone(this.get('auth.organization.id')) && this.get('session.isAuthenticated')) {
				return _emberData.default.PromiseObject.create({
					promise: this.getPricing().then(function (prices) {
						_logger.default.info(_this2, { prices: prices });
						return prices;
					})
				});
			}
		}),

		costPerActiveUserMonthly: Ember.computed.alias('subscriptionPricing.monthly'),
		costPerActiveUserMonthlyText: Ember.computed('costPerActiveUserMonthly', function () {
			return Ember.isNone(this.get('costPerActiveUserMonthly')) ? null : '$' + this.get('costPerActiveUserMonthly') + (0, _utils.loc)('/mo');
		}),
		costPerActiveUserMonthlyPerYear: Ember.computed('costPerActiveUserMonthly', function () {
			return Ember.isNone(this.get('costPerActiveUserMonthly')) ? null : this.get('costPerActiveUserMonthly') * 12;
		}),
		costPerActiveUserMonthlyPerYearText: Ember.computed('costPerActiveUserMonthlyPerYear', function () {
			return Ember.isNone(this.get('costPerActiveUserMonthlyPerYear')) ? null : '$' + this.get('costPerActiveUserMonthlyPerYear') + (0, _utils.loc)('/yr');
		}),

		costPerActiveUserAnnual: Ember.computed.alias('subscriptionPricing.annually'),
		costPerActiveUserAnnualText: Ember.computed('costPerActiveUserAnnual', function () {
			return Ember.isNone(this.get('costPerActiveUserAnnual')) ? null : '$' + this.get('costPerActiveUserAnnual') + (0, _utils.loc)('/mo');
		}),
		costPerActiveUserAnnualPerYear: Ember.computed('costPerActiveUserAnnual', function () {
			return Ember.isNone(this.get('costPerActiveUserAnnual')) ? null : this.get('costPerActiveUserAnnual') * 12;
		}),
		costPerActiveUserAnnualPerYearText: Ember.computed('costPerActiveUserAnnualPerYear', function () {
			return Ember.isNone(this.get('costPerActiveUserAnnualPerYear')) ? null : '$' + this.get('costPerActiveUserAnnualPerYear') + (0, _utils.loc)('/yr');
		}),

		costPerActiveUser: Ember.computed('costPerActiveUserMonthly', 'costPerActiveUserAnnual', 'subscriptionStatus.isAnnualPlan', function () {
			return this.get('subscriptionStatus.isAnnualPlan') ? this.get('costPerActiveUserAnnual') : this.get('costPerActiveUserMonthly');
		}),
		costPerActiveUserText: Ember.computed('costPerActiveUserMonthlyText', 'costPerActiveUserAnnualText', 'subscriptionStatus.isAnnualPlan', function () {
			return this.get('subscriptionStatus.isAnnualPlan') ? this.get('costPerActiveUserAnnualText') : this.get('costPerActiveUserMonthlyText');
		}),
		costPerActiveUserPerYear: Ember.computed('costPerActiveUserMonthlyPerYear', 'costPerActiveUserAnnualPerYear', 'subscriptionStatus.isAnnualPlan', function () {
			return this.get('subscriptionStatus.isAnnualPlan') ? this.get('costPerActiveUserAnnualPerYear') : this.get('costPerActiveUserMonthlyPerYear');
		}),
		costPerActiveUserPerYearText: Ember.computed('costPerActiveUserMonthlyPerYearText', 'costPerActiveUserAnnualPerYearText', 'subscriptionStatus.isAnnualPlan', function () {
			return this.get('subscriptionStatus.isAnnualPlan') ? this.get('costPerActiveUserAnnualPerYearText') : this.get('costPerActiveUserMonthlyPerYearText');
		}),

		currentPlanCost: Ember.computed('isPaidAccount', 'costPerActiveUser', function () {
			if (this.get('isPaidAccount')) {
				if (!Ember.isNone(this.get('costPerActiveUser'))) {
					return this.get('costPerActiveUser').toFixed(2);
				}
			}
			return '0.00';
		}),

		// temporary deprecations
		freeAccount: Ember.computed.deprecatingAlias('isFreeAccount', { id: 'subscription.deprecate-freeAccount', until: '5.0.0' }),
		trialAccount: Ember.computed.deprecatingAlias('isFreeTrial', { id: 'subscription.deprecate-freeAccount', until: '5.0.0' }),
		proAccount: Ember.computed.deprecatingAlias('isProAccount', { id: 'subscription.deprecate-freeAccount', until: '5.0.0' }),
		enterpriseAccount: Ember.computed.deprecatingAlias('isEnterpriseAccount', { id: 'subscription.deprecate-freeAccount', until: '5.0.0' }),

		isFreeAccount: Ember.computed.alias('subscriptionStatus.isFreeAccount'),
		isProAccount: Ember.computed('subscriptionStatus.isProAccount', 'features.overrides/pro', function () {
			return this.get('subscriptionStatus.isProAccount') || this.get('features.overrides/pro');
		}),

		isEnterpriseAccount: Ember.computed.alias('subscriptionStatus.isEnterpriseAccount'),

		isFreeTrial: Ember.computed('subscriptionStatus.isFreeTrial', 'auth.organization.stripeCustomerId', function () {
			return this.get('subscriptionStatus.isFreeTrial') && Ember.isNone(this.get('auth.organization.stripeCustomerId'));
		}),

		isPaidAccount: Ember.computed('isProAccount', 'isFreeTrial', function () {
			return this.get('isProAccount') && !this.get('isFreeTrial');
		}),

		planTypeFormatted: Ember.computed('subscriptionStatus.{planTypeFormatted,isProAccount,isAnnualPlan}', 'auth.organization.stripeCustomerId', function () {
			if (this.get('subscriptionStatus.isAnnualPlan') || !this.get('subscriptionStatus.isProAccount') || Ember.isNone(this.get('auth.organization.stripeCustomerId'))) {
				return this.get('subscriptionStatus.planTypeFormatted');
			} else {
				return (0, _utils.loc)('Pro');
			}
		}),

		hasTrialed: Ember.computed.alias('subscriptionStatus.hasTrialed'),
		isAccountExpired: Ember.computed.and('hasPaymentMethod', 'isFreeAccount'),

		hasPaymentMethod: Ember.computed.alias('subscriptionStatus.hasCard'),

		periodDayDate: Ember.computed('subscriptionStatus.periodDay', function () {
			if (Ember.isNone(this.get('subscriptionStatus.periodDay'))) {
				return null;
			}

			// get the start of the current month, add one month, then set the day of the month to the `periodDay`
			return _utils.Time.date().startOf('month').add(1, 'month').date(this.get('subscriptionStatus.periodDay'));
		}),

		periodDayTimestamp: Ember.computed('periodDayDate', function () {
			return !Ember.isNone(this.get('periodDayDate')) ? this.get('periodDayDate').unix() : null;
		}),

		/**
   * @private
   * @method init
   * @constructor
   */
		init: function init() {
			this._super();

			if (this.get('session.isAuthenticated')) {
				this.refresher();
			}
		},
		refresher: function refresher() {
			Ember.run.later(this, function () {
				this.refreshStatus(function () {
					return true;
				});
				this.refresher();
			}, 60000);
		},


		/**
   * Computed property to get the subscription member size for the organization
   *
   * @public
   * @property positions
   * @type {DS.Model}
   */
		maxMemberSize: Ember.computed('subscriptionStatus.isProAccount', function () {
			return this.get('subscriptionStatus.isProAccount') ? EMPLOYEE_LIMIT_PRO : EMPLOYEE_LIMIT_FREE;
		}),

		membersAvailable: Ember.computed('maxMemberSize', 'auth.memberCount', function () {
			// const userLimit = this.get('maxMemberSize');
			// const totalCurrentUsers = this.get('auth.memberCount');
			// return userLimit - totalCurrentUsers;

			return Infinity;
		}),

		reloadSubscriptionSize: function reloadSubscriptionSize() {
			this.set('auth.memberCountRefresh', this.get('auth.memberCountRefresh') + 1);
			return Ember.RSVP.resolve(this.get('auth.memberCount'));
		},
		addSubscriptionMember: function addSubscriptionMember() {
			this.reloadSubscriptionSize();
			return this;
		},
		subtractSubscriptionMember: function subtractSubscriptionMember() {
			this.reloadSubscriptionSize();
			return this;
		},


		/**
   * refresh the model until the test method passed in is satisfied or
   * until a max of 100 tries has been completed.
   *
   * @public
   * @method refreshStatus
   * @param test {function} function( model ) return true if satisfied
   * @return {RSVP}
   */
		refreshStatus: function refreshStatus(test) {
			var _this3 = this;

			return new Ember.RSVP.Promise(function (resolve, reject) {
				_this3._refreshStatus(test, function (res) {
					if (res) {
						Ember.run(null, resolve, {});
					} else {
						_logger.default.error(_this3, 'Refresh status hit max tries');
						Ember.run(null, reject, { error: "Max tries reached" });
					}
				});
			});
		},


		/**
   * @public
   * @method startTrial
   */
		startTrial: function startTrial() {
			return this.setSubscriptionStatusPro();
		},


		/**
   * Send the user to the appropriate place to start upgrading their subscription
   *
   * @public
   * @method startUpgrade
   */
		startUpgrade: function startUpgrade() {
			return this.get('router').transitionTo('company-settings', { queryParams: { bc_tab: 'subscription' } });
		},


		/**
   * @public
   * @method setSubscriptionStatusFree
   */
		setSubscriptionStatusFree: function setSubscriptionStatusFree() {
			var _this4 = this;

			var waitForRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

			return this.subscriptionRPC('downgrade-to-free').then(function (data) {
				if (waitForRefresh) {
					return _this4.refreshStatus(function (model) {
						return model.get('productHandle') === data.handle;
					}).then(function () {
						return data;
					});
				} else {
					return data;
				}
			});
		},


		/**
   *	On the server side, `subscribedThroughProduct` is set to true during the upgradeToPro RPC
   *	The `subscribedThroughProduct` property will then be available on the OrganizationSubscriptionStatus model
   *
   * @public
   * @method setSubscriptionStatusPro
   */
		setSubscriptionStatusPro: function setSubscriptionStatusPro() {
			var _this5 = this;

			var trialBool = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

			var trial = trialBool ? 1 : 0;

			return this.subscriptionRPC('upgrade-to-pro', { trial: trial }).then(function (data) {
				return _this5.refreshStatus(function (model) {
					return model.get('productHandle') === data.handle;
				}).then(function () {
					return data;
				});
			});
		},


		/**
   * NOTE: DEPRECATED
   *
   * @public
   * @method updateCard
   * @param firstName {string}
   * @param lastName {string}
   * @param cardNumber {string}
   * @param expMonth {number}
   * @param expYear {number}
   * @param cvv {string}
   * @return {RSVP}
   */
		updateCard: function updateCard(firstName, lastName, cardNumber, expMonth, expYear, cvv) {
			var _this6 = this;

			Ember.deprecate('subscription.updateCard is deprecated. savePaymentMethod should be used instead.');

			_utils.Assert.isString(firstName);
			_utils.Assert.isString(lastName);
			_utils.Assert.isString(cardNumber);
			_utils.Assert.isNumber(expMonth);
			_utils.Assert.isNumber(expYear);
			_utils.Assert.isString(cvv);

			var card = {
				first_name: firstName,
				last_name: lastName,
				card_number: cardNumber,
				exp_month: expMonth,
				exp_year: expYear,
				cvv: cvv
			};

			var last4 = cardNumber.substr(-4);
			return this.subscriptionRPC('set-credit-card', card).then(function (data) {
				return _this6.refreshStatus(function (model) {
					return model.get('cardLastFour') === last4;
				}).then(function () {
					return data.success;
				});
			});
		},


		/**
   * TODO: uncomment and finish setting up method
   *
   * @public
   * @method getCouponCode
   * @param {string} coupon code
   * @return {RSVP}
   */
		getCouponCode: function getCouponCode(code) {
			_utils.Assert.isString(code);
			return this.chargifyRPC('get-coupon-code', { code: code }).then(function (res) {
				if (!Ember.isEmpty(res)) {
					return res;
				} else {
					return false;
				}
			});
		},


		/**
   * TODO: uncomment and finish setting up method
   *
   * @public
   * @method setCouponCode
   * @param {string} coupon code
   * @return {RSVP}
   */
		setCouponCode: function setCouponCode(code) {
			_utils.Assert.isString(code);
			return this.subscriptionRPC('set-coupon-code', { code: code }).then(function (data) {
				return data.success;
			});
		},


		/**
   * @public
   * @property availablePlans
   * @type array
   */
		availablePlans: Ember.computed(function () {
			return [Ember.Object.create({ id: 10, key: 'free', name: (0, _utils.loc)('Free') }), Ember.Object.create({ id: 20, key: 'pro', name: (0, _utils.loc)('Pro (Billed Monthly)') }), Ember.Object.create({ id: 30, key: 'pro-annual', name: (0, _utils.loc)('Pro (Billed Yearly)') })];
		}),

		subscriptionRPC: function subscriptionRPC(method) {
			var _this7 = this;

			var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			_logger.default.info(this, 'subscription-rpc', method, { params: params });

			if (window.runningTests) {
				return Ember.RSVP.resolve();
			}

			return this.get('store').rpcRequest('subscription-rpc', method, params).then(function (result) {
				_logger.default.info(_this7, 'subscription-rpc', method, 'result', { result: result });
				return result.data;
			}).catch(function (err) {
				_logger.default.error(_this7, 'subscription-rpc', method, 'catch', { Error: err });
				return Ember.RSVP.reject(err);
			});
		},
		chargifyRPC: function chargifyRPC(method) {
			var _this8 = this;

			var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			if (window.runningTests) {
				return Ember.RSVP.resolve();
			}

			return this.get('store').rpcRequest('chargify-rpc', method, params).then(function (result) {
				_logger.default.info(_this8, 'chargify-rpc', method, 'result', { result: result });
				return result.data;
			}).catch(function (err) {
				_logger.default.error(_this8, 'chargify-rpc', method, { Error: err });
				return Ember.RSVP.reject(err);
			});
		},


		/**
   * refresh the model based on a test function passed in that resolves to true
   * when the test is passed
   *
   * @private
   * @method _refreshStatus
   * @param test {function} test function that takes model as a param
   * @param callback {function} callback function that takes success true or false as a param
   * @param tries {number}
   */
		_refreshStatus: function _refreshStatus(test, callback) {
			var _this9 = this;

			var tries = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

			if (!Ember.isNone(this.get('subscriptionStatus.content'))) {
				this.get('subscriptionStatus.content').reload().then(function (model) {
					if (test(model)) {
						callback(true);
					} else if (tries <= 1000) {
						Ember.run.later(_this9, function () {
							this._refreshStatus(test, callback, tries + 1);
						}, 2000);
					} else {
						callback(false);
					}
				});
			} else if (tries <= 1000) {
				Ember.run.later(this, function () {
					this._refreshStatus(test, callback, tries + 1);
				}, 2000);
			} else {
				callback(false);
			}
		},


		/**
   * Get monthly and annual pricing from the API
   *
   * example response: { "monthly": 11.99, "annually": 9.99, "discount": 2}
   *
   * @public
   * @async
   * @method getPricing
   * @return {RSVP}
   */
		getPricing: function getPricing() {
			_logger.default.info(this, 'getPricing', {});

			return this.subscriptionRPC('get-pricing');
		},


		/**
   * getBillingDetails method on Subscription RPC
   *
   * example response:
   * ```
   * {
   * 	"first_name": "John",
   * 	"last_name": "T. Doe",
   * 	"masked_card_number": "XXXX-XXXX-XXXX-1234",
   * 	"card_type": "visa",
   * 	"expiration_month": 9,
   * 	"expiration_year": 2020,
   * 	"billing_phone": "555-555-5555",
   * 	"billing_address": "555 N 555 E",
   * 	"billing_city": "St George",
   * 	"billing_state": "UT",
   * 	"billing_zip": "84790",
   * 	"billing_country": "US",
   * 	"payment_type": "credit_card"
   * }
   * ```
   *
   * @public
   * @async
   * @method getBillingDetails
   * @return {RSVP}
   */
		getBillingDetails: function getBillingDetails() {
			_logger.default.info(this, 'getBillingDetails', {});

			return this.subscriptionRPC('get-billing-details').then(function (response) {
				var data = Ember.Object.create(response);

				var cardHolderName = [data.get('first_name'), data.get('last_name')].join(' ').trim();

				return Ember.Object.create({
					cardHolderName: cardHolderName,
					maskedCardNumber: data.get('masked_card_number'),
					cardMonth: data.get('expiration_month'),
					cardYear: data.get('expiration_year'),
					paymentType: data.get('payment_type'),
					cardType: data.get('card_type'),
					cardCVC: '',

					address: data.get('billing_address'),
					address2: data.get('billing_address_2'),
					city: data.get('billing_city'),
					state: data.get('billing_state'),
					postalCode: data.get('billing_zip'),
					country: data.get('billing_country'),
					phone: data.get('billing_phone')
				});
			});
		},


		/**
   * updateBillingAddress method on Subscription RPC
   *
   * Remote errors are logged, but are not caught by this method.
   *
   * @public
   * @async
   * @method updateBillingAddress
  	 * @param paymentInformation {Object}
  	 * @param paymentInformation.address {string}
  	 * @param [paymentInformation.address2=""] {string}
  	 * @param paymentInformation.postalCode {string}
  	 * @param paymentInformation.city {string}
  	 * @param paymentInformation.state {string} 2 character state code
  	 * @param paymentInformation.country {string} 2 character country code
  	 * @param [paymentInformation.phone=""] {string}
   * @return {RSVP}
   */
		updateBillingAddress: function updateBillingAddress(billingDetails) {
			var _this10 = this;

			_logger.default.info(this, 'updateBillingAddress', { billingDetails: billingDetails });
			_utils.Assert.isObject(billingDetails);
			_utils.Assert.isString(billingDetails.get('address'));
			_utils.Assert.isString(billingDetails.get('city'));
			_utils.Assert.isString(billingDetails.get('state'));
			_utils.Assert.isString(billingDetails.get('postalCode'));
			_utils.Assert.isString(billingDetails.get('country'));

			var data = {
				billing_address: billingDetails.get('address'),
				billing_address_2: Ember.isEmpty(billingDetails.get('address2')) ? '' : billingDetails.get('address2'), // do not pass `null`, RPC requires a string
				billing_city: billingDetails.get('city'),
				billing_state: billingDetails.get('state'),
				billing_zip: billingDetails.get('postalCode'),
				billing_country: billingDetails.get('country'),
				billing_phone: Ember.isEmpty(billingDetails.get('phone')) ? '' : billingDetails.get('phone') // do not pass `null`, RPC requires a string
			};

			return this.subscriptionRPC('update-billing-address', data).catch(function (err) {
				_logger.default.error(_this10, 'updateBillingAddress', err);
				return Ember.RSVP.reject(err);
			});
		},


		/**
   * getSubscriptionDetails method on Subscription RPC
   *
   * example result:
   * ```
   * {
   * 	"id": 18019341,
   * 	"state": "active",
   * 	"activated_at": "2017-06-06T21:09:09Z",
   * 	"created_at": "2017-06-06T21:09:06Z",
   * 	"updated_at": "2017-06-07T17:04:19Z",
   * 	"balance_in_cents": -10000,
   * 	"current_period_ends_at": "2018-06-06T21:09:06Z",
   * 	"next_assessment_at": "2018-06-06T21:09:06Z"
   * }
   * ```
   *
   * @public
   * @async
   * @method getSubscriptionDetails
   * @return {RSVP}
   */
		getSubscriptionDetails: function getSubscriptionDetails() {
			_logger.default.info(this, 'getSubscriptionDetails', {});

			return this.subscriptionRPC('get-subscription-details').then(function (response) {
				var data = Ember.Object.create(response);

				return Ember.Object.create({
					activatedAt: data.get('activated_at'),
					balanceInCents: data.get('balance_in_cents'),
					currentPeriodEndsAt: data.get('current_period_ends_at'),
					id: data.get('id'),
					nextAssessmentAt: data.get('next_assessment_at'),
					state: data.get('state'),
					createdAt: data.get('created_at'),
					updatedAt: data.get('updated_at')
				});
			});
		},


		/**
   * getSubscriptionPreview method on Subscription RPC
   *
   * see `reformatSubscriptionPreviewResults()` for example of results
   *
   * @public
   * @async
   * @method getSubscriptionPreview
   * @param billingDetails {Object} 2 character state code
   * @param billingDetails.subscriptionType {string} valid values are: `yearly` and `monthly`
   * @param billingDetails.allocatedQuantity {number} number of subscriptions
   * @param billingDetails.billingCountry {string} 2 character country code
   * @param billingDetails.billingState {string} 2 character state code
   * @param billingDetails.billingCity {string} city name
   * @param billingDetails.billingZip {string} postal code
   * @param billingDetails.billingAddress {string} street address
   * @return {RSVP}
   */
		getSubscriptionPreview: function getSubscriptionPreview(billingDetails) {
			var _this11 = this;

			_logger.default.info(this, 'getSubscriptionPreview', { billingDetails: billingDetails });
			_utils.Assert.isObject(billingDetails);
			_utils.Assert.isString(billingDetails.get('subscriptionType'));
			_utils.Assert.isNumber(billingDetails.get('allocatedQuantity'));
			_utils.Assert.isString(billingDetails.get('billingCountry'));
			_utils.Assert.isString(billingDetails.get('billingState'));
			_utils.Assert.isString(billingDetails.get('billingCity'));
			_utils.Assert.isString(billingDetails.get('billingZip'));
			_utils.Assert.isString(billingDetails.get('billingAddress'));

			var params = {
				subscription_type: billingDetails.get('subscriptionType'),
				allocated_quantity: billingDetails.get('allocatedQuantity'),
				billing_country: billingDetails.get('billingCountry'),
				billing_state: billingDetails.get('billingState'),
				billing_city: billingDetails.get('billingCity'),
				billing_zip: billingDetails.get('billingZip'),
				billing_address: billingDetails.get('billingAddress')
			};

			return this.subscriptionRPC('get-subscription-preview', params).then(function (results) {
				return _this11.reformatSubscriptionPreviewResults(results, billingDetails.get('subscriptionType'));
			});
		},


		/**
   * Using the payment information on file for the current organization, charge the customer for an annual subscription for the specified number of users.
   *
   * The customer is charged at the annual rate, but a credit is applied on Chargify based on the monthly rate.
   *
   * For Example:
   *  - 10 users at an annual rate of $9.99 per month will result in a charge of $1,199.00 (10 * $9.99 * 12)
   *  - The credit to the organization is then based on the monthly rate of $12.99 for $1,558.80 (10 * $12.99 * 12)
   *
   * These credits are consumed monthly using the monthly rate, via metering, based on Active Users as defined in the Fair Billing Policy.
   *
   * @public
   * @async
   * @method chargeAnnualSubscription
   * @param numberOfUsers {number}
   * @return {RSVP}
   */
		chargeAnnualSubscription: function chargeAnnualSubscription(numberOfUsers) {
			var _this12 = this;

			_logger.default.info(this, 'chargeAnnualSubscription', { numberOfUsers: numberOfUsers });
			_utils.Assert.isNumber(numberOfUsers);

			var params = { number_of_users: numberOfUsers };

			return this.subscriptionRPC('charge-annual-subscription', params).catch(function (err) {
				var errorMessage = _this12.getFriendlyError(err, {
					593: (0, _utils.loc)('Annual discount adjustment failed.'),
					594: (0, _utils.loc)('Payment failed.'),
					595: (0, _utils.loc)('Credit Card Declined.'),
					596: (0, _utils.loc)('Not pro subscriber.'),
					597: (0, _utils.loc)('No credit card set.'),
					598: (0, _utils.loc)('User does not have permission.'),
					599: (0, _utils.loc)('Customer is already an annual subscriber.')
				});

				if (typeof errorMessage === 'string') {
					_logger.default.error(_this12, 'changeAnnualSubscription', { errorMessage: errorMessage });
				}
				return Ember.RSVP.reject(errorMessage);
			});
		},


		/**
   * Change the `numberOfUsers` that will be used the next time the annual subscription is renewed.
   * Nothing is charged to the credit card as a direct result of this call.
   *
   * Remote errors are logged, but are not caught by this method.
   *
   * @public
   * @async
   * @method changeAnnualSubscriptionCount
   * @param numberOfUsers {number}
   * @return {RSVP}
   */
		changeAnnualSubscriptionCount: function changeAnnualSubscriptionCount(numberOfUsers) {
			var _this13 = this;

			_logger.default.info(this, 'changeAnnualSubscriptionCount', { numberOfUsers: numberOfUsers });
			_utils.Assert.isNumber(numberOfUsers);

			var params = { number_of_users: numberOfUsers };

			return this.subscriptionRPC('change-annual-subscription-count', params).catch(function (err) {
				_logger.default.error(_this13, 'changeAnnualSubscriptionCount', err);
				return Ember.RSVP.reject(err);
			});
		},


		/**
   * Remote errors are logged, but are not caught by this method.
   *
   * @public
   * @async
   * @method invoiceAnnualSubscription
   * @param numberOfUsers {number}
   * @return {RSVP}
   */
		invoiceAnnualSubscription: function invoiceAnnualSubscription(numberOfUsers) {
			var _this14 = this;

			_logger.default.info(this, 'invoiceAnnualSubscription', { numberOfUsers: numberOfUsers });
			_utils.Assert.isNumber(numberOfUsers);

			var params = { number_of_users: numberOfUsers };
			return this.subscriptionRPC('invoice-annual-subscription', params).then(function (data) {
				_logger.default.info(_this14, 'getPdfInvoice', { data: data });
				var invoiceId = data && data.hasOwnProperty('invoice_id') ? parseInt(data.invoice_id, 10) : null;
				return invoiceId;
			}).catch(function (err) {
				_logger.default.error(_this14, 'invoiceAnnualSubscription', err);
				return Ember.RSVP.reject(err);
			});
		},


		/**
   * Resets `OrganizationSubscriptionStatus` properties `annualSubscriptionDate` and `annualSubscriptionCount`, preventing the annual subscription from recurring.
   * The organization remains a pro account, reverting to monthly billing instead.
   *
   * Remote errors are logged, but are not caught by this method.
   *
   * @public
   * @async
   * @method cancelAnnualSubscription
   * @return {RSVP}
   */
		cancelAnnualSubscription: function cancelAnnualSubscription() {
			var _this15 = this;

			_logger.default.info(this, 'cancelAnnualSubscription', {});

			return this.subscriptionRPC('cancel-annual-subscription').catch(function (err) {
				_logger.default.error(_this15, 'cancelAnnualSubscription', err);
				return Ember.RSVP.reject(err);
			});
		},


		/**
   * @public
   * @async
   * @method getPdfInvoice
   * @param [invoiceId] {string}
   * @return {RSVP}
   */
		getPdfInvoice: function getPdfInvoice() {
			var invoiceId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('subscriptionStatus.annualSubscriptionPendingInvoiceId');

			_logger.default.info(this, 'getPdfInvoice', { invoiceId: invoiceId });
			_utils.Assert.isInteger(invoiceId);

			var params = { invoice_id: invoiceId };
			return this.subscriptionRPC('get-pdf-invoice', params);
		},


		/**
   * Opens the PDF of the specified invoice in a new window. (annualSubscriptionPendingInvoiceId if none specified)
   *
   * Because popup blockers prevent window.open() calls from inside async actions, a blank window is opened before attempting to retrieve invoice information.
   * When the invoice information has been successfully retrieved, location.replace() is used to redirect the previously opened window.
   * If the invoice information fails to load, a bare minimum error message is displayed, prompting the user to close the window.
   *
   * @public
   * @async
   * @method openPdfInvoice
   * @param [invoiceId] {string}
   * @return {RSVP}
   */
		openPdfInvoice: function openPdfInvoice() {
			var _this16 = this;

			var invoiceId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('subscriptionStatus.annualSubscriptionPendingInvoiceId');

			_logger.default.info(this, 'openPdfInvoice', { invoiceId: invoiceId });
			_utils.Assert.isInteger(invoiceId);

			// open window before async call as workaround for popup blocking
			var invoiceWindow = window.open();
			invoiceWindow.document.write('\n\t\t\t<h1>' + (0, _utils.loc)('Loading Invoice...') + '</h1>\n\t\t');

			return this.getPdfInvoice(invoiceId).then(function (results) {
				_logger.default.info(_this16, 'openPdfInvoice', { invoiceWindow: invoiceWindow, results: results });

				if (results && results.hasOwnProperty('url')) {
					invoiceWindow.document.write('<p><button onClick="javascript:self.close()">' + (0, _utils.loc)('Return to busybusy') + '</button></p>');
					invoiceWindow.document.close(); // close document stream

					invoiceWindow.location.replace(results.url);
					invoiceWindow.focus();
				} else {
					invoiceWindow.close();
				}
			}).catch(function (err) {
				_logger.default.error(_this16, 'openPdfInvoice', { err: err });
				invoiceWindow.document.write('\n\t\t\t\t<hr>\n\t\t\t\t<h1>' + (0, _utils.loc)('Error.') + '</h1>\n\t\t\t\t<h2>' + (0, _utils.loc)('The requested invoice could not be loaded.') + '</h2>\n\t\t\t\t<h2><button onClick="javascript:self.close()">Return to busybusy</button></h2>\n\t\t\t');
				invoiceWindow.document.close(); // close document stream
				invoiceWindow.close();
			});
		},


		/**
   * wraps the series of remote calls needed to update the organizations payment information
   *
   * 1. getStripeToken() call to Stripe
   * 2. setCreditCardWithToken() call to busybusy API to attach the payment profile
   *
   * The API will pick up the updated Billing Address from Chargify as part of the `setCreditCardWithToken()` call
   *
  	 * Remote errors are logged, but are not caught by this method.
   *
   * @public
   * @async
   * @method savePaymentMethod
   * @param paymentInformation {EmberObject}
   * @param paymentInformation.cardNumber {string}
   * @param paymentInformation.cardCVC {string}
   * @param paymentInformation.cardMonth {string}
   * @param paymentInformation.cardYear {string}
   * @param paymentInformation.address {string}
   * @param [paymentInformation.address2] {string}
   * @param paymentInformation.postalCode {string}
   * @param paymentInformation.city {string}
   * @param paymentInformation.state {string} 2 character state code
   * @param paymentInformation.country {string} 2 character country code
  	 * @param [paymentInformation.phone=""] {string}
   * @return {RSVP}
   */
		savePaymentMethod: function savePaymentMethod(paymentInformation) {
			var _this17 = this;

			_logger.default.info(this, 'savePaymentMethod', { paymentInformation: paymentInformation });

			// convert NULL values to empty strings to make assertions and RPCs happy
			paymentInformation.set('address', paymentInformation.get('address') === null ? '' : paymentInformation.get('address'));
			paymentInformation.set('address2', paymentInformation.get('address2') === null ? '' : paymentInformation.get('address2'));
			paymentInformation.set('postalCode', paymentInformation.get('postalCode') === null ? '' : paymentInformation.get('postalCode'));
			paymentInformation.set('city', paymentInformation.get('city') === null ? '' : paymentInformation.get('city'));
			paymentInformation.set('state', paymentInformation.get('state') === null ? '' : paymentInformation.get('state'));
			paymentInformation.set('country', paymentInformation.get('country') === null ? '' : paymentInformation.get('country'));

			_utils.Assert.isObject(paymentInformation);
			_utils.Assert.isString(paymentInformation.get('cardNumber'));
			_utils.Assert.isString(paymentInformation.get('cardCVC'));
			_utils.Assert.isNumber(paymentInformation.get('cardMonth'));
			_utils.Assert.isNumber(paymentInformation.get('cardYear'));

			_utils.Assert.isString(paymentInformation.get('address'));
			_utils.Assert.isString(paymentInformation.get('postalCode'));
			_utils.Assert.isString(paymentInformation.get('city'));
			_utils.Assert.isString(paymentInformation.get('state'));
			_utils.Assert.isString(paymentInformation.get('country'));

			// const last4 = paymentInformation.get('cardNumber').substr(-4);

			return this.getStripeToken(paymentInformation).then(function (stripeToken) {
				return _this17.setCreditCardWithToken(stripeToken);
			})
			// .then(() => this.updateBillingAddress(paymentInformation)) // FIXME: re-enable this when chargify fixes their partial update bug
			.then(function () {
				return _this17.set('subscriptionStatusVersion', Date.now());
			}).then(function () {
				return _this17.refreshStatus(function (model) {
					return model.get('subscriptionStatusVersion') >= _this17.get('subscriptionStatusVersion');
				});
			}) // wait for busybusy to update
			.catch(function (err) {
				_logger.default.error(_this17, 'savePaymentMethod', err);
				return Ember.RSVP.reject(err);
			});
		},


		/**
   * Used by `savePaymentMethod()` to obtain a token from stripe which can then be used to attach payment information to a customer.
   * This method should only be used in conjuction with `setCreditCardWithToken()`
   *
   * Uses the Stripe service to call Stripe's API directly with the provided payment information.
   * The resulting token information is passed to the busybusy API instead of the payment information itself.
   * This approach keeps payment information secure while preventing busybusy from falling under PCI Compliance obligations.
   *
   * https://stripe.com/docs/stripe.js/v2#card-createToken
   * https://stripe.com/docs/testing#cards-responses
   *
   * @private
   * @async
   * @method getStripeToken
   * @param paymentInformation {EmberObject}
   * @param paymentInformation.cardNumber {string}
   * @param paymentInformation.cardCVC {string}
   * @param paymentInformation.cardMonth {string}
   * @param paymentInformation.cardYear {string}
   * @param paymentInformation.cardHolderName {string}
   * @param paymentInformation.address {string}
   * @param [paymentInformation.address2] {string}
   * @param paymentInformation.postalCode {string}
   * @param paymentInformation.city {string}
   * @param paymentInformation.state {string} 2 character state code
   * @param paymentInformation.country {string} 2 character country code
   * @return {RSVP}
   */
		getStripeToken: function getStripeToken(paymentInformation) {
			var _this18 = this;

			_logger.default.info(this, 'getStripeToken', { paymentInformation: paymentInformation });
			_utils.Assert.isObject(paymentInformation);
			_utils.Assert.isString(paymentInformation.get('cardNumber'));
			_utils.Assert.isString(paymentInformation.get('cardCVC'));
			_utils.Assert.isNumber(paymentInformation.get('cardMonth'));
			_utils.Assert.isNumber(paymentInformation.get('cardYear'));
			_utils.Assert.isString(paymentInformation.get('cardHolderName'));
			_utils.Assert.isString(paymentInformation.get('address'));
			_utils.Assert.isString(paymentInformation.get('postalCode'));
			_utils.Assert.isString(paymentInformation.get('city'));
			_utils.Assert.isString(paymentInformation.get('state'));
			_utils.Assert.isString(paymentInformation.get('country'));

			var cardData = {
				// required stripe fields
				number: paymentInformation.get('cardNumber'),
				cvc: paymentInformation.get('cardCVC'),
				exp_month: paymentInformation.get('cardMonth'),
				exp_year: paymentInformation.get('cardYear'),

				// optional stripe fields
				name: paymentInformation.get('cardHolderName'),
				address_line1: paymentInformation.get('address'),
				address_line2: paymentInformation.get('address2'),
				address_zip: paymentInformation.get('postalCode'),
				address_city: paymentInformation.get('city'),
				address_state: paymentInformation.get('state'),
				address_country: paymentInformation.get('country')
			};

			return this.get('stripe.card').createToken(cardData).then(function (response) {
				var data = Ember.Object.create(response);
				var stripeToken = data.get('id');
				var stripeCardId = data.get('card.id');
				var stripeCardFingerprint = data.get('card.fingerprint');

				_logger.default.info(_this18, 'upgrade-dialog', 'getStripeToken', { response: response, stripeToken: stripeToken, stripeCardId: stripeCardId, stripeCardFingerprint: stripeCardFingerprint });

				return stripeToken;
			}).catch(function (err) {
				_logger.default.error(_this18, 'savePaymentMethod', 'stripe.card.createToken()', err);

				// throw the error message returned by Stripe
				if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object' && err.hasOwnProperty('error') && err.error.hasOwnProperty('message')) {
					return Ember.RSVP.reject(err.error.message);
				}

				return Ember.RSVP.reject(err);
			});
		},


		/**
   * Used by savePaymentMethod() to relay the token obtained from `getStripeToken()` to the busybusy web services, which attaches the payment information to the customer.
   * This method should only be used in conjuction with `getStripeToken()`
   *
   * @private
   * @async
   * @method setCreditCardWithToken
   * @param tokenId {string}
   * @return {RSVP}
   */
		setCreditCardWithToken: function setCreditCardWithToken(tokenId) {
			var _this19 = this;

			_logger.default.info(this, 'setCreditCardWithToken', { tokenId: tokenId });
			_utils.Assert.isString(tokenId);

			var params = { token_id: tokenId };

			return this.subscriptionRPC('set-credit-card-with-token', params).catch(function (err) {
				_logger.default.error(_this19, 'setCreditCardWithToken', { err: err });

				var errorMessage = _this19.getFriendlyError(err, {
					547: (0, _utils.loc)('An error occurred while processing your card. Try again in a little bit.'),
					548: (0, _utils.loc)('Your card has expired.'),
					549: (0, _utils.loc)('Your card\'s security code is incorrect.'),
					550: (0, _utils.loc)('Your card was declined.')
				});

				if (typeof errorMessage === 'string') {
					_logger.default.error(_this19, 'setCreditCardWithToken', { errorMessage: errorMessage });
				}
				return Ember.RSVP.reject(errorMessage);
			});
		},


		/**
   * Transform the raw subscriptionPreview RPC results to more useable formatting.
   * Chargify returns monetary values in cents, convert them to dollars.
   *
   * Example response from RPC call:
   * ```
   * {
   * 	"line_items": [
   * 		{ "transaction_type": "charge", "amount_in_cents": 0, "memo": "Yearly Pro (06\/15\/2017 - 06\/15\/2018)", "discount_amount_in_cents": 0, "taxable_amount_in_cents": 0 },
   * 		{ "transaction_type": "charge", "amount_in_cents": 119880, "memo": "Paid Yearly Active Users: 10 discount users", "discount_amount_in_cents": 200, "taxable_amount_in_cents": 119680, "component_id": 395141,
   * 			"taxations": [ { "tax_id": null, "tax_name": "UT Tax (6.35%)", "rate": "", "tax_amount_in_cents": 7600, "tax_rules": [] } ]
   * 		},
   * 		{ "transaction_type": "adjustment", "amount_in_cents": -200, "memo": "Coupon: ANNUAL-DISCOUNT - Discounted amount for annual subscription (per user, per month)", "discount_amount_in_cents": 0, "taxable_amount_in_cents": 0 },
   * 		{ "transaction_type": "charge", "amount_in_cents": 7600, "memo": "UT Tax (6.35%)", "discount_amount_in_cents": 0, "taxable_amount_in_cents": 0 }
   * 	],
   * 	next_subtotal_in_cents: 119880
   * 	next_total_in_cents: 127492
   * 	next_total_tax_in_cents: 7612
   * 	period_type: "recurring"
   * 	start_date: "2017-09-06T18:57:35Z"
   * 	subtotal_in_cents: 119880
   * 	total_discount_in_cents: 0
   * 	total_in_cents: 127492
   * 	total_tax_in_cents: 7612
   * }
   * ```
   *
   * result example:
   * ```
   * {
   * 	startDate: 1496785872,
   * 	endDate: 1496785872,
   * 	subtotal: "$419.58",
   * 	tax: "$2.66",
   * 	total: "$422.24",
   * 	balance: "-$100.00"
   * }
   * ```
   *
   * @public
   * @method reformatSubscriptionPreviewResults
   * @param subscriptionPreview {object}
   * @return {RSVP}
   */
		reformatSubscriptionPreviewResults: function reformatSubscriptionPreviewResults(subscriptionPreview, subscriptionType) {
			_logger.default.info(this, 'reformatSubscriptionPreviewResults', { subscriptionPreview: subscriptionPreview, subscriptionType: subscriptionType });

			var results = Ember.Object.create(subscriptionPreview);

			var subtotal = results.getWithDefault('next_subtotal_in_cents', 0);
			var tax = results.getWithDefault('next_total_tax_in_cents', 0);
			var total = results.getWithDefault('next_total_in_cents', 0);
			var balance = results.getWithDefault('existing_balance_in_cents', 0);
			var startDate = results.getWithDefault('start_date', 0);
			var endDate = results.getWithDefault('end_date', 0);

			subtotal = (0, _string.centsToDollarFormat)(subtotal);
			tax = (0, _string.centsToDollarFormat)(tax);
			total = (0, _string.centsToDollarFormat)(total);
			balance = (0, _string.centsToDollarFormat)(balance);
			startDate = (0, _moment.default)(startDate).unix();
			endDate = (0, _moment.default)(endDate).unix();

			// monthly payments are due the day after the endDate
			// annual payments are due one year from today
			var nextPaymentDate = subscriptionType === 'monthly' ? _utils.Time.date(endDate).add(1, 'days').unix() : _utils.Time.date().add(1, 'years').unix();

			return {
				subtotal: subtotal,
				tax: tax,
				total: total,
				balance: balance,
				startDate: startDate,
				endDate: endDate,
				nextPaymentDate: nextPaymentDate
			};
		},


		/**
   * Given an error object, likely thrown by the API, check for and use the `err.code` value get the associated string from the provided map of error messages.
   * If no matching string is found in the map, the original error object is returned instead. Order is preserved, but duplicates and non-matches are removed.
   * If the `error.code` array contains multiple values, the associated strings are returned concatenated together using `messageSeparator`.
   *
   * Example
   * ```
   * arguments
   * 	err = { ..., code: [111, 222], ... }
   * 	messagesMap = { 111: 'User friendly error message.',  222: 'Another error message.', ... }
   *
   * result
   * 	'User friendly error message. Another error message.'
   * ```
   *
   * @private
   * @method getFriendlyError
   * @param err {object}
   * @param messagesMap {object}
   * @param [messageSeparator=" "] {string} character to use when joining multiple error messages into one string
   * @return {RSVP}
   */
		getFriendlyError: function getFriendlyError(err) {
			var messagesMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
			var messageSeparator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : " ";

			var errorCodes = [];
			if (err instanceof Error) {
				errorCodes = err.errors.map(function (i) {
					return i.code;
				});
			} else if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object' && err.hasOwnProperty('code')) {
				errorCodes = Array.isArray(err.code) ? err.code : [].concat(_toConsumableArray(err.code)); // ensure errorCodes is an array
			}

			if (!Ember.isEmpty(errorCodes)) {
				var errorMessages = Ember.A(errorCodes).uniq().map(function (code) {
					return messagesMap[code];
				}).compact();

				return !Ember.isEmpty(errorMessages) ? errorMessages.join(messageSeparator) : err;
			}
			return err;
		},
		mockApiCall: function mockApiCall(args) {
			var result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
			var delay = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 250;

			return new Ember.RSVP.Promise(function (resolve) {
				return setTimeout(function () {
					return resolve(result);
				}, delay);
			}).then(function (result) {
				_logger.default.info('mockApiCall', delay, { args: args, result: result });

				return result;
			});
		}
	});
});