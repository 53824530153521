define('busy-app/components/projects/report-header', ['exports', '@busy-web/utils', 'busy-app/utils/docs', 'busy-app/utils/debug'], function (exports, _utils, _docs, _debug) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-projects-report-header', 'page-padding'],

		store: Ember.inject.service('store'),
		auth: Ember.inject.service('auth'),
		payPeriods: Ember.inject.service('pay-period'),

		start: null,
		end: null,
		reportType: null,

		isRangeSelector: false,
		isCustomDateRange: false,
		optionSelected: null,
		hasDateSelector: false,

		columns: null,
		options: null,

		columnLabel: '',
		columDefault: '',

		dateSelector: null,
		hideDateSelector: false,

		minDate: null,
		maxDate: null,

		hideExport: false,

		title: '',

		init: function init() {
			var _this2 = this;

			this._super();

			(0, _debug.disable_test_runner)(function () {
				var payPeriod = _this2.get('payPeriods').getPayPeriod(_utils.Time.date().startOf('day').unix());
				if (!Ember.isNone(payPeriod)) {
					_this2.set('maxDate', payPeriod.get('endDate').unix());
				}
			});

			// init observer functions
			this.dateSetter();

			(0, _debug.disable_test_runner)(function () {
				return _this2.setupSelectors();
			});
		},

		dateSetter: Ember.observer('start', 'end', function () {
			var start, end;
			if (!Ember.isNone(this.get('start')) && this.get('start') > 0 && !Ember.isNone(this.get('end')) && this.get('end') > 0) {
				start = _utils.Time.date(this.get('start'));
				end = _utils.Time.date(this.get('end'));
			} else {
				start = _utils.Time.date().startOf('day').subtract(1, 'weeks');
				end = _utils.Time.date().endOf('day');
			}
			this.set('startDate', start);
			this.set('endDate', end);
		}),

		setupSelectors: Ember.observer('start', function () {
			var payPeriods = this.get('payPeriods').getPayPeriodRange(_utils.Time.timestamp(), 15);
			if (!Ember.isNone(this.get('start')) && !Ember.isEmpty(payPeriods)) {
				var _this = this;
				payPeriods.forEach(function (item) {
					if (_this.get('start') >= item.get('startDate').unix() && _this.get('start') <= item.get('endDate').unix()) {
						item.set('_selected', true);
					}
				});
			} else {
				payPeriods.objectAt(0).set('_selected', true);
			}
			this.set('dateSelector', payPeriods);
		}),

		headerDateRange: Ember.computed('dateSelector.@each._selected', function () {
			var date = '';
			if (!Ember.isNone(this.get('dateSelector')) && !this.get('isRangeSelector')) {
				var selected = this.get('dateSelector').findBy('_selected', true);
				date = selected.get('startDate').format('MM/DD/YYYY') + ' - ' + selected.get('endDate').format('MM/DD/YYYY');
				return date;
			} else if (!Ember.isNone(this.get('start')) && !Ember.isNone(this.get('end'))) {
				date = _utils.Time.date(this.get('start')).format('MM/DD/YYYY') + '-' + _utils.Time.date(this.get('end')).format('MM/DD/YYYY');
			}
			return date;
		}),

		creatorName: Ember.computed('auth.member.fullName', function () {
			return this.get('auth.member.fullName');
		}),

		createdDate: Ember.computed(function () {
			return _utils.Time.date().format('MM/DD/YYYY h:mm A');
		}),

		actions: {
			dateChange: function dateChange(item) {
				if (this.get('isRangeSelector')) {
					this.sendAction('dateSelected', this.get('startDate').unix(), this.get('endDate').unix());
				} else {
					this.sendAction('dateSelected', item);
				}
			},

			optionChange: function optionChange(item) {
				this.sendAction('optionSelected', item.get('key'));
			},

			columnChange: function columnChange(val, item) {
				this.sendAction('columnSelected', val, item);
			},

			printPage: function printPage() {
				if (!this.get('inlinePrint')) {
					_docs.default.print(this.$());
				} else {
					_docs.default.print();
				}
			}
		}
	});
});