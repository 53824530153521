define('busy-app/routes/report/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils'], function (exports, _authenticatedRouteMixin, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Routes
  *
  */
	var aggregateSeconds = Ember.Object.extend({
		start: null,
		end: null,
		seconds: null,
		dateTime: Ember.computed('start', 'end', function () {
			if (!Ember.isNone(this.get('start')) && !Ember.isNone(this.get('end'))) {
				return _utils.Time.date(this.get('start')).format('MMM. DD') + ' - ' + _utils.Time.date(this.get('end')).format('MMM. DD');
			}
			return '';
		}),
		total: Ember.computed('seconds.@each.totalSeconds', function () {
			var time = 0;
			if (!Ember.isEmpty(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return time += item.get('totalSeconds');
				});
			}
			return time / 60 / 60;
		})
	});

	/**
  * `Route/Report/Index`
  *
  * @class Index
  * @namespace Routes.Report
  * @extends Route
  */
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		model: function model() {
			var _this = this;

			Ember.run.next(this, function () {
				_this.setModel();
			});

			return [];
		},
		setModel: function setModel() {
			var _this2 = this;

			var startTime = _utils.Time.date().subtract(121, 'days').unix();
			var endTime = _utils.Time.date().unix();
			var params = {
				_gte: {
					start_time: startTime
				},
				_lte: {
					start_time: endTime
				},
				_asc: ['start_time'],
				page_size: 999999999
			};

			return Ember.RSVP.hash({
				payPeriodMemberSeconds: this.store.findAll('pay-period-member-seconds', params)
			}).then(function (data) {
				_this2.totalPayPeriodSeconds(data);
				Ember.set(_this2, 'controller.model', data.secondsByPayPeriod);
				return data.secondsByPayPeriod;
			});
		},
		totalPayPeriodSeconds: function totalPayPeriodSeconds(data) {
			_utils.Assert.test('payPeriodMemberSeconds was not found in data', !Ember.isNone(data.payPeriodMemberSeconds));

			var dates = [];
			data.payPeriodMemberSeconds.forEach(function (item) {
				if (dates.indexOf(item.get('startTime')) === -1) {
					dates.push(item.get('startTime'));
				}
			});

			var periodSeconds = [];
			dates.forEach(function (start) {
				var seconds = data.payPeriodMemberSeconds.filterBy('startTime', start);
				var end = void 0;
				if (seconds.get('length') > 0) {
					end = seconds.objectAt(0).get('endTime');
				}
				periodSeconds.pushObject(aggregateSeconds.create({ seconds: seconds, start: start, end: end }));
			});

			data.secondsByPayPeriod = periodSeconds;
		}
	});
});