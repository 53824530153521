define('busy-app/components/projects/cost-codes-select', ['exports', 'busy-app/utils/logger'], function (exports, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c--cost-code-select', 'bc-validate'],
		classNameBindings: ['showUnableToClockInWarning:invalid'],

		// services
		store: Ember.inject.service(),
		state: Ember.inject.service(),
		auth: Ember.inject.service(),
		features: Ember.inject.service(),

		projectCostCodeLinks: null,
		isSubproject: false,
		projectId: null,
		cancelText: null,
		saveText: null,
		onCancelCostCodeChanges: null,
		onSaveManagerChanges: null,

		showCostCodesManager: false,

		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),

		activeProjectCostCodeLinks: Ember.computed.filterBy('projectCostCodeLinks', 'deletedOn', null),
		showLinkedCostCodesCount: Ember.computed.and('isLinkedCostCodesEnabled', 'activeProjectCostCodeLinks.length'),

		showCostCodesAreRecommended: Ember.computed('auth.organization.costCodes.{isFulfilled,length}', function () {
			return !Ember.isNone('auth.organization.costCodes') && this.get('auth.organization.costCodes.isFulfilled') && this.get('auth.organization.costCodes.length') === 0;
		}),

		showUnableToClockInWarning: Ember.computed('auth.organization.requireCostCode', 'activeProjectCostCodeLinks.length', function () {
			return this.get('auth.organization.requireCostCode') && this.get('activeProjectCostCodeLinks.length') === 0;
		}),

		actions: {
			onOpenCostCodesManager: function onOpenCostCodesManager() {
				_logger.default.info(this, 'openCostCodesManager');

				return Ember.setProperties(this, { showCostCodesManager: true });
			},
			onCloseCostCodesManager: function onCloseCostCodesManager() {
				_logger.default.info(this, 'onCloseCostCodesManager');

				return Ember.setProperties(this, { showCostCodesManager: false });
			},
			onCancelCostCodeChanges: function onCancelCostCodeChanges() {
				var _this = this;

				_logger.default.info(this, 'onCancelCostCodeChanges');

				return Ember.get(this, 'onCancelCostCodeChanges')().then(function () {
					return _this.send('onCloseCostCodesManager');
				});
			},
			onSaveManagerChanges: function onSaveManagerChanges(projectCostCodeLinks) {
				_logger.default.info(this, 'onSaveManagerChanges', { projectCostCodeLinks: projectCostCodeLinks });

				return Ember.get(this, 'onSaveManagerChanges')(projectCostCodeLinks);
			}
		}
	});
});