define('busy-app/utils/debug', ['exports', 'busy-app/utils/settings'], function (exports, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.component_safe_api = exports.disable_test_runner = exports.log_level = exports.debug_mode = undefined;
	/**
  * @module Utils
  *
  */
	var debug_mode = exports.debug_mode = function debug_mode(cb, defaultValue) {
		return _settings.default.get('debug_mode') ? cb() : defaultValue;
	};

	var log_level = exports.log_level = function log_level(level, cb, defaultValue) {
		return _settings.default.get('log_level') >= level ? cb() : defaultValue;
	};

	var disable_test_runner = exports.disable_test_runner = function disable_test_runner() {
		var cb = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {
			return false;
		};
		return !window.runningTests ? cb() : true;
	};

	var component_safe_api = exports.component_safe_api = function component_safe_api() {
		var cb = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : function () {};
		return !window.runningTests ? Ember.RSVP.resolve(cb()) : Ember.RSVP.resolve();
	};

	debug_mode(function () {
		var logSymbol = '•'; // bullet symbol do not erase

		var level = _settings.default.get('log_level');
		var color = function color() {
			switch (level) {
				case 3:
					return 'crimson';
				case 2:
					return 'gold';
				default:
					return 'limegreen';
			}
		};

		window.console.log("%c%s %c%s %c%s %c%d", 'color:' + color() + ';font-size:15px;', logSymbol, "color:darkgoldenrod", "<utils:settings>", "color:grey", 'LOG_LEVEL', "color:indianred", level);

		window.console.log("%c%s %c%s %c%s %c%s", 'color:' + color() + ';font-size:15px;', logSymbol, "color:darkgoldenrod", "<utils:settings>", "color:grey", 'DEBUG_MODE', "color:indianred", _settings.default.get('debug_mode'));
	});
});