define("busy-app/utils/features", ["exports", "busy-app/config/environment"], function (exports, _environment) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var featureFlags = _environment.default.featureFlags;

	var kProFeatures = ["activity-report", "conflict-resolution", "custom-roles", "entry-history", "export", "labor-budgets", "member-gps-setting", "notes", "payroll-report", "photos", "photos-react", "procore", "quickbooks", "safety-sign-off", "scheduling", "daily-reports", "daily-reports-prompts", "busy-sign-in"];

	var kFreeFeatures = ["cost-codes", "equipment", "gps-tracking", "projects", "signatures", "location-based-reminders", "phone-number-in-onboarding", "report-labor-burden", "export-macro-csv", "react-main-app", "server-outage", "deprecate-legacy-reports"];

	var Features = Ember.Object.extend();

	Features.reopenClass({
		get: function get(key) {
			return Ember.get(this.getList(), key);
		},
		getList: function getList() {
			return featureFlags;
		},
		isProFeature: function isProFeature(key) {
			return kProFeatures.indexOf(key) !== -1;
		},
		isFreeFeature: function isFreeFeature(key) {
			return kFreeFeatures.indexOf(key) !== -1;
		},
		getFlags: function getFlags(state) {
			var list = {};
			// set pro features
			kProFeatures.forEach(function (key) {
				Ember.set(list, key, state === "pro");
			});

			// set free features
			kFreeFeatures.forEach(function (key) {
				Ember.set(list, key, true);
			});
			return list;
		}
	});

	exports.default = Features;
});