define('busy-app/components/busy-status-bar', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Component.extend({
		classNames: ['busy-status-bar'],
		classNameBindings: ['error'],

		error: false,
		icon: null,
		text: null,
		subText: null,
		badgeText: null,
		detailsLabel: null,
		detailsText: null,

		textAction: null,
		subTextAction: null,
		badgeAction: null,
		detailsLabelAction: null,
		detailsTextAction: null,

		hasTextAction: Ember.computed('textAction', function () {
			return this.isAction('textAction');
		}),
		hasSubTextAction: Ember.computed('subTextAction', function () {
			return this.isAction('subTextAction');
		}),
		hasBadgeAction: Ember.computed('badgeAction', function () {
			return this.isAction('badgeAction');
		}),
		hasDetailsLabelAction: Ember.computed('detailsLabelAction', function () {
			return this.isAction('detailsLabelAction');
		}),
		hasDetailsTextAction: Ember.computed('detailsTextAction', function () {
			return this.isAction('detailsTextAction');
		}),

		isAction: function isAction(key) {
			return !Ember.isNone(this.get(key));
		},


		actions: {
			onTextAction: function onTextAction() {
				if (this.isAction('textAction')) {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					this.sendAction.apply(this, ['textAction'].concat(_toConsumableArray(args)));
				} else {
					throw 'textAction is not a valid action';
				}
			},
			onSubTextAction: function onSubTextAction() {
				if (this.isAction('subTextAction')) {
					for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
						args[_key2] = arguments[_key2];
					}

					this.sendAction.apply(this, ['subTextAction'].concat(_toConsumableArray(args)));
				} else {
					throw 'subTextAction is not a valid action';
				}
			},
			onBadgeAction: function onBadgeAction() {
				if (this.isAction('badgeAction')) {
					for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
						args[_key3] = arguments[_key3];
					}

					this.sendAction.apply(this, ['badgeAction'].concat(_toConsumableArray(args)));
				} else {
					throw 'badgeAction is not a valid action';
				}
			},
			onDetailsLabelAction: function onDetailsLabelAction() {
				if (this.isAction('detailsLabelAction')) {
					for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
						args[_key4] = arguments[_key4];
					}

					this.sendAction.apply(this, ['detailsLabelAction'].concat(_toConsumableArray(args)));
				} else {
					throw 'detailsLabelAction is not a valid action';
				}
			},
			onDetailsTextAction: function onDetailsTextAction() {
				if (this.isAction('detailsTextAction')) {
					for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
						args[_key5] = arguments[_key5];
					}

					this.sendAction.apply(this, ['detailsTextAction'].concat(_toConsumableArray(args)));
				} else {
					throw 'detailsTextAction is not a valid action';
				}
			}
		}
	});
});