define('busy-app/controllers/time-card/full-report/index', ['exports', '@busy-web/utils', 'busy-app/utils/docs'], function (exports, _utils, _docs) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['start', 'end', 'member_id', 'mobile', 'sort'],

		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),
		signature: Ember.inject.service('signature'),
		payPeriods: Ember.inject.service('pay-period'),

		start: null,
		end: null,
		member_id: null,
		mobile: false,
		sort: 'desc',

		showTimeEntryEdit: false,
		editEntry: null,
		showTimeOffEdit: false,
		editTimeOffEntry: null,
		payPeriod: Ember.computed('start', function () {
			return this.get('payPeriods').getPayPeriod(this.get('start'));
		}),

		dirtyFlag: false,

		init: function init() {
			this._super();
			this.isPrintable();
		},


		isPrintable: Ember.observer('mobile', function () {
			_docs.default.setPrintable(this.get('mobile'));
		}),

		headerDateRange: Ember.computed('start', 'end', function () {
			var start = parseInt(this.get('start'), 10);
			var startFormatted = _utils.Time.timeFormat(start, 'MM/DD/YYYY');

			var end = parseInt(this.get('end'), 10);
			var endFormatted = _utils.Time.timeFormat(end, 'MM/DD/YYYY');

			return startFormatted + ' - ' + endFormatted;
		}),

		dateRangeString: Ember.computed('start', 'end', function () {
			var start = parseInt(this.get('start'), 10);
			var startFormatted = _utils.Time.timeFormat(start, 'MMM D');

			var end = parseInt(this.get('end'), 10);
			var endFormatted = _utils.Time.timeFormat(end, 'MMM D, YYYY');

			return startFormatted + ' - ' + endFormatted;
		}),

		createdDate: Ember.computed(function () {
			return _utils.Time.dateFormat(_utils.Time.date(), 'MM/DD/YYYY h:mm A');
		}),

		member: Ember.computed.alias('model.model'),

		isTimecardMine: Ember.computed('auth.memberId', 'member', function () {
			return this.get('auth.memberId') === this.get('member.id');
		}),

		isSupervisor: Ember.computed('member', function () {
			var supervisorAction = 'timeEvents';
			var member = this.get('member');

			if (Ember.isNone(member) || Ember.isNone(member.get('id'))) {
				return false;
			}

			return this.get('access').canTakeActionFor(supervisorAction, member);
		}),

		isSignable: Ember.computed.and('signature.isTimecardSigningEnabled', 'model.currentPeriod.isSignable'),

		actions: {
			openTimeEntryAction: function openTimeEntryAction(entry) {
				if (entry.get('isTimeEntry')) {
					this.set('editEntry', entry.get('model'));
					this.set('showTimeEntryEdit', true);
				} else {
					this.set('editTimeOffEntry', entry.get('model'));
					this.set('showTimeOffEdit', true);
				}
			},
			closeEditDialog: function closeEditDialog() {
				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);
			},
			itemSaved: function itemSaved() {
				var _this = this;

				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);

				Ember.run.next(this, function () {
					_this.send('syncRoute');
				});
			},
			itemDeleted: function itemDeleted() {
				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);

				this.send('syncRoute');
			},
			printReport: function printReport() {
				_docs.default.print();
			},
			sortEntries: function sortEntries() {
				var sort = this.get('sort') === 'desc' ? 'asc' : 'desc';
				this.set('sort', sort);

				this.transitionToRoute('time-card.full-report', {
					queryParams: { sort: sort }
				});

				this.send('refreshRoute');
			},
			syncRoute: function syncRoute() {
				this.set('dirtyFlag', true);
			},
			refreshRoute: function refreshRoute() {
				var _this2 = this;

				Ember.run.next(this, function () {
					Ember.getOwner(_this2).lookup('route:time-card/full-report').refresh();
				});
			}
		}
	});
});