define('busy-app/components/dialogs/error/gps-location-required', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c--dialog--gps-location-required'],

		onClose: null,

		actions: {
			onClose: function onClose() {
				this.sendAction('onClose');
			}
		}

	});
});