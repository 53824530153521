define('busy-app/utils/models/managers/time-card-conflicts', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/base', 'busy-app/utils/time-entry-validator', 'busy-app/utils/logger'], function (exports, _utils, _base, _timeEntryValidator, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		auth: Ember.inject.service(),

		start: null,
		end: null,
		projectId: null,

		getSorted: function getSorted() {
			return this.get('model').sortBy('start').reverse();
		},


		/**
   * fetches the data for a time-entry by range.
   *
   * @public
   * @method fetch
   * @param start {number} Required timestamp for the start of the range.
   * @param end {number} Required timestamp for the end of the range.
   * @param memberId {string} Optional id to filter by a member.
   * @param projectId {string} Optional param to filter by a top level project and its children.
   * @param costCodeId {string} Optional param to filter by a cost code.
   * @param exclusive {boolean} Default false, true will force the filter by projectId to disinclude children.
   * @return {promise}
   */
		fetch: function fetch(memberId, start, end, projectId, groupId) {
			var _this = this;

			var useGroupPermissionScoping = Ember.get(this, 'auth.useGroupPermissionScoping');

			this.set('start', start);
			this.set('end', end);
			this.set('projectId', projectId);

			var loadTimeEntries = void 0;
			var params = {};

			if (!Ember.isNone(start)) {
				Ember.setProperties(params, { range_start: start });
			}

			if (!Ember.isNone(end)) {
				Ember.setProperties(params, { range_end: end });
			}

			if (!Ember.isNone(memberId)) {
				Ember.setProperties(params, { member_id: memberId });
				loadTimeEntries = function loadTimeEntries() {
					return _this.store.query('time-entry-member-conflicts', params);
				};
			} else {
				loadTimeEntries = function loadTimeEntries() {
					return _this.store.query('time-entry-organization-conflicts', params);
				};
			}

			return Ember.RSVP.hash({
				timeEntries: loadTimeEntries(),
				groupMembers: this.loadScopedGroupMembers(useGroupPermissionScoping, groupId)
			}).then(function (_ref) {
				var timeEntries = _ref.timeEntries,
				    groupMembers = _ref.groupMembers;


				// if the current user is restricted to group scoped permissions,
				// 	filter out any members they shouldn't be able to access
				if (useGroupPermissionScoping || !Ember.isNone(groupId)) {
					var groupMemberIds = new Set(groupMembers.mapBy('id'));
					timeEntries = timeEntries.filter(function (entry) {
						return groupMemberIds.has(Ember.get(entry, 'memberId'));
					});
				}

				// const entries = this.removeModels(timeEntries);
				var ids = [];
				var projectIds = [];
				var costCodeIds = [];
				var equipmentIds = [];
				var logIds = [];
				var locationIds = [];
				timeEntries.forEach(function (item) {
					if (ids.indexOf(item.get('memberId')) === -1) {
						ids.push(item.get('memberId'));
					}

					if (!Ember.isNone(item.get('startLocationId')) && locationIds.indexOf(item.get('startLocationId')) === -1) {
						locationIds.push(item.get('startLocationId'));
					}

					if (!Ember.isNone(item.get('endLocationId')) && locationIds.indexOf(item.get('endLocationId')) === -1) {
						locationIds.push(item.get('endLocationId'));
					}

					if (!Ember.isNone(item.get('timeEntryBreaks'))) {
						item.get('timeEntryBreaks').forEach(function (breakItem) {
							if (!Ember.isNone(breakItem.get('startLocationId')) && locationIds.indexOf(breakItem.get('startLocationId')) === -1) {
								locationIds.push(breakItem.get('startLocationId'));
							}

							if (!Ember.isNone(breakItem.get('endLocationId')) && locationIds.indexOf(breakItem.get('endLocationId')) === -1) {
								locationIds.push(breakItem.get('endLocationId'));
							}
						});
					}

					if (!Ember.isNone(item.get('projectId')) && projectIds.indexOf(item.get('projectId')) === -1) {
						projectIds.push(item.get('projectId'));
					}

					if (!Ember.isNone(item.get('costCodeId')) && costCodeIds.indexOf(item.get('costCodeId')) === -1) {
						costCodeIds.push(item.get('costCodeId'));
					}

					if (!Ember.isNone(item.get('equipmentId')) && equipmentIds.indexOf(item.get('equipmentId')) === -1) {
						equipmentIds.push(item.get('equipmentId'));
					}

					if (logIds.indexOf(item.get('id')) === -1) {
						logIds.push(item.get('id'));
					}
				});

				return Ember.RSVP.hash({
					members: _this.store.findByIds('member', ids),
					locations: _this.store.findByIds('member-location', locationIds),
					projects: _this.store.filter('project').findFullProjects(projectIds),
					costCodes: _this.store.findByIds('cost-code', costCodeIds),
					equipment: _this.store.findByIds('equipment', equipmentIds),
					logs: _this.store.findWhereIn('client-time-entry-log', 'time_entry_id', logIds, { deleted_on: '_-NULL-_' }),
					entries: timeEntries
				}).then(function (extData) {
					var creatorIds = [];
					var creatorMap = {};
					extData.logs.sortBy('createdOn').forEach(function (log) {
						if (!Ember.isNone(log.get('timeEntryId')) && creatorMap[log.get('timeEntryId')] === undefined) {
							var _start = void 0,
							    _end = void 0,
							    startMember = void 0,
							    endMember = void 0;
							var startChange = false;
							var endChange = false;
							var map = {};
							var entryLogs = extData.logs.filterBy('timeEntryId', log.get('timeEntryId'));

							entryLogs.sortBy('createdOn').reverse().forEach(function (item) {
								if (!Ember.isNone(item.get('updaterMemberId'))) {
									var startTime = _utils.Time.date(item.get('startTime')).startOf('minute').unix();
									var endTime = !Ember.isNone(item.get('endTime')) ? _utils.Time.date(item.get('endTime')).startOf('minute').unix() : null;

									if (_start === undefined) {
										_start = startTime;
										startMember = item.get('updaterMemberId');
										map.startTime = item.get('updaterMemberId');
									} else if (!startChange && _start !== startTime) {
										_start = startTime;
										map.startTime = startMember;
										startChange = true;
									} else {
										startMember = item.get('updaterMemberId');
									}

									if (!Ember.isNone(endTime)) {
										if (_end === undefined) {
											_end = endTime;
											endMember = item.get('updaterMemberId');
											map.endTime = item.get('updaterMemberId');
										} else if (!endChange && _end !== item.get('endTime')) {
											_end = endTime;
											map.endTime = endMember;
											endChange = true;
										} else {
											endMember = item.get('updaterMemberId');
										}
									}
								}
							});

							if (!Ember.isNone(map.startTime)) {
								if (!startChange) {
									map.startTime = startMember;
								}

								if (creatorIds.indexOf(map.startTime) === -1) {
									creatorIds.push(map.startTime);
								}
							}

							if (!Ember.isNone(map.endTime)) {
								if (!endChange) {
									map.endTime = endMember;
								}

								if (creatorIds.indexOf(map.endTime) === -1) {
									creatorIds.push(map.endTime);
								}
							}

							if (!Ember.isNone(map.startTime) || !Ember.isNone(map.endTime)) {
								creatorMap[log.get('timeEntryId')] = map;
							}
						}
					});

					return Ember.RSVP.hash({
						members: extData.members,
						locations: extData.locations,
						projects: extData.projects,
						costCodes: extData.costCodes,
						equipment: extData.equipment,
						entries: extData.entries,
						creatorMap: creatorMap,
						creators: _this.store.findByIds('member', creatorIds)
					});
				});
			});
		},
		loadScopedGroupMembers: function loadScopedGroupMembers(useGroupPermissionScoping, groupId) {
			if (!useGroupPermissionScoping && Ember.isNone(groupId)) {
				return Ember.RSVP.resolve(null);
			}

			var query = {
				deleted_on: null,
				archived_on: null,
				member_group_id: groupId
			};

			if (useGroupPermissionScoping) {
				var authMember = Ember.get(this, 'auth.member');

				var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
				    id = _EmberGetProperties.id,
				    memberGroupId = _EmberGetProperties.memberGroupId;

				Ember.setProperties(query, { member_group_id: memberGroupId });

				if (Ember.isNone(memberGroupId)) {
					Ember.setProperties(query, { id: id });
				}
			}

			return this.store.findAll('member', query);
		},
		removeModels: function removeModels(data) {
			var start = this.get('start');
			var end = this.get('end');
			var projectId = this.get('projectId');
			var tooOldToEdit = _utils.Time.date().subtract(89, 'days').endOf('day').unix();
			var ids = [];
			var uniq = [];

			data.forEach(function (model) {
				if (ids.indexOf(model.id) === -1 && Ember.isNone(model.get('deletedOn')) && model.get('startTime') > tooOldToEdit) {
					if (Ember.isNone(start) && Ember.isNone(end) && Ember.isNone(projectId)) {
						ids.push(model.id);
						uniq.pushObject(model);
					} else if (Ember.isNone(projectId) && model.get('endTime') > start && model.get('startTime') < end) {
						ids.pushObject(model.id);
						uniq.pushObject(model);
					} else if (Ember.isNone(start) && Ember.isNone(end) && model.get('projectId') === projectId) {
						ids.push(model.id);
						uniq.pushObject(model);
					} else if (model.get('projectId') === projectId && model.get('endTime') > start && model.get('startTime') < end) {
						ids.push(model.id);
						uniq.pushObject(model);
					}
				}
			});
			return uniq;
		},
		setEntryData: function setEntryData(entry, data) {
			entry.get('member');
			entry.get('project');
			entry.get('costCode');
			entry.get('equipment');

			if (!Ember.isNone(entry.get('startLocationId'))) {
				var startLocation = data.locations.findBy('id', entry.get('startLocationId'));
				entry.set('startLocation', startLocation);
			}

			if (!Ember.isNone(entry.get('endLocationId'))) {
				var endLocation = data.locations.findBy('id', entry.get('endLocationId'));
				entry.set('endLocation', endLocation);
			}

			if (!Ember.isNone(entry.get('timeEntryBreaks'))) {
				entry.get('timeEntryBreaks').forEach(function (breakItem) {
					if (!Ember.isNone(breakItem.get('startLocationId'))) {
						var breakStartLocation = data.locations.findBy('id', breakItem.get('startLocationId'));
						breakItem.set('startLocation', breakStartLocation);
					}

					if (!Ember.isNone(breakItem.get('endLocationId'))) {
						var breakEndLocation = data.locations.findBy('id', breakItem.get('endLocationId'));
						breakItem.set('endLocation', breakEndLocation);
					}
				});
			}

			var creatorObj = data.creatorMap[entry.get('id')];
			if (!Ember.isNone(creatorObj)) {
				entry.set('startMember', data.creators.findBy('id', creatorObj.startTime));
				entry.set('endMember', data.creators.findBy('id', creatorObj.endTime));
			}
		},
		buildCollections: function buildCollections(data) {
			var _this2 = this;

			var index = 0;
			var entries = data.entries.sortBy('createdOn');
			entries.forEach(function (entry) {
				_this2.setEntryData(entry, data);
				index++;
				_this2.checkConflict(entry, entries, index);
			});
		},
		checkConflict: function checkConflict(model, entries, currentIndex) {
			for (var i = currentIndex; i < entries.get('length'); i++) {
				var conflictModel = entries.objectAt(i);
				if (model.id !== conflictModel.id && model.get('memberId') === conflictModel.get('memberId')) {
					var hasConflict = _timeEntryValidator.default.hasConflict(model, conflictModel);
					if (!hasConflict) {
						// this was double checking the data to see if the returned conflicts actually have conflicts
						// it shouldn't need to, and it was failing, so the check was changed to a warning
						// TODO: debug TimeEntryValidator.hasConflict()
						_logger.default.warn(this, 'Client cannot detect conflict reported by API', { model: model, conflictModel: conflictModel });
					}
					this.createTimeCardConflict(model, conflictModel);
				}
			}
		},
		createTimeCardConflict: function createTimeCardConflict(model, conflictModel) {
			this.addCollection(model, conflictModel);
		}
	});
});