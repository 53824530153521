define('busy-app/components/company/edit-card-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Component.extend({
		classNames: ['v-company-settings-dialog-edit-card'],

		store: Ember.inject.service('store'),

		card: null,
		stripeId: null,
		shouldUpdate: false,

		yearMenu: null,
		monthMenu: null,

		expYear: null,
		expMonth: null,
		nameOnCard: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.setupInfo();
		},


		setupInfo: Ember.observer('card', function () {
			if (!Ember.isNone(this.get('card'))) {
				this.setMonthMenu(this.get('card.expMonth'));
				this.setYearMenu(this.get('card.expYear'));
			}
		}),

		setMonthMenu: function setMonthMenu(activeMonth) {
			this.set('expMonth', activeMonth);

			var months = [];
			for (var i = 1; i <= 12; i++) {
				var isActive = false;
				var value = i < 10 ? '0' + i : '' + i;

				if (!Ember.isNone(activeMonth) && activeMonth === i) {
					isActive = true;
				}

				months.pushObject(Ember.Object.create({ key: i, value: value, _selected: isActive }));
			}

			this.set('monthMenu', months);
		},

		setYearMenu: function setYearMenu(activeYear) {
			this.set('expYear', activeYear);

			var years = [];
			for (var i = 0; i <= 10; i++) {
				var isActive = false;
				var year = _utils.Time.date().add(i, 'years').year();

				if (!Ember.isNone(activeYear) && activeYear === year) {
					isActive = true;
				}

				years.pushObject(Ember.Object.create({ key: year, value: year, _selected: isActive }));
			}

			this.set('yearMenu', years);
		},

		updateCard: function updateCard() {
			var card = {
				customer_stripe_id: this.get('stripeId'),
				card_id: this.get('card.id'),
				expiration_month: this.get('expMonth'),
				expiration_year: this.get('expYear'),
				name: this.get('card.name')
			};

			if (Ember.isNone(card.name) || Ember.isEmpty(card.name)) {
				return Ember.RSVP.reject((0, _utils.loc)("Name is required to update a card."));
			} else if (Ember.isNone(card.expiration_month) || Ember.isNone(card.expiration_year)) {
				return Ember.RSVP.reject((0, _utils.loc)("Exp. Year and Exp. Month are required to update a card."));
			} else {
				return this.get('store').rpcRequest('busy-stripe', 'update-stripe-card', card);
			}
		},

		deleteCard: function deleteCard() {
			var card = {
				customer_stripe_id: this.get('stripeId'),
				card_id: this.get('card.id')
			};

			if (this.get('card.isCardActive')) {
				return Ember.RSVP.reject((0, _utils.loc)("You cannot delete the default card"));
			} else {
				return this.get('store').rpcRequest('busy-stripe', 'delete-stripe-card', card);
			}
		},

		actions: {
			close: function close() {
				this.sendAction('onClose');
			},

			selectYear: function selectYear(item) {
				this.set('expYear', item.key);
			},

			selectMonth: function selectMonth(item) {
				this.set('expMonth', item.key);
			},

			saveCard: function saveCard(dialog) {
				dialog.showSavingMessage();

				var _this = this;
				this.updateCard().then(function () {
					_this.set('shouldUpdate', true);
					dialog.showSavedMessage();
				}, function (err) {
					if (typeof err === 'string') {
						dialog.showErrorMessage(err);
					} else if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object' && Ember.isArray(err.code)) {
						if (err.code[0] === 204) {
							dialog.showSavingMessage((0, _utils.loc)("The default card cannot be deleted"));
						} else {
							dialog.showSavingMessage((0, _utils.loc)("Something went wrong, please try again later."));
						}
					} else {
						dialog.showSavingMessage((0, _utils.loc)("Something went wrong, please try again later."));
					}
				});
			},

			deleteAction: function deleteAction(dialog) {
				dialog.showSavingMessage();

				var _this = this;
				this.deleteCard().then(function () {
					_this.set('shouldUpdate', true);
					dialog.showSavedMessage();
				}, function (err) {
					if (typeof err === 'string') {
						dialog.showErrorMessage(err);
					} else if ((typeof err === 'undefined' ? 'undefined' : _typeof(err)) === 'object' && Ember.isArray(err.code)) {
						if (err.code[0] === 204) {
							dialog.showSavingMessage((0, _utils.loc)("The default card cannot be deleted"));
						} else {
							dialog.showSavingMessage((0, _utils.loc)("Something went wrong, please try again later."));
						}
					} else {
						dialog.showSavingMessage((0, _utils.loc)("Something went wrong, please try again later."));
					}
				});
			}
		}
	});
});