define('busy-app/components/lib/drag-drop-box', ['exports', 'busy-app/mixins/droppable', 'busy-app/mixins/draggable'], function (exports, _droppable, _draggable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_droppable.default, _draggable.default, {
		classNames: ['c-lib-drag-drop-box'],

		model: null,

		dragStartEvent: function dragStartEvent() {
			this.sendAction('onDrag', this.get('model'));
		},
		dragEndEvent: function dragEndEvent() {
			this.sendAction('onDragEnd', this.get('model'));
		},
		dragEnterEvent: function dragEnterEvent() {
			this.sendAction('onDragOver', this.get('model'));
		},
		dropEvent: function dropEvent() {
			this.sendAction('onDrop', this.get('model'));
		},
		click: function click() {
			this.sendAction('onClick', this.get('model'));
		}
	});
});