define('busy-app/controllers/report/time-card-approval', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	// import Logger from 'busy-app/utils/logger';

	/***/
	/**
  * @module Controllers
  *
  */
	var kFiltersConfigBase = [{
		label: (0, _utils.loc)('Signed'),
		key: 'signed',
		color: '#6ac192'
	}, {
		label: (0, _utils.loc)('Re-sign Required'),
		key: 'resign',
		color: '#79b3b4'
	}, {
		label: (0, _utils.loc)('Not Signed'),
		key: 'unsigned',
		color: '#ebb977'
	}];

	var kFiltersConfigSupervisor = [{
		label: (0, _utils.loc)('Signed'),
		key: 'signed',
		color: '#6ac192'
	}, {
		label: (0, _utils.loc)('Missing supervisor signature'),
		key: 'supervisor',
		color: '#79b3b4'
	}, {
		label: (0, _utils.loc)('Missing employee signature'),
		key: 'employee',
		color: '#ebb977'
	}, {
		label: (0, _utils.loc)('Re-sign Required'),
		key: 'resign',
		color: '#7899C1'
	}, {
		label: (0, _utils.loc)('Not Signed'),
		key: 'unsigned',
		color: '#e17778'
	}];

	var kFiltersConfig = {
		base: kFiltersConfigBase,
		supervisor: kFiltersConfigSupervisor
	};

	/**
  * `Controller/Report/TimeCardApproval`
  *
  * @class TimeCardApproval
  * @namespace Controllers.Report
  * @extends Controller
  */
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),
		features: Ember.inject.service('features'),
		signature: Ember.inject.service('signature'),
		payPeriod: Ember.inject.service('payPeriod'),

		// attributes set by pay period selector
		start: null,
		end: null,

		// private
		member: Ember.computed.alias('auth.member.content'),
		organization: Ember.computed.alias('auth.organization.content'),

		currentPayPeriod: null,
		previousPayPeriod: null,

		isLoadingMembers: false,
		isLoadingPayPeriods: false,
		isLoadingMemberLocks: false,
		isLoadingMemberTimeDocuments: false,

		// signture state filters
		selectedFilter: null,
		selectedSort: null,
		selectedSortReverse: false,
		nameSearchFilter: null,
		nameSearchFilterInput: null,

		isLoading: Ember.computed.or('isLoadingPrimary', 'isLoadingSecondary'),
		isLoadingPrimary: Ember.computed.or('isLoadingMembers', 'isLoadingPayPeriods'),
		isLoadingSecondary: Ember.computed.or('isLoadingMemberLocks', 'isLoadingMemberTimeDocuments'),

		// onModelChange: observer('model', function() {
		// 	console.log('onModelChange', this.get('model.length'));
		// }),


		isSelectedPayPeriodTooNew: Ember.computed('end', function () {
			return this.get('end') && this.get('end') > _utils.Time.date().unix();
		}),

		isSelectedPayPeriodTooOld: Ember.computed('start', 'organization.signatureDate', function () {
			return this.get('start') && this.get('start') < this.get('organization.signatureDate');
		}),

		signatureDateFormatted: Ember.computed('organization.signatureDate', function () {
			return _utils.Time.timeFormat(this.get('organization.signatureDate'), 'MMMM D, YYYY');
		}),

		isSelectedPayPeriodOutOfRange: Ember.computed.or('isSelectedPayPeriodTooNew', 'isSelectedPayPeriodTooOld'),
		isSelectedPayPeriodSignable: Ember.computed.not('isSelectedPayPeriodOutOfRange'),

		memberPayPeriods: Ember.computed.mapBy('model', 'payPeriod'),
		signableMemberPayPeriods: Ember.computed('memberPayPeriods', function () {
			return this.get('memberPayPeriods').compact().filterBy('isSignable', true);
		}),

		memberTimeDocumentsRaw: Ember.computed.mapBy('model', 'memberTimeDocument'),
		memberTimeDocuments: Ember.computed.filter('memberTimeDocumentsRaw', function (doc) {
			return !Ember.isNone(doc);
		}),

		filtersConfig: kFiltersConfig,
		modeFilterConfig: Ember.computed('filtersConfig', 'signature.signatureMode', function () {
			return this.get('filtersConfig')[this.get('signature.signatureMode')];
		}),

		filterMenuItems: Ember.computed('modeFilterConfig', function () {
			var menuItems = this.get('modeFilterConfig').map(function (o) {
				return Ember.Object.create({ key: o.key, value: o.label });
			});
			menuItems.push(Ember.Object.create({ key: 'all', value: (0, _utils.loc)('All') }));

			return menuItems;
		}),

		filterMenu: Ember.computed('selectedFilter', 'filterMenuItems', function () {
			var filter = this.get('selectedFilter');

			return this.get('filterMenuItems').map(function (item) {
				item.set('_selected', item.get('key') === filter);

				return item;
			});
		}),

		filteredModel: Ember.computed('model', 'selectedFilter', 'nameSearchFilter', 'isLoading', function () {
			this.get('isLoading');

			var statusFilter = this.get('selectedFilter');
			var filteredModels = this.get('model');

			filteredModels = this.filterByPermissions(filteredModels);

			if (!Ember.isNone(statusFilter) && statusFilter !== 'All') {
				filteredModels = this.filterByStatus(filteredModels, statusFilter);
			}

			if (!Ember.isNone(this.get('nameSearchFilter'))) {
				var nameSearchFilter = this.get('nameSearchFilter').toLowerCase();

				filteredModels = filteredModels.filter(function (member) {
					return member.get('fullName').toLowerCase().indexOf(nameSearchFilter) !== -1;
				});
			}

			return filteredModels;
		}),

		sortedFilteredModel: Ember.computed('filteredModel', 'selectedSort', 'selectedSortReverse', 'isLoading', function () {
			this.get('isLoading');

			var sort = this.get('selectedSort');
			var filteredModel = this.get('filteredModel');

			if (Ember.isNone(filteredModel)) {
				return [];
			}

			var filteredModelArray = this.get('filteredModel').toArray();
			var sortedModel = this.getSortedModel(filteredModelArray, sort);

			return this.get('selectedSortReverse') ? sortedModel.reverse() : sortedModel;
		}),

		// debounce name searches to wait until typing stops
		onNameSearchInput: Ember.observer('nameSearchFilterInput', function () {
			var _this = this;

			Ember.run.debounce(function () {
				if (!_this.get('isDestroyed')) {
					_this.set('nameSearchFilter', _this.get('nameSearchFilterInput'));
				}
			}, 500);
		}),

		/**
   * @private
   * @method init
   */
		init: function init() {
			this._super();

			this.initFilters();
			this.setInitialPayPeriod();
		},
		initFilters: function initFilters() {
			if (!this.get('isDestroyed')) {
				this.set('selectedFilter', 'All');
				this.set('selectedSort', 'fullName');
				this.set('selectedSortReverse', false);
				this.set('nameSearchFilter', null);
			}
		},
		refresh: function refresh() {
			var route = Ember.getOwner(this).lookup('route:report.time-card-approval');

			if (!Ember.isNone(route)) {
				this.initFilters();
				route.refresh();
			}
		},


		/**
   * Set the initial start and end times for the selected pay period
   *
   * @private
   * @method setInitialPayPeriod
   * @return {Model.MemberTimeDocuments[]}
   */
		setInitialPayPeriod: function setInitialPayPeriod() {
			var now = _utils.Time.date().startOf('day').unix();

			var currentPayPeriod = this.get('payPeriod').getPayPeriod(now);
			var previousPayPeriod = this.get('payPeriod').getPayPeriod(currentPayPeriod.get('startDate').subtract(1, 'days').unix());
			var payPeriod = this.get('organization.createdOn') <= previousPayPeriod.get('end') ? previousPayPeriod : currentPayPeriod;

			if (!this.get('isDestroyed')) {
				this.set('currentPayPeriod', currentPayPeriod);
				this.set('previousPayPeriod', previousPayPeriod);

				this.changeDateRange(payPeriod, false);
			}
		},
		changeDateRange: function changeDateRange(payPeriod) {
			var refresh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

			var start = this.get('start');
			var newStart = payPeriod.get('startDate').unix();
			var newEnd = payPeriod.get('endDate').unix();

			if (start !== newStart && !this.get('isDestroyed')) {
				this.set('start', newStart);
				this.set('end', newEnd);

				if (refresh) {
					this.refresh();
				}
			}
		},
		filterByPermissions: function filterByPermissions(model) {
			var _this2 = this;

			if (!model) {
				return [];
			}
			return model.filter(function (item) {
				return _this2.get('access').canTakeActionForAny(['timeEvents', 'manageTimeEntries'], item);
			});
		},
		filterByStatus: function filterByStatus(model, filter) {
			// Logger.info(this, 'filterByStatus', filter);

			if (Ember.isNone(filter) || filter === 'all') {
				return model;
			} else if (this.get('signature.isSignatureModeBase')) {
				return this.filterByStatusBaseMode(model, filter);
			} else if (this.get('signature.isSignatureModeSupervisor')) {
				return this.filterByStatusSupervisorMode(model, filter);
			} else {
				return model;
			}
		},
		filterByStatusBaseMode: function filterByStatusBaseMode(model, filter) {
			// Logger.info(this, 'filterByStatusBaseMode', filter);

			if (filter === 'signed') {
				return model.filter(function (item) {
					return item.get('memberTimeDocument.hasValidSelfSignature');
				});
			} else if (filter === 'unsigned') {
				return model.filter(function (item) {
					return !item.get('memberTimeDocument.hasValidSelfSignature') && !item.get('memberTimeDocument.needsSelfSignatureResign');
				});
			} else if (filter === 'resign') {
				return model.filter(function (item) {
					return item.get('memberTimeDocument.needsSelfSignatureResign');
				});
			} else {
				return model;
			}
		},
		filterByStatusSupervisorMode: function filterByStatusSupervisorMode(model, filter) {
			// Logger.info(this, 'filterByStatusSupervisorMode', filter);

			if (filter === 'signed') {
				return model.filter(function (item) {
					return item.get('memberTimeDocument.hasBothValidSignatures');
				});
			} else if (filter === 'unsigned') {
				return model.filter(function (item) {
					return !item.get('memberTimeDocument.hasPreviousInvalidation') && (Ember.isNone(item.get('memberTimeDocument.id')) || item.get('memberTimeDocument.needsBothSignature'));
				});
			} else if (filter === 'employee') {
				return model.filter(function (item) {
					return !item.get('memberTimeDocument.hasValidSelfSignature') && item.get('memberTimeDocument.hasValidAuthoritativeSignature');
				});
			} else if (filter === 'supervisor') {
				return model.filter(function (item) {
					return !item.get('memberTimeDocument.hasValidAuthoritativeSignature') && item.get('memberTimeDocument.hasValidSelfSignature');
				});
			} else if (filter === 'resign') {
				return model.filter(function (item) {
					return item.get('memberTimeDocument.needsBothSignatureResign');
				});
			} else {
				return model;
			}
		},
		getSortedModel: function getSortedModel(model, sort) {
			if (sort === 'lastFirst') {
				return model.sortBy('lastName', 'firstName');
			} else if (sort === 'signatureStatus') {
				return model.sort(this.sortBySignatureStatus.bind(this));
			} else if (sort === 'lockDate') {
				return model.sort(this.sortByLockDate.bind(this));
			} else {
				// default to sort by name
				return model.sortBy('fullName');
			}
		},
		sortBySignatureStatus: function sortBySignatureStatus(aMember, bMember) {
			var a = 0;
			if (aMember.get('memberTimeDocument.hasValidSelfSignature')) {
				a = aMember.get('memberTimeDocument.selfSignature.createdOn');
			} else if (aMember.get('memberTimeDocument.needsSelfSignatureResign')) {
				a = 1;
			}

			var b = 0;
			if (bMember.get('memberTimeDocument.hasValidSelfSignature')) {
				b = bMember.get('memberTimeDocument.selfSignature.createdOn');
			} else if (bMember.get('memberTimeDocument.needsSelfSignatureResign')) {
				b = 1;
			}

			return a > b ? -1 : a < b ? 1 : 0;
		},
		sortByLockDate: function sortByLockDate(aMember, bMember) {
			var a = aMember.get('memberLock.effectiveDate') || 0;
			var b = bMember.get('memberLock.effectiveDate') || 0;

			return a > b ? -1 : a < b ? 1 : 0;
		},


		actions: {
			/**
    * Update the start and end times to the selected date range and trigger a route refresh
    *
    * @event changeDateRange
    * @param {Object} payPeriod
    */
			changeDateRange: function changeDateRange(payPeriod) {
				this.changeDateRange(payPeriod);
			},
			filterSelected: function filterSelected(itemKey) {
				// Logger.info(this, 'filterSelected', itemKey);

				if (!this.get('isDestroyed')) {
					if (itemKey !== this.get('selectedFilter')) {
						this.set('selectedFilter', itemKey);
					} else {
						// clicking an active filter toggles the filter off
						this.set('selectedFilter', 'all');
					}
				}
			},
			teamMemberClicked: function teamMemberClicked(item) {
				var memberId = item.get('id');
				var start = this.get('start');
				var end = this.get('end');

				var queryParams = {
					member_id: memberId,
					start: start,
					end: end
				};

				this.transitionToRoute('time-card.full-report', { queryParams: queryParams });

				// /time-card/full-report?end=1483228799&member_id=92cccae3-2aac-43c2-b69e-fbca7e472118&start=1480550400
			},
			changeSort: function changeSort(newSelectedSort) {
				if (!this.get('isDestroyed')) {
					if (this.get('selectedSort') === newSelectedSort) {
						this.set('selectedSortReverse', !this.get('selectedSortReverse'));
					} else {
						this.set('selectedSortReverse', false);
						this.set('selectedSort', newSelectedSort);
					}
				}
			}
		}
	});
});