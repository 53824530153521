define('busy-app/animations/project', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = timecard;
	function timecard(durationTo, durationBack) {
		//time card transitions
		var animate = ['project.detail', 'project.detail.reports', 'project.detail.project-time-sheet'];

		this.transition(this.toRoute(animate), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));

		// project slider transition
		this.transition(this.hasClass('slide-greater'), this.toValue(function (to, from) {
			return from < to;
		}), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));

		// project slider transition
		this.transition(this.hasClass('project-slide'), this.toValue(function (to, from) {
			return from.project.get('depth') < to.project.get('depth');
		}), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));
	}
});