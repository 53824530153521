define('busy-app/adapters/application-report', ['exports', 'busy-app/adapters/application', 'busy-app/utils/settings'], function (exports, _application, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _application.default.extend({

		host: Ember.computed(function () {
			return _settings.default.get('etl_url') + '/' + _settings.default.get('etl_version');
		}),

		isBatchEnabled: Ember.computed(function () {
			return false;
		}),

		//buildURL(modelName, id, snapshot, requestType, query) {
		//	let url = this._super(modelName, id, snapshot, requestType, query, false);

		//	console.log('url', url);
		//	return url;
		//},

		addDefaultParams: function addDefaultParams(data) {
			if (Ember.get(data, 'page_size')) {
				Ember.set(data, 'limit', Ember.get(data, 'page_size'));
				delete data.page_size;
			}

			if (Ember.get(data, 'page')) {
				delete data.page;
			}

			return;
		},
		addUrlParams: function addUrlParams(data) {
			// set debug flag
			if (_settings.default.get('debug_mode')) {
				//set(data, '_debug', true);

				if (_settings.default.get('xdebug_session_start')) {
					Ember.set(data, 'XDEBUG_SESSION_START', _settings.default.get('xdebug_session_start'));
				}
			}
		}
	});
});