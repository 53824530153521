define('busy-app/utils/settings', ['exports', 'busy-app/config/environment', '@busy-web/utils'], function (exports, _environment, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	var APP = _environment.default.APP,
	    featureFlags = _environment.default.featureFlags;

	var Settings = function () {
		function Settings() {
			_classCallCheck(this, Settings);
		}

		_createClass(Settings, null, [{
			key: 'get',
			value: function get(key) {
				_utils.Assert.isString(key);
				key = key.toUpperCase();
				return Ember.get(APP, key);
			}
		}, {
			key: 'getFeatureList',
			value: function getFeatureList() {
				return featureFlags;
			}
		}, {
			key: 'getFeature',
			value: function getFeature(key) {
				_utils.Assert.isString(key);

				return Ember.get(featureFlags, key);
			}
		}]);

		return Settings;
	}();

	exports.default = Settings;
});