define('busy-app/components/employee-selector', ['exports', '@busy-web/utils', 'busy-app/utils/debug', 'busy-app/utils/access'], function (exports, _utils, _debug, _access) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-employee-selector'],
		store: Ember.inject.service('store'),
		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),

		headerTitle: (0, _utils.loc)('Employees'),

		model: null,
		allowEditForAllPermission: false,
		accessRequired: null,
		requireMembers: false,
		allowCancel: false,

		__selectedMembers: null,
		__memberList: null,
		__restrictedMembers: null,
		__isSelectAll: false,

		init: function init() {
			this._super();
			Ember.set(this, '__memberList', []);

			// setting this to an non empty array tricks
			// the interface into not showing ui before
			// the restrictedMembers list is loaded.
			Ember.set(this, '__restrictedMembers', [1]);

			this.setEmployees();
		},


		canEdit: Ember.computed('allowEditForAllPermission', '__restrictedMembers.[]', function () {
			// override to allow edits when not all members are allowed
			if (Ember.get(this, 'allowEditForAllPermission')) {
				return true;
			}

			if (!(Ember.get(this, '__restrictedMembers.length') > 0)) {
				return true;
			}
			return false;
		}),

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();

			if (Ember.isEmpty(Ember.get(this, 'model'))) {
				Ember.set(this, '__selectedMembers', []);
			} else {
				Ember.set(this, '__selectedMembers', normalizeModels(Ember.get(this, 'model')));
			}

			if (Ember.isEmpty(this.get('accessRequired'))) {
				Ember.set(this, 'accessRequired', ['timeEvents', 'manageTimeEntries', 'manageEmployees']);
			}

			if (!Ember.isEmpty(Ember.get(this, '__memberList'))) {
				this.checkSelectedMembers();
			}
		},
		checkSelectedMembers: function checkSelectedMembers() {
			var _this = this;

			var memberList = Ember.get(this, '__memberList');
			var current = Ember.get(this, '__selectedMembers');

			// check for members the auth member cannot edit
			var cannotRemove = current.filter(function (c) {
				return Ember.isNone(memberList.find(function (m) {
					return Ember.get(m, 'id') === Ember.get(c, 'id');
				}));
			});
			Ember.set(this, '__restrictedMembers', cannotRemove);

			if (!Ember.isEmpty(Ember.get(this, 'model'))) {
				memberList.forEach(function (member) {
					var memberFound = Ember.get(_this, 'model').findBy('id', Ember.get(member, 'id'));
					if (!Ember.isNone(memberFound)) {
						Ember.set(member, 'isSelected', true);
					} else {
						Ember.set(member, 'isSelected', false);
					}
				});
			} else {
				Ember.get(this, '__memberList').forEach(function (member) {
					return Ember.set(member, 'isSelected', false);
				});
			}
		},
		setEmployees: function setEmployees() {
			var _this2 = this;

			// prevent api calls in components durring tests
			return (0, _debug.component_safe_api)(function () {
				return _this2._setEmployees();
			});
		},
		_setEmployees: function _setEmployees() {
			var _this3 = this;

			return Ember.RSVP.resolve(Ember.get(this, 'auth.member')).then(function (authMember) {
				return Ember.RSVP.resolve(Ember.get(_this3, 'auth.authMemberPosition')).then(function (permission) {
					var positionIds = getAccessForPermision(Ember.get(_this3, 'access'), permission, Ember.get(_this3, 'accessRequired'));
					var query = { _asc: ['first_name', 'last_name'], archived_on: null, deleted_on: null };

					if (Ember.get(_this3, 'auth.useGroupPermissionScoping')) {
						var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
						    id = _EmberGetProperties.id,
						    memberGroupId = _EmberGetProperties.memberGroupId;

						Ember.setProperties(query, { member_group_id: memberGroupId });

						if (Ember.isNone(memberGroupId)) {
							Ember.setProperties(query, { id: id });
						}
					}

					// load employees
					return Ember.get(_this3, 'store').findWhereIn('member', 'position_id', positionIds, query).then(function (models) {
						models = models.toArray();

						// add auth member if has self but not same permission
						if (!models.find(function (member) {
							return Ember.get(member, 'id') === Ember.get(authMember, 'id');
						})) {
							if ((0, _access.levelSelf)(permission.get('timeEvents'))) {
								models.push(authMember);
							}
						}

						Ember.set(_this3, '__models', models);

						models = models.map(function (item) {
							var json = item.toJSON({ includeId: true });
							json.fullName = Ember.get(item, 'fullName');
							return json;
						});

						// set members list
						Ember.set(_this3, '__memberList', models.sortBy('firstName', 'lastName'));

						// set selected for all members in __selectedMembers
						_this3.checkSelectedMembers();
						return true;
					});
				});
			});
		},
		addRemoveSelected: function addRemoveSelected() {
			var memberList = Ember.get(this, '__memberList');
			var cannotRemove = Ember.get(this, '__restrictedMembers') || [];
			var selected = memberList.filter(function (m) {
				return Ember.get(m, 'isSelected') === true;
			});

			// add selected members to restricted members list
			var currentSelected = [].concat(cannotRemove, selected);

			Ember.set(this, '__selectedMembers', currentSelected.sortBy('firstName', 'lastName'));
		},


		actions: {
			doneAction: function doneAction() {
				var selected = Ember.get(this, '__selectedMembers');
				Ember.set(this, 'showEmployeeDialog', false);

				if (Ember.isEmpty(selected) && Ember.get(this, 'requireMembers')) {
					selected = normalizeModels(Ember.get(this, 'model'));
					Ember.set(this, '__selectedMembers', selected);
					this.checkSelectedMembers();
				}

				selected = getModels(Ember.get(this, '__models'), selected);
				this.sendAction('onDone', selected);
			},
			cancelAction: function cancelAction() {
				Ember.set(this, 'showEmployeeDialog', false);

				var selected = normalizeModels(Ember.get(this, 'model'));
				Ember.set(this, '__selectedMembers', selected);
				this.checkSelectedMembers();

				selected = getModels(Ember.get(this, '__models'), selected);
				this.sendAction('onDone', selected);
			},
			employeeClicked: function employeeClicked(item) {
				var isSelected = !Ember.get(item, 'isSelected');
				Ember.setProperties(item, { isSelected: isSelected });
				this.addRemoveSelected();
			},
			employeeSelected: function employeeSelected() {
				this.addRemoveSelected();
			},
			selectAllItems: function selectAllItems(isChecked) {
				var memberList = Ember.get(this, '__memberList');
				memberList.forEach(function (item) {
					return Ember.set(item, 'isSelected', isChecked);
				});

				var selected = getSelected(Ember.get(this, '__models'), memberList);
				Ember.set(this, '__selectedMembers', selected);
			}
		}
	});


	var normalizeModels = function normalizeModels(models) {
		if (!Ember.isEmpty(models)) {
			return models.map(function (m) {
				if (m && m.toJSON) {
					var json = m.toJSON({ includeId: true });
					json.fullName = Ember.get(m, 'firstName') + ' ' + Ember.get(m, 'lastName');
					return json;
				}
				return m;
			});
		}
		return [];
	};

	var getSelected = function getSelected(members, list) {
		var selected = list.filterBy('isSelected', true);
		return getModels(members, selected);
	};

	var getModels = function getModels(members, selected) {
		return selected.map(function (s) {
			return members.findBy('id', Ember.get(s, 'id'));
		});
	};

	function getAccessForPermision(accessService, permission) {
		var accessRequired = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
		(false && !(!Ember.isNone(accessRequired) && Array.isArray(accessRequired)) && Ember.assert("accessRequired must be an array of strings", !Ember.isNone(accessRequired) && Array.isArray(accessRequired)));


		var accessPermission = getAccess(accessService, permission, accessRequired);

		return Ember.get(accessService, 'auth.positions').sortBy('level').map(function (item) {
			return includeId(accessPermission, permission, item);
		}).compact();
	}

	var ALLOWED_PERMISSION = ['manageEmployees', 'manageTimeEntries', 'timeEvents'];

	function getAccess(accessService, permission, accessRequired) {
		var defaultPerm = { self: false, same: false, lower: false, higher: false };
		if (!Ember.isNone(accessService)) {
			return accessRequired.map(function (cp) {
				if (ALLOWED_PERMISSION.indexOf(cp) !== -1) {
					return accessService.parseSetting(Ember.get(permission, cp));
				}
				return;
			}).reduce(function (a, b) {
				if (Ember.isNone(b)) {
					return a;
				}

				return {
					self: a.self || b.self,
					same: a.same || b.same,
					lower: a.lower || b.lower,
					higher: a.higher || b.higher
				};
			}, defaultPerm);
		} else {
			return defaultPerm;
		}
	}

	function includeId(accessPermission, permission, item) {
		var lower = accessPermission.lower && Ember.get(item, 'level') > Ember.get(permission, 'level');
		var same = accessPermission.same && Ember.get(item, 'level') === Ember.get(permission, 'level');
		var higher = accessPermission.higher && Ember.get(item, 'level') < Ember.get(permission, 'level');

		if (lower || higher || same) {
			return Ember.get(item, 'id');
		}
	}
});