define('busy-app/components/busy-header', ['exports', 'busy-app/templates/components/busy-header'], function (exports, _busyHeader) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _busyHeader.default,
		tagName: 'header',

		classNames: ['busy-header'],

		headerTitle: '',
		hasBackButton: false,

		actions: {
			backButton: function backButton() {
				this.sendAction('onBack');
			}
		}
	});
});