define('busy-app/components/projects/project-info', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Components
  *
  */
	var kDefaultLocation = {
		latitude: 37.10102990074617,
		longitude: -113.53877082086876,
		locationRadius: 50
	};

	/**
  *
  */
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		store: Ember.inject.service('store'),
		state: Ember.inject.service('state'),

		classNames: ['v-project-details'],

		project: null,
		projectInfo: null,
		projectCostCodeLinks: null,
		useGeolocation: null,

		showEditDialog: false,
		showAdvancedOptions: false,
		mapInit: Date.now(),

		_projectCostCodeLinks: null,

		init: function init() {
			this._super.apply(this, arguments);
			_logger.default.info(this, 'init');

			this.loadProjectInfo();
			this.loadSiblings();
			this.setProjectCostCodeLinks();
		},


		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),

		onUseGeolocationChange: Ember.observer('useGeolocation', function () {
			if (this.get('showEditDialog')) {
				if (this.get('useGeolocation') && Ember.isNone(this.get('projectInfo.locationRadius'))) {
					this.get('projectInfo').setProperties(kDefaultLocation);
					this.set('mapInit', Date.now());
				}
			}
		}),

		loadSiblings: Ember.observer('project.rootProjectId', 'project.parentProjectId', function () {
			var _this = this;

			if (this.get('project.rootProjectId') === this.get('project.id')) {
				this.get('store').query('project', { depth: 1 }).then(function (results) {
					_this.set('projects', results);
				});
			} else {
				this.get('store').query('project', { parent_project_id: this.get('project.parentProjectId') }).then(function (results) {
					_this.set('projects', results);
				});
			}
		}),

		loadProjectInfo: Ember.observer('project.id', function () {
			var _this2 = this;

			if (!Ember.isNone(this.get('project.id'))) {
				this.get('store').query('project-info', { project_id: this.get('project.id') }).then(function (model) {
					model = model.objectAt(0);
					if (!_this2.get('isDestroyed')) {
						if (!Ember.isNone(model)) {
							// model.set('coords', [model.get('latitude'), model.get('longitude')])
							_this2.set('projectInfo', model);
							_this2.set('requireOnSiteVerification', _this2.get('projectInfo.requireGPS'));
							_this2.set('subProjectVerication', _this2.get('projectInfo.requireGpsSubProjects'));
						} else {
							_this2.set('projectInfo', _this2.get('store').createRecord('project-info', { projectId: _this2.get('project.id') }));
						}
						_this2.set('useGeolocation', model.get('hasLocation'));
					}
				});
			}
		}),

		setApplySubProjectsFalse: Ember.observer('requireOnSiteVerification', function () {
			var requireOnSiteVerification = this.get('requireOnSiteVerification');
			if (!requireOnSiteVerification) {
				this.set('subProjectVerication', false);
			}
		}),

		setProjectCostCodeLinks: Ember.observer('project.id', function () {
			var _this3 = this;

			_logger.default.info(this, 'setProjectCostCodeLinks', Ember.get(this, 'isLinkedCostCodesEnabled'));

			if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
				return this.getProjectCostCodeLinks().then(function (projectCostCodeLinks) {
					return Ember.setProperties(_this3, { projectCostCodeLinks: projectCostCodeLinks });
				});
			} else {
				return Ember.RSVP.resolve();
			}
		}),

		onShowEditDialog: Ember.observer('showEditDialog', function () {
			var _this4 = this;

			if (this.get('showEditDialog')) {
				Ember.run.next(this, function () {
					return _this4.set('mapInit', Date.now());
				});
			}
		}),

		isArchived: Ember.computed('project.archivedOn', function () {
			if (!Ember.isNone(this.get('project.archivedOn'))) {
				return true;
			}
			return false;
		}),

		getProjectReminderStatus: Ember.computed('projectInfo.reminder', function () {
			return this.get('projectInfo.reminder') ? (0, _utils.loc)('Yes') : (0, _utils.loc)('No');
		}),

		formattedAddress: Ember.computed('projectInfo.{address1,address2}', function () {
			return [this.get('projectInfo.address1') || null, this.get('projectInfo.address2') || null].compact().join(', ');
		}),

		formattedCity: Ember.computed('projectInfo.{city,state,postalCode}', function () {
			if (Ember.isNone(this.get('projectInfo.city')) || Ember.isNone(this.get('projectInfo.state')) || Ember.isNone(this.get('projectInfo.postalCode'))) {
				return '';
			}
			return this.get('projectInfo.city') + ', ' + this.get('projectInfo.state') + ' ' + this.get('projectInfo.postalCode');
		}),

		/*
   * Cost Code Select Actions
   */

		getProjectCostCodeLinks: function getProjectCostCodeLinks() {
			_logger.default.info(this, 'getProjectCostCodeLinks');

			return Ember.get(this, 'project.projectCostCodeLinks')
			// only include ProjectCostCodeLinks where the cost code is not archived
			.then(function (links) {
				// get all the linked cost codes
				return Ember.RSVP.all(links.map(function (link) {
					// if the cost cost is not archived, return the Link object, not the cost code
					return Ember.get(link, 'costCode').then(function (cc) {
						return Ember.isNone(Ember.get(cc, 'archivedOn')) ? link : null;
					});
				}));
			})
			// filter out empty objects (had archived cost codes)
			.then(function (links) {
				return Ember.RSVP.filter(links, function (link) {
					return !Ember.isNone(link);
				});
			})
			// turn the result set into the expected array of ProjectCostCodeLink
			.then(function (links) {
				return links.toArray();
			});
		},
		recieveCostCodeChanges: function recieveCostCodeChanges(_projectCostCodeLinks) {
			_logger.default.info(this, 'recieveCostCodeChanges', _projectCostCodeLinks);
			Ember.setProperties(this, { _projectCostCodeLinks: _projectCostCodeLinks });

			return Ember.RSVP.resolve();
		},
		rollbackCostCodeChanges: function rollbackCostCodeChanges() {
			var _this5 = this;

			_logger.default.info(this, 'rollbackCostCodeChanges', this.get('_projectCostCodeLinks'));

			if (!Ember.isEmpty(Ember.get(this, '_projectCostCodeLinks'))) {
				Ember.get(this, '_projectCostCodeLinks').filterBy('hasDirtyAttributes').forEach(function (model) {
					return model.rollbackAttributes();
				});

				Ember.set(this, '_projectCostCodeLinks', null);
			}

			return this.getProjectCostCodeLinks().then(function (_projectCostCodeLinks) {
				return Ember.setProperties(_this5, { _projectCostCodeLinks: _projectCostCodeLinks });
			});
		},


		actions: {
			// triggered by onShowTab in parent container
			reInitMap: function reInitMap() {
				// pass down a new mapInit value to the leaflet component so that it will re-render itself
				this.set('mapInit', Date.now());
			},
			onProjectLocationChange: function onProjectLocationChange(latitude, longitude, locationRadius) {
				this.get('projectInfo').setProperties({
					latitude: latitude,
					longitude: longitude,
					locationRadius: locationRadius
				});
			},
			openEditDialog: function openEditDialog() {
				var _this6 = this;

				if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
					Ember.set(this, 'isLoading', true);
					this.getProjectCostCodeLinks().then(function (_projectCostCodeLinks) {
						Ember.setProperties(_this6, {
							_projectCostCodeLinks: _projectCostCodeLinks,
							showEditDialog: true,
							isLoading: false
						});
					});
				} else {
					Ember.set(this, 'showEditDialog', true);
				}
			},
			showAdvancedAction: function showAdvancedAction() {
				this.set('showAdvancedOptions', !this.get('showAdvancedOptions'));
			},
			closeEditDialog: function closeEditDialog() {
				this.get('project').rollbackAttributes();
				if (!Ember.isNone(this.get('projectInfo'))) {
					this.get('projectInfo').rollbackAttributes();
				}
				this.rollbackCostCodeChanges();

				this.set('showEditDialog', false);
			},
			saveProjectInfo: function saveProjectInfo() {
				var _this7 = this;

				var project = this.get('project');
				var projectInfo = this.get('projectInfo');
				var onSiteVerification = '';

				this.set('mapError', false);

				if (Ember.isEmpty(project.get('title'))) {
					return Ember.RSVP.reject('Project must have a title');
				}

				if (!this.get('useGeolocation')) {
					projectInfo.clearGeolocation();
				}

				if (this.get('requireOnSiteVerification') && !this.get('subProjectVerication')) {
					onSiteVerification = 'self';
				} else if (this.get('requireOnSiteVerification') && this.get('subProjectVerication')) {
					onSiteVerification = 'self_and_children';
				} else if (!this.get('requireOnSiteVerification') && this.get('subProjectVerication')) {
					onSiteVerification = 'children';
				} else {
					onSiteVerification = 'none';
				}

				projectInfo.set('requireTimeEntryGps', onSiteVerification);

				// force project location to be set if reminder is set to true
				if (projectInfo.get('reminder') && !projectInfo.get('hasLocation')) {
					this.set('mapError', true);
					return Ember.RSVP.reject((0, _utils.loc)('Geolocation required for location reminders.'));
				}

				var projectChanges = project.changedAttributes();
				var projectInfoChanges = projectInfo.changedAttributes();

				var promise = Ember.RSVP.resolve();
				if (Object.keys(projectChanges).length) {
					// if title is changed then make sure the new title does not conflict with another project
					if (!Ember.isNone(Ember.get(projectChanges, 'title'))) {
						var hasDuplicateTitle = this.get('projects').find(function (p) {
							return p.get('title') === project.get('title') && p.get('id') !== project.get('id');
						});
						if (!Ember.isNone(hasDuplicateTitle)) {
							return Ember.RSVP.reject((0, _utils.loc)('Project name must be unique'));
						}
					}

					// save project changes
					promise = project.save();
				}

				if (Object.keys(projectInfoChanges).length) {
					// save project info changes
					promise.then(function () {
						return projectInfo.save();
					});
				}

				if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
					var changedProjectCostCodeLinks = Ember.get(this, '_projectCostCodeLinks').filterBy('hasDirtyAttributes');

					if (!Ember.isNone(changedProjectCostCodeLinks)) {
						var saveLinks = changedProjectCostCodeLinks.map(function (model) {
							return model.save();
						});
						promise.then(function () {
							return Ember.RSVP.allSettled(saveLinks);
						});
					}
				}

				return promise.then(function () {
					return Ember.RSVP.all([Ember.get(_this7, 'project').reload(), _this7.loadProjectInfo(), _this7.getProjectCostCodeLinks()]);
				});
			},


			/*
    * Cost Code Select Actions
    */
			onCancelCostCodeChanges: function onCancelCostCodeChanges() {
				_logger.default.info(this, 'onCancelCostCodeChanges');

				return this.rollbackCostCodeChanges();
			},
			onSaveManagerChanges: function onSaveManagerChanges(projectCostCodeLinks) {
				_logger.default.info(this, 'onSaveManagerChanges', { projectCostCodeLinks: projectCostCodeLinks });

				var projectId = Ember.get(this, 'project.id');
				projectCostCodeLinks.setEach('projectId', projectId);

				return this.recieveCostCodeChanges(projectCostCodeLinks);
			}
		}
	});
});