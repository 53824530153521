define('busy-app/components/time/time-sheet/signature-notification', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-sheet-signature-notification'],
		elementId: 'c-time-sheet-signature-notification',

		features: Ember.inject.service(),
		payPeriod: Ember.inject.service(),
		router: Ember.inject.service(),
		signature: Ember.inject.service(),
		session: Ember.inject.service(),
		store: Ember.inject.service(),

		member: null,
		currentPayPeriod: null,
		visiblePayPeriod: null,
		dirtyFlag: null,

		isCurrentPayPeriodActive: Ember.computed('currentPayPeriod.start', 'visiblePayPeriod.start', function () {
			return Ember.get(this, 'currentPayPeriod.start') === Ember.get(this, 'visiblePayPeriod.start');
		}),

		isSignaturePossible: Ember.computed('features.signatures', 'signature.isTimecardSigningEnabled', 'previousPayPeriod', 'session.isAuthenticated', function () {
			return Ember.get(this, 'previousPayPeriod') && Ember.get(this, 'session.isAuthenticated') && Ember.get(this, 'features.signatures') && Ember.get(this, 'signature.isTimecardSigningEnabled') && Ember.get(this, 'previousPayPeriod.isSignable');
		}),

		showNotification: Ember.computed('isCurrentPayPeriodActive', 'isSignaturePossible', 'needsTimecardSignature', 'dirtyFlag', function () {
			return Ember.get(this, 'isCurrentPayPeriodActive') && !Ember.get(this, 'dirtyFlag') && Ember.get(this, 'isSignaturePossible') && Ember.get(this, 'needsTimecardSignature');
		}),

		previousPayPeriod: Ember.computed('currentPayPeriod', function () {
			var currentPayPeriod = Ember.get(this, 'currentPayPeriod');

			if (Ember.isNone(currentPayPeriod)) {
				return currentPayPeriod;
			}

			var prevEnd = Ember.get(currentPayPeriod, 'start') - 1;

			return Ember.get(this, 'payPeriod').getPayPeriod(prevEnd);
		}),

		memberTimeDocument: Ember.computed('member.id', 'previousPayPeriod.{start,end}', function () {
			return _emberData.default.PromiseObject.create({ promise: this.loadMemberTimeDocument() });
		}),

		needsTimecardSignature: Ember.computed('member.id', 'dirtyFlag', function () {
			// Logger.info(this, 'needsTimecardSignature', 'member.id', get(this, 'member.id'));
			// Logger.info(this, 'needsTimecardSignature', 'dirtyFlag', get(this, 'dirtyFlag'));

			return _emberData.default.PromiseObject.create({
				promise: this.get('signature').getNeedsTimecardSignature(Ember.get(this, 'member.id'))
			});
		}),

		actions: {
			openFullReport: function openFullReport() {
				var queryParams = {
					end: Ember.get(this, 'previousPayPeriod.end'),
					member_id: Ember.get(this, 'member.id'),
					start: Ember.get(this, 'previousPayPeriod.start')
				};

				return Ember.get(this, 'router').transitionTo('time-card.full-report', { queryParams: queryParams });
			}
		}
	});
});