define('busy-app/components/time/break-entry/edit', ['exports', '@busy-web/utils', 'busy-app/utils/round-time'], function (exports, _utils, _roundTime) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-break-entry-edit'],

		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		model: null,
		timeEntry: null,

		minDate: null,
		maxDate: null,

		startBreakTime: null,
		endBreakTime: null,
		totalBreakTime: 0,

		init: function init() {
			this._super();
			this.setTimeRoundingInterval();

			this.set('endBreakTime', (0, _roundTime.roundOrgTime)(this.get('auth.organization'), this.get('model.endTime') || _utils.Time.date().startOf('minute').unix(), { isBreak: true }));
			this.set('startBreakTime', (0, _roundTime.roundOrgTime)(this.get('auth.organization'), this.get('model.startTime'), { isBreak: true }));

			this.set('minDate', _utils.Time.date().subtract(89, 'days').unix());
			this.set('maxDate', _utils.Time.date().add(1, 'days').unix());

			this.totalBreakTimeObserver();

			var headerTitle = (0, _utils.loc)('Edit Break');
			var deleteText = (0, _utils.loc)('Delete');
			if (this.get('model.isNew')) {
				deleteText = (0, _utils.loc)('Remove');
				if (!this.get('model._isEdit')) {
					headerTitle = (0, _utils.loc)('Add Break');
				}
			}
			this.set('headerTitle', headerTitle);
			this.set('deleteText', deleteText);
		},
		setTimeRoundingInterval: function setTimeRoundingInterval() {
			var interval = 1;

			if (this.get('auth.organization.timeRounding')) {
				interval = this.get('auth.organization.timeRoundingInterval') / 60;
			}

			this.set('timeRoundingInterval', interval);
		},


		/**
    * updates the end time when the totalTime is adjusted
    *
    * @public
    * @method adjustNewEndTime
    * @returns {void}
    */
		adjustNewEndTime: Ember.observer('totalBreakTime', 'startBreakTime', function () {
			if (!Ember.isNone(this.get('startBreakTime')) && !Ember.isNone(this.get('endBreakTime'))) {
				var start = this.get('startBreakTime');
				var end = this.get('endBreakTime');
				var total = this.get('totalBreakTime');

				if (end - start !== total) {
					var newTotal = total - (end - start);
					this.set('endBreakTime', _utils.Time.date(end).add(newTotal, 'seconds').unix());
				}
			}
		}),

		/**
    * sets up and updates the total time for adding a break
    *
    * @public
    * @method totalBreakTimeObserver
    * @returns {void}
    */
		totalBreakTimeObserver: Ember.observer('startBreakTime', 'endBreakTime', function () {
			var seconds = 0;
			if (!Ember.isNone(this.get('startBreakTime')) && !Ember.isNone(this.get('endBreakTime'))) {
				var startTime = this.get('startBreakTime');
				var endTime = this.get('endBreakTime');

				this.set('model.startTime', startTime);
				this.set('model.endTime', endTime);

				seconds = endTime - startTime;
			}

			this.set('totalBreakTime', seconds);
		}),

		/**
    * validates the break to make sure it can be saved later
    *
    * @public
    * @method validate
    * @param model {DS.Model}
    * @param dialog {Component}
    */
		validate: function validate(model) {
			return model.get('timeEntry').then(function (entry) {
				return model.validate(entry);
			});
		},
		overrideWarning: function overrideWarning(model) {
			var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			Ember.set(model, '_isValid', forceSave);
			return Ember.RSVP.resolve();
		},


		actions: {
			closeAction: function closeAction() {
				this.sendAction('onClose');
			},
			saveAction: function saveAction(dialog) {
				var _this = this;

				var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

				var model = this.get('model');
				return this.overrideWarning(model, forceSave).then(function () {
					if (!forceSave) {
						var startTime = _this.get('startBreakTime');
						var endTime = _this.get('endBreakTime');

						if (_this.get('features.paidBreak')) {
							var paid = _this.get('currentBreakPaidBreak');
							model.set('paid', paid);
						}

						if (model.get('startTime') !== startTime) {
							model.set('startTime', startTime);
						}

						if (model.get('endTime') !== endTime) {
							model.set('endTime', endTime);
						}

						if (model.get('hasDirtyAttributes')) {
							return _this.validate(model);
						}
					}
					return Ember.RSVP.resolve();
				});
			},
			deleteAction: function deleteAction() {
				var model = this.get('model');
				return Ember.RSVP.resolve(this.get('model.timeEntry')).then(function (entry) {
					if (!Ember.isNone(entry) && !Ember.isNone(model)) {
						return entry.removeBreak(model);
					}
					return entry;
				});
			}
		}
	});
});