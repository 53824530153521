define('busy-app/components/bc-checkbox-input', ['exports', '@busy-web/components/components/bc-checkbox-input'], function (exports, _bcCheckboxInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bcCheckboxInput.default;
    }
  });
});