define('busy-app/utils/chargify', ['exports', 'cryptojs', 'busy-app/utils/settings'], function (exports, _cryptojs, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	//import { UUID, Time } from '@busy-web/utils';
	var Chargify = Ember.Object.extend(); /**
                                        * @module utils
                                        *
                                        */


	Chargify.reopenClass({
		generateUrl: function generateUrl(type, id) {
			var token = this.generateToken(type, id);
			return 'https://' + _settings.default.get('chargify_domain') + '.chargify.com/' + type + '/' + id + '/' + token + '?rel=_blank';
		},
		generateFormPostUrl: function generateFormPostUrl(id) {
			return 'https://api.chargify.com/api/v2/subscriptions/' + id + '/card_update';
		},
		generateToken: function generateToken(type, id) {
			var message = type + '--' + id + '--' + _settings.default.get('chargify_key');
			return _cryptojs.default.SHA1(message).toString().substr(0, 10);
		}
	});

	exports.default = Chargify;
});