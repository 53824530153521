define('busy-app/components/mod-util/aggregate-hours', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',

		model: null,

		regular: false,
		overtime: false,
		doubletime: false,
		total: false,

		padHours: true,
		type: null
	});
});