define('busy-app/components/company/permission-settings', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-company-permission-settings'],

		store: Ember.inject.service('store'),
		auth: Ember.inject.service('auth'),
		proFeature: Ember.inject.service('pro-feature'),

		showPositionDialog: false,
		showEditDialog: false,
		organization: null,

		positions: Ember.computed.alias('auth.positions'),

		didSaveNewPosition: false,

		draggingModel: null,
		droppedModel: null,
		originalPosition: null,

		proFeatureManager: null,

		init: function init() {
			this._super();

			this.get('auth.positions');

			this.set('proFeatureManager', this.get('proFeature').getManager());
		},
		moveItem: function moveItem(item) {
			if (this.get('draggingModel.level') !== item.get('level')) {
				var toPosition = this.get('positions.content').indexOf(item);
				var fromPosition = this.get('positions.content').indexOf(this.get('draggingModel'));

				this.set('draggingModel.level', toPosition + 1);
				item.set('level', fromPosition + 1);

				this.set('draggingModel.isDragOver', false);
				item.set('isDragOver', false);

				this.set('positions.content', this.get('positions.content').sortBy('level'));
			}
		},
		deleteItem: function deleteItem(item) {
			// the position is already deleted by the child component, this is just cleaning up the app state
			Ember.get(this, 'positions.content').removeObject(item);

			// every position with a higher level than than the deleted position needs to be updated
			// loop over every position double checking that the level is as expected in case the numbering was broken previously
			var level = 0;
			Ember.get(this, 'positions').sortBy('level').forEach(function (position) {
				level = level + 1;
				if (Ember.get(position, 'level') !== level) {
					Ember.setProperties(position, { level: level });
				}
			});

			var dirtyRecords = Ember.get(this, 'positions').filterBy('hasDirtyAttributes');

			if (Ember.isNone(dirtyRecords)) {
				return Ember.RSVP.resolve();
			}

			return Ember.RSVP.all(dirtyRecords.map(function (p) {
				return p.save();
			}));
		},
		editPosition: function editPosition(item) {
			this.set('newPosition', item);

			this.set('showEditDialog', !this.get('showEditDialog'));
		},
		saveMovedPositions: function saveMovedPositions() {
			var _this = this;

			this.set('isLoading', true);

			var promise = [];
			this.get('positions.content').forEach(function (position) {
				if (position.get('canEditPosition')) {
					promise.push(position.save());
				}
			});

			Ember.RSVP.all(promise).then(function () {
				_this.set('isLoading', false);
			});
		},


		actions: {
			newPosition: function newPosition() {
				var position = this.get('store').createRecord('position', {
					organizationId: this.get('organization.id'),
					canEditPosition: true,
					level: this.get('positions.length') + 1
				});

				this.set('newPosition', position);
				this.set('showPositionDialog', true);
			},
			cancelPosition: function cancelPosition() {
				this.set('showEditDialog', false);
				this.set('showPositionDialog', false);
				this.get('newPosition').rollbackAttributes();
				this.set('newPosition', null);
			},
			saveNewAction: function saveNewAction(item) {
				this.set('didSaveNewPosition', true);
				// TODO: find a future-proof way to update this array
				this.get('positions.content').pushObject(item);
			},
			deletePosition: function deletePosition(item) {
				return this.deleteItem(item);
			},
			draggingPosition: function draggingPosition(position) {
				this.set('draggingModel', position);
			},
			dragOverPosition: function dragOverPosition(position) {
				this.moveItem(position);
			},
			droppedPosition: function droppedPosition(dropPosition) {
				this.set('droppedModel', dropPosition);
			},
			dragEndPosition: function dragEndPosition() {
				if (!Ember.isNone(this.get('droppedModel.id')) && this.get('draggingModel.id') === this.get('droppedModel.id')) {
					this.saveMovedPositions();
				} else {
					this.get('positions.content').forEach(function (pos) {
						pos.rollbackAttributes();
					});

					this.set('positions.content', this.get('positions.content').sortBy('level'));
				}

				this.set('draggingModel', null);
				this.set('droppedModel', null);
				this.set('originalPosition', null);
			},
			clickedPosition: function clickedPosition(position) {
				this.editPosition(position);
			},
			showUpgradeDialog: function showUpgradeDialog() {
				this.send('cancelPosition');
				this.get('proFeatureManager').requestAction('customRoles', function () {
					window.location.reload();
				});
			},
			proFeatureManagerOpenModal: function proFeatureManagerOpenModal() {
				this.get('proFeatureManager').openModal();
			},
			proFeatureManagerCloseModal: function proFeatureManagerCloseModal() {
				this.get('proFeatureManager').closeModal();
			},
			proFeatureManagerPlanUpgraded: function proFeatureManagerPlanUpgraded() {
				this.get('proFeatureManager').onAllow();
			}
		}
	});
});