define('busy-app/components/dialogs/instructions-container', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-dialogs-instructions-container'],

		headerTitle: null,
		saveText: null,
		cancelText: null,

		actions: {
			onClose: function onClose() {
				this.sendAction('onClose');
			},
			onSave: function onSave() {
				this.sendAction('onSave');
			}
		}
	});
});