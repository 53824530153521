define('busy-app/helpers/filter-by', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.filterBy = filterBy;

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	function filterBy(list, key, value) {
		return list.filterBy(key, value);
	}

	exports.default = Ember.Helper.extend({
		list: null,

		compute: function compute(_ref) {
			var _this = this;

			var _ref2 = _slicedToArray(_ref, 3),
			    list = _ref2[0],
			    key = _ref2[1],
			    value = _ref2[2];

			if (Ember.isNone(Ember.get(this, 'list'))) {
				Ember.addObserver(this, 'list.@each.' + key, function () {
					return _this.recompute();
				});
			}

			Ember.set(this, 'list', list);

			return filterBy(list, key, value);
		}
	});
});