define("busy-app/utils/unassigned", ["exports", "@busy-web/utils"], function (exports, _utils) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = unassigned;


	var Unassigned = Ember.Object.extend({
		project: null,
		seconds: null,
		cost: null,

		id: Ember.computed.alias("project.id"),
		title: (0, _utils.loc)("Unassigned"),

		regularSeconds: Ember.computed.alias("seconds.regularSeconds").meta({
			aggregateType: "sum"
		}),
		overtimeSeconds: Ember.computed.alias("seconds.overtimeSeconds").meta({
			aggregateType: "sum"
		}),
		doubletimeSeconds: Ember.computed.alias("seconds.doubletimeSeconds").meta({
			aggregateType: "sum"
		}),
		childrenRegularSeconds: 0,
		childrenOvertimeSeconds: 0,
		childrenDoubletimeSeconds: 0,

		totalSeconds: Ember.computed("regularSeconds", "overtimeSeconds", "doubletimeSeconds", function () {
			return this.get("regularSeconds") + this.get("overtimeSeconds") + this.get("doubletimeSeconds");
		}).meta({ aggregateType: "sum" }),

		childrenTotalSeconds: Ember.computed("childrenRegularSeconds", "childrenOvertimeSeconds", "childrenDoubletimeSeconds", function () {
			return this.get("childrenRegularSeconds") + this.get("childrenOvertimeSeconds") + this.get("childrenDoubletimeSeconds");
		}).meta({ aggregateType: "sum" }),

		totalRegularSeconds: Ember.computed("regularSeconds", "childrenRegularSeconds", function () {
			return this.get("regularSeconds") + this.get("childrenRegularSeconds");
		}).meta({ aggregateType: "sum" }),

		totalOvertimeSeconds: Ember.computed("overtimeSeconds", "childrenOvertimeSeconds", function () {
			return this.get("overtimeSeconds") + this.get("childrenOvertimeSeconds");
		}).meta({ aggregateType: "sum" }),

		totalDoubletimeSeconds: Ember.computed("doubletimeSeconds", "childrenDoubletimeSeconds", function () {
			return this.get("doubletimeSeconds") + this.get("childrenDoubletimeSeconds");
		}).meta({ aggregateType: "sum" }),

		totalTotalSeconds: Ember.computed("totalSeconds", "childrenTotalSeconds", function () {
			return this.get("totalSeconds") + this.get("childrenTotalSeconds");
		}).meta({ aggregateType: "sum" }),

		regularCost: Ember.computed.alias("cost.regularCost").meta({ aggregateType: "sum" }),
		overtimeCost: Ember.computed.alias("cost.overtimeCost").meta({ aggregateType: "sum" }),
		doubletimeCost: Ember.computed.alias("cost.doubletimeCost").meta({ aggregateType: "sum" }),
		overburden: Ember.computed.alias("cost.overburden").meta({ aggregateType: "sum" }),
		childrenRegularCost: 0,
		childrenOvertimeCost: 0,
		childrenDoubletimeCost: 0,

		totalCost: Ember.computed("regularCost", "overtimeCost", "doubletimeCost", function () {
			return this.get("regularCost") + this.get("overtimeCost") + this.get("doubletimeCost");
		}).meta({ aggregateType: "sum" }),

		//unassigned totalTotalOverburden does not include subprojects
		totalTotalOverburdenCost: Ember.computed("totalCost", "overburden", function () {
			return this.get("totalCost") + this.get("overburden");
		}),

		childrenTotalCost: Ember.computed("childrenRegularCost", "childrenOvertimeCost", "childrenDoubletimeCost", function () {
			return this.get("childrenRegularCost") + this.get("childrenOvertimeCost") + this.get("childrenDoubletimeCost");
		}).meta({ aggregateType: "sum" }),

		totalRegularCost: Ember.computed("regularCost", "childrenRegularCost", function () {
			return this.get("regularCost") + this.get("childrenRegularCost");
		}).meta({ aggregateType: "sum" }),

		totalOvertimeCost: Ember.computed("overtimeCost", "childrenOvertimeCost", function () {
			return this.get("overtimeCost") + this.get("childrenOvertimeCost");
		}).meta({ aggregateType: "sum" }),

		totalDoubletimeCost: Ember.computed("doubletimeCost", "childrenDoubletimeCost", function () {
			return this.get("doubletimeCost") + this.get("childrenDoubletimeCost");
		}).meta({ aggregateType: "sum" }),

		totalTotalCost: Ember.computed("totalCost", "childrenTotalCost", function () {
			return this.get("totalCost") + this.get("childrenTotalCost");
		}).meta({ aggregateType: "sum" })
	});

	function unassigned(project, seconds, cost) {
		return {
			project: Unassigned.create({ project: project }),
			seconds: Unassigned.create({ seconds: seconds }),
			cost: Unassigned.create({ cost: cost })
		};
	}
});