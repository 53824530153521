define('busy-app/utils/data-base', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		name: 'busytime',
		version: 8,
		syncKey: 'updated_on',

		tables: [{
			name: 'member',
			keyPath: 'id',
			indexes: [{ name: 'first_name', keyPath: '', objectParams: { unique: false } }, { name: 'last_name', keyPath: '', objectParams: { unique: false } }, { name: 'updated_on', keyPath: '', objectParams: { unique: false } }],
			sync: { query: {} },
			cleanup: { archived_on: '!null' }
		}, {
			name: 'wage-history',
			keyPath: 'id',
			indexes: [{ name: 'updated_on', keyPath: '', objectParams: { unique: false } }],
			sync: { query: {} },
			cleanup: { deleted_on: '!null' }
		}]
	};
});