define('busy-app/utils/models/collections/payroll-member-project', ['exports', 'busy-app/utils/models/collections/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		payPeriods: Ember.inject.service('pay-period'),

		id: null,
		member: null,
		project: null,
		wage: null,
		startTime: null,

		memberSeconds: null,
		memberCost: null,
		projectSeconds: null,
		projectCost: null,

		hasUnassigned: false,

		isOpen: false,

		applyData: function applyData(data) {
			Ember.set(this, 'model', data);

			Ember.set(this, 'type', data.type);
			Ember.set(this, 'id', data.member.id);
			Ember.set(this, 'isModel', data.isModel);
			Ember.set(this, 'isOpen', data.isOpen);
			Ember.set(this, 'project', data.project);
			Ember.set(this, 'member', data.member);
			Ember.set(this, 'wage', data.wage);
			Ember.set(this, 'startTime', data.startTime);
			Ember.set(this, 'endTime', data.endTime);
			Ember.set(this, 'projectSeconds', data.projectSeconds);
			Ember.set(this, 'projectCost', data.projectCost);
			Ember.set(this, 'memberCost', data.memberCost);
			Ember.set(this, 'memberSeconds', data.memberSeconds);
		},


		hideData: Ember.computed('projectData.@each.isOpen', function () {
			//var isOpen = null;
			//if(!isNone(this.get('projectData')))
			//{
			//	isOpen = this.get('projectData').findBy('isOpen', true);
			//}
			return true; //!isNone(isOpen);
		}),

		filterChildren: false,

		depthString: Ember.computed('project.depth', function () {
			var depth = this.get('project.depth');
			var depthString = 'one';

			depthString = depth === 2 ? 'two' : depthString;
			depthString = depth === 3 ? 'three' : depthString;
			depthString = depth === 4 ? 'four' : depthString;
			depthString = depth === 5 ? 'five' : depthString;
			depthString = depth === 6 ? 'six' : depthString;
			depthString = depth === 7 ? 'seven' : depthString;

			return depthString;
		}),

		depthList: Ember.computed('project.depth', function () {
			var depth = this.get('project.depth');
			var array = [];
			if (depth > 0) {
				for (var i = 0; i < depth; i++) {
					array.push(i);
				}
			}
			return array;
		}),

		totalRegularSeconds: Ember.computed('projectSeconds.@each.totalRegularSeconds', 'filterChildren', function () {
			var _this = this;

			var seconds = 0;
			if (!Ember.isNone(this.get('projectSeconds'))) {
				this.get('projectSeconds').forEach(function (item) {
					if (_this.get('filterChildren')) {
						seconds += item.get('regularSeconds') || 0;
					} else {
						seconds += item.get('totalRegularSeconds');
					}
				});
			}
			return seconds;
		}),

		totalRegularTimeString: Ember.computed('totalRegularSeconds', function () {
			var totalRegularSeconds = _utils.Time.convertSeconds(this.get('totalRegularSeconds'));
			return totalRegularSeconds.hours + ' hrs ' + totalRegularSeconds.minutes + ' mins';
		}),

		/**
   * Gets Estimated Hourly Cost for the given time period
   * from all contents in content Array
   * and adds them up and returns the total.
   *
   * @public
   * @method estCostHourly
   * @returns int
   */
		estCostHourly: Ember.computed('payPeriodCostTotal', 'wage.wageRate', function () {
			var total = 0;
			if (!Ember.isNone(this.get('wage')) && this.get('wage.wageRate') === 10) {
				total = this.get('payPeriodCostTotal');
			}
			return total;
		}),

		/**
   * Gets Estimated Salary Cost for the given time period
   * from all contents in content Array who have a wage
   * type of anything but hourly and adds them up and returns the total.
   *
   * @public
   * @method estCostSalary
   * @returns int
   */
		estCostSalary: Ember.computed('payPeriodCostTotal', 'wage.wageRate', function () {
			var total = 0;
			if (!Ember.isNone(this.get('wage')) && this.get('wage.wageRate') !== 10) {
				total = this.get('payPeriodCostTotal');
			}
			return total;
		}),

		hasRegular: Ember.computed('totalRegularSeconds', 'totalRegularCost', function () {
			if ((this.get('totalRegularSeconds') || 0) > 0 || (this.get('totalRegularCost') || 0) > 0) {
				return true;
			}
			return false;
		}),

		hasOvertime: Ember.computed('totalOvertimeSeconds', 'totalOvertimeCost', function () {
			if ((this.get('totalOvertimeSeconds') || 0) > 0 || (this.get('totalOvertimeCost') || 0) > 0) {
				return true;
			}
			return false;
		}),

		hasDoubletime: Ember.computed('totalDoubletimeSeconds', 'totalDoubletimeCost', function () {
			if ((this.get('totalDoubletimeSeconds') || 0) > 0 || (this.get('totalDoubletimeCost') || 0) > 0) {
				return true;
			}
			return false;
		}),

		totalOvertimeSeconds: Ember.computed('projectSeconds.@each.totalOvertimeSeconds', 'filterChildren', function () {
			var _this2 = this;

			var seconds = 0;
			if (!Ember.isNone(this.get('projectSeconds'))) {
				this.get('projectSeconds').forEach(function (item) {
					if (_this2.get('filterChildren')) {
						seconds += item.get('overtimeSeconds') || 0;
					} else {
						seconds += item.get('totalOvertimeSeconds');
					}
				});
			}
			return seconds;
		}),

		totalOvertimeTimeString: Ember.computed('totalOvertimeSeconds', function () {
			var totalOtSeconds = _utils.Time.convertSeconds(this.get('totalOvertimeSeconds'));
			return totalOtSeconds.hours + ' hrs ' + totalOtSeconds.minutes + ' mins';
		}),

		totalDoubletimeSeconds: Ember.computed('projectSeconds.@each.totalDoubletimeSeconds', 'filterChildren', function () {
			var _this3 = this;

			var seconds = 0;
			if (!Ember.isNone(this.get('projectSeconds'))) {
				this.get('projectSeconds').forEach(function (item) {
					if (_this3.get('filterChildren')) {
						seconds += item.get('doubletimeSeconds') || 0;
					} else {
						seconds += item.get('totalDoubletimeSeconds');
					}
				});
			}
			return seconds;
		}),

		totalDoubletimeTimeString: Ember.computed('totalDoubletimeSeconds', function () {
			var totalDoubletimeSeconds = _utils.Time.convertSeconds(this.get('totalDoubletimeSeconds'));
			return totalDoubletimeSeconds.hours + ' hrs ' + totalDoubletimeSeconds.minutes + 'mins';
		}),

		totalSeconds: Ember.computed('projectSeconds.@each.totalSeconds', 'filterChildren', function () {
			var _this4 = this;

			var seconds = 0;
			if (!Ember.isNone(this.get('projectSeconds'))) {
				this.get('projectSeconds').forEach(function (item) {
					if (_this4.get('filterChildren')) {
						seconds += (item.get('regularSeconds') || 0) + (item.get('overtimeSeconds') || 0) + (item.get('doubletimeSeconds') || 0);
					} else {
						seconds += item.get('totalTotalSeconds');
					}
				});
			}
			return seconds;
		}),

		totalTimeString: Ember.computed('totalSeconds', function () {
			var totalOverallSeconds = _utils.Time.convertSeconds(this.get('totalSeconds'));
			return totalOverallSeconds.hours + ' hrs ' + totalOverallSeconds.minutes + ' mins';
		}),

		totalRegularCost: Ember.computed('projectCost.@each.totalRegularCost', 'filterChildren', function () {
			var _this5 = this;

			var cost = 0;
			if (!Ember.isNone(this.get('projectCost'))) {
				this.get('projectCost').forEach(function (item) {
					if (_this5.get('filterChildren')) {
						cost += item.get('regularCost') || 0;
					} else {
						cost += item.get('totalRegularCost');
					}
				});
			}
			return cost;
		}),

		totalRegularCostString: Ember.computed('totalRegularCost', function () {
			return this.get('totalRegularCost').toFixed(2);
		}),

		totalOvertimeCost: Ember.computed('projectCost.@each.totalOvertimeCost', 'filterChildren', function () {
			var _this6 = this;

			var cost = 0;
			if (!Ember.isNone(this.get('projectCost'))) {
				this.get('projectCost').forEach(function (item) {
					if (_this6.get('filterChildren')) {
						cost += item.get('overtimeCost') || 0;
					} else {
						cost += item.get('totalOvertimeCost');
					}
				});
			}
			return cost;
		}),

		totalOvertimeCostString: Ember.computed('totalOvertimeCost', function () {
			return this.get('totalOvertimeCost').toFixed(2);
		}),

		totalDoubletimeCost: Ember.computed('projectCost.@each.totalDoubletimeCost', 'filterChildren', function () {
			var _this7 = this;

			var cost = 0;
			if (!Ember.isNone(this.get('projectCost'))) {
				this.get('projectCost').forEach(function (item) {
					if (_this7.get('filterChildren')) {
						cost += item.get('doubletimeCost') || 0;
					} else {
						cost += item.get('totalDoubletimeCost');
					}
				});
			}
			return cost;
		}),

		totalDoubletimeCostString: Ember.computed('totalDoubletimeCost', function () {
			return this.get('totalDoubletimeCost').toFixed(2);
		}),

		totalCost: Ember.computed('projectCost.@each.totalTotalCost', 'filterChildren', function () {
			var _this8 = this;

			var cost = 0;

			if (!Ember.isNone(this.get('projectCost'))) {
				this.get('projectCost').forEach(function (item) {
					if (_this8.get('filterChildren')) {
						cost += (item.get('regularCost') || 0) + (item.get('overtimeCost') || 0) + (item.get('doubletimeCost') || 0);
					} else {
						cost += item.get('totalTotalCost');
					}
				});
			}
			return cost;
		}),

		totalProjectCost: Ember.computed('totalCost', 'totalSeconds', 'memberCost.totalTotalCost', 'memberSeconds.totalTotalSeconds', 'wage.wageRate', function () {
			if (this.get('wage.wageRate') !== 10) {
				var totalSeconds = this.get('memberSeconds.totalTotalSeconds');
				var projectTotalSeconds = this.get('totalSeconds');
				var percent = projectTotalSeconds / totalSeconds;
				return this.get('memberCost.totalTotalCost') * percent;
			} else {
				return this.get('totalCost');
			}
		}),

		totalCostString: Ember.computed('totalProjectCost', function () {
			return this.get('totalProjectCost').toFixed(2);
		}),

		totalOverburdenCost: Ember.computed('totalProjectCost', 'wage.overburden', function () {
			var cost = this.get('totalProjectCost');
			var burden = this.get('wage.overburden') || 0;
			var over = cost * (burden / 100);
			return cost + over;
		}),

		/**
   * Converts the salary to a monthly salary based on the type
   * passed in. Types are 30:weekly, 40:monthly, 50:yearly
   *
   * @public
   * @method convertSalaryType
   * @params salary {int} The salary in dollars
   * @params type {int} The salary type - weekly, monthly, yearly
   * @returns {int} yearly salary
   */
		convertSalaryType: function convertSalaryType(salary, type) {
			if (type === 30) {
				return salary * 52;
			} else if (type === 40) {
				return salary * 12;
			}
			return salary;
		},


		/**
   * Calculates the salary pay for the pay period type.
   * Types are 10:weekly, 20:biweekly, 30:Semimonthly, 40:monthly
   *
   * @public
   * @method calculateSalary
   * @params salary {int} The yearly salary in dollars
   * @params type {int} The pay period type
   * @returns {float} salary per pay-period length
   */
		calculateSalary: function calculateSalary(salary, type) {
			var totalCost = 0;
			if (type === 10) {
				totalCost = salary / 52;
			} else if (type === 20) {
				totalCost = salary / 52 * 2;
			} else if (type === 30) {
				totalCost = salary / 24;
			} else if (type === 40) {
				totalCost = salary / 12;
			}
			return totalCost;
		},


		payPeriodCostTotal: Ember.computed('memberCost.totalTotalCost', 'wage.wageRate', function () {
			if (this.get('wage.wageRate') !== 10) {
				var salary = this.convertSalaryType(this.get('wage.wage'), this.get('wage.wageRate'));
				var payPeriod = this.get('payPeriods').getPayPeriod(this.get('startTime') + 1);
				return this.calculateSalary(salary, payPeriod.get('type'));
			} else {
				return this.get('memberCost.totalTotalCost');
			}
		}),

		payPeriodCostTotalString: Ember.computed('payPeriodCostTotal', function () {
			return this.get('payPeriodCostTotal').toFixed(2);
		})
	});
});