define('busy-app/models/organization-integration', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    integrationName: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    frequency: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),
    organizationId: _emberData.default.attr('string'),
    lastAttemptId: _emberData.default.attr('string'),

    organization: _emberData.default.belongsTo('organization'),
    lastAttempt: _emberData.default.belongsTo('integration-attempt', { inverse: null, referenceKey: 'id', foreignKey: 'lastAttemptId' }),
    integrationAttempts: _emberData.default.hasMany('integration-attempt'),

    getIntegrationAttempt: function getIntegrationAttempt(id) {
      _utils.Assert.funcNumArgs(arguments, 1, true);
      _utils.Assert.isString(id);
      _utils.Assert.isUUID(id);

      return this.get('store').findRecord('integration-attempt', id);
    },
    getLastAttempt: function getLastAttempt() {
      var lastAttemptId = this.get('lastAttemptId');
      return !Ember.isNone(lastAttemptId) ? this.getIntegrationAttempt(lastAttemptId) : Ember.RSVP.resolve(null);
    },


    // setLastAttempt() {
    //   return this.getLastAttempt().then((lastAttempt) => {
    //     return this.set('lastAttempt', lastAttempt);
    //   });
    // },

    queryIntegrationAttempts: function queryIntegrationAttempts() {
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      Ember.deprecate('untested', false, { id: 'organization-integration.queryIntegrationAttempts', until: '5.0.0' });
      var organization_integration_id = this.get('id');
      var mergedQuery = Ember.merge(query, { organization_integration_id: organization_integration_id });
      return this.get('store').query('integration-attempt', mergedQuery);
    }
  });
});