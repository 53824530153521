define('busy-app/controllers/report/time-card', ['exports', '@busy-web/utils', 'busy-app/utils/docs'], function (exports, _utils, _docs) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		queryParams: ['project', 'sort_order'],

		auth: Ember.inject.service(),
		payPeriods: Ember.inject.service('pay-period'),
		proFeature: Ember.inject.service('pro-feature'),

		project: null,
		start: 0,
		end: 0,
		sort_order: 'desc',

		optionSelector: null,
		isCSVLoading: false,
		proFeatureManager: null,
		isCondensed: false,

		init: function init() {
			this._super();
			this.setPeriod();
			this.set('optionSelector', [Ember.Object.create({ key: 'export', value: (0, _utils.loc)('Export') })]);

			this.set('printSelector', [Ember.Object.create({ key: 'print', value: (0, _utils.loc)('Print All') }), Ember.Object.create({ key: 'print-small', value: (0, _utils.loc)('Print All With Time') })]);

			this.set('proFeatureManager', this.get('proFeature').getManager());
		},


		setPeriod: Ember.observer('auth.organizationPayPeriod.@each.id', function () {
			if (!Ember.isEmpty(this.get('auth.organizationPayPeriod')) && this.get('start') === 0) {
				var currentPeriod = this.get('payPeriods').getPayPeriod(_utils.Time.timestamp());
				var previousPeriod = this.get('payPeriods').getPayPeriod(currentPeriod.get('startDate').subtract(1, 'days').unix());

				this.set('start', previousPeriod.get('start'));
				this.set('end', previousPeriod.get('end'));
			}
		}),

		reportTitle: Ember.computed('model.project', function () {
			if (!Ember.isNone(this.get('model.project'))) {
				return (0, _utils.loc)("Project Time Sheet");
			}

			return (0, _utils.loc)("Time Cards");
		}),

		exportReport: function exportReport() {
			var _this = this;

			this.set('isCSVLoading', true);

			var start = this.get('start');
			var end = this.get('end');

			this.store.exportCSV('time-card-report-csv', start, end, null, this.get('model.project')).then(function () {
				_this.set('isCSVLoading', false);
			});
		},


		actions: {
			changeDateRange: function changeDateRange(period) {
				this.set('start', period.get('start'));
				this.set('end', period.get('end'));

				this.transitionToRoute('report.time-card', {
					queryParams: {
						start: period.get('start'),
						end: period.get('end')
					}
				});
				Ember.getOwner(this).lookup('route:report.time-card').refresh();
			},
			optionChange: function optionChange(type) {
				var _this2 = this;

				if (type.get('key') === 'export') {
					this.get('proFeatureManager').requestAction('export', function () {
						return _this2.exportReport();
					});
				}
			},
			printChanged: function printChanged(type) {
				if (type.get('key') === 'print') {
					_docs.default.print(Ember.$('.v-time-card-print-container'));
				} else if (type.get('key') === 'print-small') {
					this.set('isCondensed', true);
					Ember.run.next(this, function () {
						_docs.default.print(Ember.$('.v-time-card-print-container'));
						this.set('isCondensed', false);
					});
				}
			},
			changeSort: function changeSort(sort) {
				this.set('sort_order', sort);

				this.transitionToRoute('report.time-card', {
					queryParams: {
						sort_order: sort
					}
				});
				Ember.getOwner(this).lookup('route:report.time-card').refresh();
			},
			proFeatureManagerOpenModal: function proFeatureManagerOpenModal() {
				this.get('proFeatureManager').openModal();
			},
			proFeatureManagerCloseModal: function proFeatureManagerCloseModal() {
				this.get('proFeatureManager').closeModal();
			},
			proFeatureManagerPlanUpgraded: function proFeatureManagerPlanUpgraded() {
				this.get('proFeatureManager').onAllow();
			}
		}
	});
});