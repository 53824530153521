define('busy-app/routes/report/employee-list', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		auth: Ember.inject.service(),

		model: function model() {
			var query = {
				archived_on: '_-NULL-_',
				_asc: ['first_name', 'last_name']
			};

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				var authMember = Ember.get(this, 'auth.member');

				var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
				    id = _EmberGetProperties.id,
				    memberGroupId = _EmberGetProperties.memberGroupId;

				Ember.setProperties(query, { member_group_id: memberGroupId });

				if (Ember.isNone(memberGroupId)) {
					Ember.setProperties(query, { id: id });
				}
			}

			return Ember.get(this, 'store').findAll('member', query);
		}
	});
});