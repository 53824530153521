define('busy-app/components/subscriptions/landing-page', ['exports', 'busy-app/utils/logger'], function (exports, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),
		subscription: Ember.inject.service('subscription'),

		refreshSubscriptionTabState: null,

		isLoading: false,
		showUpgradeDialog: false,
		showVerifiedEmailDialog: false,

		/**
   * COMPUTEDS & OBSERVERS
   *
   */

		needsEmailVerification: Ember.computed.not('auth.member.verifiedEmail'),

		costPerActiveUserAnnualDollar: Ember.computed('subscription.costPerActiveUserAnnual', function () {
			return !Ember.isNone(this.get('subscription.costPerActiveUserAnnual')) ? this.getDollars(this.get('subscription.costPerActiveUserAnnual')) : null;
		}),

		costPerActiveUserAnnualCents: Ember.computed('subscription.costPerActiveUserAnnual', function () {
			return !Ember.isNone(this.get('subscription.costPerActiveUserAnnual')) ? this.getCents(this.get('subscription.costPerActiveUserAnnual')) : null;
		}),

		/**
   * COMPONENT INITIALIZATION
   *
   */

		/**
   * @public
   * @method init
   * @constructor
   */
		init: function init() {
			this._super();

			this.loadData();
		},


		/**
   * @public
   * @method initData
   */
		loadData: function loadData() {
			var _this = this;

			this.set('isLoading', true);

			return Ember.RSVP.resolve(this.get('subscription.subscriptionPricing')).catch(function (err) {
				return _logger.default.error(_this, err);
			}).finally(function () {
				return _this.set('isLoading', false);
			});
		},


		/**
   * UTILITY
   *
   */

		/**
   * @public
   * @method getDollars
   * @return {number}
   */
		getDollars: function getDollars(amount) {
			return Math.floor(amount);
		},


		/**
  * @public
  * @method getCents
  * @return {number}
  */
		getCents: function getCents(amount) {
			return Math.floor((amount - this.getDollars(amount)) * 100);
		},


		actions: {
			/**
    * @public
    * @event openVerifiedEmailDialog
    */
			openVerifiedEmailDialog: function openVerifiedEmailDialog() {
				this.set('showVerifiedEmailDialog', true);
			},


			/**
    * @public
    * @event closeVerifiedEmailDialog
    */
			closeVerifiedEmailDialog: function closeVerifiedEmailDialog() {
				this.set('showVerifiedEmailDialog', false);
			},


			/**
    * @public
    * @event openUpgradeDialog
    */
			openUpgradeDialog: function openUpgradeDialog() {
				if (this.get('needsEmailVerification')) {
					this.send('openVerifiedEmailDialog');
				} else {
					this.set('showUpgradeDialog', true);
				}
			},


			/**
    * @public
    * @event closeUpgradeDialog
    */
			closeUpgradeDialog: function closeUpgradeDialog() {
				if (!this.get('isDestroyed')) {
					this.sendAction('refreshSubscriptionTabState');

					this.set('showUpgradeDialog', false);
				}
			},


			/**
    * @public
    * @event openAccountSettings
    */
			openAccountSettings: function openAccountSettings() {
				Ember.getOwner(this).lookup('route:application').transitionTo('account-settings');
			}
		}
	});
});