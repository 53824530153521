define('busy-app/components/employees/reset-pass-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['v-employees-employee-dialog-reset-pass'],

    session: Ember.inject.service('session'),
    auth: Ember.inject.service('auth'),
    dataStore: Ember.inject.service('data-store'),

    confirmPass: null,
    member: null,
    passMismatch: false,

    passwordRequirement: Ember.computed('member.password', function () {
      if (this.get('member.password.length') < 8) {
        return 'Password must be at least 8 characters';
      }
      return;
    }),

    confirmPassword: function confirmPassword(password) {
      var confirm = this.get('confirmPass');
      if (password === confirm) {
        return true;
      }
      return false;
    },
    authenticateSession: function authenticateSession(username, password) {
      return this.get('session').authenticate('authenticator:basic', username, password);
    },


    actions: {
      close: function close() {
        this.sendAction('onClose');
      },
      save: function save() {
        var _this = this;

        var password = this.get('member.password');
        if (password.length >= 8) {
          if (this.confirmPassword(password)) {
            var member = this.get('member');
            member.set('password', password);
            return member.save().then(function () {
              if (_this.get('auth.member.id') === _this.get('member.id')) {
                return _this.authenticateSession(_this.get('member.username'), _this.get('member.password'));
              }
            });
          } else {
            return Ember.RSVP.reject((0, _utils.loc)('Passwords do not match'));
          }
        } else {
          return Ember.RSVP.reject((0, _utils.loc)('Password must be at least 8 Characters'));
        }
      }
    }
  });
});