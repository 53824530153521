define('busy-app/utils/web-worker', ['exports', 'busy-app/utils/settings', 'busy-app/utils/data-base'], function (exports, _settings, _dataBase) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = webWorker;


	var Listener = Ember.Object.extend(Ember.Evented, {
		message: function message(event) {
			this.trigger('onMessage', event);
		}
	});

	function webWorker(token, memberId, organizationId) {
		var opts = [{
			db: _dataBase.default,
			auth: { memberId: memberId, organizationId: organizationId },
			debug: _settings.default.get('debug_mode'),
			api: {
				host: _settings.default.get('api_url'),
				version: _settings.default.get('api_version'),
				headers: { "Key-Authorization": token }
			}
		}];

		var worker = new Worker('/workers/data-sync.js');
		var listener = Listener.create({ worker: worker });

		worker.onmessage = function (event) {
			listener.message(event);
		};

		worker.postMessage(opts);

		return listener;
	}
});