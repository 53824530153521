define('busy-app/utils/event', ['exports', '@busy-web/components/utils/event'], function (exports, _event) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'bind', {
    enumerable: true,
    get: function () {
      return _event.bind;
    }
  });
  Object.defineProperty(exports, 'unbind', {
    enumerable: true,
    get: function () {
      return _event.unbind;
    }
  });
});