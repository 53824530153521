define('busy-app/components/window-resize', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',

		eventEmitter: Ember.inject.service('event-emitter'),

		setupResize: function setupResize() {
			Ember.get(this, 'eventEmitter').on('dom', 'resize', this.resizeHandler, this);
		},
		destroyResize: function destroyResize() {
			Ember.get(this, 'eventEmitter').off('dom', 'resize', this.resizeHandler, this);
		},
		resizeHandler: function resizeHandler(event, width, height) {
			this.sendAction('onChange', event, width, height);
		},
		didInsertElement: function didInsertElement() {
			this._super();

			this.setupResize();
		},
		didDestroyElement: function didDestroyElement() {
			this._super();

			this.destroyResize();
		}
	});
});