define('busy-app/models/member-project-equipment-seconds', ['exports', 'ember-data', 'busy-app/utils/unique-id', 'busy-app/models/member-project-seconds'], function (exports, _emberData, _uniqueId, _memberProjectSeconds) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _memberProjectSeconds.default.extend({
		// used to match seconds and cost models
		uid: (0, _uniqueId.default)('startDate', 'endDate', 'memberId', 'projectId', 'equipmentId'),

		// override attrs
		equipmentId: _emberData.default.attr('id', { sortable: true, sortBy: ['equipment.equipmentName'] }),

		// override model injections
		equipment: _emberData.default.belongsTo('equipment', { query: { deleted_on: '_-DISABLE-_' } }),

		/**
   * override computed properties
   */
		showChildren: false,

		totalSecondsPlusChildren: Ember.computed('totalSeconds', 'childrenTotalSeconds', function () {
			return this.get('totalSeconds') + this.get('childrenTotalSeconds');
		}),

		isTopLevel: Ember.computed('projectId', '_project.id', function () {
			if (this.get('project.isTopLevelProject')) {
				return true;
			} else {
				return false;
			}
		}),

		projectTitle: Ember.computed('project', function () {
			return this.get('project.title');
		}),

		equipmentTitle: Ember.computed('equipment', function () {
			return this.get('equipment.equipmentName');
		})
	});
});