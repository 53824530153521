define("busy-app/routes/project/detail/reports/employees", ["exports", "@busy-web/utils", "busy-app/utils/collection"], function (exports, _utils, _collection) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		auth: Ember.inject.service(),

		queryParams: {
			custom: {
				replace: true
			},
			start: {
				replace: true
			},
			end: {
				replace: true
			}
		},

		model: function model(params) {
			var _this = this;

			var project = this.modelFor("project.detail");
			var isCustomDate = params.custom === true;
			var start = isCustomDate ? params.start : _utils.Time.date(project.project.get("createdOn")).startOf("day").subtract(10, "years").unix();
			var end = isCustomDate ? params.end : _utils.Time.timestamp();
			var useGroupPermissionScoping = Ember.get(this, "auth.useGroupPermissionScoping");

			var query = {
				start_date: start,
				end_date: end,
				project_id: project.project.id,
				limit: 1000
			};

			return Ember.RSVP.hash({
				groupMembers: this.loadGroupMembers(useGroupPermissionScoping),
				memberSeconds: this.store.findAll("member-project-seconds", query),
				memberCost: this.store.findAll("member-project-cost", query)
			}).then(function (data) {
				var memberCostDictionary = (0, _collection.toDictionary)(data.memberCost, "uid");

				var memberSeconds = data.memberSeconds.filter(function (item) {
					return Ember.get(item, "totalTotalSeconds") > 0;
				});
				var memberCost = data.memberCost;

				if (useGroupPermissionScoping) {
					var groupMemberIds = new Set(data.groupMembers.mapBy("id"));

					memberSeconds = memberSeconds.filter(function (item) {
						return groupMemberIds.has(Ember.get(item, "memberId"));
					});
					memberCost = memberCost.filter(function (item) {
						return groupMemberIds.has(Ember.get(item, "memberId"));
					});
				}

				var seconds = memberSeconds.map(function (item) {
					Ember.setProperties(item, {
						cost: memberCostDictionary.get(Ember.get(item, "uid"))
					});

					return item;
				});

				var totalTotalSeconds = (0, _collection.sumBy)(memberSeconds, "totalTotalSeconds");
				var totalTotalOverburdenCost = (0, _collection.sumBy)(memberCost, "totalTotalOverburdenCost");

				return Ember.RSVP.hash({
					members: _this.store.fetchContainer(seconds, { skipProjectData: true }, "member").then(function (d) {
						return d.member;
					}),
					totalTotalSeconds: totalTotalSeconds,
					totalTotalOverburdenCost: totalTotalOverburdenCost
				});
			});
		},
		loadGroupMembers: function loadGroupMembers(useGroupPermissionScoping) {
			if (!useGroupPermissionScoping) {
				return Ember.RSVP.resolve();
			}
			var authMember = Ember.get(this, "auth.member");
			var memberGroupId = Ember.get(authMember, "memberGroupId");

			if (Ember.isNone(memberGroupId)) {
				return Ember.RSVP.resolve([authMember]);
			} else {
				return this.store.findAll("member", {
					member_group_id: Ember.get(authMember, "memberGroupId")
				});
			}
		},
		renderTemplate: function renderTemplate(controller) {
			var project = this.modelFor("project.detail");
			var reportController = this.controllerFor("project.detail.reports");
			reportController.set("reportTitle", (0, _utils.loc)("Employees"));
			controller.set("project", project.project);
			this.render("project.detail.reports.employees");
		},


		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			}
		}
	});
});