define('busy-app/helpers/format-currency', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		/**
   * Ember injected service for auth
   *
   * @private
   * @property auth
   * @type Services.Auth
   */
		auth: Ember.inject.service('auth'),

		/**
   * Conveinence Check to see if the browser is safari.
   *
   * @private
   * @property isSafari
   * @type boolean
   */
		isSafari: Ember.computed(function () {
			// check if the broswer has a safari user agent returns true if it does.
			return (/safari/.test(window.navigator.userAgent.toLowerCase()) && !/chrome/.test(window.navigator.userAgent.toLowerCase())
			);
		}),

		/**
   * Computes the output for the helper
   *
   * @private
   * @method compute
   * @param value {number} The number to convert to a currency
   * @returns {string} The number with 2 decimals and a currency symbol.
   */
		compute: function compute(params) {
			// allow only one argument in the params.
			_utils.Assert.funcNumArgs(params, 1, true);

			// get the value from the arguments
			var value = parseFloat(params[0] || 0);
			var lang = window.navigator.language;
			if (window.navigator.languages) {
				lang = window.navigator.languages[0];
			}

			// assert the value is of type number and is not NaN
			_utils.Assert.isNumber(value);
			if (isNaN(value)) {
				return params[0];
			}

			if (!Ember.isNone(this.get('auth.organizationInfo.currencyCode')) && window.Intl && typeof window.Intl.NumberFormat === 'function') {
				var code = this.get('auth.organizationInfo.currencyCode') || 'USD';
				var cur = _utils.Currency.code(code);

				var nt = new window.Intl.NumberFormat(lang, {
					style: 'currency',
					currency: cur.get('code'),
					minimumFractionDigits: cur.get('digits'),
					maximumFractionDigits: cur.get('digits')
				});

				return nt.format(value);
			} else {
				return '$' + value.toFixed(2);
			}
		},

		/**
   * Ember Observer method for recalculating the value.
   *
   * @private
   * @method rerender
   */
		rerender: Ember.observer('auth.currencySymbol', function () {
			this.recompute();
		})
	});
});