define('busy-app/animations/employee', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = timecard;
	function timecard(durationTo, durationBack) {
		//time card transitions
		var animate = ['employees.employee', 'employees.locations'];

		this.transition(this.toRoute(animate), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));

		//employees locations transitions
		//	this.transition(
		//		this.toRoute('employees.locations'),
		//		this.fromRoute('employees.employee'),
		//		this.use('toLeft', {duration: durationTo}),
		//		this.reverse('toRight', {duration: durationBack})
		//	);
	}
});