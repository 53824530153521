define('busy-app/utils/models/collections/time-entry-project', ['exports', '@busy-web/utils', 'busy-app/utils/models/collections/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		id: null,
		project: null,

		/**
   * Takes a project model and time-entries with segments nested
   *
   */
		applyData: function applyData(model, projectId, project) {
			Ember.set(this, 'id', projectId);
			Ember.set(this, 'project', project);
			this._super(model);
		},


		regularSeconds: Ember.computed('model.@each.regularSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					//if item is a time off entry, add seconds
					//if item is time entry, add regular seconds
					if (!Ember.isNone(item.dateStamp)) {
						total += item.get('model.seconds');
					} else {
						total += item.get('regularSeconds');
					}
				});
			}
			return total;
		}),

		overtimeSeconds: Ember.computed('model.@each.overtimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('overtimeSeconds');
				});
			}
			return total;
		}),

		doubletimeSeconds: Ember.computed('model.@each.doubletimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('doubletimeSeconds');
				});
			}
			return total;
		}),

		totalSeconds: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}),

		regularTimeString: Ember.computed('regularSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('regularSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		overtimeTimeString: Ember.computed('overtimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('overtimeSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		doubletimeTimeString: Ember.computed('doubletimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('doubletimeSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		totalTimeString: Ember.computed('totalSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('totalSeconds'), true);
			return time.hours + ':' + time.minutes;
		})
	});
});