define('busy-app/utils/logger', ['exports', '@busy-web/utils', 'busy-app/utils/debug'], function (exports, _utils, _debug) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.print = print;

	function _classCallCheck(instance, Constructor) {
		if (!(instance instanceof Constructor)) {
			throw new TypeError("Cannot call a class as a function");
		}
	}

	var _createClass = function () {
		function defineProperties(target, props) {
			for (var i = 0; i < props.length; i++) {
				var descriptor = props[i];
				descriptor.enumerable = descriptor.enumerable || false;
				descriptor.configurable = true;
				if ("value" in descriptor) descriptor.writable = true;
				Object.defineProperty(target, descriptor.key, descriptor);
			}
		}

		return function (Constructor, protoProps, staticProps) {
			if (protoProps) defineProperties(Constructor.prototype, protoProps);
			if (staticProps) defineProperties(Constructor, staticProps);
			return Constructor;
		};
	}();

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var logSymbol = '•'; // bullet symbol do not erase

	function className(_class) {
		var name = '';
		if (!Ember.isNone(_class) && Ember.typeOf(_class) === 'instance') {
			// get instance name
			name = '<' + _class.toString().replace(/^[^@]*@([^:]*):([^:]*)::[\s\S]*$/, '$1:$2') + '>';
		}
		return name;
	}

	function print(args) {
		if (window.console.log) {
			var _window$console;

			(_window$console = window.console).log.apply(_window$console, _toConsumableArray(args));
		}
	}

	function startGroupCollapsed(_class, args) {
		var color = 'color:cornflowerblue;';

		if (window.console.groupCollapsed) {
			var _window$console2;

			(_window$console2 = window.console).groupCollapsed.apply(_window$console2, _toConsumableArray(addType(logSymbol, color, _class, args)));
		} else if (window.console.group) {
			var _window$console3;

			(_window$console3 = window.console).group.apply(_window$console3, _toConsumableArray(addType(logSymbol, color, _class, args)));
		} else {
			var view = className(_class);
			print(['%c\n--------------- BLOCK ' + view + ' ------------\n\n', color]);
		}
	}

	function startGroup(_class, args) {
		var color = 'color:cornflowerblue;';

		if (window.console.group) {
			var _window$console4;

			(_window$console4 = window.console).group.apply(_window$console4, _toConsumableArray(addType(logSymbol, color, _class, args)));
		} else {
			var view = className(_class);
			print(['%c\n--------------- BLOCK ' + view + ' ------------\n\n', color]);
		}
	}

	function endGroup() {
		var color = 'color:darkgoldenrod;';
		if (window.console.groupEnd) {
			window.console.groupEnd();
		} else {
			print(['%c\n--------------- BLOCK END ------------\n\n', color]);
		}
	}

	/**
 	* Get the name from the instance if its the first param
 	* otherwise just add the first param to the args list
 	*
 	* @private
 	* @method addType
 	* @param type {string}
 	* @param typeColor {string}
 	* @param view {mixed}
 	* @param args {array}
 	*/
	function addType(type, typeColor, view, args) {
		var _class = void 0;
		if (Ember.typeOf(view) === 'instance') {
			// get instance name
			_class = className(view);
			args.unshift(_class);
		} else {
			// object has no name so just add it to list
			args.unshift(view);
		}

		if (/^\t$/.test(args[0])) {
			var tab = args.shift(0);
			type = tab + type;
		}

		// add type and name string if name is set
		args.unshift(type);

		var newArgs = [];
		var argString = '';
		var dateFormat = 'MMM. Do, YYYY HH:mm:ss';
		args.forEach(function (item) {
			if (item === type) {
				argString += "%c%s";
				newArgs.push(typeColor + 'font-size:15px;');
				newArgs.push(item);
			} else if (item === _class) {
				argString += " %c%s";
				newArgs.push('color:darkgoldenrod');
				newArgs.push(item);
			} else if (typeof item === 'string') {
				if (/^unix:/.test(item)) {
					argString += ' %c%s';

					var _item$split = item.split(':'),
					    _item$split2 = _slicedToArray(_item$split, 3),
					    time = _item$split2[1],
					    format = _item$split2[2];

					format = format || dateFormat;
					newArgs.push('color:cornflowerblue;');
					newArgs.push(_utils.Time.date(parseInt(time, 10)).format(format));
				} else if (/^mili:/.test(item)) {
					argString += ' %c%s';

					var _item$split3 = item.split(':'),
					    _item$split4 = _slicedToArray(_item$split3, 3),
					    _time = _item$split4[1],
					    _format = _item$split4[2];

					_format = _format || dateFormat;
					newArgs.push('color:cornflowerblue;');
					newArgs.push(_utils.Time.date(parseInt(_time, 10) / 1000).format(_format));
				} else {
					argString += ' %c%s';
					newArgs.push('color:grey;');
					newArgs.push(item);
					_utils.Time.date;
				}
			} else if (typeof item === 'number') {
				argString += ' %c%d';
				newArgs.push('color:indianred');
				newArgs.push(item);
			} else if ((typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object' && item !== null) {
				if (item.__isMoment) {
					argString += ' %c%s';
					newArgs.push('color:cornflowerblue;font-style:italic;');
					newArgs.push(item.format(item.__format));
				} else {
					argString += ' %O';
					newArgs.push(item);
				}
			} else {
				argString += ' %s';
				newArgs.push(item);
			}
		});

		argString = argString.replace(/^ /, '');
		newArgs.unshift(argString);
		return newArgs;
	}

	/**
  * @class Logger
  * @namespace Utils
  */

	var Logger = function () {
		function Logger() {
			_classCallCheck(this, Logger);
		}

		_createClass(Logger, null, [{
			key: 'log',
			value: function log() {
				for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
					args[_key] = arguments[_key];
				}

				(0, _debug.debug_mode)(function () {
					var arg1 = args.shift();
					print(addType(logSymbol, 'color:grey;', arg1, args));
				});
			}
		}, {
			key: 'info',
			value: function info(view) {
				for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
					args[_key2 - 1] = arguments[_key2];
				}

				(0, _debug.log_level)(3, function () {
					return print(addType(logSymbol, 'color:limegreen;', view, args));
				});
			}
		}, {
			key: 'warn',
			value: function warn(view) {
				for (var _len3 = arguments.length, args = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
					args[_key3 - 1] = arguments[_key3];
				}

				(0, _debug.log_level)(2, function () {
					return print(addType(logSymbol, 'color:gold;', view, args));
				});
			}
		}, {
			key: 'error',
			value: function error(view) {
				for (var _len4 = arguments.length, args = Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
					args[_key4 - 1] = arguments[_key4];
				}

				(0, _debug.log_level)(1, function () {
					return print(addType(logSymbol, 'color:crimson;', view, args));
				});
			}
		}, {
			key: 'date',
			value: function date(view, timestamp) {
				var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
				var unix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

				if (typeof view === 'number') {
					if (typeof format === 'boolean') {
						unix = format;
					}

					if (typeof timestamp === 'string') {
						format = timestamp;
					}

					timestamp = view;
					view = null;
				}

				var str = 'unix:';
				if (!unix) {
					str = 'mili:';
				}

				str += timestamp;

				if (!Ember.isEmpty(format)) {
					str += ':' + format;
				}

				var args = [str];
				if (view) {
					args.unshift(view);
				}

				this.log.apply(this, args);
			}
		}, {
			key: 'table',
			value: function table(view) {
				for (var _len5 = arguments.length, args = Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
					args[_key5 - 1] = arguments[_key5];
				}

				(0, _debug.debug_mode)(function () {
					var tb = args.pop();
					if (!tb) {
						tb = view;
						view = null;
					}

					if (view) {
						print(addType(logSymbol, 'color:cornflowerblue;', view, args));
					}
					window.console.table(tb);
				});
			}
		}, {
			key: 'block',
			value: function block(view) {
				for (var _len6 = arguments.length, args = Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
					args[_key6 - 1] = arguments[_key6];
				}

				(0, _debug.debug_mode)(function () {
					return startGroup(view, args);
				});
				return createLoggerGroup(this);
			}
		}, {
			key: 'blockCollapsed',
			value: function blockCollapsed(view) {
				for (var _len7 = arguments.length, args = Array(_len7 > 1 ? _len7 - 1 : 0), _key7 = 1; _key7 < _len7; _key7++) {
					args[_key7 - 1] = arguments[_key7];
				}

				(0, _debug.debug_mode)(function () {
					return startGroupCollapsed(view, args);
				});
				return createLoggerGroup(this);
			}
		}]);

		return Logger;
	}();

	var createLoggerGroup = function createLoggerGroup(target) {
		var group = {};

		var logType = function logType(type, args) {
			if (!window.console.group) {
				args.unshift("\t");
			}
			target[type].apply(target, args);
			return group;
		};

		group.log = function () {
			for (var _len8 = arguments.length, args = Array(_len8), _key8 = 0; _key8 < _len8; _key8++) {
				args[_key8] = arguments[_key8];
			}

			return (0, _debug.debug_mode)(function () {
				return logType('log', args);
			}, group);
		};
		group.info = function () {
			for (var _len9 = arguments.length, args = Array(_len9), _key9 = 0; _key9 < _len9; _key9++) {
				args[_key9] = arguments[_key9];
			}

			return (0, _debug.debug_mode)(function () {
				return logType('info', args);
			}, group);
		};
		group.warn = function () {
			for (var _len10 = arguments.length, args = Array(_len10), _key10 = 0; _key10 < _len10; _key10++) {
				args[_key10] = arguments[_key10];
			}

			return (0, _debug.debug_mode)(function () {
				return logType('warn', args);
			}, group);
		};
		group.error = function () {
			for (var _len11 = arguments.length, args = Array(_len11), _key11 = 0; _key11 < _len11; _key11++) {
				args[_key11] = arguments[_key11];
			}

			return (0, _debug.debug_mode)(function () {
				return logType('error', args);
			}, group);
		};
		group.date = function () {
			for (var _len12 = arguments.length, args = Array(_len12), _key12 = 0; _key12 < _len12; _key12++) {
				args[_key12] = arguments[_key12];
			}

			return (0, _debug.debug_mode)(function () {
				return logType('date', args);
			}, group);
		};
		group.table = function () {
			for (var _len13 = arguments.length, args = Array(_len13), _key13 = 0; _key13 < _len13; _key13++) {
				args[_key13] = arguments[_key13];
			}

			return (0, _debug.debug_mode)(function () {
				return logType('table', args);
			}, group);
		};
		group.end = function () {
			return (0, _debug.debug_mode)(function () {
				return endGroup();
			});
		};

		return group;
	};

	// convenience method for access to logger
	// for console utils
	window.busyconsole = Logger;

	exports.default = Logger;
});