define('busy-app/utils/models/managers/time-off-report', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		access: Ember.inject.service('access'),
		auth: Ember.inject.service('auth'),
		start: null,
		end: null,

		getSorted: function getSorted() {
			return this.get('model').sortBy('start').reverse();
		},
		fetch: function fetch(start, end) {
			var _this = this;

			this.set('start', start);
			this.set('end', end);

			var query = {
				_gte: { date_stamp: start },
				_lte: { date_stamp: end },
				_asc: ['date_stamp']
			};

			var membersQuery = {};
			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				var authMember = Ember.get(this, 'auth.member');

				var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
				    id = _EmberGetProperties.id,
				    memberGroupId = _EmberGetProperties.memberGroupId;

				Ember.setProperties(membersQuery, { member_group_id: memberGroupId });

				if (Ember.isNone(memberGroupId)) {
					Ember.setProperties(membersQuery, { id: id });
				}
			}

			return this.store.findAll('time-off', query).then(function (timeOff) {
				var memberIds = [];
				timeOff.forEach(function (entry) {
					if (memberIds.indexOf(entry.get('memberId')) === -1) {
						memberIds.push(entry.get('memberId'));
					}
				});

				_this.set('entries', timeOff);

				return Ember.RSVP.hash({
					members: _this.store.findByIds('member', memberIds, membersQuery),
					entries: timeOff,
					start: start,
					end: end
				});
			});
		},
		buildCollections: function buildCollections(data) {
			var _this2 = this;

			data.members.forEach(function (member) {
				var memberEntries = data.entries.filterBy('memberId', member.id);
				var access = _this2.get('access');
				if (access.canTakeActionFor('manageTimeEntries', member)) {
					if (memberEntries.get('length') > 0) {
						_this2.addCollection(memberEntries, member);
					}
				}
			});

			this.set('model', this.get('model').sortBy('member.fullName'));
		},


		dateRangeString: Ember.computed('start', 'end', function () {
			var date = '';
			if (!Ember.isNone(this.get('start')) && !Ember.isNone(this.get('end'))) {
				date = _utils.Time.timeFormat(this.get('start'), 'MMM D') + ' - ' + _utils.Time.timeFormat(this.get('end'), 'MMM D, YYYY');
			}
			return date;
		}),

		totalPaidTime: Ember.computed('model.@each.totalSeconds', function () {
			return this.get('model').mapBy('totalSeconds').reduce(function (a, b) {
				return a + b;
			});
		}),

		totalDaysUnpaid: Ember.computed('module.@each.totalDaysUnpaid', function () {
			return this.get('model').mapBy('totalDaysUnpaid').reduce(function (a, b) {
				return a + b;
			});
		})
	});
});