define('busy-app/components/subscriptions/edit-billing-address', ['exports', '@busy-web/utils', 'busy-app/utils/geo', 'busy-app/utils/logger'], function (exports, _utils, _geo, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		subscription: Ember.inject.service(),

		isLoading: false,
		countryOptions: null,
		stateOptions: null,

		billingDetails: null,
		address: null,
		address2: null,
		country: null,
		state: null,
		city: null,
		postalCode: null,
		phone: null,

		isAddressInvalid: false,
		isCountryInvalid: false,
		isCityInvalid: false,
		isStateInvalid: false,
		isPostalCodeInvalid: false,
		isPhoneInvalid: false,

		addressInvalid: (0, _utils.loc)('Required Field'),
		countryInvalid: (0, _utils.loc)('Required Field'),
		cityInvalid: (0, _utils.loc)('Required Field'),
		stateInvalid: (0, _utils.loc)('Required Field'),
		postalCodeInvalid: (0, _utils.loc)('Required Field'),
		phoneInvalid: (0, _utils.loc)('Required Field'),

		/**
   * COMPUTEDS & OBSERVERS
   *
   */

		isCountryUS: Ember.computed.equal('country', 'US'),
		isPhoneRequired: Ember.computed.alias('subscription.subscriptionStatus.isAnnualPlan'),

		hasDataChanged: Ember.computed('billingDetails', 'address', 'address2', 'country', 'state', 'city', 'postalCode', 'phone', function () {
			var _this = this;

			var billingDetails = this.get('billingDetails');

			if (Ember.isNone(billingDetails) || Ember.isEmpty(billingDetails)) {
				return false;
			}

			return ['address', 'address2', 'country', 'state', 'city', 'postalCode', 'phone'].reduce(function (result, key) {
				return result || _this.get(key) !== billingDetails.get(key);
			}, false);
		}),

		/**
   * COMPONENT INITIALIZATION
   *
   */

		/**
   * @public
   * @method init
   * @constructor
   */
		init: function init() {
			this._super();

			this.setupCountryOptions();
			this.setupStateOptions();

			// copy properties of billingDetails out to this component for editing (address, city, state, etc...)
			this.setProperties(this.get('billingDetails'));
		},


		/**
   * FORM INITIALIZATION
   *
   */

		/**
   * Retrieve and format country data to populate country selection.
   * US is pushed to the top of the list for convenience.
   *
   * @public
   * @method setupCountryOptions
   */
		setupCountryOptions: function setupCountryOptions() {
			var countries = (0, _geo.getCountries)();
			var countryOptions = countries.map(function (country) {
				return {
					key: country.code,
					label: country.name
				};
			});

			// pull out US and re-insert at top of collection
			var optionUS = countryOptions.findBy('key', 'US');
			countryOptions = countryOptions.rejectBy('key', 'US');
			countryOptions.unshift(optionUS);

			this.set('countryOptions', countryOptions);
		},


		/**
   * retrieve and format state data to populate state selection
   *
   * @public
   * @method setupCountryOptions
   */
		setupStateOptions: function setupStateOptions() {
			var states = (0, _geo.getStates)();
			var stateOptions = states.map(function (state) {
				return {
					key: state.code,
					label: state.name
				};
			});

			this.set('stateOptions', stateOptions);
		},


		/**
   * FORM DATA PROCESSING
   *
   */

		/**
   * Checks if any data has changed.
   * Checks if the form data is valid.
   * Sends the data to the API.
   *
   * @public
   * @async
   * @method onSaveForm
   * @return {EmberPromise}
   */
		onSaveForm: function onSaveForm() {
			var _this2 = this;

			if (!this.get('hasDataChanged')) {
				throw (0, _utils.loc)('There are no changes to save!');
			} else if (this.isFormInvalid()) {
				throw (0, _utils.loc)('Missing required fields...');
			}

			return this.updateBillingAddress().catch(function (err) {
				_logger.default.error(_this2, 'onSaveForm', err);

				throw (0, _utils.loc)('Something went wrong. Please try again later.');
			});
		},


		/**
   * Ensure required fields are present.
   * Mark invalid input in the UI.
   *
   * @public
   * @method isFormInvalid
   * @return {boolean}
   */
		isFormInvalid: function isFormInvalid() {
			var _this3 = this;

			var isInvalid = false;

			var requiredFields = ['address', 'country', 'state', 'city', 'postalCode'];

			if (this.get('isPhoneRequired')) {
				requiredFields.push('phone');
			}

			requiredFields.forEach(function (key) {
				var value = _this3.get(key);
				_this3.set(('is-' + key + '-invalid').camelize(), false);

				if (Ember.isEmpty(value)) {
					isInvalid = true;
					_this3.set(('is-' + key + '-invalid').camelize(), true);
				}
			});

			return isInvalid;
		},


		/**
   * Copy form data to a new object and send it to the API.
   *
   * @public
   * @async
   * @method updateBillingAddress
   * @return {EmberPromise}
   */
		updateBillingAddress: function updateBillingAddress() {
			var newBillingAddress = Ember.Object.create({
				address: this.get('address'),
				address2: this.get('address2'),
				city: this.get('city'),
				state: this.get('state'),
				postalCode: this.get('postalCode'),
				country: this.get('country'),
				phone: this.get('phone')
			});

			return this.get('subscription').updateBillingAddress(newBillingAddress);
		},


		/**
   * UTILITY
   *
   */

		/**
   * retrieve value selected from DOM event
   *
   * @public
   * @method getEventValue
   */
		getEventValue: function getEventValue(evt) {
			return evt.target ? evt.target.value : evt.srcElement.value;
		},


		/**
   * ACTION HANDLERS
   *
   */

		/**
  	 * store selected country, clear state and postal code
  	 *
  	 * @public
  	 * @method onSelectCountry
  	 */
		onSelectCountry: function onSelectCountry(val) {
			if (!this.get('isDestroyed')) {
				this.setProperties({
					country: val,
					state: null,
					postalCode: null
				});
			}
		},


		/**
  * store selected state
  *
  * @public
  * @method onSelectState
  */
		onSelectState: function onSelectState(val) {
			if (!this.get('isDestroyed')) {
				this.set('state', val);
			}
		},


		actions: {
			/**
    * @public
    * @event onClose
    */
			onClose: function onClose() {
				this.sendAction('onClose');
			},


			/**
    * @public
    * @event saveForm
    */
			saveForm: function saveForm() {
				var _this4 = this;

				return this.onSaveForm().then(function () {
					return _this4.sendAction('onUpdate');
				});
			},


			/**
    * @public
    * @event onSelectCountry
    * @param evt {object} DOM event
    */
			onSelectCountry: function onSelectCountry(evt) {
				this.onSelectCountry(this.getEventValue(evt));
				evt.target.blur();
			},


			/**
    * @public
    * @event onSelectState
    * @param evt {object} DOM event
    */
			onSelectState: function onSelectState(evt) {
				this.onSelectState(this.getEventValue(evt));
				evt.target.blur();
			}
		}
	});
});