define('busy-app/models/segment-seconds', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    segmentId: _emberData.default.attr('string'),
    regularSeconds: _emberData.default.attr('number'),
    overtimeSeconds: _emberData.default.attr('number'),
    doubletimeSeconds: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),

    segment: _emberData.default.belongsTo('segment', { inverse: null })
  });
});