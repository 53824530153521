define('busy-app/components/company/profile-info', ['exports', '@busy-web/utils', 'busy-app/utils/settings'], function (exports, _utils, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-company-profile-info'],

		store: Ember.inject.service('store'),
		subscription: Ember.inject.service('subscription'),

		/**
   * Hides/Shows the profile info dialog
   *
   * @property showDialog
   * @type boolean
   */
		showDialog: false,

		/**
   * Hides/Shows the photo dialog
   *
   * @property showPhotoDialog
   * @type boolean
   */
		showPhotoDialog: false,

		/**
   * organization model instance
   *
   * @property organization
   * @type DS.Model
   */
		organization: null,

		/**
   * deactivate account dialog toggle
   *
   * @property showDeactivate
   * @type boolean
   */
		showDeactivate: false,

		/**
   * Saves the Organization model
   *
   * @public
   * @method saveOrganization
   * @returns {EmberPromise}
   */
		currencyCodeLabel: null,

		init: function init() {
			this._super();
			this.setCurrency();
		},
		setCurrency: function setCurrency() {
			var _this = this;

			var exclude = ['XTS', 'XXX', 'XDR'];
			if (_settings.default.get('debug_mode')) {
				exclude = [];
			}

			var org = this.get('organization.organizationInfo');
			var currencyOptions = [];
			_utils.Currency.codes().forEach(function (item) {
				var cur = _utils.Currency.code(item);
				var currencyString = cur.get('currency') + ' - ' + cur.get('code');
				if (exclude.indexOf(item) < 0) {
					currencyOptions.pushObject(Ember.Object.create({ key: cur.get('code'), value: currencyString, symbol: cur.get('code'), name: cur.get('currency') }));
				}

				if (org.get('currencyCode') === item) {
					_this.set('currencyCodeLabel', currencyString);
				}
			});
			this.set('currencyOptions', currencyOptions.sortBy('name'));
		},
		saveOrganization: function saveOrganization() {
			var organization = this.get('organization');

			if (Ember.isEmpty(organization.get('organizationName'))) {
				return Ember.RSVP.reject((0, _utils.loc)('Organization Name is required'));
			}

			if (organization.get('hasDirtyAttributes')) {
				return organization.save();
			} else {
				return Ember.RSVP.resolve(organization);
			}
		},


		/**
   * Saves the OrganizationProfile model
   *
   * @public
   * @method saveOrganizationProfile
   * @return {EmberPromise}
   */
		saveOrganizationProfile: function saveOrganizationProfile() {
			var profile = this.get('organization.organizationInfo');

			if (profile.get('hasDirtyAttributes')) {
				return profile.save();
			} else {
				return Ember.RSVP.resolve(profile);
			}
		},
		saveSettings: function saveSettings() {
			return Ember.RSVP.hash({
				org: this.saveOrganization(),
				profile: this.saveOrganizationProfile()
			});
		},


		actions: {
			openDeactivateAccount: function openDeactivateAccount() {
				this.set('showDeactivate', true);
			},
			closeDeactivateAccount: function closeDeactivateAccount() {
				this.set('showDeactivate', false);
			},
			editProfile: function editProfile() {
				this.set('showDialog', true);
			},
			cancelAction: function cancelAction() {
				this.get('organization').rollbackAttributes();
				this.get('organization.organizationInfo').rollbackAttributes();
				this.setCurrency();
				this.set('showDialog', false);
			},
			saveCompanySettings: function saveCompanySettings() {
				return this.saveSettings();
			},
			openPhotoDialog: function openPhotoDialog() {
				this.set('showPhotoDialog', true);
			},
			closePhotoDialog: function closePhotoDialog() {
				this.get('organization').rollbackAttributes();
				this.set('showPhotoDialog', false);
			},
			savePhoto: function savePhoto() {
				return this.saveSettings();
			},
			imageChange: function imageChange(file) {
				this.set('organization.image', file);
			},
			selectCurrency: function selectCurrency(item) {
				var org = this.get('organization.organizationInfo');
				org.set('currencyCode', item.key);
				this.set('currencyCodeLabel', item.value);
			}
		}
	});
});