define('busy-app/components/employees/wage-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['v-employees-employee-wage'],

    store: Ember.inject.service('store'),

    showCurrentWageDialog: false,
    showCurentOvertimeDialog: false,
    showWageHistory: false,
    showScheduledDetailsDialog: false,

    newWage: null,
    member: null,
    isArchived: false,

    reloadCurrentWage: function reloadCurrentWage() {
      // triggers a reload of the actual query to the api
      // calling this.get('member.currentWageHistory.content').reload() would
      // only reload the current model and not find any new models.
      this.get('member').belongsTo('currentWageHistory').reload();
    },
    deleteOvertime: function deleteOvertime(period) {
      return period.destroyRecord();
    },


    actions: {
      openCurrentWageDialog: function openCurrentWageDialog() {
        this.set('showCurrentWageDialog', true);
      },
      closeCurrentWageDialog: function closeCurrentWageDialog() {
        this.reloadCurrentWage();
        this.set('showCurrentWageDialog', false);
      },
      toggleCurrentOvertimeDialog: function toggleCurrentOvertimeDialog() {
        this.set('showCurentOvertimeDialog', !this.get('showCurentOvertimeDialog'));
      },
      toggleWageHistory: function toggleWageHistory() {
        var showWageHistory = !this.get('showWageHistory');
        if (!showWageHistory) {
          this.reloadCurrentWage();
        }
        this.set('showWageHistory', showWageHistory);
      },
      deleteOvertimeChange: function deleteOvertimeChange(member) {
        // scheduledOvertimePeriod is a DS.PromiseObject so use `content`
        // to access the model.
        return this.deleteOvertime(member.get('scheduledOvertimePeriod'));
      },
      showOvertimePreviewDialog: function showOvertimePreviewDialog() {
        var model = this.get('member.currentOvertimeRules');
        this.set('overtimePreviewModel', model);
        this.set('overtimePreviewDialog', true);
      },
      hideOvertimePreviewDialog: function hideOvertimePreviewDialog() {
        this.set('overtimePreviewModel', null);
        this.set('overtimePreviewDialog', false);
      },
      viewScheduledOvertimeChange: function viewScheduledOvertimeChange() {
        this.set('showScheduledDetailsDialog', true);
      },
      closeScheduledOvertimeChange: function closeScheduledOvertimeChange() {
        this.set('showScheduledDetailsDialog', false);
      }
    }
  });
});