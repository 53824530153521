define('busy-app/helpers/format-hours', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Helper.extend({

		/**
   * Helper override method initializes the class
   *
   * @private
   * @method init
   */
		init: function init() {
			this._super();

			// set observer for rerendering the template.
			Ember.addObserver(_utils.Time, 'hoursFormat', this, 'rerender');
		},


		/**
   * Helper override mehtod and main entry point for the template helper
   *
   * @public
   * @method compute
   * @param params {array} arguments passed by the template
   * @param namedArgs {object} key:value params passed by the template
   * @return {string}
   */
		compute: function compute(params, namedArgs) {
			(false && !(params.length === 1) && Ember.assert('format-hours can only take one argument', params.length === 1));


			var seconds = parseInt(params[0] || 0, 10);

			(false && !(typeof seconds === 'number' && !isNaN(seconds)) && Ember.assert('format-hours takes a number as an argument you passed: ' + _typeof(params[0]), typeof seconds === 'number' && !isNaN(seconds)));


			var isNeg = false;
			if (seconds < 0 && namedArgs.allowNeg === true) {
				isNeg = true;
				seconds = Math.abs(seconds);
			}

			var time = _utils.Time.convertSecondsString(seconds, namedArgs.format, namedArgs.padHours, namedArgs.showSeconds, namedArgs.longFormat, namedArgs.toFixed);

			if (isNeg) {
				time = '-' + time;
			}
			return time;
		},


		/**
   * rerenders the template
   *
   * @private
   * @method rerender
   */
		rerender: function rerender() {
			this.recompute();
		}
	});
});