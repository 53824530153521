define('busy-app/models/project-cost', ['exports', 'ember-data', 'busy-app/utils/unique-id'], function (exports, _emberData, _uniqueId) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		// used to match seconds and cost models
		uid: (0, _uniqueId.default)('startDate', 'endDate', 'projectId'),

		projectId: _emberData.default.attr('id', { sortable: true, sortBy: ['project.title'], nested: 'project.parentProjectId' }),
		startDate: _emberData.default.attr('date', { sortable: true }),
		endDate: _emberData.default.attr('date', { sortable: true }),
		regularCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overtimeCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		doubletimeCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overburden: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenRegularCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenOvertimeCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenDoubletimeCost: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenOverburden: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		updatedOn: _emberData.default.attr('date'),
		submittedOn: _emberData.default.attr('date'),

		project: _emberData.default.belongsTo('project'),

		totalCost: Ember.computed('regularCost', 'overtimeCost', 'doubletimeCost', function () {
			return this.get('regularCost') + this.get('overtimeCost') + this.get('doubletimeCost');
		}).meta({ aggregateType: 'sum' }),

		childrenTotalCost: Ember.computed('childrenRegularCost', 'childrenOvertimeCost', 'childrenDoubletimeCost', function () {
			return this.get('childrenRegularCost') + this.get('childrenOvertimeCost') + this.get('childrenDoubletimeCost');
		}).meta({ aggregateType: 'sum' }),

		childrenTotalOverburdenCost: Ember.computed('childrenTotalCost', 'childrenOverburden', function () {
			return this.get('childrenTotalCost') + this.get('childrenOverburden');
		}).meta({ aggregateType: 'sum' }),

		totalRegularCost: Ember.computed('regularCost', 'childrenRegularCost', function () {
			return this.get('regularCost') + this.get('childrenRegularCost');
		}).meta({ aggregateType: 'sum' }),

		totalOvertimeCost: Ember.computed('overtimeCost', 'childrenOvertimeCost', function () {
			return this.get('overtimeCost') + this.get('childrenOvertimeCost');
		}).meta({ aggregateType: 'sum' }),

		totalDoubletimeCost: Ember.computed('doubletimeCost', 'childrenDoubletimeCost', function () {
			return this.get('doubletimeCost') + this.get('childrenDoubletimeCost');
		}).meta({ aggregateType: 'sum' }),

		totalOverburden: Ember.computed('overburden', 'childrenOverburden', function () {
			return this.get('overburden') + this.get('childrenOverburden');
		}).meta({ aggregateType: 'sum' }),

		totalTotalCost: Ember.computed('totalCost', 'childrenTotalCost', function () {
			return this.get('totalCost') + this.get('childrenTotalCost');
		}).meta({ aggregateType: 'sum' }),

		totalOverburdenCost: Ember.computed('totalCost', 'overburden', function () {
			return this.get('totalCost') + this.get('overburden');
		}).meta({ aggregateType: 'sum' }),

		totalTotalOverburdenCost: Ember.computed('totalTotalCost', 'childrenTotalOverburdenCost', function () {
			return this.get('totalOverburdenCost') + this.get('childrenTotalOverburdenCost');
		}).meta({ aggregateType: 'sum' })
	});
});