define('busy-app/utils/project-parser', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var ProjectCSVObject = Ember.Object.extend({
		member: null,
		seconds: null,
		cost: null,

		wage: null,
		wageType: null,
		hours: null,
		time: null,

		depth1: null,
		depth2: null,
		depth3: null,
		depth4: null,
		depth5: null,
		depth6: null,
		depth7: null
	});

	/**
  * `Util/ProjectParser`
  *
  */
	/**
  * @module Utils
  *
  */
	exports.default = Ember.Object.extend(Ember.Evented, {
		payPeriods: Ember.inject.service('pay-period'),
		features: Ember.inject.service('pay-features'),

		// required
		store: null,
		startTime: null,
		endTime: null,

		// optional
		projectId: null,
		memberId: null,

		sortBy: 'member.firstName',

		parse: function parse() {
			var _this = this;

			(false && !(!Ember.isNone(this.get('startTime'))) && Ember.assert('You must set a startTime before calling parse', !Ember.isNone(this.get('startTime'))));
			(false && !(!Ember.isNone(this.get('endTime'))) && Ember.assert('You must set a endTime before calling parse', !Ember.isNone(this.get('endTime'))));
			(false && !(!Ember.isNone(this.get('store'))) && Ember.assert('You must set a store before calling parse', !Ember.isNone(this.get('store'))));


			return new Ember.RSVP.Promise(function (resolve) {
				_this.loadData().then(function (data) {
					var list = _this.createList(data);
					list = list.sortBy(_this.get('sortBy'));
					resolve(list);
				});
			});
		},
		loadData: function loadData() {
			var _this2 = this;

			return new Ember.RSVP.Promise(function (resolve) {
				_this2.getPayPeriodData().then(function (payPeriodSeconds) {
					var projects = [];
					var members = [];
					payPeriodSeconds.forEach(function (item) {
						if (!Ember.isNone(item.get('memberId'))) {
							var memberPromise = _this2.getMemberData(item.get('memberId'));
							members.push(memberPromise);
						}

						if (!Ember.isNone(item.get('projectId'))) {
							var projectPromise = _this2.getProjectData(item.get('projectId'));
							projects.push(projectPromise);
						}
					});

					Ember.RSVP.hash({
						members: Ember.RSVP.all(members),
						projects: Ember.RSVP.all(projects)
					}).then(function (data) {
						data.payPeriodSeconds = payPeriodSeconds;
						resolve(data);
					});
				});
			});
		},
		getPayPeriodData: function getPayPeriodData() {
			if (!Ember.isNone(this.get('projectId'))) {
				return this._getPayPeriodDataByProject(this.get('projectId'));
			} else {
				return this._getPayPeriodData();
			}
		},
		_getPayPeriodData: function _getPayPeriodData() {
			var query = {
				start_time: this.get('startTime'),
				end_time: this.get('endTime'),
				page_size: 10000
			};

			if (!Ember.isNone(this.get('memberId'))) {
				query.member_id = this.get('memberId');
			}

			return this.store.query('pay-period-member-project-seconds', query);
		},
		_getPayPeriodDataByProject: function _getPayPeriodDataByProject(projectId) {
			var _this3 = this;

			return new Ember.RSVP.Promise(function (resolve) {
				var query = {
					root_project_id: projectId,
					page_size: 10000
				};

				_this3.store.query('project', query).then(function (projects) {
					var total = 0;
					var found = 0;
					var query2 = {
						start_time: _this3.get('startTime'),
						end_time: _this3.get('endTime'),
						project_id: projectId,
						page_size: 10000
					};

					var payPeriods = null;
					projects.forEach(function (item) {
						total++;
						query2.project_id = item.get('id');
						_this3.store.query('pay-period-member-project-seconds', query2).then(function (payPeriod) {
							found++;
							if (Ember.isNone(payPeriods)) {
								payPeriods = payPeriod;
							} else {
								payPeriods.pushObjects(payPeriod.get('content'));
							}

							if (total <= found) {
								resolve(payPeriods);
							}
						});
					});
				});
			});
		},
		getMemberData: function getMemberData(memberId) {
			var _this4 = this;

			return new Ember.RSVP.Promise(function (resolve) {
				_this4.store.findRecord('member', memberId).then(function (member) {
					var params = {
						member_id: member.get('id'),
						_lte: { change_date: _this4.get('startTime') },
						_desc: ['change_date']
					};

					_this4.store.query('wage-history', params).then(function (wage) {
						member.set('wage', wage.objectAt(0));
						resolve(member);
					});
				});
			});
		},
		getProjectData: function getProjectData(projectId) {
			return this.store.findRecord('project', projectId);
		},
		createList: function createList(data) {
			var _this5 = this;

			var list = [];
			data.payPeriodSeconds.forEach(function (item) {
				_this5.buildObject(item, list, data);
			});
			list = list.sortBy('member.firstName', 'member.lastName', 'depth1', 'depth2', 'depth3', 'depth4', 'depth5', 'depth6', 'depth7');
			return list;
		},
		buildObject: function buildObject(payPeriod, list, data) {
			var member = this.getMemberObj(payPeriod.get('memberId'), data);
			var projectData = Ember.get(data, 'projects');

			var object = ProjectCSVObject.create();
			object.set('member', member);
			object.set('seconds', payPeriod);

			if (!Ember.isNone(payPeriod.get('projectId'))) {
				this.setProjectPath(payPeriod.get('projectId'), object, projectData);
			}

			var isHourly = member.get('wage.isHourly');
			var wage = parseFloat(member.get('wage.effectiveRate'));
			var grossWages = isHourly ? 'Hourly' : 'Salary';
			if (isHourly) {
				if (payPeriod.get('regularSeconds') > 0) {
					//extra info
					object.set('wage', wage.toFixed(2));
					object.set('wageType', 'REG');
					object.set('grossWages', grossWages);
					object.set('hours', (payPeriod.get('regularSeconds') / 60 / 60).toFixed(2));
					object.set('time', _utils.Time.date(payPeriod.get('endTime')).format('MM/DD/YYYY'));
					list.pushObject(object);
				}

				if (payPeriod.get('overtimeSeconds') > 0) {
					var otObject = this.copy(object);
					otObject.set('wage', (wage * 1.5).toFixed(2));
					otObject.set('wageType', 'OT_1.5x');
					otObject.set('grossWages', grossWages);
					otObject.set('hours', (payPeriod.get('overtimeSeconds') / 60 / 60).toFixed(2));
					list.pushObject(otObject);
				}

				if (payPeriod.get('doubletimeSeconds') > 0) {
					var dtObject = this.copy(object);
					dtObject.set('wage', (wage * 2).toFixed(2));
					dtObject.set('wageType', 'OT_2x');
					dtObject.set('grossWages', grossWages);
					dtObject.set('hours', (payPeriod.get('doubletimeSeconds') / 60 / 60).toFixed(2));
					list.pushObject(dtObject);
				}
			} else {
				if (payPeriod.get('regularSeconds') > 0 || payPeriod.get('overtimeSeconds') > 0 || payPeriod.get('doubletimeSeconds')) {
					var payPeriodDef = this.get('payPeriods')._getPayPeriodDef(payPeriod.get('startTime'));
					var workWeek = parseFloat(member.get('wage.workWeek'));
					var hours = workWeek.toString();
					if (payPeriodDef.get('payPeriodType') === 20) {
						hours = (workWeek * 2).toString();
					} else if (payPeriodDef.get('payPeriodType') === 30) {
						hours = (workWeek * 52 / 24).toFixed(3);
					} else if (payPeriodDef.get('payPeriodType') === 40) {
						hours = (workWeek * 52 / 12).toFixed(3);
					}

					object.set('wage', wage.toFixed(2));
					object.set('wageType', 'SAL');
					object.set('grossWages', grossWages);
					object.set('hours', hours);
					object.set('time', _utils.Time.date(payPeriod.get('endTime')).format('MM/DD/YYYY'));
					list.pushObject(object);
				}
			}
		},
		getMemberObj: function getMemberObj(memberId, data) {
			var members = Ember.get(data, 'members');
			return members.findBy('id', memberId);
		},
		setProjectPath: function setProjectPath(projectId, object, projectData) {
			if (!Ember.isNone(projectId)) {
				var project = projectData.findBy('id', projectId);
				if (!Ember.isNone(project)) {
					object.set('depth' + project.get('depth'), project.get('title'));
					if (project.get('depth') === 1) {
						return object;
					}
					var parentProjectId = project.get('parentProjectId');
					return this.setProjectPath(parentProjectId, object, projectData);
				} else {
					_logger.default.warn('ProjectParser', 'Project path was not found!');
					return object;
				}
			} else {
				_logger.default.warn('ProjectParser', 'Project path was not found!');
				return object;
			}
		},
		copy: function copy(obj) {
			return ProjectCSVObject.create({
				member: obj.get('member'),
				cost: obj.get('cost'),
				seconds: obj.get('seconds'),
				wage: obj.get('wage'),
				wageType: obj.get('wageType'),
				hours: obj.get('hours'),
				time: obj.get('time'),
				depth1: obj.get('depth1'),
				depth2: obj.get('depth2'),
				depth3: obj.get('depth3'),
				depth4: obj.get('depth4'),
				depth5: obj.get('depth5'),
				depth6: obj.get('depth6'),
				depth7: obj.get('depth7')
			});
		},
		handleError: function handleError(error) {
			this.trigger('error', error);
		}
	});
});