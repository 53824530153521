define('busy-app/models/project-lookup', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    parentId: _emberData.default.attr('string'),
    childId: _emberData.default.attr('string'),
    isDirect: _emberData.default.attr('boolean'),
    updatedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),

    parent: _emberData.default.belongsTo('project', { inverse: null, referenceKey: 'parentId', foreignKey: 'id' }),
    child: _emberData.default.belongsTo('project', { inverse: null, referenceKey: 'childId', foreignKey: 'id' })
  });
});