define('busy-app/components/company/integrations/vendors/procore/oauth-callback', ['exports', '@busy-web/utils', 'busy-app/utils/logger', 'busy-app/utils/settings', 'busy-app/components/company/integrations/oauth-callback'], function (exports, _utils, _logger, _settings, _oauthCallback) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _oauthCallback.default.extend({
		// integration,
		// integrationName,
		// dashboardUrl,
		// grantCode,

		procoreCompanies: null,
		procoreCompaniesAvailable: null,
		procoreCompaniesUnavailable: null,

		selectedCompanyId: null,
		// companySelectOptions: [],

		procoreCostCodeLists: null,
		selectedCostCodeListId: null,
		// costCodeSelectOptions: [],

		/**
   * Toggle display of a confirmation dialog when aborting the integration
   *
   * @public
   * @property showAbortIntegrationOverlay
   * @type {boolean}
   * @default false
   */
		showAbortIntegrationOverlay: false,

		/**
   * Toggle display of the primary dialog
   *
   * @public
   * @property showAbortIntegrationOverlay
   * @type {boolean}
   * @default false
   */
		showDialog: true,

		rpcError: null,
		hasRpcError: Ember.computed('rpcError', function () {
			return !Ember.isNone(this.get('rpcError.debug.errors'));
		}),
		rpcErrorString: Ember.computed('rpcError', 'hasRpcError', function () {
			return this.get('hasRpcError') ? JSON.stringify(this.get('rpcError'), null, '\t') : null;
		}),

		// getCompanySelectOptions(companies, selectedId) {
		// 	return this.getSelectOptions(companies, selectedId);
		// },

		// getCostCodeSelectOptions(costCodeLists, selectedId) {
		// 	return this.getSelectOptions(costCodeLists, selectedId);
		// },
		//
		// getSelectOptions(options, selectedId) {
		// 	if (!isNone(options) && Array.isArray(options)) {
		// 		return options.map(option => EmberObject.create({
		// 			key: option.id,
		// 			label: option.title,
		// 			_selected: (option.id === selectedId)
		// 		}));
		// 	} else {
		// 		return [];
		// 	}
		// },

		/**
   * Computed: `procoreCompanies`
   *
   * Show a different label in the select box depending on whether companies data been recieved from the RPC yet
   *
   * @public
   * @property companySelectLabel
   * @type {string}
   * @default "Loading companies..."
   */
		companySelectLabel: Ember.computed('procoreCompanies', function () {
			return !Ember.isNone(this.get('procoreCompanies')) ? (0, _utils.loc)("Select a company") : (0, _utils.loc)("Loading companies...");
		}),

		selectedCompanyIdChanged: Ember.observer('selectedCompanyId', function () {
			var id = this.get('selectedCompanyId');

			if (!Ember.isNone(id)) {
				this.loadCostCodes(id);
			}
		}),

		/**
   * Computed: `procoreCostCodeLists`
   *
   * Show a different label in the select box depending on whether costCodeLists data been recieved from the RPC yet
   *
   * @public
   * @property costCodeSelectLabel
   * @type {string}
   * @default "Loading cost codes lists..."
   */
		costCodeSelectLabel: Ember.computed('procoreCostCodeLists', function () {
			return !Ember.isNone(this.get('procoreCostCodeLists')) ? (0, _utils.loc)("Select a cost code list") : (0, _utils.loc)("Loading cost codes lists...");
		}),

		/**
   * Override the base class to not redirect immediately
   *
   * @private
   * @method handleGrantCode
   * @param {grantCode}
   * @async
   * @return {Object}
   */
		handleGrantCode: function handleGrantCode(grantCode) {
			var _this = this;

			this.set('grantCode', grantCode);
			this.send('showDialog');

			if (!window.runningTests) {
				return this.get('integration').rpcProcoreSetCredentials(this.get('integrationName'), grantCode).then(function (procoreCompanies) {

					if (!_this.get('isDestroyed') && !Ember.isNone(procoreCompanies)) {
						if (!Array.isArray(procoreCompanies)) {
							procoreCompanies = [];
						}

						// FIXME: remove map
						procoreCompanies = procoreCompanies.map(function (c, i) {
							if (!c.hasOwnProperty('available')) {
								c.available = !(i % 2); // fake available flag for testing
							}

							return c;
						});

						var procoreCompaniesAvailable = procoreCompanies.filter(function (c) {
							return c.available;
						});
						var procoreCompaniesUnavailable = procoreCompanies.reject(function (c) {
							return c.available;
						});
						var selectedCompanyId = procoreCompaniesAvailable.length === 1 ? procoreCompaniesAvailable[0].id : null;

						// const companySelectOptions = this.getCompanySelectOptions(procoreCompanies, selectedCompanyId);

						_logger.default.info('OAuthCallback', 'handleGrantCode', 'setCredentials', { selectedCompanyId: selectedCompanyId, procoreCompanies: procoreCompanies, procoreCompaniesAvailable: procoreCompaniesAvailable, procoreCompaniesUnavailable: procoreCompaniesUnavailable });

						_this.setProperties({
							selectedCompanyId: selectedCompanyId,
							procoreCompanies: procoreCompanies,
							procoreCompaniesAvailable: procoreCompaniesAvailable,
							procoreCompaniesUnavailable: procoreCompaniesUnavailable
						});
					}

					return procoreCompanies;
				}).catch(function (err) {
					var errObj = Ember.Object.create(err);
					var errMsg = errObj.get('debug.errors.0');

					// if it's a bad grant code it's probably a page refresh, but this possibly cause an infinite redirect issue so do this if DEBUG is enabled
					if (_settings.default.get('debug_mode') && errMsg === "invalid grant code provided") {
						return _this.reRequestAuth();
					} else {
						return _this.onRpcError(err);
					}
				});
			} else {
				return Ember.RSVP.resolve([]);
			}
		},


		/**
   * Sends the user selected companyId to the `get-cost-code-lists` RPC,  returning an array of costCodeLists
   *
   * Side effect: if there is only one costCodeList, trigger the `onSelectCostCodeList` action to select it
   *
   * @public
   * @method loadCostCodes
   * @param {integer} companyId
   * @async
   * @return {array}
   */
		loadCostCodes: function loadCostCodes(companyId) {
			var _this2 = this;

			this.set('selectedCostCodeListId', null);
			this.set('procoreCostCodeLists', null);

			if (Ember.isNone(companyId)) {
				return Ember.RSVP.resolve();
			}

			return this.get('integration').rpcProcoreGetCostCodeLists(this.get('integrationName'), companyId).then(function (procoreCostCodeLists) {
				if (!_this2.get('isDestroyed') && !Ember.isNone(procoreCostCodeLists)) {
					if (!Array.isArray(procoreCostCodeLists)) {
						procoreCostCodeLists = [];
					}

					var selectedCostCodeListId = procoreCostCodeLists.length === 1 ? procoreCostCodeLists[0].id : null;

					_logger.default.info('OAuthCallback', 'loadCostCodes', 'getCostCodeLists', { selectedCostCodeListId: selectedCostCodeListId, procoreCostCodeLists: procoreCostCodeLists });

					_this2.setProperties({
						selectedCostCodeListId: selectedCostCodeListId,
						procoreCostCodeLists: procoreCostCodeLists
						// costCodeSelectOptions,
					});
				}

				return procoreCostCodeLists;
			}).catch(function (err) {
				return _this2.onRpcError(err);
			});
		},


		/**
   * Sends the user selected companyId and costCodeListId to the `get-cost-code-lists` RPC,  returning an array of costCodeLists
   *
   * Side effect: On success, redirect the user to the dashboard
   *
   * @public
   * @method finalizeIntegration
   * @param {integer} companyId
   * @async
   * @return {array}
   */
		finalizeIntegration: function finalizeIntegration(selectedCompanyId, selectedCostCodeListId) {
			var _this3 = this;

			return this.get('integration').rpcProcoreFinalizeConfiguration(this.get('integrationName'), selectedCompanyId, selectedCostCodeListId).then(function () {
				return _this3.returnToDashboard();
			}).catch(function (err) {
				return _this3.onRpcError(err);
			});
		},
		abortIntegration: function abortIntegration() {
			return this.get('integration').rpcDisconnect(this.get('integrationName'));
		},


		/**
   * @public
   * @method returnToDashboard
   */
		returnToDashboard: function returnToDashboard() {
			return this.transitionTo(this.get('dashboardUrl'));
		},
		onRpcError: function onRpcError(err) {
			_logger.default.error('OAuthCallback', 'onRpcError', { err: err });

			this.set('rpcError', err);

			return Ember.RSVP.reject(err);
		},


		/**
   * Gets the grantUrl from the RPC then opens a new window to that location
   *
   * @private
   * @method connectIntegration
   */
		reRequestAuth: function reRequestAuth() {
			_logger.default.info('oauth-callback', 'reRequestAuth');

			this.set('isLoading', true);

			return this.rpcGrantUrl().then(function (results) {
				_logger.default.info('oauth-callback', 'reRequestAuth', 'grantUrl', { results: results });

				if (results && results.data && results.data.grant_url) {
					var oauthUrl = results.data.grant_url;

					_logger.default.info('oauth-callback', 'reRequestAuth', 'grantUrl', { oauthUrl: oauthUrl });

					return window.location = oauthUrl;
				}
			});
		},
		rpcGrantUrl: function rpcGrantUrl() {
			return this.get('integration').rpcGrantUrl(this.get('integrationName'));
		},
		getEventValue: function getEventValue(evt) {
			return evt.target ? evt.target.value : evt.srcElement.value;
		},


		actions: {
			/**
    * Triggered on page load if there were no OAuth errors
    *
    * @event showDialog
    */
			showDialog: function showDialog() {
				this.set('showDialog', true);
			},


			/**
    * Triggered when setup is completed or aborted
    *
    * @event hideDialog
    */
			hideDialog: function hideDialog() {
				this.set('showDialog', false);
			},


			/**
    * Triggered when the user selects a company from the list
    *
    * @event onSelectCompany
    * @param entry {EmberObject}
    */
			// onSelectCompany(entry) {
			// 	Logger.info('OAuthCallback', 'onSelectCompany', { entry });
			//
			// 	if (!this.get('isDestroyed')) {
			// 		this.set('selectedCompanyId', entry.key);
			// 	}
			// },
			onSelectCompany: function onSelectCompany(evt) {
				if (!this.get('isDestroyed')) {
					var val = this.getEventValue(evt);

					this.set('selectedCompanyId', parseInt(val, 10));

					evt.target.blur();
				}
			},


			/**
    * Triggered when the user selectes a costCodeList from the list
    *
    * @event onSelectCostCodeList
    * @param entry {EmberObject}
    */
			// onSelectCostCodeList(entry) {
			// 	Logger.info('OAuthCallback', 'onSelectCostCodeList', { entry });
			//
			// 	if (!this.get('isDestroyed')) {
			// 		this.set('selectedCostCodeListId', entry.key);
			// 	}
			// },
			onSelectCostCodeList: function onSelectCostCodeList(evt) {
				if (!this.get('isDestroyed')) {
					var val = this.getEventValue(evt);

					this.set('selectedCostCodeListId', parseInt(val, 10));

					evt.target.blur();
				}
			},


			/**
    * Triggered when the user clicks the "connect" button.
    *
    * Confirms required values are set, then calls `finalizeIntegration`
    *
    * @event finalizeIntegration
    */
			finalizeIntegration: function finalizeIntegration() {
				_logger.default.info('OAuthCallback', 'finalizeIntegration');

				var selectedCompanyId = this.get('selectedCompanyId');
				var selectedCostCodeListId = this.get('selectedCostCodeListId');

				if (!selectedCompanyId) {
					return Ember.RSVP.reject((0, _utils.loc)('You must select a company'));
				} else if (!selectedCostCodeListId) {
					return Ember.RSVP.reject((0, _utils.loc)('You must select a cost code list'));
				} else {
					return this.finalizeIntegration(selectedCompanyId, selectedCostCodeListId);
				}
			},


			/**
    * Triggered when the user clicks the "abort" button
    *
    * @event abortIntegration
    */
			abortIntegration: function abortIntegration() {
				_logger.default.info('OAuthCallback', 'abortIntegration');

				this.set('showAbortIntegrationOverlay', true);
			},


			/**
    * Triggered when the user clicks the "abort" button in the confirmation dialog
    *
    * Hides the dialog and redirects the user to the dashboard
    *
    * @event abortIntegrationConfirm
    */
			abortIntegrationConfirm: function abortIntegrationConfirm() {
				var _this4 = this;

				_logger.default.info('OAuthCallback', 'abortIntegrationConfirm');

				return this.abortIntegration().then(function () {
					_this4.set('showAbortIntegrationOverlay', false);

					return _this4.returnToDashboard();
				});
			},


			/**
    * Triggered when the user clicks the "cancel" button in the confirmation dialog
    *
    * @event abortIntegrationCancel
    */
			abortIntegrationCancel: function abortIntegrationCancel() {
				_logger.default.info('OAuthCallback', 'abortIntegrationCancel');

				this.set('showAbortIntegrationOverlay', false);
			}
		}
	});
});