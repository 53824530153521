define('busy-app/polymorphs/pay-period-member-project', ['exports', 'busy-app/utils/collection', 'busy-app/polymorphs/base'], function (exports, _collection, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		project: null,
		seconds: null,
		cost: null,
		includeChildren: true,

		totalRegularSeconds: Ember.computed('seconds.@each.totalRegularSeconds', function () {
			var _this = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this.get('includeChildren')) {
						total += item.get('totalRegularSeconds');
					} else {
						total += item.get('regularSeconds');
					}
				});
			}
			return total;
		}),

		totalOvertimeSeconds: Ember.computed('seconds.@each.totalOvertimeSeconds', function () {
			var _this2 = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this2.get('includeChildren')) {
						total += item.get('totalOvertimeSeconds');
					} else {
						total += item.get('overtimeSeconds');
					}
				});
			}
			return total;
		}),

		totalDoubletimeSeconds: Ember.computed('seconds.@each.totalDoubletimeSeconds', function () {
			var _this3 = this;

			var total = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (_this3.get('includeChildren')) {
						total += item.get('totalDoubletimeSeconds');
					} else {
						total += item.get('doubletimeSeconds');
					}
				});
			}
			return total;
		}),

		totalSeconds: Ember.computed('totalRegularSeconds', 'totalOvertimeSeconds', 'totalDoubletimeSeconds', function () {
			return this.get('totalRegularSeconds') + this.get('totalOvertimeSeconds') + this.get('totalDoubletimeSeconds');
		}),

		totalRegularCost: Ember.computed('includeChildren', 'cost.@each.{totalRegularCost,regularCost}', function () {
			var _this4 = this;

			var cost = Ember.get(this, 'cost');
			var total = 0;
			if (!Ember.isNone(cost)) {
				cost.forEach(function (item) {
					if (_this4.get('includeChildren')) {
						total += item.get('totalRegularCost');
					} else {
						total += item.get('regularCost');
					}
				});
			}
			return total;
		}),

		totalOvertimeCost: Ember.computed('cost.@each.totalOvertimeCost', function () {
			var _this5 = this;

			var total = 0;
			if (!Ember.isNone(this.get('cost'))) {
				this.get('cost').forEach(function (item) {
					if (_this5.get('includeChildren')) {
						total += item.get('totalOvertimeCost');
					} else {
						total += item.get('overtimeCost');
					}
				});
			}
			return total;
		}),

		totalDoubletimeCost: Ember.computed('cost.@each.totalDoubletimeCost', function () {
			var _this6 = this;

			var total = 0;
			if (!Ember.isNone(this.get('cost'))) {
				this.get('cost').forEach(function (item) {
					if (_this6.get('includeChildren')) {
						total += item.get('totalDoubletimeCost');
					} else {
						total += item.get('doubletimeCost');
					}
				});
			}
			return total;
		}),

		totalCost: Ember.computed('totalRegularCost', 'totalOvertimeCost', 'totalDoubletimeCost', function () {
			return this.get('totalRegularCost') + this.get('totalOvertimeCost') + this.get('totalDoubletimeCost');
		}),

		totalNoChildrenSeconds: Ember.computed('seconds.@each.totalNoChildrenSeconds', function () {
			var total = 0;
			if (!Ember.isEmpty(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return total += item.get('totalNoChildrenSeconds');
				});
			}
			return total;
		}),

		totalChildrenSeconds: Ember.computed('seconds.@each.totalChildrenSeconds', function () {
			var total = 0;
			if (!Ember.isEmpty(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					return total += item.get('totalChildrenSeconds');
				});
			}
			return total;
		}),

		overburdenCost: Ember.computed('cost.@each.overburden', function () {
			var cost = Ember.get(this, 'cost');
			var total = Ember.isNone(cost) ? 0 : (0, _collection.sumBy)(cost, 'overburden');

			return total;
		}),

		overburdenWithChildrenCost: Ember.computed('cost.@each.childrenOverburden', function () {
			var cost = Ember.get(this, 'cost');
			var total = Ember.isNone(cost) ? 0 : (0, _collection.sumBy)(cost, 'childrenOverburden');

			return total;
		})
	});
});