define('busy-app/components/employees/import-employees', ['exports', 'busy-app/utils/employee-csv-import', '@busy-web/utils'], function (exports, _employeeCsvImport, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ["c-employees-import-employees"],

		store: Ember.inject.service('store'),
		subscription: Ember.inject.service('subscription'),
		analytics: Ember.inject.service('analytics'),

		model: null,
		importMembers: null,

		showImportError: false,
		showMapping: false,
		showPreview: false,
		showPostReview: false,

		importProgress: (0, _utils.loc)('Saving'),

		importErrorMessage: '',

		init: function init() {
			this._super();
			// prime the call to subscriptionMembersLeft.
			this.get('subscription.membersAvailable');
			this.setupButtons();
		},
		setupButtons: function setupButtons() {
			this.set('buttonInfo1', [{ action: this.get('actions.closeImportAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfo2', [{ action: this.get('actions.openPreviewAction'), color: 'blue', text: (0, _utils.loc)('Next'), target: this }, { action: this.get('actions.closeMappingAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfo3', [{ action: this.get('actions.openPostReviewAction'), color: 'blue', text: (0, _utils.loc)('Next'), target: this }, { action: this.get('actions.closePreviewAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfo4', [{ action: this.get('actions.saveAction'), color: 'blue', text: (0, _utils.loc)('Save'), target: this }, { action: this.get('actions.closePostReviewAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfoError', [{ action: this.get('actions.closeErrorAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
		},
		sendCsvEmail: function sendCsvEmail() {
			var _this = this;

			var members = this.get('importMembers.model');
			var memberList = [];

			members.forEach(function (member) {
				var memberObj = Ember.Object.create({
					first_name: member.get('firstName'),
					last_name: member.get('lastName'),
					username: member.get('username'),
					password: member.get('password'),
					email: member.get('email')
				});
				memberList.pushObject(memberObj);
			});

			this.get('store').rpcRequest('account', 'company-csv-list', { member_list: memberList }).then(function () {
				_this.get('subscription').reloadSubscriptionSize();
			});
		},
		stripeUsersLeft: function stripeUsersLeft() {
			return this.get('subscription.membersAvailable');
		},
		closeImport: function closeImport() {
			this.set('showImportError', false);
			this.set('showMapping', false);
			this.set('showPreview', false);
			this.set('showPostReview', false);
		},
		showError: function showError(err) {
			this.set('importErrorMessage', err);
			this.set('showImportError', true);
		},
		setPosition: function setPosition(position, item) {
			item.set('positionId', position.get('id'));
			item.set('position', position.get('_model'));
		},


		actions: {
			openIntercom: function openIntercom() {
				this.get('analytics').openIntercom();
			},
			closeImportAction: function closeImportAction() {
				this.closeImport();
				this.sendAction('onClose');
			},
			closeErrorAction: function closeErrorAction() {
				this.set('showMapping', false);
				this.set('showPreview', false);
				this.set('showPostReview', false);
				this.set('showImportError', false);
			},
			openMappingAction: function openMappingAction() {
				this.set('showMapping', true);
			},
			closeMappingAction: function closeMappingAction() {
				this.set('showMapping', false);
			},
			openPreviewAction: function openPreviewAction() {
				this.get('importMembers').triggerParse();
				this.set('showPreview', true);
			},
			closePreviewAction: function closePreviewAction() {
				this.set('showPreview', false);
			},
			openPostReviewAction: function openPostReviewAction() {
				this.set('showPostReview', true);
			},
			closePostReviewAction: function closePostReviewAction() {
				this.set('showPostReview', false);
			},
			uploadAction: function uploadAction(data) {
				var _this2 = this;

				data = data[0];
				var owner = Ember.getOwner(this);
				var csv = _employeeCsvImport.default.create(owner.ownerInjection());

				csv.on('onFinishFileParse', function (headers, data) {
					if (data.length <= _this2.stripeUsersLeft()) {
						_this2.set('showMapping', true);
					} else {
						_this2.showError((0, _utils.loc)('This file has more employees than your subscription allows.'));
					}
				});

				csv.on('progress', function (left, total) {
					if (total > 5) {
						_this2.set('importProgress', (0, _utils.loc)('Importing employees %@%', [Math.ceil((total - left) / total * 100)]));
					}
				});

				csv.on('complete', function () {
					_this2.sendAction('onComplete', _this2.get('model'));
				});

				csv.on('error', function (err) {
					_this2.showError(err);
				});

				csv.run(data);
				this.set('importMembers', csv);
			},
			setPermisionFor: function setPermisionFor(member, position) {
				this.setPosition(position, member);
			},
			selectListItem: function selectListItem(item) {
				this.get('importMembers').setHeaderByListType(item);
			},
			saveAction: function saveAction() {
				var _this3 = this;

				return this.get('importMembers').saveModel().then(function () {
					_this3.set('model', _this3.get('importMembers.model'));
					_this3.sendCsvEmail();
					return (0, _utils.loc)("Saved employees successfully!");
				}).finally(function () {
					return _this3.closeImport();
				});
			}
		}
	});
});