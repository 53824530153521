define('busy-app/utils/models/managers/time-entry-log', ['exports', 'busy-app/utils/models/managers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		getSorted: function getSorted() {
			return this.get('model');
		},

		/**
   * fetches the data for a time-entry by range.
   *
   * @public
   * @method fetch
   * @return {promise}
   */
		fetch: function fetch(timeEntryId) {
			var _this = this;

			return Ember.RSVP.hash({
				timeEntryLog: this.store.query('client-time-entry-log', { time_entry_id: timeEntryId }),
				timeEntryBreakLog: this.store.query('client-time-entry-break-log', { time_entry_id: timeEntryId })
			}).then(function (data) {
				var ids = [];
				var memberIds = [];
				data.timeEntryLog.forEach(function (model) {
					if (memberIds.indexOf(model.get('memberId')) === -1) {
						memberIds.push(model.get('memberId'));
					}

					if (ids.indexOf(model.get('startLocationId')) === -1 && !Ember.isNone(model.get('startLocationId'))) {
						ids.push(model.get('startLocationId'));
					}

					if (ids.indexOf(model.get('endLocationId')) === -1 && !Ember.isNone(model.get('endLocationId'))) {
						ids.push(model.get('endLocationId'));
					}
				});

				data.timeEntryBreakLog.forEach(function (entryBreak) {
					if (ids.indexOf(entryBreak.get('startLocationId')) === -1 && !Ember.isNone(entryBreak.get('startLocationId'))) {
						ids.push(entryBreak.get('startLocationId'));
					}

					if (ids.indexOf(entryBreak.get('endLocationId')) === -1 && !Ember.isNone(entryBreak.get('endLocationId'))) {
						ids.push(entryBreak.get('endLocationId'));
					}
				});

				return Ember.RSVP.hash({
					members: _this.store.findByIds('member', memberIds),
					locations: _this.store.findByIds('member-location', ids)
				}).then(function (meta) {
					data.timeEntryBreakLog.forEach(function (entryBreak) {

						// only gather start location for break start action types
						if ([10, 11].indexOf(entryBreak.get('actionType')) !== -1) {
							var startLocation = meta.locations.findBy('id', entryBreak.get('startLocationId'));
							entryBreak.set('startLocation', startLocation);
						}

						// only gather end location for break end action types
						if ([20, 21].indexOf(entryBreak.get('actionType')) !== -1) {
							var endLocation = meta.locations.findBy('id', entryBreak.get('endLocationId'));
							entryBreak.set('endLocation', endLocation);
						}

						var timeEntry = data.timeEntryLog.findBy('id', entryBreak.get('clientTimeEntryLogId'));
						entryBreak.set('timeEntry', timeEntry);
					});

					data.timeEntryLog.forEach(function (entry) {
						// only gather start location for clock in action types
						if ([10, 11, 30, 31].indexOf(entry.get('actionType')) !== -1) {
							var startLocation = meta.locations.findBy('id', entry.get('startLocationId'));
							entry.set('startLocation', startLocation);
						}

						// only gather end location for clock out action types
						if ([20, 21, 40, 41].indexOf(entry.get('actionType')) !== -1) {
							var endLocation = meta.locations.findBy('id', entry.get('endLocationId'));
							entry.set('endLocation', endLocation);
						}

						var member = meta.members.findBy('id', entry.get('memberId'));
						entry.set('member', member);
					});

					return data;
				});
			});
		},
		buildCollections: function buildCollections(data) {
			var _this2 = this;

			var entries = data.timeEntryLog.sortBy('createdOn');
			entries.forEach(function (model) {
				var breaks = _this2.getBreaks(data.timeEntryBreakLog, model);

				// set breaks for the collection data and timeEntryBreaks for maps and templates
				model.set('breaks', breaks);
				model.set('timeEntryBreaks', breaks);

				if (Ember.isNone(model.get('originalTimeEntryId')) || model.get('timeEntryId') === model.get('originalTimeEntryId')) {
					var prevModel = _this2.getPrevState(entries, model);

					// get merged entry state
					var mergedEntry = _this2.getMergeState(entries, model);

					// add to the collection
					_this2.addCollection(model, prevModel, mergedEntry);
				}
			});
		},
		getMergeState: function getMergeState(entries, model) {
			var mergedEntry = null;
			if (!Ember.isNone(model.get('mergedTimeEntryId')) && model.get('actionType') === 80) {
				// more than one merged log can be in the new logs
				var mEntry = entries.filterBy('originalTimeEntryId', model.get('mergedTimeEntryId'));
				if (!Ember.isEmpty(mEntry)) {
					// the last merged entry log should be the last state of
					// the merged entry.
					mergedEntry = mEntry.get('lastObject');
				}
			}
			return mergedEntry;
		},
		getPrevState: function getPrevState(entries, model) {
			var previousIndex = entries.indexOf(model) - 1;
			var prevModel = null;
			if (previousIndex >= 0) {
				var prev = entries.objectAt(previousIndex);
				if (prev.get('timeEntryId') === prev.get('originalTimeEntryId')) {
					prevModel = prev;
				} else {
					prevModel = this.getPrevState(entries, prev);
				}
			}
			return prevModel;
		},
		getBreaks: function getBreaks(breakEntries, model) {
			var modelBreaks = breakEntries.filterBy('clientTimeEntryLogId', model.get('id'));
			var breaks = breakEntries.filter(function (item) {
				// return all breaks that are linked to the entry log
				if (model.get('id') === item.get('clientTimeEntryLogId')) {
					return true;
				}

				var modelBreak = modelBreaks.findBy('timeEntryBreakId', item.get('timeEntryBreakId'));
				return model.get('createdOn') >= item.get('createdOn') && Ember.isNone(modelBreak);
			});
			return breaks;
		},
		oldGetBreaks: function oldGetBreaks(breakEntries, entries, model) {
			var breaks = [];
			var ids = [];
			var isMergedEntry = Ember.isNone(model.get('originalTimeEntryId')) || model.get('timeEntryId') !== model.get('originalTimeEntryId');

			// loop through the list of entries from newest to oldest
			// so any breaks found that are deleted will not be added to the list of
			// last state breaks
			var list = entries.sortBy('createdOn').reverse();
			var modelIndex = list.indexOf(model);

			list = list.slice(modelIndex);
			list.forEach(function (item) {
				// find breaks for only merged or only unmerged entries so breaks dont get mixed
				// in the log entries.
				var isMerged = Ember.isNone(item.get('originalTimeEntryId')) || item.get('timeEntryId') !== item.get('originalTimeEntryId');
				if (isMergedEntry === isMerged) {
					var breakLogs = breakEntries.filterBy('clientTimeEntryLogId', item.id);
					breakLogs.forEach(function (breakItem) {
						if (ids.indexOf(breakItem.get('timeEntryBreakId')) === -1) {
							breaks.pushObject(breakItem);

							// store the id even when deletedOn is set
							// so it wont get added later
							ids.push(breakItem.get('timeEntryBreakId'));
						}
					});
				}
			});
			return breaks;
		}
	});
});