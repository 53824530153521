define('busy-app/routes/cost-code', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'busy-app/mixins/check-permission'], function (exports, _authenticatedRouteMixin, _checkPermission) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _checkPermission.default, {
		permissions: Ember.computed(function () {
			return ['manageCostCodes'];
		}),

		beforeModel: function beforeModel(transition) {
			var org = this.modelFor('application').organization;
			if (Ember.isNone(org) || !this.features.get('cost-codes')) {
				transition.abort();
				this.transitionTo('index');
			} else {
				return this._super.apply(this, arguments);
			}
		}
	});
});