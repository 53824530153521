define('busy-app/utils/launch-darkly-sdk', ['exports', '@busy-web/ember-cli-launch-darkly/utils/launch-darkly-sdk'], function (exports, _launchDarklySdk) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _launchDarklySdk.default;
    }
  });
});