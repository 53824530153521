define('busy-app/models/equipment', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		organizationId: _emberData.default.attr('string'),
		equipmentGroupId: _emberData.default.attr('string'),
		image: _emberData.default.attr('file'),
		imageUrl: _emberData.default.attr('string'),
		imageThumbUrl: _emberData.default.attr('string'),
		equipmentName: _emberData.default.attr('string'),
		equipmentModelId: _emberData.default.attr('string'),
		serialNumber: _emberData.default.attr('string'),
		trackImoId: _emberData.default.attr('number'),
		importType: _emberData.default.attr('string'),
		fuelCapacity: _emberData.default.attr('string'),
		lastLocationId: _emberData.default.attr('string'),
		createdOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		archivedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		equipmentModel: Ember.computed('equipmentModelId', function () {
			if (!Ember.isNone(this.get('equipmentModelId'))) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.queryRecord('equipment-model', {
						id: this.get('equipmentModelId'),
						deleted_on: '_-DISABLE-_'
					})
				});
			}
		}),

		equipmentJoins: Ember.computed('equipmentModelId', function () {
			var _this = this;

			if (!Ember.isNone(this.get('equipmentModelId'))) {
				return _emberData.default.PromiseObject.create({
					promise: this.store.queryRecord('equipment-model', {
						id: Ember.get(this, 'equipmentModelId'),
						deleted_on: '_-DISABLE-_'
					}).then(function (model) {
						var category = Ember.RSVP.resolve(null);
						if (!Ember.isNone(model) && !Ember.isNone(Ember.get(model, 'equipmentCategoryId'))) {
							category = _this.store.queryRecord('equipment-category', {
								id: Ember.get(model, 'equipmentCategoryId'),
								deleted_on: '_-DISABLE-_'
							});
						}

						var make = Ember.RSVP.resolve(null);
						if (!Ember.isNone(model) && !Ember.isNone(Ember.get(model, 'equipmentMakeId'))) {
							make = _this.store.queryRecord('equipment-make', {
								id: Ember.get(model, 'equipmentMakeId'),
								deleted_on: '_-DISABLE-_'
							});
						}

						return Ember.RSVP.hash({ model: model, category: category, make: make });
					})
				});
			}
		}),

		isVisionLink: Ember.computed('importType', function () {
			return this.get('importType') === 'VisionLink';
		}),

		fullName: Ember.computed('equipmentName', 'equipmentModel.{title,equipmentMake.title}', function () {
			var name = this.get('equipmentName') || '';
			var make = this.get('equipmentModel.equipmentMake.title') || '';
			var model = this.get('equipmentModel.title') || '';

			if (model.toLowerCase() === 'unknown') {
				model = '';
			}

			if (make.toLowerCase() === 'unknown') {
				make = '';
			}

			if (name.toLowerCase() === 'unknown') {
				name = '';
			}

			var title = (name + ' ' + make + ' ' + model).trim();
			return title.length ? title : (0, _utils.loc)('Unknown');
		}),

		makeModel: Ember.computed('equipmentModel.{title,equipmentMake.title}', function () {
			var make = this.get('equipmentModel.equipmentMake.title') || '';
			var model = this.get('equipmentModel.title') || '';

			// prevents the title from saying `unknown unknown`
			if (model.toLowerCase() === 'unknown' && make.toLowerCase() === 'unknown') {
				return (0, _utils.loc)('Unknown');
			} else {
				return (make + ' ' + model).trim();
			}
		}),

		equipmentCategory: Ember.computed('equipmentModel.equipmentCategory', function () {
			return this.get('equipmentModel.equipmentCategory.title');
		}),

		equipmentImage: Ember.computed('imageUrl', 'equipmentModel.{imageUrl,equipmentMake.imageUrl,equipmentCategory.imageUrl}', function () {
			if (Ember.isNone(this.get('imageUrl')) && Ember.isNone(this.get('equipmentModel.imageUrl')) && Ember.isNone(this.get('equipmentModel.equipmentMake.imageUrl')) && Ember.isNone(this.get('equipmentModel.equipmentCategory.imageUrl'))) {
				return '/images/80x80.png';
			} else if (Ember.isNone(this.get('imageUrl')) && Ember.isNone(this.get('equipmentModel.imageUrl')) && Ember.isNone(this.get('equipmentModel.equipmentCategory.imageUrl'))) {
				return this.get('equipmentModel.equipmentMake.imageUrl');
			} else if (Ember.isNone(this.get('imageUrl')) && Ember.isNone(this.get('equipmentModel.imageUrl'))) {
				return this.get('equipmentModel.equipmentCategory.imageUrl');
			} else if (Ember.isNone(this.get('imageUrl'))) {
				return this.get('equipmentModel.imageUrl');
			} else {
				return this.get('imageUrl');
			}
		}),

		equipmentThumb: Ember.computed('imageThumbUrl', 'equipmentModel.{imageThumbUrl,equipmentMake.imageThumbUrl,equipmentCategory.imageThumbUrl}', function () {
			if (Ember.isNone(this.get('imageThumbUrl')) && Ember.isNone(this.get('equipmentModel.imageThumbUrl')) && Ember.isNone(this.get('equipmentModel.equipmentMake.imageThumbUrl')) && Ember.isNone(this.get('equipmentModel.equipmentCategory.imageThumbUrl'))) {
				return '/images/80x80.png';
			} else if (Ember.isNone(this.get('imageThumbUrl')) && Ember.isNone(this.get('equipmentModel.imageThumbUrl')) && Ember.isNone(this.get('equipmentModel.equipmentCategory.imageThumbUrl'))) {
				return this.get('equipmentModel.equipmentMake.imageThumbUrl');
			} else if (Ember.isNone(this.get('imageThumbUrl')) && Ember.isNone(this.get('equipmentModel.imageThumbUrl'))) {
				return this.get('equipmentModel.equipmentCategory.imageThumbUrl');
			} else if (Ember.isNone(this.get('imageThumbUrl'))) {
				return this.get('equipmentModel.imageThumbUrl');
			} else {
				return this.get('imageThumbUrl');
			}
		})
	});
});