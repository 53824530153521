define('busy-app/components/time/time-conflict/merge-entries', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		store: Ember.inject.service('store'),

		classNames: ['time-conflict-merge-entries'],

		entry1: null,
		entry2: null,

		showMore: false,
		isDisabled: false,

		mergedStartTime: null,
		mergedEndTime: null,
		mergedDescription: null,
		mergedProjectId: null,
		mergedCostCodeId: null,

		breakArray: null,
		projectArray: null,
		costCodeArray: null,
		equipmentArray: null,
		descriptionArray: null,

		init: function init() {
			this._super();
			this.setDefaults();
		},


		setDefaults: Ember.observer('entry1.id', 'entry2.id', function () {
			if (!Ember.isNone(this.get('entry1.id')) && !Ember.isNone(this.get('entry2.id'))) {
				this.set('mergedStartTime', this.get('entry1.startTime'));
				this.set('mergedEndTime', this.get('entry1.endTime'));

				this.setBreakArray();
				this.setProjects();
				this.setCostCodes();
				this.setEquipment();
				this.setDescriptions();
			}
		}),

		setBreakArray: function setBreakArray() {
			var breakArray = [];
			if (this.get('entry1.timeEntryBreaks.length') > 0) {
				this.get('entry1.timeEntryBreaks').forEach(function (timeEntryBreak) {
					timeEntryBreak.set('includeInMerge', false);
					breakArray.pushObject(timeEntryBreak);
				});
			}

			if (this.get('entry2.timeEntryBreaks.length') > 0) {
				this.get('entry2.timeEntryBreaks').forEach(function (timeEntryBreak) {
					timeEntryBreak.set('includeInMerge', false);
					breakArray.pushObject(timeEntryBreak);
				});
			}
			this.set('breakArray', breakArray.sortBy('startTime'));
		},


		canShowMore: Ember.computed('projectArray.length', 'costCodeArray.length', 'descriptionArray.length', function () {
			return this.get('projectArray.length') || this.get('costCodeArray.length') || this.get('descriptionArray.length') || this.get('equipmentArray.length');
		}),

		getModelByType: function getModelByType(entryNum, type) {
			var model = this.get('entry' + entryNum + '.' + type);
			if (model.isFulfilled !== undefined) {
				model = this.get('entry' + entryNum + '.' + type + '.content');
			}
			return model;
		},
		setAttachments: function setAttachments(type) {
			var items = [];
			var entry1Id = this.get('entry1.' + type + 'Id');
			var entry2Id = this.get('entry2.' + type + 'Id');
			var entry1 = this.getModelByType(1, type);
			var entry2 = this.getModelByType(2, type);

			if (!Ember.isNone(entry1Id) && entry1Id === entry2Id) {
				if (!Ember.isNone(entry1)) {
					entry1.set('entryTitle', (0, _utils.loc)('Both'));
					entry1.set('_selected', true);
					this.set('merged' + Ember.String.classify(type) + 'Id', entry1.id);
					items.push(entry1);
				}
			} else {
				var selected = false;
				if (!Ember.isNone(entry1Id)) {
					if (!Ember.isNone(entry1)) {
						selected = true;
						entry1.set('entryTitle', (0, _utils.loc)('Time entry 1'));
						entry1.set('_selected', selected);
						this.set('merged' + Ember.String.classify(type) + 'Id', entry1.id);
						items.push(entry1);
					}
				}

				if (!Ember.isNone(entry2Id)) {
					if (!Ember.isNone(entry2)) {
						entry2.set('entryTitle', (0, _utils.loc)('Time entry 2'));
						if (!selected) {
							entry2.set('_selected', true);
							this.set('merged' + Ember.String.classify(type) + 'Id', entry2.id);
						}
						items.push(entry2);
					}
				}
			}

			this.set(type + 'Array', items);
		},
		setProjects: function setProjects() {
			this.setAttachments('project');
		},
		setCostCodes: function setCostCodes() {
			this.setAttachments('costCode');
		},
		setEquipment: function setEquipment() {
			this.setAttachments('equipment');
		},
		setDescriptions: function setDescriptions() {
			var objects = [];
			if (!Ember.isNone(this.get('entry1.description')) && this.get('entry1.description') === this.get('entry2.description')) {
				var desc = Ember.Object.create({
					description: this.get('entry1.description'),
					entryTitle: (0, _utils.loc)('Both'),
					_selected: true
				});
				this.set('mergedDescription', desc.get('description'));
				objects.push(desc);
			} else {
				var selected = false;
				if (!Ember.isNone(this.get('entry1.description'))) {
					var _desc = Ember.Object.create({
						description: this.get('entry1.description'),
						entryTitle: (0, _utils.loc)('Time entry 1'),
						_selected: true
					});
					selected = true;
					this.set('mergedDescription', _desc.get('description'));
					objects.push(_desc);
				}

				if (!Ember.isNone(this.get('entry2.description'))) {
					var _desc2 = Ember.Object.create({
						description: this.get('entry2.description'),
						entryTitle: (0, _utils.loc)('Time entry 2')
					});
					if (!selected) {
						_desc2.set('_selected', true);
						this.set('mergedDescription', _desc2.get('description'));
					}
					objects.push(_desc2);
				}
			}

			this.set('descriptionArray', objects);
		},
		mergeTimeEntries: function mergeTimeEntries() {
			var _this = this;

			// get entry 1 and 2
			var conflictItemOne = this.get('entry1');
			var conflictItemTwo = this.get('entry2');

			// set the merged properties on time entry 1
			conflictItemOne.set('startTime', this.get('mergedStartTime'));
			conflictItemOne.set('endTime', this.get('mergedEndTime'));
			conflictItemOne.set('projectId', this.get('mergedProjectId'));
			conflictItemOne.set('costCodeId', this.get('mergedCostCodeId'));
			conflictItemOne.set('equipmentId', this.get('mergedEquipmentId'));
			conflictItemOne.set('description', this.get('mergedDescription'));

			// set the merged ids for entry1 and entry2
			conflictItemOne.set('mergedTimeEntryId', conflictItemTwo.id);
			conflictItemTwo.set('mergedTimeEntryId', conflictItemOne.id);

			// get the old time entry logs and break logs
			return Ember.RSVP.hash({
				mergeLogs: this.get('store').query('client-time-entry-log', { time_entry_id: conflictItemTwo.id }),
				mergeBreakLogs: this.get('store').query('client-time-entry-break-log', { time_entry_id: conflictItemTwo.id }),
				breaks: this.mergeBreaks(conflictItemOne)
			}).then(function (data) {
				// copy the old logs to the new entry
				return _this.copyLogs(data.mergeLogs, data.mergeBreakLogs, data.breaks.addedBreaks, conflictItemOne.id, conflictItemTwo.id).then(function (entryLogData) {
					// set the action type to conflict resolved
					conflictItemOne.set('actionType', 80);

					// set the action type to deleted by resolution and delete the entry
					conflictItemTwo.set('actionType', 100);
					conflictItemTwo.set('deletedOn', _utils.Time.date().unix());

					return Ember.RSVP.hash({
						entry1: conflictItemOne._save(),
						entry2: conflictItemTwo._save()
					}).then(function (timeEntryData) {
						return _this.saveLogs(entryLogData).then(function () {
							return timeEntryData;
						});
					});
				});
			});
		},
		mergeBreaks: function mergeBreaks(timeEntry) {
			var deletedPromise = [];
			var addedPromise = [];

			// loop through the breaks and create new or delete unwanted breaks
			this.get('breakArray').forEach(function (breakEntry) {
				// add new breaks that are not already on the time entry
				if (breakEntry.get('includeInMerge') && breakEntry.get('timeEntryId') !== timeEntry.id) {
					// create break and copy its properties
					var newBreak = timeEntry.createBreak(breakEntry.get('startTime'), breakEntry.get('endTime'));
					newBreak.set('id', _utils.UUID.generate());
					newBreak.set('actionType', 80);
					newBreak.set('mergedTimeEntryBreakId', breakEntry.id);
					newBreak.set('startTimeDst', breakEntry.get('startTimeDst'));
					newBreak.set('startMetaOffset', breakEntry.get('startMetaOffset'));
					newBreak.set('endTimeDst', breakEntry.get('endTimeDst'));
					newBreak.set('endMetaOffset', breakEntry.get('endMetaOffset'));
					newBreak.set('paidBreak', breakEntry.get('paidBreak'));
					newBreak.set('startLocationId', breakEntry.get('startLocationId'));
					newBreak.set('endLocationId', breakEntry.get('endLocationId'));
					newBreak.set('startDeviceTime', breakEntry.get('startDeviceTime'));
					newBreak.set('endDeviceTime', breakEntry.get('endDeviceTime'));
					newBreak.set('startTimeTrusted', breakEntry.get('startTimeTrusted'));
					newBreak.set('endTimeTrusted', breakEntry.get('endTimeTrusted'));
					newBreak.set('updatedOn', breakEntry.get('updatedOn'));
					newBreak.set('createdOn', breakEntry.get('createdOn'));

					addedPromise.push(newBreak);
				} else if (!breakEntry.get('includeInMerge')) {
					// delete breaks not included
					timeEntry.removeBreak(breakEntry, true);
					deletedPromise.push(breakEntry);
				}
			});

			// return a hash with deleted and added breaks
			return Ember.RSVP.hash({
				addedBreaks: Ember.RSVP.all(addedPromise),
				deletedBreaks: Ember.RSVP.all(deletedPromise)
			});
		},
		saveLogs: function saveLogs(logs) {
			// save the time entry logs first
			var promise1 = [];
			logs.entryLogs.forEach(function (log) {
				promise1.push(log.save());
			});

			return Ember.RSVP.all(promise1).then(function (entryLogs) {
				// save the breaks logs
				var promise2 = [];
				logs.breakLogs.forEach(function (breakLog) {
					promise2.push(breakLog.save());
				});

				// return an object with both log arrays
				return Ember.RSVP.hash({
					entryLogs: entryLogs,
					breakLogs: Ember.RSVP.all(promise2)
				});
			});
		},
		copyLogs: function copyLogs(mergeLogs, mergeBreakLogs, breaks, timeEntryId, mergedEntryId) {
			var _this2 = this;

			var logsArray = [];
			var breakLogsArray = [];

			// loop through the entry logs first
			// there will alway be a one to many relationship
			// with entry logs and break logs.
			mergeLogs.forEach(function (entryLog) {
				// create new time entry log
				var newEntryLog = _this2.createLog(entryLog, timeEntryId, mergedEntryId);
				logsArray.push(newEntryLog);

				// find any break logs associated with the old entry log
				var breakLogs = mergeBreakLogs.filterBy('clientTimeEntryLogId', entryLog.id);
				breakLogs.forEach(function (breakLog) {
					// get the new break merged from the old time entry if it was merged
					var newBreak = breaks.findBy('mergedTimeEntryBreakId', breakLog.get('timeEntryBreakId'));
					var newBreakId = null;

					// if the break was not moved over
					// then dont copy the logs over
					if (!Ember.isNone(newBreak)) {
						newBreakId = newBreak.id;
					}

					// create the new time entry break log
					var newBreakLog = _this2.createBreakLog(breakLog, newEntryLog.id, timeEntryId, newBreakId);
					breakLogsArray.push(newBreakLog);
				});
			});

			return Ember.RSVP.hash({
				entryLogs: logsArray,
				breakLogs: breakLogsArray
			});
		},
		createLog: function createLog(log, timeEntryId, mergedTimeEntryId) {
			return this.get('store').createRecord('client-time-entry-log', {
				id: _utils.UUID.generate(),
				updaterMemberId: log.get('updaterMemberId'),
				originalTimeEntryId: mergedTimeEntryId,
				mergedTimeEntryId: mergedTimeEntryId,
				timeEntryId: timeEntryId,
				startTime: log.get('startTime'),
				endTime: log.get('endTime'),
				offset: log.get('offset'),
				metaOffset: log.get('metaOffset'),
				daylightSavingTime: log.get('daylightSavingTime'),
				metaDaylightSavingTime: log.get('metaDaylightSavingTime'),
				description: log.get('description'),
				memberId: log.get('memberId'),
				projectId: log.get('projectId'),
				costCodeId: log.get('costCodeId'),
				actionType: log.get('actionType'),
				timeEntryCreatedOn: log.get('createdOn'),
				timeEntryDeletedOn: log.get('deletedOn'),
				updatedOn: log.get('updatedOn'),
				createdOn: log.get('createdOn'),
				submittedOn: log.get('submittedOn'),
				deletedOn: log.get('deletedOn'),
				deviceType: log.get('deviceType'),
				startLocationId: log.get('startLocationId'),
				endLocationId: log.get('endLocationId'),
				startDeviceTime: log.get('startDeviceTime'),
				endDeviceTime: log.get('endDeviceTime'),
				startTimeTrusted: log.get('startTimeTrusted'),
				endTimeTrusted: log.get('endTimeTrusted')
			});
		},
		createBreakLog: function createBreakLog(log, logId, timeEntryId, breakId) {
			return this.get('store').createRecord('client-time-entry-break-log', {
				timeEntryId: timeEntryId,
				timeEntryBreakId: breakId || log.get('timeEntryBreakId'),
				clientTimeEntryLogId: logId,
				originalTimeEntryBreakId: log.get('originalTimeEntryBreakId'),
				mergedTimeEntryBreakId: log.get('originalTimeEntryBreakId'),
				startMetaOffset: log.get('startMetaOffset'),
				paidBreak: log.get('paidBreak'),
				startTimeDst: log.get('startTimeDst'),
				endMetaOffset: log.get('endMetaOffset'),
				endTimeDst: log.get('endTimeDst'),
				timeEntryBreakCreatedOn: log.get('timeEntryBreakCreatedOn'),
				timeEntryBreakDeletedOn: log.get('timeEntryBreakDeletedOn'),
				updaterMemberId: log.get('updaterMemberId'),
				startTime: log.get('startTime'),
				endTime: log.get('endTime'),
				actionType: log.get('actionType'),
				updatedOn: log.get('updatedOn'),
				createdOn: log.get('createdOn'),
				submittedOn: log.get('submittedOn'),
				deletedOn: log.get('deletedOn'),
				deviceType: log.get('deviceType'),
				startLocationId: log.get('startLocationId'),
				endLocationId: log.get('endLocationId'),
				startDeviceTime: log.get('startDeviceTime'),
				endDeviceTime: log.get('endDeviceTime'),
				startTimeTrusted: log.get('startTimeTrusted'),
				endTimeTrusted: log.get('endTimeTrusted')
			});
		},


		actions: {
			toggleDetails: function toggleDetails() {
				this.set('showMore', !this.get('showMore'));
			},
			selectStartTime: function selectStartTime(value) {
				this.set('mergedStartTime', value);
			},
			selectEndTime: function selectEndTime(value) {
				this.set('mergedEndTime', value);
			},
			selectProject: function selectProject(value) {
				this.set('mergedProjectId', value);
			},
			selectCostCode: function selectCostCode(value) {
				this.set('mergedCostCodeId', value);
			},
			selectDescription: function selectDescription(value) {
				this.set('mergedDescription', value);
			},
			saveMerge: function saveMerge() {
				var _this3 = this;

				this.set('isDisabled', true);

				if (Ember.isNone(this.get('mergedStartTime')) || Ember.isNone(this.get('mergedEndTime'))) {
					return Ember.RSVP.reject('You must select a start and end time before merging.');
				}

				return this.mergeTimeEntries().then(function (data) {
					_this3.set('isDisabled', false);
					_this3.sendAction('onResolved', data.entry1, data.entry2);
					return (0, _utils.loc)('Conflict resolved');
				});
			},
			cancelAction: function cancelAction() {
				this.sendAction('onCancel');
			},
			backAction: function backAction() {
				this.sendAction('onBack');
			}
		}
	});
});