define('busy-app/routes/time-card/time-entries', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils'], function (exports, _authenticatedRouteMixin, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

		queryParams: {
			start: {
				refreshModel: true
			},
			member_id: {
				refreshModel: true
			}
		},

		model: function model(params) {
			var _this = this;

			var start = parseInt(params.start, 10);
			var memberId = params.member_id;

			_utils.Assert.isNumber(start);
			_utils.Assert.isUUID(memberId);

			var startOfDay = _utils.Time.date(params.start).startOf('day').unix();
			var endOfDay = _utils.Time.date(params.start).endOf('day').unix();

			return this.store.manager('time-entry-pto-range', startOfDay, endOfDay, params.member_id).then(function (model) {
				model.forEach(function (item) {
					if (item.get('isOpenEntry') && item.get('model.timeInterval') !== 60000) {
						item.get('model').setTimeInterval(60000);
					}
				});
				return _this.store.findRecord('member', memberId).then(function (member) {
					return { member: member, content: model };
				});
			});
		},

		actions: {
			reloadModel: function reloadModel() {
				this.refresh();
			}
		}
	});
});