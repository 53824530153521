define("busy-app/controllers/application", ["exports", "@busy-web/utils", "busy-app/utils/settings"], function (exports, _utils, _settings) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		session: Ember.inject.service("session"),
		features: Ember.inject.service("features"),

		isReady: false,

		init: function init() {
			this.set("isReady", true);

			if (window.BusyApp._application !== undefined) {
				window.BusyApp._application.set("controller", this);
			}

			if (_settings.default.get("build") !== "production") {
				var build = _settings.default.get("build") === "betaio" ? "beta" : _settings.default.get("build");
				Ember.$("html > head > title").html("busybusy (" + build + ")");
			}

			var osType = _utils.Browser.osType();
			Ember.$("body").addClass(Ember.String.dasherize(osType));

			var browserType = _utils.Browser.type();
			Ember.$("body").addClass(Ember.String.dasherize(browserType));

			this.sessionInit();

			this._super();
		},


		sessionInit: Ember.observer("session.isAuthenticated", function () {
			if (this.get("session.isAuthenticated")) {
				Ember.$("body").removeClass("no-auth");
				Ember.$("body").removeClass("signup");
			} else {
				Ember.$("body").addClass("no-auth");
			}
		}),

		homeUrl: Ember.computed(function () {
			return _settings.default.get("home_url");
		}),

		showNavView: Ember.computed("session.isAuthenticated", "currentPath", function () {
			var hideNavPaths = ["ticket", "account-ready", "not-found"];
			if (this.get("session.isAuthenticated") && hideNavPaths.indexOf(this.get("currentPath")) === -1) {
				return true;
			}

			return false;
		}),

		doRender: Ember.computed("currentPath", "session.isAuthenticated", "features.onboardingInterop", function () {
			var allowedUnauthenticatedRoutes = ["signup", "ticket", "not-found"];
			var currentPath = Ember.get(this, "currentPath");
			var isPathWhiteListed = allowedUnauthenticatedRoutes.includes(currentPath);

			var isInteropMode = Ember.get(this, "features.onboardingInterop");
			var isLoggedIn = Ember.get(this, "session.isAuthenticated");

			var doRender = isPathWhiteListed || !isInteropMode || isInteropMode && isLoggedIn;

			return doRender;
		})
	});
});