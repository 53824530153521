define('busy-app/components/charts/progress-chart', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-charts-progress-chart'],

		maxValue: 0,
		actualValue: 0,
		graphColor: '#555',
		graphColorSafe: Ember.computed('graphColor', function () {
			return Ember.String.htmlSafe('color: ' + this.get('graphColor') + ';');
		}),

		changeTimeout: null,

		percentComplete: Ember.computed('actualValue', 'maxValue', function () {
			var percent = 0;
			if (!Ember.isNone(this.get('actualValue')) && !Ember.isNone(this.get('maxValue')) && this.get('actualValue') > 0 && this.get('maxValue') >= 0) {
				percent = Math.round(this.get('actualValue') / this.get('maxValue') * 100);
			}

			if (percent > 999) {
				percent = 999;
			}
			return percent;
		}),

		percentString: Ember.computed('percentComplete', function () {
			if (this.get('percentComplete') > 999) {
				return 999 + '+';
			}
			return this.get('percentComplete');
		}),

		fillStyle: Ember.observer('percentComplete', 'graphColor', function () {
			var _this = this;

			// get timeout time
			var timeout = this.get('changeTimeout');

			// if timeout is null or undefined then clear the timeout
			if (!Ember.isNone(timeout)) {
				window.clearTimeout(timeout);
			}

			// set timeout and save the timeer
			var timer = window.setTimeout(function () {
				_this.setFillStyles();
			}, 500);
			this.set('changeTimeout', timer);
		}),

		didInsertElement: function didInsertElement() {
			this._super();
			this.setFillStyles();
		},
		setFillStyles: function setFillStyles() {
			if (!this.get('isDestroyed')) {
				// get percent
				var percent = this.get('percentComplete');

				// if percent is less than 100 then set it to 100
				percent = percent > 100 ? 100 : percent;

				var lastLeft = this.get('leftRotation') || 0;
				var lastRight = this.get('rightRotation') || 0;

				// left side calculations
				var leftPercent = percent > 50 ? percent - 50 : 0;
				var leftSpeed = 1300 * (Math.abs(lastLeft - leftPercent) / 100);
				var leftRotation = this.calcRotation(leftPercent);

				// right side calculations
				var rightPercent = percent <= 50 ? percent : 50;
				var rightSpeed = 1300 * (Math.abs(lastRight - rightPercent) / 100);
				var rightRotation = this.calcRotation(rightPercent);

				var leftCSS = Ember.String.htmlSafe('background:' + this.get('graphColor') + ';transition-duration:' + leftSpeed + 'ms;' + leftRotation);
				var rightCSS = Ember.String.htmlSafe('background:' + this.get('graphColor') + ';transition-duration:' + rightSpeed + 'ms;' + rightRotation);

				if (lastLeft === 0 && leftPercent > 0) {
					Ember.run.later(this, function () {
						this.set('leftRotation', leftPercent);
						this.set('leftFillStyle', leftCSS);
					}, rightSpeed);

					this.set('rightRotation', rightPercent);
					this.set('rightFillStyle', rightCSS);
				} else if (lastLeft > 0 && leftPercent === 0) {
					Ember.run.later(this, function () {
						this.set('rightRotation', rightPercent);
						this.set('rightFillStyle', rightCSS);
					}, leftSpeed);

					this.set('leftRotation', leftPercent);
					this.set('leftFillStyle', leftCSS);
				} else {
					this.set('leftRotation', leftPercent);
					this.set('leftFillStyle', leftCSS);
					this.set('rightRotation', rightPercent);
					this.set('rightFillStyle', rightCSS);
				}
			}
		},
		calcRotation: function calcRotation(percent) {
			// get degrees from the percent passed in
			var degrees = 360 * (percent / 100);

			// if degrees is less than 180 then set it to 180.
			degrees = degrees > 180 ? 180 : degrees;

			// return the css string for transform
			return Ember.String.htmlSafe('transform: rotate(' + degrees + 'deg);-webkit-transform: rotate(' + degrees + 'deg);-moz-transform: rotate(' + degrees + 'deg);-ms-transform: rotate(' + degrees + 'deg);-o-transform: rotate(' + degrees + 'deg);');
		}
	});
});