define('busy-app/utils/access', ['exports', 'busy-app/utils/logger'], function (exports, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.logPermission = exports.binaryPermissionToObject = exports.levelSelf = exports.levelLower = exports.levelSame = exports.levelHigher = exports.LEVEL_HIGHER = exports.LEVEL_SAME = exports.LEVEL_LOWER = exports.LEVEL_SELF = undefined;
	exports.hasPermission = hasPermission;

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var LEVEL_SELF = exports.LEVEL_SELF = 1;
	var LEVEL_LOWER = exports.LEVEL_LOWER = 2;
	var LEVEL_SAME = exports.LEVEL_SAME = 4;
	var LEVEL_HIGHER = exports.LEVEL_HIGHER = 8;

	function hasPermission(positionRecords, positionId, permissionTypes) {
		var minBinaryLevel = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : LEVEL_SELF;
		var strict = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;

		var position = _getPosition(positionRecords, positionId);
		var permissions = _getPermision(position, permissionTypes);

		return _reducePermission(permissions, minBinaryLevel, strict);
	}

	var _reducePermission = function _reducePermission(permissions) {
		var minBinaryLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : LEVEL_SELF;
		var strict = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
		return permissions.reduce(function (a, b) {
			if (strict === false) {
				return typeof b === 'boolean' ? a && b : a && b >= minBinaryLevel;
			} else {
				return typeof b === 'boolean' ? a || b : a || b >= minBinaryLevel;
			}
		}, true);
	};

	var _getPermision = function _getPermision(positionModel) {
		var permissionTypes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
		return permissionTypes.map(function (type) {
			return Ember.get(positionModel, type);
		});
	};

	var _getPosition = function _getPosition(positionRecords, id) {
		return positionRecords.find(function (p) {
			return p.id === id;
		});
	};

	var mod = function mod(value) {
		for (var _len = arguments.length, modValue = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
			modValue[_key - 1] = arguments[_key];
		}

		if (modValue.length === 0) {
			return value;
		}
		var mv = modValue.shift();
		return mod.apply(undefined, [value % mv].concat(modValue));
	};

	var levelHigher = exports.levelHigher = function levelHigher() {
		var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
		return value >= LEVEL_HIGHER;
	};

	var levelSame = exports.levelSame = function levelSame() {
		var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
		return mod(value, LEVEL_HIGHER) >= LEVEL_SAME;
	};

	var levelLower = exports.levelLower = function levelLower() {
		var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
		return mod(value, LEVEL_HIGHER, LEVEL_SAME) >= LEVEL_LOWER;
	};

	var levelSelf = exports.levelSelf = function levelSelf() {
		var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
		return mod(value, LEVEL_HIGHER, LEVEL_SAME, LEVEL_LOWER) >= LEVEL_SELF;
	};

	var binaryPermissionToObject = exports.binaryPermissionToObject = function binaryPermissionToObject() {
		var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
		return {
			higher: levelHigher(value),
			same: levelSame(value),
			lower: levelLower(value),
			self: levelSelf(value)
		};
	};

	var logPermission = exports.logPermission = function logPermission(positionModel, permissionKey) {
		var value = void 0;
		if (typeof positionModel === 'number') {
			value = positionModel;
		} else {
			value = Ember.get(positionModel, permissionKey);
		}

		if (!Ember.isNone(value)) {
			var key = permissionKey || 'permission';

			var block = _logger.default.block('<utils:access>', 'binaryPermission', key, value);
			block.table(_defineProperty({}, key, binaryPermissionToObject(value)));
			block.end();
		} else {
			_logger.default.error('<utils:access>', 'logPermission requires a (position <model> and a permission key <string> | binary value <number>)');
		}
	};
});