define('busy-app/components/company/integrations/vendors/quickbooks/sync-options', ['exports', 'moment', '@busy-web/utils', 'busy-app/utils/settings', 'busy-app/utils/logger'], function (exports, _moment, _utils, _settings, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	/***/
	var kMinSyncDate = (0, _moment.default)('2018-06-18', 'YYYY-MM-DD').utc().unix(); // sync date must be AFTER this date


	/*
  * The selected sync date must be after the server limited kMinSyncDate
  *   and it must be after the Closing Date set in the users quickbooks
  *
  * The selected date is inclusive
  */

	exports.default = Ember.Component.extend({
		auth: Ember.inject.service(),
		integration: Ember.inject.service(),
		payPeriod: Ember.inject.service('pay-period'),
		store: Ember.inject.service(),

		// attributes
		closeDialog: null,
		quickbooksConnected: false,

		// form values
		syncType: null,
		syncDate: null,
		isTemporary: true,

		// private properties
		_isLoading: false,
		_payPeriod: null,
		_prevPeriod: null,
		_closingDate: null,
		_featureMinSyncDate: kMinSyncDate,

		// do not allow any sync dates before the feature is supported, or before the date set as a Closing Date in QuickBooks
		_minSyncDate: Ember.computed('_featureMinSyncDate', '_closingDate', function () {
			var featureMinSyncDate = Ember.get(this, '_featureMinSyncDate');
			var closingDate = Ember.get(this, '_closingDate');

			// exclusionBoundary is the last date UNAVAILABLE for syncing
			// minDate is the first date AVAILABLE for syncing
			// QB's Closing Date is exclusionary, so the sync date must be AFTER that date
			var exclusionBoundary = this.safeMax(featureMinSyncDate, closingDate);
			var minDate = _utils.Time.date(exclusionBoundary).startOf('day').add(1, 'days').unix();

			return minDate;
		}),

		_recentTooltip: (0, _utils.loc)('Syncing of time entries in the current pay period will automatically stop seven days after the pay period ends. This prevents historical time entries from being unintentionally changed by updates or changes made in busybusy.'),
		_customTooltip: (0, _utils.loc)('Allow time entries to be synced back to the specified date.'),
		_syncDateTooOldError: Ember.computed('_minSyncDate', function () {
			return (0, _utils.loc)('The earliest valid sync date is %@', [_utils.Time.date(Ember.get(this, '_minSyncDate')).format('LL')]);
		}),

		_isSyncDateTooOld: Ember.computed('syncDate', '_minSyncDate', function () {
			return Ember.get(this, 'syncDate') < Ember.get(this, '_minSyncDate');
		}),

		// The default Cutoff Date is 7 days from the beginning of the previous pay period,
		// 	unless the Feature Min Date or the Closing date is more recent (_minSyncDate), then that value is used
		defaultCutoffDate: Ember.computed('_prevPayPeriod.startDate', '_minSyncDate', function () {
			var payPeriodStartDate = Ember.get(this, '_prevPayPeriod.startDate');
			var defaultCutoffDate = payPeriodStartDate.add(7, 'days').unix();
			var minDate = this.safeMax(defaultCutoffDate, Ember.get(this, '_minSyncDate'));

			return minDate;
		}),

		init: function init() {
			this._super();

			if (Ember.get(this, 'quickbooksConnected')) {
				this.initData();
			}
		},
		initData: function initData() {
			var _this = this;

			var _payPeriod = Ember.get(this, 'payPeriod').getPayPeriod();
			var _prevPayPeriod = Ember.get(this, 'payPeriod').getPayPeriod(_payPeriod.startDate.subtract(1, 'days').unix());

			this.safeSetProperties({
				_isLoading: true,
				_payPeriod: _payPeriod,
				_prevPayPeriod: _prevPayPeriod
			});

			return this.initOptionsData().finally(function () {
				_this.safeSet('_isLoading', false);
			});
		},
		initOptionsData: function initOptionsData() {
			var _this2 = this;

			_logger.default.info(this, 'initOptionsData');

			return Ember.RSVP.hash({
				manualDateData: this.rpcGetOrganizationManualDate(), // manualDate is the Sync Date set in this dialog, it can be NULL
				closingDateData: this.rpcGetOrganizationClosingDate() // closingDate comes from QB
			}).then(function (props) {
				var manualDateData = props.manualDateData,
				    closingDateData = props.closingDateData;

				var _closingDate = Ember.get(closingDateData, 'date');
				var manualDate = Ember.get(manualDateData, 'date');

				_logger.default.info(_this2, 'initOptionsData', { manualDateData: manualDateData, closingDateData: closingDateData, _closingDate: _closingDate, manualDate: manualDate });

				_this2.safeSetProperties({
					_closingDate: _closingDate,
					syncType: 'recent',
					syncDate: Ember.get(_this2, 'defaultCutoffDate'),
					isTemporary: true
				});

				if (Ember.isPresent(manualDate)) {
					var isPermanent = Ember.get(manualDateData, 'is_permanent');
					var isTemporary = !isPermanent;

					_this2.safeSetProperties({
						syncType: 'custom',
						syncDate: _utils.Time.date(manualDate).startOf('day').unix(),
						isTemporary: isTemporary
					});
				}

				_logger.default.info(_this2, 'initOptionsData', Ember.getProperties(_this2, 'syncType', 'syncDate', 'isTemporary'));

				return manualDateData.data;
			});
		},
		rpcRequestQuickbooks: function rpcRequestQuickbooks(method) {
			var _this3 = this;

			var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			// Logger.info(this, 'rpcRequestQuickbooks', { method, params });

			return Ember.get(this, 'store').rpcRequest('quick-books', method, params, _settings.default.get('qb_url')).then(function (result) {
				_logger.default.info(_this3, 'rpcRequestQuickbooks', method, { params: params, result: result });

				return Ember.get(result, 'data');
			});
		},
		rpcSetOrganizationManualDate: function rpcSetOrganizationManualDate() {
			var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

			// Logger.info(this, 'rpcSetOrganizationManualDate', { params });

			return this.rpcRequestQuickbooks('setOrganizationManualDate', params);
		},
		rpcGetOrganizationManualDate: function rpcGetOrganizationManualDate() {
			// Logger.info(this, 'rpcGetOrganizationManualDate');

			return this.rpcRequestQuickbooks('getOrganizationManualDate');
		},
		rpcGetOrganizationClosingDate: function rpcGetOrganizationClosingDate() {
			// Logger.info(this, 'rpcGetOrganizationClosingDate');

			return this.rpcRequestQuickbooks('getOrganizationClosingDate');
		},
		saveOptions: function saveOptions() {
			var params = Ember.get(this, 'syncType') === 'recent' ? {} : {
				date: Ember.get(this, 'syncDate'),
				is_permanent: !Ember.get(this, 'isTemporary')
			};

			_logger.default.info(this, 'saveOptions', { params: params });

			return this.rpcSetOrganizationManualDate(params);
		},
		safeSet: function safeSet(key, val) {
			if (!Ember.get(this, 'isDestroyed')) {
				return Ember.set(this, key, val);
			}
		},
		safeSetProperties: function safeSetProperties(props) {
			if (!Ember.get(this, 'isDestroyed')) {
				return Ember.setProperties(this, props);
			}
		},
		safeMax: function safeMax() {
			for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
				args[_key] = arguments[_key];
			}

			var safeInts = args.compact(); // undefined values make max() return NaN
			var maxValue = safeInts.length ? Math.max.apply(Math, _toConsumableArray(safeInts)) : null;

			return maxValue;
		},


		actions: {
			close: function close() {
				return this.closeDialog();
			},
			save: function save() {
				var _this4 = this;

				if (Ember.get(this, '_isSyncDateTooOld')) {
					return Ember.RSVP.reject(Ember.get(this, '_syncDateTooOldError'));
				}

				return this.saveOptions().then(function () {
					return _this4.sendAction('close');
				});
			},
			selectSyncType: function selectSyncType(syncType) {
				this.safeSet('syncType', syncType);

				return false; // don't bubble
			},
			toggleProperty: function toggleProperty(key) {
				this.toggleProperty(key);

				return false;
			}
		}
	});
});