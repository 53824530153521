define('busy-app/models/quickbooks-payroll-item-wage', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		listId: _emberData.default.attr('string'),
		organizationId: _emberData.default.attr('string'),
		wageItemName: _emberData.default.attr('string'),
		timeCreated: _emberData.default.attr('number'),
		timeModified: _emberData.default.attr('number'),
		wageType: _emberData.default.attr('string'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		wageTypeFormatted: Ember.computed('wageType', function () {
			var typeName = '';
			if (!Ember.isNone(this.get('wageType'))) {
				var type = this.get('wageType').underscore().split('_');
				type.forEach(function (word) {
					typeName += ' ' + word[0].toUpperCase() + word.slice(1);
				});
			}
			return typeName.trim();
		})
	});
});