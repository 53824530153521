define('busy-app/utils/models/managers/time-entry-pto-range', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/base', 'busy-app/utils/time-entry-validator'], function (exports, _utils, _base, _timeEntryValidator) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		start: null,
		end: null,
		sortOrder: 'desc',

		getSorted: function getSorted() {
			var sorted = this.get('model').sortBy('start');
			if (this.get('sortOrder') === 'desc') {
				sorted = sorted.reverse();
			}
			return sorted;
		},


		/**
   * fetches the data for a time-entry by range.
   *
   * @public
   * @method fetch
   * @param start {number} Required timestamp for the start of the range.
   * @param end {number} Required timestamp for the end of the range.
   * @param memberId {string} Optional id to filter by a member.
   * @param projectId {string} Optional param to filter by a top level project and its children.
   * @param costCodeId {string} Optional param to filter by a cost code.
   * @param exclusive {boolean} Default false, true will force the filter by projectId to disinclude children.
   * @return {promise}
   */
		fetch: function fetch(start, end, memberId, projectId, costCodeId, equipmentId, exclusive, sortOrder) {
			sortOrder = !Ember.isNone(sortOrder) ? sortOrder : 'desc';
			exclusive = !Ember.isNone(exclusive) ? exclusive : false;

			(false && !(sortOrder === 'desc' || sortOrder === 'asc') && Ember.assert('sortOrder must be a String [desc | asc]', sortOrder === 'desc' || sortOrder === 'asc'));


			this.set('start', start);
			this.set('end', end);
			this.set('sortOrder', sortOrder);

			return this.dispatcherFor('time-entry-segment').findAllByRange(start, end, memberId, projectId, costCodeId, equipmentId, exclusive);
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			var start = this.get('start');
			var end = this.get('end');

			var entries = data.timeEntries.sortBy('startTime');
			entries.forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));
				_this.checkConflict(model, entries);
				_this.addCollection(model, start, end);
			});

			var timeOffEntries = data.timeOffEntries.sortBy('dateStamp');
			timeOffEntries.forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));
				_this.addCollection(model, start, end, false);
			});

			var openEntries = data.openTimeEntries.sortBy('startTime');
			openEntries.forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));
				_this.addCollection(model, start, end);
			});

			this.set('content', this.get('content').sortBy('sortTime'));
		},
		checkConflict: function checkConflict(model, entries) {
			model.set('hasConflict', false);
			entries.forEach(function (conflictModel) {
				if (model.id !== conflictModel.id && model.get('memberId') === conflictModel.get('memberId')) {
					if (_timeEntryValidator.default.hasConflict(model, conflictModel)) {
						model.set('hasConflict', true);
					}
				}
			});
		},


		totalSeconds: Ember.computed('model.@each.{regularSeconds,overtimeSeconds,doubletimeSeconds}', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('regularSeconds') + item.get('overtimeSeconds') + item.get('doubletimeSeconds');
				});
			}
			return total;
		}),

		/**
   * totalTimeString is the total time calculated from the time entry segments using
   * the regularSeconds, overtimeSeconds, and doubletimeSeconds
   *
   */
		totalTimeString: Ember.computed('totalSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('totalSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		}),

		regularSeconds: Ember.computed('model.@each.{regularSeconds,timeOffSeconds}', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('regularSeconds');
				});
			}
			return total;
		}),

		/**
   * regularTimeString is the regulartime calculated from the time entry segments
   *
   */
		regularTimeString: Ember.computed('regularSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('regularSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		}),

		overtimeSeconds: Ember.computed('model.@each.overtimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('overtimeSeconds');
				});
			}
			return total;
		}),

		/**
   * overtimeTimeString is the overtime calculated from the time entry segments
   *
   */
		overtimeTimeString: Ember.computed('overtimeSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('overtimeSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		}),

		doubletimeSeconds: Ember.computed('model.@each.doubletimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('doubletimeSeconds');
				});
			}
			return total;
		}),

		/**
   * doubletimeTimeString is the doubletime calculated from the time entry segments
   *
   */
		doubletimeTimeString: Ember.computed('doubletimeSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('doubletimeSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		}),

		breaktimeSeconds: Ember.computed('model.@each.breaktimeSeconds', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('breaktimeSeconds');
				});
			}
			return total;
		}),

		/**
   * breakTimeString is the break time calculated from the time entries
   *
   */
		breakTimeString: Ember.computed('breaktimeSeconds', function () {
			var timeObj = _utils.Time.convertSeconds(this.get('breaktimeSeconds'), true);
			return timeObj.hours + ':' + timeObj.minutes;
		})
	});
});