define('busy-app/utils/models/managers/time-card-report-csv', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/base'], function (exports, _utils, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	exports.default = _base.default.extend({
		start: null,
		end: null,
		memberId: null,
		projectId: null,

		showProject: false,
		showCostCode: false,
		showEquipment: false,

		hasCostCode: false,
		hasEquipment: false,

		filename: Ember.computed('start', 'end', function () {
			var start = _utils.Time.date(this.get('start')).format('MM-DD-YY');
			var end = _utils.Time.date(this.get('end')).format('MM-DD-YY');
			return 'Time_Card_Report_' + start + '_to_' + end;
		}),

		getSorted: function getSorted() {
			return this.get('model').sortBy('model.member.firstName', 'model.member.lastName', 'startTime', 'endTime');
		},


		dataMap: Ember.computed(function () {
			// dataMap for the csv export key:val object where key is the path to a
			// data property and the val is the header title
			var dataMap = {
				'model.member.memberNumber': 'Employee ID',
				'model.member.firstName': 'First Name',
				'model.member.lastName': 'Last Name',
				'startTimeString': 'Start',
				'endTimeString': 'End',
				'breakTimeString': 'Break',
				'totalTimeString': 'Total',
				'customer': 'Customer',
				'projectNumber': 'Project #',
				'depth1.title': 'Project',
				'projectNumber2': 'Subproject 1 #',
				'depth2.title': 'Subproject 1',
				'projectNumber3': 'Subproject 2 #',
				'depth3.title': 'Subproject 2',
				'projectNumber4': 'Subproject 3 #',
				'depth4.title': 'Subproject 3',
				'projectNumber5': 'Subproject 4 #',
				'depth5.title': 'Subproject 4',
				'projectNumber6': 'Subproject 5 #',
				'depth6.title': 'Subproject 5',
				'projectNumber7': 'Subproject 6 #',
				'depth7.title': 'Subproject 6'
			};

			if (this.get('hasCostCode')) {
				dataMap['model.costCode.costCode'] = 'Cost Code';
				dataMap['model.costCode.title'] = 'Cost Code Desc.';
			}

			if (this.get('hasEquipment')) {
				dataMap['model.equipment.fullName'] = 'Equipment';
			}

			dataMap['model.description'] = 'Description';

			return dataMap;
		}),

		fetch: function fetch(start, end, memberId, projectId) {
			this.set('start', start);
			this.set('end', end);
			this.set('memberId', memberId);
			this.set('projectId', projectId);

			return this.dispatcherFor('time-entry').findByDateRange(start, end, memberId, projectId);
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			data.timeEntries.forEach(function (item) {
				_this.set('hasCostCode', !Ember.isNone(item.get('costCode')));
				_this.set('hasEquipment', !Ember.isNone(item.get('equipment')));
				_this.addCollection(item);
			});

			data.timeOffEntries.forEach(function (item) {
				return _this.addCollection(item);
			});
		},
		addCollection: function addCollection(model) {
			var collection = this.collectionFor().push(model);
			collection.set('start', this.get('start'));
			collection.set('end', this.get('end'));

			this.setProjectPath(collection, model.get('project'));
			this.addInternalModel(collection);
		},
		setProjectPath: function setProjectPath(collection, project) {
			if (!Ember.isNone(project)) {
				var depth = project.get('depth');

				if (!Ember.isNone(depth)) {
					var _collection$setProper;

					var depthKey = 'depth' + depth;
					var projectInfoKey = !Ember.isNone(depth) && depth === 1 ? 'projectInfo' : 'projectInfo' + depth;
					var info = project.get('projectInfo');

					collection.setProperties((_collection$setProper = {}, _defineProperty(_collection$setProper, depthKey, project), _defineProperty(_collection$setProper, projectInfoKey, info), _collection$setProper));
				}

				if (!Ember.isNone(project.get('parentProject'))) {
					this.setProjectPath(collection, project.get('parentProject'));
				}
			}
		},
		_setProjectPath: function _setProjectPath(collection) {
			var fullPath = collection.get('model.project.fullTitle');
			if (!Ember.isNone(fullPath) && !Ember.isEmpty(fullPath)) {
				var path = fullPath.split(' > ');
				Ember.$.each(path, function (key, val) {
					if (path.hasOwnProperty(key)) {
						collection.set('depth' + (key + 1), val);
					}
				});
			}
		}
	});
});