define('busy-app/utils/models/dispatchers/member-pay-period', ['exports', 'busy-app/utils/models/dispatchers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	function _filterMembers(members, start) {
		var remove = [];
		members.forEach(function (item) {
			if (!Ember.isNone(item) && !Ember.isNone(item.get('archivedOn')) && item.get('archivedOn') < start) {
				remove.pushObject(item);
			}
		});
		members.removeObjects(remove);
		return members.sortBy('firstName', 'lastName');
	} /**
    * @module Utils/Models/Dispatchers
    *
    */


	function _getMembers(store, start, end, seconds, wage) {
		var memberIds = [];
		var wageMembers = [];

		seconds.forEach(function (item) {
			if (memberIds.indexOf(item.get('memberId')) === -1) {
				memberIds.push(item.get('memberId'));
			}
		});

		// salary employees with no time
		// needs to filter employees by start and end hire dates.
		wage.forEach(function (item) {
			if (memberIds.indexOf(item.get('memberId')) === -1 && wageMembers.indexOf(item.get('memberId')) === -1 && item.get('wageRate') !== 10) {
				wageMembers.push(item.get('memberId'));
			}
		});

		return store.findByIds('member', memberIds).then(function (members) {
			if (wageMembers.length > 0) {
				return store.findByIds('member', wageMembers, { _lte: { created_on: end } }).then(function (membersTwo) {
					members.pushObjects(membersTwo.toArray());
					return _filterMembers(members, start);
				});
			} else {
				return _filterMembers(members, start);
			}
		});
	}

	/**
  * `Util/Model/Dispatcher/MemberPayPeriod`
  *
  * @class MemberPayPeriod
  * @namespace Utils.Models.Dispatchers
  * @extends Utils.Models.Dispatchers.Base
  */
	exports.default = _base.default.extend({
		auth: Ember.inject.service(),

		find: function find(start, end) {
			var _this = this;

			(false && !(!Ember.isNone(start)) && Ember.assert('You must provide a start {number} to member-pay-period dispatcher', !Ember.isNone(start)));
			(false && !(!Ember.isNone(end)) && Ember.assert('You must provide a end {number} to member-pay-period dispatcher', !Ember.isNone(end)));


			var useGroupPermissionScoping = Ember.get(this, 'auth.useGroupPermissionScoping');
			var store = Ember.get(this, 'store');

			return Ember.RSVP.hash({
				cost: store.findAll('pay-period-member-cost', { start_time: start, end_time: end }),
				groupScopedMembers: this.loadGroupScopedMembers(useGroupPermissionScoping),
				seconds: store.findAll('pay-period-member-seconds', { start_time: start, end_time: end }),
				wage: store.findAll('wage-history') //, {_lte: {change_date: start}, _desc: ['change_date']}) // commented because the api gives back multile of the same record.
			}).then(function (_ref) {
				var cost = _ref.cost,
				    groupScopedMembers = _ref.groupScopedMembers,
				    seconds = _ref.seconds,
				    wage = _ref.wage;

				if (useGroupPermissionScoping) {
					var groupScopedMemberIds = new Set(groupScopedMembers.mapBy('id'));

					cost = _this.filterByMember(cost, groupScopedMemberIds);
					seconds = _this.filterByMember(seconds, groupScopedMemberIds);
					wage = _this.filterByMember(wage, groupScopedMemberIds);
				}

				return Ember.RSVP.hash({
					cost: cost,
					seconds: seconds,
					wage: wage,
					members: _getMembers(store, start, end, seconds, wage)
				});
			});
		},
		loadGroupScopedMembers: function loadGroupScopedMembers(useGroupPermissionScoping) {
			if (!useGroupPermissionScoping) {
				return Ember.RSVP.resolve(null);
			}

			var authMember = Ember.get(this, 'auth.member');

			var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
			    id = _EmberGetProperties.id,
			    memberGroupId = _EmberGetProperties.memberGroupId;

			var query = {
				archived_on: null,
				member_group_id: memberGroupId
			};

			if (Ember.isNone(memberGroupId)) {
				Ember.setProperties(query, { id: id });
			}

			return Ember.get(this, 'store').findAll('member', query);
		},
		filterByMember: function filterByMember(collections, memberIds) {
			return collections.filter(function (item) {
				return memberIds.has(Ember.get(item, 'memberId'));
			});
		}
	});
});