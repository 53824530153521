define('busy-app/models/equipment-category', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		title: _emberData.default.attr('string'),
		equipmentTypeId: _emberData.default.attr('string'),
		image: _emberData.default.attr('file'),
		imageUrl: _emberData.default.attr('string'),
		imageThumbUrl: _emberData.default.attr('string'),
		review: _emberData.default.attr('boolean'),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number')
	});
});