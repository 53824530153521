define('busy-app/controllers/scheduling', ['exports', '@busy-web/utils', 'busy-app/utils/date-range-pay-period', 'busy-app/utils/logger'], function (exports, _utils, _dateRangePayPeriod, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	var MOBILE_WIDTH = 980;

	/**
  *
  */
	exports.default = Ember.Controller.extend({
		queryParams: ['start', 'end', 'selected', 'custom', 'isMobile', 'currentDay', 'group'],

		// queryParams
		start: 0,
		end: 0,
		currentDay: 0,
		selected: 'weekly',
		custom: false,
		isMobile: false,
		group: null,

		// services
		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),
		subscription: Ember.inject.service('subscription'),
		proFeature: Ember.inject.service('pro-feature'),
		payPeriod: Ember.inject.service('payPeriod'),
		features: Ember.inject.service('features'),
		router: Ember.inject.service('router'),

		showAdd: false,
		isBreak: false,

		minDate: null,
		maxDate: null,
		memberGroups: null,

		init: function init() {
			this._super();

			Ember.set(this, 'currentDay', _utils.Time.date().startOf('day').unix());

			defaultParams(this, {
				start: Ember.get(this, 'start'),
				end: Ember.get(this, 'end'),
				selected: Ember.get(this, 'selected'),
				custom: Ember.get(this, 'custom'),
				isMobile: Ember.get(this, 'isMobile')
			});

			Ember.set(this, 'mobileDateRangeActions', [Ember.Object.create({ id: 'daily', name: (0, _utils.loc)('Daily'), span: 1, type: 'days', sort: 100, selected: true })]);

			Ember.set(this, 'dateRangeActions', [Ember.Object.create({ id: 'weekly', name: (0, _utils.loc)('Weekly'), span: 1, type: 'weeks', sort: 200, selected: true }), Ember.Object.create({ id: 'monthly', name: (0, _utils.loc)('Monthly'), span: 1, type: 'months', sort: 300, selected: false }), (0, _dateRangePayPeriod.default)(Ember.get(this, 'payPeriod'))]);

			Ember.set(this, 'minDate', _utils.Time.date().subtract(3, 'years').startOf('day').unix());
			Ember.set(this, 'maxDate', _utils.Time.date().add(3, 'years').endOf('day').unix());
		},


		scheduleOptions: Ember.computed(function () {
			return [Ember.Object.create({ key: 'work', value: 'Schedule work' }), Ember.Object.create({ key: 'break', value: 'Schedule break' })];
		}),

		groupFilterDefaultOptions: Ember.computed('group', function () {
			var group = this.get('group');

			return [Ember.Object.create({ id: null, groupName: (0, _utils.loc)('All Members'), defaultGroup: true, _selected: Ember.isEmpty(group) }), Ember.Object.create({ id: 'ungrouped', groupName: (0, _utils.loc)('Ungrouped'), ungroupedGroup: true, _selected: group === 'ungrouped' })];
		}
		// EmberObject.create({id: 'archived', groupName: loc('Archived'), archivedGroup: true, _selected: (group === 'archived')})
		),

		groupFilterOptions: Ember.computed('group', 'model.memberGroups.[]', function () {
			var group = this.get('group');
			var memberGroups = this.get('model.memberGroups');

			return memberGroups.map(function (memberGroup) {
				return Ember.Object.create(Object.assign({ _selected: group === memberGroup.get('id') }, memberGroup.getProperties('id', 'groupName')));
			});
		}),

		showScheduleButton: Ember.computed('auth.member.positionId', function () {
			var position = this.get('auth.authMemberPosition');
			if (position.get('timeEvents') > 0) {
				return true;
			}
			return false;
		}),

		isGroupFilterApplied: Ember.computed('group', function () {
			return !Ember.isNone(Ember.get(this, 'group'));
		}),

		combinedGroupFilterOptions: Ember.computed('groupFilterOptions.@each._selected', 'groupFilterDefaultOptions.@each._selected', function () {
			return [].concat(_toConsumableArray(Ember.get(this, 'groupFilterOptions')), _toConsumableArray(Ember.get(this, 'groupFilterDefaultOptions')));
		}),

		selectedGroupFilter: Ember.computed('combinedGroupFilterOptions.@each._selected', function () {
			return Ember.get(this, 'combinedGroupFilterOptions').findBy('_selected');
		}),

		actions: {
			resizeHandler: function resizeHandler(event, width) {
				var custom = false;
				var isMobile = false;
				var selected = 'weekly';

				if (width < MOBILE_WIDTH) {
					isMobile = true;
					selected = 'daily';
				}

				if (Ember.get(this, 'isMobile') !== isMobile) {
					var queryParams = defaultParams(this, { start: 0, end: 0, selected: selected, custom: custom, isMobile: isMobile });
					this.transitionToRoute('scheduling', { queryParams: queryParams });
					this.send('refreshModel');
				}
			},
			dateChange: function dateChange(start, end, custom, selected) {
				if (selected === 'weekly' && Ember.get(this, 'selected') !== selected) {
					end = null;
				}

				var queryParams = defaultParams(this, { start: start, end: end, selected: selected, custom: custom, isMobile: Ember.get(this, 'isMobile') });
				this.transitionToRoute('scheduling', { queryParams: queryParams });
				this.send('refreshModel');
			},
			addScheduleSaved: function addScheduleSaved() {
				Ember.set(this, 'showAdd', false);
				this.send('refreshModel');
			},
			scheduleAction: function scheduleAction(item) {
				Ember.set(this, 'isBreak', item.key === 'break');
				Ember.set(this, 'showAdd', true);
			},
			closeAddDialog: function closeAddDialog() {
				Ember.set(this, 'showAdd', false);
			},
			onSelectGroupFilter: function onSelectGroupFilter(item) {
				_logger.default.info(this, 'onSelectGroupFilter', item);

				this.set('group', Ember.get(item, 'id'));
				this.send('refreshModel');
			},
			clearGroupFilter: function clearGroupFilter() {
				this.send('onSelectGroupFilter', { id: null });
			}
		}
	});


	var defaultParams = function defaultParams(controller, _ref) {
		var _ref$start = _ref.start,
		    start = _ref$start === undefined ? 0 : _ref$start,
		    _ref$end = _ref.end,
		    end = _ref$end === undefined ? 0 : _ref$end,
		    _ref$selected = _ref.selected,
		    selected = _ref$selected === undefined ? 'weekly' : _ref$selected,
		    _ref$custom = _ref.custom,
		    custom = _ref$custom === undefined ? false : _ref$custom,
		    _ref$isMobile = _ref.isMobile,
		    isMobile = _ref$isMobile === undefined ? false : _ref$isMobile;

		if (window.innerWidth <= MOBILE_WIDTH && !isMobile) {
			selected = 'daily';
			custom = false;
			isMobile = true;
		} else if (window.innerWidth > MOBILE_WIDTH && isMobile) {
			selected = 'weekly';
			custom = false;
			isMobile = false;
		}

		// update controller in case params have changed
		Ember.set(controller, 'start', start);
		Ember.set(controller, 'end', end);
		Ember.set(controller, 'selected', selected);
		Ember.set(controller, 'custom', custom);
		Ember.set(controller, 'isMobile', isMobile);

		return { start: start, end: end, selected: selected, custom: custom, isMobile: isMobile };
	};
});