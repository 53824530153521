define('busy-app/components/time/time-conflict/resolve-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['resolve-dialog'],

		proFeature: Ember.inject.service('pro-feature'),
		features: Ember.inject.service('features'),
		store: Ember.inject.service('store'),

		day: null,
		member: null,
		isDisabled: false,
		showConflictDialog: false,

		showTop: false,
		showMore: false,
		dataLoaded: false,

		init: function init() {
			this._super();
			this.loadData();
		},


		loadData: Ember.observer('allowConflictResolution', 'member.id', 'startTime', 'endTime', function () {
			var _this = this;

			if (this.get('allowConflictResolution') && !Ember.isNone(this.get('member.id'))) {
				this.get('store').manager('time-card-conflicts', this.get('member.id'), this.get('startTime'), this.get('endTime')).then(function (data) {
					if (!_this.get('isDestroyed')) {
						_this.set('totalConflicts', data.get('length'));
						_this.set('conflicts', data);
						_this.set('dataLoaded', true);
					}
				});
			}
		}),

		allowConflictResolution: Ember.computed('features.conflict-resolution', function () {
			this.get('features.conflict-resolution');
			return this.get('proFeature').allowProFeature('conflict-resolution');
		}),

		startTime: Ember.computed('day', function () {
			var day = this.get('day');
			if (!Ember.isNone(day)) {
				return _utils.Time.date(day).startOf('day').unix();
			}
		}),

		endTime: Ember.computed('day', function () {
			var day = this.get('day');
			if (!Ember.isNone(day)) {
				return _utils.Time.date(day).endOf('day').unix();
			}
		}),

		hasConflicts: Ember.observer('totalConflicts', 'conflicts', function () {
			var totalConflicts = this.get('totalConflicts');
			var container = Ember.$('#c-conflict-resolution-banner-container');
			var text = (0, _utils.loc)("%@ time entries with conflicts.", [totalConflicts]);
			if (totalConflicts === 1) {
				text = (0, _utils.loc)("1 time entry with conflicts.", [totalConflicts]);
			}

			container.find('div > span').html(text);

			if (totalConflicts) {
				if (!container.hasClass('show')) {
					container.addClass('show');
					Ember.run.later(function () {
						return container.addClass('front');
					}, 300);
					Ember.$('body > .ember-view').addClass('conflict-bar');
				}
			} else {
				container.removeClass('front');
				Ember.run.later(function () {
					return container.removeClass('show');
				}, 100);
				Ember.$('body > .ember-view').removeClass('conflict-bar');
			}
		}),

		didInsertElement: function didInsertElement() {
			var _this2 = this;

			var viewId = this.get('elementId');
			var container = Ember.$('#c-conflict-resolution-banner-container');

			if (this.get('showTop')) {
				container.addClass('top');
			}

			if (this.get('showMore')) {
				container.addClass('more');
			}

			var action = container.find('div > a');
			action.unbind('click.' + viewId);
			action.bind('click.' + viewId, function () {
				return _this2.send('openConflictDialog');
			});
		},
		willDestroyElement: function willDestroyElement() {
			// remove conflict-bar class from main div
			Ember.$('body > .ember-view').removeClass('conflict-bar');

			var viewId = this.get('elementId');
			var container = Ember.$('#c-conflict-resolution-banner-container');
			container.removeClass('top');
			container.removeClass('more');
			container.removeClass('front');
			container.removeClass('show');

			var action = container.find('div > a');
			action.unbind('click.' + viewId);
		},


		actions: {
			openConflictDialog: function openConflictDialog() {
				this.set('showConflictDialog', true);
			},
			closeConflictDialog: function closeConflictDialog() {
				if (!this.get('isDisabled')) {
					this.set('showConflictDialog', false);
					this.sendAction('onDone');
				}
			}
		}
	});
});