define('busy-app/helpers/currency-symbol', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		/**
   * Ember service injection for auth
   *
   * @private
   * @property auth
   * @type Services.Auth
   */
		auth: Ember.inject.service('auth'),

		/**
   * Main helper entry point for the template helper function
   *
   * @public
   * @method compute
   * @return {string} currency symbol
   */
		compute: function compute() {
			if (!Ember.isNone(this.get('auth.currencySymbol'))) {
				return this.get('auth.currencySymbol');
			} else {
				return '$';
			}
		},


		/**
   * Ember observer to rerender the helper
   *
   * @private
   * @method rerender
   */
		rerender: Ember.observer('auth.currencySymbol', function () {
			this.recompute();
		})
	});
});