define('busy-app/services/store', ['exports', 'ember-data', '@busy-web/data', '@busy-web/utils', 'busy-app/utils/request-manager', 'busy-app/utils/container', 'busy-app/utils/query-builder'], function (exports, _emberData, _data, _utils, _requestManager, _container, _queryBuilder) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = _emberData.default.Store.extend(_data.StoreMixin, {
		_maxPageSize: 80,

		fetchContainer: function fetchContainer(models) {
			_utils.Assert.isArray(models);

			for (var _len = arguments.length, types = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
				types[_key - 1] = arguments[_key];
			}

			return _container.default.apply(undefined, [models].concat(_toConsumableArray(types)));
		},
		socket: function socket(modelType, query) {
			_utils.Assert.isString(modelType);

			var owner = Ember.getOwner(this);
			var socket = owner.factoryFor('util:models.socket').create(owner.ownerInjection());
			socket.store = this;

			if (typeof query === 'string') {
				return socket.findRecord(modelType, query);
			} else {
				return socket.query(modelType, query);
			}
		},
		filter: function filter(type) {
			_utils.Assert.isString(type);

			var filter = Ember.getOwner(this).lookup('util:models.filters.' + type);

			(false && !(!Ember.isNone(filter)) && Ember.assert('A Filter does not exist for ' + type.classify(), !Ember.isNone(filter)));


			filter.store = this;

			return filter;
		},
		dispatcher: function dispatcher(type) {
			_utils.Assert.isString(type);

			var dispatcher = Ember.getOwner(this).lookup('util:models.dispatchers.' + type);

			(false && !(!Ember.isNone(dispatcher)) && Ember.assert('No Dispatcher was found for ' + type.classify(), !Ember.isNone(dispatcher)));


			dispatcher.store = this;

			return dispatcher;
		},
		manager: function manager(managerType) {
			_utils.Assert.isString(managerType);

			var args = Array.prototype.slice.call(arguments, 1);
			var owner = Ember.getOwner(this);

			var manager = owner.factoryFor('util:models.managers.' + managerType).class;

			(false && !(!Ember.isNone(manager)) && Ember.assert('A manager does not exist for ' + managerType.classify(), !Ember.isNone(manager)));


			args.unshift(this);

			manager.typeName = managerType;

			return manager.fetch.apply(manager, args);
		},
		exportCSV: function exportCSV(managerType) {
			_utils.Assert.isString(managerType);

			var args = Array.prototype.slice.call(arguments, 1);
			var owner = Ember.getOwner(this);

			var _manager = owner.factoryFor('util:models.managers.' + managerType).class;

			(false && !(!Ember.isNone(_manager)) && Ember.assert('A manager does not exist for ' + managerType.classify(), !Ember.isNone(_manager)));


			_manager.typeName = managerType;

			var manager = _manager._create(owner.ownerInjection(), { content: Ember.A() });
			manager.store = this;

			return manager._fetch.apply(manager, args).then(function (data) {
				// get the csv export util
				var filename = manager.get('filename') || managerType.underscore();
				var exportCSV = owner.lookup('util:csv-export');

				// get the dataMap for mapping data from this
				// manager to the csv format.
				var dataMap = manager.get('dataMap');
				var isReversed = manager.get('isReversedDataMap') || false;

				(false && !(!Ember.isNone(dataMap)) && Ember.assert('To export a manager to a csv, the manager must provide a dataMap', !Ember.isNone(dataMap)));

				// set the datamap

				exportCSV.setDataMap(dataMap, isReversed);

				// get the data from the ProjectParser and pass it
				// to exportCSV.setData
				exportCSV.setData(data.getSorted());

				// call generate on exportCSV class and pass the file name.
				exportCSV.generate(filename);

				return true;
			});
		},


		/**
   *
   * @method csvExport
   * @param data {object[]}
   * @param dataMap {object}
   * @param filename {string}
   */
		csvExport: function csvExport(data, dataMap) {
			var filename = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "busybusy";
			var isReversed = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
			var bypassGetSorted = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

			var owner = Ember.getOwner(this);
			var exportCSV = owner.lookup('util:csv-export');

			// set the datamap and
			// set isReversed if the dataMap is formated with label: propertyPath
			// instead of propertyPath: label
			exportCSV.setDataMap(dataMap, isReversed);

			// get the data from the ProjectParser and pass it
			// to exportCSV.setData
			if (bypassGetSorted) {
				exportCSV.setData(data);
			} else {
				exportCSV.setData(data.getSorted());
			}

			// call generate on exportCSV class and pass the file name.
			exportCSV.generate(filename);

			return Ember.RSVP.resolve();
		},
		_filterByQuery: function _filterByQuery(models, query) {
			var excludeKeys = ['_in', '_desc', '_asc', '_lte', '_gte', '_lt', '_gt', 'page', 'page_size'];
			for (var key in query) {
				if (query.hasOwnProperty(key) && excludeKeys.indexOf(key) === -1) {
					var property = Ember.String.camelize(key);

					var param = query[key];
					param = param === '_-NULL-_' ? null : param;
					if (param === '!_-NULL-_') {
						models.removeObjects(models.filterBy(property, null));
					} else if (param !== '_-DISABLE-_') {
						models = models.filterBy(property, param);
					}
				}
			}
			return models;
		},
		getter: function getter() {
			var owner = Ember.getOwner(this);
			return _requestManager.default.create(owner.ownerInjection(), {
				store: this,
				operations: Ember.A(),
				__storedOperations: Ember.A()
			});
		},
		buildQuery: function buildQuery() {
			var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
			var modelName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

			if (typeof query === 'string') {
				modelName = query;
				query = {};
			}

			var model = void 0;
			if (!Ember.isEmpty(modelName)) {
				var owner = Ember.getOwner(this);
				modelName = Ember.String.dasherize(modelName);
				model = owner.factoryFor('model:' + modelName);
			}
			return (0, _queryBuilder.default)(query, modelName, model.class);
		}
	}

	//_scheduleFetch(internalModel, options) {
	//	debugger;
	//  if (internalModel._loadingPromise) {
	//    return internalModel._loadingPromise;
	//  }

	//  let { id, modelName } = internalModel;
	//  let resolver = EmberPromise.defer(`Fetching ${modelName}' with id: ${id}`);
	//  let pendingFetchItem = {
	//    internalModel,
	//    resolver,
	//    options
	//  };

	//  let promise = resolver.promise;

	//  internalModel.loadingData(promise);
	//  if (this._pendingFetch.size === 0) {
	//    schedule('afterRender', this, this.flushAllPendingFetches);
	//  }

	//  this._pendingFetch.get(modelName).push(pendingFetchItem);

	//  return promise;
	//},
	);
});