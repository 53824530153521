define('busy-app/mixins/model-sync', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	/**
  * @module Mixins
  *
  */
	var INTERVAL = 10000;

	/**
  * `Mixin/ModelSync`
  *
  * Mixin class that adds a sync method to a DS.Model for syncing joined models according
  * to a sync property - like `model.updatedOn`
  *
  * @class ModelSync
  * @namespace Mixins
  * @extends Mixin
  */
	exports.default = Ember.Mixin.create({
		/**
   * The property to check for the syncstamp
   *
   * @property syncName
   * @type string
   * @default updatedOn
   */
		syncName: 'updatedOn',

		/**
   * Calls update or reload record every second until the model
   * most up to date model is retrieved
   *
   * This method has no return. Models will update when the sync is done
   * and can be accessed like normal on this model.
   *
   * @public
   * @method sync
   * @param type {string} path to joined model on this model
   */
		sync: function sync(type, expectModel) {
			var syncname = this.get('syncName');
			if (!Ember.isEmpty(type) && typeof this.get(syncname) === 'number') {
				__sync.call(this, type, syncname, expectModel);
			}
		}
	});


	/**
  * Calls the reload or update method on the adapterPopulated model
  * then checks the sync property to see if it is after the sync property
  * passed in
  *
  * @private
  * @method __syncModel
  * @param record {DS.AdapterPopulatedRecord}
  * @param syncstamp {number} the time to check the sync against
  * @param syncname {string} the name of the property to compare
  * @param expectModel {boolean} true if the sync expects to search until it finds a model
  * @return {EmberPromise|boolean}
  */
	function __syncModel(record, syncstamp, syncname, expectModel) {
		// return model is in sync when no timestamp is found
		if (Ember.isNone(syncstamp)) {
			return Ember.RSVP.resolve(true);
		}

		// set defualt promise if the record does not have reload or update method
		var promise = Ember.RSVP.resolve(null);

		if (record && record.reload) {
			// reload for a single record
			promise = record.reload();
		} else if (record && record.update && !record.get('isUpdating')) {
			// update for an array of records
			promise = record.update().then(function (model) {
				return model.sortBy(syncname).reverse().get('firstObject');
			});
		}

		// promise will return true if the data is synced and
		// it will return false if the data is out of sync
		return promise.then(function (item) {
			// default to in sync when item is null or undefiend or empty string or empty array
			if (Ember.isEmpty(item)) {
				return expectModel ? false : true;
			}

			// check if syncstamp is greater than item syncstamp
			if (syncstamp > item.get(syncname)) {
				return false; // false for not in sync
			} else {
				return true; // true for in sync
			}
		});
	}

	/**
  * calls the __syncModel method every second until it returns a truthy value
  *
  * @private
  * @method __sync
  * @param type {string} the name of the joined model to get from the this model
  * @param syncname {string} the name of the property to compare for this model and the joined model
  * @param expectModel {boolean} true if the sync expects to run until a model is found
  */
	function __sync(type, syncname, expectModel) {
		var _this = this;

		var tries = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;

		if (tries > 30) {
			return;
		}

		var record = this.get(type + '.content') || this.get(type);
		var syncstamp = this.get(syncname);
		__syncModel(record, syncstamp, syncname, expectModel).then(function (isSync) {
			if (!isSync) {
				Ember.run.later(_this, function () {
					__sync.call(this, type, syncname, expectModel, tries + 1);
				}, INTERVAL);
			}
		});
	}
});