define('busy-app/helpers/format-number', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	exports.default = Ember.Helper.extend({
		/**
   * Ember injected service for auth
   *
   * @private
   * @property auth
   * @type {Services.Auth}
   */
		auth: Ember.inject.service('auth'),

		/**
   * Conveinence Check to see if the browser is safari.
   *
   * @private
   * @property isSafari
   * @type {boolean}
   */
		isSafari: Ember.computed(function () {
			return (/safari/.test(window.navigator.userAgent.toLowerCase()) && !/chrome/.test(window.navigator.userAgent.toLowerCase())
			);
		}),

		/**
   * Computes the output for the helper
   *
   * @private
   * @method compute
   * @param value {number} The number to convert to a currency
   * @returns {string} The number with 2 decimals and a currency symbol.
   */
		compute: function compute(params) {
			var _params = _slicedToArray(params, 3),
			    _params$ = _params[0],
			    value = _params$ === undefined ? 0 : _params$,
			    _params$2 = _params[1],
			    minimumFractionDigits = _params$2 === undefined ? 1 : _params$2,
			    _params$3 = _params[2],
			    maximumFractionDigits = _params$3 === undefined ? minimumFractionDigits : _params$3;

			if (value === 'N/A') {
				return value;
			}

			if (!this.toLocaleStringSupportsOptions() && value !== 'N/A') {
				return value.toFixed(minimumFractionDigits);
			}

			return value.toLocaleString(window.navigator.language, { style: 'decimal', minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits });
		},
		toLocaleStringSupportsOptions: function toLocaleStringSupportsOptions() {
			return !!((typeof Intl === 'undefined' ? 'undefined' : _typeof(Intl)) == 'object' && Intl && typeof Intl.NumberFormat == 'function');
		},


		/**
   * Ember Observer method for recalculating the value.
   *
   * @private
   * @method rerender
   */
		rerender: Ember.observer('auth.currencySymbol', function () {
			this.recompute();
		})
	});
});