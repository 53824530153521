define('busy-app/utils/integrations/intercom', ['exports', '@busy-web/utils', 'busy-app/utils/settings', 'cryptojs', 'busy-app/utils/integrations/base'], function (exports, _utils, _settings, _cryptojs, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		auth: Ember.inject.service('auth'),
		session: Ember.inject.service('session'),
		subscription: Ember.inject.service('subscription'),

		featureName: 'integrations/analytics',

		isLoadReady: function isLoadReady() {
			return typeof window.Intercom === 'function';
		},
		isRunReady: function isRunReady() {
			if (this.isAuth()) {
				return !Ember.isNone(this.get('auth.organizationInfo.id')) && !Ember.isNone(this.get('subscription.subscriptionStatus.id'));
			} else {
				return true;
			}
		},
		load: function load(callback) {
			var _this = this;

			if (this.isAuth()) {
				var script = document.createElement('script');
				script.type = 'text/javascript';
				script.async = true;
				script.id = 'intercom-loader';
				script.src = 'https://widget.intercom.io/widget/' + _settings.default.get('intercom_app_key');

				var node = document.getElementsByTagName('script')[0];
				node.parentNode.insertBefore(script, node);

				// reattach intercom messenger
				window.Intercom('reattach_activator');

				// set up hide listener event for intercom.
				window.Intercom('onHide', function () {
					var $intercom = Ember.$('#intercom-container, .intercom-lightweight-app');
					if ($intercom) {
						$intercom.removeClass('show');
					}
				});

				window.Intercom('onShow', function () {
					// ensure the .show class gets added
					_this.showRecursive();
				});

				window.Intercom('onUnreadCountChange', function (unreadCount) {
					if (unreadCount > 0) {
						window.Intercom('show');
						_this.showRecursive();
					}
				});
			}
			callback();
		},


		// wait for the container element to be inserted to the dom, then add the .show className
		// by default it will look for the element 100 times, waiting 100ms between each attempt
		showRecursive: function showRecursive() {
			var _this2 = this;

			var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { attempt: 0, limit: 100, wait: 100 },
			    attempt = _ref.attempt,
			    limit = _ref.limit,
			    wait = _ref.wait;

			var $intercom = Ember.$('#intercom-container, .intercom-lightweight-app');
			var $container = Ember.$('#intercom-container');

			if (!$container.length && attempt < limit) {
				attempt++;
				return setTimeout(function () {
					return _this2.showRecursive({ attempt: attempt, limit: limit, wait: wait });
				}, wait);
			}

			return $intercom.addClass('show');
		},
		run: function run() {
			if (this.isAuth()) {
				// update intercom
				this.updateCompany({}, 'boot');
			} else {
				window.Intercom('boot', {
					app_id: _settings.default.get('intercom_app_key'),
					app_version: _settings.default.get('version')
				});
			}
		},
		restart: function restart() {
			if (this.isEnabled()) {
				// hide open intercom messages
				window.Intercom('hide');

				// shutdown intercom
				this.shutdown();

				// pause and restart Intercom
				Ember.run.later(this, function () {
					this._initializeRun();
				}, 100);
			}
		},
		update: function update() {
			if (this.isEnabled()) {
				if (this.isReady()) {
					window.Intercom('update', {
						app_id: _settings.default.get('intercom_app_key'),
						app_version: _settings.default.get('version')
					});
				}
			}
		},


		/**
   * Updates intercom with the company information provided
   * @public
   * @method updateIntercom
   * @param companyInfo {object} Other company data to send
   */
		updateCompany: function updateCompany(companyInfo) {
			var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'update';

			if (this.isEnabled()) {
				if (this.isReady()) {
					// survicate integration --------------
					var survicateId = this.get('auth.member.id');
					(function (opts) {
						opts.intercom = { user_id: survicateId };
					})(window.__sv = window.__sv || {});

					var publicKeyUrl = 'https://' + window.location.hostname + '?authorize=' + window.btoa(this.get('session.data.authenticated.token') + ':' + this.get('session.data.authenticated.id'));

					// -----------------
					var params = {
						app_id: _settings.default.get('intercom_app_key'),
						user_hash: _cryptojs.default.HmacSHA256(this.get('auth.member.id'), _settings.default.get('intercom_secret')).toString(),
						user_id: this.get('auth.member.id'),
						name: this.get('auth.member.firstName') + ' ' + this.get('auth.member.lastName'),
						email: this.get('auth.member.email') || null,
						phone: this.get('auth.member.phone') || null,
						created_at: this.get('auth.member.createdOn'),
						updated_at: this.get('auth.member.updatedOn'),
						last_request_at: _utils.Time.timestamp(),
						avatar: {
							type: 'avatar',
							image_url: this.get('auth.member.imageUrl') || null
						},
						user_agent_data: window.navigator.userAgent,
						username: this.get('auth.member.username'),
						app_version: _settings.default.get('version'),
						app_name: 'busybusy',
						platform: 'web',
						position_id: this.get('auth.authMemberPosition.id'),
						position_title: this.get('auth.authMemberPosition.title'),
						is_owner: this.get('auth.isOwner'),
						'public key url': publicKeyUrl,
						company: this.getCompanyInfo()
					};
					window.Intercom(type, params);
				}
			}
		},
		getCompanyInfo: function getCompanyInfo() {
			var company = {
				type: 'company',
				company_id: this.get('auth.organization.id'),
				name: this.get('auth.organization.organizationName'),
				remote_created_at: this.get('auth.organization.createdOn'),
				updated_at: this.get('auth.organization.updatedOn'),
				plan: this.get('subscription.subscriptionStatus.productHandle'),
				users: this.get('auth.memberCount'),
				//monthly_spend: parseFloat(this.get('subscription.currentPlanCost')), // not sending correct amount for now.
				'owner id': this.get('auth.organization.ownedBy'),
				phone: this.get('auth.organizationInfo.phone') || null,
				'chargify subscription id': this.get('subscription.subscriptionStatus.subscriptionId'),
				'chargify customer id': this.get('subscription.subscriptionStatus.customerId'),
				'chargify plan': this.get('subscription.subscriptionStatus.productHandle'),
				'chargify status': this.get('subscription.subscriptionStatus.status'),
				'chargify plan price': '$' + this.get('subscription.currentPlanCost') + '/per user',
				'chargify coupon code': this.get('subscription.subscriptionStatus.couponCode') || null,
				'chargify referral code': this.get('subscription.subscriptionStatus.referralCode') || null
			};

			if (this.get('auth.organization.ownedBy') === this.get('auth.member.id')) {
				company.email = this.get('auth.member.email'); //jshint ignore:line
			}

			if (!Ember.isNone(this.get('auth.organizationInfo.employeeMin'))) {
				var companySize = this.get('auth.organizationInfo.employeeMin');

				if (!Ember.isNone(this.get('auth.organizationInfo.employeeMax'))) {
					companySize += ' - ' + this.get('auth.organizationInfo.employeeMax');
				} else {
					companySize += '+';
				}

				company['est company size'] = companySize;
			}
			if (!Ember.isNone(this.get('auth.organizationInfo.trade'))) {
				company['company trade'] = this.get('auth.organizationInfo.trade');
			}

			return company;
		},
		show: function show() {
			var showMessages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

			if (this.isEnabled()) {
				var $intercom = Ember.$('#intercom-container, .intercom-lightweight-app');
				if ($intercom.length > 0 && !$intercom.hasClass('show')) {
					window.Intercom(showMessages ? 'showMessages' : 'show');
					$intercom.addClass('show');
				}
			}
		},
		hide: function hide() {
			if (this.isEnabled()) {
				var $intercom = Ember.$('#intercom-container, .intercom-lightweight-app');
				if ($intercom.length > 0) {
					$intercom.removeClass('show');
					window.Intercom('hide');
				}
			}
		},
		shutdown: function shutdown() {
			if (this.isEnabled()) {
				if (this.isReady()) {
					window.Intercom('shutdown');
					this.set('isInitialized', false);
				}
			}
		}
	});
});