define('busy-app/models/time-entry-location', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		timeEntryId: _emberData.default.attr('string'),
		triggerEvent: _emberData.default.attr('number'),
		longitude: _emberData.default.attr('number'),
		latitude: _emberData.default.attr('number'),
		horizontalAccuracy: _emberData.default.attr('number'),
		altitude: _emberData.default.attr('number'),
		verticalAccuracy: _emberData.default.attr('number'),
		timeGmt: _emberData.default.attr('number'),
		timeLocal: _emberData.default.attr('number'),
		timeZoneOffset: _emberData.default.attr('number'),
		timeDst: _emberData.default.attr('boolean'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		hasLocation: Ember.computed('longitude', 'latitude', function () {
			if (!Ember.isNone(this.get('longitude')) && !Ember.isNone(this.get('latitude'))) {
				return true;
			}
			return false;
		})
	});
});