define('busy-app/components/busy-pagination', ['exports', '@busy-web/utils/local-storage'], function (exports, _localStorage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-pagination'],

		pagination: null,
		totalRows: 0,

		pageSize: null,

		page: 1,
		maxPageSize: 1000,
		openTop: false,

		showPages: 5,
		showPageNumbers: false,

		selectMenu: null,

		init: function init() {
			this._super();

			this.pageChangeHandler();
			this.rowChange();
		},
		getPageSize: function getPageSize() {
			var size = this.get('pageSize') || _localStorage.default.getProperty('member-pagination-size');
			size = size === 'all' ? this.get('maxPageSize') : size;
			return parseInt(size, 10);
		},
		setPageSize: function setPageSize(size) {
			// check if pagesize is max page size
			size = size === 'all' ? this.get('maxPageSize') : size;

			// add pagesize to local storage for lookup after refresh
			_localStorage.default.setProperty('member-pagination-size', size);

			// set the pagesize and send changeSize action
			this.set('pageSize', size);
			return this;
		},


		pageChangeHandler: Ember.observer('pageSize', function () {
			// get page size
			var size = this.getPageSize();

			var selectMenu = [Ember.Object.create({ key: 30, value: 30, _selected: size === 30 }), Ember.Object.create({ key: 50, value: 50, _selected: size === 50 }), Ember.Object.create({ key: 75, value: 75, _selected: size === 75 }), Ember.Object.create({ key: 100, value: 100, _selected: size === 100 }), Ember.Object.create({ key: 200, value: 200, _selected: size === 200 }), Ember.Object.create({ key: 'all', value: 'all', _selected: size === this.get('maxPageSize') })];

			if (Ember.isNone(selectMenu.findBy('_selected', true))) {
				selectMenu.objectAt(0).set('_selected', true);
			}

			this.set('selectMenu', selectMenu);
		}),

		hasPaging: Ember.computed('totalRows', 'page', 'pageSize', function () {
			var size = this.getPageSize();
			var hasPaging = false;

			// always show page size when all is selected
			if (this.getPageSize() === this.get('maxPageSize')) {
				return true;
			}

			if (size > 30) {
				hasPaging = true;
			} else if (this.get('page') > 1) {
				hasPaging = true;
			} else if (this.get('totalRows') > size) {
				hasPaging = true;
			}
			return hasPaging;
		}),

		hasPrevPage: Ember.computed('page', function () {
			if (this.get('page') > 1) {
				return true;
			}
			return false;
		}),

		hasNextPage: Ember.computed('totalRows', 'pageSize', 'page', function () {
			var size = this.getPageSize();
			if (this.get('page') < Math.ceil(this.get('totalRows') / size)) {
				return true;
			}
			return false;
		}),

		hasPrevOrNext: Ember.computed('hasPrevPage', 'hasNextPage', function () {
			return this.get('hasPrevPage') || this.get('hasNextPage');
		}),

		rowChange: Ember.observer('totalRows', function () {
			if (this.get('totalRows') > 0) {
				this.setPageArray();
			}
		}),

		setPageArray: function setPageArray() {
			if (this.get('showPageNumbers')) {
				(false && Ember.warn("This is not a finished paging style. Do not use this"));


				var size = this.getPageSize();
				var totalPages = Math.ceil(this.get('totalRows') / size);
				var startPage = this.get('page') - 2;

				if (startPage < 1) {
					startPage = 1;
				} else if (startPage > totalPages - this.get('showPages')) {
					startPage = totalPages - this.get('showPages');
				}

				var pages = [];
				for (var i = startPage; i <= this.get('showPages'); i++) {
					pages.pushObject(Ember.Object.create({ page: i }));
				}

				this.set('pagination', pages);
			}
		},
		_changePage: function _changePage(page) {
			this.set('page', page);
			this.sendAction('changePage', page);
			this.setPageArray();
		},


		actions: {
			changePageSize: function changePageSize(item) {
				// get the page size
				var size = item.get('key');
				size = this.setPageSize(size).getPageSize();
				this.sendAction('changeSize', size);
			},
			openPage: function openPage(pageObj) {
				this._changePage(pageObj.get('page'));
			},
			prevPage: function prevPage() {
				if (this.get('page') > 1 && this.get('hasPrevPage')) {
					this._changePage(this.get('page') - 1);
				}
			},
			nextPage: function nextPage() {
				var size = this.getPageSize();
				if (this.get('page') < Math.ceil(this.get('totalRows') / size) && this.get('hasNextPage')) {
					this._changePage(this.get('page') + 1);
				}
			}
		}
	});
});