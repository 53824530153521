define('busy-app/utils/container/container-array', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.ArrayProxy.extend({
		sortKeys: null,
		reverseSort: false,
		type: null,

		sorted: function sorted() {
			if (!Ember.isEmpty(this.get('reverseSortKeys'))) {
				var sorted = this.sortBy.apply(this, this.get('reverseSortKeys'));
				sorted = sorted.reverse();
				return sorted;
			}

			if (!Ember.isEmpty(this.get('sortKeys'))) {
				var _sorted = this.sortBy.apply(this, this.get('sortKeys'));
				if (this.get('reverseSort')) {
					_sorted = _sorted.reverse();
				}
				return _sorted;
			}
		}
	});
});