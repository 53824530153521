define('busy-app/animations/report', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = timecard;
	function timecard(durationTo, durationBack) {
		//time card transitions
		var animate = ['report.payroll', 'report.payroll.result-member', 'report.payroll.result-project', 'report.signature', 'report.report-setup', 'report.employee-list', 'report.equipment-project', 'report.time-card', 'report.time-off'];

		this.transition(this.toRoute(animate), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));
	}
});