define('busy-app/components/company/deactivate-account', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-company-deactivate-account'],

		auth: Ember.inject.service('auth'),
		subscription: Ember.inject.service('subscription'),
		store: Ember.inject.service('store'),

		isDowngrade: true,
		isDelete: false,

		/**
   * Downgrades the account to a free account
   *
   * @private
   * @method cancelSubscription
   * @return {RSVP}
   */
		cancelSubscription: function cancelSubscription() {
			var waitForRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

			// only downgrade if the account is a pro account
			if (this.get('subscription.subscriptionStatus.isProAccount')) {
				// call subscription service downgrade method
				return this.get('subscription').setSubscriptionStatusFree(waitForRefresh);
			} else {
				// return promise if free account
				return Ember.RSVP.resolve();
			}
		},


		/**
   * Sets deletedOn on the organization model to delete
   * the organization
   *
   * @private
   * @method deleteAccount
   * @return {RSVP}
   */
		deleteAccount: function deleteAccount() {
			var _this = this;

			// archive all members first then delete organization
			return this.archiveMembers().then(function () {
				// set deletedOn
				_this.set('auth.organization.deletedOn', _utils.Time.timestamp());
				// return deleted org promise
				return _this.get('auth.organization').save();
			});
		},


		/**
   * Creates a new organizationCancelDetail Model and submits it
   *
   * @private
   * @method submitCancelReason
   * @return {RSVP}
   */
		submitCancelReason: function submitCancelReason() {
			// create and save cancel detail model
			return this.get('store').createRecord('organization-cancel-detail', {
				organizationId: this.get('auth.organization.id'),
				memberId: this.get('auth.member.id'),
				cancelReason: this.get('cancelReason')
			}).save();
		},


		/**
   * Updates the account to reflect the choice taken by the user in
   * the interface, Delete account or downgrade account.
   *
   * This method will also submit the cancel reason model if the textarea
   * is filled out by the user
   *
   * @public
   * @method updateAccount
   * @return {RSVP}
   */
		updateAccount: function updateAccount() {
			var _this2 = this;

			// submit cancel reason
			var cancelPromise = Ember.RSVP.resolve();
			if (!Ember.isEmpty(this.get('cancelReason'))) {
				cancelPromise = this.submitCancelReason(this.get('cancelReason'));
			}

			// wait for cancel reason to submit
			return cancelPromise.then(function () {
				// cancel subscription if downgrade or delete
				return _this2.cancelSubscription(_this2.get('isDowngrade')).then(function () {
					if (_this2.get('isDelete') === true) {
						// if delete then delete account
						return _this2.deleteAccount();
					} else {
						// otherwise return promise
						return Ember.RSVP.resolve();
					}
				});
			});
		},


		/**
   * Arvhives all members for an organization
   *
   * @private
   * @method archiveMembers
   * @return {RSVP}
   */
		archiveMembers: function archiveMembers() {
			var now = _utils.Time.timestamp();
			return this.get('store').findAll('member').then(function (model) {
				model.forEach(function (item) {
					item.set('archivedOn', now);
				});
				return model.save();
			});
		},


		actions: {
			submitAction: function submitAction(dialog) {
				// set save messages for cancel or delete
				var saveMessage = (0, _utils.loc)("Your account is being downgraded to free");
				if (this.get('isDelete') === true) {
					saveMessage = (0, _utils.loc)("your account is being deleted");
				}

				// show loading message
				dialog.showSavingMessage(saveMessage);

				// call update account to downgrade or delete account
				this.updateAccount().catch(function (err) {
					_logger.default.error(err);
					dialog.showErrorMessage((0, _utils.loc)("There was a problem deleting your account. Please contact customer service at 1-855-287-9287."));
				}).finally(function () {
					// show success message
					dialog.showSavedMessage();
					window.location.reload();
				});
			},
			selectCancelType: function selectCancelType(type) {
				// set flags to false
				this.set('isDowngrade', false);
				this.set('isDelete', false);

				// set selected flag to true
				this.set(type, true);
			},
			closeAction: function closeAction() {
				this.sendAction('onClose');
			}
		}
	});
});