define('busy-app/models/member-location', ['exports', '@busy-web/utils', 'ember-data'], function (exports, _utils, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		longitude: _emberData.default.attr('number'),
		latitude: _emberData.default.attr('number'),
		horizontalAccuracy: _emberData.default.attr('number'),
		altitude: _emberData.default.attr('number'),
		verticalAccuracy: _emberData.default.attr('number'),
		timeGmt: _emberData.default.attr('number'),
		timeLocal: _emberData.default.attr('number'),
		timeZoneOffset: _emberData.default.attr('number'),
		timeDst: _emberData.default.attr('boolean'),
		archivedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),

		member: _emberData.default.belongsTo('member'),

		hasLocation: Ember.computed('longitude', 'latitude', function () {
			if (!Ember.isNone(this.get('longitude')) && !Ember.isNone(this.get('latitude'))) {
				return true;
			}
			return false;
		}),

		timeLocalString: Ember.computed('timeLocal', function () {
			return _utils.Time.timeFormat(this.get('timeLocal'), 'h:mm A');
		})
	});
});