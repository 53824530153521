define('busy-app/routes/note/detail', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		model: function model(params) {
			var _this = this;

			return this.store.findRecord('media-collection', params.id).then(function (collection) {
				return _this.store.query('media-entry', { media_collection_id: collection.id, _desc: ['created_on'], page_size: 10000 }).then(function (mediaEntries) {
					return collection.get('member').then(function () {
						collection.set('mediaEntries', mediaEntries);
						return collection;
					});
				});
			});
		}
	});
});