define('busy-app/routes/report/activity/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'busy-app/utils/logger'], function (exports, _authenticatedRouteMixin, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		auth: Ember.inject.service(),

		model: function model() {
			var _this = this;

			var params = this.modelFor('report.activity');
			var type = params.type || 'member-project';

			var modelSecondsType = type + '-seconds';
			var modelCostType = type + '-cost';
			var dataType = this.showType(type);

			var controller = this.controllerFor('report/activity/index');
			controller.set('pController', this.controllerFor('report/activity'));

			// NOTE: the limit has been changed to 1000 as a temp fix until the api
			// gets their paging working.
			var query = {
				start_date: params.start,
				end_date: params.end,
				limit: 10000 //this will limit to 100 records per call, but will still find all records
			};

			if (dataType === 'day') {
				query = {
					_gte: { start_date: params.start },
					_lte: { start_date: params.end },
					deleted_on: "_-DISABLE-_"
				};
			}

			return Ember.RSVP.hash({
				seconds: this.store.findAll(modelSecondsType, query),
				cost: this.store.findAll(modelCostType, query),
				groupMembers: this.loadGroupMembers()
			}).then(function (data) {
				if (Ember.get(_this, 'auth.useGroupPermissionScoping')) {
					var memberGroupIds = new Set(Ember.get(data, 'groupMembers').mapBy('id'));

					data.seconds = data.seconds.filter(function (item) {
						return memberGroupIds.has(Ember.get(item, 'memberId'));
					});
				}

				var filter = [];
				data.seconds.forEach(function (item) {
					if (!Ember.isNone(item.get('totalTotalSeconds')) && item.get('totalTotalSeconds') === 0 || Ember.isNone(item.get('totalTotalSeconds')) && item.get('totalSeconds') === 0) {
						// filter out models with no seconds or cost
						filter.push(item);
					} else {
						var cost = data.cost.findBy('uid', item.get('uid'));
						if (!Ember.isNone(cost)) {
							item.set('cost', cost);
						} else {
							_logger.default.warn(_this, 'no match found', item);
						}
					}
				});

				// filter out 0 time results
				data.seconds.removeObjects(filter);

				var showType = dataType === 'day' ? 'startDate' : dataType;
				/*if (dataType !== 'day') {*/
				return _this.store.fetchContainer(data.seconds, showType).then(function (container) {
					container.set('showType', showType);
					container.set('settings', params);
					if (container.get(showType + '.length') > 0) {
						container.set('hasData', true);
					}
					return container;
				});
				/*} else {
    	const dataArray = [];
    	data.seconds.forEach(item => {
    		let dataObj = dataArray.findBy('startDateFormatted', item.get('startDateFormatted'));
    		if (!dataObj) {
    			dataObj = EmberObject.create({
    				startDateFormatted: item.get('startDateFormatted'),
    				regularCost: item.get('cost.regularCost'),
    				regularSeconds: item.get('regularSeconds'),
    				overtimeCost: item.get('cost.overtimeCost'),
    				overtimeSeconds: item.get('overtimeSeconds'),
    				doubletimeCost: item.get('cost.doubletimeCost'),
    				doubletimeSeconds: item.get('doubletimeSeconds'),
    				totalCost: item.get('cost.totalCost'),
    				totalSeconds: item.get('totalSeconds'),
    				startDate: item.get('startDate'),
    				endDate: item.get('endDate'),
    			});
    			dataArray.push(dataObj);
    		} else {
    			dataObj.regularSeconds += item.get('regularSeconds');
    			dataObj.regularCost += item.get('cost.regularCost');
    			dataObj.overtimeSeconds += item.get('overtimeSeconds');
    			dataObj.overtimeCost += item.get('cost.overtimeCost');
    			dataObj.doubletimeSeconds += item.get('doubletimeSeconds');
    			dataObj.doubletimeCost += item.get('cost.doubletimeCost');
    			dataObj.totalSeconds += item.get('totalSeconds');
    			dataObj.totalCost += item.get('cost.totalCost');
    		}
    	});
    	return EmberObject.create({
    		rawDay: data.seconds,
    		startDate: dataArray.sortBy('startDate'),
    		hasData: data.seconds.content.length > 0,
    		showType: 'startDate',
    	});
    }*/
			});
		},
		loadGroupMembers: function loadGroupMembers() {
			if (!Ember.get(this, 'auth.useGroupPermissionScoping')) {
				return Ember.RSVP.resolve();
			}

			var authMember = Ember.get(this, 'auth.member');

			var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
			    id = _EmberGetProperties.id,
			    memberGroupId = _EmberGetProperties.memberGroupId;

			var query = {
				member_group_id: memberGroupId
			};

			if (Ember.isNone(memberGroupId)) {
				Ember.setProperties(query, { id: id });
			}

			return Ember.get(this, 'store').findAll('member', query);
		},
		showType: function showType(type) {
			var _type = type.replace('cost-code', 'costCode');
			_type = _type.split('-');
			_type = _type[_type.length - 1];
			return _type;
		}
	});
});