define('busy-app/services/integration', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		store: Ember.inject.service('store'),

		/**
   * Lookup the RPC endpoint for the given integration. If no matching mapping is defined in `endpointMap`, the integration name itself is returned.
   *
   * @public
   * @method rpcGetEndpoint
   * @param integrationName {string} The name of the integration
   * @returns {string}
   */
		rpcGetEndpoint: function rpcGetEndpoint(integrationName) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isString(integrationName);

			var endpointMap = {
				// procore: 'procore',
			};

			var endpoint = endpointMap.hasOwnProperty(integrationName) ? endpointMap[integrationName] : integrationName; // pass through the integrationName if there isn't a mapping

			return endpoint;
		},


		/**
   * Convenience wrapper to call the correct endpoint point with the requested RPC function
   *
   * @public
   * @method rpcRequest
   * @param integrationName {string} The name of the integration
   * @param rpcFn {string} The function to be called on the RPC
   * @param rpcParams {object} The parameters to be passed to the RPC
   * @param [customUri] {string} An optional custom URL to be passed to the RPC
   * @returns {EmberPromise}
   */
		rpcRequest: function rpcRequest(integrationName, rpcFn, rpcParams) {
			var customUri = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

			_utils.Assert.funcNumArgs(arguments, 4);
			_utils.Assert.isString(integrationName);
			_utils.Assert.isString(rpcFn);

			if (!Ember.isNone(rpcParams)) {
				_utils.Assert.isObject(rpcParams);
			}

			if (!Ember.isNone(customUri)) {
				_utils.Assert.isString(customUri);
			}

			var endpoint = this.rpcGetEndpoint(integrationName);
			_logger.default.info('Integration', 'rpcRequest', { endpoint: endpoint, rpcFn: rpcFn, rpcParams: rpcParams, customUri: customUri });
			return this.get('store').rpcRequest(endpoint, rpcFn, rpcParams, customUri).then(function (results) {
				_logger.default.info('Integration', 'rpcRequest', { results: results });
				return results;
			}).catch(function (err) {
				_logger.default.error('Integration', 'rpcRequest', { err: err });
				throw err;
			});
		},


		/**
   * Run a customizable method depending on the status code of the provided error.
   *
   * The default handler is `getConnectionError()` in this service
   *
   * @public
   * @method rpcErrorHandler
   * @param err {object} The error object to handle, must contain an HTTPS status code in `.status`
   * @param err.status {number} HTTPS status code
   * @param customHandlers {object} A hash of functions to be called depending on the status code
   * @returns {varies}
   *
   * @example
   *	rpcErrorHandler(err, {
   *		405: (err) => Logger.error('Method not allowed'),
   *		409: () => { return this.get('target.router').refresh(),
   *	});
   */
		rpcErrorHandler: function rpcErrorHandler(err, customHandlers) {
			var _this = this;

			_utils.Assert.funcNumArgs(arguments, 2);
			_utils.Assert.isObject(err);

			if (!Ember.isNone(customHandlers)) {
				_utils.Assert.isObject(customHandlers);
			}

			// customHandlers.default will override this default
			var defaultHandlers = {
				default: function _default() {
					return _this.getConnectionError(err);
				}
			};

			// create a new object with customHandlers merged over the default handlers
			var errorHandlers = Ember.merge(defaultHandlers, customHandlers);

			if (err && err.hasOwnProperty('status')) {
				var handlerKey = errorHandlers.hasOwnProperty(err.status) ? err.status : 'default';
				var handler = errorHandlers[handlerKey];

				return handler(err);
			} else {
				return this.getUnhandledError(err);
			}
		},


		/**
   * Get the remote URL where the client should be sent to start the OAuth dance
   *
   * @public
   * @method rpcGrantUrl
   * @param integrationName {string} The name of the integration
   * @returns {string}
   */
		rpcGrantUrl: function rpcGrantUrl(integrationName) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isString(integrationName);

			return this.rpcRequest(integrationName, 'grant_url');
		},


		/**
   * Store the code obtained from the OAuth provider
   *
   * @public
   * @method rpcSetCredentials
   * @param integrationName {string} The name of the integration
   * @param grant_code {string} The code returned from the remote OAuth provider
   * @returns {Models.OrganizationIntegration}
   */
		rpcSetCredentials: function rpcSetCredentials(integrationName, grant_code) {
			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isString(integrationName);
			_utils.Assert.isString(grant_code);

			return this.rpcRequest(integrationName, 'set-credentials', { grant_code: grant_code });
		},


		/**
   * Disconnect the given integration from the organization
   *
   * @public
   * @method rpcDisconnect
   * @param integrationName {string} The name of the integration
   * @returns {Models.OrganizationIntegration}
   */
		rpcDisconnect: function rpcDisconnect(integrationName) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isString(integrationName);

			return this.rpcRequest(integrationName, 'disconnect');
		},


		/**
   * Request an import cycle be started for the given integration
   *
   * @public
   * @method rpcTriggerImport
   * @param integrationName {string} The name of the integration
   * @returns {null}
   */
		rpcTriggerImport: function rpcTriggerImport(integrationName) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isString(integrationName);

			return this.rpcRequest(integrationName, 'trigger-import');
		},


		/**
   * Returns an EmberObject with an `errorType` of 'Connection Error' and `errorMessage` set to the supllied error's `statusText`
   *
   * @public
   * @method getConnectionError
   * @param err {object} error object returned from an RPC call
   * @returns {EmberObject}
   */
		getConnectionError: function getConnectionError(err) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(err);

			return Ember.Object.create({
				errorType: 'Connection Error',
				errorMessage: err.statusText
			});
		},


		/**
   * Returns an EmberObject with an `errorType` of 'Error' and `errorMessage` as stringified version of the supplied error object
   *
   * @public
   * @method getUnhandledError
   * @param err {object} error object thrown by a failed RPC call
   * @returns {EmberObject}
   */
		getUnhandledError: function getUnhandledError(err) {
			_utils.Assert.funcNumArgs(arguments, 1, true);
			_utils.Assert.isObject(err);

			return Ember.Object.create({
				errorType: 'Error',
				errorMessage: JSON.stringify(err)
			});
		},


		/**
  * Returns a promise containing whatever it is passed after the specified delay
  *
  * @public
  * @method mockApiCall
  * @param result {any} what to return
  * @param delay {integer} how many milliseconds to wait
  * @returns {RSVP.Promise}
  */
		mockApiCall: function mockApiCall() {
			var result = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
			var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;

			return new Ember.RSVP.Promise(function (resolve) {
				return setTimeout(function () {
					return resolve(result);
				}, delay);
			}).then(function (results) {
				_logger.default.info('Integration', 'mockApiCall', delay, { result: result });
				return results;
			});
		},


		/**
   * Procore specific endpoints
   * https://github.com/busybusy/webservices-procore-platform/issues/4
   *
   */

		/**
  	 * The Procore RPC for set-credentials also returns an array of companies
  	 *
  	 * @public
  	 * @method rpcProcoreSetCredentials
  	 * @param integrationName {string} The name of the integration
  	 * @param grant_code {string} The code returned from the remote OAuth provider
  	 * @returns {Object}
  	 */
		rpcProcoreSetCredentials: function rpcProcoreSetCredentials(integrationName, grant_code) {
			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isString(integrationName);
			_utils.Assert.isString(grant_code);

			return this.rpcSetCredentials(integrationName, grant_code).then(function (results) {
				return results && results.data && results.data.companies ? results.data.companies : [];
			});
		},


		/**
  	 * Get Procore's list of cost codes for the specified companyId
  	 *
  	 * @public
  	 * @method rpcProcoreGetCostCodeLists
  	 * @param integrationName {string} The name of the integration
  	 * @param company_id {string} The id of a procore company
  	 * @returns {Array}
  	 */
		rpcProcoreGetCostCodeLists: function rpcProcoreGetCostCodeLists(integrationName, company_id) {
			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isString(integrationName);

			return this.rpcRequest(integrationName, 'get-cost-code-lists', { company_id: company_id }).then(function (results) {
				return results && results.data && results.data.cost_code_lists ? results.data.cost_code_lists : [];
			});
		},


		/**
  	 * Finalize the integration by sending the user selected companyId and costCodeList
  	 *
  	 * @public
  	 * @method rpcProcoreFinalizeConfiguration
  	 * @param integrationName {string} The name of the integration
  	 * @param company_id {string} The id of a procore company
  	 * @param cost_code_list {string} The id of a procore cost code list
  	 * @returns {Models.OrganizationIntegration}
  	 */
		rpcProcoreFinalizeConfiguration: function rpcProcoreFinalizeConfiguration(integrationName, company_id, cost_code_list_id) {
			_utils.Assert.funcNumArgs(arguments, 3, true);
			_utils.Assert.isString(integrationName);

			return this.rpcRequest(integrationName, 'finalize-configuration', { company_id: company_id, cost_code_list_id: cost_code_list_id });
		}
	});
});