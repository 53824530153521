define('busy-app/models/member', ['exports', '@busy-web/utils', 'busy-app/utils/round-time', 'busy-app/utils/time-entry', 'ember-data', 'moment'], function (exports, _utils, _roundTime, _timeEntry, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    auth: Ember.inject.service('auth'),
    features: Ember.inject.service('features'),

    username: _emberData.default.attr('string'),
    password: _emberData.default.attr('password'),
    pinCode: _emberData.default.attr('password'),
    firstName: _emberData.default.attr('string', { sortable: true }),
    lastName: _emberData.default.attr('string', { sortable: true }),
    // email: DS.attr('string'),
    pendingEmail: _emberData.default.attr('string'), // don't enable pendingEmail or certifiedEmail until/unless we deprecate the email attribute
    certifiedEmail: _emberData.default.attr('string'),
    image: _emberData.default.attr('file'),
    imageUrl: _emberData.default.attr('string'),
    imageThumbUrl: _emberData.default.attr('string'),
    organizationId: _emberData.default.attr('string'),
    memberGroupId: _emberData.default.attr('string'),
    positionId: _emberData.default.attr('string'),
    memberNumber: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    verifiedEmail: _emberData.default.attr('boolean'),
    emailVerificationRequired: _emberData.default.attr('boolean'),
    usernameUnique: _emberData.default.attr('boolean'),
    archivedOn: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),
    sendEmail: _emberData.default.attr('boolean'),
    sendText: _emberData.default.attr('boolean'),

    // allowed values are 'AUTO', 'YES', and 'NO'.
    // 'AUTO' is backwards compatible, indicating GPS should be provided when available
    timeLocationRequired: _emberData.default.attr('string', { defaultValue: 'AUTO' }),

    position: _emberData.default.belongsTo('position'),
    memberGroup: _emberData.default.belongsTo('member-group'),
    wageHistory: _emberData.default.hasMany('wage-history', { inverse: 'member', query: { _desc: ['start_time'] } }),
    memberLock: _emberData.default.belongsTo('member-lock', { inverse: null, referenceKey: 'id', foreignKey: 'memberId' }),

    currentWageHistory: _emberData.default.belongsTo('wage-history', { inverse: null, query: { member_id: 'self.id', _desc: ['change_date', 'created_on'] } }),
    //currentOvertimeRules: DS.belongsTo('member-overtime-period', { query: {member_id: 'self.id', _desc: ['change_date', 'created_on'], _lte: { change_date: Time.timestamp() }} }),
    //scheduledOvertimePeriod: DS.belongsTo('member-overtime-period', { query: {member_id: 'self.id', _desc: ['change_date', 'created_on'], _gt: { change_date: Time.timestamp() }} }),
    currentOrgOvertimeRules: _emberData.default.belongsTo('organization-overtime-period', { inverse: null, query: { _desc: ['start_time', 'created_on'], _lte: { start_time: _utils.Time.timestamp() } } }),

    currentOvertimeRules: Ember.computed('_overtimePeriods.@each.changeDate', function () {

      if (this.get('_overtimePeriods.length') === 1) {
        return this.get('_overtimePeriods.firstObject');
      } else if (this.get('_overtimePeriods.length') > 1) {
        if (this.get('_overtimePeriods.firstObject.changeDate') > _utils.Time.timestamp()) {
          return this.get('_overtimePeriods.lastObject');
        }
        return this.get('_overtimePeriods.firstObject');
      }
      return null;
    }),

    scheduledOvertimePeriod: Ember.computed('_overtimePeriods.@each.changeDate', function () {
      if (this.get('_overtimePeriods.length') > 1 && this.get('_overtimePeriods.firstObject.changeDate') > _utils.Time.timestamp()) {
        return this.get('_overtimePeriods.firstObject');
      }
      return null;
    }),

    _overtimePeriods: Ember.computed('id', function () {
      return _emberData.default.PromiseArray.create({
        promise: this.store.query('member-overtime-period', { member_id: this.id, deleted_on: '_-NULL-_', _desc: ['change_date', 'created_on'], page_size: 2 })
      });
    }),

    timeEntries: null, //DS.hasMany('time-entry'),
    timeOffEntries: null, //DS.hasMany('time-off'),
    wage: null, //DS.hasMany('wage-history'),

    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    imagePlaceholder: '/images/imageplaceholder.png',

    positionName: Ember.computed('position.title', function () {
      var title = '';
      if (!Ember.isNone(this.get('position.title'))) {
        title = this.get('position.title');
      }
      return title;
    }),

    openEntry: null,
    todaysTimeOff: null,

    lockDate: Ember.computed('memberLock.effectiveDate', 'features.locks', function () {
      var lockDate = _utils.Time.date().subtract(89, 'days').startOf('day').unix();
      if (this.get('features.locks')) {
        lockDate = this.get('memberLock.effectiveDate');
        if (Ember.isNone(lockDate)) {
          lockDate = _utils.Time.date().subtract(30, 'years').startOf('day').unix();
        } else {
          lockDate = _utils.Time.date(lockDate).add(1, 'days').startOf('day').unix();
        }
      }
      return lockDate;
    }),

    /**
     * returns the current elapsed time or current elapsed break time
     * for the open entry.
     *
     */
    totalTimeString: Ember.computed('openEntry.elapsedTimeString', function () {
      var total = '00:00';
      if (!Ember.isNone(this.get('openEntry.elapsedTimeString'))) {
        total = this.get('openEntry.elapsedTimeString');
      }
      return total;
    }),

    /*
    *	regular clocked in time aggregators
    */

    /**
     * returns the total time for the open entry for today.
     *
     */
    openRegularTime: Ember.computed('openEntry.totalTime', function () {
      var dayStart = _utils.Time.date().startOf('day').unix();
      var dayEnd = _utils.Time.date().endOf('day').unix();
      var openEntry = this.get('openEntry');

      if (Ember.isNone(openEntry)) {
        return 0;
      } else {
        var time = _timeEntry.default.timeByRange(openEntry, dayStart, dayEnd, true);
        return time.endTime - time.startTime - time.breakTime;
      }
    }),

    /**
     * returns the total closed time entry time for today.
     *
     */
    closedRegularTime: Ember.computed('timeEntries.@each.segmentTotalSeconds', function () {
      var dayStart = _utils.Time.date().startOf('day').unix();
      var dayEnd = _utils.Time.date().endOf('day').unix();
      //let time = 0;
      //this.getWithDefault('timeEntries').forEach(entry => {
      //	time += entry.get('segments')
      //						.filter(s => s.get('startTime') >= dayStart && s.get('endTime') <= dayEnd)
      //						.reduce((a, b) => a + b.get('totalSeconds'), 0);
      //});

      //return time;

      return this.getWithDefault('timeEntries', []).reduce(function (acc, timeEntry) {
        var time = _timeEntry.default.timeByRange(timeEntry, dayStart, dayEnd, true);
        return acc + (time.endTime - time.startTime - time.breakTime);
      }, 0);
    }),

    /**
     * returns the total open and closed time for today.
     *
     */
    combinedRegularTime: Ember.computed('closedRegularTime', 'openRegularTime', function () {
      var closedTotal = this.getWithDefault('closedRegularTime', 0);
      var openTotal = this.getWithDefault('openRegularTime', 0);
      return closedTotal + openTotal;
    }),

    openRegularTimeString: Ember.computed('openRegularTime', function () {
      var timeInSeconds = this.getWithDefault('openRegularTime', 0);
      return this.secondsToFormattedString(timeInSeconds);
    }),

    closedRegularTimeString: Ember.computed('closedRegularTime', function () {
      var timeInSeconds = this.getWithDefault('closedRegularTime', 0);
      return this.secondsToFormattedString(timeInSeconds);
    }),

    combinedRegularTimeString: Ember.computed('combinedRegularTime', function () {
      var timeInSeconds = this.getWithDefault('combinedRegularTime', 0);
      return this.secondsToFormattedString(timeInSeconds);
    }),

    /*
    *	Break time aggregators
    */

    /**
     * Gets the total break time of the open entry including open break time.
     *
     */
    openBreakTime: Ember.computed('clockedIn', 'openEntry.{breakTime,elapsedTime}', function () {
      var dayStart = _utils.Time.date().startOf('day').unix();
      var dayEnd = _utils.Time.date().endOf('day').unix();
      var total = 0;
      if (!Ember.isNone(this.get('openEntry'))) {
        var time = _timeEntry.default.timeByRange(this.get('openEntry'), dayStart, dayEnd, true);
        total = time.breakTime;
      }
      return total;
    }),

    /**
     * Gets the total break time of all the closed entries
     *
     */
    closedBreakTime: Ember.computed('timeEntries.@each.breakTime', function () {
      var dayStart = _utils.Time.date().startOf('day').unix();
      var dayEnd = _utils.Time.date().endOf('day').unix();
      var breaksOnClosedEntries = this.getWithDefault('timeEntries', []).reduce(function (acc, timeEntry) {
        var time = _timeEntry.default.timeByRange(timeEntry, dayStart, dayEnd, true);
        return acc + time.breakTime;
      }, 0);
      return breaksOnClosedEntries;
    }),

    /**
     * returns the total break time for open and closed breaks.
     *
     */
    combinedBreakTime: Ember.computed('closedBreakTime', 'openBreakTime', function () {
      var closedTotal = this.getWithDefault('closedBreakTime', 0);
      var openTotal = this.getWithDefault('openBreakTime', 0);
      return closedTotal + openTotal;
    }),

    openBreakTimeString: Ember.computed('openBreakTime', function () {
      var timeInSeconds = this.getWithDefault('openBreakTime', 0);
      return this.secondsToFormattedString(timeInSeconds);
    }),

    closedBreakTimeString: Ember.computed('closedBreakTime', function () {
      var timeInSeconds = this.getWithDefault('closedBreakTime', 0);
      return this.secondsToFormattedString(timeInSeconds);
    }),

    combinedBreakTimeString: Ember.computed('combinedBreakTime', function () {
      var timeInSeconds = this.getWithDefault('combinedBreakTime', 0);
      return this.secondsToFormattedString(timeInSeconds);
    }),

    /**
     *	stateful aggregators
     *		returns break time when `onBreak`
     * 		return regular time when NOT `onBreak`
     */
    openElapsedTime: Ember.computed('onBreak', 'openRegularTime', 'openBreakTime', function () {
      if (this.get('onBreak')) {
        return this.getWithDefault('openBreakTime', 0);
      } else {
        return this.getWithDefault('openRegularTime', 0);
      }
    }),

    closedElapsedTime: Ember.computed('onBreak', 'closedRegularTime', 'closedBreakTime', function () {
      if (this.get('onBreak')) {
        return this.getWithDefault('closedBreakTime', 0);
      } else {
        return this.getWithDefault('closedRegularTime', 0);
      }
    }),

    combinedElapsedTime: Ember.computed('closedElapsedTime', 'openElapsedTime', function () {
      var closedTotal = this.getWithDefault('closedElapsedTime', 0);
      var openTotal = this.getWithDefault('openElapsedTime', 0);
      return closedTotal + openTotal;
    }),

    openElapsedTimeString: Ember.computed('openElapsedTime', function () {
      var elapsedTime = this.getWithDefault('openElapsedTime', 0);
      return this.secondsToFormattedString(elapsedTime);
    }),

    closedElapsedTimeString: Ember.computed('closedElapsedTime', function () {
      var elapsedTime = this.getWithDefault('closedElapsedTime', 0);
      return this.secondsToFormattedString(elapsedTime);
    }),

    combinedElapsedTimeString: Ember.computed('combinedElapsedTime', function () {
      var elapsedTime = this.getWithDefault('combinedElapsedTime', 0);
      return this.secondsToFormattedString(elapsedTime);
    }),

    secondsToFormattedString: function secondsToFormattedString(seconds) {
      var _Time$convertSeconds = _utils.Time.convertSeconds(seconds, true),
          hours = _Time$convertSeconds.hours,
          minutes = _Time$convertSeconds.minutes;

      return hours + ':' + minutes;
    },


    latestTimeEntry: Ember.computed('timeEntries', 'openEntry', function () {
      if (!Ember.isNone(this.get('openEntry'))) {
        return this.get('openEntry');
      } else if (!Ember.isEmpty(this.get('timeEntries'))) {
        return this.get('timeEntries.lastObject');
      }
    }),

    paidTimeOffEntries: Ember.computed.filterBy('timeOffEntries', 'paid'),
    paidTimeOffEntriesSeconds: Ember.computed.mapBy('paidTimeOffEntries', 'seconds'),
    paidTimeOffEntriesTotalSeconds: Ember.computed.sum('paidTimeOffEntriesSeconds'),

    timeOffEntriesTypesCollapsed: Ember.computed('timeOffEntries.[]', function () {
      var timeOffEntries = this.get('timeOffEntries') || Ember.A();
      if (timeOffEntries.get('length') === 0) {
        return '';
      } else if (timeOffEntries.get('length') === 1) {
        return timeOffEntries.get('firstObject.getType');
      } else {
        return (0, _utils.loc)('Time Off');
      }
    }),

    timeOffEntriesTypesFull: Ember.computed('timeOffEntries', function () {
      return this.getWithDefault('timeOffEntries', []).mapBy('getType').join(' / ');
    }),

    onBreak: Ember.computed('clockedIn', 'openEntry.onBreak', function () {
      return this.get('openEntry.onBreak') || false;
    }),

    clockedIn: Ember.computed('openEntry', function () {
      return Ember.isNone(this.get('openEntry')) ? false : true;
    }),

    hasLocation: Ember.computed('clockedIn', 'openEntry.{isStartLocationValid,isEndLocationValid}', function () {
      return this.get('openEntry.isStartLocationValid') || this.get('openEntry.isEndLocationValid');
    }),

    dailyOvertime: Ember.computed('currentOvertimeRules', 'currentOrgOvertimeRules', function () {
      var dailyOvertime = null;
      var currentRules = this.get('currentOvertimeRules');

      if (!Ember.isNone(currentRules) && currentRules.get('adhereToOrg')) {
        currentRules = this.get('currentOrgOvertimeRules');
      }

      if (!Ember.isNone(currentRules)) {
        dailyOvertime = currentRules.get('dailyOvertime');
      }
      return dailyOvertime;
    }),

    weeklyOvertime: Ember.computed('currentOvertimeRules', 'currentOrgOvertimeRules', function () {
      var weeklyOvertime = null;
      var currentRules = this.get('currentOvertimeRules');

      if (!Ember.isNone(currentRules) && currentRules.get('adhereToOrg')) {
        currentRules = this.get('currentOrgOvertimeRules');
      }

      if (!Ember.isNone(currentRules)) {
        weeklyOvertime = currentRules.get('weeklyOvertime');
      }
      return weeklyOvertime;
    }),

    overtimePreference: Ember.computed('currentOvertimeRules.overtimePreference', function () {
      var pref = '';
      var currentRules = this.get('currentOvertimeRules');
      if (!Ember.isNone(currentRules)) {
        pref = currentRules.get('overtimePreference');
      }
      return pref;
    }),

    // when timeLocationRequired is set to 'YES', users cannot perform clock actions on themselves
    // memberGpsSetting is the LD pro flag controlling if the GPS setting can be changed,
    //   even when it is disabled, the last set value is still valid and should be used
    // memberGpsSettingDevelopment controls if the feature code is active
    isTimeLocationRequired: Ember.computed('timeLocationRequired', 'id', 'auth.member.id', 'features.memberGpsSettingDevelopment', function () {
      return this.get('features.memberGpsSettingDevelopment') && this.get('timeLocationRequired') === 'YES' && this.get('id') === this.get('auth.member.id');
    }),

    timeLocationRequiredDescription: Ember.computed('timeLocationRequired', function () {
      var map = {
        'AUTO': (0, _utils.loc)('Not Required'),
        'YES': (0, _utils.loc)('Required'),
        'NO': (0, _utils.loc)('Off')
      };

      return map[this.get('timeLocationRequired')];
    }),

    /**
     * Clock in a new time entry
     *
     * @public
     * @method clockIn
     * @param projectId {string} Project model id
     * @param costCodeId {string} CostCode model id
     * @param description {string} description for the timecard
     * @return {EmberPromise}
     */
    clockIn: function clockIn(projectId, costCodeId, description, equipmentId, startLocation) {
      var forceSave = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
      var validateOnly = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
      var isSwitch = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : false;
      var signInAnswers = arguments[8];
      var authMember = arguments[9];

      var _this = this;

      var covidRestricted = arguments[10];
      var isFreeAccount = arguments[11];

      var startTime = (0, _roundTime.roundOrgTime)(this.get('auth.organization'), _utils.Time.nistTimestamp(), { isStart: true, isSwitch: isSwitch });

      if (this.get('isTimeLocationRequired')) {
        _utils.Assert.test('A location is required for this member to self clockIn', !Ember.isNone(startLocation));
      }

      var params = {
        memberId: this.id,
        actionType: 10,
        startTime: startTime
      };

      if (!Ember.isNone(projectId)) {
        params.projectId = projectId;
      }

      if (!Ember.isNone(costCodeId)) {
        params.costCodeId = costCodeId;
      }

      if (!Ember.isNone(description) && !Ember.isEmpty(description)) {
        description = description.trim();
        params.description = description;
      }

      if (!Ember.isNone(equipmentId)) {
        params.equipmentId = equipmentId;
      }

      if (!Ember.isNone(startLocation)) {
        params.startLocation = startLocation;
      }

      var record = this.store.createRecord('time-entry', params);
      if (validateOnly) {
        return record.validate(false, signInAnswers, authMember, covidRestricted, isFreeAccount).finally(function () {
          return record.destroy();
        });
      } else {
        return record.save(forceSave, signInAnswers, authMember, covidRestricted, isFreeAccount).then(function (model) {
          _this.set('openEntry', model);
          return model;
        });
      }
    },
    clockInAt: function clockInAt(startTime, projectId, costCodeId, description, equipmentId) {
      var _this2 = this;

      var forceSave = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;

      _utils.Assert.funcNumArgs(arguments, 6);

      _utils.Assert.test('A startTime must be provided to clockInAt', typeof startTime === 'number');

      var params = {
        memberId: this.id,
        actionType: 30,
        startTime: (0, _roundTime.roundOrgTime)(this.get('auth.organization'), startTime, { isStart: true })
      };

      if (!Ember.isNone(projectId)) {
        params.projectId = projectId;
      }

      if (!Ember.isNone(costCodeId)) {
        params.costCodeId = costCodeId;
      }

      if (!Ember.isNone(description) && !Ember.isEmpty(description)) {
        params.description = description;
      }

      if (!Ember.isNone(equipmentId)) {
        params.equipmentId = equipmentId;
      }

      return this.store.createRecord('time-entry', params).save(forceSave).then(function (model) {
        _this2.set('openEntry', model);
        return model;
      });
    },
    clockOut: function clockOut() {
      var _this3 = this;

      var isSwitch = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var endLocation = arguments[1];

      var endTime = (0, _roundTime.roundOrgTime)(this.get('auth.organization'), _utils.Time.nistTimestamp(), { isEnd: true, isSwitch: isSwitch });

      if (this.get('isTimeLocationRequired')) {
        _utils.Assert.test('A location is required for this member to self clockout', !Ember.isNone(endLocation));
      }

      if (Ember.isNone(this.get('timeEntries'))) {
        this.set('timeEntries', []);
      }

      if (this.get('openEntry.hasDirtyAttributes')) {
        this.get('openEntry').rollbackAttributes();
      }

      _utils.Assert.test("You must have an open entry to call clockOut", !Ember.isNone(this.get('openEntry')));

      this.set('openEntry.actionType', 20);
      this.set('openEntry.endTime', endTime);
      this.get('openEntry').populate();

      return this.get('openEntry')._save().then(function (model) {
        _this3.set('openEntry', null);
        _this3.get('timeEntries').pushObject(model._internalModel);
        return model;
      });
    },
    clockOutAt: function clockOutAt(endTime) {
      var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var validateOnly = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      var _this4 = this;

      var endLocation = arguments[3];
      var isClockOut = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

      if (Ember.isNone(this.get('timeEntries'))) {
        this.set('timeEntries', []);
      }

      if (isClockOut && this.get('isTimeLocationRequired')) {
        _utils.Assert.test('A location is required for this member to self clockout', !Ember.isNone(endLocation));
      }

      _utils.Assert.test('An endTime must be provided to clockOutAt', typeof endTime === 'number');
      _utils.Assert.test("You must have an open entry to call clockOut", !Ember.isNone(this.get('openEntry')));

      this.set('openEntry.endTime', (0, _roundTime.roundOrgTime)(this.get('organization.id'), endTime, { isEnd: true }));
      this.set('openEntry.actionType', 40);

      if (validateOnly) {
        return this.get('openEntry').validate(forceSave === true ? false : true);
      }

      return this.get('openEntry').save(forceSave).then(function (model) {
        _this4.set('openEntry', null);
        _this4.get('timeEntries').pushObject(model._internalModel);
        return model;
      });
    },
    createTimeEntry: function createTimeEntry(startTime, endTime) {
      _utils.Assert.test('A startTime must be provided to manualTimeEntry', typeof startTime === 'number');
      _utils.Assert.test('An endTime must be provided to manualTimeEntry', typeof endTime === 'number');

      if (Ember.isNone(this.get('timeEntries'))) {
        this.set('timeEntries', []);
      }

      var params = {
        id: _utils.UUID.generate(),
        memberId: this.id,
        actionType: 50,
        startTime: startTime,
        endTime: endTime
      };

      var entry = this.store.createRecord('time-entry', params);
      entry.set('member', this);

      this.get('timeEntries').pushObject(entry);
      return entry;
    },
    createTimeOffEntry: function createTimeOffEntry(datestamp, type, isMulti, seconds, isPaid, description) {
      var _this5 = this;

      var forceSave = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

      _utils.Assert.test('A datestamp must be provided to newTimeOff', typeof datestamp === 'number');

      if (Ember.isEmpty(this.get('timeOffEntries'))) {
        this.set('timeOffEntries', []);
      }

      var offset = _utils.Time.timezone(this.get('date'));
      var isDST = _utils.Time.isDST(this.get('dateStamp'));
      var date = _utils.Time.date().unix();

      if (!Ember.isNone(description) && !Ember.isEmpty(description)) {
        description = description.trim();
      }

      var params = {
        id: _utils.UUID.generate(),
        dateStamp: datestamp,
        memberId: this.id,
        type: type,
        seconds: seconds,
        paid: isPaid,
        description: description,
        createdOnDaylightSavingTime: isDST,
        createdOnOffset: offset,
        submitterMemberId: this.get('auth.member.id'),
        submittedOn: date
      };

      return this.store.createRecord('time-off', params).save(forceSave, isMulti).then(function (model) {
        _this5.get('timeOffEntries').pushObject(model._internalModel);
        return model;
      });
    },
    manualTimeEntry: function manualTimeEntry(startTime, endTime, projectId, costCodeId, description, equipmentId) {
      var _this6 = this;

      _utils.Assert.funcNumArgs(arguments, 6);
      _utils.Assert.isNumber(startTime);
      _utils.Assert.isNumber(endTime);

      if (Ember.isNone(this.get('timeEntries'))) {
        this.set('timeEntries', []);
      }

      var params = {
        memberId: this.id,
        actionType: 50,
        startTime: startTime,
        endTime: endTime
      };

      if (!Ember.isNone(projectId)) {
        params.projectId = projectId;
      }

      if (!Ember.isNone(costCodeId)) {
        params.costCodeId = costCodeId;
      }

      if (!Ember.isNone(description) && !Ember.isEmpty(description)) {
        description = description.trim();
        params.description = description;
      }

      if (!Ember.isNone(equipmentId)) {
        params.equipmentId = equipmentId;
      }

      return this.store.createRecord('time-entry', params).save().then(function (model) {
        _this6.get('timeEntries').pushObject(model._internalModel);
        return model;
      });
    },
    startBreak: function startBreak(paidBreak, override, startLocation) {
      _utils.Assert.test('You must be clocked in before you can start a break!', !Ember.isNone(this.get('openEntry')));

      if (this.get('isTimeLocationRequired')) {
        _utils.Assert.test('A location is required for this member to self startBreak', !Ember.isNone(startLocation));
      }

      return this.get('openEntry').startBreak(paidBreak, override, startLocation);
    },
    stopBreak: function stopBreak(override, endLocation) {
      _utils.Assert.test('You must be clocked in before you can stop a break!', !Ember.isNone(this.get('openEntry')));

      if (this.get('isTimeLocationRequired')) {
        _utils.Assert.test('A location is required for this member to self stopBreak', !Ember.isNone(endLocation));
      }

      return this.get('openEntry').stopBreak(override, endLocation);
    },


    /**
     * Query the API for the `member-lock` for this `member`
     *
     * @method getMemberLock
     * @async
     * @chainable
     * @return {Models.MemberLock}
     *
     */
    getMemberLock: function getMemberLock() {
      return this.get('memberLock');
    },


    /**
     * Cache the result of `this.getMemberLock()` to `this.memberLock`
     *
     * @method setMemberLock
     * @async
     * @chainable
     * @return {Models.MemberLock}
     *
     */
    setMemberLock: function setMemberLock() {
      _utils.Assert.test("memberLock can no longer be set with this method. Just use `member.get('memberLock')`", {});
      return this.get('memberLock');
    },


    /**
     * Update the effective date and push the new 'member-lock' to the API.
     * `effectiveDate` property must be either an epoch timestamp or `_-NULL-_`
     *
     * @method setMemberLock
     * @property effectiveDate {number|string}
     * @async
     * @chainable
     * @return {Models.MemberLock}
     *
     */
    updateMemberLock: function updateMemberLock(effectiveDate) {
      var _this7 = this;

      var updaterMemberId = this.get('auth.member.id');
      var _memberLock = this.get('memberLock');

      _utils.Assert.isUUID(updaterMemberId);

      // fix NULL to use `_-NULL-_` string
      if (Ember.isNone(effectiveDate)) {
        effectiveDate = null;
      }

      // make sure the effectiveDate is a valid epoch
      if (effectiveDate !== null && effectiveDate !== '_-NULL-_') {
        _utils.Assert.isInteger(effectiveDate);

        // ensure the effectiveDate is clamped to 23:59:59
        effectiveDate = _utils.Time.date(effectiveDate).endOf('day').unix();
      }

      // if memberLock isn't set, get it
      var promisedMemberLock = !Ember.isNone(_memberLock) ? Ember.RSVP.resolve(_memberLock) : this.getMemberLock();
      return promisedMemberLock.then(function (memberLock) {
        if (Ember.isNone(memberLock)) {
          memberLock = _this7.get('store').createRecord('member-lock', { memberId: _this7.get('id'), effectiveDate: effectiveDate, updaterMemberId: updaterMemberId });
        } else {
          if (effectiveDate === memberLock.get('effectiveDate')) {
            return memberLock;
          }
          memberLock.setProperties({ effectiveDate: effectiveDate, updaterMemberId: updaterMemberId });
        }
        return memberLock.save().then(function () {
          _this7.set('memberLock', memberLock);
        });
      });
    },


    /**
     * Compares `member.memberLock.effectiveDate` to the provided date
     *
     * @method isDateLocked
     * @param member {Models.Member}
     * @return {Boolean}
     *
     */
    isDateLocked: function isDateLocked(compareDate) {
      var lockDate = this.get('memberLock.effectiveDate');
      if (Ember.isNone(lockDate)) {
        return false;
      } else {
        compareDate = _moment.default.isMoment(compareDate) ? compareDate.unix() : compareDate;
        return compareDate <= lockDate;
      }
    },


    email: Ember.computed('pendingEmail', 'certifiedEmail', function () {
      var _EmberGetProperties = Ember.getProperties(this, ['pendingEmail', 'certifiedEmail']),
          pendingEmail = _EmberGetProperties.pendingEmail,
          certifiedEmail = _EmberGetProperties.certifiedEmail;

      return certifiedEmail || pendingEmail;
    }),

    latestEmail: Ember.computed('pendingEmail', 'certifiedEmail', function () {
      var _EmberGetProperties2 = Ember.getProperties(this, ['pendingEmail', 'certifiedEmail']),
          pendingEmail = _EmberGetProperties2.pendingEmail,
          certifiedEmail = _EmberGetProperties2.certifiedEmail;

      return pendingEmail || certifiedEmail;
    })

  });
});