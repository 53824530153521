define('busy-app/controllers/scheduling/index', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service(),

		isEdit: false,
		isCopy: false,
		isDelete: false,

		actionModel: null,

		employeeSelectorPermissions: Ember.computed(function () {
			return ['timeEvents'];
		}),

		showEdit: Ember.computed('isEdit', 'isCopy', function () {
			return Ember.get(this, 'isEdit') || Ember.get(this, 'isCopy');
		}),

		scheduleOptions: Ember.computed(function () {
			return [Ember.Object.create({ key: 'copy', value: 'Copy' }), Ember.Object.create({ key: 'edit', value: 'Edit' }), Ember.Object.create({ key: 'move', value: 'Move to Next Day' }), Ember.Object.create({ key: 'delete', value: 'Delete' })];
		}),

		selectedDay: Ember.computed('model.days.@each.selected', function () {
			return this.get('model.days').findBy('selected', true);
		}),

		moveReminderToNextDay: function moveReminderToNextDay(reminder) {
			var _this = this;

			var newStartTime = _utils.Time.date(Ember.get(reminder, 'startTime')).add(1, 'days').unix();
			var newEndTime = _utils.Time.date(Ember.get(reminder, 'endTime')).add(1, 'days').unix();

			Ember.set(reminder, 'startTime', newStartTime);
			Ember.set(reminder, 'endTime', newEndTime);

			return reminder.save().then(function () {
				return _this.send('refreshModel');
			});
		},
		deleteReminder: function deleteReminder(reminder) {
			reminder.deleteRecord();
			return reminder.save();
		},


		actions: {
			scheduleOptionAction: function scheduleOptionAction(reminder, action) {
				Ember.set(this, 'isCopy', false);
				Ember.set(this, 'isEdit', false);
				Ember.set(this, 'isDelete', false);

				if (action.key === 'move') {
					return this.moveReminderToNextDay(reminder);
				} else {
					Ember.set(this, 'actionModel', reminder);
					Ember.set(this, 'is' + Ember.String.classify(action.key), true);
				}
			},
			editCopySaved: function editCopySaved() {
				Ember.set(this, 'actionModel', null);
				Ember.set(this, 'isCopy', false);
				Ember.set(this, 'isEdit', false);
				this.send('refreshModel');
			},
			confirmDelete: function confirmDelete() {
				var _this2 = this;

				return this.deleteReminder(Ember.get(this, 'actionModel')).then(function () {
					Ember.set(_this2, 'actionModel', null);
					Ember.set(_this2, 'isDelete', false);
					_this2.send('refreshModel');
				});
			},
			closeDeleteDialog: function closeDeleteDialog() {
				Ember.set(this, 'actionModel', null);
				Ember.set(this, 'isDelete', false);
			},
			closeEditDialog: function closeEditDialog() {
				Ember.set(this, 'actionModel', null);
				Ember.set(this, 'isCopy', false);
				Ember.set(this, 'isEdit', false);
			},
			addEmployees: function addEmployees(item, selected) {
				var _this3 = this;

				var scheduleLinks = Ember.get(item, 'scheduleLinks');
				if (!Ember.isEmpty(scheduleLinks) && !Ember.isEmpty(selected)) {
					var prev = scheduleLinks.mapBy('member');
					var removed = prev.filter(function (m) {
						return Ember.isNone(selected.findBy('id', m.id));
					});
					var added = selected.filter(function (m) {
						return Ember.isNone(prev.findBy('id', m.id));
					});

					var removeMemberLinks = Ember.RSVP.resolve();
					if (!Ember.isEmpty(removed)) {
						removeMemberLinks = Ember.RSVP.all(removed.map(function (member) {
							var link = scheduleLinks.findBy('memberId', member.id);
							if (link) {
								link.set('deletedOn', _utils.Time.timestamp());
								return link.save();
							} else {
								return Ember.RSVP.resolve();
							}
						}));
					}

					var addMemberLinks = Ember.RSVP.resolve();
					if (!Ember.isEmpty(added)) {
						addMemberLinks = Ember.RSVP.all(added.map(function (member) {
							return _this3.get('store').createRecord('schedule-reminder-link', {
								scheduleReminderId: item.id,
								memberId: member.id
							}).save();
						}));
					}

					if (!Ember.isEmpty(removed) || !Ember.isEmpty(added)) {
						Ember.RSVP.hash({ removeMemberLinks: removeMemberLinks, addMemberLinks: addMemberLinks }).then(function () {
							_this3.send('refreshModel');
						});
					}
				}
			}
		}
	});
});