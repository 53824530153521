define('busy-app/components/time/time-entry/edit-open', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['v-time-card-dialog-edit-time-entry'],

    auth: Ember.inject.service('auth'),

    timeEntry: null,
    member: null,
    memberEntries: null,

    project: null,
    costCode: null,
    equipment: null,

    projectChanged: false,
    costCodeChanged: false,
    equipmentChanged: false,

    startDateTime: null,
    minDate: null,
    maxDate: null,

    breakTime: 0,
    selectedProject: null,
    selectedCostCode: null,
    selectedEquipment: null,

    costCodesAutoOpen: false,
    projectsAutoOpen: false,
    isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),
    disableCostCodeSelection: Ember.computed('selectedProject.id', 'isLinkedCostCodesEnabled', function () {
      return Ember.get(this, 'isLinkedCostCodesEnabled') && Ember.isNone(Ember.get(this, 'selectedProject.id'));
    }),

    init: function init() {
      this._super();

      var startDateTime = this.get('member.openEntry.startTime');

      this.set('startDateTime', startDateTime);

      this.set('minDate', _utils.Time.date().startOf('day').unix());
      this.set('maxDate', _utils.Time.date().unix());
      this.setTimeRoundingInterval();

      this.set('breakTime', this.get('member.openEntry.breakTime'));

      if (Ember.isNone(this.get('selectedProject'))) {
        this.set('selectedProject', this.get('member.openEntry.project'));
      }

      if (Ember.isNone(this.get('selectedCostCode'))) {
        this.set('selectedCostCode', this.get('member.openEntry.costCode'));
      }

      if (Ember.isNone(this.get('selectedEquipment'))) {
        this.set('selectedEquipment', this.get('member.openEntry.equipment'));
      }
    },
    setTimeRoundingInterval: function setTimeRoundingInterval() {
      var interval = 1;

      if (this.get('auth.organization.timeRounding')) {
        interval = this.get('auth.organization.timeRoundingInterval') / 60;
      }
      this.set('timeRoundingInterval', interval);
    },


    setStartTime: Ember.observer('startDateTime', function () {
      if (!Ember.isNone(this.get('startDateTime')) && this.get('member.openEntry.startTime') !== this.get('startDateTime')) {
        this.set('member.openEntry.startTime', this.get('startDateTime'));
      }
    }),

    actions: {
      projectSelected: function projectSelected(item) {
        // Logger.info(this, 'projectSelected', item);

        if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
          var projectId = Ember.isNone(item) ? null : Ember.get(item, 'id');

          if (projectId !== Ember.get(this, 'selectedProject.id')) {
            this.send('costCodeSelected', null);
          }
        }

        Ember.set(this, 'project', item);
        Ember.set(this, 'projectChanged', true);
      },
      costCodeSelected: function costCodeSelected(item) {
        // Logger.info(this, 'costCodeSelected', item);

        Ember.set(this, 'costCode', item);
        Ember.set(this, 'costCodeChanged', true);
      },
      equipmentSelected: function equipmentSelected(item) {
        // Logger.info(this, 'equipmentSelected', item);

        Ember.set(this, 'equipment', item);
        Ember.set(this, 'equipmentChanged', true);
      },


      //closes dialog if clicked on cancel button or clicked in background window
      close: function close() {
        if (!Ember.isNone(this.get('member.openEntry')) && this.get('member.openEntry.hasDirtyAttributes')) {
          this.get('member.openEntry').rollbackAttributes();
        }

        this.sendAction('onClose');
      },
      saveTimeEntry: function saveTimeEntry(dialog) {
        var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        var timeEntry = this.get('member.openEntry');
        timeEntry.set('metaDaylightSavingTime', null);

        var startTime = this.get('startDateTime');
        if (timeEntry.get('startTime') !== startTime) {
          timeEntry.set('startTime', startTime);
        }

        if (this.get('projectChanged')) {
          if (!Ember.isNone(this.get('project.id'))) {
            timeEntry.set('projectId', this.get('project.id'));
            timeEntry.set('project', this.get('project'));
          } else {
            timeEntry.set('projectId', '_-NULL-_');
            timeEntry.set('project', null);
          }
        }

        if (this.get('costCodeChanged')) {
          if (!Ember.isNone(this.get('costCode.id'))) {
            timeEntry.set('costCodeId', this.get('costCode.id'));
            timeEntry.set('costCode', this.get('costCode'));
          } else {
            timeEntry.set('costCodeId', '_-NULL-_');
            timeEntry.set('costCode', null);
          }
        }

        if (this.get('equipmentChanged')) {
          if (!Ember.isNone(this.get('equipment.id'))) {
            timeEntry.set('equipmentId', this.get('equipment.id'));
            timeEntry.set('equipment', this.get('equipment'));
          } else {
            timeEntry.set('equipmentId', '_-NULL-_');
            timeEntry.set('equipment', null);
          }
        }

        if (timeEntry.get('hasDirtyAttributes') || timeEntry.get('timeEntryBreaks').filterBy('hasDirtyAttributes', true).get('length') > 0) {
          if (timeEntry.get('hasDirtyAttributes')) {
            timeEntry.set('actionType', 60);
          } else if (timeEntry.get('timeEntryBreaks').filterBy('hasDirtyAttributes', true).get('length') > 0) {
            timeEntry.set('actionType', 70);
          }

          timeEntry.get('timeEntryBreaks').forEach(function (item) {
            if (item.get('hasDirtyAttributes') && !item.get('isNew') && !item.get('isDeleted')) {
              item.set('actionType', 40);
            }
          });

          return timeEntry.save(forceSave);
        } else {
          return Ember.RSVP.resolve();
        }
      },
      projectMenuClosed: function projectMenuClosed(didSelectValue, selectedItem, openedBy) {
        var _this = this;

        // Logger.info(this, 'projectMenuClosed', didSelectValue, selectedItem, openedBy);

        if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
          if (didSelectValue) {
            Ember.run.next(this, function () {
              Ember.setProperties(_this, {
                costCodesAutoOpen: Date.now()
              });
            });
          } else if (Ember.isNone(selectedItem) && openedBy === 'auto') {
            // Logger.info(this, 'projectMenuClosed', 'revert to initial');

            Ember.run.next(this, function () {
              Ember.setProperties(_this, {
                projectChanged: false,
                project: Ember.get(_this, 'member.openEntry.project'),
                selectedProject: Ember.get(_this, 'member.openEntry.project'),

                costCodeChanged: false,
                costCode: Ember.get(_this, 'member.openEntry.costCode'),
                selectedCostCode: Ember.get(_this, 'member.openEntry.costCode')
              });
            });
          }
        }
      },
      costCodeMenuClosed: function costCodeMenuClosed(didSelectValue, selectedItem, openedBy) {
        var _this2 = this;

        // Logger.info(this, 'costCodeMenuClosed', didSelectValue, selectedItem, openedBy);

        // if the cost code menu was opened automatically by the double nav and the user hits back (!didSelectValue) then re-open the project selecter
        if (Ember.get(this, 'isLinkedCostCodesEnabled') && !didSelectValue && openedBy === 'auto') {
          Ember.run.next(this, function () {
            Ember.setProperties(_this2, {
              costCode: null,
              projectsAutoOpen: Date.now()
            });
          });
        }
      }
    }
  });
});