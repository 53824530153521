define('busy-app/utils/models/collections/base', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var Collection = Ember.Object.extend({
		model: null,

		applyData: function applyData(model) {
			Ember.set(this, 'model', model);
		},
		push: function push() {
			this.applyData.apply(this, arguments);
			return this;
		}
	}); /**
      * @module utils/models/collections
      *
      */
	exports.default = Collection;
});