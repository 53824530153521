define('busy-app/utils/time-entry-break', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var TimeEntryBreak = Ember.Object.extend();

	/**
  * `Utils/TimeEntryBreak`
  *
  * @class TimeEntryBreak
  * @namespace Utils
  * @extends EmberObject
  */
	/**
  * @module utils
  *
  */
	TimeEntryBreak.reopenClass({
		totalTime: function totalTime(breaks, startBounds) {
			var _this = this;

			var endBounds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _utils.Time.nistTimestamp();
			var calcOpenBreak = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

			_utils.Assert.funcNumArgs(arguments, 4);
			_utils.Assert.isArray(breaks);
			_utils.Assert.isNumber(startBounds);
			_utils.Assert.isNumber(endBounds);
			_utils.Assert.isBoolean(calcOpenBreak);

			breaks.forEach(function (entry) {
				if (!Ember.get(entry, 'isDeleted')) {
					_this.checkOverlappingTime(entry, breaks);
				}
			});

			var breakTime = 0;
			breaks.forEach(function (entry) {
				if (!Ember.get(entry, 'isDeleted')) {
					var _entryTotalTime = _this.entryTotalTime(entry, startBounds, endBounds, calcOpenBreak),
					    total = _entryTotalTime.total;

					breakTime += total;
				}
			});

			return breakTime;
		},
		entryTotalTime: function entryTotalTime(entry, startBounds) {
			var endBounds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _utils.Time.nistTimestamp();
			var includeOpenBreak = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;

			_utils.Assert.funcNumArgs(arguments, 4);
			_utils.Assert.isObject(entry);
			_utils.Assert.isNumber(startBounds);
			_utils.Assert.isNumber(endBounds);
			_utils.Assert.isBoolean(includeOpenBreak);

			// get start and end time of the break
			var startTime = Ember.get(entry, 'adjustedStartTime') || Ember.get(entry, 'startTime');
			var endTime = Ember.get(entry, 'adjustedEndTime') || Ember.get(entry, 'endTime');

			var isOpenBreak = false;
			if (Ember.isNone(endTime)) {
				isOpenBreak = true;
				if (includeOpenBreak) {
					endTime = endBounds;
				} else {
					endTime = startTime;
				}
			}

			startTime = startTime < startBounds ? startBounds : startTime;
			endTime = endTime > endBounds ? endBounds : endTime;
			endTime = endTime < startTime ? startTime : endTime;

			Ember.set(entry, 'adjustedStartTime', startTime);
			Ember.set(entry, 'adjustedEndTime', endTime);
			Ember.set(entry, 'isOpenBreak', true);

			return {
				startTime: startTime,
				endTime: endTime,
				total: endTime - startTime,
				isOpenBreak: isOpenBreak
			};
		},
		checkOverlappingTime: function checkOverlappingTime(entry, breaks) {
			var _this2 = this;

			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isObject(entry);
			_utils.Assert.isArray(breaks);

			var isOverlapping = false;
			var id = Ember.get(entry, 'id');

			// get start and end adjusted time if it has it otherwise get the real start and endtime
			var startTime = Ember.get(entry, 'adjustedStartTime') || Ember.get(entry, 'startTime');
			var endTime = Ember.get(entry, 'adjustedEndTime') || Ember.get(entry, 'endTime');
			if (!Ember.isNone(endTime) && startTime < endTime) {
				breaks.forEach(function (item) {
					if (id !== Ember.get(item, 'id')) {
						var checkStart = Ember.get(item, 'adjustedStartTime') || Ember.get(item, 'startTime');
						var checkEnd = Ember.get(item, 'adjustedEndTime') || Ember.get(item, 'endTime');

						var _hasConflict = _this2.hasConflict(startTime, endTime, checkStart, checkEnd),
						    startsInside = _hasConflict.startsInside,
						    endsInside = _hasConflict.endsInside;

						if (startsInside && endsInside) {
							// The break is contained inside another break
							// set the end the same as the start so the time does not
							// effect the total break time.
							endTime = startTime;
							isOverlapping = true;
						} else if (startsInside) {
							// The startTime is contained inside another break
							// set the startTime to the other breaks endTime
							startTime = checkEnd;
							isOverlapping = true;
						} else if (endsInside) {
							// The endTime is contained inside another break
							// set the endTime to the other breaks startTime
							endTime = checkStart;
							isOverlapping = true;
						}
					}
				});
			}

			Ember.set(entry, 'adjustedStartTime', startTime);
			Ember.set(entry, 'adjustedEndTime', endTime);
			Ember.set(entry, 'overlappingBreak', isOverlapping);

			return isOverlapping;
		},
		hasConflict: function hasConflict(thisStart, thisEnd, thatStart, thatEnd) {
			var startsInside = false;
			var endsInside = false;
			if (thisStart === thisEnd || thatStart === thatEnd) {
				// one or both entries have no time which is not a conlfict
				startsInside = false;
				endsInside = false;
			} else if (thisStart >= thatStart && thisStart < thatEnd) {
				// entry1 start is within entry2 start and end
				startsInside = true;
			} else if (thisEnd > thatStart && thisEnd <= thatEnd) {
				// entry1 end is within entry2 start and end
				endsInside = true;
			}
			return { startsInside: startsInside, endsInside: endsInside };
		},
		validateTime: function validateTime(entry, startBounds, endBounds) {
			_utils.Assert.funcNumArgs(arguments, 3);
			_utils.Assert.isObject(entry);
			_utils.Assert.isNumber(startBounds);
			if (!Ember.isNone(endBounds)) {
				_utils.Assert.isNumber(endBounds);
			}

			var startTime = Ember.get(entry, 'startTime');
			var endTime = Ember.get(entry, 'endTime');
			var startInvalid = false;
			var endInvalid = false;

			if (startTime < startBounds) {
				startInvalid = true;
			} else if (!Ember.isNone(endBounds) && startTime >= endBounds) {
				startInvalid = true;
			}

			if (!Ember.isNone(endTime)) {
				if (!Ember.isNone(endBounds) && endTime > endBounds) {
					endInvalid = true;
				} else if (endTime <= startBounds) {
					endInvalid = true;
				}
			}

			Ember.set(entry, 'startTime', startTime);
			Ember.set(entry, 'endTime', endTime);

			return { startInvalid: startInvalid, endInvalid: endInvalid };
		}
	});

	exports.default = TimeEntryBreak;
});