define('busy-app/controllers/cost-code/index', ['exports', '@busy-web/utils', 'busy-app/mixins/controller-filter', 'busy-app/utils/debug', 'busy-app/utils/logger'], function (exports, _utils, _controllerFilter, _debug, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_controllerFilter.default, {
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		queryParams: ['group', 'search', 'page_size', 'page', 'filter'],

		group: null,
		search: '',
		page_size: 30,
		page: 1,
		filter: false,

		isLoading: false,

		edit: false,

		insuranceClassMenu: null,

		classTitle: '',
		insuranceClassType: null,
		insuranceClassItem: null,
		workersComp: null,
		generalLiability: null,

		costCode: null,
		newCostCode: null,
		groups: null,

		newGroupName: '',

		selectedGroup: null,
		groupItems: null,

		nameMenu: null,
		descriptionMenu: null,
		wcMenu: null,
		glMenu: null,
		unitMenu: null,
		catMenu: null,

		currentCostCode: null,

		shouldAddGroup: false,

		showMoveGroupDialog: false,
		showNewDialog: false,
		showEditDialog: false,
		showAddInsuranceClassDialog: false,
		showInsuranceClassDialog: false,
		deleteCostCodeDialog: false,
		hideRowActions: false,

		currentType: null,
		updatedClassType: null,

		isProcoreIntegrationEnabled: false,
		procoreCostCodesWarningText: (0, _utils.loc)('Due to a limitation in Procore, busybusy cannot alter Procore cost codes. When updating cost codes, please perform the edits on Procore and the changes will sync to busybusy.'),

		init: function init() {
			this._super();

			Ember.set(this, 'editItems', []);
			Ember.set(this, 'insuranceClassMenu', [Ember.Object.create({ key: 'workers-comp', value: (0, _utils.loc)("Workers' Compensation") }), Ember.Object.create({ key: 'general-liability', value: (0, _utils.loc)('General Liability') })]);

			// loads the organization
			Ember.get(this, 'auth.organization');
			this.isEditMode();
		},


		isEditMode: Ember.observer('filter', function () {
			if (Ember.get(this, 'filter')) {
				Ember.set(this, 'edit', true);
			} else {
				Ember.set(this, 'edit', false);
			}
		}),

		canManageCostCodeGroups: Ember.computed('auth.authMemberPosition.manageCostCodes', function () {
			return Ember.get(this, 'auth.authMemberPosition.manageCostCodes');
		}),

		canManageCostCodes: Ember.computed('auth.authMemberPosition.manageCostCodes', function () {
			return Ember.get(this, 'auth.authMemberPosition.manageCostCodes');
		}),

		canAddCostCodes: Ember.computed('canManageCostCodes', 'group', function () {
			return Ember.get(this, 'canManageCostCodes') && Ember.get(this, 'group') !== 'archived';
		}),

		canViewManagementTools: Ember.computed.and('canManageCostCodes', 'features.managementListsCostCodes'),

		hasRowActions: Ember.computed('canManageCostCodes', 'hideRowActions', function () {
			return Ember.get(this, 'canManageCostCodes') && !Ember.get(this, 'hideRowActions');
		}),

		totalRows: Ember.computed('model.@each.id', function () {
			var rows = 0;
			var meta = Ember.get(this, 'model.meta');
			if (!Ember.isNone(meta)) {
				rows = meta.totalRows;
			}
			return rows;
		}),

		mergedClassList: Ember.computed('workersComp', 'generalLiability', function () {
			var workers = Ember.get(this, 'workersComp').sortBy('title');
			var general = Ember.get(this, 'generalLiability').sortBy('title');
			var combined = [].concat(workers, general);
			combined = combined.filter(function (item) {
				return Ember.get(item, 'title') !== 'No Insurance';
			});
			return combined.sortBy('title');
		}),

		workersCompList: Ember.computed('workersComp', 'costCode.workersComp', function () {
			if (!Ember.isEmpty(Ember.get(this, 'workersComp'))) {
				var selected = Ember.get(this, 'costCode.workersComp');
				var models = Ember.get(this, 'workersComp').toArray();
				models.forEach(function (item) {
					Ember.set(item, '_selected', !Ember.isNone(selected) && Ember.get(item, 'title') === Ember.get(selected, 'title') ? true : false);
					Ember.set(item, 'sortWeight', Ember.get(item, 'title') === 'No Insurance' ? 1 : 2);
				});
				return models.sortBy('sortWeight', 'title');
			}
			return [];
		}),

		generalLiabilityList: Ember.computed('generalLiability', 'costCode.generalLiability', function () {
			if (!Ember.isNone(Ember.get(this, 'generalLiability'))) {
				var selected = Ember.get(this, 'costCode.generalLiability');
				var models = Ember.get(this, 'generalLiability').toArray();
				models.forEach(function (item) {
					Ember.set(item, '_selected', !Ember.isNone(selected) && Ember.get(item, 'title') === Ember.get(selected, 'title') ? true : false);
					Ember.set(item, 'sortWeight', Ember.get(item, 'title') === 'No Insurance' ? 1 : 2);
				});
				return models.sortBy('sortWeight', 'title');
			}
			return [];
		}),

		resetInsuranceClassMenu: function resetInsuranceClassMenu() {
			Ember.set(this, 'insuranceClassMenu', [Ember.Object.create({ key: 'workers-comp', value: (0, _utils.loc)("Workers' Compensation") }), Ember.Object.create({ key: 'general-liability', value: (0, _utils.loc)('General Liability') })]);
		},


		selectMenu: Ember.computed('group', function () {
			if (Ember.get(this, 'group') === 'archived') {
				return [Ember.Object.create({ key: 'unarchive', value: (0, _utils.loc)('Unarchive') })];
			} else {
				return [Ember.Object.create({ key: 'move-to-group', value: (0, _utils.loc)('Move to Group') }), Ember.Object.create({ key: 'archive', value: (0, _utils.loc)('Archive') })];
			}
		}),

		getGroups: function getGroups() {
			var _this = this;

			return (0, _debug.component_safe_api)(function () {
				return _this.store.query('cost-code-group', { _asc: ['group_name'], page_size: 10000 }).then(function (models) {
					return Ember.set(_this, 'groups', models);
				});
			});
		},
		getInsuranceClasses: function getInsuranceClasses() {
			var _this2 = this;

			return Ember.RSVP.hash({
				workersComp: this.store.findAll('workers-comp', { deleted_on: '_-NULL-_' }).then(function (workersComp) {
					return Ember.set(_this2, 'workersComp', workersComp);
				}),
				generalLiability: this.store.findAll('general-liability', { deleted_on: '_-NULL-_' }).then(function (generalLiability) {
					return Ember.set(_this2, 'generalLiability', generalLiability);
				})
			});
		},


		editAction: null,
		editItems: null,

		validateFilters: function validateFilters(options) {
			if (options.filter) {
				if ((!Ember.isEmpty(options.search) || !Ember.isNone(options.group) && options.group !== 'ungrouped' && options.group !== 'archived') && !options.page) {
					options.page = 1;
					options.page_size = 1000;
				} else if ((options.group === 'ungrouped' || options.group === 'archived') && !options.page) {
					options.page = 1;
					options.page_size = Ember.get(this, 'page_size');
				}
			}

			options.filter = options.filter === true ? true : false;
			options.group = Ember.isNone(options.group) ? null : options.group;
			options.search = Ember.isEmpty(options.search) ? '' : options.search;
			return true;
		},


		/**
   * Clears the edit action
   *
   * @public
   * @method clearEdit
   */
		clearEdit: function clearEdit() {
			// bc-list sets isSelected on the model
			// so loop through the models and set isSelected
			// to false to uncheck the checkboxes
			Ember.get(this, 'editItems').forEach(function (item) {
				return Ember.set(item, 'isSelected', false);
			});

			// set local vars back to defaults
			Ember.set(this, 'editAction', null);
			Ember.set(this, 'editItems', []);
			Ember.set(this, 'edit', false);
			Ember.set(this, 'hideRowActions', false);

			this.sendFilter({
				search: Ember.get(this, 'search'),
				group: Ember.get(this, 'group')
			});
		},
		openArchiveDialog: function openArchiveDialog(items) {
			if (items.length < 2) {
				Ember.set(this, 'currentCostCode', items.objectAt(0));
			}
			Ember.set(this, 'archiveItems', items);

			Ember.set(this, 'showArchiveDialog', true);
		},
		archive: function archive() {
			var _this3 = this;

			var items = Ember.get(this, 'archiveItems');
			return Ember.RSVP.all(items.map(function (item) {
				Ember.set(item, 'archivedOn', _utils.Time.timestamp());
				item.save();
			})).then(function () {
				_this3.clearEdit();
				Ember.set(_this3, 'archiveItems', null);
				Ember.set(_this3, 'showArchiveDialog', false);
				_this3.refreshRoute();
			});
		},
		unarchive: function unarchive(items, callback) {
			var _this4 = this;

			callback = callback !== undefined ? callback : function () {};
			return Ember.RSVP.all(items.map(function (item) {
				Ember.set(item, 'archivedOn', '_-NULL-_');
				item.save();
			})).then(function () {
				_this4.refreshRoute();
				Ember.run.next(function () {
					return callback();
				});
			});
		},
		moveToGroup: function moveToGroup(items) {
			if (!Ember.isNone(items) && Ember.get(items, 'length') > 0) {
				Ember.set(this, 'groupItems', items);
				Ember.set(this, 'showMoveGroupDialog', true);
			}
		},
		saveCostCode: function saveCostCode(costCode) {
			Ember.set(costCode, 'costCode', (Ember.get(costCode, 'costCode') || '').trim());
			Ember.set(costCode, 'title', (Ember.get(costCode, 'title') || '').trim());
			Ember.set(costCode, 'unitTitle', (Ember.get(costCode, 'unitTitle') || '').trim());

			if (!Ember.isEmpty(Ember.get(costCode, 'costCode'))) {
				return costCode.save().then(function (model) {
					model.getChildren();
					return model;
				});
			}
			return Ember.RSVP.reject((0, _utils.loc)('You must fill in the required fields'));
		},
		addCostCodeToGroup: function addCostCodeToGroup(group) {
			var _this5 = this;

			var groupId = '_-NULL-_';
			if (!Ember.isNone(group)) {
				groupId = Ember.get(group, 'id');
			}

			var groupItems = Ember.get(this, 'groupItems');
			return Ember.RSVP.all(groupItems.map(function (item) {
				Ember.set(item, 'costCodeGroupId', groupId);
				return item.save();
			})).then(function () {
				if (!Ember.isNone(Ember.get(_this5, 'group')) && groupId !== Ember.get(_this5, 'group') || Ember.get(_this5, 'group') !== 'ungrouped') {
					Ember.get(_this5, 'model').removeObjects(groupItems);
				}

				Ember.set(_this5, 'groupItems', []);
				Ember.set(_this5, 'selectedGroup', null);
				Ember.set(_this5, 'showMoveGroupDialog', false);

				return _this5.refreshRoute();
			});
		},
		_createGroup: function _createGroup(groupName) {
			var _this6 = this;

			var organizationId = Ember.get(this, 'auth.organization.id');
			return this.store.createRecord('cost-code-group', { groupName: groupName, organizationId: organizationId }).save().finally(function () {
				return _this6.getGroups();
			});
		},
		refreshRoute: function refreshRoute() {
			return location.reload();
		},


		archiveDialogMessage: Ember.computed('currentCostCode', function () {
			var costCodeText = (0, _utils.loc)("these cost codes");
			if (!Ember.isNone(Ember.get(this, 'currentCostCode.title'))) {
				costCodeText = Ember.get(this, 'currentCostCode.title');
			}
			return (0, _utils.loc)("Are you sure you want to archive %@ ?", [costCodeText]);
		}),

		// when a group is deleted, ungroup all the cost-code's which were assigned to it
		ungroupOrphans: function ungroupOrphans(group) {
			var _this7 = this;

			_logger.default.info(this, 'ungroupOrphans', group, Ember.get(group, 'id'));

			if (Ember.isNone(group)) {
				return Ember.RSVP.resolve();
			}

			var cost_code_group_id = Ember.get(group, 'id');
			var params = {
				cost_code_group_id: cost_code_group_id,
				page_size: 1000
			};

			Ember.set(this, 'isLoading', true);
			return Ember.get(this, 'store').findAll('cost-code', params).then(function (costCodes) {
				costCodes.setEach('costCodeGroupId', null);

				return Ember.RSVP.all(costCodes.invoke('save'));
			}).then(function () {
				return _this7.refreshRoute();
			}).finally(function () {
				return Ember.set(_this7, 'isLoading', false);
			});
		},


		actions: {
			addCostCodeToGroupAction: function addCostCodeToGroupAction(costCode) {
				return this.addCostCodeToGroup(costCode);
			},
			rowItemSelectAction: function rowItemSelectAction(action, member, costCode) {
				if (Ember.get(action, 'key') === 'archive') {
					return this.openArchiveDialog([costCode]);
				} else if (Ember.get(action, 'key') === 'unarchive') {
					return this.unarchive([costCode]);
				} else if (Ember.get(action, 'key') === 'move-to-group') {
					return this.moveToGroup([costCode]);
				} else {
					_logger.default.error("An unrecognized action was called in the controller/project/index actionView");
				}
			},
			costCodeSelected: function costCodeSelected(isChecked, item) {
				if (isChecked) {
					Ember.get(this, 'editItems').pushObject(item);
				} else {
					Ember.get(this, 'editItems').removeObject(item);
				}
			},
			selectAll: function selectAll(isChecked, items) {
				if (isChecked) {
					Ember.set(this, 'editItems', items);
				} else {
					Ember.set(this, 'editItems', []);
				}
			},
			editListAction: function editListAction(action) {
				Ember.set(this, 'editAction', Ember.get(action, 'key'));
				Ember.set(this, 'hideRowActions', true);
				Ember.set(this, 'edit', true);

				var params = {
					filter: true,
					group: Ember.get(this, 'group'),
					ungrouped: Ember.get(this, 'ungrouped'),
					search: Ember.get(this, 'search')
				};

				if (Ember.get(action, 'key') === 'unarchive') {
					params.group === 'archived';
				}

				this.sendFilter(params);
			},
			submitEditAction: function submitEditAction() {
				var _this8 = this;

				var filteredItems = Ember.get(this, 'editItems');
				if (Ember.isNone(filteredItems) || Ember.get(this, 'editItems.length') === 0) {
					this.clearEdit();
				} else if (Ember.get(this, 'editAction') === 'archive') {
					this.openArchiveDialog(filteredItems);
				} else if (Ember.get(this, 'editAction') === 'unarchive') {
					this.unarchive(filteredItems, function () {
						_this8.clearEdit();
					});
				} else if (Ember.get(this, 'editAction') === 'move-to-group') {
					if (!Ember.isNone(filteredItems) && Ember.get(this, 'editItems.length') > 0) {
						Ember.set(this, 'groupItems', filteredItems);
						Ember.set(this, 'showMoveGroupDialog', true);
					}
					this.clearEdit();
				}
			},
			cancelEditAction: function cancelEditAction() {
				this.clearEdit();
			},
			selectWorkersComp: function selectWorkersComp(item) {
				if (!Ember.isNone(Ember.get(this, 'newCostCode'))) {
					if (item.id !== '_-NULL-_') {
						Ember.set(this, 'newCostCode.workersCompId', Ember.get(item, 'id'));
					} else {
						Ember.set(this, 'newCostCode.workersCompId', null);
					}
				} else if (!Ember.isNone(Ember.get(this, 'costCode'))) {
					// TODO:
					// rewrite next line to use embers check for a set property that is not yet saved.
					if (item.id === '_-NULL-_' && Ember.get(this, 'costCode._internalModel._data.workersCompId') !== null) {
						Ember.set(this, 'costCode.workersCompId', Ember.get(item, 'id'));
					} else if (item.id === '_-NULL-_' && Ember.get(this, 'costCode.workersCompId') !== null) {
						Ember.set(this, 'costCode.workersCompId', null);
						Ember.set(this, 'costCode.workersComp', null);
					} else {
						Ember.set(this, 'costCode.workersCompId', Ember.get(item, 'id'));
						Ember.set(this, 'costCode.workersComp', item);
					}
				} else {
					Ember.Error('The newCostCode or costCode must be set before selectWorkersComp is called');
				}
			},
			selectGeneralLiability: function selectGeneralLiability(item) {
				if (!Ember.isNone(Ember.get(this, 'newCostCode'))) {
					if (item.id !== '_-NULL-_') {
						Ember.set(this, 'newCostCode.generalLiabilityId', item.id);
					} else {
						Ember.set(this, 'newCostCode.generalLiabilityId', null);
					}
				} else if (!Ember.isNone(Ember.get(this, 'costCode'))) {
					// TODO:
					// rewrite next line to use embers check for a set property that is not yet saved.
					if (item.id === '_-NULL-_' && Ember.get(this, 'costCode._internalModel._data.generalLiabilityId') !== null) {
						Ember.set(this, 'costCode.generalLiabilityId', Ember.get(item, 'id'));
					} else if (item.id === '_-NULL-_' && Ember.get(this, 'costCode.generalLiabilityId') !== null) {
						Ember.set(this, 'costCode.generalLiabilityId', null);
						Ember.set(this, 'costCode.generalLiability', null);
					} else {
						Ember.set(this, 'costCode.generalLiabilityId', Ember.get(item, 'id'));
						Ember.set(this, 'costCode.generalLiability', item);
					}
				} else {
					Ember.Error('The newCostCode or costCode must be set before selectGeneralLiability is called');
				}
			},
			selectNewClass: function selectNewClass(item) {
				if (Ember.get(this, 'insuranceClassItem._internalModel.modelName') !== item.key) {
					Ember.set(this, 'insuranceClassItem._changeType', item.key);
				}
			},
			openEditCostCodeDialog: function openEditCostCodeDialog(costCode) {
				this.getInsuranceClasses();
				if (!Ember.get(this, 'edit')) {
					Ember.set(this, 'showEditDialog', true);
					Ember.set(this, 'costCode', costCode);
				}
			},
			closeEditCostCodeDialog: function closeEditCostCodeDialog() {
				Ember.set(this, 'showEditDialog', false);
				Ember.get(this, 'costCode').rollbackAttributes();
				Ember.set(this, 'costCode', null);
			},
			saveCostCode: function saveCostCode() {
				var costCode = Ember.get(this, 'costCode');

				if (Ember.get(costCode, 'hasDirtyAttributes')) {
					return this.saveCostCode(costCode).catch(function (err) {
						if (typeof err === 'string') {
							return Ember.RSVP.reject(err);
						} else {
							return Ember.RSVP.reject((0, _utils.loc)("Something went wrong. Please try again later."));
						}
					});
				} else {
					return Ember.RSVP.resolve();
				}
			},
			deleteCostCode: function deleteCostCode() {
				var _this9 = this;

				var costCode = Ember.get(this, 'costCode');
				Ember.set(costCode, 'archivedOn', _utils.Time.timestamp());
				return costCode.save().then(function () {
					Ember.set(_this9, 'showEditDialog', false);
					_this9.refreshRoute();
				});
			},
			saveNewCostCode: function saveNewCostCode() {
				var _this10 = this;

				var costCode = Ember.get(this, 'newCostCode');
				return this.saveCostCode(costCode).catch(function (err) {
					return Ember.RSVP.reject(typeof err === 'string' ? err : (0, _utils.loc)("Something went wrong. Please try again later."));
				}).finally(function () {
					return _this10.refreshRoute();
				});
			},
			openNewCostCodeDialog: function openNewCostCodeDialog() {
				this.getInsuranceClasses();

				var costCode = this.store.createRecord('cost-code', {
					organizationId: Ember.get(this, 'auth.organization.id')
				});

				Ember.set(this, 'newCostCode', costCode);
				Ember.set(this, 'showNewDialog', true);
			},
			closeNewCostCodeDialog: function closeNewCostCodeDialog() {
				Ember.get(this, 'mergedClassList').forEach(function (item) {
					return Ember.set(item, '_selected', false);
				});

				Ember.set(this, 'showNewDialog', false);
				Ember.set(this, 'newCostCode', null);
			},
			openEditInsuranceClassDialog: function openEditInsuranceClassDialog(item) {
				this.getInsuranceClasses();

				Ember.set(this, 'insuranceClassItem', item);
				Ember.set(this, 'showInsuranceClassDialog', false);
				Ember.set(this, 'showEditInsuranceClassDialog', true);
				Ember.set(this, 'currentType', item.type);
			},
			closeEditInsuranceClassDialog: function closeEditInsuranceClassDialog() {
				this.getInsuranceClasses();

				Ember.get(this, 'insuranceClassItem').rollbackAttributes();
				Ember.set(this, 'showInsuranceClassDialog', true);
				Ember.set(this, 'showEditInsuranceClassDialog', false);
			},
			openAddInsuranceClassDialog: function openAddInsuranceClassDialog() {
				Ember.set(this, 'showInsuranceClassDialog', false);
				Ember.set(this, 'showAddInsuranceClassDialog', true);
				Ember.set(this, 'insuranceClassType', null);
				Ember.set(this, 'insuranceClassTitle', null);

				this.resetInsuranceClassMenu();
			},
			closeAddInsuranceClassDialog: function closeAddInsuranceClassDialog() {
				Ember.set(this, 'classTitle', '');
				Ember.set(this, 'showAddInsuranceClassDialog', false);
				Ember.set(this, 'showInsuranceClassDialog', true);
			},
			openInsuranceClassDialog: function openInsuranceClassDialog() {
				Ember.set(this, 'showInsuranceClassDialog', true);
			},
			closeInsuranceClassDialog: function closeInsuranceClassDialog() {
				Ember.set(this, 'showInsuranceClassDialog', false);
				Ember.set(this, 'showEditInsuranceClassDialog', false);
				Ember.set(this, 'showAddInsuranceClassDialog', false);
			},
			saveNewClass: function saveNewClass() {
				var _this11 = this;

				var type = Ember.get(this, 'insuranceClassType');

				if (!Ember.isEmpty(type)) {
					return Ember.RSVP.reject((0, _utils.loc)('You must select an insurance type'));
				}

				if (!Ember.isEmpty(Ember.get(this, 'classTitle'))) {
					return Ember.RSVP.reject((0, _utils.loc)('You must provide a title for this insurance class'));
				}

				var model = Ember.get(this, type);
				Ember.set(model, 'title', Ember.get(this, 'classTitle'));
				return model.save().finally(function () {
					return Ember.get(_this11, Ember.String.camelize(type)).update() && Ember.set(_this11, type, null);
				});
			},
			saveInsuranceClass: function saveInsuranceClass() {
				var _this12 = this;

				var insurance = Ember.get(this, 'insuranceClassItem');
				var type = Ember.get(insurance, '_changeType');
				if (!Ember.isNone(insurance)) {
					if (!Ember.isNone(type)) {
						var organizationId = Ember.get(insurance, 'organizationId');
						var title = Ember.get(insurance, 'title');
						return this.store.createRecord(type, { title: title, organizationId: organizationId }).save() // save new insuranceClassItem
						.then(function (model) {
							return insurance.destroyRecord() && Ember.set(_this12, 'insuranceClassItem', model) && model;
						}) // delete old insuranceClassItem and set new insuranceClassItem
						.finally(function () {
							return Ember.get(_this12, Ember.String.camelize(type)).update();
						}); // reload insuranceClassItems lists
					} else if (Ember.get(insurance, 'hasDirtyAttributes')) {
						return insurance.save();
					}
				}
				return Ember.RSVP.resolve(Ember.get(this, 'insuranceClassItem'));
			},
			deleteInsuranceClass: function deleteInsuranceClass() {
				var _this13 = this;

				return Ember.get(this, 'insuranceClassItem').destroyRecord().finally(function () {
					Ember.get(_this13, 'workersComp').update();
					Ember.get(_this13, 'generalLiability').update();
				});
			},
			setSelectedGroup: function setSelectedGroup(view, group) {
				Ember.set(this, 'selectedGroup', group);
			},
			addNewGroup: function addNewGroup(value) {
				return this._createGroup(value);
			},
			createGroup: function createGroup() {
				var _this14 = this;

				if (!Ember.isEmpty(Ember.get(this, 'newGroupName'))) {
					return this._createGroup(Ember.get(this, 'newGroupName')).finally(function () {
						return Ember.set(_this14, 'newGroupName', '');
					});
				}
				return Ember.RSVP.reject("The group must have a title");
			},
			onDeleteGroup: function onDeleteGroup(group) {
				_logger.default.info(this, 'onDeleteGroup', group);

				return this.ungroupOrphans(group);
			},
			showAddGroup: function showAddGroup() {
				Ember.set(this, 'shouldAddGroup', !Ember.get(this, 'shouldAddGroup'));
			},
			closeGroupDialog: function closeGroupDialog() {
				Ember.set(this, 'showMoveGroupDialog', false);
			},
			archiveCostCode: function archiveCostCode() {
				return this.archive();
			},
			closeArchiveDialog: function closeArchiveDialog() {
				Ember.set(this, 'archiveItems', null);
				Ember.set(this, 'showArchiveDialog', false);
			},
			filterGroup: function filterGroup(group) {
				var options = {
					search: Ember.get(this, 'search'),
					filter: Ember.get(this, 'filter'),
					group: Ember.get(group, 'id')
				};
				this.sendFilter(options);
			},
			searchAction: function searchAction(keyword) {
				keyword = !Ember.isEmpty(keyword) ? keyword : '';

				this.sendFilter({
					search: keyword,
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group')
				});
			},
			changeSize: function changeSize(size) {
				size = parseInt(size, 10);
				size = !isNaN(size) && size > 30 ? size : 30;

				this.sendFilter({
					page: 1,
					page_size: size,
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group'),
					search: Ember.get(this, 'search')
				});
			},
			changePage: function changePage(page) {
				page = parseInt(page, 10);
				page = !isNaN(page) && page > 1 ? page : 1;

				this.sendFilter({
					page: page,
					page_size: Ember.get(this, 'page_size'),
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group'),
					search: Ember.get(this, 'search')
				});
			},


			/*** Cost Code Import ***/

			openImportAction: function openImportAction() {
				Ember.set(this, 'showNewDialog', false);
				Ember.set(this, 'showImportDialog', true);
			},
			closeImportAction: function closeImportAction() {
				Ember.set(this, 'showImportDialog', false);
				Ember.set(this, 'showNewDialog', false);
			},
			cancelImportAction: function cancelImportAction() {
				Ember.set(this, 'showImportDialog', false);
				Ember.set(this, 'showNewDialog', true);
			},
			importCompleteAction: function importCompleteAction() {
				Ember.set(this, 'showNewDialog', false);
				Ember.set(this, 'showImportDialog', false);
				this.refreshRoute();
			}

			/*** Cost Code Import End ***/

		}
	});
});