define('busy-app/polymorphs/custom-wage-date/payroll-member', ['exports', 'busy-app/utils/collection', 'busy-app/polymorphs/pay-period-member'], function (exports, _collection, _payPeriodMember) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _payPeriodMember.default.extend({
		wage: null,
		wageHistory: null,
		payPeriodType: null,
		projects: null,
		nestedMember: false,

		hasChildren: Ember.computed('projects.[]', function () {
			return !Ember.isEmpty(Ember.get(this, 'projects'));
		}),

		hasRegular: Ember.computed('regularSeconds', 'regularCost', 'totalRegularSeconds', 'totalRegularCost', function () {
			if (Ember.get(this, 'regularSeconds') > 0 || Ember.get(this, 'regularCost') > 0) {
				return true;
			} else if (Ember.get(this, 'nestedMember') && (Ember.get(this, 'totalRegularSeconds') > 0 || Ember.get(this, 'totalRegularCost') > 0)) {
				return true;
			}
			return false;
		}),

		hasOvertime: Ember.computed('overtimeSeconds', 'overtimeCost', 'totalOvertimeSeconds', 'totalOvertimeCost', function () {
			if (Ember.get(this, 'overtimeSeconds') > 0 || Ember.get(this, 'overtimeCost') > 0) {
				return true;
			} else if (Ember.get(this, 'nestedMember') && (Ember.get(this, 'totalOvertimeSeconds') > 0 || Ember.get(this, 'totalOvertimeCost') > 0)) {
				return true;
			}
			return false;
		}),

		hasDoubletime: Ember.computed('doubletimeSeconds', 'doubletimeCost', 'totalDoubletimeSeconds', 'totalDoubletimeCost', function () {
			if (Ember.get(this, 'doubletimeSeconds') > 0 || Ember.get(this, 'doubletimeCost') > 0) {
				return true;
			} else if (Ember.get(this, 'nestedMember') && (Ember.get(this, 'totalDoubletimeSeconds') > 0 || Ember.get(this, 'totalDoubletimeCost') > 0)) {
				return true;
			}
			return false;
		}),

		/**
   * regular seconds should be used when dealing with member only data.
   * When dealing with pay-period-member-project-seconds this will give you
   * the seconds without the child seconds factored in.
   */
		regularSeconds: Ember.computed('seconds.@each.{regularSeconds,overtimeSeconds,doubletimeSeconds}', 'wage.isSalary', function () {
			var seconds = Ember.get(this, 'seconds');

			if (Ember.isNone(seconds)) {
				return 0;
			} else if (Ember.get(this, 'wage.isSalary')) {
				return (0, _collection.sumBy)(seconds, ['regularSeconds', 'overtimeSeconds', 'doubletimeSeconds']);
			} else {
				return (0, _collection.sumBy)(seconds, 'regularSeconds');
			}
		}),

		/**
   * totalRegularSeconds will provide child regular seconds as well as regualr seconds.
   */
		totalRegularSeconds: Ember.computed('seconds.@each.{totalRegularSeconds,totalOvertimeSeconds,totalDoubletimeSeconds}', 'wage.isSalary', function () {
			var seconds = Ember.get(this, 'seconds');

			if (Ember.isNone(seconds)) {
				return 0;
			} else if (Ember.get(this, 'wage.isSalary')) {
				return (0, _collection.sumBy)(seconds, ['totalRegularSeconds', 'totalOvertimeSeconds', 'totalDoubletimeSeconds']);
			} else {
				return (0, _collection.sumBy)(seconds, 'totalRegularSeconds');
			}
		}),

		overtimeSeconds: Ember.computed('seconds.@each.overtimeSeconds', 'wage.isSalary', function () {
			var seconds = Ember.get(this, 'seconds');

			if (Ember.isNone(seconds) || Ember.get(this, 'wage.isSalary')) {
				return 0;
			} else {
				return (0, _collection.sumBy)(seconds, 'overtimeSeconds');
			}
		}),

		totalOvertimeSeconds: Ember.computed('seconds.@each.totalOvertimeSeconds', 'wage.isSalary', function () {
			var seconds = Ember.get(this, 'seconds');

			if (Ember.isNone(seconds) || Ember.get(this, 'wage.isSalary')) {
				return 0;
			} else {
				return (0, _collection.sumBy)(seconds, 'totalOvertimeSeconds');
			}
		}),

		doubletimeSeconds: Ember.computed('seconds.@each.doubletimeSeconds', 'wage.isSalary', function () {
			var seconds = Ember.get(this, 'seconds');

			if (Ember.isNone(seconds) || Ember.get(this, 'wage.isSalary')) {
				return 0;
			} else {
				return (0, _collection.sumBy)(seconds, 'doubletimeSeconds');
			}
		}),

		totalDoubletimeSeconds: Ember.computed('seconds.@each.totalDoubletimeSeconds', 'wage.isSalary', function () {
			var seconds = Ember.get(this, 'seconds');

			if (Ember.isNone(seconds) || Ember.get(this, 'wage.isSalary')) {
				return 0;
			} else {
				return (0, _collection.sumBy)(seconds, 'totalDoubletimeSeconds');
			}
		}),

		regularCost: Ember.computed('cost.@each.regularCost', 'totalSeconds', 'wage.isHourly', function () {
			var cost = Ember.get(this, 'cost');

			if (Ember.isNone(cost)) {
				return 0;
			} else if (Ember.get(this, 'wage.isHourly')) {
				return (0, _collection.sumBy)(cost, 'regularCost');
			} else {
				return this.getSalaryCostByType('totalSeconds');
			}
		}),

		totalRegularCost: Ember.computed('cost.@each.totalRegularCost', 'totalWithChildrenSeconds', 'wage.isHourly', function () {
			var cost = Ember.get(this, 'cost');

			if (Ember.isNone(cost)) {
				return 0;
			} else if (Ember.get(this, 'wage.isHourly')) {
				return (0, _collection.sumBy)(cost, 'totalRegularCost');
			} else {
				return this.getSalaryCostByType('totalWithChildrenSeconds');
			}

			// return !isNone(cost) ? sumBy(cost, 'totalRegularCost') : 0;
		}),

		overtimeCost: Ember.computed('cost.@each.overtimeCost', 'wage.isSalary', function () {
			var cost = Ember.get(this, 'cost');

			if (Ember.isNone(cost) || Ember.get(this, 'wage.isSalary')) {
				return 0;
			} else {
				return (0, _collection.sumBy)(cost, 'overtimeCost');
			}
		}),

		totalOvertimeCost: Ember.computed('cost.@each.totalOvertimeCost', 'wage.isSalary', function () {
			var cost = Ember.get(this, 'cost');

			if (Ember.isNone(cost) || Ember.get(this, 'wage.isSalary')) {
				return 0;
			} else {
				return (0, _collection.sumBy)(cost, 'totalOvertimeCost');
			}
		}),

		doubletimeCost: Ember.computed('cost.@each.doubletimeCost', 'wage.isSalary', function () {
			var cost = Ember.get(this, 'cost');

			if (Ember.isNone(cost) || Ember.get(this, 'wage.isSalary')) {
				return 0;
			} else {
				return (0, _collection.sumBy)(cost, 'doubletimeCost');
			}
		}),

		totalDoubletimeCost: Ember.computed('cost.@each.totalDoubletimeCost', function () {
			var cost = Ember.get(this, 'cost');

			return !Ember.isNone(cost) ? (0, _collection.sumBy)(cost, 'totalDoubletimeCost') : 0;
		}),

		totalOverburdenCost: Ember.computed('totalCost', 'overburdenCost', function () {
			var _EmberGetProperties = Ember.getProperties(this, ['totalCost', 'overburdenCost']),
			    totalCost = _EmberGetProperties.totalCost,
			    overburdenCost = _EmberGetProperties.overburdenCost;

			return totalCost + overburdenCost;
		}),

		totalOverburdenCostWithChildren: Ember.computed('totalWithChildrenCost', 'overburdenWithChildrenCost', function () {
			var _EmberGetProperties2 = Ember.getProperties(this, ['totalWithChildrenCost', 'overburdenWithChildrenCost']),
			    totalWithChildrenCost = _EmberGetProperties2.totalWithChildrenCost,
			    overburdenWithChildrenCost = _EmberGetProperties2.overburdenWithChildrenCost;

			return totalWithChildrenCost + overburdenWithChildrenCost;
		}),

		actualCost: Ember.computed('totalCost', 'payPeriodSalary', function () {
			if (Ember.get(this, 'payPeriodSalary') > 0) {
				return Ember.get(this, 'payPeriodSalary');
			} else {
				return Ember.get(this, 'totalCost');
			}
		}),

		payPeriodSalary: Ember.computed('wage.{wage,wageRate,isSalary,}', 'payPeriodType', function () {
			var _EmberGetProperties3 = Ember.getProperties(this, ['wage', 'payPeriodType']),
			    wage = _EmberGetProperties3.wage,
			    payPeriodType = _EmberGetProperties3.payPeriodType;

			var _EmberGetProperties4 = Ember.getProperties(wage, ['wageRate', 'isSalary']),
			    wageRate = _EmberGetProperties4.wageRate,
			    isSalary = _EmberGetProperties4.isSalary;

			if (isSalary && !Ember.isNone(wageRate) && !Ember.isNone(payPeriodType)) {
				return wage.getPayPeriodSalary(payPeriodType);
			} else {
				return 0;
			}
		}),

		salaryCostPerSecond: Ember.computed('wage.isSalary', 'payPeriodSalary', 'member.seconds.totalSeconds', function () {
			if (Ember.get(this, 'wage.isSalary') && Ember.get(this, 'payPeriodSalary') > 0) {
				// this should be the total seconds for the member for the whole pay period
				var totalSeconds = Ember.get(this, 'member.seconds.totalSeconds');
				var salary = Ember.get(this, 'payPeriodSalary');

				if (Ember.isNone(totalSeconds) || totalSeconds <= 0) {
					return 0;
				}

				return salary / totalSeconds;
			} else {
				return 0;
			}
		}),

		getSalaryCostByType: function getSalaryCostByType(typeKey) {
			var _EmberGetProperties5 = Ember.getProperties(this, [typeKey, 'payPeriodSalary', 'salaryCostPerSecond']),
			    payPeriodSalary = _EmberGetProperties5.payPeriodSalary,
			    salaryCostPerSecond = _EmberGetProperties5.salaryCostPerSecond,
			    typeSeconds = _EmberGetProperties5[typeKey];

			if (!Ember.isNone(typeSeconds) && typeSeconds > 0 && !Ember.isNone(salaryCostPerSecond)) {
				return typeSeconds * salaryCostPerSecond;
			} else {
				return payPeriodSalary;
			}
		},


		estCostHourly: Ember.computed('wage.isHourly', 'totalOverburdenCost', function () {
			return Ember.get(this, 'wage.isHourly') ? Ember.get(this, 'totalOverburdenCost') : 0;
		}),

		estCostSalary: Ember.computed('wage.isSalary', 'totalOverburdenCost', function () {
			return Ember.get(this, 'wage.isSalary') ? Ember.get(this, 'totalOverburdenCost') : 0;
		}),

		estCostHourlyWithChildren: Ember.computed('wage.isHourly', 'totalOverburdenCostWithChildren', function () {
			return Ember.get(this, 'wage.isHourly') ? Ember.get(this, 'totalOverburdenCostWithChildren') : 0;
		}),

		estCostSalaryWithChildren: Ember.computed('wage.isSalary', 'totalOverburdenCostWithChildren', function () {
			return Ember.get(this, 'wage.isSalary') ? Ember.get(this, 'totalOverburdenCostWithChildren') : 0;
		})
	});
});