define('busy-app/components/employees/edit-lock-date', ['exports', 'moment', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _moment, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),
		analytics: Ember.inject.service('analytics'),
		features: Ember.inject.service('features'),
		store: Ember.inject.service('store'),

		/**
   * Public Properties
   */

		/**
   * @public
   * @property classNames
   * @type string[]
   * @default v-employees-edit-lock-date
   */

		classNames: ['c-employees-edit-lock-date'],

		/**
   * @public
   * @property memberEntries
   * @type Models.Member[]
   */
		memberEntries: null,

		/**
   * @public
   * @property totalRows
   * @type number
   */
		totalRows: null,

		/**
   * Private Properties
   */

		/**
   * @private
   * @property lockDate
   * @type Moment
   */
		lockDate: null,

		/**
   * @private
   * @property format
   * @type string
   */
		format: null,

		/**
   * @private
   * @property maxDate
   * @type Moment
   */
		maxDate: null,

		/**
   * @private
   * @property showSaveConfirmOverlay
   * @type boolean
   * @default false
   */
		showSaveConfirmOverlay: false,

		/**
   * @private
   * @property showDeleteConfirmOverlay
   * @type boolean
   * @default false
   */
		showDeleteConfirmOverlay: false,

		/**
   * Component Lifecycle
   */

		/**
   * @private
   * @constructor
   * @async
   * @method init
   */

		init: function init() {
			this._super();

			_utils.Assert.isArray(this.get('memberEntries'));
			_utils.Assert.test('Must select a member', this.get('memberEntries.length'));

			// config for the date picker
			this.set('format', _utils.Locale.format("ddd, MMM DD", _moment.default.locale()));
			this.set('maxDate', _utils.Time.date().add(-1, 'days').endOf('day').unix());
			this.set('lockDate', this.getDefaultLockDate());

			// make sure every member has memberLock set
			return Ember.RSVP.all(this.get('memberEntries').map(function (member) {
				_utils.Assert.isObject(member);
				return function () {
					return member.get('memberLock');
				};
			}));
		},


		/**
   * Computed Properties
   */

		/**
   * Compute the end of the day for the current `lockDate`
   *
   * @public
   * @property lockDateEnd
   * @type {Moment}
   */
		lockDateEnd: Ember.computed('lockDate', function () {
			var lockDate = _utils.Time.date(this.get('lockDate'));
			_utils.Assert.isMoment(lockDate);
			return lockDate.endOf('day');
		}),

		/**
   * Compute the timestamp for end of the day for the current `lockDate`
   *
   * @public
   * @property lockDateEndTimeStamp
   * @type {Moment}
   */
		lockDateEndTimeStamp: Ember.computed('lockDateEnd', function () {
			_utils.Assert.isMoment(this.get('lockDateEnd'));
			return this.get('lockDateEnd').unix();
		}),

		/**
   * Compute an array of memberId's based on the `memberEntries` attribute
   *
   * @public
   * @property memberIds
   * @type {string[]}
   */
		memberIds: Ember.computed.mapBy('memberEntries', 'id'),

		/**
   * Confirmation message to be shown when deleting lock dates
   *
   * @public
   * @property confirmDeleteMessage
   * @type {string}
   */
		confirmDeleteMessage: Ember.computed('memberEntries.[]', function () {
			var numMembers = this.get('memberEntries.length');

			return (0, _utils.loc)("Are you sure you want to delete the lock date for %@ employees?", numMembers);
		}),

		// /**
		//  * Warning message to be shown regarding members with lock dates newer than the selected lock date
		//  *
		//  * @public
		//  * @property membersWithNewerLocksWarning
		//  * @type {string}
		//  */
		// membersWithNewerLocksWarning: computed('membersWithNewerLocks.[]', function()
		// {
		// 	return loc(`Selected employee has a lock date more recent than the selected date`);
		// }),

		/**
   * Warning message to be shown regarding members with open entries older than the selected lock date
   *
   * @public
   * @property membersWithOlderOpenEntries
   * @type {string}
   */
		membersWithOlderOpenEntriesWarning: Ember.computed('membersWithOlderOpenEntries.[]', function () {
			var lockDate = _utils.Time.date(this.get('lockDate'));

			_utils.Assert.isMoment(lockDate);

			var formattedDate = _utils.Time.timeFormat(lockDate.unix(), "MMMM DD");
			return (0, _utils.loc)("Open time entries on or before %@ are preventing the lock date from being moved for", [formattedDate]);
		}),

		// /**
		//  * Filtered collection of members who have a lock date newer than the selected lock date
		//  *
		//  * @public
		//  * @property membersWithNewerLocks
		//  * @type {Models.Member[]}
		//  */
		// membersWithNewerLocks: computed('memberEntries.@each.memberLock', 'lockDateEnd', function()
		// {
		// 	const newLockDate = this.get('lockDateEnd');
		// 	Assert.isMoment(newLockDate);
		//
		// 	return this.get('memberEntries').filter((member) =>
		// 	{
		// 		const memberLock = member.get('memberLock');
		//
		// 		if (isNone(memberLock))
		// 		{
		// 			return false;
		// 		}
		// 		else
		// 		{
		// 			const currentTimestamp = memberLock.get('effectiveDate');
		// 			const currentLockDate = Time.date(currentTimestamp);
		// 			Assert.isMoment(currentLockDate);
		//
		// 			return currentLockDate.isAfter(newLockDate);
		// 		}
		//
		// 	});
		// }),


		// /**
		//  * "And More" list of membersWithNewerLocks
		//  *
		//  * @public
		//  * @property membersWithNewerLocksList
		//  * @type {Models.Member[]}
		//  */
		// membersWithNewerLocksList: computed('membersWithNewerLocks', function()
		// {
		// 	const items = this.get('membersWithNewerLocks').map((member) => {
		// 		const formattedDate = Time.date(member.get('memberLock.effectiveDate')).format('L');
		//
		// 		return `${formattedDate} - ${member.get('fullName')}`;
		// 	});
		//
		// 	return this.truncateArrayToPlusMore(items);
		// }),


		/**
   * Filtered collection of members with time entries opened before the selected lock date
   *
   * @public
   * @property getMembersWithConflictingOpenEntries
   * @type {Models.Member[]}
   */
		membersWithOlderOpenEntries: Ember.computed('memberEntries.@each.openEntry', 'lockDateEnd', function () {
			var lockDateEndUnix = this.get('lockDateEnd').unix();

			return this.get('memberEntries').filter(function (member) {
				var openEntry = member.get('openEntry');

				if (!Ember.isNone(openEntry) && openEntry.get('startTime') <= lockDateEndUnix) {
					return true;
				} else {
					return false;
				}
			});
		}),

		/**
   * "And More" list of membersWithOlderOpenEntries
   *
   * @public
   * @property membersWithOlderOpenEntriesList
   * @type {Models.Member[]}
   */
		membersWithOlderOpenEntriesList: Ember.computed('membersWithOlderOpenEntries.[]', function () {
			var items = this.get('membersWithOlderOpenEntries').mapBy('fullName');

			return this.truncateArrayToPlusMore(items, 3, 2);
		}),

		/**
   * Collection of members who have fatal errors
   *
   * @public
   * @property membersNotUpdateable
   * @type {Models.Member[]}
   */
		membersNotUpdateable: Ember.computed('membersWithOlderOpenEntries', 'memberEntries', function () {
			var membersWithOlderOpenEntries = this.get('membersWithOlderOpenEntries');
			var invalidMemberIds = [];

			if (!Ember.isNone(membersWithOlderOpenEntries) && membersWithOlderOpenEntries.get('length')) {
				invalidMemberIds = invalidMemberIds.concat(membersWithOlderOpenEntries.mapBy('id'));
			}

			// filter to members who can't be updated
			return this.get('memberEntries').filter(function (member) {
				return invalidMemberIds.includes(member.get('id'));
			});
		}),

		/**
   * Collection of members who are valid to be updated
   *
   * @public
   * @property membersUpdateable
   * @type {Models.Member[]}
   */
		membersUpdateable: Ember.computed('membersNotUpdateable', 'memberEntries', function () {
			var membersNotUpdateable = this.get('membersNotUpdateable');
			var invalidMemberIds = membersNotUpdateable.mapBy('id');

			// remove members who can't be updated
			return this.get('memberEntries').reject(function (member) {
				return invalidMemberIds.includes(member.get('id'));
			});
		}),

		/**
   * Private Methods
   */

		/**
   * Update memberLocks with the selected `effectiveDate`
   *
   * @private
   * @method updateMemberLocks
   * @async
   * @chainable
   * @return {Models.MemberLock[]}
   */
		updateMemberLocks: function updateMemberLocks() {
			var effectiveDate = this.get('lockDateEnd').unix();
			var members = this.get('membersUpdateable');

			this.get('busySave').showSavingMessage('Saving');

			this.analyticsEvent('LOCK_DATE_SUBMIT', members.mapBy('id'));

			return this.saveMemberLocks(effectiveDate, members);
		},


		/**
   * Update memberLocks with `null` values for the `effectiveDate`
   *
   * @private
   * @method deleteMemberLocks
   * @async
   * @chainable
   * @return {Models.MemberLock[]}
   */
		deleteMemberLocks: function deleteMemberLocks() {
			var deletedValue = '_-NULL-_';

			this.get('busySave').showSavingMessage('Deleting');

			this.analyticsEvent('LOCK_DATE_CANCEL', this.get('memberEntries').mapBy('id'));

			return this.saveMemberLocks(deletedValue, this.get('memberEntries')).then(function () {
				return (0, _utils.loc)('Deleted');
			});
		},


		/**
   * Calls API on `member-lock`
   * Submit updated memberLocks to the server
   *
   * @private
   * @method deleteMemberLocks
   * @param effectiveDate {number}
   * @param members {Models.Member[]}
   * @async
   * @chainable
   * @return {Models.MemberLock[]}
   */
		saveMemberLocks: function saveMemberLocks(effectiveDate, members) {
			var promises = members.map(function (member) {
				return member.updateMemberLock(effectiveDate);
			});

			return Ember.RSVP.all(promises).catch(function (err) {
				_logger.default.error('EditLockDate', 'saveMemberLocks', { effectiveDate: effectiveDate, members: members, err: err });
				return Ember.RSVP.reject(err);
			});
		},


		/**
   * Determine the appropriate default date to for the date picker.
   * If there's more than one member, use yesterday, otherwise use the current lock date.
   *
   * @private
   * @method getDefaultLockDate
   * @return {Moment}
   */
		getDefaultLockDate: function getDefaultLockDate() {
			return _utils.Time.date().add(-1, 'days').endOf('day').unix();
		},


		/**
   * Is `lockDateEnd` newer than yesterday?
   *
   * @private
   * @method isTooNew
   * @return {boolean}
   */
		isTooNew: function isTooNew() {
			var yesterday = _utils.Time.date().add('-1', 'days').endOf('day');
			return this.get('lockDateEnd').isAfter(yesterday);
		},


		/**
   * Return a trancated array. Last element shows number how many were suppressed.
   *
   * @public
   * @method truncateArrayToPlusMore
   * @param items {string[]}
   * @param [limit=3] {number} Only truncate if there are more than this many items
   * @param [showNum=2] {number} How many items to show if truncation is trigged
   */
		truncateArrayToPlusMore: function truncateArrayToPlusMore(items) {
			var limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
			var showNum = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;

			_utils.Assert.isArray(items);
			_utils.Assert.isInteger(limit);
			_utils.Assert.isInteger(showNum);

			_utils.Assert.test('limit must be gt 0', limit > 0);
			_utils.Assert.test('showNum must be gt 0', showNum > 0);

			var numItems = items.length;

			if (items.length > limit && items.length > showNum) {
				var numMoreItems = numItems - showNum;

				items = items.slice(0, showNum).concat((0, _utils.loc)("+ %@ more", [numMoreItems]));
			}

			return items;
		},


		/**
   * @private
   * @method getConflictWarnings
   * @return {string[]}
   */
		getConflictWarnings: function getConflictWarnings() {
			// const membersWithNewerLocks = this.get('membersWithNewerLocks');
			var membersWithOlderOpenEntries = this.get('membersWithOlderOpenEntries');
			var warningMessages = [];

			// if (!isNone(membersWithNewerLocks) && membersWithNewerLocks.get('length') > 0)
			// {
			// 	const items = this.get('membersWithNewerLocksList');
			// 	const message = this.get('membersWithNewerLocksWarning');
			//
			// 	warningMessages.push(EmberObject.create({ items, message }));
			// }

			if (!Ember.isNone(membersWithOlderOpenEntries) && membersWithOlderOpenEntries.get('length') > 0) {
				var items = this.get('membersWithOlderOpenEntriesList');
				var message = this.get('membersWithOlderOpenEntriesWarning');

				warningMessages.push(Ember.Object.create({ items: items, message: message }));
			}

			return warningMessages;
		},


		/**
   * @private
   * @method analyticsEvent
   * @async
   */
		analyticsEvent: function analyticsEvent(type, targetMemberIds) {
			var typeMap = {
				'LOCK_DATE_SUBMIT': 80,
				'LOCK_DATE_CANCEL': 81
			};
			var navSource = window.location.pathname;

			_utils.Assert.isString(type);
			_utils.Assert.isArray(targetMemberIds);
			_utils.Assert.isInteger(typeMap[type]);

			return this.get('analytics').addEvent(typeMap[type], { navSource: navSource, targetMemberIds: targetMemberIds });
		},


		/**
   * Dialog Controls
   */

		/**
   * @private
   * @method openDeleteConfirmOverlay
   */
		openDeleteConfirmOverlay: function openDeleteConfirmOverlay() {
			return this.set('showDeleteConfirmOverlay', true);
		},


		/**
   * @private
   * @method closeDeleteConfirmOverlay
   */
		closeDeleteConfirmOverlay: function closeDeleteConfirmOverlay() {
			return this.set('showDeleteConfirmOverlay', false);
		},


		/**
   * @private
   * @method openSaveConfirmOverlay
   */
		openSaveConfirmOverlay: function openSaveConfirmOverlay() {
			return this.set('showSaveConfirmOverlay', true);
		},


		/**
   * @private
   * @method closeSaveConfirmOverlay
   */
		closeSaveConfirmOverlay: function closeSaveConfirmOverlay() {
			return this.set('showSaveConfirmOverlay', false);
		},


		/**
   * Action Events
   */

		actions: {

			/**
    * @event onClose
    */
			closeAction: function closeAction() {
				return this.sendAction('onClose');
			},


			/**
    * @event onDelete
    * @param busySave {Components.BusySave}
    */
			deleteAction: function deleteAction(busySave) {
				this.set('busySave', busySave);
				return this.deleteMemberLocks();
			},


			/**
    * @event onSave
    * @param busySave {Components.BusySave}
    */
			saveAction: function saveAction(busySave) {
				this.set('busySave', busySave);

				// check for fatal errors
				if (this.isTooNew()) {
					return Ember.RSVP.reject((0, _utils.loc)("Lock date must be earlier than today"));
				}

				var warningMessages = this.getConflictWarnings();
				if (warningMessages.length) {
					this.set('warningMessages', warningMessages);
					return this.openSaveConfirmOverlay();
				} else {
					return this.updateMemberLocks();
				}
			},


			/**
    * @event saveConfirmAccepted
    */
			saveConfirmAccepted: function saveConfirmAccepted() {
				this.closeSaveConfirmOverlay();
				return this.updateMemberLocks(this.get('lockDateEnd'));
			},


			/**
    * @event saveConfirmDeclined
    */
			saveConfirmDeclined: function saveConfirmDeclined() {
				this.closeSaveConfirmOverlay();
			}
		}
	});
});