define('busy-app/components/time/time-off/edit', ['exports', 'moment', '@busy-web/utils'], function (exports, _moment, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-time-off-edit'],

		analytics: Ember.inject.service('analytics'),

		hasBackButton: false,

		dateStamp: null,
		oldStartDate: null,
		isPaid: false,
		type: null,

		minDate: null,
		maxDate: null,
		initialDateStamp: null,

		model: null,

		totalTime: 0,

		projectIdChange: null,
		costCodeIdChange: null,

		format: _utils.Locale.format("ddd, MMM DD", _moment.default.locale()),

		init: function init() {
			this._super();

			this.setDates();
			this.setTypesMenu();

			this.set('isPaid', this.get('model.paid'));
			this.set('seconds', this.get('model.seconds'));
			this.set('type', this.get('model.type'));
			this.set('description', this.get('model.description'));

			// if the locks feature is enabled
			if (this.get('features.locks')) {
				// add an observer to the initializeDate method. So it will get called again
				// if the member has not loaded.
				Ember.addObserver(this, 'model.member.id', this, 'initializeDate');

				// call initializeDate
				this.initializeDate();
			} else {
				// for old support set the 90 day limit.
				this.set('minDate', _utils.Time.date().subtract(89, 'days').startOf('day').unix());
				this.set('maxDate', _utils.Time.date().add(90, 'days').endOf('day').unix());
			}
		},

		/**
   * initialize function for setting the lock date.
   *
   * This is a function by itself for support of the features.locks.
   * It can't be an observer unless locks are enabled so in init
   * this gets added as an observer if locks is enabled.
   *
   * This needs to be in an if statement to prevent it from throwing an
   * error in cases where the member is not set or the time off model
   * is not set.
   *
   * @private
   * @method initializeDate
   */
		initializeDate: function initializeDate() {
			var member = this.get('model.member');

			// make sure the member model is loaded.
			if (!Ember.isNone(member)) {
				// set the initialDateStamp to the dateStamp of the time off entry
				this.set('initialDateStamp', this.get('model.dateStamp'));

				// call the get memberLock to load it on the member model.
				this.get('model.member.memberLock');
			}
		},
		setTypesMenu: function setTypesMenu() {
			this.set('typesList', [Ember.Object.create({ key: 'holiday', value: (0, _utils.loc)('Holiday') }), Ember.Object.create({ key: 'personal', value: (0, _utils.loc)('Personal') }), Ember.Object.create({ key: 'sick', value: (0, _utils.loc)('Sick') }), Ember.Object.create({ key: 'vacation', value: (0, _utils.loc)('Vacation') }), Ember.Object.create({ key: 'other', value: (0, _utils.loc)('Other') })]);
		},


		setDates: Ember.observer('model.dateStamp', function () {
			var startTime = this.get('model.dateStamp');
			if (Ember.isNone(startTime)) {
				startTime = _utils.Time.timestamp();
			}
			this.set('oldStartDate', startTime);
			this.set('dateStamp', startTime);
		}),

		setModel: Ember.observer('dateStamp', 'model.member.memberLock.effectiveDate', function () {
			if (!this.get('isDestroying') && !this.get('isDestroyed') && !Ember.isNone(this.get('model'))) {
				if (!Ember.isNone(this.get('dateStamp'))) {
					this.set('model.startTime', this.get('dateStamp'));
				}

				if (this.get('isSavedDateLocked') || this.get('isSelectedDateLocked')) {
					this.get('analytics').addEvent(84);
				}
			}
		}),

		submitTimeOffEntry: function submitTimeOffEntry(forceSave) {
			var dateStamp = _utils.Time.date(this.get('dateStamp')).startOf('day').unix();

			if (!this.get('isPaid')) {
				this.set('seconds', 0);
			}

			if (this.get('model.dateStamp') !== dateStamp) {
				this.set('model.dateStamp', dateStamp);
			}

			if (this.get('model.paid') !== this.get('isPaid')) {
				this.set('model.paid', this.get('isPaid'));
			}

			if (this.get('model.seconds') !== this.get('seconds')) {
				this.set('model.seconds', this.get('seconds'));
			}

			if (this.get('model.type') !== this.get('type')) {
				this.set('model.type', this.get('type'));
			}

			if (this.get('model.description') !== this.get('description')) {
				this.set('model.description', this.get('description'));
			}

			if (this.get('model.hasDirtyAttributes')) {
				if (this.get('isPaid') && this.get('seconds') <= 0) {
					return Ember.RSVP.reject('Hours must be more than 0.');
				} else {
					return this.get('model').save(forceSave);
				}
			} else {
				return Ember.RSVP.resolve();
			}
		},


		/**
   * Checks if any validation errors have been set
   *
   * @property isSavingDisabled
   * @type {Boolean}
   * @static
   *
   */
		isSavingDisabled: Ember.computed.or('isTooOldToEdit', 'isSavedDateLocked', 'isSelectedDateLocked'),

		/**
   * Logic flip of `isSavingDisabled`
   *
   * @property isSavingEnabled
   * @type {Boolean}
   * @static
   *
   */
		isSavingEnabled: Ember.computed.not('isSavingDisabled'),

		/**
   * Is the original `startTime` before the lock date
   *
   * @property isSavedDateLocked
   * @type {Boolean}
   * @static
   *
   */
		isSavedDateLocked: Ember.computed('features.locks', 'model.member.{id,memberLock.effectiveDate}', 'initialDateStamp', function () {
			if (!this.get('features.locks')) {
				return false;
			}

			var lockDate = this.get('model.member.memberLock.effectiveDate');
			return !Ember.isNone(lockDate) && this.get('initialDateStamp') <= lockDate;
		}),

		/**
   * Is the currently selected `startTime` before the lock date
   *
   * @property isSelectedDateLocked
   * @type {Boolean}
   * @static
   *
   */
		isSelectedDateLocked: Ember.computed('features.locks', 'model.member.memberLock.effectiveDate', 'dateStamp', function () {
			if (!this.get('features.locks')) {
				return false;
			}

			var lockDate = this.get('model.member.memberLock.effectiveDate');
			return !Ember.isNone(lockDate) && this.get('dateStamp') <= lockDate;
		}),

		actions: {
			typeTimeOffSelected: function typeTimeOffSelected(item) {
				if (item.get('key') === 'holiday') {
					this.set('type', 10);
				} else if (item.get('key') === 'personal') {
					this.set('type', 20);
				} else if (item.get('key') === 'sick') {
					this.set('type', 30);
				} else if (item.get('key') === 'vacation') {
					this.set('type', 40);
				} else if (item.get('key') === 'other') {
					this.set('type', 50);
				}
			},
			saveAction: function saveAction(dialog) {
				var _this = this;

				var forceSave = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

				return this.submitTimeOffEntry(forceSave).then(function (model) {
					_this.sendAction('onSaved', model);
					return model;
				});
			},
			deleteAction: function deleteAction() {
				var _this2 = this;

				var timeOffEntry = this.get('model');
				timeOffEntry.deleteRecord();
				return timeOffEntry.save().then(function () {
					_this2.sendAction('onDelete');
					return;
				});
			},
			cancelAction: function cancelAction() {
				if (!Ember.isNone(this.get('model'))) {
					this.get('model').rollbackAttributes();
				}
				this.sendAction('onCancel');
			},
			cancelEdit: function cancelEdit() {
				if (!Ember.isNone(this.get('model'))) {
					this.get('model').rollbackAttributes();
				}
				this.sendAction('onCancel');
			}
		}
	});
});