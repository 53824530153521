define('busy-app/components/busy-sliding-panel', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-sliding-panel'],

		showPanel: false,
		isHidden: true,
		showContainer: false,

		__showPanel: Ember.observer('showPanel', function () {
			var _this = this;

			if (this.get('showPanel')) {
				this.set('showContainer', true);
				Ember.run.later(function () {
					return _this.set('isHidden', false);
				}, 100);
			} else {
				this.set('isHidden', true);
				Ember.run.later(function () {
					return _this.set('showContainer', false);
				}, 300);
			}
		}),

		actions: {
			closeAction: function closeAction() {
				this.sendAction('onClose');
			}
		}
	});
});