define('busy-app/utils/db-migration', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		name: 'busytime',
		version: 7,

		tables: [{
			name: 'member',
			keyPath: 'id',
			indexes: [{ name: 'first_name', keyPath: '', objectParams: { unique: false } }, { name: 'last_name', keyPath: '', objectParams: { unique: false } }, { name: 'updated_on', keyPath: '', objectParams: { unique: false } }]
		}, {
			name: 'wage-history',
			keyPath: 'id',
			indexes: [{ name: 'updated_on', keyPath: '', objectParams: { unique: false } }]
		}]
	};
});