define('busy-app/routes/report/time-entries/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils'], function (exports, _authenticatedRouteMixin, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		payPeriods: Ember.inject.service('pay-period'),

		queryParams: {
			start: {
				replace: true
			},
			end: {
				replace: true
			}
		},

		model: function model(params, transition) {
			var start = params.start;
			var end = params.end;

			var pController = this.controllerFor('report/time-entries');
			var project = transition.queryParams.project;
			var sort = transition.queryParams.sort_order || 'desc';

			if (start === 0) {
				var currentPeriod = this.get('payPeriods').getPayPeriod(_utils.Time.timestamp());
				var previousPeriod = this.get('payPeriods').getPayPeriod(currentPeriod.get('startDate').subtract(1, 'days').unix());

				start = previousPeriod.get('start');
				end = previousPeriod.get('end');
			}

			pController.set('start', start);
			pController.set('end', end);

			return this.store.manager('time-entry-report', start, end, null, project, null, null, false, sort);
		}
	});
});