define('busy-app/components/time/break-entry/add-edit-input', ['exports', '@busy-web/utils', 'busy-app/utils/round-time'], function (exports, _utils, _roundTime) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-break-entry-add-edit-input'],

		model: null,
		editModel: null,
		auth: Ember.inject.service('auth'),

		showList: false,
		showEdit: false,

		hasBreaks: Ember.computed('model.timeEntryBreaks.[]', function () {
			return this.get('model.timeEntryBreaks.length') > 0;
		}),

		addNewBreak: function addNewBreak() {
			var startTime = _utils.Time.date(this.get('model.startTime')).startOf('minute').unix();
			var endTime = this.get('model.endTime');
			if (Ember.isNone(endTime)) {
				endTime = _utils.Time.nistTimestamp();
			}

			var duration = this.get('auth.organization.breakDurationSeconds');
			var timeRoundingInterval = 1;
			if (this.get('auth.organization.timeRounding') && Ember.isNone(this.get('auth.organization.breakDurationSeconds'))) {
				timeRoundingInterval = this.get('auth.organization.timeRoundingInterval');
				duration = timeRoundingInterval;
			}

			// convert rounding to minutes
			timeRoundingInterval = parseInt(timeRoundingInterval / 60, 10);

			if (endTime - startTime < duration) {
				duration = endTime - startTime - 1;
			}

			// set the break to the begining of the
			// time entry and round up to avoid rounding to
			// less than the start time of the entry
			var breakStart = (0, _roundTime.roundTime)(startTime, timeRoundingInterval, 'up');
			var breakEnd = (0, _roundTime.roundTime)(breakStart + duration, timeRoundingInterval, 'up');

			// try to find a breaktime that does not overlap other breaktimes
			if (this.get('model.timeEntryBreaks')) {
				var breaks = this.get('model.timeEntryBreaks').sortBy('startTime');
				breaks.forEach(function (item) {
					if (item.get('startTime') <= breakStart && item.get('endTime') >= breakStart || item.get('startTime') <= breakEnd && item.get('endTime') >= breakEnd) {
						// set the start time to the endtime of the last break and round the time up to avoid
						// conflicting with the last entry
						breakStart = (0, _roundTime.roundTime)(item.get('endTime'), timeRoundingInterval, 'up');
						breakEnd = (0, _roundTime.roundTime)(breakStart + duration, timeRoundingInterval, 'up');
					}
				});
			}

			// if the break is outside the time entry then
			// set it to the end of the time entry and
			// let it overlap another break
			if (breakStart >= endTime || breakEnd >= endTime) {
				// set the end time to the time enrty end time and round down to avoid goin over the endtime
				breakEnd = (0, _roundTime.roundTime)(endTime, timeRoundingInterval, 'down');
				breakStart = (0, _roundTime.roundTime)(breakEnd - duration, timeRoundingInterval, 'down');
			}

			if (breakStart < startTime) {
				breakStart = (0, _roundTime.roundTime)(startTime, timeRoundingInterval, 'up');
			}

			this.set('editModel', this.get('model').createBreak(breakStart, breakEnd));
		},


		actions: {
			clickAction: function clickAction() {
				if (this.get('hasBreaks')) {
					// show list
					this.set('showList', true);
				} else {
					// show add new
					this.addNewBreak();
					this.set('showEdit', true);
				}
			},
			closeListAction: function closeListAction() {
				this.set('showList', false);
			},
			createNewAction: function createNewAction() {
				// show add new
				this.addNewBreak();
				this.set('showEdit', true);
			},
			editBreakAction: function editBreakAction(item) {
				item.set('_isEdit', true);

				// open edit break dialog
				this.set('editModel', item);
				this.set('showList', false);
				this.set('showEdit', true);
			},
			closeEditAction: function closeEditAction() {
				var editModel = this.get('editModel');
				if (!Ember.isNone(this.get('model')) && !Ember.isNone(editModel) && !editModel.get('isDestroyed')) {
					if (!editModel.get('_isEdit')) {
						this.get('model').calculateTime();

						var _break = this.get('model.timeEntryBreaks').findBy('id', editModel.id);
						if (!Ember.isNone(_break)) {
							_break.set('_isValid', editModel.get('_isValid'));
						}

						if (!editModel.get('_isValid')) {
							this.get('model').removeBreak(editModel);
						}
					}
					editModel.set('_isEdit', false);
				}
				this.set('editModel', null);
				this.set('showEdit', false);

				return Ember.RSVP.resolve();
			},
			editSavedAction: function editSavedAction() {//model, dialog) {
				//this.validateBreak(timeEntryBreak, dialog);
			},
			editDeleteAction: function editDeleteAction() {}
		}
	});
});