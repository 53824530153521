define('busy-app/utils/models/managers/time-entry-report', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/time-entry-pto-day'], function (exports, _utils, _timeEntryPtoDay) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _timeEntryPtoDay.default.extend({
		features: Ember.inject.service('features'),

		hasOpenTime: false,

		addCollection: function addCollection(model, days, projects) {
			var collection = this.collectionFor().push(model, days, projects, this.get('start'), this.get('end'));
			this.get('content').pushObject(collection);
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			this.set('_rangeItems', []);

			// build the time entry list
			var sortedEntries = data.timeEntries.sortBy('startTime', 'endTime');
			sortedEntries.forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));
				_this.checkConflict(model, sortedEntries);
				_this.splitEntries(model);
			});

			// add timeoff to the time entry list
			data.timeOffEntries.sortBy('dateStamp').forEach(function (model) {
				model.set('member', data.members.findBy('id', model.get('memberId')));

				var startTime = _utils.Time.date(model.get('dateStamp')).startOf('day').unix();
				var endTime = _utils.Time.date(model.get('dateStamp')).endOf('day').unix();

				_this.addRangeCollection(model, startTime, endTime, false);
			});

			this.set('hasOpenTime', data.openTimeEntries.get('length') > 0);

			// sort time entries list by member
			data.members.sortBy('fullName').forEach(function (member) {
				var projectData = [];
				var days = [];

				// get member time entries sorted by start and end time and reverse the order for desc. list
				var collections = _this.get('_rangeItems').filterBy('model.memberId', member.id).sortBy('model.startTime', 'model.endTime');
				if (collections && collections[0]) {
					if (_this.get('sortOrder') === 'desc') {
						collections = collections.reverse();
					}

					// build the member time entry list
					collections.forEach(function (model) {
						var projectId = model.get('model.projectId');
						var project = model.get('model.project');
						if (Ember.isNone(projectId)) {
							projectId = '_-NULL-_';
							project = Ember.Object.create({
								id: '_-NULL-_',
								title: (0, _utils.loc)('Unassigned'),
								fullTitle: (0, _utils.loc)('Unassigned')
							});
						}

						var prevProj = projectData.findBy('id', projectId);
						if (!Ember.isNone(prevProj)) {
							prevProj.get('model').pushObject(model);
						} else {
							projectData.pushObject(_this.collectionFor('time-entry-project').push([model], projectId, project));
						}

						var day = model.get('dayString');
						var date = model.get('start');

						var prev = days.findBy('day', day);
						if (!Ember.isNone(prev)) {
							prev.get('model').pushObject(model);
						} else {
							days.pushObject(_this.collectionFor('time-entry-pto-day').push(model, date, day));
						}
					});
				}

				_this.addCollection(member, days, projectData.sortBy('project.fullTitle'));
			});
		},


		regularSeconds: Ember.computed('model.@each.projectDataRegularSeconds', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('projectDataRegularSeconds') || 0;
			});
			return total;
		}),

		regularTimeString: Ember.computed('regularSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('regularSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		overtimeSeconds: Ember.computed('model.@each.projectDataOvertimeSeconds', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('projectDataOvertimeSeconds') || 0;
			});
			return total;
		}),

		overtimeTimeString: Ember.computed('overtimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('overtimeSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		doubletimeSeconds: Ember.computed('model.@each.projectDataDoubletimeSeconds', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('projectDataDoubletimeSeconds') || 0;
			});
			return total;
		}),

		doubletimeTimeString: Ember.computed('doubletimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('doubletimeSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		totalPTOSeconds: Ember.computed('model.@each.totalPTOSeconds', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('totalPTOSeconds');
			});
			return total;
		}),

		regularHoursWorked: Ember.computed('model.@each.regularHoursWorked', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('regularHoursWorked');
			});
			return total;
		}),

		totalSeconds: Ember.computed('model.@each.projectDataTotalSeconds', function () {
			var total = 0;
			this.get('model').forEach(function (item) {
				total += item.get('projectDataTotalSeconds') || 0;
			});
			return total;
		}),

		totalTimeString: Ember.computed('totalSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('totalSeconds'), true);
			return time.hours + ':' + time.minutes;
		})
	});
});