define('busy-app/utils/type-cast', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = typeCast;
	/**
  * @module Utils
  *
  */

	/**
  * type cast a string to the proper value
  *
  * @public
  * @method typeCast
  * @params value {string}
  * @return {mixed}
  */
	function typeCast(value) {
		if (typeof value === 'string') {
			if (/^([Tt]rue|[Ff]alse)$/.test(value)) {
				// boolean
				value = value === true || value === 'True' ? true : false;
			} else if (/^[0-9]+\.[0-9]*$/.test(value)) {
				// float
				return parseFloat(value);
			} else if (/^[0-9]+$/.test(value)) {
				// int
				return parseInt(value, 10);
			} else if (/^ ?{/.test(value)) {
				// remove whitespace
				value = value.replace(/ /g, '').replace(/([^:]*)(:{|:[^,^}]*[,}])/g, '"$1"$2').replace(/"{/g, '{"').replace(/""/g, '"').replace(/('"|"')/g, '"');
				return JSON.parse(value);
			} else {
				return value;
			}
		}
		return value;
	}
});