define('busy-app/components/dialogs/open-entry-location', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    subscription: Ember.inject.service(),
    openEntry: null,
    member: null,
    showEndTime: false,
    boundItems: null,

    init: function init() {
      this._super();
      this.set('markers', []);
      this.setBreaks();
      this.setStartAndEndMarkers();
      this.set('boundItems', []);
    },
    setBreaks: function setBreaks() {
      var _this = this;

      this.get('store').query('time-entry-break', { time_entry_id: this.get('openEntry.id') }).then(function (results) {
        _this.set('breaks', results);
      });
    },
    setStartAndEndMarkers: function setStartAndEndMarkers() {
      var _this2 = this;

      var markers = this.get('markers');
      var viewEntryLocation = this.get('openEntry');

      this.get('store').query('client-time-entry-log', { original_time_entry_id: viewEntryLocation.get('id'), action_type: 60 }).then(function (results) {
        var edits = results.filter(function (item) {
          if (item.get('actionType') === 60) {
            return item;
          }
        });

        var mostRecentEdit = edits.sortBy('createdOn').reverse()[0];

        if (viewEntryLocation.get('startLocationId')) {
          _this2.get('store').find('member-location', viewEntryLocation.get('startLocationId')).then(function (item) {
            var startTimeRangeStart = viewEntryLocation.get('startTime') - 180;
            var startTimeRangeEnd = viewEntryLocation.get('startTime') + 180;

            var markerImage = {
              url: startTimeRangeStart < item.get('timeLocal') && startTimeRangeEnd > item.get('timeLocal') ? '/images/icons/map_icons_start.png' : '/images/icons/map_icons_dark_gray.png',
              size: new window.google.maps.Size(35, 35),
              anchor: new window.google.maps.Point(15, 15),
              labelOrigin: new window.google.maps.Point(15, 15)
            };

            var markerType = startTimeRangeStart < item.get('timeLocal') && startTimeRangeEnd > item.get('timeLocal') ? 'Start' : 'Location Update';

            item.set('markerImage', markerImage);
            item.set('markerType', markerType);
            _this2.setProperties({
              lat: item.get('latitude'),
              lng: item.get('longitude'),
              zoom: 10
            });
            if (startTimeRangeStart < item.get('timeLocal') && startTimeRangeEnd > item.get('timeLocal')) {
              item.set('markerImage', {
                url: '/images/icons/map_icons_dark_gray.png',
                size: new window.google.maps.Size(35, 35),
                anchor: new window.google.maps.Point(15, 15),
                labelOrigin: new window.google.maps.Point(15, 15)
              });
              item.set('markerType', 'Location Update');
              markers.push(item);
            } else {
              markers.push(item);
            }
            // let boundItem = new window.google.maps.LatLng();
            var boundItem = { lat: item.get('latitude'), lng: item.get('longitude') };
            _this2.get('boundItems').push(boundItem);
          });
        }

        if (viewEntryLocation.get('endLocationId')) {
          _this2.get('store').find('member-location', viewEntryLocation.get('endLocationId')).then(function (item) {
            var endTimeRangeStart = viewEntryLocation.get('endTime') - 180;
            var endTimeRangeEnd = viewEntryLocation.get('endTime') + 180;

            var markerImage = {
              url: endTimeRangeStart < item.get('timeLocal') && endTimeRangeEnd > item.get('timeLocal') ? '/images/icons/map_icons_end.png' : '/images/icons/map_icons_dark_gray.png',
              size: new window.google.maps.Size(35, 35),
              anchor: new window.google.maps.Point(15, 15),
              labelOrigin: new window.google.maps.Point(15, 15)
            };

            var markerType = endTimeRangeStart < item.get('timeLocal') && endTimeRangeEnd > item.get('timeLocal') ? 'End' : 'Location Update';

            item.set('markerImage', markerImage);
            item.set('markerType', markerType);
            if (Ember.isPresent(mostRecentEdit)) {
              if (endTimeRangeStart < item.get('timeLocal') && endTimeRangeEnd > item.get('timeLocal')) {
                item.set('markerImage.url', '/images/icons/map_icons_dark_gray.png');
                item.set('markerType', 'Location Update');
                markers.push(item);
              } else {
                markers.push(item);
              }
            } else {
              markers.push(item);
            }
            var boundItem = { lat: item.get('latitude'), lng: item.get('longitude') };
            _this2.get('boundItems').push(boundItem);
          });
        }
      }).finally(function () {
        _this2.setMarkers();
      });
    },


    /**
     * Sets the marker colors based on location's time status.
     * @private
     * @method setMarkers
     */
    setMarkers: function setMarkers() {
      var _this3 = this;

      this.set('mapLoading', true);
      var markers = this.get('markers');
      var viewEntryLocation = this.get('openEntry');
      var start = viewEntryLocation.get('startTime');
      var showEndTime = this.get('showEndTime');
      var end = _utils.Time.now().unix();
      if (showEndTime) {
        end = viewEntryLocation.get('endTime');
      }

      if (!Ember.isNone(viewEntryLocation)) {
        var query = {
          _lte: { time_local: end },
          _gte: { time_local: start },
          member_id: viewEntryLocation.get('memberId'),
          deleted_on: '_-NULL-_',
          archived_on: '_-NULL-_'
        };

        this.get('store').query('member-location', query).then(function (results) {
          var locationItems = results.filter(function (loc) {
            if (Ember.isPresent(viewEntryLocation.get('endLocationId'))) {
              if (loc.get('id') !== viewEntryLocation.get('startLocationId') && loc.get('id') !== viewEntryLocation.get('endLocationId')) {
                return loc;
              }
            } else {
              if (loc.get('id') !== viewEntryLocation.get('startLocationId')) {
                return loc;
              }
            }
          });

          locationItems.forEach(function (item) {
            var iconUrl = '/images/icons/map_icons_dark_gray.png';
            var markerType = 'Location';
            var breaksLength = _this3.get('breaks.length');

            if (breaksLength > 0) {
              _this3.get('breaks').forEach(function (entryBreak) {
                if (item.get('id') === entryBreak.get('startLocationId')) {
                  iconUrl = '/images/icons/map_icons_start-break.png';
                  markerType = 'Break Start';
                } else if (item.get('id') === entryBreak.get('endLocationId')) {
                  iconUrl = '/images/icons/map_icons_end-break.png';
                  markerType = 'Break End';
                } else if (item.get('timeLocal') > entryBreak.get('startDeviceTime') && item.get('timeLocal') < entryBreak.get('deviceEndTime')) {
                  item.set('doNotTrack', true);
                }
              });
            }

            var markerImage = {
              url: iconUrl,
              size: new window.google.maps.Size(35, 35),
              anchor: new window.google.maps.Point(15, 15),
              labelOrigin: new window.google.maps.Point(15, 15)
            };

            item.set('markerImage', markerImage);
            item.set('markerType', markerType);

            if (item.get('markerType') === 'Location') {
              item.set('isLocationChange', true);
            } else {
              item.set('isLocationChange', false);
            }

            if (!item.get('doNotTrack') && _this3.get('subscription.isProAccount')) {
              markers.pushObject(item);
            }
            if (!_this3.get('subscription.isProAccount') && (item.get('markerType') === 'Break Start' || item.get('markerType') === 'Break End')) {
              markers.pushObject(item);
            }
            var boundItem = { lat: item.get('latitude'), lng: item.get('longitude') };
            _this3.get('boundItems').push(boundItem);
          });
        }).finally(function () {
          _this3.set('mapLoading', false);

          _this3.setProjectMarker();
        });
      }
    },
    setProjectMarker: function setProjectMarker() {
      var _this4 = this;

      var viewEntryLocation = this.get('openEntry');
      var project = viewEntryLocation.get('project.title');
      var projectId = viewEntryLocation.get('projectId');

      if (Ember.isPresent(projectId)) {
        this.get('store').query('project-info', { project_id: projectId }).then(function (results) {
          if (Ember.isPresent(results.content[0])) {
            var projectInfo = results.content[0]._data;
            var projectMarker = Ember.Object.create({
              project: project,
              latitude: projectInfo.latitude,
              longitude: projectInfo.longitude,
              locationRadius: projectInfo.locationRadius,
              timeLocal: _utils.Time.now().unix(),
              noTooltip: true
            });
            var markerImage = {
              url: '/images/icons/project_pin.png',
              size: new window.google.maps.Size(30, 30),
              anchor: new window.google.maps.Point(15, 15),
              labelOrigin: new window.google.maps.Point(15, 15)
            };

            projectMarker.set('markerImage', markerImage);
            _this4.set('mapMarkers', _this4.get('markers').sortBy('timeLocal').map(function (marker) {
              marker.set('distanceFromProject', _this4.getPinDistance(projectInfo.latitude, projectInfo.longitude, marker.get('latitude'), marker.get('longitude'), projectInfo.locationRadius));
              return marker;
            }));
            _this4.get('mapMarkers').pushObject(projectMarker);
            // let boundItem = new window.google.maps.LatLng();
            var boundItem = { lat: projectInfo.latitude, lng: projectInfo.longitude };
            _this4.get('boundItems').push(boundItem);
          } else {
            _this4.set('mapMarkers', _this4.get('markers').sortBy('timeLocal'));
          }
        });
      } else {
        this.set('mapMarkers', this.get('markers').sortBy('timeLocal'));
      }
    },
    getPinDistance: function getPinDistance(projectLat, projectLng, pinLat, pinLng, projectRadius) {
      if (projectLat == pinLat && projectLng == pinLng) {
        return 0;
      } else if (projectLat === null && projectLng === null) {
        return '';
      } else {
        var radProjectLat = Math.PI * projectLat / 180;
        var radPinLat = Math.PI * pinLat / 180;
        var angle = projectLng - pinLng;
        var radAngle = Math.PI * angle / 180;
        var distance = Math.sin(radProjectLat) * Math.sin(radPinLat) + Math.cos(radProjectLat) * Math.cos(radPinLat) * Math.cos(radAngle);
        if (distance > 1) {
          distance = 1;
        }
        distance = Math.acos(distance);
        distance = distance * 180 / Math.PI;
        distance = distance * 60 * 1.1515;
        if (!Ember.isNone(projectRadius)) {
          var projRad = projectRadius / 1609;
          distance = distance - projRad;

          if (distance <= 0) {
            return '';
          }
          if (distance < 0.1) {
            return '< 0.1';
          } else {
            return distance.toFixed(1);
          }
        }
        if (distance <= 0) {
          return '';
        }
        if (distance < 0.1) {
          return '< 0.1';
        } else {
          return distance.toFixed(1);
        }
      }
    },


    actions: {
      closeDialog: function closeDialog() {
        this.sendAction('onClose');
      },
      onLoad: function onLoad(_ref) {
        var map = _ref.map;

        var bounds = new window.google.maps.LatLngBounds();
        if (Ember.isPresent(this.get('boundItems'))) {
          this.get('boundItems').forEach(function (item) {
            var boundItem = new window.google.maps.LatLng(item.lat, item.lng);
            bounds.extend(boundItem);
          });
          map.fitBounds(bounds, 20);
          map.setCenter(bounds.getCenter());
        }
      },
      closeInfoWindows: function closeInfoWindows() {
        this.get('markers').forEach(function (marker) {
          marker.set('showInfoWindow', false);
        });
      },
      openInfoWindow: function openInfoWindow(item) {
        var clickedFromMap = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

        if (!item.get('noTooltip')) {
          if (!item.get('active')) {
            this.get('markers').forEach(function (marker) {
              marker.setProperties({
                showInfoWindow: false,
                active: false
              });
            });
            item.setProperties({
              showInfoWindow: true,
              active: true
            });

            if (clickedFromMap) {
              var anchor = 'marker-' + item.get('timeLocal');
              var pageHeight = Ember.$(document).height();
              if (Ember.$('#' + anchor).position().top > pageHeight || Ember.$('#' + anchor).position().top < 0) {
                Ember.$('.marker-list').animate({
                  scrollTop: Ember.$('#' + anchor).position().top
                }, 1000);
              }
            }
          }
        }
      }
    }
  });
});