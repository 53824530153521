define('busy-app/utils/container/model-manager', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	function attr(type, options) {
		return Ember.computed({
			get: function get(key) {
				return Ember.get(this, '_internalModel.' + key);
			},
			set: function set(key, value) {
				Ember.set(this, '_internalModel.' + key, value);
			}
		}).meta({ type: type, isAttribute: true, options: options });
	}

	// defines a set of default sort attrs
	var kDefaultSort = Ember.Object.extend({
		_internalModel: null,
		memberId: attr('id', { defaultValue: null, sortable: true, sortBy: ['member.firstName', 'member.lastName', 'member.createdOn'], sortChildren: ['startTime'], reverseSortChildren: true }),
		projectId: attr('id', { defaultValue: null, sortable: true, sortBy: ['project.fullTitle'], sortChildren: ['startTime'], reverseSortChildren: true }),
		costCodeId: attr('id', { defaultValue: null, sortable: true, sortBy: ['costCode.costCode'], sortChildren: ['startTime'], reverseSortChildren: true }),
		equipmentId: attr('id', { defaultValue: null, sortable: true, sortBy: ['equipment.equipmentName'], sortChildren: ['startTime'], reverseSortChildren: true })
	});

	kDefaultSort.reopenClass({
		_create: kDefaultSort.create,
		create: function create(props) {
			var _class = this._create();
			_class._internalModel = {};
			_class.setProperties(props);
			return _class;
		}
	});

	/**
  * `Util/Container/ModelManager`
  *
  *
  */
	exports.default = Ember.Object.extend({
		model: null,
		_currentAttr: null,

		sortProperties: null,
		aggregateProperties: null,
		sortMapByType: null,
		rawMeta: null,

		/**
   * Sets up initial map objects
   *
   * @private
   * @method init
   */
		init: function init() {
			this._super();
			this.sortProperties = new window.Map();
			this.aggregateProperties = new window.Map();
			this.sortMapByType = new window.Map();
			this.rawMeta = new window.Map();

			this.setup();
		},


		/**
   * Sets up all the map objects with valid data
   * from the model so it can be quickly accessed
   * when needed
   *
   * @private
   * @method setup
   */
		setup: function setup() {
			// set default sort properties
			this.parseMeta(kDefaultSort.create({}));

			// override defaults with model properties
			var model = this.get('model');
			this.parseMeta(model);
		},
		parseMeta: function parseMeta(model) {
			// loop through raw model properties to find any computed properties
			for (var key in model) {
				// properties must be an object
				if (!Ember.isEmpty(model[key]) && _typeof(model[key]) === 'object') {
					// property object must have an _meta property
					if (!Ember.isNone(model[key]._meta)) {
						// get attr meta, sortable, and aggregateType
						var meta = model[key]._meta;
						if (!Ember.isNone(Ember.get(meta, 'options'))) {
							Ember.merge(meta, Ember.get(meta, 'options'));
						}

						this.rawMeta.set(key, meta);

						var sortable = Ember.get(meta, 'sortable');
						var aggregateType = Ember.get(meta, 'aggregateType');

						// if sortable then add to sort attrs
						// else if aggregateType is set then add to aggregateProperties
						if (sortable === true) {
							var type = this._type(key, meta);
							var id = this._id(key, meta, model);

							var sortBy = this._sortBy(type, key, meta, 'sortBy');
							var reverseSortBy = this._sortBy(type, key, meta, 'reverseSortBy');

							if (Ember.isEmpty(sortBy) && Ember.isEmpty(reverseSortBy)) {
								sortBy = [type];
							}

							var _meta = {
								sortable: sortable, aggregateType: aggregateType, id: id, type: type,
								sortBy: sortBy, reverseSortBy: reverseSortBy,
								attrType: Ember.get(meta, 'type'),
								reverseSort: Ember.get(meta, 'reverseSort'),
								sortChildren: Ember.get(meta, 'sortChildren') || [],
								reverseSortChildren: Ember.get(meta, 'reverseSortChildren'),
								nested: Ember.get(meta, 'nested'),
								format: Ember.get(meta, 'format')
							};

							// add new meta object for sortProperties map
							this.sortProperties.set(key, _meta);

							// add type and id to maps for quick lookups
							this.sortMapByType.set(type, key);
						} else if (!Ember.isEmpty(aggregateType)) {
							// add to aggregateProperties map
							this.aggregateProperties.set(key, { aggregateType: aggregateType });
						}
					}
				}
			}
		},
		hasProjectData: function hasProjectData() {
			var meta = this.getMetaByAttr('projectId');
			if (!Ember.isNone(meta) && (typeof meta === 'undefined' ? 'undefined' : _typeof(meta)) === 'object' && !Ember.isEmpty(meta.nested)) {
				return true;
			}
			return false;
		},


		/**
   * keeps track of which attribute is currently
   * being referenced in the eachAttribute callback
   *
   * @private
   * @method currentAttr
   * @param attr {string} the property to set as the current attribute
   */
		currentAttr: function currentAttr(attr) {
			_utils.Assert.isString(attr);
			this.set('_currentAttr', attr);
		},


		/**
   * Quick reference method for getting the meta data
   *
   * this method can only be used when inside the eachAttribute callback
   * function
   *
   * @public
   * @method getMeta
   * @returns {object}
   */
		getMeta: function getMeta(model) {
			var attr = this.get('_currentAttr');
			if (!Ember.isEmpty(attr)) {
				var meta = this.getMetaByAttr(attr);
				return {
					sortable: meta.sortable,
					aggregateType: meta.aggregateType,
					id: this.getId(model),
					type: meta.type,
					attrType: meta.attrType,
					reverseSort: meta.reverseSort,
					sortBy: (meta.sortBy || []).slice(0),
					reverseSortBy: (meta.reverseSortBy || []).slice(0),
					sortChildren: (meta.sortChildren || []).slice(0),
					reverseSortChildren: (meta.reverseSortChildren || []).slice(0),
					nested: meta.nested,
					format: meta.format
				};
			}
			return null;
		},
		getId: function getId(model) {
			var attr = this.get('_currentAttr');
			if (!Ember.isEmpty(attr)) {
				var meta = this.rawMeta.get(attr);
				return this._id(attr, meta, model);
			}
			return null;
		},


		/**
   * Quick reference method for getting the nested id
   *
   * this method can only be used when inside the eachAttribute callback
   * function
   *
   * @public
   * @method getNestedId
   * @returns {object}
   */
		getNestedId: function getNestedId(model) {
			var meta = this.getMeta();
			if (!Ember.isNone(meta)) {
				return model.get(meta.nested);
			}
			return null;
		},


		/**
   * loop method for iterating over the sortProperties map
   *
   * @public
   * @method eachAttribute
   * @param callback {function}
   * @param target {object}
   */
		eachAttribute: function eachAttribute(callback) {
			var _this = this;

			var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

			this.sortProperties.forEach(function (meta, attr) {
				_this.currentAttr(attr);
				callback.call(target, attr, meta);
			});
		},


		/**
   * loop method for iterating over the aggregateProperties map
   *
   * @public
   * @method eachProperty
   * @param callback {function}
   * @param target {object}
   */
		eachProperty: function eachProperty(callback) {
			var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

			this.aggregateProperties.forEach(function (meta, attr) {
				callback.call(target, attr, meta);
			});
		},


		/**
   * getter for the meta object when not inside a eachAttribute loop
   *
   * this method needs the type string passed in
   *
   * @public
   * @method getMetaByType
   * @param type {string}
   * @return {objects}
   */
		getMetaByType: function getMetaByType(type) {
			return this.getMetaByAttr(this.sortMapByType.get(type));
		},


		/**
   * getter for the meta object when not inside a eachAttribute loop
   *
   * this method needs the attribute key passed in
   *
   * @public
   * @method getMetaByType
   * @param type {string}
   * @return {objects}
   */
		getMetaByAttr: function getMetaByAttr(key) {
			if (!Ember.isEmpty(key)) {
				return this.sortProperties.get(key);
			}
			return null;
		},


		/**
   * method for getting the belongsTo data to load.
   * this method returns a promise when all the data is loaded
   *
   * @public
   * @method loadData
   * @returns {EmberPromise}
   */
		loadData: function loadData() {
			var _this2 = this;

			var promise = [];
			this.sortProperties.forEach(function (meta) {
				var res = _this2.get('model.' + meta.type);
				if (!res.then) {
					res = Ember.RSVP.resolve(res);
				}
				promise.push(res);
			});

			return Ember.RSVP.all(promise).then(function () {
				return _this2;
			});
		},
		_id: function _id(attr, meta, model) {
			var attrType = Ember.get(meta, 'type');
			var id = model.get(attr);
			if (attrType === 'date') {
				var format = Ember.getWithDefault(meta, 'format', 'YYYY-MM-DD');
				if (!Ember.isNone(format)) {
					id = _utils.Time.date(id).format(format);
				}
			}
			return id;
		},
		_type: function _type(attr, meta) {
			var attrType = Ember.get(meta, 'type');

			// if sortType is `id` then assume the models
			// has a defined belongsTo relationship for
			// the id
			var type = attr;
			if (attrType === 'id') {
				type = type.replace('Id', '');
			}
			return type;
		},
		_sortBy: function _sortBy(type, attr, meta, sortType) {
			// get the sort keys for this data type
			var sortBy = Ember.get(meta, sortType);
			if (!Ember.isEmpty(sortBy)) {
				// copy the array and add sortWeight for models joins
				sortBy = sortBy.slice(0);
				sortBy.unshift(type + '.sortWeight');
			}
			return sortBy;
		}
	});
});