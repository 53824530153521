define('busy-app/controllers/ticket', ['exports', '@busy-web/utils', 'busy-app/utils/settings', 'cryptojs'], function (exports, _utils, _settings, _cryptojs) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['verify_email', 'reset_password', 'invitation', 'unsubscribe'],

		session: Ember.inject.service('session'),

		verify_email: null,
		reset_password: null,
		invitation: null,
		unsubscribe: null,

		isSuccess: false,
		errorMessage: '',

		resetPassword: '',
		resetPasswordCheck: '',

		invitePassword: '',
		invitePasswordCheck: '',
		inviteNextStep: false,

		isVerifyEmail: Ember.computed('verify_email', function () {
			if (!Ember.isNone(this.get('verify_email'))) {
				this.sendVerification(this.get('verify_email'));
				return true;
			}
			return false;
		}),

		isResetPassword: Ember.computed('reset_password', function () {
			var reset_password = !Ember.isNone(this.get('reset_password'));
			if (reset_password && this.get('session.isAuthenticated')) {
				var store = Ember.getOwner(this).lookup('session-store:application');
				store.clear();
				window.location.reload();
			}
			return reset_password;
		}),

		isInvitaion: Ember.computed('invitation', function () {
			var isInvitaion = !Ember.isNone(this.get('invitation'));
			if (isInvitaion && this.get('session.isAuthenticated')) {
				var store = Ember.getOwner(this).lookup('session-store:application');
				store.clear();
				window.location.reload();
			}
			return isInvitaion;
		}),

		isUnsubscribe: Ember.computed('unsubscribe', function () {
			if (!Ember.isNone(this.get('unsubscribe'))) {
				this.sendUnsubscribe(this.get('unsubscribe'));
				return true;
			}
			return false;
		}),

		isRedirect: Ember.computed.or('isResetPassword', 'isUnsubscribe'),

		sendVerification: function sendVerification(hash) {
			var _this = this;

			this.store.rpcRequest('account', 'verifyEmail', { hash: hash }).then(function (res) {
				if (res.success) {
					_this.set('isSuccess', true);
					Ember.run.later(function () {
						window.location = '/account-ready';
					}, 3000);
				} else if (parseInt(res.code.objectAt(0), 10) === 201) {
					_this.set('isSuccess', false);
					_this.set('errorMessage', (0, _utils.loc)('Email verification has failed.'));
					_this.set('errorDescription', (0, _utils.loc)('This url has expired or has already been used to verify this email!'));
				} else {
					_this.set('isSuccess', false);
					_this.set('errorMessage', (0, _utils.loc)('There was an error unsubscribing your account.'));
					_this.set('errorDescription', (0, _utils.loc)('Please call customer service at 1-855-287-9287.'));
				}
			});
		},
		sendUnsubscribe: function sendUnsubscribe(hash) {
			var _this2 = this;

			var errorMessage = 'There was an error unsubscribing your account.';
			var errorDescription = 'Please call customer service at 1-855-287-9287.';

			var errorAlreadyUnsubscribed = '\n\t\t\tThis url has expired or has already been used to unsubscribe this email!\n\t\t\tFor additional questions or concerns, please call customer service at 1-855-287-9287.\n\t\t';

			this.store.rpcRequest('email-report', 'unsubscribe', { hash: hash }, _settings.default.get('special_rpc_url')).then(function (res) {
				if (Ember.get(res, 'data.success')) {
					_this2.set('isSuccess', true);
				} else {
					if (Ember.get(res, 'code').includes(-32000)) {
						errorDescription = errorAlreadyUnsubscribed;
					}

					_this2.set('isSuccess', false);
					_this2.set('errorMessage', (0, _utils.loc)(errorMessage));
					_this2.set('errorDescription', (0, _utils.loc)(errorDescription));
				}
			}).catch(function () {
				_this2.set('isSuccess', false);
				_this2.set('errorMessage', (0, _utils.loc)(errorMessage));
				_this2.set('errorDescription', (0, _utils.loc)(errorDescription));
			});
		},
		validatePasswords: function validatePasswords(password, passwordMatch) {
			this.set('errorMessage', '');
			this.set('errorMatchMessage', '');
			this.set('isInvalid', false);
			this.set('isInvalidMatch', false);

			if (!Ember.isEmpty(passwordMatch) && password !== passwordMatch) {
				this.set('errorMatchMessage', (0, _utils.loc)('The passwords don\'t match!'));
				this.set('isInvalidMatch', true);
			}

			if (password.length < 8) {
				this.set('errorMessage', (0, _utils.loc)('Password must be at least 8 characters in length.'));
				this.set('isInvalid', true);
			}
			return true;
		},


		actions: {
			homeAction: function homeAction() {
				window.location = '/';
			},
			submitPassword: function submitPassword() {
				var _this3 = this;

				this.set('errorMessage', '');
				var password = this.get('resetPassword');
				var passwordMatch = this.get('resetPasswordCheck');

				if (password !== passwordMatch) {
					this.set('errorMessage', (0, _utils.loc)("The passwords don't match!"));
					return false;
				}

				if (password.length < 8) {
					this.set('errorMessage', (0, _utils.loc)('Password must be at least 8 characters in length.'));
					this.set('isInvalid', true);
				}

				password = _cryptojs.default.SHA256(password).toString();
				this.store.rpcRequest('account', 'reset-password', { hash: this.get('reset_password'), password: password }).then(function (result) {
					if (result.data) {
						_this3.set('isSuccess', true);
						Ember.run.later(function () {
							window.location = '/';
						}, 3000);
					} else {
						_this3.set('errorMessage', (0, _utils.loc)('This reset url has already been used. You must submit a new password reset request.'));
					}
				}).catch(function () {
					_this3.set('errorMessage', (0, _utils.loc)('This reset url has already been used. You must submit a new password reset request.'));
				});
			},
			validatePasswordAction: function validatePasswordAction() {
				var password = this.get('invitePassword');
				var passwordMatch = this.get('invitePasswordCheck');
				this.validatePasswords(password, passwordMatch);
			},
			invitePasswordAction: function invitePasswordAction() {
				var _this4 = this;

				var password = this.get('invitePassword');
				var passwordMatch = this.get('invitePasswordCheck');
				if (!this.validatePasswords(password, passwordMatch)) {
					return false;
				}

				password = _cryptojs.default.SHA256(password).toString();
				this.store.rpcRequest('account', 'reset-password', { hash: this.get('invitation'), password: password }).then(function (result) {
					if (result.data) {
						_this4.set('inviteNextStep', true);
					} else {
						_this4.set('errorMessage', (0, _utils.loc)('This invite url has already been used. Contact your account admin to get a new invatation.'));
						_this4.set('isInvalid', true);
					}
				}).catch(function () {
					_this4.set('errorMessage', (0, _utils.loc)('This invite url has already been used. Contact your account admin to get a new invatation.'));
					_this4.set('isInvalid', true);
				});
			},
			loginPageAction: function loginPageAction() {
				window.location = '/';
			}
		}
	});
});