define('busy-app/routes/company-settings/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/components/mixins/tab-route'], function (exports, _authenticatedRouteMixin, _tabRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _tabRoute.default, {
		auth: Ember.inject.service('auth'),

		queryParams: {
			_desc: { replace: true }
		},

		model: function model() {
			var _this = this;

			return this.get('auth.organization').then(function (org) {
				// load the organization-info model from the api
				return _this.store.query('organization-info', { organization_id: org.get('id') }).then(function (model) {
					// if the model doesn't exist then create it
					var profile = model.objectAt(0);
					if (Ember.isNone(profile)) {
						// create the organization-info model and set the organizationId
						profile = _this.store.createRecord('organization-info', {
							organizationId: org.get('id')
						});
					}
					org.set('organizationInfo', profile);
					return org;
				});
			});
		}
	});
});