define('busy-app/components/dialogs/content-view', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c-dialogs-content-view'],

    analytics: Ember.inject.service('analytics'),

    /** public property list */
    saveText: (0, _utils.loc)('Save'),
    cancelText: (0, _utils.loc)('Cancel'),
    deleteText: (0, _utils.loc)('Delete'),
    saveCloseText: (0, _utils.loc)('Save and Close'),
    removeText: (0, _utils.loc)('Remove'),
    savingMessage: (0, _utils.loc)('Saving'),
    saveColor: 'blue',
    hideFooter: false,
    saveDisabled: false,

    headerTitle: '',

    closeOnSave: true,
    confirmOnDelete: true,
    confirmOnSave: false,
    showCancelButton: true,
    hideSaveAndClose: false,

    /** private property list */
    hasFooter: false,
    canClose: false,
    isDisabled: false,

    showDeleteOverlay: false,
    showConfirmSaveOverlay: false,

    confirmSaveOverlay: false,
    confirmReasonText: '',
    confirmCallback: null,
    disablePadding: false,

    buttonInfo: null,

    /**
     * inititalizes the dialog
     *
     * @private
     * @method init
     * @returns {void}
     */
    init: function init() {
      this._super();

      if (!Ember.isNone(this.get('headerTitle'))) {
        this.get('analytics').addPageView(this.get('headerTitle') + ' ' + 'dialog');
      }

      if (Ember.isNone(this.get('buttonInfo'))) {
        this.setupButtons();
      }
    },


    showFooter: Ember.computed('buttonInfo.length', 'hideFooter', function () {
      if (this.get('buttonInfo.length') && !this.get('hideFooter')) {
        return true;
      }
      return false;
    }),

    setupButtons: function setupButtons() {
      var disabled = false;

      var list = [];
      if (!Ember.isNone(this.get('onSaveConfirmError'))) {

        list.push({ action: this.get('onSaveConfirmError'), color: this.get('saveColor'), text: this.get('saveText'), closeAfter: this.get('closeOnSave'), confirmOnError: true, disabled: disabled });

        if (!this.get('closeOnSave') && !this.get('hideSaveAndClose')) {
          list.push({ action: this.get('onSaveConfirmError'), color: this.get('saveColor'), text: this.get('saveCloseText'), closeAfter: true, confirmOnError: true });
        }
      }

      if (!Ember.isNone(this.get('onSave'))) {
        list.push({ action: this.get('onSave'), color: this.get('saveColor'), text: this.get('saveText'), closeAfter: this.get('closeOnSave') });

        if (!this.get('closeOnSave') && !this.get('hideSaveAndClose')) {
          list.push({ action: this.get('onSave'), color: this.get('saveColor'), text: this.get('saveCloseText'), closeAfter: true });
        }
      }

      if (!Ember.isNone(this.get('onDelete'))) {
        list.push({
          action: this.get('onDelete'), color: 'red', text: this.get('deleteText'), left: true, closeAfter: true,
          confirm: this.get('confirmOnDelete'), confirmText: (0, _utils.loc)('Delete'), confirmReasonText: (0, _utils.loc)('Are you sure you want to delete this?')
        });
      }

      if (!Ember.isNone(this.get('onRemove'))) {
        list.push({
          action: this.get('onRemove'), color: 'red', text: this.get('removeText'), left: true, closeAfter: true,
          confirm: true, confirmText: (0, _utils.loc)('Remove'), confirmReasonText: (0, _utils.loc)('Are you sure you want to remove this?')
        });
      }
      if (!Ember.isNone(this.get('onCancel')) && this.get('showCancelButton')) {

        list.push({ action: this.get('onCancel'), color: 'grey', text: this.get('cancelText') });
      }

      this.set('buttonInfo', list);
    },


    /**
     * Shows a saving message at the
     * bottom of the dialog
     *
     * @public
     * @method showSavingMessage
     * @param message {string} The save message text
     * @param timeout {number} The time in miliseconds to show the message for
     * @returns {EmberPromise}
     */
    showSavingMessage: function showSavingMessage(message, timeout) {
      return this.get('busySave').showSavingMessage(message, timeout);
    },


    /**
     * Shows an error message at the
     * bottom of the dialog
     *
     * @public
     * @method showErrorMessage
     * @param message {string} The error message text
     * @param timeout {number} The time in miliseconds to show the message for
     * @returns {EmberPromise}
     */
    showErrorMessage: function showErrorMessage(message, timeout) {
      return this.get('busySave').showErrorMessage(message, timeout);
    },


    /**
     * Shows a saved message at the
     * bottom of the dialog and fires the close dialog event.
     *
     * @public
     * @method showSavedMessage
     * @param message {string} The saved message text
     * @param timeout {number} The time in miliseconds to show the message for
     * @returns {EmberPromise}
     */
    showSavedMessage: function showSavedMessage(message, timeout) {
      return this.get('busySave').showSavedMessage(message, timeout);
    },
    invokeAction: function invokeAction(type) {
      // make sure a string is passed in
      _utils.Assert.isString(type);

      // get the method
      var func = this.get(type);
      _utils.Assert.test('Component: [content-view] actions must be passed with (action "someAction")', typeof func === 'function');

      // invoke func method
      var promise = func();

      // if the method returns a promise then show status
      if (!Ember.isNone(promise) && typeof promise.then === 'function') {
        // listen for promise to return
        return promise;
      } else {
        return Ember.RSVP.resolve();
      }
    },


    actions: {
      doneAction: function doneAction() {
        if (!Ember.isNone(this.get('onClose'))) {
          this.invokeAction('onClose');
        } else if (!Ember.isNone(this.get('onCancel'))) {
          this.invokeAction('onCancel');
        }
      },
      saveAction: function saveAction(actionName) {
        if (!Ember.isNone(this.get('buttonBar')) && !Ember.isEmpty(actionName)) {
          this.get('buttonBar').invokeActionItem(actionName);
        }
      },
      closeAction: function closeAction() {
        this.sendAction('onCancel');
      },
      cancelAction: function cancelAction() {
        this.sendAction('onCancel');
      },
      saveCloseAction: function saveCloseAction() {
        var _this = this;

        var busySave = this.get('busySave');

        if (!busySave.get('isDisabled')) {
          busySave.set('canClose', true);
        }

        busySave.showConfirm = function (message, callback) {
          _this.set('confirmSaveOverlay', true);
          _this.set('confirmReasonText', message);
          _this.set('confirmCallback', function () {
            callback.call(null, true, busySave);
          });
        };

        //this.sendAction('onSave', busySave);
      },
      deleteAction: function deleteAction() {
        if (this.get('confirmOnDelete')) {
          this.set('showDeleteOverlay', true);
        } else {
          this.send('confirmDelete');
        }
      },
      confirmSave: function confirmSave() {
        if (this.get('confirmCallback')) {
          this.get('confirmCallback').call(null);
        } else {
          this.sendAction('onConfirmSave', this.get('busySave'));
        }
        this.set('confirmSaveOverlay', false);
      },
      cancelConfirmSave: function cancelConfirmSave() {
        this.get('busySave').showErrorMessage('', 0);
        this.set('confirmSaveOverlay', false);
      },
      confirmDelete: function confirmDelete() {
        this.set('showDeleteOverlay', false);
        this.sendAction('onDelete', this.get('busySave'));
      },
      cancelDelete: function cancelDelete() {
        this.set('showDeleteOverlay', false);
      },
      backAction: function backAction() {
        this.sendAction('onBack');
      }
    }
  });
});