define('busy-app/routes/company-settings', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'busy-app/mixins/check-permission'], function (exports, _authenticatedRouteMixin, _checkPermission) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _checkPermission.default, {
    permissions: Ember.computed(function () {
      return ['manageCompanySettings'];
    })
  });
});