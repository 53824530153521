define('busy-app/components/busy-notification-bar', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.isDismissed = isDismissed;
	exports.default = Ember.Component.extend({
		classNames: ['busy-notification-bar', 'page-hide'],

		classNameBindings: ['isWarning:busy-notification-bar-warning', 'isError:busy-notification-bar-error', 'isSuccess:busy-notification-bar-success', 'isInfo:busy-notification-bar-info', 'isShowing::is-dismissed'],

		isWarning: Ember.computed.equal('type', 'warning'),
		isError: Ember.computed.equal('type', 'error'),
		isSuccess: Ember.computed.equal('type', 'success'),
		isInfo: Ember.computed.equal('type', 'info'),
		isShowing: false,

		onDismiss: null,
		isDismissable: false,

		name: '',
		clickText: '',

		init: function init() {
			this._super();
			_utils.Assert.test("name must be a string", !Ember.isEmpty(this.get('name')));

			this.setShowing();
		},


		setShowing: Ember.observer('name', function () {
			// only show the notification if the notification is not in local storage
			var isShowing = _utils.LocalStorage.getProperty(this.get('name')) ? false : true;
			this.set('isShowing', isShowing);
		}),

		actions: {
			clickAction: function clickAction() {
				this.sendAction('onClick');
			},
			onDismiss: function onDismiss() {
				_utils.LocalStorage.setProperty(this.get('name'), true);
				this.setShowing();
				window.location.reload();
			}
		}
	});
	function isDismissed(name) {
		return _utils.LocalStorage.getWithDefault(name, false);
	}
});