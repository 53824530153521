define('busy-app/models/time-entry-organization-conflicts', ['exports', 'busy-app/models/time-entry', '@busy-web/data'], function (exports, _timeEntry, _data) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _timeEntry.default.extend(_data.RPCModelMixin, {
    _clientName: 'entry-conflict',
    _methodName: 'get-by-organization'
  });
});