define('busy-app/utils/system-info', ['exports', '@busy-web/graylog/utils/system-info'], function (exports, _systemInfo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _systemInfo.default;
    }
  });
});