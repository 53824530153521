define('busy-app/mixins/scroll-loader', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({
		/**
   * String path of the element to bind the scroll listener to.
   *
   * @public
   * @property scrollElement
   * @type srting
   */
		scrollElement: null,

		/**
   * Propperty to allow or unallow the auto loader
   * to load data
   *
   * @public
   * @property autoLoad
   * @type boolean
   */
		autoLoad: true,

		/**
   * Private property to stop multiple loads triggered by
   * the scroll.
   *
   * @private
   * @property __canTrigger
   * @type boolean
   */
		__canTrigger: true,

		/**
   * Generates a string for the class name this mixin gets associated with
   *
   * @private
   * @method _classNameString
   * @returns {string}
   */
		_classNameString: function _classNameString() {
			return this.toString().replace(/^</, '').replace(/::ember[\d]*>$/, '');
		},

		/**
   * Handles checking and dispatching the load more if the scroll is
   * within the margins (scrollHeight-scrollPosition) <= 0
   *
   * @private
   * @method _scrollHandler
   * @param scrollPosition {number}
   * @param scrollHeight {number}
   */
		_scrollHandler: function _scrollHandler(scrollPosition, scrollHeight) {
			// __canTrigger is true and the scrollHeight - scrollPosition is <= 0.
			if (this.get('autoLoad') && this.__canTrigger && scrollHeight - scrollPosition <= 0) {
				// can trigger is false while the load is triggered
				this.__canTrigger = false;

				//after loadMore than __canTrigger will be true again
				this.trigger('loadMore');
			}
		},


		/**
   * Sets up the jQuery bin listeners for scroll and element mutations
   *
   * @private
   * @method _bindListeners
   */
		_bindListeners: Ember.on('didInsertElement', function () {
			var _this = this;

			// if the scrollElement in defined.
			if (!Ember.isNone(this.get('scrollElement'))) {
				// get the element specified by the scrollElement string
				var scroller = Ember.$(this.get('scrollElement'));

				// require the scrollElement is set and has retireved a valid DOM object.
				(false && !(scroller.length > 0) && Ember.assert('The scrollElement could not be found in the DOM. Please check the path: ' + this.get('scrollElement'), scroller.length > 0));

				// unbind and rebind the scroll listener

				scroller.unbind('scroll.' + this._classNameString());
				scroller.bind('scroll.' + this._classNameString(), function (evt) {
					// scroll element
					var el = Ember.$(evt.target);

					// call scroll handler
					_this._scrollHandler(el.scrollTop() + el.innerHeight(), el.get(0).scrollHeight);
				});

				// unbind and rebind the modified dom listener event
				scroller.unbind('DOMSubtreeModified.' + this._classNameString());

				// the element changed can trigger is true no
				scroller.bind('DOMSubtreeModified.' + this._classNameString(), function () {
					return _this.__canTrigger = true;
				});
			}
		})
	});
});