define('busy-app/components/busy-date-time', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-date-time'],

		date: null,

		minDate: null,
		maxDate: null,

		format: "ddd. MMM Do h:mm A",

		actions: {
			changeDate: function changeDate(time1) {
				this.sendAction('onChange', time1);
			}
		}
	});
});