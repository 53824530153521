define('busy-app/components/busy-search-bar', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['busy-search-bar'],

		/**
   * The value of the current keyword in
   * the input field.
   *
   * @public
   * @property keyword
   * @type string
   */
		keyword: '',

		/**
   * The placeholder text for the input.
   *
   * @public
   * @property placeholder
   * @type string
   */
		placeholder: (0, _utils.loc)('Search'),

		/**
   * The time to wait before sending an api call after a letter
   * has been typed in the text field.
   *
   * @public
   * @property timeout
   * @type number
   */
		timeout: null,

		/**
   *
   * @public
   * @property keywordSetter
   * @type string
   */
		keywordSetter: null,

		/**
   * Internal initialization method.
   *
   * @private
   * @method init
   */
		init: function init() {
			this._super();

			// if keywordSetter is set the set the keyword to it.
			if (!Ember.isNone(this.get('keywordSetter'))) {
				this.set('keyword', this.get('keywordSetter'));
			}
		},


		actions: {
			/**
    * Key press action event.
    *
    * @public
    * @event onSubmit
    * @method keyPressAction
    */
			keyPressAction: function keyPressAction() {
				var _this = this;

				if (!Ember.isNone(this.get('timeout'))) {
					window.clearTimeout(this.get('timeout'));
				}

				if (this.get('keyword').length >= 4 || this.get('keyword').length === 0) {
					this.set('timeout', setTimeout(function () {
						if (!_this.get('isDestroyed')) {
							_this.set('timeout', null);
							_this.sendAction('onSubmit', _this.get('keyword'));
						}
					}, 600));
				}

				return false;
			},


			/**
    * Enter or click submit action event.
    *
    * @public
    * @event onSubmit
    * @method submitAction
    */
			submitAction: function submitAction() {
				this.sendAction('onSubmit', this.get('keyword'));
			}
		}
	});
});