define('busy-app/utils/currency-codes.js', ['exports', '@busy-web/utils/utils/currency-codes.js'], function (exports, _currencyCodes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _currencyCodes.default;
    }
  });
});