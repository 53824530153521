define('busy-app/models/organization-cancel-detail', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    organizationId: _emberData.default.attr('string'),
    memberId: _emberData.default.attr('string'),
    cancelReason: _emberData.default.attr('string'),
    updatedOn: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('string')
  });
});