define('busy-app/utils/models/collections/payroll-member', ['exports', 'busy-app/utils/models/collections/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({

		payPeriods: Ember.inject.service('pay-period'),

		id: null,
		member: null,
		project: null,
		wage: null,
		startTime: null,

		memberSeconds: null,
		memberCost: null,
		projectSeconds: null,
		projectCost: null,

		hasUnassigned: false,
		isOpen: false,

		applyData: function applyData(data) {
			Ember.set(this, 'model', data);
			Ember.set(this, 'type', data.type);
			Ember.set(this, 'id', data.member.id);
			Ember.set(this, 'isModel', data.isModel);
			Ember.set(this, 'isOpen', false);
			Ember.set(this, 'member', data.member);
			Ember.set(this, 'wage', data.wage);
			Ember.set(this, 'startTime', data.startTime);
			Ember.set(this, 'endTime', data.endTime);
			Ember.set(this, 'projectSeconds', data.projectSeconds);
			Ember.set(this, 'projectCost', data.projectCost);
			Ember.set(this, 'memberCost', data.memberCost);
			Ember.set(this, 'memberSeconds', data.memberSeconds);
		},


		hideData: Ember.computed('projectData.@each.isOpen', function () {
			//let isOpen = null;
			//if (!isNone(this.get('projectData'))) {
			//	isOpen = this.get('projectData').findBy('isOpen', true);
			//}
			// overridden to always hide this data.
			return true; //!isNone(isOpen);
		}),

		/**
   * Gets Estimated Hourly Cost for the given time period
   * from all contents in content Array
   * and adds them up and returns the total.
   *
   * @public
   * @method estCostHourly
   * @returns int
   */
		estCostHourly: Ember.computed('totalCost', 'wage.wageRate', function () {
			var total = 0;
			if (!Ember.isNone(this.get('wage')) && this.get('wage.wageRate') === 10) {
				total = this.get('totalCost');
			}
			return total;
		}),

		/**
   * Gets Estimated Salary Cost for the given time period
   * from all contents in content Array who have a wage
   * type of anything but hourly and adds them up and returns the total.
   *
   * @public
   * @method estCostSalary
   * @returns int
   */
		estCostSalary: Ember.computed('totalCost', 'wage.wageRate', function () {
			var total = 0;
			if (!Ember.isNone(this.get('wage')) && this.get('wage.wageRate') !== 10) {
				total = this.get('totalCost');
			}
			return total;
		}),

		hasRegular: Ember.computed('memberSeconds.regularSeconds', 'memberCost.regularCost', function () {
			if ((this.get('memberSeconds.regularSeconds') || 0) > 0 || (this.get('memberCost.regularCost') || 0) > 0) {
				return true;
			}
			return false;
		}),

		hasOvertime: Ember.computed('memberSeconds.overtimeSeconds', 'memberCost.overtimeCost', function () {
			if ((this.get('memberSeconds.overtimeSeconds') || 0) > 0 || (this.get('memberCost.overtimeCost') || 0) > 0) {
				return true;
			}
			return false;
		}),

		hasDoubletime: Ember.computed('memberSeconds.doubletimeSeconds', 'memberCost.doubletimeCost', function () {
			if ((this.get('memberSeconds.doubletimeSeconds') || 0) > 0 || (this.get('memberCost.doubletimeCost') || 0) > 0) {
				return true;
			}
			return false;
		}),

		memberRegularSeconds: Ember.computed('memberSeconds.regularSeconds', function () {
			return this.get('memberSeconds.regularSeconds') || 0;
		}),

		totalRegularTimeString: Ember.computed('memberRegularSeconds', function () {
			var totalRegularSeconds = _utils.Time.convertSeconds(this.get('memberRegularSeconds'));

			return totalRegularSeconds.hours + ' hrs ' + totalRegularSeconds.minutes + ' mins';
		}),

		memberOvertimeSeconds: Ember.computed('memberSeconds.overtimeSeconds', function () {
			return this.get('memberSeconds.overtimeSeconds') || 0;
		}),

		totalOvertimeTimeString: Ember.computed('memberOvertimeSeconds', function () {
			var totalOtSeconds = _utils.Time.convertSeconds(this.get('memberOvertimeSeconds'));

			return totalOtSeconds.hours + ' hrs ' + totalOtSeconds.minutes + ' mins';
		}),

		memberDoubletimeSeconds: Ember.computed('memberSeconds.doubletimeSeconds', function () {
			return this.get('memberSeconds.doubletimeSeconds') || 0;
		}),

		totalDoubletimeTimeString: Ember.computed('memberDoubletimeSeconds', function () {
			var totalDoubletimeSeconds = _utils.Time.convertSeconds(this.get('memberDoubletimeSeconds'));

			return totalDoubletimeSeconds.hours + ' hrs ' + totalDoubletimeSeconds.minutes + ' mins';
		}),

		totalSeconds: Ember.computed('memberRegularSeconds', 'memberOvertimeSeconds', 'memberDoubletimeSeconds', function () {
			return this.get('memberRegularSeconds') + this.get('memberOvertimeSeconds') + this.get('memberDoubletimeSeconds');
		}),

		totalTimeString: Ember.computed('totalSeconds', function () {
			var totalOverallSeconds = _utils.Time.convertSeconds(this.get('totalSeconds'));

			return totalOverallSeconds.hours + ' hrs ' + totalOverallSeconds.minutes + ' mins';
		}),

		totalRegularCostString: Ember.computed('memberCost.regularCost', function () {
			return (this.get('memberCost.regularCost') || 0).toFixed(2);
		}),

		totalOvertimeCostString: Ember.computed('memberCost.overtimeCost', function () {
			return (this.get('memberCost.overtimeCost') || 0).toFixed(2);
		}),

		totalDoubletimeCostString: Ember.computed('memberCost.doubletimeCost', function () {
			return (this.get('memberCost.doubletimeCost') || 0).toFixed(2);
		}),

		totalCost: Ember.computed('wage.wageRate', 'memberCost.{regularCost,overtimeCost,doubletimeCost}', function () {
			var totalCost = 0;
			if (!Ember.isNone(this.get('wage')) && this.get('wage.wageRate') !== 10) {
				var salary = this.convertSalaryType(this.get('wage.wage'), this.get('wage.wageRate'));
				var payPeriod = this.get('payPeriods').getPayPeriod(this.get('startTime') + 1);

				totalCost = this.calculateSalary(salary, payPeriod.get('type'));
			} else {
				totalCost = (this.get('memberCost.regularCost') || 0) + (this.get('memberCost.overtimeCost') || 0) + (this.get('memberCost.doubletimeCost') || 0);
			}

			return totalCost;
		}),

		totalCostString: Ember.computed('totalCost', function () {
			return this.get('totalCost').toFixed(2);
		}),

		totalOverburdenCost: Ember.computed('totalCost', 'wage.overburden', function () {
			var cost = this.get('totalCost');
			var burden = this.get('wage.overburden') || 0;

			var over = cost * (burden / 100);

			return cost + over;
		}),

		/**
   * Converts the salary to a monthly salary based on the type
   * passed in. Types are 30:weekly, 40:monthly, 50:yearly
   *
   * @public
   * @method convertSalaryType
   * @params salary {int} The salary in dollars
   * @params type {int} The salary type - weekly, monthly, yearly
   * @returns {int} yearly salary
   */
		convertSalaryType: function convertSalaryType(salary, type) {
			if (type === 30) {
				return salary * 52;
			} else if (type === 40) {
				return salary * 12;
			}

			return salary;
		},

		/**
   * Calculates the salary pay for the pay period type.
   * Types are 10:weekly, 20:biweekly, 30:Semimonthly, 40:monthly
   *
   * @public
   * @method calculateSalary
   * @params salary {int} The yearly salary in dollars
   * @params type {int} The pay period type
   * @returns {float} salary per pay-period lenght
   */
		calculateSalary: function calculateSalary(salary, type) {
			var totalCost = 0;
			if (type === 10) {
				totalCost = salary / 52;
			} else if (type === 20) {
				totalCost = salary / 52 * 2;
			} else if (type === 30) {
				totalCost = salary / 24;
			} else if (type === 40) {
				totalCost = salary / 12;
			}
			return totalCost;
		}
	});
});