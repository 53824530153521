define('busy-app/components/busy-dialog-footer', ['exports', 'busy-app/templates/components/busy-dialog-footer'], function (exports, _busyDialogFooter) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		layout: _busyDialogFooter.default,
		classNames: ['busy-dialog-footer'],

		init: function init() {
			this._super();
			this.set('parentView.parentView.parentView.hasFooter', true);
		}
	});
});