define('busy-app/utils/models/managers/time-entry-day', ['exports', '@busy-web/utils', 'busy-app/utils/models/managers/time-entry-range'], function (exports, _utils, _timeEntryRange) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _timeEntryRange.default.extend({
		addCollection: function addCollection(model, start, day) {
			var prev = this.get('model').findBy('day', day);
			if (!Ember.isNone(prev)) {
				prev.get('model').unshiftObject(model);
			} else {
				this._super(model, start, day);
			}
		},
		buildCollections: function buildCollections(data) {
			var _this = this;

			this.set('_rangeItems', []);
			var entries = data.entries.sortBy('startTime');
			entries.forEach(function (model) {
				_this.checkConflict(model, entries);
				_this.splitEntries(model);
			});

			var rangeItems = this.get('_rangeItems').sortBy('start');
			rangeItems.forEach(function (model) {
				var day = model.get('dayString');
				var date = model.get('start');
				_this.addCollection(model, date, day);
			});
			this.set('content', this.get('content').sortBy('start').reverse());
		},


		_rangeItems: null,
		splitEntries: function splitEntries(model) {
			var start = this.get('start');
			var end = this.get('end');

			var startTime = _utils.Time.date(model.get('startTime')).startOf('day').unix();
			if (startTime < start) {
				startTime = start;
			}

			var endTime = _utils.Time.date(model.get('endTime')).endOf('day').unix();
			if (endTime > end) {
				endTime = end;
			}

			while (startTime < endTime) {
				var endDay = _utils.Time.date(startTime).endOf('day').unix() + 1;
				var collection = this.collectionFor('time-entry-range');
				var _collection = collection.push(model, startTime, endDay);
				this.get('_rangeItems').pushObject(_collection);
				startTime = _utils.Time.date(startTime).add(1, 'days').unix();
			}
		},


		totalTime: Ember.computed('model.@each.{regularSeconds,overtimeSeconds,doubletimeSeconds}', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('regularSeconds') + item.get('overtimeSeconds') + item.get('doubletimeSeconds');
				});
			}
			return total;
		})
	});
});