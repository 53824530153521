define('busy-app/components/selectors/position-select', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),

		selected: null,

		positions: Ember.computed('auth.positions.@each.id', 'selected', function () {
			if (!Ember.isEmpty(this.get('auth.positions'))) {
				var list = this.get('auth.positions').sortBy('level');
				var selectedId = this.get('selected');

				// select the lowest permission or the permission
				// that matches the selectedId
				var selected = list.get('lastObject');
				if (!Ember.isEmpty(selectedId)) {
					selected = list.findBy('id', selectedId);
				}

				// return a list of ember objects
				return list.map(function (item) {
					return Ember.Object.create({
						id: item.id,
						title: item.get('title'),
						_selected: selected.id === item.id,
						_model: item
					});
				});
			}

			// default return empty list
			return [];
		}),

		actions: {
			itemSelected: function itemSelected(item) {
				this.sendAction('onSelect', item);
			}
		}
	});
});