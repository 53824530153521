define('busy-app/utils/models/dispatchers/time-entry-segment', ['exports', 'busy-app/utils/models/dispatchers/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _defineProperty(obj, key, value) {
		if (key in obj) {
			Object.defineProperty(obj, key, {
				value: value,
				enumerable: true,
				configurable: true,
				writable: true
			});
		} else {
			obj[key] = value;
		}

		return obj;
	}

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = _base.default.extend({
		auth: Ember.inject.service('auth'),
		signature: Ember.inject.service('signature'),
		features: Ember.inject.service('features'),

		/**
   * finds all the time-entry data with segments for a time-entry by range.
   *
   * @public
   * @method fetch
   * @param start {number} Required timestamp for the start of the range.
   * @param end {number} Required timestamp for the end of the range.
   * @param memberId {string} Optional id to filter by a member.
   * @param projectId {string} Optional param to filter by a top level project and its children.
   * @param costCodeId {string} Optional param to filter by a cost code.
   * @param equipmentId {string} Optional param to filter by an equipment model.
   * @param exclusive {boolean} Default false, true will force the filter by projectId to disinclude children.
   * @return {promise}
   */
		findAllByMemberRange: function findAllByMemberRange(start, end, memberId, projectId, costCodeId, equipmentId, exclusive) {
			var _this = this;

			var timeOffPromise = Ember.RSVP.resolve([]);
			var openEntryPromise = Ember.RSVP.resolve([]);
			if ((Ember.isNone(projectId) || projectId === '_-NULL-_' || projectId === null) && (Ember.isNone(costCodeId) || costCodeId === '_-NULL-_' || costCodeId === null)) {
				timeOffPromise = this.store.filter('time-off').findAllByRange(start, end, memberId);

				// all open entries started before the end of today even if not opened today and started after
				// the start time
				openEntryPromise = this.store.filter('time-entry').findAllOpenEntries(memberId, null, end);
			}

			return Ember.RSVP.hash({
				timeEntries: this.store.filter('time-entry').findAllByRange(start, end, memberId, projectId, costCodeId, equipmentId, exclusive),
				openTimeEntries: openEntryPromise,
				timeOffEntries: timeOffPromise,
				members: this.getAllMemberData(memberId, start, end)
			}).then(function (data) {
				var memberIds = [];
				data.timeEntries.forEach(function (entry) {
					return memberIds.indexOf(entry.get('memberId')) === -1 && Ember.isNone(data.members.findBy('id', entry.get('memberId'))) ? memberIds.push(entry.get('memberId')) : '';
				});
				data.timeOffEntries.forEach(function (entry) {
					return memberIds.indexOf(entry.get('memberId')) === -1 && Ember.isNone(data.members.findBy('id', entry.get('memberId'))) ? memberIds.push(entry.get('memberId')) : '';
				});
				data.openTimeEntries.forEach(function (entry) {
					return memberIds.indexOf(entry.get('memberId')) === -1 && Ember.isNone(data.members.findBy('id', entry.get('memberId'))) ? memberIds.push(entry.get('memberId')) : '';
				});

				return _this.getMemberDataByIds(memberIds, start, end).then(function (addMembers) {
					if (addMembers.get('length') > 0) {
						data.members.pushObjects(addMembers.get('content'));
					}

					return Ember.RSVP.hash({
						timeEntries: _this.getRelatedModels(data.timeEntries, start, end),
						openTimeEntries: _this.getRelatedModels(data.openTimeEntries, start, end),
						timeOffEntries: data.timeOffEntries,
						members: data.members
					});
				});
			});
		},


		/**
   * finds all the time-entry data with segments for a time-entry by range.
   *
   * @public
   * @method fetch
   * @param start {number} Required timestamp for the start of the range.
   * @param end {number} Required timestamp for the end of the range.
   * @param memberId {string} Optional id to filter by a member.
   * @param projectId {string} Optional param to filter by a top level project and its children.
   * @param costCodeId {string} Optional param to filter by a cost code.
   * @param equipmentId {string} Optional param to filter by an equipment model.
   * @param exclusive {boolean} Default false, true will force the filter by projectId to disinclude children.
   * @return {promise}
   */
		findAllByRange: function findAllByRange() {
			var start = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
			var end = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
			var memberId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
			var projectId = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
			var costCodeId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

			var _this2 = this;

			var equipmentId = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
			var exclusive = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

			var options = start;
			if (Ember.isNone(options) || (typeof options === 'undefined' ? 'undefined' : _typeof(options)) !== 'object') {
				options = {
					start: start, end: end, memberId: memberId, projectId: projectId, costCodeId: costCodeId, equipmentId: equipmentId, exclusive: exclusive,
					disableTimeOff: false,
					disableOpenEntry: false
				};
			}

			var timeOffPromise = Ember.RSVP.resolve([]);
			var openEntryPromise = Ember.RSVP.resolve([]);
			if ((Ember.isNone(options.projectId) || options.projectId === '_-NULL-_' || options.projectId === null) && (Ember.isNone(options.costCodeId) || options.costCodeId === '_-NULL-_' || options.costCodeId === null)) {
				if (!options.disableTimeOff) {
					timeOffPromise = this.store.filter('time-off').findAllByRange(options.start, options.end, options.memberId);
				}

				if (!options.disableOpenEntry) {
					// all open entries started before the end of today even if not opened today and started after
					// the start time
					openEntryPromise = this.store.filter('time-entry').findAllOpenEntries(options.memberId, options.start, options.end);
				}
			}

			return Ember.RSVP.hash({
				timeEntries: this.store.filter('time-entry').findAllByRange(options.start, options.end, options.memberId, options.projectId, options.costCodeId, options.equipmentId, options.exclusive),
				openTimeEntries: openEntryPromise,
				timeOffEntries: timeOffPromise
			}).then(function (models) {
				var memberIds = [];
				models.timeEntries.forEach(function (entry) {
					return memberIds.indexOf(entry.get('memberId')) === -1 ? memberIds.push(entry.get('memberId')) : '';
				});
				models.timeOffEntries.forEach(function (entry) {
					return memberIds.indexOf(entry.get('memberId')) === -1 ? memberIds.push(entry.get('memberId')) : '';
				});
				models.openTimeEntries.forEach(function (entry) {
					return memberIds.indexOf(entry.get('memberId')) === -1 ? memberIds.push(entry.get('memberId')) : '';
				});

				return Ember.RSVP.hash({
					timeEntries: _this2.getRelatedModels(models.timeEntries, options.start, options.end),
					openTimeEntries: _this2.getRelatedModels(models.openTimeEntries, options.start, options.end),
					timeOffEntries: models.timeOffEntries,
					members: _this2.getMemberDataByIds(memberIds, options.start, options.end)
				});
			});
		},
		getRelatedModels: function getRelatedModels(entries, start, end) {
			var projectIds = [];
			var costCodeIds = [];
			var breakIds = [];
			var equipmentIds = [];
			var segmentIds = [];

			entries.forEach(function (entry) {
				breakIds.push(entry.id);
				segmentIds.push(entry.id);

				if (!Ember.isNone(entry.get('projectId')) && projectIds.indexOf(entry.get('projectId')) === -1) {
					projectIds.push(entry.get('projectId'));
				}

				if (!Ember.isNone(entry.get('costCodeId')) && costCodeIds.indexOf(entry.get('costCodeId')) === -1) {
					costCodeIds.push(entry.get('costCodeId'));
				}

				if (!Ember.isNone(entry.get('equipmentId')) && equipmentIds.indexOf(entry.get('equipmentId')) === -1) {
					equipmentIds.push(entry.get('equipmentId'));
				}
			});

			return Ember.RSVP.hash({
				projects: this.store.filter('project').findFullProjects(projectIds),
				costCodes: this.store.findByIds('cost-code', costCodeIds),
				equipment: this.store.findByIds('equipment', equipmentIds),
				breaks: this.store.findWhereIn('time-entry-break', 'time_entry_id', breakIds, { deleted_on: null }),
				segments: this.store.filter('segment').findByTimeEntryIds(segmentIds, { _gte: { start_time: start }, _lte: { end_time: end + 1 }, deleted_on: null, _asc: ['start_time'] })
			}).then(function (data) {
				entries.forEach(function (item) {
					// Since the models were loaded into ember-data store
					// in the calls above, and the model has DS.belongsTo
					// for the following models. Calling get on each joined
					// model will load the joined model into this model from
					// memory.
					item.get('project');
					item.get('costCode');
					item.get('equipment');

					// Since the following models are DS.hasMany calling get will not always
					// return the desired models. For instance if a time entry spans mutliple
					// days then it will have multiple segments and the desired return may not
					// need all segments from all days. So call get would return all segements.
					var breaks = data.breaks.filterBy('timeEntryId', item.get('id'));
					if (!Ember.isEmpty(breaks)) {
						item.set('timeEntrieBreaks', breaks);
					}

					var segments = data.segments.filterBy('timeEntryId', item.get('id'));
					if (!Ember.isEmpty(segments)) {
						item.set('segments', segments);
					}
				});
				return entries;
			});
		},
		getAllMemberData: function getAllMemberData(id, start, end) {
			var _this3 = this;

			var query = {};
			var query2 = {};
			if (!Ember.isNone(id)) {
				query.id = id;
				query2.id = id;
				query2.archived_on = null;
			} else {
				query.archived_on = null;
				query._lte = { created_on: end };
				query2._gte = { archived_on: start };
				query._lte = { created_on: end };
			}

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				var authMember = Ember.get(this, 'auth.member');

				var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
				    _id = _EmberGetProperties.id,
				    memberGroupId = _EmberGetProperties.memberGroupId;

				Ember.setProperties(query, { member_group_id: memberGroupId });
				Ember.setProperties(query2, { member_group_id: memberGroupId });

				if (Ember.isNone(memberGroupId)) {
					Ember.setProperties(query, { id: _id });
					Ember.setProperties(query2, { id: _id });
				}
			}

			return Ember.RSVP.hash({
				members: Ember.get(this, 'store').findAll('member', query),
				archived: Ember.get(this, 'store').findAll('member', query2)
			}).then(function (_ref) {
				var members = _ref.members,
				    archived = _ref.archived;

				members.pushObjects(archived.get('content'));

				return _this3.getMemberRelatedData(members.mapBy('id'), start, end).then(function (data) {
					return _this3.setMemberData(members, data, start);
				});
			});
		},
		getMemberDataByIds: function getMemberDataByIds(ids, start, end) {
			var _this4 = this;

			var query = {};
			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				var authMember = Ember.get(this, 'auth.member');

				var _EmberGetProperties2 = Ember.getProperties(authMember, ['id', 'memberGroupId']),
				    id = _EmberGetProperties2.id,
				    memberGroupId = _EmberGetProperties2.memberGroupId;

				Ember.setProperties(query, { member_group_id: memberGroupId });

				if (Ember.isNone(memberGroupId)) {
					Ember.setProperties(query, { id: id });
				}
			}

			return Ember.RSVP.hash({
				members: this.store.findByIds('member', ids.copy(), query),
				related: this.getMemberRelatedData(ids, start, end)
			}).then(function (_ref2) {
				var members = _ref2.members,
				    related = _ref2.related;

				return _this4.setMemberData(members, related, start);
			});
		},
		getMemberRelatedData: function getMemberRelatedData(ids, start, end) {
			var mtdParams = {
				start_time: start,
				end_time: end,
				deleted_on: null,
				page_size: 999999
			};

			return Ember.RSVP.hash({
				wages: this.store.filter('wage-history').findByMemberIds(ids, start),
				memberTimeDocuments: this.store.findWhereIn('member-time-document', 'member_id', ids, mtdParams).then(function (documents) {
					var promise = [];
					documents.forEach(function (item) {
						promise.push(item.getSelfSignature());
						promise.push(item.getAuthoritativeSignature());
					});
					return Ember.RSVP.all(promise).then(function () {
						return documents;
					});
				})
			});
		},


		/**
   * Add member related models to the member model
   *
   */
		setMemberData: function setMemberData(members, data, start) {
			members.forEach(function (item) {
				var wages = data.wages.filterBy('memberId', item.get('id')).sortBy('changeDate');
				item.set('wages', wages);

				var wage = wages.objectAt(0);
				wages.forEach(function (item) {
					if (item.get('changeDate') > wage.get('changeDate') && item.get('changeDate') <= start) {
						wage = item;
					}
				});

				item.set('wage', wage);
				item.set('memberTimeDocuments', data.memberTimeDocuments.filterBy('memberId', item.get('id')));
			});
			return members;
		},


		/**
   * NOTE:
   * This is not correct in the assumptions it is making. This call is getting member time documents
   * by pay period where as this dispatcher is not bound by pay periods and in some case will logically fail
   * when the date range does match a pay period. This function should not be used and this function should
   * be removed in the future.
   */
		getMemberTimeDocuments: function getMemberTimeDocuments(memberIds, startTime, endTime) {
			var _this5 = this;

			// short circuit if signatures feature is not enabled
			if (!this.get('features').isEnabled('signatures') || Ember.isEmpty(memberIds)) {
				return Ember.RSVP.resolve();
			}

			var queryParams = {
				start_time: startTime,
				end_time: endTime,
				_in: { member_id: memberIds },
				_desc: ['submitted_on']
			};

			return this.get('signature').queryMemberTimeDocuments(queryParams).then(function (memberTimeDocumentsCache) {
				if (Ember.isEmpty(memberTimeDocumentsCache)) {
					return memberTimeDocumentsCache;
				}

				// convert array of memberIds to a hash of promises keyed by memberId
				var memberPayPeriodState = memberIds.reduce(function (result, memberId) {
					return Ember.merge(result, _defineProperty({}, memberId, _this5.getByMemberPayPeriod(memberId, startTime, endTime, memberTimeDocumentsCache)));
				}, {});

				return Ember.RSVP.hash(memberPayPeriodState).then(function (hash) {
					return Ember.Object.create(hash);
				});
			});
		},


		/**
   * NOTE:
   * This function is like the function above and should also be removed
   *
   */
		getByMemberPayPeriod: function getByMemberPayPeriod(memberId, startTime, endTime, memberTimeDocumentsCache) {
			var payPeriod = this.store.createRecord('pay-period-member-seconds', { memberId: memberId, startTime: startTime, endTime: endTime });

			return this.get('signature').getByMemberPayPeriod(payPeriod, memberTimeDocumentsCache).then(function (mtd) {
				return Ember.isEmpty(mtd) ? mtd : mtd.getSelfSignature().then(function () {
					return mtd;
				});
			});
		}
	});
});