define('busy-app/events/router', ['exports', 'busy-app/events/emitter'], function (exports, _emitter) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emitter.default.extend({
		/**
   * Internal property to keep track of the
   * current router path
   *
   * @property _path
   * @type {string}
   */
		_path: '',

		/**
   * Called by the router to trigger a
   * beforeTransition event
   *
   * @private
   * @method _transition
   */
		_transition: function _transition(path) {
			var lastPath = this.get('_path');

			// set current router path
			this.set('_path', path);

			// trigger the event
			return [path, lastPath];
		}
	});
});