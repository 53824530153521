define('busy-app/components/company/integrations/oauth-callback', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		integration: Ember.inject.service('integration'),
		router: Ember.inject.service(),

		classNames: ['v-company-settings-integrations-oauth-callback'],

		/**
   * Label identifying the name of the organization-integration
   *
   * @public
   * @property integrationName
   * @type {string}
   */
		integrationName: null,

		/**
   * Displayed on an error page
   *
   * @public
   * @property integrationLabel
   * @type {string}
   */
		integrationLabel: null,

		/**
  * Where to redirect after this component completes
  *
  * @public
  * @property dashboardUrl
  * @type {string}
  */
		dashboardUrl: null,

		/**
  * The code returned from the OAuth prover to be sent to the API
  *
  * @public
  * @property grantCode
  * @type {string}
  */
		grantCode: null,

		/**
   * Component Lifecycle
   *
   */

		/**
  * @private
  * @method init
  * @construtor
  */
		init: function init() {
			this._super();

			_utils.Assert.test('integrationName is a required property', !Ember.isEmpty(this.get('integrationName')));
			_utils.Assert.test('integrationLabel is a required property', !Ember.isEmpty(this.get('integrationLabel')));
			_utils.Assert.test('dashboardUrl is a required property', !Ember.isEmpty(this.get('dashboardUrl')));

			_utils.Assert.isString(this.get('integrationName'));
			_utils.Assert.isString(this.get('integrationLabel'));
			_utils.Assert.isString(this.get('dashboardUrl'));

			var grantCode = this.get('grantCode');

			if (!Ember.isNone(grantCode)) {
				return this.handleGrantCode(grantCode);
			} else {
				return this.set('rpcError', {
					errorType: (0, _utils.loc)('OAuth Error'),
					errorMessage: (0, _utils.loc)('Authentication token not provided')
				});
			}
		},


		/**
   * Handle the `grantCode` the grantCode returned by OAuth callback
   *
   * @private
   * @method handleGrantCode
   * @param {grantCode}
   * @async
   * @return {Object}
   */
		handleGrantCode: function handleGrantCode(grantCode) {
			var _this = this;

			return this.setCredentials(grantCode).then(function () /* result */{
				return _this.transitionTo(_this.get('dashboardUrl'));
			});
		},


		/**
   * Submit the `grantCode` to the RPC
   *
   * @private
   * @method setCredentials
   * @param {grantCode}
   * @async
   * @return {Object}
   */
		setCredentials: function setCredentials(grantCode) {
			var _this2 = this;

			if (!window.runningTests) {
				return this.get('integration').rpcSetCredentials(this.get('integrationName'), grantCode).catch(function (err) {
					var rpcError = _this2.get('integration').rpcErrorHandler(err);
					_this2.set('rpcError', rpcError);
					throw err;
				});
			} else {
				return Ember.RSVP.resolve();
			}
		},


		/**
  * @private
  * @method transitionTo
  * @param route {string}
  */
		transitionTo: function transitionTo(route) {
			return this.get('router').transitionTo(route);
		}
	});
});