define('busy-app/models/pay-period-verification', ['exports', 'ember-data', '@busy-web/utils'], function (exports, _emberData, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		endTime: _emberData.default.attr('number'),
		signatureId: _emberData.default.attr('string'),
		canceled: _emberData.default.attr('boolean'),
		signedOnLocal: _emberData.default.attr('number'),
		signedOnGmt: _emberData.default.attr('number'),
		signedOnOffset: _emberData.default.attr('number'),
		edited: _emberData.default.attr('boolean'),
		regularSeconds: _emberData.default.attr('number'),
		overtimeSeconds: _emberData.default.attr('number'),
		doubletimeSeconds: _emberData.default.attr('number'),
		regularCost: _emberData.default.attr('number'),
		overtimeCost: _emberData.default.attr('number'),
		doubletimeCost: _emberData.default.attr('number'),
		wage: _emberData.default.attr('number'),
		wageRate: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),
		signatureUrl: _emberData.default.attr('string'),
		signatureThumbUrl: _emberData.default.attr('string'),

		regularTimeString: Ember.computed('regularSeconds', function () {
			var seconds = this.get('regularSeconds');
			return this.convertSeconds(seconds);
		}),

		overtimeTimeString: Ember.computed('overtimeSeconds', function () {
			var seconds = this.get('overtimeSeconds');
			return this.convertSeconds(seconds);
		}),

		totalTimeString: Ember.computed('regularSeconds', 'overtimeSeconds', function () {
			var seconds = this.get('regularSeconds') + this.get('overtimeSeconds');
			return this.convertSeconds(seconds);
		}),

		dateRequested: Ember.computed('createdOn', function () {
			return (0, _utils.loc)('Requested On %@', [_utils.Time.timeFormat(this.get('verification.createdOn'), 'MMM D, YYYY')]);
		}),

		wageString: Ember.computed('wage', 'wageRate', function () {
			var wageRate = this.get('wageRate');
			var wageString = this.get('wage').toFixed(2);
			if (!Ember.isNone(this.get('wage'))) {
				if (wageRate === 10) {
					wageString = '$' + wageString + (0, _utils.loc)('/hour');
				} else if (wageRate === 20) {
					wageString = '$' + wageString + (0, _utils.loc)('/week');
				} else if (wageRate === 30) {
					wageString = '$' + wageString + " " + (0, _utils.loc)('Semimonthly');
				} else if (wageRate === 40) {
					wageString = '$' + wageString + " " + (0, _utils.loc)('Monthly');
				}
			}
			return wageString;
		}),

		convertSeconds: function convertSeconds(seconds) {
			var hours = Math.floor(seconds / 60 / 60);
			var minutes = Math.floor((seconds - hours * 60 * 60) / 60);
			return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
		}
	});
});