define('busy-app/utils/models/collections/time-entry-day', ['exports', 'busy-app/utils/models/collections/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		day: null,
		start: null,

		total: Ember.computed('model.@each.totalTime', function () {
			var total = 0;
			if (!Ember.isNone(this.get('model'))) {
				this.get('model').forEach(function (item) {
					total += item.get('totalTime');
				});
			}
			var time = _utils.Time.convertSeconds(total, true);
			return time.hours + ':' + time.minutes;
		}),

		applyData: function applyData(model, start, day) {
			Ember.set(this, 'day', day);
			Ember.set(this, 'start', start);
			this._super([model]);
		},


		hasConflict: Ember.computed('model.@each.hasConflict', function () {
			var conflict = this.get('model').filterBy('hasConflict', true);
			return conflict.length > 0;
		})
	});
});