define('busy-app/components/company/integrations/sync-log-dialog/log-dialog', ['exports', 'busy-app/utils/docs', '@busy-web/utils'], function (exports, _docs, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),
		store: Ember.inject.service('store'),

		classNames: ['v-company-settings-integrations-dialog'],

		/**
   * Component Attributes
   */

		integrationName: null,
		headerTitle: (0, _utils.loc)('Activity Log'),
		closeText: (0, _utils.loc)('Close'),
		printText: (0, _utils.loc)('Print'),

		/**
   * Public Properties
   */

		isLoadingIntegrationAttempts: false,
		isLoadingIntegrationLog: false,
		integrationAttempts: null,

		/**
   * Private Properties
   */
		_integrationLogs: null,
		_selectedIntegrationAttempt: null,

		/**
   *	Public Computeds
   */

		/**
   * Computed on `integrationAttempts`,`_selectedIntegrationAttempt`
   *
  	 * @public
   * @property selectMenuOptions
   * @type {EmberObject[]}
   */
		selectMenuOptions: Ember.computed('integrationAttempts', '_selectedIntegrationAttempt', function () {
			var _this = this;

			var attempts = this.get('integrationAttempts');

			if (!Ember.isNone(attempts) && !Ember.isEmpty(attempts)) {
				return attempts.map(function (attempt) {
					var key = attempt.get('id');
					var label = _utils.Time.date(attempt.get('endTime')).format('MMMM DD, YYYY HH:mm A');
					var isSelected = attempt.get('id') === _this.get('_selectedIntegrationAttempt.id');

					return Ember.Object.create({
						key: key,
						label: label,
						_selected: isSelected
					});
				});
			}
		}),

		/**
   * Computed on `_integrationLogs`
   *
   * @public
   * @property sectionizedIntegrationLogs
   * @type EmberObject
   */
		sectionizedIntegrationLogs: Ember.computed('_integrationLogs', function () {
			var logs = this.get('_integrationLogs');

			if (!Ember.isNone(logs)) {
				var sectionNames = logs.mapBy('resourceType').uniq().sort();
				var sectionized = Ember.Object.create();

				sectionNames.forEach(function (sectionName) {
					var section = logs.filterBy('resourceType', sectionName);
					var importEntries = section.filterBy('actionType', 'import');
					var exportEntries = section.filterBy('actionType', 'export');

					sectionized.set(sectionName, { importEntries: importEntries, exportEntries: exportEntries });
				});

				return sectionized;
			}
		}),

		/**
   * Component Lifecycle
   */

		/**
   * @private
   * @method init
   */
		init: function init() {
			var _this2 = this;

			this._super();

			this.setIntegrationAttempts().then(function () {
				_this2.initSelectMenu();
			});
		},


		/**
   * Public Methods
   */

		/**
  	 * Sets result of `getIntegrationAttempts` to `integrationAttempts`
  	 *
  	 * @public
  	 * @method setIntegrationAttempts
  	 * @returns {Models.IntegrationAttempt[]}
   */
		setIntegrationAttempts: function setIntegrationAttempts() {
			var _this3 = this;

			this.set('isLoadingIntegrationAttempts', true);
			return this.getIntegrationAttempts().then(function (integrationAttempts) {
				_this3.set('integrationAttempts', integrationAttempts);

				return integrationAttempts;
			}).finally(function () {
				_this3.set('isLoadingIntegrationAttempts', false);
			});
		},


		/**
   * Gets the the newest 10 `integration-attempt` records for the current `organization` and `integrationName`
   *
  	 * @public
   * @method getIntegrationAttempts
   * @returns {Models.IntegrationAttempt[]}
   */
		getIntegrationAttempts: function getIntegrationAttempts() {
			var organization = this.get('auth.organization.content');
			var integrationName = this.get('integrationName');

			return organization.getIntegration(integrationName).then(function (organizationIntegration) {
				var query = {
					_desc: ['end_time'],
					page: 1,
					page_size: 10,
					end_date: '!_-NULL-_'
				};

				return organizationIntegration.queryIntegrationAttempts(query);
				// return this.getIntegrationAttemptsMocked(organizationIntegration);
			});
		},


		/**
   * Sets the `integration-log` records for the current `integration-attempt` to `_selectedIntegrationAttempt`
   *
  	 * @public
   * @method setIntegrationLogs
   * @returns {Models.IntegrationLog[]}
   */
		setIntegrationLogs: function setIntegrationLogs() {
			var _this4 = this;

			this.set('isLoadingIntegrationLog', true);
			return this.get('_selectedIntegrationAttempt').getIntegrationLogs().then(function (integrationLogs) {
				_this4.set('_integrationLogs', integrationLogs);

				return integrationLogs;
			}).finally(function () {
				_this4.set('isLoadingIntegrationLog', false);
			});
		},


		/**
   * Trigger `selectMenuOptions` to select the the first option
   *
   * @private
   * @method initSelectMenu
   */
		initSelectMenu: function initSelectMenu() {
			// selectMenuOptions is a computed off integrationAttempts
			if (!Ember.isNone(this.get('selectMenuOptions')) && !Ember.isEmpty(this.get('selectMenuOptions'))) {
				var firstOption = this.get('selectMenuOptions.firstObject');

				this.send('onSelectMenuOption', firstOption);
			}
		},


		/**
   *	Actions
   */

		actions: {

			/** @event onClose */
			onClose: function onClose() {
				return this.sendAction('onClose');
			},


			/** @event onPrint */
			onPrint: function onPrint() {
				var $container = Ember.$('.v-company-settings-integrations-sync-log');
				_docs.default.print($container);
			},


			/**
    * Triggered when `selectMenuOptions` changes. Store the selected entry to `_selectedIntegrationAttempt` and call `setIntegrationLogs()`
    *
    * @event onSelectMenuOption
    * @param entry {EmberObject}
    */
			onSelectMenuOption: function onSelectMenuOption(entry) {
				var entryId = entry.get('key');
				var selectedIntegrationAttempt = this.get('integrationAttempts').findBy('id', entryId);
				if (!Ember.isNone(entryId) && !Ember.isNone(selectedIntegrationAttempt) && entryId !== this.get('_selectedIntegrationAttempt.id')) {
					this.set('_selectedIntegrationAttempt', selectedIntegrationAttempt);
					this.setIntegrationLogs();
				}
			}
		}
	});
});