define('busy-app/models/member-connector', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		organizationId: _emberData.default.attr('string'),
		memberConnectorId: _emberData.default.attr('string'),
		syncTimeEntries: _emberData.default.attr('boolean'),
		lastTimeEntrySyncDate: _emberData.default.attr('number'),
		mappingConfirmation: _emberData.default.attr('boolean'),
		createdOn: _emberData.default.attr('number'),
		updatedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		member: null,
		employeePayrollItemConnectors: null,

		regularItems: Ember.computed('employeePayrollItemConnectors.@each.wageType', function () {
			return this.get('employeePayrollItemConnectors').filterBy('wageType', 'HourlyRegular');
		}),

		overtimeItems: Ember.computed('employeePayrollItemConnectors.@each.wageType', function () {
			return this.get('employeePayrollItemConnectors').filterBy('wageType', 'HourlyOvertime');
		}),

		salaryItems: Ember.computed('employeePayrollItemConnectors.@each.wageType', function () {
			return this.get('employeePayrollItemConnectors').filterBy('wageType', 'SalaryRegular');
		}),

		hasHourlyItems: Ember.computed('regularItems', 'overtimeItems', function () {
			if (this.get('regularItems.length') > 0 || this.get('overtimeItems.length') > 0) {
				return true;
			}
			return false;
		}),

		hasSalaryItems: Ember.computed('salaryItems', function () {
			return this.get('salaryItems.length') > 0;
		}),

		hasPayrollItems: Ember.computed('hasHourlyItems', 'hasSalaryItems', function () {
			return this.get('hasHourlyItems') || this.get('hasSalaryItems');
		}),

		regularWageType: Ember.computed('employeePayrollItemConnectors.@each.isRegular', function () {
			return this.get('employeePayrollItemConnectors').findBy('isRegular', true);
		}),

		regularWageTypeName: Ember.computed('regularWageType.wageName', function () {
			return this.get('regularWageType.wageName');
		}),

		overtimeWageType: Ember.computed('employeePayrollItemConnectors.@each.isOverttime', function () {
			return this.get('employeePayrollItemConnectors').findBy('isOverttime', true);
		}),

		overtimeWageTypeName: Ember.computed('overtimeWageType.wageName', function () {
			return this.get('overtimeWageType.wageName');
		}),

		doubletimeWageType: Ember.computed('employeePayrollItemConnectors.@each.doubletime', function () {
			return this.get('employeePayrollItemConnectors').findBy('isDoubletime', true);
		}),

		doubletimeWageTypeName: Ember.computed('doubletimeWageType.wageName', function () {
			return this.get('doubletimeWageType.wageName');
		}),

		salaryWageType: Ember.computed('employeePayrollItemConnectors.@each.isSalary', function () {
			return this.get('employeePayrollItemConnectors').findBy('isSalary', true);
		}),

		salaryWageTypeName: Ember.computed('salaryWageType.wageName', function () {
			return this.get('salaryWageType.wageName');
		})
	});
});