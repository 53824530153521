define('busy-app/components/g-map/overlay', ['exports', 'ember-google-maps/components/g-map/overlay'], function (exports, _overlay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _overlay.default;
    }
  });
});