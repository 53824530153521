define('busy-app/models/chargify-customer-statement', ['exports', 'ember-data', '@busy-web/data'], function (exports, _emberData, _data) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr;
	exports.default = Model.extend(_data.RPCModelMixin, {
		_clientName: 'statement-rpc',
		_methodName: 'get-statements',

		// arguments
		// pageSize: attr('number', { defaultValue: 6 }),
		// pageNum: attr('number', { defaultValue: 1 }),

		usage: attr('number'), // number of active users
		amount: attr('number'),
		createdOnDate: attr('number'),
		paidOnDate: attr('number'),
		periodStartDate: attr('number'),
		periodEndDate: attr('number'),

		amountString: Ember.computed('amount', function () {
			if (!Ember.isNone(this.get('amount'))) {
				var amount = this.get('amount') / 100;
				amount = amount.toFixed(2);
				return '$' + amount;
			}
		})
	});
});