define('busy-app/models/project-hours-budget', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		projectId: _emberData.default.attr('string'),
		budgetSeconds: _emberData.default.attr('number', { defaultValue: 0 }),
		createdOn: _emberData.default.attr('string'),
		updatedOn: _emberData.default.attr('string'),
		deletedOn: _emberData.default.attr('string'),
		submittedOn: _emberData.default.attr('string'),

		project: _emberData.default.belongsTo('project'),

		seconds: Ember.computed('budgetSeconds', function () {
			var seconds = parseFloat(this.get('budgetSeconds'));
			return seconds;
		}),

		hours: Ember.computed('seconds', function () {
			return (this.get('seconds') / 3600).toFixed(2);
		})
	});
});