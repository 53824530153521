define('busy-app/components/time/pay-period/list', ['exports', '@busy-web/utils', 'busy-app/mixins/pay-period-loader'], function (exports, _utils, _payPeriodLoader) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_payPeriodLoader.default, {
		classNames: ['c-time-card-list'],

		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		//payPeriods: service('pay-period'),
		signature: Ember.inject.service('signature'),

		member: null,
		scrollElement: '.index-scrolling-list > section',

		initialPageSize: 10,
		extendedPageSize: 30,
		openEntries: null,
		headerList: null,
		isArchived: false,
		dirtyFlag: false,

		organization: null,
		isLoadingSignatureState: false,
		closedPayPeriod: null,

		init: function init() {
			this._super();

			this.set('isLoading', true);

			this.set('selectMenu', [Ember.Object.create({ key: 'new-time-entry', value: (0, _utils.loc)('New Time Entry') }), Ember.Object.create({ key: 'new-time-off', value: (0, _utils.loc)('Add Time Off') })]);

			this.set('headerList', [(0, _utils.loc)("Pay Period"), (0, _utils.loc)("Reg Time"), (0, _utils.loc)("Overtime"), (0, _utils.loc)("Doubletime"), (0, _utils.loc)("Total")]);

			this.set('organization', this.get('auth.organization.content'));
		},
		didInsertElement: function didInsertElement() {
			this._super();
			this.getPayPeriods();
		},


		payPeriodSeconds: Ember.computed('openPayPeriods.[]', 'model.[]', function () {
			return this.get('unionedPayPeriodSeconds');
		}),

		/**
   * loads the initail data for showing ten
   * rows and the load more button
   *
   * @method getPayPeriods
   */
		getPayPeriods: function getPayPeriods() {
			var _this = this;

			var memberId = this.get('member.id');
			if (!Ember.isNone(memberId)) {
				// load pay periods here
				this.getPayPeriodMemberSeconds(memberId, this.get('initialPageSize'), true).then(function () {
					if (!_this.get('isDestroyed')) {
						// set autoload to true if no more results can be found to
						// hide the load more button.
						_this.set('autoLoad', !_this.get('hasLoadMore'));
					}
				});
			} else {
				this.addObserver('member.id', this, 'getPayPeriods');
			}
		},
		getPayPeriodMemberSeconds: function getPayPeriodMemberSeconds(memberId) {
			var pageSize = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
			var autoLoad = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

			_utils.Assert.funcNumArgs(arguments, 3);
			_utils.Assert.isUUID(memberId);
			_utils.Assert.isNumber(pageSize);
			_utils.Assert.isBoolean(autoLoad);

			// use scroll loaded load method.
			// this call is only used to setup the inital load and the call
			// to start the auto loader. The loaded will handle all calls beyond that.
			return this.load(memberId, 0, pageSize, true, autoLoad);
		},


		/**
   * Event callback in scroll loader gets called after the
   * models have loaded.
   *
   */
		_afterLoad: Ember.on('afterLoad', function (models) {
			// eslint-disable-line ember/no-on-calls-in-components
			// handle addition loading after pay period is loaded.
			this.setMemberTimeDocuments(models);
		}),

		/**
   * generate pay periods to cover all open entries
   *
   * @property openPayPeriods
   */
		//	openPayPeriods: computed('openEntries', function() {
		//		const openEntries = this.get('openEntries');
		//		if (isNone(openEntries)) {
		//			return [];
		//		}
		//
		//		const mockedOpenPayPeriods = openEntries.map(entry => {
		//			return this.getMockedPayPeriod(entry.get('startTime'));
		//		});
		//
		//		return mockedOpenPayPeriods;
		//	}),

		//	getMockedPayPeriod(startTime) {
		//		Assert.isNumber(startTime);
		//
		//		const payPeriod = this.get('payPeriod').getPayPeriod(startTime);
		//
		//		// matchup with expected properties
		//		const model = this.get('store').createRecord('pay-period-member-seconds', {
		//			startTime: payPeriod.get('startDate').unix(),
		//			endTime: payPeriod.get('endDate').unix(),
		//			regularSeconds: 0,
		//			overtimeSeconds: 0,
		//			doubletimeSeconds: 0,
		//		});
		//
		//		return model;
		//	},

		/**
   * get the pay periods with open entries not covered in the main payPeriodSeconds recordset
   *
   * @property missingOpenPayPeriods
   */
		//	missingOpenPayPeriods: computed('openPayPeriods.[]', 'model.[]', function() {
		//		const openPayPeriods = this.get('openPayPeriods');
		//		const closedPayPeriodSeconds = this.get('model');
		//
		//		// no openPayPeriods, short circuit
		//		if (isNone(openPayPeriods)) {
		//			return [];
		//		} else if (isNone(closedPayPeriodSeconds)) { // no closedPayPeriods, no need to merge, just return the openPayPeriods
		//			return openPayPeriods;
		//		}
		//
		//		// remove openPayPeriods already covered by closedPayPeriodSeconds
		//		const missingOpenPayPeriods = openPayPeriods.reject((openPayPeriod) => {
		//			return this.isOpenPeriodCoveredByClosedPeriods(openPayPeriod);
		//		});
		//
		//		return missingOpenPayPeriods;
		//	}),

		//	isOpenPeriodCoveredByClosedPeriods(openPayPeriod) {
		//		return this.get('model').any((closedPayPeriod) => {
		//			return (openPayPeriod.get('start') >= closedPayPeriod.get('startTime') && openPayPeriod.get('end') <= closedPayPeriod.get('endTime'));
		//		});
		//	},

		/**
   * combine closedPayPeriodSeconds and missingOpenPayPeriods, results are unique
   *
   * @property unionedPayPeriodSeconds
   */
		//	unionedPayPeriodSeconds: union('missingOpenPayPeriods.[]', 'model.[]'),

		// payPeriodDateString(payPeriod) {
		// 	const start = payPeriod.get('startDate');
		// 	const end = payPeriod.get('endDate');
		//
		// 	Assert.isMoment(start);
		// 	Assert.isMoment(end);
		//
		// 	const startString = Time.timeFormat(start, 'MMM D');
		// 	const endString = Time.timeFormat(end, 'MMM D, YYYY');
		//
		// 	return `${startString} - ${endString}`;
		// },

		openCard: function openCard(payPeriod) {
			var applicationRoute = Ember.getOwner(this).lookup('route:application');
			var queryParams = {
				start: payPeriod.get('startTime'),
				end: payPeriod.get('endTime'),
				member_id: this.get('member.id')
			};

			return applicationRoute.transitionTo('time-card.pay-period', { queryParams: queryParams });
		},


		/**
   * hides the load more button and loads the scroll loader
   *
   * @method startAutoLoad
   */
		startAutoLoad: function startAutoLoad() {
			// start auto loader
			this.set('autoLoad', true);

			// get the 1st page for the auto loader.
			return this.getPayPeriodMemberSeconds(this.get('member.id'), this.get('extendedPageSize'), true);
		},
		willDestroyElement: function willDestroyElement() {
			if (this.hasObserverFor('member.id')) {
				this.removeObserver('member.id', this, 'getPayPeriods');
			}
		},


		/**
   *	Signatures Feature
   *
   */

		/**
   * If the signatures feature is enabled, attach some properties to determine signature state
   *
   * @private
   * @method setMemberTimeDocuments
   * @param {Models.PayPeriodMemberSeconds[]} payPeriod
   * @return {EmberObject}
   */
		setMemberTimeDocuments: function setMemberTimeDocuments(payPeriods) {
			var _this2 = this;

			if (this.get('features.signatures') && this.get('signature.isTimecardSigningEnabled')) {
				_utils.Assert.funcNumArgs(arguments, 1, true);
				_utils.Assert.isArray(payPeriods);

				if (!Ember.isEmpty(payPeriods)) {
					var queryParams = {
						member_id: this.get('member.id'),
						_gte: { start_time: this.get('organization.signatureDate') }
					};

					// only query against `memberTimeDocument` once, pass this results though as a cache
					this.set('isLoadingSignatureState', true);
					return this.get('signature').queryMemberTimeDocuments(queryParams).then(function (memberTimeDocuments) {
						var signatureStatePromises = payPeriods.map(function (payPeriod) {
							return _this2.setPayPeriodMemberTimeDocument(payPeriod, memberTimeDocuments);
						});
						return Ember.RSVP.all(signatureStatePromises);
					}).finally(function () {
						_this2.set('isLoadingSignatureState', false);
					});
				} else {
					return Ember.RSVP.resolve();
				}
			}
		},
		setPayPeriodMemberTimeDocument: function setPayPeriodMemberTimeDocument(payPeriod, memberTimeDocuments) {
			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.isObject(payPeriod);
			_utils.Assert.isArray(memberTimeDocuments);

			return this.get('signature').getStateForPayPeriod(payPeriod, memberTimeDocuments).then(function (memberTimeDocument) {
				payPeriod.set('memberTimeDocument', memberTimeDocument);
				return payPeriod;
			});
		},


		actions: {
			startAutoLoadAction: function startAutoLoadAction() {
				this.startAutoLoad();
			},
			openCardAction: function openCardAction() {
				this.openCard.apply(this, arguments);
			},
			reloadCardsAction: function reloadCardsAction() {
				this.reloadCards.apply(this, arguments);
			},
			loadMoreAction: function loadMoreAction() {
				this.loadMore.apply(this, arguments);
			},
			viewPDFAction: function viewPDFAction() {
				this.viewPDF.apply(this, arguments);
			},
			viewRequestedSignatureAction: function viewRequestedSignatureAction() {
				this.viewRequestedSignature.apply(this, arguments);
			},
			viewSignedPDFAction: function viewSignedPDFAction() {
				this.viewSignedPDF.apply(this, arguments);
			},
			setTimeTypeDecAction: function setTimeTypeDecAction() {
				this.setTimeTypeDec.apply(this, arguments);
			},
			setTimeTypeHMAction: function setTimeTypeHMAction() {
				this.setTimeTypeHM.apply(this, arguments);
			},
			selectItemAction: function selectItemAction(item) {
				if (item.get('key') === 'new-time-entry') {
					this.set('showNewTimeEntryDialog', true);
				} else if (item.get('key') === 'new-time-off') {
					this.set('isDisabled', false);
					this.set('showNewTimeOffDialog', true);
				}
			},
			closeNewTimeEntryDialog: function closeNewTimeEntryDialog() {
				this.set('showNewTimeEntryDialog', false);
			},
			closeNewTimeOffDialog: function closeNewTimeOffDialog() {
				this.set('showNewTimeOffDialog', false);
			},
			reloadModel: function reloadModel() {
				this.getPayPeriods();
			},
			reloadSync: function reloadSync() {
				this.set('dirtyFlag', true);
			}
		}
	});
});