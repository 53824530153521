define('busy-app/helpers/add', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.add = add;
	function add(params) {
		var args = params.slice(0); //Array.prototype.slice.call(params, 0);
		if (args.length === 1 && Array.isArray(args[0])) {
			args = args[0];
		}
		return args.reduce(function (a, b) {
			return a + b;
		}, 0);
	} /**
    * @module helpers
    *
    */
	exports.default = Ember.Helper.helper(add);
});