define('busy-app/utils/models/collections/time-entry-range', ['exports', 'busy-app/utils/models/collections/base', '@busy-web/utils', 'busy-app/utils/time-entry'], function (exports, _base, _utils, _timeEntry) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		start: null,
		end: null,

		startTime: null,
		endTime: null,

		regularSeconds: 0,
		overtimeSeconds: 0,
		doubletimeSeconds: 0,
		breaktimeSeconds: 0,

		includeSegments: false,
		modifiedSegments: false,

		applyData: function applyData(model, start, end) {
			Ember.set(this, 'start', start);
			Ember.set(this, 'end', end);
			Ember.set(this, 'includeSegments', true);

			this._super(model);
			this.setup();
		},


		dayString: Ember.computed('startTime', function () {
			var date = '';
			if (!Ember.isNone(this.get('startTime'))) {
				var start = this.get('startTime');
				if (start < this.get('start')) {
					start = this.get('start');
				}
				date = _utils.Time.timeFormat(start, 'ddd, MMM DD');
			}
			return date;
		}),

		dateString: Ember.computed('startTime', 'endTime', 'modifiedSegments', function () {
			var date = '';
			if (!Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
				var format = 'h:mm A';
				if (_utils.Time.date(this.get('endTime') - 1).date() !== _utils.Time.date(this.get('startTime')).date()) {
					format = 'MMM Do h:mm A';
				}

				date = _timeEntry.default.timeRangeString(this, format);
				if (this.get('modifiedSegments')) {
					date = '*' + date;
				}
			}
			return date;
		}),

		hasConflict: Ember.computed('model.hasConflict', function () {
			return this.get('model.hasConflict');
		}),

		startDateString: Ember.computed('startTime', 'endTime', 'modifiedSegments', function () {
			var date = '';
			if (!Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
				var format = 'h:mm A';
				if (_utils.Time.date(this.get('endTime') - 1).date() !== _utils.Time.date(this.get('startTime')).date()) {
					format = 'MMM Do h:mm A';
				}

				date = _timeEntry.default.startTimeString(this, format);
				if (this.get('modifiedSegments')) {
					date = '*' + date;
				}
			}
			return date;
		}),

		endDateString: Ember.computed('startTime', 'endTime', 'modifiedSegments', function () {
			var date = '';
			if (!Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
				var format = 'h:mm A';
				if (_utils.Time.date(this.get('endTime') - 1).date() !== _utils.Time.date(this.get('startTime')).date()) {
					format = 'MMM Do h:mm A';
				}
				date = _timeEntry.default.endTimeString(this, format);
			}
			return date;
		}),

		/**
   * @property regularTimeString
   * @type string
   */
		regularTimeString: Ember.computed('regularSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('regularSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		totalTime: Ember.computed('regularSeconds', 'overtimeSeconds', 'doubletimeSeconds', function () {
			return this.get('regularSeconds') + this.get('overtimeSeconds') + this.get('doubletimeSeconds');
		}),

		/**
   * @property totalTimeString
   * @type string
   */
		totalTimeString: Ember.computed('totalTime', function () {
			var time = _utils.Time.convertSeconds(this.get('totalTime'), true);
			return time.hours + ':' + time.minutes;
		}),

		/**
   * @property breakTimeString
   * @type string
   */
		breakTimeString: Ember.computed('breaktimeSeconds', function () {
			var time = _utils.Time.convertSeconds(this.get('breaktimeSeconds'), true);
			return time.hours + ':' + time.minutes;
		}),

		setup: Ember.observer('model.id', 'model.totalTime', 'model.breakTime', 'model.segmentTotalSeconds', 'includeSegments', function () {
			if (!Ember.isNone(this.get('model'))) {
				this.setupTime();
				if (this.get('includeSegments')) {
					this.calculateSegmentTime();
				} else {
					this.calculateTime();
				}
			}
		}),

		setupTime: function setupTime() {
			var model = this.get('model');
			if (!Ember.isNone(model) && !Ember.isNone(this.get('start')) && !Ember.isNone(this.get('end'))) {
				this.set('offset', model.get('offset'));
				this.set('metaOffset', model.get('metaOffset'));
				this.set('daylightSavingTime', model.get('daylightSavingTime'));
				this.set('metaDaylightSavingTime', model.get('metaDaylightSavingTime'));

				var timeObj = _timeEntry.default.timeByRange(model, this.get('start'), this.get('end'));

				this.set('startTime', timeObj.startTime);
				this.set('endTime', timeObj.endTime);
			}
		},
		calculateTime: function calculateTime() {
			if (!Ember.isNone(this.get('model')) && !Ember.isNone(this.get('startTime')) && !Ember.isNone(this.get('endTime'))) {
				var startTime = this.get('startTime');
				var endTime = this.get('endTime');
				var breakTimeObj = _timeEntry.default.totalBreakTime(this.get('model'), startTime, endTime);
				var breaktime = breakTimeObj.total;
				var regular = endTime - startTime - breaktime;

				this.set('breaktimeSeconds', breaktime);
				this.set('regularSeconds', regular);
			}
		},
		calculateSegmentTime: function calculateSegmentTime() {
			var start = this.get('startTime');
			var end = this.get('endTime');
			var model = this.get('model');

			var regular = 0;
			var overtime = 0;
			var doubletime = 0;
			var breakTime = 0;
			var removedSegments = false;

			if (model.get('segmentTotalSeconds') > 0 && !Ember.isEmpty(model.get('segments'))) {
				model.get('segments').forEach(function (item) {
					if (item.get('startTime') >= start && item.get('startTime') < end || item.get('endTime') > start && item.get('endTime') <= end) {
						regular += item.get('regularSeconds');
						overtime += item.get('overtimeSeconds');
						doubletime += item.get('doubletimeSeconds');
						breakTime += item.get('breakSeconds');
					} else {
						removedSegments = true;
					}
				});
			}

			this.set('modifiedSegments', removedSegments);
			this.set('regularSeconds', regular);
			this.set('overtimeSeconds', overtime);
			this.set('doubletimeSeconds', doubletime);
			this.set('breaktimeSeconds', breakTime);
		}
	});
});