define('busy-app/components/time/time-entry/employee-list', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-time-entry-employee-list'],

		store: Ember.inject.service('store'),
		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),

		model: null,
		start: null,
		end: null,
		isDirty: false,
		groupByMember: false,
		gbmModel: null,
		unassignedProjects: false,
		equipmentHeader: false,
		headerTitle: null,

		member: null,
		project: null,
		equipment: null,

		isLoading: false,

		editEntry: null,
		editTimeOff: null,

		init: function init() {
			this._super();
			this.loadTimeEntries();

			if (Ember.isNone(this.get('headerTitle'))) {
				this.set('headerTitle', 'Time Entries');
			}
		},


		dateRangeString: Ember.computed('start', 'end', function () {
			var range = '';
			if (!Ember.isNone(this.get('start')) && !Ember.isNone(this.get('end'))) {
				var start = _utils.Time.date(this.get('start'));
				var end = _utils.Time.date(this.get('end'));
				var format = 'MMM DD';
				if (start.year() !== end.year()) {
					format = 'MMM DD, YYYY';
				}
				range = start.format(format) + ' - ' + end.format('MMM DD, YYYY');
			}
			return range;
		}),

		isCustomDate: Ember.computed('start', function () {
			return this.get('start') === 0 ? false : true;
		}),

		loadTimeEntries: Ember.observer('start', 'end', 'member.id', 'project.id', 'equipment.equipment.id', function () {
			if (!this.get('isDestroyed')) {
				this.set('isDirty', false);
				this.findTimeEntries();
			}
		}),

		findTimeEntries: function findTimeEntries() {
			var _this = this;

			var start = this.get('start');
			var end = this.get('end');

			if (Ember.isNone(start) && Ember.isNone(end)) {
				start = 0;
				end = _utils.Time.date().endOf('day').unix();

				this.set('start', start);
				this.set('end', end);
			}

			if (!Ember.isNone(start) && !Ember.isNone(end)) {
				this.set('isLoading', true);

				return Ember.RSVP.hash({
					models: this.loadTimeEntryModels(start, end),
					groupMembers: this.loadGroupMembers()
				}).then(function (_ref) {
					var models = _ref.models,
					    groupMembers = _ref.groupMembers;

					if (!_this.get('isDestroyed')) {
						// attach the member to each of the time-off entries so that the edit dialog works
						var member = _this.get('member');

						if (Ember.get(_this, 'auth.useGroupPermissionScoping')) {
							models = _this.applyGroupPermissionScoping(models, groupMembers);
						}

						if (!Ember.isNone(member)) {
							models.forEach(function (model) {
								model.get('model').forEach(function (item) {
									// set the member if the member is not already set.
									if (Ember.isNone(item.get('model.member'))) {
										item.set('model.member', member);
									}
								});
							});
						}
						_this.set('model', models);
					}
				}).finally(function () {
					if (!_this.get('isDestroyed')) {
						_this.set('isLoading', false);
					}
				});
			}
		},
		loadTimeEntryModels: function loadTimeEntryModels(start, end) {
			var exclusive = false;

			var projectId = this.get('project.id');
			if (this.get('project.isUnassigned')) {
				projectId = '_-NULL-_';

				if (!Ember.isNone(this.get('project.parentProjectId'))) {
					projectId = this.get('project.parentProjectId');
					exclusive = true;
				}
			}

			if (this.get('unassignedProjects')) {
				exclusive = true;
			}

			return this.get('store').manager('time-entry-pto-day', start, end, this.get('member.id'), projectId, null, this.get('equipment.equipment.id'), exclusive, 'desc');
		},
		loadGroupMembers: function loadGroupMembers() {
			var member = Ember.get(this, 'auth.member');

			// short circuit in test mode
			if (Ember.isNone(member)) {
				return Ember.RSVP.resolve([]);
			}

			var memberGroupId = Ember.get(member, 'memberGroupId');

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				return Ember.isNone(memberGroupId) ? Ember.RSVP.resolve([member]) : this.get('store').findAll('member', { member_group_id: memberGroupId });
			} else {
				return Ember.RSVP.resolve([]);
			}
		},
		applyGroupPermissionScoping: function applyGroupPermissionScoping(models, groupMembers) {
			var groupMemberIds = new Set(groupMembers.mapBy('id'));

			// only show days that have time entries to display
			return models.filter(function (day) {

				// only allow access to time entries for members who are in the users group
				var dayTimeEntries = Ember.get(day, 'model').filter(function (timeEntryData) {
					var memberId = Ember.get(timeEntryData, 'model.memberId');
					var isMemberInGroup = groupMemberIds.has(memberId);

					return isMemberInGroup;
				});

				Ember.set(day, 'model', dayTimeEntries);

				return Ember.get(day, 'model.length');
			});
		},


		groupedByMember: Ember.observer('groupByMember', 'model', function () {
			var model = this.get('model');
			if (this.get('groupByMember') && !Ember.isNone(model)) {
				model.forEach(function (item) {
					var groupedResult = [];
					item.get('model').forEach(function (entry) {
						var member = groupedResult.findBy('member.id', entry.get('model.member.id'));
						if (!member) {
							member = Ember.Object.create({
								member: entry.get('model.member'),
								contents: []
							});
							groupedResult.pushObject(member);
						}
						member.get('contents').pushObject(entry);
					});
					item.set('groupedResult', groupedResult);
				});
			}
		}),

		actions: {
			savedAction: function savedAction() {
				this.set('isDirty', true);
				this.findTimeEntries();
			},
			closeAction: function closeAction() {
				if (this.get('isDirty')) {
					this.sendAction('onChanged');
				}
				this.sendAction('onClose');
			},
			openTimeOffEntry: function openTimeOffEntry(entry, member) {
				if (this.get('access').canTakeActionFor('manageTimeEntries', member)) {
					this.set('editTimeOff', entry);
				}
			},
			openTimeEntry: function openTimeEntry(entry, member) {
				if (this.get('access').canTakeActionFor('manageTimeEntries', member)) {
					this.set('editEntry', entry);
				}
			},
			cancelEdit: function cancelEdit() {
				if (!Ember.isNone(this.get('editTimeOff'))) {
					this.get('editTimeOff').rollbackAttributes();
				}
				this.set('editEntry', null);
				this.set('editTimeOff', null);
			}
		}
	});
});