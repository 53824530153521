define('busy-app/components/company/integrations/vendors/procore/settings-dialog', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),
		classNames: ['integrations-settings-dialog'],

		/**
   * @public
   * @property integrationName
   * @type {string}
   */
		integrationName: null,

		/**
   * @public
   * @property autoImportEnabledOptions
   * @type {EmberObject[]}
   */
		autoImportEnabledOptions: null,

		/**
   * @public
   * @property error
   * @type {EmberObject}
   */
		error: null,

		/**
   * @private
   * @property _organizationIntegration
   * @type {Models.OrganizationIntegration}
   */
		_organizationIntegration: null,

		/**
   * @private
   * @property _autoImportEnabledValue
   * @type {string}
   */
		_autoImportEnabledValue: 'P1D',

		/**
   * @private
   * @property _autoImportDisabledValue
   * @type {string}
   */
		_autoImportDisabledValue: '_-NULL-_',

		/**
   * @private
   * @method init
   * @constructor
   */
		init: function init() {
			var _this = this;

			this._super();

			this.setOrganizationIntegration().then(function () {
				_this.setAutoImportEnabledOptions();
			});
		},


		/**
   * Get Organization's OrganizationIntegration from the API
   *
   * @public
   * @method getOrganizationIntegration
   * @return {Models.organizationIntegration}
   */
		getOrganizationIntegration: function getOrganizationIntegration() {
			var organization = this.get('auth.organization.content');
			var integrationName = this.get('integrationName');

			return organization.getIntegration(integrationName);
		},


		/**
   * Caches `this.getOrganizationIntegration()` result to `_organizationIntegration`
   *
   * @public
   * @method setOrganizationIntegration
   * @return {Models.organizationIntegration}
   */
		setOrganizationIntegration: function setOrganizationIntegration() {
			var _this2 = this;

			return this.getOrganizationIntegration().then(function (organizationIntegration) {
				_this2.set('_organizationIntegration', organizationIntegration);

				return organizationIntegration;
			});
		},
		setAutoImportEnabledOptions: function setAutoImportEnabledOptions() {
			var currentValue = this.get('_organizationIntegration.frequency');

			var enabledValue = this.get('_autoImportEnabledValue');
			var disabledValue = this.get('_autoImportDisabledValue');

			var options = [Ember.Object.create({
				value: 'Yes',
				key: enabledValue,
				_selected: currentValue === enabledValue
			}), Ember.Object.create({
				value: 'No',
				key: disabledValue,
				_selected: currentValue === disabledValue || Ember.isNone(currentValue)
			})];

			this.set('autoImportEnabledOptions', options);
		},
		saveSettings: function saveSettings() {
			var _this3 = this;

			return this.get('_organizationIntegration').save().then(function (result) {
				_this3.sendAction('onClose');

				return result;
			}).catch(function (err) {
				_this3.set('error', err);

				throw err;
			});
		},


		actions: {
			onSave: function onSave() {
				return this.saveSettings();
			},
			onSaveAndImport: function onSaveAndImport() {
				var _this4 = this;

				return this.saveSettings().then(function () {
					_this4.sendAction('triggerImport');
				});
			},
			onSelectAutoImportOption: function onSelectAutoImportOption(modelSelected) {
				this.set('_organizationIntegration.frequency', modelSelected.key);
			}
		}
	});
});