define('busy-app/models/member-sign-in-answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    memberId: _emberData.default.attr('id'),
    localTime: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    answer: _emberData.default.attr('string'),
    questionTitle: _emberData.default.attr('string'),
    questionDescription: _emberData.default.attr('string'),
    questionPrompt: _emberData.default.attr('string'),
    flagged: _emberData.default.attr('boolean'),
    memberSignInSubmissionId: _emberData.default.attr('id'),
    resolvedOn: _emberData.default.attr('number'),
    resolverId: _emberData.default.attr('id'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    archivedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number')
  });
});