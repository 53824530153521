define('busy-app/components/time/pay-period/time-off-list', ['exports', 'busy-app/mixins/model-loader'], function (exports, _modelLoader) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_modelLoader.default, {
		classNames: ['v-time-off-list'],

		store: Ember.inject.service('store'),

		member: null,

		scrollElement: '.index-scrolling-list > section',
		initialPageSize: 10,
		extendedPageSize: 30,
		isArchived: false,

		didInsertElement: function didInsertElement() {
			this._super();
			this.getTimeOff();
		},
		getTimeOff: function getTimeOff() {
			var _this = this;

			if (!Ember.isNone(this.get('member.id'))) {
				// get query params
				var query = this.getQuery(this.get('initialPageSize'));

				// load time off entries here
				this.get('store').query('time-off', query).then(function (timeOffEntries) {
					// set autoload to true if no more results can be found to
					// hide the load more button.
					_this.set('autoLoad', Ember.isNone(timeOffEntries.get('meta.next')));

					// set models
					_this.set('model', timeOffEntries);
				});
			} else {
				// add an observer to call this method again later.
				this.addObserver('member.id', this, 'getTimeOff');
			}
		},
		getQuery: function getQuery(pageSize) {

			// setup the queryParams
			return {
				member_id: this.get('member.id'),
				_desc: ['date_stamp'],
				page: 1,
				page_size: pageSize
			};
		},


		/**
   * hides the load more button and loads the scroll loader
   *
   * @method startAutoLoad
   */
		startAutoLoad: function startAutoLoad() {
			var query = this.getQuery(this.get('extendedPageSize'));
			this.load('time-off', query, true, true);
		},
		openCard: function openCard(timeOffEntry) {
			var route = Ember.getOwner(this).lookup('route:application');

			route.transitionTo('time-card.time-off-entry', {
				queryParams: {
					member_id: this.get('member.id'),
					id: timeOffEntry.get('id')
				}
			});
		},
		willDestroyElement: function willDestroyElement() {
			if (this.hasObserverFor('member.id')) {
				this.removeObserver('member.id', this, 'getTimeOff');
			}
		},


		actions: {
			startAutoLoadAction: function startAutoLoadAction() {
				this.startAutoLoad();
			},
			getTimeOffAction: function getTimeOffAction() {
				this.getTimeOff();
			},
			openCardAction: function openCardAction() {
				this.openCard.apply(this, arguments);
			},
			reloadCardsAction: function reloadCardsAction() {
				this.reloadCards.apply(this, arguments);
			},
			loadMoreAction: function loadMoreAction() {
				this.loadMore.apply(this, arguments);
			},
			openNewTimeOffDialog: function openNewTimeOffDialog() {
				this.set('isDisabled', false);
				this.set('showNewTimeOffDialog', true);
			},
			closeNewTimeEntryDialog: function closeNewTimeEntryDialog() {
				this.set('showNewTimeEntryDialog', false);
			},
			closeNewTimeOffDialog: function closeNewTimeOffDialog() {
				this.set('showNewTimeOffDialog', false);
			},
			reloadModel: function reloadModel() {
				this.getTimeOff();
			}
		}
	});
});