define('busy-app/models/cost-code-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    organizationId: _emberData.default.attr('string'),
    groupName: _emberData.default.attr('string'),
    createdOn: _emberData.default.attr('number'),
    updatedOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),

    costCodes: _emberData.default.hasMany('cost-code')
  });
});