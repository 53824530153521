define('busy-app/models/member-cost-code-seconds', ['exports', 'ember-data', 'busy-app/utils/unique-id', 'busy-app/models/member-seconds'], function (exports, _emberData, _uniqueId, _memberSeconds) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _memberSeconds.default.extend({
    // used to match seconds and cost models
    uid: (0, _uniqueId.default)('startDate', 'endDate', 'memberId', 'costCodeId'),

    // override attrs
    costCodeId: _emberData.default.attr('id', { sortable: true, sortBy: ['costCode.costCode'] }),

    // override model injections
    costCode: _emberData.default.belongsTo('cost-code', { query: { deleted_on: '_-DISABLE-_' } })
  });
});