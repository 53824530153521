define('busy-app/routes/time-card/time-entry', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		queryParams: {
			id: {
				refreshModel: true
			},
			member_id: {
				refreshModel: true
			}
		},

		model: function model(params) {
			//return this.store.findRecord('time-entry', params.id);

			var getter = this.store.getter().findRecord('time-entry', params.id).join('member', 'time-entry', 'member_id', { deleted_on: '_-NULL-_' }).join('project', 'time-entry', 'project_id', { deleted_on: '_-NULL-_' }).join('cost-code', 'time-entry', 'cost_code_id', { deleted_on: '_-NULL-_' }).join('equipment', 'time-entry', 'equipment_id', { deleted_on: '_-NULL-_' }).join('member-location', 'time-entry', 'start_location_id', { deleted_on: '_-NULL-_' }, 'startLocation').join('member-location', 'time-entry', 'end_location_id', { deleted_on: '_-NULL-_' }, 'endLocation').joinAll('time-entry-break', 'time-entry', 'id', { _asc: ['start_time'], deleted_on: '_-NULL-_' }).join('member-location', 'time-entry-break', 'start_location_id', { deleted_on: '_-NULL-_' }, 'startLocation').join('member-location', 'time-entry-break', 'end_location_id', { deleted_on: '_-NULL-_' }, 'endLocation');

			return getter.fetch().then(function (data) {
				data.timeEntry.get('timeEntryBreaks').forEach(function (item) {
					item.set('timeEntry', data.timeEntry);
				});
				return data.timeEntry;
			});
		}
	});
});