define('busy-app/models/stripe-customer-card', ['exports', 'ember-data', '@busy-web/data'], function (exports, _emberData, _data) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr;
	exports.default = Model.extend(_data.RPCModelMixin, {
		_clientName: 'busy-stripe',

		brand: attr('string'),
		expMonth: attr('number'),
		expYear: attr('number'),
		isCardActive: attr('boolean'),
		last4: attr('string'),
		name: attr('string')
	});
});