define('busy-app/utils/error', ['exports', 'busy-app/utils/logger'], function (exports, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	//import RSVP from 'rsvp';
	//import Settings from './settings';

	var ignoreList = ["TransitionAborted", "Call Aborted", "Not Authorized", "The adapter operation was aborted", "invalid credentials"];

	function ignore() {
		var err = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

		var status = parseInt(err.status, 10);
		if (status && (status === 401 || status === 429)) {
			return true;
		} else if (Ember.isNone(err) || ignoreList.indexOf(err.name) !== -1 || ignoreList.indexOf(err.message) !== -1) {
			return true;
		}
		return false;
	}

	//function getStack(error) {
	//	let stack = error.stack;
	//	let message = error.message;
	//	if (stack && stack.indexOf(message) === -1) {
	//		stack = message + '\n' + stack;
	//	}
	//	return stack;
	//}

	//function logError(err) {
	//	if (err instanceof Error) {
	//		Logger.error(getStack(err));
	//	} else {
	//		Logger.error(err);
	//	}
	//}
	//
	//function logWarn(err) {
	//	if (err instanceof Error) {
	//		Logger.warn(getStack(err));
	//	} else {
	//		Logger.warn(new Error(err));
	//	}
	//}

	exports.default = Ember.Object.extend({

		graylog: Ember.inject.service('graylog'),
		auth: Ember.inject.service('auth'),

		init: function init() {
			this._super();

			//RSVP.on('error', (err) => {
			//	logError(err);
			//if (err) {
			//	err.isRSVP = true;
			//	this.handleError("ERROR_RSVP", err);
			//} else {
			//	Logger.warn('RSVP Error was called with no error object');
			//}
			//});

			//		// pause the err to allow any RSVP errors to fire first
			//		next(() => {
			//			if (err) {
			//				if (!err.isRSVP) {
			//					this.handleError("ERROR_THROWN", err);
			//				}
			//			} else {
			//				Logger.warn('Error was called with no error object');
			//			}
			//		});
			//	};
		},
		handleError: function handleError(type, err) {
			var _this = this;

			if (err.errors) {
				if (err.errors.length) {
					var showErr = false;
					err.errors.forEach(function (error) {
						if (!ignore(error)) {
							showErr = true;
							_logger.default.error(error.title || type, err.message, error);
						} else {
							if (error.status === 401) {
								_this.graylogUnauthorizedEvent();
							}
							_logger.default.warn('Ignore', error.title || type, err.message, error);
						}
					});
					if (showErr) {
						_logger.default.error(err);
					}
				} else {
					if (!ignore(err.errors)) {
						_logger.default.error(err.errors.title || type, err.errors.message, err.errors);
					} else {
						_logger.default.warn('Ignore', err.errors.title || type, err.errors.message, err.errors);
					}
				}
			} else {
				if (!ignore(err)) {
					_logger.default.error(type, err, "\n", err.stack);
				} else {
					_logger.default.warn('Ignore', type, err, err.stack);
				}
			}
		},
		graylogUnauthorizedEvent: function graylogUnauthorizedEvent() {
			this.get('graylog').sendEvent({
				short_message: 'Unauthorized User Event',
				full_message: 'Unauthorized User Event',
				level: 6,
				_member_id: this.get('auth.member.id')
			});
		}
	});
});