define('busy-app/components/company/integrations/vendors/procore/integration-dashboard', ['exports', 'busy-app/components/company/integrations/integration-dashboard', 'busy-app/utils/logger'], function (exports, _integrationDashboard, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _integrationDashboard.default.extend({
		showConnectDialog: false,
		showDisconnectDialog: false,
		showLearnMoreDialog: false,
		showSettingsDialog: false,
		showSyncLogDialog: false,
		showSyncLogErrorsDialog: false,
		showUpgradeDialog: false,

		integrationName: 'procore',

		actions: {

			/**
    * passthroughs to parent
    *
    */
			connectIntegration: function connectIntegration() {
				_logger.default.info('Procore/IntegrationDashboard', 'connectIntegration');

				this.send('closeConnectDialog');

				return this._super();
			},
			disconnectIntegration: function disconnectIntegration() {
				//Logger.info('Procore/IntegrationDashboard', 'disconnectIntegration');

				this.send('closeDisconnectDialog');

				return this._super();
			},
			openSubscriptionPage: function openSubscriptionPage() {
				//Logger.info('Procore/IntegrationDashboard', 'openSubscriptionPage');

				this.send('closeUpgradeDialog');

				return this._super();
			},


			/**
    * Dialog Toggles
    */

			openConnectDialog: function openConnectDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'openConnectDialog');

				this.set('showConnectDialog', true);
			},
			closeConnectDialog: function closeConnectDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'closeConnectDialog');

				this.set('showConnectDialog', false);
			},
			openDisconnectDialog: function openDisconnectDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'openDisconnectDialog');

				this.set('showDisconnectDialog', true);
			},
			closeDisconnectDialog: function closeDisconnectDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'closeDisconnectDialog');

				this.set('showDisconnectDialog', false);
			},
			openLearnMoreDialog: function openLearnMoreDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'openLearnMoreDialog');

				this.set('showLearnMoreDialog', true);
			},
			closeLearnMoreDialog: function closeLearnMoreDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'closeLearnMoreDialog');

				this.set('showLearnMoreDialog', false);
			},
			openSettingsDialog: function openSettingsDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'openSettingsDialog');

				this.set('showSettingsDialog', true);
			},
			closeSettingsDialog: function closeSettingsDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'closeSettingsDialog');

				this.set('showSettingsDialog', false);
			},
			openSyncLogDialog: function openSyncLogDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'openSyncLogDialog');

				this.set('showSyncLogDialog', true);
			},
			closeSyncLogDialog: function closeSyncLogDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'closeSyncLogDialog');

				this.set('showSyncLogDialog', false);
			},
			printSyncLog: function printSyncLog() {
				//Logger.info('Procore/IntegrationDashboard', 'config');

				this.set('showSyncLogDialog', false);
			},
			openSyncLogErrorsDialog: function openSyncLogErrorsDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'openSyncLogErrorsDialog');

				this.set('showSyncLogErrorsDialog', true);
			},
			closeSyncLogErrorsDialog: function closeSyncLogErrorsDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'closeSyncLogErrorsDialog');

				this.set('showSyncLogErrorsDialog', false);
			},
			printSyncLogErrors: function printSyncLogErrors() {
				//Logger.info('Procore/IntegrationDashboard', 'config');

				this.set('showSyncLogErrorsDialog', false);
			},
			openUpgradeDialog: function openUpgradeDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'openUpgradeDialog');

				this.set('showUpgradeDialog', true);
			},
			closeUpgradeDialog: function closeUpgradeDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'closeUpgradeDialog');

				this.set('showUpgradeDialog', false);
			},
			openOauthExternal: function openOauthExternal() {
				//Logger.info('Procore/IntegrationDashboard', 'openOauthCallback');

				this.openOauthExternal();
			},
			openOauthCallback: function openOauthCallback() {
				//Logger.info('Procore/IntegrationDashboard', 'openOauthCallback');

				this.openOauthCallback();
			},


			// multiple actions
			closeConnectAndOpenUpgradeDialog: function closeConnectAndOpenUpgradeDialog() {
				//Logger.info('Procore/IntegrationDashboard', 'closeConnectAndOpenUpgradeDialog');

				this.send('closeConnectDialog');
				this.send('openUpgradeDialog');
			},
			onBack: function onBack() {
				var transitionToUrl = '/company-settings#tab-integrations';
				Ember.getOwner(this).lookup('route:application').transitionTo(transitionToUrl);
				// return window.location = transitionToUrl;
			}
		}
	});
});