define('busy-app/components/dialogs/instructions-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c-dialogs-instructions-section'],

    tagName: 'section',

    title: ''
  });
});