define('busy-app/models/project-info', ['exports', 'ember-data', 'busy-app/utils/maps'], function (exports, _emberData, _maps) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		projectId: _emberData.default.attr('string'),
		customer: _emberData.default.attr('string'),
		number: _emberData.default.attr('string'),
		reminder: _emberData.default.attr('boolean', { defaultValue: false }),
		longitude: _emberData.default.attr('number'),
		latitude: _emberData.default.attr('number'),
		locationRadius: _emberData.default.attr('number'),
		address1: _emberData.default.attr('string'),
		address2: _emberData.default.attr('string'),
		city: _emberData.default.attr('string'),
		county: _emberData.default.attr('string'),
		state: _emberData.default.attr('string'),
		postalCode: _emberData.default.attr('string'),
		requireTimeEntryGps: _emberData.default.attr('string'),
		phone: _emberData.default.attr('string'),
		country: _emberData.default.attr('string'),
		updatedOn: _emberData.default.attr('number'),
		createdOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deleteOn: _emberData.default.attr('number'),

		project: _emberData.default.belongsTo('project'),

		coords: Ember.computed('latitude', 'longitude', function () {
			return [this.get('latitude'), this.get('longitude')];
		}),

		requireGPS: Ember.computed('requireTimeEntryGps', function () {
			var requireTimeEntryGps = this.get('requireTimeEntryGps');
			return requireTimeEntryGps === 'self' || requireTimeEntryGps === 'self_and_children';
		}),

		requireGpsSubProjects: Ember.computed('requireTimeEntryGps', function () {
			var requireTimeEntryGps = this.get('requireTimeEntryGps');
			return requireTimeEntryGps === 'children' || requireTimeEntryGps === 'self_and_children';
		}),

		locationString: Ember.computed('address1', 'city', 'state', 'postalCode', 'country', function () {
			var address = '';
			if (!Ember.isNone(this.get('address1'))) {
				address += this.get('address1') + ', ';
			}

			if (!Ember.isNone(this.get('city'))) {
				address += this.get('city') + ', ';
			}

			if (!Ember.isNone(this.get('state'))) {
				address += this.get('state') + ', ';
			}

			if (!Ember.isNone(this.get('postalCode'))) {
				address += this.get('postalCode') + ', ';
			}

			if (!Ember.isNone(this.get('country'))) {
				address += this.get('country');
			}
			return address.replace(/, $/, '');
		}),

		hasLocation: Ember.computed('longitude', 'latitude', function () {
			if (!Ember.isNone(this.get('longitude')) && !Ember.isNone(this.get('latitude'))) {
				var location = (0, _maps.normalizeLocation)(this);
				if ((0, _maps.isValidLatLng)(location)) {
					return true;
				}
			}
			return false;
		}),

		clearGeolocation: function clearGeolocation() {
			this.setProperties({
				reminder: null,
				locationRadius: null,
				longitude: null,
				latitude: null
			});
		}
	});
});