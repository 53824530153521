define('busy-app/models/pay-period-member-project-cost-code-cost', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		memberId: _emberData.default.attr('string'),
		projectId: _emberData.default.attr('string'),
		costCodeId: _emberData.default.attr('string'),
		startTime: _emberData.default.attr('number'),
		endTime: _emberData.default.attr('number'),
		regularCost: _emberData.default.attr('number', { defaultValue: 0 }),
		overtimeCost: _emberData.default.attr('number', { defaultValue: 0 }),
		doubletimeCost: _emberData.default.attr('number', { defaultValue: 0 }),
		childrenRegularCost: _emberData.default.attr('number', { defaultValue: 0 }),
		childrenOvertimeCost: _emberData.default.attr('number', { defaultValue: 0 }),
		childrenDoubletimeCost: _emberData.default.attr('number', { defaultValue: 0 }),
		updatedOn: _emberData.default.attr('number'),
		submittedOn: _emberData.default.attr('number'),
		deletedOn: _emberData.default.attr('number'),

		totalCost: Ember.computed('regularCost', 'overtimeCost', 'doubletimeCost', function () {
			return this.get('regularCost') + this.get('overtimeCost') + this.get('doubletimeCost');
		}),

		totalRegularCost: Ember.computed('regularCost', 'childrenRegularCost', function () {
			return (this.get('regularCost') || 0) + (this.get('childrenRegularCost') || 0);
		}),

		totalOvertimeCost: Ember.computed('overtimeCost', 'childrenOvertimeCost', function () {
			return (this.get('overtimeCost') || 0) + (this.get('childrenOvertimeCost') || 0);
		}),

		totalDoubletimeCost: Ember.computed('doubletimeCost', 'childrenDoubletimeCost', function () {
			return (this.get('doubletimeCost') || 0) + (this.get('childrenDoubletimeCost') || 0);
		}),

		totalTotalCost: Ember.computed('totalRegularCost', 'totalOvertimeCost', 'totalDoubletimeCost', function () {
			return this.get('totalRegularCost') + this.get('totalOvertimeCost') + this.get('totalDoubletimeCost');
		})
	});
});