define('busy-app/utils/request-manager', ['exports', 'busy-app/utils/request-handler', 'busy-app/utils/request-helpers', '@busy-web/utils'], function (exports, _requestHandler, _requestHelpers, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	var getModelProperty = _requestHelpers.default.getModelProperty,
	    setModelProperty = _requestHelpers.default.setModelProperty,
	    generateModelPath = _requestHelpers.default.generateModelPath,
	    generateId = _requestHelpers.default.generateId,
	    mergeObject = _requestHelpers.default.mergeObject;


	function buildDataObject(toObject, fromObject) {
		if (Object.keys(fromObject).length === 0) {
			return toObject;
		}

		for (var i in fromObject) {
			if (fromObject.hasOwnProperty(i)) {
				var base = i.split('.');
				base.pop();
				base = base.join('.');
				if (getModelProperty(toObject, base)) {
					setModelProperty(toObject, i, getModelProperty(fromObject, i));
					delete fromObject[i];
				}
			}
		}
		return buildDataObject(toObject, fromObject);
	}

	/**
  * `Util\Manager`
  *
  */
	exports.default = Ember.Object.extend({
		store: null,
		operations: null,

		__collection: null,
		__storedOperations: null,

		__lastOperation: null,
		__nestedOperation: null,

		query: function query(modelType) {
			var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
			var alias = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

			this.addOperation('query', modelType, { query: query }, alias);
			return this;
		},
		queryRecord: function queryRecord(modelType) {
			var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
			var alias = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

			this.addOperation('queryRecord', modelType, { query: query }, alias);
			return this;
		},
		findAll: function findAll(modelType) {
			var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
			var alias = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

			this.addOperation('findAll', modelType, { query: query }, alias);
			return this;
		},
		findWhereIn: function findWhereIn(modelType, key, id) {
			var query = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
			var alias = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

			this.addOperation('findWhereIn', modelType, { key: key, id: id, query: query }, alias);
			return this;
		},
		findRecord: function findRecord(modelType, id, alias) {
			this.addOperation('findRecord', modelType, { id: id }, alias);
			return this;
		},
		outerJoin: function outerJoin(modelType, join, joinOn, query, alias) {
			this.addOperation('outerJoin', modelType, { join: join, joinOn: joinOn, query: query }, alias);
			return this;
		},
		outerJoinAll: function outerJoinAll(modelType, join, joinOn, query, alias) {
			this.addOperation('outerJoinAll', modelType, { join: join, joinOn: joinOn, query: query }, alias);
			return this;
		},
		join: function join(modelType, _join, joinOn, query, alias) {
			this.addOperation('join', modelType, { join: _join, joinOn: joinOn, query: query }, alias);
			return this;
		},
		joinAll: function joinAll(modelType, join, joinOn, query, alias) {
			this.addOperation('joinAll', modelType, { join: join, joinOn: joinOn, query: query }, alias);
			return this;
		},
		callJoinFromModel: function callJoinFromModel(lastOperation, key) {
			_utils.Assert.funcNumArgs(arguments, 2, true);
			_utils.Assert.test('You must have a record type to call a `get` join model for that record', !Ember.isNone(lastOperation));

			// get the join data from the last operations model
			var owner = Ember.getOwner(this);
			var modelName = lastOperation.get('modelType');
			var model = owner.factoryFor('model:' + Ember.String.dasherize(modelName));
			var meta = model.metaForProperty(key);
			var opts = meta.join;

			// throw error if not a valid join object
			_utils.Assert.test('get did not find a valid join param at key [' + key + ']', !Ember.isNone(opts) && (typeof opts === 'undefined' ? 'undefined' : _typeof(opts)) === 'object' && opts._isJoin === true);

			this[opts.method].call(this, opts.modelType, modelName, opts.joinOn, opts.query, key);
		},
		get: function get(key) {
			_utils.Assert.funcNumArgs(arguments, 1, true);

			// get the last operation to join onto
			var lastOperation = this.__lastOperation;

			// call method to add join
			this.callJoinFromModel(lastOperation, key);

			this.__lastOperation = lastOperation;
			return this;
		},
		beginGet: function beginGet(key) {
			_utils.Assert.funcNumArgs(arguments, 1, true);

			// get the last operation to join onto
			var lastOperation = this.__lastOperation;

			// call method to add join
			this.callJoinFromModel(lastOperation, key);

			// if no nested create new nested array map
			if (Ember.isNone(this.__nestedOperation)) {
				this.__nestedOperation = [];
			}

			// push the last operation to the nested map
			this.__nestedOperation.push(lastOperation);

			return this;
		},
		endGet: function endGet() {
			// pop the lastoperation off the nested map
			this.__lastOperation = this.__nestedOperation.pop();

			// if the array is empty set it back to null
			if (Ember.isEmpty(this.__nestedOperation)) {
				this.__nestedOperation = null;
			}

			return this;
		},
		collection: function collection(type) {
			var owner = Ember.getOwner(this);
			var Collection = owner.factoryFor('collection:' + Ember.String.dasherize(type));
			var collection = Collection.create({
				store: this.store,
				manager: this,
				getter: this,
				content: Ember.A()
			});

			this.__collection = collection;

			return this;
		},
		polymorph: function polymorph(type) {
			var owner = Ember.getOwner(this);
			var Polymorph = owner.factoryFor('polymorph:' + Ember.String.dasherize(type));
			var polymorph = Polymorph.create({
				store: this.store,
				manager: this,
				getter: this,
				id: generateId(),
				_name: type
			});

			this.__polymorph = polymorph;

			return this;
		},
		clearPolymorph: function clearPolymorph() {
			this.__polymorph = null;
			return this;
		},
		addOperation: function addOperation(operationType, modelType, params, alias) {
			var id = generateId();
			if (Ember.isEmpty(alias)) {
				alias = modelType;
			}

			if (params.join) {
				var join = this.__storedOperations.findBy('alias', params.join);
				params.joinModel = Ember.get(join, 'modelType');
			}

			var operation = Ember.Object.create({ id: id, alias: alias, params: params, operationType: operationType, modelType: modelType, polymorph: this.__polymorph });
			this.operations.pushObject(operation);
			this.__storedOperations.pushObject(Ember.Object.create({ id: id, alias: alias, params: params, operationType: operationType, modelType: modelType, polymorph: this.__polymorph }));
			this.__lastOperation = operation;
		},
		reloadOperations: function reloadOperations() {
			var _this = this;

			this.__storedOperations.forEach(function (item) {
				_this.operations.pushObject(Ember.Object.create({
					id: item.get('id'),
					operationType: item.get('operationType'),
					modelType: item.get('modelType'),
					alias: item.get('alias'),
					params: item.get('params'),
					polymorph: item.get('polymorph')
				}));
			});
		},
		update: function update() {
			this.reloadOperations();
			return this.fetch();
		},
		applyPolymorphs: function applyPolymorphs(data) {
			var operations = Ember.get(this, '__storedOperations');
			var dataMap = {};
			operations.forEach(function (item) {
				var type = item.get('modelType');
				var parentType = item.get('query.join');
				var path = generateModelPath(parentType, type);

				var polymorph = item.get('polymorph');
				if (!Ember.isNone(polymorph)) {
					var model = getModelProperty(data, path);
					setModelProperty(data, path, null);

					var polyName = generateModelPath(parentType, polymorph.get('_name'));
					if (getModelProperty(dataMap, polyName) === undefined) {
						setModelProperty(dataMap, polyName, polymorph);
					}
					setModelProperty(dataMap, generateModelPath(polyName, type), model);
				}
			});

			for (var i in data) {
				if (data.hasOwnProperty(i)) {
					if (data[i] === null) {
						delete data[i];
					}
				}
			}
			return buildDataObject(data, dataMap);
		},
		fetch: function fetch() {
			var _this2 = this;

			var args = arguments;
			var requester = _requestHandler.default.create({ store: this.store, finishedList: Ember.A() });
			var operations = Ember.get(this, 'operations');
			if (operations.length === 0) {
				return this.__collection.model.apply(this.__collection, args);
			} else {
				return this.__fetch(requester, operations).then(function (data) {
					var polyData = _this2.applyPolymorphs(data);
					if (!Ember.isNone(_this2.__collection)) {
						return _this2.__collection.populateModels(polyData);
					} else {
						return polyData;
					}
				});
			}
		},
		__fetch: function __fetch(req, operations, parents, tries) {
			var _this3 = this;

			parents = parents || {};
			tries = tries || 0;

			var length = operations.get('length');
			if (length === 0 || tries >= 100) {
				return Ember.RSVP.resolve(parents);
			}

			return req.buildRequest(operations, parents).then(function (data) {
				if (!Ember.isNone(data)) {
					mergeObject(parents, data);
				}
				return _this3.__fetch(req, operations, parents, ++tries);
			});
		}
	});
});