define('busy-app/controllers/company-settings/procore/callback', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['code'],
		code: null
	});
});