define('busy-app/controllers/report/payroll', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: [],

		auth: Ember.inject.service('auth'),
		payPeriods: Ember.inject.service('pay-period'),
		proFeature: Ember.inject.service('pro-feature'),
		features: Ember.inject.service(),
		subscription: Ember.inject.service(),
		discovery: Ember.inject.service(),

		type: 'member',

		start: null,
		currentPeriodStart: null,

		regHoursCol: true,
		otHoursCol: true,
		ot2HoursCol: true,
		totalHoursCol: true,
		totalWagesCol: true,

		employeeIdCol: true,
		wageCol: true,
		laborBurdenCol: true,

		isCSVLoading: false,

		optionSelector: null,
		columnArray: null,

		proFeatureManager: null,
		isFreeAccount: null,

		init: function init() {
			this._super();

			this.setColumnArray();
			this.setPeriod();

			this.set('proFeatureManager', this.get('proFeature').getManager());

			if (this.get('subscription.isFreeAccount')) {
				this.set('isFreeAccount', this.get('subscription.isFreeAccount'));
			} else {
				this.set('isFreeAccount', false);
			}
		},


		setPeriod: Ember.observer('start', 'auth.organizationPayPeriod.@each.id', function () {
			if (Ember.isNone(this.get('start')) && !Ember.isEmpty(this.get('auth.organizationPayPeriod'))) {
				var start = _utils.Time.date().startOf('day').unix();

				var currentPeriod = this.get('payPeriods').getPayPeriod(start);
				this.set('currentPeriodStart', currentPeriod.get('start'));

				var previousPeriod = this.get('payPeriods').getPayPeriod(currentPeriod.get('startDate').subtract(1, 'days').unix());
				this.set('start', previousPeriod.get('start'));
			}
		}),

		end: Ember.computed('start', function () {
			var time = null;
			if (!Ember.isNone(this.get('start'))) {
				var payPeriod = this.get('payPeriods').getPayPeriod(this.get('start'));
				time = payPeriod.get('end');
			}
			return time;
		}),

		setColumnArray: Ember.observer('type', function () {
			var exportMacroCsv = Ember.get(this, 'features.exportMacroCsv');

			if (this.get('type') === 'member' || this.get('type') === 'member-project') {
				this.set('columnArray', [Ember.Object.create({ key: 'regHoursCol', title: (0, _utils.loc)('Reg Hours'), value: true }), Ember.Object.create({ key: 'otHoursCol', title: (0, _utils.loc)('O.T. Hours (x1.5)'), value: true }), Ember.Object.create({ key: 'ot2HoursCol', title: (0, _utils.loc)('O.T. Hours (x2)'), value: true }), Ember.Object.create({ key: 'totalHoursCol', title: (0, _utils.loc)('Total Hours'), value: true }), Ember.Object.create({ key: 'totalWagesCol', title: (0, _utils.loc)('Est. Cost'), value: true }), Ember.Object.create({ key: 'employeeIdCol', title: (0, _utils.loc)('Employee Id'), value: false }), Ember.Object.create({ key: 'wageCol', title: (0, _utils.loc)('Wage'), value: false }), Ember.Object.create({ key: 'laborBurdenCol', title: (0, _utils.loc)('Labor Burden'), value: false })]);

				//This select Menu is in the Header
				this.set('optionSelector', [Ember.Object.create({ key: 'export-basic', value: (0, _utils.loc)('Basic') }), exportMacroCsv ? Ember.Object.create({ key: 'export-advanced', value: (0, _utils.loc)('Advanced') }) : null, Ember.Object.create({ key: 'export-daily', value: (0, _utils.loc)('Daily') }), Ember.Object.create({ key: 'export-daily-project', value: (0, _utils.loc)('Daily By Project') }), Ember.Object.create({ key: 'export-daily-cost-code', value: (0, _utils.loc)('Daily By Cost Code') }), Ember.Object.create({ key: 'export-daily-project-cost-code', value: (0, _utils.loc)('Daily By Project & Cost Code') })].compact());
			}
		}),

		reportName: Ember.computed('type', function () {
			var reportName = '';
			var name = this.get('type').split('-');

			name.forEach(function (item) {
				reportName += item.classify() + ' ';
			});

			return (reportName + (0, _utils.loc)('Payroll')).replace(/Member/, (0, _utils.loc)('Employee'));
		}),

		exportReport: function exportReport(item) {
			var _this = this;

			this.set('isCSVLoading', true);
			var start = this.get('start') || _utils.Time.date().unix(); //.subtract(5, 'days').unix();
			var payPeriod = this.get('payPeriods').getPayPeriod(start);

			var promise = Ember.RSVP.resolve();
			if (item.get('key') === 'export-basic') {
				promise = this.store.exportCSV('payroll-basic-csv', payPeriod.get('startDate').unix(), payPeriod.get('endDate').unix());
			} else if (item.get('key') === 'export-daily') {
				promise = this.store.exportCSV('payroll-member-csv', payPeriod.get('startDate').unix(), payPeriod.get('endDate').unix());
			} else if (item.get('key') === 'export-daily-project') {
				promise = this.store.exportCSV('payroll-member-project-csv', payPeriod.get('startDate').unix(), payPeriod.get('endDate').unix());
			} else if (item.get('key') === 'export-daily-cost-code') {
				promise = this.store.exportCSV('payroll-member-cost-code-csv', payPeriod.get('startDate').unix(), payPeriod.get('endDate').unix());
			} else if (item.get('key') === 'export-daily-project-cost-code') {
				promise = this.store.exportCSV('payroll-member-project-cost-code-csv', payPeriod.get('startDate').unix(), payPeriod.get('endDate').unix());
			} else if (item.get('key') === 'export-advanced') {
				promise = this.exportAdvanced(payPeriod.get('startDate'), payPeriod.get('endDate'));
			}

			return promise.then(function () {
				_this.set('isCSVLoading', false);
			}).catch(function (e) {
				_this.set('isCSVLoading', false);

				// eslint-disable-next-line no-console
				console.error('ERROR', e);

				window.alert((0, _utils.loc)('Export failed, please try again.'));
			});
		},


		/**
   * This only works against production data. There are no pre-production endpoints currently available
   */
		exportAdvanced: function exportAdvanced(startDate, endDate) {
			var _this2 = this;

			var format = 'Y-MM-DD HH:mm:ss';
			var startDateIso = startDate.format(format);
			var endDateIso = endDate.endOf('day').format(format);

			var ajaxProps = {
				data: {
					start: startDateIso,
					end: endDateIso
				}
			};

			return Ember.get(this, 'discovery').ajax('time-entry-export', ajaxProps).then(function (data /*, status, xhr*/) {
				var fileName = 'advanced-export_' + startDateIso + '-' + endDateIso + '.' + Date.now() + '.csv';

				return _this2.triggerCsvDownload(data, fileName);
			}).catch(function (xhr, status, err) {
				var errorMessage = err || 'Export failed';

				// eslint-disable-next-line no-console
				console.error('ERROR', errorMessage, { xhr: xhr, status: status, err: err });

				return Ember.RSVP.reject(errorMessage);
			});
		},
		triggerCsvDownload: function triggerCsvDownload(data, fileName) {
			var el = document.createElement('a');
			var encodedData = encodeURIComponent(data);

			el.href = 'data:text/csv;charset=utf-8,' + encodedData;
			el.target = '_blank';
			el.download = fileName;

			return new Ember.RSVP.Promise(function (resolve, reject) {
				el.addEventListener('click', function (e) {
					resolve(e);
					el.remove();
				});

				el.addEventListener('error', function (e) {
					reject(e);
					el.remove();
				});

				el.click();
			});
		},


		actions: {
			changeDateRange: function changeDateRange(item) {
				var start = item.get('startDate').unix();
				var route = Ember.getOwner(this).lookup('route:report.payroll');

				route.transitionTo({ queryParams: { start: start } }).then(function () {
					return route.refresh();
				});
			},
			optionChange: function optionChange(item) {
				var _this3 = this;

				if (item.get('key') !== 'print-report') {
					this.get('proFeatureManager').requestAction('export', function () {
						return _this3.exportReport(item);
					});
				}
			},
			changeColumn: function changeColumn(val, item) {
				this.set(item.get('key'), val);
				Ember.getOwner(this).lookup('route:report.payroll').refresh();
			},
			proFeatureManagerOpenModal: function proFeatureManagerOpenModal() {
				this.get('proFeatureManager').openModal();
			},
			proFeatureManagerCloseModal: function proFeatureManagerCloseModal() {
				this.get('proFeatureManager').closeModal();
			},
			proFeatureManagerPlanUpgraded: function proFeatureManagerPlanUpgraded() {
				this.get('proFeatureManager').onAllow();
			}
		}
	});
});