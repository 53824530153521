define('busy-app/utils/models/collections/payroll-basic-csv', ['exports', 'busy-app/utils/models/collections/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		seconds: null,
		cost: null,
		wageHistory: null,

		wage: null,
		wageType: null,
		hours: null,
		wageKey: null
	});
});