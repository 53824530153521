define('busy-app/controllers/map', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    access: Ember.inject.service(),
    showMembers: true,
    showProjects: false,
    showEquipment: false,
    selectedViewTitle: '',
    showCurrentLocations: true,

    init: function init() {
      var _this = this;

      this._super();
      setTimeout(function () {
        _this.setMarkers();
        _this.set('selectedViewTitle', 'Current Locations');
        _this.setLatLngZoom(39.8283, -98.5785, 5);
      }, 500);
    },
    setLocationInfo: function setLocationInfo() {
      var _this2 = this;

      var model = this.get('model'),
          timeEntries = model.timeEntries,
          members = model.members,
          projects = model.projects,
          equipments = model.equipment,
          locationItems = [],
          projectMemberList = [],
          access = this.get('access');

      var entriesFinished = 0;

      this.setProperties({
        locationInfo: null,
        markers: null
      });

      timeEntries.forEach(function (entry) {

        _this2.store.filter('time-entry').getRelatedModels(entry).then(function (props) {
          props.timeEntryBreaks = props.breaks;
          entry.setProperties(props);

          if (Ember.isPresent(entry.get('startLocation.content'))) {
            var member = members.findBy('id', entry.get('memberId'));

            if (Ember.isPresent(member)) {
              if (access.canTakeActionFor('timeEvents', member)) {
                var locationObject = Ember.Object.create({
                  type: 'member',
                  memberName: member.get('fullName'),
                  member: member,
                  memberId: entry.get('memberId'),
                  imageThumbUrl: member.get('imageThumbUrl'),
                  equipment: entry.get('equipment'),
                  costCode: entry.get('costCode'),
                  timeEntry: entry,
                  isEquipment: false,
                  isMember: true,
                  isProject: false
                });
                var project = projects.findBy('id', entry.get('projectId'));
                locationObject.set('project', project);

                var isOnBreak = false;
                var openBreaks = entry.breaks.filter(function (b) {
                  if (Ember.isEmpty(b.get('endTime'))) {
                    return b;
                  }
                });

                openBreaks.length > 0 ? isOnBreak = true : isOnBreak = false;

                _this2.get('store').query('member-location', { member_id: member.get('id'), _gte: { created_on: _utils.Time.date().startOf('day').unix() } }).then(function (memLocs) {
                  var sortedMemLocs = memLocs.sortBy('submittedOn');

                  var memLoc = sortedMemLocs.get('lastObject');
                  locationObject.set('startTime', memLoc.get('timeLocalString'));
                  if (!_this2.get('showCurrentLocations')) {
                    var clockInMemLoc = sortedMemLocs.filter(function (loc) {
                      if (loc.get('id') === entry.get('startLocationId')) {
                        return loc;
                      }
                    });

                    memLoc = clockInMemLoc.get('firstObject');
                    locationObject.set('startTime', entry.get('startTimeString'));
                  }
                  locationObject.setProperties({
                    lat: memLoc.get('latitude'),
                    lng: memLoc.get('longitude')
                  });

                  var isEndBreak = false;
                  entry.breaks.forEach(function (b) {
                    if (b.get('endLocationId') === memLoc.get('id')) {
                      isEndBreak = true;
                    }
                  });

                  if (entry.get('startLocationId') === memLoc.get('id')) {
                    locationObject.set('locationType', 'Clocked In');
                  } else if (isOnBreak) {
                    locationObject.set('locationType', 'Started Break');
                  } else if (isEndBreak) {
                    locationObject.set('locationType', 'Ended Break');
                  } else {
                    locationObject.set('locationType', 'Location Changed');
                  }
                  if (locationItems.indexOf(locationObject) === -1) {
                    locationItems.pushObject(locationObject);
                  }
                });
              }
            }
          }

          if (Ember.isPresent(entry.get('projectId'))) {
            var _project = projects.findBy('id', entry.get('projectId'));

            if (Ember.isPresent(_project)) {
              var projectObject = Ember.Object.create({
                type: 'project',
                projectTitle: _project.get('title'),
                projectId: _project.get('id'),
                project: entry.get('project'),
                equipment: entry.get('equipment'),
                costCode: entry.get('costCode'),
                isEquipment: false,
                isMember: false,
                isProject: true
              });

              var _member = members.findBy('id', entry.get('memberId'));

              if (access.canTakeActionFor('timeEvents', _member)) {
                var memberObj = Ember.Object.create({
                  member: _member,
                  costCode: entry.get('costCode'),
                  equipment: entry.get('equipment'),
                  projectId: _project.get('id'),
                  project: _project,
                  timeEntry: entry,
                  startTime: entry.get('startTimeString'),
                  parentProjects: _project.get('parentProjects'),
                  projectTitle: entry.get('project.title')
                });

                projectMemberList.pushObject(memberObj);
              }

              _this2.get('store').query('project-info', { project_id: _project.get('id') }).then(function (info) {
                if (info.content.length > 0) {
                  var projInfo = info.content[0]._data;

                  projectObject.setProperties({
                    lat: projInfo.latitude,
                    lng: projInfo.longitude,
                    locationRadius: projInfo.locationRadius,
                    number: projInfo.number
                  });
                }
                if (Ember.isPresent(projectObject.lat && _project.get('title'))) {
                  if (locationItems.indexOf(projectObject) === -1) {
                    locationItems.pushObject(projectObject);
                  }
                }
              });
              if (_project.get('parentProjectId') && _project.get('id') !== _project.get('parentProjectId')) {
                _this2.getProjectInfo(_project, entry).then(function (data) {
                  if (Ember.isPresent(data)) {
                    locationItems.pushObject(data.get('project'));
                    if (data.get('project.project.parentProjectId') && data.get('project.projectId') !== data.get('project.project.parentProjectId')) {
                      _this2.getProjectInfo(data.get('project.project'), entry).then(function (data) {
                        if (Ember.isPresent(data)) {
                          locationItems.pushObject(data.get('project'));
                        }
                      });
                    }
                  }
                });
              }
            }
          }

          if (Ember.isPresent(entry.get('equipmentId'))) {
            var equipment = equipments.findBy('id', entry.get('equipmentId'));
            if (Ember.isPresent(equipment)) {
              var equipMember = members.findBy('id', entry.get('memberId'));
              var _project2 = null;

              if (entry.get('projectId')) {
                _project2 = projects.findBy('id', entry.get('projectId'));
              }
              var equipmentObject = Ember.Object.create({
                type: 'equipment',
                equipmentName: equipment.get('equipmentName'),
                memberId: entry.get('memberId'),
                project: _project2,
                equipment: entry.get('equipment'),
                costCode: entry.get('costCode'),
                timeEntry: entry,
                member: equipMember,
                isEquipment: true,
                isMember: false,
                isProject: false
              });

              var _member2 = members.findBy('id', equipmentObject.get('memberId'));

              var canTakeAction = false;
              if (access.canTakeActionFor('timeEvents', _member2)) {
                canTakeAction = true;
              }

              equipmentObject.setProperties({
                imageThumbUrl: equipment.get('equipmentThumb'),
                totalTimeString: entry.get('totalTimeString'),
                startTime: entry.get('startTimeString'),
                canTakeAction: canTakeAction
              });

              if (Ember.isPresent(_member2)) {
                equipmentObject.set('memberName', _member2.get('firstName') + ' ' + _member2.get('lastName'));
              }

              if (Ember.isPresent(equipment.get('lastLocationId'))) {
                _this2.get('store').find('equipment-location', equipment.get('lastLocationId')).then(function (equipLoc) {
                  equipmentObject.setProperties({
                    lat: equipLoc.get('latitude'),
                    lng: equipLoc.get('longitude')
                  });
                  if (Ember.isPresent(equipmentObject.get('equipmentName')) && equipmentObject.get('canTakeAction')) {
                    if (locationItems.indexOf(equipmentObject) === -1) {
                      locationItems.pushObject(equipmentObject);
                    }
                  }
                });
              }
            }
          }

          entriesFinished++;
          if (entriesFinished === _this2.get('model.timeEntries.length')) {
            _this2.set('locationInfo', Ember.Object.create({
              locationItems: locationItems,
              projectMemberList: projectMemberList
            }));
          }
        });
      });
    },


    setInfoLoader: Ember.observer('model.modelSet', function () {
      var _this3 = this;

      if (this.get('model.modelSet')) {
        Ember.run.once(function () {
          _this3.setLocationInfo();
        });
      }
    }),

    getProjectInfo: function getProjectInfo(project, entry) {
      var _this4 = this;

      return this.get('store').find('project', project.get('parentProjectId')).then(function (proj) {
        var parentLocation = Ember.Object.create({
          type: 'project',
          projectTitle: proj.get('title'),
          projectId: proj.get('id'),
          project: proj,
          equipment: entry.get('equipment'),
          costCode: entry.get('costCode'),
          isEquipment: false,
          isMember: false,
          isProject: true
        });

        return _this4.get('store').query('project-info', { project_id: proj.get('id') }).then(function (info) {

          if (info.content.length > 0) {

            var projInfo = info.content[0]._data;
            parentLocation.setProperties({
              lat: projInfo.latitude,
              lng: projInfo.longitude,
              locationRadius: projInfo.locationRadius
            });
          }
          if (Ember.isPresent(parentLocation.get('lat'))) {
            return Ember.Object.create({
              project: parentLocation
            });
          }
        });
      });
    },


    layerType: Ember.computed('showMap', function () {
      var showMap = this.get('showMap');
      if (showMap) {
        return 'map';
      } else {
        return 'hybrid';
      }
    }),

    mapViewOptions: Ember.computed(function () {
      return [Ember.Object.create({ key: 'current-location', value: 'Current Locations' }), Ember.Object.create({ key: 'clock-in-location', value: 'Clock In Locations' })];
    }),

    /**
     * Sets the initial latitude, longitude and zoom
     * @private
     * @method setLatLngZoom
     */
    setLatLngZoom: function setLatLngZoom(lat, lng, zoom) {
      this.set('lat', lat);
      this.set('lng', lng);
      this.set('zoom', zoom);
    },


    /**
     * Sets the marker colors based on current equipment status.
     * @private
     * @method setMarkers
     */
    setMarkers: Ember.observer('locationInfo.locationItems.@each', 'locationInfo.projectMemberList.@each', 'showMembers', 'showProjects', 'showEquipment', function () {
      var _this5 = this;

      var markers = [];
      var bounds = null;
      bounds = new window.google.maps.LatLngBounds();
      if (!Ember.isEmpty(this.get('locationInfo'))) {
        this.get('locationInfo.locationItems').forEach(function (item) {
          item.set('markerLabel', { text: ' ', color: '#ffffff' });
          var iconUrl = void 0;
          if (item.get('isMember')) {
            iconUrl = '/images/icons/employee_pin.png';
          } else if (item.get('isEquipment')) {
            iconUrl = '/images/icons/equipment_pin.png';
          } else if (item.get('isProject')) {
            iconUrl = '/images/icons/project_pin.png';
          }

          var markerImage = {
            url: iconUrl,
            size: new window.google.maps.Size(30, 30),
            anchor: new window.google.maps.Point(15, 15),
            labelOrigin: new window.google.maps.Point(15, 15)
          };

          item.set('markerImage', markerImage);
          if (item.get('isProject')) {
            var members = [];
            _this5.get('locationInfo.projectMemberList').forEach(function (member) {
              if (member.get('projectId') === item.get('projectId') || member.get('parentProjects') && member.get('parentProjects').isAny('id', item.get('projectId'))) {
                members.pushObject(member);
              }
            });
            item.set('members', members.sortBy('member.lastName'));
          }
          if (_this5.get('showMembers') && item.get('isMember')) {
            markers.pushObject(item);
          }
          if (_this5.get('showProjects') && item.get('isProject')) {
            markers.pushObject(item);
          }
          if (_this5.get('showEquipment') && item.get('isEquipment')) {
            markers.pushObject(item);
          }
          var boundItem = new window.google.maps.LatLng(item.lat, item.lng);
          bounds.extend(boundItem);
        });
        if (!this.get('boundsSet')) {
          this.set('bounds', bounds);
        }
        this.set('markers', markers);
      }
    }),

    /**
      * Flag to check whether there are locations to display
      * @private
      * @property hasLocations
      * @type {boolean}
      */
    hasLocations: Ember.computed('locationInfo.locationItems.@each', 'markers.@each', function () {

      return this.get('locationInfo.locationItems.length') > 0 && this.get('markers.length') > 0;
    }),

    actions: {
      showProjectDialog: function showProjectDialog(item) {
        this.set('currentProject', item);
        this.set('showDialog', true);
        this.get('currentProject.members').forEach(function (item) {
          item.set('totalTime', _utils.Time.now().unix() - item.get('timeEntry.startTime'));
        });
      },
      closeDialog: function closeDialog() {
        this.set('showDialog', false);
      },
      loadEmployee: function loadEmployee(member) {
        this.setProperties({
          currentMember: member,
          showMemberDialog: true
        });
      },
      closeMemberDialog: function closeMemberDialog() {
        this.setProperties({
          currentMember: null,
          showMemberDialog: false
        });
      },


      /**
        * Opens the info tooltip
        * @event openInfoWindow
        */
      openInfoWindow: function openInfoWindow(item, _ref) {
        var map = _ref.map;

        this.get('locationInfo.locationItems').forEach(function (locationItems) {
          locationItems.set('showInfoWindow', false);
        });
        item.set('totalTime', _utils.Time.now().unix() - item.get('timeEntry.startTime'));
        if (item.get('showInfoWindow')) {
          item.set('showInfoWindow', false);
        } else {
          item.set('showInfoWindow', true);
        }
        map.setCenter({ lat: item.get('lat'), lng: item.get('lng') });
      },
      closeInfoWindows: function closeInfoWindows() {
        this.get('locationInfo.locationItems').forEach(function (locationItems) {
          locationItems.set('showInfoWindow', false);
        });
      },
      loadMap: function loadMap(_ref2) {
        var map = _ref2.map;

        map.fitBounds(this.get('bounds'));
        map.setCenter(this.get('bounds').getCenter());
        this.set('boundsSet', true);
      },
      mapViewAction: function mapViewAction(item) {
        this.set('selectedViewTitle', item.get('value'));
        if (item.get('key') === 'clock-in-location') {
          this.set('showCurrentLocations', false);
          this.setLocationInfo();
        } else {
          this.set('showCurrentLocations', true);
          this.setLocationInfo();
        }
      }
    }
  });
});