define('busy-app/components/employees/edit-photo-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    member: null,

    actions: {
      close: function close() {
        this.get('member').rollbackAttributes();
        this.sendAction('onClose');
      },
      savePhoto: function savePhoto() {
        return this.get('member').save();
      },
      imageChange: function imageChange(file) {
        this.set('member.image', file);
      }
    }
  });
});