define('busy-app/services/event-emitter', ['exports', '@busy-web/utils', 'busy-app/events/router', 'busy-app/events/dom'], function (exports, _utils, _router, _dom) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	function _toConsumableArray(arr) {
		if (Array.isArray(arr)) {
			for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
				arr2[i] = arr[i];
			}

			return arr2;
		} else {
			return Array.from(arr);
		}
	}

	exports.default = Ember.Service.extend({
		init: function init() {
			var _this = this;

			this._super();

			window.addEventListener('resize', function (event) {
				if (_this.__throttle) {
					Ember.run.cancel(_this.__throttle);
				}

				_this.__throttle = Ember.run.later(null, function () {
					_this.send('dom', 'resize', event);
				}, 100);
			});
		},


		_eventsTypes: Ember.computed(function () {
			return {
				router: _router.default.create(),
				dom: _dom.default.create()
			};
		}),

		on: function on(type, name, callback) {
			var target = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

			_utils.Assert.isString(type);
			_utils.Assert.isString(name);

			var eventType = Ember.get(this, '_eventsTypes.' + type);

			_utils.Assert.test('The event type ' + type + ' does not exist', !Ember.isNone(eventType));

			eventType.on(name, target, callback);
		},
		off: function off(type, name, callback) {
			var target = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

			_utils.Assert.isString(type);
			_utils.Assert.isString(name);

			var eventType = Ember.get(this, '_eventsTypes.' + type);

			_utils.Assert.test('The event type ' + type + ' does not exist', !Ember.isNone(eventType));

			eventType.off(name, target, callback);
		},
		send: function send(type, name) {
			_utils.Assert.isString(type);
			_utils.Assert.isString(name);

			var eventType = Ember.get(this, '_eventsTypes.' + type);

			_utils.Assert.test('The event type ' + type + ' does not exist', !Ember.isNone(eventType));

			for (var _len = arguments.length, args = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
				args[_key - 2] = arguments[_key];
			}

			var hasEvent = eventType.send.apply(eventType, [name].concat(_toConsumableArray(args)));

			_utils.Assert.test('The event type ' + type + ' does not support a trigger for ' + name, hasEvent);
		}
	});
});