define('busy-app/components/report/time-card/signature-state', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service(),
		features: Ember.inject.service(),
		signature: Ember.inject.service(),

		classNames: ['signature-state'],
		tagName: 'span',

		// attributes
		memberTimeDocument: null,
		payPeriodSeconds: null,

		// properties
		signatureBreadcrumbState: Ember.computed('payPeriodSeconds.{isSignable}', 'memberTimeDocument.{hasValidSelfSignature,needsSelfSignatureResign,hasBothValidSignatures,needsEitherSignatureResign}', function () {
			if (Ember.isNone(this.get('payPeriodSeconds'))) {
				return null;
			}

			var isTimecardMine = true; // hardcoded for reporting consistency
			var isSupervisor = true; // hardcoded for reporting consistency

			var signatureState = this.get('signature').getSignatureState(isTimecardMine, isSupervisor, this.get('payPeriodSeconds'), this.get('features'), this.get('signature'), this.get('memberTimeDocument'));

			return signatureState;
		}),

		// transform the breadcrumb style state
		signatureState: Ember.computed('signatureBreadcrumbState', function () {
			var state = this.get('signatureBreadcrumbState');

			if (state === 'Sign') {
				return 'Not Signed';
			} else if (state === 'Re-Sign') {
				return 'Re-sign Required';
			} else if (state === null) {
				return null;
			} else {
				// Signed
				return state;
			}
		}),

		signatureStateClass: Ember.computed('signatureBreadcrumbState', function () {
			var state = this.get('signatureBreadcrumbState');

			if (state === 'Sign') {
				return 'unsigned';
			} else if (state === 'Re-Sign') {
				return 'resign';
			} else if (state === 'Signed') {
				return 'signed';
			} else {
				return '';
			}
		})
	});
});