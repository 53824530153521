define('busy-app/helpers/can-take-action-for', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		access: Ember.inject.service('access'),
		actionName: null,
		member: null,

		compute: function compute(params) {
			var actionName = params[0];
			var forMember = params[1];

			Ember.set(this, 'actionName', actionName);
			Ember.set(this, 'member', forMember);

			if (!Ember.isNone(forMember) && !Ember.isNone(Ember.get(forMember, 'id')) && !Ember.isNone(Ember.get(this, 'access.auth.authMemberPosition.id')) && !Ember.isNone(Ember.get(this, 'access.auth.organization.id'))) {
				return Ember.get(this, 'access').canTakeActionFor(actionName, forMember);
			}
			return false;
		},


		onUpdate: Ember.observer('access.auth.authMemberPosition.id', 'access.auth.organization.id', 'member.positionId', function () {
			if (!Ember.isNone(Ember.get(this, 'member.positionId')) && !Ember.isNone(Ember.get(this, 'access.auth.authMemberPosition.id')) && !Ember.isNone(Ember.get(this, 'access.auth.organization.id'))) {
				Ember.run.next(this, function () {
					this.recompute();
				});
			}
		})
	});
});