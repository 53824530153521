define('busy-app/controllers/index', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['timesheet_start', 'timesheet_end', 'timesheet_mode'],

		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),
		features: Ember.inject.service('features'),

		showAddTime: true,

		timecardBadgeContent: '',
		timecardBadgeColor: 'red',

		timesheet_start: 0,
		timesheet_end: 0,
		timesheet_mode: null,

		member: Ember.computed.alias('auth.member.content'),
		organization: Ember.computed.alias('auth.organization.content'),

		onStartChange: Ember.observer('timesheet_start', function () {
			var timesheet_start = this.get('timesheet_start');

			if (!Ember.isNone(timesheet_start) && parseInt(timesheet_start, 10) !== timesheet_start) {
				this.set('timesheet_start', parseInt(timesheet_start, 10));
			}
		}),

		onEndChange: Ember.observer('timesheet_end', function () {
			var timesheet_end = this.get('timesheet_end');

			if (!Ember.isNone(timesheet_end) && parseInt(timesheet_end, 10) !== timesheet_end) {
				this.set('timesheet_end', parseInt(timesheet_end, 10));
			}
		}),

		init: function init() {
			this._super.apply(this, arguments);

			this.setProperties({
				timesheet_start: null,
				timesheet_end: null,
				timesheet_mode: null
			});
		},
		getStartOfToday: function getStartOfToday() {
			return _utils.Time.date().startOf('day');
		},


		actions: {
			openLoacationAction: function openLoacationAction(member) {
				this.transitionToRoute('employees.employee-locations', {
					queryParams: {
						id: member.get('openEntry.id'),
						member_id: member.get('id')
					}
				});
			}
		}
	});
});