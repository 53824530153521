define('busy-app/components/mod-util/each-day', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _slicedToArray = function () {
		function sliceIterator(arr, i) {
			var _arr = [];
			var _n = true;
			var _d = false;
			var _e = undefined;

			try {
				for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
					_arr.push(_s.value);

					if (i && _arr.length === i) break;
				}
			} catch (err) {
				_d = true;
				_e = err;
			} finally {
				try {
					if (!_n && _i["return"]) _i["return"]();
				} finally {
					if (_d) throw _e;
				}
			}

			return _arr;
		}

		return function (arr, i) {
			if (Array.isArray(arr)) {
				return arr;
			} else if (Symbol.iterator in Object(arr)) {
				return sliceIterator(arr, i);
			} else {
				throw new TypeError("Invalid attempt to destructure non-iterable instance");
			}
		};
	}();

	exports.default = Ember.Component.extend({
		tagName: '',

		model: null,

		start: null,
		end: null,

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();

			// create content and set default empty array.
			var content = [];

			// get models
			var models = Ember.get(this, 'model');

			if (!Ember.isNone(models)) {
				// enforce the model is an array of models
				(false && !(Array.isArray(models) || Array.isArray(Ember.get(models, 'content'))) && Ember.assert('<component:each-day> `model` must be an array of model objects.', Array.isArray(models) || Array.isArray(Ember.get(models, 'content'))));

				// converts non regular objects to regular objects

				models = convertObjects(models);

				// only generate the day range objects
				// when the model entries in the array.
				if (Ember.get(models, 'length') > 0) {

					// get the rangeStart and rangeEnd if they were provided to the component.
					var _getModelRange = getModelRange(models, Ember.get(this, 'start'), Ember.get(this, 'end')),
					    rangeStart = _getModelRange.rangeStart,
					    rangeEnd = _getModelRange.rangeEnd;

					// generate the date range by day and include all models that fit within
					// the day generated.


					content = generateRange(models, rangeStart, rangeEnd);
				}
			}
			Ember.set(this, 'content', content);
		}
	});


	var getDayTime = function getDayTime(time) {
		return _utils.Time.date(time).startOf('day').unix();
	};

	var generateRange = function generateRange(models, rangeStart, rangeEnd) {
		var rangeModels = [];

		// helper methods
		var getNextDayTime = function getNextDayTime(time) {
			return _utils.Time.date(time).startOf('day').add(1, 'days').unix();
		};

		// set currentDay to the start of the range
		var currentDay = getDayTime(rangeStart);

		// generate days for each day in the range

		var _loop = function _loop() {
			var startTime = currentDay;
			var endTime = _utils.Time.date(startTime).endOf('day').unix();
			if (endTime > rangeEnd) {
				endTime = rangeEnd;
			}

			// in range helper
			var isInRange = function isInRange(time) {
				return time >= startTime && time <= endTime;
			};
			var spansRange = function spansRange(start, end) {
				return start < startTime && end > endTime;
			};

			// filter all models with a start or end time
			// for the current day
			var filter = models.filter(function (item) {
				return isInRange(getStart(item)) || isInRange(getEnd(item)) || spansRange(getStart(item), getEnd(item));
			}).map(function (item) {
				return createDayObject(item, startTime, endTime);
			});

			if (filter.length) {
				// push the models into the range list
				rangeModels.push({ startTime: startTime, endTime: endTime, model: filter });
			}

			// set currentDay ahead 1 day
			currentDay = getNextDayTime(currentDay);
		};

		while (rangeEnd > currentDay) {
			_loop();
		}
		return rangeModels;
	};

	var createDayObject = function createDayObject(model, min, max) {
		var getStartTime = function getStartTime(start) {
			return start < min ? [min, true] : [start, false];
		};
		var getEndTime = function getEndTime(end) {
			return end > max ? [max, true] : [end, false];
		};

		var startTime = getStart(model);
		var endTime = getEnd(model);

		var isTimeOff = Ember.get(model, 'isTimeOff');

		if (isTimeOff) {
			isTimeOff = true;
			var span = Ember.get(model, 'seconds');
			if (span > 0) {
				endTime = _utils.Time.date(startTime).add(span, 'seconds').unix();
			}
		}

		var _getStartTime = getStartTime(startTime),
		    _getStartTime2 = _slicedToArray(_getStartTime, 2),
		    start = _getStartTime2[0],
		    isStartOutside = _getStartTime2[1];

		var _getEndTime = getEndTime(endTime),
		    _getEndTime2 = _slicedToArray(_getEndTime, 2),
		    end = _getEndTime2[0],
		    isEndOutside = _getEndTime2[1];

		var json = model;
		if (model._internalModel) {
			json = model.toJSON({ includeId: true });
			model.eachRelationship(function (key) {
				json[key] = Ember.get(model, key);
			});

			if (!Ember.isEmpty(Ember.get(model, 'segments'))) {
				json.segments = (Ember.get(model, 'segments') || []).filter(function (seg) {
					return Ember.get(seg, 'startTime') >= start && Ember.get(seg, 'endTime') <= end + 1;
				});
			} else if (isTimeOff) {
				json.segments = [{ seconds: [{ regularSeconds: json.seconds, overtimeSeconds: 0, doubletimeSeconds: 0 }] }];
			}
		}

		var copy = Object.assign({}, json, {
			startTime: start,
			endTime: end,
			isStartOutside: isStartOutside,
			isEndOutside: isEndOutside,
			isTimeOff: isTimeOff
		});

		copy.__originalModel = model;
		return copy;
	};

	var getModelRange = function getModelRange(models, rangeStart, rangeEnd) {
		if (Ember.isNone(rangeStart) || Ember.isNone(rangeEnd)) {
			// sort days helper
			var sortTimes = function sortTimes(a, b) {
				return a < b ? -1 : a > b ? 1 : 0;
			};

			// get list of all days in models array
			var days = models.map(function (m) {
				return getDayTime(getStart(m));
			}).uniq().sort(sortTimes);

			// use the provided range start time or use the generated time
			if (Ember.isNone(rangeStart)) {
				rangeStart = days[0];
			}

			// use the provided range end time or use the generated time
			if (Ember.isNone(rangeEnd)) {
				rangeEnd = _utils.Time.date(days[days.length - 1]).endOf('day').unix();
			}
		}
		return { rangeStart: rangeStart, rangeEnd: rangeEnd };
	};

	var getStart = function getStart(model) {
		if (!Ember.isNone(Ember.get(model, 'startTime'))) {
			return Ember.get(model, 'startTime');
		} else if (!Ember.isNone(Ember.get(model, 'startDate'))) {
			return Ember.get(model, 'startDate');
		} else if (!Ember.isNone(Ember.get(model, 'dateStamp'))) {
			return Ember.get(model, 'dateStamp');
		}
	};

	var getEnd = function getEnd(model) {
		if (!Ember.isNone(Ember.get(model, 'endTime'))) {
			return Ember.get(model, 'endTime');
		} else if (!Ember.isNone(Ember.get(model, 'endDate'))) {
			return Ember.get(model, 'endDate');
		} else if (!Ember.isNone(Ember.get(model, 'dateStamp'))) {
			return Ember.get(model, 'dateStamp') + Ember.get(model, 'seconds');
		}
	};

	// const validKeys = ['startTime', 'endTime', 'startDate', 'endDate'];
	// const getModelKeys = (model, startKey, endKey) => {
	//   if (model && model.toJSON) {
	//     model = model.toJSON({ includeId: true });
	//   }

	//   if (isEmpty(startKey)) {
	//     const opts = Object.keys(model).filter(key => /^start/.test(key));
	//     startKey = validKeys.find(vk => opts.indexOf(vk) !== -1);
	//   }

	//   if (isEmpty(endKey)) {
	//     const opts = Object.keys(model).filter(key => /^end/.test(key));
	//     endKey = validKeys.find(vk => opts.indexOf(vk) !== -1);
	//   }

	//   if (isEmpty(startKey) && isEmpty(endKey) && !isNone(model.dateStamp)) {
	//     startKey = 'startTime';
	//     endKey = 'endTime';
	//   }

	//   return { startKey, endKey };
	// };

	var convertObjects = function convertObjects(models) {
		var modelArray = models.toArray();
		return modelArray.map(function (model) {
			if (model && model._internalModel) {
				return model._internalModel.getRecord();
			}
			return model;
		});
	};
});