define('busy-app/models/project-day-cost', ['exports', 'ember-data', '@busy-web/utils', 'busy-app/utils/unique-id'], function (exports, _emberData, _utils, _uniqueId) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		// used to match seconds and cost models
		uid: (0, _uniqueId.default)('type', 'startDate', 'projectId'),

		// used to differentiate uid for month and day models
		type: "day",

		projectId: _emberData.default.attr('id', { sortable: true, sortBy: ['project.title'], nested: 'project.parentProjectId' }),
		organizationId: _emberData.default.attr('id'),
		startDate: _emberData.default.attr('date', { sortable: true, sortBy: ['startDate'], reverseSort: true, sortChildren: ['project.title', 'startDate'] }),
		regular: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overtime: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		doubletime: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		overburden: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenRegular: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenOvertime: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenDoubletime: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		childrenOverburden: _emberData.default.attr('number', { defaultValue: 0, aggregateType: 'sum' }),
		updatedOn: _emberData.default.attr('date'),
		submittedOn: _emberData.default.attr('date'),

		project: _emberData.default.belongsTo('project'),

		startDateFormatted: Ember.computed('startDate', function () {
			return _utils.Time.date(this.get('startDate')).format('MMM D');
		}),

		startOfWeek: Ember.computed('startDate', function () {
			return _utils.Time.date(this.get('startDate')).startOf('week').format('MM/D/YY');
		}),

		endOfWeek: Ember.computed('startDate', function () {
			return _utils.Time.date(this.get('startDate')).endOf('week').format('MM/D/YY');
		}),

		weekRangeFormatted: Ember.computed('startOfWeek', function () {
			return this.get('startOfWeek') + ' - ' + this.get('endOfWeek');
		}),

		regularCost: Ember.computed.alias('regular').meta({ aggregateType: 'sum' }),
		overtimeCost: Ember.computed.alias('overtime').meta({ aggregateType: 'sum' }),
		doubletimeCost: Ember.computed.alias('doubletime').meta({ aggregateType: 'sum' }),
		childrenRegularCost: Ember.computed.alias('childrenRegular').meta({ aggregateType: 'sum' }),
		childrenOvertimeCost: Ember.computed.alias('childrenOvertime').meta({ aggregateType: 'sum' }),
		childrenDoubletimeCost: Ember.computed.alias('childrenDoubletime').meta({ aggregateType: 'sum' }),

		totalCost: Ember.computed('regular', 'overtime', 'doubletime', function () {
			return this.get('regular') + this.get('overtime') + this.get('doubletime');
		}).meta({ aggregateType: 'sum' }),

		childrenTotalCost: Ember.computed('childrenRegular', 'childrenOvertime', 'childrenDoubletime', function () {
			return this.get('childrenRegular') + this.get('childrenOvertime') + this.get('childrenDoubletime');
		}).meta({ aggregateType: 'sum' }),

		childrenTotalOverburdenCost: Ember.computed('childrenTotalCost', 'childrenOverburden', function () {
			return this.get('childrenTotalCost') + this.get('childrenOverburden');
		}).meta({ aggregateType: 'sum' }),

		totalRegularCost: Ember.computed('regular', 'childrenRegular', function () {
			return this.get('regular') + this.get('childrenRegular');
		}).meta({ aggregateType: 'sum' }),

		totalOvertimeCost: Ember.computed('overtime', 'childrenOvertime', function () {
			return this.get('overtime') + this.get('childrenOvertime');
		}).meta({ aggregateType: 'sum' }),

		totalDoubletimeCost: Ember.computed('doubletime', 'childrenDoubletime', function () {
			return this.get('doubletime') + this.get('childrenDoubletime');
		}).meta({ aggregateType: 'sum' }),

		totalOverburden: Ember.computed('overburden', 'childrenOverburden', function () {
			return this.get('overburden') + this.get('childrenOverburden');
		}).meta({ aggregateType: 'sum' }),

		totalTotalCost: Ember.computed('totalCost', 'childrenTotalCost', function () {
			return this.get('totalCost') + this.get('childrenTotalCost');
		}).meta({ aggregateType: 'sum' }),

		totalOverburdenCost: Ember.computed('totalCost', 'overburden', function () {
			return this.get('totalCost') + this.get('overburden');
		}).meta({ aggregateType: 'sum' }),

		totalTotalOverburdenCost: Ember.computed('totalTotalCost', 'childrenTotalOverburdenCost', function () {
			return this.get('totalOverburdenCost') + this.get('childrenTotalOverburdenCost');
		}).meta({ aggregateType: 'sum' })
	});
});