define('busy-app/utils/models/collections/hours-and-cost', ['exports', 'busy-app/utils/models/collections/base', '@busy-web/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		auth: Ember.inject.service('auth'),

		seconds: null,
		cost: null,
		member: null,
		project: null,
		costCode: null,

		applyData: function applyData(seconds, cost, member, project, costCode) {
			this.set('seconds', seconds);
			this.set('cost', cost);
			this.set('member', member);
			this.set('project', project);
			this.set('costCode', costCode);

			this._super(null);
		},


		fullName: Ember.computed('member.fullName', function () {
			return this.get('member.fullName');
		}),

		title: Ember.computed('project.{title,isParent}', function () {
			var title = this.get('project.title');
			if (this.get('project.isParent')) {
				title = 'Unassigned';
			}
			return title;
		}),

		totalSeconds: Ember.computed('seconds.@each.totalTotalSeconds', 'project.isParent', function () {
			var _this = this;

			var totalSeconds = 0;
			if (!Ember.isNone(this.get('seconds'))) {
				this.get('seconds').forEach(function (item) {
					if (!_this.get('project.isParent')) {
						totalSeconds += item.get('totalTotalSeconds');
					} else {
						totalSeconds += item.get('totalSeconds');
					}
				});
			}
			return totalSeconds;
		}),

		totalHours: Ember.computed('totalTotalSeconds', function () {
			return this.get('totalTotalSeconds') / 60 / 60;
		}),

		totalSecondsString: Ember.computed('totalSeconds', function () {
			return _utils.Time.convertSecondsString(this.get('totalSeconds'), 10, false, false, true);
		}),

		totalCost: Ember.computed('cost.@each.totalTotalCost', 'project.isParent', function () {
			var _this2 = this;

			var totalCost = 0;
			if (!Ember.isNone(this.get('cost'))) {
				this.get('cost').forEach(function (item) {
					if (!_this2.get('project.isParent')) {
						totalCost += item.get('totalTotalCost');
					} else {
						totalCost += item.get('totalCost');
					}
				});
			}
			return totalCost;
		}),

		totalCostString: Ember.computed('totalCost', 'auth.currencySymbol', function () {
			var symbol = this.get('auth.currencySymbol') || '$';
			var cost = this.get('totalCost').toFixed(2);
			return '' + symbol + cost;
		}),

		secondsToHours: Ember.computed('totalSeconds', function () {
			return parseInt(_utils.Time.convertSeconds(this.get('totalSeconds')).hours, 10);
		}),

		roundedDollars: Ember.computed('totalCost', function () {
			var dollars = this.get('totalCost');
			return parseInt(dollars.toFixed(2), 10);
		})
	});
});