define('busy-app/transforms/boolean', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		deserialize: function deserialize(serialized) {
			if (typeof serialized === 'string') {
				serialized = serialized === 'true' || serialized === '1' ? true : false;
			} else if (typeof serialized === 'number') {
				serialized = serialized === 1 ? true : false;
			} else if (typeof serialized !== 'boolean') {
				serialized = null;
			}

			return serialized;
		},

		serialize: function serialize(deserialized) {
			if (typeof deserialized === 'string') {
				if (deserialized !== '_-NULL-_') {
					deserialized = deserialized === 'true' || deserialized === '1' ? true : false;
				}
			} else if (typeof deserialized === 'number') {
				deserialized = deserialized === 1 ? true : false;
			} else if (typeof deserialized !== 'boolean') {
				deserialized = null;
			}

			return deserialized;
		}
	});
});