define('busy-app/components/employees/wage-history', ['exports', 'ember-data', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _emberData, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		features: Ember.inject.service('features'),
		store: Ember.inject.service('store'),

		classNames: ['v-employees-employee-wage-history'],

		member: null,
		overtimePreviewModel: null,
		previewEffectiveDate: null,

		wageHistory: Ember.computed('member.id', function () {
			return _emberData.default.PromiseArray.create({
				promise: this.get('store').findAll('wage-history', {
					member_id: this.get('member.id'),
					_desc: ['change_date', 'created_on'],
					deleted_on: '_-NULL-_'
				})
			});
		}),

		overtimePeriods: Ember.computed('member.id', function () {
			return _emberData.default.PromiseArray.create({
				promise: this.get('store').findAll('member-overtime-period', {
					member_id: this.get('member.id'),
					_desc: ['change_date', 'created_on'],
					deleted_on: '_-NULL-_'
				})
			});
		}),

		currentTab: 'wages',

		tabOptions: Ember.computed(function () {
			return [Ember.Object.create({ key: 'wages', label: (0, _utils.loc)('Wages') }), Ember.Object.create({ key: 'overtime', label: (0, _utils.loc)('Overtime') })];
		}),

		showWage: Ember.computed('currentTab', function () {
			if (this.get('currentTab') === 'wages') {
				return true;
			} else {
				return false;
			}
		}),

		selectMenu: Ember.computed(function () {
			return [Ember.Object.create({ key: 'delete', value: 'Delete' })];
		}),

		dialogButtonOptions: Ember.computed(function () {
			var _this = this;

			return [{
				name: 'add_circle',
				title: 'Change Wage',
				action: function action() {
					return _this.send('showWageDialog');
				}
			}];
		}),

		deleteWageHistory: function deleteWageHistory(item) {
			var _this2 = this;

			return item.destroyRecord().then(function () {
				_this2.get('wageHistory.content').update();
				return true;
			});
		},


		actions: {
			selectedRowItem: function selectedRowItem(action, member, wage) {
				if (action.get('key') === 'delete') {
					this.set('deleteWageModel', wage);
					this.set('showDeleteDialog', true);
				} else {
					_logger.default.error('An unrecognized action was called in the view/employees/employee/wageHistory actionView');
				}
			},
			cancelDelete: function cancelDelete() {
				this.set('showDeleteDialog', false);
				this.get('deleteWageModel').rollbackAttributes();
				this.set('deleteWageModel', null);
			},
			closeDelete: function closeDelete() {
				this.set('showDeleteDialog', false);
				this.set('deleteWageModel', null);
			},
			deleteWageChange: function deleteWageChange() {
				var _this3 = this;

				if (this.get('deleteWageModel.isLocked')) {
					return Ember.RSVP.reject((0, _utils.loc)('Cannot delete a wage with an effective date older than the member lock date'));
				}

				return this.deleteWageHistory(this.get('deleteWageModel')).then(function () {
					return (0, _utils.loc)('Deleted successfuly');
				}).catch(function (error) {
					_logger.default.error('delete error', error);
					return Ember.RSVP.reject((0, _utils.loc)('The wage change failed to delete. Please try again later.'));
				}).finally(function () {
					return _this3.send('closeDelete');
				});
			},
			closeWageHistory: function closeWageHistory() {
				this.sendAction('onClose');
			},
			selectTab: function selectTab(item) {
				this.set('currentTab', item.get('key'));
			},
			showOvertimePreviewDialog: function showOvertimePreviewDialog(item) {
				this.set('previewEffectiveDate', item.get('effectiveDate'));
				this.set('overtimePreviewModel', item);
				this.set('overtimePreviewDialog', true);
			},
			hideOvertimePreviewDialog: function hideOvertimePreviewDialog() {
				this.set('previewEffectiveDate', null);
				this.set('overtimePreviewModel', null);
				this.set('overtimePreviewDialog', false);
			},
			showWageDialog: function showWageDialog(wageHistoryId) {
				if (!Ember.isNone(wageHistoryId)) {
					this.set('wageHistoryId', wageHistoryId);
				}

				this.set('showWageHistory', true);
			},
			closeWageDialog: function closeWageDialog() {
				var _this4 = this;

				Ember.RSVP.resolve(this.get('wageHistory.content').update()).then(function () {
					_this4.set('showWageHistory', false);
					_this4.set('wageHistoryId', null);
				});
			}
		}
	});
});