define('busy-app/models/active-users', ['exports', 'ember-data', '@busy-web/data', '@busy-web/utils'], function (exports, _emberData, _data, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend(_data.RPCModelMixin, {
		_clientName: 'usage-report',
		_methodName: 'get-most-recent-reports',

		endDay: _emberData.default.attr('number'),
		total: _emberData.default.attr('number'),

		endDayDate: Ember.computed('endDay', function () {
			return _utils.Time.date(this.get('endDay'));
		})
	});
});