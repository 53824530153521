define('busy-app/events/dom', ['exports', 'busy-app/events/emitter'], function (exports, _emitter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emitter.default.extend({
    _resize: function _resize(event) {
      return [event, window.innerWidth, window.innerHeight];
    }
  });
});