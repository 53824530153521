define('busy-app/services/analytics', ['exports', 'busy-app/utils/integrations/google-tag-manager', 'busy-app/utils/integrations/google-maps', 'busy-app/utils/integrations/appcues', 'busy-app/utils/integrations/busy-event', 'busy-app/utils/integrations/full-story', 'busy-app/utils/integrations/intercom', 'busy-app/utils/integrations/receptive'], function (exports, _googleTagManager, _googleMaps, _appcues, _busyEvent, _fullStory, _intercom, _receptive) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Service.extend({
		eventEmitter: Ember.inject.service(),

		init: function init() {
			var _this = this;

			this._super.apply(this, arguments);

			var owner = Ember.getOwner(this);

			// google tab manager should be included first.
			this.TagManager = _googleTagManager.default.create(owner.ownerInjection());

			// google maps
			this.GoogleMaps = _googleMaps.default.create(owner.ownerInjection());

			// more integrations
			this.Intercom = _intercom.default.create(owner.ownerInjection());
			this.Busylitics = _busyEvent.default.create(owner.ownerInjection());

			this.Appcues = _appcues.default.create(owner.ownerInjection());

			_fullStory.default.create(owner.ownerInjection());
			_receptive.default.create(owner.ownerInjection());

			// listener for route transitions
			this.get('eventEmitter').on('router', 'transition', function () {
				return _this.updateRoute();
			});
		},
		updateRoute: function updateRoute() {
			var _this2 = this;

			this.TagManager.update();
			this.Intercom.update();
			this.Busylitics.pageView();

			Ember.run.next(function () {
				_this2.Appcues.start();
			});
		},
		updateCompany: function updateCompany(companyInfo) {
			this.Intercom.updateCompany(companyInfo);
		},
		addEvent: function addEvent(eventType, params) {
			this.Busylitics.send(eventType, params);
		},
		generateTitle: function generateTitle(pathName) {
			this.Busylitics.generateTitle(pathName);
		},
		addPageView: function addPageView(title) {
			this.Busylitics.pageView(title);
		},
		openIntercom: function openIntercom() {
			this.Intercom.show();
			return this;
		},
		closeIntercom: function closeIntercom() {
			this.Intercom.hide();
			return this;
		}
	});
});