define('busy-app/components/company/permission-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var kState = {
		_self: 1,
		_lower: 2,
		_same: 4,
		_higher: 8
	};

	/**
  *
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['c-company-permission-dialog'],

		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		headerTitle: '',

		position: null,
		timeEventDisableLower: false,
		timeEventDisableSame: false,
		timeEventDisableHigher: false,

		showUpgradeDialog: null,

		init: function init() {
			this._super.apply(this, arguments);
			this.disableSelfTimeEvent();
		},


		isDisabledAll: Ember.computed('position.canEditPosition', 'features.customRoles', function () {
			// disable dialog changes for free account
			if (!this.get('features.customRoles')) {
				return true;
			}

			var isDisabled = false;
			if (!Ember.isNone(this.get('position'))) {
				isDisabled = !this.get('features').isEnabled('customRoles') || !this.get('position.canEditPosition');
			}
			return isDisabled;
		}),

		isDisabledManageEmployeeGroup: Ember.computed('position.scopeByGroup', 'isDisabledAll', function () {
			return this.get('isDisabledAll') || this.get('position.scopeByGroup');
		}),

		disableSelfTimeEvent: Ember.observer('position.manageTimeEntries', function () {
			var _this = this;

			var position = this.get('position');

			if (!Ember.isNone(position)) {
				var value = position.parseSetting(position.get('manageTimeEntries'));
				var newTimeEvents = position.get('timeEvents');

				if (value.lower) {
					newTimeEvents = newTimeEvents | kState._lower;
				}
				if (value.same) {
					newTimeEvents = newTimeEvents | kState._same;
				}
				if (value.higher) {
					newTimeEvents = newTimeEvents | kState._higher;
				}

				position.set('timeEvents', newTimeEvents);

				Ember.run.next(function () {
					_this.set('timeEventDisableLower', value.lower);
					_this.set('timeEventDisableSame', value.same);
					_this.set('timeEventDisableHigher', value.higher);
				});
			}
		}),

		activityReportPermission: Ember.computed('position.viewAllActivityReport', function () {
			var viewAllActivityReport = this.get('position.viewAllActivityReport');
			if (viewAllActivityReport) {
				return (0, _utils.loc)('All Activity Reports - Web & Mobile');
			} else {
				return (0, _utils.loc)('Employee Report Only - Mobile Only');
			}
		}),

		actions: {
			cancelAction: function cancelAction() {
				this.sendAction('onClose');
			},
			saveAction: function saveAction() {
				var _this2 = this;

				var position = this.get('position');

				if (Ember.isEmpty(position.get('title')) || Ember.isNone(position.get('level'))) {
					return Ember.RSVP.reject((0, _utils.loc)('Please fill out the required fields.'));
				} else if (position.get('level') === 1) {
					return Ember.RSVP.reject((0, _utils.loc)("You can only view the Admin Position. Changes will not be saved."));
				} else {
					position.set('manageProjectGroups', position.get('manageProjects'));

					if (position.get('timeEvents') < 1) {
						position.set('timeEvents', 1);
					}

					if (position.get('viewMemberActivityReport') < 1) {
						position.set('viewMemberActivityReport', 1);
					}

					return position.save().then(function (item) {
						return _this2.sendAction('onSave', item);
					}).catch(function () {
						return Ember.RSVP.reject((0, _utils.loc)('Something went wrong. Please try again Later.'));
					});
				}
			},
			deleteAction: function deleteAction() {
				var _this3 = this;

				var position = this.get('position');

				if (position.get('level') === 1) {
					return Ember.RSVP.reject((0, _utils.loc)("The Admin Position cannot be deleted."));
				} else {
					if (!position.get('isDeleted')) {
						position.deleteRecord();
					}

					return position.save().then(function (item) {
						return _this3.sendAction('onDelete', item);
					}).catch(function (err) {
						var errorMessage = 'Something went wrong. Please try again Later.';
						var errCode = Ember.get(err, 'errors.firstObject.code');

						if (errCode === 599) {
							// const numMembers = get(position, 'members.length');
							errorMessage = "You can't delete a position that has members assigned to it.";
						}

						return Ember.RSVP.reject((0, _utils.loc)(errorMessage));
					});
				}
			},
			changeActivityReportPermission: function changeActivityReportPermission(key) {
				var position = this.get('position');
				this.set('activityReportPermission', key);
				if (key === 'All Activity Reports - Web & Mobile') {
					position.set('viewAllActivityReport', true);
					position.set('viewMemberActivityReport', 15);
				} else if (key === 'Employee Report Only - Mobile Only') {
					position.set('viewAllActivityReport', false);
				}
			},
			showUpgradeDialog: function showUpgradeDialog() {
				this.sendAction('showUpgradeDialog');
			},


			// disable the "Manage Employee Groups" option when "Limit permissions to employee groups" is selected
			onChangeScopeByGroup: function onChangeScopeByGroup(val) {
				if (val) {
					this.set('position.manageEmployeeGroups', false);
				}
			},


			// when dailyProjectPrompts is disabled, also disable dailyProjectPromptsUseSubprojects
			onChangeDailyProjectPrompts: function onChangeDailyProjectPrompts(val) {
				Ember.set(this, 'position.dailyProjectPrompts', val);

				if (!val) {
					Ember.set(this, 'position.dailyProjectPromptsUseSubprojects', false);
				}
			}
		}
	});
});