define('busy-app/components/selectors/checkbox-select', ['exports', '@busy-web/components/components/bc-select-menu'], function (exports, _bcSelectMenu) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _bcSelectMenu.default.extend({
		classNames: ['c-selectors-checkbox-select'],

		/**
   * change event listener called when
   * a checkbox is toggled on or off
   *
   * action event
   *
   * @public
   * @property onChange
   * @type {function|string}
   */
		onChange: null,

		/**
   * Property to stop the select menu from allowing changes
   * to the menu title
   *
   * this should never be set to false for this class
   *
   * @private
   * @property disableChange
   * @type {boolean}
   */
		disableChange: true,

		/**
   * overridden method of the bc-select-menu class
   *
   * @private
   * @method createOption
   * @param el {object} jquery input element
   * @returns {object}
   */
		createOption: function createOption(el) {
			// create the option object
			return Ember.Object.create({
				label: el.attr('name'),
				value: el.val(),
				selected: el.is(':checked'),
				disabled: el.is(':disabled')
			});
		},


		actions: {
			/**
    * change event callback for checkboxes
    *
    * @private
    * @method changeAction
    * @param item {object}
    * @returns {void}
    */
			changeAction: function changeAction(item) {
				if (!item.get('disabled')) {
					var selected = !Ember.get(item, 'selected');
					Ember.set(item, 'selected', selected);
					this.sendAction('onChange', Ember.get(item, 'value'), selected);
				}
			}
		}
	});
});