define('busy-app/controllers/report/time-entries', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({

		queryParams: ['project', 'sort_order'],

		auth: Ember.inject.service(),
		payPeriods: Ember.inject.service('pay-period'),
		proFeature: Ember.inject.service('pro-feature'),

		project: null,
		start: 0,
		end: 0,
		sort_order: 'desc',

		optionSelector: null,
		isCSVLoading: false,
		proFeatureManager: null,

		init: function init() {
			this._super();
			this.setPeriod();
			this.set('optionSelector', [Ember.Object.create({ key: 'export', value: (0, _utils.loc)('Export') })]);

			this.set('proFeatureManager', this.get('proFeature').getManager());
		},


		setPeriod: Ember.observer('auth.organizationPayPeriod.@each.id', function () {
			if (!Ember.isEmpty(this.get('auth.organizationPayPeriod')) && this.get('start') === 0) {
				var currentPeriod = this.get('payPeriods').getPayPeriod(_utils.Time.timestamp());
				var previousPeriod = this.get('payPeriods').getPayPeriod(currentPeriod.get('startDate').subtract(1, 'days').unix());

				this.set('start', previousPeriod.get('start'));
				this.set('end', previousPeriod.get('end'));
			}
		}),

		reportTitle: Ember.computed('model.project', function () {
			if (!Ember.isNone(this.get('model.project'))) {
				return (0, _utils.loc)("Project Time Entries");
			}

			return (0, _utils.loc)("Time Entries");
		}),

		exportReport: function exportReport() {
			var _this = this;

			this.set('isCSVLoading', true);

			var start = this.get('start');
			var end = this.get('end');

			this.store.exportCSV('time-card-report-csv', start, end, null, this.get('model.project')).then(function () {
				_this.set('isCSVLoading', false);
			});
		},


		actions: {
			changeDateRange: function changeDateRange(start, end) {
				this.set('start', start);
				this.set('end', end);

				this.transitionToRoute('report.time-entries', {
					queryParams: {
						start: start,
						end: end,
						sort_order: this.get('sort_order'),
						project: this.get('project')
					}
				});
				Ember.getOwner(this).lookup('route:report.time-entries').refresh();
			},
			optionChange: function optionChange() {
				var _this2 = this;

				this.get('proFeatureManager').requestAction('export', function () {
					return _this2.exportReport();
				});
			},
			changeSort: function changeSort(sort) {
				this.set('sort_order', sort);

				this.transitionToRoute('report.time-entries', {
					queryParams: {
						sort_order: sort,
						project: this.get('project')
					}
				});
				Ember.getOwner(this).lookup('route:report.time-entries').refresh();
			},
			proFeatureManagerOpenModal: function proFeatureManagerOpenModal() {
				this.get('proFeatureManager').openModal();
			},
			proFeatureManagerCloseModal: function proFeatureManagerCloseModal() {
				this.get('proFeatureManager').closeModal();
			},
			proFeatureManagerPlanUpgraded: function proFeatureManagerPlanUpgraded() {
				this.get('proFeatureManager').onAllow();
			}
		}
	});
});