define('busy-app/components/company/integrations/sync-log-errors-dialog', ['exports', '@busy-web/utils', 'busy-app/utils/docs'], function (exports, _utils, _docs) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service('auth'),
		store: Ember.inject.service('store'),

		classNames: ['c-company-settings-integrations-dialog'],

		/**
   * Attributes
   */

		organizationIntegration: null,
		headerTitle: (0, _utils.loc)('Error Log'),
		closeText: (0, _utils.loc)('Close'),
		printText: (0, _utils.loc)('Print'),

		/**
   * Properties
   */

		page: 1,
		pageSize: 20,

		init: function init() {
			this._super();

			this.set('integrationAttemptErrors', null);

			return this.getIntegrationErrors();
		},
		getIntegrationErrors: function getIntegrationErrors() {
			var _this = this;

			if (window.runningTests) {
				return Ember.RSVP.resolve([]);
			}

			var query = {
				organization_integration_id: this.get('organizationIntegration.id'),
				success: false,
				page: this.get('page'),
				page_size: this.get('pageSize'),
				_desc: ['end_time', 'id']
			};

			return this.get('store').query('integration-attempt', query).then(function (integrationAttemptErrors) {
				integrationAttemptErrors = integrationAttemptErrors.forEach(function (err) {
					// time display should be in local time
					var startTime = _utils.Time.date(err.get('startTime')).local();
					var endTime = _utils.Time.date(err.get('endTime')).local();

					err.set('localeStartTime', startTime.format("L LTS"));
					err.set('localeEndTime', endTime.format("L LTS"));
				});

				if (!_this.get('isDestroyed')) {
					_this.set('integrationAttemptErrors', integrationAttemptErrors);
				}

				return integrationAttemptErrors;
			});
		},


		/**
   *	Actions
   */
		actions: {
			/**
    * @event onClose
    */
			onClose: function onClose() {
				return this.sendAction('onClose');
			},


			/**
    * @event onPrint
    */
			onPrint: function onPrint() {
				var $container = Ember.$('.v-company-settings-integrations-sync-log-errors');
				_docs.default.print($container);
			}
		}
	});
});