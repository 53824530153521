define('busy-app/components/company/integrations/vendors/quickbooks/grid-item', ['exports', 'busy-app/components/company/integrations/grid/grid-item-base', '@busy-web/utils', 'busy-app/utils/settings'], function (exports, _gridItemBase, _utils, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _gridItemBase.default.extend({
		/**
   * @properties classNames
   * @type {string[]}
   * @default ['grid-item-quickbooks']
   */
		classNames: ['grid-item-quickbooks'],

		/**
   * Label identifying the name of the organization-integration
   *
   * @public
   * @properties integrationName
   * @type {string}
   * @default 'quickbooks'
   */
		integrationName: 'quickbooks',

		/**
   * Ember route to follow when this element is clicked
   *
   * @public
   * @properties dashboardRoute
   * @type {string}
   * @default 'company-settings.intuit-quickbooks'
   */
		dashboardRoute: 'company-settings.intuit-quickbooks',

		/**
   * URL path to the company logo image. Should begin with '/'
   *
   * @public
   * @properties logo
   * @type {string}
   * @default '/images/integration-logos/integration_logos-01.png'
   */
		logo: '/images/integration-logos/integration_logos-01.png',

		/**
   * Feature flag for this integration
   *
   * @public
   * @properties featureFlag
   * @type {string}
   * @default 'quickbooks'
   */
		featureFlag: 'quickbooks',

		isEnabled: Ember.computed('features.quickbooks', function () {
			return this.get('features').isEnabled('quickbooks');
		}),

		/**
  * @private
  * @method init
  * @returns {null}
  */
		init: function init() {
			this._super();
			this.setStatus();
		},


		/**
  * Override inherited `getStatus()`
  *
  * @method getStatus
  * @returns {RSVP}
  */
		getStatus: function getStatus() {
			var _this = this;

			var rpcParams = {
				organization_id: this.get('auth.organization.id')
			};

			this.set('isLoading', true);
			return this.get('integration').rpcRequest('quick-books', 'getStatus', rpcParams, _settings.default.get('qb_url')).then(function (integrationStatus) {
				if (integrationStatus.data === 'connected' || integrationStatus.data === 'pending') {
					return {
						isConnected: true
					};
				}
			}).catch(function (error) {
				return {
					errorType: (0, _utils.loc)('Connection Error'),
					errorMessage: error.statusText
				};
			}).finally(function () {
				if (!_this.get('isDestroyed')) {
					_this.set('isLoading', false);
				}
			});
		}
	});
});