define('busy-app/utils/csv-import', ['exports', '@busy-web/utils', 'busy-app/utils/logger', 'busy-app/utils/settings'], function (exports, _utils, _logger, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/**
  * `CsvRow`
  *
  * Object for parseing csv json data rows
  *
  */
	/**
  * @module Utils
  *
  */
	var CsvRow = Ember.Object.extend({
		/**
   * Holds the CSV data row
   *
   * @private
   * @property _data
   * @type object
   */
		_data: null,

		/**
   * The CsvImport Class that created an instance of this
   *
   * @private
   * @property csvClass
   * @type CsvImport instance
   */
		csvClass: null,

		pushData: function pushData(data) {
			this.set('_data', data);
		},
		get: function get(name) {
			var value = null;
			var headerMap = this.csvClass.get('headerMap');
			var _data = this._data;
			var key = headerMap[name];

			(false && !(!Ember.isNone(key)) && Ember.assert("Invalid Name passed to getVal() - [" + name + "]", !Ember.isNone(key)));


			if (!Ember.isNone(Ember.get(_data, key))) {
				value = Ember.get(_data, key);
			}
			return value;
		}
	});

	/**
  * `Library/CsvImport`
  *
  * base class extendable for specific csv imports
  */
	var CsvImport = Ember.Object.extend(Ember.Evented, {
		/**
   * Ember store intsance passed in on load
   *
   * @public
   * @property store
   * @type object
   */
		store: Ember.inject.service('store'),
		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),

		/**
   * parsed csv models get stored here
   * and can be accessed with instance.get('model')
   *
   * @public
   * @property model
   * @type array
   */
		model: null,

		/**
   * stores the raw csv headers
   *
   * @private
   * @property _rawHeaders
   * @type object
   */
		_rawHeaders: null,

		/**
   * stores the raw csv data
   *
   * @private
   * @property _rawDataRows
   * @type array
   */
		_rawDataRows: null,

		/**
   * stores the data parsed by the csv in a key:value
   * map to prevent duplicates from being created
   *
   * @private
   * @property _dataMap
   * @type Map
   */
		_dataMap: null,

		/**
   * papaparse option for parsing a .csv file
   * set this for the csv file delimiter
   *
   * @public
   * @property delimiter
   * @type string
   * @default |comma|
   */
		delimiter: ',',

		/**
   * papaparse options for parsing a .csv file
   * set this if the csv has a header
   *
   * @public
   * @property hasHeader
   * @type boolean
   * @default true
   */
		hasHeader: true,

		/**
   * papaparse option for parsing a .csv file
   * set this if dynamic typing should be used
   *
   * @public
   * @property useDynamicTyping
   * @type boolean
   * @default true
   */
		useDynamicTyping: true,

		/**
   * Key:value object for maping the headers of the csv
   * to the headers used by default in the defaultMap property.
   *
   * Each row of raw csv data will be a new instance of a CsvRow object.
   * The CsvRow object will use the headerMap to get values from the row
   * based on the key defined in the default map and use its value
   * to get the value from the CsvRow object.
   *
   * @public
   * @property headerMap
   * @type object
   */
		headerMap: null,

		/**
   * Default header map to define the expected rows cell value
   * from the csv.
   *
   * See headerMap for more information.
   *
   * @public
   * @property defaultMap
   * @type object
   */
		defaultMap: Ember.computed(function () {
			return {};
		}),

		/**
   * The authenticated organization that represents
   * the data being imported
   *
   * @public
   * @property organization
   * @type DS.Model
   */
		organization: Ember.computed('auth.organization', function () {
			return this.get('auth.organization');
		}),

		/**
   * The authenticated organization id that represents
   * the data being imported
   *
   * @public
   * @property organizationId
   * @type string
   */
		organizationId: Ember.computed('organization.id', function () {
			return this.get('organization.id');
		}),

		run: function run(file) {
			(false && !(!Ember.isNone(file)) && Ember.assert("You must provide a file before calling run.", !Ember.isNone(file)));


			this.readFile(file);
			return this;
		},
		readFile: function readFile(file) {
			var _this = this;

			// Get the file extension from the FileObject.name
			var extension = file.name.split('.').pop().toLowerCase();

			// lists of the supported file types
			// for excel parse and csv parse
			var isExcel = ['xlsx', 'xls'];
			var isOther = ['csv'];

			// create a FileReader instance
			var reader = new window.FileReader();
			reader.onload = function (evt) {
				var uploadedFile = void 0;
				if (evt.srcElement !== undefined && evt.srcElement.result) {
					// use the proper parse class for the extension
					uploadedFile = evt.srcElement.result;
				} else {
					uploadedFile = evt.target.result;
				}

				if (isExcel.indexOf(extension) !== -1) {
					_this.parseXLSFile(uploadedFile);
				} else {
					_this.parseCSVFile(uploadedFile);
				}
			};

			// read the file as binary for xls types
			if (isExcel.indexOf(extension) !== -1) {
				reader.readAsBinaryString(file);
			} else if (isOther.indexOf(extension) !== -1) {
				reader.readAsText(file);
			} else {
				// throw not accepted file type error
				this.triggerError('File type [' + extension + '] not accepted!');
			}
		},
		parseXLSFile: function parseXLSFile(file) {
			var XLSX = window.XLSX;
			var result = XLSX.read(file, { type: 'binary' });
			var sheet = result.Sheets[result.SheetNames[0]];
			var json = XLSX.utils.sheet_to_json(sheet);

			var prepJSON = [];
			Object.keys(json).forEach(function (key) {
				var item = Ember.get(json, key);
				var obj = {};
				Object.keys(item).forEach(function (name) {
					var value = Ember.get(item, name);
					Ember.set(obj, name.trim().replace(/ {2}/g, ' '), value);
				});
				prepJSON.push(obj);
			});
			this.prepareJSON(prepJSON);
		},
		parseCSVFile: function parseCSVFile(file) {
			// remove empty lines
			file = file.replace(/^(,,)+$/mg, function () {
				return '';
			});

			// remove whitespace
			file = file.replace(/((,[ ]+)|([ ]+,))/g, ',');
			file = file.replace(/(("[ ]+)|([ ]+"))/g, '"');
			file = file.replace(/ {2}/g, ' ');

			var options = {
				delimiter: this.get('delimiter'),
				header: this.get('hasHeader'),
				dynamicTyping: this.get('useDynamicTyping')
			};

			var result = window.Papa.parse(file, options);
			this.prepareJSON(this.removeInvalid(result.data));
		},
		removeInvalid: function removeInvalid(data) {
			var res = [];
			var cols = Object.keys(data[0]);
			Ember.$.each(data, function (k, v) {
				if (data.hasOwnProperty(k)) {
					var isValid = true;
					Ember.$.each(cols, function (key, val) {
						if (cols.hasOwnProperty(key)) {
							if (Ember.get(v, val) === undefined) {
								isValid = false;
							}
						}
					});

					if (isValid) {
						res.push(v);
					}
				}
			});
			return res;
		},
		prepareJSON: function prepareJSON(jsonArray) {
			var headers = Object.keys(jsonArray[0]);
			this.set('_rawHeaders', headers);
			this.set('_rawDataRows', jsonArray);
			this.onFinishFileParse(headers, jsonArray);
			return this;
		},
		parse: function parse() {
			var _this2 = this;

			var data = this.get('_rawDataRows');
			this.set('model', []);
			this._dataMap = Ember.Map.create();
			this.set('hasError', false);

			var finishedItems = 0;
			Ember.$.each(data, function (key, rowData) {
				if (data.hasOwnProperty(key) && !_this2.get('hasError')) {
					var row = CsvRow.create({ csvClass: _this2 });
					row.pushData(rowData);

					_this2.extractData(row);

					finishedItems++;
					if (finishedItems >= data.length) {
						_this2.onDoneParsing();
					}
				}
			});
			return this;
		},
		applyTransform: function applyTransform(model, data, name) {
			var value = data.get(name) || null;

			if (/\u00a0/.test(value)) {
				_logger.default.warn('Non Breaking Space detected', value);
			}

			if (!Ember.isNone(value) && typeof value === 'string') {
				value = this.csvSafeString(value);
			}

			if (/\u00a0/.test(value)) {
				_logger.default.warn('Non Breaking Space removed', value);
			}

			var transform = this.get(name + 'Transform');
			if (!Ember.isNone(transform)) {
				value = transform.call(this, value, model);
			}

			model.set(name, value);
			return value;
		},
		csvSafeString: function csvSafeString(str) {
			// replace non breaking spaces with regular space
			str = str.replace(/\u00a0/g, ' ');

			// remove begining and end quotes
			str = str.replace(/^"/, '');
			str = str.replace(/"$/, '');

			// escape any quotes left inside the string
			str = str.replace(/""/g, '"');

			// remove invalid char
			str = str.replace(/\uFFFD/g, '');

			// add the begining and ending quotes
			return str;
		},
		extractData: function extractData(data) {
			var _this3 = this;

			var model = this.createNewModel();
			var _map = this.get('headerMap');
			Ember.$.each(_map, function (key) {
				if (_map.hasOwnProperty(key) && !_this3.get('hasError')) {
					_this3.applyTransform(model, data, key);
				}
			});

			if (!this.hasModel(model) && this.modelHasData(model)) {
				this.get('model').pushObject(model);
			}
			return this;
		},
		hasModel: function hasModel(model) {
			(false && !(false) && Ember.assert("You must override this function to return a boolean", false));

			return model;
		},
		modelHasData: function modelHasData(model) {
			(false && !(false) && Ember.assert("You must override this function to return a boolean", false));

			return model;
		},
		createNewModel: function createNewModel() {
			(false && !(false) && Ember.assert("createNewModel should be overriden to return a DS.Model instance", false));
		},
		setHeaderMap: function setHeaderMap(headerMap) {
			var _map = this.get('defaultMap');

			if (!Ember.isNone(headerMap)) {
				Ember.$.each(headerMap, function (key, item) {
					if (headerMap.hasOwnProperty(key)) {
						Ember.set(_map, key, item);
					}
				});
			}
			this.set('headerMap', _map);
			return _map;
		},
		triggerError: function triggerError(error) {
			this.set('hasError', true);
			this.trigger('error', error);
		},
		onFinishFileParse: function onFinishFileParse(headers, data) {
			this.trigger('onFinishFileParse', headers, data);
		},
		onDoneParsing: function onDoneParsing() {
			this.trigger('done', this.get('model'));
		},
		saveModel: function saveModel() {
			var _this4 = this;

			var models = this.get('model');
			return this._saveModels(models).then(function (data) {
				_this4.trigger('complete');
				return data;
			});
		},
		_saveModels: function _saveModels(models) {
			var _this5 = this;

			return new Ember.RSVP.Promise(function (resolve) {
				var success = [];

				// sort the models so the batch saves
				// in the correct order
				models = _this5.sortForSave(models);

				// before save gives pre dependent models a chance to save first.
				_this5.beforeSave(models).then(function () {
					var savePromise = [];
					models.forEach(function (model) {
						var savePromiseObj = _this5.saveModelRetry(model).then(function (savedModel) {
							// store the savedModel
							success.pushObject(savedModel);
							return savedModel;
						});
						savePromise.push(savePromiseObj);
					});

					Ember.RSVP.all(savePromise).then(function () {
						var afterPromise = [];
						models.forEach(function (model) {
							// allow dependent models to save
							_this5.saveDependentModel(model);

							// call afterSave method
							var afterPromiseObj = _this5.afterSave(model);

							// store the promise for later
							afterPromise.push(afterPromiseObj);
						});

						// after all the promises are fullfilled
						// resolve the _saveModels promise.
						Ember.RSVP.all(afterPromise).then(function () {
							resolve(success);
						});
					});
				});
			});
		},
		saveModelRetry: function saveModelRetry(model) {
			var _this6 = this;

			return this.onSaveModel(model).catch(function (err) {
				if (err && err.errors) {
					// TODO:
					// change one of these to the err code that drew puts for
					// invalid uuid.
					if (err.errors[0] === 300 || err.errors[0] === 300) {
						model.id = _utils.UUID.generate();
						return _this6.saveModelRetry(model);
					}
				}
				return Ember.RSVP.reject(err);
			});
		},
		onSaveModel: function onSaveModel(model) {
			return model.saveBatch(true);
		},
		beforeSave: function beforeSave() /*model*/{
			return new Ember.RSVP.resolve({});
		},
		afterSave: function afterSave() /*model*/{
			return new Ember.RSVP.resolve({});
		},
		saveDependentModel: function saveDependentModel(model) {
			return model;
		},
		sortForSave: function sortForSave(models) {
			return models;
		},
		prepareRequests: function prepareRequests(req) {
			var requests = {};
			Ember.$.each(req, function (key, val) {
				if (req.hasOwnProperty(key)) {
					var id = val.key;
					delete val.key;
					Ember.set(requests, id, val);
				}
			});
			return { requests: requests };
		},
		prepareObject: function prepareObject(type, data) {
			var obj = {
				method: "POST",
				data: data,
				url: type,
				key: type + '_' + data.id
			};

			if (_settings.default.get('api_version') >= 3.2) {
				obj._version = _settings.default.get('api_version');
			} else {
				obj.version = _settings.default.get('api_version');
			}

			if (_settings.default.get('debug_mode')) {
				obj._debug = true;
			}
			return obj;
		},
		sendRequest: function sendRequest(data, pageSize) {
			this.trigger('progress', data.length, data.length);
			return this.processRequest(data, data.length, pageSize);
		},
		processRequest: function processRequest(data, total, pageSize) {
			var _this7 = this;

			pageSize = pageSize || 20;
			if (data.length === 0) {
				return Ember.RSVP.resolve(true);
			}

			var processData = data.splice(0, pageSize);
			processData = this.prepareRequests(processData);
			return this.get('store').rpcRequest('batch', 'batch-rest', processData).then(function () {
				_this7.trigger('progress', data.length, total);
				return _this7.processRequest(data, total, pageSize);
			});
		}
	});

	exports.default = CsvImport;
});