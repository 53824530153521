define('busy-app/controllers/report/time-card/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service('auth'),
		access: Ember.inject.service('access'),

		queryParams: ['start', 'end'],

		start: 0,
		end: 0,

		showTimeEntryEdit: false,
		editEntry: null,
		showTimeOffEdit: false,
		editTimeOffEntry: null,

		actions: {
			openTimeEntryAction: function openTimeEntryAction(entry) {
				if (this.get('access').canTakeActionFor('manageTimeEntries', entry.get('model.member'))) {
					if (entry.get('isTimeEntry')) {
						this.set('editEntry', entry.get('model'));
						this.set('showTimeEntryEdit', true);
					} else {
						this.set('editTimeOffEntry', entry.get('model'));
						this.set('showTimeOffEdit', true);
					}
				}
			},
			openTimeCard: function openTimeCard(model) {
				this.transitionToRoute('time-card.full-report', {
					queryParams: {
						start: model.get('start'),
						end: model.get('end'),
						member_id: model.get('member.id')
					}
				});
			},
			closeEditDialog: function closeEditDialog() {
				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);
			},
			itemSaved: function itemSaved() {
				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);
				Ember.getOwner(this).lookup('route:report/time-card').refresh();
			},
			itemDeleted: function itemDeleted() {
				this.set('showTimeEntryEdit', false);
				this.set('showTimeOffEdit', false);
				this.set('editEntry', null);
				this.set('editTimeOffEntry', null);
				Ember.getOwner(this).lookup('route:report/time-card').refresh();
			}
		}
	});
});