define('busy-app/utils/unique-id', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = uniqueId;


	/**
  * gets a value or returns 0
  *
  * @private
  * @method getValue
  * @param target {Instance} a class instance or object
  * @param key {string} the property to get the value for
  * @param {mixed} returns 0 if value is null or undefined
  */
	function getValue(target, key) {
		var value = Ember.get(target, key);
		if (Ember.isNone(value)) {
			return 0;
		}
		return value;
	}

	/**
  * returns a uniqueId based on the property values retrieved from the props passed in.
  *
  * @public
  * @method uniqueId
  * @param keysArray {string[]}
  * @returns {string} computed property string
  */
	/**
  * @module Utils
  *
  */
	function uniqueId(keysArray) {
		// get keysArray from arg list or use
		// keysArray as an array if passed in that way.
		if (!Array.isArray(keysArray)) {
			var args = [].slice.call(arguments);
			if (args.length > 0) {
				keysArray = args;
			}
		}

		// make sure keysArray is an array
		(false && !(Array.isArray(keysArray) && keysArray.length > 0) && Ember.assert("uniqueId requires an array of props to generate id with", Array.isArray(keysArray) && keysArray.length > 0));


		// this is a computed property only because the value has to be created on the fly
		// this should not be converted into a computed property that has listeners.
		return Ember.computed(function () {
			var _this = this;

			return keysArray.reduce(function (a, b) {
				return a + '_' + getValue(_this, b, 0);
			}, 'uid');
		});
	}
});