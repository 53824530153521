define('busy-app/collections/custom-wage-date/payroll-member', ['exports', 'busy-app/collections/base', 'busy-app/collections/custom-wage-date/utils'], function (exports, _base, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		/**
   * Use this function to rebuild the data structure
   * returned from the getter calls
   *
   * @public
   * @method buildModels
   * @param data {object} keyed model object
   * @returns {array|object}
   */
		buildModels: function buildModels(model) {
			var _this = this;

			var data = [];

			// get payPeriodType
			var payPeriodType = model.organizationPayPeriod.objectAt(0).get('payPeriodType');

			// add member array
			model.member.forEach(function (item) {
				_this.buildModel(item, model, data, payPeriodType);
			});

			// add archivedMember array
			model.archivedMember.forEach(function (item) {
				_this.buildModel(item, model, data, payPeriodType);
			});

			this.setAggregates(data);

			return data.sortBy('member.fullName');
		},
		buildModel: function buildModel(item, model, data, payPeriodType) {
			var wageHistory = (0, _utils.getWageHistory)(item, Ember.get(this, 'start'), Ember.get(this, 'end'));
			var wage = Ember.get(wageHistory, 'lastObject');
			var isSalary = !Ember.isNone(wage) && wage.get('isSalary');

			var hasMultipleWage = Ember.get(wageHistory, 'length') !== 1;
			var hasSalary = wageHistory.any(function (w) {
				return Ember.get(w, 'isSalary');
			});
			var hasExceptionalWageChange = hasMultipleWage && hasSalary;

			var seconds = model.payPeriodMemberProjectSeconds.filterBy('memberId', item.id);
			var cost = model.payPeriodMemberProjectCost.filterBy('memberId', item.id);
			var hasSeconds = seconds.any(function (s) {
				return Ember.get(s, 'totalSeconds') > 0;
			});

			// add members with time clocked or members with salary that may not have any time clocked.
			if (hasSeconds || isSalary) {
				// each member needs a total seconds to reference for calculating saly employees.
				var memberSeconds = this.createPolymorph('pay-period-member');
				memberSeconds.set('seconds', model.payPeriodMemberProjectSeconds.filterBy('memberId', item.id));
				item.set('seconds', memberSeconds);

				cost.forEach(function (costItem) {
					if (Ember.get(costItem, 'totalCost') > 0) {
						var costWage = wageHistory.find(function (wageItem) {
							return Ember.get(costItem, 'startTime') >= Ember.get(wageItem, 'changeDate') && Ember.get(costItem, 'endTime') <= Ember.get(wageItem, 'endDate');
						});
						Ember.setProperties(costItem, { wage: costWage });
					}
				});

				var poly = this.createPolymorph('custom-wage-date/payroll-member');
				Ember.setProperties(poly, {
					member: item,
					seconds: seconds,
					cost: cost,
					wage: wage,
					wageHistory: wageHistory,
					hasMultipleWage: hasMultipleWage,
					hasExceptionalWageChange: hasExceptionalWageChange,
					payPeriodType: payPeriodType
				});

				data.pushObject(poly);
			}
		},
		setAggregates: function setAggregates(data) {
			var regularSeconds = 0;
			var overtimeSeconds = 0;
			var doubletimeSeconds = 0;
			var totalSeconds = 0;
			var estCostSalary = 0;
			var estCostHourly = 0;

			data.forEach(function (item) {
				regularSeconds += item.get('regularSeconds');
				overtimeSeconds += item.get('overtimeSeconds');
				doubletimeSeconds += item.get('doubletimeSeconds');
				totalSeconds += item.get('totalSeconds');

				if (item.get('wage.wageRate') !== 10) {
					estCostSalary += item.get('totalOverburdenCost');
				} else {
					estCostHourly += item.get('totalOverburdenCost');
				}
			});

			// round the salary and hourly before adding the total
			// so the numbers add correctly in the interface.
			estCostSalary = estCostSalary.toFixed(2);
			estCostHourly = estCostHourly.toFixed(2);

			// to fixed creates a string so parseFloat befaore adding the values.
			var total = parseFloat(estCostSalary) + parseFloat(estCostHourly);

			this.set('regularSeconds', regularSeconds);
			this.set('overtimeSeconds', overtimeSeconds);
			this.set('doubletimeSeconds', doubletimeSeconds);
			this.set('totalSeconds', totalSeconds);
			this.set('estCostSalary', estCostSalary);
			this.set('estCostHourly', estCostHourly);
			this.set('totalCost', total);
		},


		/**
   * Collections may have a predefined model
   * structure for reusability. This is not required
   * so this function may be removed if not used.
   *
   * @public
   * @method model
   * @return {EmberPromise}
   */
		model: function model(start, end, memberId) {
			this.set('start', start);
			this.set('end', end);

			var query = {
				start_time: start,
				end_time: end
			};

			// filter by member id
			if (!Ember.isNone(memberId)) {
				query.member_id = memberId;
			}

			var memberQuery = { archived_on: null };
			var archivedMembersQuery = { _gte: { archived_on: start } };

			if (Ember.get(this, 'auth.useGroupPermissionScoping')) {
				var memberGroupId = Ember.get(this, 'auth.member.memberGroupId');

				if (Ember.isNone(memberGroupId)) {
					var authMemberId = Ember.get(this, 'auth.memberId');
					Ember.setProperties(query, { member_id: authMemberId });
					Ember.setProperties(memberQuery, { id: authMemberId });
					Ember.setProperties(archivedMembersQuery, { id: authMemberId });
				} else {
					Ember.setProperties(query, { member_group_id: memberGroupId });
					Ember.setProperties(memberQuery, { member_group_id: memberGroupId });
					Ember.setProperties(archivedMembersQuery, { member_group_id: memberGroupId });
				}
			}

			return this.getter.findAll('pay-period-member-project-seconds', query).findAll('pay-period-member-project-cost', query).findAll('organization-pay-period', { _lte: { start_date: start }, _desc: ['start_date'] }).findAll('member', memberQuery).findAll('member', archivedMembersQuery, 'archivedMember').joinAll('wage-history', 'member', 'id', { deleted_on: null }).joinAll('wage-history', 'archivedMember', 'id', { deleted_on: null }).outerJoin('project', 'pay-period-member-project-seconds', 'project_id').fetch();
		}
	});
});