define('busy-app/controllers/report/safety-signature/date', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['date'],

    auth: Ember.inject.service('auth'),

    date: 0,

    showSummaryDialog: false,
    settings: null,

    init: function init() {
      this._super();
      this.setTotals();
      this.setupSummaryView(Ember.get(this, 'date') || _utils.Time.timestamp());
    },
    setupSummaryView: function setupSummaryView(date) {
      var start = _utils.Time.date(date).startOf('day').unix();
      var end = _utils.Time.date(date).endOf('day').unix();
      var settings = Ember.Object.create({
        start: start,
        end: end,
        type: 'member',
        column: 'total-hours',
        totalHours: true,
        hideMemberName: true
      });

      Ember.set(this, 'settings', settings);
    },


    dayTitle: Ember.computed('date', function () {
      var date = parseInt(this.get('date'), 10);
      var format = "MMM D";
      if (_utils.Time.date(date).year() !== _utils.Time.date().year()) {
        format = "MMM D, YYYY";
      }
      return _utils.Time.date(date).format(format);
    }),

    setTotals: Ember.observer('model', function () {
      var model = this.get('model');
      var notSigned = 0;
      var incorrectTime = 0;
      var injured = 0;
      var breakIssues = 0;
      if (this.get('model.length') > 0) {
        model.forEach(function (item) {
          if (item.get('incorrectTime') === 'Yes') {
            incorrectTime += 1;
          }
          if (item.get('injured') === 'Yes') {
            injured += 1;
          }
          if (item.get('injured') === '-' && item.get('incorrectTime') === '-' && item.get('breakPolicyFollowed') === '-') {
            notSigned += 1;
          }
          if (item.get('breakPolicyFollowed') === 'Yes') {
            breakIssues += 1;
          }
        });
      }
      this.setProperties({
        notSigned: notSigned,
        incorrectTime: incorrectTime,
        injured: injured,
        breakIssues: breakIssues
      });
    }),

    needsMoreSigs: Ember.computed('currentMember', function () {
      var member = Ember.get(this, 'currentMember');

      if (Ember.isPresent(member)) {
        var lastSig = member.signatures[member.signatures.length - 1];
        var lastEntry = member.timeEntries[member.timeEntries.length - 1];

        return lastEntry.get('endTime') > lastSig.get('endTime');
      }
      return false;
    }),

    actions: {
      openMemberDialog: function openMemberDialog(member) {
        Ember.set(this, 'currentMember', member);
        Ember.set(this, 'showMemberDialog', true);
      },
      closeMemberDialog: function closeMemberDialog() {
        Ember.set(this, 'currentMember', null);
        Ember.set(this, 'showMemberDialog', false);
      },
      signatureRowClicked: function signatureRowClicked(sig) {
        if (!Ember.isNone(Ember.get(sig, 'showDetails'))) {
          Ember.set(sig, 'showDetails', !sig.get('showDetails'));
        } else {
          Ember.set(sig, 'showDetails', true);
        }
      },
      viewTimeEntries: function viewTimeEntries(date) {
        var start = _utils.Time.date(date).startOf('day').unix();
        var end = _utils.Time.date(date).endOf('day').unix();
        var settings = Ember.get(this, 'settings');

        Ember.setProperties(settings, { start: start, end: end });
        Ember.set(this, 'summaryModel', Ember.get(this, 'currentMember'));
        Ember.set(this, 'showSummaryDialog', true);
      },
      closeSummaryDialog: function closeSummaryDialog() {
        Ember.set(this, 'showSummaryDialog', false);
        Ember.set(this, 'summaryModel', null);
      },
      showSafetyDialog: function showSafetyDialog() {
        Ember.set(this, 'showSignOffRequiredDialog', true);
      },
      closeSafetyDialog: function closeSafetyDialog() {
        Ember.set(this, 'showSignOffRequiredDialog', false);
      }
    }
  });
});