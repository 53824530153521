define('busy-app/components/time/full-report/signatures', ['exports', '@busy-web/utils', 'busy-app/utils/logger'], function (exports, _utils, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service(),
		signature: Ember.inject.service(),

		model: null,
		isSupervisor: null,

		showSignaturePad: false,
		showSupervisorSignaturePad: false,
		signatureSvg: null,
		signaturePadCommand: null,

		hasOpenTimeEntries: Ember.computed.bool('model.hasOpenTime'),

		showEmployeeSignature: Ember.computed.bool('model.memberTimeDocument.hasValidSelfSignature'),
		requestEmployeeSignature: Ember.computed('signature.isTimecardSigningEnabled', 'model.{currentPeriod.isSignable,memberTimeDocument.hasValidSelfSignature}', function () {
			return this.get('signature.isTimecardSigningEnabled') && this.get('model.currentPeriod.isSignable') && !this.get('model.memberTimeDocument.hasValidSelfSignature');
		}),

		showSupervisorSignature: Ember.computed.bool('model.memberTimeDocument.hasValidAuthoritativeSignature'),
		requestSupervisorSignature: Ember.computed('isSupervisor', 'signature.isTimecardSigningEnabled', 'model.{currentPeriod.isSignable,memberTimeDocument.hasValidAuthoritativeSignature}', function () {
			return this.get('isSupervisor') && this.get('signature.isTimecardSigningEnabled') && this.get('model.currentPeriod.isSignable') && !this.get('model.memberTimeDocument.hasValidAuthoritativeSignature');
		}),

		showOpenTimeEntriesWarning: Ember.computed('hasOpenTimeEntries', 'showSignaturePad', 'showSupervisorSignaturePad', function () {
			return this.get('hasOpenTimeEntries') && (this.get('showSignaturePad') || this.get('showSupervisorSignaturePad'));
		}),

		acceptSignature: function acceptSignature(dialog, signatureType) {
			var _this = this;

			var signatureSvg = this.get('signatureSvg');
			var model = this.get('model');
			var memberTimeDocument = this.get('model.memberTimeDocument');

			_logger.default.log(this, 'acceptSignature', { dialog: dialog, signatureType: signatureType, signatureSvg: signatureSvg, memberTimeDocument: memberTimeDocument, model: model });

			// Assert.isString(signatureSvg);
			_utils.Assert.isObject(memberTimeDocument);

			var signatureObject = this.get('signature').createSignature(signatureType, memberTimeDocument, signatureSvg);

			dialog.showSavingMessage();

			if (Ember.isNone(signatureSvg)) {
				return dialog.showErrorMessage((0, _utils.loc)('Please sign the above pad.'));
			} else {
				var saveMethod = signatureType === 'selfSignature' ? 'attachSignatureAndSave' : 'attachSupervisorSignatureAndSave';

				return this.get('signature')[saveMethod](memberTimeDocument, signatureObject).catch(function (err) {
					_logger.default.error(_this, { err: err });

					return Ember.RSVP.reject(err);
				}).then(function () {
					return dialog.showSavedMessage('', 0);
				}).then(function () {
					return _this.sendAction('refreshRoute');
				}).then(function () {
					return _this.send('closeSignaturePad');
				});
			}
		},


		actions: {
			openSignaturePad: function openSignaturePad() {
				this.set('showSignaturePad', true);
			},
			acceptSignature: function acceptSignature(dialog) {
				return this.acceptSignature(dialog, 'selfSignature');
			},
			openSupervisorSignaturePad: function openSupervisorSignaturePad() {
				this.set('showSupervisorSignaturePad', true);
			},
			acceptSupervisorSignature: function acceptSupervisorSignature(dialog) {
				return this.acceptSignature(dialog, 'authoritativeSignature');
			},
			closeSignaturePad: function closeSignaturePad() {
				this.set('showSignaturePad', false);
				this.set('showSupervisorSignaturePad', false);
			},
			clearSignaturePad: function clearSignaturePad() {
				this.set('signaturePadCommand', { command: 'clear' });
			},
			onSignatureData: function onSignatureData(signatureSvg) {
				this.set('signatureSvg', signatureSvg);
			}
		}

	});
});