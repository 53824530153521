define('busy-app/controllers/report/safety-signature', ['exports', '@busy-web/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service(),
    payPeriods: Ember.inject.service('pay-period'),
    router: Ember.inject.service('router'),

    start: null,
    currentPeriodStart: null,
    reportName: (0, _utils.loc)('Daily Sign-Off'),
    cController: null,

    init: function init() {
      this._super();
      this.setPeriod();
    },


    hideDateSelector: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName') === 'report.safety-signature.date';
    }),

    dateTitle: Ember.computed('cController.dayTitle', function () {
      return this.get('cController.dayTitle');
    }),

    setPeriod: Ember.observer('auth.organizationPayPeriod.@each.id', function () {
      if (Ember.isNone(this.get('start')) && !Ember.isEmpty(this.get('auth.organizationPayPeriod'))) {
        var start = _utils.Time.date().startOf('day').unix();

        var currentPeriod = this.get('payPeriods').getPayPeriod(start);
        this.set('start', currentPeriod.get('start'));
      }
    }),

    actions: {
      changeDateRange: function changeDateRange(item) {
        var _this = this;

        this.set('start', item.get('startDate').unix());
        setTimeout(function () {
          Ember.getOwner(_this).lookup('route:report.safety-signature').refresh();
        }, 500);
      }
    }
  });
});