define('busy-app/components/report/time-card-approval/lock-date', ['exports', '@busy-web/utils'], function (exports, _utils) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Component.extend({
				tagName: 'span',
				classNames: ['lock-date'],
				attributeBindings: ['title'],

				title: (0, _utils.loc)('Lock Date')
		});
});