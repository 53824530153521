define('busy-app/components/time/time-sheet/dates-list', ['exports', 'busy-app/utils/logger', '@busy-web/utils'], function (exports, _logger, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['c-time-sheet--dates-list'],

		// arguments
		memberDaySeconds: null,
		timeOffAggregates: null,
		start: null,
		end: null,
		openDayActivity: null,
		conflicts: null,
		openEntry: null,

		init: function init() {
			this._super();
		},


		reportDates: Ember.computed('start', 'end', function () {
			var start = Ember.get(this, 'start');
			var end = Ember.get(this, 'end');

			// Logger.info(this, 'reportDates', start, end);

			if (Ember.isNone(start) || Ember.isNone(end)) {
				_logger.default.error(this, 'reportDates', 'invalid start or end', { start: start, end: end });
				return [];
			} else {
				var startTime = _utils.Time.date(start).startOf('day');
				var endTime = _utils.Time.date(end).startOf('day');
				var numDays = endTime.diff(startTime, 'days');

				var dates = [];

				for (var i = 0; i <= numDays; i++) {
					dates.push(startTime.clone().add(i, 'days').unix());
				}

				// Logger.info(this, 'reportDates', start, end, dates);

				return dates;
			}
		}),

		reportSeconds: Ember.computed('memberDaySeconds', function () {
			var memberDaySeconds = Ember.get(this, 'memberDaySeconds');
			// Logger.info(this, 'reportSeconds', {memberDaySeconds} );

			if (Ember.isNone(memberDaySeconds) || Ember.isEmpty(memberDaySeconds)) {
				// Logger.warn(this, 'reportSeconds', 'no data', { memberDaySeconds } );
				return [];
			}

			var reportSeconds = memberDaySeconds.map(function (r) {
				var date = Ember.get(r, 'startDate');
				var seconds = Ember.get(r, 'totalSeconds');

				return { date: date, seconds: seconds };
			});

			// Logger.info(this, 'reportSeconds', { reportSeconds } );

			return reportSeconds;
		}),

		reportData: Ember.computed('reportSeconds', 'reportDates', 'timeOffAggregates', function () {
			var reportDates = Ember.get(this, 'reportDates');
			var timeOffAggregates = Ember.get(this, 'timeOffAggregates');
			var reportSeconds = Ember.get(this, 'reportSeconds');

			// Logger.info(this, 'reportData', { reportDates, reportSeconds, timeOffAggregates } );

			var reportData = reportDates.map(function (date) {
				return {
					date: date,
					seconds: 0,
					timeOffEntries: [],
					timeOffSeconds: 0,
					hasPaidTimeOff: false,
					hasUnpaidTimeOff: false
				};
			});

			if (!Ember.isEmpty(reportSeconds) && !Ember.isEmpty(reportData)) {
				reportSeconds.forEach(function (d) {
					var match = reportData.findBy('date', Ember.get(d, 'date')) || {};
					var timeOff = timeOffAggregates.findBy('dateStamp', Ember.get(d, 'date'));

					if (!Ember.isNone(match)) {
						Ember.setProperties(match, {
							seconds: Ember.get(match, 'seconds') + Ember.get(d, 'seconds'),
							hasAggregate: true
						});
					}

					if (!Ember.isNone(timeOff)) {
						if (Ember.isNone(match)) {
							Object.assign(match, {
								date: Ember.get(d, 'date'),
								seconds: 0
							});
						}

						Ember.setProperties(match, {
							timeOffEntries: Ember.get(timeOff, 'entries'),
							hasPaidTimeOff: Ember.get(timeOff, 'hasPaid'),
							hasUnpaidTimeOff: Ember.get(timeOff, 'hasUnpaid'),
							hasAggregate: true
						});
					}
				});

				// Logger.info(this, 'reportData', { reportDates, reportSeconds } );
			} else {}
				// Logger.warn(this, 'reportData', 'no data', { reportSeconds } );


				// Logger.info(this, 'reportData', { reportData } );


			return reportData;
		}),

		actions: {
			openDayActivity: function openDayActivity(summaryDate) {
				_utils.Assert.isInteger(summaryDate);
				// Logger.info(this, 'openDayActivity', summaryDate);
				this.openDayActivity(summaryDate);
			}
		}
	});
});