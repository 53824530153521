define('busy-app/controllers/time-card/time-entries', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		auth: Ember.inject.service('auth'),

		queryParams: ['start', 'member_id'],
		start: null,
		member_id: null,
		dirtyFlag: false,

		showEditDialog: false,
		showNewTimeEntryDialog: false,
		showNewTimeOffDialog: false,

		isTooOldToEdit: Ember.computed('start', function () {
			var start = _utils.Time.date().unix();
			var minDate = _utils.Time.date().subtract(89, 'days').unix();
			if (!Ember.isNone(this.get('start'))) {
				start = _utils.Time.date(this.get('start')).unix();
			}
			return start <= minDate;
		}),

		dataRangeString: Ember.computed('start', function () {
			var start = Number(this.get('start'));
			var date = '';
			if (!isNaN(start)) {
				date = _utils.Time.timeFormat(start, 'ddd, MMM D, YYYY');
			}
			return date;
		}),

		member: Ember.computed('member_id', function () {
			if (!Ember.isNone(this.get('member_id'))) {
				return this.store.findRecord('member', this.get('member_id'));
			}
		}),

		actions: {
			openTimeEntry: function openTimeEntry(item) {
				this.transitionToRoute('time-card.time-entry', {
					queryParams: {
						id: item.get('id'),
						member_id: this.get('member_id')
					}
				});
			},
			openTimeOffEntry: function openTimeOffEntry(item) {
				this.transitionToRoute('time-card.time-off-entry', {
					queryParams: {
						id: item.get('id'),
						member_id: this.get('member_id')
					}
				});
			},
			openNewTimeEntryDialog: function openNewTimeEntryDialog() {
				this.set('showNewTimeEntryDialog', true);
			},
			openNewTimeOffDialog: function openNewTimeOffDialog() {
				this.set('showNewTimeOffDialog', true);
			},
			closeNewTimeEntryDialog: function closeNewTimeEntryDialog() {
				this.set('showNewTimeEntryDialog', false);
			},
			closeTimeOffDialog: function closeTimeOffDialog() {
				this.set('showNewTimeOffDialog', false);
			},
			openEditDialog: function openEditDialog(entry) {
				// set openEntry on the member model.
				entry.set('model.member.openEntry', entry.get('model'));

				// set on entry, not controller
				entry.set('showEditDialog', true);
			},
			closeEditDialog: function closeEditDialog(entry) {
				// set on entry, not controller
				entry.set('showEditDialog', false);
				this.send('reloadModel');
			},
			reloadSync: function reloadSync() {
				this.set('dirtyFlag', true);
			}
		}
	});
});