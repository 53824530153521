define('busy-app/models/member-lock', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		/**
   * UUID of this Member.
   *   Required to create, not used in updates.
   *
   * @property memberId
   * @type {DS.String}
   */
		memberId: _emberData.default.attr('string'),

		/**
   * UUID of the Member who lasted changed the lock date
   *
   * @property updaterMemberId
   * @type {DS.String}
   */
		updaterMemberId: _emberData.default.attr('string'),

		/**
   * Epoch date indicating the end of the locked period
   *		Date is inclusive and should end with 23:59:59
   *		`_-NULL-_` to indicate a lock is not used for the member
   *
   * @property effectiveDate
   * @type {DS.Number}
   */
		effectiveDate: _emberData.default.attr('number'),

		/**
   * Epoch date created on the server
   *
   * @property updatedOn
   * @type {DS.Number}
   */
		updatedOn: _emberData.default.attr('number'),

		/**
   * Epoch date created on the server
   *
   * @property createdOn
   * @type {DS.Number}
   */
		createdOn: _emberData.default.attr('number'),

		/**
   * Epoch date created on the server
   *
   * @property submittedOn
   * @type {DS.Number}
   */
		submittedOn: _emberData.default.attr('number')

		// member: DS.belongsTo('member'),
		// updaterMember: DS.belongsTo('member'),
	});
});