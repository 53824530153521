define('busy-app/routes/map', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		auth: Ember.inject.service(),

		resetController: function resetController(controller) {
			controller.set('ids', null);
		},
		model: function model() {
			var _this = this;

			var controller = this.controllerFor('map');
			controller.set('isLoading', true);
			return Ember.RSVP.hash({
				timeEntries: this.store.query('time-entry', { end_time: '_-NULL-_', deleted_on: '_-NULL-_' }),
				scopedGroupMembers: this.getScopedGroupMembers()
			}).then(function (_ref) {
				var timeEntries = _ref.timeEntries,
				    scopedGroupMembers = _ref.scopedGroupMembers;

				if (Ember.get(_this, 'auth.useGroupPermissionScoping')) {
					timeEntries = _this.filterByMembers(timeEntries, scopedGroupMembers);
				}
				var projectIds = timeEntries.map(function (entry) {
					return entry.get('projectId');
				});

				var equipmentIds = timeEntries.map(function (entry) {
					return entry.get('equipmentId');
				});

				var equipIdStrings = equipmentIds.filter(function (item) {
					if (typeof item === 'string') {
						return item;
					}
				});

				var memberIds = timeEntries.map(function (entry) {
					return entry.get('memberId');
				});

				var members = [];

				memberIds.uniq().forEach(function (item) {
					if (Ember.isPresent(item)) {
						_this.store.findRecord('member', item).then(function (results) {
							members.pushObject(results);
						});
					}
				});

				var projects = [];

				projectIds.uniq().forEach(function (item) {
					if (Ember.isPresent(item)) {
						_this.store.findRecord('project', item).then(function (results) {
							projects.pushObject(results);
						});
					}
				});

				var equipment = [];

				equipIdStrings.uniq().forEach(function (item) {
					if (Ember.isPresent(item)) {
						_this.store.findRecord('equipment', item).then(function (results) {
							equipment.pushObject(results);
						});
					}
				});

				return Ember.RSVP.hash({
					timeEntries: timeEntries,
					members: members,
					projects: projects,
					equipment: equipment,
					modelSet: true
				});
			}).finally(function () {
				Ember.run.later(function () {
					controller.set('isLoading', false);
				}, 1000);
			});
		},
		getScopedGroupMembers: function getScopedGroupMembers() {
			if (!Ember.get(this, 'auth.useGroupPermissionScoping')) {
				return null;
			}

			var query = { archived_on: '_-NULL-_' };
			var authMember = Ember.get(this, 'auth.member');

			var _EmberGetProperties = Ember.getProperties(authMember, ['id', 'memberGroupId']),
			    id = _EmberGetProperties.id,
			    memberGroupId = _EmberGetProperties.memberGroupId;

			Ember.setProperties(query, { member_group_id: memberGroupId });

			if (Ember.isNone(memberGroupId)) {
				Ember.setProperties(query, { id: id });
			}

			return Ember.get(this, 'store').findAll('member', query);
		},
		filterByMembers: function filterByMembers(timeEntries, members) {
			var memberIds = new Set(members.mapBy('id'));

			return timeEntries.filter(function (item) {
				return memberIds.has(Ember.get(item, 'memberId'));
			});
		},


		actions: {
			willTransition: function willTransition() {
				this.controller.setProperties({
					showMembers: true,
					showProjects: false,
					showEquipment: false
				});
			}
		}
	});
});