define('busy-app/controllers/time-card/time-off-entry', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['id', 'member_id'],

		id: null,
		member_id: null,
		showEditDialog: false,

		init: function init() {
			this._super();
			this.getMember();
		},
		getMember: function getMember() {
			var _this = this;

			if (!Ember.isNone(this.get('member_id'))) {
				this.store.findRecord('member', this.get('member_id')).then(function (member) {
					_this.set('member', member);
				});
			}
		},


		actions: {
			editTimeOffEntry: function editTimeOffEntry() {
				this.set('showEditDialog', true);
			},
			closeEditDialog: function closeEditDialog() {
				this.set('showEditDialog', false);
			},
			itemDeleted: function itemDeleted() {
				this.set('showEditDialog', false);
				window.history.back();
			}
		}
	});
});