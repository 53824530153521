define('busy-app/components/cost-codes/import-cost-codes', ['exports', 'busy-app/utils/cost-code-csv-import', '@busy-web/utils'], function (exports, _costCodeCsvImport, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ["c-cost-codes-import-cost-codes"],

		analytics: Ember.inject.service('analytics'),

		model: null,
		importData: null,

		showImportError: false,
		showPreview: false,

		importProgress: (0, _utils.loc)('Saving'),

		importErrorMessage: '',

		init: function init() {
			this._super();
			this.setupButtons();
		},
		setupButtons: function setupButtons() {
			this.set('buttonInfo1', [{ action: this.get('actions.closeImportAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfo2', [{ action: this.get('actions.openPreviewAction'), color: 'blue', text: (0, _utils.loc)('Next'), target: this }, { action: this.get('actions.closeMappingAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfo3', [{ action: this.get('actions.saveAction'), color: 'blue', text: (0, _utils.loc)('Save'), target: this }, { action: this.get('actions.closePreviewAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
			this.set('buttonInfoError', [{ action: this.get('actions.closeErrorAction'), color: 'grey', text: (0, _utils.loc)('Back'), target: this }]);
		},
		closeImport: function closeImport() {
			this.set('showImportError', false);
			this.set('showPreview', false);
			this.set('showMapping', false);
		},
		showError: function showError(err) {
			this.set('importErrorMessage', err);
			this.set('showImportError', true);
		},
		checkRequiredFields: function checkRequiredFields() {
			var importData = this.get('importData');
			var noDescriptionCount = 0;
			var noCostCodeCount = 0;

			importData._rawDataRows.forEach(function (item) {
				if (Ember.isEmpty(item[importData.headerMap.title])) {
					noDescriptionCount += 1;
				}

				if (Ember.isEmpty(item[importData.headerMap.costCode])) {
					noCostCodeCount += 1;
				}
			});

			if (noCostCodeCount > 0) {
				var message = (0, _utils.loc)('Cost code number is required for all imported cost codes. There were %@ missing cost code numbers. Please check your uploaded file and try again.', [noCostCodeCount]);
				this.showError(message);
			} else if (noDescriptionCount > 0) {
				var _message = (0, _utils.loc)('Description is required for all imported cost codes. There were %@ missing descriptions. Please check your uploaded file and try again.', [noDescriptionCount]);
				this.showError(_message);
			} else {
				this.set('showPreview', true);
				importData.triggerParse();
			}
		},


		actions: {
			closeImportAction: function closeImportAction() {
				this.closeImport();
				this.sendAction('onClose');
			},
			closeErrorAction: function closeErrorAction() {
				this.set('showPreview', false);
				this.set('showMapping', false);
				this.set('showImportError', false);
			},
			openPreviewAction: function openPreviewAction() {
				this.checkRequiredFields();
			},
			closePreviewAction: function closePreviewAction() {
				this.set('showPreview', false);
			},
			openMappingAction: function openMappingAction() {
				this.set('showMapping', true);
			},
			closeMappingAction: function closeMappingAction() {
				this.set('showMapping', false);
			},
			selectListItem: function selectListItem(item) {
				this.get('importData').setHeaderByListType(item);
			},
			uploadAction: function uploadAction(data) {
				var _this = this;

				data = data[0];
				var owner = Ember.getOwner(this);
				var csv = _costCodeCsvImport.default.create(owner.ownerInjection());

				csv.set('showInsurance', this.get('features.insuranceClasses'));

				csv.on('onFinishFileParse', function () {
					_this.set('showMapping', true);
				});

				csv.on('progress', function (left, total) {
					if (total > 5) {
						_this.set('importProgress', (0, _utils.loc)('Importing cost codes %@%', [Math.ceil((total - left) / total * 100)]));
					}
				});

				csv.on('complete', function () {
					_this.sendAction('onComplete', _this.get('model'));
				});

				csv.on('error', function (err) {
					_this.showError(err);
				});

				csv.run(data);
				this.set('importData', csv);
			},
			saveAction: function saveAction() {
				var _this2 = this;

				var data = this.get('importData');
				return data.saveModel().then(function (res) {
					_this2.set('model', data.get('model'));
					return (0, _utils.loc)("Saved %@ cost codes successfully!", [res.length]);
				}).finally(function () {
					return _this2.closeImport();
				});
			},
			openIntercom: function openIntercom() {
				this.get('analytics').openIntercom();
			}
		}
	});
});