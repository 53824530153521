define('busy-app/animations/company', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = timecard;
	function timecard(durationTo, durationBack) {
		//time card transitions
		var animate = ['company-settings.subscription-edit', 'company-settings.intuit-quickbooks', 'company-settings.procore'];

		this.transition(this.toRoute(animate), this.use('toLeft', { duration: durationTo }), this.reverse('toRight', { duration: durationBack }));
	}
});