define('busy-app/components/time/time-entry/edit', ['exports', '@busy-web/utils', 'busy-app/utils/round-time'], function (exports, _utils, _roundTime) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};

	exports.default = Ember.Component.extend({
		classNames: ['c-time-time-entry-edit'],

		auth: Ember.inject.service('auth'),
		analytics: Ember.inject.service('analytics'),
		features: Ember.inject.service('features'),

		hasBackButton: false,

		startDateTime: null,
		endDateTime: null,
		oldStartDate: null,

		maxDate: null,

		model: null,
		project: null,
		costCode: null,

		breakTime: 0,
		totalTime: 0,

		projectIdChange: null,
		costCodeIdChange: null,
		equipmentIdChange: null,
		isTooOldToEdit: false,

		initialStartTime: null,

		allowSignatureInvalidationWarning: false,

		costCodesAutoOpen: false,
		projectsAutoOpen: false,
		isLinkedCostCodesEnabled: Ember.computed.alias('auth.organization.isLinkedCostCodesEnabled'),
		disableCostCodeSelection: Ember.computed('project.id', 'isLinkedCostCodesEnabled', function () {
			return Ember.get(this, 'isLinkedCostCodesEnabled') && Ember.isNone(Ember.get(this, 'project.id'));
		}),

		init: function init() {
			var _this = this;

			this._super();

			this.setTimeRoundingInterval();

			this.set('__savedStart', this.get('model.startTime'));
			this.set('__savedEnd', this.get('model.endTime'));

			var startTime = this.roundTimestamp(this.get('model.startTime'), 'start');
			var endTime = this.roundTimestamp(this.get('model.endTime'), 'end');

			this.set('endDateTime', endTime);
			this.set('oldStartDate', startTime);
			this.set('startDateTime', startTime);

			Ember.run.schedule('afterRender', function () {
				_this.set('model.startTime', startTime);
				_this.set('model.endTime', endTime);
			});

			this.set('maxDate', _utils.Time.date().endOf('day').unix());

			this.set('project', this.get('model.project'));
			this.set('costCode', this.get('model.costCode'));
			this.set('equipment', this.get('model.equipment'));

			this.set('initialStartTime', this.get('model.startTime'));

			if (this.get('features.locks')) {
				_utils.Assert.isObject(this.get('model.member'));
				Ember.RSVP.resolve(this.get('model.member.memberLock')).then(function (memberLock) {
					if (_this.get('isSavedStartTimeLocked')) {
						_this.analyticsEventBlockedByLock();
					}
					_this.initObservers();
					return memberLock;
				});
			} else {
				this.initObservers();
			}
		},
		initObservers: function initObservers() {
			this.setDates();
			this.setBreakTimes();
			this.adjustEndTime();
			this.totalAdjusted();
			this.totalTimeString();
		},
		setTimeRoundingInterval: function setTimeRoundingInterval() {
			var interval = 1;

			if (this.get('auth.organization.timeRounding')) {
				interval = parseInt(this.get('auth.organization.timeRoundingInterval') / 60, 10);
			}

			this.set('timeRoundingInterval', interval);
		},


		/**
    * @method roundTotalTime
    */
		roundTimestamp: function roundTimestamp(timestamp, type) {
			return (0, _roundTime.roundOrgTime)(this.get('auth.organization'), timestamp, { isStart: type === 'start', isEnd: type === 'end' });
		},
		didInsertElement: function didInsertElement() {
			// get this sub-dialog to draw on top by creating it after the primary dialog is created
			if (this.get('features.signatures')) {
				Ember.run.next(this, function () {
					this.set('allowSignatureInvalidationWarning', true);
				});
			}
		},


		setDates: Ember.observer('model.startTime', 'model.endTime', 'model.member.lockDate', function () {
			if (this.get('features.locks')) {
				if (this.get('isSelectedStartTimeLocked')) {
					this.analyticsEventBlockedByLock();
				}
			}

			if (!Ember.isNone(this.get('model.startTime')) && !Ember.isNone(this.get('model.endTime')) && !Ember.isNone(this.get('model.member.lockDate'))) {
				if (this.get('model.startTime') > this.get('model.member.lockDate')) {
					this.set('isTooOldToEdit', false);

					var startTime = this.roundTimestamp(this.get('model.startTime'), 'start');
					var endTime = this.roundTimestamp(this.get('model.endTime'), 'end');

					this.set('endDateTime', endTime);
					this.set('oldStartDate', startTime);
					this.set('startDateTime', startTime);
				} else {
					this.set('isTooOldToEdit', true);
				}
			}
		}),

		setModel: Ember.observer('startDateTime', 'endDateTime', function () {
			if (!Ember.isNone(this.get('startDateTime')) && !Ember.isNone(this.get('endDateTime'))) {
				this.set('model.startTime', this.get('startDateTime'));
				this.set('model.endTime', this.get('endDateTime'));
			}
		}),

		setBreakTimes: Ember.observer('model.breakTime', function () {
			if (!Ember.isNone(this.get('model.breakTime'))) {
				this.set('breakTime', this.get('model.breakTime'));
			}
		}),

		adjustEndTime: Ember.observer('startDateTime', function () {
			if (!Ember.isNone(this.get('startDateTime'))) {
				var start = this.get('startDateTime');
				var breakTime = this.get('breakTime');

				var oldStart = this.get('oldStartDate');
				if (Ember.isNone(oldStart)) {
					oldStart = start;
				}

				if (_utils.Time.date(oldStart).date() !== _utils.Time.date(start).date()) {
					var totalTime = this.get('totalTime');
					var curDuration = totalTime + breakTime;
					this.set('endDateTime', _utils.Time.date(start).add(curDuration, 'seconds').unix());
				} else {
					var end = this.get('endDateTime');
					var seconds = end - start - breakTime;
					this.set('totalTime', seconds);
				}

				this.set('oldStartDate', this.get('startDateTime'));
			}
		}),

		totalAdjusted: Ember.observer('totalTime', function () {
			if (!Ember.isNone(this.get('startDateTime')) && !Ember.isNone(this.get('endDateTime'))) {
				var start = this.get('startDateTime');
				var total = this.get('totalTime');
				var breakTime = this.get('breakTime');

				var totalTime = total + breakTime;

				this.set('endDateTime', _utils.Time.date(start).add(totalTime, 'seconds').unix());
			}
		}),

		/**
   * function that calculates the amount of hours and minutes
   * from clock-in to clock-out input boxes.
   *
   * @public
   * @method totalTimeString
   */
		totalTimeString: Ember.observer('endDateTime', 'breakTime', function () {
			if (!Ember.isNone(this.get('startDateTime')) && !Ember.isNone(this.get('endDateTime'))) {
				//variables for setting up seconds, minutes, and hours
				var seconds = this.get('endDateTime') - this.get('startDateTime');
				var breakSeconds = this.get('breakTime');

				seconds = seconds - breakSeconds;

				this.set('totalTime', seconds);
			}
		}),

		submitTimeEntry: function submitTimeEntry() {
			var forceSave = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

			var startTime = this.get('startDateTime');
			var endTime = this.get('endDateTime');
			var projectId = this.get('projectIdChange');
			var costCodeId = this.get('costCodeIdChange');
			var equipmentId = this.get('equipmentIdChange');

			if (!Ember.isNone(projectId)) {
				if (projectId === 'NONE') {
					projectId = null;
				}
				this.set('model.projectId', projectId);
				this.set('model.project', this.get('project'));
			}

			if (!Ember.isNone(costCodeId)) {
				if (costCodeId === 'NONE') {
					costCodeId = null;
				}
				this.set('model.costCodeId', costCodeId);
				this.set('model.costCode', this.get('costCode'));
			}

			if (!Ember.isNone(equipmentId)) {
				if (equipmentId === 'NONE') {
					equipmentId = null;
				}
				this.set('model.equipmentId', equipmentId);
				this.set('model.equipment', this.get('equipment'));
			}

			if (!Ember.isEmpty(this.get('model.description'))) {
				var description = this.get('model.description').trim();
				if (/^\n+$/.test(description)) {
					description = null;
				} else if (/^\t+$/.test(description)) {
					description = null;
				} else if (/^(\r\n)+$/.test(description)) {
					description = null;
				} else if (Ember.isEmpty(description)) {
					description = null;
				}
				this.set('model.description', description);
			}

			if (this.get('model.startTime') !== startTime) {
				this.set('model.startTime', startTime);
			}

			if (this.get('model.endTime') !== endTime) {
				this.set('model.endTime', endTime);
			}

			var hasBreakChanges = this.get('model.timeEntryBreaks').filterBy('hasDirtyAttributes', true).get('length');
			if (this.get('model.hasDirtyAttributes') || hasBreakChanges) {
				if (this.get('model.hasDirtyAttributes')) {
					this.set('model.actionType', 60);
				} else if (hasBreakChanges) {
					this.set('model.actionType', 70);
				}

				this.get('model.timeEntryBreaks').forEach(function (item) {
					if (item.get('hasDirtyAttributes') && !item.get('isNew') && !item.get('isDeleted')) {
						item.set('actionType', 40);
					}
				});

				return this.get('model').save(forceSave);
			} else {
				return Ember.RSVP.resolve();
			}
		},


		/**
  * If the feature is enabled, and there is a lock on the member, return the lock date
  *
  * @public
  * @property memberLockDate
  * @type {number}
  * @default null
  */
		memberLockDate: Ember.computed('model.member.memberLock.effectiveDate', function () {
			if (this.get('features.locks')) {
				return this.get('model.member.memberLock.effectiveDate');
			}
		}),

		/**
   * Return a the `memberLockDate` as a `Moment` object
   *
   * @public
   * @property memberLockDateTime
   * @type {Moment}
   * @default null
   */
		memberLockDateTime: Ember.computed('memberLockDate', function () {
			if (!Ember.isNone(this.get('memberLockDatae'))) {
				return _utils.Time.date(this.get('memberLockDate'));
			}
		}),

		/**
   * Checks if any validation errors have been set
   *
   * @public
   * @property isSavingDisabled
   * @type {boolean}
   */
		isSavingDisabled: Ember.computed.or('isTooOldToEdit', 'isSavedStartTimeLocked', 'isSelectedStartTimeLocked'),

		/**
   * Logic flip of `isSavingDisabled`
   *
   * @public
   * @property isSavingEnabled
   * @type {boolean}
   */
		isSavingEnabled: Ember.computed.not('isSavingDisabled'),

		/**
   * Is the original `startTime` before the lock date
   *
   * @public
   * @property isSavedStartTimeLocked
   * @type {boolean}
   */
		isSavedStartTimeLocked: Ember.computed('features.locks', 'memberLockDate', 'initialStartTime', 'model.member', function () {
			if (!this.get('features.locks')) {
				return false;
			}

			var lockDate = this.get('memberLockDate');
			return !Ember.isNone(lockDate) && this.get('initialStartTime') <= lockDate;
		}),

		/**
   * Is the currently selected `startTime` before the lock date
   *
   * @public
   * @property isSelectedStartTimeLocked
   * @type {boolean}
   */
		isSelectedStartTimeLocked: Ember.computed('features.locks', 'memberLockDate', 'startDateTime', 'model.member', function () {
			if (!this.get('features.locks')) {
				return false;
			} else if (Ember.isNone(this.get('startDateTime'))) {
				return false;
			}

			var lockDate = this.get('memberLockDate');
			return !Ember.isNone(lockDate) && this.get('startDateTime') <= lockDate;
		}),

		/**
   * @private
   * @method analyticsEventBlockedByLock
   * @return {Object}
   */
		analyticsEventBlockedByLock: function analyticsEventBlockedByLock() {
			this.get('analytics').addEvent(84);
		},


		actions: {
			saveAction: function saveAction(dialog, forceSave) {
				var _this2 = this;

				return this.submitTimeEntry(forceSave).then(function (model) {
					_this2.sendAction('onSaved', model);
					return model;
				});
			},
			deleteAction: function deleteAction() {
				var _this3 = this;

				var timeEntry = this.get('model');
				timeEntry.deleteRecord();
				return timeEntry.save().then(function () {
					_this3.sendAction('onDelete');
					return;
				});
			},
			cancelAction: function cancelAction() {
				this.sendAction('onCancel');
			},
			cancelEdit: function cancelEdit() {
				this.sendAction('onCancel');
			},
			projectSelected: function projectSelected(item) {
				// Logger.info(this, 'projectSelected', item);

				if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
					if (Ember.isNone(item) || Ember.get(item, 'id') !== Ember.get(this, 'projectIdChange')) {
						this.send('costCodeSelected', null);
					}
				}

				if (!Ember.isNone(item) && (typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object') {
					this.set('projectIdChange', item.id);
				} else {
					this.set('projectIdChange', 'NONE');
				}
			},
			costCodeSelected: function costCodeSelected(item) {
				// Logger.info(this, 'costCodeSelected', item);

				if (!Ember.isNone(item) && (typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object') {
					this.set('costCodeIdChange', item.id);
				} else {
					this.set('costCodeIdChange', 'NONE');
				}
			},
			equipmentSelected: function equipmentSelected(item) {
				if (!Ember.isNone(item) && (typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object') {
					this.set('equipmentIdChange', item.id);
				} else {
					this.set('equipmentIdChange', 'NONE');
				}
			},
			projectMenuClosed: function projectMenuClosed(didSelectValue, selectedItem, openedBy) {
				var _this4 = this;

				// Logger.info(this, 'projectMenuClosed', didSelectValue, selectedItem, openedBy);

				if (Ember.get(this, 'isLinkedCostCodesEnabled')) {
					if (didSelectValue) {
						Ember.run.next(this, function () {
							Ember.setProperties(_this4, {
								costCodesAutoOpen: Date.now()
							});
						});
					} else if (Ember.isNone(selectedItem) && openedBy === 'auto') {
						// Logger.info(this, 'projectMenuClosed', 'revert to initial');

						Ember.run.next(this, function () {
							Ember.setProperties(_this4, {
								projectIdChange: false,
								project: Ember.get(_this4, 'model.project'),

								costCodeIdChange: false,
								costCode: Ember.get(_this4, 'model.costCode')
							});
						});
					}
				}
			},
			costCodeMenuClosed: function costCodeMenuClosed(didSelectValue, selectedItem, openedBy) {
				var _this5 = this;

				// Logger.info(this, 'costCodeMenuClosed', didSelectValue, selectedItem, openedBy);

				// if the cost code menu was opened automatically by the double nav and the user hits back (!didSelectValue) then re-open the project selecter
				if (Ember.get(this, 'isLinkedCostCodesEnabled') && !didSelectValue && openedBy === 'auto') {
					Ember.run.next(this, function () {
						Ember.setProperties(_this5, {
							costCode: null,
							projectsAutoOpen: Date.now()
						});
					});
				}
			}
		}
	});
});