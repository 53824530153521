define('busy-app/utils/models/collections/payroll-member-project-csv', ['exports', 'busy-app/utils/models/collections/base'], function (exports, _base) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		member: null,
		project: null,

		wage: null,
		wageType: null,
		wageTypeOrder: null,
		hours: null,
		time: null,
		timeSort: null,
		wageKey: null,

		_depth1: null,
		_depth2: null,
		_depth3: null,
		_depth4: null,
		_depth5: null,
		_depth6: null,
		_depth7: null,

		depth1: Ember.computed('_depth1.title', function () {
			return this.get('_depth1.title') || '';
		}),

		depth1Sort: Ember.computed('depth1', function () {
			var sort = this.get('depth1');
			if (Ember.isEmpty(sort)) {
				sort = 'ZZZZZZZ';
			}
			return sort;
		}),

		depth2: Ember.computed('_depth2.title', function () {
			return this.get('_depth2.title') || '';
		}),

		depth3: Ember.computed('_depth3.title', function () {
			return this.get('_depth3.title') || '';
		}),

		depth4: Ember.computed('_depth4.title', function () {
			return this.get('_depth4.title') || '';
		}),

		depth5: Ember.computed('_depth5.title', function () {
			return this.get('_depth5.title') || '';
		}),

		depth6: Ember.computed('_depth6.title', function () {
			return this.get('_depth6.title') || '';
		}),

		depth7: Ember.computed('_depth7.title', function () {
			return this.get('_depth7.title') || '';
		}),

		projectNumber: Ember.computed('projectInfo.number', function () {
			return this.getCsvValue('projectInfo.number');
		}),

		projectNumber2: Ember.computed('projectInfo2.number', function () {
			return this.getCsvValue('projectInfo2.number');
		}),

		projectNumber3: Ember.computed('projectInfo3.number', function () {
			return this.getCsvValue('projectInfo3.number');
		}),

		projectNumber4: Ember.computed('projectInfo4.number', function () {
			return this.getCsvValue('projectInfo4.number');
		}),

		projectNumber5: Ember.computed('projectInfo5.number', function () {
			return this.getCsvValue('projectInfo5.number');
		}),

		projectNumber6: Ember.computed('projectInfo6.number', function () {
			return this.getCsvValue('projectInfo6.number');
		}),

		projectNumber7: Ember.computed('projectInfo7.number', function () {
			return this.getCsvValue('projectInfo7.number');
		}),

		customer: Ember.computed('projectInfo.customer', function () {
			return this.getCsvValue('projectInfo.customer');
		}),

		description: Ember.computed('model.description', function () {
			return this.get('model.description') || '';
		}),

		getCsvValue: function getCsvValue(key) {
			return !Ember.isNone(this.get(key)) ? this.get(key) : '';
		}
	});
});