define('busy-app/controllers/report/index', ['exports', 'busy-app/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var GRAYLOG_REPORTS = {
    INDEX: 'index',
    PAYROLL_EMPLOYEE: 'payroll_employee',
    PAYROLL_EMPLOYEE_PROJECT: 'payroll_employee_project',
    PAYROLL_PROJECT_EMPLOYEE: 'payroll_project_employee',
    TIME_CARDS: 'time_cards',
    TIME_CARD_APPROVAL: 'time_card_approval',
    DAILY_SIGN_OFF: 'daily_sign_off',
    TIME_ENTRIES: 'time_entries',
    TIME_OFF: 'time_off',
    ACTIVITY: {
      'member-project': 'project_activity',
      'member': 'employee_activity',
      'member-cost-code': 'cost_code_activity',
      'member-equipment': 'equipment_activity',
      'member-day': 'date_activity'
    },
    EMPLOYEES_LIST: 'employees_list'
  };

  /**
   * `Controller/Report/Index`
   *
   * @class Index
   * @namespace Controllers.Report
   * @extends Controller
   */
  /**
   * @module Controllers
   *
   */
  exports.default = Ember.Controller.extend({
    auth: Ember.inject.service('auth'),
    features: Ember.inject.service('features'),
    proFeature: Ember.inject.service('pro-feature'),
    interop: Ember.inject.service('interop'),
    graylog: Ember.inject.service('graylog'),
    access: Ember.inject.service('access'),
    subscription: Ember.inject.service('subscription'),

    allowPayrollReport: Ember.computed('features.payrollReport', function () {
      this.get('features.payrollReport');
      return this.get('proFeatureManager').allowProFeature('payroll-report');
    }),

    showPayrollProBadge: Ember.computed.not('allowPayrollReport'),

    allowBreakHoursReport: Ember.computed.alias('features.breakHoursReport'),

    showDeprecationWarning: Ember.computed.alias('features.deprecateLegacyReports'),

    proFeatureManager: null,

    init: function init() {
      this._super();

      this.set('proFeatureManager', this.get('proFeature').getManager());
      this.graylogReportView(GRAYLOG_REPORTS.INDEX);
    },
    requestPayrollReport: function requestPayrollReport(actionRequested) {
      this.get('proFeatureManager').requestAction('payroll-report', actionRequested);
    },


    signOffEnabled: Ember.computed('auth.organization.{safetySignature,timeAccuracy}', function () {
      if (this.get('auth.organization.safetySignature') || this.get('auth.organization.timeAccuracy') || this.get('auth.organization.breakPolicy')) {
        return true;
      }
      return false;
    }),

    graylogReportView: function graylogReportView(reportName) {
      var event = {
        short_message: 'Report Viewed',
        full_message: 'Report Viewed',
        level: 6,
        _member_id: this.get('auth.memberId'),
        _member_position_id: this.get('auth.member.positionId'),
        _is_pro_account: this.get('subscription.isProAccount'),
        _view_project_expenses: this.get('access').hasPermissionFor('viewProjectExpenses'),
        _view_all_activity_report: this.get('access').hasPermissionFor('viewAllActivityReport'),
        _report_type: 'legacy',
        _view_type: reportName
      };
      this.get('graylog').sendEvent(event);
    },


    actions: {
      employeePayroll: function employeePayroll() {
        var queryParams = {
          project: false,
          type: 'member'
        };

        this.graylogReportView(GRAYLOG_REPORTS.PAYROLL_EMPLOYEE);
        this.transitionToRoute('report.payroll.index', { queryParams: queryParams });
      },
      employeeProjectPayroll: function employeeProjectPayroll() {
        var _this = this;

        var queryParams = {
          project: true,
          type: 'member-project'
        };

        this.graylogReportView(GRAYLOG_REPORTS.PAYROLL_EMPLOYEE_PROJECT);
        this.requestPayrollReport(function () {
          return _this.transitionToRoute('report.payroll.index', { queryParams: queryParams });
        });
      },
      projectEmployeePayroll: function projectEmployeePayroll() {
        var _this2 = this;

        var queryParams = {
          project: true,
          type: 'project'
        };

        this.graylogReportView(GRAYLOG_REPORTS.PAYROLL_PROJECT_EMPLOYEE);
        this.requestPayrollReport(function () {
          return _this2.transitionToRoute('report.payroll.index', { queryParams: queryParams });
        });
      },
      safetySignOff: function safetySignOff() {
        var _this3 = this;

        this.graylogReportView(GRAYLOG_REPORTS.DAILY_SIGN_OFF);
        this.requestPayrollReport(function () {
          return _this3.transitionToRoute('report.safety-signature');
        });
      },
      equipmentByProject: function equipmentByProject() {
        var _this4 = this;

        this.get('proFeatureManager').requestAction('equipmentProjectReport', function () {
          return _this4.transitionToRoute('report.equipment-project');
        });
      },
      activityReport: function activityReport(type) {
        var reportName = GRAYLOG_REPORTS.ACTIVITY.hasOwnProperty(type) ? GRAYLOG_REPORTS.ACTIVITY[type] : 'activity';
        this.graylogReportView(reportName);
        this.transitionToRoute('report.activity', { queryParams: { type: type } });
      },
      linkTo: function linkTo(props) {
        var route = props.route,
            queryParams = props.queryParams,
            logKey = props.logKey;

        try {
          var reportName = GRAYLOG_REPORTS[logKey];
          this.graylogReportView(reportName);
        } catch (err) {
          _logger.default.error(this, 'Invalid logKey', logKey);
          _logger.default.error(this, err);
        }
        this.transitionToRoute(route, { queryParams: queryParams });
      },
      openReactApp: function openReactApp(path, customParameters, event) {
        var _this5 = this;

        this.requestPayrollReport(function () {
          return _this5.get('interop').openReactApp(path, customParameters, event);
        });
      },
      openReactAppFree: function openReactAppFree(path, customParameters, event) {
        this.get('interop').openReactApp(path, customParameters, event);
      },
      proFeatureManagerOpenModal: function proFeatureManagerOpenModal() {
        this.get('proFeatureManager').openModal();
      },
      proFeatureManagerCloseModal: function proFeatureManagerCloseModal() {
        this.get('proFeatureManager').closeModal();
      },
      proFeatureManagerPlanUpgraded: function proFeatureManagerPlanUpgraded() {
        this.get('proFeatureManager').onAllow();
      }
    }
  });
});