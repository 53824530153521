define('busy-app/routes/project/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils'], function (exports, _authenticatedRouteMixin, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		queryParams: {
			group: {
				refreshModel: true
			},
			search: {
				refreshModel: true
			},
			page: {
				refreshModel: true
			},
			page_size: {
				refreshModel: true
			},
			filter: {
				refreshModel: true
			}
		},

		onSetup: Ember.on('activate', function () {
			this.setDefaultPageSize();
		}),

		resetController: function resetController(controller) {
			controller.resetFilters();
			this.setDefaultPageSize();
		},
		setDefaultPageSize: function setDefaultPageSize() {
			var qp = this.get('_qp');
			var pageSize = parseInt(_utils.LocalStorage.getProperty('member-pagination-size') || 30, 10);
			if (pageSize && qp && qp.qps) {
				var obj = qp.qps.findBy('prop', 'page_size');
				if (obj) {
					var defaultValue = parseInt(obj.defaultValue || 30, 10);
					if (defaultValue !== pageSize) {
						obj.defaultValue = pageSize;
						obj.serializedDefaultValue = pageSize;
						obj.undecoratedDefaultValue = pageSize;
					}
				}
			}
		},
		model: function model(params) {
			var query = {
				depth: 1,
				_asc: ['title'],
				page_size: params.page_size || 30,
				page: params.page || 1
			};

			if (params.group === 'archived') {
				query.archived_on = '!_-NULL-_';
				query.deleted_on = '_-DISABLE-_';
			} else if (params.group === 'ungrouped') {
				query.project_group_id = null;
				query.archived_on = null;
			} else {
				if (!Ember.isEmpty(params.group)) {
					query.project_group_id = params.group;
				}
				query.archived_on = null;
			}

			if (!Ember.isEmpty(params.search)) {
				query._search = params.search;
			}

			var controller = this.controllerFor('project/index');
			controller.set('isLoading', true);
			return this.store.query('project', query).then(function (models) {
				controller.set('isLoading', false);
				controller.getGroups();

				return models;
			});
		},


		actions: {
			refreshModel: function refreshModel() {
				this.refresh();
			}
		}
	});
});