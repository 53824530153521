define('busy-app/utils/integrations/appcues', ['exports', '@busy-web/utils', 'busy-app/utils/integrations/base', 'busy-app/utils/settings'], function (exports, _utils, _base, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _base.default.extend({
		auth: Ember.inject.service('auth'),
		subscription: Ember.inject.service('subscription'),

		featureName: 'integrations/appcues',

		isLoadReady: function isLoadReady() {
			if (this.isAuth()) {
				return !Ember.$('#inject-appcues').length;
			} else {
				return true;
			}
		},
		isRunReady: function isRunReady() {
			if (this.isAuth()) {
				return window.Appcues && typeof window.Appcues.identify === 'function' && !Ember.isNone(this.get('auth.organization.id')) && !Ember.isNone(this.get('subscription.subscriptionStatus.id'));
			} else {
				return true;
			}
		},
		load: function load(callback) {
			if (this.isAuth()) {
				var script = document.createElement('script');
				script.id = 'inject-appcues';
				script.type = 'text/javascript';
				script.src = 'https://fast.appcues.com/19912.js';

				Ember.$('body').append(script);
			}
			callback();
		},
		run: function run() {
			var _this = this;

			if (this.isAuth()) {
				Ember.RSVP.resolve(this.getWithDefault('auth.memberCount', 1)).then(function (memberCount) {
					var data = {
						memberId: _this.get('auth.member.id'),
						memberEmail: _this.getWithDefault('auth.member.email', null),
						memberPhone: _this.getWithDefault('auth.member.phone', null),
						memberFirstName: _this.getWithDefault('auth.member.firstName', null),
						memberLastName: _this.getWithDefault('auth.member.lastName', null),
						memberFullName: _this.getWithDefault('auth.member.fullName', null),
						memberImageUrl: _this.getWithDefault('auth.member.imageUrl', null),
						memberImageThumbUrl: _this.getWithDefault('auth.member.imageThumbUrl', null),
						memberUserName: _this.getWithDefault('auth.member.username', null),
						memberNumber: _this.getWithDefault('auth.member.memberNumber', null),
						memberCreatedOn: _this.get('auth.member.createdOn'),
						memberCreatedOnDate: _utils.Time.date(_this.get('auth.member.createdOn')).format('YYYY-MM-DD'),

						memberPosition: _this.getWithDefault('auth.member.positionName', null),
						memberEmailVerificationRequired: _this.getWithDefault('auth.member.emailVerificationRequired', false),
						memberIsOwner: _this.get('auth.organization.ownedBy') === _this.get('auth.member.id'),

						orgId: _this.get('auth.organization.id'),
						orgName: _this.getWithDefault('auth.organization.organizationName', null),
						orgImageUrl: _this.getWithDefault('auth.organization.imageUrl', null),
						orgImageThumbUrl: _this.getWithDefault('auth.organization.imageThumbUrl', null),
						orgCreatedOn: _this.get('auth.organization.createdOn'),

						orgCreatedOnDate: _utils.Time.date(_this.get('auth.organization.createdOn')).format('YYYY-MM-DD'),
						orgSubscriptionId: _this.get('subscription.subscriptionStatus.subscriptionId'),
						orgCustomerId: _this.get('subscription.subscriptionStatus.customerId'),

						orgTrackCostCode: _this.get('auth.organization.trackCostCode'),
						orgTrackProject: _this.get('auth.organization.trackProject'),
						orgTrackPaidBreak: _this.get('auth.organization.trackPaidBreak'),
						orgTrackBudgets: _this.get('auth.organization.trackBudgets'),
						orgTrackEquipment: _this.get('auth.organization.trackEquipment'),
						orgIsSignaturesEnabled: _this.get('auth.organization.isSignaturesEnabled'),
						orgSignatureDateTime: _this.getWithDefault('auth.organization.signatureDateTime', null),
						orgNumEmployees: memberCount - 1, // don't include owner

						subFreeAccount: _this.get('subscription.subscriptionStatus.isFreeAccount'),
						subProAccount: _this.get('subscription.subscriptionStatus.isProAccount'),
						subFreeTrial: _this.get('subscription.subscriptionStatus.isTrialAccount'),
						subEnterpriseAccount: _this.get('subscription.subscriptionStatus.isEnterpriseAccount'),

						appVersion: _settings.default.get('version')
					};

					window.Appcues.identify(data.memberId, data);
				});
			}
		},
		start: function start() {
			if (this.isEnabled() && this.isReady()) {
				if (window.Appcues && typeof window.Appcues.start === 'function') {
					window.Appcues.start();
				}
			}
		}
	});
});