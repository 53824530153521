define('busy-app/routes/report/time-off/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', '@busy-web/utils'], function (exports, _authenticatedRouteMixin, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
		payPeriods: Ember.inject.service('pay-period'),

		model: function model() {
			var pController = this.controllerFor('report/time-off');
			var start = pController.get('start');
			var end = pController.get('end');

			if (start === 0) {
				var currentPeriod = this.get('payPeriods').getPayPeriod(_utils.Time.timestamp());
				var previousPeriod = this.get('payPeriods').getPayPeriod(currentPeriod.get('startDate').subtract(1, 'days').unix());
				start = previousPeriod.get('start');
				end = previousPeriod.get('end');
			}

			return this.store.manager('time-off-report', start, end);
		}
	});
});