define('busy-app/utils/mod/sum', ['exports', 'busy-app/utils/mod/array'], function (exports, _array) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.hours = exports.createTimeObject = undefined;


	/**
  * Converts a time in seconds to { hours, minutes, seconds }
  *
  * @method createTimeObject
  * @param rawSeconds {number}
  * @return {object}
  */
	var createTimeObject = exports.createTimeObject = function createTimeObject(rawSeconds) {
		var hours = Math.floor(rawSeconds / 3600);
		var minutes = Math.floor(mod(rawSeconds, 3600) / 60);
		var seconds = Math.ceil(mod(rawSeconds, 3600, 60));

		return { hours: hours, minutes: minutes, seconds: seconds };
	};

	/**
  * Creates a total number from a list of model objects for
  * the keyPath provided and returns the total string according
  * to the format
  *
  * @method hours
  * @param models {object[]}
  * @param keyPath {string} path to property on model array
  * @param format {string} moment like duration format
  * @return {string}
  */
	/**
  * @module utils
  *
  */
	var hours = exports.hours = function hours(models, keyPath) {
		var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "hh:mm";

		var rawSeconds = (0, _array.reduce)((0, _array.map)(models, function (m) {
			return Ember.get(m, keyPath);
		}), function (a, b) {
			return a + b;
		}, 0);

		// get the hours, minutes seconds from the total rawSeconds

		var _createTimeObject = createTimeObject(rawSeconds),
		    hours = _createTimeObject.hours,
		    minutes = _createTimeObject.minutes,
		    seconds = _createTimeObject.seconds;

		if (!/[Hh]/.test(format)) {
			minutes = minutes + hours * 60;
			hours = 0;
		}

		if (!/m/.test(format)) {
			seconds = seconds + minutes * 60;
			minutes = 0;
		}

		// format the times into a string
		return replaceMap(createHMSMap(hours, minutes, seconds), format);
	};

	var mod = function mod(value) {
		for (var _len = arguments.length, nums = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
			nums[_key - 1] = arguments[_key];
		}

		if (Ember.isEmpty(nums)) {
			return value;
		}
		var num = nums.pop();
		return mod.apply(undefined, [value].concat(nums)) % num;
	};

	var addPlaceHolderText = function addPlaceHolderText(formatStr, placeHolder) {
		// return formatStr if there is no placeHolder string
		if (Ember.isEmpty(placeHolder)) {
			return formatStr;
		}

		// add placeHolder text back in to string
		placeHolder.split(' ').forEach(function (val) {
			formatStr = formatStr.replace(/_/, val);
		});

		// remove placeHolder bracket indicators
		return formatStr.replace(/\[|\]/g, '');
	};

	var removePlaceHolderText = function removePlaceHolderText(formatStr) {
		// remove and store any placeHolder text inside of brackets `[]`
		var placeHolder = formatStr.replace(/[^\[]*(\[[^\]]*\])/g, '$1 '); // eslint-disable-line no-useless-escape
		placeHolder = placeHolder.trim();

		// get new format without placeHolder text
		var format = formatStr.replace(/([^\[]*)(\[[^\]]*\])/g, '$1_'); // eslint-disable-line no-useless-escape

		// return format and placeHolder
		return { format: format, placeHolder: placeHolder };
	};

	var replaceMap = function replaceMap(map, formatStr) {
		var target = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

		var _removePlaceHolderTex = removePlaceHolderText(formatStr),
		    format = _removePlaceHolderTex.format,
		    placeHolder = _removePlaceHolderTex.placeHolder;

		// replace all values in map
		Object.keys(map).map(function (key) {
			return format = format.replace(new RegExp(key, 'g'), function () {
				return map[key].call(target);
			});
		});

		// return str out
		return addPlaceHolderText(format, placeHolder);
	};

	var createHMSMap = function createHMSMap(hours, minutes, seconds) {
		return {
			"[Hh]{2}": function Hh2() {
				return '' + (hours < 10 ? '0' : '') + hours;
			},
			"[Hh]{1}": function Hh1() {
				return '' + hours;
			},
			"[m]{2}": function m2() {
				return '' + (minutes < 10 ? '0' : '') + minutes;
			},
			"[m]{1}": function m1() {
				return '' + minutes;
			},
			"[s]{2}": function s2() {
				return '' + (seconds < 10 ? '0' : '') + seconds;
			},
			"[s]{1}": function s1() {
				return '' + seconds;
			}
		};
	};
});