define('busy-app/models/safety-signature', ['exports', '@busy-web/utils', 'ember-data'], function (exports, _utils, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    memberId: _emberData.default.attr('string'),
    proxyMemberId: _emberData.default.attr('string'),
    injured: _emberData.default.attr('boolean', { defaultValue: null }),
    injuryDescription: _emberData.default.attr('string'),
    timeAccurate: _emberData.default.attr('boolean', { defaultValue: null }),
    timeAccurateReason: _emberData.default.attr('string'),
    signature: _emberData.default.attr('svg'),
    signatureId: _emberData.default.attr('string'),
    signatureUrl: _emberData.default.attr('string'),
    signatureThumbUrl: _emberData.default.attr('string'),
    updatedOn: _emberData.default.attr('number'),
    createdOn: _emberData.default.attr('number'),
    submittedOn: _emberData.default.attr('number'),
    deletedOn: _emberData.default.attr('number'),
    startTime: _emberData.default.attr('number'),
    endTime: _emberData.default.attr('number'),
    startTimeOffset: _emberData.default.attr('number'),
    createdOnOffset: _emberData.default.attr('number'),
    endTimeOffset: _emberData.default.attr('number'),
    createdOnDst: _emberData.default.attr('boolean'),
    startTimeDst: _emberData.default.attr('boolean'),
    endTimeDst: _emberData.default.attr('boolean'),
    deviceType: _emberData.default.attr('string'),
    breakPolicyFollowed: _emberData.default.attr('boolean'),
    breakPolicyFollowedReason: _emberData.default.attr('string'),

    endTimeDay: Ember.computed('endTime', function () {
      var endTime = this.get('endTime');
      return _utils.Time.date(endTime).format('MMM Do');
    }),

    endTimeDate: Ember.computed('endTime', function () {
      var endTime = this.get('endTime');
      return _utils.Time.date(endTime).endOf('day').unix();
    }),

    endTimeString: Ember.computed('endTime', function () {
      return _utils.Time.timeFormat(this.get('endTime'), 'h:mm A');
    }),

    injuredString: Ember.computed('injured', function () {
      var injured = this.get('injured');
      if (injured) {
        return (0, _utils.loc)('Injured');
      }
      return '';
    }),

    injuredIsNull: Ember.computed('injured', function () {
      if (this.get('injured') === null) {
        return true;
      }
      return false;
    }),

    timeAccurateIsNull: Ember.computed('timeAccurate', function () {
      if (this.get('timeAccurate') === null) {
        return true;
      }
      return false;
    }),

    breakPolicyIsNull: Ember.computed('breakPolicyFollowed', function () {
      if (this.get('breakPolicyFollowed') === null) {
        return true;
      }
      return false;
    }),

    member: Ember.computed('memberId', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('member', { id: this.get('memberId') }).then(function (data) {
          return data.get('firstObject');
        })
      });
    }),

    proxyMember: Ember.computed('proxyMemberId', function () {
      return _emberData.default.PromiseObject.create({
        promise: this.store.query('member', { id: this.get('proxyMemberId') }).then(function (data) {
          return data.get('firstObject');
        })
      });
    }),

    createdToday: Ember.computed('createdOn', 'startTime', function () {
      if (_utils.Time.date(this.get('createdOn')).format('MM-DD-YYYY') === _utils.Time.date(this.get('startTime')).format('MM-DD-YYYY')) {
        return true;
      }
      return false;
    }),

    createdOnDate: Ember.computed('createdOn', function () {
      var createdOn = this.get('createdOn') + this.get('createdOnOffset');
      return _utils.Time.date(createdOn).startOf('day').unix();
    }),

    createdOnTime: Ember.computed('createdOn', function () {
      return this.get('createdOn') + this.get('createdOnOffset');
    }),

    createdOnString: Ember.computed('createdOn', function () {
      var createdOn = this.get('createdOn') + this.get('createdOnOffset');
      return _utils.Time.date(createdOn).format('h:mm A (M/D/YY)');
    }),

    createdAtString: Ember.computed('createdOn', function () {
      var createdOn = this.get('createdOn') + this.get('createdOnOffset');
      return _utils.Time.date(createdOn).format('h:mm A');
    }),

    submittedOnTime: Ember.computed('submittedOn', function () {
      return this.get('submittedOn') + this.get('createdOnOffset');
    }),

    signatureRequiredString: Ember.computed('memberId', 'proxyMemberId', function () {
      if (this.get('memberId') !== this.get('proxyMemberId')) {
        return (0, _utils.loc)('Employee signature required when signing from supervisor account');
      }
    })
  });
});