define('busy-app/components/employees/reset-pin-dialog', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		classNames: ['v-employees-employee-dialog-reset-pin'],

		session: Ember.inject.service('session'),
		auth: Ember.inject.service('auth'),
		dataStore: Ember.inject.service('data-store'),

		newPin: null,
		confirmPin: null,
		member: null,

		confirmPinNumber: function confirmPinNumber(pin) {
			var confirm = this.get('confirmPin');
			if (pin === confirm) {
				return true;
			}
			return false;
		},


		actions: {
			close: function close() {
				this.sendAction('onClose');
			},
			save: function save() {
				var pin = this.get('newPin');
				if (pin) {
					if (/^[0-9]{4}$/.test(pin)) {
						if (this.confirmPinNumber(pin)) {
							var member = this.get('member');
							member.set('pinCode', pin);
							return member.save();
						} else {
							return Ember.RSVP.reject((0, _utils.loc)('PINs do not match'));
						}
					} else {
						this.set('newPin', null);
						this.set('confirmPin', null);
						return Ember.RSVP.reject((0, _utils.loc)('PIN must be 4 numbers'));
					}
				} else {
					var _member = this.get('member');
					_member.set('pinCode', '');
					return _member.save();
				}
			}
		}
	});
});