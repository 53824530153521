define('busy-app/controllers/reset', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		email: '',
		nextMessage: null,
		successMessage: false,
		isSubmitting: false,

		_nextMessage: Ember.computed(function () {
			return {
				"200": (0, _utils.loc)('We sent you an email with a link to reset your password.'),
				"201": (0, _utils.loc)('The username was not found in the system.'),
				"202": (0, _utils.loc)('The system has hit an error. Please try again later.'),
				"203": (0, _utils.loc)('The email you provided was not associated with any accounts on busybusy.')
			};
		}),

		actions: {
			resetPassword: function resetPassword() {
				var _this = this;

				if (!this.get('isSubmitting')) {
					this.set('isSubmitting', true);
					var email = this.get('email').trim();
					if (!Ember.isEmpty(email)) {
						this.store.rpcRequest('account', 'password-reset-request', { email: email }).then(function (result) {
							if (result.data) {
								_this.set('successMessage', true);
								_this.set('nextMessage', _this.get('_nextMessage')["200"]);
							} else {
								var code = 202;
								if (result.errors && result.errors[0] && result.errors[0].code) {
									code = result.errors[0].code;
								}
								_this.set('nextMessage', _this.get('_nextMessage')[code]);
							}
							_this.set('isSubmitting', false);
						}).catch(function (err) {
							var code = 202;
							if (err.errors && err.errors[0] && err.errors[0].code) {
								code = err.errors[0].code;
							}
							_this.set('nextMessage', _this.get('_nextMessage')[code]);
							_this.set('isSubmitting', false);
						});
					} else {
						this.set('nextMessage', (0, _utils.loc)("You must provide an email to reset your password."));
						this.set('isSubmitting', false);
					}
				}
			}
		}
	});
});