define('busy-app/components/bc-list', ['exports', '@busy-web/components/components/bc-list'], function (exports, _bcList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _bcList.default;
    }
  });
});