define("busy-app/components/deprecation-warning", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		analytics: Ember.inject.service("analytics"),
		deprecationDateString: "",

		actions: {
			openHelpDialog: function openHelpDialog() {
				this.set("showHelpDialog", true);
			},
			closeHelpDialog: function closeHelpDialog() {
				this.set("showHelpDialog", false);
			},
			openIntercom: function openIntercom() {
				// hide the help dialog
				this.set("showHelpDialog", false);
				this.get("analytics").openIntercom();
			}
		}
	});
});