define('busy-app/utils/mod/array', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	/**
  * @module Utils
  *
  */
	var arrayProto = function arrayProto(method, array, args) {
		var ArrayUtil = Ember.Object.extend(Ember.Array).create();

		(false && !(!Ember.isNone(ArrayUtil[method])) && Ember.assert('Array.' + method + ' is not a function', !Ember.isNone(ArrayUtil[method])));


		return ArrayUtil[method].apply(array, args);
	};

	var isArray = exports.isArray = function isArray(array) {
		return Ember.isArray(array);
	};
	var map = exports.map = function map(array) {
		for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
			args[_key - 1] = arguments[_key];
		}

		return Array.prototype.map.apply(array, args);
	};

	var pushObject = exports.pushObject = function pushObject(array) {
		for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
			args[_key2 - 1] = arguments[_key2];
		}

		return arrayProto('pushObject', array, args);
	};

	var unshiftObject = exports.unshiftObject = function unshiftObject(array) {
		for (var _len3 = arguments.length, args = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
			args[_key3 - 1] = arguments[_key3];
		}

		return arrayProto('unshiftObject', array, args);
	};

	var findBy = exports.findBy = function findBy(array) {
		for (var _len4 = arguments.length, args = Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
			args[_key4 - 1] = arguments[_key4];
		}

		return arrayProto('findBy', array, args);
	};

	var filterBy = exports.filterBy = function filterBy(array) {
		for (var _len5 = arguments.length, args = Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
			args[_key5 - 1] = arguments[_key5];
		}

		return arrayProto('filterBy', array, args);
	};

	var mapBy = exports.mapBy = function mapBy(array) {
		for (var _len6 = arguments.length, args = Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
			args[_key6 - 1] = arguments[_key6];
		}

		return arrayProto('mapBy', array, args);
	};

	var reduce = exports.reduce = function reduce(array) {
		for (var _len7 = arguments.length, args = Array(_len7 > 1 ? _len7 - 1 : 0), _key7 = 1; _key7 < _len7; _key7++) {
			args[_key7 - 1] = arguments[_key7];
		}

		return arrayProto('reduce', array, args);
	};

	var compact = exports.compact = function compact(array) {
		for (var _len8 = arguments.length, args = Array(_len8 > 1 ? _len8 - 1 : 0), _key8 = 1; _key8 < _len8; _key8++) {
			args[_key8 - 1] = arguments[_key8];
		}

		return arrayProto('compact', array, args);
	};
});