define('busy-app/components/lib/bind-context', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		model: null,

		actions: {
			bindAction: function bindAction(arg1, arg2, arg3) {
				this.sendAction('onBind', this.get('model'), arg1, arg2, arg3);
			}
		}
	});
});