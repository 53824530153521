define('busy-app/controllers/employees/index', ['exports', '@busy-web/utils', 'busy-app/mixins/controller-filter', 'busy-app/utils/debug', 'busy-app/utils/logger', 'busy-app/utils/settings'], function (exports, _utils, _controllerFilter, _debug, _logger, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend(_controllerFilter.default, {
		queryParams: ['group', 'search', 'filter', 'page', 'page_size'],

		analytics: Ember.inject.service('analytics'),
		access: Ember.inject.service('access'),
		auth: Ember.inject.service('auth'),
		subscription: Ember.inject.service('subscription'),
		payPeriodUtils: Ember.inject.service('pay-period'),
		features: Ember.inject.service('features'),
		graylog: Ember.inject.service('graylog'),

		group: null,
		search: '',
		filter: null,

		isLoading: false,

		edit: false,
		showDialog: false,
		showDetail: false,
		skipClear: false,

		position: null,

		selectMenu: null,
		importMenu: null,

		memberCount: null,

		newMember: null,
		newMemberWage: null,
		groups: null,
		members: null,
		currentMember: null,
		newMembersArray: null,

		newGroupName: '',

		selectedGroup: null,
		groupMembers: null,

		shouldAddGroup: false,

		firstNameHeader: null,
		lastNameHeader: null,
		employeeIdHeader: null,
		payrollRateHeader: null,
		payrollTypeHeader: null,
		phoneHeader: null,
		emailHeader: null,

		showMoveGroupDialog: false,
		showClockInDialog: false,
		shownewTimeOffDialog: false,
		showSwitchDialog: false,
		showArchiveDialog: false,
		showUpgradeDialog: false,
		showSubscriptionDialog: false,
		showImportDialog: false,
		showLockDateDialog: false,
		showSafetySignOffDialog: false,
		showGpsLocationRequiredDialog: false,

		hideRowActions: false,

		sendEmailInvite: false,
		sendTextInvite: false,
		manualInvite: false,
		requirePassword: false,

		showHelpDialog: false,
		debugMode: _settings.default.get('debug_mode'),

		entryConflictCutoff: null,

		init: function init() {
			this._super();
			var previousPayPeriod = Ember.get(this, 'payPeriodUtils').getPreviousPayPeriod();
			var entryConflictCutoff = Ember.get(previousPayPeriod, 'startDate').unix();

			Ember.set(this, 'editItems', []);
			Ember.set(this, 'groupMembers', []);
			Ember.set(this, 'entryConflictCutoff', entryConflictCutoff);

			// loads the organization
			Ember.get(this, 'auth.organization');
			Ember.get(this, 'auth.memberCount');

			this.isEditMode();
			this.setupMenu();
		},


		modelFilteredByPermission: Ember.computed('model', 'edit', 'editAction', 'auth.authMemberPosition.id', function () {
			var _this = this;

			Ember.get(this, 'auth.authMemberPosition.id');

			var model = Ember.get(this, 'model');
			if (Ember.get(this, 'edit') && !Ember.isNone(Ember.get(this, 'editAction'))) {
				model = model.filter(function (member) {
					return _this.canTakeAction(member, Ember.get(_this, 'editAction'));
				});
			}
			return model.sortBy('fullName');
		}),

		isEditMode: Ember.observer('filter', function () {
			if (!Ember.isNone(Ember.get(this, 'filter'))) {
				Ember.set(this, 'edit', true);
			} else {
				Ember.set(this, 'edit', false);
			}
		}),

		canManageEmployeeGroups: Ember.computed('auth.{useGroupPermissionScoping,authMemberPosition.manageEmployeeGroups}', function () {
			return Ember.get(this, 'auth.authMemberPosition.manageEmployeeGroups') && !Ember.get(this, 'auth.useGroupPermissionScoping');
		}),

		canManageEmployees: Ember.computed('auth.authMemberPosition.id', function () {
			if (!Ember.isNone(Ember.get(this, 'auth.authMemberPosition.id'))) {
				return Ember.get(this, 'auth.authMemberPosition.manageEmployees') > 0 || Ember.get(this, 'auth.authMemberPosition.manageTime') > 0 || Ember.get(this, 'auth.authMemberPosition.timeEvents') > 0 || Ember.get(this, 'auth.authMemberPosition.manageEmployeeGroups');
			}
			return false;
		}),

		canAddEmployees: Ember.computed('auth.{authMemberPosition.manageEmployees,member.memberGroupId,useGroupPermissionScoping}', 'group', function () {
			var canPositionManageEmployees = Ember.get(this, 'auth.authMemberPosition.manageEmployees') > 1;
			var isViewingArchived = Ember.get(this, 'group') === 'archived';
			var useGroupPermissionScoping = Ember.get(this, 'auth.useGroupPermissionScoping');
			var memberGroupId = Ember.get(this, 'auth.member.memberGroupId');

			// if the Group Scoped Permissions feature is enabled,
			// 	and the current user doesn't have a group, they cannot add employees
			if (useGroupPermissionScoping && Ember.isNone(memberGroupId)) {
				return false;
			} else {
				return canPositionManageEmployees && !isViewingArchived;
			}
		}),

		canViewCostCodes: Ember.computed.and('features.costCodes', 'auth.organization.trackCostCode'),

		canViewEquipment: Ember.computed.bool('features.equipment'),

		hasRowActions: Ember.computed('canManageEmployees', 'hideRowActions', function () {
			return Ember.get(this, 'canManageEmployees') && !Ember.get(this, 'hideRowActions');
		}),

		totalRows: Ember.computed('model.@each.id', function () {
			var rows = 0;
			var meta = Ember.get(this, 'model.meta');
			if (!Ember.isNone(meta)) {
				rows = meta.totalRows;
			}
			return rows;
		}),

		canViewManagementTools: Ember.computed('canManageEmployees', 'auth.authMemberPosition.manageEmployees', 'features.managementListsEmployees', function () {
			var canManageEmployees = Ember.get(this, 'auth.authMemberPosition.manageEmployees') > 0;
			var isFeatureEnabled = Ember.get(this, 'features.managementListsEmployees');

			return canManageEmployees && isFeatureEnabled;
		}),

		setupMenu: Ember.observer('group', function () {
			if (Ember.get(this, 'group') === 'archived') {
				Ember.set(this, 'selectMenu', [Ember.Object.create({
					key: 'unarchive',
					value: (0, _utils.loc)('Unarchive'),
					permissions: ['manageEmployees', 'isNotSelf']
				})]);
			} else {
				Ember.set(this, 'selectMenu', [Ember.Object.create({
					key: 'clock-in',
					value: (0, _utils.loc)('Clock In'),
					permissions: ['timeEvents'],
					filters: ['!clockedIn']
				}), Ember.Object.create({
					key: 'clock-out',
					value: (0, _utils.loc)('Clock Out'),
					permissions: ['timeEvents'],
					filters: ['clockedIn', '!onBreak']
				}), Ember.Object.create({
					key: 'go-on-break',
					value: (0, _utils.loc)('Go on Break'),
					permissions: ['timeEvents'],
					filters: ['clockedIn', '!onBreak']
				}), Ember.Object.create({
					key: 'go-off-break',
					value: (0, _utils.loc)('End Break'),
					permissions: ['timeEvents'],
					filters: ['onBreak']
				}), Ember.Object.create({
					key: 'switch',
					value: (0, _utils.loc)('Switch'),
					permissions: ['timeEvents'],
					filters: ['clockedIn', '!onBreak']
				}), Ember.Object.create({
					key: 'move-to-group',
					value: (0, _utils.loc)('Move to Group'),
					permissions: ['manageEmployeeGroups']
				}), Ember.Object.create({
					key: 'clock-in-at',
					value: (0, _utils.loc)('Clock In At'),
					permissions: ['manageTimeEntries'],
					filters: ['!clockedIn']
				}), Ember.Object.create({
					key: 'clock-out-at',
					value: (0, _utils.loc)('Clock Out At'),
					permissions: ['manageTimeEntries'],
					filters: ['clockedIn', '!onBreak']
				}), Ember.Object.create({
					key: 'archive',
					value: (0, _utils.loc)('Archive'),
					permissions: ['manageEmployees', 'isNotSelf', 'isNotOwner'],
					filters: []
				}), Ember.Object.create({ key: 'new-time-entry', value: (0, _utils.loc)('New Time Entry'), permissions: ['manageTimeEntries'] }), Ember.Object.create({ key: 'new-time-off', value: (0, _utils.loc)('Add Time Off'), permissions: ['manageTimeEntries'] }), Ember.Object.create({
					key: 'locations',
					value: (0, _utils.loc)('View Location'),
					permissions: ['timeEvents'],
					filters: ['clockedIn', 'hasLocation']
				}), Ember.Object.create({
					key: 'edit-lock-date',
					value: (0, _utils.loc)('Edit Lock Date'),
					permissions: ['memberLock', 'features.locks']
				})]
				// EmberObject.create({key: 'invite', value: 'Invite to busybusy', permissions: ['manageEmployees']})
				);
			}
		}),

		positionsMenu: Ember.computed('auth.{positions.[],authMemberPosition.level}', function () {
			var memberPosition = Ember.get(this, 'auth.authMemberPosition');
			var positions = Ember.get(this, 'auth.positions');
			if (!Ember.isNone(memberPosition) && !Ember.isNone(positions)) {
				var allowedPositions = positions.filter(function (position) {
					return Ember.get(memberPosition, 'level') <= Ember.get(position, 'level');
				});
				return allowedPositions;
			}
			return [];
		}),

		// get a filtered SelectMenu containing only the actions available for the current user
		allowedSelectMenu: Ember.computed('selectMenu', 'model', 'auth.authMemberPosition.id', function () {
			var _this2 = this;

			var filterablePermissions = ['manageEmployees', 'timeEvents', 'manageTimeEntries', 'manageEmployeeGroups', 'memberLock'];
			Ember.get(this, 'auth.authMemberPosition.id');

			return Ember.get(this, 'selectMenu').filter(function (entry) {
				// make sure the current user can take all applicable `permissions` attached to the select menu entry
				// if they are "self" or higher, they should get the menu option
				return Ember.get(entry, 'permissions').reduce(function (result, permission) {
					if (filterablePermissions.indexOf(permission) !== -1) {
						return result && Ember.get(_this2, 'access').hasPermissionFor(permission); // valid results are `true` and `>= 1`, fails are `false` and `0`;
					} else {
						return result && true; // make everything else have no effect
					}
				}, true);
			});
		}),

		gpsLocationMenu: Ember.computed('newMember.timeLocationRequired', function () {
			var curentValue = this.get('newMember.timeLocationRequired');

			var options = [Ember.Object.create({ key: 'YES', title: (0, _utils.loc)('Required'), _selected: curentValue === 'YES' }), Ember.Object.create({ key: 'AUTO', title: (0, _utils.loc)('Not Required'), _selected: curentValue === 'AUTO' }), Ember.Object.create({ key: 'NO', title: (0, _utils.loc)('Off'), _selected: curentValue === 'NO' })];

			// If the account is not pro, the only option is the current value
			if (!Ember.get(this, 'features.memberGpsSetting')) {
				options.forEach(function (item) {
					var _unselectable = Ember.get(item, 'key') !== curentValue;
					Ember.setProperties(item, { _unselectable: _unselectable });
				});
			}

			return options;
		}),

		// tests if the current user can take the specified action on any of the provided members
		canTakeActionForAnyOf: function canTakeActionForAnyOf(permission, members) {
			var _this3 = this;

			return members.reduce(function (result, member) {
				return result || Ember.get(_this3, 'access').canTakeActionFor(permission, member);
			}, false);
		},
		getGroups: function getGroups() {
			var _this4 = this;

			return (0, _debug.component_safe_api)(function () {
				return _this4.store.query('member-group', { _asc: ['group_name'], page_size: 10000 }).then(function (models) {
					return Ember.set(_this4, 'groups', models);
				});
			});
		},
		updateList: function updateList(type) {
			var params = {
				filter: type,
				group: Ember.get(this, 'group'),
				search: Ember.get(this, 'search')
			};

			if (type === 'unarchive') {
				params.group === 'archived';
			}

			this.sendFilter(params);
		},
		validateFilters: function validateFilters(options) {
			var isAll = Ember.isNone(options.group);
			var isUngrouped = !Ember.isNone(options.group) && options.group === 'ungrouped';
			var isArchived = !Ember.isNone(options.group) && options.group !== 'archived';

			if ((!Ember.isEmpty(options.search) || !isAll && !isUngrouped && !isArchived || !Ember.isNone(options.filter)) && !options.page) {
				options.page = 1;
				options.page_size = 1000;
			} else if ((isUngrouped || isArchived) && !options.page) {
				options.page = 1;
				options.page_size = Ember.get(this, 'page_size');
			}

			options.filter = Ember.isNone(options.filter) ? null : options.filter;
			options.group = Ember.isNone(options.group) ? null : options.group;
			options.search = Ember.isEmpty(options.search) ? '' : options.search;

			return true;
		},
		unarchive: function unarchive(items) {
			var _this5 = this;

			if (this.usersLeft() >= Ember.get(items, 'length')) {
				var promise = items.map(function (item) {
					Ember.set(item, 'archivedOn', '_-NULL-_');
					return Ember.RSVP.resolve(item.save());
				});

				return Ember.RSVP.all(promise).then(function () {
					return Ember.get(_this5, 'subscription').reloadSubscriptionSize();
				}).finally(function () {
					return _this5.refreshRoute();
				});
			} else {
				return Ember.set(this, 'showUpgradeDialog', true);
			}
		},
		filterList: function filterList(list, type) {
			var _this6 = this;

			var nlist = list.filter(function (item) {
				if (_this6.canTakeAction(item, type)) {
					return item;
				}
			});

			return nlist;
		},
		canTakeAction: function canTakeAction(member, action) {
			var accessManager = Ember.get(this, 'access');
			var authUser = Ember.get(this, 'auth.member');
			var hasPermission = false;

			if (action === 'clock-in') {
				if (!Ember.get(member, 'clockedIn')) {
					hasPermission = accessManager.canTakeActionFor('timeEvents', member);
				}
			} else if (action === 'clock-out' || action === 'go-on-break' || action === 'switch') {
				if (Ember.get(member, 'clockedIn') && !Ember.get(member, 'onBreak')) {
					hasPermission = accessManager.canTakeActionFor('timeEvents', member);
				}
			} else if (action === 'go-off-break') {
				if (Ember.get(member, 'onBreak')) {
					hasPermission = accessManager.canTakeActionFor('timeEvents', member);
				}
			} else if (action === 'clock-in-at') {
				if (!Ember.get(member, 'clockedIn')) {
					hasPermission = accessManager.canTakeActionFor('manageTimeEntries', member);
				}
			} else if (action === 'clock-out-at') {
				if (Ember.get(member, 'clockedIn') && !Ember.get(member, 'onBreak')) {
					hasPermission = accessManager.canTakeActionFor('manageTimeEntries', member);
				}
			} else if (action === 'locations') {
				if (Ember.get(member, 'clockedIn') && Ember.get(member, 'hasLocation')) {
					hasPermission = accessManager.canTakeActionFor('timeEvents', member);
				}
			} else if (action === 'new-time-entry') {
				hasPermission = accessManager.canTakeActionFor('timeEvents', member);
			} else if (action === 'new-time-off') {
				hasPermission = accessManager.canTakeActionFor('manageTimeEntries', member);
			} else if (action === 'move-to-group') {
				hasPermission = accessManager.hasPermissionFor('manageEmployeeGroups', member);
			} else if (action === 'archive') {
				if (!Ember.get(member, 'clockedIn')) {
					hasPermission = accessManager.canTakeActionFor('manageEmployees', member) && !accessManager.isOwner(member) && Ember.get(member, 'id') !== Ember.get(authUser, 'id');
				}
			} else if (action === 'unarchive') {
				hasPermission = accessManager.canTakeActionFor('manageEmployees', member) && Ember.get(member, 'id') !== Ember.get(authUser, 'id');
			} else if (action === 'invite') {
				hasPermission = hasPermission = accessManager.canTakeActionFor('manageEmployees', member) && !accessManager.isOwner(member) && Ember.get(member, 'id') !== Ember.get(authUser, 'id');
			} else if (action === 'edit-lock-date') {
				hasPermission = accessManager.canTakeActionFor('memberLock', member);
			}

			return hasPermission;
		},


		editAction: null,
		editItems: null,

		/**
   * Clears the edit action
   *
   * @public
   * @method clearEdit
   */
		clearEdit: function clearEdit() {
			// bc-list sets isSelected on the model
			// so loop through the models and set isSelected
			// to false to uncheck the checkboxes
			Ember.get(this, 'editItems').forEach(function (item) {
				Ember.set(item, 'isSelected', false);
			});

			// set local vars back to defaults
			Ember.set(this, 'editAction', null);
			Ember.set(this, 'editItems', []);
			Ember.set(this, 'edit', false);
			Ember.set(this, 'hideRowActions', false);

			if (!Ember.get(this, 'skipClear')) {
				this.sendFilter({
					search: Ember.get(this, 'search'),
					group: Ember.get(this, 'group')
					// filter: get(this, 'filter'),
				});
			}
		},


		openTaskItems: null,

		dispatchTask: function dispatchTask(action, items) {
			Ember.set(this, 'openTaskItems', items);

			if (this.isGpsLocationRequiredForAction(action, items)) {
				this.send('openDialog', 'GpsLocationRequiredDialog');
				return false;
			}

			if (action === 'clock-in') {
				Ember.set(this, 'showClockInDialog', true);
				this.graylogEmployeeGroupAction('clock_in', items.length);
			} else if (action === 'clock-out') {
				this.clockOutMembers(items);
				this.graylogEmployeeGroupAction('clock_out', items.length);
			} else if (action === 'go-on-break') {
				items.forEach(function (item) {
					return item.startBreak(false, false);
				});
				this.graylogEmployeeGroupAction('start_break', items.length);
			} else if (action === 'go-off-break') {
				items.forEach(function (item) {
					return item.stopBreak(false);
				});
				this.graylogEmployeeGroupAction('end_break', items.length);
			} else if (action === 'new-time-entry') {
				Ember.set(this, 'showNewTimeEntryDialog', true);
				this.graylogEmployeeGroupAction('new_entry', items.length);
			} else if (action === 'new-time-off') {
				Ember.set(this, 'showNewTimeOffDialog', true);
				this.graylogEmployeeGroupAction('time_off', items.length);
			} else if (action === 'switch') {
				Ember.set(this, 'showSwitchDialog', true);
				this.graylogEmployeeGroupAction('switch', items.length);
			} else if (action === 'move-to-group') {
				if (!Ember.isNone(items) && Ember.get(items, 'length') > 0) {
					Ember.set(this, 'groupMembers', items);
					Ember.set(this, 'showMoveGroupDialog', true);
				}
				this.graylogEmployeeGroupAction('move_to_group', items.length);
			} else if (action === 'locations') {
				Ember.set(this, 'skipClear', true);
				this.graylogEmployeeGroupAction('view_location', items.length);
				this.transitionToRoute('employees.locations', { queryParams: { ids: items.mapBy('id'), from: 'employees' } });
			} else if (action === 'archive') {
				if (Ember.get(items, 'length') < 2) {
					Ember.set(this, 'currentMember', Ember.get(items, 'firstObject'));
				}
				this.graylogEmployeeGroupAction('archive', items.length);
				Ember.set(this, 'members', items);
				Ember.set(this, 'showArchiveDialog', true);
			} else if (action === 'unarchive') {
				this.unarchive(items);
				this.graylogEmployeeGroupAction('unarchive', items.length);
			} else if (action === 'clock-in-at') {
				Ember.set(this, 'showClockInAtDialog', true);
				this.graylogEmployeeGroupAction('clock_in_at', items.length);
			} else if (action === 'clock-out-at') {
				Ember.set(this, 'showClockOutAtDialog', true);
				this.graylogEmployeeGroupAction('clock_out_at', items.length);
			} else if (action === 'edit-lock-date') {
				Ember.set(this, 'showLockDateDialog', true);
				this.graylogEmployeeGroupAction('lock_date', items.length);
			}
		},
		isGpsLocationRequiredForAction: function isGpsLocationRequiredForAction(action, items) {
			var restrictedActions = ['clock-in', 'clock-out', 'go-on-break', 'go-off-break', 'switch'];

			return restrictedActions.includes(action) && items.any(function (member) {
				return member.get('isTimeLocationRequired');
			});
		},
		clockOutMembers: function clockOutMembers(items) {
			var _this7 = this;

			// show safetySignOff dialog if the items array is only one member and that member is the auth member
			if (Ember.get(this, 'features.safetySignOff') && (Ember.get(this, 'auth.organization.safetySignature') || Ember.get(this, 'auth.organization.timeAccuracy'))) {
				if (Ember.get(items, 'length') === 1 && Ember.get(items, 'firstObject.id') === Ember.get(this, 'auth.member.id')) {
					Ember.set(this, 'openEntry', Ember.get(items, 'firstObject.openEntry'));
					Ember.set(this, 'showSafetySignOffDialog', true);
					return Ember.RSVP.resolve();
				}
			}

			return this._clockOutMembers(items).then(function () {
				if (Ember.get(_this7, 'features.safetySignOff') && (Ember.get(_this7, 'auth.organization.safetySignature') || Ember.get(_this7, 'auth.organization.timeAccuracy'))) {
					Ember.set(_this7, 'showSignOffRequiredDialog', true);
				} else {
					_this7.refreshRoute();
				}
			});
		},
		_clockOutMembers: function _clockOutMembers(items) {
			var promise = [];
			items.forEach(function (item) {
				if (!Ember.isNone(Ember.get(item, 'openEntry'))) {
					promise.push(item.clockOut(false));
				}
			});
			return Ember.RSVP.allSettled(promise).then(function (data) {
				return Ember.RSVP.resolve(data);
			});
		},
		addMemberToGroup: function addMemberToGroup(group) {
			var _this8 = this;

			var groupId = null;
			if (!Ember.isNone(group)) {
				groupId = Ember.get(group, 'id');
			}

			Ember.RSVP.all(Ember.get(this, 'groupMembers').map(function (item) {
				Ember.set(item, 'memberGroupId', groupId);
				return item.save();
			})).then(function () {
				Ember.set(_this8, 'showMoveGroupDialog', false);
				Ember.set(_this8, 'groupMembers', []);
				Ember.set(_this8, 'selectedGroup', null);
				_this8.refreshRoute();
			});
		},
		usersLeft: function usersLeft() {
			return Ember.get(this, 'subscription.membersAvailable');
		},
		checkUsersAvailable: function checkUsersAvailable() {
			return this.usersLeft() > 0;
		},
		_createGroup: function _createGroup(groupName) {
			var _this9 = this;

			var organizationId = Ember.get(this, 'auth.organization.id');
			return this.store.createRecord('member-group', { groupName: groupName, organizationId: organizationId }).save().finally(function () {
				return _this9.getGroups();
			});
		},
		generatePassword: function generatePassword() {
			var now = _utils.Time.date();
			var orgName = Ember.get(this, 'auth.organization.organizationName').trim().toLowerCase().slice(0, 6);
			return orgName + now.format('DDMM');
		},


		requirePasswordObserver: Ember.observer('manualInvite', function () {
			if (Ember.get(this, 'manualInvite')) {
				Ember.set(this, 'requirePassword', true);
			} else {
				Ember.set(this, 'requirePassword', false);
			}
		}),

		refreshRoute: function refreshRoute() {
			this.send('refreshModel');
		},
		analyticsAddFilterEvent: function analyticsAddFilterEvent(element) {
			// Element values are one of the following: All, Archived, Ungrouped, Group, Search
			Ember.get(this, 'analytics').addEvent(61, { element: element });
		},
		analyticsAddSearchEvent: function analyticsAddSearchEvent(keyword) {
			// The search event should only fire when the user enters one or more character and it should be throttled to 1 event per 5 second windows.
			if (keyword && keyword.length > 1) {
				Ember.run.throttle(this, 'analyticsAddFilterEvent', 'Search', 5 * 1000);
			}
		},


		archiveDialogMessage: Ember.computed('currentMember', function () {
			var memberText = (0, _utils.loc)('these employees');
			if (!Ember.isNone(Ember.get(this, 'currentMember.fullName'))) {
				memberText = Ember.get(this, 'currentMember.fullName');
			}
			return (0, _utils.loc)('Are you sure you want to archive ') + memberText + '?';
		}),

		_archiveMember: function _archiveMember() {
			var _this10 = this;

			var members = Ember.get(this, 'members');
			var today = _utils.Time.timestamp();

			return this._clockOutMembers(members).then(function () {
				var promise = [];
				members.forEach(function (member) {
					// dont archive self or owner.
					if (Ember.get(member, 'id') !== Ember.get(_this10, 'auth.member.id') || Ember.get(member, 'id') !== Ember.get(_this10, 'auth.organization.ownedBy')) {
						Ember.set(member, 'archivedOn', today);
						promise.push(member.save());
					}
				});

				return Ember.RSVP.allSettled(promise).then(function () {
					Ember.set(_this10, 'members', null);
					Ember.set(_this10, 'currentMember', null);
					Ember.get(_this10, 'subscription').subtractSubscriptionMember();
				});
			});
		},
		saveNewMemberWage: function saveNewMemberWage(member) {
			var _this11 = this;

			var newMemberWage = Ember.get(this, 'newMemberWage');
			var store = Ember.get(this, 'store');

			return store.queryRecord('wage-history', { member_id: Ember.get(member, 'id'), page_size: 1 }).then(function (wage) {
				if (Ember.isNone(wage)) {
					var currentPayPeriod = Ember.get(_this11, 'payPeriodUtils').getPayPeriod(_utils.Time.timestamp());
					wage = store.createRecord('wage-history', {
						wageRate: 10, // hourly
						overburden: 0,
						changeDate: Ember.get(currentPayPeriod, 'start'),
						workWeek: '_-NULL-_'
					});
				}

				Ember.set(wage, 'wage', Ember.getWithDefault(newMemberWage, 'wage', 0));
				return wage.save();
			});
		},
		appcuesEvent: function appcuesEvent(eventName) {
			var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

			if (window.Appcues && typeof window.Appcues.track === 'function') {
				window.Appcues.track(eventName, data);
				window.Appcues.page();
			}
		},
		appcuesEventAddEmployee: function appcuesEventAddEmployee() {
			var _this12 = this;

			Ember.RSVP.resolve(Ember.get(this, 'auth.memberCount')).then(function (memberCount) {
				var totalEmployees = memberCount - 1; // owner doesn't count
				var addedFirstMember = totalEmployees === 1;

				if (addedFirstMember) {
					_this12.appcuesEvent('employees:addFirstEmployee', {});
				}
			});
		},


		// when a group is deleted, ungroup all the members which were assigned to it
		ungroupOrphans: function ungroupOrphans(group) {
			var _this13 = this;

			_logger.default.info(this, 'ungroupOrphans', group, Ember.get(group, 'id'));

			if (Ember.isNone(group)) {
				return Ember.RSVP.resolve();
			}

			var member_group_id = Ember.get(group, 'id');
			var params = {
				member_group_id: member_group_id,
				page_size: 1000
			};

			Ember.set(this, 'isLoading', true);
			return Ember.get(this, 'store').findAll('member', params).then(function (members) {
				members.setEach('memberGroupId', null);

				return Ember.RSVP.all(members.invoke('save'));
			}).then(function () {
				return _this13.refreshRoute();
			}).finally(function () {
				return Ember.set(_this13, 'isLoading', false);
			});
		},


		/* Graylog events */

		graylogAddedEmployee: function graylogAddedEmployee(position) {
			this.get('graylog').sendEvent({
				short_message: 'Added Employee',
				full_message: 'Added Employee',
				level: 6,
				_member_id: this.get('auth.memberId'),
				_member_position_id: this.get('auth.member.positionId'),
				_new_employee_position: position
			});
		},
		graylogEmployeeGroupAction: function graylogEmployeeGroupAction(actionType, selectedEmployeeCount) {
			this.get('graylog').sendEvent({
				short_message: 'Employee Group Action',
				full_message: 'Employee Group Action',
				level: 6,
				_member_id: this.get('auth.memberId'),
				_member_position_id: this.get('auth.member.positionId'),
				_action_type: actionType,
				_selected_employee_count: selectedEmployeeCount
			});
		},


		passwordRequirement: Ember.computed('newMember.password', function () {
			if (this.get('newMember.password.length') < 8) {
				return 'Password must be at least 8 characters';
			}
			return;
		}),

		actions: {
			addMemberToGroupAction: function addMemberToGroupAction(member) {
				this.addMemberToGroup(member);
			},
			toggleDetail: function toggleDetail() {
				Ember.set(this, 'showDetail', !Ember.get(this, 'showDetail'));
			},
			teamMemberClicked: function teamMemberClicked(item) {
				if (!Ember.get(this, 'edit')) {
					this.transitionToRoute('employees.employee', Ember.get(item, 'id'));
				}
			},
			teamMemberSelected: function teamMemberSelected(isChecked, item) {
				if (isChecked) {
					Ember.get(this, 'editItems').pushObject(item);
				} else {
					Ember.get(this, 'editItems').removeObject(item);
				}
			},
			selectAll: function selectAll(isChecked, items) {
				if (isChecked) {
					Ember.set(this, 'editItems', items);
				} else {
					Ember.set(this, 'editItems', []);
				}
			},
			editListAction: function editListAction(action) {
				var _this14 = this;

				Ember.set(this, 'editAction', Ember.get(action, 'key'));
				Ember.set(this, 'hideRowActions', true);

				this.updateList(Ember.get(action, 'key'));

				Ember.run.later(function () {
					return Ember.set(_this14, 'edit', true);
				}, 300);
			},
			submitEditAction: function submitEditAction() {
				if (Ember.get(this, 'editItems').length > 0) {
					this.dispatchTask(Ember.get(this, 'editAction'), Ember.get(this, 'editItems'));
				}

				this.clearEdit();
			},
			cancelEditAction: function cancelEditAction() {
				this.clearEdit();
			},
			openMemberDialog: function openMemberDialog() {
				var position = Ember.get(this, 'auth.positions.lastObject');
				var canAddEmployee = this.checkUsersAvailable();

				if (canAddEmployee) {
					// When Group Permissions Scoping is enabled, assign the new member to the same group as the auth member
					var memberGroupId = Ember.get(this, 'auth.useGroupPermissionScoping') ? Ember.get(this, 'auth.member.memberGroupId') : null;

					var member = Ember.get(this, 'store').createRecord('member', {
						organizationId: Ember.get(this, 'auth.organization.id'),
						position: position,
						memberGroupId: memberGroupId
					});

					var wage = Ember.get(this, 'store').createRecord('wage-history', {
						wage: 0
					});

					Ember.set(this, 'newMember', member);
					Ember.set(this, 'newMemberWage', wage);
					Ember.set(this, 'showDialog', true);
					Ember.set(this, 'showImportDialog', false);
				} else {
					Ember.set(this, 'showUpgradeDialog', true);
				}
			},
			selectNewMemberPosition: function selectNewMemberPosition(position) {
				Ember.set(this, 'newMember.position', position);
			},
			selectGpsLocation: function selectGpsLocation(item) {
				this.set('newMember.timeLocationRequired', item.get('key'));
			},
			closeMemberDialog: function closeMemberDialog() {
				Ember.set(this, 'showDialog', false);
				Ember.get(this, 'newMember').rollbackAttributes();
				Ember.set(this, 'newMember', null);
				Ember.set(this, 'manualInvite', false);
				Ember.set(this, 'requirePassword', false);
				Ember.set(this, 'sendEmailInvite', false);
				Ember.set(this, 'sendTextInvite', false);
			},
			closeUpgradeDialog: function closeUpgradeDialog() {
				Ember.set(this, 'showUpgradeDialog', false);
			},
			closeSubscriptionDialog: function closeSubscriptionDialog() {
				Ember.set(this, 'showSubscriptionDialog', false);
			},
			goToCompanySettings: function goToCompanySettings() {
				Ember.set(this, 'showSubscriptionDialog', false);
				Ember.set(this, 'showUpgradeDialog', false);
				window.location = '/company-settings#tab-subscription';
			},
			saveNewMember: function saveNewMember() {
				var _this15 = this;

				var newMember = Ember.get(this, 'newMember');

				var member = Ember.get(this, 'store').createRecord('member', {
					firstName: Ember.getWithDefault(newMember, 'firstName', '').trim(),
					lastName: Ember.getWithDefault(newMember, 'lastName', '').trim(),
					username: Ember.getWithDefault(newMember, 'username', '').trim(),
					password: Ember.get(newMember, 'password'),
					pendingEmail: Ember.getWithDefault(newMember, 'pendingEmail', '').trim(),
					phone: Ember.getWithDefault(newMember, 'phone', '').trim(),
					memberNumber: Ember.getWithDefault(newMember, 'memberNumber', '').trim(),
					organizationId: Ember.get(newMember, 'organizationId'),
					positionId: Ember.get(newMember, 'position.id'),
					sendEmail: Ember.get(this, 'sendEmailInvite'),
					sendText: Ember.get(this, 'sendTextInvite'),
					timeLocationRequired: Ember.get(newMember, 'timeLocationRequired'),
					memberGroupId: Ember.get(newMember, 'memberGroupId'),
					pinCode: Ember.get(newMember, 'pinCode')
				});

				// if (isEmpty(get(member, 'password')) && !get(this, 'requirePassword')) {
				// 	let password = this.generatePassword();
				// 	set(member, 'password', password);
				// }
				if (!Ember.isEmpty(Ember.get(member, 'firstName')) && !Ember.isEmpty(Ember.get(member, 'lastName')) && !Ember.isEmpty(Ember.get(member, 'username')) && !Ember.isEmpty(Ember.get(member, 'password')) && !Ember.isEmpty(Ember.get(newMember, 'passwordConfirm'))) {
					// if (get(member, 'sendEmail') && isEmpty(get(member, 'email'))) {
					// 	return reject('Email is required to email instructions to employee');
					// }
					//
					// if (get(member, 'sendText') && isEmpty(get(member, 'phone'))) {
					// 	return reject('Phone is required to text instructions to employee');
					// }
					//
					// if (!get(this, 'sendTextInvite') && !get(this, 'sendEmailInvite') && !get(this, 'manualInvite')) {
					// 	return reject('You must select an invitation format');
					// }

					if (Ember.get(member, 'password.length') >= 8) {
						if (Ember.get(newMember, 'passwordConfirm') !== Ember.get(newMember, 'password')) {
							return Ember.RSVP.reject((0, _utils.loc)('Password must match Confirm Password'));
						}

						if (!/^\S{2}\S*$/.test(Ember.get(member, 'username'))) {
							return Ember.RSVP.reject((0, _utils.loc)('Username must be at least 2 characters.'));
						}

						if (!Ember.isEmpty(Ember.get(member, 'pendingEmail'))) {
							if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(Ember.get(member, 'pendingEmail'))) {
								return Ember.RSVP.reject((0, _utils.loc)('Email must be a vaild email address.'));
							}
						} else {
							Ember.set(member, 'pendingEmail', null);
						}

						if (Ember.isEmpty(Ember.get(member, 'phone'))) {
							Ember.set(member, 'phone', null);
						}

						if (!Ember.isEmpty(Ember.get(member, 'pinCode'))) {
							if (!/^[0-9]{4}$/.test(Ember.get(member, 'pinCode'))) {
								return Ember.RSVP.reject((0, _utils.loc)('PIN code must be 4 numbers'));
							}
						}

						var wageAmount = Ember.get(this, 'newMemberWage.wage');
						if (wageAmount < 0) {
							return Ember.RSVP.reject((0, _utils.loc)('Pay Rate must be a positive number'));
						} else if (!/^([0-9]*|[0-9]+\.[0-9]{0,2})$/.test(wageAmount)) {
							return Ember.RSVP.reject((0, _utils.loc)('Pay Rate is an invalid amount'));
						}

						return this.store.rpcRequest('account', 'username-available', { username: Ember.get(member, 'username') }).then(function (data) {
							if (!data.data) {
								return Ember.RSVP.reject((0, _utils.loc)('This username is taken. Please choose another.'));
							} else {
								return member.save().then(function (member) {
									return Ember.get(_this15, 'subscription').addSubscriptionMember() && member;
								}).then(function (member) {
									return _this15.saveNewMemberWage(member) && member;
								}).then(function (member) {
									return _this15.appcuesEventAddEmployee() && member;
								}).finally(function () {
									_this15.graylogAddedEmployee(Ember.get(newMember, 'position.title'));
									_this15.send('closeMemberDialog');
									_this15.refreshRoute();
								});
							}
						});
					} else {
						return Ember.RSVP.reject((0, _utils.loc)('Password is invalid.'));
					}
				} else {
					return Ember.RSVP.reject((0, _utils.loc)('Please fill out all required fields.'));
				}
			},
			archiveMember: function archiveMember() {
				var _this16 = this;

				Ember.set(this, 'showArchiveDialog', false);
				Ember.set(this, 'isLoading', true);

				this._archiveMember().finally(function () {
					Ember.set(_this16, 'isLoading', false);
					_this16.refreshRoute();
				});
			},
			closeArchiveDialog: function closeArchiveDialog() {
				Ember.set(this, 'currentMember', null);
				Ember.set(this, 'members', null);
				Ember.set(this, 'showArchiveDialog', false);
			},
			closeClockIn: function closeClockIn() {
				Ember.set(this, 'openTaskItems', null);
				Ember.set(this, 'showClockInDialog', false);
				this.refreshRoute();
			},
			closeNewTimeEntry: function closeNewTimeEntry() {
				Ember.set(this, 'openTaskItems', null);
				Ember.set(this, 'showNewTimeEntryDialog', false);
				this.refreshRoute();
			},
			closeTimeOff: function closeTimeOff() {
				Ember.set(this, 'openTaskItems', null);
				Ember.set(this, 'showNewTimeOffDialog', false);
				this.refreshRoute();
			},
			closeClockInAt: function closeClockInAt() {
				Ember.set(this, 'openTaskItems', null);
				Ember.set(this, 'showClockInAtDialog', false);
				this.refreshRoute();
			},
			closeClockOutAt: function closeClockOutAt() {
				Ember.set(this, 'openTaskItems', null);
				Ember.set(this, 'showClockOutAtDialog', false);
				this.refreshRoute();
			},
			closeSwitchProject: function closeSwitchProject() {
				Ember.set(this, 'openTaskItems', null);
				Ember.set(this, 'showSwitchDialog', false);
				this.refreshRoute();
			},
			setSelectedGroup: function setSelectedGroup(view, group) {
				Ember.set(this, 'selectedGroup', group);
			},
			addNewGroup: function addNewGroup(value) {
				this._createGroup(value);
			},
			createGroup: function createGroup() {
				var _this17 = this;

				var name = Ember.get(this, 'newGroupName');
				if (!Ember.isEmpty(name)) {
					this._createGroup(name).then(function () {
						Ember.set(_this17, 'newGroupName', '');
					});
				}
			},
			showAddGroup: function showAddGroup() {
				Ember.set(this, 'shouldAddGroup', !Ember.get(this, 'shouldAddGroup'));
			},
			closeGroupDialog: function closeGroupDialog() {
				Ember.set(this, 'showMoveGroupDialog', false);
			},
			rowItemSelectAction: function rowItemSelectAction(action, member) {
				this.dispatchTask(Ember.get(action || {}, 'key'), [member]);
			},
			filterGroup: function filterGroup(group) {
				var options = {
					search: Ember.get(this, 'search'),
					filter: Ember.get(this, 'filter'),
					group: Ember.get(group, 'id')
				};

				if (options.group === null) {
					this.analyticsAddFilterEvent('All');
				} else if (options.group === 'ungrouped') {
					this.analyticsAddFilterEvent('Ungrouped');
				} else if (options.group === 'archived') {
					this.analyticsAddFilterEvent('Archived');
				} else {
					this.analyticsAddFilterEvent('Group');
				}

				this.sendFilter(options);
			},
			searchAction: function searchAction(keyword) {
				keyword = !Ember.isEmpty(keyword) ? keyword : '';
				this.analyticsAddSearchEvent(keyword);
				this.sendFilter({
					search: keyword,
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group')
				});
			},
			changeSize: function changeSize(size) {
				size = parseInt(size, 10);
				size = !isNaN(size) && size > 30 ? size : 30;

				// reset the page to 1
				this.sendFilter({
					page: 1,
					page_size: size,
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group'),
					search: Ember.get(this, 'search')
				});
			},
			changePage: function changePage(page) {
				page = parseInt(page, 10);
				page = !isNaN(page) && page > 1 ? page : 1;

				this.sendFilter({
					page: page,
					page_size: Ember.get(this, 'page_size'),
					filter: Ember.get(this, 'filter'),
					group: Ember.get(this, 'group'),
					search: Ember.get(this, 'search')
				});
			},
			openIntercom: function openIntercom() {
				Ember.$('#intercom-launcher > .intercom-launcher-button').click();
			},


			/*** Employees Import ***/

			openImportAction: function openImportAction() {
				Ember.set(this, 'showDialog', false);
				Ember.set(this, 'showImportDialog', true);
			},
			closeImportAction: function closeImportAction() {
				Ember.set(this, 'showImportDialog', false);
				Ember.set(this, 'showDialog', false);
			},
			cancelImportAction: function cancelImportAction() {
				Ember.set(this, 'showImportDialog', false);
				Ember.set(this, 'showDialog', true);
			},
			importCompleteAction: function importCompleteAction() {
				Ember.set(this, 'showDialog', false);
				Ember.set(this, 'showImportDialog', false);
				this.refreshRoute();
			},


			/*** Employees Import End ***/

			performCallToAction: function performCallToAction() {
				this.send('openSubscriptionDetails');
			},
			toggleUsernameConflictTooltip: function toggleUsernameConflictTooltip(member) {
				member.toggleProperty('showUsernameConflictTooltip');
				return false;
			},


			/**
    * @event closeLockDateDialog
    *
    */
			closeLockDateDialog: function closeLockDateDialog() {
				Ember.set(this, 'showLockDateDialog', false);
				Ember.set(this, 'showDialog', false);
			},
			openHelpDialog: function openHelpDialog() {
				Ember.set(this, 'showIntercom', true);
				Ember.set(this, 'showReceptive', true);
				Ember.set(this, 'showHelpDialog', true);
				this.send('closeUpgradeDialog');
			},
			closeHelpDialog: function closeHelpDialog() {
				Ember.set(this, 'showHelpDialog', false);
			},
			showSafetyDialog: function showSafetyDialog() {
				Ember.set(this, 'showSignOffRequiredDialog', true);
			},
			closeSafetyDialog: function closeSafetyDialog() {
				Ember.set(this, 'showSignOffRequiredDialog', false);
				this.refreshRoute();
			},
			closeSafetyDialogAndClockOut: function closeSafetyDialogAndClockOut() {
				var _this18 = this;

				Ember.set(this, 'showSafetySignOffDialog', false);
				this._clockOutMembers(Ember.get(this, 'openTaskItems')).then(function () {
					_this18.refreshRoute();
				});
			},
			openDialog: function openDialog(dialogName) {
				this.set('show' + dialogName, true);
			},
			closeDialog: function closeDialog(dialogName) {
				this.set('show' + dialogName, false);
			},
			onDeleteGroup: function onDeleteGroup(group) {
				_logger.default.info(this, 'onDeleteGroup', group);

				return this.ungroupOrphans(group);
			}
		}
	});
});