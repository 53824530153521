define('busy-app/components/model-title/project', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		tagName: '',
		state: Ember.inject.service(),

		/**
   * id is the only property that
   * should be passed in. All other
   * properties are set by the component.
   *
   * this is a id for the project model
   *
   * @property id
   * @type {string} model UUID
   */
		id: null,

		/*** props made available in yield hash ***/

		title: '',
		subTitle: '',
		condensedTitle: '',
		isUnassigned: false,
		hasInfo: false,
		project: null,

		/*** end props ***/

		didReceiveAttrs: function didReceiveAttrs() {
			this._super();

			// load project data from ember data cache
			this.getProjects();

			// set project titles
			this.setTitle();
		},
		getProjects: function getProjects() {
			var state = Ember.get(this, 'state');
			var id = Ember.get(this, 'id');
			if (!Ember.isEmpty(id)) {
				Ember.set(this, 'project', loadProjectFromLocalStore(state, id));
				Ember.set(this, 'hasInfo', true);
			} else {
				Ember.set(this, 'project', null);
				Ember.set(this, 'hasInfo', false);
			}
		},
		setTitle: function setTitle() {
			var project = Ember.get(this, 'project');

			var title = '';
			var subTitle = Ember.get(this, 'project.projectInfo.number') || '';
			var condensedTitle = '';
			var isUnassigned = false;

			if (!Ember.isNone(project)) {
				var titleParts = getTitle(project);
				title = titleParts.join(' / ');
				condensedTitle = project.title;
				if (titleParts.length > 1) {
					condensedTitle = condensedTitle + '&nbsp;&nbsp;<i>(' + titleParts[0] + ')</i>';
				}
			}

			if (Ember.isEmpty(title)) {
				title = (0, _utils.loc)("Unassigned");
				condensedTitle = title;
				isUnassigned = true;
			}

			this.setProperties({ title: title, subTitle: subTitle, condensedTitle: condensedTitle, isUnassigned: isUnassigned });
		}
	});


	var getTitle = function getTitle(project) {
		var title = [Ember.get(project, 'title')];
		if (!Ember.isNone(Ember.get(project, 'parentProject'))) {
			title = [].concat(getTitle(Ember.get(project, 'parentProject')), title);
		}
		return title;
	};

	/**
  * Loads a project from the state service
  * which is also only loading data from the local
  * ember data store.
  *
  * @method loadProjectFromLocalStore
  * @param state {StateService}
  * @param id {string} project id uuid
  * @return {Project | null}
  */
	function loadProjectFromLocalStore(state, id) {
		var project = state.getState('project', id);
		if (!Ember.isNone(project)) {
			loadParent(state, project);
			loadProjectInfo(state, project);
		}
		return project;
	}

	function loadParent(state, model) {
		var parent = void 0;
		if (model && model.parentProjectId && model.parentProjectId !== model.id) {
			parent = state.getState('project', model.parentProjectId);
			if (!Ember.isNone(parent)) {
				loadParent(state, parent);
			}
		}
		model.parentProject = parent;
	}

	function loadProjectInfo(state, model) {
		var projectInfo = state.getStateBy('project-info', 'projectId', model.id);
		model.projectInfo = projectInfo;
	}
});