define('busy-app/transforms/password', ['exports', 'ember-data', 'cryptojs'], function (exports, _emberData, _cryptojs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (!Ember.isNone(deserialized) && !Ember.isEmpty(deserialized) && deserialized !== '_-NULL-_') {
        deserialized = _cryptojs.default.SHA256(deserialized).toString();
      }
      return deserialized;
    }
  });
});