define('busy-app/components/subscriptions/order-confirmation', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	/***/
	var kSupportedSubscriptionTypes = ['annualInvoice', 'annualCredit', 'monthlyCredit'];

	/**
  * `Components/Subscriptions/OrderConfirmation`
  *
  * @class OrderConfirmation
  * @namespace Components.Subscriptions
  * @extends Component
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		auth: Ember.inject.service(),
		subscription: Ember.inject.service(),

		subscriptionType: null,
		invoiceId: null,
		invoiceDueDate: null,

		/**
   * @public
   * @method init
   * @constructor
   */
		init: function init() {
			this._super();
			var subscriptionType = this.get('subscriptionType');

			// ensure the specified `subscriptionType` is valid
			_utils.Assert.test('\'' + subscriptionType + '\' is not a supported subscriptionType', kSupportedSubscriptionTypes.includes(subscriptionType));

			// ensure invoiced sales include the additional required attributes
			if (subscriptionType === 'annualInvoice') {
				_utils.Assert.isInteger(this.get('invoiceDueDate'));
				_utils.Assert.isInteger(this.get('invoiceId'));
			}
		},


		actions: {
			/**
    * @public
    * @event onClose
    */
			onClose: function onClose() {
				if (!this.get('isDestroyed')) {
					this.sendAction('onClose');
				}
			},


			/**
    * @public
    * @event openInvoice
    * @param invoiceId {number}
    */
			openInvoice: function openInvoice(invoiceId) {
				return this.get('subscription').openPdfInvoice(invoiceId);
			}
		}
	});
});