define('busy-app/routes/project/detail', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(param) {
      var _this = this;

      var id = param.id;

      return this.store.findRecord('project', id).then(function (model) {
        return Ember.RSVP.hash({
          project: model,
          subprojects: _this.store.findAll('project', { _asc: ['title'], parent_project_id: model.id, deleted_on: null })
        });
      });
    }
  });
});