define('busy-app/transforms/boolean-null', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Transform.extend({
		deserialize: function deserialize(serialized) {
			if (!Ember.isNone(serialized)) {
				if (typeof serialized === 'string') {
					if (serialized === 'true') {
						serialized = true;
					} else if (serialized === '1') {
						serialized = true;
					} else {
						serialized = false;
					}
				}

				if (serialized === 1) {
					serialized = true;
				} else if (serialized === true) {
					serialized = true;
				} else {
					serialized = false;
				}
			}
			return serialized;
		},
		serialize: function serialize(deserialized) {
			return deserialized;
		}
	});
});