define('busy-app/services/pro-feature', ['exports', 'busy-app/utils/logger'], function (exports, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var DEFAULT_ACTION = function DEFAULT_ACTION() {
		_logger.default.error('ProFeature', 'No action queued');
		return;
	}; /**
     * @module Services
     *
     */


	var ProFeatureManager = Ember.Object.extend({
		isModalVisible: false,

		_queuedAction: DEFAULT_ACTION,

		requestAction: function requestAction(featureFlag, actionRequested) {
			//Logger.info(this, 'requestAction', { featureFlag, actionRequested });

			if (this.allowProFeature(featureFlag)) {
				actionRequested();
			} else {
				//Logger.warn(this, 'requestAction', 'access denied', { featureFlag, actionRequested });
				this.onDeny(actionRequested);
			}
		},
		onDeny: function onDeny(actionRequested) {
			//Logger.info(this, 'onDeny', { actionRequested });

			this.set('_queuedAction', actionRequested);
			this.openModal();
		},
		onAllow: function onAllow() {
			var fn = this.get('_queuedAction');

			_logger.default.info(this, 'onAllow', { fn: fn });

			if (typeof fn === 'function') {
				fn();
			} else {
				_logger.default.error(this, 'onAllow', 'queued action not a valid function', { fn: fn });
			}

			this.set('_queuedAction', DEFAULT_ACTION);
		},
		openModal: function openModal() {
			this.set('isModalVisible', true);
		},
		closeModal: function closeModal() {
			this.set('isModalVisible', false);
		}
	});

	/**
  * `Services/ProFeature`
  *
  * @class ProFeature
  * @namespace Services
  * @extends Services
  */
	exports.default = Ember.Service.extend({
		features: Ember.inject.service('features'),
		subscription: Ember.inject.service('subscription'),

		getManager: function getManager() {
			for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
				args[_key] = arguments[_key];
			}

			var props = Object.assign({
				allowProFeature: this.allowProFeature.bind(this) // pass the method bound to this service so that the features service works
			}, args);

			return ProFeatureManager.create(props);
		},
		startTrial: function startTrial() {
			_logger.default.warn(this, 'startTrial');
			return this.get('subscription').startTrial();
		},
		startUpgrade: function startUpgrade() {
			_logger.default.warn(this, 'startUpgrade');
			return this.get('subscription').startUpgrade();
		},


		// determine if user is allowed access to the specified feature
		allowProFeature: function allowProFeature(featureFlag) {
			//Logger.info(this, 'allowProFeature', { featureFlag });
			return this.get('features').isEnabled(featureFlag);
		}
	});
});