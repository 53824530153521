define('busy-app/utils/cost-code-csv-import', ['exports', 'busy-app/utils/csv-import', '@busy-web/utils'], function (exports, _csvImport, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _csvImport.default.extend({
		headerLists: null,
		showInsurance: false,

		defaultMap: Ember.computed('showInsurance', function () {
			var data = {
				costCode: "Cost Code #",
				title: "Description",
				unitTitle: "Unit of Measure"
			};

			if (this.get('showInsurance')) {
				data.workersCompTitle = "Insurance Workers Compensation Class";
				data.generalLiabilityTitle = "Insurance General Liability Class";
			}
			return data;
		}),

		triggerParse: function triggerParse() {
			this.parse();
		},
		setHeaderByListType: function setHeaderByListType(listItem) {
			this.set('headerMap.' + listItem.get('mapKey'), listItem.get('value'));
		},
		buildHeaderLists: function buildHeaderLists(headers) {
			var _this = this;

			var defaultMap = this.get('defaultMap');
			defaultMap = this.setHeaderMap(defaultMap);
			var headerList = [];
			Ember.$.each(defaultMap, function (mapKey, mapHeader) {
				var simpleKey = mapHeader.camelize();
				if (defaultMap.hasOwnProperty(mapKey)) {
					var list = Ember.Object.create({ key: mapKey, label: mapHeader, value: [] });
					headers.forEach(function (header) {
						var simpleHeader = header.camelize();
						var isSelected = simpleKey === simpleHeader;
						if (!isSelected && mapKey === 'costCode' && /cost/.test(simpleHeader)) {
							isSelected = true;
						} else if (!isSelected && mapKey === 'title' && /desc/.test(simpleHeader)) {
							isSelected = true;
						} else if (!isSelected && mapKey === 'unitTitle' && /unit/.test(simpleHeader)) {
							isSelected = true;
						}

						var item = Ember.Object.create({ key: simpleHeader, mapKey: mapKey, value: header, _selected: isSelected });
						if (isSelected) {
							_this.setHeaderByListType(item);
						}
						list.get('value').pushObject(item);
					});
					headerList.pushObject(list);
				}
			});
			this.set('headerLists', headerList);
		},
		createNewModel: function createNewModel() {
			return this.get('store').createRecord('cost-code', {
				id: _utils.UUID.generate(),
				organizationId: this.get('organizationId')
			});
		},
		beforeSave: function beforeSave(models) {
			var _this2 = this;

			var glArray = [];
			var wcArray = [];
			models.forEach(function (model) {
				var glTitle = model.get('generalLiabilityTitle');
				var wcTitle = model.get('workersCompTitle');
				if (!Ember.isNone(glTitle)) {
					var glModel = glArray.findBy('title', glTitle);
					if (Ember.isNone(glModel)) {
						glModel = _this2.get('store').createRecord('general-liability', {
							id: _utils.UUID.generate(),
							organizationId: _this2.get('organizationId'),
							title: glTitle
						});
						glArray.pushObject(glModel);
					}
					model.set('generalLiabilityId', glModel.id);
					model.set('generalLiability', glModel);
				}

				if (!Ember.isNone(wcTitle)) {
					var wcModel = wcArray.findBy('title', wcTitle);
					if (Ember.isNone(wcModel)) {
						wcModel = _this2.get('store').createRecord('workers-comp', {
							id: _utils.UUID.generate(),
							organizationId: _this2.get('organizationId'),
							title: wcTitle
						});
						wcArray.pushObject(wcModel);
					}
					model.set('workersCompId', wcModel.id);
					model.set('workersComp', wcModel);
				}
			});

			var insArray = wcArray.concat(glArray);
			var promise = [];
			insArray.forEach(function (item) {
				promise.push(item.saveBatch(true));
			});
			return Ember.RSVP.all(promise);
		},
		onFinishFileParse: function onFinishFileParse(headers, data) {
			this._super(headers, data);
			this.buildHeaderLists(headers);
		},
		hasModel: function hasModel(model) {
			var found = this.get('model').find(function (item) {
				if (item.get('costCode') === model.get('costCode')) {
					return true;
				}
				return false;
			});
			return !Ember.isNone(found) ? found : false;
		},
		modelHasData: function modelHasData(model) {
			var costCode = (model.get('costCode') || '').toString().trim();
			if (!Ember.isEmpty(costCode)) {
				return true;
			}
			return false;
		}
	});
});