define('busy-app/utils/time-zones', ['exports', '@busy-web/utils/utils/time-zones'], function (exports, _timeZones) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _timeZones.default;
    }
  });
});