define('busy-app/models/stripe-customer-subscription', ['exports', 'ember-data', '@busy-web/data'], function (exports, _emberData, _data) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr;
	exports.default = Model.extend(_data.RPCModelMixin, {
		_clientName: 'busy-stripe',

		cancelAtPeriodEnd: attr('boolean'),
		cancelAt: attr('number'),
		currentPeriodEnd: attr('number'),
		currentPeriodStart: attr('number'),
		endedAt: attr('number'),
		planDescription: attr('string'),
		planId: attr('string'),
		start: attr('number'),
		status: attr('string'),
		trialEnd: attr('number'),
		trialStart: attr('number')
	});
});