define('busy-app/components/select-menu/base', ['exports', '@busy-web/utils', '@busy-web/components/mixins/close-on-escape', 'busy-app/utils/logger'], function (exports, _utils, _closeOnEscape, _logger) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend(_closeOnEscape.default, {

		classNames: ['c-select-menu'],

		auth: Ember.inject.service('auth'),
		features: Ember.inject.service('features'),
		state: Ember.inject.service('state'),
		store: Ember.inject.service('store'),

		model: null,
		page: 1,
		pageSize: 30,

		/**
   * hides and shows the menu
   *
   * @private
   * @property isMenuOpen
   * @type boolean
   */
		isMenuOpen: false,
		slideIn: false,
		disabled: false,
		autoOpen: false,

		/**
   * the title to show after a selection has been made
   *
   * @property selectedTitle
   * @type string
   */
		selectedTitle: '',

		/**
   * the selected item stored for refreference
   *
   * @property selectedItem
   * @type object
   */
		selectedItem: null,

		keyword: '',

		/**
   * the title showing in the menu.
   *
   * computed property calculated off the selectedTitle
   *
   * @property menuTitle
   * @type string
   */
		menuTitle: Ember.computed('selectedTitle', function () {
			// default title is `None`
			var title = (0, _utils.loc)('None');

			// if the selectedTitle is set to non-empty string
			// then use it for the title
			if (!Ember.isEmpty(Ember.get(this, 'selectedTitle'))) {
				title = Ember.get(this, 'selectedTitle');
			}

			// assert title is a string and return it
			_utils.Assert.isString(title);

			return title;
		}),

		_loadData: function _loadData() {
			var _this = this;

			// Logger.info(this, '_loadData');

			Ember.setProperties(this, {
				model: [],
				isLoaded: false
			});

			var keyword = Ember.get(this, 'keyword');
			var dataLoader = Ember.isEmpty(keyword) ? this.loadData.bind(this) : this.loadSearch.bind(this, keyword);

			return Ember.RSVP.hash({
				recentModel: this.loadRecent(), // load recent data
				data: dataLoader() // load model data
			}).then(function (_ref) {
				var recentModel = _ref.recentModel,
				    data = _ref.data;

				var model = Ember.isNone(Ember.get(data, 'results')) ? data : Ember.get(data, 'results');
				var totalRows = Ember.get(data, 'meta.totalRows');
				// Logger.info(this, '_loadData', get(model, 'length'));

				Ember.setProperties(_this, {
					recentModel: recentModel,
					totalRows: totalRows,
					model: model
				});

				return model;
			}).finally(function () {
				Ember.set(_this, 'model.isLoaded', true);
			});
		},
		loadRecent: function loadRecent() {
			_utils.Assert.throw('Function must be overriden by classes extending SelectMenu.Base');
		},
		loadData: function loadData() {
			_utils.Assert.throw('Function must be overriden by classes extending SelectMenu.Base');
		},
		_loadSearch: function _loadSearch(keyword) {
			var _this2 = this;

			return this.loadSearch(keyword).then(function (data) {
				var model = Ember.isNone(Ember.get(data, 'results')) ? data : Ember.get(data, 'results');
				var totalRows = Ember.get(data, 'meta.totalRows');
				// Logger.info(this, '_loadSearch', get(model, 'length'));

				Ember.setProperties(_this2, {
					totalRows: totalRows,
					model: model
				});
			});
		},
		loadSearch: function loadSearch() /*keyword*/{
			_utils.Assert.throw('Function must be overriden by classes extending SelectMenu.Base');
		},
		getPropertyArray: function getPropertyArray(array, key) {
			var keys = [];
			array.forEach(function (item) {
				var val = Ember.get(item, key);
				if (!Ember.isEmpty(val) && keys.indexOf(val) === -1) {
					keys.push(val);
				}
			});
			return keys;
		},
		startLoading: function startLoading() {
			Ember.set(this, 'isLoading', true);
		},
		stopLoading: function stopLoading() {
			var _this3 = this;

			Ember.run.later(function () {
				return Ember.set(_this3, 'isLoading', false);
			}, 300);
		},
		onEscape: function onEscape() {
			return this.closeMenuHandler(false).then(function () {
				return false;
			});
		},
		reset: function reset() {
			// Logger.info(this, 'reset');

			Ember.setProperties(this, {
				// save selected
				_selectedTitle: Ember.get(this, 'selectedTitle'),
				_selectedItem: Ember.get(this, 'selectedItem'),

				// set selected to none
				selectedTitle: '',
				selectedItem: null,

				// reset page to 1
				page: 1,
				keyword: null
			});
		},
		restore: function restore() {
			// Logger.info(this, 'restore');

			Ember.setProperties(this, {
				// restore selected values
				selectedTitle: Ember.get(this, '_selectedTitle'),
				selectedItem: Ember.get(this, '_selectedItem'),

				// clear saved values
				_selectedTitle: '',
				_selectedItem: null
			});
		},
		openMenuHandler: function openMenuHandler(openedBy) {
			var _this4 = this;

			_logger.default.info(this, 'openMenuHandler', openedBy);
			if (!Ember.get(this, 'isDestroyed') && !Ember.get(this, 'isOpening')) {

				Ember.setProperties(this, {
					openedBy: openedBy,
					isOpening: true
				});

				// bind escape key to close menu
				this.unbindEscape();
				this.bindEscape();

				// reset menu so it opens fresh
				this.reset();

				// show loading animation
				this.startLoading();

				// open menu to show overlay background
				Ember.set(this, 'isMenuOpen', true);

				// load data
				this._loadData().then(function () {

					// stop loading
					_this4.stopLoading();

					// trigger menu slide in anamation
					Ember.setProperties(_this4, {
						slideIn: true,
						isOpening: false
					});
				});
			}
		},
		closeMenuHandler: function closeMenuHandler(didSelectValue) {
			var _this5 = this;

			_logger.default.info(this, 'closeMenuHandler', didSelectValue, Ember.get(this, 'selectedItem'), Ember.get(this, 'openedBy'));

			if (!this.get('isDestroyed') && !Ember.get(this, 'isClosing')) {
				Ember.set(this, 'isClosing', true);

				return new Ember.RSVP.Promise(function (resolve) {
					// remove escape listeners
					_this5.unbindEscape();

					// slide menu out of view
					Ember.set(_this5, 'slideIn', false);

					// restore previous menu selections
					_this5.restore();

					// wait for menu animation
					Ember.run.later(_this5, function () {

						// remove menu overlay
						Ember.setProperties(this, {
							isMenuOpen: false,
							isClosing: false
						});

						this.sendAction('onMenuClose', didSelectValue, Ember.get(this, 'selectedItem'), Ember.get(this, 'openedBy'));

						return resolve(true);
					}, 300);
				});
			} else {
				return Ember.RSVP.resolve();
			}
		},
		handleBack: function handleBack() {
			return this.closeMenuHandler(false);
		},


		/**
   * Sets the selected title with the selected item
   *
   * @private
   * @method setSelectedTitle
   * @param item
   * @return {void}
   */
		getSelectedTitle: function getSelectedTitle() /*item*/{
			_utils.Assert.throw('Function must be overriden by classes extending SelectMenu.Base');
		},
		selectHandler: function selectHandler(item) {
			// Logger.info(this, 'selectHandler');
			if (!Ember.get(this, 'isDestroyed')) {
				// save selection
				if (Ember.isNone(item)) {
					Ember.set(this, 'selectedTitle', '');
					Ember.set(this, 'selectedItem', null);
				} else {
					Ember.set(this, 'selectedTitle', this.getSelectedTitle(item));
					Ember.set(this, 'selectedItem', item);
				}

				// call reset to update the saved selection
				this.reset();

				// send select action
				this.sendAction('onSelect', item);

				// close menu
				return this.closeMenuHandler(true);
			}
		},


		actions: {
			openMenu: function openMenu() {
				var openedBy = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "manual";

				_logger.default.info(this, 'action', 'openMenu', openedBy);
				if (!Ember.get(this, 'disabled')) {
					this.openMenuHandler(openedBy);
				}
			},
			closeMenu: function closeMenu() {
				// Logger.info(this, 'action', 'closeMenu');
				return this.closeMenuHandler(false);
			},
			selectAction: function selectAction(item) {
				// Logger.info(this, 'action', 'selectAction');
				return this.selectHandler(item);
			},
			selectNoneAction: function selectNoneAction() {
				return this.selectHandler(null);
			},
			changePage: function changePage(page) {
				Ember.setProperties(this, { page: page });
				return this._loadData();
			},
			changeSize: function changeSize(pageSize) {
				Ember.setProperties(this, {
					pageSize: pageSize,
					page: 1
				});
				return this._loadData();
			},
			backAction: function backAction() {
				return this.handleBack();
			},
			searchAction: function searchAction(keyword) {
				// Logger.info(this, 'searchAction', keyword);

				Ember.setProperties(this, {
					keyword: keyword,
					page: 1
				});

				return this._loadData();
			}
		}
	});
});