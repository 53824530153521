define('busy-app/components/version-refresher', ['exports', '@busy-web/utils', 'busy-app/utils/settings'], function (exports, _utils, _settings) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	var kVersionKey = 'busy-version-info';

	/**
  *
  */
	/**
  * @module Components
  *
  */
	exports.default = Ember.Component.extend({
		classNames: ['v-version-box'],

		features: Ember.inject.service('features'),

		/**
   * flag for showing the version dialog
   *
   * @property isNewVersion
   * @type boolean
   */
		isNewVersion: false,

		isMinor: false,
		isPatch: false,
		version: null,

		init: function init() {
			this._super();

			if (this.get('features.version-refresh')) {
				this.interval();
			}
		},


		/**
   * returns a default list for local storeage
   *
   */
		defaultList: function defaultList() {
			return { current: _settings.default.get('version'), ignore: [] };
		},
		blackList: function blackList() {
			// get list from local storage
			var list = _utils.LocalStorage.getProperty(kVersionKey);

			// if the list has never been set it will be undefined
			if (Ember.isNone(list)) {
				list = this.defaultList();
			} else {
				// parse the json string
				list = JSON.parse(list);

				// if the current version does not math the app version
				// then return a new default list
				if (list.current !== _settings.default.get('version')) {
					list = this.defaultList();
				}
			}
			return list;
		},
		addToBlackList: function addToBlackList(version) {
			_utils.Assert.isString(version);

			// get the black listed versions
			var list = this.blackList();

			// if its not already in the list then add it
			if (list.ignore.indexOf(version) === -1) {
				list.ignore.push(version);
				_utils.LocalStorage.setProperty(kVersionKey, JSON.stringify(list));
			}
		},
		reset: function reset() {
			// reset the local store black list to a new default list
			_utils.LocalStorage.setProperty(kVersionKey, JSON.stringify(this.defaultList()));
		},
		interval: function interval() {
			// set timer for 5 minute interval checking
			Ember.run.later(this, function () {
				// check version data
				this.checkVersion();

				// reset timer
				this.interval();
			}, 300000);
		},
		checkVersion: function checkVersion() {
			var _this = this;

			// make a call to get the json version number
			Ember.$.getJSON('//' + window.location.host + '/version.json', function (data) {
				// if data is an object with a version
				if (data && data.version) {
					// validate the version number
					_this.validate(data.version);
				}
			});
		},
		validate: function validate(version) {
			_utils.Assert.isString(version);

			// store the version for later
			this.set('version', version);

			// set bollean default values
			var isNewVersion = false;
			var isMinor = false;
			var isPatch = false;

			// make sure we are not already on the new version
			if (version !== _settings.default.get('version')) {
				// get the black list
				var list = this.blackList();

				// check if the version is in the black list
				if (list.ignore.indexOf(version) === -1) {
					// split the versions into arrays to check minor and patch changes
					var oldVer = list.current.split('.');
					var newVer = version.split('.');

					// future check for only triggering a message on minor version changes
					if (oldVer[oldVer.length - 2] !== newVer[newVer.length - 2]) {
						isMinor = true;
					}

					// future check for triggering a message on patch changes
					if (oldVer[oldVer.length - 1] !== newVer[newVer.length - 1]) {
						isPatch = true;
					}

					// always trigger message for now
					if (isMinor || isPatch) {
						isNewVersion = true;
					}
				}
			}

			this.set('isNewVersion', isNewVersion);
			this.set('isMinor', isMinor);
			this.set('isPatch', isPatch);
		},


		actions: {
			refresh: function refresh() {
				this.set('isNewVersion', false);
				this.set('isMinor', false);
				this.set('isPatch', false);

				// reset the black list with the new version info.
				this.reset();

				// refresh the current page
				window.location = window.location.pathname;
			},
			cancel: function cancel() {
				this.set('isNewVersion', false);
				this.set('isMinor', false);
				this.set('isPatch', false);

				// add the version to the black list
				this.addToBlackList(this.get('version'));
			}
		}
	});
});