define('busy-app/components/projects/cost-codes-manager/group', ['exports', '@busy-web/utils'], function (exports, _utils) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		// arguments
		costCodeGroup: null,
		createCostCodeLink: null,
		deleteCostCodeLink: null,

		// properties
		_expanded: false,

		toggleGroupSelection: function toggleGroupSelection(group) {
			var _this = this;

			// Logger.info(this, 'toggleGroupSelection', get(group, 'groupName'), !get(group, '_selected'));

			var includeCostCode = !Ember.get(group, '_selected');
			var now = _utils.Time.timestamp();

			Ember.get(group, '_costCodes').forEach(function (costCode) {
				if (includeCostCode) {
					Ember.get(_this, 'createCostCodeLink')(costCode, now);
				} else {
					Ember.get(_this, 'deleteCostCodeLink')(costCode, now);
				}
			});

			return group;
		},


		actions: {
			toggleGroupSelection: function toggleGroupSelection(group) {
				// Logger.info(this, 'toggleGroupSelection', get(group, 'id'), get(group, '_selected'), { arguments: [...arguments] });

				return this.toggleGroupSelection(group);
			},
			toggleGroupDetails: function toggleGroupDetails() {
				// Logger.info(this, 'toggleGroupDetails', get(this, 'costCodeGroup.groupName'), get(this, '_expanded'));

				return this.toggleProperty('_expanded');
			}
		}
	});
});