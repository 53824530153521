define('busy-app/models/stripe-customer-invoice', ['exports', 'ember-data', '@busy-web/utils/time', '@busy-web/data'], function (exports, _emberData, _time, _data) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberData.default.Model,
	    attr = _emberData.default.attr,
	    belongsTo = _emberData.default.belongsTo;
	exports.default = Model.extend(_data.RPCModelMixin, {
		_clientName: 'busy-stripe',
		_methodName: 'get-stripe-invoices',

		ammountDue: attr('number'),
		attemptCount: attr('number'),
		card: attr('string'),
		date: attr('number'),
		livemode: attr('boolean'),
		paid: attr('boolean'),
		periodEnd: attr('number'),
		periodStart: attr('number'),
		subtotal: attr('number'),
		total: attr('number'),

		discount: belongsTo('stripe-customer-discount'),
		lines: belongsTo('stripe-customer-line'),

		invoiceDate: Ember.computed('data', function () {
			if (!Ember.isNone(this.get('date'))) {
				return _time.default.date(this.get('date')).format('MM/DD/YY');
			}
			return '';
		}),

		dateRange: Ember.computed('periodStart', 'periodEnd', function () {
			if (!Ember.isNone(this.get('periodStart')) && !Ember.isNone(this.get('periodEnd'))) {
				var startDate = _time.default.date(this.get('periodStart')).format('MM/DD/YY');
				var endDate = _time.default.date(this.get('periodEnd')).format('MM/DD/YY');
				return startDate + ' - ' + endDate;
			}
			return '';
		}),

		totalPrice: Ember.computed('total', function () {
			if (!Ember.isNone(this.get('total'))) {
				return '$' + ((this.get('total') || 0) / 100).toFixed(2);
			}
			return '';
		})
	});
});